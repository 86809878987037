import React, { Component } from 'react';
import { format, addDays } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import NumberFormat from 'react-number-format';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import LoadingPopup from '../../controls/loadingPopup';
import http from '../../../service/httpService';
import { ReportManagement } from '../../../service/api';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';

var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var getMonth = require('date-fns/get_month');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class TotalOnTimeIdleTimeUtilization extends Component {

    constructor(props, context) {
        super(props, context);
        
        this.state = {
            type: 'Month',
            filterType: "Month",
            startDate: '',
            endDate: '',
            loaderIconVisible: false,
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            datapickflag:false,
            widgetInformation:[]
        };

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount = async () => {
        this.setState({
            filterType: "Month",
        });
        currentUnit = this.props.properties.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        this.getTotalOnTimeIdleTimeUtilization("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
    }

    componentDidUpdate = async (prevProp, prevState) => {
        if (this.props.globalDropdownFilter !== "Year" && this.props.globalDropdownFilter !== "Quarter") {
			if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
				this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
				this.fetchData(this.props.globalDropdownFilter);
				await this.props.handleDropdownChangeEnd();
			} else {
				if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
					if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
						this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
						await this.props.handleDropdownChangeEnd();
					}
					if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
						this.handleChangeStart(this.props.globalDropdownFilterStartDate);
						await this.props.handleDropdownChangeEnd();
					}
				}
			}
		}
	}


    getTotalOnTimeIdleTimeUtilization(type, fromdate, todate, unitID, brandID) {
        this.setState({ loaderIconVisible: true });
        currentUnit = this.props.properties.currentUnit;
        let singleLane = ((currentUnit.MODEL_NAME === "XPE12") || (currentUnit.MODEL_NAME === "XPG12") || (currentUnit.MODEL_NAME === "ME-12") || (currentUnit.MODEL_NAME === "MG-12"));
        let twoLane = ((currentUnit.MODEL_NAME === "XPE24") || (currentUnit.MODEL_NAME === "XPG24") || (currentUnit.MODEL_NAME === "ME-24") || (currentUnit.MODEL_NAME === "MG-24"));
        let url = '';
        let filterType = '';
        switch (type) {
            case 'Week':
                filterType = 'weekly';
                break;
            case 'Year':
                filterType = 'yearly';
                break;
            case 'Day':
                filterType = 'daily';
                break;
            case 'Quarter':
                filterType = 'quarterly';
                break;
            case 'Date Range':
                filterType = 'custom';
                break;
            default:
                filterType = 'monthly';
        }

        url = `${ReportManagement.garlandMultiWidget}`;

        const header = {
            'Content-Type': 'application/json',
            'unitid': unitID,
            'reqdate': fromdate,
            'filtertype': filterType,
            'laneid': singleLane ? 1 : twoLane ? 2 : 3,
            'showtrend': "true"
        }

        if (filterType === 'custom') {
            delete header.reqdate;
            header.startdate = todate;
            header.enddate = fromdate;
            header.showtrend = "false";
        }
        else if (filterType === 'weekly') {
            delete header.reqdate;
            header.startdate = todate;
            header.enddate = fromdate;
        }

        this.getData(url, header).then(response => {
            console.log(response)
            let currentValue = null;
            let priorValue = null;
            let trend = 0;
            let lbsOilCurrentValue = null;
            let lbsOilPriorValue = null;
            let lbsOilTrend = 0;
            if (response && response.widgetInformation && response.widgetInformation[0].avgOilLife) {
                currentValue = response.widgetInformation[0].avgOilLife !== undefined ? response.widgetInformation[0].avgOilLife[0].value : 0;
                priorValue = response.widgetInformation[0].avgOilLife !== undefined && response.widgetInformation[0].avgOilLife.length > 1 ? response.widgetInformation[0].avgOilLife[1].value : 0;
                trend = response.widgetInformation[0].avgOilLife !== undefined && response.widgetInformation[0].avgOilLife.length > 2 ? response.widgetInformation[0].avgOilLife[2].value : 0;
            }
            if (response && response.widgetInformation && response.widgetInformation[0].totalLBSOilDisposed) {
                lbsOilCurrentValue = response.widgetInformation[0].totalLBSOilDisposed !== undefined ? response.widgetInformation[0].totalLBSOilDisposed[0].value : 0;
                lbsOilPriorValue = response.widgetInformation[0].totalLBSOilDisposed !== undefined && response.widgetInformation[0].totalLBSOilDisposed.length > 1 ? response.widgetInformation[0].totalLBSOilDisposed[1].value : 0;
                lbsOilTrend = response.widgetInformation[0].totalLBSOilDisposed !== undefined && response.widgetInformation[0].totalLBSOilDisposed.length > 2 ? response.widgetInformation[0].totalLBSOilDisposed[2].value : 0;
            }

            this.setState({
                loaderIconVisible: false,
                currentValue: currentValue,
                priorValue: priorValue,
                trend: trend,
                lbsOilCurrentValue: lbsOilCurrentValue,
                lbsOilPriorValue: lbsOilPriorValue,
                lbsOilTrend: lbsOilTrend,
                widgetInformation:response.widgetInformation
            });
        }).catch(error => {
            this.setState({ loaderIconVisible: false });
            console.log(error);
        });
    }

    getData(url, header) {
        return new Promise((resolve, reject) => {
            http
                .get(url,
                    {
                        headers: header,
                        data: {}
                    })
                .then(response => {
                    resolve({
                        widgetInformation: response.data
                    })
                })
                .catch(error => {
                    reject({
                        widgetInformation: {}
                    })
                });
        });
    }

    handleChangeStart(filtrationDate, mindate) {
        this.setState({
            startDate: filtrationDate,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            this.onDropDownHandler(e, 'LBSOilLifeDispose');
        });
    }

    handleChangeEnd(filtrationDate) {
        this.setState({
            endDate: filtrationDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            this.onDropDownHandler(e, 'LBSOilLifeDispose');
        });
    }

    fetchData(text) {
        currentUnit = this.props.properties.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else if (text === 'Week') {
			weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
			todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			var startDateMonth = getMonth(new Date(weekDateFormatted));
			var endDateMonth = getMonth(new Date(todaysDateFormatted));

			if (startDateMonth !== endDateMonth) {
				weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
			}
		}
        else {
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
        }

        this.setState({ type: text, datapickflag: text === 'Date Range' });
        if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.getTotalOnTimeIdleTimeUtilization(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
        }
    }

    onDropDownHandler(e, type = '') {
        this.fetchData(e.nodeText);
        this.setState({
            filterType: e.nodeText,
            datapickflag: e.nodeText === 'Date Range'
        });
    }

    render() {
        let { 
            loaderIconVisible,widgetInformation,type } = this.state;
        currentUnit = this.props.properties.currentUnit;
        let oilUsageFilterTabHeader = null;
        let singlePlaten = ((currentUnit.MODEL_NAME === "XPE12") || (currentUnit.MODEL_NAME === "XPG12") || (currentUnit.MODEL_NAME === "ME-12") || (currentUnit.MODEL_NAME === "MG-12"));
        let twoPlaten = ((currentUnit.MODEL_NAME === "XPE24") || (currentUnit.MODEL_NAME === "XPG24") || (currentUnit.MODEL_NAME === "ME-24") || (currentUnit.MODEL_NAME === "MG-24"));
        let threePlaten = ((currentUnit.MODEL_NAME === "XPE36") || (currentUnit.MODEL_NAME === "XPG36") || (currentUnit.MODEL_NAME === "ME-36") || (currentUnit.MODEL_NAME === "MG-36"));
        if (currentUnit.BRAND_NAME === 'GARLAND') {
            oilUsageFilterTabHeader = (
                <div className="pageTabHeader greybg">
                    <ul>
                        <li className="filterDropdown">
                            <p className="globalFilterText"><FormattedMessage id="KC0097"/></p>
                            <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate}
                                minDateDisable={this.state.sdmindate}
                                maxDateDisable={this.state.sdmaxdate}
                                endminDateDisable={this.state.edmindate}
                                endmaxDateDisable={this.state.edmaxdate}
                                endDatepara={this.state.endDate}
                                handleChangeStartProps={(date) => this.handleChangeStart(date)}
                                handleChangeEndProps={(date) => this.handleChangeEnd(date)}
                                OnChange={(e) => this.onDropDownHandler(e, "LBSOilLifeDispose")}
                                filterType={this.state.type}
                                />
                        </li>
                    </ul>
                </div>
            )
        }

        function getConsumptionDate(i, j, key) {
            let date, durationValue, days, hours, mins, valueIndex;
            if (key === "currentConsumption") {
                valueIndex = 0;
            } else if (key === "lastConsumption") {
                valueIndex = 1;
            }
            try {
                date = widgetInformation[i][j].content[valueIndex].value;
            } catch (error) {
                date = "0days 0h 0m";
            }
            durationValue = date.split(" ");
            days = durationValue[0].replace("days", "");
            hours = durationValue[1].replace("h", "");
            mins = durationValue[2].replace("m", "");
            return (
                <>
                    {days}
                    <span className="TimeRangeSpan">{days > 1 ? <FormattedMessage id="KC1886"/> : <FormattedMessage id="KC1885"/>}</span> {hours}    
                    <span className="TimeRangeSpan"><FormattedMessage id="KC1898"/></span> {mins}
                    <span className="TimeRangeSpan"><FormattedMessage id="KC1899"/></span>
                </>
            );
        }

        function getConsumptionPercentage(i, j, key) {
            let Percentage, valueIndex;
            if (key === "currentConsumption") {
                valueIndex = 0;
            } else if (key === "lastConsumption") {
                valueIndex = 1;
            }
            try {
                Percentage = widgetInformation[i][j].content[valueIndex].value;
            } catch (error) {
                Percentage = "0";
            }
            return `${Percentage > 0 ? parseFloat(Percentage).toFixed(1): Percentage}%`
            
        }

        function getTrend(i, j) {
            let trend;
            try {
                trend = widgetInformation[i][j].content[2].value;
            } catch (error) {
                trend = "-1";
            }
            return <span className={trend === '1' ? "valH1Span valHigh" : trend === '0' ? "valH1Span valLow" : "valH1Span"}> &nbsp;</span>
            
        }

        function platenWidget(platenCount) {
            let platen =[];
            for (let i = 0; i <platenCount; i++) {
               platen.push(
                <div className="gridView">
                    <div className="colm4D colm4D1">
                        <div className="colmDi">
                            {/* <h4>TOTAL ON TIME {platenCount !== 1 ? `${'PLATEN '}`+ (i+1) :'' }</h4> */}
                            <h4>{platenCount !== 1 ? <FormattedMessage id="KC1813" values={{ count: (i+1)}}/> : <FormattedMessage id="KC1896"/> }</h4>

                            <div className="colmDiVal">
                            <div className="valH1Div timeDiv">{getConsumptionDate(i,1,'currentConsumption')} {type !== 'Date Range' ? getTrend(i,1): null}</div>
                                {type !== 'Date Range' ? 
                                    <div className="valH5Div">
                                        {mappingStringID[`prior ${type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${type.toLowerCase()}`]}/> : `prior ${type.toLowerCase()}` } : {getConsumptionDate(i,1,'lastConsumption')}
                                    </div> 
                                : null}
                            </div>
                        </div>  
                    </div>
                    <div className="colm4D">
                        <div className="colmDi">
                            {/* <h4>TOTAL IDLE {platenCount !== 1 ? `${'/ PLATEN '}`+ (i+1) :'/ UNIT' } OFF TIME</h4> */}
                            <h4>{platenCount !== 1 ? <FormattedMessage id="KC1814" values={{ count: (i+1)}}/> : <FormattedMessage id="KC1815"/> }</h4>
                            <div className="colmDiVal">
                            <div className="valH1Div timeDiv">{getConsumptionDate(i,2,'currentConsumption')}{type !== 'Date Range' ? getTrend(i,2): null}</div> 
                                {type !== 'Date Range' ? 
                                    <div className="valH5Div">
                                        {mappingStringID[`prior ${type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${type.toLowerCase()}`]}/> : `prior ${type.toLowerCase()}` } : {getConsumptionDate(i,2,'lastConsumption')}
                                    </div> 
                               : null}
                            </div>
                        </div>
                    </div>
                    <div className="colm4D colm4D3">
                        <div className="colmDi">
                            {/* <h4>Utilization {platenCount !== 1 ? `${'PLATEN '}`+ (i+1) :'' }</h4>  */}
                            <h4>{platenCount !==1 ? <FormattedMessage id="KC1816" values={{count: (i+1)}}/> : <FormattedMessage id="KC0270"/> }</h4>
                            <div className="colmDiVal">
                                <div className="valH1Div">{getConsumptionPercentage(i,0,'currentConsumption')} {type !== 'Date Range' ? getTrend(i,0): null}</div>
                                    {type !== 'Date Range' ? 
                                    <div className="valH5Div">
                                        {mappingStringID[`prior ${type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${type.toLowerCase()}`]}/> : `prior ${type.toLowerCase()}` } : {getConsumptionPercentage(i,0,'lastConsumption')}
                                    </div> 
                                : null}
                            </div>
                        </div>
                    </div>
                </div>
               )
            }
            return <div className="gridViewTable gridViewcolm4DSM">{platen}</div>;
        }
        
        return (
            <React.Fragment>
                <LoadingPopup loading={loaderIconVisible} />
                {/* {oilUsageFilterTabHeader} */}
                {singlePlaten ? platenWidget(1) : ""}
                {twoPlaten ? platenWidget(2) : ""}
                {threePlaten ? platenWidget(3) : ""}
                {!singlePlaten && !twoPlaten && !threePlaten ? platenWidget(1) : ""}
            </React.Fragment>
        );
    }
};

export default TotalOnTimeIdleTimeUtilization;
