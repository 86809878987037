import React, { useState } from 'react';
import $ from 'jquery';
import { FormattedMessage, injectIntl } from 'react-intl';
import { smartTagMangement } from '../../../service/api';
import { BRAND_ID } from '../../../utils/appConstants';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import TagOrgModal from './tagOrgModel';
import moment from '../../../utils/momentHelper.js';
import authService from '../../../service/authService';

const SmartTagUnitDetail = (props) => {
    const [showOrgChart, setshowOrgChart] = useState(false);
    const {smartTag, onDownloadStart, onDownloadFinish, resetState} = props;

    const downloadUnitList = (e) => {
        e.preventDefault();
        onDownloadStart(true);
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
		if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag='yes';
        }
        const url = `${smartTagMangement.getPresignedUrl}?registrationFlag=${registrationFlag}&tagName=${smartTag.selectedRow.tagName}&isView=yes`;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'customerid': localStorage.getItem("customerId"),
                'cgid': localStorage.getItem("custGroupID"),
                'brandid': BRAND_ID,
            }, data: {}
        }).then(async response => {
            if (response && response.data && response.data.data) {
                onDownloadFinish(false);
                if (response.data.data.preSignedUrl && response.data.data.preSignedUrl !== '#') {
                    window.location = response.data.data.preSignedUrl;
                }
            }
        }).catch(err => {
            onDownloadFinish(false);
        });
    }
    const handleOrgChart = () =>{
        $("#orgViewModal").modal('show')
        //setshowOrgChart(true);
    }

    return (
        <>
       
        < div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="stUnitDetailsModal" tabIndex="-1" role="dialog" aria-labelledby="stUnitDetailsModalLabel" >
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <FormattedMessage id="KC2312" />
                    </div>
                    <div className="modal-body">

                        <div className="reportDetailsD">
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li>
                                        <div className="tagTypeUnit" ><span>{smartTag.selectedRow.tagName}</span></div>
                                    </li>
                                </ul>
                            </div>
                            <h6><FormattedMessage id="KC0195" /></h6>
                            <p>{smartTag.selectedRow.description ? smartTag.selectedRow.description : 'NA'}</p>
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                        <li>
                                            <p><FormattedMessage id="KC2313" /> <button type="button" onClick={handleOrgChart} data-toggle="modal" className={(smartTag.selectedRow.allcgids && smartTag.selectedRow.allcgids.length === 0) || (smartTag.selectedRow.allunitIds && smartTag.selectedRow.allunitIds.length === 0) ? "orgViewIcon btn disabled" : "orgViewIcon btn"}><img alt='' src={require('../../../images/icons/iconUnitSelection.svg').default} /></button> <FormattedMessage id="KC2307" /></p>
                                        </li>
                                    <li>
                                        <Link to='#' onClick={downloadUnitList} className="downloadListof"><FormattedMessage id="KC2314" /></Link>
                                    </li>
                                </ul>
                            </div>
                            {(smartTag.selectedRow && smartTag.selectedRow.allcgids && smartTag.selectedRow.allcgids.length === 0) || (smartTag.selectedRow.allunitIds && smartTag.selectedRow.allunitIds.length === 0) ? <p class="notUsed"><FormattedMessage id='KC2357' /></p> : null}
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li><FormattedMessage id="KC0589" /></li>
                                    <li><FormattedMessage id="KC1278" /></li>
                                </ul>
                                <ul className="tBody">
                                    <li>
                                        <div className="createdEditedDetails">
                                            <ul>
                                                <li><FormattedMessage id="KC0241" />:</li>
                                                <li>{smartTag.selectedRow.createdByName ? smartTag.selectedRow.createdByName : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0136" />:</li>
                                                <li>{smartTag.selectedRow.createdBy ? smartTag.selectedRow.createdBy : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0298" />:</li>
                                                <li>{smartTag.selectedRow.createdAt ? moment.GetDateTimeByFormat(smartTag.selectedRow.createdAt, 'LLL'): ''}</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="createdEditedDetails">
                                            <ul>
                                                <li><FormattedMessage id="KC0241" />:</li>
                                                <li>{smartTag.selectedRow.updatedByName ? smartTag.selectedRow.updatedByName : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0136" />:</li>
                                                <li>{smartTag.selectedRow.updatedBy ? smartTag.selectedRow.updatedBy : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0298" />:</li>
                                                <li>{smartTag.selectedRow.UpdatedAt ? moment.GetDateTimeByFormat(smartTag.selectedRow.UpdatedAt, 'LLL'): ''}</li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer textAlignCenter">
                        <button className="btn btn-secondary okBtn" data-dismiss="modal" type="button"  onClick={() => resetState()}><FormattedMessage id="KC0777" /></button>
                    </div>
                </div>
            </div>
        </div >
        <TagOrgModal details={smartTag} />
        </>
    )
}

export default injectIntl(SmartTagUnitDetail)