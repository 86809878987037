import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { injectIntl, FormattedMessage } from 'react-intl';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import ErrorMoal from '../../../../modal/error-model';
import _ from 'lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'


// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class GarlandCookbookAddRecipe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            categoryList: [],
            deleteCategoryVisible: false,
            editEnabled: false,
            isChanged: false,
            isEdit: false,
            isImport: false,
            showDiscardPopup: false,
            isDeleteCategory: false,
            selectedCategory: {},
            selectedCategoryName: "",
            cookbook_name: '',
            errorCookbookName: ''
        }
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        this.updateDimensions();
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let selectedCategory = localStorage.getItem("selectedCategory");
            let selectedCategoryObj = JSON.parse(selectedCategory);

            let categoryDetails = localStorage.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
            let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === selectedCategoryObj.ID);

            // let defaultLibraryDetails = JSON.parse(localStorage.getItem("defaultLibraryData"));
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            this.setState({
                categoryList: selectedCategoryDetailsObj.subCategory,
                selectedCategoryName: selectedCategoryObj.categoryName,
                isChanged: (selectedCategoryDetailsObj.subCategory && selectedCategoryDetailsObj.subCategory.length > 0) || (selectedCategoryDetailsObj.recipe_items && selectedCategoryDetailsObj.recipe_items.length > 0) ? true : false,
                recipeList: selectedCategoryDetailsObj ? selectedCategoryDetailsObj.recipe_items : [],
                isEdit: libraryDetails.isEdit,
                isImport: libraryDetails.Import,
                cookbook_name: libraryDetails.cookbookName,
                selectedModel: parseInt(localStorage.getItem("selectedProductID"))
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
        var windowHeight = $(window).height();
        var menuFilterHeader = $(".menuFilterHeader").height();
        var pageRRHeight = ((windowHeight) - (menuFilterHeader)) - 105;
        $(".readyRecipeSrlTable").css("height", pageRRHeight);

    }


    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.categoryList,
            result.source.index,
            result.destination.index
        );

        this.setState({
            categoryList: items,
        });

        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);

        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);

        categoryDetailsObj[foundIndex]['subCategory'] = items;

        localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
    }

    onDragReceipeEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.recipeList,
            result.source.index,
            result.destination.index
        );

        this.setState({
            recipeList: items,
        });

        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);

        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);

        categoryDetailsObj[foundIndex]['recipe_items'] = items;

        localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
    }

    redirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false,
            isDeleteCategory: false,
            errorCookbookName: ""
        })
    }

    onDeleteConfirm = () => {
        this.setState({
            loaderIconVisible: true
        })
        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
        let changedSubCategoryDetailsObj = categoryDetailsObj[foundIndex].subCategory.filter(c => c.ID !== this.state.selectedCategory.ID);
        categoryDetailsObj[0].subCategory.filter(c => c.ID !== this.state.selectedCategory.ID);
        categoryDetailsObj[foundIndex].subCategory = changedSubCategoryDetailsObj;
        localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
        this.setState({
            loaderIconVisible: false,
            categoryList: changedSubCategoryDetailsObj,
            isChanged: changedSubCategoryDetailsObj.length > 0 ? true : false,
            isDeleteCategory: false
        });
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        let path = "/recipeEditor";
        if (libraryDetails.isEdit) {
            path = "/libraryList";
        }
        this.props.history.push({
            pathname: path,
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    onclickEditCookbook = () => {
        this.setState({
            deleteCategoryVisible: !this.state.deleteCategoryVisible,
            editEnabled: !this.state.editEnabled,
        });
    }

    addSubCategory = () => {
        localStorage.setItem("isEditSubCategory", "");
        this.props.history.push({
            pathname: "/cookbookAddSubCategory",
            isEditSubCategory: false,
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    editSubCategory = (e, item) => {
        localStorage.setItem("isEditSubCategory", true);
        localStorage.setItem("EditSubCategoryData", JSON.stringify(item));
        this.props.history.push({
            pathname: "/cookbookAddSubCategory",
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    addRecipes = () => {
        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);
        if (!selectedCategoryObj.subCategory) {
            selectedCategoryObj.subCategory = {};
        }
        else {
            delete selectedCategoryObj.subCategory.imageData;
            delete selectedCategoryObj.subCategory.thumbnailData;
        }
        selectedCategoryObj.subCategory.selected = false;
        localStorage.setItem("selectedCategory", JSON.stringify(selectedCategoryObj));
        this.props.history.push({
            pathname: "/garlandCookbookAddRecipeItem",
            isEdit: false,
            previouspath: "/garlandCookbookAddRecipe"
        });
    }

    onCategorySelect = (e, item) => {
        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);
        item.selected = true;
        delete item.imageData;
        delete item.thumbnailData;
        selectedCategoryObj.subCategory = item;
        localStorage.setItem("selectedCategory", JSON.stringify(selectedCategoryObj));
        this.props.history.push({
            pathname: "/cookbookRecipeList",
            isEdit: false,
            previouspath: "/cookbookEditor"
        });
    }

    onSaveButtonClick = () => {
        if (this.state.isChanged) {
            let cookbookName = this.state.cookbook_name.trim();
            if (cookbookName.length < 1 || cookbookName.length > 20) {
                this.setState({
                    errorCookbookName: "KC1240"
                })
            }
            else {
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                libraryDetails.cookbookName = cookbookName;
                localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                this.props.history.push({
                    pathname: "/assignCookbookToTopology",
                    isEdit: false,
                    previouspath: "/cookbookEditor"
                });
            }
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1241"
                }
            });
        }
    }

    onDeleteCategory = (e, item) => {
        this.setState({
            selectedCategory: item,
            loaderIconVisible: false,
            isDeleteCategory: true
        });
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/cookbookEditor",
            isEdit: false,
            previouspath: "/garlandCookbookAddRecipe"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onCookbookNameChange = (e) => {
        if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            this.setState({
                cookbook_name: e.target.value,
                errorCookbookName: ""
            });
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, loaderIconVisible } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = localStorage.getItem("selectedbrandID");
        let { categoryList, recipeList, deleteCategoryVisible, isChanged, showDiscardPopup, editEnabled, error_modal, isDeleteCategory, selectedCategoryName, cookbook_name, errorCookbookName, isEdit, isImport, selectedModel } = this.state;
        categoryList = _.orderBy(categoryList);
        recipeList = recipeList && recipeList.length > 0 ? recipeList : [];

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658" /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li className="selectedModel">ME, MG</li>
                            </ul>
                        </div>

                        <div className="rcDeviceWrapper">
                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} /> <button type="button" onClick={() => this.props.history.replace('/garlandCookbookToTopology')} className="btn saveExitBtn">Save File & Exit</button></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={() => this.props.history.replace('/garlandCookbookEditor')} className="btn backButton">&nbsp;</button>
                                    {selectedCategoryName}
                                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn alignTop">
                                                <DragDropContext onDragEnd={this.onDragReceipeEnd}>
                                                    <Droppable droppableId="droppable" direction="horizontal">
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.droppableProps} class="cbcategory menuEditActive">
                                                                <ul>
                                                                    <li className='actionIcon'>
                                                                        <Link to="#" title={formatMessage({ id: 'KC1253' })} onClick={this.addRecipes}>
                                                                            <span className="navIcon"><img src={require("../../../../../images/recipecreation/addButton.png")} /></span>
                                                                            <span className="navTitle"><FormattedMessage id="KC1253" /></span>
                                                                        </Link>
                                                                    </li>
                                                                    {recipeList.map((item, index) => (
                                                                        <Draggable key={item.ID} draggableId={item.LIBRARY_NAME} index={index}>
                                                                            {(provided) => (
                                                                                <li key={item.ID} ref={provided.innerRef} {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}>
                                                                                    <div class="cbcategoryBox">
                                                                                        <span class="cbRCicon"></span>
                                                                                        <span class="navIcon"><img src={item.ID === 0 ? require("../../../../../images/recipecreation/Auto.png") :  item.IMAGE_S3_SIGNED_URL ||  require("../../../../../images/recipecreation/Default Recipe.bmp")} alt="" /></span>
                                                                                        <span class="navTitle">{item.LIBRARY_NAME}</span>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default injectIntl(GarlandCookbookAddRecipe)