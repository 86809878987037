import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import ErrorMoal from '../../../../../modal/error-model';
import { FormattedMessage, injectIntl } from 'react-intl';

class FrymasterEcoMode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            ecoMode: 'true',
            temperature: 10,
            automatic: false,
            manualDisplayButton: false,
            generalTemperature: 0,
            error_modal: {
                open: false,
                message: ""
            },
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = localStorage.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            if (ObjSettingsFile.general.temperature.temperature_unit) {
                this.setState({ generalTemperature: ObjSettingsFile.general.temperature.temperature_unit })
            }
            if (ObjSettingsFile.eco_mode) {
                this.setState({
                    ecoMode: ObjSettingsFile.eco_mode.eco_mode === 1 ? "true" : "false",
                    temperature: ObjSettingsFile.eco_mode.temperature, 
                    automatic: ObjSettingsFile.eco_mode.automatic === 0 ? false : true,
                    manualDisplayButton: ObjSettingsFile.eco_mode.manual_display_button === 0 ? false : true
                })
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        let { ecoMode, temperature, automatic, manualDisplayButton, generalTemperature } = this.state;
        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.eco_mode) {
            ObjSettingsFile.eco_mode = {}
        }
        if (generalTemperature === 0) {
            if ((Number(temperature) < 0 || Number(temperature) > 45)) {
                this.setState({
                    error_modal: {
                        open: true,
                        message: "KC1485"
                    }
                });
                return;
            }
        }
        if (generalTemperature === 1) {
            if ((Number(temperature) < -17.7 || Number(temperature) > 7.2)) {
                this.setState({
                    error_modal: {
                        open: true,
                        message: "KC1977"
                    }
                });
                return;
            }
        }
        ObjSettingsFile.eco_mode.eco_mode = ecoMode === "true" ? 1 : 0;
        ObjSettingsFile.eco_mode.temperature = temperature;
        ObjSettingsFile.eco_mode.automatic = automatic === false ? 0 : 1;
        ObjSettingsFile.eco_mode.manual_display_button = manualDisplayButton === false ? 0 : 1;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/fryMasterSettings`);
    }

    onSaveWhileAction = (key, value) => {
        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.eco_mode) {
            ObjSettingsFile.eco_mode = {};
        }
        ObjSettingsFile.eco_mode[key] = value;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryData", strSettingsFile);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/fryMasterSettings`);
    }

    onToggleChange = (e, stateKey, localStateKey, value) => {
        this.setState({
            [stateKey]: e.toString()
        }, () => {
            this.onSaveWhileAction(localStateKey, value)
        });
    }

    onCheckValue = (e, key, localStateKey, value) => {
        this.setState({
            [key]: e.target.checked
        }, () => {
            this.onSaveWhileAction(localStateKey, value)
        });
    }

    onSetValueChange = (e, key) => {
        const re = /^[0-9\b]+$/;
        const value = e.target.value;
        // if value is not blank, then test the regex
        if (value === '' || re.test(value)) {
            this.setState({ [key]: value }, () => {
                this.onSaveWhileAction('temperature', value)
            })
        }
    }


    closeModel() {
        this.setState({
            error_modal: {
                open: false,
                message: ""
            }
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");

        const { ecoMode, temperature, automatic, manualDisplayButton, error_modal } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Eco Mode Header Page */}
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Eco Mode Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Eco Mode Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC0455"/>
                                </div>


                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li><FormattedMessage id="KC0455"/></li>
                                                        <li>
                                                            <div class="btn-group-toggle" data-toggle="buttons">
                                                                <span class={ecoMode === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1360"/></span> 
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'ecoMode', 'eco_mode', e ? 1 : 0)}
                                                                    checked={ecoMode === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={ecoMode === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1405"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1486"/></li>
                                                        <li class="smallFormFields">
                                                            <InputMask
                                                                placeholder={this.state.generalTemperature === "°F" ? formatMessage({ id: 'KC1980' }) : formatMessage({ id: 'KC1981' })}
                                                                onChange={(e) => this.onSetValueChange(e, "temperature")}
                                                                value={temperature}
                                                                class="form-control" />
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm ecoModeDiv">
                                                    <ul>
                                                        <li class="width245px"><div class="customCheckbox">
                                                            <input id="ManualDisplayButton" name="EcoMode" type="checkbox" value="ManualDisplayButton" onChange={(e) => this.onCheckValue(e, 'manualDisplayButton', 'manual_display_button', e.target.checked === false ? 0 : 1)} checked={manualDisplayButton} />
                                                            <label for="ManualDisplayButton"><FormattedMessage id="KC1487"/></label>
                                                        </div></li>
                                                        <li class="smallFormFields">&nbsp;</li>
                                                    </ul>
                                                    <ul class="borderNone">
                                                        <li class="width245px"><div class="customCheckbox">
                                                            <input id="Automatic" name="EcoMode" type="checkbox" value="Automatic" onChange={(e) => this.onCheckValue(e, 'automatic', 'automatic', e.target.checked === false ? 0 : 1)} checked={automatic} />
                                                            <label for="Automatic"><FormattedMessage id="KC0155"/></label>
                                                        </div></li>
                                                        <li class="arrowBtn"><button type="button" onClick={() => this.props.history.push('/frymasterAutomaticEcoMode')} class={automatic ? "btn nextButton" : "btn nextButton disabled"}>&nbsp;</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Eco Mode Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterEcoMode)
