import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { DropDownListLink } from '../../controls/dropDownListLink';
import { format } from 'date-fns';
import { getCleaningTableInfo } from '../../../actions/reportManagement/cleaningTableAction';
import { getCurrentUnit } from '../../../actions/organizationentity/entityactions';
import authHelper from '../../../utils/authHelper';
import { TableHeader } from '../../controls/tableHeader';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';


var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = "";
let currentUnit;

class CleaningTable extends Component {
	constructor(props, context) {
		super(props, context);
		this.state = {
			type: 'Month',
			filterType: "Month",
			dateRange: {
				selection: {
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection',
				},
			},
			noResultMsg: 'No Results',
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			sortColumnDrinks: { path: "", order: "", sort_color: "#FFFFFF" },
			startDate: '',
			endDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			//loaderIconVisible: true,
			hideDateRange: true,
			ResetCycle: [],
			datapickflag: false
		};

		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount = async () => {
		todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;
		if (brandID === 'CONVOTHERM') {
			this.props.getCleaningTableInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
		else {
			this.props.getCleaningTableInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, '67');
		}
		setTimeout(() => {
			this.setState({
				//loaderIconVisible: false,
			});
		}, 2000)
	}

	componentDidUpdate = async (prevProp, prevState) => {
		if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.globalDropdownFilter !== 'Date Range' && this.props.isGlobalDropdownChange) {
			this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
			await this.handleCleaningTable(this.props.globalDropdownFilter, 'clickHandler');
			await this.props.handleDropdownChangeEnd();
		}
	}

	handleChangeStart(date) {
		this.setState({
			startDate: date,
			datapickflag:true
		}, () => {
			let selectedtext = 'Date Range';
			this.handleCleaningTable(selectedtext, 'clickHandler');
		});
	}

	handleChangeEnd(date) {
		this.setState({ endDate: date,datapickflag:true }
			, () => {
				let selectedtext = 'Date Range';
				this.handleCleaningTable(selectedtext, 'clickHandler');
			});
	}

	onDropDownHandler(e, type = '') {
		const { nodeText:text } = e;
		this.setState({type:text, datapickflag: text === 'Date Range', startDate:'', endDate:''})
		this.handleCleaningTable(text, type);
	}

	handleCleaningTable(text, type) {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;
		if (text === "Date Range") {
			todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
			weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
		}
		else if (text === 'Week') {
			weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
			todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			var startDateMonth = getMonth(new Date(weekDateFormatted));
			var endDateMonth = getMonth(new Date(todaysDateFormatted));

			if (startDateMonth !== endDateMonth) {
				weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
			}
		}
		else {
			todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
			weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
		}

		this.setState({ type: text });
		if (type === "clickHandler") {
			this.props.getCleaningTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn
		});
	}

	onSortDrinks = sortColumnDrinks => {
		this.setState({
			sortColumnDrinks
		});
	}

	errorCloseModel = () => {
		authHelper.deleteLocalSession();
		this.props.history.push('/');
	}

	render() {

		let heatMapTableWithColumn = [];
		const { cleaningTableInfo } = this.props;
		const { type } = this.state;
		let cleaningTableData = [];

		const { filterType } = this.props;

		if (localStorage.getItem("brandName") === "CONVOTHERM") {
			cleaningTableData = cleaningTableInfo.content === "{}" ? [] : cleaningTableInfo.content;
		}
		else {
			cleaningTableData = cleaningTableInfo;
		}

		if ((localStorage.getItem("brandName") === "CONVOTHERM") && cleaningTableData && cleaningTableData.length > 0) {
			for (var j = 0; j < cleaningTableData.length; j++) {
				if (cleaningTableData[j].count > 0) {
					let heatMapTableWithColumnArr = {};
					/** Convotherm mini & maxx cleaning empty condition check code included 15-feb-2021 */
					// let checkEmptyCleaning = cleaningTableData[j].cleaningtype === "" ? "Undefined Cleaning Level - " + cleaningTableData[j].cleaningname : cleaningTableData[j].cleaningtype + ' ' + cleaningTableData[j].cleaningmode;
					/* Convotherm actual_cleaning_name -> mini, maxx, maxx pro and c4 - Refer US:60946 Convotherm PH I: Cleaning Data */
					let checkEmptyCleaning = cleaningTableData[j].actual_cleaning_name ? cleaningTableData[j].actual_cleaning_name : "--" ;
					heatMapTableWithColumnArr.cleaningtype = checkEmptyCleaning;
					heatMapTableWithColumnArr.datetime = cleaningTableData[j].datetime;
					heatMapTableWithColumnArr.count = cleaningTableData[j].count;
					heatMapTableWithColumn.push(heatMapTableWithColumnArr);
				}
			}
		}
		else {
			heatMapTableWithColumn = [];
		}

		const { sortColumn, sortColumnDrinks } = this.state;
		let heatmaptableclass = "cleaningDataTable";
		if (filterType === "daily") {
			heatmaptableclass = "cleaningDataTable cleaningDataTableDay";
		}
		else if (filterType === "weekly") {
			heatmaptableclass = "cleaningDataTable cleaningDataTableWeek";
		}
		else if (filterType === "monthly") {
			heatmaptableclass = "cleaningDataTable cleaningDataTableMonth";
		}
		else if (filterType === "quarterly") {
			heatmaptableclass = "cleaningDataTable cleaningDataTableQuarter";
		}
		else if (filterType === "yearly") {
			heatmaptableclass = "cleaningDataTable cleaningDataTableYear";
		}

		let columnsDrinks = [
			{ path: "", label: "#" }
		];

		if ((localStorage.getItem("brandName") === "CONVOTHERM")) {
			if (heatMapTableWithColumn.length > 0) {

				Object.keys(heatMapTableWithColumn[0]).forEach(function (key) {
					if (key === "cleaningtype") {
						columnsDrinks.push({ path: key, labelStringID: "KC1802" }); 
					}
					else if (key === "datetime") {
						columnsDrinks.push({ path: key, labelStringID: "KC1267" });  
					}
					else if (key === "count") {
						columnsDrinks.push({ path: key, labelStringID: "KC0172" });
					}
					else {
						columnsDrinks.push({ path: key, label: key });
					}
				});
			}
			else {
				columnsDrinks.push(
					{ path: "cleaningtype", labelStringID: "KC1802" }, 
					{ path: "datetime", labelStringID: "KC1267" },
					{ path: "count", labelStringID: "KC0172" }
				);
			}
		}
		heatMapTableWithColumn = _.orderBy(heatMapTableWithColumn, [sortColumnDrinks.path], [sortColumnDrinks.order]);

		let displayWidgetResponse;


		displayWidgetResponse = (
			Object.values(heatMapTableWithColumn).map((item, i) => (
				<ul>
					<li>{i + 1}</li>
					{
						Object.keys(heatMapTableWithColumn[i]).map((key) => {
							if (key === "cleaningtype") {
								return <li>{heatMapTableWithColumn[i]["cleaningtype"]}</li>
							}
							else if (key === "datetime") {
								return <li>{heatMapTableWithColumn[i]["datetime"]} </li>
							}
							else if (key === "count") {
								return <li>{heatMapTableWithColumn[i]["count"]} </li>
							}
							else {
								return <li>{heatMapTableWithColumn[i][key]} </li>
							}
						})
					}
				</ul>
			))
		)

		return (
			<div className="gridViewTable">
				<div class="gridView">
					<div class="colm12D">
						<div class="colmDi">
							<h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName} &nbsp; <span class="red-txt"> </span>
								<span class="cornerIcon"></span>
								<DropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e, "clickHandler")} filterType={this.state.type} disableDatePicker={this.state.hideDateRange} />
							</h4>
							<div class="cleaningDataTableOuter minheightSetTable">
								<div class={heatmaptableclass}>
									<TableHeader
										sortColumn={sortColumnDrinks}
										onSort={this.onSortDrinks}
										columns={columnsDrinks} />
									{displayWidgetResponse}

								</div>
								<div>
									{
										heatMapTableWithColumn && heatMapTableWithColumn.length > 0 && heatMapTableWithColumn != null ? " " :
											<div className="noResultMsg"><FormattedMessage id="KC0264"/></div>
									}
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>
		)
	}
};
function mapStateToProps(state) {
	return {
		cleaningTableInfo: state.cleaningTableInformation.cleaningTableInformation,
		currentUnit: state.entityReducer.currentUnit,
		filterType: state.filterType.filterType
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getCleaningTableInfo,
		getCurrentUnit,
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CleaningTable);