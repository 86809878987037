import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Link, withRouter } from 'react-router-dom';
import AllLocationSidebar from '../layoutSection/allLocationSidebar';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import MainLayout from "../../layout/mainLayout";
import LogoutForm from "../logout/logoutForm";
import  auth from '../../service/authService';
import  authHelper from '../../utils/authHelper';
import  messageConfig from '../../utils/messageConfig';
import  pointerDevice from '../../utils/pointerDeviceFinder';
import ErrorMoal from '../modal/error-model';   
import  broadCastTokenEvent from '../../events/tokenAuthrizer';
import FilterBase from '../FilterBase/FilterBase';
import $ from 'jquery';
import GlobalSearchBar from '../../components/globalSearchBar/globalSearchBar';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import LanguagePreferenceCommon from '../../components/languageDropdown/LanguagePreferenceCommon';
import { FormattedMessage, injectIntl } from 'react-intl';


am4core.useTheme(am4themes_animated); 

class AllLocationHeaderLayout extends Component {
	constructor(props) {
    super(props);
    this.editInformation = this.editInformation.bind(this);
    this.nextPath = this.nextPath.bind(this);
    this.state = {
            isHidden: false,
            isHiddenforFilters:false,
        }     
  }

  nextPath(path) {		
    this.props.history.push("/");
    }

  showLoginForm = (event, isHidden) => {
    WoopraEvents(`${Constants.ACCESS_USER_PROFILE}`);	
    this.setState({
        isHidden: !isHidden
    });
    if(this.state.isHidden){
        $('#backdropElement').click()
    }
    event.preventDefault();  
  }

  showLoginbg = (event, isHidden, isHiddenforFilters) => {
    if(this.state.isHidden === true){
        this.setState({
            isHidden: !isHidden
        });
        $('#backdropElement').click()
    }
  if(this.state.isHiddenforFilters === true){
      this.setState({
          isHiddenforFilters: !isHiddenforFilters
      });
      $('#backdropElement').click()
  }
  event.preventDefault();  
}

  hideLoginForm = (event) =>  { 
    event.preventDefault();  
    this.setState({
        isHidden: false
     });   
     if(pointerDevice.IsTouchDevice() && event.target) {
        let targetClass = event.target.getAttribute('class');
        if(targetClass && targetClass.indexOf('navFilter')!==-1){
            this.showFilter(event, false);
        }
     }      
   }

   toggleHiddenforFilters (event) {
    this.setState({
            isHiddenforFilters: false
    });
    event.preventDefault();
    if(pointerDevice.IsTouchDevice() && event.target) {  
        let targetClass = event.target.getAttribute('class');
        if(targetClass && targetClass.indexOf('navUser')!==-1){
            this.showLoginForm(event, false);
        }
     }
   }

    showFilter = (event, isShowFilter) =>{
        event.preventDefault();
        this.setState({
            isHiddenforFilters: !isShowFilter
          });
    }

    editInformation() {
        this.props.history.push('/myinformationedit');
    }

	componentWillMount() {
		this.props.getCurrentUnit("currentUnit");
		this.props.getCurrentLocation("currentLocation");
    } 

    componentDidMount() {
        let selectedCountryList = this.props.selectedCountries ? this.props.selectedCountries :[];
		let selectedRegionList = this.props.selectedRegions ? this.props.selectedRegions :[];
		let selectedLocationList = this.props.selectedLocations ? this.props.selectedLocations :[];
		let selectedCustomerList = this.props.selectedCustomers ? this.props.selectedCustomers :[];
		let selectedBrandList = this.props.selectedBrands ? this.props.selectedBrands :[];
        let selectedModelList = this.props.selectedModels ? this.props.selectedModels :[];
        
        let selectedCgids =[];
        let selectedCids =[];
        let selectedBrands =[];
        let selectedModels =[];

		selectedCountryList.map(c =>{ if(c.checked ===  true){
			return selectedCgids.push(c.cust_gp_id)
        }});
        
        selectedRegionList.map(c =>{ if(c.checked ===  true){
			return selectedCgids.push(c.cust_gp_id)
        }});
        
        selectedLocationList.map(c =>{ if(c.checked ===  true){
			return selectedCgids.push(c.cust_gp_id)
        }});
        
        selectedCustomerList.map(c =>{ if(c.checked ===  true){
			return selectedCids.push(c.ID)
        }});
        
        selectedBrandList.map(c =>{ if(c.checked ===  true){
			return selectedBrands.push(c.ID)
        }});
        
        selectedModelList.map(c =>{ if(c.checked ===  true){
			return selectedModels.push(c.ID)
        }});
        
        var d = document.getElementById("spannavNotifiStatus");
         if (selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0){
                 d.className = "navNotifiStatus";
             }
         else {
             d.className = "";
         }

         this.subscription = broadCastTokenEvent.IsReceiveJwtTokenExpired().subscribe(isJwtExpired => {
            if (isJwtExpired)
               this.setState({ IsTokenExpired:  isJwtExpired });
        });
    }

    errorCloseModel = () => {     
        authHelper.deleteLocalSession();
        window.location.href ='/';
      }	


    render() { 
        const { currentLocation, currentUnit, activeClass } = this.props;
        const { formatMessage } = this.props.intl;

        let location = currentLocation;
        let path = this.props.location.pathname;
        let loginUser =   JSON.parse(localStorage.getItem("loginInfo")) || '';
        let loggedinUser = loginUser.username;
        
        let locationBrand_Logo = location && location.Brand_Logo !=null?location.Brand_Logo:"";
        let locationBrand_Alt = location && location.Brand_Alt !=null?location.Brand_Alt:"";
        let locationBrand_Title = location && location.Brand_Title !=null?location.Brand_Title:"";
        let locationlocation_name = location && location.location_name !=null?location.location_name:"";
        let locationcity = location && location.city !=null?location.city:"";

        let displayAllLocationClass = "navbar-nav mr-auto titlebarLeft";
        let displayspecificlocationClass = "navbar-nav mr-auto navbarLeft displaynone";
        let values = this.props.location.pathname.split('/')[2];
        if (values === "specificLocation" || values === "unitsView")
        {
            displayspecificlocationClass = "navbar-nav mr-auto navbarLeft";
            displayAllLocationClass = "navbar-nav mr-auto titlebarLeft displaynone";
        }


        let logoutForm = null
        if(this.state.isHidden) {
            logoutForm = (<LogoutForm hideFlag={this.state.isHidden} hideProfileEditBtn={false} editinfo={this.editInformation} nextPath={this.nextPath} hideLoginForm = {(e) => this.hideLoginForm(e)} />)
        }

        let filterbase=null
        if (this.state.isHiddenforFilters)
        {
            filterbase=(<FilterBase filterHidden= {this.state.isHiddenforFilters} history={this.props.history} filterKey={this.props.filterKey} callFilterHideToggle={(e) => this.toggleHiddenforFilters(e)} />)
        }

        let IsTokenExpired = !this.state.IsTokenExpired ? auth.IsTokenTimeExipired() : this.state.IsTokenExpired;
        
        return (
            <React.Fragment>
                <ErrorMoal open={ IsTokenExpired ? true : false } message={ messageConfig.SessionExpire } stringID={messageConfig.SessionExpire} onCloseModel={() => this.errorCloseModel()}/>	
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bg-Welbilt"  onClick={(e) => {this.showLoginbg(e, this.state.isHidden)}}> 
                        <div className="navbar-header">
                            <Link className="navbar-brand welbiltRLogo" title={formatMessage({id: 'KC0038'})} to="/dashBoardLocations">&nbsp;</Link>
                            <Link className="navbar-brand sideNavBar" to="#">&nbsp;</Link>
                        </div>
                        <ul className={displayAllLocationClass}>
                            <li className="nav-item">
                                <Link className="nav-link" to="#" title={formatMessage({id: 'KC0046'})}><FormattedMessage id='KC0046'/></Link>
                            </li>
                        </ul>
                        <ul className={displayspecificlocationClass}>
                            <li className="nav-item">
                                <Link className="nav-link" to="#" >
                                    <span className="navStoreIcon"><img src={locationBrand_Logo} alt={locationBrand_Alt } title={locationBrand_Title} /></span>
                                    <span className="navStoreText"><span title={formatMessage({id:"KC1040"})}>{locationlocation_name}</span><b><span title={formatMessage({id: 'KC0098'})}>{locationcity}</span></b></span>
                                </Link>
                            </li>
						</ul>
                        <ul className="navbar-nav mr-auto navbarRight">

                            {/* Global Search */}
                            { path &&  < GlobalSearchBar path ={ path} /> }
                            
                                {/* Multilingual Support  */}
                                <LanguagePreferenceCommon />
                                {/* Multilingual Support  */}
                                
                            <li className={this.state.isHiddenforFilters ? "nav-item activeFilter": "nav-item"}>                                
                                <Link to="#" className={this.state.isHiddenforFilters ? "nav-link navFilter active":"nav-link navFilter"} title={formatMessage({id: "KC0996"})} onClick={(e) => {this.showFilter(e, this.state.isHiddenforFilters)}}><span id="spannavNotifiStatus" className=""></span>&nbsp;</Link>
                            </li>
                            <li className="nav-item">                                
                                <Link className= "nav-link navSearch" style={{ display: 'none' }} title={formatMessage({id: 'KC0037'})} to="#">&nbsp;</Link>
                            </li>
                            <li className="nav-item">                                
                                <Link className="nav-link navAlarm" style={{ display: 'none' }} title={formatMessage({id: "KC0997"})} to="#">&nbsp;</Link>
                            </li>
                            <li className={this.state.isHidden ? "nav-item activeNavUser": "nav-item"}><Link className={this.state.isHidden ? "nav-link navUser active":"nav-link navUser"} title={loggedinUser} to="#" onClick={(e) => {this.showLoginForm(e, this.state.isHidden)}}><span id="spannavNotifiStatus"></span>&nbsp;</Link></li>
                        </ul> 
                </nav>
                <MainLayout flagstate={this.state.isHidden} />
                <AllLocationSidebar activeClass={activeClass} />                 
                <div ref={node => { this.pop = node }}>                    
                    {logoutForm}
                </div>
                <div ref={node1 => { this.pop1 = node1 }} className={this.state.isHiddenforFilters ? "navFilterWrapper showLogoutModal" : "navFilterWrapper"}>                                                                           
                   {filterbase}
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
        selectedCountries: state.filterInformation.selectedCountries,
        selectedRegions: state.filterInformation.selectedRegions,
        selectedLocations: state.filterInformation.selectedLocations,
        selectedCustomers: state.filterInformation.selectedCustomers,
        selectedBrands: state.filterInformation.selectedBrands,
        selectedModels: state.filterInformation.selectedModels
    }
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getCurrentLocation, getCurrentUnit }, dispatch);
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(injectIntl(AllLocationHeaderLayout, MainLayout)));