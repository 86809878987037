import React from 'react';
import $ from 'jquery';
import { useSelector, useDispatch } from 'react-redux';
import Loader from '../../components/Loader';
import UserManagementHeader from '../../components/layoutSection/userManagementHeader';
import UsertListComponent from '../../Features/userMgmt/list';
import { searchData_function } from '../../components/globalSearchBar/selectOption';
import { userListImage } from '../../utils/imgContants';
import { UserMgmtAddUser } from '../../service/api';
import appConstants from '../../utils/appConstants';
import http from '../../service/httpService';
import { searchText, searchVisible } from '../../actions/advanceSearch/advanceSearchAction';
import { getUserDataModel, setUserDataModel } from '../../actions/stateActionsData/userAction';
import { setSnackBarData } from '../../actions/snackbar';
import { snackbarClosingSecond } from '../../components/navigation/organizationManagement/organizationManagement';

const pageSize = 25;

const UserManagementList = (props) => {
    const [loader, setLoading] = React.useState(false);
    const [user, setUser] = React.useState([]);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [totalPages, setTotalPages] = React.useState(1);
    const [sortColumn, setSortColumn] = React.useState({ path: "name", order: "asc", sort_color: "#63778a" });
    const [allSelect, setAllSelect] = React.useState(false);
    const [isIndeterminateSelect, setIsIndeterminate] = React.useState(true);

    const dispatch = useDispatch();

    const searchData = useSelector(state => state.advanceSearch.searchData);
    const filterModelValue = useSelector(state => state.filterModel.filterModel);
    const filterState = React.useRef({});

    let getUserModel = {
        user: {
            name: "",
            firstName: "",
            lastName: "",
            email: "",
            retypeEmail: "",
            country_code: "",
            phoneNumber: "",
            customerId: localStorage.getItem("customerId")
        },
        roleId: 0,
        userGroup: [],
        userPreference: [],
        countryCodeList: []
    };

    React.useEffect(() => {
        document.body.style.backgroundColor = "#F2F2F2";
        window.addEventListener('resize', updateDimensions);
        dispatch(searchVisible(true));
        dispatch(setUserDataModel({}));
        init();
        return () => {
            dispatch(searchText({}));
            dispatch(searchVisible(false));
        }
    }, []);

    React.useEffect(() => {
        updateDimensions()
    }, [totalPages])

    React.useEffect(() => {
        if(searchData && Object.keys(searchData).length) getUserList(1);
    }, [searchData]);

    const snackBarToast = (message, state) => {
        return new Promise((reslve, _) => {
            reslve(dispatch(setSnackBarData({ message, isActive: true, state, seconds: snackbarClosingSecond })))
        }) 
    }

    const init = async () => {
        await getFilterDetails();
        await getUserList(1);
    }

    const updateDimensions = () => {
        const windowHeight = $(window).height();
        const $tableBody = $('.userMgmtTableSrlBar');
        const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
    }

    const getFilterDetails = async () => {
        const ffModel = localStorage.getItem(appConstants.userManagementFilter);
        const filterModel = ffModel && JSON.parse(ffModel);
        if (filterModel) {
            filterModel = filterModelValue;
        }

        if (!filterModel) {
            return;
        }

        let selectedCountryList = filterModel.find(el => el.field_name === 'country');
        let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
        let selectedLocationList = filterModel.find(el => el.field_name === 'location');
        let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
        let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
        let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
        let selectedModelList = filterModel.find(el => el.field_name === 'model');
        let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');

        let selectedCgids = [];
        let selectedCids = [];
        let selectedBrands = [];
        let selectedModels = [];
        let selectedMachineTypes = [];

        selectedCountryList.dataSource.map(c => {
            return selectedCgids.push(c.id)
        });

        if (selectedRegionList.dataSource.length > 0) {
            selectedCgids = [];
        }

        selectedRegionList.dataSource.map(c => {
            return selectedCgids.push(c.cust_gp_id)
        });

        if (selectedLocationList.dataSource.length > 0) {
            selectedCgids = [];
        }

        selectedLocationList.dataSource.map(c => {
            return selectedCgids.push(c.cust_gp_id)
        });

        if (selectedWithinLocationList.dataSource.length > 0) {
            selectedCgids = [];
        }

        selectedWithinLocationList.dataSource.map(c => {
            return selectedCgids.push(c.cust_gp_id)
        });

        selectedCustomerList.dataSource.map(c => {
            return selectedCids.push(c.ID)
        });

        selectedBrandList.dataSource.map(c => {
            return selectedBrands.push(c.ID)
        });

        selectedModelList.dataSource.map(c => {
            return selectedModels.push(c.ID)
        });

        selectedMachineTypeList.dataSource.map(c => {
            return selectedMachineTypes.push(c.ID)
        });

        let cgids = "";
        if (selectedCgids.length > 0) {
            cgids = selectedCgids.join(',');
        }
        else {
            //cgids = localStorage.getItem("custGroupID");
        }

        filterState.current = ({
            sCgids: cgids,
            sCids: selectedCids,
            sBrandids: selectedBrands,
            sModels: selectedModels,
            sMachineTypes: selectedMachineTypes,
        });

        var d = document.getElementById("spannavNotifiStatus");
        if (selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
            d.className = "navNotifiStatus";
        }
        else {
            d.className = "";
        }
    }


    const getUserList = async (currentPage, sortColumnParams = sortColumn) => {
        let req = filterState.current;
        setLoading(true);
        let userDetails = localStorage.getItem("loginInfo");
        let userName = JSON.parse(userDetails).username;
        let url = UserMgmtAddUser.getUserList + localStorage.getItem("customerId") + "&&email=" + userName;
        let _search = searchData_function(searchData);

        if (_search) {
            url = `${url}${_search}`;
        }
        if (sortColumnParams && sortColumnParams.path) {
            url = `${url}&sortOrder=${sortColumnParams.order}&sortBy=${sortColumnParams.path}`
        }
        let headers = {
            'Content-Type': 'application/json',
            'cgid': '',
            'brandid': '',
            'machinetype': '',
            'role': localStorage.getItem('roleId') || ''
        }
        if (Object.keys(req).length) {
            headers['cgid'] = req.sCgids;
            headers['brandid'] = req.sBrandids.length > 0 ? req.sBrandids.join(',') : "";
            headers['machinetype'] = req.sMachineTypes;

        }
        if (localStorage.getItem('emulation')) {
            headers.emulation = localStorage.getItem('emulation');
            headers.base_country = localStorage.getItem('basecountry');
        }

        let pageNumber = `&pageNum=` + currentPage;
        let pageLimit = '&pageSize=' + pageSize;
        url = url + pageNumber + pageLimit;
        const response = await http.get(url,
            {
                headers,
                data: {}
            })
        if (response && response.data) {
            let { response: usersList = [] } = response.data;
            usersList?.map((item, i) => {
                item.checked = false;
                item.order_id = ++i;
                let pickRole = userListImage.find(r => r.name === item.roleName);
                if (pickRole != null) {
                    item.img = pickRole.img;
                }
                else if (pickRole === undefined) {
                    item.img = require("../../images/users/user.png");
                }
            });
            if (usersList) {
                setCurrentPage(currentPage)
            }
            setUser(usersList);
            setSortColumn(sortColumnParams);
            setTotalPages(response.data.numberOfPages);
            setLoading(false);
        }
    }

    const onSort = sortColumn => {
        getUserList(1, sortColumn)
    }

    const onPageChange = (currentPage) => {
        getUserList(currentPage, sortColumn)
    }

    const getUserToState = (param = getUserModel) => {
        dispatch(getUserDataModel(param));
    }

    const handleChange = (e, userVal, type) => {
        if (type === 'multi') {
            setUser((prev) => {
                const newData = prev.map((item => {
                    return { ...item, checked: e.target.checked }
                }))
                return newData
            })
        } else {
            setUser((prev) => {
                const newData = prev.map((item => {
                    if (item.email === userVal.email) {
                        return { ...item, checked: e.target.checked }
                    }
                    return item;
                }))
                return newData
            })
        }
    }

    return (
        <>
            <Loader loading={loader} />
            <UserManagementHeader headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav" />
            <UsertListComponent
                user={user}
                setLoading={setLoading}
                loader={loader}
                totalPages={totalPages}
                currentPage={currentPage}
                isIndeterminateSelect={isIndeterminateSelect}
                allSelect={allSelect}
                sortColumn={sortColumn}
                onPageChange={onPageChange}
                setAllSelect={setAllSelect}
                setIsIndeterminate={setIsIndeterminate}
                searchData={searchData}
                pageSize={pageSize}
                handleChange={handleChange}
                getUserToState={getUserToState}
                history={props.history}
                snackBarToast={snackBarToast}
                onSort={onSort} />
        </>
    )
}


export default UserManagementList;