import $ from 'jquery';
import React, { useEffect, useState, Fragment, useRef } from 'react';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import authService from '../../service/authService';
import fConsts from '../../utils/featureConstants';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import TandCList from '../TermsAndConditions/tandcList';
import TandCView from '../TermsAndConditions/tandcView';
import { BaseService } from '../../service/api';
import http from '../../service/httpService';
import { useSelector } from 'react-redux';

const TermsPricingCondition = (props) => {
    const { formatMessage } = props.intl;
    const [loaderIconVisible, setLoaderIconVisible] = useState(false);
    const [tacList, setTacList] = useState([]);
    const langDetails = useSelector(state => state.localeInfo.locale);

    useEffect(() => {
        $("div").removeClass("modal-backdrop fade show");
        document.body.style.backgroundColor = "#F2F2F2";
        init();
        return () => {
        }
    }, [langDetails]);

    const init = async () => {
        setLoaderIconVisible(true);
        let url = BaseService.getTandCdetails;
        const payLoadData = {
            'userId': localStorage.getItem('user'),
            "roleId": localStorage.getItem('roleId'),
            "customerId": localStorage.getItem('customerId'),
            "content": "required",
            "langCode" : langDetails ? langDetails : 'en-US'
        }
        const response = await http.post(url, payLoadData,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        )
        if (response && response.data) {
            let data = response.data.body.policy;            
            if (data?.length) {
                if (data.length>1){
                    data[1].selected = true;
                } else {
                    data[0].selected = true;
                }
                setTacList(data);
            }
        }
        setLoaderIconVisible(false)
    }

    let userNotificationAuthFeature = authService.getFeatureByFeatureName(fConsts.Notifications);
    let userProfileFeature = authService.getFeatureByFeatureName(fConsts.UserProfile);

    let NotificationManagementEditOrViewLink;
    let isUserProfileFormViewStatus;
    let myinformationEditOrViewLink = "/myinformationEdit";
    let assignedunitgroupsEditOrViewLink = "/assignedunitgroupsEdit";
    if (userNotificationAuthFeature && userNotificationAuthFeature.feature_active) {
        NotificationManagementEditOrViewLink = "/NotificationManagement";
    }
    if (userProfileFeature && userProfileFeature.feature_active) {
        isUserProfileFormViewStatus = userProfileFeature.is_editable ? false : true;
    }

    // Woopra Events
    const trackEvent = (e) => {
        WoopraEvents(`${e.trackTitle}`);
    }

    const itemClickedInList = (list) => {
        setTacList((prev) => {
            const newData = prev.map((item => {
                if (item.id === list.id) {
                    return { ...item, selected: true }
                }
                return { ...item, selected: false }
            }))
            return newData
        })
    };


    return (
        <React.Fragment>
            <div id="adminWrapper">
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                {/* <!-- //Loading Progress -->*/}

                <div id="page-content-wrapper">
                    <div class="userManagementWrapper">
                        <div class="userProfileHeader">
                            <ul>
                                <li>
                                    <Link to={myinformationEditOrViewLink} title={formatMessage({ id: 'KC1916' })}><FormattedMessage id='KC1916' /></Link>
                                    <Link to={assignedunitgroupsEditOrViewLink} title={formatMessage({ id: 'KC1917' })} onClick={(e) => { e.trackTitle = "Assigned Unit Groups"; trackEvent(e) }}><FormattedMessage id='KC1917' /></Link>
                                    {NotificationManagementEditOrViewLink &&
                                        <Link to={{ pathname: NotificationManagementEditOrViewLink, state: { fromViewAccountpageValue: false } }} title={formatMessage({ id: 'KC0109' })} onClick={(e) => { e.trackTitle = "Notification Management"; trackEvent(e) }}><FormattedMessage id='KC0109' /></Link>}
                                    <Link to="/timeNumberFormatedit" title={formatMessage({ id: 'KC0110' })} onClick={(e) => { e.trackTitle = "Time & Number Format"; trackEvent(e) }}><FormattedMessage id='KC0110' /></Link>
                                    {/* <Link to="/mySubscriptionEdit" title="Subscription Management">Subscription Management</Link> */}
                                    <Link to="/termsPricingCondition" title={formatMessage({ id: 'KC2439' })} class="active"><FormattedMessage id='KC2439' /></Link>
                                </li>
                            </ul>
                        </div>
                        {loaderIconVisible ? null : <div className="TCWrapper">
                            {tacList && tacList.length > 1 ? <TandCList isView={true} itemClickedInList={itemClickedInList} tacList={tacList?.length > 0 ? tacList : []} /> : ""}
                            <TandCView isView={true} tacList={tacList?.length > 0 ? tacList : []} />
                        </div>}
                    </div>
                </div>
                <UserManagementHeader hideUserProfileEditBtn={true} headerName="User Profile" headerNameStringID="KC0106" previousPage="User Profile" activeClass="UserProfileNav" />
            </div>
        </React.Fragment>
    )
};


export default injectIntl(TermsPricingCondition)