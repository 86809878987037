import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import _ from 'lodash';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { MenuBrandsResponse } from '../../../utils/unitResponseBuilder';
import { getMediaLibraryList } from '../../../actions/navigation/mediaManagement/mediaLibraryListActions'
import { BaseService, HACCPManagementAPI, UserProfile } from '../../../service/api';
import { BRAND_ID } from '../../../utils/appConstants';
import $ from 'jquery';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';

class HACCPBrands extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			brandList: [],
			loaderIconVisible: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			pushOption: "New"
		};
		this.onButtonClickHandler = this.onButtonClickHandler.bind(this);
	}

	getBrands = () => {
		this.setState({ loaderIconVisible: true });
		let url = BaseService.root + HACCPManagementAPI.getHACCPBrandList;
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				'brandid': BRAND_ID,
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({
					loaderIconVisible: false,
					brandList: response.data,
				});
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	getUserInfo = () => {
		this.setState({ loaderIconVisible: true });
		let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
		if (!loginInfo.rolename) {
			http.get(UserProfile.getUserProfileDetails,
				{headers: {
					'customer_id': localStorage.getItem("customerId"),
					'email': loginInfo.username,
					'Content-Type': 'application/json',
					'base_country': localStorage.getItem("basecountry"),
					'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
				},data:{},
			}).then(response => {
				loginInfo.rolename = response.data.role_name;
				localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
				this.setState({
					loaderIconVisible:false,
				})
			}).catch(err => {
				this.setState({ loaderIconVisible:false });
			});
		}
	}

	componentDidMount = () => {
		$('body').removeClass("modal-backdrop show");
		document.body.style.backgroundColor = "#F2F2F2";
		this.getUserInfo();
		this.getBrands();
	}

	onButtonClickHandler = (item) => {
		localStorage.setItem("brandName",item.BRAND_NAME);
		localStorage.setItem("selectedbrandID",item.BRAND_ID);
		WoopraEvents(`${Constants.SELECT_BRAND}-${item.BRAND_NAME}`);
		this.props.history.push(`/haccpLogLibrary`);
	}

	render() {
		const { formatMessage } = this.props.intl;
		let {brandList, loaderIconVisible, pushOption } = this.state;
		let branList = [];
		let orderId = 1;
		let menuItemList;
		let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
		if (Object.keys(brandList).length > 0 && brandList.branList) {

			branList = MenuBrandsResponse(brandList.branList);
			branList = _.orderBy(branList, "BRAND_NAME", "asc");
			menuItemList = (
				branList.map((item) =>
					<ul className="tBody" key={item.ID} onClick = {() => this.onButtonClickHandler(item)}>
						<li>{orderId++}</li>
						<li>{item.BRAND_NAME}</li>
						<li className="logoH">{<img src={item.img} alt="" />} </li>
						<li><button type="button" class="btn forwardNext">&nbsp;</button></li>
					</ul>
				)
			)
		}
		else {
			menuItemList = (
				<div className="menuBrandsTable">
					<NoRecordsFound loaderIconVisible={loaderIconVisible} length={branList !== undefined ? branList.length : 0} classname={'norecordsfoundbluetext'} />
					</div>)
		}
		return (
			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">

					<div className="menuUpdateWrapper">
							<div className="menuUpdateHeader">
								<ul>
									<li>
										<Link to="/menuBrands" title={formatMessage({ id: 'KC0661' })} className="active"><FormattedMessage id="KC0661"/></Link>
									</li>
								</ul>
							</div>

							<div className="menuBrandsTable">
									<ul className="tHead">
										<li>#</li>
										<li><FormattedMessage id="KC0873"/></li>
										<li><FormattedMessage id="KC2332"/></li>
										<li>&nbsp;</li>
									</ul>
							</div>
						<form className="menuBrandsTableDiv">
							<div className="menuBrandsTable">
								
								{menuItemList}
							</div>
						</form>
					</div>
				</div>
				{/* <!-- Select Flow Modal --> */}
                <div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="ConfirmationOfImportModal" tabIndex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationYesNo"> 
                                    <h5><FormattedMessage id= 'KC1114'/></h5>
                                    <div className="uploadOption">
                                        <div className="customRadioBtn">
                                            <input id="selectFromLibrary" type="radio" name="uploadOption" value="New" checked={pushOption === "New" ? true : false} onClick={this.onPushTypeSelect} />
                                            <label for="selectFromLibrary" value="New"><FormattedMessage id= 'KC1743'/></label>
                                        </div> 
                                        <div className="customRadioBtn">
                                            <input id="selectFromDevice" type="radio" name="uploadOption" value="Legacy" checked={pushOption === "Legacy" ? true : false} onClick={this.onPushTypeSelect} />
                                            <label for="selectFromDevice" value="Legacy"><FormattedMessage id= 'KC1113'/></label>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary noBtn" id="selectFromLiberary" type="button" onClick={this.cancelPushClick}><FormattedMessage id="KC0021"/></button>
                                <button className="btn btn-secondary yesBtn" id="ImportButton" type="button" onClick={this.submitPushClick}><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- // Select Flow Modal --> */}
				<UserManagementHeader headerName="HACCP Management" headerNameStringID="KC0636" activeClass="haccpNav" />
			</div>
		)
	}
};

function mapStateToProps(state) {

	return {
		mediaLibraryListInfo: state.mediaLibraryListInformation.mediaLibraryListInformation,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaLibraryList,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HACCPBrands));

