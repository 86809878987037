import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { getCycleRatesOnTime } from '../../../actions/unitDashboard/cycleRatesOnTimeActions';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { UNIT_BRAND_ID, TOTAL_ON_TIME_WIDGET_ID, FRYMASTER_UHCTHD_TOTAL_ON_TIME_WIDGET_ID, CONVOTHERM_TOTAL_ON_TIME_WIDGET_ID } from '../../../utils/appConstants';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';

// common global parameters
var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'MM-DD-YYYY');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'MM-DD-YYYY');
let unitID = "";
let brandID = "";
let currentUnit;

class TotalOnTimeWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            locationInformation: {},
            deviceInformation: {},
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            startDateOnTime: "",
            endDateOnTime: "",
            datapickflag:false,
            disableDatePicker: false,
        }
        this.handleChangeStartOnTime = this.handleChangeStartOnTime.bind(this);
        this.handleChangeEndOnTime = this.handleChangeEndOnTime.bind(this);
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        let widgetId = brandID === 11 ? FRYMASTER_UHCTHD_TOTAL_ON_TIME_WIDGET_ID : brandID === 6 ? CONVOTHERM_TOTAL_ON_TIME_WIDGET_ID : TOTAL_ON_TIME_WIDGET_ID;
        this.props.getCycleRatesOnTime("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, widgetId);
    }

    componentDidUpdate = async (prevProp, prevState) => {
        if(this.props.widgetID == "115" && this.props.globalDropdownFilter === 'Date Range'){
            return;
        }
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleTotalOnTime(this.props.globalDropdownFilter);
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDateOnTime !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEndOnTime(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDateOnTime !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStartOnTime(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //start Date Change Handler
    handleChangeStartOnTime(onTimeDate) {
        if(this.state.startDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            startDateOnTime: onTimeDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleTotalOnTime(selectedtext);
        });
    }

    //end Date Change Handler
    handleChangeEndOnTime(onTimeDate) {
        if(this.state.endDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            endDateOnTime: onTimeDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleTotalOnTime(selectedtext);
        });
    }

    //dropdown Handler
    onDropDownHandler(e) {
        const { nodeText : text } = e;
        this.setState({type:text,datapickflag: text === 'Date Range', startDateOnTime: '', endDateOnTime: ''})
        this.handleTotalOnTime(text);
    }

    handleTotalOnTime(text) {
        currentUnit = this.props.currentUnit;
        const { startDateOnTime, endDateOnTime } = this.state;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        if (text === "Date Range") {
            todaysDateFormatted = format(endDateOnTime, 'YYYY-MM-DD');
            weekDateFormatted = format(startDateOnTime, 'YYYY-MM-DD');
        }
        else {
            todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
            weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
        }
        if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") &&
            (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            let widgetId = brandID === 11 ? FRYMASTER_UHCTHD_TOTAL_ON_TIME_WIDGET_ID : brandID === 6 ? CONVOTHERM_TOTAL_ON_TIME_WIDGET_ID : TOTAL_ON_TIME_WIDGET_ID;
            this.props.getCycleRatesOnTime(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, widgetId);
        }
    }

    render() {

        const { cycleRatesOnTimeInfo } = this.props;
        const onTimeInformation = cycleRatesOnTimeInfo.cycleRatesOnTimeResponse ? cycleRatesOnTimeInfo.cycleRatesOnTimeResponse : {};
        let onTimeModel = onTimeInformation.content;
        let value = 0, days = "", hours = "", mins = "";
        let cycleRatesOnTimeWidget, onTimeValue = null;
        const isTrend = _.isUndefined(onTimeModel) ? false : _.isObject(_.find(onTimeModel, el => el.key === 'trend'));

        value = onTimeModel && onTimeModel[0] ? onTimeModel[0].value : "0days 0h 0m";

        if ((Array.isArray(onTimeModel) && onTimeModel.length) || value) {
            try {
                const durationValue = value ? value.split(" ") : '';
                days = durationValue[0].replace("days", "");
                hours = durationValue[1].replace("h", "");
                mins = durationValue[2].replace("m", "");
            }
            catch (err) {
                days = 0;
                hours = 0;
                mins = 0;
            }
            onTimeValue = (<div class="valH1Div timeDiv">{days}<span class="TimeRangeSpan">{+days > 1 ? <FormattedMessage id='KC1886'/> : <FormattedMessage id ='KC1885'/>}</span> {hours}<span class="TimeRangeSpan"><FormattedMessage id="KC1898"/></span> {mins}<span class="TimeRangeSpan"><FormattedMessage id="KC1899"/></span> {(isTrend && this.state.type !== "Date Range") && (<span className={onTimeModel[2].value === '1' ? "valH1Span valHigh" : onTimeModel[2].value === '0' ? "valH1Span valLow" : "valH1Span"}> &nbsp;</span>)}</div>); 
            cycleRatesOnTimeWidget = (
                <div class="colmDiVal">
                    {onTimeValue}
                    {(isTrend && this.state.type !== "Date Range") && (<div class="valH5Div">{ mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }:  {onTimeModel[1].value} </div>)}
                </div>
            )
        }
        // Merco Cycle Rates Total On Time Widget
        let OnTimeWidget = null;
        OnTimeWidget = (<div class="colmDi">
            <h4>{this.props.widgetID == "115" ?<FormattedMessage id='KC0395' /> : <FormattedMessage id='KC0355' />}
                    <span className="cornerIcon"></span>
                <NewDropDownListLink translation={true}  datapickflag={this.state.datapickflag} startDatepara={this.state.startDateOnTime} maxDateDisable={this.state.sdmaxdate} minDateDisable={this.state.sdmindate}
                    endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDateOnTime} handleChangeStartProps={(date) => this.handleChangeStartOnTime(date)} handleChangeEndProps={(date) => this.handleChangeEndOnTime(date)} OnChange={(e) => this.onDropDownHandler(e, "TotalOnTime")} filterType={this.state.type} disableDatePicker={this.props.widgetID == "115"}/>
            </h4>
            {cycleRatesOnTimeWidget}

        </div>)

        return (
            <React.Fragment>
                <div className="colm4D colm4D1">
                    {OnTimeWidget}
                </div>
            </React.Fragment>
        )
    }
};
function mapStateToProps(state) {
    return {
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
        cycleRatesOnTimeInfo: state.cycleRatesOnTimeResponse,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCurrentUnit, getCurrentLocation, getCycleRatesOnTime }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(TotalOnTimeWidget);
