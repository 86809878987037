import {BaseService, ReportManagement} from '../../service/api';
import http from '../../service/httpService';
import { BRAND_ID, CONVO_TOP_TEN_PRODUCT_WIDGET_ID } from '../../utils/appConstants';
export const TOPTEN_TABLE_REQUEST = 'TOPTEN_TABLE_REQUEST';
export const TOPTEN_TABLE_SUCCESS = 'TOPTEN_TABLE_SUCCESS';
export const TOPTEN_TABLE_FAIL = 'TOPTEN_TABLE_FAIL';


export function getTopTenTableInfo (type, fromdate, todate, deviceId, brandid, page) {
        let url ;
        let filterType;
        let widgetId = 19;
        let convoTopTenWidgetId = CONVO_TOP_TEN_PRODUCT_WIDGET_ID;
        let UNIT_BRAND_ID = brandid;
        let groupbyValue = 'month';
        switch (type) {
            case 'Week':
                filterType = 'weekly';
                groupbyValue = 'day';      
                break; 
            case 'Year':
                filterType='yearly';  
                groupbyValue = 'quarter';          
                break; 
            case 'Day':            
                filterType='daily';
                groupbyValue = 'day';          
                break;
            case 'Quarter':
                filterType='quarterly';            
                groupbyValue = 'quarter';
                break; 
            case 'Date Range':
                filterType = 'custom';
                groupbyValue = 'day';          
                break; 
            default: 
                filterType = 'monthly';              
          }    

        //url = api.topTenDrinksSold.replace("{deviceIdvalue}", deviceId).replace("{fromDatevalue}", fromdate).replace("{filterTypeValue}", filterType).replace("{groupbyValue}",groupbyValue)        
        if(localStorage.getItem("brandName")==="MERRYCHEF")
        {
            url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
        }
        else if(localStorage.getItem("brandName")==="CONVOTHERM")
        {
            url = `${BaseService.root}${ReportManagement.widgetAPI}/${convoTopTenWidgetId}/${UNIT_BRAND_ID}`;
        }
        else if(localStorage.getItem("brandName")==="GARLAND")
        {
            widgetId = 89;
            url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
        }
        else{
            url = `${ReportManagement.root}`
        }
    return dispatch => {
        // dispatch({
        //     type: TOPTEN_TABLE_REQUEST
        // })
          
        let header;
        if(localStorage.getItem("brandName")==="MERRYCHEF" || localStorage.getItem("brandName")==="CONVOTHERM" || localStorage.getItem("brandName")==="GARLAND")
        {   
            if (filterType === 'weekly')
            {
                header = {
                    'Content-Type':'application/json',            
                    'unitid' : deviceId,  
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType,             
                    'limit' : 10,                      
                    'showtrend':"true",
                }; 
            }else if (filterType === 'custom')
            {
                header = {
                    'Content-Type':'application/json',            
                    'unitid' : deviceId,                                
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType,             
                    'limit' : 10
                }; 
            }
            else{
                header = {
                    'Content-Type':'application/json',            
                    'unitid' : deviceId,            
                    'reqdate' : fromdate,
                    'filterType' : filterType,             
                    'limit' : 10,            
                    'showtrend':"true",
                }; 
            }
        }else{
            if (filterType === 'weekly' || filterType === 'custom')
            {
                header = {
                    'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'unitid' : deviceId,
                    //'brandid' : brandid,
                    'brandid': brandid !==undefined ? ( brandid.length > 0 ?  brandid : BRAND_ID):BRAND_ID,
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType,
                    'groupby' : 'product_type',
                    'limit' : 10
                }; 
            }
            else
            {
                header = {
                    'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'unitid' : deviceId,
                    //'brandid' : brandid,
                    'brandid': brandid !==undefined ? ( brandid.length > 0 ?  brandid : BRAND_ID):BRAND_ID,
                    'reqdate' : fromdate,
                    'filterType' : filterType,
                    'groupby' : 'product_type',
                    'limit' : 10,                 
                }; 
            }
        }
        header.cid=localStorage.getItem("customerId") ;


        http
            .get(url,
                {
                  headers: header,
                  data:{}
                })
            .then(response => {
                dispatch({
                    type: TOPTEN_TABLE_SUCCESS,
                    time: type,
                    page: page,
                    device: deviceId,
                    topTenTableInformation: response.data,
                })
            })
            .catch(error => {
                dispatch({
                    type: TOPTEN_TABLE_FAIL,
                    error
                })
            })
    }
}