
import axios from 'axios';
import { MenuUploadAPI, BaseService } from '../../../service/api';

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

function uploadLibraryToCloud(url, httpMethod, headerpresigned, postData, imageDataBuffer, thumbnaildata, jsondata, isImageChanged) {
	return new Promise((resolve, reject) => {
		let token = localStorage.getItem('Access-Token');
		headerpresigned.Authorization = token;
		axios({
			url: BaseService.root + url,
			method: HTTP_METHOD.GET,
			headers: headerpresigned,
			data: {}
		}).then(response => {
			let { imagePresignedURL, thumbnailPresignedURL, jsonPresignedURL } = response.data.presignedURLs
			let imageurl = imagePresignedURL;
			let thumbnailurl = thumbnailPresignedURL;
			let jsonurl = jsonPresignedURL;

			let UploadedImages3url = "";
			if (imageurl) {
				UploadedImages3url = imageurl.split('?');
				UploadedImages3url = UploadedImages3url[0];
			}
			let UploadedJSONs3url = "";
			if (jsonurl) {
				UploadedJSONs3url = jsonurl.split('?');
				UploadedJSONs3url = UploadedJSONs3url[0];
			}
			if ((postData.type.toUpperCase() === 'RECIPE' || postData.type.toUpperCase() === 'READYRECIPE') && isImageChanged) {
				var xhrthumb = new XMLHttpRequest();
				xhrthumb.open(HTTP_METHOD.PUT, thumbnailurl);
				xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
				xhrthumb.onloadend = (res) => {
					if (res.target.status === 200) {
						var xhrimage = new XMLHttpRequest();
						xhrimage.open(HTTP_METHOD.PUT, imageurl);
						xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
						xhrimage.onloadend = (res) => {
							if (res.target.status === 200) {
								var xhrjson = new XMLHttpRequest();
								xhrjson.open(HTTP_METHOD.PUT, jsonurl);
								xhrjson.setRequestHeader('Content-Type', 'multipart/form-data');
								xhrjson.onloadend = (res) => {
									if (res.target.status === 200) {
										postData.imageS3Url = UploadedImages3url;
										postData.libraryS3Url = UploadedJSONs3url;
										axios({
											method: httpMethod,
											url: `${BaseService.root}${MenuUploadAPI.getLibraryList}`,
											data: postData,
											headers: {
												'Content-Type': 'application/json',
												'Authorization': token
											}
										}).then(res => {
											let { data } = res;
											let recipeId = [];
											if (postData.type === "Recipe" && httpMethod === "POST") {
												const existingIds = JSON.parse(localStorage.getItem('newCreatedRecipeId')) || [];
												existingIds.push(data.id);
												localStorage.setItem('newCreatedRecipeId', JSON.stringify(existingIds));
											}
											let { httpCode,  message } = data;
											if (httpCode === "HTTP_200") {
												resolve(message);
											}
											else {
												reject(res);
											}
										}).catch(err => {
											reject(err);
										});
									}
								};
								xhrjson.onerror = (err) => {
									reject(err);
								};
								xhrjson.send(jsondata);
							}
						};
						xhrimage.onerror = (err) => {
							reject(err);
						};
						xhrimage.send(imageDataBuffer);
					}
				};
				xhrthumb.onerror = (err) => {
					reject(err);
				};
				xhrthumb.send(thumbnaildata);
			}
			else if ((postData.type.toUpperCase() === 'RECIPE' || postData.type.toUpperCase() === 'READYRECIPE') && !isImageChanged) {
				var xhrjson = new XMLHttpRequest();
				xhrjson.open(HTTP_METHOD.PUT, jsonurl);
				xhrjson.setRequestHeader('Content-Type', 'multipart/form-data');
				xhrjson.onloadend = (res) => {
					if (res.target.status === 200) {
						// postData.imageS3Url = UploadedImages3url;
						postData.libraryS3Url = UploadedJSONs3url;
						axios({
							method: httpMethod,
							url: `${BaseService.root}${MenuUploadAPI.getLibraryList}`,
							data: postData,
							headers: {
								'Content-Type': 'application/json',
								'Authorization': token
							}
						}).then(res => {
							let { data } = res;
							let recipeId = [];
							if (postData.type === "Recipe" && httpMethod === "POST") {
								const existingIds = JSON.parse(localStorage.getItem('newCreatedRecipeId')) || [];
								existingIds.push(data.id);
								localStorage.setItem('newCreatedRecipeId', JSON.stringify(existingIds));
							}
							let { httpCode,  message } = data;
							if (httpCode === "HTTP_200") {
								resolve(message);
							}
							else {
								reject(res);
							}
						}).catch(err => {
							reject(err);
						});
					}
				};
				xhrjson.onerror = (err) => {
					reject(err);
				};
				xhrjson.send(jsondata);
			}
			else if (postData.type.toUpperCase() === 'SETTINGS' && isImageChanged) {
				var xhrimage = new XMLHttpRequest();
				xhrimage.open(HTTP_METHOD.PUT, imageurl);
				xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
				xhrimage.onloadend = (res) => {
					if (res.target.status === 200) {
						var xhrjson = new XMLHttpRequest();
						xhrjson.open(HTTP_METHOD.PUT, jsonurl);
						xhrjson.setRequestHeader('Content-Type', 'multipart/form-data');
						xhrjson.onloadend = (res) => {
							if (res.target.status === 200) {
								postData.imageS3Url = UploadedImages3url;
								postData.libraryS3Url = UploadedJSONs3url;
								axios({
									method: httpMethod,
									url: `${BaseService.root}${MenuUploadAPI.getLibraryList}`,
									data: postData,
									headers: {
										'Content-Type': 'application/json',
										'Authorization': token
									}
								}).then(res => {
									let { data } = res;
									if(data) {
										if(data.id) {
											localStorage.setItem("librarySettingsId", data.id);
										}
									}
									let { httpCode,  message } = data;
									if (httpCode === "HTTP_200") {
										resolve(message);
									}
									else {
										reject(res);
									}
								}).catch(err => {
									reject(err);
								});
							}
						};
						xhrjson.onerror = (err) => {
							reject(err);
						};
						xhrjson.send(jsondata);
					}
				};
				xhrimage.onerror = (err) => {
					reject(err);
				};
				xhrimage.send(imageDataBuffer);
			}
			else if (postData.type.toUpperCase() === 'SETTINGS' && !isImageChanged) {
				var xhrjson = new XMLHttpRequest();
				xhrjson.open(HTTP_METHOD.PUT, jsonurl);
				xhrjson.setRequestHeader('Content-Type', 'multipart/form-data');
				xhrjson.onloadend = (res) => {
					if (res.target.status === 200) {
						postData.imageS3Url = UploadedImages3url;
						postData.libraryS3Url = UploadedJSONs3url;
						axios({
							method: httpMethod,
							url: `${BaseService.root}${MenuUploadAPI.getLibraryList}`,
							data: postData,
							headers: {
								'Content-Type': 'application/json',
								'Authorization': token
							}
						}).then(res => {
							let { data } = res;
							if(data) {
								if (data.id) {
									localStorage.setItem("librarySettingsId", data.id);
								}
							}
							let { httpCode,  message } = data;
							if (httpCode === "HTTP_200") {
								resolve(message);
							}
							else {
								reject(res);
							}
						}).catch(err => {
							reject(err);
						});
					}
				};
				xhrjson.onerror = (err) => {
					reject(err);
				};
				xhrjson.send(jsondata);
			}
			else if (postData.type.toUpperCase() === 'COOKBOOK') {
				var xhrjson = new XMLHttpRequest();
				xhrjson.open(HTTP_METHOD.PUT, jsonurl);
				xhrjson.setRequestHeader('Content-Type', 'multipart/form-data');
				xhrjson.onloadend = (res) => {
					if (res.target.status === 200) {
						postData.libraryS3Url = UploadedJSONs3url;
						axios({
							method: httpMethod,
							url: `${BaseService.root}${MenuUploadAPI.getLibraryList}`,
							data: postData,
							headers: {
								'Content-Type': 'application/json',
								'Authorization': token
							}
						}).then(res => {
							let { data } = res;
							let { httpCode,  message } = data;
							if (httpCode === "HTTP_200") {
								localStorage.removeItem("newCreatedRecipeId");
								resolve(message);
							}
							else {
								reject(res);
							}
						}).catch(err => {
							reject(err);
						});
					}
				};
				xhrjson.onerror = (err) => {
					reject(err);
				};
				xhrjson.send(jsondata);
			}
		}).catch(err => {
			reject(err);
		});
	});
}

export default uploadLibraryToCloud;
