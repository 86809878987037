import React, { PureComponent } from 'react';
import http from '../../../service/httpService';
import { ServiceManagement } from '../../../service/api';
import { format, addDays } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { HolderList } from './holderList';
import { BRANDNAME } from '../../../utils/appConstants';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { FormattedMessage } from 'react-intl';

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let currentUnit;

class MostFrequentErrorsChart extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            errorChartInformation: [],
            filterType: "Month",
            startDate: '',
            endDate: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            responseLength: 0,
            datapickflag:false
        }
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount() {
        this.getReportData(this.state.filterType, todaysDateFormatted, weekDateFormatted);
    }

    toggleDatePicker(e) {
        this.setState({ filterType: "Date Range" })
    }

    handleChangeStart(date, mindate) {
        this.setState({
            startDate: date,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText: selectedtext };
            this.onDropDownHandler(e);
        });
    }

    handleChangeEnd(date) {
        this.setState({
            endDate: date,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText: selectedtext };
            this.onDropDownHandler(e);
        });
    }

    getReportData(text, startDate, date, holder_id = '') {
        let unitID = "";
        let brandID = "";
        currentUnit = this.props.properties.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_NAME ? currentUnit.BRAND_NAME : BRANDNAME;

        const header = this.getHeaders(text, startDate, date, unitID, brandID, holder_id);
        let url = `${ServiceManagement.frequentErrors}`;
        this.getData(url, header).then(response => {
            if (response.errorChartInformation) {
                this.setState({
                    responseLength: response.errorChartInformation.length
                });
                this.renderChartData(text, response.errorChartInformation);
            }
        }).catch(err => {
            //this.renderChartData(err);
        });
    }

    fetchData(holder_id, text) {
        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else if (text === "Week") { 
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate,- 6), 'YYYY-MM-DD');
        }
        else {
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        }
        this.getReportData(text, todaysDateFormatted, weekDateFormatted, holder_id);
    }
    
    onDropDownHandler(e, type = '') {
        this.fetchData(this.state.holder_id, e.nodeText);
        this.setState({
            filterType: e.nodeText,
            datapickflag: e.nodeText === 'Date Range'
        });
    }

    onHolderSelection(e, type = '') {
        const holderid = e.target.text.replace("Frypot ","").replace("Fryer","")
        this.setState({ 
            holder_id: holderid,
            holder_name: e.target.text
        });
        this.fetchData(holderid, type);
    }

    getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '', unitID = '', brandID = '', holder_id = '') {
        let filterType;
        switch (type) {
            case 'Week':
                filterType = 'weekly';
                break;
            case 'Year':
                filterType = 'yearly';
                break;
            case 'Day':
                filterType = 'daily';
                break;
            case 'Quarter':
                filterType = 'quarterly';
                break;
            case 'Date Range':
                filterType = 'custom';
                break;
            default:
                filterType = 'monthly';
        }

        let header;
        if (filterType === 'custom' || filterType === 'weekly') {
            header = {
                'Content-Type': 'application/json',
                'unitId': unitID,
                'startdate': endDateFormatted,
                'enddate': startDateFormatted,
                'filterType': filterType,
                'brandid': brandID
            };

        }
        else {
            header = {
                'Content-Type': 'application/json',
                'unitId': unitID,
                'reqdate': startDateFormatted,
                'filterType': filterType,
                'brandid': brandID
            };
        }
        if (localStorage.getItem("brandName") === "FRYMASTER" && holder_id !== "") {
            header.holderid = holder_id;
        }
        return header;
    }


    getData(url, header) {

        return new Promise((resolve, reject) => {
            http
                .get(url,
                    {
                        headers: header,
                        data: {}
                    })
                .then(response => {
                    resolve({
                        errorChartInformation: response.data
                    })
                })
                .catch(error => {
                    reject({
                        errorChartInformation: []
                    })
                });
        });
    }

    renderChartData = (type, response) => {
        let chart = am4core.create("mostFrequentErrBarChartdiv", am4charts.XYChart);
        if (response && response.length > 0) {
            for (let index = 0; index < 10; index++) {
               if (!response[index]) {
                response.push({"error_code":"UN"+(index+1),"value":0,"is_critical":0,"description":""});
               }
            }
            chart.seriesContainer.zIndex = -1;
            chart.data = response;
            this.chart = chart;
            chart.responsive.enabled = true;

            // Create axes
            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "error_code";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 10;
            categoryAxis.renderer.labels.template.fill = am4core.color("#FFF");
            categoryAxis.renderer.labels.template.fontSize = 10;
            categoryAxis.renderer.inside = false;
            categoryAxis.renderer.labels.template.disabled = false;
            categoryAxis.renderer.grid.template.strokeOpacity = 0;

            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.renderer.grid.template.location = 0;
            valueAxis.renderer.minGridDistance = 100;
            // Enable .total and .totalPercent placeholders for stacked series
            valueAxis.calculateTotals = true;
            valueAxis.renderer.inside = false;
            valueAxis.renderer.labels.template.disabled = false;
            valueAxis.min = 0;
            valueAxis.renderer.labels.template.fill = am4core.color("#FFF");
            valueAxis.renderer.labels.template.fontSize = 10;
            valueAxis.renderer.grid.template.strokeOpacity = 1;
            valueAxis.renderer.grid.template.stroke = am4core.color("#FFF");
            valueAxis.renderer.grid.template.strokeWidth = 0.3;

            categoryAxis.renderer.labels.template.adapter.add("text", function(text, target) {
                if (target.dataItem && target.dataItem.dataContext && (target.dataItem.dataContext.error_code.includes('E'))) {
                    return target.dataItem.dataContext.error_code;
                }
                else {
                    return "";
                }
              });

            // Create series
            let series = chart.series.push(new am4charts.ColumnSeries());
            series.dataFields.valueY = "value";
            series.dataFields.categoryX = "error_code";
            series.columns.template.width = am4core.percent(93);
            series.columns.template.tooltipText = "{description}";
            series.columns.template.properties.fillOpacity = 1;

            series.columns.template.adapter.add("fill", function (fill, target) {
                if (target.dataItem && (target.dataItem.dataContext.is_critical === 0)) {
                    return am4core.color("#FFBE00");
                }
                else {
                    return am4core.color("#F93251");
                }
            });

            // Series Tooltip Test and bg color
            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color("#FFFFFF");
            series.tooltip.label.fill = am4core.color("#000000");

            let columnTemplate = series.columns.template;
            columnTemplate.strokeWidth = 0;
            columnTemplate.strokeOpacity = 0;

            categoryAxis.renderer.grid.template.location = 0;
        }
        else {
            if (this.chart) {
                this.chart.data = [];
            }
        }
    }

    componentWillUnmount = () => {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        const {responseLength} = this.state;
        return (
            <div className="colmDi">
                <h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
                    <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.filterType} disableDatePicker={this.props.disableDatePicker ? this.props.disableDatePicker : false} />
                    <HolderList OnHolderChange={(e) => this.onHolderSelection(e, this.state.filterType)} />
                </h4>
                { responseLength > 0 ? <div id="mostFrequentErrBarChartdiv"></div> : <div className="noResultMsg"><FormattedMessage id="KC0264"/></div>}
            </div>
        );
    }
}

export default MostFrequentErrorsChart;
