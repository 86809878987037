import React, { Component, PureComponent } from 'react';
import http from '../../../service/httpService';
import { ReportManagement } from '../../../service/api';
import { format } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import { FormattedMessage } from 'react-intl';
import { reactIntl } from '../../../locale/locale-provider';


var moment = require('moment');
am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);
var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var startOfQuarter = require('date-fns/start_of_quarter');
var endOfWeek = require('date-fns/end_of_week');
var endOfMonth = require('date-fns/end_of_month');
var endOfQuarter = require('date-fns/end_of_quarter');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let currentUnit;
let modelNo;

class TrayUtilizationLineChart extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			peakHourChartInformation: [],
			total_unit_count: 0,
			loading: true,
			filterType: "Month",
			average: 0,
			sd: '',
			ed: '',
			startDate: '',
			endDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			widgetShow: true,
			datapickflag: false
		}
		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount() {
		let chart = am4core.create("totalProductsCookedBarChartdiv", am4charts.XYChart);
		this.getReportData(this.state.filterType, "", "");
	}

	toggleDatePicker(e) {
		this.setState({ filterType: "Date Range" })
	}

	handleChangeStart(date) {
		this.setState({
			startDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext } };
			this.onDropDownHandler(e);
		});
	}

	handleChangeEnd(date) {
		this.setState({
			endDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext } };
			this.onDropDownHandler(e);
		});
	}

	getReportData(text, startDate, date) {
		let unitID = "";
		let brandID = "";
		currentUnit = this.props.properties.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;

		const header = this.getHeaders(text, startDate, date, unitID);
		let url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + brandID}`;
		this.getData(url, header).then(response => {
			let _average = response.peakHourChartInformation.average ? response.peakHourChartInformation.average : 0;
			this.setState({
				average: _average
			})
			this.renderChartData(text, response.peakHourChartInformation);
		}).catch(err => {
			//this.renderChartData(err);
		});
	}

	onDropDownHandler(e) {
		const { nodeText:text } = e;
		this.setState({datapickflag: text === 'Date Range'})
		this.handleTrayUtilization(text);
	}

	handleTrayUtilization(text) {
		const { startDate, endDate } = this.state;
		this.setState({ filterType: text })
		if (text === "Date Range") {
			if (startDate !== "" && endDate !== "") {
				this.getReportData(text, startDate, endDate);
			}
		}
		else {
			this.getReportData(text, "", "");
		}
	}

	getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '', unitID = '') {
		let filterType;
		switch (type) {
			case 'Week':
				filterType = 'weekly';
				break;
			case 'Year':
				filterType = 'yearly';
				break;
			case 'Day':
				filterType = 'daily';
				break;
			case 'Quarter':
				filterType = 'quarterly';
				break;
			case 'Date Range':
				filterType = 'custom';
				break;
			default:
				filterType = 'monthly';
		}

		let header;

		if (filterType === 'custom' || filterType === 'weekly') {
			if (type === 'Week') {
				startDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
				endDateFormatted = format(todaysDate, 'YYYY-MM-DD');

				var startDateMonth = getMonth(new Date(startDateFormatted));
				var endDateMonth = getMonth(new Date(endDateFormatted));

				if (startDateMonth !== endDateMonth) {
					startDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
				}
			}
			else {
				startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');;
				endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');;
			}
			modelNo = currentUnit.PRODUCT_NAME;

			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'startdate': startDateFormatted,
				'enddate': endDateFormatted,
				'filterType': filterType,
				'showtrend': "false",
				'model': modelNo,
			};

		}
		else {
			startDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			currentUnit = this.props.properties.currentUnit;
			modelNo = currentUnit.PRODUCT_NAME;

			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'reqdate': startDateFormatted,
				'filterType': filterType,
				'showtrend': "false",
				'model': modelNo,
			};
		}

		return header;
	}


	getData(url, header) {

		return new Promise((resolve, reject) => {
			http
				.get(url,
					{
						headers: header,
						data: {}
					})
				.then(response => {
					resolve({
						peakHourChartInformation: response.data
					})
				})
				.catch(error => {
					let { response } = error;
					if (response && response.data && response.data.body) {
						this.setState({ widgetShow: error.response.data.body.widgetShow })
					}
					reject({
						peakHourChartInformation: []
					})
				});
		});
	}

	renderChartData = (type, data) => {


		let response = data.Data && data.Data.length > 0 ? data.Data : [];

		if (type !== "Day") {
			let dateFormat = (type === "Week" || type === "Month" || type === "Custom") ? "D" : (type === "Year" || type === "Quarter") ? "MMMM" : "";
			if (response.length > 0) {
				response.map(item => {
					let day = item.Key.split('-')[2];
					let month = item.Key.split('-')[1];
					day = day && day.length === 1 ? '0' + day : day;
					month = month && month.length === 1 ? '0' + month : month;
					let date = item.Key.split('-')[0] + '-' + month + '-' + day;
					item.Key = (moment(date).format(dateFormat));
					item.alpha = 1;
				});
			}
			else {
				response = [];
			}
		}
		else {
			if (response.length > 0) {				
				if (localStorage.getItem("timeFormat") === "24"){
					response.map(item => { item.Key = (item.Key === 24 ? "00" : item.Key); item.alpha = 1 });
				} else {
					response.map(item => { item.Key = (item.Key === "12" ? "12 pm" : item.Key === "24" ? "12 am" : item.Key > 12 ? item.Key - 12 + " pm" : item.Key + " am"); item.alpha = 1 });
				}
			}
			else {
				response = [];
			}
		}

		let chart = am4core.create("totalProductsCookedBarChartdiv", am4charts.XYChart);
		if (response && response.length > 0) {
			chart.seriesContainer.zIndex = -1;
			chart.data = response;
			this.chart = chart;
			chart.responsive.enabled = true;

			// Create axes
			let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = "Key";
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.renderer.minGridDistance = (type === "Year" || type === "Quarter") ? 60 : 10;
			categoryAxis.renderer.labels.template.fill = am4core.color("#FFF");
			categoryAxis.renderer.labels.template.fontSize = 10;
			categoryAxis.renderer.inside = false;
			categoryAxis.renderer.labels.template.disabled = false;
			categoryAxis.renderer.grid.template.disabled = true;
			categoryAxis.renderer.grid.template.strokeOpacity = 0;

			categoryAxis.renderer.axisFills.template.disabled = false;
			categoryAxis.renderer.axisFills.template.fill = am4core.color("#D2D68D");
			categoryAxis.renderer.axisFills.template.fillOpacity = 0.2;

			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis.renderer.grid.template.location = 0;
			valueAxis.renderer.minGridDistance = 100;
			// Enable .total and .totalPercent placeholders for stacked series
			valueAxis.calculateTotals = true;
			valueAxis.renderer.inside = false;
			valueAxis.renderer.labels.template.disabled = false;

			valueAxis.renderer.labels.template.fill = am4core.color("#FFF");
			valueAxis.renderer.labels.template.fontSize = 10;
			valueAxis.renderer.grid.template.strokeOpacity = 1;
			valueAxis.renderer.grid.template.stroke = am4core.color("#FFF");
			valueAxis.renderer.grid.template.strokeWidth = 0.3;

			let average = Math.round(data.average ? data.average : 0);
			if (average > 0) {
				var range = valueAxis.axisRanges.create();
				range.value = average;
				range.grid.stroke.above = true;
				range.grid.above = true;
				range.grid.stroke = am4core.color("#FFFFFF");
				range.grid.strokeWidth = 1.5;
				range.grid.strokeOpacity = 1;
			}

			// Setting the Series Name based on the unit brand
			let seriesName = localStorage.getItem("brandName") === "CONVOTHERM" ? reactIntl.formatMessage({ id: 'Tray Utilization' }) : reactIntl.formatMessage({ id: 'KC0095' }); // TODO

			// Line series
			let series = chart.series.push(new am4charts.LineSeries());
			series.dataFields.valueY = "Value";
			series.dataFields.categoryX = "Key";
			series.name = seriesName;
			series.tooltipText = " " + seriesName + " : [bold]{valueY}[/]";
			let gradient = new am4core.LinearGradient();
			gradient.rotation = 90;
			gradient.addColor(am4core.color("#1f6387"));
			gradient.addColor(am4core.color("#293b47"));
			series.fill = gradient;
			series.stroke = am4core.color("#0ea1e9");
			categoryAxis.renderer.ticks.template.stroke = am4core.color("#d9d9d9");
			series.strokeWidth = 1;
			series.fillOpacity = 0.8;

			// Series Tooltip Test and bg color
			series.tooltip.getFillFromObject = false;
			series.tooltip.background.fill = am4core.color("#FFFFFF");
			series.tooltip.label.fill = am4core.color("#000000");

			chart.cursor = new am4charts.XYCursor();
			chart.cursor.lineY.opacity = 0;

			categoryAxis.fillRule = function (dataItem) {
				var date = new Date(dataItem.alpha);
				if (dataItem.alpha !== undefined && dataItem.alpha === 1) {
					dataItem.axisFill.visible = true;
				}
				else {
					dataItem.axisFill.visible = false;
				}
			}

			let categoryStarts = null;
			let categoryEnds = null;
			if (type === "Week") {
				var startDateMonth = getMonth(startOfWeek(todaysDate, { weekStartsOn: 1 }));
				var endDateMonth = getMonth(endOfWeek(todaysDate, { weekStartsOn: 1 }));
				var todayMonth = getMonth(todaysDate);

				if (startDateMonth < todayMonth && endDateMonth === todayMonth) {
					categoryStarts = format(startOfMonth(todaysDate), 'D');
					categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
				}
				else if (startDateMonth === todayMonth && endDateMonth > todayMonth) {
					categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
					categoryEnds = format(endOfMonth(todaysDate), 'D');
				}
				else {
					categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
					categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
				}
			}
			else if (type === "Quarter") {
				categoryStarts = format(startOfQuarter(todaysDate), 'MMMM');
				categoryEnds = format(endOfQuarter(todaysDate), 'MMMM');
			}

			// Create category axis range
			var categoryAxis_range = categoryAxis.axisRanges.create();
			categoryAxis_range.category = categoryStarts;
			categoryAxis_range.endCategory = categoryEnds;
			categoryAxis_range.axisFill.fill = am4core.color("#396478");
			categoryAxis_range.axisFill.fillOpacity = 0.3;
			categoryAxis_range.label.disabled = true;

			// Create a hover state
			var hoverState = series.columns.template.states.create("hover");
			hoverState.properties.fill = am4core.color("#00a4f2");

			let columnTemplate = series.columns.template;
			columnTemplate.strokeWidth = 0;
			columnTemplate.strokeOpacity = 0;

			categoryAxis.renderer.grid.template.location = 0;
		}
		else {
			if (this.chart) {
				this.chart.data = response;
			}
		}
	}

	componentWillUnmount = () => {
		if (this.chart) {
			this.chart.dispose();
		}
	}

	render() {
		let { widgetShow } = this.state;

		return (
			<div class={widgetShow === false ? "displaynone" : "colm12D"}>
				<div className="colmDi">
					<h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID} /> : this.props.widgetName}
						<NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.filterType} disableDatePicker={this.props.disableDatePicker ? this.props.disableDatePicker : false} />
					</h4>
					{widgetShow && <div class="chartTitleLgnd">{this.props.chartTitleStringID ? <FormattedMessage id={this.props.chartTitleStringID} /> : this.props.chartTitle}</div>}
					{widgetShow && <div class="chartValLgnd">{(this.state.average > 0 && this.state.average < 1) ? "<1" : Math.round(this.state.average)}</div>}
					<div id="totalProductsCookedBarChartdiv"></div>
				</div>
			</div>
		);
	}
}

export default TrayUtilizationLineChart;
