import React, { useState, useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";

const Captcha = ({_reCaptchaRef, language, onChange }) => {
    const [recaptchaKey, setRecaptchaKey] = useState(0);
    useEffect(() => {
      // Increment the recaptchaKey to re-render the component
      setRecaptchaKey(prevKey => prevKey + 1);
    }, [language]);
  
    return (
      <ReCAPTCHA
          ref={_reCaptchaRef}
        key={recaptchaKey}
        sitekey="6Lfm7poUAAAAAAhOzR85EpbAqgbQ1KiIhai2HyBG"
        hl={language} // Pass the language as a prop
        onChange={onChange}
      />
    );
  };

export default Captcha;