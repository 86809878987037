import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import { SelectedBrandImage } from '../../../utils/unitResponseBuilder';
import { getBakingPlans } from '../../../actions/navigation/softwareManagement/bakingPlanActions'
import { getOrganizationCategoryDetails } from '../../../actions/navigation/organizationmanagement/organizationCategoryDetailsActions'
import { getStoreInstalledItem } from '../../../actions/navigation/softwareManagement/storeInstalledItemAction'
import { TableHeader } from '../../controls/tableHeader';
import { BRAND_ID_VALUE } from '../../../utils/appConstants'
import UserManagementHeader from '../../layoutSection/userManagementHeader'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GoToPagination from '../../controls/goToPagination';
import moment from '../../../utils/momentHelper.js';
import $ from 'jquery';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';

class BakingPlan extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sortColumn: { path: "CREATED", order: "desc", sort_color: "#63778a" },
            brandImage: {},
            currentPage: 1,
            pageSize: 25,
        };
    }

    componentDidMount() {
        const { currentPage, pageSize, sortColumn } = this.state;
        window.addEventListener('resize', this.updateDimension);
        this.props.getBakingPlans(currentPage, pageSize, sortColumn);
        this.props.getOrganizationCategoryDetails();
        document.body.style.backgroundColor = "#F2F2F2";
        this.setState({
            brandImage: SelectedBrandImage(localStorage.getItem("brandName")),
        })
    }

    onInstallButtonClickHandler(item) {
        let message = "";
        this.props.getStoreInstalledItem(item);
        localStorage.setItem("brandName", item.brand_name);

        if (item.brand_name === "CONVOTHERM") {
            let brandId = `${BRAND_ID_VALUE[item.brand_name]}`
            localStorage.setItem("modelIdValue", item.MODEL_IDS);
            this.props.history.push({
                pathname: "/softwareScheduleUpdate",
                FILE_NAME: item.baking_plan_file_name,
                UPLOADED_ON: item.created,
                brandId: brandId,
                MODEL_NAMES: [],
                TYPE: item.type,
                ID: item.market_id
            });
        }
        if (message !== "") {
            this.setState({
                error_modal: {
                    open: true,
                    message: message
                }
            }
            );
        }
    }

    // Woopra Events
	trackEvent = (e, title = '') => {
		WoopraEvents(`${Constants.SOFTWARE}-${(title || e.target.title)}`)
	}
    
    updateDimension() {
        const windowHeight = $(window).height();
        const $tableBody = $('.pageScrollbar');
        const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
    }
    componentDidUpdate () {
		this.updateDimension();
    }
    
    onSort = sortColumn => {
        this.setState({
            sortColumn: sortColumn,
        });
        const {currentPage, pageSize} = this.state
        this.props.getBakingPlans(currentPage, pageSize, sortColumn);
    }

    onPageChange = (currentPage) => {
        const {pageSize, sortColumn} = this.state
        this.props.getBakingPlans(currentPage, pageSize, sortColumn);
    }

    render() {
        console.log('this.props: ', this.props)
        const { data, totalPages, currentPage, loaderIconVisible, OrganizationCategoryDetailsInfo } = this.props;
        const { formatMessage } = this.props.intl;
        const { sortColumn, brandImage } = this.state;
        const { customer_group_category } = OrganizationCategoryDetailsInfo
        let dynamicHeader = customer_group_category && customer_group_category.length > 0 ? 1 : 0;

        let columns = [
            { path: "FILE_NAME", labelStringID: "KC0677" },
            { path: "LOCATION", labelStringID: "KC0050" },
            { path: "WITHIN_LOCATION", labelStringID: "KC0061" },
            { path: "CREATED", labelStringID: "KC0725" },
            { path: "", label: "", isNbSp: true },
        ];

        if(dynamicHeader) {
            columns[1].label = customer_group_category.filter(data => data.level_id === 4 )[0].display_name
            columns[2].label = customer_group_category.filter(data => data.level_id === 5 )[0].display_name
            delete columns[1].labelStringID
            delete columns[2].labelStringID
        }

        return (
            <React.Fragment>
                <div id="adminWrapper">
                    <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                        <div className="loadingProgressIn"></div>
                    </div>
                    {/* <!-- //Fixed Header navbar --> */}
                    <div id="page-content-wrapper">

                        <div className="softwareUpdateWrapper">
                            <div className="softwareUpdateHeader">
                                <ul>
                                    <li>
                                        <Link to="/softwareAvailableUpdates" title={formatMessage({ id: 'KC0830' })} onClick={(e) => this.trackEvent(e, 'Available Updates')}><FormattedMessage id="KC0830" /></Link>
                                        <Link to="#" title={formatMessage({ id: 'KC2345' })} className="active" ><FormattedMessage id="KC2345" /></Link>
                                        <Link to="/softwareUpdatesStatus" title={formatMessage({ id: 'KC0659' })} onClick={(e) => this.trackEvent(e, 'Update Status')}><FormattedMessage id="KC0659" /></Link>
                                    </li>
                                    <li>
                                        <span className="selectedBrand"><img src={brandImage.Brand_Logo} alt={brandImage.Brand_Alt} title={brandImage.Brand_Title} /></span>
                                    </li>
                                </ul>
                            </div>
                            <div className="SWavlUpdtsDivOuter">
                                <div className="bakingPlansList">
                                    <TableHeader
                                        sortColumn={sortColumn}
                                        onSort={this.onSort}
                                        columns={columns} />
                                </div>
                                <div className='pageScrollbar'>
                                <div className="bakingPlansList">

                                    {data.map(item => {
                                        return (
                                            <React.Fragment>
                                                <ul className="tBody">
                                                    <li>{item.baking_plan_file_name}</li>
                                                    <li>{item.location}</li>
                                                    <li>{item.within_location}</li>
                                                    <li>{item.created ? moment.ConvertLocalTimeFromUTCTime(item.created, "MMMM DD, YYYY hh:mm A") : ""}</li>
                                                    <li><button onClick={() => this.onInstallButtonClickHandler(item)} className="btn btn-default"><FormattedMessage id="KC0561" /></button></li>
                                                </ul>
                                            </React.Fragment>
                                        )
                                    })}
                                </div>
                                {!loaderIconVisible && data && data.length === 0 ?  <div className={'norecordsfoundbluetext'}><FormattedMessage id="KC2918"/></div> : null}
                                </div>
                            </div>
                        </div>
                        { totalPages && totalPages > 1 ?
                        <GoToPagination
                            totalPageNumber={totalPages}
                            pageNumber={currentPage}
                            navigateTo={(p) => this.onPageChange(p)} /> : null}
                        </div>
                </div>
                <UserManagementHeader headerName="Software Management" headerNameStringID="KC0633" activeClass="softwareNav" />
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        data: state.bakingPlan.data,
        totalPages: state.bakingPlan.totalPages,
        loaderIconVisible: state.bakingPlan.loaderIconVisible,
        storeInstalledItemInfo: state.storeInstalledItemInformation.storeInstalledItemInformation,
        OrganizationCategoryDetailsInfo: state.OrganizationCategoryDetailsInformation.OrganizationCategoryDetailsInformation,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getBakingPlans, getStoreInstalledItem, getOrganizationCategoryDetails
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(BakingPlan));