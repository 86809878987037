import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { getDeviceAPIUrl, getDeviceInfo } from '../../../service/unitManagement/deviceinfo';
import { getMenuAPIUrl, getMenuFileInfo } from '../../../service/unitManagement/menuinfo';
import UnitManagementInfo from './widgetconstants';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';

am4core.useTheme(am4themes_animated);



class SoftwareVersionWidget extends Component {

	constructor(props) {
		super(props);
		this.state = {
			deviceInformation: {},
			loaderIconVisible: true,
			softwareFrypotValue: {},
			softwareLaneValue: null,
			swFrypotIndex: 0
		}
		this.selectSoftwareFrypot = this.selectSoftwareFrypot.bind(this);
		this.selectSoftwareLane = this.selectSoftwareLane.bind(this);
	}

	componentDidMount = async () => {
		let deviceUrl = getDeviceAPIUrl(localStorage.getItem("unitID"), localStorage.getItem("brandName"));
		let deviceInfo = await getDeviceInfo(deviceUrl);
		let frypotID;
		let laneID;
		if (deviceInfo && Object.keys(deviceInfo).length > 0 && localStorage.getItem("brandName") === 'FRYMASTER') {
			if (deviceInfo.softwareVersion && deviceInfo.softwareVersion.frypots) {
				let frypotLists = deviceInfo.softwareVersion.frypots;
				let frypotList = Object.keys(frypotLists).map(function (key) {
					return { key: key, value: frypotLists[key] };
				});
				frypotID = frypotList.length > 0 ? frypotList[0].key : "frypotId1";
			}

			//To display Menu File Name - Frymaster
			delete deviceInfo.menu;
			deviceInfo.menu = {};
			let menuDetails = await getMenuFileInfo(getMenuAPIUrl(), localStorage.getItem("unitID"), localStorage.getItem("unitBrandId"));
			if (menuDetails && menuDetails.menuInfo) {
				deviceInfo.menu.menuFileName = menuDetails.menuInfo.FILE_NAME ? menuDetails.menuInfo.FILE_NAME : "N/A";
			}
			else {
				deviceInfo.menu.menuFileName = "N/A";
			}
		}

		//Garland Software information

		if (deviceInfo && Object.keys(deviceInfo).length > 0 && localStorage.getItem("brandName") === 'GARLAND') {
			if (deviceInfo.softwareVersion && deviceInfo.softwareVersion.lanes) {
				let laneViewLists = deviceInfo.softwareVersion.lanes;
				let laneList = Object.keys(laneViewLists).map(function (key) {
					return { key: key, value: laneViewLists[key] };
				});
				laneID = laneList.length > 0 ? laneList[0].key : "laneId1";
			}

			//To display Menu File Name - Frymaster
			delete deviceInfo.menu;
			deviceInfo.menu = {};
			let menuDetails = await getMenuFileInfo(getMenuAPIUrl(), localStorage.getItem("unitID"), localStorage.getItem("unitBrandId"));
			if (menuDetails && menuDetails.menuInfo) {
				deviceInfo.menu.menuFileName = menuDetails.menuInfo.FILE_NAME ? menuDetails.menuInfo.FILE_NAME : "N/A";
			}
			else {
				deviceInfo.menu.menuFileName = "N/A";
			}
		}
		
		if (deviceInfo) {
			this.setState({
				deviceInformation: deviceInfo,
				softwareFrypotValue: frypotID,
				softwareLaneValue: laneID,
			});
		}

		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	selectSoftwareFrypot = (e) => {
		if (!e) return;
		const copyOfObj = e.target.value;
		this.setState({
			softwareFrypotValue: copyOfObj
		});
	}

	selectSoftwareLane = (e) => {
		if (!e) return;
		const selectedLane = e.target.value;
		this.setState({
			softwareLaneValue: selectedLane
		});
	}

	render() {
		const { deviceInformation, softwareFrypotValue, softwareLaneValue } = this.state;
		const unitMngInfo = UnitManagementInfo;
		const brandName = localStorage.getItem("brandName");
		const expobarProductName = localStorage.getItem("expobarModelName");
		let softwareVersionInfo = unitMngInfo[brandName].softwareVersionInfo;
		let expobarSoftwareVersion = unitMngInfo[brandName].expobarSoftwareVersion;
		let softwareFrypots = unitMngInfo[brandName].softwareFrypots;
		let menuFileInfo = unitMngInfo[brandName].menuFileInfo;
		const modelFamily = localStorage.getItem("modelFamilyName");
		let intuitionSoftwareInfo = unitMngInfo[brandName].intuitionSoftwareInfo;
		let intutionSoftwareFrypots = unitMngInfo[brandName].intutionSoftwareFrypots;
		let errormsg = "";
		let displaySoftwareWidget = "";

		let selectedFryPots = null;
		let selectedFrypotList = null;
		let frypotList = [];
		let selectedLanes = null;
		let laneList = [];
		//User Story 47079: KC Portal - Frymaster Intuition model new items
		if (brandName === "FRYMASTER" && modelFamily === "Intuition") {
			if (deviceInformation && Object.keys(deviceInformation).length > 0) {

				if (deviceInformation.softwareVersion && deviceInformation.softwareVersion.frypots) {
	
					selectedFryPots = deviceInformation.softwareVersion.frypots || {};
	
					frypotList = Object.keys(selectedFryPots).map(function (key) {
						return { key: key, value: selectedFryPots[key] };
					});

					selectedFryPots = (frypotList.length > 0) ? frypotList.find(fp => fp.key === softwareFrypotValue) : {};
				}
			}
			//Software Version section should display the VIB, SIB, UIB fields by default
			if (selectedFryPots === null) {

				selectedFrypotList = intutionSoftwareFrypots.map(el => {
					let value = "";
					
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label> 
							<span class="labelVal">{value || <FormattedMessage id="KC1664"/>}</span>
						</li>
					)
				})
				
			}
			else {

			selectedFrypotList = selectedFryPots && intutionSoftwareFrypots.map(el => {
	
				return (
					<li key={el.sort}>
						<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
						<span class="labelVal">{selectedFryPots.value[el.fieldValue] || <FormattedMessage id="KC1664"/>}</span> 
					</li>
				)
			})
		}
			displaySoftwareWidget = <ul>
				<div class="dropdown frypotBtnGroup">
					<select className="btn btn-secondary dropdown-toggle frypotSelection" onChange={(e) => this.selectSoftwareFrypot(e)} value={softwareFrypotValue}>
						{frypotList && frypotList.map((item, i) => {
							if(deviceInformation && deviceInformation.totalFrypots && (i+1 <= parseInt(deviceInformation.totalFrypots))){
								return <option key={i} value={item.key}> {item.key.replace("frypotId","Software Frypot ")} </option>
							}
						})}
					</select>
				</div>
				{
					selectedFrypotList
				}
				{intuitionSoftwareInfo.map(el => {
					let value = null;
					if (deviceInformation && deviceInformation.hasOwnProperty('softwareVersion')) {
						value = deviceInformation['softwareVersion'][el.fieldValue] ? deviceInformation['softwareVersion'][el.fieldValue] : "N/A";
					}
					else if (deviceInformation && deviceInformation.hasOwnProperty('firmware')) {
						value = deviceInformation['firmware'][el.fieldValue];
					}
					else {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : deviceInformation[el.fieldValue] === undefined ? "Awaiting connection" : "N/A";
					}
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
							<span class="labelVal">{value === "" ? <FormattedMessage id="KC1664"/> : value}</span> 
						</li>
					)
				})}
				{menuFileInfo && menuFileInfo.map(el => {
					let value = null;
					if (deviceInformation && deviceInformation.hasOwnProperty('menu')) {
						value = deviceInformation['menu'] ? deviceInformation['menu'][el.fieldValue] ? deviceInformation['menu'][el.fieldValue] : "N/A" : "";
					} else {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : deviceInformation[el.fieldValue] === undefined ? "Awaiting connection" : "N/A";
					}
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
							<span class="labelVal">{value === "" ? <FormattedMessage id="KC1664"/> : value}</span> 
						</li>
					)
				})}

			</ul>
		}
		else if (brandName === "FRYMASTER") {

			if (deviceInformation && Object.keys(deviceInformation).length > 0) {

				if (deviceInformation.softwareVersion && deviceInformation.softwareVersion.frypots) {
	
					selectedFryPots = deviceInformation.softwareVersion.frypots || {};
	
					frypotList = Object.keys(selectedFryPots).map(function (key) {
						return { key: key, value: selectedFryPots[key] };
					});

					selectedFryPots = (frypotList.length > 0) ? frypotList.find(fp => fp.key === softwareFrypotValue) : {};
				}
			}
			//Software Version section should display the VIB, SIB, UIB fields by default
			if (selectedFryPots === null) {

				selectedFrypotList = softwareFrypots.map(el => {
					let value = "";
					
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label> 
							<span class="labelVal">{value || <FormattedMessage id="KC1664"/>}</span>
						</li>
					)
				})
				
			}
			else {

			selectedFrypotList = selectedFryPots && softwareFrypots.map(el => {
	
				return (
					<li key={el.sort}>
						<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
						<span class="labelVal">{selectedFryPots.value[el.fieldValue] || <FormattedMessage id="KC1664"/>}</span>
					</li>
				)
			})
		}
			displaySoftwareWidget = <ul>
				<div class="dropdown frypotBtnGroup">
					<select className="btn btn-secondary dropdown-toggle frypotSelection" onChange={(e) => this.selectSoftwareFrypot(e)} value={softwareFrypotValue}>
						{frypotList && frypotList.map((item, i) => {
							if(deviceInformation && deviceInformation.totalFrypots && (i+1 <= parseInt(deviceInformation.totalFrypots))){
								return <option key={i} value={item.key}> {item.key.replace("frypotId","Software Frypot ")} </option>
							}
						})}
					</select>
				</div>
				{
					selectedFrypotList
				}
				{softwareVersionInfo.map(el => {
					let value = null;
					if (deviceInformation && deviceInformation.hasOwnProperty('softwareVersion')) {
						value = deviceInformation['softwareVersion'][el.fieldValue] ? deviceInformation['softwareVersion'][el.fieldValue] : "N/A";
					}
					else if (deviceInformation && deviceInformation.hasOwnProperty('firmware')) {
						value = deviceInformation['firmware'][el.fieldValue];
					}
					else {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : deviceInformation[el.fieldValue] === undefined ? "Awaiting connection" : "N/A";
					}
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
							<span class="labelVal">{value === "" ? <FormattedMessage id="KC1664"/> : value}</span> 
						</li>
					)
				})}
				{menuFileInfo && menuFileInfo.map(el => {
					let value = null;
					if (deviceInformation && deviceInformation.hasOwnProperty('menu')) {
						value = deviceInformation['menu'] ? deviceInformation['menu'][el.fieldValue] ? deviceInformation['menu'][el.fieldValue] : "N/A" : "";
					} else {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : deviceInformation[el.fieldValue] === undefined ? "Awaiting connection" : "N/A";
					}
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
							<span class="labelVal">{value === "" ? <FormattedMessage id="KC1664"/> : value}</span> 
						</li>
					)
				})}

			</ul>
		} 
		//Garland Software information 
		else if (brandName === "GARLAND") {

			if (deviceInformation && Object.keys(deviceInformation).length > 0) {

				if (deviceInformation.softwareVersion && deviceInformation.softwareVersion.lanes) {
	
					selectedLanes = deviceInformation.softwareVersion.lanes || {};
	
					laneList = Object.keys(selectedLanes).map(function (key) {
						return { key: key, value: selectedLanes[key] };
					});
					selectedLanes = (laneList.length > 0) ? laneList.find(fp => fp.key == softwareLaneValue) : {};
				}
			}
			//**Garland SOFTWARE VERSIONS "Lane" Dropdown List should not be shown for 1 lane model code implemented*/
			displaySoftwareWidget = <ul>
				{laneList.length > 1 ?<div class="dropdown frypotBtnGroup">
					<select className="btn btn-secondary dropdown-toggle frypotSelection" onChange={(e) => this.selectSoftwareLane(e)} value={softwareLaneValue}>
						{laneList && laneList.map((item, i) =>
								<option key={i} value={item.key}> {item.key.replace("laneId","Lane")} </option>
							)}
					</select>
				</div>: ""}
				{
					selectedFrypotList
				}
				{softwareVersionInfo.map(el => {
					let value = _.isObject(selectedLanes)? selectedLanes.value:null;
					if (deviceInformation && _.isObject(value)) {
						value = value[el.fieldValue] || "N/A";
					}
					
					else {
						value = "Awaiting connection" ;
					}
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
							<span class="labelVal">{value === "" ? <FormattedMessage id="KC1664"/> : value}</span>  
						</li>
					)
				})}
				{menuFileInfo && menuFileInfo.map(el => {
					let value = null;
					if (deviceInformation && deviceInformation.hasOwnProperty('menu')) {
						value = deviceInformation['menu'] ? deviceInformation['menu'][el.fieldValue] ? deviceInformation['menu'][el.fieldValue] : "N/A" : "";
					} else {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : deviceInformation[el.fieldValue] === undefined ? "Awaiting connection" : "N/A";
					}
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
							<span class="labelVal">{value === "" ? <FormattedMessage id="KC1664"/> : value}</span> 
						</li>
					)
				})}

			</ul>
		}
		//CREM Expobar Model(EX3 DISPLAY & DIAMANT PRO DISPLAY) Software Information
		else if(brandName === "CREM" && (expobarProductName === "EX3" || expobarProductName === "DIAMANT PRO")){
			displaySoftwareWidget = <ul>
				{expobarSoftwareVersion.map(el => {
					let value = null;
					if (deviceInformation && deviceInformation.length > 0) {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : "N/A";
					}
					else {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : "N/A";
					}
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
							<span class="labelVal">{(value === "" || value === undefined || value === null) ? <FormattedMessage id="KC1664"/> : value}</span> 
						</li>
					)
				})}
				</ul>
		}

		else {
			displaySoftwareWidget = <ul>
				{softwareVersionInfo.map(el => {
					let value = null;
					if (deviceInformation && deviceInformation.hasOwnProperty('softwareVersion')) {
						value = deviceInformation['softwareVersion'][el.fieldValue];
					}
					else if (deviceInformation && deviceInformation.hasOwnProperty('firmware')) {
						value = deviceInformation['firmware'][el.fieldValue];
					}
					else {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : "N/A";
					}
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
							<span class="labelVal">{value === "" ? <FormattedMessage id="KC1664"/> : value}</span> 
						</li>
					)
				})}
				{menuFileInfo && menuFileInfo.map(el => {
					let value = null;
					if (deviceInformation && deviceInformation.hasOwnProperty('menu')) {
						value = deviceInformation['menu'] ? deviceInformation['menu'][el.fieldValue] ? deviceInformation['menu'][el.fieldValue] : "N/A" : "";
					} else {
						value = deviceInformation[el.fieldValue] ? deviceInformation[el.fieldValue] : "N/A";
					}
					return (
						<li key={el.sort}>
							<label>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</label>
							<span class="labelVal">{value === "" ? <FormattedMessage id="KC1664"/> : value}</span> 
						</li>
					)
				})}
			</ul>
		}
		return (
			<React.Fragment>
				<div class="colm6D colm6D1">
					<div class="colmDi">
						<h4><FormattedMessage id="KC0287"/> &nbsp; <span class="red-txt">{errormsg}</span></h4>
						<div class="colmDiVal">
							<div class="gridTableView gridTableViewSW">
								<ul>
									<li>
										{displaySoftwareWidget}
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};
export default SoftwareVersionWidget;