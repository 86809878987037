import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import Switch from 'react-switch';
import { injectIntl, FormattedMessage } from 'react-intl';

class DateAndTimeSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customerId: parseInt(localStorage.getItem("customerId")),
            selectedModel: 0,
            automatic_enabled: ""
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = localStorage.getItem("librarySettingsData");
            let jsonDateAndTime = JSON.parse(jsonSettingsFile);

            this.setState({
                automatic_enabled: jsonDateAndTime.automatic_enabled
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onBackButtonClick = () => {
        let { automatic_enabled } = this.state;
        if (automatic_enabled) {
            let jsonSettingsFile = localStorage.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            ObjSettingsFile.automatic_enabled = automatic_enabled;
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            localStorage.setItem("librarySettingsData", strSettingsFile);
        }
            this.props.history.push({
                pathname: "/settingsEditor",
                isEdit: false,
                previouspath: "/dateAndTimeSettings"
            });
    }

    onSettingsClick = (settingName) => {
        let pathName = "";
        if (settingName === "ChangeFormat") {
            pathName = "/dateAndTime";
        }
        else if (settingName === "Timezone") {
            pathName = "/timezone";
        }
        this.props.history.push({
            pathname: pathName,
            isEdit: false,
            type: "Settings",
            previouspath: "/dateAndTimeSettings"
        });
    }

    onLockTypeSelect = (e) => {
        debugger
        this.setState({
            automatic_enabled: e.toString()
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }
    
    render() {
        let { automatic_enabled } = this.state;
        let brandid = localStorage.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658" })}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">

                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>

                            <div class="recipeEditorWrapper">

                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC2248"/>
                                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            <div class="rcDateTimeSettings">
                                                                <ul onClick={() => { this.onSettingsClick('ChangeFormat') }}>
                                                                    <li><FormattedMessage id="KC1327"/></li>
                                                                    <li><img src={require("../../../../../images/recipecreation/rightArrow.png")}  alt=""/></li>
                                                                </ul>
                                                            </div>
                                                            <div class="rcDateTimeSettings">
                                                                <ul onClick={() => { this.onSettingsClick('Timezone') }}>
                                                                    <li><FormattedMessage id="KC2249"/></li>
                                                                    <li data-toggle="modal" data-target="#ConfirmationOfImportModal">UTC<img src={require("../../../../../images/recipecreation/rightArrow.png")}  alt=""/></li>
                                                                </ul>
                                                            </div>
                                                            <div class="rcDateTimeSettings">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC0155"/></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onLockTypeSelect(e)}
                                                                                checked={automatic_enabled  ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>

                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(DateAndTimeSettings)
