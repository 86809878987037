import React from 'react';
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { filterBaseMappingStringID } from '../../../locale/constant';

const FilterList = (props) => {
    let filterObj = null;
    let loaderIconVisible = true;
    let { filterList } = props;
    // remove customer level
    filterList = filterList.filter(val => val.field_name != "customer");
    filterList = _.orderBy(filterList, ['sort'], ['asc']);
    for (let i = 0; i < filterList.length; i++) {
        filterList[i].visible = false
    }
    const custlevelID = localStorage.getItem("custLevelID") ? parseInt(localStorage.getItem("custLevelID").replace("level", "")) : 0;     
    if (custlevelID) {
        for (let i = custlevelID - 1; i < filterList.length; i++) {
            if (props.history.location.pathname === "/userManagementList") {
                if (filterList[i].field_name === "model"){
                    filterList[i].visible = false
                } else {
                    filterList[i].visible = true
                }
            } else {   
                filterList[i].visible = true                
            }
        }
    }

    filterList = filterList.filter(contact => contact.visible === true);
    if (filterList && filterList.length) {
        filterObj = filterList.map(el => {
            let applyclass = ['nextIcon', 'btn'];
            let applyclassInCloseBtn = ['close'];
            if (props.history.location.pathname === "/dashBoardLocations/specificLocation") {
                if (el.display_name === "Brand" || el.display_name === "Model" || el.display_name === "Within Location") {
                    applyclass = [...applyclass, 'btn'];
                    // applyclassInCloseBtn = "close"
                } else {
                    applyclass = [...applyclass, 'btn', 'disabled'];
                    applyclassInCloseBtn = [...applyclassInCloseBtn, 'disabled'];
                }
            }
            else if (props.history.location.pathname === "/libraryList") {
                if (el.display_name === "Model") {
                    applyclass = [...applyclass, 'btn'];
                } else {
                    applyclass = [...applyclass, 'btn', 'disabled'];
                    applyclassInCloseBtn = [...applyclassInCloseBtn, 'disabled'];
                }
            }
            // const { loading, fieldName } = props.apicall;

            let itemList = (
                <React.Fragment>
                    <li>
                        {el.dataSource.map(ds => (
                            <div key={ds.id} class="selectedCountry alert alert-dismissible fade show" role="alert">
                                <button type="button" class={applyclassInCloseBtn.join(' ')} onClick={() => props.removeitem(ds)}>
                                    <span aria-hidden="true"> &times;</span>
                                </button>
                                {ds && ds.type && (ds.type === "org" || ds.type === "unit") ? <div className={ds.type === "org" ? "tagTypeLocation" : "tagTypeUnit"} ><span>{ds.value}</span></div>: ds.value}
                            </div>
                        ))}
                    </li>
                    {
                        <li><button type="button" className={applyclass.join(' ')} id="LocationDiv" onClick={() => props.clicked(el)}></button></li>
                    }
                </React.Fragment>
            )
            return (
                <ul className="filterBase" key={el.id}>
                    <li>{filterBaseMappingStringID[el.display_name] ? <FormattedMessage id={filterBaseMappingStringID[el.display_name]}/> : el.display_name}</li>
                    {itemList}
                </ul>
            );
        });
        loaderIconVisible = false;
    }
    else {
        loaderIconVisible = false;
    }
    return (
        <React.Fragment>
            <div id="loader" className={`loadingProgress ${loaderIconVisible ? 'showloader': ''}`}>
                <div className="loadingProgressIn"></div>
            </div>
            <div class="filterCategoryTable">
                {filterObj}
            </div>
        </React.Fragment>
    )
}
export default FilterList;