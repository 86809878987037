import React, { Component } from "react";
import http from "../../service/httpService";
import authService from "../../service/authService";
import { UserProfile, BaseService } from "../../service/api";
import auth from "../../utils/authHelper";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getFilterbyCountries,
  getFilterbyRegions,
  getFilterbyLocations,
  getFilterbyCustomers,
  getFilterbyBrands,
  getFilterbyModels,
} from "./../../actions/listView/locations/filterbyAction";
import { resetSelectedFilterList } from "../../actions/listView/locations/filterListAction";
import { logOutApplication } from "../../actions/logout/logoutAction";
import fConsts from "../../utils/featureConstants";
import backDropEvent from "../../events/backdropEvent";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import WoopraEvents from "../../woopraConfig/WoopraEvents";
import Constants from "../../woopraConfig/Woopraconstant";
import { FormattedMessage, injectIntl } from "react-intl";

require("../../css/welbilt.scss");

const userImages = [
  {
    name: "System Manager",
    img: require("../../images/users/owner.svg").default,
  },
  {
    name: "Crem System Admin",
    img: require("../../images/users/owner.svg").default,
  },
  {
    name: "Service Technician",
    img: require("../../images/users/service.svg").default,
  },
  {
    name: "1st Line User",
    img: require("../../images/users/leaser.svg").default,
  },
  {
    name: "2nd Line User",
    img: require("../../images/users/chef.svg").default,
  },
  { name: "Crem User", img: require("../../images/users/manager.svg").default },
];

const Mailto = ({ email, subject, body, children }) => {
  return <a href={`mailto:${email}`}>{children}</a>;
};

class LogoutForm extends Component {
  constructor(props) {
    super(props);
    this.nextPath = this.nextPath.bind(this);
    this.hideUserDetails = this.hideUserDetails.bind(this);
    this.state = {
      showSignOutOnly: false,
      userData: {},
      emailfromTokan: "",
      countryCodeWithoutPlus: "",
      selectedCountries: [],
      selectedRegions: [],
      selectedLocations: [],
      selectedCustomers: [],
      selectedBrands: [],
      selectedModels: [],
      loaderIconVisible: false,
    };
  }

  nextPath(path) {
    if (this.props.nextPath !== undefined) {
      this.props.nextPath();
    }
    if (this.props.nextPathNav !== undefined) {
      this.props.nextPathNav();
    }
  }

  editinfo = () => {
    this.props.editinfo();
  };

  signoutForm = async (e) => {
    this.ClearAll();
    localStorage.setItem("signoutStatus", true);
    await this.callLogoutService();
    $("#backdropElement").remove();
    let logID = localStorage.getItem("logid");
    this.logUpdateService(logID);
    auth.deleteLocalSession();
    this.props.history.push("/");
    this.props.resetSelectedFilterList(this.props.filterKey);
    WoopraEvents(`${Constants.LOGOUT}`);
  };

  ClearAll = () => {
    //Country
    let selctedCountryList = [...this.props.selectedCountries];
    if (selctedCountryList !== undefined && selctedCountryList !== null) {
      selctedCountryList &&
        selctedCountryList.length > 0 &&
        selctedCountryList.map((item, i) => {
          item.checked = false;
        });
      this.props.getFilterbyCountries(selctedCountryList);
    }

    //Chain
    let selectedRegionList = [...this.props.selectedRegions];
    if (selectedRegionList !== undefined && selectedRegionList !== null) {
      selectedRegionList &&
        selectedRegionList.length > 0 &&
        selectedRegionList.map((item, i) => {
          item.checked = false;
        });
      this.props.getFilterbyRegions(selectedRegionList);
    }
    //Location
    let selectedLocationList = [...this.props.selectedLocations];
    if (selectedLocationList !== undefined && selectedLocationList !== null) {
      selectedLocationList &&
        selectedLocationList.length > 0 &&
        selectedLocationList.map((item, i) => {
          item.checked = false;
        });
      this.props.getFilterbyLocations(selectedLocationList);
    }

    //Customer
    let selectedCustomerList = [...this.props.selectedCustomers];
    if (selectedCustomerList !== undefined && selectedCustomerList !== null) {
      selectedCustomerList &&
        selectedCustomerList.length > 0 &&
        selectedCustomerList.map((item, i) => {
          item.checked = false;
        });
      this.props.getFilterbyCustomers(selectedCustomerList);
    }

    //Brand
    let selectedBrandList = [...this.props.selectedBrands];
    if (selectedBrandList !== undefined && selectedBrandList !== null) {
      selectedBrandList &&
        selectedBrandList.length > 0 &&
        selectedBrandList.map((item, i) => {
          item.checked = false;
        });
      this.props.getFilterbyBrands(selectedBrandList);
    }

    //Model
    let selectedModelList = [...this.props.selectedModels];
    if (selectedModelList !== undefined && selectedModelList !== null) {
      selectedModelList &&
        selectedModelList.length > 0 &&
        selectedModelList.map((item, i) => {
          item.checked = false;
        });
      this.props.getFilterbyModels(selectedModelList);
    }

    this.setState({
      selectedCountries: selctedCountryList,
      selectedRegions: selectedRegionList,
      selectedLocations: selectedLocationList,
      selectedCustomers: selectedCustomerList,
      selectedBrands: selectedBrandList,
      selectedModels: selectedModelList,
    });

    var d = document.getElementById("spannavNotifiStatus");
    d.className = "";
  };

  componentDidMount() {
    this.getUserProfileInformation();
    if (this.props.hideFlag) {
      let elem = backDropEvent.showBackDrop();
      let navHeader = document.querySelector(".navbar");
      navHeader.addEventListener("click", this.backdropClose);
      navHeader.addEventListener("touchstart", this.backdropClose);
      elem.addEventListener("click", this.backdropClose);
      elem.addEventListener("touchstart", this.backdropClose);
    }
  }

  backdropClose = (e) => {
    if (e.target) {
      if (
        !$(e.target).hasClass("loadingProgress") &&
        !$(e.target).hasClass("navUser")
      ) {
        if ($("body").has("#backdropElement")) {
          document
            .querySelector(".navbar")
            .removeEventListener("click", this.backdropClose);
          document
            .querySelector(".navbar")
            .removeEventListener("touchstart", this.backdropClose);
          this.props.hideLoginForm(e);
          backDropEvent.hideBackdrop();
        }
      }
    }
  };

  componentWillReceiveProps() {
    this.getUserProfileInformation();
  }

  hideUserDetails(event) {
    this.setState({ showSignOutOnly: true });
  }

  logUpdateService = (logID) => {
    if (logID) {
      let headers = {
        "Content-Type": "application/json",
        log_id: logID,
      };
      http({
        method: "GET",
        url: BaseService.emulationLogout,
        headers,
        data: {},
      })
        .then(function (res) {
          console.log(res.status);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  callLogoutService = async () => {
    let promise = await new Promise((resolve, reject) => {
      http({
        method: "PUT",
        url: BaseService.logout,
        data: {},
      })
        .then(function (response) {
          if (response) {
            this.props.logOutApplication();
          }
          resolve("Success");
        })
        .catch((err) => {
          this.setState({
            loaderIconVisible: false,
            success_modal: {
              open: true,
              message: "KC0141",
            },
          });
          this.props.logOutApplication();
          resolve("failed");
        });
    });
    return promise;
  };

  getUserProfileInformation = () => {
    const profileInfo = localStorage.getItem("customerInfoDetails")
      ? JSON.parse(localStorage.getItem("customerInfoDetails"))
      : null;
    let getUserData = userProfileMockData.user;
    let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
    if (!loginUser) return this.props.history.push("/");

    let loggedinUser = loginUser.username;
    this.setState({ emailfromTokan: loggedinUser, loaderIconVisible: true });
    if (!profileInfo) {
      http({
        method: "GET",
        url: UserProfile.getUserProfileDetails,
        headers: {
          customer_id: localStorage.getItem("customerId"),
          email: loggedinUser,
          "Content-Type": "application/json",
          base_country: localStorage.getItem("basecountry"),
          emulation: localStorage.getItem("emulation") ? "true" : "false",
        },
        data: {},
      })
        .then((response) => {
          this.setState(
            {
              userData: response.data,
            },
            () => {}
          );
          this.setState({
            userstate: response.data.state,
            countryCodeWithoutPlus: response.data.country_code
              ? response.data.country_code.replace("+", "")
              : "",
            loaderIconVisible: false,
          });
          this.setState({
            catchSelCountryCode: response.data.country_code
              ? response.data.country_code
              : "",
          });
        })
        .catch((err) => {
          console.log("catch exception occured");
          this.setState({ userData: getUserData, loaderIconVisible: false });
        });
    } else {
      this.setState({
        userData: profileInfo,
        catchSelCountryCode: profileInfo.country_code
          ? profileInfo.country_code
          : "",
        userstate: profileInfo.state,
        countryCodeWithoutPlus: profileInfo.country_code
          ? profileInfo.country_code.replace("+", "")
          : "",
        loaderIconVisible: false,
      });
    }
  };
  // Woopra Events
  trackEvent = () => {
    WoopraEvents(`${Constants.VIEW_ACCOUNT_INFORMATION}`);
  };
  contactAdmin = () => {
    WoopraEvents(`${Constants.CONTACT_YOUR_ADMIN}`);
  };

  render() {
    const { formatMessage } = this.props.intl;
    let { userData, loaderIconVisible } = this.state;
    const showsignOut = this.state.showSignOutOnly;
    const hidden = this.props.hideFlag;
    let pickRole = userImages.find((r) => r.name === userData.role_name);
    let userProfileAuthFeature = authService.getFeature("/myinformationedit");
    let userNotificationAuthFeature = authService.getFeatureByFeatureName(
      fConsts.Notifications
    );
    let userRoleImage = "";
    if (pickRole != null) {
      userRoleImage = pickRole.img;
    } else if (pickRole === undefined) {
      userRoleImage = require("../../images/users/user.png");
    }

    let getmailID =
      userData && userData.adminEmail
        ? userData.adminEmail
        : "admin@kitchenconnect.com";

    let phonenumandcountrycode = null;
    if (this.state.countryCodeWithoutPlus)
      phonenumandcountrycode = (
        <li x-ms-format-detection="none">
          {"+" + this.state.countryCodeWithoutPlus}&nbsp;{userData.phone_number}
        </li>
      );

    return (
      <React.Fragment>
        <div
          className={
            hidden ? "navUserWrapper showLogoutModal" : "navUserWrapper"
          }
        >
          <div className="treeinfoView">
            <div
              class={
                loaderIconVisible
                  ? "loadingProgress showloader"
                  : "loadingProgress"
              }
            >
              <div class="loadingProgressIn"></div>
            </div>
            <div class="userDetailsDiv">
              {userData.name ? (
                <div class="userProfile">
                  <h4>
                    <FormattedMessage id="KC0111" />
                    {/* {!localStorage.getItem("emulation") && <Link to="/myinformationedit" style={ (( userProfileAuthFeature && userProfileAuthFeature.is_editable === 1) || (userNotificationAuthFeature && userNotificationAuthFeature.is_editable === 1) ) ? { display: 'block' } : { display: 'none' }} class="btn profileEdit">
                                        <img src={require('../../images/icons/penB.svg')} alt={ formatMessage({ id: 'KC0115' }) } title={ formatMessage({ id: 'KC0115' }) } />
                                    </Link>} */}
                  </h4>
                  <div class="userDetails">
                    <div class="userProfileImg">
                      <img alt="" src={userRoleImage} />
                    </div>
                    <div class="userNameRole">
                      <span class="userName">{userData.name}</span>
                      <span class="userRole">{userData.role_name}</span>
                    </div>
                  </div>
                  <ul class="userDetailsList">
                    <li>{this.state.emailfromTokan}</li>
                    {phonenumandcountrycode}
                    <li>&nbsp;</li>
                    <li>
                      {userData.street_name} {userData.street_number}
                    </li>
                    <li>
                      {userData.zip_code} {userData.city}
                    </li>
                    <li>{userData.country}</li>
                    <li>&nbsp;</li>
                    <li
                      style={
                        this.props.hideViewAccBtn
                          ? { display: "none" }
                          : { display: "block" }
                      }
                    >
                      {!localStorage.getItem("emulation") && (
                        <Link
                          to="/myinformationedit"
                          title={formatMessage({ id: "KC1058" })}
                          alt={formatMessage({ id: "KC1058" })}
                          class="viewAccount"
                          onClick={() => this.trackEvent()}
                        >
                          <FormattedMessage id="KC1058" />
                        </Link>
                      )}
                    </li>
                    {/* <li onClick={() => this.contactAdmin()} style={this.props.hideViewAccBtn ? { display: 'none' } : { display: 'block' }}> */}
                    {/* <Mailto  email="admin@kitchenconnect.com" subject="Hello & Welcome" body="Hello world!"><div className="email">Contact your administrator</div></Mailto> */}
                    {/* <Mailto  email={getmailID}><div className="email"><FormattedMessage id="KC0113"/></div></Mailto> */}
                    {/* </li> */}
                    <li class="emptyLI">&nbsp;</li>
                  </ul>
                  <div className="modal-scroll-tip"></div>
                </div>
              ) : (
                <div className="spinnerd"></div>
              )}
              <button
                onClick={this.hideUserDetails}
                className="btn btn-secondary signOutBtn"
                type="button"
                data-toggle="modal"
                data-target="#SignOutModal"
              >
                <span className="signOutBtnIcon"></span>
                <FormattedMessage id="KC0114" />{" "}
              </button>
            </div>
          </div>
        </div>
        <div
          className={
            showsignOut
              ? "modal fade SignOutModal show"
              : "modal fade SignOutModal"
          }
          data-keyboard="true"
          data-backdrop="false"
          id="SignOutModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="SignOutModalLabel"
        >
          <div className="modal-dialog modal-md loginModal">
            <div className="modal-content">
              <div className="modal-header loginModalHeader modal-header-danger">
                <FormattedMessage id="KC0114" />
              </div>
              <div class="modal-body">
                <div class="singOutConfirmation">
                  <FormattedMessage
                    id="KC1060"
                    values={{ email: this.state.emailfromTokan }}
                  />
                </div>
              </div>
              <div class="modal-footer textAlignCenter">
                <button
                  class="btn btn-secondary signOutNoBtn"
                  data-dismiss="modal"
                  type="button"
                >
                  <FormattedMessage id="KC0021" />
                </button>
                {/* <button class="btn btn-secondary signOutYesBtn" onClick={() => this.nextPath('/')} type="button">Sign Out</button> */}
                {/* <Link to='/' onClick={(e) => this.signoutForm(e)} class="btn btn-secondary signOutYesBtn">Sign Out</Link> */}
                <button
                  class="btn btn-secondary signOutYesBtn"
                  onClick={(e) => this.signoutForm(e)}
                  type="button"
                >
                  <FormattedMessage id="KC0114" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
function mapStateToProps(state) {
  return {
    filterKey: [],
    selectedCountries: state.filterInformation.selectedCountries,
    selectedRegions: state.filterInformation.selectedRegions,
    selectedLocations: state.filterInformation.selectedLocations,
    selectedCustomers: state.filterInformation.selectedCustomers,
    selectedBrands: state.filterInformation.selectedBrands,
    selectedModels: state.filterInformation.selectedModels,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getFilterbyCountries,
      getFilterbyRegions,
      getFilterbyLocations,
      getFilterbyCustomers,
      getFilterbyBrands,
      getFilterbyModels,
      resetSelectedFilterList,
      logOutApplication,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(injectIntl(LogoutForm)));

const userProfileMockData = {
  user: {
    name: "",
    Id: "1",
    phone_number: "",
    email: "",
    street_name: "",
    street_number: "",
    zip_code: "",
    city: "",
    country: "",
    state: "",
  },
};
