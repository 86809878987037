import React, { Component, PureComponent } from 'react';
import http from '../../../service/httpService';
import { ReportManagement } from '../../../service/api';
import { format, addDays } from 'date-fns';
import StackedBarChart from '../globalWidgets/stackedBarChart';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as _ from 'lodash';
import { FormattedMessage } from 'react-intl';

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

var moment = require('moment');
var todaysDate = new Date();
let currentUnit;

const Colors = ["#ff97cd", "#0083b3"];

const mappingStringID = {
	"Total Quick Filters Completed": "KC1794",
	"Total Auto Filters Completed": "KC1909", 
	"Total Quick Filters Bypassed": "KC1795",
	"Total Auto Filters Bypassed": "KC1910", 
  }

class TotalFiltersCompletedBypassed extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			peakHourChartInformation: [],
			total_unit_count: 0,
			loading: true,
			filterType: "Month",
			average: 0,
			sd: '',
			ed: '',
			startDate: '',
			endDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			productList: [],
			showPopup: false,
			chartInfo: {},
			loaderIconVisible: true,
			datapickflag:false
		}
		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount() {		
		this.getReportData(this.state.filterType, "", "");
	}

	toggleDatePicker(e) {
		this.setState({ filterType: "Date Range" })
	}

	handleChangeStart(date) {
		this.setState({
			startDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			this.onDropDownHandler(e);
		});
	}

	handleChangeEnd(date) {
		this.setState({
			endDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext  };
			this.onDropDownHandler(e);
		});
	}

	getReportData(text, startDate, date) {
		this.setState({
			loaderIconVisible: true
		});
		let unitID = "";
		let brandID = "";
		currentUnit = this.props.properties.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;

		const header = this.getHeaders(text, startDate, date, unitID);
		let url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + brandID}`;

		// Added for Frymaster McD CR
		const productName = localStorage.getItem("modelFamilyName");
		if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
			header.productModel = productName;
		}

		this.getData(url, header).then(response => {
			let _average = response.peakHourChartInformation.average ? response.peakHourChartInformation.average : 0;
			this.setState({
				average: _average,
				chartInfo: response.peakHourChartInformation,
				loaderIconVisible: false
			})
		}).catch(err => {
			//this.renderChartData(err);
		});
	}

	onDropDownHandler(e) {
		const { nodeText:text } = e;
		this.setState({datapickflag: text === 'Date Range'})
		this.handleTotalFilterCompleted(text);
	}

	handleTotalFilterCompleted(text) {
		const { startDate, endDate } = this.state;
		this.setState({ filterType: text, chartInfo: null });

		if (text === "Date Range") {
			if (startDate !== "" && endDate !== "") {
				this.getReportData(text, startDate, endDate);
			}			
		}
		else {
			this.getReportData(text, "", "");
		}
	}

	getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '', unitID = '') {
		let filterType;
		switch (type) {
			case 'Week':
				filterType = 'weekly';
				break;
			case 'Year':
				filterType = 'yearly';
				break;
			case 'Day':
				filterType = 'daily';
				break;
			case 'Quarter':
				filterType = 'quarterly';
				break;
			case 'Date Range':
				filterType = 'custom';
				break;
			default:
				filterType = 'monthly';
		}

		let header;

		if (filterType === 'custom' || filterType === 'weekly') {
			if (type === 'Week') {
				endDateFormatted = format(todaysDate, 'YYYY-MM-DD');
				startDateFormatted = format(addDays(todaysDate,- 6), 'YYYY-MM-DD');
			}
			else {
				startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');
				endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');
			}
			
			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'startdate': startDateFormatted,
				'enddate': endDateFormatted,
				'filterType': filterType
			};

		}
		else {
			startDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'reqdate': startDateFormatted,
				'filterType': filterType
			};
		}

		return header;
	}


	getData(url, header) {

		return new Promise((resolve, reject) => {
			http
				.get(url,
					{
						headers: header,
						data: {}
					})
				.then(response => {
					resolve({
						peakHourChartInformation: response.data
					})
				})
				.catch(error => {
					reject({
						peakHourChartInformation: []
					})
				});
		});
	}

	componentWillUnmount = () =>{
		if (this.chart) {
			this.chart.dispose();
		}
	}

	render() {
		let {chartInfo, loaderIconVisible} = this.state;
		let stackedBarChart = null;
		currentUnit = this.props.properties.currentUnit;

		if (chartInfo && chartInfo.Data && chartInfo.Data.length > 0) {
			stackedBarChart = (<React.Fragment>
				<div class="chartTitleLgnd">{this.props.chartTitleStringID ?  <FormattedMessage id ={this.props.chartTitleStringID}/> : this.props.chartTitle}</div>
				<div class="chartValLgnd">{(this.state.average > 0 && this.state.average < 1) ? "<1" : Math.round(this.state.average)}</div>
			<StackedBarChart chartData={chartInfo.Data} allowPopup={true} average={chartInfo.average} chartID={"cleaningsBarChartdiv"} filterType={this.state.filterType} /></React.Fragment>);
		}
		else {
			stackedBarChart = (<React.Fragment>
				<div class="chartTitleLgnd">{this.props.chartTitleStringID ? <FormattedMessage id={this.props.chartTitleStringID}/> : this.props.chartTitle}</div>
				<div class="chartValLgnd">0</div>
				<div id="cleaningsBarChartdiv"/></React.Fragment>);
		}

		
		let filtrationWidgetsName = 'Quick Filters';
		const productName = _.upperCase(currentUnit.MODEL_FAMILY_NAME);
		if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
			filtrationWidgetsName = 'Auto Filters'
		}

		return (
			<div className="colmDi">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
					{this.props.displayFilter ? <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} 
					minDateDisable={this.state.sdmindate} 
					maxDateDisable={this.state.sdmaxdate} 
					endminDateDisable={this.state.edmindate} 
					endmaxDateDisable={this.state.edmaxdate} 
					endDatepara={this.state.endDate} 
					handleChangeStartProps={(date) => this.handleChangeStart(date)} 
					handleChangeEndProps={(date) => this.handleChangeEnd(date)} 
					OnChange={(e) => this.onDropDownHandler(e)} 
					filterType={this.state.filterType} 
					disableDatePicker={this.props.disableDatePicker ? this.props.disableDatePicker : false} 
					minDaysRequired={6} 
					maxDaysRequired={29} /> : ""}
				</h4>
				{stackedBarChart}
				<ul id="filtersChartlegend" class="filtersLegendSection">
					<li><span></span>{mappingStringID[`Total ${filtrationWidgetsName} Completed`] ? <FormattedMessage id={mappingStringID[`Total ${filtrationWidgetsName} Completed`]}/> : `Total ${filtrationWidgetsName} Completed` }</li>  
					<li><span></span>{mappingStringID[`Total ${filtrationWidgetsName} Bypassed`] ? <FormattedMessage id={mappingStringID[`Total ${filtrationWidgetsName} Bypassed`]}/> : `Total ${filtrationWidgetsName} Bypassed` }</li>  
				</ul>
				
			</div>
		);
	}
}

export default TotalFiltersCompletedBypassed;
