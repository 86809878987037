import { ServiceManagement } from "../../../service/api";
import http from '../../../service/httpService';
import $ from 'jquery';
import { action as Action, customDateFormatted, dashboardRenderErrorTypeImages, errorTypes } from "../../../utils/dashboardRenderer";
import { minutesToDHM, getDateFromISOString } from "../configuration-compiler-helper";
var moment = require('moment');

const clientFunctions = {
    unitErrorStatusWidget: {
        onTriggerClick: ({ props }) => {
            const { match } = props;
            const { serialNo } = match.params;

            props.history.push(`/_dashboard/${serialNo}/servicedashboard/`)
        },
        transformResponse: ({ _clientResponse }) => {
            let value = [];
            const responseData = _clientResponse.data.data;
            if (responseData.errorCount && responseData.errorCount.length) {
                value = [{
                    compare: 'Critical',
                    label: 'KC0156',
                    value: '0',
                    type: 'danger'
                }, {
                    compare: 'Non-Critical',
                    label: 'KC0157',
                    value: '0',
                    type: 'warning'
                }];
                for (let index = 0; index < value.length; index++) {
                    const element = value[index];
                    const errorLabel = element.compare === "Critical" ? "Error Critical" : "Error Non-critical";
                    const count = responseData.errorCount.find((i) => errorLabel === i.type)?.count;
                    if (count) {
                        value[index].value = count.toString();
                    }
                }
            }
            value.forEach(a => delete a.compare);
            let description = [];
            if (responseData.latestReportedError && responseData.latestReportedError.length) {
                description.push({
                    label: `${responseData.latestReportedError[0].errorData.errorCode.value} : ${responseData.latestReportedError[0].errorDesc}`,
                    value: responseData.latestReportedError[0].errorDesc,
                    type: responseData.latestReportedError[0].errorType === "Error Critical" ? 'danger' : 'warning'
                });
            }
            return {
                data: {
                    value,
                    description
                }
            }
        }
    },
    currentErrorListWidget: {
        onFilterChange: ({ event, widget }) => {
            return [
                { type: Action.setWidgetScope, value: { widgetId: widget.rawConfiguration.id, widgetScope: { filterValue: event.data.items.map((i) => i.value) } } },
                { type: Action.loadWigetData, value: { widgetId: widget.rawConfiguration.id } }
            ]
        },
        transformResponse: ({ _clientResponse, _rootScope, _widgetScope }) => {
            let value = [];
            const filterValue = _widgetScope.filterValue || [];
            const responseData = _clientResponse.data.data;
            let criticalErrorCount = 0;
            let nonCriticalErrorCount = 0;
            let eventsCount = 0;
            let totalRecords = 0;
            if (responseData.currentErrorsTotalCount?.length) {
                criticalErrorCount =  responseData.currentErrorsTotalCount?.find((i) => i.type === "Error Critical")?.count || 0;
                nonCriticalErrorCount = responseData.currentErrorsTotalCount?.find((i) => i.type === "Error Non-critical")?.count || 0;
                eventsCount = responseData.currentErrorsTotalCount?.find((i) => i.type === "Event")?.count || 0;
                if(filterValue?.length && filterValue.includes('Error Critical')){
                    totalRecords += criticalErrorCount;
                }
                if(filterValue?.length && filterValue.includes('Error Non-critical')){
                    totalRecords += nonCriticalErrorCount;
                }
                if(filterValue?.length && filterValue.includes('Event')){
                    totalRecords += eventsCount;
                }
            }
            if (responseData.currentErrors && responseData.currentErrors.length) {
                for (let index = 0; index < responseData.currentErrors.length; index++) {
                    const element = responseData.currentErrors[index];
                    const errorTypeImage = dashboardRenderErrorTypeImages[element.errorType];
                    const startedAt = getDateFromISOString(element.startedAt, false);
                    const startedAtFormatted = moment(startedAt).format(customDateFormatted);
                    const duration = moment(_rootScope.unitCurrentDate).diff(moment(startedAt), 'minutes');
                    const durationFormatted = minutesToDHM(duration, true);
                    const helpLink = `${_rootScope.helpLinkUrl}?brand=${_rootScope.unitDetails.BRAND_NAME}&model=${_rootScope.unitDetails.MODEL_FAMILY_NAME}&eventCode=${element.errorCode}`;
                    const durationInSeconds = moment(_rootScope.unitCurrentDate).diff(moment(startedAt), 'seconds');
                    let durationProgress = 0;
                    if (durationInSeconds <= 100) {
                        durationProgress = 0;
                    } else if (durationInSeconds <= 43200) {
                        durationProgress = 30;
                    } else if (durationInSeconds <= 86400) {
                        durationProgress = 50;
                    } else if (durationInSeconds > 86400 && durationInSeconds < 432000) {
                        durationProgress = 75;
                    } else {
                        durationProgress = 100;
                    }
                    value.push({
                        ...element,
                        startedAt,
                        startedAtFormatted,
                        duration,
                        durationFormatted,
                        durationProgress,
                        helpLink,
                        errorTypeImage,
                        checked: false
                    });
                }
            }
            if(_rootScope.tabName === 'livediagnostic'){
                if(value.length > 3){
                    $('.dashboard-livediagnostic-tab .kc-ui-table .kc-ui-colm-div ').css('overflow-y','scroll')
                }
            }
            return { data: { value, totalRecords, criticalErrorCount, nonCriticalErrorCount, eventsCount } };
        }
    },
    historicErrorListWidget: {
        onFilterChange: ({ event, widget }) => {
            return [
                { type: Action.setWidgetScope, value: { widgetId: widget.rawConfiguration.id, widgetScope: { filterValue: event.data.items.map((i) => i.value) } } },
                { type: Action.loadWigetData, value: { widgetId: widget.rawConfiguration.id } }
            ]
        },
        onCheckboxChange: ({ event, widget }) => {
            return [
                { type: Action.setWidgetScope, value: { widgetId: widget.rawConfiguration.id, widgetScope: { selectedItems: event.data.items } } },
            ]
        },
        onToolbarControlClick: ({ event, widget, _widgetScope, setWidgetLoadingValue }) => {
            return new Promise((resolve, _) => {
                if (event.data.value === "deleteerror" && _widgetScope.selectedItems?.length) {
                    // api call for delete error in historic tab
                    setWidgetLoadingValue(widget.rawConfiguration.id, true)
                    let apiUrl = ServiceManagement.deleteError
                    apiUrl = apiUrl.replace("{deviceId}", localStorage.getItem("unitID"));
                    let selErrorListCode = [];
                    for (let index = 0; index < _widgetScope.selectedItems.length; index++) {
                        const element = _widgetScope.selectedItems[index];
                        selErrorListCode.push({ "error_code": element.errorCode });
                    }
                    http.delete(apiUrl, {
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        data: {
                            'brandId': localStorage.getItem("brandName"),
                            'errors': selErrorListCode,
                        }
                    }).then(response => {
                        //success
                        setWidgetLoadingValue(widget.rawConfiguration.id, false)
                        resolve([
                            { type: Action.setWidgetScope, value: { widgetId: widget.rawConfiguration.id, widgetScope: { selectedItems: [] } } },
                            { type: Action.loadWigetData, value: { widgetId: widget.rawConfiguration.id } }
                        ])
                    }).catch(error => {
                        setWidgetLoadingValue(widget.rawConfiguration.id, false)
                        resolve([])
                    })
                } else {
                    resolve([])
                }
            })
        },
        transformResponse: ({ _clientResponse, _rootScope, _widgetScope }) => {
            let value = [];
            const responseData = _clientResponse.data.data;
            const filterValue = _widgetScope.filterValue || [];
            let criticalErrorCount = 0;
            let nonCriticalErrorCount = 0;
            let eventsCount = 0;
            let totalRecords =  0;
            if (responseData.historicErrorsTotalCount?.length) {
                criticalErrorCount = responseData.historicErrorsTotalCount?.find((i) => i.type === "Error Critical")?.count || 0;
                nonCriticalErrorCount = responseData.historicErrorsTotalCount?.find((i) => i.type === "Error Non-critical")?.count || 0;
                eventsCount = responseData.historicErrorsTotalCount?.find((i) => i.type === "Event")?.count || 0;
                if(filterValue?.length && filterValue.includes('Error Critical')){
                    totalRecords += criticalErrorCount;
                }
                if(filterValue?.length && filterValue.includes('Error Non-critical')){
                    totalRecords += nonCriticalErrorCount;
                }
                if(filterValue?.length && filterValue.includes('Event')){
                    totalRecords += eventsCount;
                }
            }            
            if (responseData.historicErrors && responseData.historicErrors.length) {
                for (let index = 0; index < responseData.historicErrors.length; index++) {
                    const element = responseData.historicErrors[index];
                    const errorTypeImage = dashboardRenderErrorTypeImages[element.errorType];
                    const occurrence = element.occurrence
                    const occurrenceFormatted = `${occurrence} x`;

                    const lastResolvedAt = getDateFromISOString(element.lastResolvedAt, false);
                    const lastResolvedAtFormatted = moment(lastResolvedAt).format(customDateFormatted);
                    const lastStartedAt = getDateFromISOString(element.lastStartedAt, false);
                    const lastStartedAtFormatted = moment(lastStartedAt).format(customDateFormatted);

                    const duration = moment(lastResolvedAt).diff(moment(lastStartedAt), 'minutes');
                    const durationFormatted = minutesToDHM(duration, true);

                    const helpLink = `${_rootScope.helpLinkUrl}?brand=${_rootScope.unitDetails.BRAND_NAME}&model=${_rootScope.unitDetails.MODEL_FAMILY_NAME}&eventCode=${element.errorCode}`;
                    let durationProgress = 100;

                    value.push({
                        ...element,
                        occurrence,
                        occurrenceFormatted,
                        lastResolvedAt,
                        lastResolvedAtFormatted,
                        lastStartedAt,
                        lastStartedAtFormatted,
                        duration,
                        durationFormatted,
                        durationProgress,
                        helpLink,
                        errorTypeImage
                    });
                }
            }

            return { data: { value, totalRecords, criticalErrorCount, nonCriticalErrorCount, eventsCount } };
        }
    },
};

export default clientFunctions;
