import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import http from '../../../service/httpService';
import { BaseService, MenuUploadAPI, unitAPI, UnitInfo } from '../../../service/api';
import authService from '../../../service/authService';
import ErrorMoal from '../../modal/error-model';
import SuccessMoal from '../../modal/success-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { TableHeader } from '../../controls/tableHeader';
import NoRecordsFound from '../../controls/noRecordsFound';
import { CommonDetailViewEdit } from '../../controls/commonDetailViewEdit';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE, BRAND_ID } from '../../../utils/appConstants';
import moment from '../../../utils/momentHelper.js';
import { isEqualObj, searchData_function } from '../../globalSearchBar/selectOption';
import * as momentFormat from 'moment';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import { orderBy } from 'lodash';
import Jimp from 'jimp';
import axios from 'axios';
import uploadLibraryToCloud from './uploadLibrary';
import AdmZip from 'adm-zip';
import request from 'request';
import LZString from 'lz-string';
import JSZip from 'jszip';
import md5 from 'md5';
import settingsJSON from './Templates/SettingsJSON';
import beautify from "json-beautify";
import { ungzip } from 'pako';

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}
const GET_LIBRARY_PRESIGNED_URL = `${BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl}`

class LibraryList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			customerId: parseInt(localStorage.getItem("customerId")),
			isSelectAllLibrary: false,
			isIndeterminateSelect: false,
			isAssign: false,
			isEdit: false,
			isRetrieve: true,
			isUpload: true,
			isDownload: false,
			isDelete: false,
			isCopy: false,
			data: [],
			getLibraryDetails: [],
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			unitIdValue: {},
			sortColumn: { path: "UPDATED_ON", order: "desc", sort_color: "#63778a" },
			created_by: '',
			last_edited_by: '',
			cookbookCount: 0,
			recipeCount: 0,
			readyRecipeCount: 0,
			legacyCount: 0,
			settingsCount: 0,
			selected_item: {},
			characterCount: 250,
			default_Count: 250,
			totalfilesize: 0.00,
			getType: "",
			errorCopyName: "",
			uploadOption: 'CookBook',
			selectedFile: {},
			actionType: 'Upload',
			upload_filename: '',
			errorUploadFileName: '',
			isUnitAvailable: false,
			searchData: null,
			allMenuRecipes: []
		}
		this.filterstate = {
			sCgids: [],
			sCids: [],
			sBrandids: [],
			sModels: [],
			sMachineTypes: [],
		};
		this.getLibraryList = this.getLibraryList.bind(this);
		this.onSelectLibrary = this.onSelectLibrary.bind(this);
		this.selectAllLibrary = this.selectAllLibrary.bind(this);
		this.onUploadLibraryCheck = this.onUploadLibraryCheck.bind(this);
		this.OnUploadLibrary = this.OnUploadLibrary.bind(this);
		this.OnDownloadLibrary = this.OnDownloadLibrary.bind(this);
		this.OnEditLibrary = this.OnEditLibrary.bind(this);
		this.OnDeleteLibrary = this.OnDeleteLibrary.bind(this);
		this.OnCancelLibrary = this.OnCancelLibrary.bind(this);
		this.OnAssignTimezone = this.OnAssignTimezone.bind(this);
		this.OnToggleDetails = this.OnToggleDetails.bind(this);
		this.OnAssignLibrary = this.OnAssignLibrary.bind(this);
		this.onUploadTypeSelect = this.onUploadTypeSelect.bind(this);
		this.OnRetrieveLibrary = this.OnRetrieveLibrary.bind(this);
		this.onUploadNameChange = this.onUploadNameChange.bind(this);
		this.onCopyModal = this.onCopyModal.bind(this);
		this.OnTryAgain = this.OnTryAgain.bind(this);
		this.onDownloadSettingsData = this.onDownloadSettingsData.bind(this);
		this.onDownloadDefaultSettingsData = this.onDownloadDefaultSettingsData.bind(this);
		this.containsSpecialChars = this.containsSpecialChars.bind(this);
	}

	async componentDidMount() {
		this.props.searchVisible(true);
		$('body').removeClass("modal-open");
		$('div').removeClass("modal-backdrop show");
		$('.styles_overlayCenter__YHoO7').addClass("displaynone");
		document.body.style.backgroundColor = "#F2F2F2";
		localStorage.setItem("libraryData", "{}");
		localStorage.setItem("librarySettingsData", "{}");
		localStorage.setItem("libraryDetails", "{}");
		localStorage.setItem("libraryCookbookDetails", "{}");
		localStorage.setItem("garlandRecipe", "{}");
		localStorage.setItem("libraryImage", "");
		localStorage.setItem("schedulerDetails", "{}");
		localStorage.setItem("defaultLibraryData", "{}");
		localStorage.setItem("resourceDetails", "[]");
		localStorage.setItem("selectedCategory", "{}");
		localStorage.setItem("selected_cgid", "[]");
		localStorage.setItem("selectedGroupID", "[]");
		localStorage.setItem("cookBookFileName", "");
		localStorage.setItem("MerryChefCookBookFileName", "");
		localStorage.setItem("Selected_cookbook_item", "");
		localStorage.setItem("ProductRecgDetails", "{}");
		localStorage.setItem("selectedPRRecipes", "{}");
		localStorage.setItem("libraryScreenSaverExt", "");
		localStorage.setItem("allRecipeDetails", LZString.compress("[]"));
		localStorage.removeItem("newCreatedRecipeId");
		window.addEventListener('resize', this.updateDimensions());
		$('#UploadLibrary').click(function () { $('#menuUpdate').trigger('click'); });
		localStorage.setItem("selected_cgid", "[]");
		if (localStorage.getItem("brandName") === "MERRYCHEF" || localStorage.getItem('brandName') === "GARLAND") {
			const modelFId = localStorage.getItem("brandName") === "MERRYCHEF" ? '1,2,40,41' : '31,32';
			const brandId = localStorage.getItem("brandName") === "MERRYCHEF" ? '1' : '9';
			await this.getUnitList(modelFId, brandId);
		} else {
			await this.getLibraryList().catch((err) => {
				let { message, errorCode } = err.response.data;
				if (errorCode == '403100' && message == 'Token Expired Or Invalid') {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: "KC1678"
						}
					});
				} else {
					this.setState({
						loaderIconVisible: false
					});
				}
			});
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions);
		$('div').removeClass("modal-backdrop fade show");
		clearInterval(this.interval);
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	static getDerivedStateFromProps(props) {
		return { ...props };
	}

	componentDidUpdate(prevState) {
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.getLibraryList();
		}
	}

	decompressData(base64) {
		try {
            const buffer = Buffer.from(base64, "base64");
            const bufferArr = ungzip(buffer);
            const json = new TextDecoder("utf8").decode(bufferArr);
            return JSON.parse(json);
        } catch (err) {
            console.error(err);
            return null;
        }
	}

	getUnitList(modelFamilyIds, brandId) {
		this.setState({
			loaderIconVisible: true
		});
		http.get(`${UnitInfo.list}?pageNum=1&pageSize=24`, {
            headers: {
                "Content-Type": "application/json",
                cgid: localStorage.getItem("custGroupID"),
                cid: CUSTOMER_ID,
                modelfamilyids: modelFamilyIds,
                brandid: brandId,
                model: "",
                machinetype: "",
            },
            data: {},
            params: {
                compress: true,
            },
        })
            .then(async (response) => {
                let { data } = response;
                let units = data && data.units ? data.units : [];
                if (response.data.compressed) {
                    units = this.decompressData(units) || [];
                }
                let isUnitAvailable = units.length > 0 ? true : false;
                this.setState({
                    isUnitAvailable: isUnitAvailable,
                    loaderIconVisible: false,
                });

                await this.getLibraryList().catch((err) => {
                    let { message, errorCode } = err.response.data;
                    if (errorCode == "403100" && message == "Token Expired Or Invalid") {
                        this.setState({
                            loaderIconVisible: false,
                            error_modal: {
                                open: true,
                                message: "KC1678",
                            },
                        });
                    } else {
                        this.setState({
                            loaderIconVisible: false,
                        });
                    }
                });
            })
            .catch((err) => {
                this.setState({
                    loaderIconVisible: false,
                });
            });
	}

	updateDimensions() {
		const windowHeight = $(window).height();
		const assignUListSrlBar = $(".menuLibrarySrlTable");
		assignUListSrlBar.css('height', windowHeight - (assignUListSrlBar && assignUListSrlBar.offset() && assignUListSrlBar.offset().top));
	}

	OnAssignTimezone = () => {
		$("#OpenedTimeZone").addClass("disabled");
	}

	OnToggleDetails = (assignedTopologyDetails) => {
		const { ASSIGNED_CUSTOMER_GROUP, ...rest } = assignedTopologyDetails;
		if (localStorage.getItem("brandName") !== "MERRYCHEF") {
			// initialize a Set object
			var newArray = ASSIGNED_CUSTOMER_GROUP.filter(value => Object.keys(value).length !== 0);
			const uniqueValuesSet = new Set();
			// array of objects with duplicate values 
			const filteredArr = newArray.filter((obj) => {
				// check if name property value is already in the set 
				const isPresentInSet = uniqueValuesSet.has(obj.cust_gp_id);
				uniqueValuesSet.add(obj.cust_gp_id)
				return !isPresentInSet
			});
			this.setState({
				assignedTopologyDetails: { ASSIGNED_CUSTOMER_GROUP: filteredArr, ...rest },
			}, () => {
				$('#detailsInfoModal').modal('show')
			});
		}
		else {
			this.setState({
				assignedTopologyDetails: { ASSIGNED_CUSTOMER_GROUP: [], ...rest },
			}, () => {
				$('#detailsInfoModal').modal('show')
			});
		}
	}

	OnTryAgain = (item) => {
		this.setState({
			loaderIconVisible: true
		});
		let postData = {
			'uploadtype': 'Retry',
			'RetryId': item.ID
		}
		http({
			method: "POST",
			url: `${MenuUploadAPI.uploadLibraryFile}`,
			data: postData,
			headers: {
				'Content-Type': 'application/json'
			}
		}).then(res => {
			let { data } = res;
			let { httpCode, message, stringID } = data;
			if (httpCode === "HTTP_200") {
				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: stringID || message
					}
				})
				this.getLibraryList();
			}
			else if (httpCode === "HTTP_404") {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: stringID || message
					}
				})
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false
			});
			this.openErrorModal("KC1065");
		});
	}

	containsSpecialChars = (str) => {
		const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		return specialChars.test(str);
	}

	getLibraryList = async () => {
		this.setState({
			loaderIconVisible: true
		});
		let { searchData, getLibraryDetails } = this.state;
		let url = MenuUploadAPI.getLibraryList;
		let _checkIfExists, encoded;
		let encodedSearchData = { searchtext: '' };
		if (searchData && searchData.searchtext != '') {
			_checkIfExists = this.containsSpecialChars(searchData.searchtext);
		}
		if (_checkIfExists) {
			encoded = encodeURIComponent(searchData.searchtext);
			encodedSearchData.searchtext = encoded;
		}
		//searchData_function
		let _search = await searchData_function(encodedSearchData && encodedSearchData.searchtext != '' ? encodedSearchData : searchData);
		let _encodedFlag = encodedSearchData && encodedSearchData.searchtext != '' ? `&encoded=true` : `&encoded=false`;
		if (_search) {
			url = `${url}?${_search}${_encodedFlag}`;
		}
		//User Story 82509: MRCHEF NRE - Recipe Editor Tab removal and displaying only cookbooks in the Library
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		let headerParams = {
			'Content-Type': 'application/json',
			'customerid': CUSTOMER_ID,
			'cgids': localStorage.getItem("custGroupID"),
			'brandid': localStorage.getItem("selectedbrandID"),
			'modelFamilyIds': '',
			'basecountry': BASECOUNTRY_ID
		}
		if (localStorage.getItem("brandName") === "MERRYCHEF") {
			headerParams = {
				'Content-Type': 'application/json',
				'customerid': CUSTOMER_ID,
				'cgids': localStorage.getItem("custGroupID"),
				'brandid': localStorage.getItem("selectedbrandID"),
				'modelFamilyIds': '',
				'basecountry': BASECOUNTRY_ID,
				'type': 'CookBook,Legacy',
				'createdby': loggedinUser
			}
		}
		return new Promise((resolve, reject) => {
			http.get(url,
				{
					headers: headerParams,
					data: {}
				}).then(response => {
					let { data } = response;
					let libraryList = data && data.libraryList ? data.libraryList : [];
					libraryList.length > 0 && libraryList.map(item => {
						item.checked = false;
						item.UPDATED_ON = moment.ConvertLocalTimeFromUTCTime(item.UPDATED_ON, "MMMM D, YYYY hh:mm A");
						item.LIBRARY_FILE_SIZE = item.LIBRARY_FILE_SIZE ? parseFloat(item.LIBRARY_FILE_SIZE, 2) : 0.00;
					});
					let createdDateTimeplusOne = null;
					let inProgressData = libraryList.find(l => (l.UPLOAD_RETRIEVE_STATUS === "In Progress" || l.UPLOAD_RETRIEVE_STATUS === "Initiated"));
					if (inProgressData) {
						let createdDateTime = momentFormat(inProgressData.CREATED_ON).local().toDate();
						let createdDateTimeHours = createdDateTime.getHours();
						createdDateTimeplusOne = createdDateTime.setHours(createdDateTimeHours + 1);
					}
					else {
						clearInterval(this.interval);
					}

					if (createdDateTimeplusOne && createdDateTimeplusOne > new Date() && (!this || !this.interval)) {
						this.interval = setInterval(() => this.getLibraryList(), 40000);
					}

					this.setState({
						loaderIconVisible: false,
						data: libraryList,
						getLibraryDetails: getLibraryDetails && getLibraryDetails.length === 0 ? libraryList : getLibraryDetails
					});
					resolve(response);
				}).catch(err => {
					this.setState({
						loaderIconVisible: false,
						data: []
					});
					reject(err);
				});
		});
	}

	async getRecipeList(selected_item) {
		const _getModelFamilyID = selected_item.MODEL_FAMILY?.[0]?.ID || '';
		const { username: loggedinUser } = JSON.parse(localStorage.getItem("loginInfo"));

		const response = await http.get(MenuUploadAPI.getLibraryList, {
			headers: {
				'Content-Type': 'application/json',
				'customerid': CUSTOMER_ID,
				'cgids': localStorage.getItem("custGroupID"),
				'brandid': localStorage.getItem("selectedbrandID"),
				'modelfamilyids': _getModelFamilyID,
				'basecountry': BASECOUNTRY_ID,
				'Type': "Recipe",
				'createdby': loggedinUser
			},
			data:{}
		});
		if (response.data?.fetchFromS3) {
			const data = await this.fetchDataFromS3(response.data.presignedS3URL);
			response.data.libraryList = data.libraryList;
		}
		return response.data?.libraryList || [];
	}

	async fetchDataFromS3(presignedS3URL) {
		const s3Response = await fetch(presignedS3URL);
		const arrayBuffer = await s3Response.arrayBuffer();

		const zip = await JSZip.loadAsync(arrayBuffer);
		const file = Object.values(zip.files).find(file => !file.dir);
		if (!file) {
			throw new Error('No files found in zip file');
		}
		const content = await file.async('string');
		return JSON.parse(content);
	}

	selectAllLibrary = (e) => {
		const { data } = this.state;
		if (data && data.length > 0) {
			let libraries = [...data];
			const selectedBrands = [...new Set(libraries.map(a => a.BRAND_NAME))];

			if (selectedBrands.length === 1) {
				libraries && libraries.length > 0 && libraries.map(item => {
					item.checked = (item.UPLOAD_RETRIEVE_STATUS === null || item.UPLOAD_RETRIEVE_STATUS === "SUCCEEDED" || item.UPLOAD_RETRIEVE_STATUS === "FAILED") ? e.target.checked : false;
				});

				let selectedUnits = libraries.filter(c => c.checked === true && c.UPLOAD_RETRIEVE_STATUS !== "FAILED");
				let selectedCookbook = libraries.filter(c => c.TYPE === "CookBook" && c.UPLOAD_RETRIEVE_STATUS !== "FAILED" && c.checked === true);
				let selectedRecipes = libraries.filter(c => c.TYPE === "Recipe" && c.UPLOAD_RETRIEVE_STATUS !== "FAILED" && c.checked === true);
				let selectedSettings = libraries.filter(c => c.TYPE === "Settings" && c.UPLOAD_RETRIEVE_STATUS !== "FAILED" && c.checked === true);
				let selectedReadyRecipes = libraries.filter(c => c.TYPE === 'ReadyRecipe' && c.UPLOAD_RETRIEVE_STATUS !== "FAILED" && c.checked === true)
				let selectedLegacy = libraries.filter(c => c.TYPE === "Legacy" && c.UPLOAD_RETRIEVE_STATUS !== "FAILED" && c.checked === true);
				let failedLibraries = libraries.filter(c => c.UPLOAD_RETRIEVE_STATUS === "FAILED" && c.checked === true);
				this.isSelectAssignActiveDelete(selectedUnits, selectedCookbook, selectedSettings, selectedLegacy, selectedReadyRecipes, selectedRecipes, failedLibraries);

				this.setState({
					data: libraries,
					isSelectAllLibrary: e.target.checked,
					isIndeterminateSelect: false,
					cookbookCount: selectedCookbook.length,
					recipeCount: selectedRecipes.length + selectedReadyRecipes.length,
					settingsCount: selectedSettings.length,
					readyRecipeCount: selectedReadyRecipes.length
				});
			}
			else {
				this.openErrorModal("KC1174");
			}
		}
	}

	getArrayDifferences(result1, result2) {
		//Find values that are in result2 but not in result1
		var uniqueResultTwo = result2.filter(function (obj) {
			return !result1.some(function (obj2) {
				return obj.ID == obj2.ID;
			});
		});

		return uniqueResultTwo;
	}

	setArrayConsolidated(result1, result2, checked) {
		if (checked) {
			//Find values that are in result2 but not in result1
			var uniqueResultTwo = result2.filter((obj) => !result1.some((obj2) => obj.cust_gp_id == obj2.cust_gp_id));

			//Combine the two arrays of unique entries
			var result = result1.concat(uniqueResultTwo);
			localStorage.setItem("selected_cgid", JSON.stringify(result));
		}
		else {
			let selected_cgid = JSON.parse(localStorage.getItem("selected_cgid"));
			result2.forEach((obj) => {
				let index = selected_cgid.findIndex((i) => i.cust_gp_id === obj.cust_gp_id);
				selected_cgid.splice(index, 1);
			});
			localStorage.setItem("selected_cgid", JSON.stringify(selected_cgid));
		}
	}

	onSelectLibrary = (e, item) => {
		const { data } = this.state;

		if (data && data.length > 0) {
			const libraries = [...data];
			const selectedBrands = libraries.filter(c => c.checked === true);
			let selectedbrand = selectedBrands.length > 0 ? selectedBrands[0].BRAND_NAME : "";
			let selectedFamily = selectedBrands.length > 0 ? selectedBrands[0].MODEL_FAMILY : [];
			let currentFamily = selectedBrands.length > 0 ? item.MODEL_FAMILY : [];
			let difference = [];
			if (currentFamily.length > selectedFamily.length) {
				difference = this.getArrayDifferences(currentFamily, selectedFamily);
			}
			else {
				difference = this.getArrayDifferences(selectedFamily, currentFamily);
			}
			let isValid = selectedBrands.length > 0 && difference.length === 0 && selectedbrand === item.BRAND_NAME ? true : selectedBrands.length === 0 ? true : false;
			if (isValid || !e.target.checked) {
				let selected_cgid = localStorage.getItem("selected_cgid") ? JSON.parse(localStorage.getItem("selected_cgid")) : [];
				let current_cgid = item.ASSIGNED_CUSTOMER_GROUP ? item.ASSIGNED_CUSTOMER_GROUP : [];
				this.setArrayConsolidated(selected_cgid, current_cgid, e.target.checked);

				const index = libraries.indexOf(item);
				libraries[index] = { ...libraries[index] };
				libraries[index].checked = e.target.checked;

				let selectedUnits = libraries.filter(c => c.checked === true && c.UPLOAD_RETRIEVE_STATUS !== "FAILED");
				let selectedCookbook = libraries.filter(c => c.TYPE === "CookBook" && c.UPLOAD_RETRIEVE_STATUS !== "FAILED" && c.checked === true);
				let selectedRecipes = libraries.filter(c => c.TYPE === "Recipe" && c.UPLOAD_RETRIEVE_STATUS !== "FAILED" && c.checked === true);
				let selectedReadyRecipes = libraries.filter(c => c.TYPE === "ReadyRecipe" && c.UPLOAD_RETRIEVE_STATUS !== "FAILED" && c.checked === true);
				let selectedSettings = libraries.filter(c => c.TYPE === "Settings" && c.UPLOAD_RETRIEVE_STATUS !== "FAILED" && c.checked === true);
				let selectedLegacy = libraries.filter(c => c.TYPE === "Legacy" && c.UPLOAD_RETRIEVE_STATUS !== "FAILED" && c.checked === true);
				let failedLibraries = libraries.filter(c => c.UPLOAD_RETRIEVE_STATUS === "FAILED" && c.checked === true);
				this.isSelectAssignActiveDelete(selectedUnits, selectedCookbook, selectedSettings, selectedLegacy, selectedReadyRecipes, selectedRecipes, failedLibraries);

				if (selectedUnits.length === 1) {
					this.setState({
						selected_item: selectedUnits[0]
					});
				}

				function getCheckBoxstate() {
					if (selectedUnits.length == libraries.length) {
						return { isSelectAllLibrary: true, isIndeterminateSelect: false };
					} else if (selectedUnits.length == 0 && libraries.length != 0) {
						return { isSelectAllLibrary: false, isIndeterminateSelect: false };
					} else if (libraries.length >= selectedUnits.length) {
						return { isSelectAllLibrary: true, isIndeterminateSelect: true };
					}
				}

				this.setState({
					data: libraries,
					cookbookCount: selectedCookbook.length,
					recipeCount: selectedRecipes.length + selectedReadyRecipes.length,
					settingsCount: selectedSettings.length,
					legacyCount: selectedLegacy.length,
					readyRecipeCount: selectedReadyRecipes.length,
					...getCheckBoxstate()
				});
			}
		}
		else {
			localStorage.setItem("selected_cgid", "[]");
		}
	}

	isSelectAssignActiveDelete = (selectedUnits, selectedCookbook, selectedSettings, selectedLegacy, selectedReadyRecipes, selectedRecipes, failedLibraries) => {
		let totalfilesize = selectedUnits.reduce((prev, cur) => prev + parseFloat(cur.LIBRARY_FILE_SIZE), 0);
		let isAssign, isEdit, isRetrieve, isUpload, isDownload, isDelete, isCopy = false;

		if (selectedUnits.length === 1 && selectedRecipes.length === 1) {
			isAssign = false;
			isEdit = true;
			isRetrieve = false;
			isUpload = false;
			isDownload = false;
			isDelete = true;
			isCopy = true;
		} else if (selectedReadyRecipes.length > 0 || selectedRecipes.length > 0 || failedLibraries.length > 0) {
			isAssign = false;
			isEdit = false;
			isRetrieve = false;
			isUpload = false;
			isDownload = false;
			isDelete = true;
			isCopy = false;
		} else if (selectedUnits.length === 1 && selectedLegacy.length === 1) {
			isAssign = true;
			isEdit = false;
			isRetrieve = false;
			isUpload = false;
			isDownload = true;
			isDelete = true;
			isCopy = false;
		} else if (selectedUnits.length === 1 && selectedLegacy.length === 0 && selectedReadyRecipes.length === 0) {
			isAssign = true;
			isEdit = true;
			isRetrieve = false;
			isUpload = false;
			isDownload = true;
			isDelete = true;
			isCopy = true;
		} else if ((selectedUnits.length > 1 && selectedCookbook.length > 1) || (selectedUnits.length > 1 && selectedSettings.length > 1)) {
			isAssign = false;
			isEdit = false;
			isRetrieve = false;
			isUpload = false;
			isDownload = false;
			isDelete = true;
			isCopy = false;
		} else if ((selectedUnits.length > 1 && selectedCookbook.length < 2 && selectedLegacy.length === 0) || (selectedUnits.length > 1 && selectedSettings.length < 2 && selectedLegacy.length === 0)) {
			isAssign = true;
			isEdit = false;
			isRetrieve = false;
			isUpload = false;
			isDownload = true;
			isDelete = true;
			isCopy = false;
		} else {
			isAssign = false;
			isEdit = false;
			isRetrieve = true;
			isUpload = true;
			isDownload = false;
			isDelete = false;
			isCopy = false;
		}

		this.setState({
			isAssign,
			isEdit,
			isRetrieve,
			isUpload,
			isDownload,
			isDelete,
			isCopy,
			totalfilesize
		});
	}

	onUploadNameChange = (e) => {
		if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
			this.setState({
				upload_filename: e.target.value,
				errorUploadFileName: ""
			});
		}
	}

	OnAssignLibrary = () => {
		const { data } = this.state;

		let selecteddata = data.filter(c => c.checked == true);
		if (selecteddata) {
			let item = selecteddata[0];
			localStorage.setItem("selectedFamilyID", item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.ID).toString() : '');
			let libraryIds = selecteddata.map(a => a.LIBRARY_ID);
			const types = [...new Set(selecteddata.map(a => a.TYPE))];
			let type = "";
			if (types.length > 1) {
				type = "Package";
			}
			else if (types.length === 1) {
				type = types[0];
			}

			this.props.history.push({
				pathname: "/libraryScheduleUpdateNow",
				libraryIds: libraryIds,
				libraryType: type,
				previouspath: "/libraryList"
			});
		}
	}

	async setMerrychefSettings(libraryFiles, type, b64) {
		let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);

		let libraryDetails = {};
		libraryDetails.id = UpdatedJSONObj.id;
		let library_filename = libraryFiles[0].fileName.substring(0, libraryFiles[0].fileName.lastIndexOf('_'));
		libraryDetails.library_id = (library_filename + '_' + Date.now()).replace(/\s+/g, '');
		libraryDetails.settingName = this.state.upload_filename;
		libraryDetails.crc = md5(b64, "base64");
		libraryDetails.isEdit = false;
		libraryDetails.day = UpdatedJSONObj.scheduler && UpdatedJSONObj.scheduler.length > 0 ? UpdatedJSONObj.scheduler[0].day : "Monday";
		libraryDetails.initialMethod = "Upload";
		libraryDetails.fileType = type;

		let librarySettings = [];
		let libraryData = JSON.parse(localStorage.getItem("libraryData"));
		let resourceFiles = libraryFiles.filter(lib => lib.fileName !== libraryFiles[0].fileName);

		await resourceFiles.map(async (item, _n) => {
			let fileData = null;
			if (IMAGE_FILE_FORMAT === "wav") {
				fileData = item.fileBuffer.toString('base64');
			}
			else {
				fileData = Buffer.from(item.fileBuffer);
			}
			let imageextSplit = item.fileName.split('.');
			let LIBRARY_NAME = imageextSplit[0].split('_')[0];
			let library_id = (LIBRARY_NAME + '_' + Date.now()).replace(/\s+/g, '');
			let IMAGE_FILE_FORMAT = imageextSplit[1];
			let fileSize = Buffer.byteLength(item.fileBuffer);
			let IMAGE_FILE_SIZE = (fileSize / 1048576).toFixed(2);
			let imagefilekey = library_id + '.' + IMAGE_FILE_FORMAT;
			if (IMAGE_FILE_FORMAT !== "json" && IMAGE_FILE_FORMAT !== "JSON") {
				let headerpresigned = {
					'Content-Type': 'application/json',
					'customerid': CUSTOMER_ID,
					'imagefilekey': IMAGE_FILE_FORMAT !== "wav" ? imagefilekey : "",
					'jsonfilekey': "",
					'audiofilekey': IMAGE_FILE_FORMAT === "wav" ? imagefilekey : "",
					'isthumbnail': false
				}
				let token = localStorage.getItem('Access-Token');
				headerpresigned.Authorization = token;
				await axios({
					url: GET_LIBRARY_PRESIGNED_URL,
					method: "GET",
					headers: headerpresigned,
					data: {}
				}).then(response => {
					let { imagePresignedURL, audioPresignedUrl } = response.data.presignedURLs
					let imageurl;
					if (IMAGE_FILE_FORMAT === "wav") {
						imageurl = audioPresignedUrl;
					}
					else {
						imageurl = imagePresignedURL;
					}
					let UploadedImages3url = "";
					if (imageurl) {
						UploadedImages3url = imageurl.split('?');
						UploadedImages3url = UploadedImages3url[0];
					}

					var xhrimage = new XMLHttpRequest();
					xhrimage.open("PUT", imageurl);
					xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
					xhrimage.onloadend = (res) => {
						if (res.target.status == 200) {
							let settings = {};
							settings.fileFormat = IMAGE_FILE_FORMAT;
							settings.fileName = LIBRARY_NAME + "." + IMAGE_FILE_FORMAT;
							settings.fileSize = IMAGE_FILE_SIZE;
							if (libraryData.sounds) {
								if (libraryData.sounds.end_of_cycle.replace('Resources/Audio/', '').includes(settings.fileName.replace('.wav', ''))) {
									settings.fileType = "end_of_cycle";
								}
								else if (libraryData.sounds.pad_press.replace('Resources/Audio/', '').includes(settings.fileName.replace('.wav', ''))) {
									settings.fileType = "pad_press";
								}
								else if (libraryData.sounds.error.replace('Resources/Audio/', '').includes(settings.fileName.replace('.wav', ''))) {
									settings.fileType = "error";
								}
								else if (libraryData.sounds.door_open.replace('Resources/Audio/', '').includes(settings.fileName.replace('.wav', ''))) {
									settings.fileType = "door_open";
								}
								else {
									settings.fileType = "screensaver";
								}
							}
							settings.isDefault = false;
							settings.s3FileName = library_id + '.' + IMAGE_FILE_FORMAT;
							settings.s3Url = UploadedImages3url;
							settings.type = IMAGE_FILE_FORMAT === "wav" ? "audio" : "image";
							librarySettings.push(settings);
							if (librarySettings && librarySettings.length === resourceFiles.length) {
								libraryDetails.settings = librarySettings;
								libraryData.sounds = librarySettings;
								localStorage.setItem("libraryData", JSON.stringify(libraryData));
								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
								localStorage.setItem("libraryImage", b64);
								this.props.history.push({
									pathname: "/assignSettingsToTopology",
									previouspath: "/libraryList"
								});
							}
						}
					};
					xhrimage.send(fileData);
				})
			}
		})
	}
	//User Story 82509: MRCHEF NRE - Recipe Editor Tab removal and displaying only cookbooks in the Library
	// Recipe editor handler
	OnRecipeEditor = async () => {
		this.props.history.push({
			pathname: "/librarySelectDevice",
			previouspath: "/libraryList"
		})
	}
	onUploadLibraryCheck = async (event) => {
		if (localStorage.getItem("brandName") === "GARLAND") {
			this.props.history.push({
				pathname: "/menuScheduleUpdateNow",
				previouspath: "/libraryList"
			});
		} else {
			this.props.history.push({
				pathname: "/menuLibraryUpload",
				previouspath: "/libraryList"
			});
		}
	}

	// Check the unique field is present or not to identify the correct json file
	uniqueKeyFind = (brand, type) => {
		if (brand === "MERRYCHEF") {
			if (type === 'CookBook') {
				return 'menus'
			} else if (type === 'Settings') {
				return 'mode'
			} else {
				return 'ordered_steps'
			}
		}
		if (brand === "FRYMASTER") {
			if (type === 'CookBook') {
				return 'autoMenuChange'
			} else if (type === 'Settings') {
				return 'general'
			} else {
				return 'conditional_steps'
			}
		}
	}

	handleCbrFileType(event, fileNamewithOutExt, fileext, fileSize) {
		const { files } = event.target;

		var reader = new FileReader();
		reader.readAsDataURL(files[0]);
		reader.onload = async (e) => {
			let fileBuffer = Buffer.from(e.target.result.replace(/^data:application\/octet-stream;base64,/, ""), "base64");
			let library_id = (fileNamewithOutExt + '_' + Date.now()).replace(/\s+/g, '');
			let menufilekey = library_id + '.' + fileext;
			let headerpresigned = {
				'Content-Type': 'application/json',
				'customerid': CUSTOMER_ID,
				'imagefilekey': "",
				'jsonfilekey': "",
				'audiofilekey': "",
				'menufilekey': menufilekey,
				'isthumbnail': false
			};
			let token = localStorage.getItem('Access-Token');
			headerpresigned.Authorization = token;
			await axios({
				url: GET_LIBRARY_PRESIGNED_URL,
				method: "GET",
				headers: headerpresigned,
				data: {}
			}).then(async (response) => {
				let { menuPresignedURL } = response.data.presignedURLs;

				let UploadedMenu3url = "";
				if (menuPresignedURL) {
					UploadedMenu3url = menuPresignedURL.split('?');
					UploadedMenu3url = UploadedMenu3url[0];
				}

				var xhrimage = new XMLHttpRequest();
				xhrimage.open("PUT", menuPresignedURL);
				xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
				xhrimage.onloadend = async (res) => {
					if (await res.target.status == 200) {
						let fileData = {};
						fileData.fileS3Url = UploadedMenu3url;
						fileData.libraryId = library_id;
						fileData.crc = md5(fileBuffer, "base64");
						// return fileData;
						let libraryDetails = {};
						libraryDetails.fileName = fileNamewithOutExt;
						libraryDetails.fileFormat = fileext;
						libraryDetails.fileSize = fileSize;
						libraryDetails.fileS3Url = fileData.fileS3Url;
						libraryDetails.crc = fileData.crc;
						libraryDetails.libraryId = fileData.libraryId;
						libraryDetails.fileType = "Legacy";
						localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
						this.props.history.push({
							pathname: "/UploadSelectDevice",
							previouspath: "/libraryList"
						});
					}
				};
				xhrimage.send(fileBuffer);
			});
		};
	}

	// Check the json is valid or not
	isValidJson(json) {
		try {
			JSON.parse(json);
		} catch (e) {
			return false;
		}
		return true;
	}

	checkJSONExist = async (json) => {
		let jsonExist = false;
		await json.forEach((jsonObj) => {
			if (jsonObj.entryName.includes('json') && !jsonObj.entryName.includes('/')) {
				jsonExist = true;
			}
		})
		return jsonExist;
	}

	uploadImageForThumbnailBuffer = async (imageBuffer, LIBRARY_NAME, IMAGE_FILE_FORMAT, url) => {
		return new Promise((resolve, reject) => {
			Jimp.read(imageBuffer)
				.then(image => {
					image.resize(80, 80, (err, image) => {
						image.getBufferAsync('image/png').then(thumbnailbuffer => {
							// let imageextSplit = LIBRARY_NAME.split('.');
							// LIBRARY_NAME = imageextSplit[0];
							let library_id = (LIBRARY_NAME.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_') + '_' + Date.now()).replace(/\s+/g, '');
							let imagefilekey = library_id + '.' + IMAGE_FILE_FORMAT;
							let headerpresigned = {
								'Content-Type': 'application/json',
								'customerid': CUSTOMER_ID,
								'imagefilekey': imagefilekey,
								'jsonfilekey': "",
								'audiofilekey': "",
								'isthumbnail': true
							};
							let token = localStorage.getItem('Access-Token');
							headerpresigned.Authorization = token;
							axios({
								url,
								method: "GET",
								headers: headerpresigned,
								data: {}
							}).then(response => {

								let { imagePresignedURL, thumbnailPresignedURL } = response.data.presignedURLs;
								let imageurl = imagePresignedURL;
								let thumbnailurl = thumbnailPresignedURL;

								let UploadedImages3url = "";
								if (imageurl) {
									UploadedImages3url = imageurl.split('?');
									UploadedImages3url = UploadedImages3url[0];
								}

								var xhrthumb = new XMLHttpRequest();
								xhrthumb.open("PUT", thumbnailurl);
								xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
								xhrthumb.onloadend = async (res) => {
									if (await res.target.status == 200) {
										var xhrimage = new XMLHttpRequest();
										xhrimage.open("PUT", imageurl);
										xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
										xhrimage.onloadend = (res) => {
											if (res.target.status == 200) {
												resolve({
													imagefilekey, thumbnailbuffer, image, imageurl, thumbnailurl, UploadedImages3url, library_id
												})
											}
										};
										xhrimage.send(imageBuffer);
									}
								};
								xhrthumb.send(thumbnailbuffer);
							});
						});
					});
				});
		})
	}

	uploadFrymasterImageForThumbnailBuffer = async (imageBuffer, LIBRARY_NAME, IMAGE_FILE_FORMAT, url) => {
		return new Promise((resolve, _) => {
			Jimp.read(imageBuffer)
				.then(image => {
					image.resize(80, 80, (err, image) => {
						image.getBufferAsync('image/png').then(thumbnailbuffer => {
							let imageextSplit = LIBRARY_NAME.split('.');
							LIBRARY_NAME = imageextSplit[0];
							let library_id = (LIBRARY_NAME + '_' + Date.now()).replace(/\s+/g, '');
							let imagefilekey = library_id + '.' + IMAGE_FILE_FORMAT;
							let headerpresigned = {
								'Content-Type': 'application/json',
								'customerid': CUSTOMER_ID,
								'imagefilekey': imagefilekey,
								'jsonfilekey': "",
								'audiofilekey': "",
								'isthumbnail': true
							};
							let token = localStorage.getItem('Access-Token');
							headerpresigned.Authorization = token;
							axios({
								url,
								method: "GET",
								headers: headerpresigned,
								data: {}
							}).then(response => {

								let { imagePresignedURL, thumbnailPresignedURL } = response.data.presignedURLs;
								let imageurl = imagePresignedURL;
								let thumbnailurl = thumbnailPresignedURL;

								let UploadedImages3url = "";
								if (imageurl) {
									UploadedImages3url = imageurl.split('?');
									UploadedImages3url = UploadedImages3url[0];
								}

								let imageFileSize = Buffer.byteLength(imageBuffer);
								imageFileSize = (imageFileSize / 1048576);

								var xhrthumb = new XMLHttpRequest();
								xhrthumb.open("PUT", thumbnailurl);
								xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
								xhrthumb.onloadend = async (res) => {
									if (await res.target.status == 200) {
										var xhrimage = new XMLHttpRequest();
										xhrimage.open("PUT", imageurl);
										xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
										xhrimage.onloadend = (res) => {
											if (res.target.status == 200) {
												let jsonRecipeFile = localStorage.getItem("libraryData");
												let ObjRecipe = JSON.parse(jsonRecipeFile);
												ObjRecipe.image = "Resources/Images/" + LIBRARY_NAME;
												localStorage.setItem("libraryData", JSON.stringify(ObjRecipe));

												let resourceDetails = localStorage.getItem("resourceDetails");
												let resourceDetailsObj = JSON.parse(resourceDetails);
												let resource = {};
												resource.fileName = LIBRARY_NAME;
												resource.fileFormat = IMAGE_FILE_FORMAT;
												resource.fileSize = imageFileSize;
												resource.s3Url = UploadedImages3url;
												resource.imageurl = imageurl;
												resource.s3FileName = imagefilekey;
												resource.imageData = imageBuffer.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
												resource.thumbnailData = thumbnailbuffer.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
												resource.type = "image";
												resource.isDefault = false;
												resourceDetailsObj = [];
												resourceDetailsObj.push(resource);
												localStorage.setItem("resourceDetails", JSON.stringify(resourceDetailsObj));
												resolve({
													resourceDetails
												})
											}
										};
										xhrimage.send(imageBuffer);
									}
								};
								xhrthumb.send(thumbnailbuffer);
							});
						});
					});
				});
		})
	}

	getrecipeDetails = async (recipe, imageBuffer, data, imageFileFormat, imageFileName, imageFileSize) => {
		return new Promise(async (resolve, reject) => {
			let brandName = localStorage.getItem("brandName");
			let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
			let loggedinUser = loginUser.username;
			let LIBRARY_NAME = recipe.name.en_GB;

			let imagefilekey = imageFileName;
			let imageurl = "";
			let library_id = "";
			let UploadedImages3url = "";

			if (imageBuffer) {
				({ imagefilekey, imageurl, library_id, UploadedImages3url } = await this.uploadImageForThumbnailBuffer(imageBuffer, LIBRARY_NAME, imageFileFormat.replace('.', ''), `${GET_LIBRARY_PRESIGNED_URL}`));
			}
			else {
				library_id = (LIBRARY_NAME + '_' + Date.now()).replace(/\s+/g, '');
			}

			recipe.image = "Resources/Images/" + imagefilekey;
			// convert JSON object to beautify String
			var jsonStr = beautify(recipe, null, 2, 80)
			// read json string to Buffer
			const jsonBuffer = Buffer.from(jsonStr);

			let jsonfileSize = Buffer.byteLength(jsonBuffer);
			jsonfileSize = (jsonfileSize / 1048576);

			let libraryrecipe = data.find(d => d.LIBRARY_GUID === recipe.id);
			let libraryID = libraryrecipe && libraryrecipe.LIBRARY_ID ? libraryrecipe.LIBRARY_ID : library_id;
			let crc = md5(imageBuffer ? imageBuffer : jsonBuffer, "base64");
			let libraryPostData = {
				"customerId": CUSTOMER_ID,
				"brandId": BRAND_ID_VALUE[brandName],
				"modelFamilyIds": libraryrecipe && libraryrecipe.MODEL_FAMILY ? Array.prototype.map.call(libraryrecipe.MODEL_FAMILY, m => m.ID).toString() : localStorage.getItem("selectedFamilyID"),
				"libraryId": libraryID,
				"libraryName": LIBRARY_NAME,
				"libraryFileName": libraryID + ".json",
				"libraryFileFormat": "json",
				"libraryFileSize": (jsonfileSize + parseInt(imageFileSize)).toFixed(2),
				"libraryS3Url": "",
				"type": "Recipe",
				"createdUser": ' (' + loggedinUser + ')',
				"crc": crc,
				"baseCountry": BASECOUNTRY_ID,
				"imageFileName": imageFileFormat ? libraryID + '.' + imageFileFormat.replace('.', '') : '',
				"imageFileFormat": imageFileFormat,
				"imageFileSize": imageFileSize,
				"imageS3Url": UploadedImages3url,
				"cgids": libraryrecipe && libraryrecipe.ASSIGNED_CUSTOMER_GROUP ? Array.prototype.map.call(libraryrecipe.ASSIGNED_CUSTOMER_GROUP, m => m.cust_gp_id) : [parseInt(localStorage.getItem("custGroupID"))],
				"json": JSON.stringify(recipe),
				"libraryGuid": recipe.id
			};

			let recipeDetails = {};
			let httpMethod = "";
			if (libraryrecipe) {
				// if exists update
				httpMethod = "PUT";
				delete libraryPostData.customerId;
				delete libraryPostData.createdUser;
				libraryPostData.updatedUser = ' (' + loggedinUser + ')';
				recipeDetails = {
					"LIBRARY_GUID": recipe.id,
					"LIBRARY_ID": libraryID,
					"IMAGE_S3_SIGNED_URL": imageurl,
					"LIBRARY_NAME": LIBRARY_NAME,
					"checked": false
				}
			}
			else {
				// else Insert
				libraryPostData.status = "DRAFT";
				httpMethod = "POST";
				recipeDetails = {
					"LIBRARY_GUID": recipe.id,
					"LIBRARY_ID": libraryID,
					"IMAGE_S3_SIGNED_URL": imageurl,
					"LIBRARY_NAME": LIBRARY_NAME,
					"checked": false
				}
			}

			let headerpresigned = {
				'Content-Type': 'application/json',
				'customerid': CUSTOMER_ID,
				'imagefilekey': '',
				'jsonfilekey': library_id + '.json',
				'audiofilekey': '',
				'isthumbnail': false
			}

			await uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
				resolve({ recipeDetails });
			}).catch(err => {
				reject(err);
			});
		})
	}

	OnUploadLibrary = async () => {
		let { data, selectedFile: files, uploadOption, actionType, upload_filename } = this.state;

		if (actionType === "Upload") {
			if (upload_filename.length < 1 || upload_filename.length > 20) {
				this.setState({
					errorUploadFileName: "KC2107"
				})
			}
			else {
				if (files && files[0]) {
					this.setState({
						loaderIconVisible: true
					});

					let fileName = files[0].name;
					fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
					let fileextSplit = fileName.split('.');
					let fileext = fileextSplit[1];

					if (fileext === "zip" || fileext === "ZIP") {
						var libraryFiles = [];
						var b64 = "";
						var imageBuffer = null;
						let type = uploadOption;
						let LIBRARY_NAME = "";
						let IMAGE_FILE_FORMAT = "";
						let IMAGE_FILE_SIZE = "0";
						try {
							var reader = new FileReader();
							reader.readAsDataURL(files[0]);
							reader.onload = async (e) => {
								let fileBuffer = Buffer.from(e.target.result.replace(/^data:application\/x-zip-compressed;base64,/, "").replace(/^data:application\/zip;base64,/, ""), "base64");
								var zip = new AdmZip(fileBuffer);
								var zipEntries = zip.getEntries();

								var validEntries = [];

								let validzipEntries = zipEntries.filter(z => z.entryName.charAt(z.entryName.length - 1) !== "/");
								// Identify the correct file and elimante the extra files if present in zip file
								validzipEntries.forEach((entry) => {
									// No Manifest file is required to push
									if (!entry.entryName.includes('manifest')) {
										// Push resource file
										if (entry.entryName.includes('Resources')) {
											validEntries.push(entry);
										}
										// If the file is json, then need to validate and find the correct file
										if (entry.entryName.includes('json')) {
											const file = zip.readFile(entry);
											const fileJSONObj = JSON.parse(file);
											// JSON validation
											if (this.isValidJson(JSON.stringify(fileJSONObj))) {
												// Get unique key for the selected type and brand
												const uniqueField = this.uniqueKeyFind(localStorage.getItem("brandName"), type);
												// Check if the unique key is present in the JSON
												if (fileJSONObj[uniqueField]) {
													validEntries.push(entry);
												}
											}

										}
									}
								})

								const checkIsJsonExist = await this.checkJSONExist(validEntries);


								if (!checkIsJsonExist) {
									this.openErrorModal("KC1177");
									this.OnCancelLibrary();
									this.inputElement.value = "";
									return;
								}

								validEntries.forEach((entry, i) => {
									libraryFiles[i] = {};
									if (type === "CookBook") {
										if (!entry.entryName.includes('manifest')) {
											libraryFiles[i].fileName = entry.entryName.replace('Resources/Images/', '');
											imageBuffer = zip.readFile(entry);
											libraryFiles[i].fileBuffer = imageBuffer;
											b64 = imageBuffer.toString('base64');
											if (entry.entryName.includes('.json')) {
												libraryFiles[i].fileType = "JSON";
											}
											else {
												libraryFiles[i].fileType = "OTHERS";
											}
										}
									}
									else if (type === "Settings" && localStorage.getItem("brandName") === "MERRYCHEF") {
										if (!entry.entryName.includes('manifest')) {
											libraryFiles[i] = {};
											libraryFiles[i].fileName = entry.entryName.replace('Resources/Images/', '').replace('Resources/Audio/', '');
											imageBuffer = zip.readFile(entry);
											if (entry.entryName.includes('.json')) {
												libraryFiles[i].fileType = "JSON";
											} else if (!entry.entryName.includes('.wav')) {
												b64 = imageBuffer.toString('base64');
												libraryFiles[i].fileType = "OTHERS";
											} else {
												libraryFiles[i].fileType = "OTHERS";
											}
											libraryFiles[i].fileBuffer = imageBuffer;
										}
									}
									else {
										if (!entry.entryName.includes('manifest')) {
											libraryFiles[i].fileName = entry.entryName.replace('Resources/Images/', '').replace('Resources/Audio/', '');
											libraryFiles[i].fileBuffer = zip.readFile(entry);
											if (entry.entryName.includes('.json')) {
												libraryFiles[i].fileType = "JSON";
											}
											else {
												libraryFiles[i].fileType = "OTHERS";
											}
										}
										if (!entry.entryName.includes('.json')) {
											imageBuffer = Buffer.from(libraryFiles[i].fileBuffer);
											b64 = imageBuffer.toString('base64');
										}
									}
								});

								if (libraryFiles.length > 0) {
									libraryFiles = libraryFiles.sort((a, b) => a.fileType !== b.fileType ? a.fileType < b.fileType ? -1 : 1 : 0);
								}

								if (localStorage.getItem("brandName") === "MERRYCHEF") {
									localStorage.setItem("selectedFamilyID", "1,2,40,41");
									localStorage.setItem("selectedbrandID", 1);
									localStorage.setItem("brandName", "MERRYCHEF");

									this.props.history.push({
										pathname: "/libraryUploadTopology",
										previouspath: "/libraryList",
										srcfile: files,
										uploadOption: uploadOption,
										upload_filename: upload_filename
									});
								}
								else if (localStorage.getItem("brandName") === "FRYMASTER") {
									if (libraryFiles[0] && type === "Recipe") {
										({ LIBRARY_NAME, IMAGE_FILE_FORMAT, IMAGE_FILE_SIZE } = await this.handleFrymasterRecipe(libraryFiles, LIBRARY_NAME, IMAGE_FILE_FORMAT, imageBuffer, IMAGE_FILE_SIZE, b64, type, data));
									}
									else if (libraryFiles[0] && type === "CookBook") {
										let md5;
										({ md5, LIBRARY_NAME, IMAGE_FILE_FORMAT, IMAGE_FILE_SIZE } = this.handleFrymasterCookbook(libraryFiles, LIBRARY_NAME, IMAGE_FILE_FORMAT, imageBuffer, IMAGE_FILE_SIZE, b64, type, data));
									}
									else {
										//Settings
										({ LIBRARY_NAME, IMAGE_FILE_FORMAT, IMAGE_FILE_SIZE } = this.handleFrymasterSettings(libraryFiles, LIBRARY_NAME, IMAGE_FILE_FORMAT, IMAGE_FILE_SIZE, b64, type));
									}
								}
							}
						}
						catch (ex) {
							this.openErrorModal("KC1178");
						}
					}
					else {
						this.openErrorModal("KC1179");
					}
				}
			}
		}
		else {
			this.props.history.push({
				pathname: "/libraryAssignUpdateList",
				previouspath: "/libraryList",
				retrieveType: actionType,
				libraryType: uploadOption
			});
		}
	}

	setMerrychefCookbook = async (UpdatedJSONObj, menus, libraryFiles, data) => {
		let categoryDetailsObj = [];
		let recipeDetailsObj = [];
		let pressAndGoItems = [];
		for (let m = 0; m < UpdatedJSONObj.recipes.length; m++) {
			const { imageData, imageFileFormat, imageFileName, imageFileSize } = await this.getMerrychefImageDetails(libraryFiles, UpdatedJSONObj.recipes[m], null, "", "", "");
			const { recipeDetails } = await this.getrecipeDetails(UpdatedJSONObj.recipes[m], imageData, data, imageFileFormat, imageFileName, imageFileSize);
			recipeDetailsObj.push(recipeDetails);
		}

		pressAndGoItems = UpdatedJSONObj.PressAndGoItems ? UpdatedJSONObj.PressAndGoItems : [];
		if (pressAndGoItems.length > 0) {
			let pressGoObj = {};
			pressGoObj.ID = "Press&Go";
			pressGoObj.categoryName = "Press&Go";
			pressGoObj.imageFileFormat = "";
			pressGoObj.imageFileName = "";
			pressGoObj.imageFileSize = "";
			pressGoObj.imageS3Url = "";
			pressGoObj.parentID = "";
			pressGoObj.selected = false;
			pressGoObj.thumbnailData = null;
			pressGoObj.type = "Category";
			pressGoObj.recipe_items = [];
			for (let p = 0; p < pressAndGoItems.length; p++) {
				const recipeDetails = recipeDetailsObj.find(r => r.LIBRARY_GUID === pressAndGoItems[p]);
				pressGoObj.recipe_items[p] = recipeDetails ? recipeDetails : {};
			}
			categoryDetailsObj.push(pressGoObj);
		}

		for (let i = 0; i < menus.length; i++) {
			const item = menus[i];
			let category = {};
			category.ID = item.id;

			let imageData = null;
			let imageFileFormat = "";
			let imageFileName = "";
			let imageFileSize = "";

			category.categoryName = item.name ? item.name.en_GB ? item.name.en_GB : "" : "";
			category.imageData = "";
			category.recipe_items = [];
			category.type = "Category";

			if (item.image) {
				({ imageData, imageFileFormat, imageFileName, imageFileSize } = await this.getMerrychefImageDetails(libraryFiles, item, imageData, imageFileFormat, imageFileName, imageFileSize));
				const { imagefilekey, thumbnailbuffer, UploadedImages3url } = await this.uploadImageForThumbnailBuffer(imageData, category.categoryName, imageFileFormat.replace('.', ''), `${GET_LIBRARY_PRESIGNED_URL}`);
				category.imageFileName = imagefilekey;
				category.imageFileFormat = imageFileFormat;
				category.imageFileSize = imageFileSize;
				category.imageS3Url = UploadedImages3url ? UploadedImages3url : "";
				category.thumbnailData = thumbnailbuffer.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
			}
			else {
				category.imageFileName = "";
				category.imageFileFormat = "";
				category.imageFileSize = "0.00";
				category.imageS3Url = "";
				category.thumbnailData = null;
			}

			for (let k = 0; k < item.recipe_items.length; k++) {
				const recipeDetails = recipeDetailsObj.find(r => r.LIBRARY_GUID === item.recipe_items[k]);
				category.recipe_items[k] = recipeDetails ? recipeDetails : {};
			}

			category.subCategory = [];

			for (let l = 0; l < item.menu_items.length; l++) {
				const subitem = item.menu_items[l];
				let subCategory = {};
				subCategory.ID = subitem.id;

				let imageData = null;
				let imageFileFormat = "";
				let imageFileName = "";
				let imageFileSize = "";

				subCategory.categoryName = subitem.name ? subitem.name.en_GB ? subitem.name.en_GB : "" : "";
				subCategory.imageData = "";
				subCategory.recipe_items = [];
				subCategory.type = "SubCategory";

				if (subitem.image) {
					({ imageData, imageFileFormat, imageFileName, imageFileSize } = await this.getMerrychefImageDetails(libraryFiles, subitem, imageData, imageFileFormat, imageFileName, imageFileSize));
					const { imagefilekey, thumbnailbuffer, UploadedImages3url } = await this.uploadImageForThumbnailBuffer(imageData, subCategory.categoryName, imageFileFormat.replace('.', ''), `${GET_LIBRARY_PRESIGNED_URL}`);
					subCategory.imageFileName = imagefilekey;
					subCategory.imageFileFormat = imageFileFormat;
					subCategory.imageFileSize = imageFileSize;
					subCategory.imageS3Url = UploadedImages3url ? UploadedImages3url : "";
					subCategory.thumbnailData = thumbnailbuffer.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
				}
				else {
					subCategory.imageFileName = "";
					subCategory.imageFileFormat = "";
					subCategory.imageFileSize = "";
					subCategory.imageS3Url = "";
					subCategory.thumbnailData = null;
				}

				for (let n = 0; n < subitem.recipe_items.length; n++) {
					const recipeDetails = recipeDetailsObj.find(r => r.LIBRARY_GUID === subitem.recipe_items[n]);
					subCategory.recipe_items[n] = recipeDetails ? recipeDetails : {};
				}

				category.subCategory.push(subCategory);
			}
			categoryDetailsObj.push(category);
		}
		localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
	}

	OnRetrieveLibrary = () => {
		let action_type = "Retrieve";
		this.setState({
			actionType: action_type
		})
		localStorage.setItem("selectedFamilyID", "");
		this.props.history.push({
			pathname: "/fileRetrievalList",
			previouspath: "/libraryList",
			retrieveType: action_type,
			libraryType: this.state.uploadOption
		});
		//$('#ConfirmationOfImportModal').modal('show');
	}

	OnDownloadLibrary = () => {
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {
			let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
			let loggedinUser = loginUser.username;
			let selectedLibraryIds = this.getSelectedIds('LIBRARY_ID');
			let headerpresigned = {
				'Content-Type': 'application/json',
				'library_id': encodeURIComponent(selectedLibraryIds),
				'library_isedit': "false",
				'username': loggedinUser,
				'useremail': loggedinUser,
				'encryption': "true"
			}
			if (localStorage.getItem("brandName") !== "MERRYCHEF") {
				delete headerpresigned.encryption;
				headerpresigned.library_id = selectedLibraryIds;
			}
			http.get(MenuUploadAPI.getlibraryDownloadUrl, {
				headers: headerpresigned,
				data: {}
			}).then(async response => {
				if (localStorage.getItem('brandName') === "GARLAND") {
					if (response && response.data) {
						let file_path = response.data.presignedURL;
						resolve(response.data);
						fetch(file_path)
							.then(response => response.blob())
							.then(blob => {
								const link = document.createElement("a");
								link.href = URL.createObjectURL(blob);
								link.download = "recipes.db";
								link.click();
							})
							.catch(() => {
								this.setState({
									loaderIconVisible: false,
									error_modal: {
										open: true,
										message: 'Download Failed.'
									}
								})
							});
						$(".loadingProgress").removeAttr("style");
						this.setState({
							loaderIconVisible: false
						});
					}
				} else {
					if (response && response.data) {
						resolve(response.data);
						let file_path = response.data.presignedURL;
						var a = document.createElement('A');
						a.href = file_path;
						a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
						document.body.appendChild(a);
						a.click();
						document.body.removeChild(a);
						$(".loadingProgress").removeAttr("style");
						this.setState({
							loaderIconVisible: false
						});
					}
				}
			}).catch(err => {
				this.setState({
					loaderIconVisible: false
				});
			});
		});
	}


	onModelSelection = (item) => {
		localStorage.setItem("selectedFamilyID", item.MODEL_FAMILY_ID.toString());
		localStorage.setItem("selectedbrandID", item.BRAND_ID);
		localStorage.setItem("brandName", item.BRAND_ID === 1 ? "MERRYCHEF" : item.BRAND_ID === 3 ? "FRYMASTER" : item.BRAND_ID === 9 ? "GARLAND" : "CONVOTHERM");
		let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
		if (libraryDetails.fileType === "Legacy") {
			if (item.MODEL_FAMILY_ID === 1 || item.MODEL_FAMILY_ID === 2) {
				this.setState({
					error_modal: {
						open: true,
						message: 'KC2063'
					}
				})
			}
			else {
				this.props.history.push(`/assignLegacyMenuToTopology`);
			}
		}
		else if (item.BRAND_ID === 3) {
			if (libraryDetails.fileType === "Recipe") {
				this.props.history.push(`/assignFrymasterRecipeToTopology`);
			}
			else if (libraryDetails.fileType === "CookBook") {
				this.props.history.push(`/assignFrymasterMenuToTopology`);
			}
			else {
				this.props.history.push(`/assignFrymasterSettingsToTopology`);
			}
		}
		else {
			if (libraryDetails.fileType === "Recipe") {
				this.props.history.push(`/assignLibraryToTopology`);
			}
			else if (libraryDetails.fileType === "CookBook") {
				this.props.history.push(`/assignCookbookToTopology`);
			}
			else {
				this.props.history.push(`/assignSettingsToTopology`);
			}
		}
	}

	onCopyModal = async () => {
		await localStorage.setItem('copyLibrary', JSON.stringify(this.state.selected_item));
		this.props.history.push('/copyLibrary')
	}

	getRecipeByID = (id) => {
		const { allMenuRecipes } = this.state
		return allMenuRecipes.filter(z => z.LIBRARY_GUID === id);
	}

	c2f = (celsius) => {
		return Math.round((1.8 * celsius) + 32);
	}

	c2fGrill = (celsius) => {
		return Math.round((1.8 * celsius));
	}

	OnEditLibrary = async () => {
		this.setState({
			loaderIconVisible: true
		});
		let { data, getLibraryDetails, selected_item } = this.state;
		let fetchAvailableRecipes = await this.getRecipeList(selected_item);

		if (data && getLibraryDetails) {
			if (data.length != getLibraryDetails.length) {
				let difference = this.getArrayDifferences(data, getLibraryDetails);
				if (difference && difference.length > 0) {
					data.splice(0, data.length, ...getLibraryDetails);
				}
			}
		}

		localStorage.setItem("selectedbrandID", selected_item.BRAND_ID);
		localStorage.setItem("brandName", selected_item.BRAND_NAME);

		let headerpresigned = {
			'Content-Type': 'application/json',
			'library_id': encodeURIComponent(selected_item.LIBRARY_ID),
			'library_isedit': true,
			'encryption': "true"
		}
		if (localStorage.getItem("brandName") !== "MERRYCHEF") {
			delete headerpresigned.encryption;
			headerpresigned.library_id = selected_item.LIBRARY_ID;
		}
		await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
			headers: headerpresigned,
			data: {}
		}).then(response => {
			if (response && response.data) {
				let file_path = response.data.presignedURL;
				console.log("file_path", file_path)
				var libraryFiles = [];
				var b64 = "";
				var imageBuffer = null;
				localStorage.setItem("selectedGroupID", selected_item.ASSIGNED_CUSTOMER_GROUP ? Array.prototype.map.call(selected_item.ASSIGNED_CUSTOMER_GROUP, m => m.cust_gp_id).toString() : 0);
				try {
					request.get({ url: file_path, encoding: null }, async (err, res, body) => {
						var zip = new AdmZip(body);
						var zipEntries = zip.getEntries();
						let UpdatedJSONObj = "";
						console.log("zip", zip)
						console.log("zipEntries", zipEntries)

						let validzipEntries = zipEntries.filter(z => z.entryName.charAt(z.entryName.length - 1) !== "/");
						console.log("validzipEntries", validzipEntries)

						validzipEntries.forEach((entry, i) => {
							if (selected_item.TYPE === "CookBook" || (localStorage.getItem("brandName") === "MERRYCHEF" && selected_item.TYPE === "Recipe") || (localStorage.getItem("brandName") === "GARLAND" && selected_item.TYPE === "Recipe")) {

								libraryFiles[i] = {};
								libraryFiles[i].fileName = entry.entryName.replace('Resources/Images/', '');
								imageBuffer = zip.readFile(entry);

								if (!entry.entryName.includes('.json')) {
									if (selected_item.TYPE === "Recipe") {
										libraryFiles[i].fileBuffer = imageBuffer;
										if (selected_item.IMAGE_FILE_NAME && UpdatedJSONObj && UpdatedJSONObj.image && selected_item.IMAGE_FILE_NAME === UpdatedJSONObj.image.replace('Resources/Images/', '')) {
											libraryFiles[i].fileType = "RecipeImage";
										}
										else {
											if (localStorage.getItem("brandName") === "GARLAND") {
												// libraryFiles[i].fileBuffer = imageBuffer.toString('base64');
												libraryFiles[i].fileType = "Image";
											} else {
												libraryFiles[i].fileType = "StepImage";
											}
										}
									}
									else {
										libraryFiles[i].fileBuffer = imageBuffer.toString('base64');
										libraryFiles[i].fileType = "Image";
									}
								}
								else {
									libraryFiles[i].fileBuffer = imageBuffer;
									UpdatedJSONObj = JSON.parse(imageBuffer);
									b64 = imageBuffer.toString('base64');
									libraryFiles[i].fileType = "JSON";
								}
							}
							else if (selected_item.TYPE === "Settings" && localStorage.getItem("brandName") === "MERRYCHEF") {
								libraryFiles[i] = zip.readFile(entry);

								if (!entry.entryName.includes('.json') && !entry.entryName.includes('.wav')) {
									imageBuffer = Buffer.from(libraryFiles[i]);
									b64 = imageBuffer.toString('base64');
									let fileScreenSaverExt = entry.entryName.substring(entry.entryName.lastIndexOf('.') + 1);
									localStorage.setItem("libraryScreenSaverExt", fileScreenSaverExt);
								}
							}
							else {
								libraryFiles[i] = zip.readFile(entry);

								if (!entry.entryName.includes('.json')) {
									imageBuffer = Buffer.from(libraryFiles[i]);
									b64 = imageBuffer.toString('base64');
								}
							}
						});

						localStorage.setItem("selectedFamilyID", selected_item.MODEL_FAMILY ? Array.prototype.map.call(selected_item.MODEL_FAMILY, m => m.ID).toString() : '');
						if (selected_item.BRAND_NAME === "MERRYCHEF") {
							localStorage.setItem("MerryChefCookBookFileName", selected_item.LIBRARY_NAME);
							localStorage.setItem("librarySettingsId", selected_item.TYPE === "CookBook" ? selected_item.SETTINGS_LIBRARY_ID : '');
							localStorage.setItem("Selected_cookbook_item", selected_item.TYPE === "CookBook" ? selected_item.LIBRARY_ID : '');
							if (libraryFiles[0] && (selected_item.TYPE === "Recipe" || selected_item.TYPE === "ReadyRecipe" || selected_item.TYPE === "Settings")) {
								let libraryData = [];
								if (selected_item.TYPE === "Recipe" || selected_item.TYPE === "ReadyRecipe") {
									UpdatedJSONObj && UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.map((item, i) => {
										libraryData[i] = {};
										libraryData[i].index = i;
										libraryData[i].temp = item.actions.set_point.value + item.actions.set_point.units.replace('deg', '');
										let total = 0;
										if (item.duration) {
											let duration = item.duration.replace('PT', '');
											let minutes, seconds = 0;
											if (duration.includes('M')) {
												minutes = duration.substr(0, duration.indexOf('M'))
											}
											if (duration.includes('S')) {
												seconds = duration.substr(duration.indexOf('M') + 1, duration.indexOf('S') - 3)
											}
											total = minutes + ':' + seconds;
										}
										libraryData[i].time = total;
										libraryData[i].fan = item.actions.fan;
										libraryData[i].power = item.actions.microwave;
										libraryData[i].paramDelete = '';
										libraryData[i].instruction = (item.actions.prompt && item.actions.prompt.message) ? item.actions.prompt.message.en_GB : '';
										libraryData[i].instructionShowid = '';
										libraryData[i].instructionDelete = '';
										libraryData[i].instructionVisible = '';
										if (item.actions.prompt && item.actions.prompt.image) {
											libraryData[i].instructionImage = item.actions.prompt.image;
											libraryData[i].instructionImageS3Url = "";

											libraryFiles && libraryFiles.map((libraryitem, j) => {
												if (!libraryitem.fileName.toLowerCase().includes('.json')) {
													if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === item.actions.prompt.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/", "")) {
														libraryData[i].instructionImageb64 = libraryitem.fileBuffer.toString('base64');
														let imageSize = Buffer.byteLength(libraryitem.fileBuffer);
														libraryData[i].instructionImageSize = (imageSize / 1048576).toFixed(2);
													}
												}
											});
										}
									})

									let libraryDetails = {};
									libraryDetails.id = UpdatedJSONObj.id;
									libraryDetails.settings = {};
									libraryDetails.settings.portion = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.portion : 1;
									libraryDetails.settings.state = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.state : "ambient";
									libraryDetails.settings.weight = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.weight : 50;
									localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
								}
								else {
									libraryData = JSON.parse(libraryFiles[0]);
									let schedulers = libraryData.scheduler;
									if (!Array.isArray(schedulers)) {
										libraryData.scheduler = []
									}
									else {
										schedulers.map((parent, i) => {
											parent.configurations.map((item, j) => {
												if (item.type !== "Cold") {
													item.id = j + 1;
												}
												else {
													item.id = 12;
												}
											})
										})
									}

									localStorage.setItem("schedulerDetails", JSON.stringify(libraryData.scheduler))
								}
								localStorage.setItem("librarySettingsData", JSON.stringify(libraryData));
								try {
									localStorage.setItem("defaultLibraryData", JSON.stringify(libraryData));
								}
								catch (err) {
									localStorage.setItem("defaultLibraryData", LZString.compress(JSON.stringify(libraryData)));
								}
							}

							if (selected_item.TYPE === "Recipe" || selected_item.TYPE === "ReadyRecipe") {
								//Recipe
								let RecipeImage = libraryFiles.find(l => l.fileType === "RecipeImage");
								imageBuffer = RecipeImage ? RecipeImage.fileBuffer : null;
								if (imageBuffer !== null) {
									Jimp.read(imageBuffer)
										.then(image => {
											image.resize(80, 80, (err, image) => {
												image.getBase64Async('image/png').then(thumbnailb64 => {
													let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
													libraryDetails = libraryDetails ? libraryDetails : {};
													let imageData = {};
													imageData.imagefileName = selected_item.LIBRARY_NAME + "." + selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													imageData.imagefileext = selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													imageData.imagefileNamewithOutExt = selected_item.LIBRARY_NAME;
													imageData.imagefileSize = selected_item.IMAGE_FILE_SIZE;
													imageData.imagefilelength = selected_item.LIBRARY_NAME.length;
													imageData.crc = md5(b64, "base64");
													imageData.imageS3Url = selected_item.IMAGE_S3URL;
													libraryDetails.imageData = imageData;
													libraryDetails.id = selected_item.LIBRARY_GUID;
													libraryDetails.recipeName = selected_item.LIBRARY_NAME;
													libraryDetails.isEdit = true;
													libraryDetails.library_id = selected_item.LIBRARY_ID;
													libraryDetails.isImageChanged = false;
													libraryDetails.initialMethod = "Edit";
													libraryDetails.recipeType = selected_item.TYPE;

													localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
													localStorage.setItem("libraryImage", thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
													this.props.history.push({
														pathname: "/recipeCreation",
														previouspath: "/libraryList"
													});
												});
											})
										})
								} else {
									let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
									libraryDetails = libraryDetails ? libraryDetails : {};
									let imageData = {};
									imageData.imagefileName = "";
									imageData.imagefileext = "";
									imageData.imagefileNamewithOutExt = "";
									imageData.imagefileSize = "";
									imageData.imagefilelength = "";
									imageData.crc = "";
									imageData.imageS3Url = "";
									libraryDetails.imageData = imageData;
									libraryDetails.id = selected_item.LIBRARY_GUID;
									libraryDetails.recipeName = selected_item.LIBRARY_NAME;
									libraryDetails.isEdit = true;
									libraryDetails.library_id = selected_item.LIBRARY_ID;
									libraryDetails.isImageChanged = false;
									libraryDetails.initialMethod = "Edit";
									libraryDetails.recipeType = selected_item.TYPE;

									localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
									this.props.history.push({
										pathname: "/recipeCreation",
										previouspath: "/libraryList"
									});
								}
							}
							else if (selected_item.TYPE === "CookBook") {
								let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
								let allRecipeItems = [];
								let all_recipes = [];
									UpdatedJSONObj.recipes && UpdatedJSONObj.recipes.map((recipeitem, k) => {
										all_recipes = fetchAvailableRecipes && fetchAvailableRecipes.filter(d => d.LIBRARY_ID === recipeitem.LIBRARY_ID);
										if (all_recipes.length > 0) {
											allRecipeItems.push(all_recipes[0]);
										}
									});
								let categoryDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
								let categoryDetailObject = JSON.parse(categoryDetails);
								if (categoryDetailObject.length === 0) {
									localStorage.setItem("allRecipeDetails", LZString.compress(JSON.stringify(allRecipeItems)));
								}
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.cookbookName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.initialMethod = "Edit"

								//CookBook
								let categoryDetailsObj = [];
								UpdatedJSONObj && UpdatedJSONObj.menus && UpdatedJSONObj.menus.map((item, i) => {
									let category = {};
									category.ID = item.id;

									let imageData = null;
									let thumbnailData = null;
									let imageFileFormat = "";
									let imageFileName = "";
									let imageFileSize = "";

									let fileext = item.image.substring(item.image.lastIndexOf('.') + 1);
									let itemFileName = item.image.replace(fileext, "png");

									libraryFiles && libraryFiles.map((libraryitem, j) => {
										if (libraryitem.fileName.includes('tmb_')) {
											if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === itemFileName.replace("Resources/Images/", "tmb_").replace("Resources/", "tmb_").replace("/", "")) {
												thumbnailData = libraryitem.fileBuffer;
											}
										}
										else if (!libraryitem.fileName.toLowerCase().includes('.json')) {
											if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === item.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/", "")) {
												imageData = libraryitem.fileBuffer;
												let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
												let fileextSplit = fileName.split('.');
												let fileExt = fileextSplit[1];
												imageFileFormat = "." + fileExt;
												imageFileName = libraryitem.fileName;
												// read json string to Buffer
												let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
												imageFileSize = (fileSize / 1048576).toFixed(2);
											}
										}
									});

									category.imageFileName = imageFileName;
									category.imageFileFormat = imageFileFormat;
									category.imageFileSize = imageFileSize;
									category.imageS3Url = item.imageS3Url ? item.imageS3Url : "";
									category.categoryName = item.name ? item.name.en_GB ? item.name.en_GB : "" : "";
									category.imageData = "";
									category.thumbnailData = thumbnailData;
									category.recipe_items = [];
									item.recipe_items && item.recipe_items.map((recipeitem, k) => {
										let recipe = fetchAvailableRecipes.filter(d => d.LIBRARY_ID === recipeitem);
										category.recipe_items[k] = recipe && recipe[0] ? recipe[0] : [];
									});
									let _checkRecipeExist = data.filter(o1 => category.recipe_items.some(o2 => o1.LIBRARY_ID === o2.LIBRARY_ID));
									category.recipe_items = category.recipe_items && category.recipe_items.filter(item => item.length == undefined);
									category.subCategory = [];
									item.menu_items && item.menu_items.map((subitem, l) => {
										let subCategory = {};
										subCategory.ID = subitem.id;

										let thumbnailData = null;
										let imageFileFormat = "";
										let imageFileName = "";
										let imageFileSize = "";

										let fileext = subitem.image.substring(subitem.image.lastIndexOf('.') + 1);
										let itemFileName = subitem.image.replace(fileext, "png");

										libraryFiles && libraryFiles.map((libraryitem, m) => {
											if (libraryitem.fileName.includes('tmb_')) {
												if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === itemFileName.replace("Resources/Images/", "tmb_").replace("Resources/", "tmb_").replace("/", "")) {
													thumbnailData = libraryitem.fileBuffer;
												}
											}
											else if (!libraryitem.fileName.toLowerCase().includes('.json')) {
												if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === subitem.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/", "")) {
													imageData = libraryitem.fileBuffer;
													let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
													let fileextSplit = fileName.split('.');
													let fileExt = fileextSplit[1];
													imageFileFormat = "." + fileExt;
													imageFileName = libraryitem.fileName;
													// read json string to Buffer
													let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
													imageFileSize = (fileSize / 1048576).toFixed(2);
												}
											}
										});

										subCategory.imageFileName = imageFileName;
										subCategory.imageFileFormat = imageFileFormat;
										subCategory.imageFileSize = imageFileSize;
										subCategory.imageS3Url = subitem.imageS3Url ? subitem.imageS3Url : "";
										subCategory.categoryName = subitem.name ? subitem.name.en_GB ? subitem.name.en_GB : "" : "";
										subCategory.imageData = "";
										subCategory.thumbnailData = thumbnailData;
										subCategory.recipe_items = [];
										subitem.recipe_items && subitem.recipe_items.map((recipeitem, n) => {
											let recipe = fetchAvailableRecipes.filter(d => d.LIBRARY_ID === recipeitem);
											subCategory.recipe_items[n] = recipe && recipe[0] ? recipe[0] : [];
										});
										subCategory.recipe_items = subCategory.recipe_items && subCategory.recipe_items.filter(item => item.length == undefined);
										category.subCategory.push(subCategory);
									});
									categoryDetailsObj.push(category);
								});

								localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
								localStorage.setItem("libraryCookbookDetails", JSON.stringify(libraryDetails));

								let recipeDetails = LZString.decompress(localStorage.getItem("allRecipeDetails"));
								let recipeDetailsFromARD = recipeDetails ? JSON.parse(recipeDetails) : [];
								let resourceDetails = JSON.parse(localStorage.getItem("resourceDetails"));
								let allRecipesFromRD = [];
								resourceDetails.forEach(obj => {
									if (obj.hasOwnProperty('recipe_items')) {
										allRecipesFromRD = allRecipesFromRD.concat(obj.recipe_items);
									}
								});
								allRecipesFromRD = [...new Set(allRecipesFromRD.map(item => item.ID))].map(id => allRecipesFromRD.find(item => item.ID === id));
								recipeDetailsFromARD.forEach(obj1 => {
									if (!allRecipesFromRD.some(obj2 => JSON.stringify(obj1) === JSON.stringify(obj2))) {
										obj1.allRecipeType = 'allrecipe'
									}
								});
								localStorage.setItem("allRecipeDetails", LZString.compress(JSON.stringify(recipeDetailsFromARD)));
								if (selected_item && selected_item.TYPE === "CookBook") {
									if (selected_item.SETTINGS_LIBRARY_ID && selected_item.SETTINGS_LIBRARY_ID !== null && selected_item.SETTINGS_LIBRARY_ID !== 0) {
										// new cookbook files
										await this.onDownloadSettingsData();
									} else if (selected_item.SETTINGS_LIBRARY_ID === null) {
										// old cookbook files
										await this.onDownloadDefaultSettingsData();
									}
								} else if (selected_item.SETTINGS_LIBRARY_ID !== null || selected_item.SETTINGS_LIBRARY_ID !== 0) {
									// new settings files
									await this.OnEdit_For_Setting_Library();
								}
								else {
									this.props.history.push({
										pathname: "/cookbookEditor",
										previouspath: "/libraryList"
									});
								}
							}
							else {
								//Settings
								let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.settingName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.settings = UpdatedJSONObj.sounds;
								libraryDetails.day = UpdatedJSONObj.scheduler && UpdatedJSONObj.scheduler.length > 0 ? UpdatedJSONObj.scheduler[0].day : "Monday";
								libraryDetails.initialMethod = "Edit"

								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
								localStorage.setItem("libraryImage", b64);

								this.props.history.push({
									pathname: "/settingsEditor",
									previouspath: "/libraryList"
								});
							}
						}
						else if (this.state.selected_item.BRAND_NAME === "FRYMASTER") {
							if (libraryFiles[0] && this.state.selected_item.TYPE === "Recipe") {
								let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
								let quality_timer = UpdatedJSONObj.settings.quality_timer;
								UpdatedJSONObj.settings.quality_timer = quality_timer ? this.getFormatedTime(quality_timer) : '';
								let drain_timer = UpdatedJSONObj.settings.drain_timer;
								UpdatedJSONObj.settings.drain_timer = drain_timer ? this.getFormatedTime(drain_timer) : '';
								let hold_timer = UpdatedJSONObj.settings.hold_timer;
								UpdatedJSONObj.settings.hold_timer = hold_timer ? this.getFormatedTime(hold_timer) : '';
								let instant_on = UpdatedJSONObj.settings.instant_on;
								UpdatedJSONObj.settings.instant_on = instant_on ? this.getFormatedTime(instant_on) : '';

								UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.map((item, i) => {
									UpdatedJSONObj.ordered_steps[i].duration = item.duration ? this.getFormatedTime(item.duration) : '';
								});

								UpdatedJSONObj.conditional_steps && UpdatedJSONObj.conditional_steps.map((item, i) => {
									let duration = item.actions.prompt.duration;
									UpdatedJSONObj.conditional_steps[i].actions.prompt.duration = duration ? this.getFormatedTime(duration) : '';
									UpdatedJSONObj.conditional_steps[i].condition.property_value = duration ? this.getFormatedTime(duration) : '';
								});

								localStorage.setItem("libraryData", JSON.stringify(UpdatedJSONObj));

								let resourceDetails = UpdatedJSONObj.recipeResources;
								if (UpdatedJSONObj.recipeResources.length > 0) {
									resourceDetails[0].imageData = '';
									resourceDetails[0].thumbnailData = b64;
								}
								localStorage.setItem("resourceDetails", JSON.stringify(resourceDetails));

								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
								libraryDetails.library_id = this.state.selected_item.LIBRARY_ID;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.initialMethod = "Edit"

								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));

								this.props.history.push({
									pathname: "/frymasterRecipeCreation",
									previouspath: "/libraryList"
								});
							}
							else if (libraryFiles[0] && selected_item.TYPE === "CookBook") {
								let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.cookbookName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.autoMenuChange = UpdatedJSONObj.autoMenuChange;
								libraryDetails.limitedTimeOffer = UpdatedJSONObj.limitedTimeOffer;
								libraryDetails.initialMethod = "Edit";

								//CookBook
								let categoryDetailsObj = [];
								UpdatedJSONObj && UpdatedJSONObj.menus && UpdatedJSONObj.menus.map((item, i) => {
									let category = {};
									category.ID = item.id;
									category.categoryName = item.name ? item.name.en_US ? item.name.en_US : "" : "";
									category.type = "Category";
									category.recipe_items = [];
									item.recipe_items && item.recipe_items.map((recipeitem, k) => {
										let recipe = data.filter(d => d.LIBRARY_ID === recipeitem);
										category.recipe_items[k] = recipe && recipe[0] ? recipe[0] : [];
									});
									categoryDetailsObj.push(category);
								});

								localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));

								this.props.history.push({
									pathname: "/FrymasterMenuEditor",
									previouspath: "/libraryList"
								});
							}
							else {
								//Settings
								let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
								let libraryDetails = {};
								libraryDetails.id = UpdatedJSONObj.id;
								libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
								libraryDetails.library_id = selected_item.LIBRARY_ID;
								libraryDetails.settingName = selected_item.LIBRARY_NAME;
								libraryDetails.crc = md5(b64, "base64");
								libraryDetails.isEdit = true;
								libraryDetails.initialMethod = "Edit";

								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
								localStorage.setItem("libraryData", JSON.stringify(UpdatedJSONObj));
								try {
									localStorage.setItem("defaultLibraryData", JSON.stringify(UpdatedJSONObj));
								}
								catch (err) {
									localStorage.setItem("defaultLibraryData", LZString.compress(JSON.stringify(UpdatedJSONObj)));
								}

								this.props.history.push({
									pathname: "/frymasterSettings",
									previouspath: "/libraryList"
								});
							}
						} else if (this.state.selected_item.BRAND_NAME === "GARLAND") {
							console.log("libraryFiles", libraryFiles)
							const temp_units = UpdatedJSONObj && UpdatedJSONObj.temp_units ? JSON.parse(UpdatedJSONObj.temp_units) : JSON.parse("{\"unit_cooking_method\":\"°C\",\"unit_cooking_steps\":\"°C\"}");

							if (libraryFiles[0] && (selected_item.TYPE === "Recipe")) {
								let libraryData = {};
								if (selected_item.TYPE === "Recipe") {
									const cookingMethodologyMapping = {
										0: 'Steel',
										1: 'Standard',
										2: 'Steel / Water',
										3: 'LRS / Water'
									}
									console.log("UpdatedJSONObj", UpdatedJSONObj)
									libraryData = UpdatedJSONObj;
									let unitCooking = temp_units['unit_cooking_method'] ? temp_units['unit_cooking_method'] : "°C";
									libraryData['unit'] = unitCooking;
									let too_cold_limit = Number(libraryData['too_cold_limit']) !== 0 ? Number(libraryData['too_cold_limit']) / 10 : 0;
									let too_hot_limit = Number(libraryData['too_hot_limit']) !== 0 ? Number(libraryData['too_hot_limit']) / 10 : 0;
									too_cold_limit = too_cold_limit === 0 ? too_cold_limit : unitCooking === "°F" ? this.c2fGrill(too_cold_limit) : too_cold_limit;
									too_hot_limit = too_hot_limit === 0 ? too_hot_limit : unitCooking === "°F" ? this.c2fGrill(too_hot_limit) : too_hot_limit;
									libraryData['too_cold_limit'] = too_cold_limit > 100 ? 100 : too_cold_limit;
									libraryData['too_hot_limit'] = too_hot_limit > 100 ? 100 : too_hot_limit;
									libraryData['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;
									libraryData['cooking_methodology'] = cookingMethodologyMapping[UpdatedJSONObj.cooking_methodology];
									let cookObj = [];
									const promptMapping = {
										'sear': 'Sear',
										'remove in time': 'Remove in time',
										'flip (must ack)': 'Flip (Must Ack)',
										'flip (run time)': 'Flip (Run time)',
										'default done': 'Default Done',
										'platen closing': 'Platen Closing',
										'none': 'None'
									}
									const unitCookingSteps = temp_units['unit_cooking_steps'] ? temp_units['unit_cooking_steps'] : "°C";
									for (let index = 0; index < UpdatedJSONObj.cookingSteps.length; index++) {
										const element = UpdatedJSONObj.cookingSteps[index];
										let obj = {
											cookTime: element.CookTime,
											promptSelection: promptMapping[element.PromptSelection] ? promptMapping[element.PromptSelection] : element.PromptSelection,
											prompt: element.Prompt,
											uniqueId: (new Date()).getTime(),
											flatCooking: element.isFlat === 1 ? 'Yes' : 'No',
											cookingGap: element && element.Gap ? element.Gap / 100000 : 0,
											unit: unitCookingSteps,
											upperPlattenTemp: element.tempUpperPlaten === 0 ? 'Off' : unitCookingSteps === "°F" ? this.c2f(element.tempUpperPlaten / 10) : element.tempUpperPlaten / 10,
											upperPlattenCheck: element.tempUpperPlaten === 0 ? false : true,
											upperPlattenValue: element.tempUpperPlaten === 0 ? '' : unitCookingSteps === "°F" ? this.c2f(element.tempUpperPlaten / 10) : element.tempUpperPlaten / 10,

											grillPlattenTemp: element.tempLowerPlaten === 0 ? 'Off' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerPlaten / 10) : element.tempLowerPlaten / 10,
											grillPlattenCheck: element.tempLowerPlaten === 0 ? false : true,
											grillPlattenValue: element.tempLowerPlaten === 0 ? '' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerPlaten / 10) : element.tempLowerPlaten / 10,

											grillFrontTemp: element.tempLowerFront === 0 ? 'Off' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerFront / 10) : element.tempLowerFront / 10,
											grillFrontCheck: element.tempLowerFront === 0 ? false : true,
											grillFrontValue: element.tempLowerFront === 0 ? '' : unitCookingSteps === "°F" ? this.c2f(element.tempLowerFront / 10) : element.tempLowerFront / 10,
										}
										cookObj.push(obj)
									}
									libraryData['steps'] = cookObj;
								}

								localStorage.setItem("garlandRecipe", JSON.stringify(libraryData));
							}

							if (selected_item.TYPE === "Recipe") {
								console.log("libraryFiles", libraryFiles)
								//Recipe
								let RecipeImage = libraryFiles.find(l => l.fileType === "Image");
								imageBuffer = RecipeImage ? RecipeImage.fileBuffer : null;
								console.log("imageBuffer", imageBuffer)

								if (imageBuffer !== null) {
									return Jimp.read(imageBuffer)
										.then(image => {
											console.log("image", image)
											image.resize(80, 80, (err, image) => {
												image.getBase64Async('image/png').then(thumbnailb64 => {
													let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
													libraryDetails = libraryDetails ? libraryDetails : {};
													let imageData = {};
													imageData.imagefileName = selected_item.LIBRARY_NAME + "." + selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													imageData.imagefileext = selected_item.IMAGE_FILE_FORMAT.replace('.', '');
													imageData.imagefileNamewithOutExt = selected_item.LIBRARY_NAME;
													imageData.imagefileSize = selected_item.IMAGE_FILE_SIZE;
													imageData.imagefilelength = selected_item.LIBRARY_NAME.length;
													imageData.crc = md5(b64, "base64");
													imageData.imageS3Url = selected_item.IMAGE_S3_SIGNED_URL;
													libraryDetails.imageData = imageData;
													libraryDetails.id = selected_item.LIBRARY_GUID;
													libraryDetails.recipeName = selected_item.LIBRARY_NAME;
													libraryDetails.isEdit = true;
													libraryDetails.library_id = UpdatedJSONObj.recipeID;
													libraryDetails.isImageChanged = false;
													libraryDetails.initialMethod = "Edit";
													libraryDetails.recipeType = selected_item.TYPE;
													libraryDetails.imageThumbnailb64 = thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, "");

													localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
													localStorage.setItem("libraryImage", thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
													this.props.history.push({
														pathname: "/garlandRecipeCreation",
														previouspath: "/libraryList"
													});
												});
											})
										}).catch((err) => {
											console.log("catch 1", err)

											let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
											libraryDetails = libraryDetails ? libraryDetails : {};
											let imageData = {};
											libraryDetails.imageData = imageData;
											libraryDetails.id = selected_item.LIBRARY_GUID;
											libraryDetails.recipeName = selected_item.LIBRARY_NAME;
											libraryDetails.isEdit = true;
											libraryDetails.library_id = selected_item.LIBRARY_ID;
											libraryDetails.isImageChanged = false;
											libraryDetails.initialMethod = "Edit";
											libraryDetails.recipeType = selected_item.TYPE;
											libraryDetails['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

											localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
											this.props.history.push({
												pathname: "/garlandRecipeCreation",
												previouspath: "/libraryList"
											});
										})
									// }).catch((err) => {
									// 	console.log("catch ", err)
									// 	let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
									// 	libraryDetails = libraryDetails ? libraryDetails : {};
									// 	let imageData = {};
									// 	libraryDetails.imageData = imageData;
									// 	libraryDetails.id = selected_item.LIBRARY_GUID;
									// 	libraryDetails.recipeName = selected_item.LIBRARY_NAME;
									// 	libraryDetails.isEdit = true;
									// 	libraryDetails.library_id = selected_item.LIBRARY_ID;
									// 	libraryDetails.isImageChanged = false;
									// 	libraryDetails.initialMethod = "Edit";
									// 	libraryDetails.recipeType = selected_item.TYPE;
									// 	libraryDetails['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

									// 	localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
									// 	this.props.history.push({
									// 		pathname: "/garlandRecipeCreation",
									// 		previouspath: "/libraryList"
									// 	});
									// })

								} else {
									let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
									console.log("libraryDetails parse", libraryDetails)

									libraryDetails = libraryDetails ? libraryDetails : {};
									let imageData = {};
									imageData.imagefileName = "";
									imageData.imagefileext = "";
									imageData.imagefileNamewithOutExt = "";
									imageData.imagefileSize = "";
									imageData.imagefilelength = "";
									imageData.crc = "";
									imageData.imageS3Url = "";
									libraryDetails.imageData = imageData;
									libraryDetails.id = selected_item.LIBRARY_GUID;
									libraryDetails.recipeName = selected_item.LIBRARY_NAME;
									libraryDetails.isEdit = true;
									libraryDetails.library_id = selected_item.LIBRARY_ID;
									libraryDetails.isImageChanged = false;
									libraryDetails.initialMethod = "Edit";
									libraryDetails.recipeType = selected_item.TYPE;
									libraryDetails['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

									localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
									this.props.history.push({
										pathname: "/garlandRecipeCreation",
										previouspath: "/libraryList"
									});
								}
							}

							//Garland Cookbook
							if (libraryFiles[0] && (selected_item.TYPE === "CookBook")) {
								localStorage.setItem("cookBookFileName", selected_item.LIBRARY_NAME);
								await this.getGarlandRecipeDetails()
								let cookBooklibraryData = {};

								if (selected_item.TYPE === "CookBook") {
									const cookingMethodologyMapping = {
										0: 'Steel',
										1: 'Standard',
										2: 'Steel / Water',
										3: 'LRS / Water'
									}
									console.log("UpdatedJSONObj", UpdatedJSONObj)
									cookBooklibraryData = UpdatedJSONObj;
									cookBooklibraryData['unit'] = "°C";
									cookBooklibraryData['s3Url'] = selected_item.IMAGE_S3_SIGNED_URL;

									let garlandMenus = [];
									for (let intCtr = 0; intCtr < UpdatedJSONObj.menus.length; intCtr++) {
										let element = UpdatedJSONObj.menus[intCtr];
										let objs = {
											imageFileName: element.icon ? element.icon : "",
											imageFileFormat: ".png",
											imageFileSize: "",
											// 	imageS3Url: UploadedImages3url,
											imageData: "",
											thumbnailData: "",
											categoryName: element.menuName ? element.menuName : '',
											ID: element.menuID,
											type: "Category",
											parentID: "",
											selected: false,
											unit: element.unit ? element.unit : "°C",

											grill_Plate_limit: element.preHeatLowerPlaten === 0 ? 'Off' : element.unit === "°F" ? this.c2f(element.preHeatLowerPlaten / 10) : element.preHeatLowerPlaten / 10,
											grillPlateLimitCheck: element.preHeatLowerPlaten === 0 ? false : true,
											grillPlateLimitValue: element.preHeatLowerPlaten === 0 ? '' : element.unit === "°F" ? this.c2f(element.preHeatLowerPlaten / 10) : element.preHeatLowerPlaten / 10,

											grill_Front_limit: element.preHeatLowerFront === 0 ? 'Off' : element.unit === "°F" ? this.c2f(element.preHeatLowerFront / 10) : element.preHeatLowerFront / 10,
											grillFrontLimitCheck: element.preHeatLowerFront === 0 ? false : true,
											grillFrontLimitValue: element.preHeatLowerFront === 0 ? '' : element.unit === "°F" ? this.c2f(element.preHeatLowerFront / 10) : element.preHeatLowerFront / 10,

											upper_Platen_limit: element.preHeatUpperPlaten === 0 ? 'Off' : element.unit === "°F" ? this.c2f(element.preHeatUpperPlaten / 10) : element.preHeatUpperPlaten / 10,
											upperPlatenLimitCheck: element.preHeatUpperPlaten === 0 ? false : true,
											upperPlatenLimitValue: element.preHeatUpperPlaten === 0 ? '' : element.unit === "°F" ? this.c2f(element.preHeatUpperPlaten / 10) : element.preHeatUpperPlaten / 10,
											flat_Cooking: element.is_flat === 1 ? 'Yes' : 'No',
										}
										if (element.icon) {
											let RecipeImage = libraryFiles.find(l => l.fileType === "Image" && l.fileName.includes(element.icon));
											imageBuffer = RecipeImage ? RecipeImage.fileBuffer : null;
											if (imageBuffer !== null) {
												objs.thumbnailData = imageBuffer.replace(/^data:image\/[A-Za-z]+;base64,/, "");
												let fileSize = Buffer.byteLength(RecipeImage.fileBuffer);
												objs.imageFileSize = (fileSize / 1048576).toFixed(2);
											}
										}
										objs.recipe_items = [];
										const prObjToFindRecipe = UpdatedJSONObj && UpdatedJSONObj.product_recognition ? UpdatedJSONObj.product_recognition : {};
										for (let rcCtr = 0; rcCtr < element.recipeList.length; rcCtr++) {
											const isAuto = prObjToFindRecipe && prObjToFindRecipe.recipeID ?
												prObjToFindRecipe.recipeID === element.recipeList[rcCtr]
												: false;
											let recipe = isAuto ? [{
												ID: 0,
												recipeId: prObjToFindRecipe.recipeID,
												LIBRARY_GUID: prObjToFindRecipe && prObjToFindRecipe.recipeID ? prObjToFindRecipe.recipeID : '',
												LIBRARY_ID: prObjToFindRecipe && prObjToFindRecipe.recipeID ? prObjToFindRecipe.recipeID : '',
												recipeName: prObjToFindRecipe && prObjToFindRecipe.recipeName ? prObjToFindRecipe.recipeName : 'Auto',
												LIBRARY_NAME: prObjToFindRecipe && prObjToFindRecipe.recipeName ? prObjToFindRecipe.recipeName : 'Auto',
												CREATED_ON: selected_item.UPDATED_ON || "",
												UPDATED_ON: selected_item.UPDATED_ON || ""
											}] : this.getRecipeByID(element.recipeList[rcCtr])
											if (recipe && recipe.length > 0) {
												let regObj = recipe[0];
												objs.recipe_items.push(regObj)
											}
										}
										garlandMenus.push(objs)
									}
									let libraryDetails = {};
									libraryDetails.id = UpdatedJSONObj.id;
									libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
									libraryDetails.library_id = selected_item.LIBRARY_ID;
									libraryDetails.cookbookName = selected_item.LIBRARY_NAME;
									libraryDetails.crc = md5(b64, "base64");
									libraryDetails.isEdit = true;
									libraryDetails.initialMethod = "Edit";


									localStorage.setItem("resourceDetails", JSON.stringify(garlandMenus));
									localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));

									//Product Recognition			
									if (UpdatedJSONObj.product_recognition) {
										let productRecognitionObj = {};
										let prElement = UpdatedJSONObj.product_recognition;
										productRecognitionObj.unit = prElement.unit ? prElement.unit : "°C";

										let too_cold_limit = Number(prElement.too_cold_limit) !== 0 ? Number(prElement.too_cold_limit) / 10 : 0;
										let too_hot_limit = Number(prElement.too_hot_limit) !== 0 ? Number(prElement.too_hot_limit) / 10 : 0;
										too_cold_limit = too_cold_limit === 0 ? too_cold_limit : prElement.unit === "°F" ? this.c2fGrill(too_cold_limit) : too_cold_limit;
										too_hot_limit = too_hot_limit === 0 ? too_hot_limit : prElement.unit === "°F" ? this.c2fGrill(too_hot_limit) : too_hot_limit;
										productRecognitionObj.tooColdLimitValue = too_cold_limit > 100 ? 100 : too_cold_limit;
										productRecognitionObj.tooHotLimitValue = too_hot_limit > 100 ? 100 : too_hot_limit;

										let prCooking = prElement.cookingSteps ? prElement.cookingSteps[0] : {}
										productRecognitionObj.grill_Plate_limit = prCooking.tempLowerPlaten === 0 ? 'Off' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerPlaten / 10) : prCooking.tempLowerPlaten / 10;
										productRecognitionObj.grillPlateLimitCheck = prCooking.tempLowerPlaten === 0 ? false : true;
										productRecognitionObj.grillPlateLimitValue = prCooking.tempLowerPlaten === 0 ? '' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerPlaten / 10) : prCooking.tempLowerPlaten / 10;

										productRecognitionObj.grill_Front_limit = prCooking.tempLowerFront === 0 ? 'Off' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerFront / 10) : prCooking.tempLowerFront / 10;
										productRecognitionObj.grillFrontLimitCheck = prCooking.tempLowerFront === 0 ? false : true;
										productRecognitionObj.grillFrontLimitValue = prCooking.tempLowerFront === 0 ? '' : prElement.unit === "°F" ? this.c2f(prCooking.tempLowerFront / 10) : prCooking.tempLowerFront / 10;

										productRecognitionObj.upper_Platen_limit = prCooking.tempUpperPlaten === 0 ? 'Off' : prElement.unit === "°F" ? this.c2f(prCooking.tempUpperPlaten / 10) : prCooking.tempUpperPlaten / 10;
										productRecognitionObj.upperPlatenLimitCheck = prCooking.tempUpperPlaten === 0 ? false : true;
										productRecognitionObj.upperPlatenLimitValue = prCooking.tempUpperPlaten === 0 ? '' : prElement.unit === "°F" ? this.c2f(prCooking.tempUpperPlaten / 10) : prCooking.tempUpperPlaten / 10;

										productRecognitionObj.cookingMethodology = prCooking.cooking_methodology ? cookingMethodologyMapping[prElement.cooking_methodology] : 'Steel';
										productRecognitionObj.recipeID = prElement.recipeID;
										productRecognitionObj.recipeName = prElement.recipeName ? prElement.recipeName : 'Auto';
										productRecognitionObj.ID = 0;
										productRecognitionObj.recipe_items = [];

										let prCookingRegions = prElement.cookingRegions ? prElement.cookingRegions : [];
										for (let prCtr = 0; prCtr < prCookingRegions.length; prCtr++) {
											let recipe = this.getRecipeByID(prCookingRegions[prCtr].regionRecipeID)
											if (recipe && recipe.length > 0) {
												let regObj = recipe[0];
												regObj.minGap = prCookingRegions[prCtr].gapLowerLimit / 100000;
												regObj.maxGap = prCookingRegions[prCtr].gapUpperLimit / 100000;
												productRecognitionObj.recipe_items.push(regObj)
											}
										}
										localStorage.setItem("selectedPRRecipes", JSON.stringify(productRecognitionObj.recipe_items));
										localStorage.setItem("ProductRecgDetails", JSON.stringify(productRecognitionObj));
									}

								}
								this.props.history.push({
									pathname: "/garlandCookbookCreation",
									previouspath: "/libraryList"
								});
							}

						}
						// this.setState({
						// 	loaderIconVisible: false
						// });
					});
				}
				catch (ex) {
					this.setState({
						loaderIconVisible: false
					});
				}
			}
		})
			.catch(err => {
				this.setState({
					loaderIconVisible: false
				});
			});
	}


	onDownloadSettingsData = async () => {
		let { selected_item } = this.state;

		await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
			headers: {
				'Content-Type': 'application/json',
				'library_id': encodeURIComponent(selected_item.SETTINGS_LIBRARY_ID),
				'library_isedit': true,
				'encryption': "true"
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				let file_path = response.data.presignedURL;
				console.log("file_path", file_path)
				var libraryFiles = [];
				var b64 = "";
				var imageBuffer = null;
				try {
					request.get({ url: file_path, encoding: null }, async (err, res, body) => {
						var zip = new AdmZip(body);
						var zipEntries = zip.getEntries();
						let UpdatedJSONObj = "";
						console.log("zip", zip)
						console.log("zipEntries", zipEntries)
						let validzipEntries = zipEntries.filter(z => z.entryName.charAt(z.entryName.length - 1) !== "/");
						console.log("validzipEntries", validzipEntries)

						validzipEntries.forEach((entry, i) => {
							if (localStorage.getItem("brandName") === "MERRYCHEF") {
								libraryFiles[i] = zip.readFile(entry);

								if (!entry.entryName.includes('.json') && !entry.entryName.includes('.wav')) {
									imageBuffer = Buffer.from(libraryFiles[i]);
									b64 = imageBuffer.toString('base64');
									let fileScreenSaverExt = entry.entryName.substring(entry.entryName.lastIndexOf('.') + 1);
									localStorage.setItem("libraryScreenSaverExt", fileScreenSaverExt);
								}
							}

						});
						if (selected_item.BRAND_NAME === "MERRYCHEF") {
							this.setState({
								loaderIconVisible: true
							});
							if (libraryFiles[0] && (selected_item.SETTINGS_LIBRARY_ID && selected_item.SETTINGS_LIBRARY_ID !== null)) {
								let libraryData = [];
								libraryData = JSON.parse(libraryFiles[0]);
								let schedulers = libraryData.scheduler;
								if (!Array.isArray(schedulers)) {
									libraryData.scheduler = []
								}
								else {
									schedulers.map((parent, i) => {
										parent.configurations.map((item, j) => {
											if (item.type !== "Cold") {
												item.id = j + 1;
											}
											else {
												item.id = 12;
											}
										})
									})
								}

								localStorage.setItem("schedulerDetails", JSON.stringify(libraryData.scheduler))
								localStorage.setItem("librarySettingsData", JSON.stringify(libraryData));
								try {
									localStorage.setItem("defaultLibraryData", JSON.stringify(libraryData));
								}
								catch (err) {
									localStorage.setItem("defaultLibraryData", LZString.compress(JSON.stringify(libraryData)));
								}
							}
							//Settings
							let UpdatedJSONObj = libraryFiles[0] ? JSON.parse(libraryFiles[0]) : {};
							let libraryDetails = {};
							libraryDetails.id = UpdatedJSONObj.id;
							libraryDetails.library_id = selected_item.SETTINGS_LIBRARY_ID;
							libraryDetails.settingName = selected_item.LIBRARY_NAME;
							libraryDetails.crc = md5(b64, "base64");
							libraryDetails.isEdit = true;
							libraryDetails.settings = UpdatedJSONObj.sounds;
							libraryDetails.day = UpdatedJSONObj.scheduler && UpdatedJSONObj.scheduler.length > 0 ? UpdatedJSONObj.scheduler[0].day : "Monday";
							libraryDetails.initialMethod = "Edit"

							localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
							localStorage.setItem("libraryImage", b64);

							this.props.history.push({
								pathname: "/cookbookEditor",
								previouspath: "/libraryList"
							});
						}
					});
				}
				catch (ex) {
					this.setState({
						loaderIconVisible: false
					});
				}
			}
		})
			.catch(err => {
				this.setState({
					loaderIconVisible: false
				});
			});
	}

	onDownloadDefaultSettingsData = async () => {
		return new Promise((resolve, reject) => {
			//Header values are not used in below API so dummy values are passed.
			http.get(MenuUploadAPI.getdefaultLibraryFiles,
				{
					headers: {
						'Content-Type': 'application/json',
						'customerid': CUSTOMER_ID,
						'cgids': localStorage.getItem("custGroupID"),
						'brandid': localStorage.getItem("selectedbrandID"),
						'basecountry': BASECOUNTRY_ID
					},
					data: {}
				}).then(response => {
					let { data } = response;
					let settings = data && data.settings ? data.settings : [];
					let libraryData = settingsJSON.default;
					libraryData.sounds = settings;
					localStorage.setItem("librarySettingsData", JSON.stringify(libraryData));
					localStorage.setItem("schedulerDetails", JSON.stringify(libraryData.scheduler));
					localStorage.setItem("selectedGroupID", 0);
					localStorage.setItem("defaultLibraryData", JSON.stringify(libraryData));
					resolve(response);
					this.props.history.push({
						pathname: "/cookbookEditor",
						previouspath: "/libraryList"
					});
				}).catch(err => {
					this.setState({
						loaderIconVisible: false
					});
					reject(err);
				});
		});
	}

	getGarlandRecipeDetails = () => {
		this.setState({
			// loaderIconVisible: true,            
		});

		return new Promise((resolve, reject) => {
			http.get(MenuUploadAPI.getLibraryList,
				{
					headers: {
						'Content-Type': 'application/json',
						'customerid': CUSTOMER_ID,
						'cgids': localStorage.getItem("custGroupID"),
						'brandid': localStorage.getItem("selectedbrandID"),
						'modelfamilyids': localStorage.getItem("selectedFamilyID"),
						'basecountry': BASECOUNTRY_ID,
						'Type': "Recipe"
					},
					data: {}
				}).then(response => {
					let { data } = response;
					let libraryList = data && data.libraryList ? data.libraryList : [];

					libraryList.length > 0 && libraryList.map(item => {
						item.CREATED_ON = moment.ConvertLocalTimeFromUTCTime(item.CREATED_ON, "DD/MM/YYYY");
						item.checked = false;
						item.minGap = 0;
						item.maxGap = 0;
					});

					this.setState({
						// loaderIconVisible: false,
						allMenuRecipes: libraryList,
					});
					resolve(response);
				}).catch(err => {
					this.setState({
						// loaderIconVisible: false,
					});
					reject(err);
				});
		});
	}

	getMerrychefImageDetails(libraryFiles, item, imageData, imageFileFormat, imageFileName, imageFileSize) {
		libraryFiles && libraryFiles.map((libraryitem, j) => {
			if (!libraryitem.fileName.toLowerCase().includes('.json')) {
				if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "") === item.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/", "")) {
					imageData = libraryitem.fileBuffer;
					let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
					let fileextSplit = fileName.split('.');
					let fileExt = fileextSplit[1];
					imageFileFormat = "." + fileExt;
					imageFileName = libraryitem.fileName;
					// read json string to Buffer
					let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
					imageFileSize = (fileSize / 1048576).toFixed(2);
				}
			}
		});
		return { imageData, imageFileFormat, imageFileName, imageFileSize };
	}

	handleFrymasterSettings(libraryFiles, LIBRARY_NAME, IMAGE_FILE_FORMAT, IMAGE_FILE_SIZE, b64, type) {
		let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
		let libraryDetails = {};
		libraryDetails.id = UpdatedJSONObj.id;
		libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
		LIBRARY_NAME = libraryFiles[0].fileName.substring(0, libraryFiles[0].fileName.lastIndexOf('_'));
		let library_id = (LIBRARY_NAME + '_' + Date.now()).replace(/\s+/g, '');
		libraryDetails.library_id = library_id;
		libraryDetails.settingName = LIBRARY_NAME;
		libraryDetails.crc = md5(b64, "base64");
		libraryDetails.isEdit = false;
		libraryDetails.initialMethod = "Upload";
		libraryDetails.fileType = type;

		localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
		localStorage.setItem("libraryData", JSON.stringify(UpdatedJSONObj));
		try {
			localStorage.setItem("defaultLibraryData", JSON.stringify(UpdatedJSONObj));
		}
		catch (err) {
			localStorage.setItem("defaultLibraryData", LZString.compress(JSON.stringify(UpdatedJSONObj)));
		}


		this.props.history.push({
			pathname: "/UploadSelectDevice",
			previouspath: "/libraryList"
		});
		return { LIBRARY_NAME, IMAGE_FILE_FORMAT, IMAGE_FILE_SIZE };
	}


	getFrymasterRecipeDetails = async (recipeitem, UpdatedJSONObj, data, libraryFiles) => {
		return new Promise(async (resolve, reject) => {
			let recipe = UpdatedJSONObj.recipes.filter(recipe => recipe.id === recipeitem);
			recipe = recipe && recipe[0] ? recipe[0] : {};

			let fileext = recipe.image.substring(recipe.image.lastIndexOf('.') + 1);
			let itemFileName = recipe.image.replace('.' + fileext, "");

			// convert JSON object to beautify String
			var jsonStr = beautify(recipe, null, 2, 80)
			// read json string to Buffer
			const jsonBuffer = Buffer.from(jsonStr);

			let jsonfileSize = Buffer.byteLength(jsonBuffer);
			jsonfileSize = (jsonfileSize / 1048576);
			let imageFileFormat = "";
			let imageFileName = "";
			let imageFileSize = 0.00;
			let imageBuffer = null;

			libraryFiles && libraryFiles.map((libraryitem, j) => {
				// thumbnailData
				if (!libraryitem.fileName.toLowerCase().includes('.json')) {
					if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/", "").includes(itemFileName.replace("Resources/Images/", "").replace("Resources/", "").replace("/", ""))) {
						imageBuffer = libraryitem.fileBuffer;
						let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
						let fileextSplit = fileName.split('.');
						let fileExt = fileextSplit[1];
						imageFileFormat = "." + fileExt;
						imageFileName = libraryitem.fileName;
						// read json string to Buffer
						let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
						imageFileSize = (fileSize / 1048576);
					}
				}
			});

			let brandName = localStorage.getItem("brandName");
			let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
			let loggedinUser = loginUser.username;
			let LIBRARY_NAME = recipe.name.en_US;
			let library_id = (LIBRARY_NAME + '_' + Date.now()).replace(/\s+/g, '');
			let resourceDetails = [];
			if (imageBuffer) {
				resourceDetails = await this.uploadFrymasterImageForThumbnailBuffer(imageBuffer, imageFileName, imageFileFormat.replace('.', ''), `${GET_LIBRARY_PRESIGNED_URL}`);
			}
			let crc = md5(jsonBuffer, "base64");

			let libraryrecipe = data.find(d => d.LIBRARY_GUID === recipeitem);
			let libraryID = libraryrecipe && libraryrecipe.LIBRARY_ID ? libraryrecipe.LIBRARY_ID : library_id;

			let libraryPostData = {
				"customerId": CUSTOMER_ID,
				"brandId": BRAND_ID_VALUE[brandName],
				"modelFamilyIds": libraryrecipe.MODEL_FAMILY ? Array.prototype.map.call(libraryrecipe.MODEL_FAMILY, m => m.ID).toString() : [],
				"libraryId": libraryID,
				"libraryName": LIBRARY_NAME,
				"libraryFileName": libraryID + ".json",
				"libraryFileFormat": "json",
				"libraryFileSize": (jsonfileSize + imageFileSize).toFixed(2),
				"libraryS3Url": "",
				"type": "Recipe",
				"createdUser": ' (' + loggedinUser + ')',
				"crc": crc,
				"baseCountry": BASECOUNTRY_ID,
				"imageFileName": resourceDetails[0] ? resourceDetails[0].s3FileName : "",
				"imageFileFormat": resourceDetails[0] ? resourceDetails[0].fileFormat.replace('.', '') : "",
				"imageFileSize": resourceDetails[0] ? (resourceDetails[0].fileSize ? resourceDetails[0].fileSize.toFixed(2) : "0.00") : "0.00",
				"imageS3Url": resourceDetails[0] ? resourceDetails[0].s3Url : "",
				"cgids": libraryrecipe.ASSIGNED_CUSTOMER_GROUP ? Array.prototype.map.call(libraryrecipe.ASSIGNED_CUSTOMER_GROUP, m => m.cust_gp_id) : [parseInt(localStorage.getItem("custGroupID"))],
				"json": JSON.stringify(recipe),
				"libraryGuid": recipe.id
			};

			let recipeDetails = {};
			let httpMethod = "";
			if (libraryrecipe) {
				// if exists update
				httpMethod = "PUT";
				delete libraryPostData.customerId;
				delete libraryPostData.createdUser;
				libraryPostData.updatedUser = ' (' + loggedinUser + ')';
				recipeDetails = {
					"LIBRARY_ID": libraryID,
					"IMAGE_S3_SIGNED_URL": resourceDetails[0] ? resourceDetails[0].imageurl : "",
					"LIBRARY_NAME": LIBRARY_NAME,
					"checked": false
				}
			}
			else {
				// else Insert
				libraryPostData.status = "DRAFT";
				httpMethod = "POST";
				recipeDetails = {
					"LIBRARY_ID": libraryID,
					"IMAGE_S3_SIGNED_URL": resourceDetails[0] ? resourceDetails[0].imageurl : "",
					"LIBRARY_NAME": LIBRARY_NAME,
					"checked": false
				}
			}

			let headerpresigned = {
				'Content-Type': 'application/json',
				'customerid': CUSTOMER_ID,
				'imagefilekey': '',
				'jsonfilekey': library_id + '.json',
				'audiofilekey': '',
				'isthumbnail': false
			}

			await uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
				resolve({ recipeDetails });
			}).catch(err => {
				reject(err);
			});
		})
	}

	async handleFrymasterCookbook(libraryFiles, LIBRARY_NAME, IMAGE_FILE_FORMAT, imageBuffer, IMAGE_FILE_SIZE, b64, type, data) {
		let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);

		let libraryDetails = {};
		libraryDetails.id = UpdatedJSONObj.id;
		libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
		let imagename = UpdatedJSONObj.image ? UpdatedJSONObj.image.replace('Resources/Images/', '') : '';
		let imageextSplit = imagename.split('.');
		LIBRARY_NAME = libraryFiles[0].fileName.substring(0, libraryFiles[0].fileName.lastIndexOf('_'));
		IMAGE_FILE_FORMAT = imageextSplit[1];
		let fileSize = Buffer.byteLength(imageBuffer);
		IMAGE_FILE_SIZE = (fileSize / 1048576);
		let library_id = (LIBRARY_NAME + '_' + Date.now()).replace(/\s+/g, '');
		libraryDetails.library_id = library_id;
		libraryDetails.cookbookName = LIBRARY_NAME;
		libraryDetails.crc = md5(b64, "base64");
		libraryDetails.isEdit = false;
		libraryDetails.autoMenuChange = UpdatedJSONObj.autoMenuChange;
		libraryDetails.limitedTimeOffer = UpdatedJSONObj.limitedTimeOffer;
		libraryDetails.initialMethod = "Upload";
		libraryDetails.fileType = type;



		//CookBook
		let categoryDetailsObj = [];
		for (let index = 0; index < UpdatedJSONObj.menus.length; index++) {
			const item = UpdatedJSONObj.menus[index];
			let category = {};
			category.ID = item.id;
			category.categoryName = item.name ? item.name.en_US ? item.name.en_US : "" : "";
			category.type = "Category";
			category.recipe_items = [];


			for (let k = 0; k < item.recipe_items.length; k++) {
				const recipeitem = item.recipe_items[k];
				const { recipeDetails } = await this.getFrymasterRecipeDetails(recipeitem, UpdatedJSONObj, data, libraryFiles);

				category.recipe_items[k] = recipeDetails ? recipeDetails : [];;

			}
			categoryDetailsObj.push(category);
		}
		localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
		localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));

		this.props.history.push({
			pathname: "/UploadSelectDevice",
			previouspath: "/libraryList"
		});
		return { md5, LIBRARY_NAME, IMAGE_FILE_FORMAT, IMAGE_FILE_SIZE };
	}

	async handleFrymasterRecipe(libraryFiles, LIBRARY_NAME, IMAGE_FILE_FORMAT, imageBuffer, IMAGE_FILE_SIZE, b64, type, data) {
		let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
		let quality_timer = UpdatedJSONObj.settings.quality_timer;
		UpdatedJSONObj.settings.quality_timer = quality_timer ? this.getFormatedTime(quality_timer) : '';
		let drain_timer = UpdatedJSONObj.settings.drain_timer;
		UpdatedJSONObj.settings.drain_timer = drain_timer ? this.getFormatedTime(drain_timer) : '';
		let hold_timer = UpdatedJSONObj.settings.hold_timer;
		UpdatedJSONObj.settings.hold_timer = hold_timer ? this.getFormatedTime(hold_timer) : '';
		let instant_on = UpdatedJSONObj.settings.instant_on;
		UpdatedJSONObj.settings.instant_on = instant_on ? this.getFormatedTime(instant_on) : '';

		UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.map((item, i) => {
			UpdatedJSONObj.ordered_steps[i].duration = item.duration ? this.getFormatedTime(item.duration) : '';
		});

		UpdatedJSONObj.conditional_steps && UpdatedJSONObj.conditional_steps.map((item, i) => {
			let duration = item.actions.prompt.duration;
			UpdatedJSONObj.conditional_steps[i].actions.prompt.duration = duration ? this.getFormatedTime(duration) : '';
			UpdatedJSONObj.conditional_steps[i].condition.property_value = duration ? this.getFormatedTime(duration) : '';
		});

		UpdatedJSONObj.recipeSegmentType = ((UpdatedJSONObj.conditional_steps && UpdatedJSONObj.conditional_steps.length > 0) || (UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.length > 1)) ? "Multi" : "Single";
		localStorage.setItem("libraryData", JSON.stringify(UpdatedJSONObj));

		let libraryDetails = {};
		libraryDetails.id = UpdatedJSONObj.id;
		libraryDetails.version = UpdatedJSONObj.version ? UpdatedJSONObj.version.slice(0, UpdatedJSONObj.version.indexOf('.')) : "";
		let imagename = UpdatedJSONObj.image.replace('Resources/Images/', '');
		let imageextSplit = imagename.split('.');
		LIBRARY_NAME = UpdatedJSONObj.name ? UpdatedJSONObj.name.en_US : "";
		IMAGE_FILE_FORMAT = imageextSplit[1];
		let fileSize = Buffer.byteLength(imageBuffer);
		IMAGE_FILE_SIZE = (fileSize / 1048576);

		const { imagefilekey, thumbnailbuffer, image, imageurl, library_id, thumbnailurl, UploadedImages3url } = await this.uploadImageForThumbnailBuffer(imageBuffer, LIBRARY_NAME, IMAGE_FILE_FORMAT, `${GET_LIBRARY_PRESIGNED_URL}`);

		var xhrthumb = new XMLHttpRequest();
		xhrthumb.open("PUT", thumbnailurl);
		xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
		xhrthumb.onloadend = (res) => {
			if (res.target.status == 200) {
				var xhrimage = new XMLHttpRequest();
				xhrimage.open("PUT", imageurl);
				xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
				xhrimage.onloadend = (res) => {
					if (res.target.status == 200) {
						image.getBase64Async('image/png').then(thumbnailb64 => {
							let libraryrecipe = data.find(d => d.LIBRARY_GUID === libraryDetails.id);
							let libraryID = libraryrecipe && libraryrecipe.LIBRARY_ID ? libraryrecipe.LIBRARY_ID : library_id;
							libraryDetails.library_id = libraryID;
							libraryDetails.crc = md5(b64, "base64");
							libraryDetails.isEdit = libraryrecipe ? true : false;
							libraryDetails.initialMethod = "Upload";
							libraryDetails.fileType = type;
							localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));

							let resourceDetails = [];
							let resource = {};
							resource.fileFormat = IMAGE_FILE_FORMAT;
							resource.fileName = LIBRARY_NAME;
							resource.fileSize = IMAGE_FILE_SIZE;
							resource.isDefault = false;
							resource.s3FileName = imagefilekey;
							resource.s3Url = UploadedImages3url;
							resource.type = 'image';
							resource.thumbnailData = thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, "");
							resource.imageData = '';
							resourceDetails.push(resource);
							localStorage.setItem("resourceDetails", JSON.stringify(resourceDetails));

							this.props.history.push({
								pathname: "/UploadSelectDevice",
								previouspath: "/libraryList"
							});
						});
					}
				};
				xhrimage.send(imageBuffer);
			}
		};
		xhrthumb.send(thumbnailbuffer);
		return { LIBRARY_NAME, IMAGE_FILE_FORMAT, IMAGE_FILE_SIZE };
	}

	handleRecipeImage = (imageBuffer, LIBRARY_NAME, IMAGE_FILE_FORMAT, IMAGE_FILE_SIZE, b64, type, data) => {
		return new Promise(async (resolve, _) => {

			const { thumbnailbuffer, image, imageurl, library_id, thumbnailurl, UploadedImages3url } = await this.uploadImageForThumbnailBuffer(imageBuffer, LIBRARY_NAME, IMAGE_FILE_FORMAT, `${GET_LIBRARY_PRESIGNED_URL}`);

			var xhrthumb = new XMLHttpRequest();
			xhrthumb.open("PUT", thumbnailurl);
			xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
			xhrthumb.onloadend = (res) => {
				if (res.target.status == 200) {
					var xhrimage = new XMLHttpRequest();
					xhrimage.open("PUT", imageurl);
					xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
					xhrimage.onloadend = (res) => {
						if (res.target.status == 200) {
							image.getBase64Async('image/png').then(thumbnailb64 => {
								let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
								libraryDetails = libraryDetails ? libraryDetails : {};
								let libraryrecipe = data.find(d => d.LIBRARY_GUID === libraryDetails.id);
								let libraryID = libraryrecipe && libraryrecipe.LIBRARY_ID ? libraryrecipe.LIBRARY_ID : library_id;
								let imageData = {};
								imageData.imagefileName = LIBRARY_NAME + "." + IMAGE_FILE_FORMAT;
								imageData.imagefileext = IMAGE_FILE_FORMAT;
								imageData.imagefileNamewithOutExt = LIBRARY_NAME;
								imageData.imagefileSize = IMAGE_FILE_SIZE;
								imageData.imagefilelength = LIBRARY_NAME.length;
								imageData.crc = md5(b64, "base64");
								imageData.imageS3Url = UploadedImages3url;
								libraryDetails.imageData = imageData;
								libraryDetails.id = libraryrecipe.LIBRARY_GUID;
								libraryDetails.recipeName = libraryrecipe.LIBRARY_ID;
								libraryDetails.isEdit = libraryrecipe ? true : false;
								libraryDetails.library_id = libraryID;
								libraryDetails.isImageChanged = false;
								libraryDetails.initialMethod = "Upload";
								libraryDetails.fileType = type;

								localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
								localStorage.setItem("libraryImage", thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
								this.props.history.push({
									pathname: "/assignLibraryToTopology",
									previouspath: "/libraryList"
								});
							});
						}
					};
					resolve(xhrimage.send(imageBuffer));
				}
			};
			resolve(xhrthumb.send(thumbnailbuffer));
		})
	}

	handleMerryChefRecipe(UpdatedJSONObj, libraryFiles, libraryData, LIBRARY_NAME, IMAGE_FILE_FORMAT, imageBuffer, IMAGE_FILE_SIZE) {
		UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
		UpdatedJSONObj && UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.map((item, i) => {
			libraryData[i] = {};
			libraryData[i].index = i;
			libraryData[i].temp = item.actions.set_point.value + item.actions.set_point.units.replace('deg', '');
			let total = 0;
			if (item.duration) {
				let duration = item.duration.replace('PT', '');
				let minutes, seconds = 0;
				if (duration.includes('M')) {
					minutes = duration.substr(0, duration.indexOf('M'));
				}
				if (duration.includes('S')) {
					seconds = duration.substr(duration.indexOf('M') + 1, duration.indexOf('S') - 3);
				}
				total = minutes + ':' + seconds;
			}
			libraryData[i].time = total;
			libraryData[i].fan = item.actions.fan;
			libraryData[i].power = item.actions.microwave;
			libraryData[i].paramDelete = '';
			libraryData[i].instruction = item.actions.prompt.message.en_GB;
			libraryData[i].instructionShowid = '';
			libraryData[i].instructionDelete = '';
			libraryData[i].instructionVisible = '';
		});

		let libraryDetails = {};
		libraryDetails.id = UpdatedJSONObj.id;
		libraryDetails.settings = {};
		libraryDetails.settings.portion = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.portion : 1;
		libraryDetails.settings.state = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.state : "ambient";
		libraryDetails.settings.weight = UpdatedJSONObj.settings ? UpdatedJSONObj.settings.weight : 50;
		let imagename = UpdatedJSONObj && UpdatedJSONObj.image ? UpdatedJSONObj.image.replace('Resources/Images/', '') : '';
		let imageextSplit = imagename.split('.');
		LIBRARY_NAME = UpdatedJSONObj.name ? UpdatedJSONObj.name.en_GB : "";
		IMAGE_FILE_FORMAT = imageextSplit[1];
		let fileSize = Buffer.byteLength(imageBuffer);
		IMAGE_FILE_SIZE = (fileSize / 1048576);
		libraryDetails.recipeType = "Recipe";
		localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
		return { UpdatedJSONObj, LIBRARY_NAME, IMAGE_FILE_FORMAT, IMAGE_FILE_SIZE };
	}

	getFormatedTime(time) {
		let total = 0;
		if (time) {
			let duration = time.replace('PT', '');
			let minutes, seconds = 0;
			if (duration.includes('M')) {
				minutes = duration.substr(0, duration.indexOf('M'))
			}
			if (duration.includes('S')) {
				seconds = duration.substr(duration.indexOf('M') + 1, duration.indexOf('S') - 3)
			}
			total = minutes + ':' + seconds;
		}
		return total;
	}

	OnCancelLibrary = () => {
		$('#ConfirmationOfImportModal').modal('hide');
	}

	OnDeleteLibrary = async () => {
		let { data, getLibraryDetails } = this.state;
		this.setState({
			loaderIconVisible: true
		})
		let selectedLibraryIds = await this.getSelectedIds('ID');
		let postData = {
			libraryIds: selectedLibraryIds
		}

		this.putData(postData, MenuUploadAPI.getLibraryList, HTTP_METHOD.DELETE).then(response => {
			if (response && response.data && response.data.message) {
				if (response.data.httpCode === "HTTP_200") {
					let libraries = [...this.state.data];
					let unAssignedUnits = libraries.filter(item => item.checked !== true);
					if (unAssignedUnits && getLibraryDetails) {
						if (unAssignedUnits.length != getLibraryDetails.length) {
							getLibraryDetails.splice(0, getLibraryDetails.length, ...unAssignedUnits);
						}
					}
					this.setState({
						data: unAssignedUnits,
						getLibraryDetails: getLibraryDetails && getLibraryDetails.length === 0 ? data : getLibraryDetails,
						cookbookCount: 0,
						recipeCount: 0,
						readyRecipeCount: 0,
						settingsCount: 0,
						legacyCount: 0,
						totalfilesize: 0,
						loaderIconVisible: false,
						isSelectAllLibrary: false,
						isIndeterminate: false,
						isAssign: true,
						isEdit: false,
						isRetrieve: true,
						isUpload: true,
						isDownload: false,
						isDelete: false,
						isCopy: false,
						success_modal: {
							open: true,
							message: response.data.stringID || response.data.message
						}
					});
				}
			}
		}).catch(err => {
			this.showError(err);
		});
	}

	putData = (postData, url, method) => {
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				headers: {},
				data: postData
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== "HTTP_200") {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	getSelectedIds = (key) => {
		let libraries = [...this.state.data];
		let assingUnits = [];
		if (libraries && libraries.length > 0) {
			for (let index = 0; index < libraries.length; index++) {
				if (libraries[index].checked == true) {
					assingUnits.push(libraries[index][key]);
				}
			}
		}
		return assingUnits;
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				let { body } = data;
				if (body) {
					this.openErrorModal(body.message);
				}
			}
		} else {
			this.openErrorModal("KC1065");
		}
	}

	openErrorModal = (message) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: true,
				message
			}
		});
	}


	onSuccessCloseModal = () => {
		const { history } = this.props;
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			}
		})
		history.push(`/libraryList`);
	}

	closeModel = () => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ''
			}
		})
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	updateSize() {
		const { totalfilesize } = this.state;
		let widthPercent = '0%';
		if (totalfilesize <= 80) {
			widthPercent = (totalfilesize * 1.25) + '%';
		} else if (totalfilesize > 80) {
			widthPercent = '100%';
		} return <div>
			<div className="maxFileSize">
				<div className={(totalfilesize > 80) ? "progress-bar errorUpload" : "progress-bar "} role="progressbar"
					style={{ width: widthPercent }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
					{this.roundingTheSize(totalfilesize)} <FormattedMessage id="KC1498" /></div>
				<FormattedMessage id="KC2064" /></div>
		</div>
	}

	roundingTheSize(totalfilesize) {
		if (totalfilesize >= 0)
			return Math.floor(totalfilesize * 100) / 100;
		else
			return 0;
	}

	onUploadTypeSelect = (event) => {
		this.setState({
			uploadOption: event.target.value
		});
	}

	onCloseDetailsPopup = () => {
		this.setState({
			assignedTopologyDetails: null,
		})
	}

	onSaveDetailsPopup = (event) => {
		const { data } = this.state;
		data.forEach((item) => {
			if (item.ID === event.ID) {
				item.DESCRIPTION = event.DESCRIPTION;
				item.ASSIGNED_CUSTOMER_GROUP = event.ASSIGNED_CUSTOMER_GROUP;
				item.UPDATED_ON = event.UPDATED_ON;
				item.UPDATED_BY = event.UPDATED_BY
			}
		})
		this.setState({
			data
		})
	}

	OnEdit_For_Setting_Library = async () => {
		this.setState({
			loaderIconVisible: true
		});
		let { data, getLibraryDetails, selected_item } = this.state;

		// if (data && getLibraryDetails) {
		//     if (data.length != getLibraryDetails.length) {
		//         let difference = this.getArrayDifferences(data, getLibraryDetails);
		//         if (difference && difference.length > 0) {
		//             data.splice(0, data.length, ...getLibraryDetails);
		//         }
		//     }
		// }
		let headerpresigned = {
			'Content-Type': 'application/json',
			'library_id': encodeURIComponent(selected_item.SETTINGS_LIBRARY_ID),
			'library_isedit': true,
			'encryption': "true"
		}
		if (localStorage.getItem("brandName") !== "MERRYCHEF") {
			delete headerpresigned.encryption;
			headerpresigned.library_id = selected_item.SETTINGS_LIBRARY_ID;
		}
		await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
			headers: headerpresigned,
			data: {}
		}).then(response => {
			if (response && response.data) {
				let file_path = response.data.presignedURL;
				console.log("file_path", file_path)
				var libraryFiles = [];
				var b64 = "";
				var imageBuffer = null;
				try {
					request.get({ url: file_path, encoding: null }, async (err, res, body) => {
						var zip = new AdmZip(body);
						var zipEntries = zip.getEntries();
						let UpdatedJSONObj = "";
						console.log("zip", zip)
						console.log("zipEntries", zipEntries)

						let validzipEntries = zipEntries.filter(z => z.entryName.charAt(z.entryName.length - 1) !== "/");
						console.log("validzipEntries", validzipEntries)

						validzipEntries.forEach((entry, i) => {
							if (localStorage.getItem("brandName") === "MERRYCHEF") {
								libraryFiles[i] = zip.readFile(entry);

								if (!entry.entryName.includes('.json') && !entry.entryName.includes('.wav')) {
									imageBuffer = Buffer.from(libraryFiles[i]);
									b64 = imageBuffer.toString('base64');
									let fileScreenSaverExt = entry.entryName.substring(entry.entryName.lastIndexOf('.') + 1);
									localStorage.setItem("libraryScreenSaverExt", fileScreenSaverExt);
								}
							}
						});

						if (selected_item.BRAND_NAME === "MERRYCHEF") {
							if (libraryFiles[0] && (selected_item.TYPE === "Recipe" || selected_item.TYPE === "ReadyRecipe" || selected_item.TYPE === "Settings")) {
								let libraryData = [];

								libraryData = JSON.parse(libraryFiles[0]);
								let schedulers = libraryData.scheduler;
								if (!Array.isArray(schedulers)) {
									libraryData.scheduler = []
								}
								else {
									schedulers.map((parent, i) => {
										parent.configurations.map((item, j) => {
											if (item.type !== "Cold") {
												item.id = j + 1;
											}
											else {
												item.id = 12;
											}
										})
									})
								}

								localStorage.setItem("schedulerDetails", JSON.stringify(libraryData.scheduler))

								localStorage.setItem("librarySettingsData", JSON.stringify(libraryData));
								try {
									localStorage.setItem("defaultLibraryData", JSON.stringify(libraryData));
								}
								catch (err) {
									localStorage.setItem("defaultLibraryData", LZString.compress(JSON.stringify(libraryData)));
								}
							}


							//Settings
							let UpdatedJSONObj = libraryFiles[0] ? JSON.parse(libraryFiles[0]) : {};
							let libraryDetails = {};
							libraryDetails.id = UpdatedJSONObj.id;
							libraryDetails.library_id = selected_item.LIBRARY_ID;
							libraryDetails.settingName = selected_item.LIBRARY_NAME;
							libraryDetails.crc = md5(b64, "base64");
							libraryDetails.isEdit = true;
							libraryDetails.settings = UpdatedJSONObj.sounds;
							libraryDetails.day = UpdatedJSONObj.scheduler && UpdatedJSONObj.scheduler.length > 0 ? UpdatedJSONObj.scheduler[0].day : "Monday";
							libraryDetails.initialMethod = "Edit"

							localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
							localStorage.setItem("libraryImage", b64);

							this.props.history.push({
								pathname: "/cookbookEditor",
								previouspath: "/libraryList"
							});

						}
						this.setState({
							loaderIconVisible: false
						});
					});
				}
				catch (ex) {
					this.setState({
						loaderIconVisible: false
					});
				}
			}
		})
			.catch(err => {
				this.setState({
					loaderIconVisible: false
				});
			});
	}

	render() {
		let { data: dataList, isSelectAllLibrary, isIndeterminateSelect, isRetrieve, isUpload, isDownload, isAssign, isEdit, isDelete, isCopy, error_modal, success_modal, loaderIconVisible, sortColumn, created_by, last_edited_by, cookbookCount, recipeCount, readyRecipeCount, settingsCount, legacyCount, totalfilesize, uploadOption, actionType, assignedTopologyDetails, upload_filename, errorUploadFileName, isUnitAvailable, selected_item, description, errorCopyName } = this.state;
		dataList = dataList && dataList.length > 0 ? dataList : [];
		const { formatMessage } = this.props.intl;

		let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
		let libraryAuthFeature = authService.getFeature("/libraryList");
		let readyLibraryAuthFeature = authService.getFeature("/readyRecipeLibraryList");
		let columns = [];
		if (localStorage.getItem("brandName") === "MERRYCHEF") {
			columns = [
				{ path: "", label: "#" },
				{ path: "TYPE", labelStringID: "KC0102" },
				{ path: "BRAND_NAME", labelStringID: "KC0062" },
				{ path: "", labelStringID: "KC0649" },
				{ path: "LIBRARY_NAME", labelStringID: "KC0241" },
				{ path: "UPDATED_ON", labelStringID: "KC2005" },
				{ path: "", label: "" },
				{ path: "LIBRARY_FILE_SIZE", labelStringID: "KC0682" },
				{ path: "", labelStringID: "KC0325" },
				{ path: "", label: "", isCheckBox: true, isCheckBoxActive: libraryAuthFeature.is_editable },
			];
		}
		else {
			columns = [
				{ path: "", label: "#" },
				{ path: "TYPE", labelStringID: "KC0102" },
				{ path: "", labelStringID: "KC0680" },
				{ path: "BRAND_NAME", labelStringID: "KC0062" },
				{ path: "", labelStringID: "KC0649" },
				{ path: "LIBRARY_NAME", labelStringID: "KC0241" },
				{ path: "UPDATED_ON", labelStringID: "KC2005" },
				{ path: "", label: "" },
				{ path: "LIBRARY_FILE_SIZE", labelStringID: "KC0682" },
				{ path: "", labelStringID: "KC0325" },
				{ path: "", label: "", isCheckBox: true, isCheckBoxActive: libraryAuthFeature.is_editable },
			];
		}

		if (sortColumn.path === "UPDATED_ON") {
			if (sortColumn.order === "desc") {
				dataList = dataList.sort((d1, d2) => new Date(d2.UPDATED_ON) - new Date(d1.UPDATED_ON));
			}
			else {
				dataList = dataList.sort((d1, d2) => new Date(d1.UPDATED_ON) - new Date(d2.UPDATED_ON));
			}
		}
		else if (sortColumn.path === "LIBRARY_FILE_SIZE") {
			dataList = orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path]], [sortColumn.order]);
		}
		else {
			dataList = orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path].toString().toLowerCase()], [sortColumn.order]);
		}
		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper" class="withSearchBar">
						<div className="menuUpdateWrapper">
							<div className="menuFilterHeader">
								<ul>
									<li>
										<Link to="/libraryList" title={formatMessage({ id: 'KC0658' })} className="active"><FormattedMessage id="KC0658" /></Link>
										{(localStorage.getItem("brandName") === "GARLAND") && isUnitAvailable && libraryAuthFeature.is_editable === 1 ?
											<Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })}><FormattedMessage id="KC1180" /></Link> :
											(localStorage.getItem("brandName") !== "MERRYCHEF" && localStorage.getItem("brandName") !== "GARLAND") ?
												<Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })}><FormattedMessage id="KC1180" /></Link> : ''}
										{/* Commented Ready Recipe & Retrive from device, since its not implemented in device side. Will be enabled in furture*/}
										{/* {localStorage.getItem("brandName") === "MERRYCHEF" && <Link to="/readyRecipeLibraryList" title="Ready Recipes" className={readyLibraryAuthFeature.is_view_only === 1 || readyLibraryAuthFeature.is_editable === 1 ? "" : "displaynone"}>Ready Recipes</Link>} */}
										{loginInfo.rolename !== "Public Admin" && <Link to="/menuUpdateStatus" title={formatMessage({ id: 'KC0659' })}><FormattedMessage id="KC0659" /></Link>}
									</li>
									<li>
										{localStorage.getItem("brandName") === "MERRYCHEF" && <button type="button" title={formatMessage({ id: 'KC1182' })} className={loginInfo.rolename === "Public Admin" ? "displaynone" : libraryAuthFeature.is_editable === 1 ? `btn btn-default-text cbfileretrieval ${isRetrieve ? "" : "disabled"}` : "btn btn-default-text cbfileretrieval disabled"} onClick={(e) => this.OnRetrieveLibrary()}>&nbsp;</button>}
										{(localStorage.getItem("brandName") === "MERRYCHEF") && isUnitAvailable && libraryAuthFeature.is_editable === 1 ?
											<button type="button" title={formatMessage({ id: 'KC0685' })} className="btn recipeEditor" onClick={() => this.OnRecipeEditor()}>&nbsp;</button> :
											(localStorage.getItem("brandName") !== "MERRYCHEF" && localStorage.getItem("brandName") !== "GARLAND" && localStorage.getItem("brandName") !== "FRYMASTER") ?
												<button type="button" title={formatMessage({ id: 'KC0685' })} className="btn recipeEditor" onClick={() => this.OnRecipeEditor()}>&nbsp;</button> : ''}
										<button type="button" title={formatMessage({ id: 'KC1183' })} className={libraryAuthFeature.is_editable === 1 ? `btn btn-default-text menuUpload ${isUpload ? "" : "disabled"}` : "btn btn-default-text menuUpload disabled"} onClick={(e) => { this.onUploadLibraryCheck() }}>&nbsp;</button>
										<button type="button" title={formatMessage({ id: 'KC1184' })} className={libraryAuthFeature.is_editable === 1 ? `btn btn-default-text menuDownload ${isDownload ? "" : "disabled"}` : "btn btn-default-text menuDownload disabled"} onClick={(e) => this.OnDownloadLibrary()}>&nbsp;</button>
										<button type="button" title={formatMessage({ id: 'KC2424' })} className={((cookbookCount || recipeCount || readyRecipeCount || settingsCount || legacyCount) && totalfilesize <= 80 && libraryAuthFeature.is_editable === 1) ? `btn btn-default-text transfertoSG ${isAssign ? "" : "disabled"}` : "btn btn-default-text transfertoSG disabled"} onClick={() => this.OnAssignLibrary()}>&nbsp;</button>
										<button type="button" title={formatMessage({ id: 'KC2423' })} className={libraryAuthFeature.is_editable === 1 ? `btn btn-default-text menuEdit ${isEdit ? "" : "disabled"}` : "btn btn-default-text menuEdit disabled"} onClick={() => this.OnEditLibrary()}>&nbsp;</button>
										{localStorage.getItem("brandName") === "MERRYCHEF" && <button type="button" title={formatMessage({ id: 'KC2251' })} className={libraryAuthFeature.is_editable === 1 ? `btn btn-default-text createCopy ${isCopy ? "" : "disabled"}` : "btn btn-default-text createCopy disabled"} onClick={() => this.onCopyModal()}>&nbsp;</button>}
										<button type="button" title={formatMessage({ id: 'KC1187' })} className={libraryAuthFeature.is_editable === 1 ? `btn btn-default-text menuDelete ${isDelete ? "" : "disabled"}` : "btn btn-default-text menuDelete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal" onClick={() => { }}>&nbsp;</button>
									</li>
								</ul>
							</div>
							<form className="menuLibraryTableForm">
								<div className={localStorage.getItem("brandName") === "MERRYCHEF" ? "menuLbryMcfTable" : "menuLibraryTable"}>
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.selectAllLibrary}
										columns={columns}
										isIndeterminate={isIndeterminateSelect}
										isSelectAll={isSelectAllLibrary} />
								</div>
								<div className="menuLibrarySrlTable">
									<div className={localStorage.getItem("brandName") === "MERRYCHEF" ? "menuLbryMcfTable" : "menuLibraryTable"}>
										{
											dataList.map((item, i) => {
												return (
													<ul className="tBody" key={i}>
														<li>{i + 1}</li>
														<li><span className="menuType"><img src={require(item.TYPE === "ReadyRecipe" ? "../../../images/icons/readyRecipD.png" : item.TYPE === "Recipe" ? "../../../images/icons/recipeS.png" : item.TYPE === "Settings" ? "../../../images/icons/settingLG.png" : item.TYPE === "Legacy" ? "../../../images/icons/legacyMenuD.png" : "../../../images/icons/manualS.png")} title={item.TYPE === "ReadyRecipe" ? formatMessage({ id: 'KC0640' }) : item.TYPE === "Recipe" ? formatMessage({ id: 'KC1381' }) : item.TYPE === "Settings" ? formatMessage({ id: 'KC1190' }) : item.TYPE === "Legacy" ? formatMessage({ id: 'KC1113' }) : formatMessage({ id: 'KC2009' })} alt="" /></span></li>
														{localStorage.getItem("brandName") === "MERRYCHEF" ? "" : <li><span className="menuPicture">{item.TYPE === "Settings" ? "" : item.IMAGE_S3_SIGNED_URL ? <img src={item.IMAGE_S3_SIGNED_URL} /> : localStorage.getItem("brandName") === "GARLAND" ? item.TYPE === "Recipe" ? <img src={require("../../../images/recipecreation/Default Recipe.bmp")} /> : <img src={require("../../../images/recipecreation/Default Menu.png")} /> : ""}</span></li>}
														<li className={item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated" || item.UPLOAD_RETRIEVE_STATUS === "FAILED" ? "disabled" : ""}>{item.BRAND_NAME}&nbsp;
															<div className="menuSListSM">{item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.NAME).toString() : ''}</div>
															<div className="menuSListSMF">{item.LIBRARY_NAME}</div>
															<div className="menuSListRS">{item.UPDATED_ON} {item.ASSIGNED_COUNT ? "" : <span className="badge badge-primary"><FormattedMessage id="KC2112" /></span>}</div>
															<div className="menuSListAS">{item.LIBRARY_FILE_SIZE ? item.LIBRARY_FILE_SIZE.toFixed(2) : "0.00"} <FormattedMessage id="KC1498" /></div>
														</li>
														<li className={item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated" || item.UPLOAD_RETRIEVE_STATUS === "FAILED" ? "disabled" : ""}>{item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.NAME).toString().replace(/,/g, ', ') : ''}</li>
														<li className={item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated" || item.UPLOAD_RETRIEVE_STATUS === "FAILED" ? "disabled" : ""}>{item.LIBRARY_NAME}</li>
														<li className={item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated" || item.UPLOAD_RETRIEVE_STATUS === "FAILED" ? "disabled" : ""}>{item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated" || item.UPLOAD_RETRIEVE_STATUS === "FAILED" ? "-- --" : item.UPDATED_ON}</li>
														<li>{item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated" || item.UPLOAD_RETRIEVE_STATUS === "FAILED" || item.ASSIGNED_COUNT ? "" : <span className="badge badge-primary">new</span>}</li>
														<li className={item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated" || item.UPLOAD_RETRIEVE_STATUS === "FAILED" ? "disabled" : ""}>{item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated" || item.UPLOAD_RETRIEVE_STATUS === "FAILED" ? "-- --" : item.LIBRARY_FILE_SIZE ? item.LIBRARY_FILE_SIZE.toFixed(2) : "0.00"}</li>
														<li>
															{(item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated") && item.uploadType === "Upload" ? <button type="button" class="btn scheduleDetails" data-toggle="modal" data-target="#uploadModal">&nbsp;</button>
																: (item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated") && item.uploadType === "Retrieve" ? <button type="button" class="btn scheduleDetails" data-toggle="modal" data-target="#retrieveModal">&nbsp;</button>
																	: (item.UPLOAD_RETRIEVE_STATUS === "In Progress" || item.UPLOAD_RETRIEVE_STATUS === "Initiated") && item.uploadType === "Copy" ? <button type="button" class="btn scheduleDetails" data-toggle="modal" data-target="#copyModal">&nbsp;</button>
																		: item.UPLOAD_RETRIEVE_STATUS === "FAILED" && item.uploadType === "Copy" ? <span><img alt="Failed" title='Failed' src={require("../../../images/icons/ErrorD.svg").default} /></span>
																			: item.UPLOAD_RETRIEVE_STATUS === "FAILED" ? <button type="button" class="btn btn-danger" onClick={() => this.OnTryAgain(item)}><FormattedMessage id="KC1847" /></button>
																				: <button type="button" className="btn btn-default-text menuInfo" data-toggle="modal" data-target="#detailsInfoModal" onClick={() => this.OnToggleDetails(item)}>&nbsp;</button>}
														</li>
														<li>
															<div className={libraryAuthFeature.is_editable == 1 && (item.UPLOAD_RETRIEVE_STATUS === null || item.UPLOAD_RETRIEVE_STATUS === "SUCCEEDED" || item.UPLOAD_RETRIEVE_STATUS === "FAILED") ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"} >
																<input id={item.ID} name="menuLibraryList" type="checkbox" value={item.checked} checked={item.checked} onChange={(e) => this.onSelectLibrary(e, item)} />
																<label htmlFor={item.ID}></label>
															</div>
														</li>
													</ul>
												)
											})
										}
									</div>
									<NoRecordsFound loaderIconVisible={loaderIconVisible} length={dataList !== undefined ? dataList.length : 0} classname={'norecordsfoundbluetext'} />
								</div>

							</form>
							{/* <!-- Assign -->  */}
							<div className={loginInfo.rolename === "Public Admin" ? "displaynone" : "menuAssignFTable"}>
								<ul>
									<li>
										<div className={localStorage.getItem("brandName") === "MERRYCHEF" ? "displaynone" : "menuAssignL"}>
											<ul>
												<li>
													<div className={cookbookCount ? "dragedDetailsTable active" : "dragedDetailsTable"}>
														<span className="menuManualCount">{cookbookCount}</span>
														<span className="menuManualText">{localStorage.getItem('brandName') === "GARLAND" ? "Menu" : <FormattedMessage id="KC1188" />}</span>
													</div>
												</li>
												<li>
													<div className={recipeCount ? "dragedDetailsTable active" : "dragedDetailsTable"}>
														<span className="menuRecipeCount">{recipeCount}</span>
														<span className="menuRecipeText"><FormattedMessage id="KC1189" /></span>
													</div>
												</li>
												{localStorage.getItem('brandName') === "GARLAND" ? null : <li>
													<div className={settingsCount ? "dragedDetailsTable active" : "dragedDetailsTable"}>
														<span className="menuSettingsCount">{settingsCount}</span>
														<span className="menuSettingsText"><FormattedMessage id="KC1190" /></span>
													</div>
												</li>}
												{/* <li>
													<div className={legacyCount ? "dragedDetailsTable active" : "dragedDetailsTable"}>
														<span className="menuRecipeCount">{legacyCount}</span>
														<span className="menuRecipeText">Legacys</span>
													</div>
												</li> */}
											</ul>
										</div>
										{localStorage.getItem("brandName") === "MERRYCHEF" ? "" : this.updateSize()}
									</li>
								</ul>
							</div>
							{/* <!-- //Assign --> */}
							<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
							<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
						</div>
					</div>
					<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" history={this.props.history} />
				</div>
				{/* <!-- Info Modal --> */}
				{assignedTopologyDetails ? <CommonDetailViewEdit detailsProps={assignedTopologyDetails} onSave={this.onSaveDetailsPopup} onClose={this.onCloseDetailsPopup} thumbnail={assignedTopologyDetails.TYPE === "Settings" ? "" : assignedTopologyDetails.IMAGE_S3_SIGNED_URL ? assignedTopologyDetails.IMAGE_S3_SIGNED_URL : ""} /> : null}
				{/* <!-- //Info Modal --> */}

				{/* <!-- Delete Confirmation Modal --> */}
				<div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="deleteConfirmation">
									<FormattedMessage id="KC0684" />
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancelLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580" /></button>
								<button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDeleteLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611" /></button>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- //Delete Confirmation --> */}
				{/* <!-- Select Flow Modal --> */}
				<div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="ConfirmationOfImportModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-body">
								<div class="confirmationYesNo">
									<h5><FormattedMessage id="KC0241" /> *</h5>
									<div class="input-group">
										<input class="form-control" type="text" maxLength="20" placeholder={uploadOption === "Settings" ? formatMessage({ id: 'KC1277' }) : formatMessage({ id: 'KC0686' })} value={upload_filename} onChange={(e) => this.onUploadNameChange(e)} />
										<div className="errorMsg">{errorUploadFileName != '' ? <FormattedMessage id={errorUploadFileName} /> : ''}</div>
									</div>
								</div>
								<div class="confirmationYesNo">
									<h5><FormattedMessage id="KC1191" /> {actionType === 'Retrieve' ? "retrieve" : "upload"}</h5>
									<div class="uploadOption">
										<div class="customRadioBtn">
											<input id="Cookbook" type="radio" name="uploadOption" value="CookBook" checked={uploadOption === "CookBook" ? true : false} onClick={this.onUploadTypeSelect} />
											<label for="Cookbook" value="CookBook">{localStorage.getItem("brandName") === "FRYMASTER" ? <FormattedMessage id="KC0676" /> : <FormattedMessage id="KC2009" />}</label>
										</div>
										<div class="customRadioBtn">
											<input id="Settings" type="radio" name="uploadOption" value="Settings" checked={uploadOption === "Settings" ? true : false} onClick={this.onUploadTypeSelect} />
											<label for="Settings" value="Settings"><FormattedMessage id="KC1190" /></label>
										</div>
									</div>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary noBtn" id="CancelLiberary" type="button" onClick={this.OnCancelLibrary}><FormattedMessage id="KC0021" /></button>
								<button class="btn btn-secondary yesBtn" type="button" onClick={() => { this.OnUploadLibrary() }}>{actionType === 'Retrieve' ? "Retrieve" : <FormattedMessage id="KC0683" />}</button>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- // Select Flow Modal --> */}
				<div class="modal fade confirmationRMModal" data-keyboard="true" data-backdrop="static" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="confirmationRMModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-header modal-header-danger">
								<FormattedMessage id="KC2255" /> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
							</div>
							<div class="modal-body">
								<div class="reportStatusModal">
									<span class="rmStatusIcon"><img src={require("../../../images/icons/iconReportInProgress.svg").default} /></span>
									<h6><FormattedMessage id="KC2256" /></h6>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0777" /></button>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- // Copy Flow Modal --> */}
				<div class="modal fade confirmationRMModal" data-keyboard="true" data-backdrop="static" id="copyModal" tabindex="-1" role="dialog" aria-labelledby="confirmationRMModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-header modal-header-danger">
								<FormattedMessage id="KC2382" /> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
							</div>
							<div class="modal-body">
								<div class="reportStatusModal">
									<span class="rmStatusIcon"><img src={require("../../../images/icons/iconReportInProgress.svg").default} /></span>
									<h6><FormattedMessage id="KC2381" /></h6>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0777" /></button>
							</div>
						</div>
					</div>
				</div>
				<div class="modal fade confirmationRMModal" data-keyboard="true" data-backdrop="static" id="retrieveModal" tabindex="-1" role="dialog" aria-labelledby="confirmationRMModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-header modal-header-danger">
								<FormattedMessage id="KC2271" /> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
							</div>
							<div class="modal-body">
								<div class="reportStatusModal">
									<span class="rmStatusIcon"><img src={require("../../../images/icons/iconReportInProgress.svg").default} /></span>
									<h6><FormattedMessage id="KC2272" /></h6>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0777" /></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};

const mapStateToProps = state => {
	return {
		searchData: state.advanceSearch.searchData
	}
}

const mapDispatchToProps = (dispatch) => {
	return bindActionCreators({
		searchVisible, searchText
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LibraryList));
