import $ from 'jquery';
import React, { useEffect, useState, useRef } from 'react';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import { headerRow } from './plan';
import PaymentCard from '../SubscriptionForm/paymentCard';
import DirectDebitCard from '../SubscriptionForm/directDebitCard';
import { BaseService, subscriptionManagement,DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';

const paymentTypeImage = {
    'visa': require('../../images/cardtype/visa.png'),
    'american_express': require('../../images/cardtype/american_express.png'),
    'master': require('../../images/cardtype/mastercard.png'),
    'discover': require('../../images/cardtype/discover.png'),
    'diners_club': require('../../images/cardtype/dinersclub.png'),
    'jcb': require('../../images/cardtype/jcb.png'),
    'unionpay': require('../../images/cardtype/unionpay.png'),
    'cirrus': require('../../images/cardtype/cirrus.png'),
    'delta': require('../../images/cardtype/delta.png'),
    'direct': require('../../images/cardtype/direct.png'),
    'electronvisa': require('../../images/cardtype/electronvisa.png'),
    'maestro': require('../../images/cardtype/maestro.png'),
    'paypal': require('../../images/cardtype/paypal.png'),
    'solo': require('../../images/cardtype/solo.png'),
    'switch': require('../../images/cardtype/switch.png'),
    'westernunion': require('../../images/cardtype/westernunion.png')
}

const Payments = (props) => {
    const { formatMessage } = props.intl;
    const [loaderIconVisible, setLoaderIconVisible] = useState(true);
    const [loaded, setLoaded] = useState(false);
    const [noPaymentAvailable, setNoPaymentAvailable] = useState(false);
    const [paymentDetails, setPaymentDetails] = useState({});
    const [editView, setEditView] = useState(false);
    const [isAuth, setIsAuth] = useState(localStorage.getItem('Access-Token') ? true : false)
    const [cardType, setCardType] = useState('');
    const [currentChargifySite, setChargifySite] = useState({});
    const [showLoaderText, setShowLoaderText] = useState(false);
    const [errorText, setErrorText] = useState('');
    const creditcCard = useRef();

    useEffect(() => {
        var urlParams = new URLSearchParams(window.location.search);
        const encodedEmail = urlParams.get("euc");
        const decodedEmail = atob(encodedEmail);
        if (!isAuth) {
            props.history.push(`/login?redirectUri=payment&euc=${encodedEmail}`)
        } else {
            let user = localStorage.getItem('user');
            if (user !== decodedEmail && encodedEmail) {
                if (localStorage.getItem('isCookieAccepted')) {
                    localStorage.clear();
                    localStorage.setItem('isCookieAccepted', true);
                } else {
                    localStorage.clear();
                }
                props.history.push(`/login?redirectUri=payment&euc=${encodedEmail}`)
            }
        }
        $('body').removeClass("loginBg");
        $("div").removeClass("modal-backdrop fade show");
        getPaymentInfo();
        getChargifyApiKey();
        document.body.style.backgroundColor = "#F2F2F2";
        return () => {
        }
    }, []);

    const getChargifyApiKey = () => {
        return new Promise((res, rej) => {
            let url = DeviceRegistration.getChargifyApiKey;

            http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {}
            }).then(async response => {
                if (response.data && response.data.length) {
                    const envIDValue = localStorage.getItem('subscriptionDetails') && JSON.parse(localStorage.getItem('subscriptionDetails'))?.env_id ? JSON.parse(localStorage.getItem('subscriptionDetails'))?.env_id : 1
                    const envDet = response.data.find((i) => i.id === envIDValue);                    
                    setChargifySite(envDet)
                    //await this.loadKCUI(envId);
                    res(true);
                }
            }).catch(err => {
                res(false);
            });
        })
    }

    const getPaymentInfo = async () => {
        setLoaderIconVisible(true);
        await http.get(`${subscriptionManagement.paymentApi}?customerid=${localStorage.getItem("customerId")}`, {
            headers: {
                'Content-Type': 'application/json',
            },
            data: {}
        }).then(async response => {
            setLoaderIconVisible(false);
            if (response && response.data) {
                if (response?.data?.payment_profile) {
                    setPaymentDetails(response.data.payment_profile);
                    setNoPaymentAvailable(false);
                } else {
                    setNoPaymentAvailable(true)
                }
            }
        }).catch(err => {
            setLoaderIconVisible(false);
        });
    }

    const updatePaymentMethod = (token) => {        
        return new Promise(async (resolve, reject) => {
            setLoaderIconVisible(true)
            await http.post(`${subscriptionManagement.paymentApi}`, {
                "customerid": localStorage.getItem("customerId"),
                token,
                "isBillingAddressSame": true //(creditcCard.current.state.checkedValue).toString()
            },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(async response => {
                    if (response.data?.payment_profile) {
                        setPaymentDetails(response.data?.payment_profile)
                        $('#paymentSuccss').modal('show');
                        setLoaderIconVisible(false);
                    } else {
                        setLoaderIconVisible(false);
                        $('#paymentFaileddModal').modal('show');
                    }
                    resolve(response)
                }).catch(err => {
                    resolve(err)
                });
        });
    }

    const throwError = (err) => {
        setLoaderIconVisible(false);
        let errMsg = err;
        if (errMsg.message !== "Some fields are not valid.") {
            $('#paymentFaileddModal').modal('show');
        }
    }

    const functionOne = async () => {        
        setLoaderIconVisible(true);
        setShowLoaderText(true);
        setTimeout(functionTwo, 5000);
    }

    const functionTwo = async () => {
        setLoaderIconVisible(false);
        setShowLoaderText(false);
        await save();
    }

    const callChargifyApi = () => {
        return new Promise(async (res, rej) => {            
            if (cardType === 'creditCard') {
                setLoaderIconVisible(true);
            }
            try {
                const apiCall = await creditcCard.current.handleSubmit();
                res(apiCall);
            } catch (err) {
                setLoaderIconVisible(false);
                let errMsg = err;
                if (errMsg.message !== "Some fields are not valid.") {
                    $('#paymentFaileddModal').modal('show');
                }
                rej(err)
            }
        })
    }

    const setIsLoaded = (val) => {
        setLoaded(val)
    }

    const createCustomerInChargify = async (token) => {
        return new Promise(async (resolve, _) => {
            setLoaderIconVisible(true);
            const noSubcriptionAvl = localStorage.getItem("subscriptionId");
            await http.post(`${subscriptionManagement.createCustomoer}`, {
                "customer_id": localStorage.getItem("customerId"),
                token,
                "isBillingAddressSame": noSubcriptionAvl === 'null' ? 'false' : (creditcCard.current.state.checkedValue).toString(),
                "payment_type": cardType === "directDebit" ? "bank_account" : "credit_card",
            },
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            )
                .then(async response => {
                    await getPaymentInfo();
                    resolve(response)
                }).catch(err => {
                    setLoaderIconVisible(false);
                    resolve(err)
                });
        });
    }

    const getCustomerGroupdet = async () => {
        await http.get(BaseService.customer_Group_det,
            {
                headers: {
                    'customer_id': localStorage.getItem("customerId"),
                    'Content-Type': 'application/json'
                }, data: {},
            }).then(async response => {
                localStorage.setItem("chargifySubscriptionStatus", response.data.chargifySubscriptionStatus || 'Inactive');
                localStorage.setItem("subscriptionId", localStorage.getItem('subscriptionId') !== "" ? localStorage.getItem('subscriptionId') : response.data.subscriptionDetails.chargify_subscription_id ? response.data.subscriptionDetails.chargify_subscription_id : "");
                localStorage.setItem("subscriptionPlan", response.data.subscriptionDetails.kc_subscription_plan_id ? response.data.subscriptionDetails.kc_subscription_plan_id : "");
            }).catch(err => {
            });
    }

    const save = async () => {
        try {
            await callChargifyApi();
            const isExistingChargifyCustomer = localStorage.getItem("chargifySubscriptionStatus");
            if (isExistingChargifyCustomer === "Inactive") {
                try {
                    await createCustomerInChargify(creditcCard.current.state.token);
                    await getCustomerGroupdet();
                    $('#paymentSuccss').modal('show');
                    setLoaderIconVisible(false);
                } catch (error) {
                    setLoaderIconVisible(false);
                    let errMsg = error;
                    if (errMsg.message !== "Some fields are not valid.") {
                        $('#paymentFaileddModal').modal('show');
                    }
                }
            } else {
                await updatePaymentMethod(creditcCard.current.state.token);
            }
        } catch (error) {
            setLoaderIconVisible(false);
            let errMsg = error;             
            if (errMsg && errMsg.invalidFields && errMsg.invalidFields.length > 0) {
                let findErrorFld = 'Some fields are not valid.';
                const value = errMsg.invalidFields[0];
                if (value === 'firstName') {
                    findErrorFld = 'Invalid First Name';
                } else if (value === 'lastName') {
                    findErrorFld = 'Invalid Last Name';
                } else if (value === 'number') {
                    findErrorFld = 'Invalid Card Number';
                } else if (value === 'month' || value === 'year') {
                    findErrorFld = 'Invalid Expiration ' + value.charAt(0).toUpperCase() + value.slice(1);
                } else if (value === 'cvv') {
                    findErrorFld = 'Invalid CVV';
                } else if (value === 'address') {
                    findErrorFld = 'Invalid Address Line 1';
                } else if (value === 'country' || value === 'city' || value === 'State') {
                    findErrorFld = 'Invalid Country';
                    findErrorFld = 'Invalid ' + value.charAt(0).toUpperCase() + value.slice(1);
                } else if (value === 'zip') {
                    findErrorFld = 'Invalid ZIP Code';
                } else if (value === 'bankIban') {
                    findErrorFld = 'Invalid IBAN';
                } else if (value === 'accountHolderType') {
                    findErrorFld = 'Invalid Account Holder Type';
                } else if (value === 'bankName') {
                    findErrorFld = 'Invalid Bank Name';
                } else if (value === 'email') {
                    findErrorFld = 'Invalid Email Address';
                } else if (value === 'accountNumber') {
                    findErrorFld = 'Invalid Account Number';
                } else if (value === 'branchCode') {
                    findErrorFld = 'Invalid Sort Code';
                } else if (value === 'routingNumber') {
                    findErrorFld = 'Invalid Routing Number';
                } else if (value === 'accountType') {
                    findErrorFld = 'Invalid Account Type';
                }
                setErrorText(findErrorFld);
                $('#paymentFaileddModal').modal('show');
            }
        }
    }

    const setCardTypeUpdate = (event) => {
        setCardType(event);
    }

    const splitCardNumber = paymentDetails?.masked_card_number?.split('-');
    return (
        <div id="adminWrapper">
            <div className="searchToggleTransparent"></div>
            <div className="toggleTransparent"></div>
            <div className="sideNavToggleTransparent"></div>
            <div className="userToggleTransparent"></div>
            <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
                {showLoaderText && <div className='loadingProgressInText'></div>}
            </div>
            {isAuth ? <div id="page-content-wrapper">
                <div className="lightBgWrapper">
                    <div className="navBarXScroll">
                        <ul>
                            {headerRow(formatMessage, 'Payments')}
                        </ul>
                    </div>
                    {editView ? null : noPaymentAvailable ? <div className="tableSMViewW">
                        <div className="paymentsTable">
                            <h2><FormattedMessage id='KC2966' /></h2>
                            <div className="cardInfoDimLabel"><FormattedMessage id='KC2486' /></div>
                            <hr className='smRow' />
                            <div className="buttonWrapper alignCenter"><div className="subscriptioninfoD"><FormattedMessage id='KC2487' /></div></div>
                            <div className="buttonWrapper alignCenter"><button type="button" className="btn addPayment" onClick={() => 
                                {
                                setEditView(true)
                                setCardTypeUpdate('creditCard')
                                }
                                }><FormattedMessage id='KC2488' /></button></div>
                        </div>
                    </div> :
                        <div className="tableSMViewW">
                            <div className="paymentsTable">
                                <h2><FormattedMessage id='KC2966' /></h2>
                                <div className="cardInfoDimLabel"><FormattedMessage id='KC2486' /></div>
                                <hr className='smRow' />
                                {paymentDetails ? <div className="paymentFDetailsO">
                                    <div className="billingInfoFirstSec">
                                        <div className='cardInfoLabel'>{paymentDetails.payment_type === "bank_account" ? <FormattedMessage id='KC2975'/> : <FormattedMessage id='KC2967'/> }</div>
                                        <div className='cardInfoDimLabel'><FormattedMessage id='KC2968' /></div>
                                    </div>
                                    <div className="paymentFDetails">
                                        {paymentDetails.payment_type === "bank_account" ? <>
                                            <div className='bankName'> <img className='bankImg' src={require(`../../images/cardtype/bank.png`)} alt="" /> {paymentDetails.bank_name}</div><span className="activeCardBtn"><FormattedMessage id='KC0537' /></span>
                                            <div className='acountDetails'>
                                                <div className="nameandaccno"><FormattedMessage id='KC0241' /></div>
                                                <div className="accountNumberDD">{paymentDetails.first_name} {paymentDetails.last_name}</div>
                                                <div className="nameandaccno"><FormattedMessage id='KC2969' /></div>
                                                <div className="accountNumberDD"><span>{paymentDetails.masked_bank_account_number}</span></div>
                                            </div>
                                        </>
                                            :
                                            <>
                                                <div className='bankName'><FormattedMessage id='KC2490' /></div><span className="activeCardBtn"><FormattedMessage id='KC0537' /></span>
                                                <div className="cardType">{paymentTypeImage[paymentDetails?.card_type] ? <img src={paymentTypeImage[paymentDetails?.card_type]} alt={paymentDetails?.card_type} /> : paymentDetails?.card_type?.toUpperCase()}</div>
                                                <div className="accountNumber"><span>●●●●</span><span>●●●●</span><span>●●●●</span><span>{splitCardNumber?.length ? splitCardNumber[splitCardNumber.length - 1] : ''}</span></div>
                                                <div className="accountHname">{paymentDetails.first_name} {paymentDetails.last_name} <span>{paymentDetails.expiration_month}/{paymentDetails?.expiration_year ? paymentDetails?.expiration_year.toString().charAt(paymentDetails?.expiration_year.toString().length - 2) + paymentDetails?.expiration_year.toString().charAt(paymentDetails?.expiration_year.toString().length - 1) : ''}</span></div>
                                            </>
                                        }
                                    </div>
                                </div> : null}
                                <div className="buttonWrapper alignCenter"><button type="button" className="btn addPayment" onClick={() => 
                                    {setEditView(true)
                                    setCardTypeUpdate('creditCard')}
                                    }><FormattedMessage id='KC2489' /></button></div>
                            </div>
                        </div>}


                    <form className="tableSMViewW" style={{ display: loaded && editView ? 'block' : 'none' }}>
                        <div className="paymentsTable">
                            <div>
                                <span className='paymentInfoTxt'><FormattedMessage id='KC2440' /></span>
                                <div className="formRequired"><FormattedMessage id='KC2441' /></div>
                            </div>

                            <div className="payWith">
                                <span className='paymentInfoLabel'><FormattedMessage id='KC2963' /></span>
                                <div className="paymentType">
                                    {currentChargifySite?.payment_gateway?.credit_card?.is_enabled === true ?
                                        <>
                                            <div className="subscriptionCustomRadioBtn">
                                                <input id="UpdateNow" type="radio" name="CardTypeOption" value="creditCard" checked={cardType === "creditCard" ? true : false} onClick={async () => setCardTypeUpdate("creditCard")} />
                                                <label for="UpdateNow"><FormattedMessage id="KC2961" /></label>
                                            </div>
                                        </>
                                    : null}
                                    {currentChargifySite?.payment_gateway?.direct_debit?.is_enabled === true ?
                                        <>
                                            <div className="subscriptionCustomRadioBtn">
                                                <input id="ScheduleUpdate" type="radio" name="CardTypeOption" value="directDebit" checked={cardType === "directDebit" ? true : false} onClick={async () => setCardTypeUpdate("directDebit")} />
                                                <label for="ScheduleUpdate"><FormattedMessage id="KC2962" /></label>
                                            </div>
                                        </>
                                    : null}
                                </div>
                                {cardType === "creditCard" ? <>
                                    <span className='formRequired'><FormattedMessage id='KC2964' /></span>
                                    <div className="cardType">
                                        <img className='cardImg' src={require(`../../images/cardtype/visaCards.png`)} alt="" />
                                        <img className='cardImg' src={require(`../../images/cardtype/masterCards.png`)} alt="" />
                                        <img className='cardImg' src={require(`../../images/cardtype/discoverCards.png`)} alt="" />
                                        <img className='cardImg' src={require(`../../images/cardtype/amexCards.png`)} alt="" />
                                        <img className='cardImg' src={require(`../../images/cardtype/dinersclubCards.png`)} alt="" />
                                        <img className='cardImg' src={require(`../../images/cardtype/jcbCards.png`)} alt="" />
                                        <img className='cardImg' src={require(`../../images/cardtype/unionpayCards.png`)} alt="" />
                                    </div>
                                    <div className="formRequired"><FormattedMessage id='KC2965' /></div>
                                </>
                                    : null}
                            </div>
                            <div>
                                {
                                    cardType === "creditCard" ? <PaymentCard ref={creditcCard} loaderIconVisible={loaderIconVisible} handleLoader={(val) => setLoaderIconVisible(val)} setIsLoaded={setIsLoaded} throwError={throwError} siteDetails={currentChargifySite} paymentProfile={paymentDetails} /> :
                                    cardType === "directDebit" ? <DirectDebitCard ref={creditcCard} loaderIconVisible={loaderIconVisible} handleLoader={(val) => setLoaderIconVisible(val)} setIsLoaded={setIsLoaded} throwError={throwError} siteDetails={currentChargifySite} paymentProfile={paymentDetails} /> :
                                    <></>
                                }
                            </div>

                            <div className="buttonWrapper">
                                <button type="button" className="btn cencelBtn" data-dismiss="modal" onClick={async () => {
                                    setEditView(false)
                                    // setCardType('creditCard')
                                    // await creditcCard.current.loadKCUI(creditcCard.current.state.envData);
                                }}><FormattedMessage id='KC0021' />
                                </button>
                                <button type="button" className="btn saveBtn" onClick={ cardType === 'creditCard' ? save : functionOne}><FormattedMessage id='KC0123' /></button></div>
                        </div>
                        <div className="pageNotes_payment">* <FormattedMessage id='KC2442' /></div>
                    </form>
                </div>
            </div> : null}
            {/* <!-- Payment details updated --> */}
            <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="paymentSuccss" tabindex="-1" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="confirmMContententD">
                                <div className="h2Title successInfo"><FormattedMessage id='KC2484' /></div>
                                {/* <p><FormattedMessage id='KC2485' /></p> */}
                            </div>
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn confirmnBtn" data-dismiss="modal" type="button" onClick={ async () => { 
                                setEditView(false)
                                setCardType('creditCard')
                            }}><FormattedMessage id='KC0777' /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Payment details updated --> */}
            {/* <!-- Payment details update failed --> */}
            <div className="modal fade confirmModalSM" data-keyboard="true" data-backdrop="static" id="paymentFaileddModal" tabindex="-1" role="dialog">
                <div className="modal-dialog modal-md">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="confirmMContententD">
                                <div className="h2Title failedInfo"><FormattedMessage id='KC2491' /></div>                                
                                <p>{errorText}</p>
                            </div>
                        </div>
                        <div className="modal-footer textAlignCenter">
                            <button className="btn confirmnBtn" data-dismiss="modal" type="button" onClick={() => setEditView(true)}><FormattedMessage id='KC0777' /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Payment details update failed --> */}
            {isAuth ? <UserManagementHeader headerName={formatMessage({ id: 'KC1668' })} headerNameStringID="KC1668" activeClass="billingPortalNav" /> : null}
        </div >
    )
};

export default injectIntl(Payments)