import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import { TemperatureTypes } from '../../../../../components/controls/temperatureTypes';
import http from '../../../../../service/httpService';
import { MenuUploadAPI, UnitInfo } from '../../../../../service/api';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import $ from 'jquery';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import Jimp from 'jimp';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import { injectIntl,FormattedMessage } from 'react-intl';
import { CUSTOMER_ID, BRAND_ID } from '../../../../../utils/appConstants';
import { ungzip } from 'pako';
require('jquery-mousewheel')
var AdmZip = require('adm-zip');
var request = require('request');
var LZString = require('lz-string');

class RecipeCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            selectedModel: 0,
            data: [],
            dynamicdataList: [{ index: 1, temp: '0', time: '00:01', fan: '20', power: '0', paramDelete: '', instruction: '', instructionShowid: '', instructionDelete: '', instructionVisible: false }],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            disableAddStep: false,
            isChanged: false,
            showDiscardPopup: false,
            time_mask : [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            tempType : "° C",
            settingTempType : ""
        }
        this.getParameterList = this.getParameterList.bind(this);
        this.onDeleteStep = this.onDeleteStep.bind(this);
        this.onSaveRecipe = this.onSaveRecipe.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.handleTempTypeChange = this.handleTempTypeChange.bind(this);
        this.temperatureValuesOnload = this.temperatureValuesOnload.bind(this);
        this.addStep = this.addStep.bind(this);
        this.onInstructionDelete = this.onInstructionDelete.bind(this);
        this.handleParamChange = this.handleParamChange.bind(this);
        this.handleParamSubmit = this.handleParamSubmit.bind(this);
        this.handleParamCheck = this.handleParamCheck.bind(this);
        this.showInstruction = this.showInstruction.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.OnImport = this.OnImport.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            this.getParameterList();
            window.addEventListener('resize', this.updateDimensions());
            this.setState({
                selectedModel: parseInt(localStorage.getItem("selectedProductID"))
            });
            if(!localStorage.getItem("isConnexUser")){
                await this.getConnexUnits();
            }
            if (this.props.history.location.previouspath === "/recipeCreationSave" || this.props.history.location.previouspath === "/libraryList" || this.props.history.location.previouspath === "/readyRecipeLibraryList" || this.props.history.location.previouspath === "/stageInformation" || this.props.history.location.previouspath==="/cookbookPressAndGo" || this.props.history.location.previouspath==="/cookbookAllRecipes" || this.props.history.location.previouspath==="/cookbookAddRecipes" || this.props.history.location.previouspath==="/menurcPressAndGo" || this.props.history.location.previouspath==="/menuAllRecipes" || this.props.history.location.previouspath==="/cookbookSubCategoryList" || this.props.history.location.previouspath==="/cookbookRecipeList") {
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                let libraryData = JSON.parse(localStorage.getItem("libraryData"));
                let librarySettingsData = localStorage.getItem("librarySettingsData");
                let libraryDataObj = JSON.parse(librarySettingsData);
                let defaultLibraryDetails = "";
                try {
                    defaultLibraryDetails = JSON.parse(localStorage.getItem("defaultLibraryData"));
                }
                catch (err) {
                    defaultLibraryDetails = JSON.parse(LZString.decompress(localStorage.getItem("defaultLibraryData")));
                }
                this.setState({
                    isChanged: !_.isEqual(defaultLibraryDetails, libraryData)
                });
                let tempType = libraryData[0] ? libraryData[0].temp.includes('C') ? "° C"  : "° F" : "° C";
                tempType = libraryDataObj && libraryDataObj.units && libraryDataObj.units.includes('C') ? "° C"  : "° F";
                let settingTempType = libraryData[0] && libraryData[0].temp.includes('C') ? "° C"  : "° F";
                libraryData = Object.values(libraryData);
                libraryData && libraryData.map((item, i) => {
                    item.instructionVisible = item.instruction ? true : false;
                    item.temp = item.temp.replace('C', '').replace('F', '');
                });
                if (libraryData && libraryData.length === 6) {
                    this.setState({
                        disableAddStep: true
                    });
                }
                if(libraryData && libraryData.length === 0) {
                    this.setState({
                        isEdit: libraryDetails.isEdit ? libraryDetails.isEdit : false,
                        isImageChanged: libraryDetails.isImageChanged ? libraryDetails.isImageChanged : false,
                        tempType: tempType,
                        settingTempType: settingTempType
                    })
                } else {
                    this.setState({
                        dynamicdataList: libraryData,
                        isEdit: libraryDetails.isEdit ? libraryDetails.isEdit : false,
                        isImageChanged: libraryDetails.isImageChanged ? libraryDetails.isImageChanged : false,
                        tempType: tempType,
                        settingTempType: settingTempType
                    })
                }
                if(this.props.history.location.previouspath !== "/recipeCreationSave") {
                    this.temperatureValuesOnload(tempType,libraryData,settingTempType);
                }
            }
            else {
                this.state.dynamicdataList.map((item, i) => {
                    item.instructionVisible = false;
                });
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
        const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
    }

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false
        })
    }

    OnDiscard = () => {
        let { history } = this.props;
        this.setState({
            loaderIconVisible: true
        })
        if (this.state.isEdit) {
            if(history.location.previouspath === '/cookbookPressAndGo') {
                this.props.history.push(`/cookbookPressAndGo`);
            } else {
                this.props.history.push(`/libraryList`);
            }
        }
        else {
            this.props.history.push(`/cookbookEditor`);
        }
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    onBackButtonClick = () => {
        let { history } = this.props;
        if (!this.state.isChanged) {
          if (this.state.isEdit) {
            if(history.location.previouspath === '/cookbookPressAndGo') {
              this.props.history.push(`/cookbookPressAndGo`);
            } else if (history.location.previouspath === '/cookbookAllRecipes') {
              this.props.history.push(`/cookbookAllRecipes`);
            } else if (history.location.previouspath === '/cookbookAddRecipes') {
              this.props.history.push(`/cookbookAddRecipes`);
            } else if (history.location.previouspath === '/menurcPressAndGo') {
              this.props.history.push(`/menurcPressAndGo`);
            } else if (history.location.previouspath === '/menuAllRecipes') {
              this.props.history.push(`/menuAllRecipes`);
            } else if (history.location.previouspath === '/cookbookSubCategoryList') {
              this.props.history.push(`/cookbookSubCategoryList`);
            } else if (history.location.previouspath === '/cookbookRecipeList') {
              this.props.history.push(`/cookbookRecipeList`);
            } else {
              this.props.history.push(`/libraryList`);
            }
          }
          else {
            if (history.location.previouspath === '/menurcPressAndGo') {
              this.props.history.push(`/menurcPressAndGo`);
            } else if (history.location.previouspath === '/menuAllRecipes') {
              this.props.history.push(`/menuAllRecipes`);
            } else if (history.location.previouspath === '/cookbookSubCategoryList') {
              this.props.history.push(`/cookbookSubCategoryList`);
            } else if (history.location.previouspath === '/cookbookRecipeList') {
              this.props.history.push(`/cookbookRecipeList`);
            } else {
              this.props.history.push(`/cookbookEditor`);
            }
          }
        }
        else {
            this.setState({ loaderIconVisible: false });
            if(history.location.previouspath === '/cookbookPressAndGo') {
                this.props.history.push(`/cookbookPressAndGo`);
            } else if (history.location.previouspath === '/cookbookAllRecipes') {
                this.props.history.push(`/cookbookAllRecipes`);
            } else if (history.location.previouspath === '/cookbookAddRecipes') {
                this.props.history.push(`/cookbookAddRecipes`);
            }else {
                this.props.history.push(`/cookbookEditor`);
            } 
        }
    }

    getParameterList = () => {
        this.setState({
            loaderIconVisible: true
        });

        return new Promise((resolve, reject) => {
            http.get(MenuUploadAPI.getRecipeParameters,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'brandid': localStorage.getItem("selectedbrandID")
                    },
                    data: {}
                }).then(response => {
                    let { data } = response;
                    let recipeParametersList = data && data.RecipeParamtersList ? data.RecipeParamtersList : [];
                    this.setState({
                        loaderIconVisible: false,
                        data: recipeParametersList
                    });
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
        });
    }

    onSaveRecipe = () => {
        this.setState({
            loaderIconVisible: true,
        });

        if (this.state.dynamicdataList.length > 0) {
            let emptyInput = false;
            this.state.dynamicdataList.map((item, i) => {
                if(this.state.tempType){
                    item.temp = item.temp && this.state.tempType == '° C' ? item.temp !=='OFF' ? item.temp + 'C' : '0C' : item.temp !=='OFF' ? item.temp + 'F' : '0F';
                }
                if (!emptyInput) {
                    emptyInput = item.time && item.time !== "00:00" && !item.time.includes('_') ? false : true;
                }
            });

            if (!emptyInput) {
                let recipeData = JSON.stringify(this.state.dynamicdataList);
                localStorage.setItem("libraryData", recipeData);
                this.props.history.push({
                    pathname: "/recipeCreationSave",
                    previouspath: "/recipeCreation",
                });
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1206"
                    }
                });
            }
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1207"
                }
            });
        }
    }

    temperatureValuesOnload(tempValue, data, settingTempValue) {
        let { tempType, settingTempType } = this.state;
        data.map((item, j) => {
            if (item.temp) {
                if(tempValue === settingTempValue){
                    data[j] = item;
                }
                else {
                    let value = item.temp;
                    if (tempValue === "° F") {
                        item.temp = parseFloat(((value * 9/5) + 32).toFixed(3)) > 0 ? Math.round(parseFloat(((value * 9/5) + 32).toFixed(3))) : 0;
                    }
                    else {
                        item.temp = parseFloat(((value - 32) * 5/9).toFixed(3)) > 0 ? Math.round(parseFloat(((value - 32) * 5/9).toFixed(3))) : 0;
                    }
                    data[j] = item;
                }
            }
        })
    }

    addStep = (e) => {
        this.setState((prevState) => ({
            dynamicdataList: [...prevState.dynamicdataList, { index: prevState.dynamicdataList.length + 1, temp: '0', time: '00:01', fan: '20', power: '0', paramDelete: '', instruction: '', instructionDelete: '', instructionVisible: false }],
        }));
        if (this.state.dynamicdataList.length > 4) {
            this.setState({
                disableAddStep: true
            });
        }
    }

    handleParamChange = (e) => {
        this.setState({
            isChanged: true
        });
        if (["form-control temp"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            let temp = 0;
            if (this.state.tempType === '° C') {
                temp = parseFloat(e.target.value.replace('C','').replace(' ',''));
                temp = isNaN(temp) ? 0 : temp;
                if (temp === 0) {
                    temp = 'OFF'
                }
                dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = temp !== 'OFF' ? Math.round(temp) : temp;
            }
            else {
                temp = parseFloat(e.target.value.replace('F','').replace(' ',''));
                temp = isNaN(temp) ? 0 : temp;
                if (temp === 32 || temp === 0) {
                    temp = 'OFF'
                }
                dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] =  temp !== 'OFF' ? Math.round(temp) : temp;
            }
            this.setState({ dynamicdataList: dynamicdataList })
        } 
        else if (["form-control fan"].includes(e.target.className)) {
            let fan = 0;
            let dynamicdataList = [...this.state.dynamicdataList]
            fan = parseInt(e.target.value.replace('%',''));
            fan = isNaN(fan) ? 0 : fan;
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = fan
            this.setState({ 
                dynamicdataList: dynamicdataList
             })
        }
        else if (["form-control power"].includes(e.target.className)) {
            let power = 0;
            let dynamicdataList = [...this.state.dynamicdataList]
            power = parseInt(e.target.value.replace('%',''));
            power = isNaN(power) ? 0 : power;
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = power
            this.setState({ 
                dynamicdataList: dynamicdataList
             })
        }
        else if (["form-control time"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = e.target.value.replace('__:__', '00:01').replace('_:__', '0:00').replace(':__', ':00').replace('_', '0');
            this.setState({ 
                 dynamicdataList: dynamicdataList 
            })
        }
        else if (["form-control instruction"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = e.target.value
            
            this.setState({ 
                 dynamicdataList: dynamicdataList 
            })
        }
        else {
            this.setState({ [e.target.name]: e.target.value.toUpperCase() })
        }
    }

    handleParamCheck = (e) => {
        this.setState({
            isChanged: true
        });
        const modelFamilyID = {
            'dev' : '81',
            'other': '41'
        }
        const hostname = window && window.location && window.location.hostname;
        let backendHost = hostname === 'www.welbiltdigitaldev.com' || hostname === 'welbiltdigitaldev.com' ? 'dev' : 'other';

        const maxCelsiusValue = localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? 260 : 275;
        const maxFarenheitValue = localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? 500 : 527;
        if (["form-control temp"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            let tempvalue = e.target.value !== 'OFF' ? parseFloat(e.target.value.replace('F','').replace('C','') ? e.target.value.replace('F','').replace('C','') : 0) : e.target.value;
            tempvalue = tempvalue !== 'OFF' ? Math.round(tempvalue) : tempvalue;
            if (this.state.tempType === '° C') {
                dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = (tempvalue === 0 ? "OFF" : tempvalue !== 'OFF' ? tempvalue < 100 ? 100 : tempvalue > maxCelsiusValue ? maxCelsiusValue : tempvalue : tempvalue);
            }
            else {
                dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = (tempvalue === 0 ? "OFF" : tempvalue !== 'OFF' ? tempvalue < 212 ? 212 : tempvalue > maxFarenheitValue ? maxFarenheitValue : tempvalue : tempvalue);
            }
            this.setState({ 
                dynamicdataList: dynamicdataList,
                error_modal: {
                    open: tempvalue === 0 ? false : (this.state.tempType === '° C' && (tempvalue < 100 || tempvalue > maxCelsiusValue)) ? true : (this.state.tempType === '° F' && (tempvalue < 212 || tempvalue > maxFarenheitValue)) ? true : false,
                    message: this.state.tempType === '° C' ?  localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? "KC2290" : "KC1209" :  localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? "KC2291" : "KC1328"
                }
             })
        }
        else if (["form-control time"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            let paramvalue = parseInt(e.target.value.replace(':',''));
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = e.target.value ? (parseInt(e.target.value.replace(':',''))  > 1000 || e.target.value[0] === "1") ? "10:00" : parseInt(e.target.value.replace(':',''))  === 0 ? "00:01" : e.target.value : e.target.value;
            this.setState({ 
                 dynamicdataList: dynamicdataList,
                 error_modal: {
                    open: (paramvalue < 1 || paramvalue > 1000) ? true : false,
                    message: "Please enter time value between 00:01 to 10:00 minutes."
                }
            })
        }
        else if (["form-control fan"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            let paramvalue = parseInt(e.target.value.replace('%','').replace(' ','') ? e.target.value.replace('%','').replace(' ','') : 0);
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = (paramvalue === 0 ? paramvalue : paramvalue < 10 ? 10 : paramvalue > 100 ? 100 : paramvalue);
            this.setState({ 
                dynamicdataList: dynamicdataList,
                error_modal: {
                    open: paramvalue === 0 ? false : (paramvalue < 10 || paramvalue > 100) ? true : false,
                    message: "Please enter fan value 0 or between 10 to 100%."
                }
             })
        }
        else if (["form-control power"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            let paramvalue = parseInt(e.target.value.replace('%','').replace(' ','') ? e.target.value.replace('%','').replace(' ','') : 0);
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = (paramvalue === 0 ? paramvalue : paramvalue < 5 ? 5 : paramvalue > 100 ? 100 : paramvalue);
            this.setState({ 
                dynamicdataList: dynamicdataList,
                error_modal: {
                    open: paramvalue === 0 ? false : (paramvalue < 5 || paramvalue > 100) ? true : false,
                    message: "KC1210"
                }
             })
        }
        else if (["form-control instruction"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            let paramvalue = e.target.value.trim();
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = paramvalue;
            this.setState({ 
                 dynamicdataList: dynamicdataList,
                 error_modal: {
                    open: paramvalue.length > 30 ? true : false,
                    message: "Max 30 characters allowed for instructions."
                }
            })
        }
    }

    onDeleteStep = (e) => {
        let id = e.target.id.replace('paramDelete-', '');
        if (id) {
            let splicedata = this.state.dynamicdataList;
            splicedata.splice(Math.round(id), 1);
            this.setState({
                dynamicdataList: splicedata
            });
            if (splicedata.length < 6) {
                this.setState({
                    disableAddStep: false
                });
            }
        }
    }

    showInstruction = (e) => {
        if (e.target.id) {
            let id = e.target.id.replace('instructionShow-', '');
            $("#instruction-" + id).removeClass("displaynone");
            $("#instructionIcon-" + id).removeClass("displaynone");
            $("#instructionDelete-" + id).removeClass("displaynone");
            $("#instructionIcon-" + id).parent().parent().removeClass("displaynone");
        }
    }

    showStage = (e) => { 
          if (this.state.dynamicdataList.length > 0) {
                let emptyInput = false;
                this.state.dynamicdataList.map((item, i) => {
                    if(this.state.tempType){
                        item.temp = item.temp && this.state.tempType == '° C' ? item.temp + 'C' : item.temp + 'F';
                    }
                    if (!emptyInput) {
                        emptyInput = item.time && item.time !== "00:00" && !item.time.includes('_') ? false : true;
                    }
                });
                if (!emptyInput) {
                    let recipeData = JSON.stringify(this.state.dynamicdataList);
                    localStorage.setItem("libraryData", recipeData);
                    localStorage.setItem("recipeStageID",e)
                    this.props.history.push({
                        pathname: "/stageInformation",
                        previouspath: "/recipeCreation",
                    });
                }
            }
    }

    decompressData(base64) {
        try {
            const buffer = Buffer.from(base64, "base64");
            const bufferArr = ungzip(buffer);
            const json = new TextDecoder("utf8").decode(bufferArr);
            return JSON.parse(json);
        } catch (err) {
            console.error(err);
            return null;
        }
    }

    getConnexUnits = () => {
		this.setState({
            loaderIconVisible: true,
        });
        let url = `${UnitInfo.list}`;
        let pageNumber = "?pageNum=1";
        let pageLimit = "&pageSize=50";

        let apiUrl = url + pageNumber + pageLimit;

        let searchParam = {
            modelName: "connex",
        };
        apiUrl = `${apiUrl}&searchParams=${btoa(JSON.stringify(searchParam))}`;
        return new Promise((resolve, reject) => {
            http.get(apiUrl, {
                headers: {
                    "Content-Type": "application/json",
                    cgid: localStorage.getItem("custGroupID"),
                    cid: CUSTOMER_ID,
                    brandid: BRAND_ID,
                    model: "",
                    machinetype: "",
                    sortby: "",
                    sorttype: "",
                },
                data: {},
                params: {
                    compress: true,
                },
            })
                .then((response) => {
                    let { units } = response?.data;
                    if (response.data.compressed) {
                        units = this.decompressData(units) || [];
                    }
                    localStorage.setItem("isConnexUser", units ? units.length > 0 : false);
                    this.setState({
                        loaderIconVisible: false,
                    });
                    resolve(response);
                })
                .catch((err) => {
                    this.setState({
                        loaderIconVisible: false,
                    });
                    reject(err);
                });
        });
	}

    onInstructionDelete = (e) => {
        if (e.target.dataset.id) {
            let dynamicdataList = [...this.state.dynamicdataList]
            dynamicdataList[e.target.dataset.id]["instruction"] = "";
            dynamicdataList[e.target.dataset.id]["instructionVisible"] = false;
            dynamicdataList[e.target.dataset.id]["instructionImage"] = "";
            dynamicdataList[e.target.dataset.id]["instructionImageS3Url"] = "";
            dynamicdataList[e.target.dataset.id]["instructionImageb64"] = "";
            this.setState({ dynamicdataList: dynamicdataList })
            let id = e.target.id.replace('instructionDelete-', '');
            $("#instruction-" + id).addClass("displaynone");
            $("#instructionIcon-" + id).addClass("displaynone");
            $("#instructionDelete-" + id).addClass("displaynone");
            $("#instructionIcon-" + id).parent().parent().addClass("displaynone");
        }
    }

    handleParamSubmit = (e) => { e.preventDefault() }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    handleTempTypeChange(e) {
        this.setState({
            tempType: e.target.value
        })

        let {dynamicdataList, tempType} = this.state;
        dynamicdataList.map((item, j) => {
            if (item.temp) {
                if (tempType !== e.target.value) {
                    let value = item.temp;
                    if (e.target.value === "° F") {
                        item.temp = parseFloat(((value * 9/5) + 32).toFixed(3)) > 0 ? Math.round(parseFloat(((value * 9/5) + 32).toFixed(3))) : 0;
                    }
                    else {
                        item.temp = parseFloat(((value - 32) * 5/9).toFixed(3)) > 0 ? Math.round(parseFloat(((value - 32) * 5/9).toFixed(3))) : 0;
                    }
                    dynamicdataList[j] = item;
                }
            }
        })
    }

    async OnImport(item) {
        this.setState({
            loaderIconVisible: true
        });
        await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
            headers: {
                'Content-Type': 'application/json',
                'library_id': item.LIBRARY_ID,
                'library_isedit': true
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                let file_path = response.data.presignedURL;
                var libraryFiles = [];
                var b64 = "";
                var imageBuffer = null;
                localStorage.setItem("selectedGroupID", item.CGID);
                try {
                    request.get({ url: file_path, encoding: null }, (err, res, body) => {
                        var zip = new AdmZip(body);
                        var zipEntries = zip.getEntries();

                        let validzipEntries = zipEntries.filter(z => z.entryName.charAt(z.entryName.length - 1) !== "/");
                        validzipEntries.forEach((entry, i) => {
                            libraryFiles[i] = {};
                            libraryFiles[i].fileName = entry.entryName.replace('Resources/Images/', '');
                            imageBuffer = zip.readFile(entry);

                            if (!entry.entryName.includes('.json')) {
                                if (i === 1) {
                                    libraryFiles[i].fileBuffer = imageBuffer;
                                }
                                else {
                                    libraryFiles[i].fileBuffer = imageBuffer.toString('base64');
                                }
                            }
                            else {
                                libraryFiles[i].fileBuffer = imageBuffer;
                                b64 = imageBuffer.toString('base64');
                            }
                        });

                        localStorage.setItem("selectedFamilyID", item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.ID).toString() : '');

                        imageBuffer = libraryFiles[1].fileBuffer;
                        Jimp.read(imageBuffer)
                        .then(image => {
                            image.resize(80, 80, (err, image)  => {
                                image.getBase64Async('image/png').then(thumbnailb64 => {
                                    let libraryData = [];
                                    let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
                                    UpdatedJSONObj && UpdatedJSONObj.ordered_steps && UpdatedJSONObj.ordered_steps.map((item, i) => {
                                        libraryData[i] = {};
                                        libraryData[i].index = i;
                                        libraryData[i].temp = item.actions.set_point.value + item.actions.set_point.units.replace('deg', '');
                                        let total = 0;
                                        if (item.duration) {
                                            let duration = item.duration.replace('PT', '');
                                            let minutes, seconds = 0;
                                            if (duration.includes('M')) {
                                                minutes = duration.substr(0, duration.indexOf('M'))
                                            }
                                            if (duration.includes('S')) {
                                                seconds = duration.substr(duration.indexOf('M') + 1, duration.indexOf('S') - 3)
                                            }
                                            total = minutes + ':' + seconds;
                                        }
                                        libraryData[i].time = total;
                                        libraryData[i].fan = item.actions.fan;
                                        libraryData[i].power = item.actions.microwave;
                                        libraryData[i].paramDelete = '';
                                        libraryData[i].instruction = item.actions.prompt.message.en_GB;
                                        libraryData[i].instructionShowid = '';
                                        libraryData[i].instructionDelete = '';
                                        libraryData[i].instructionVisible = '';
                                        if(item.actions.prompt.image) {
											libraryData[i].instructionImage = item.actions.prompt.image;
											libraryData[i].instructionImageS3Url = "";

											libraryFiles && libraryFiles.map((libraryitem, j) => {
												if (!libraryitem.fileName.toLowerCase().includes('.json')) {
													if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "").replace("/","") === item.actions.prompt.image.replace("Resources/Images/", "").replace("Resources/", "").replace("/","")) {
														libraryData[i].instructionImageb64 = libraryitem.fileBuffer.toString('base64');;
													}
												}
											});
										}
                                    })

                                    localStorage.setItem("defaultLibraryData", JSON.stringify(libraryData));
                                    libraryData.Import = true;
                                    localStorage.setItem("libraryData", JSON.stringify(libraryData));
                                    
                                    libraryData && libraryData.map((item, i) => {
                                        item.instructionVisible = item.instruction ? true : false;
                                    });

                                    let libraryDetails = {};
                                    libraryDetails.id = UpdatedJSONObj.id;
                                    let md5 = require('md5');
                                    let imageData = {};
                                    imageData.imagefileName = item.LIBRARY_NAME + "." + item.IMAGE_FILE_FORMAT;
                                    imageData.imagefileext = item.IMAGE_FILE_FORMAT;
                                    imageData.imagefileNamewithOutExt = item.LIBRARY_NAME;
                                    imageData.imagefileSize = item.IMAGE_FILE_SIZE;
                                    imageData.imagefilelength = item.LIBRARY_NAME.length;
                                    imageData.library_id = item.LIBRARY_ID;
                                    imageData.crc = md5(b64, "base64");
                                    imageData.imageS3Url = item.IMAGE_S3URL;
                                    libraryDetails.imageData = imageData;
                                    libraryDetails.recipeName = item.LIBRARY_NAME;
                                    libraryDetails.isEdit = false;
                                    libraryDetails.isImageChanged = true;
                                    libraryDetails.initialMethod = "Add";
                                    libraryDetails.recipeType = item.TYPE;

                                    localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
								    localStorage.setItem("libraryImage", thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
                                    this.setState({
                                        dynamicdataList: libraryData,
                                        pathname: "/recipeCreation",
                                        previouspath: "/libraryList",
                                        isEdit: false,
                                        loaderIconVisible: false,
                                        isChanged: true,
                                        isImageChanged: true,
                                        tempType: libraryData[0] ? libraryData[0].temp.includes('C') ? "° C"  : "° F" : "° C"
                                    });
                                    });
                                })
                            })
                        })
                }
                catch (ex) {
                    console.log(ex);
                    this.setState({
                        loaderIconVisible: false
                    });
                }
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({
                loaderIconVisible: false
            });
        });
    }

    _onFocus(id, value) {
        if(value === '' || value === 'OFF') {
            document.getElementById(id).value = "";
        }
    }

    _onTimerFocus = (id) => {
        var textfield = document.getElementById(id);
        textfield.setSelectionRange(0, 0);
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: basedataList, dynamicdataList, disableAddStep, isChanged, success_modal, error_modal, loaderIconVisible, showDiscardPopup, time_mask, isEdit, tempType, selectedModel } = this.state;
        basedataList = basedataList && basedataList.length > 0 ? basedataList : [];
        basedataList = basedataList.filter(b => b.PARAMETER_NAME !== "Instruction");
        let brandid = localStorage.getItem("selectedbrandID");

        console.log(dynamicdataList);

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader  headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload disabled" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} alt="" /></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1211"/>
				                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">
                                                <div class="recipeCreationForm">
                                                    <ul class="tHead">
                                                        <li>&nbsp;</li>
                                                        <li>&nbsp;</li>
                                                        <li>
                                                            <div class="rcSettingsTable">
                                                                <ul>
                                                                    {
                                                                        basedataList.map((item, i) => {
                                                                            return (
                                                                                <li>
                                                                                    <img src={require(item.PARAMETER_NAME === "Temp" ? "../../../../../images/recipecreation/tempIcon.png" : item.PARAMETER_NAME === "Time" ? "../../../../../images/recipecreation/timerIcon.png" : item.PARAMETER_NAME === "FAN" ? "../../../../../images/recipecreation/fanSpeedIcon.png" : "../../../../../images/recipecreation/powerIcon.png")} alt="" />
                                                                                    {item.PARAMETER_NAME === "Temp" && <TemperatureTypes selecteditem={tempType} isTempEditable={false} handleTempTypeChangeProps={(e) => this.handleTempTypeChange(e)} />}
                                                                                </li>
                                                                            )
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                </div>
                                                <div class="recipeCreationWrapper">
                                                    {
                                                        dynamicdataList.map((val, idx) => {
                                                            let tempid = `temp-${idx}`, timeid = `time-${idx}`, fanid = `fan-${idx}`, powerid = `power-${idx}`, paramDeleteid = `paramDelete-${idx}`, instructionid = `instruction-${idx}`, instructionShowid = `instructionShow-${idx}`, instructionDeleteid = `instructionDelete-${idx}`
                                                            return (
                                                                <form class="recipeCreationForm" onSubmit={this.handleParamSubmit} onChange={this.handleParamChange} onBlur={this.handleParamCheck} key={idx}>
                                                                    <ul className={val.instructionVisible ? "notesField" : "notesField displaynone"}>
                                                                        <li>&nbsp;</li>
                                                                        <li>
                                                                            <button onClick={() => this.showStage(idx)} id={"instructionIcon-" + idx} type="button" class={val.instructionVisible ? "btn-default-text recipeNotesInfo" : "btn-default-text recipeNotesInfo displaynone"}>&nbsp;</button>
                                                                        </li>
                                                                        <li>
                                                                            <div class={val.instructionImage ? "rcNotesFormField addedImage" : "rcNotesFormField"}>
                                                                                <input name={instructionid} maxLength="30" onClick={() => this.showStage(idx)}
                                                                                    data-id={idx} value={val.instruction}
                                                                                    id={instructionid} type="text" class={val.instructionVisible ? "form-control instruction" : "form-control instruction displaynone"} />
                                                                            </div>
                                                                            {val.instructionImage && <span class="recipeAddedImg">&nbsp;</span>}
                                                                        </li>
                                                                        <li>
                                                                            <button name={instructionDeleteid}
                                                                                data-id={idx}
                                                                                id={instructionDeleteid} type="button" class={val.instructionVisible ? "btn btn-default-text deleteButton" : "btn btn-default-text deleteButton displaynone"} onClick={(e) => { this.onInstructionDelete(e) }} title={formatMessage({ id: 'KC2010' })}>&nbsp;</button>
                                                                        </li>
                                                                    </ul>
                                                                    <ul>
                                                                        <li>
                                                                            <button name={instructionShowid}
                                                                                data-id={idx}
                                                                                id={instructionShowid} type="button" class="btn btn-default-text msgButton" onClick={(e) => { this.showInstruction(e) }} title={formatMessage({ id: 'KC1212' })}>&nbsp;</button>
                                                                        </li>
                                                                        <li>
                                                                            <button type="button" class="btn btn-default-text recipeRowCount">{idx + 1}</button>
                                                                        </li>
                                                                        <li>
                                                                            <div class="rcSettingsTable">
                                                                                <ul>
                                                                                    <li><input type="text" maxLength="4" name={tempid} onFocus={(e) => this._onFocus(tempid, e.target.value)}
                                                                                        data-id={idx} value={typeof val.temp === 'string' ? val.temp === 'OFF' ? val.temp : val.temp !== '0' ? val.temp.replace('F', '').replace('C', '') : 'OFF' : val.temp} placeholder={tempType.replace('° ','')}
                                                                                        id={tempid} class="form-control temp" />{/* KCCN */}
                                                                                    </li>
                                                                                    <li>
                                                                                        <InputMask mask={time_mask} maskChar="" onClick={(e) => this._onTimerFocus(timeid)} placeholder={formatMessage({ id: 'KC2085' })} value={val.time} name={timeid} data-id={idx} id={timeid} class="form-control time" />
                                                                                    </li>
                                                                                    <li>
                                                                                        <input type="text" maxLength="4" name={fanid}
                                                                                        data-id={idx} value={val.fan} placeholder=""
                                                                                        id={fanid} class="form-control fan" />
                                                                                        <span class="percentage">%</span>
                                                                                    </li>
                                                                                    <li>
                                                                                        <input type="text" maxLength="4" name={powerid}
                                                                                        data-id={idx} value={val.power} placeholder=""
                                                                                        id={powerid} class="form-control power" />
                                                                                        <span class="percentage">%</span>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </li>
                                                                        <li>
                                                                            <button name={paramDeleteid}
                                                                                data-id={idx}
                                                                                id={paramDeleteid} onClick={(e) => { this.onDeleteStep(e) }} type="button" class="btn btn-default-text deleteButton" title={formatMessage({ id: 'KC1213' })}>&nbsp;</button>
                                                                        </li>
                                                                    </ul>
                                                                </form>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                    <button type="button" id="scrollDownButton" onClick={this.addStep} class={disableAddStep ? "btn btn-default-text displaynone" : "btn btn-default-text addButton"} title={formatMessage({ id: 'KC1215' })}>&nbsp;</button>&nbsp;
                                    <button type="button" class={isChanged || isEdit ? "btn btn-default-text nextButton" : "btn btn-default-text nextButton disabled"} onClick={() => { this.onSaveRecipe() }} title={formatMessage({ id: 'KC1216' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                {/* <!-- Import Modal --> */}
                {/* <LibraryListPopup libraryType={['Recipe','ReadyRecipe']} OnImportLibrary={(item) => this.OnImport(item)} modelID={selectedModel} /> */}
                {/* <!-- //Import Modal --> */}
            </React.Fragment>
        )
    }
}

export default injectIntl(RecipeCreation)
