import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { format, addDays } from 'date-fns';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { getHoldingTableData } from '../../../actions/reportManagement/holdingTableActions';
import { HolderList } from '../../unitDashboard/globalWidgets/holderList';
import { TableHeader } from '../../controls/tableHeader';
import { FrypotStatusPieChart } from './frypotStatusPieChart';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class ProductMixByHourTable extends Component {

	constructor(props, context) {
		super(props, context);
		const holder_name = 'holder_name_' + props.widgetID;
		this.state = {
			type: 'Month',
			filterType: "Month",
			noResultMsg: 'No Results',
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			frypotStatusSortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			IsServerError: false,
			sd: '',
			ed: '',
			startDate: '',
			endDate: '',
			loaderIconVisible: true,
			hideDateRange: true,
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			holder_id: "",
			[holder_name]: "Fryer",
			datapickflag:false
		};

		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount = async () => {
		todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME ? currentUnit.BRAND_NAME : localStorage.getItem("brandName");;

		this.props.getHoldingTableData("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, '68');

		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	componentDidUpdate = async (prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.fetchData(this.state.holder_id,this.props.globalDropdownFilter);
			await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
					await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStart(this.props.globalDropdownFilterStartDate);
					await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

	handleChangeStart(holdingDate) {
		if(this.state.startDate === holdingDate){
			return;
		}
		this.setState({
			startDate: holdingDate,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			this.fetchData(this.state.holder_id,selectedtext);
		});
	}

	handleChangeEnd(holdingDate) {
		if(this.state.endDate === holdingDate){
			return;
		}
		this.setState({
			endDate: holdingDate,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			this.fetchData(this.state.holder_id,selectedtext);
		});
	}

	fetchData(holder_id, text) {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME ? currentUnit.BRAND_NAME : localStorage.getItem("brandName");
		if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else { 
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate,- 6), 'YYYY-MM-DD');
        }

		if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
			this.props.getHoldingTableData(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, '68', holder_id);
		}
	}

	onDropDownHandler(e) {
		this.setState({
			type: e.nodeText,
			datapickflag: e.nodeText === 'Date Range', startDate: '', endDate: ''
		});
		this.fetchData(this.state.holder_id, e.nodeText);
	}

	onHolderSelection(e, type = '') {
		const holderid = e.target.text.replace("Frypot ", "").replace("Fryer", "")
		this.setState({
			holder_id: holderid,
			holder_name: e.target.text
		});
		this.fetchData(holderid, type);
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	onfrypotStatusSort = frypotStatusSortColumn => {
		this.setState({
			frypotStatusSortColumn: frypotStatusSortColumn
		});
	}

	render() {
		const { holdingTableInfo } = this.props;
		let { sortColumn, frypotStatusSortColumn } = this.state;
		const holdingTableData = holdingTableInfo.holdingTableResponse ? holdingTableInfo.holdingTableResponse : {};
		let holdingTableContent = holdingTableData.content && holdingTableData.content.length > 0 ? holdingTableData.content : [];
		let frypotStatusContent = holdingTableData.frypotStatus && holdingTableData.frypotStatus.length > 0 ? holdingTableData.frypotStatus : [];
		let frypotStatusChartContent = frypotStatusContent.filter(a => a.Key === "Cooking" || a.Key === "Idle" || a.Key === "Unit Off" || a.Key === "Other");
		frypotStatusContent = frypotStatusContent.filter(a => a.Key !== "Other");
		const displayContents = _.orderBy(holdingTableContent, [sortColumn.path], [sortColumn.order]);
		const displayfrypotStatusContent = _.orderBy(frypotStatusContent, [frypotStatusSortColumn.path], [frypotStatusSortColumn.order]);
		let displayHoldingDatas;

		let columns = [
			{ path: "", label: "#" },
			{ path: "hours", labelStringID: "KC0449" },
			{ path: "totalproducts", labelStringID: "KC0450" },
			{ path: "totalcount", labelStringID: "KC1895" },  
			{ path: "", labelStringID: "KC0325" },
		];

		let frypotStatusColumns = [
			{ path: "Key", labelStringID: "KC0457" },
			{ path: "Value", label: "%" }
		];

		if (Array.isArray(displayContents) && displayContents.length) {
			displayHoldingDatas = displayContents.map((item, i) =>
				<React.Fragment key={i}>
					<div className="productMixTable">
						<ul className="tBody">
							<li>{i + 1}</li>
							<li>{item.hours}</li>
							<li>{item.totalproducts}</li>
							<li>{item.totalcount}</li>
							<li><a role="button" data-toggle="collapse" draggable="false" data-parent="#holdingDataAccordion" data-target={`#holdingDataAcc_` + item.sno} aria-expanded="false" aria-controls="holdingDataAcc_1" className="btn collapseExpand collapsed">&nbsp;</a></li>
						</ul>
					</div>
					<div id={`holdingDataAcc_` + item.sno} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`holdingDataAcc_` + item.sno}>
						<div className="panel-body productMixExpTable">
							{displayContents[i] && displayContents[i].products && displayContents[i].products.map((items, j) =>
								<React.Fragment key={j}>
									<ul className="tBody">
										<li>&nbsp;</li>
										<li>{items.product ? items.product : ""}</li>
										<li>{items.product ? items.product : ""}</li>
										<li>{items.count ? items.count : ""}</li>
										<li>&nbsp;</li>
									</ul>
								</React.Fragment>
							)}
						</div>
					</div>
				</React.Fragment>
			)
		}
		else {
			displayHoldingDatas = (<div className="noResultMsg"><FormattedMessage id="KC0264"/></div>)
		}
		return (
			<React.Fragment>
				<div className="pageTabHeader greybg minHeight01">
					<ul>
						<li class="filterDropdown">
							<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
							<NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate}
								minDateDisable={this.state.sdmindate}
								maxDateDisable={this.state.sdmaxdate}
								endminDateDisable={this.state.edmindate}
								endmaxDateDisable={this.state.edmaxdate}
								endDatepara={this.state.endDate}
								handleChangeStartProps={(date) => this.handleChangeStart(date)}
								handleChangeEndProps={(date) => this.handleChangeEnd(date)}
								OnChange={(e) => this.onDropDownHandler(e, "HoldingData")}
								filterType={this.state.type}
								disableDatePicker={false} />
							<HolderList OnHolderChange={(e) => this.onHolderSelection(e, this.state.type)} />
						</li>
					</ul>
				</div>

				<div className="gridViewTable">
					<div className="gridView">
						<div className="colm12D">
							<div className="colmDi">
								<h4><FormattedMessage id="KC0447"/></h4>
								<div className={displayfrypotStatusContent.length === 0 ? "noResultMsg" : "displaynone"}><FormattedMessage id="KC0264"/></div>
								<div className={displayfrypotStatusContent && displayfrypotStatusContent.length > 0 ? "frypotStatusOuter" : "displaynone"}>
									<div className="frypotStatusChart">
										<FrypotStatusPieChart frypotStatusInformation={frypotStatusChartContent} />
										<ul className="frypotStsLegendSection">
											<li><span></span><FormattedMessage id="KC0453"/></li>
											<li><span></span><FormattedMessage id="KC0360"/></li>
											<li><span></span><FormattedMessage id="KC0361"/></li>
											<li><span></span><FormattedMessage id="KC0454"/></li>
										</ul>
									</div>
									<div className="frypotStatusRD">
										<div className="frypotStatusTable">
											<TableHeader
												userMgmtListScreen={this.state.userListFlag}
												sortColumn={frypotStatusSortColumn}
												onSort={this.onfrypotStatusSort}
												columns={frypotStatusColumns} />
											{displayfrypotStatusContent.map((item, i) => <ul className="tBody">
												<li>{item.Key ? item.Key : ""}</li>
												<li>{item.Value ? item.Value : 0}%</li>
											</ul>)}
										</div>
									</div>
								</div>
								<div>
									&nbsp;
								</div>
								<h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}</h4>
								{/* <!-- Utilization Table -->   */}
								<div className="productMixTableDiv">
									<div className="productMixTableOuter">
										<div className="productMixTable">
											<TableHeader
												userMgmtListScreen={true}
												sortColumn={sortColumn}
												onSort={this.onSort}
												columns={columns} />
										</div>
										<div className="panel panel-default" id="holdingDataAccordion" role="tablist" aria-multiselectable="true">
											{/* <!-- Header 01 --> */}
											{displayHoldingDatas}
											{/* <!-- Header 01 Content End --> */}
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
				{/* //Utilization Table   */}
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		holdingTableInfo: state.holdingTableResponse,
		currentUnit: state.entityReducer.currentUnit,
		currentLocation: state.entityReducer.currentLocation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getHoldingTableData,
		getCurrentLocation,
		getCurrentUnit
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductMixByHourTable);
