import http from '../../../service/httpService';
import {smartGroupAPI} from '../../../service/api';
export const PURPOSES_REQUEST = 'PURPOSES_REQUEST';
export const PURPOSES_SUCCESS = 'PURPOSES_SUCCESS';
export const PURPOSES_FAIL = 'PURPOSES_FAIL';
export const SELECTED_PURPOSES_LIST ='SELECTED_PURPOSES_LIST';

export function getPurposes (req) {
    return dispatch => {
        dispatch({
            type: PURPOSES_REQUEST
        })
        let url = smartGroupAPI.purposeList + localStorage.getItem("customerId");
        http.get(url,
                {
                    headers: {
                        'Content-Type':'application/json',  
                        // 'cid': localStorage.getItem("customerId"),                      
                    },
                    data:{}
                }
                )
            .then(response => {
                let res = response.data;
                res.forEach(i => delete i.userId);
                res.forEach(i => delete i.customerId);                
                dispatch({
                    type: PURPOSES_SUCCESS,
                    purposesInformation: res,
                })
            })
            .catch(error => {
                dispatch({
                    type: PURPOSES_FAIL,
                    error
                })
            })
    }
}
 