var garlandRecipeJSON = {
    "active_compression": 0,
    "cookingRegions": [],
    "cookingSteps": [],
    "cooking_methodology": 0,
    "icon": "",
    "partial_load": {
        "elapsed_time": 0,
        "enabled": 0,
        "temp_delta": 0,
        "time_scale_factor": 0
    },
    "pr_thaw": {
        "detect_delta_gap": 0,
        "enabled": 0,
        "time": 0
    },
    "protein_type": "beef",
    "recipeID": "",
    "recipeName": "",
    "recipe_type": 0,
    "schemaVersion": "1.1",
    "smokeFanStart_T_Minus": 0,
    "smokeFanStop_T_Plus": 0,
    "too_cold_limit": 0,
    "too_hot_limit": 0
}

export default garlandRecipeJSON 