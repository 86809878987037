const Constants = {
   CREATE_ACCOUNT: 'Create Account',
   NEW_ACCOUNT_CREATED: 'New Account Created',
   FORGOT_PASSWORD: 'Forgot Password',
   FORGET_PASSWORD_REQUEST: 'Forget Password Requested',
   FORGET_PASSWORD_REQUEST_FAIL: 'Forget Password Request Fail',
   SUPPORT_WIDGET: 'Contact Support',
   USER_LOGIN: 'User Login',
   DASHBOARD_UNIT_LOCATION: 'Dashboard Unit Location ',
   SIDE_BAR_MENU: ' ',
   LIST_VIEW_LOCATION: 'List View Location',
   LIST_VIEW_UNIT: 'List View Unit',
   LOGOUT:'User Logout',
   MAP_VIEW_LOCATION: 'Map View Location',
   MAP_VIEW_UNIT: 'Map View Unit',
   ERROR_REPORT_EMAIL: 'Error Report Email',
   SERVICE_MANAGEMENT: 'Service Management',
   MAIN_MENU: 'Main Menu ',
   UNIT_DETAILS: 'Unit Detail ',
   CLEAR_ERROR: 'Clear Error', // crem
   ERROR_HELP_SECTION: 'Error Help Section',
   REPORT_AND_ANALYTICS: 'Report and Analytics',
   HELP_MANUALS: 'Help Manuals',
   DOWNLOAD_BACKUP_LOG: 'Download Backup Log',
   RETRIEVE_BACKUP_LOG: 'Retrieve Backup Log',
   CANCEL_BACKUP_LOG: 'Cancel Retrieve Operation',
   OPEN_MACHINE_AND_AGENT_LOG: 'Open Log Files',
   RETRIEVE_MACHING_AND_AGENT_LOG: 'Retrieve Latest Log Files',
   DOWNLOAD_AGENT_LOG: 'Download Agent Log',
   DOWNLOAD_MACHINE_LOG:'Download Machine Log',
   ADD_SMART_GROUP: 'Add To Other Smart Group',
   REMOVE_SMART_GROUP: 'Remove Smart Group',
   SERVICE_MGMNT_CURRENT_AND_HISTORIC_ERRORS: 'Service Management',
   ORG_MANAGEMENT: 'Organization Management',
   NODE_ADDED: 'Node Added',
   NODE_UPDATED: 'Node Updated',
   USER_MANAGEMENT: 'User Management',
   MENU_AND_UNITSETTING:'Menu and Unit Setting',
   RENAME_COLUMN: 'Renamed Column', // org mgnt levelheader
   CANCLED_RENAME: 'Canceled Rename Column', // org mgnt
   CANCEL_RENAME: 'Cancel Rename',// org mgnt
   CONFIRM_RENAME: 'Confirm Rename',// org mgnt
   RENAME_COLUMN_FAILED: 'Rename Column Failed',// org mgnt
   NODE_IMAGE_UPLOAD: 'Node Image Upload',// org mgnt
   NODE_IMAGE_UPLOADED: 'Node Image Uploaded', // org mgnt,
   DELETED_NODE: 'Node Deleted',// org mgnt,
   DEVIVCE_ACTIVATION_STATUS: 'Activation Status',// org mgnt,
   DRAG_AND_CONFIRM:'Drag and Drop Confirmed',
   // DRAG_AND_CONFIRM:'Drag and Drop Cancel'
   MENU_AND_UNITSETTING:'Menu & Unit Setting',
   CANCLE_RENAME: 'Cancel Rename Column', // org mgnt

   SMARTGROUP:'Smart Group',
   ADD_SMART_GROUPS:'Add Smart Group',
   EDIT_SMART_GROUP:'Edit Smart Group',
   DELETE_SMART_GROUP:'Delete Smart Group',   
   ADD_PURPOSE :"Add Purpose",
   EDIT_PURPOSE:"Edit Purpose",
   PURPOSE_ADDED:"Purpose Added",
   PURPOSE_UPDATED:"Purpose updated",
   PURPOSE_DELETED:"Purpose deleted",
   PURPOSE_CONFIRM:"Purpose Confirm",
   PURPOSE_CANCELED:"Purpose Canceled",
   SMART_GROUP_NAME_VALIDATED:"Smart Group Name Validated",
   SMART_GROUP_CREATED:"Smart Group Created",
   PURPOSE_VALIDATION_CONFIRM:"Purpose Validation Confirm",
   PURPOSE_VALIDATION_CANCELED:"Purpose Validation Canceled",
   SMART_GROUP_UPDATED:"Smart Group Updated",
   EDIT_SMART_GROUP_UNITS:"Update Smart Group Units",
   
   // Unit Registration and Assignment
   UNIT_ACTIVATED: 'Unit Activated',
   ASSIGN_UNITS: 'Assigned Unit',
   ADD_UNIT: 'Add Unit',
   DELETE_UNIT: 'Unit Deleted',
   // User Management
   USER_STATUS: 'User Status',
   RESET_PASSWORD_REQUEST_SEND: 'Reset Password Request Send', 
   RESET_PASSWORD_REQUEST_FAILED: 'Reset Password Request Failed',
   USER_NODE_ASSIGNED: 'User Node Assigned',
   USER_CREATED:'User Created',
   USER_UPDATED:'User Updated',
   USER_DELETED: 'User Deleted',
   UPDATE_USER_ROLE: 'Update User Role',
   CREATE_NEW_USER_ROLE: 'Create User Role',
   DELETE_ROLE:'Delete User Role',

   //Menu Management   
   ADD_MENU:"Add Menu",
   EDIT_MENU:"Edit Menu",
   DELETE_MENU:"Delete Menu",
   MENU_TRANSFER:"Menu Transfer",
   MENU_SG_ASSIGN:"Menu Smart Group Assign",   
   MENU:"Menu",
   MENU_ADDED:"Menu Added",
   MENU_UPDATED:"Menu Updated",
   MENU_DELETED:"Menu Deleted",
   MENU_SG_ASSIGNED:"Menu Smart Group Assigned",
   MENU_CANCELED:"Menu Canceled",
   
   //Software Management   
   SOFTWARE:"Software",
   SOFTWARE_TRANSFER:"Software Transfer",
   SOFTWARE_SG_ASSIGN:"Software Smart Group Assign",
   SOFTWARE_SG_ASSIGNED:"Software Smart Group Assigned",

   //Media Management 
   MEDIA:"Media",
   MEDIA_TRANSFER:"Media Transfer",
   MEDIA_SG_ASSIGN:"Media Smart Group Assign",
   MEDIA_SG_ASSIGNED:"Media Smart Group Assigned",

   // HACCP Management
   SELECT_BRAND: 'Select Brand',
   RETRIEVE_STATUS: 'Retrieve Status',
   LOG_LIBRARY: 'Log Library',
   DOWNLOAD_SELECTED_LOG_LIBRARY: 'Download Log Library',
   DELETE_LOG_LIBRARY: 'Delete Log Library',
   HACCP_LOG_VIEWER: 'HACCP Log Viewer',
   ADD_LOG_LIBRARY: 'Add Log Library',
   ADD_LOG_LIBRARY_FAILED: 'Assignment Failed', // log library
   REFRESH: 'Refresh',
   
   // Reports
   REPORTS: 'Reports',
   DOWNLOAD_THE_REPORT_LOG: 'Download Report Log',
   DOWNLOAD_THE_REPORT_LOG_FAILED: 'Download Report Failed',
   DELETE_REPORTS: 'Delete Reports',
   DELETE_REPORTS_FAILED: 'Delete Report Failed',  
   GENARATE_NEW_REPORT: 'New Report Generated', // CREM
   GENARATE_NEW_REPORT_FAILED: 'Report Generate failed',// CREM
   EMAIL_REPORT_CONFIGURATION: 'Email Report Configuration Setting',
   EMAIL_REPORT_CONFIGURATION_UPDATED: 'Email Report Configuration Updated',
   EMAIL_REPORT_CONFIGURATION_FAILED: 'Email Report Configuration Failed',

   MEDIA_DELETED:"Media Deleted",
   MEDIA_CANCELED:"Media Canceled",   
   MEDIA_FILE_UPLOADED:"Media File Uploaded",
   PACKAGE_CREATED:"Package Created",

   FILE_SELECTED:"File Selected",
   UNITS_FOR_FILE_TRANSFER:"Units For File Transfer",
   FILE_TRANSFERED:"File Transfered",
   MENU_BRAND_SELECTED:"Menu Brand Selected",
   SOFTWARE_BRAND_SELECTED:"Software Brand Selected",
   MEDIA_BRAND_SELECTED:"Media Brand Selected",
   RETRY_DISTRIBUTION: "Distribution Retried",
   DELETE_DISTRIBUTION: "Distribution Deleted",
   CANCEL_DISTRIBUTION: "Distribution Canceled",
   DATE_APPLIED: "Date Filter Applied",
   STATUS_FILTER_APPLIED: "Status Filter Applied",
   SMARTGROUP_STATUS:"Smart Group Status",
   LIST_STATUS:"List Status",

   // Error Center
   HISTORIC_ERROR: 'Historic Error',
   CURRENT_ERROR: 'Current Error',
   ERROR_TYPE: 'Error Type',
   DELETE_ERROR: 'Delete Error',

   // Setting and Product Selection
   EXCLUDE_PRODUCT_UPDATED: 'Exclude Product Updated',
   EXCLUDE_PRODUCT_CREATED: 'Exclude Product Created',
   EXCLUDE_PRODUCT_DELETED:'Exclude Product Deleted',

   // User Profile
   ACCESS_USER_PROFILE: 'User Profile',
   USER_PROFILE_UPDATED: 'User Profile Updated',
   MANAGE_PASSWORD: 'User Password Updated',
   UPDATE_NOTIFICATION: 'Update Notification',
   UPDATE_TIME: 'Update Time',
   VIEW_ACCOUNT_INFORMATION: 'View Account',
   CONTACT_YOUR_ADMIN: 'Contact Your Admin',

   //Notification Library
   TROUBLESHOOTING_THROUGH_VIDEO:"Troubleshooting Through Video",
   TROUBLESHOOTING_THROUGH_LINK:"Troubleshooting Through Link",

   UNITS: 'Dashboard Units',
   LOCATION: 'Dashboard Location',
   REPLACE: 'Replace Screen Saver',
   PREVIEW_IMAGE: 'Screen Saver Preview',
   ORG_CHART_VIEW: 'Organization Chart View',
   USER_EDIT: 'User Edit',
   USER_ASSIGN: 'User Assign',

}
export default Constants;  