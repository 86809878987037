import { IconWidget } from '@kcui/react/IconWidget';
import { DetailWidget } from '@kcui/react/DetailWidget';
import { StatusWidget } from '@kcui/react/StatusWidget';
import { TableWidget } from '@kcui/react/TableWidget';
import { HeaderWidget } from '@kcui/react/HeaderWidget';
import { InformationWidget } from '@kcui/react/InformationWidget';
import { TrendWidget } from '@kcui/react/TrendWidget';
import { LinkWidget } from '@kcui/react/LinkWidget';
import { HorizontalStackBarChartWidget } from '@kcui/react/HorizontalStackBarChartWidget';
import { LineChartWidget } from '@kcui/react/LineChartWidget';
import { BarChartWidget } from '@kcui/react/BarChartWidget';
import { DualListWidget } from '@kcui/react/DualListWidget';

import { StatusWidgetV2 } from '@kcui/react/StatusWidgetV2';
import { IconWidgetV2 } from '@kcui/react/IconWidgetV2';
import { InformationWidgetV2 } from '@kcui/react/InformationWidgetV2';
import { DualListWidgetV2 } from '@kcui/react/DualListWidgetV2';
import { DiskSpaceWidget } from '@kcui/react/DiskSpaceWidget';



import { PieChartTableWidget } from '@kcui/react/PieChartTableWidget';
import { BarChartTargetWidget } from '@kcui/react/BarChartTargetWidget';
import { CustomBarChartWidget } from '@kcui/react/CustomBarChartWidget';
import { MultiSeriesLineChartWidget } from '@kcui/react/MultiSeriesLineChartWidget';
import { SmoothCurvedLineChartWidget } from '@kcui/react/SmoothCurvedLineChartWidget';

//Custom Components with least configuration.
import LogDownloaderWidget from '../components/unitManagement/widget/logDownloaderWidget';
import MediaLibraryWidget from '../components/menuManagement/mediaLibraryWidget';

// Crypto
import { createDecipheriv, createCipheriv } from 'crypto';
import { CRYPTO_SECRET_KEY } from './appConstants';

const algorithm = 'aes-256-ctr';
const iv = Buffer.from("vOVH6sdmpNWjRRIq", "utf-8");

const get32CharKey = (secretKey) => {
    return secretKey.substr(0, 32);
}

var moment = require('moment');

export const liveViewDuration = 300;
export const liveViewDurationInMinutes = liveViewDuration / 60;
export const liveDiagnosticDuration = 300;
export const liveDiagnosticDurationInMinutes = liveDiagnosticDuration / 60;
export const liveDiagnosticWarningPopupDuration = 0;
export const liveDiagnosticSoftwareVersion = {
    'MERCO': '06.00.00',
    'XPRESSCHEF': '00.00.00',
    'ICE-O-MATIC': '00.00.00',
    'EGRO': '00.00.00'
}

export const liveDiagnosticBrandConfig = {
    MERCO: [
        "data.data.data.version.shadow.document.deviceStatusInformation.versionData.firmwareVersionIoT.value",
        "data.data.data.version.shadow.document.softwareVersion.firmwareVersionIoT"
    ],
    XPRESSCHEF: [
        "data.data.data.version.shadow.document.deviceStatusInformation.versionData.softwareVersionMainBoardEE.value",
    ],
    'ICE-O-MATIC': [
        "data.data.data.version.shadow.document.deviceStatusInformation.versionData.iotSoftwareVersion.value"
    ],
    EGRO: [
        "data.data.data.version.shadow.document.deviceStatusInformation.versionData.softwareVersionIOTAgent.value"
    ]
}

export const liveDiagnosticAction = {
    Enable: 'enable',
    Disable: 'disable',
    Extend: 'extend'
}

// URL[menuName] to gloabalHeaderLayout active property value.
export const menuMapping = {
    'unitdashboard': 'dashboardNav',
    'reportdashboard': 'reportNav',
    'servicedashboard': 'serviceNav',
    'assetdashboard': 'unitNav',
    'mediadashboard': 'mediaNav'
}

// URL[menuName] to configuration mapping
export const dataMapping = {
    'unitdashboard': 'unitDashboard',
    'reportdashboard': 'reportDashboard',
    'servicedashboard': 'serviceDashboard',
    'assetdashboard': 'assetDashboard',
    'mediadashboard': 'mediaDashboard'
}

// brand which supports scalable dashboard
export const brandWithDashboardRenderer = {
    'MERCO': true,
    'XPRESSCHEF': true,
    'ICE-O-MATIC': true,
    'EGRO': true
}

// make sure the format is compatible with Date constructor 
export const apiDateFormat = 'YYYY-MM-DD';

export const panDateFormat = 'YYYY-MM-DD HH:mm:ss';

export const offlinePopupTimeFormat = 'MMM D, YYYY h:mm A (UTCZ)';


export const mappingMercoURL = {
    "/reportManagement": "reportdashboard",
    "/serviceManagement": "servicedashboard",
    "/unitManagement": "assetdashboard",
    "/unitDashboard": "unitdashboard",
    "/mediaLibrary": "mediadashboard"
}

export const Widget = {
    IconWidget: 'IconWidget',
    DetailWidget: 'DetailWidget',
    StatusWidget: 'StatusWidget',
    TableWidget: 'TableWidget',
    InformationWidget: 'InformationWidget',
    TrendWidget: 'TrendWidget',
    HeaderWidget: 'HeaderWidget',
    LinkWidget: 'LinkWidget',
    HorizontalStackBarChartWidget: 'HorizontalStackBarChartWidget',
    DualListWidget: 'DualListWidget',
    LineChartWidget: 'LineChartWidget',
    BarChartWidget: 'BarChartWidget',
    StatusWidgetV2: 'StatusWidgetV2',
    IconWidgetV2: 'IconWidgetV2',
    DualListWidgetV2: 'DualListWidgetV2',
    InformationWidgetV2: 'InformationWidgetV2',
    LogDownloaderWidget: 'LogDownloaderWidget',
    PieChartTableWidget: 'PieChartTableWidget',
    BarChartTargetWidget: 'BarChartTargetWidget',
    CustomBarChartWidget: 'CustomBarChartWidget',
    MultiSeriesLineChartWidget: 'MultiSeriesLineChartWidget',
    SmoothCurvedLineChartWidget: 'SmoothCurvedLineChartWidget',
    MediaLibraryWidget: 'MediaLibraryWidget',
    DiskSpaceWidget: 'DiskSpaceWidget'
}

export function ComponentMapping(widget) {
    switch (widget.type) {
        case Widget.IconWidget:
            return IconWidget;
        case Widget.DetailWidget:
            return DetailWidget;
        case Widget.StatusWidget:
            return StatusWidget;
        case Widget.TableWidget:
            return TableWidget;
        case Widget.InformationWidget:
            return InformationWidget;
        case Widget.TrendWidget:
            return TrendWidget;
        case Widget.HeaderWidget:
            return HeaderWidget;
        case Widget.LinkWidget:
            return LinkWidget;
        case Widget.HorizontalStackBarChartWidget:
            return HorizontalStackBarChartWidget;
        case Widget.DualListWidget:
            return DualListWidget;
        case Widget.StatusWidgetV2:
            return StatusWidgetV2;
        case Widget.IconWidgetV2:
            return IconWidgetV2;
        case Widget.DualListWidgetV2:
            return DualListWidgetV2;
        case Widget.InformationWidgetV2:
            return InformationWidgetV2;
        case Widget.LineChartWidget:
            return LineChartWidget;
        case Widget.BarChartWidget:
            return BarChartWidget;
        case Widget.LogDownloaderWidget:
            return LogDownloaderWidget
        case Widget.PieChartTableWidget:
            return PieChartTableWidget;
        case Widget.BarChartTargetWidget:
            return BarChartTargetWidget;
        case Widget.CustomBarChartWidget:
            return CustomBarChartWidget;    
        case Widget.MultiSeriesLineChartWidget:
            return MultiSeriesLineChartWidget;
        case Widget.SmoothCurvedLineChartWidget:
            return SmoothCurvedLineChartWidget;
        case Widget.MediaLibraryWidget:
            return MediaLibraryWidget
        case Widget.DiskSpaceWidget:
            return DiskSpaceWidget;
        default:
            return "";
    }
}

export const action = {
    loadWigetData: "loadWidgetData",
    setWidgetScope: "setWidgetScope",
    setWidgetDateFilter: "setWidgetDateFilter"
}

// get formatted date for the given date filter value
// this formatted date being sent to api
// as this is used for mongoDB date query, we are adding (+1) day for toDate
export const getFormattedDateFilterValue = (dateFilterValue) => {
    const formattedPreviousValue = calculatePreviousDate(dateFilterValue);
    const formattedFromDate = dateFilterValue.fromDate ? moment(dateFilterValue.fromDate).format(apiDateFormat) : '';
    const formattedToDate = dateFilterValue.toDate ? moment(dateFilterValue.toDate).add(1, 'day').format(apiDateFormat) : '';

    return {
        dateFilterValueFormatted: {
            value: dateFilterValue.value,
            fromDate: formattedFromDate,
            toDate: formattedToDate
        },
        dateFilterValueFormattedPrevious: {
            value: dateFilterValue.value, ...formattedPreviousValue
        },
        dateFilterValueFormattedTrend: {
            value: dateFilterValue.value,
            fromDate: formattedPreviousValue.fromDate,
            toDate: formattedToDate
        },
        dateFilterDays: formattedToDate && formattedFromDate ? moment(formattedToDate).diff(moment(formattedFromDate), 'days') : 0,
        dateFilterDaysPrevious: formattedPreviousValue.fromDate && formattedPreviousValue.toDate ? moment(formattedPreviousValue.toDate).diff(moment(formattedPreviousValue.fromDate), 'days') : 0,
        dateFilterMinutes: formattedToDate && formattedFromDate ? moment(formattedToDate).diff(moment(formattedFromDate), 'minutes') : 0,
        dateFilterMinutesPrevious: formattedPreviousValue.fromDate && formattedPreviousValue.toDate ? moment(formattedPreviousValue.toDate).diff(moment(formattedPreviousValue.fromDate), 'minutes') : 0,
    }
}

export const getDateRange = (filterType, reqDate) => {
    const dateRange = {
        fromDate: "",
        toDate: moment(reqDate).add(1, 'day').format(apiDateFormat)
    }
    switch (filterType) {
        case "Day": {
            dateRange.fromDate = moment(reqDate).format(apiDateFormat);
            break;
        }
        case "Week": {
            dateRange.fromDate = moment(reqDate).subtract(1, 'week').format(apiDateFormat);
            break;
        }
        case "Month": {
            dateRange.fromDate = moment(reqDate).subtract(1, 'month').format(apiDateFormat);
            break;
        }
        case "Quarter": {
            dateRange.fromDate = moment(reqDate).subtract(1, 'quarter').format(apiDateFormat);
            break;
        }
        case "Year": {
            dateRange.fromDate = moment(reqDate).subtract(1, 'year').format(apiDateFormat);
            break;
        }
        default: {
            dateRange.fromDate = ""
            break;
        }
    }
    return dateRange
}

export const getProductionDateRange = (filterType, reqDate) => {
    const currentRange = getDateRange(filterType, reqDate);
    const reqDatePrevRange = moment(currentRange.fromDate).subtract(1, 'day')
    const prevRange = getDateRange(filterType, reqDatePrevRange)
    const dateRanges = {
        currentRange: currentRange,
        prevRange: prevRange
    }
    return dateRanges
}

export const dateFilterOptions = {
    Day: "Day",
    Week: "Week",
    Month: "Month",
    Quarter: "Quarter",
    Year: "Year",
    Custom: "Custom",
}

const dateFilterTrendLabelMapping = {
    [dateFilterOptions.Day]: "KC2385",
    [dateFilterOptions.Week]: "KC2386",
    [dateFilterOptions.Month]: "KC2384",
    [dateFilterOptions.Quarter]: "KC2387",
    [dateFilterOptions.Year]: "KC2388",
    [dateFilterOptions.Custom]: "",
}

export const calculatePreviousDate = (dateFilterValue) => {
    let calculatedDate = {};
    switch (dateFilterValue.value) {
        case dateFilterOptions.Day:
            calculatedDate = {
                fromDate: moment(dateFilterValue.fromDate).subtract(1, 'day').format(apiDateFormat),
                toDate: moment(dateFilterValue.toDate).format(apiDateFormat),
            }
            break;
        case dateFilterOptions.Week:
            calculatedDate = {
                fromDate: moment(dateFilterValue.fromDate).subtract(1, 'week').format(apiDateFormat),
                toDate: moment(dateFilterValue.toDate).subtract(1, 'week').endOf('week').add(2, 'days').format(apiDateFormat),
            }
            break;
        case dateFilterOptions.Month:
            calculatedDate = {
                fromDate: moment(dateFilterValue.fromDate).subtract(1, 'month').format(apiDateFormat),
                toDate: moment(dateFilterValue.toDate).subtract(1, 'month').endOf('month').add(1, 'days').format(apiDateFormat),
            }
            break;
        case dateFilterOptions.Quarter:
            calculatedDate = {
                fromDate: moment(dateFilterValue.fromDate).subtract(1, 'quarter').format(apiDateFormat),
                toDate: moment(dateFilterValue.toDate).subtract(1, 'quarter').endOf('quarter').add(1, 'days').format(apiDateFormat),
            }
            break;
        case dateFilterOptions.Year:
            calculatedDate = {
                fromDate: moment(dateFilterValue.fromDate).subtract(1, 'year').format(apiDateFormat),
                toDate: moment(dateFilterValue.toDate).subtract(1, 'year').endOf('year').add(1, 'days').format(apiDateFormat),
            }
            break;
        default:
            calculatedDate = { fromDate: "", toDate: "" }
            break;
    }
    return calculatedDate;
}

export const dashboardRendererImageConstant = {
    'sadSmiley': require('../images/icons/smileyBad.svg').default,
    'happySmiley': require('../images/icons/smileyGood.svg').default,
    'offlineSmiley': require('../images/icons/smileyOffline.svg').default,
    'book': require('../images/icons/manual.svg').default,
    'clock': require('../images/icons/clock.svg').default,
    'circleCheck': require('../images/icons/circleTick.svg').default,
    'circleLightning': require('../images/icons/criticalIcon.svg').default,
    'circleExclamation': require('../images/icons/noCriticalIcon.svg').default,
    'wifi': require('../images/icons/wifi.svg').default,
    'wifiSlash': require('../images/icons/wifiOffline.svg').default,
}
export const errorTypes = {
    ErrorCritical: 'Error Critical',
    ErrorNonCritical: 'Error Non-critical',
    Event: 'Event'
}
export const dashboardRenderErrorTypeImages = {
    [errorTypes.ErrorCritical]: require('../images/icons/CriticalError.svg').default,
    [errorTypes.ErrorNonCritical]: require('../images/icons/NonCError.svg').default,
    [errorTypes.Event]: require('../images/icons/Event.svg').default
}

export const trendArrowImages = {
    ["high"]: require('../images/icons/arrow3UpT.svg').default,
    ["low"]: require('../images/icons/arrow3DownD.svg').default,
    [""]: require('../images/icons/arrow3UpT.svg').default,
}

// This format is used to display current unit time in trend widgets
export const unitTimeFormat = 'hh:mm a';

// This format is used to display error started-resolved date
export const customDateFormatted = 'MMMM D, YYYY HH:mm'

export const getUnitCurrentDate = (timeZone) => {
    return new Date().toLocaleString("en-US", { timeZone });
}

export const getRequestEnableDate = (date, timeZone) => {
    return new Date(date).toLocaleString();
}

export const getUnitCurrentTimeFormatted = (timeZone) => {
    return moment(getUnitCurrentDate(timeZone)).format(unitTimeFormat)
}

export const getTrendLabel = (value) => {
    return dateFilterTrendLabelMapping[value];
}

export const getHelpLinkUrl = () => {
    const hostname = window && window.location && window.location.hostname;
    let helpLinkUrl = 'https://help.welbiltdigitaldev.com/';
    if (hostname === 'www.welbiltdigitalqa.com' || hostname === 'welbiltdigitalqa.com') {
        //For QA Environment
        helpLinkUrl = 'https://help.welbiltdigitalqa.com/';
    } else if (hostname === 'www.welbiltdigitaluat.com' || hostname === 'welbiltdigitaluat.com') {
        //For UAT Environment
        helpLinkUrl = 'https://help.welbiltdigitaluat.com/';
    } else if (hostname === 'www.welbiltconnect.com' || hostname === 'welbiltconnect.com') {
        //For Production Environment
        helpLinkUrl = 'https://help.welbiltconnect.com/';
    } else if (hostname === 'www.welbiltdigitalintegration.com' || hostname === 'welbiltdigitalintegration.com') {
        //For Integration Environment
        helpLinkUrl = 'https://help.welbiltdigitalintegration.com/';
    }
    return helpLinkUrl;
}

export const liveDiagnosticWebSocketURL = () => {
    const hostname = window && window.location && window.location.hostname;
    let websocketUrl = "wss://websocketapi.welbiltdigitaldev.com/live-diagnostic";
    if (hostname === 'www.welbiltdigitalqa.com' || hostname === 'welbiltdigitalqa.com') {
        //For QA Environment
        websocketUrl = "wss://websocketapi.welbiltdigitalqa.com/live-diagnostic";
    } else if (hostname === 'www.welbiltdigitaluat.com' || hostname === 'welbiltdigitaluat.com') {
        //For UAT Environment
        websocketUrl = "wss://websocketapi.welbiltdigitaluat.com/live-diagnostic";
    } else if (hostname === 'www.welbiltconnect.com' || hostname === 'welbiltconnect.com') {
        //For Production Environment
        websocketUrl = "wss://websocketapi.welbiltconnect.com/live-diagnostic";
    } else if (hostname === 'www.welbiltdigitalintegration.com' || hostname === 'welbiltdigitalintegration.com') {
        //For Integration Environment
        websocketUrl = "wss://websocketapi.welbiltdigitalintegration.com/live-diagnostic";
    }
    return websocketUrl;
}

export const liveDiagnosticTimer = (seconds, requestDuration) => {
    if (!isNaN(seconds) && seconds >= 0) {
        const remaningSeconds = requestDuration - seconds;
        const mins = Math.floor((remaningSeconds / 60)).toString().padStart(2, '0');
        const remSeconds = Math.floor((remaningSeconds % 60)).toString().padStart(2, '0');
        return `${mins}:${remSeconds}`;
    }
    return '00:00';
};

export const liveDiagnosticStates = {
    Online: "Online",
    Offline: "Offline",
    EstablishConnection: "EstablishConnection",
    SessionSuccess: "SessionSuccess",
    SessionFailure: "SessionFailure",
    SessionWarning: "SessionWarning",
    ExtendSuccess: "ExtendSuccess"
}

export const diagnosticChartDataParser = {
    [Widget.LineChartWidget]: ({ _widgetScope, _sessionData, _liveData }) => {
        const data = _sessionData || _liveData;
        const { yAxisValueProp, stackValueProp } = _widgetScope.rawConfiguration.data;
        let result = [];
        if (data) {
            for (let index = 0; index < data.length; index++) {
                const { eventData, timeStamp } = data[index];
                result.push({
                    x: new Date(timeStamp),
                    y: eventData[yAxisValueProp].value,
                    value: `${stackValueProp}${eventData[stackValueProp].value}`,
                    binVal: eventData[stackValueProp].value,
                    yUnit: eventData[yAxisValueProp].unit
                })
            }
        }
        result = result.sort((a, b) => parseFloat(a.binVal) - parseFloat(b.binVal));
        result = result.map(ele => ({
            x: ele.x,
            y: ele.y,
            value: ele.value,
            yUnit: ele.yUnit
        }));

        const response = {};
        if (_sessionData) {
            response.value = result;
        }
        if (_liveData) {
            response.stream = result;
        }
        return {
            data: response
        }
    },
    [Widget.BarChartWidget]: ({ _widgetScope, _sessionData, _liveData }) => {
        const data = _sessionData || _liveData;
        const { yAxisValueProp, stackValueProp } = _widgetScope.rawConfiguration.data;
        let result = [];
        if (data) {
            for (let index = 0; index < data.length; index++) {
                const { eventData, timeStamp } = data[index];
                result.push({
                    x: new Date(timeStamp),
                    y: eventData[yAxisValueProp].value,
                    value: `${stackValueProp}${eventData[stackValueProp].value}`,
                    binVal: eventData[stackValueProp].value,
                    yUnit: eventData[yAxisValueProp].unit
                })
            }
        }
        result = result.sort((a, b) => parseFloat(a.binVal) - parseFloat(b.binVal));
        result = result.map(ele => ({
            x: ele.x,
            y: ele.y,
            value: ele.value,
            yUnit: ele.yUnit
        }));
        const response = {};
        if (_sessionData) {
            response.value = result;
        }
        if (_liveData) {
            response.stream = result;
        }
        return {
            data: response
        }
    },
    [Widget.MultiSeriesLineChartWidget]: ({ _widgetScope, _sessionData, _liveData }) => {
        const data = _sessionData || _liveData;
        const { stack } = _widgetScope.rawConfiguration.properties;
        let result = [];
        if (data) {
            for (let index = 0; index < data.length; index++) {
                const { eventData, timeStamp } = data[index];
                let newData = {
                    x: new Date(timeStamp)
                }
                for(let i = 0; i < stack.length; i++) {
                    const stackName = stack[i].value
                    newData[stackName] = eventData[stackName].value
                    newData.yUnit = eventData[stackName].unit
                }
                result.push(newData)
            }
        }
        const response = {};
        if (_sessionData) {
            response.value = result;
        }
        if (_liveData) {
            response.stream = result;
        }
        return {
            data: response
        }
    },
    '_default': ({ _widgetScope, _sessionData, _liveData }) => {
        const data = _sessionData || _liveData;
        const { yAxisValueProp, stackValueProp } = _widgetScope.rawConfiguration.data;
        let result = [];
        if (data) {
            for (let index = 0; index < data.length; index++) {
                const { eventData, timeStamp } = data[index];
                result.push({
                    x: new Date(timeStamp),
                    y: eventData[yAxisValueProp].value,
                    value: `${stackValueProp}${eventData[stackValueProp].value}`,
                    binVal: eventData[stackValueProp].value,
                    yUnit: eventData[yAxisValueProp].unit
                })
            }
        }
        result = result.sort((a, b) => parseFloat(a.binVal) - parseFloat(b.binVal));
        result = result.map(ele => ({
            x: ele.x,
            y: ele.y,
            value: ele.value,
            yUnit: ele.yUnit
        }));

        const response = {};
        if (_sessionData) {
            response.value = result;
        }
        if (_liveData) {
            response.stream = result;
        }
        return {
            data: response
        }
    }
}

export const liveViewModeWebSocketURL = () => {
    const hostname = window && window.location && window.location.hostname;
    let websocketUrl = "wss://websocketapi.welbiltdigitaldev.com/live-view";
    if (hostname === 'www.welbiltdigitalqa.com' || hostname === 'welbiltdigitalqa.com') {
        //For QA Environment
        websocketUrl = "wss://websocketapi.welbiltdigitalqa.com/live-view";
    } else if (hostname === 'www.welbiltdigitaluat.com' || hostname === 'welbiltdigitaluat.com') {
        //For UAT Environment
        websocketUrl = "wss://websocketapi.welbiltdigitaluat.com/live-view";
    } else if (hostname === 'www.welbiltconnect.com' || hostname === 'welbiltconnect.com') {
        //For Production Environment
        websocketUrl = "wss://websocketapi.welbiltconnect.com/live-view";
    } else if (hostname === 'www.welbiltdigitalintegration.com' || hostname === 'welbiltdigitalintegration.com') {
        //For Integration Environment
        websocketUrl = "wss://websocketapi.welbiltdigitalintegration.com/live-view";
    }
    return websocketUrl;
}

export const liveViewModeDeviceInfoWebSocketURL = () => {
    const hostname = window && window.location && window.location.hostname;
    let websocketUrl = "wss://websocketapi.welbiltdigitaldev.com/device-info";
    if (hostname === 'www.welbiltdigitalqa.com' || hostname === 'welbiltdigitalqa.com') {
        //For QA Environment
        websocketUrl = "wss://websocketapi.welbiltdigitalqa.com/device-info";
    } else if (hostname === 'www.welbiltdigitaluat.com' || hostname === 'welbiltdigitaluat.com') {
        //For UAT Environment
        websocketUrl = "wss://websocketapi.welbiltdigitaluat.com/device-info";
    } else if (hostname === 'www.welbiltconnect.com' || hostname === 'welbiltconnect.com') {
        //For Production Environment
        websocketUrl = "wss://websocketapi.welbiltconnect.com/device-info";
    } else if (hostname === 'www.welbiltdigitalintegration.com' || hostname === 'welbiltdigitalintegration.com') {
        //For Integration Environment
        websocketUrl = "wss://websocketapi.welbiltdigitalintegration.com/device-info";
    }
    return websocketUrl;
}

export const encriptData = async (input) => {
    let encryptedValue = '';
    try {
        if (!input) {
            throw "Not a valid input";
        }
        const secretKey = get32CharKey(CRYPTO_SECRET_KEY);
        const cipher = createCipheriv(algorithm, secretKey, iv);
        const encrypted = Buffer.concat([cipher.update(input), cipher.final()]);
        const hash = {
            iv: iv.toString('hex'),
            content: encrypted.toString('hex')
        };
        const objJsonStr = JSON.stringify(hash);
        encryptedValue = Buffer.from(objJsonStr).toString("base64");
        return encryptedValue;
    } catch (error) {
        console.log('Error in encription', error);
        return encryptedValue;
    }
};

export const decriptData = (base64String) => new Promise(async (resolve, reject) => {
    let decryptedValue = '';
    try {
        const secretKey = get32CharKey(CRYPTO_SECRET_KEY);
        let buff = Buffer.from(base64String, 'base64');
        let text = buff.toString('ascii');
        const hash = JSON.parse(text);
        const decipher = createDecipheriv(algorithm, secretKey, Buffer.from(hash.iv, 'hex'));
        const decrpyted = Buffer.concat([decipher.update(Buffer.from(hash.content, 'hex')), decipher.final()]);
        decryptedValue = decrpyted.toString();
        resolve(decryptedValue);
    } catch (error) {
        console.log('Error in decription', error);
        resolve(decryptedValue);
    }
});