import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import ErrorMoal from '../../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';

class FrymasterConf extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            energyType: "true",
            oilType: "true",
            demoMode: "true",
            error_modal: {
                open: false,
                message: ""
            }
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.closeModel = this.closeModel.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        try {
            let jsonSettingsFile = localStorage.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                energyType: ObjSettingsFile.general.fryer_configuration.energy_type === 1 ? "true" : "false",
                oilType: ObjSettingsFile.general.fryer_configuration.oil_type === 1 ? "true" : "false",
                demoMode: ObjSettingsFile.general.fryer_configuration.demo_mode === 1 ? "true" : "false"
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true,
        });
        let { energyType, oilType, demoMode } = this.state;
        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.general.fryer_configuration) {
            ObjSettingsFile.general.fryer_configuration = {};
        }
        ObjSettingsFile.general.fryer_configuration.energy_type = energyType === "true" ? 1 : 0;
        ObjSettingsFile.general.fryer_configuration.oil_type = oilType === "true" ? 1 : 0;
        ObjSettingsFile.general.fryer_configuration.demo_mode = demoMode === "true" ? 1 : 0;

        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterGeneralSettings`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSettingsClick = (pathname) => {
        this.props.history.push({
            pathname,
            isEdit: false,
            type: "Settings",
            previouspath: "/fryMasterSettings"
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterGeneralSettings`);
    }

    onSaveWhileAction = (key, value) => {
        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.general.fryer_configuration) {
            ObjSettingsFile.general.fryer_configuration = {};
        }
        ObjSettingsFile.general.fryer_configuration[key] = value;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryData", strSettingsFile);
    }


    onToggleChange = (e, stateKey, localStateKey, localeStateValue) => {
        this.setState({
            [stateKey]: e.toString()
        }, () => {
            this.onSaveWhileAction(localStateKey, localeStateValue)
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");
        const { energyType, oilType, demoMode, error_modal } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Configuration Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        {/* FryMaster Configuration Page Content */}
                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>
                            {/* Frymaster Configuration Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1433"/>
                                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li><FormattedMessage id="KC0507"/></li>
                                                        <li class="smallFormFields">
                                                            <div class="btn-group-toggle" data-toggle="buttons"> <span class={energyType === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1434"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'energyType', 'energy_type', e ? 1 : 0)}
                                                                    checked={energyType === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                />
                                                                <span class={energyType === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1435"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1436"/></li>
                                                        <li class="smallFormFields">
                                                            <div class="btn-group-toggle" data-toggle="buttons"><span class={oilType === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1437"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'oilType', 'oil_type', e ? 1 : 0)}
                                                                    checked={oilType === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={oilType === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1438"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1439"/></li>
                                                        <li class="arrowBtn"><button type="button" onClick={() => this.props.history.push('/frymasterFryerFreshOilType')} class="btn nextButton">&nbsp;</button></li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1440"/></li>
                                                        <li class="arrowBtn"><button type="button" onClick={() => this.props.history.push('/frymasterFryerDisposeOilType')} class="btn nextButton">&nbsp;</button></li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1441"/></li>
                                                        <li>
                                                            <div class="btn-group-toggle" data-toggle="buttons"><span class={demoMode === "false" ? "toggleOFF" : "toggleOFF disabled"}>OFF</span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'demoMode', 'demo_mode', e ? 1 : 0)}
                                                                    checked={demoMode === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={demoMode === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC2002"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm">
                                                    <ul class="borderNone">
                                                        <li class="width190px"><FormattedMessage id="KC1442"/></li>
                                                        <li class="arrowBtn"><button type="button" onClick={() => this.props.history.push('/frymasterFryerPotCoverPrompt')} class="btn nextButton">&nbsp;</button></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Configuration Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterConf)
