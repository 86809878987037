import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserProfile ,smartGroupAPI} from '../../../service/api';
import http from '../../../service/httpService';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { Link } from 'react-router-dom';
import ConfirmModal from '../../modal/confirm-model';
import { TableHeader } from '../../controls/tableHeader';
import authService from '../../../service/authService';
import _ from 'lodash';
import Tooltip from 'react-tooltip-lite';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class PurposesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            loaderIconVisible: false,
            responseData: [],
            excludedProductList: [],
            oldProdID: "",
            oldProdName: "",
            curr_Prod_ID: "",
            curr_Prod_Name: "",
            add_mode: false,
            update_mode: false,
            isMultiDelete: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            allSelect: false,
            isDelete: false,
            isEdit: false,
            purposeID:"",
            access_modal: {
                open: false,
                message: ""
            },
            selectedPurposesIDs:[],
            sortColumn: { path: "", order: "", sort_color: "#63778a" },
            isIndeterminateSelect: false,
        };
    }

    componentDidMount = () => {
        $('body').removeClass("modal-backdrop show");
        document.body.style.backgroundColor = "#F2F2F2";
        this.getPurposesDetails();
        this.getUserInfo();
    }

    getPurposesDetails = () => {
        this.setState({ loaderIconVisible: true });        
        let url = smartGroupAPI.purposeList + localStorage.getItem("customerId");
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }, data: {}
        }).then(async response => {
            if (response && response.data) {

                let arr = [];
				let isDelete = false;
                for (let i = 0; i < response.data.length; i++) {                                   
                    isDelete = await this.checkDeleteAccess(response.data[i]);
					arr.push({...response.data[i],isAuthorized: isDelete})
                };

                this.setState({
                    excludedProductList: arr,
                    loaderIconVisible: false,
                    isEdit: false,
                    isDelete: false,
                    allSelect: false,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }


    onSelectRole = async(e, item) => {
        this.setState({
            oldProdID: item.product_id,
            oldProdName: item.product_name,
            curr_Prod_ID: item.product_id,
            curr_Prod_Name: item.product_name,
            add_mode: false,
            update_mode: true,
        })

        if (this.state.excludedProductList && this.state.excludedProductList.length > 0) {
            const rolesList = [...this.state.excludedProductList];
            const index = rolesList.indexOf(item);
            rolesList[index] = { ...rolesList[index] };
            rolesList[index].checked =rolesList[index].isAuthorized ? e.target.checked : false;
            let checked = rolesList.filter(c => c.checked === true);
            let standardCount = rolesList.filter(c => c.purposeType == 'Standard'); 

            function getCheckBoxstate(ListCount,SelectedCount) {
                if (SelectedCount == ListCount) {
                return { allSelect: true, isIndeterminateSelect: false };
                } else if (SelectedCount == 0 && ListCount != 0) {
                return { allSelect: false, isIndeterminateSelect: false };
                } else if (ListCount >= SelectedCount) {
                return { allSelect: true, isIndeterminateSelect: true };
                }
            }


            this.setState({
                excludedProductList: rolesList,
                isDelete : checked.length > 0,
                isEdit: checked.length === 1 ? true : false,
                ...getCheckBoxstate(parseInt(rolesList.length) - parseInt(standardCount.length) ,checked.length)
            })    
        }        
    }

    handleChange = async(e) => {
        var rolesList = [...this.state.excludedProductList];
        let allSelect = e.target.checked;
        rolesList.forEach(r => {
            if (r.purposeType === "Corporate" || r.purposeType === "Standard"){
                r.checked = false;
                allSelect = false;
            } else {
                r.checked = r.isAuthorized ? e.target.checked : false;
                allSelect = r.isAuthorized ? e.target.checked : false;
            }            
        });  
        let checked = rolesList.filter((i)=> i.checked === true);      
        this.setState({
            excludedProductList: rolesList,
            allSelect,
            isDelete:checked.length > 0 ,
            isEdit: false,
            isMultiDelete: true,
            isIndeterminateSelect:false,
        })
    };

    onEditClick = (e, item) => {
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(i => {
            if (i.checked === true || i === true) {
                this.setState({
                    add_mode: false,
                    update_mode: true,
                    curr_Prod_ID: i.name,
                    curr_Prod_Name: i.description,
                    purposeID:i.id,
                })
            }

        });
    }

    onAddClick = (e, item) => {
        this.setState({
            oldProdID: "",
            oldProdName: "",
            add_mode: true,
            update_mode: false,
            curr_Prod_ID: "",
            curr_Prod_Name: "",
            isEdit: false,
            isDelete: false,
            allSelect: false,
        })
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(r => {
            r.checked = false;
        });
    }

    findAddtOrUpdateProduct = () => {
        this.setState({ loaderIconVisible: true })
        if (this.state.add_mode) {
            this.addPurposeDetails();
            this.setState({ curr_Prod_ID: "", curr_Prod_Name: "" })
        } else if (this.state.update_mode) {
            this.updatePurposesDetails();
        }
    }

    addPurposeDetails = (e, item) => {
        const { formatMessage } = this.props.intl;

        let reqData = {
            "name": this.state.curr_Prod_ID,
            "description": this.state.curr_Prod_Name,    
            "customerId": localStorage.getItem("customerId"),
            "userId": localStorage.getItem("user"), 
        };
        let url = smartGroupAPI.purposeList;
        http.post(url, reqData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                this.setState({
                    purposesDesc: "",
                    purposesName: "",
                    loaderIconVisible: false,
                    allSelect: false,
                    isDelete: false,
                    isEdit: false,
                    success_modal: {
                        open: true,
                        message: formatMessage({id:"KC1652"})
                    },
                    allSelect: false, 
                    isIndeterminateSelect: false
                });
                WoopraEvents(`${Constants.ADD_PURPOSE}`);
                this.getPurposesDetails();
            }).catch(err => {
                this.setState({
                    purposesDesc: "",
                    purposesName: "",
                    loaderIconVisible: false,
                    allSelect: false,
                    isDelete: false,
                    isEdit: false,
                    error_modal: {
                        open: true,
                        message: err.response && err.response.data && err.response.data.message? err.response.data.message: "KC1148",
                    },
                });
            });

    }
    
    updatePurposesDetails = (e, item) => {
        const { formatMessage } = this.props.intl;

        let reqData = {
            "name": this.state.curr_Prod_ID,
            "description": this.state.curr_Prod_Name,
            "customerId": localStorage.getItem("customerId"),
            "userId": localStorage.getItem("user"),
            "purposeId":this.state.purposeID,
        };
        let url = smartGroupAPI.deletepurposes;
        http.put(url, reqData,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            let { data } = response;
            this.setState({
                curr_Prod_ID: "",
                curr_Prod_Name: "",
                loaderIconVisible: false,
                success_modal: {
                    open: true,
                    message: formatMessage({id:"KC1653"})
                },
                allSelect: false, 
                isIndeterminateSelect: false
            });
            WoopraEvents(`${Constants.PURPOSE_UPDATED}`);
            this.getPurposesDetails();
            
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response && err.response.data && err.response.data.message? err.response.data.message: "KC1148",
                },
            });
        });
    }

    deletePurposesDetails = () => {
        const { formatMessage } = this.props.intl;

        this.setState({ loaderIconVisible: true });
        let purposeIds=[];
        let reqData;
        var rolesList = [...this.state.excludedProductList];
        rolesList.forEach(i => {
            if (i.checked === true) { 
                purposeIds.push(i.id);
            }
        });

        reqData = {
            "purposeId": purposeIds,
        }

        let url = smartGroupAPI.deletepurposes;
        http.delete(url,{
            headers: {
                'Content-Type': 'application/json',                
            },
            data: reqData,
        }).then(response => {
            let { data } = response;
            if (data && data.has2Delete){
                    this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,                    
                    selectedPurposesIDs:purposeIds,
                    access_modal: {
                        open: true,
                        message:  formatMessage({id:"KC1654"})
                    },
                    allSelect: false, 
                    isIndeterminateSelect: false
                });
            } else {
                this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: this.state.isMultiDelete ? formatMessage({id:"KC1656"}) : formatMessage({id:"KC1655"})
                    },
                    allSelect: false, 
                    isIndeterminateSelect: false
                });
                WoopraEvents(`${Constants.PURPOSE_DELETED}`);
                this.getPurposesDetails();
            }
            
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response,
                },
            });
        });
    }

    deletePurposesForcely = () => {
        const { formatMessage } = this.props.intl;

        this.setState({ loaderIconVisible: true });
        let reqData;
        reqData = {
            "purposeId": this.state.selectedPurposesIDs,
            "has2Delete": true
        }

        let url = smartGroupAPI.deletepurposes;
        http.delete(url,{
            headers: {
                'Content-Type': 'application/json',                
            },
            data: reqData,
        }).then(response => {
            let { data } = response;
            this.setState({
                curr_Prod_ID: "",
                curr_Prod_Name: "",
                loaderIconVisible: false,
                success_modal: {
                    open: true,
                    message: this.state.isMultiDelete ? formatMessage({id:"KC1656"}) : formatMessage({id:"KC1655"})
                },
                allSelect: false, 
                isIndeterminateSelect: false
            });
            WoopraEvents(`${Constants.PURPOSE_DELETED}`);
            this.getPurposesDetails();
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response,
                },
            });
        });
    }

    prodId_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            curr_Prod_ID: rName,
        })
    }

    prodName_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            curr_Prod_Name: rName,
        })
    }

    onSuccessCloseModal = (e) => {
        this.setState({
            success_modal: {
                open: false
            }
        })
        this.getPurposesDetails();
    }

    closeModel = (err) => {
        this.setState({
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            }
        });
    }

    accessNowModel= (e) => {
        this.deletePurposesForcely();
		this.setState({
			access_modal:{
				open:false				
			  }
        })        
	}

	accessLaterModel= () =>  {        
		this.setState({
			access_modal:{
				open:false				
			  }
		})
	}

    onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

    getUserInfo = () => {
		this.setState({ loaderIconVisible: true });
		let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
		if (!loginInfo.rolename) {
			http.get(UserProfile.getUserProfileDetails,
				{headers: {
					'customer_id': localStorage.getItem("customerId"),
					'email': loginInfo.username,
					'Content-Type': 'application/json'
				},data:{},
			}).then(response => {
				loginInfo.rolename = response.data.role_name;
				localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
			}).catch(err => {
			});
		}
	}


    checkDeleteAccess = async (mediaValue) => {
		const userListEmail = JSON.parse(localStorage.getItem('childUsersEmail'));
		const { username } = JSON.parse(localStorage.getItem('loginInfo'));
		const roleName = localStorage.getItem('roleName') || '';

		let isDelete = false;
        if(mediaValue.userId && mediaValue.userId.toString().match('@')){
            isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(mediaValue.userId) || username === mediaValue.userId ? true : false;
        }else{
            isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
        }
        if (isDelete === false){
            return isDelete;
        }
		return isDelete;
	}

    // Woopra Events
    trackEvent = (e, val) => {
        WoopraEvents(`${Constants.ORG_MANAGEMENT}-${val}`);
    }
    // Woopra Events
    trackTitle = (e) => {
        WoopraEvents(`${Constants.ORG_MANAGEMENT}-${e.trackTitle}`);
    }


    render() {
        const { formatMessage } = this.props.intl;

        let { excludedProductList, loaderIconVisible,isIndeterminateSelect,sortColumn, error_modal, success_modal, isEdit, 
        isDelete, allSelect,access_modal,update_mode } = this.state;
        let userProfileAuthFeature = authService.getFeatureByFeatureName("Organization Management");

        let orderId = 1;

        let columns = [
            { path: "", label: "#" },
            { path: "name", label: formatMessage({id:"KC0545"}) },
            { path: "purposeType", label: formatMessage({id:"KC0102"}) },
            { path: "description", label: formatMessage({id:"KC0195"}) },
            { path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
        ];

        if (excludedProductList){
            excludedProductList = _.orderBy(excludedProductList, [sortColumn.path], [sortColumn.order]);
        }

        excludedProductList && excludedProductList.length >0 && excludedProductList.forEach(item => {
            if (item.order_id){
                item.order_id = orderId++
            }
        });

       
        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>

                <div id="page-content-wrapper">
                    <div className="stickyTableHeader">
                        <div className="menuUpdateWrapper">
                            <div className="orgHeader">
                                <ul>
                                    <li>
                                        <Link to="/organizationManagement" title={formatMessage({id:"KC0525"})} onClick={(e) => {e.trackTitle="All";this.trackTitle(e)}} ><FormattedMessage id='KC0525' /></Link>
                                        <Link to="/smartgroupslist" title={formatMessage({id:"KC0520"})} onClick={(e) => {e.trackTitle="Smart Groups";this.trackTitle(e)}}><FormattedMessage id='KC0520' /></Link>
                                        <Link to="/purposesList" title={formatMessage({id:"KC0544"})} class="active"><FormattedMessage id='KC0544' /></Link>
                                    </li>

                                    <li>
                                        <button type="button" onClick={(e) => this.onEditClick(e)} data-toggle="modal" data-target="#excludeProductModal" class={userProfileAuthFeature.is_editable === 1 && isEdit ? "btn userEdit" : "btn userEdit disabled"} >&nbsp;</button>
                                        <button type="button" class={userProfileAuthFeature.is_editable === 1 && isDelete ? "btn delete" : "btn delete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal" >&nbsp;</button>
                                        <button onClick={(e) => this.onAddClick(e)} onChange={(e) => this.onAddClick(e)} type="button" className="btn add" data-toggle="modal" data-target="#excludeProductModal" >&nbsp;</button>
                                    </li>
                                </ul>
                            </div>

                            <form className="orgPurposeTableForm">
                                <div className="orgPurposeTable">
                                    <TableHeader
                                        isIndeterminate={isIndeterminateSelect}
                                        selectAllChange={this.handleChange}
                                        sortColumn={sortColumn}
                                        onSort={this.onSort}
                                        columns={columns}
                                        isSelectAll={allSelect} />
                                    {excludedProductList && excludedProductList.length>0 && excludedProductList.map((item, i) =>
                                        <ul className="tBody">
                                            <li>{i+1}</li>
                                            <li>{item.name}</li>                                            
                                            <li>{item.purposeType}</li>
                                            <li>{item.description}</li>
                                            <li>
                                                {!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1748' })} direction="left" className="targetdisabled">
                                                    <div className={"customCheckbox customCheckboxWOlabel disabled"}>
                                                        <input id={i} name={i} type="checkbox"
                                                            checked={item.checked}
                                                            disabled
                                                            onChange={(e) => this.onSelectRole(e, item)} />
                                                        <label htmlFor={i}></label>
                                                    </div>
                                                </Tooltip> : <div className={item.purposeType === "Corporate" || item.purposeType === "Standard" ? "customCheckbox customCheckboxWOlabel disabled" : "customCheckbox customCheckboxWOlabel"}>
                                                    <input id={i} name={i} type="checkbox"
                                                        checked={item.checked}
                                                        onChange={(e) => this.onSelectRole(e, item)} />
                                                    <label htmlFor={i}></label>
                                                </div>}
                                               
                                            </li>
                                        </ul>
                                    )}
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />                
                <UserManagementHeader headerName="Organization Management" headerNameStringID="KC0519" activeClass="orgznManagementNav" />
                <ConfirmModal open={access_modal.open} stringID={access_modal.message} onAccessNowModel={(e) =>this.accessNowModel(e)} onAccessLaterModel={(e) =>this.accessLaterModel(e)}/>
                {/* <!-- Exclude Product Confirmation Modal -->  */}
                <div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="excludeProductModal" tabindex="-1" role="dialog" aria-labelledby="excludeProductModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationYesNo">
                                    <h5><FormattedMessage id='KC0559'/></h5>
                                    <div className="modalFormWrapper">
                                        <div className="modalForm">
                                            <ul>
                                                <li>
                                                    {update_mode?<label><FormattedMessage id='KC0545'/></label>:""}
                                                    <div className="input-group">
                                                        <input onChange={(e) => this.prodId_Input_Value(e)} value={this.state.curr_Prod_ID} type="text" className="form-control"  placeholder={formatMessage({id:'KC0545'})} />
                                                    </div>
                                                </li>
                                                <li>
                                                    {update_mode?<label><FormattedMessage id='KC0560'/></label>:""}
                                                    <div className="input-group">
                                                        <input onChange={(e) => this.prodName_Input_Value(e)} value={this.state.curr_Prod_Name} type="text" className="form-control" placeholder={formatMessage({id:'KC0560'})} />
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary noBtn" data-dismiss="modal" type="button" onClick={(e) => this.trackEvent(e,`${Constants.PURPOSE_CANCELED}`)}><FormattedMessage id='KC0021'/></button>
                                <button onClick={(e) => this.findAddtOrUpdateProduct(e)} className={this.state.curr_Prod_ID?"btn btn-secondary yesBtn":"btn btn-secondary yesBtn disabled"} id="timeZoneAssign" data-dismiss="modal" type="button">{update_mode?formatMessage({id:'KC0561'}):formatMessage({id:'KC0563'})}</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Exclude Product -->
                <!-- //Clear List Confirmation -->
                <!-- Clear List Confirmation Modal --> */}
                <div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id='KC1657'/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button" onClick={(e) => this.trackEvent(e,`${Constants.PURPOSE_CANCELED}`)}><FormattedMessage id='KC0021'/></button>
                                <button onClick={(e) => this.deletePurposesDetails(e)} className="btn btn-secondary deleteYesBtn" id="deleteYesBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0558'/> </button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Clear List Confirmation -->
	            <!-- Clear List Confirmation Modal --> */}
                <div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="deleteConfirmationOkModal" tabindex="-1" role="dialog" aria-labelledby="confirmationOkModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationDiv">
                                    <FormattedMessage id='KC1659'/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary confirmationOKBtn" onclick="location.href='cremproductsslectn.html'" type="button"><FormattedMessage id='KC0777'/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
        )
    }
};

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({     
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(PurposesList));