import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReportManagement,smartGroupAPI } from '../../../service/api';
import http from '../../../service/httpService';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ConfirmModal from '../../modal/confirm-model';
import appconstants from '../../../utils/appConstants';
import { updateSelectedFilterList,resetSelectedFilterList,} from '../../../actions/listView/locations/filterListAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { TableHeader } from '../../controls/tableHeader';
import authService from '../../../service/authService';
import Tooltip from 'react-tooltip-lite';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

class EditSmartGrp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            loaderIconVisible: false,
            responseData: [],
            purposesList: [],
            oldProdID: "",
            oldProdName: "",
            purposesDesc: "",
            purposesName: "",
            smartGroupName : "",
            add_mode: false,
            update_mode: false,
            isMultiDelete: false,
            isAdd: false,
            isEdit: false,
            isDelete: false,            
            allSelect: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            selectedPurposes:[],
            smartGrpId: props.location.smartGrp_id,
            editSmartGrpResponse: props.location.editSmartGrpRes,
            purposeID:"",
            oldSmartGroupName : "",
            oldSelectedPurposes:[],
            sumartGroupID:"",
            access_modal: {
                open: false,
                message: ""
            },
            selectedPurposesIDs:[],
            selectedPurposesList: [],
            notAllowedEdit: false,
            sortColumn: { path: "", order: "", sort_color: "#63778a" },
            isIndeterminateSelect: false,
        };
    }    

    componentWillMount() {		
		if (this.props.location && this.props.location.state) {
            let values = this.props.location.state;

            let afterEditSG = JSON.parse(localStorage.getItem("afterEditSG"));
            let beforeEditSG = JSON.parse(localStorage.getItem("beforeEditSG"));
            this.setState({
                smartGroupName:afterEditSG.smarGrpName,
                selectedPurposes:afterEditSG.purposes,
                oldSmartGroupName:beforeEditSG.smarGrpName,
                oldSelectedPurposes:beforeEditSG.purposes,
            });
		}
	}

    componentWillUnmount = async () =>{
        if (window.location.pathname !== "/editsmartGrpTopology") {
            await this.removeLocalStorage();
        }
    }

    componentDidMount = async () => {
        $('body').removeClass("modal-backdrop show");
        document.body.style.backgroundColor = "#F2F2F2";
        this.getData();
        
    }

    checkEditDelet = (rolesList) =>{
        const { rolename = '', username = '' } = JSON.parse(localStorage.getItem('loginInfo'));

        let custValue = 0, notOurList = 0, standardValue =0;
        rolesList.forEach(r => {
            if ((r.purposeType.toUpperCase() === "CUSTOM") && r.checked && r.isAuthorized) {
                custValue = custValue + 1;
            }else if((r.purposeType.toUpperCase() === "CUSTOM") && r.checked && r.userId !== username){
                notOurList = notOurList + 1;
            }else if((r.purposeType.toUpperCase() === "CORPORATE"|| r.purposeType.toUpperCase() === "STANDARD") && r.checked && r.isAuthorized){
                standardValue = standardValue + 1;
            }
        });

        if(custValue > 0){
            this.setState({
                isEdit: true,
                isDelete: notOurList === 0 && standardValue === 0 ? true : false,
                notAllowedEdit: notOurList === 0 && standardValue  === 0 ? false : true
            })
        }else{
            this.setState({
                isEdit: false,
                isDelete: false
            })
        }
    }

    getData () {
        this.setState({
            loaderIconVisible: true,
            allSelect: false,
            isDelete: false,
            isEdit: false,
        });
        Promise.all([this.getPurposeDetails(),this.getSmartGroupDetails()])
        .then(async response => {
            let purposeValue=response[0] || [];
            let smartGrpResponse = response[1] || [];
            let selPurposeIds=[]

            let savedPuposeDet = smartGrpResponse[0].purpose;
            _.forEach(savedPuposeDet, a1 => {
                selPurposeIds.push(a1.purposeId)
            });

            let beforeEditSG={};
            beforeEditSG.smarGrpName = smartGrpResponse[0].smarGrpName;
            beforeEditSG.purposes = selPurposeIds;                
            localStorage.setItem("beforeEditSG",JSON.stringify(beforeEditSG));

            let afterEditSG = JSON.parse(localStorage.getItem("afterEditSG"));
            beforeEditSG = JSON.parse(localStorage.getItem("beforeEditSG"));
            if (afterEditSG === null){
                afterEditSG = beforeEditSG;
            }

            let purposeValueList = [...purposeValue];
            _.forEach(purposeValueList, a1 => {
                const ifExist = afterEditSG && afterEditSG.purposes && afterEditSG.purposes.length ? afterEditSG.purposes.includes(a1.id) : selPurposeIds.includes(a1.id);
                a1.checked = ifExist ? true : false
               
            });

            for (let i = 0; i < purposeValueList.length; i++) {                                   
                purposeValueList[i].isAuthorized = purposeValueList[i].purposeType.toLowerCase() === 'standard' ? true : await this.checkDeleteAccess(purposeValueList[i]);
            };

            let checked =purposeValueList.filter(c => c.checked === true);

            this.setState({
                purposesList: purposeValueList,
                loaderIconVisible: false,
                ...this.getCheckBoxstate(purposeValueList.length,checked.length),
                editSmartGrpResponse: smartGrpResponse,
                sumartGroupID:smartGrpResponse[0].smartGrpId,
                smartGroupName:afterEditSG.smarGrpName,
                selectedPurposes:afterEditSG.purposes,
                oldSmartGroupName:beforeEditSG.smarGrpName,
                oldSelectedPurposes:beforeEditSG.purposes,
            });
            this.checkEditDelet(checked)
            const ifExist = false;
            let existingFilter;
            if (localStorage.getItem("smartGroupFilter") !== null) {
                existingFilter=JSON.parse(localStorage.getItem("smartGroupFilter"))[9].dataSource;
                existingFilter.forEach(i => {
                    if (i.smarGrpName === smartGrpResponse[0].smarGrpName) {
                        ifExist=true;
                    }
                });
            }
    
            if (smartGrpResponse.length>0 && ifExist === false){
                let editedSmartGroupDetails = smartGrpResponse[0];
                editedSmartGroupDetails.field= "smartgroup";
                editedSmartGroupDetails.isChecked = true;
                editedSmartGroupDetails.groupsize = 9;
                editedSmartGroupDetails.value=smartGrpResponse[0].smarGrpName;
                editedSmartGroupDetails.id=smartGrpResponse[0].smartGrpId;
                
                this.props.updateSelectedFilterList(editedSmartGroupDetails, appconstants.smartGroupFilter)
            }

            
           
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });

    }

    getSmartGroupDetails =()=> {
        return new Promise((resolve, reject) => {
            let queryString = `customerId=${localStorage.getItem("customerId")}`
            if (localStorage.getItem("sgID")){
                queryString = queryString + `&sgid=${localStorage.getItem("sgID")}`
            }
            let url = `${smartGroupAPI.createSmartGroup}?${queryString}`;        
            http.get(url, {
                headers: {
                    'Content-Type': 'application/json',                
                }, data: {}
            }).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err)
            });
        });
        
    }

    getPurposeDetails = () => {
        return new Promise((resolve, reject) => {
            let url = smartGroupAPI.purposeList + localStorage.getItem("customerId");
            http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                }, data: {}
            }).then(response => {
                resolve(response.data);
            }).catch(err => {
                reject(err)
            });
        });
       
        
    }
   
    onAddClick = (e, item) => {
        this.setState({
            purposesName: "",
            purposesDesc:"",
            oldProdName: "",
            add_mode: true,
            update_mode: false,
        })
        WoopraEvents(`${Constants.ADD_PURPOSE}`);
    }

    onEditClick = (e, item) => {
        var rolesList = [...this.state.purposesList];
        rolesList.forEach(i => {
            if (i.checked === true || i === true) {
                this.setState({
                    add_mode: false,
                    update_mode: true,
                    purposesName: i.name,
                    purposesDesc: i.description,
                    purposeID:i.id,
                })
            }

        });
        WoopraEvents(`${Constants.EDIT_PURPOSE}`);
    }

    findAddtOrUpdateProduct = () => {
        this.setState({ loaderIconVisible: true })
        if (this.state.add_mode) {
            this.addPurposeDetails();
            this.setState({ purposesDesc: "", purposesName: "" })
        } else if (this.state.update_mode) {
            this.updateProductDetails();
        }
    }

    updateSelectedPurposes = async (val) =>{
        var rolesList =val;
        let checkedItems = [];
        rolesList.forEach(i => {
            if( i.checked === true ) {
                checkedItems.push( i.id);
            }
        });

        let afterEditSG={};        
        afterEditSG.smarGrpName = this.state.smartGroupName;
        afterEditSG.purposes = checkedItems; 
        localStorage.setItem('afterEditSG', JSON.stringify(afterEditSG));
        
    }

    onEditMultipleClick = async (e, item) => {
        var rolesList = [...this.state.purposesList];
        let { purposeID } = this.state;
        let checkedItems = [];
        
        await this.updateSelectedPurposes(rolesList);
        rolesList = rolesList.filter(c => c.purposeType === "Custom");
        rolesList.forEach(i => {
            if( i.checked === true && i.isAuthorized ) {
                checkedItems.push( i);
            }
                     if ( i.checked === true || i === true ) {
                        this.setState({
                            add_mode: false,
                            update_mode: true,
                        })
                    }

        });
        
        if( checkedItems && checkedItems.length > 0) {
            // default selected 0 item 
            this.setState({
                purposesName : checkedItems[0].name,
                purposesDesc : checkedItems[0].description,
                purposeID    : checkedItems[0].id,
                selectedPurposesList: checkedItems,
            });
        }
        WoopraEvents(`${Constants.EDIT_PURPOSE}`);
    }


    addPurposeDetails = (e, item) => {
        const { formatMessage } = this.props.intl;

        let reqData = {
            "name": this.state.purposesName,
            "customerId": localStorage.getItem("customerId"),
            "userId": localStorage.getItem("user"),            
            "description": this.state.purposesDesc,            
        };
        let url = smartGroupAPI.purposeList;
        http.post(url, reqData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                // let { data } = response;
                // let { message } = data;
                this.setState({
                    purposesDesc: "",
                    purposesName: "",
                    loaderIconVisible: false,
                    allSelect: false,
                    isDelete: false,
                    isEdit: false,
                    purposeID: null,
                    success_modal: {
                        open: true,
                        message: formatMessage({id:"KC1652"})
                    },
                    allSelect: false, isIndeterminateSelect: false
                });
                WoopraEvents(`${Constants.PURPOSE_ADDED}`);
            }).catch(err => {
                this.setState({
                    purposesDesc: "",
                    purposesName: "",
                    loaderIconVisible: false,
                    allSelect: false,
                    isDelete: false,
                    isEdit: false,
                    error_modal: {
                        open: true,
                        message: err.response && err.response.data && err.response.data.message? err.response.data.message: "KC1148",
                    },
                    allSelect: false, isIndeterminateSelect: false
                });
            });

    }

    updateProductDetails = (e, item) => {
        const { formatMessage } = this.props.intl;

        let reqData = {
            "name": this.state.purposesName,
            "description": this.state.purposesDesc,
            "customerId": localStorage.getItem("customerId"),
            "userId": localStorage.getItem("user"),
            "purposeId":this.state.purposeID,
        };
        let url = smartGroupAPI.deletepurposes;
        http.put(url, reqData,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            let { data } = response;
            let { httpCode, responseCode, message } = data;
            this.setState({
                curr_Prod_ID: "",
                curr_Prod_Name: "",
                loaderIconVisible: false,
                purposesName: null,
                purposesDesc: null,
                purposeID: null,
                success_modal: {
                    open: true,
                    message: formatMessage({id:"KC1653"})
                },
                allSelect: false, isIndeterminateSelect: false
            });  
            WoopraEvents(`${Constants.PURPOSE_UPDATED}`);              
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response && err.response.data && err.response.data.message? err.response.data.message: "KC1148",
                },
            });
        });
    }


    deleteSelectedProduct = (e, item) => {
        const { formatMessage } = this.props.intl;

        this.setState({ loaderIconVisible: true, allSelect: false, isIndeterminateSelect: false })
        let reqData = [];
        let val;
        var rolesList = [...this.state.purposesList];
        rolesList.forEach(r => {
            if (r.checked) {
                val = {
                    "product_id": r.product_id,
                    "product_name": r.product_name,
                }
                reqData.push(val);
            }
        });

        let url = smartGroupAPI.purposeList;
        http.delete(url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    "brandid": localStorage.getItem("selectedbrandID"),
                    "cid": localStorage.getItem("customerId"),
                    "userid": localStorage.getItem("user"),
                },
                data: reqData,
            }
        )
            .then(response => {
                let { data } = response;
                let { httpCode, responseCode, message } = data;
                if (httpCode === "HTTP_200") {
                    this.setState({
                        purposesDesc: "",
                        purposesName: "",
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: formatMessage({id:"KC0780"}),
                        },
                    });
                }
                WoopraEvents(`${Constants.PURPOSE_DELETED}`);
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: err.response,
                    },
                });
            });

    }

    deletePurposesDetails = () => {
        const { formatMessage } = this.props.intl;

        this.setState({ loaderIconVisible: true });
        let purposeIds=[];
        let reqData;
        var rolesList = [...this.state.purposesList];
        rolesList.forEach(i => {
            if (i.checked === true && i.isAuthorized) { 
                purposeIds.push(i.id);
            }
        });

        reqData = {
            "purposeId": purposeIds,
        }

        let url = smartGroupAPI.deletepurposes;
        http.delete(url,{
            headers: {
                'Content-Type': 'application/json',                
            },
            data: reqData,
        }).then(response => {
            let { data } = response;
            let { httpCode } = data;  
            if (data && data.has2Delete){
                    this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,                    
                    selectedPurposesIDs:purposeIds,
                    access_modal: {
                        open: true,
                        message:formatMessage({id:"KC1654"})
                    },
                    allSelect: false, isIndeterminateSelect: false
                });
            } else {
                this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: this.state.isMultiDelete ? formatMessage({id:"KC1656"}) : formatMessage({id:"KC1655"})
                    },
                    allSelect: false, isIndeterminateSelect: false
                });
                //this.getPurposesDetails();
            }
            
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response,
                },
            });
        });
    }

    deletePurposesForcely = () => {
        const { formatMessage } = this.props.intl;

        this.setState({ loaderIconVisible: true });
        let reqData;
        reqData = {
            "purposeId": this.state.selectedPurposesIDs,
            "has2Delete": true
        }

        let url = smartGroupAPI.deletepurposes;
        http.delete(url,{
            headers: {
                'Content-Type': 'application/json',                
            },
            data: reqData,
        }).then(response => {
            let { data } = response;
            let { httpCode } = data;           
            this.setState({
                curr_Prod_ID: "",
                curr_Prod_Name: "",
                loaderIconVisible: false,
                success_modal: {
                    open: true,
                    message: this.state.isMultiDelete ? formatMessage({id:"KC1656"}) : formatMessage({id:"KC1655"})
                },
                allSelect: false, isIndeterminateSelect: false
            });
            //this.getPurposesDetails();
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response,
                },
            });
        });
    }


    purposesDesc_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            purposesDesc: rName,
        })
    }

    purposesName_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            purposesName: rName,
        })
    }

    sgName_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            smartGroupName: rName,
        })
    }

    onSuccessCloseModal = async (e) => {
        this.setState({
            success_modal: {
                open: false
            }
        })
        this.getData();
    }

    closeModel = (err) => {
        this.setState({
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            }
        });
    }

    handleChange = (e) => {
        var rolesList = [...this.state.purposesList];
        rolesList.forEach(r => {
            r.checked = r.isAuthorized ? e.target.checked : false;
        });
        this.setState({
            purposesList: rolesList,
            allSelect: e.target.checked,
            isIndeterminateSelect: false,
            selectedPurposes:[],
        })
        this.checkEditDelet(rolesList.filter((i)=>i.checked === true));
    };

    onSelectedList =( e, item) => {
            this.setState({
                purposesName : item.name,
                purposesDesc : item.description,
                purposeID    : item.id,
            });
    }

    getCheckBoxstate(ListCount,SelectedCount) {
        if (SelectedCount == ListCount) {
        return { allSelect: true, isIndeterminateSelect: false };
        } else if (SelectedCount == 0 && ListCount != 0) {
        return { allSelect: false, isIndeterminateSelect: false };
        } else if (ListCount >= SelectedCount) {
        return { allSelect: true, isIndeterminateSelect: true };
        }
    }

    onSelectRole = (e, item) => {
        if (this.state.purposesList && this.state.purposesList.length > 0) {
            const rolesList = [...this.state.purposesList];
            const index = rolesList.indexOf(item);
            rolesList[index] = { ...rolesList[index] };
            rolesList[index].checked = rolesList[index].isAuthorized ? e.target.checked : false;

            let checked = rolesList.filter(c => c.checked === true);

            this.setState({
                purposesList: rolesList,
                selectedPurposes:[],
                isDelete: checked.length > 0 ? true : false,
                isEdit: checked.length === 1,
                ...this.getCheckBoxstate(rolesList.length,checked.length)
            })

            this.checkEditDelet(rolesList.filter((i)=> i.checked === true));
        }
    }

    accessNowModel= (e) => {
        this.deletePurposesForcely();
		this.setState({
			access_modal:{
				open:false				
			  }
        })        
	}

	accessLaterModel= () =>  {        
		this.setState({
			access_modal:{
				open:false				
			  }
		})
    }
    
    showNext = (selCheckboxDet) => {	
        let afterEditSG={};
        afterEditSG.smarGrpName = this.state.smartGroupName;
        afterEditSG.purposes = selCheckboxDet; 

        localStorage.setItem('afterEditSG', JSON.stringify(afterEditSG));
		this.props.history.push({
            pathname: '/editsmartGrpTopology',
		})
    }

    removeLocalStorage = async () => {
        localStorage.removeItem("smartGroupFilter");
        const arr=[];
        this.props.resetSelectedFilterList(arr);
    }
    
    onBackButton = async () => {
        await this.removeLocalStorage();
		this.props.history.push({
            pathname: '/smartgroupslist',
        })
        
    }

    onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

    checkDeleteAccess = async (mediaValue) => {
		const userListEmail = JSON.parse(localStorage.getItem('childUsersEmail'));
		const { username } = JSON.parse(localStorage.getItem('loginInfo'));
		const roleName = localStorage.getItem('roleName') || '';

		let isDelete = false;
        if(mediaValue.userId && mediaValue.userId.toString().match('@')){
            isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(mediaValue.userId) || username === mediaValue.userId ? true : false;
        }else{
            isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
        }
        if (isDelete === false){
            return isDelete;
        }
		return isDelete;
	}

    // Woopra Events
	trackEvent = (e) => {
		WoopraEvents(`${e}`)
	}

    render() {
        const { formatMessage } = this.props.intl;

        let { notAllowedEdit, purposesList,isIndeterminateSelect,sortColumn,selectedPurposes,access_modal,smartGroupName,update_mode,purposeID,selectedPurposesList,
             loaderIconVisible, error_modal, success_modal, data, allSelect, isEdit, isDelete } = this.state;
        let isNextEnabled;
        let userProfileAuthFeature = authService.getFeatureByFeatureName("Organization Management");

        let orderId = 1;

        let columns = [
            { path: "", label: "#" },
            { path: "name", label: formatMessage({id:"KC0545"}) },
			{ path: "purposeType", label: formatMessage({id:"KC0102" })},
			{ path: "description", label: formatMessage({id:"KC0195"}) },
            { path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
        ];

        purposesList = _.orderBy(purposesList, [sortColumn.path], [sortColumn.order]);

        purposesList.forEach(item => {
            item.order_id = orderId++
        });
        
        let selCheckboxDet = []; 

        purposesList && purposesList.forEach(r => {
            if (r.checked) {
                selCheckboxDet.push(r.id)
            }
        });  

        if (smartGroupName && selCheckboxDet.length>0){
            isNextEnabled = true;
        } else {
            isNextEnabled = false;
        }

        let allowMultipleEdit = false;

        if( isEdit === true ) {                
            let selCustomCheckbox = purposesList.filter(c => c.purposeType === "Custom");      
            if( selCustomCheckbox.length > 1 ) {
                allowMultipleEdit = true; 
            } else {
                allowMultipleEdit = false;
            }
        }
        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>

                <div id="page-content-wrapper">
                    <div class="userManagementWrapper">
                        <div class="addUserHeader">
                            <ul>
                                <li>                                    
                                    <button onClick={() => { this.onBackButton() }} title={formatMessage({id:"KC0989"})} class="btn backIcon">&nbsp;</button>
                                </li>
                                <li>
                                    <FormattedMessage id='KC0521'/>
                                </li>
                                <li>

                                <button onClick={() => { this.showNext(selCheckboxDet) }} class={isNextEnabled?"btn activeState" : "btn disabled"} title={formatMessage({id:"KC0543"})}><FormattedMessage id="KC0543" /></button>
                                </li>
                            </ul>
                        </div>

                        <div class="chooseUserFormWrapper">

                            <div class="chooseUserForm">
                                <ul>
                                    <li>
                                        <div class="smartGroup">
                                            <input type="text" class="form-control"  onChange={(e) => this.sgName_Input_Value(e)} value={this.state.smartGroupName} placeholder={formatMessage({id:'KC0571'})}/>
                                        </div>
                                    </li>

                                </ul>
                            </div>

                            <div class="orgHeader">
                                <ul>
                                    <li>
                                        <a href="#" title={formatMessage({id:"KC1756"})} class="active"><FormattedMessage id='KC0544'/></a>
                                    </li>
                                    {/* <li>
                                        <button onClick={(e) => this.onEditClick(e)} type="button" data-toggle="modal" data-target="#addCustomPurposeModal" class={isEdit?"btn userEdit":"btn userEdit disabled"}>&nbsp;</button>
                                        { allowMultipleEdit && <button onClick={(e) => this.onEditMultipleClick(e)} type="button" data-toggle="modal" data-target="#editPurposeListModal" class={isEdit?"btn userEdit":"btn userEdit disabled"}>&nbsp;</button>}
                                        <button type="button" class= {isDelete?"btn delete":"btn delete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal">&nbsp;</button>
                                        <button onClick={(e) => this.onAddClick(e)} type="button" data-toggle="modal" data-target="#addCustomPurposeModal" class="btn add">&nbsp;</button>                                        
                                    </li> */}

                                    <li>                                        
                                    {  allowMultipleEdit && <button className={userProfileAuthFeature.is_editable === 1 && isEdit ?"btn userEdit":"btn userEdit disabled"} onClick={(e) => this.onEditMultipleClick(e)} type="button" data-toggle="modal" data-target="#editPurposeListModal">&nbsp;</button>}
                                        { !allowMultipleEdit && <button className={userProfileAuthFeature.is_editable === 1 && isEdit ?"btn userEdit":"btn userEdit disabled"} onClick={(e) => this.onEditClick(e)} type="button" data-toggle="modal" data-target="#addCustomPurposeModal">&nbsp;</button>}
                                        <button type="button" className= {userProfileAuthFeature.is_editable === 1 && isDelete ?"btn delete":"btn delete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal">&nbsp;</button>
                                        <button onClick={(e) => this.onAddClick(e)} type="button" data-toggle="modal" data-target="#addCustomPurposeModal" className="btn add">&nbsp;</button>                                        
                                    </li>
                                </ul>
                            </div>
                            <form class="orgPurposeTableForm">
                                <div class="orgPurposeTable">
                                    <TableHeader
                                        isIndeterminate={isIndeterminateSelect}
                                        selectAllChange={this.handleChange}
                                        sortColumn={sortColumn}
                                        onSort={this.onSort}
                                        columns={columns}
                                        isSelectAll={allSelect} />                   
                                    {purposesList && purposesList.map((item, i) =>
                                        <ul class="tBody">
                                            <li>{i+1}</li>
                                            <li>{item.name}</li>                                            
                                            <li>{item.purposeType}</li>
                                            <li>{item.description}</li>
                                            <li>                       
                                            {!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1748' })} direction="left" className="targetdisabled">
                                                <div className={"customCheckbox customCheckboxWOlabel disabled"}>
                                                    <input id={i} name={i} 
                                                        type="checkbox"
														checked={item.checked}
                                                        disabled
														onChange={(e) => this.onSelectRole(e, item)} />
													<label htmlFor={i}></label>
												</div>
                                                        </Tooltip> : <div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
                                                    <input id={i} name={i} 
                                                        type="checkbox"
														checked={item.checked}
														onChange={(e) => this.onSelectRole(e, item)} />
													<label htmlFor={i}></label>
												</div>}                        
                                                
                                            </li>
                                        </ul>
                                        )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {error_modal &&  error_modal.message && <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />}
                {success_modal && success_modal.message && <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />}
                <UserManagementHeader headerName="Organization Management" headerNameStringID="KC0519" activeClass="orgznManagementNav" />
                <ConfirmModal open={access_modal.open} stringID={access_modal.message} onAccessNowModel={(e) =>this.accessNowModel(e)} onAccessLaterModel={(e) =>this.accessLaterModel(e)}/>
                
                {/* <!-- Edit Custom Purpose Modal --> */}
                <div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="addCustomPurposeModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">  
                            <div className="modal-body"> 
                                <div className="confirmationYesNo">  
                                    <h5><FormattedMessage id='KC0559'/></h5>
                                    <div className="modalFormWrapper">
                                        <div className="modalForm">
                                            <ul>
                                                <li>
                                                    <label><FormattedMessage id='KC0545'/></label>
                                                    <input type="text" onChange={(e) => this.purposesName_Input_Value(e)} value={this.state.purposesName} class="form-control" placeholder={formatMessage({id:'KC0545'})}/>   
                                                </li>
                                                <li>
                                                    <label><FormattedMessage id='KC0560'/></label>
                                                    <textarea class="form-control" onChange={(e) => this.purposesDesc_Input_Value(e)} value={this.state.purposesDesc}   rows="2" cols="50" placeholder={formatMessage({id:'KC0560'})}></textarea>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="modal-footer textAlignCenter">
                            <button class="btn btn-secondary noBtn" data-dismiss="modal" type="button" onClick={(e) => this.trackEvent(`${Constants.PURPOSE_CANCELED}`)}><FormattedMessage id='KC0021'/></button>
                            <button class={this.state.purposesName?"btn btn-secondary yesBtn":"btn btn-secondary yesBtn disabled"} onClick={(e) => this.findAddtOrUpdateProduct(e)} data-dismiss="modal" type="button">{update_mode?formatMessage({id:'KC0561'}):formatMessage({id:'KC0563'})}</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- //Add Custom Purpose --> */}
                <div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id='KC1657'/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button" onClick={(e) => this.trackEvent(`${Constants.PURPOSE_CANCELED}`)}><FormattedMessage id='KC0021'/></button>
                                <button onClick={(e) => this.deletePurposesDetails(e)} className="btn btn-secondary deleteYesBtn" id="deleteYesBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0558'/> </button>
                            </div>
                        </div>
                    </div>
                </div>

                                {/* Start Edit Purpose List Modal */}
                                <div class="modal fade confirmationYesNoModal" 
                data-keyboard="true" data-backdrop="static" id="editPurposeListModal"
                data-backdrop-limit="-1" 
                tabindex="-1" role="dialog" aria-labelledby="editPurposeListModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">  
                            <div class="modal-body">
                                <div class="confirmationYesNo">  
                                    <h5><FormattedMessage id='KC0557'/></h5>
                                    <div class="editPurposeList">
                                        {selectedPurposesList && selectedPurposesList.map((item, i) => <>
                                            <div class="customRadioBtn">

                                                <input id={item.name} 
                                                    name = { 'purpose' }
                                                    onChange={ (e)=> this.onSelectedList(e, item)}
                                                    type="radio"
                                                    defaultChecked = { purposeID  === item.id ? item.name: null }
                                                    value={item.name} />

                                                <label htmlFor={item.name}>{item.name}</label>
                                            </div>
                                            
                                        </>)}
                                    </div>
                                </div>                                
                                { notAllowedEdit && <div className="noteText" style={{color: "#0D3968"}} > <FormattedMessage id='KC1658' /></div>}
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary noBtn" data-dismiss="modal" type="button" onClick={(e) => this.trackEvent(`${Constants.PURPOSE_CANCELED}`)}><FormattedMessage id='KC0021'/></button>
                                <button class="btn btn-secondary yesBtn" data-dismiss="modal"  id="editPurposeBtn" type="button" data-toggle="modal" data-target="#addCustomPurposeModal" onClick={(e) => this.trackEvent(`${Constants.PURPOSE_CONFIRM}`)} ><FormattedMessage id='KC0558'/></button>
                                
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Edit Purpose List Modal */}

            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({updateSelectedFilterList,resetSelectedFilterList}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EditSmartGrp));