import http from '../../../service/httpService';
import * as _ from 'lodash';
import {LocationInfo} from '../../../service/api';
import { BRAND_ID } from '../../../utils/appConstants';
export const MACHINE_TYPE_REQUEST = 'MACHINE_TYPE_REQUEST';
export const MACHINE_TYPE_SUCCESS = 'MACHINE_TYPE_SUCCESS';
export const MACHINE_TYPE_FAIL = 'MACHINE_TYPE_FAIL';

export function getMachineType (req = '',filterKey, isCascadeActionRequest) {
    return dispatch => {
        dispatch({
            type: MACHINE_TYPE_REQUEST,
            isCascadeActionRequest : isCascadeActionRequest ? true : false
        }) 
        // let brands = JSON.parse(localStorage.getItem('brandId'));
        // let BRAND_ID =  brands && brands.length>0 ? brands.join(',') : [1,2,4,6].join(','); 
        // Apply model filter Brand , Type and Model
        let cgIds = '';
        let selectedBrandId = BRAND_ID;
        const ffModel = localStorage.getItem(filterKey);
        const filterModel1 = JSON.parse(ffModel) || [];
        if (filterModel1.length) {
            let dataSource = _.find(filterModel1, { field_name: 'brand' }).dataSource
            // Selected brand id to display as brand id header param
            selectedBrandId = _.isArray(dataSource) && dataSource.length ? dataSource.map(el => el.ID).join(',') : selectedBrandId;
            cgIds = _.isArray(dataSource) && dataSource.length ? dataSource.map(el => el.BRAND_NAME).join(',') : '';
        }
        if (cgIds==='')
        {
            cgIds = "";
        }
        http
            .get(`${LocationInfo.filters}`,
                {headers: {
                        'Content-Type':'application/json',                        
                        'Type': 'machinetype',
                        'brandid': selectedBrandId, 
                        'cid': localStorage.getItem("customerId"),
                    },
                    data:{}})
            .then(response => {
                dispatch({
                    type: MACHINE_TYPE_SUCCESS,
                    machineTypeInformation: response.data                    
                })
            })
            .catch(error => {
                dispatch({
                    type: MACHINE_TYPE_FAIL,
                    error
                })
            })
    }
}
