import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { FormattedMessage } from 'react-intl';
import { UserProfile } from '../../service/api';
import http from '../../service/httpService';
import { brandLocations, unitModels } from '../../utils/imgContants';
import { readArchivesFromCompressedResponse } from '../../utils/helpers';
var bg = require('../../images/recipecreation/photoPlaceholder.png')

const formUnits = (units, colorCode) => {
    const unitStyle = { 'background': colorCode, 'borderRadius': '4px' };
    const unitLinkStyle = { 'background': colorCode, 'border': '2px solid #0aaeff' };

    const brandImageStorage = JSON.parse(localStorage.getItem('modelImage')) || [];

    return units.map((unit) => {
        let { BRAND_NAME, MODEL_NAME } = unit;
        let pickUnit = unitModels.find(b => b.name === MODEL_NAME);

        const filteredBrandImage = brandImageStorage.filter((i) => i.BRAND_NAME === BRAND_NAME && i.MODEL_NAME === MODEL_NAME);
        if (filteredBrandImage && filteredBrandImage[0].LOGO_URL) {
            const { LOGO_URL, MODEL_NAME } = filteredBrandImage[0];
            pickUnit = { 'img': LOGO_URL, 'alt': MODEL_NAME, 'title': MODEL_NAME };
        }
        return (
            <li key={unit.UNITID}>
                <div className='orgznSiteTreeLiDiv' style={unitStyle}>
                    <Link to="#" style={unitLinkStyle}>
                        <div>
                            <span className="treeImg">
                                <img src={pickUnit ? pickUnit.img : bg} alt="" />
                            </span>
                            <span className="treeText deviceModel"><b>{unit.UNITID || ''}</b><b><FormattedMessage id="KC0863" /></b></span>
                            <span className="treeCountDispaly" >{units.length || ''} </span>
                        </div>
                    </Link>
                </div>
            </li>
        );
    });
}

const formOrgStructure = (linkedList, isInitial, ID = '', colorCode) => {
    if (!linkedList || !linkedList.length) return null;
    return (
        <ul className={isInitial ? '' : "collapse show"} id={isInitial ? '' : ID}>
            {linkedList.map((linkedLevelValue) => {
                const { level_id, cust_gp_id, parent_group_id, category_id, group_name, imagePath, dynastyAttributes, units } = linkedLevelValue;
                const nextLevel = level_id + 1;
                const id = cust_gp_id + parent_group_id + category_id + level_id;
                const leveClassName = 'level' + nextLevel + 'Expand0' + id;
                const location_type_Select = JSON.parse(dynastyAttributes);
                const picLocation = location_type_Select && location_type_Select.location_type ? brandLocations.find(b => b.name === location_type_Select.location_type) : null;
                const nextLevelData = linkedLevelValue['level' + nextLevel];
                const hasUnits = level_id === 5 && units && units.length > 0;
                const hasNextLevel = nextLevelData && nextLevelData.length > 0;

                return (
                    <li key={cust_gp_id}>
                        <div className="orgznSiteTreeLiDiv">
                            <Link to="#" className={(hasUnits || hasNextLevel) ? 'treeNodeAvailable treeActive' : 'treeNodeAvailableNoLast treeActive'}>
                                <div>
                                    <span className="treeImg">
                                        <img src={imagePath ? imagePath : picLocation ? picLocation.img : bg} alt="" />
                                    </span>
                                    <span className="treeText deviceModel">
                                        <b>{group_name || ''}</b>
                                    </span>
                                </div>
                            </Link>
                            {hasNextLevel ?
                                <React.Fragment>
                                    <button className="btn btn-primary expdCollpse" type="button" data-toggle="collapse" data-target={`#${leveClassName}`} aria-expanded="false" aria-controls={`${leveClassName}`}>&nbsp;</button>
                                    {formOrgStructure(nextLevelData, false, leveClassName, colorCode)}
                                </React.Fragment>
                                : hasUnits ?
                                    <React.Fragment>
                                        <button className="btn btn-primary expdCollpse" type="button" data-toggle="collapse" data-target={`#${leveClassName}`} aria-expanded="false" aria-controls={`${leveClassName}`}>&nbsp;</button>
                                        <ul className="collapse show" id={leveClassName}>
                                            {formUnits(units, colorCode)}
                                        </ul>
                                    </React.Fragment>
                                    : null}
                        </div>
                    </li>
                )
            })}
        </ul>
    )
}

const EventOrg = ({ selectedNotification, getBrandId, workflowDropdown, selectedWorkflowId }) => {
    const [linkList, setLinkList] = useState({});
    const [loader, setLoader] = useState(true);
    const loginUser = JSON.parse(localStorage.getItem("loginInfo"));
    const loggedinUser = loginUser.username;
    const custGroupID = localStorage.getItem('custGroupID');
    const basecountry = localStorage.getItem('basecountry');
    const customerId = localStorage.getItem("customerId");
    const currentWorkflow = workflowDropdown.find((i) => i.id === selectedWorkflowId);
    const colorCode = currentWorkflow?.defaultColorCode || '#aac8e3';

    useEffect(() => {
        const brandId = getBrandId(selectedNotification.brand)
        let headerVal = {
            "Cgids": custGroupID,
            'Content-Type': 'application/json',
            'useremail': loggedinUser,
            'basecountry': basecountry,
            'requestType': 'ANM_ORG_CHART',
            'modelFamilyName': selectedNotification.model || '',
            'brandids': brandId
        }
        http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + customerId}`,
            headers: headerVal, data: {},
        }).then(async response => {
            // if (response.data.fetchFromS3) {
            //     await $.getJSON(response.data.presignedS3URL, async (data) => {
            //         await setLinkList(data);
            //         setLoader(false);
            //     });
            // }
            // else {
            //     await setLinkList(response.data);
            //     setLoader(false);
            // }
            let orgJSON = await readArchivesFromCompressedResponse(response.data);
            await setLinkList(orgJSON);
            setLoader(false);

        }).catch(err => {
            setLoader(false);
        });
    }, [selectedNotification.model]);

    return (
        <React.Fragment>
            <div id="page-loader" className={loader ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <div class="orgznSiteTreeOuter1">
                <div class="orgznSiteTree">
                    {linkList.level1 && formOrgStructure(linkList.level1, true, '', colorCode)}
                </div>
            </div>
        </React.Fragment>);
}

export default EventOrg;