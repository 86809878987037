import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import { injectIntl,FormattedMessage } from 'react-intl';

class FrymasterOQSReadingDisplay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            oilReadingDisplayState: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }


    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = localStorage.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                oilReadingDisplayState: Number(ObjSettingsFile.oqs.reading_display),
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        let { oilReadingDisplayState } = this.state;
        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.oqs) {
            ObjSettingsFile.oqs = {}
        }
        ObjSettingsFile.oqs.reading_display = Number(oilReadingDisplayState);
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterOQS`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/fryMasterOQS`);
    }

    onChangeOilType = (e) => {
        this.setState({
            oilReadingDisplayState: Number(e.target.value)
        });
    }

    render() {
        let brandid = localStorage.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        const { oilReadingDisplayState } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster OQS Reading Display Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster OQS Reading Display Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster OQS Reading Display Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1484"/>
                                </div>


                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="textWRadioBtnList">
                                                    {[{ label: "Text and Number", id: 0 }, { label: "KC0135", id: 1 }, { label: "KC0121", id: 2 }].map((oilReadingDisplay, index) => {
                                                        return (
                                                            <ul key={index}>
                                                                {/* <li><FormattedMessage id={oilReadingDisplay.label}/></li> */}
                                                                <li>
                                                                    <div class="customRadioBtn">
                                                                        <input id={oilReadingDisplay.id} type="radio" name="ReadingDisplay" value={oilReadingDisplay.id} onChange={(e) => this.onChangeOilType(e)} checked={oilReadingDisplay.id === oilReadingDisplayState} />
                                                                        <label for={formatMessage({ id: oilReadingDisplay.id })}>&nbsp;</label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster OQS Reading Display Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterOQSReadingDisplay)
