export const brandLocations = [{ name: 'Casual Stop', img: require('../images/store/FastCasual.svg').default, alt: 'Casual Stop', title: 'Casual Stop' },
{ name: 'Quick Stop', img: require('../images/store/Restaurant.svg').default, alt: 'Quick Stop', title: 'Quick Stop' },
{ name: 'Office', img: require('../images/store/business.svg').default, alt: 'Office', title: 'Office' },
{ name: 'Night Stop', img: require('../images/store/Hotel.svg').default, alt: 'Night Stop', title: 'Night Stop' },
{ name: 'Pit Stop', img: require('../images/store/Convenience.svg').default, alt: 'Pit Stop', title: 'Pit Stop' },
{ name: 'Cafe', img: require('../images/store/cafe.svg').default, alt: 'Cafe', title: 'Cafe' },
{ name: 'Casual  Stop', img: require('../images/store/fast-casual.svg').default, alt: 'Casual Stop', title: 'Casual Stop' },
{ name: 'Hotel', img: require('../images/store/Hotel.svg').default, alt: 'Hotel', title: 'Hotel' },
{ name: 'Store', img: require('../images/store/supermarket.svg').default, alt: 'Store', title: 'Store' },
{ name: 'Hospital', img: require('../images/store/hospital.svg').default, alt: 'hospital', title: 'hospital' },
{ name: 'CVS', img: require('../images/store/Convenience.svg').default, alt: 'CVS', title: 'CVS' },
{ name: 'QSR', img: require('../images/store/QSR.svg').default, alt: 'QSR', title: 'QSR' },
{ name: 'Restaurant', img: require('../images/store/Restaurant.svg').default, alt: 'Restaurant', title: 'Restaurant' },
{ name: 'Government', img: require('../images/store/military.svg').default, alt: 'Government', title: 'Government' },
{ name: 'University', img: require('../images/store/schools.svg').default, alt: 'schools', title: 'schools' },
{ name: 'Other', img: require('../images/store/c-store.svg').default, alt: 'Other', title: 'Other' },
{ name: 'Manitowoc Ice', img: require('../images/unit/MTWice.svg').default, alt: 'Manitowoc Ice', title: 'Manitowoc Ice' },
];


export const brandUnits = [{ name: 'CREM', img: require('../images/unit/CremCarrera.svg').default, alt: 'Crem Carrera', title: 'Crem Carrera' },
{ name: 'MERCO', img: require('../images/unit/mercoVHC.svg').default, alt: "Merco", title: "Merco" },
{ name: 'FRYMASTER', img: require('../images/unit/FRY-FQ-4000-2Vat.svg').default, alt: 'Frymaster FQ4000', title: 'Frymaster FQ4000' },
{ name: 'MERRYCHEF', img: require('../images/unit/MerrychefeikonE2S.svg').default, alt: 'Merrychef Eikon E2S', title: 'Merrychef Eikon E2S' },
{ name: 'MANITOWOC ICE', img: require('../images/unit/MTWice.svg').default, alt: 'Manitowoc Ice', title: 'Manitowoc Ice' },
{ name: 'DELFIELD', img: require('../images/unit/Delfield-SpecLine.svg').default, alt: 'Delfield Specline', title: 'Delfield Specline' },
{ name: 'CONVOTHERM', img: require('../images/unit/ConvothermMini.svg').default, alt: 'Convotherm Mini', title: 'Convotherm Mini' },
{ name: 'ManitowocIce', img: require('../images/unit/MTWice.svg').default, alt: 'Manitowoc Ice', title: 'Manitowoc Ice' },
{ name: 'FRYMASTER-UHCTHD', img: require('../images/unit/Frymaster-UHCTHD-3.png'), alt: 'Frymaster-UHCTHD', title: 'Frymaster-UHCTHD' },
{ name: 'GARLAND', img: require('../images/unit/garlandGrill.svg').default, alt: 'Garland', title: 'Garland' },
{ name: 'MULTIPLEX', img: require('../images/unit/Multiplex-N2Fusion.png'), alt: 'Multiplex', title: 'Multiplex' },
{ name: 'LINCOLN', img: require('../images/unit/lincoln1600.svg').default, alt: 'Lincoln', title: 'Lincoln' },
];

export const customerLogo = [{ name: 'Selecta', img: require('../images/customers/Selecta.svg').default, alt: 'Selecta', title: 'Selecta' },
{ name: 'KFC', img: require('../images/customers/KFC.svg').default, alt: "KFC", title: "KFC" },
{ name: 'FRYMASTER', img: require('../images/unit/FRY-FQ-4000-2Vat.svg').default, alt: 'Frymaster FQ4000', title: 'Frymaster FQ4000' },
{ name: 'MERRYCHEF', img: require('../images/unit/MerrychefeikonE2S.svg').default, alt: 'Merrychef Eikon E2S', title: 'Merrychef Eikon E2S' },
{ name: 'Manitowoc Ice', img: require('../images/unit/MTWice.svg').default, alt: 'Manitowoc Ice', title: 'Manitowoc Ice' },
{ name: 'Delfield', img: require('../images/unit/Delfield-SpecLine.svg').default, alt: 'Delfield Specline', title: 'Delfield Specline' },
{ name: 'Convotherm', img: require('../images/unit/ConvothermMini.svg').default, alt: 'Convotherm Mini', title: 'Convotherm Mini' },
{ name: 'Manitowoc Ice', img: require('../images/unit/MTWice.svg').default, alt: 'Manitowoc Ice', title: 'Manitowoc Ice' },
];

export const unitModels = [{ name: 'CARRARA MEDIUM', img: require('../images/unit/models/Carrara.png'), alt: 'CARRARA MEDIUM', title: 'CARRARA MEDIUM' },
{ name: 'CARRARA SMALL', img: require('../images/unit/models/Carrara.png'), alt: 'CARRARA SMALL', title: 'CARRARA SMALL' },
{ name: 'CARRARA LARGE', img: require('../images/unit/models/Carrara-large.png'), alt: "CARRARA LARGE", title: "CARRARA LARGE" },
{ name: 'GDRINK', img: require('../images/unit/models/G-Drink.png'), alt: 'GDRINK', title: 'GDRINK' },
{ name: 'GSNACK', img: require('../images/unit/models/G-Snack.png'), alt: 'GSNACK', title: 'GSNACK' },
{ name: 'PSL50', img: require('../images/unit/models/PSL50.png'), alt: 'PSL50', title: 'PSL50' },
{ name: 'PSL200', img: require('../images/unit/models/PSL200.png'), alt: 'PSL200', title: 'PSL200' },
{ name: 'BROOKLYN', img: require('../images/unit/models/PSL200.png'), alt: 'BROOKLYN', title: 'BROOKLYN' },
{ name: 'PSL1000', img: require('../images/unit/models/PSL1000R.png'), alt: 'PSL1000', title: 'PSL1000' },
{ name: 'SIMPLICITY', img: require('../images/unit/models/Simplicity.png'), alt: 'SIMPLICITY', title: 'SIMPLICITY' },
{ name: 'UNITY', img: require('../images/unit/models/Unity.png'), alt: 'UNITY', title: 'UNITY' },
{ name: 'CUSTOM LINE', img: require('../images/unit/models/Crem-Custom_Line.png'), alt: 'CUSTOM LINE', title: 'CUSTOM LINE' },
{ name: 'KAFFA LATTE', img: require('../images/unit/models/Crem-Custom_Line.png'), alt: 'KAFFA LATTE', title: 'KAFFA LATTE' },
{ name: 'EVO II', img: require('../images/unit/models/EVOII.png'), alt: 'EVO II', title: 'EVO II' },
{ name: 'DIAMANT PRO', img: require('../images/unit/models/Crem-Diamant-Pro-2GR.png'), alt: 'DIAMANT PRO', title: 'DIAMANT PRO' },
{ name: 'EX3', img: require('../images/unit/models/Crem-EX3.png'), alt: 'EX3', title: 'EX3' },
{ name: 'MANHATTAN', img: require('../images/unit/models/WBT_Crem-Manhattan_2022-07.png'), alt: 'MANHATTAN', title: 'MANHATTAN' },
{ name: 'MHG42SAT2W', img: require('../images/unit/merco/Merco_4x2-portait.png'), alt: 'Merco VHC 4x2 Portrait', title: 'Merco VHC 4x2 Portrait' },
{ name: 'MHC22SNT1T', img: require('../images/unit/merco/Merco_2x2-portait.png'), alt: 'Merco VHC 2x2 Portrait', title: 'Merco VHC 2x2 Portrait' },
{ name: 'MHC22SNL1T', img: require('../images/unit/merco/Merco_2x2-landscape.png'), alt: 'Merco VHC 2x2 Landscape', title: 'Merco VHC 2x2 Landscape' },
{ name: 'MHC24SNT2T', img: require('../images/unit/merco/Merco_2x4.png'), alt: 'Merco VHC 2x4', title: 'Merco VHC 2x4' },
{ name: 'MHC24SNL2T', img: require('../images/unit/merco/Merco_2x4.png'), alt: 'Merco VHC 2x4', title: 'Merco VHC 2x4' },
{ name: 'MHC52SNT1T', img: require('../images/unit/merco/Merco_5x2-portait.png'), alt: 'Merco VHC 5x2 Portrait', title: 'Merco VHC 5x2 Portrait' },
{ name: 'MHC54SNL1T', img: require('../images/unit/merco/Merco_4x5.png'), alt: 'Merco VHC 5x4', title: 'Merco VHC 5x4' },
{ name: 'MHC54SNT1T', img: require('../images/unit/merco/Merco_4x5.png'), alt: 'Merco VHC 5x4', title: 'Merco VHC 5x4' },
{ name: 'MHG22SAT1W', img: require('../images/unit/merco/Merco_2x2-portait.png'), alt: 'Merco VHC 2x2 Portrait', title: 'Merco VHC 2x2 Portrait' },
{ name: 'MHG22SAT2W', img: require('../images/unit/merco/Merco_2x2-portait.png'), alt: 'Merco VHC 2x2 Portrait', title: 'Merco VHC 2x2 Portrait' },
{ name: 'MHG32SAT1W', img: require('../images/unit/merco/Merco_3x2-landscape.png'), alt: 'Merco VHC 3x2 Landscape', title: 'Merco VHC 3x2 Landscape' },
{ name: 'MHG32SAT2W', img: require('../images/unit/merco/Merco_3x2-portait.png'), alt: 'Merco VHC 3x2 Portrait', title: 'Merco VHC 3x2 Portrait' },
{ name: 'MHG42SAT1W', img: require('../images/unit/merco/Merco_4x2-portait.png'), alt: 'Merco VHC 4x2 Portrait', title: 'Merco VHC 4x2 Portrait' },
{ name: 'e1s', img: require('../images/unit/MerrychefeikonE1S.png'), alt: 'e1s', title: 'e1s' },
{ name: 'e2s', img: require('../images/unit/MerrychefeikonE2S.svg').default, alt: 'e2s', title: 'e2s' },
{ name: 'e4s', img: require('../images/unit/MerrychefeikonE4S.svg').default, alt: 'e4s', title: 'e4s' },
{ name: 'conneX 12', img: require('../images/unit/MerrychefConneX12.svg').default, alt: 'conneX 12', title: 'conneX 12' },
{ name: 'conneX 16', img: require('../images/unit/MerrychefConneX12.svg').default, alt: 'conneX 16', title: 'conneX 16' },
{ name: 'conneX 12 SP', img: require('../images/unit/MerrychefConneX12.svg').default, alt: 'conneX 12 SP', title: 'conneX 12 SP' },
{ name: 'conneX 12 HP', img: require('../images/unit/MerrychefConneX12.svg').default, alt: 'conneX 12 HP', title: 'conneX 12 HP' },
{ name: 'conneX 16 HP', img: require('../images/unit/MerrychefConneX12.svg').default, alt: 'conneX 16 HP', title: 'conneX 16 HP' },
{ name: 'conneX 12e', img: require('../images/unit/MerrychefeikonE1S.png'), alt: 'conneX 12e', title: 'conneX 12e    ' },
{ name: 'mini 6.06', img: require('../images/unit/convotherm/ConvothermMini.png'), alt: 'mini 6.06', title: 'mini 6.06' },
{ name: 'mini 6.10', img: require('../images/unit/convotherm/ConvothermMini.png'), alt: 'mini 6.10', title: 'mini 6.10' },
{ name: 'mini 10.10', img: require('../images/unit/ConvothermMini.svg').default, alt: 'mini 10.10', title: 'mini 10.10' },
{ name: 'maxx 6.10', img: require('../images/unit/convotherm/Convotherm_maxx_610.png'), alt: 'maxx 6.10', title: 'maxx 6.10' },
{ name: 'maxx 10.10', img: require('../images/unit/convotherm/Convotherm_maxx_1010.png'), alt: 'maxx 10.10', title: 'maxx 10.10' },
{ name: 'maxx pro 6.10', img: require('../images/unit/convotherm/Convotherm_maxx_610.png'), alt: 'maxx pro 6.10', title: 'maxx pro 6.10' },
{ name: 'maxx pro 10.10', img: require('../images/unit/convotherm/Convotherm_maxx_1010.png'), alt: 'maxx pro 10.10', title: 'maxx pro 10.10' },
{ name: 'maxx pro 6.20', img: require('../images/unit/convotherm/Convotherm_maxx_610.png'), alt: 'maxx pro 6.20', title: 'maxx pro 6.20' },
{ name: 'maxx pro 10.20', img: require('../images/unit/convotherm/C4_easyTouch_1020.png'), alt: 'maxx pro 10.20', title: 'maxx pro 10.20' },
{ name: 'C4 6.10', img: require('../images/unit/convotherm/C4_easyTouch_610.png'), alt: 'C4 6.10', title: 'C4 6.10' },
{ name: 'C4 6.20', img: require('../images/unit/convotherm/C4_easyTouch_620.png'), alt: 'C4 6.20', title: 'C4 6.20' },
{ name: 'C4 10.10', img: require('../images/unit/convotherm/C4_easyTouch_1010.png'), alt: 'C4 10.10', title: 'C4 10.10' },
{ name: 'C4 10.20', img: require('../images/unit/convotherm/C4_easyTouch_1020.png'), alt: 'C4 10.20', title: 'C4 10.20' },
{ name: 'C4 12.20', img: require('../images/unit/convotherm/C4eT_1220_GS.png'), alt: 'C4 12.20', title: 'C4 12.20' },
{ name: 'C4 20.10', img: require('../images/unit/convotherm/Convotherm_C4_2010.png'), alt: 'C4 20.10', title: 'C4 20.10' },
{ name: 'C4 20.20', img: require('../images/unit/convotherm/C4eT_2020_EB.png'), alt: 'C4 20.20', title: 'C4 20.20' },
{ name: 'BIC 650', img: require('../images/unit/multiplex/Multiplex-Blend-in-Cup-Workstation.png'), alt: 'BIC 650', title: 'BIC 650' },
{ name: 'N2', img: require('../images/unit/multiplex/Multiplex-N2Fusion.png'), alt: 'N2', title: 'N2' },
{ name: '1620', img: require('../images/unit/lincoln1600.svg').default, alt: '1620', title: '1620' },
{ name: '2428', img: require('../images/unit/lincoln/Lincoln-2424.png'), alt: '2428', title: '2428' },
{ name: 'IYF300', img: require('../images/unit/manitowocice/Manitowoc-Indigo-NXT-iT0300-D400.png'), alt: 'IYF300', title: 'IYF300'},
{ name: 'IT0420', img: require('../images/unit/manitowocice/Manitowoc-Indigo-NXT-iT0420-D320.png'), alt: 'IT0420', title: 'IT0420'},
{ name: 'IT0450', img: require('../images/unit/manitowocice/Manitowoc-Indigo-NXT-iT0450-D400.png'), alt: 'IT0450', title: 'IT0450'},
{ name: 'IT0620', img: require('../images/unit/manitowocice/Manitowoc-Indigo-NXT-iT0620-D420.png'), alt: 'IT0620', title: 'IT0620'},
{ name: 'IT0750', img: require('../images/unit/manitowocice/Manitowoc-Indigo-NXT-iT0750-D570.png'), alt: 'IT0750', title: 'IT0750'},
{ name: 'IT0900', img: require('../images/unit/manitowocice/Manitowoc-Indigo-NXT-iT0900-D570.png'), alt: 'IT0900', title: 'IT0900'},
{ name: 'IT1200', img: require('../images/unit/manitowocice/Manitowoc-Indigo-NXT-iT1200-D570.png'), alt: 'IT1200', title: 'IT1200'},
{ name: 'IT1500', img: require('../images/unit/manitowocice/Manitowoc-Indigo-NXT-iT1500-D970.png'), alt: 'IT1500', title: 'IT1500'},
{ name: 'ME-12', img: require('../images/unit/garland/Garland-XPress-Grill-1x.png'), alt: 'ME-12', title: 'ME-12'},
{ name: 'ME-24', img: require('../images/unit/garland/Garland-XPress-Grill-2x.png'), alt: 'ME-24', title: 'ME-24'},
{ name: 'ME-36', img: require('../images/unit/garland/Garland-XPress-Grill-3x.png'), alt: 'ME-36', title: 'ME-36'},
{ name: 'MG-12', img: require('../images/unit/garland/Garland-XPress-Grill-1x.png'), alt: 'MG-12', title: 'MG-12'},
{ name: 'MG-24', img: require('../images/unit/garland/Garland-XPress-Grill-2x.png'), alt: 'MG-24', title: 'MG-24'},
{ name: 'MG-36', img: require('../images/unit/garland/Garland-XPress-Grill-3x.png'), alt: 'MG-36', title: 'MG-36'},
{ name: 'XPE12', img: require('../images/unit/garland/Garland-XPress-Grill-1x.png'), alt: 'XPE12', title: 'XPE12'},
{ name: 'XPE24', img: require('../images/unit/garland/Garland-XPress-Grill-2x.png'), alt: 'XPE24', title: 'XPE24'},
{ name: 'XPE36', img: require('../images/unit/garland/Garland-XPress-Grill-3x.png'), alt: 'XPE36', title: 'XPE36'},
{ name: 'XPG12', img: require('../images/unit/garland/Garland-XPress-Grill-1x.png'), alt: 'XPG12', title: 'XPG12'},
{ name: 'XPG24', img: require('../images/unit/garland/Garland-XPress-Grill-2x.png'), alt: 'XPG24', title: 'XPG24'},
{ name: 'XPG36', img: require('../images/unit/garland/Garland-XPress-Grill-3x.png'), alt: 'XPG36', title: 'XPG36'},
{ name: '1FQG120', img: require('../images/unit/Frymaster-FQG120x1.png'), alt: '1FQG120', title: '1FQG120' },
{ name: '2FQG120', img: require('../images/unit/Frymaster-FQG120x2.png'), alt: '2FQG120', title: '2FQG120' },
{ name: '3FQG120', img: require('../images/unit/Frymaster-FQG120x3.png'), alt: '3FQG120', title: '3FQG120' },
{ name: '4FQG120', img: require('../images/unit/Frymaster-FQG120x4.png'), alt: '4FQG120', title: '4FQG120' },
{ name: '5FQG120', img: require('../images/unit/Frymaster-FQG120x5.png'), alt: '5FQG120', title: '5FQG120' },
{ name: '6FQG120', img: require('../images/unit/Frymaster-FQG120x6.png'), alt: '6FQG120', title: '6FQG120' },
{ name: '1FQE120', img: require('../images/unit/Frymaster-FQE120x1.png'), alt: '1FQE120', title: '1FQE120' },
{ name: '2FQE120', img: require('../images/unit/Frymaster-FQE120x2.png'), alt: '2FQE120', title: '2FQE120' },
{ name: '3FQE120', img: require('../images/unit/Frymaster-FQE120x3.png'), alt: '3FQE120', title: '3FQE120' },
{ name: '4FQE120', img: require('../images/unit/Frymaster-FQE120x4.png'), alt: '4FQE120', title: '4FQE120' },
{ name: '5FQE120', img: require('../images/unit/Frymaster-FQE120x5.png'), alt: '5FQE120', title: '5FQE120' },
{ name: '6FQE120', img: require('../images/unit/Frymaster-FQE120x6.png'), alt: '6FQE120', title: '6FQE120' },
{ name: '1FQG80', img: require('../images/unit/Frymaster-FQG80x1.png'), alt: '1FQG80', title: '1FQG80' },
{ name: '1FQE80', img: require('../images/unit/Frymaster-FQE80x1.png'), alt: '1FQE80', title: '1FQE80' },
{ name: '1FQG60', img: require('../images/unit/Frymaster-FQG60x1.png'), alt: '1FQG60', title: '1FQG60' },
{ name: '2FQG60', img: require('../images/unit/Frymaster-FQG60x2.png'), alt: '2FQG60', title: '2FQG60' },
{ name: '3FQG60', img: require('../images/unit/Frymaster-FQG60x3.png'), alt: '3FQG60', title: '3FQG60' },
{ name: '1FQE60', img: require('../images/unit/Frymaster-FQE60x1.png'), alt: '1FQE60', title: '1FQE60' },
{ name: '2FQE60', img: require('../images/unit/Frymaster-FQE60x2.png'), alt: '2FQE60', title: '2FQE60' },
{ name: '3FQE60', img: require('../images/unit/Frymaster-FQE60x3.png'), alt: '3FQE60', title: '3FQE60' },
{ name: '2FQG30', img: require('../images/unit/Frymaster-FQG30x2.png'), alt: '2FQG30', title: '2FQG30' },
{ name: '3FQG30', img: require('../images/unit/Frymaster-FQG30x3.png'), alt: '3FQG30', title: '3FQG30' },
{ name: '4FQG30', img: require('../images/unit/Frymaster-FQG30x4.png'), alt: '4FQG30', title: '4FQG30' },
{ name: '2FQE30', img: require('../images/unit/Frymaster-FQE30x2.png'), alt: '2FQE30', title: '2FQE30' },
{ name: '3FQE30', img: require('../images/unit/Frymaster-FQE30x3.png'), alt: '3FQE30', title: '3FQE30' },
{ name: '4FQE30', img: require('../images/unit/Frymaster-FQE30x4.png'), alt: '4FQE30', title: '4FQE30' },
{ name: 'BIELA114', img: require('../images/unit/Frymaster-FQE60x1.png'), alt: 'BIELA114', title: 'BIELA114' },
{ name: 'BIELA214', img: require('../images/unit/Frymaster-FQE30x2.png'), alt: 'BIELA214', title: 'BIELA214' },
{ name: 'BIGLA230', img: require('../images/unit/Frymaster-FQG30x2.png'), alt: 'BIGLA230', title: 'BIGLA230' },
{ name: 'BIELA314', img: require('../images/unit/Frymaster-FQE30x3.png'), alt: 'BIELA314', title: 'BIELA314' },
{ name: 'BIGLA330', img: require('../images/unit/Frymaster-FQG30x3.png'), alt: 'BIGLA330', title: 'BIGLA330' },
{ name: 'BIELA414', img: require('../images/unit/Frymaster-FQE30x4.png'), alt: 'BIELA414', title: 'BIELA414' },
{ name: 'BIGLA430', img: require('../images/unit/Frymaster-FQG30x4.png'), alt: 'BIGLA430', title: 'BIGLA430' },
{ name: '2IQG30', img: require('../images/unit/Frymaster-FQG30x2.png'), alt: '2IQG30', title: '2IQG30' },
{ name: '2IQE30', img: require('../images/unit/Frymaster-FQE30x2.png'), alt: '2IQE30', title: '2IQE30' },
{ name: '3IQG30', img: require('../images/unit/Frymaster-FQG30x3.png'), alt: '3IQG30', title: '3IQG30' },
{ name: '3IQE30', img: require('../images/unit/Frymaster-FQE30x3.png'), alt: '3IQE30', title: '3IQE30' },
{ name: '4IQG30', img: require('../images/unit/Frymaster-FQG30x4.png'), alt: '4IQG30', title: '4IQG30' },
{ name: '4IQE30', img: require('../images/unit/Frymaster-FQE30x4.png'), alt: '4IQE30', title: '4IQE30' },
{ name: 'UHCTHD3TP', img: require('../images/unit/Frymaster-UHCTHD-3.png'), alt: 'Frymaster UHCTHD', title: 'Frymaster UHCTHD' },
{ name: 'UHCTHD6TP', img: require('../images/unit/Frymaster-UHCTHD-6.png'), alt: 'Frymaster UHCTHD', title: 'Frymaster UHCTHD' },
{ name: 'UHCTHD3TT', img: require('../images/unit/Frymaster-UHCTHD-3.png'), alt: 'Frymaster UHCTHD', title: 'Frymaster UHCTHD' },
{ name: 'UHCTHD6TT', img: require('../images/unit/Frymaster-UHCTHD-6.png'), alt: 'Frymaster UHCTHD', title: 'Frymaster UHCTHD' },
{ name: 'UHCTHD3SP', img: require('../images/unit/Frymaster-UHCTHD-3.png'), alt: 'Frymaster UHCTHD', title: 'Frymaster UHCTHD' },
{ name: 'UHCTHD6SP', img: require('../images/unit/Frymaster-UHCTHD-6.png'), alt: 'Frymaster UHCTHD', title: 'Frymaster UHCTHD' },
{ name: 'UHCTHD3ST', img: require('../images/unit/Frymaster-UHCTHD-3.png'), alt: 'Frymaster UHCTHD', title: 'Frymaster UHCTHD' },
{ name: 'UHCTHD6ST', img: require('../images/unit/Frymaster-UHCTHD-6.png'), alt: 'Frymaster UHCTHD', title: 'Frymaster UHCTHD' },
{ name: '1FQG100', img: require('../images/unit/Frymaster-FQG120x1.png'), alt: '1FQG100', title: '1FQG100' },
{ name: '2FQG100', img: require('../images/unit/Frymaster-FQG120x2.png'), alt: '2FQG100', title: '2FQG100' },
{ name: '3FQG100', img: require('../images/unit/Frymaster-FQG120x3.png'), alt: '3FQG100', title: '3FQG100' }
];

export const brandLogo = [{ name: 'CREM', img: require('../images/logos/Crem_Color.svg').default, alt: 'Crem', title: 'Crem' },
{ name: 'MERCO', img: require('../images/logos/Merco_Color.svg').default, alt: 'Merco', title: 'Merco' },
{ name: 'MERRYCHEF', img: require('../images/logos/Merrychef_Color.svg').default, alt: 'Merrychef', title: 'Merrychef' },
{ name: 'CONVOTHERM', img: require('../images/logos/Convotherm_Color.png'), alt: 'Convotherm', title: 'Convotherm' },
{ name: 'FRYMASTER', img: require('../images/logos/Frymaster_Color.svg').default, alt: 'Frymaster', title: 'Frymaster' },
{ name: 'MANITOWOC ICE', img: require('../images/logos/MAN_blue.svg').default, alt: 'Manitowoc Ice', title: 'Manitowoc Ice' },
{ name: 'DELFIELD', img: require('../images/logos/Delfield_Color.svg').default, alt: 'Delfield', title: 'Delfield' },
{ name: 'MULTIPLEX', img: require('../images/logos/Multiplex_Color.svg').default, alt: 'Multiplex', title: 'Multiplex' },
{ name: 'GARLAND', img: require('../images/logos/Garland_Color.svg').default, alt: 'Garland', title: 'Garland' },
{ name: 'LINCOLN', img: require('../images/logos/Lincoln_Color.svg').default, alt: 'Lincoln', title: 'Lincoln' },
{ name: 'FRYMASTER-UHCTHD', img: require('../images/logos/frymaster-uhcthd-blue.png'), alt: 'Frymaster-UHCTHD', title: 'Frymaster-UHCTHD' },
{ name: 'XPRESSCHEF', img: require('../images/logos/XpressChef_Logo_w_R.jpg'), alt: 'XPRESSCHEF', title: 'XPRESSCHEF' },
{ name: 'ICE-O-MATIC', img: require('../images/logos/Ice-O-Matic-Logo.jpg'), alt: 'ICE-O-MATIC', title: 'ICE-O-MATIC' },
{ name: 'EGRO', img: require('../images/logos/Egro_logo.svg').default, alt: 'EGRO', title: 'EGRO' },
];

export const modelFamily = [{ name: 'CARRARA', img: require('../images/unit/models/Carrara.png'), alt: "CARRARA", title: "CARRARA" },
{ name: 'GDRINK', img: require('../images/unit/models/G-Drink.png'), alt: 'GDRINK', title: 'GDRINK' },
{ name: 'GSNACK', img: require('../images/unit/models/G-Snack.png'), alt: 'GSNACK', title: 'GSNACK' },
{ name: 'BROOKLYN', img: require('../images/unit/models/PSL200.png'), alt: 'BROOKLYN', title: 'BROOKLYN' },
{ name: 'PSL', img: require('../images/unit/models/PSL1000R.png'), alt: 'PSL', title: 'PSL' },
{ name: 'SIMPLICITY', img: require('../images/unit/models/Simplicity.png'), alt: 'SIMPLICITY', title: 'SIMPLICITY' },
{ name: 'UNITY', img: require('../images/unit/models/Unity.png'), alt: 'UNITY', title: 'UNITY' },
{ name: 'EX3', img: require('../images/unit/models/Crem-EX3.png'), alt: 'EX3', title: 'EX3' },
{ name: 'EVO II', img: require('../images/unit/models/EVOII.png'), alt: 'EVO II', title: 'EVO II' },
{ name: 'DIAMANT', img: require('../images/unit/models/Crem-Diamant-Pro-2GR.png'), alt: 'DIAMANT', title: 'DIAMANT' },
{ name: 'CUSTOM LINE', img: require('../images/unit/models/Crem-Custom_Line.png'), alt: 'CUSTOM LINE', title: 'CUSTOM LINE' },
{ name: 'KAFFA LATTE', img: require('../images/unit/models/Crem-Custom_Line.png'), alt: 'KAFFA LATTE', title: 'KAFFA LATTE' },
{ name: 'eikon® e1s', img: require('../images/unit/MerrychefeikonE1S.png'), alt: 'Merrychef e1s', title: 'Merrychef e1s' },
{ name: 'eikon® e2s', img: require('../images/unit/MerrychefeikonE2S.svg').default, alt: 'Merrychef e2s', title: 'Merrychef e2s' },
{ name: 'eikon® e4s', img: require('../images/unit/MerrychefeikonE4S.svg').default, alt: 'Merrychef e4s', title: 'Merrychef e4s' },
{ name: 'conneX 12 SP', img: require('../images/unit/MerrychefConneX12.svg').default, alt: 'conneX 12 SP', title: 'conneX 12 SP' },
{ name: 'conneX 12 HP', img: require('../images/unit/MerrychefConneX12.svg').default, alt: 'conneX 12 HP', title: 'conneX 12 HP' },
{ name: 'conneX 16 HP', img: require('../images/unit/MerrychefConneX12.svg').default, alt: 'conneX 16 HP', title: 'conneX 16 HP' },
{ name: 'conneX 12e', img: require('../images/unit/MerrychefeikonE1S.png'), alt: 'conneX 12e', title: 'conneX 12e    ' },
{ name: 'C4', img: require('../images/unit/convotherm/C4_easyTouch_1010.png'), alt: 'C4', title: 'C4' },
{ name: 'maxx', img: require('../images/unit/convotherm/Convotherm_maxx_610.png'), alt: 'maxx', title: 'maxx' },
{ name: 'mini', img: require('../images/unit/ConvothermMini.svg').default, alt: 'mini', title: 'mini' },
{ name: 'maxx pro', img: require('../images/unit/convotherm/Convotherm_maxx_610.png'), alt: 'maxx pro', title: 'maxx pro' },
{ name: 'FQ100', img: require('../images/unit/Frymaster-FQG120x1.png'), alt: 'FQ100', title: 'FQ100' },
{ name: 'FQ120', img: require('../images/unit/Frymaster-FQE120x6.png'), alt: 'FQ120', title: 'FQ120' },
{ name: 'FQ80', img: require('../images/unit/Frymaster-FQG120x3.png'), alt: 'FQ80', title: 'FQ80' },
{ name: 'FQ60', img: require('../images/unit/Frymaster-FQE60x3.png'), alt: 'FQ60', title: 'FQ60' },
{ name: 'FQ30', img: require('../images/unit/Frymaster-FQE30x2.png'), alt: 'FQ30', title: 'FQ30' },
{ name: 'TLOV', img: require('../images/unit/Frymaster-FQE60x1.png'), alt: 'TLOV', title: 'TLOV' },
{ name: 'UHCTHD', img: require('../images/unit/Frymaster-UHCTHD-6.png'), alt: 'UHCTHD', title: 'UHCTHD' },
{ name: 'ME', img: require('../images/unit/garland/Garland-XPress-Grill-2x.png'), alt: 'ME', title: 'ME' },
{ name: 'MG', img: require('../images/unit/garland/Garland-XPress-Grill-3x.png'), alt: 'MG', title: 'MG' },
{ name: 'XPE', img: require('../images/unit/garland/Garland-XPress-Grill-2x.png'), alt: 'XPE', title: 'XPE' },
{ name: 'XPG', img: require('../images/unit/garland/Garland-XPress-Grill-3x.png'), alt: 'XPG', title: 'XPG' },
{ name: '1620', img: require('../images/unit/lincoln1600.svg').default, alt: '1620', title: '1620' },
{ name: '2428', img: require('../images/unit/lincoln/Lincoln-2424.png'), alt: '2428', title: '2428' },
{ name: 'MHC', img: require('../images/unit/merco/Merco_4x2-portait.png'), alt: 'MHC', title: 'MHC' },
{ name: 'MHG', img: require('../images/unit/merco/Merco_4x2-portait.png'), alt: 'MHG', title: 'MHG' },
{ name: 'BIC', img: require('../images/unit/multiplex/Multiplex-Blend-in-Cup-Workstation.png'), alt: 'BIC', title: 'BIC' },
{ name: 'N2', img: require('../images/unit/multiplex/Multiplex-N2Fusion.png'), alt: 'N2', title: 'N2' },
{ name: 'Intuition', img: require('../images/unit/Frymaster-FQE30x2.png'), alt: 'Intuition', title: 'Intuition' },
{ name: 'Indigo NXT', img: require('../images/unit/manitowocice/Manitowoc-Indigo-NXT-iT0300-D400.png'), alt: 'IYF300', title: 'IYF300'},
];

export const userListImage = [
	{ name: "System Manager", img: require("../images/users/owner.svg").default },
	{ name: "Crem System Admin", img: require("../images/users/owner.svg").default },
	// { name: "Admin", img: require("../images/users/manager.svg").default },
	{ name: "Service Technician", img: require("../images/users/service.svg").default },
	// { name: "Media Manager", img: require("../images/users/leaser.svg").default },
	// { name: "Operator", img: require("../images/users/chef.svg").default },
	{ name: "1st Line User", img: require("../images/users/leaser.svg").default },
	{ name: "2nd Line User", img: require("../images/users/chef.svg").default },
	{ name: "Crem User", img: require("../images/users/manager.svg").default }
]