import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MenuUploadAPI } from '../../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../../service/httpService';
import { setUserDataModel } from '../../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../../modal/success-model';
import ErrorMoal from '../../../modal/error-model';
import UserManagementHeader from '../../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID } from '../../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewTopology from '../../../../components/controls/viewTopology';
import { v1 as uuidv1 } from 'uuid';
import { injectIntl,FormattedMessage } from 'react-intl';
var request = require('request');
var beautify = require("json-beautify");

const HTTP_METHOD ={
	GET:'GET',
	PUT:'PUT',
	POST:'POST',
	DELETE:'DELETE'
}

class AssignLegacyRecipeToTopology extends Component {
    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            linkListValue: {},
            findTreeLevel: "",
            selectValues: {},
            pageLoad: true,
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        $("body").removeClass("loginBg");
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        this.props.history.push({
            pathname: this.props.location.previouspath ? this.props.location.previouspath : "/readyRecipeLibraryList",
            isEdit: false,
            previouspath: "/assignLegacyCookbookToTopology",
        });
    }

    submitAddLibrary = (e, _customIds) => {
        e.preventDefault();
        let kcbrandsitedata = localStorage.getItem("kcbrandsitedata");
        let objbrandsitedata = JSON.parse(kcbrandsitedata);
        let md5 = require('md5');

        if (objbrandsitedata && objbrandsitedata.readyrecipeids) {
            this.setState({
                loaderIconVisible: true
            });
            let brandID = localStorage.getItem("selectedbrandID");
            this.getLibraryInfo(objbrandsitedata.readyrecipeids).then((res) => {
                let {readyRecipeDetails} = res;
                let libraryGuid = uuidv1();
                let jsonObj = JSON.parse(readyRecipeDetails.JSON);
                jsonObj.id = libraryGuid;
                // convert JSON object to beautify String
                var jsonStr = beautify(jsonObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);
                const crc = md5(jsonBuffer);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);

                
                let libraryId = readyRecipeDetails.RECIPE_NAME + '_' + Date.now();
                let imagefilekey = readyRecipeDetails.IMAGE_FILE_NAME.replace(readyRecipeDetails.IMAGE_FORMAT,'').replace('.','') + '_' + Date.now() + '.' + readyRecipeDetails.IMAGE_FORMAT;
                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryGuid": libraryGuid,
                    "libraryName": readyRecipeDetails.RECIPE_NAME,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + parseFloat(readyRecipeDetails.IMAGE_SIZE)).toFixed(2),
                    "libraryS3Url": "",
                    "type": "ReadyRecipe",
                    "createdUser": localStorage.getItem("user"),
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": imagefilekey,
                    "imageFileFormat": readyRecipeDetails.IMAGE_FORMAT,
                    "imageFileSize": readyRecipeDetails.IMAGE_SIZE,
                    "imageS3Url": "",
                    "cgids": _customIds,
                    "json": readyRecipeDetails.JSON,
                    "readyRecipeId": objbrandsitedata.readyrecipeids
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': imagefilekey,
                    'jsonfilekey': libraryId + '.json',
                    'audiofilekey': '',
                    'isthumbnail': true
                }

                http({
                    method: HTTP_METHOD.GET,
                    url: `${MenuUploadAPI.getlibraryUploadpresignedUrl}`,
                    headers: headerpresigned,
			        data: {}
                })
                .then((response) => {
                    let { imagePresignedURL, thumbnailPresignedURL, jsonPresignedURL } = response.data.presignedURLs
                    let imageurl = imagePresignedURL;
                    let thumbnailurl = thumbnailPresignedURL;
                    let jsonurl = jsonPresignedURL;

                    let UploadedImages3url = "";
                    if (imageurl) {
                        UploadedImages3url = imageurl.split('?');
                        UploadedImages3url = UploadedImages3url[0];
                    }
                    let UploadedJSONs3url = "";
                    if (jsonurl) {
                        UploadedJSONs3url = jsonurl.split('?');
                        UploadedJSONs3url = UploadedJSONs3url[0];
                    }
                    request.get({ url: readyRecipeDetails.imageS3URL, encoding: null }, (err, res, imageDataBuffer) => {
                        //let imagesrc = body.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                        request.get({ url: readyRecipeDetails.tmbImageS3URL, encoding: null }, (err, res, thumbnailDataBuffer) => {
                            //let tmbsrc = tmbbody.toString('base64').replace(/^data:image\/[A-Za-z]+;base64,/, "");
                            var xhrthumb = new XMLHttpRequest();
                            xhrthumb.open(HTTP_METHOD.PUT, thumbnailurl);
                            xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
                            xhrthumb.onloadend = (res) => {
                                if (res.target.status === 200) {
                                    var xhrimage = new XMLHttpRequest();
                                    xhrimage.open(HTTP_METHOD.PUT, imageurl);
                                    xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
                                    xhrimage.onloadend = (res) => {
                                        if (res.target.status === 200) {
                                            var xhrjson = new XMLHttpRequest();
                                            xhrjson.open(HTTP_METHOD.PUT, jsonurl);
                                            xhrjson.setRequestHeader('Content-Type', 'multipart/form-data');
                                            xhrjson.onloadend = (res) => {
                                                if (res.target.status === 200) {
                                                    libraryPostData.imageS3Url = UploadedImages3url;
                                                    libraryPostData.libraryS3Url = UploadedJSONs3url;
                                                    http({
                                                        method: HTTP_METHOD.POST,
                                                        url: `${MenuUploadAPI.legacyLibrarylist}/${brandID}/libraries`,
                                                        data: libraryPostData,
                                                        headers: {}
                                                    }).then(res => {
                                                        let { data } = res;
                                                        let { httpCode,  message } = data;
                                                        if (httpCode === "HTTP_200") {
                                                            this.setState({
                                                                loaderIconVisible: false,
                                                                success_modal: {
                                                                    open: true,
                                                                    message: "KC1202"
                                                                },
                                                            });
                                                        }
                                                        else {
                                                            this.setState({
                                                                loaderIconVisible: false,
                                                                success_modal: {
                                                                    open: true,
                                                                    message: "KC1203"
                                                                },
                                                            });
                                                        }
                                                    }).catch(err => {
                                                        console.log(err);
                                                    });
                                                }
                                            };
                                            xhrjson.onerror = (err) => {
                                                console.log(err);
                                            };
                                            xhrjson.send(jsonBuffer);
                                        }
                                    };
                                    xhrimage.onerror = (err) => {
                                        console.log(err);
                                    };
                                    xhrimage.send(imageDataBuffer);
                                }
                            };
                            xhrthumb.onerror = (err) => {
                                console.log(err);
                            };
                            xhrthumb.send(thumbnailDataBuffer);
                        })
                    })
                })
                .catch((err) => {
                    console.log(err);
                });
            })
            .catch((err) => {
                console.log(err);
            });
        }
    }

    getLibraryInfo = (id) => {
        let headers = {
            'Content-Type': "application/json",
            "readyrecipeid":id.toString()
        };
        let brandID = localStorage.getItem("selectedbrandID");
		return new Promise((resolve, reject) => {
			http.get(`${MenuUploadAPI.legacyLibrarylist}/${brandID}/ready-recipes/info`, {
                headers: headers,
                data: {},
            })
			.then((response) => {
				resolve(response.data);
			})
			.catch((err) => {
				reject(err);
			});
		});
	}

    changeLoadingStatus=(value)=>{
        this.setState({loaderIconVisible:value})
    }

    render() {
        let { success_modal, error_modal, } = this.state;
        const { loaderIconVisible } = this.state;
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology 
                    title={`KC1677`} 
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus} 
                    onBackClickHandler={this.onBackClickHandler}
                    modelFamilyIds={localStorage.getItem("selectedFamilyID")} 
                    start_level_id={1}
                    selected_cgid={true} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignLegacyRecipeToTopology));
