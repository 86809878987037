import React, {useEffect} from "react";
import auth from '../../service/authService'

import csvExUnitRedirect from '../../utils/csvExUnitRedirect'

const CsvExpoRedirect = (props) =>{
  const {match:{params}} = props;
  console.log(params.brand+' === '+params.unit)
  useEffect(()=>{
    // check if brand and units are valid by api call
    // localStorage.setItem("csvUnitBrandId", '6');
    // localStorage.setItem("csvUnitID", 'CONVODEV98');
    localStorage.setItem("csvUnitBrandId", params.brand.trim());
    localStorage.setItem("csvUnitID", params.unit.trim());
    // if valid check of logged in
    if(auth.getJwt()){
      csvExUnitRedirect(params.unit.trim(), params.brand.trim(), props.history.push)
      // if logged show unitDashboard
    }else{
      
      // if not logged in show login component 
      return props.history.push('/');
    }
  },[])

  return(
    <>
      <h1>Redirecting..</h1>
    </>
  )
}

export default CsvExpoRedirect