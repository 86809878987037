
import authConstants from './authConstants'
import permissionConfig from './permissionConfig'
import appConstants from './appConstants'

/**
 * Remove Local Sessions
 * 1. Token
 * 2. Login User Info
 * 3. Session Time
 */
export const deleteLocalSession = () => {
    localStorage.removeItem(authConstants.tokenKey);
    localStorage.removeItem(authConstants.loginInfo);
    localStorage.removeItem(authConstants.tokenExpireTime);
    localStorage.removeItem(authConstants.refreshToken);
    localStorage.removeItem(authConstants.refreshTokenErrorStatus);
    localStorage.removeItem(authConstants.rememberMe);
    localStorage.removeItem(authConstants.featuresKey);
    localStorage.removeItem(appConstants.filterKey);
    localStorage.removeItem(appConstants.mediaFilters);
    localStorage.removeItem(appConstants.softwareFilters);
    localStorage.removeItem(appConstants.kcbrandsitedata);
    localStorage.removeItem(appConstants.kcTransferData);
	if(localStorage.getItem('isCookieAccepted')){
        localStorage.clear();
        localStorage.setItem('isCookieAccepted', true);
    }else{
        localStorage.clear();
    }
}

/**
 * Recieve Server Features by Role
 * Map Features with local permission list
 * Return updated features list to store it local storage
 * @param {*} features 
 */
export const applyPermissions = (features) =>
{
    let localFeaturesTemplate  = permissionConfig.features;

    // let localFeaturesTemplate = [
    // {
    //         title : "Global functions",
    //         feature_category : "Global functions",
    //         feature_name : fConsts.DashBoard,
    //         featuteIcon : require('../images/mainmenu/users.svg'),
    //         productedRoutes : [
    //         "/reportManagement",
    //         "/reportManagement/production",
    //         '/dashBoardLocations',
    //         '/dashBoardLocations/specificLocation',
    //         '/dashBoardUnits',
    //         '/locationsView',
    //         '/locationsView/filterBy',
    //         '/locationsView/filterBy/filterByCountry',
    //         '/locationsView/filterBy/filterByRegion',
    //         '/locationsView/filterBy/filterByLocation',
    //         '/locationsView/filterBy/filterByCustomer',
    //         '/locationsView/filterBy/filterByBrand',
    //         '/locationsView/filterBy/filterByModel',
    //         '/unitsView',
    //         '/locationsMap',
    //         '/unitsMap'
    //         ],
    //         isMainMenu: false,
    //         featureAvailable:true
    
    // },
    // {
    //     title :"User Management",
    //     feature_category : "Main Menu Apps",
    //     feature_name : fConsts.UserManagement,
    //     featuteIcon : require('../images/mainmenu/users.svg'),
    //     productedRoutes : ["/userManagementList"],
    //     isMainMenu: true,
    //     featureAvailable:true

    // },
    // {
    //     title:"Unit Registration",
    //     feature_category : "Main Menu Apps",
    //     feature_name : fConsts.UnitRegistration,
    //     featuteIcon : require('../images/mainmenu/UnitRegistrationAssignment.svg'),
    //     productedRoutes : ["/unitRegistration"],
    //     isMainMenu: false,
    //     featureAvailable:true
                  
    // },
    // {
    //     title: `Organization Management`,
    //     feature_category : "Main Menu Apps",
    //     feature_name : fConsts.SiteManagement,
    //     featuteIcon : require('../images/mainmenu/OrganizationMgmt.svg'),
    //     productedRoutes : ["/organizationManagement"],
    //     isMainMenu: true,
    //     featureAvailable:true

    // },
    // {
    //     title:"Subscription",
    //     feature_category : "Main Menu Apps",
    //     feature_name : fConsts.Subscription,
    //     featuteIcon : require('../images/mainmenu/warranty.svg'),
    //     productedRoutes : ["/subscription"],
    //     isMainMenu: true,
    //     featureAvailable: false

    // },
    // {
    //     title:"Media",
    //     feature_category : "Main Menu Apps",
    //     feature_name : fConsts.MediaManagement,
    //     featuteIcon : require('../images/mainmenu/cloud.svg'),
    //     productedRoutes : ["/mediaLibraryList"],
    //     isMainMenu: true,
    //     featureAvailable:true

    // },
    // {
    //     title:"Software",
    //     feature_category : "Main Menu Apps",
    //     feature_name : fConsts.SoftwareManagement,
    //     featuteIcon : require('../images/mainmenu/plugins.svg'),
    //     productedRoutes : ["/softwareAvailableUpdates"],
    //     isMainMenu: true,
    //     featureAvailable:true

    // }];

    let currentFeatures = [];

    features && localFeaturesTemplate.forEach(lf => {
        let roleFeature = features.filter(f => f.feature_category === lf.feature_category && f.feature_name === lf.feature_name);
        if(roleFeature && roleFeature.length>0){
           lf.feature_active = roleFeature[0].feature_active;
           lf.is_view_only = roleFeature[0].is_view_only;
           lf.is_editable = roleFeature[0].is_editable;
           lf.feature_category = roleFeature[0].feature_category;
           currentFeatures.push(lf);
        }
    });

    return currentFeatures.length>0 ? currentFeatures : [];
}

export default {
    deleteLocalSession,
    applyPermissions
}