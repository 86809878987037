import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { UnitInfo } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import http from '../../../service/httpService'
import $ from 'jquery';
import _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { BaseService, ReportManagement } from '../../../service/api';
import { format } from 'date-fns';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import appconstants from '../../../utils/appConstants';
import multiDownload from './multiple-file-download';
import OfflineMediaImg from '../../../images/icons/offlineT.svg';
import Tooltip from 'react-tooltip-lite';
import authService from '../../../service/authService';
import moment from '../../../utils/momentHelper.js';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { ungzip } from 'pako';
import NoRecordsFound from '../../controls/noRecordsFound';

$(window).on('beforeunload', function () {
	if ($(".export-error-report").length > 0) {
		$("#exportReport").removeClass("export-error-report");
		$(".loadingProgress").css("display", "none");
	}
});

class reportAssignUpdateList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			data: [],
			unitsSelected: 0,
			unitsOffline: 0,
			userSelectAll: false,
			isIndeterminateSelect: false,
			userChecked: [],
			userCheckedData: [],
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			total_unit_count: 0,
			nameFromReport: '',
			startDateFromReport: '',
			endDateFromReport: '',
			selectedoptionsFromReport: '',
			reportName: '',
			startDate: '',
			endDate: '',
			reportByUnit: true,
			reportByStore: false,
			reportFormat: "XLSX",
			reportOptions: [],
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			errorResponse: "",
			fixedHeaderHeight: ''
		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.myReports = this.myReports.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
	}

	componentDidMount() {
		document.body.style.backgroundColor = "#F2F2F2";
		try {
			this.requestData();
			if (this.props.location) {
				this.setState({
					startDateFromReport: this.props.location.selectedstartDate,
					endDateFromReport: this.props.location.selectedendDate
				});
			}
			if (localStorage.getItem("brandName") === "FRYMASTER") {
				const { location } = this.props;
				if (location && location.state) {
					this.setState({ ...location.state });
				}
			}
			if (localStorage.getItem("brandName") === "GARLAND") {
				const { location } = this.props;
				if (location && location.state) {
					this.setState({ ...location.state });
				}
			}
		} catch (error) {
			//Error Goes Here
		}
		this.pageWrapperMinH();
	}

	componentWillMount() {
		if (this.props.location && this.props.location.state) {
			this.setState({
				nameFromReport: this.props.location.state.nameFromReport,
				startDateFromReport: this.props.location.state.startDateFromReport,
				endDateFromReport: this.props.location.state.endDateFromReport,
				selectedoptionsFromReport: this.props.location.state.selectedoptionsFromReport
			});
		} if (localStorage.getItem("brandName") === "GARLAND") {
			const { location } = this.props;
			if (location && location.state) {
				this.setState({ ...location.state });
			}
		}
	}

	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	/**
	 * Decompress Base64 encode string into JSON
	 * @param {*} base64 Base64 encoded string
	 * @returns JSON | null
	 */
	decompressData(base64) {
		try {
			const buffer = Buffer.from(base64, 'base64');
			const bufferArr = ungzip(buffer);
			const json = new TextDecoder('utf8').decode(bufferArr);
			return JSON.parse(json);
		} catch(err) {
			console.error(err);
			return null;
		}
	}

	requestData = async () => {
		this.setState({ loaderIconVisible: true });
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.softwareFilters);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
		}

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];

		selectedCountryList.dataSource.map(c => {
			selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			selectedMachineTypes.push(c.ID)
		});

		var d = document.getElementById("spannavNotifiStatus");
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
			d.className = "navNotifiStatus";
		}
		else if (selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
			d.className = "navNotifiStatus";
		}
		else {
			d.className = "";
		}
		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		let apiUrl = `${UnitInfo.unitList}?compress=true`;

		let headerValue = {
			'Content-Type': 'application/json',
			'cgid': cgids,
			'cid': selectedCids.join(','),
			'brandid': localStorage.getItem("selectedbrandID"),
			'model': selectedModels.join(','),
			'machinetype': selectedMachineTypes.join(','),
			'sortby': sortedID,
			'sorttype': sortedDesc,
			'basecountry': BASECOUNTRY_ID
		}

		if (localStorage.getItem("selectedbrandID") === "4" || localStorage.getItem("selectedbrandID") === "3" || localStorage.getItem("selectedbrandID") === "9") {
			headerValue = {
				'Content-Type': 'application/json',
				'cgid': cgids,
				'brandid': localStorage.getItem("selectedbrandID"),
				'cid': localStorage.getItem("customerId"),
				'machinetype': selectedMachineTypes.join(','),
				'basecountry': BASECOUNTRY_ID
			}
		}

		await http.get(apiUrl,
			{
				headers: headerValue,
				data: {}
			}).then(response => {
				if (response.data.compressed) {
					response.data.units = this.decompressData(response.data.units) || [];
				}
				let { units, total_unit_count, } = response.data;
				units = UnitBuilder(units);

				var checkedCopy = [];
				var userSelectAll = this.state.userSelectAll;
				units.forEach(function (e, index) {
					checkedCopy.push(userSelectAll);
				});

				this.setState({
					loaderIconVisible: false,
					data: units,
					userChecked: checkedCopy,
					userSelectAll: userSelectAll,
					total_unit_count: total_unit_count
				});

			}).catch(err => {

			});
	}

	onBackClickHandler = () => {
		if (localStorage.getItem("brandName") === "FRYMASTER") {
			this.props.history.push({
				pathname: `/newReportWithOptions`,
				state: {
					reportName: this.state.reportName,
					startDate: new Date(this.state.startDate),
					endDate: new Date(this.state.endDate),
					unitChecked: this.state.reportByUnit,
					storeChecked: this.state.reportByStore,
					pdfChecked: (this.state.reportFormat === "PDF"),
					excelChecked: (this.state.reportFormat === "XLSX"),
					reportOptions: this.state.reportOptions
				},
			});
		} else if (localStorage.getItem("brandName") === "GARLAND") {
			this.props.history.push({
				pathname: `/garlandReport`,
				state: {
					reportName: this.state.reportName,
					startDate: new Date(this.state.startDate),
					endDate: new Date(this.state.endDate),
					unitChecked: this.state.reportByUnit,
					storeChecked: this.state.reportByStore,
					pdfChecked: (this.state.reportFormat === "PDF"),
					excelChecked: (this.state.reportFormat === "XLSX"),
					reportOptions: this.state.reportOptions
				},
			});
		} else {
			this.props.history.push({
				pathname: `/newReportWithOptions`,
				state: {
					nameFromReport: this.state.nameFromReport,
					startDateFromReport: this.state.startDateFromReport,
					endDateFromReport: this.state.endDateFromReport,
					selectedoptionsFromReport: this.state.selectedoptionsFromReport
				},
			});
		}
	}

	myReports = () => {
		this.setState({
			loaderIconVisible: false,
		});
		this.props.history.push(`/reportList`);
	}

	handleChange = () => {
		var userSelectAll = !this.state.userSelectAll;
		this.setState({ userSelectAll: userSelectAll });
		var checkedCopy = [];
		let units = [...this.state.data];

		units && units.length > 0 && units.map(item => {
			item.checked = item.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" ? userSelectAll : false;
			checkedCopy.push(item.checked);
		});

		this.isSelectAssign(checkedCopy);
		let unitsOfflineCount = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.CONNECT_STATE === "Offline");
		let unitsSelected = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);

		if (userSelectAll) {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				isIndeterminateSelect: false,
				unitsSelected: unitsSelected.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: []
			});
		}
		else {
			this.setState({
				userChecked: checkedCopy,
				data: units,
				isIndeterminateSelect: false,
				unitsSelected: 0,
				unitsOffline: 0,
				userCheckedData: [],
			});
		}
	};



	getCheckBoxstate(ListCount, unselectedCount) {
		if (ListCount === unselectedCount) {
			return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (unselectedCount === 0) {
			return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (ListCount > unselectedCount) {
			return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange = (e, index, rowData) => {
		var checkedCopy = this.state.userChecked;
		checkedCopy[index] = !this.state.userChecked[index];
		if (checkedCopy[index] === false) {
			this.setState({ userSelectAll: false });
		}
		let checkAll = [];

		const units = [...this.state.data];
		units[index] = { ...units[index] };
		units[index].checked = e.target.checked;

		checkAll = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && (c.checked === false || c.checked === undefined));
		let checked = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);
		let unitsOfflineCount = checked.filter(c => c.CONNECT_STATE === "Offline");
		let unitRegistedCount = units.filter(c => c.PROVISIONING_STATUS === "UNIT_REGISTERED").length

		this.setState({
			data: units,
			userChecked: checkedCopy,
			unitsSelected: checked.length,
			unitsOffline: unitsOfflineCount.length,
			userCheckedData: checked,
			...this.getCheckBoxstate(parseInt(units.length) -parseInt(unitRegistedCount) 
			,checkAll.length)
		});

		this.isSelectAssign(this.state.userChecked);
	};

	isSelectAssign = (units) => {
		let checkAllTrue = [];
		checkAllTrue = units.filter(c => c === true);
		if (localStorage.getItem("brandName") === "FRYMASTER") {
			if (checkAllTrue.length > 1 || checkAllTrue.length === 0) {
				$("#btnfinish").removeClass("activeState");
				$("#btnfinish").addClass("disabled");
			}
			else {
				$("#btnfinish").addClass("btn-default-text activeState");
				$("#btnfinish").removeClass("disabled");
			}
		}
		else {
			if (checkAllTrue.length > 0) {
				$("#btnfinish").addClass("btn-default-text activeState");
				$("#btnfinish").removeClass("disabled");
			}
			else {
				$("#btnfinish").removeClass("activeState");
				$("#btnfinish").addClass("disabled");
			}
		}
	}

	onClickFinish = async (e) => {
		if (localStorage.getItem("brandName") === "CREM") {
			this.submitForExportReport();
		}
		else if (localStorage.getItem("brandName") === "FRYMASTER") {
			this.submitForFryerReport();
		}
		else if (localStorage.getItem("brandName") === "GARLAND") {
			this.submitForGarlandReport();
		} else {
			this.onFinishClickHandler();
		}
	}

	// Garland report generation logic //
	submitForGarlandReport = async () => {
		this.setState({ loaderIconVisible: true })
		return new Promise((resolve, reject) => {
			this.setState({ loaderIconVisible: true }, () => {
				let unitId = [];
				let unitData = [];
				const {
					data,
					startDate,
					endDate,
					reportName,
					reportByUnit,
					reportByStore,
					reportFormat,
					reportOptions
				} = this.state;
				[...data].map(item => {
					if (item.checked) {
						unitId.push(item.UNITID);
						const [street, city, zipcode, country] = item.address ? item.address.split(',') : [];
						item.dynastyAttributes = JSON.stringify({street, city, zipcode, country});
						unitData.push(_.pick(item, ['BASE_COUNTRY', 'BRAND_ID', 'CLOUD_UNIT_NAME', 'CUSTOMER_ID', 'PRODUCT_NAME', 'UNITID', 'PRODUCT_NUMBER', 'dynastyAttributes', 'PRODUCT_TYPE', 'BRAND_NAME', 'location_name', 'TimeZone_Standard']));
					}
				});
				const reportOptionObj = {};
				if (reportByUnit) {
					_.each(reportOptions.filter(el => el.isChecked), obj => { reportOptionObj[obj.fieldName] = obj.isChecked });
				}
				const headObject = unitData[0];
				let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
				let loggedinUser = loginUser.username;
				const postdata = {
					startDate, endDate, reportName, reportByUnit, reportByStore, reportFormat,
					timeZone: "abc",
					unitIds: unitId,
					unitData: unitData,
					brandId: headObject.BRAND_ID,
					customerId: +headObject.CUSTOMER_ID,
					brandName: localStorage.getItem("brandName"),
					userEmail: loggedinUser,
					...reportOptionObj
				};
				const heders = {
					headers: {
						'Content-Type': 'application/json',
						basecountry: localStorage.getItem("basecountry")
					}
				};
				return http.post(ReportManagement.exportGarlandReport, postdata, heders).then(async (response) => {
					if (Array.isArray(response.data)) {
						const urls = response.data;
						await this.disbleFinishBtn();
						await multiDownload(urls, { delay: 1000 });
						await this.delay(1000);
						this.setState({
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: "Reports created successfully."
							}
						});
						resolve(response.data);
						WoopraEvents(`${Constants.GENARATE_NEW_REPORT}`);
					} else {
						WoopraEvents(`${Constants.GENARATE_NEW_REPORT}`);
						resolve(response.data);
						this.props.history.push(`/reportList`);
					}
				}).catch(err => {
					WoopraEvents(`${Constants.GENARATE_NEW_REPORT_FAILED}`);
					this.setState({ loaderIconVisible: false });
					reject(err);
				});
			});
		});
	}

	submitForExportReport = async (e) => {
		this.setState({ loaderIconVisible: true, })
		const { data } = this.state;
		let selectedUnitList = [];
		if (data && data.length > 0) {
			data.map(item => {
				if (item.checked) {
					selectedUnitList.push(item.UNITID);
				}
			});
		}
		let fileName = this.state.nameFromReport;
		//if no filename exist send below format as filename
		if (!fileName) {
			fileName = moment.ConvertLocalTimeFromUTCTime(new Date(), "MMDDYYYY_hhmmss");
		}
		let postdata = {
			'startDate': this.props.location.state.startDateFromReport,
			'endDate': this.props.location.state.endDateFromReport,
			"customerId": localStorage.getItem("customerId"),
			"brandId": localStorage.getItem("selectedbrandID"),
			"filename": fileName,
			"selectedoptions": this.props.location.state.selectedoptionsFromReport,
			"units": selectedUnitList,
			"userId": localStorage.getItem("user")
		};
		await http.post(ReportManagement.submitExportReport, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
					basecountry: localStorage.getItem("basecountry"),
				}
			}
		).then(response => {
			if (response && response.data) {
				this.setState({ responseData: response.data, loaderIconVisible: false });

				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: "Report generation is In-progress.",
					},
				});
				WoopraEvents(`${Constants.GENARATE_NEW_REPORT}`);
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
			WoopraEvents(`${Constants.GENARATE_NEW_REPORT_FAILED}`);
		});
	}

	delay = ms => new Promise(resolve => setTimeout(resolve, ms));
	disbleFinishBtn = async () => {
		$("#btnfinish").removeClass("activeState");
		$("#btnfinish").addClass("disabled");
	};

	// Frymaster Report Finish Handler
	submitForFryerReport = () => {
		return new Promise((resolve, reject) => {
			this.setState({ loaderIconVisible: true }, () => {
				let unitId = [];
				let unitData = [];
				const {
					data,
					startDate,
					endDate,
					reportName,
					reportByUnit,
					reportByStore,
					reportFormat,
					reportOptions
				} = this.state;
				[...data].map(item => {
					if (item.checked) {
						unitId.push(item.UNITID);
						const [street, city, zipcode, country] = item.address ? item.address.split(',') : [];
						item.dynastyAttributes = JSON.stringify({street, city, zipcode, country});
						unitData.push(_.pick(item, ['BASE_COUNTRY', 'BRAND_ID', 'CLOUD_UNIT_NAME', 'CUSTOMER_ID', 'PRODUCT_NAME', 'UNITID', 'PRODUCT_NUMBER', 'dynastyAttributes', 'PRODUCT_TYPE', 'BRAND_NAME', 'location_name', 'TimeZone_Standard']));
					}
				});
				const reportOptionObj = {};
				if (reportByUnit) {
					_.each(reportOptions.filter(el => el.isChecked), obj => { reportOptionObj[obj.fieldName] = obj.isChecked });
				}
				const headObject = unitData[0];
				let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
				let loggedinUser = loginUser.username;
				const postdata = {
					startDate, endDate, reportName, reportByUnit, reportByStore, reportFormat,
					timeZone: "abc",
					unitIds: unitId,
					unitData: unitData,
					brandId: headObject.BRAND_ID,
					customerId: +headObject.CUSTOMER_ID,
					brandName: localStorage.getItem("brandName"),
					userEmail: loggedinUser,
					...reportOptionObj
				};
				const heders = {
					headers: {
						'Content-Type': 'application/json',
						basecountry: localStorage.getItem("basecountry")
					}
				};
				return http.post(ReportManagement.exportFryerReport, postdata, heders).then(async (response) => {
					if (Array.isArray(response.data)) {
						const urls = response.data;
						await this.disbleFinishBtn();
						await multiDownload(urls, { delay: 1000 });
						await this.delay(1000);
						this.setState({
							loaderIconVisible: false,
							success_modal: {
								open: true,
								message: "Reports created successfully."
							}
						});
						WoopraEvents(`${Constants.GENARATE_NEW_REPORT}`);
						resolve(response.data);
					} else {
						WoopraEvents(`${Constants.GENARATE_NEW_REPORT}`);
						resolve(response.data);
						this.props.history.push(`/reportList`);
					}
				}).catch(err => {
					this.setState({ loaderIconVisible: false });
					WoopraEvents(`${Constants.GENARATE_NEW_REPORT_FAILED}`);
					reject(err);
				});
			});
		});
	};

	onFinishClickHandler() {
		this.setState({ loaderIconVisible: true })
		const { data } = this.state;
		const unitList = [];
		const unitData = [];
		if (data && data.length > 0) {
			data.map(item => {
				if (item.checked) {
					unitList.push(item.CLOUD_UNIT_NAME);
					unitData.push(
						{
							"CLOUD_UNIT_NAME": item.CLOUD_UNIT_NAME,
							"PRODUCT_NAME": item.PRODUCT_NAME,
							"UNITID": item.UNITID,
							"PRODUCT_NUMBER": item.PRODUCT_NUMBER,
							"dynastyAttributes": item.dynastyAttributes,
						});
				}
			});

		}
		//modelNumber
		let startdateofReport = this.props.location.selectedstartDate;
		startdateofReport = new Date(startdateofReport);
		startdateofReport = format(startdateofReport, 'YYYY-MM-DD');

		let enddateofReport = this.props.location.selectedendDate;
		enddateofReport = new Date(enddateofReport).toDateString();
		enddateofReport = format(enddateofReport, 'YYYY-MM-DD');

		let token = localStorage.getItem('Access-Token');
		let ErrorReportPostData = {
			startDate: startdateofReport,
			endDate: enddateofReport,
			timeZone: "abc",
			unitIds: unitList,
			unitData: unitData
		};

		let url = localStorage.getItem("selectedbrandID") === "11" ? ReportManagement.uhcdeviceDataApi : ReportManagement.deviceDataApi;
		http({
			method: "POST",
			url: `${BaseService.root}${url}`,
			data: ErrorReportPostData,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': token,
			}
		}).then(response => {
			if (response)
				this.setState({
					loaderIconVisible: false,
					errorResponse: response.data.fileUrl
				});
			$("#exportReport").addClass("export-error-report");
			window.location = response.data.fileUrl;
			WoopraEvents(`${Constants.GENARATE_NEW_REPORT}`);
		}).catch(err => {
			WoopraEvents(`${Constants.GENARATE_NEW_REPORT_FAILED}`);
			this.showError(err);
		});
	}

	showError = (err) => {
		try {
			let { response } = err;
			if (response && response.status) {

				let { data } = response;
				let { body } = data;
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: body.message ? body.message : ""
					}
				});
			} else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: err.message
					}

				});
			}
		} catch (e) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.message
				}

			});
		}
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/reportList`);
	}

	pageWrapperMinH = () => {
		let windowHeight = $(window).height();
		let selectedUnitHeader = $(".selectedUnitHeader").height();
		let adminBgHeader = $(".adminBgHeader").height();
		let reportAsgnListH = $(".reportAsgnList .tHead").height();
		let reportAsgnListSrlBarH = ((windowHeight) - (adminBgHeader + selectedUnitHeader + reportAsgnListH)) - 70;
		this.setState({ fixedHeaderHeight: reportAsgnListSrlBarH }, () => ({ reportAsgnListSrlBarH }));
	}

	render() {
		const { loaderIconVisible, data, sortColumn, unitsSelected, unitsOffline, error_modal, success_modal, fixedHeaderHeight } = this.state;
		let userProfileAuthFeature = authService.getFeature("/reportList");
		let orderId = 1;
		let Updatedunits = (parseInt(unitsSelected) - parseInt(unitsOffline));
		let columns = [
			{ path: "", label: "#" },
			{ path: "", label: "Unit", isNbSp: true },
			{ path: "PRODUCT_TYPE", label: "Type" },
			{ path: "PRODUCT_NAME", label: "Model Name" },
			{ path: "UNITID", label: "Serial Number" },
			{ path: "UNIT_NUMBER", label: "Unit Number" },
			{ path: "location_name", label: "Store" },
			{ path: "within_location", label: "Within Location" },
			{ path: "CONNECT_STATE", label: "Status", isNbSp: true },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		let unitList = data;

		unitList.forEach(item => {
			item.order_id = orderId++
		});

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);

		let brandid = localStorage.getItem("selectedbrandID");
		const dynamicHeaderReport = `Reports - ${brandid === 1 ? "MERRYCHEF" : brandid === 3 ? "FRYMASTER" : brandid === 2 ? "MERCO" : brandid === 4 ? "CREM" : brandid === 6 ? "CONVOTHERM" : "Frymaster-UHCTHD"}`;

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div id="exportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">

						<div className="reportWrapper">
							<div className="adminBgHeader">
								<ul>
									<li>
										<Link to="#" onClick={() => this.onBackClickHandler()} title="Back" className="backIcon">&nbsp;</Link>
									</li>
									<li className="txtboldmsg">
										Select Units
				          </li>
									<li>
										<Link id="btnfinish" to="#" title="Finish" className="disabled" onClick={() => this.onClickFinish()}>Finish</Link>
									</li>
								</ul>
							</div>
							<div className="selectedUnitHeader">
							<ul>
								<li>{this.state.unitsSelected} Units selected / {this.state.unitsOffline} Units offline</li>
								<li>
									<Link to="/assignReportUnitToTopology" className="btn assignunitSite">&nbsp;</Link>
									<Link id="btnAssign" to="#" className="assignunitList active" >&nbsp;</Link>
								</li>
							</ul>
							</div>
							<form className="reportAsgnListOuter">
								<div className="reportAsgnList">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isIndeterminate={this.state.isIndeterminateSelect}
										isSelectAll={this.state.userSelectAll} />
								</div>
								<div className="reportAsgnListSrlBar" style={{ height: `${fixedHeaderHeight}px` }}>
									<div className="reportAsgnList">
										{
											unitList.map((item, i) => (
												<ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody"} key={i}>
													<li>{i + 1}</li>
													<li><img src={item.Brand_Logo}  alt="" /></li>
													<li>{item.PRODUCT_TYPE}</li>
													<li>{item.MODEL_NAME}</li>
													<li>{item.UNITID}</li>
													<li>{item.UNIT_NUMBER}</li>
													<li>{item.location_name}</li>
													<li>{item.within_location}</li>
													<li>
														{item.CONNECT_STATE === 'Offline' && <img src={OfflineMediaImg} alt="" />}
													</li>

													<li>{item.PROVISIONING_STATUS !== "CERTIFICATE_RECEIVED" ? <Tooltip content={'Unit not provisioned'} direction="left" className="targetdisabled">
														<div className={"customCheckbox customCheckboxWOlabel disabled"}>
															<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
																checked={item.checked}
																onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
															<label for={item.order_id - 1}></label>
														</div></Tooltip>
														: <div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
															<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
																checked={item.checked}
																onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
															<label for={item.order_id - 1}></label>
														</div>}
													</li>
												</ul>
											))
										}
									</div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={unitList !== undefined ? unitList.length : 0} classname={'norecordsfoundbluetext'} />
								</div>
							</form>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<UserManagementHeader headerName={dynamicHeaderReport} activeClass="reportNav" filterKey={appconstants.softwareFilters} history={this.props.history} />
				</div>
				<div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationOkModal" tabIndex="-1" role="dialog" aria-labelledby=" confirmationOkModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="confirmationContentDiv">
									<h5>Software Update</h5>
									<p>{Updatedunits} / {unitsSelected} units successfully updating. {unitsOffline} units pending (offline)</p>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary confirmationOKBtn" data-dismiss="modal" onClick={() => this.myReports()} type="button">OK</button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};
function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(reportAssignUpdateList);