import { MenuUploadAPI } from '../../service/api';
import http from '../../service/httpService';
import { BASECOUNTRY_ID } from '../../utils/appConstants';

import { SET_MENU_LIST, SET_MENU_LIST_LOADING, MODEL_FAIL} from '../stateActionsData/type';
export const setMenuListData = ( data ) => ({ type: SET_MENU_LIST, payload: data});
const setLoader= ( bol ) => ({ type: SET_MENU_LIST_LOADING, payload: bol});

export const  getMenuList =  (reqData)  => dispatch => {
    // confirm modal 
    dispatch( setMenuListData(null));
    dispatch( setLoader(true));
    let customerId = localStorage.getItem('customerId');
    let brandId = localStorage.getItem('selectedbrandID');

    http.get(MenuUploadAPI.getMenuList,
        {
          headers: {
            'Content-Type': 'application/json',
            'basecountry': BASECOUNTRY_ID,
            "brandId": brandId,
            "customerId": customerId
            }, data: {}
        }
        ).then( response => {
            dispatch( setLoader(false));
            let { data } = response;
                dispatch(setMenuListData(data.getMenuList));
            //sucess modal
        }).catch(error => {
            dispatch( setLoader(false));
            dispatch({
                type: MODEL_FAIL,
            });
        });
}

