import { Link } from "react-router-dom";
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { getAPIUrl, getErrorList, getAPIFlagBaseUrl } from '../../service/servicemanagement/serviceManagement';
import LoadingPopup from '../controls/loadingPopup';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import $ from 'jquery';
import { CUSTOMER_ID } from '../../utils/appConstants';
import http from '../../service/httpService';
import { ServiceManagement, HACCPManagementAPI } from '../../service/api';
import authService from '../../service/authService';
import { TableHeader } from '../controls/tableHeader';
import NoRecordsFound from '../controls/noRecordsFound';
import { isEqualObj } from '../globalSearchBar/selectOption';
import WoopraEvent from '../../woopraConfig/WoopraEvents';
import Constants from "../../woopraConfig/Woopraconstant";
import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from "../controls/goToPagination";

class ServiceMgmtList extends PureComponent {

  constructor(probs) {
    super(probs);
    this.state = {
      data: [],
      loaderIconVisible: false,
      totalPages: null,
      loading: true,
      currentPage: 1,
      pageSize: 25,
      sortColumn: { path: "", order: "", sort_color: "#F2F2F2" },
      errorType: "",
      errorEvent: false,
      IsServerError: false,
      error_modal: {
        open: false,
        message: ""
      },
      success_modal: {
        open: false,
        message: ""
      },
      noRecords: null,
      dataToTrobleShoot: [],
      isSelectAllRoles: false,
      allowToDelete: false,
      thingDetails: [],
      isCriticalSel: true,
      isNonCriticalSel: true,
      isErrorEventSel: false,
      currentErrCount: "",
      cbGroup: { "Warning": true, "Error": true, "eventsError": false },
      eventType1: false,
      searchData: null,
      isIndeterminate: false
    }

    this.onSortedChange = this.onSortedChange.bind(this);
    this.changeErrorList = this.changeErrorList.bind(this);
    this.closeModel = this.closeModel.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#000000";
    this.updateDimensions();
    const { currentPage, sortColumn } = this.state;
    this.loadData(currentPage, sortColumn);

    this.getCloudsDetailsForThink();
    this.countValue();
    this.interval = setInterval(() => this.loadData(this.state.currentPage, sortColumn), 30000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  componentDidUpdate(prevState) {
    let { searchData } = prevState;
    let curSearchData = this.state && this.state.searchData;
    const prevLocale = prevState.locale;
		const { locale } = this.props;
    if ((curSearchData && (isEqualObj(searchData, curSearchData) === false)) || (prevLocale !== locale)) {
      const { currentPage, sortColumn } = this.state;
      this.setState({loaderIconVisible: true})
      this.loadData(currentPage, sortColumn);
    }
  }


  
  updateDimensions() {
    var windowHeight = $(window).height();
    var pageTabHeader = $(".pageTabHeader").height();
    var mediaHeader = $(".mediaHeader").height();
    var pageWrapperHeight = ((windowHeight) - (pageTabHeader)) - 114;
    var mediaPageWrapperHeight = ((windowHeight) - (mediaHeader)) - 155;
    var gidviewListHeight = ((windowHeight) - 440);
    $(".setMinHeight01").css("min-height", pageWrapperHeight);
    $(".mediaTableListForm").css("min-height", mediaPageWrapperHeight);
    $(".allLocationsGrid, .allUnitsGrid").css("min-height", gidviewListHeight);
  }


  /**
   * Fetch server data by On-Demand
   * Send current page number
   * Send Sorted details
   */
  loadData = async (currentPage, sortColumn) => {
    try {
      this.setState({ loaderIconVisible: true });
      const response = await this.requestData(currentPage, sortColumn);
      this.setState({
        data: response.rows,
        totalPages: response.pages,
        currentPage: currentPage,
        loading: false
      });

      var rolesList = response.rows;
      rolesList.forEach(r => {
        r.checked = false;
      });

      this.setState({
        data: rolesList,
        loaderIconVisible: false,
      });

      this.countValue();

    } catch (error) {
      this.setState({ loading: false, loaderIconVisible: false });
    }

  }

  countValue = (errType) => {
    let reqData = {
      "data": {
        "filters": "model",
        "brand": localStorage.getItem("brandId"),
        "criticalType": errType,
        'device_id': localStorage.getItem("unitID")
      }
    };
    let url = ServiceManagement.allCurrErrorCounts;
    http.post(url, reqData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        this.setState({ currentErrCount: response.data })
      }).catch(err => {
      });
  }

  /**
 * When navigate new page this event will return current page number
 * @param {*} currentPage 
 */
  onPageChange = (currentPage) => {
    this.loadData(currentPage);
  }

  /**
   * When sort the record by On-Demand
   * This event will return the sorted column details
   */
  onSortedChange = (sortColumn) => {
    this.setState({
      sortColumn: sortColumn,
      currentPage: 1
    });
    this.loadData(1, sortColumn);
  }

  /**
   * Business login to fetch units by below constraints
   * 1. Fetch by Brand specific units
   * 2. Fetch by Sorted column details
   * 3. Fetch by page wise
   */
  requestData = async (currentPage, sortColumn) => {
    try {
      const sortData = sortColumn && sortColumn.path !== "" ? sortColumn : this.state.sortColumn;
      const pageSize = this.state.pageSize;
      const sortType = sortData.order;
      const sortBy = sortData.path;
      const page = currentPage - 1;

      let pageNumber = (page + 1);
      let pageLimit = pageSize;
      let { searchData } = this.state;

      let order = page * pageSize;
      let currentUnit = this.props.currentUnit;
      currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
      this.setState({ loaderIconVisible: true });
      const { locale } = this.props;
      let apiUrl = getAPIFlagBaseUrl(this.state.errorType, this.state.eventType1, pageNumber, pageLimit, currentUnit.UNITID, "ErrorList", searchData,locale);
      const response = await getErrorList(apiUrl, sortBy, sortType);

      let { data, totalPages } = response;
      data.map((item, i) => {
        item.order_id = ++order;
      });
      this.setState({ loaderIconVisible: false });
      this.setState({
        noRecords: data.length > 0 ? false : true,
      });


      return {
        rows: data,
        pages: totalPages
      };

    } catch (error) {
      return {
        rows: [],
        pages: 0
      };
    }

  };

  changeErrorList = (event) => {
    let errorType = event.target.value === "All Errors" ? "" : event.target.value === "Warning" ? 'non-critical' : 'critical';
    this.state.errorType = errorType;
    this.setState({
      errorType: errorType,
      loading: true
    });
    let pageNumber = 1;
    let pageLimit = 25;
    let currentUnit = this.props.currentUnit;
    currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");    
    let apiUrl = getAPIUrl(errorType, pageNumber, pageLimit, currentUnit.UNITID, "ErrorList");
    getErrorList(apiUrl).then(response => {
      response.data.map((item, i) => {
        item.order_id = i + 1;
      });
      this.setState({
        data: response.data,
        totalPages: response.totalPages,
        currentPage: 1,
        loading: false
      });
    })
      .catch(err => {
        this.showError(err);
      })
  }

  validateErrorTypes = (isCritical, isNonCritical, isErrorEvent) => {
    let req = {};
    if (isCritical === false && isNonCritical === false && isErrorEvent === true) {
      req = { errorEvent: false }
    }
    else if (isCritical === false && isNonCritical === true && isErrorEvent === false) {
      req = { criticalType: false }
    }
    else if (isCritical === true && isNonCritical === true && isErrorEvent === false) {
      req = { criticalType: true }
    }
    else if (isCritical === true && isNonCritical === false && isErrorEvent === false) {
      req = { criticalType: true }
    }
    else if (isCritical === false && isNonCritical === true && isErrorEvent === true) {
      req = { criticalType: false, errorEvent: false }
    }
    else if (isCritical === true && isNonCritical === false && isErrorEvent === true) {
      req = { criticalType: true, errorEvent: false }
    }
    return req;
  }

  changeErrorList_checkBox = (event, key) => {
    let { isCriticalSel, isNonCriticalSel, isErrorEventSel, cbGroup } = this.state;
    let isChecked = event && event.target && event.target.checked;

    let errorType = "All", eventType = false;
    cbGroup[event.target.value] = isChecked;
    eventType = cbGroup["eventsError"];


    if (cbGroup["Warning"] && cbGroup["Error"]) {
      errorType = "All";
    } else if (!cbGroup["Warning"] && !cbGroup["Error"]) {
      errorType = "";
    } else if (cbGroup["Warning"]) {
      errorType = 'non-critical';
    } else if (cbGroup["Error"]) {
      errorType = 'critical';
    }

    if (!cbGroup["Warning"] && !cbGroup["Error"] && !cbGroup['eventsError']) {
      errorType = "none";
    }

    if (errorType == "" && eventType) {
      eventType = true;
    }


    this.state.errorType = errorType;
    this.state.eventType1 = eventType;

    this.setState({
      errorType: errorType,
      eventType1: eventType,
      loading: true
    });
    let pageNumber = 1;
    let pageLimit = 25;
    let currentUnit = this.props.currentUnit;
    currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
    const { locale } = this.props;
    let apiUrl = getAPIFlagBaseUrl(errorType, eventType, pageNumber, pageLimit, currentUnit.UNITID, "ErrorList","",locale);
    let setSelectedCheckbox = {};
    setSelectedCheckbox[key] = isChecked;

    switch (key) {
      case 'isCriticalSel':
        isCriticalSel = isChecked;
        break;
      case 'isNonCriticalSel':
        isNonCriticalSel = isChecked;
        break;
      case 'isErrorEventSel':
        isErrorEventSel = isChecked;
        break;
    }
    this.setState({ ...setSelectedCheckbox });

    getErrorList(apiUrl).then(response => {
      response.data.map((item, i) => {
        item.order_id = i + 1;
      });
      this.setState({
        data: response.data,
        totalPages: response.totalPages,
        currentPage: 1,
        loading: false
      });
    })
      .catch(err => {
        this.showError(err);
      })
  }

  showError = (err) => {
    let { response } = err;
    if (response) {
      this.setState({
        data: response.data,
        totalPages: response.totalPages,
        currentPage: 1,
        loading: false
      });
      this.setState({ loading: false });
    } else {
      this.setState({
        IsServerError: true
      });
    }
  }

  errorCloseModel = () => {
    localStorage.removeItem("Access-Token");
    this.setState({
      IsServerError: false
    });
    this.props.OnCloseSessionModal();
  }

  closeModel() {
    this.setState({
      error_modal: {
        open: false
      }
    });
  }

  onSuccessCloseModal() {
    this.setState({
      success_modal: {
        open: false
      },
      isIndeterminate: false,
			isSelectAllRoles: false
    });
  }

  onSendEmailHandler() {
    this.setState({
      loaderIconVisible: true
    });

    return new Promise((resolve, reject) => {

      let postData = {
        'customer_id': parseInt(CUSTOMER_ID),
        'brand_id': localStorage.getItem("brandName"),
        'user_email': localStorage.getItem("user"),
        'unit_id': localStorage.getItem("unitID")
      }

      http({
        method: 'POST',
        url: ServiceManagement.emailReport,
        data: postData
      })
        .then(res => {
          let { body } = res.data;
          let { responseCode, message, stringID } = body;
          if (responseCode !== 200) {
            this.setState({
              loaderIconVisible: false,
              error_modal: {
                open: true,
                message: message
              }
            });
            reject({
              response: {
                data: res.data
              }
            });
          } else {
            this.setState({
              loaderIconVisible: false,
              success_modal: {
                open: true,
                message: stringID ? stringID : message
              },
              isIndeterminate: false,
			        isSelectAllRoles: false
            });
            WoopraEvent(`${Constants.ERROR_REPORT_EMAIL}`);
            resolve(res);
          }
        }).catch(err => {
          reject(err);
        });
    });
  }
  
  errorDetailsForTroubleShoot = (e, selectRow) => {
    let resData = this.state.data;
    this.setState({
      dataToTrobleShoot: resData[selectRow - 1],
    }, () => {
      this.setState({
        dataToTrobleShoot: resData[selectRow - 1],
      })
    })
  }

  errorHelpSection = async (item) => {
      const currentUnit = JSON.parse(localStorage.getItem('currentUnit'))
      const brandName = currentUnit.BRAND_NAME;
      const modelName = item.model_family_name;
      const errorCode = item.error_code;
      const { locale } = this.props;
      const linkFormation = `${item.helplink}?brand=${brandName}&model=${modelName}&eventCode=${errorCode}&lang=${locale}`;
      window.open(linkFormation, "_blank");
  }


  handleChange = (e) => {
    var rolesList = [...this.state.data];
    rolesList.forEach(r => {
      r.checked = e.target.checked;
    });
    this.isSelectAllOption(rolesList);
  };

  onSelectRole = (e, item) => {
    if (this.state.data && this.state.data.length > 0) {
      const rolesList = [...this.state.data];
      const index = rolesList.indexOf(item);
      rolesList[index] = { ...rolesList[index] };
      rolesList[index].checked = e.target.checked;
      this.isSelectAllOption(rolesList);
    }
  }

  isSelectAllOption = (rolesList) => {
		let selectedRoles = rolesList.filter(item => item.checked === true);
		let isIndeterminate, isSelectAllRoles;
		
		if(selectedRoles.length === rolesList.length) {
			isSelectAllRoles= true; isIndeterminate = false;
		} else if(selectedRoles.length === 0 && rolesList.length !==0){
			isSelectAllRoles= false; isIndeterminate = false;
		} else if(rolesList.length > selectedRoles.length) {
			isSelectAllRoles= true; isIndeterminate = true;
		} else {
			isSelectAllRoles= false; isIndeterminate = false;
		}

		this.setState({
			data: rolesList,
			isIndeterminate: isIndeterminate,
			isSelectAllRoles: isSelectAllRoles
		});
	}

  getCloudsDetailsForThink = async () => {
    let unitID = localStorage.getItem("unitID");
    const url = HACCPManagementAPI.unitLogDetails + unitID;
    await http.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }, data: {}
    }).then(response => {
      if (response && response.data) {
        this.setState({
          thingDetails: response.data,
        });
      }
    }).catch(err => {

    });
  }

  clearErrorList = async (e) => {
    let haccpLogDetails = this.state.thingDetails;
    let thingList = [{
      "thingArn": haccpLogDetails.thingArn,
      "modelName": haccpLogDetails.modelName,
      "modelNumber": haccpLogDetails.modelNumber,
      "cloudUnitName": haccpLogDetails.cloudUnitName,
      "unitTimeZone": haccpLogDetails.unitTimeZone,
    }];

    var rolesList = [...this.state.data];
    let selErrorListCode = [];
    rolesList.forEach(r => {
      if (r.checked) {
        let val = { "error_code": r.error_code };
        selErrorListCode.push(val);
      }
    });

    let reqData = {
      'thingList': thingList,
      'errors': selErrorListCode,
      'brandId': localStorage.getItem("brandName"),
    }

    let apiUrl = ServiceManagement.clearError
    apiUrl = apiUrl.replace("{deviceid}", localStorage.getItem("unitID"));
    http.put(apiUrl, reqData,
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(response => {
        const { currentPage, sortColumn } = this.state;
        this.loadData(currentPage, sortColumn);
        this.setState({
          loaderIconVisible: false,
          success_modal: {
            open: true,
            message: "KC1823"
          },
          isIndeterminate: false,
			    isSelectAllRoles: false
        });
        WoopraEvent(`${Constants.CLEAR_ERROR}`);
      })
      .catch(error => {
        this.setState({
          loaderIconVisible: false,
          error_modal: {
            open: true,
            message: error.response.data.message ? error.response.data.message : "KC1900",
          }
        });
      })
  }
  static getDerivedStateFromProps = (props) => ({ ...props });

  render() {
    const currentUnit = JSON.parse(localStorage.getItem('currentUnit'))
    const brandName = currentUnit.BRAND_NAME;
    const modelName = currentUnit.PRODUCT_NAME;

    const { formatMessage } = this.props.intl;

    $(document).ready(function () {
      $(document).on('click', '.ReactTable .rt-table .rt-thead .rt-tr .rt-th', function () {
        $(this).parent().children().removeClass('-sort-desc');
      });

      // Nav Toggle
      $('.descCollapse').off('click').on('click', function () {
        $(this).parents(".tBody").toggleClass('descExp');
      });
    });

    const { data, totalPages, currentPage, sortColumn, IsServerError, success_modal, error_modal, noRecords, dataToTrobleShoot, isSelectAllRoles, pageSize, isCriticalSel, isNonCriticalSel, isErrorEventSel, loaderIconVisible, isIndeterminate } = this.state;
    let emailErrorReportAuthFeature = {};
    emailErrorReportAuthFeature = authService.getFeatureByFeatureName("Email Error Report");

    let errorCenterAuthFeature = {};
    errorCenterAuthFeature = authService.getFeatureByFeatureName("Error Center");

    let critical = ''
    let nonCritical = ""
    let totalEventsErrorCount = null;
    if (this.state.currentErrCount["currentErrCount"]) {
      critical = this.state.currentErrCount["currentErrCount"]["critical"]
      nonCritical = this.state.currentErrCount["currentErrCount"]["nonCritical"]
      totalEventsErrorCount = this.state.currentErrCount["currentErrCount"]["eventsError"]
    }

    //Columns Schema
    let columns = [];
    if (localStorage.getItem("brandName") === "FRYMASTER") {
      columns = [
        { path: "", label: "" },
        { path: "", label: "#" },
        { path: "is_critical", labelStringID: "KC0102" },
        { path: "error_code", labelStringID: "KC0194" },
        { path: "holder_id", label: "Frypot" },
        { path: "description", labelStringID: "KC0195" },
        { path: "current_start_date", labelStringID: "KC0196" },
        { path: "duration", labelStringID: "KC0197" },
        { path: "", labelStringID: "KC0198" },
        { path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
      ];
    } else  if (localStorage.getItem("brandName") === "GARLAND") {
      columns = [
        { path: "", label: "" },
        { path: "", label: "#" },
        { path: "is_critical", labelStringID: "KC0102" },
        { path: "error_code", labelStringID: "KC0194" },
        { path: "holder_id", labelStringID: "KC0336" },
        { path: "description", labelStringID: "KC0195" },
        { path: "current_start_date", labelStringID: "KC0196" },
        { path: "duration", labelStringID: "KC0197" },
        { path: "", labelStringID: "KC0198" },
        { path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
      ];
    } else if (localStorage.getItem("brandName") === "CREM") {
      columns = [
        { path: "", label: "" },
        { path: "", label: "#" },
        { path: "is_critical", labelStringID: "KC0102" },
        { path: "error_code", labelStringID: "KC0194" },
        { path: "description", labelStringID: "KC0195" },
        { path: "current_start_date", labelStringID: "KC0196" },
        { path: "duration", labelStringID: "KC0197" },
        { path: "", labelStringID: "KC0198" },
        { path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
      ];
    } else {
      columns = [
        { path: "", label: "" },
        { path: "", label: "#" },
        { path: "is_critical", labelStringID: "KC0102" },
        { path: "error_code", labelStringID: "KC0194" },
        { path: "description", labelStringID: "KC0195" },
        { path: "current_start_date", labelStringID: "KC0196" },
        { path: "duration", labelStringID: "KC0197" },
        { path: "", labelStringID: "KC0198" },
        { path: "", label: "", isCheckBox: true, isCheckBoxActive: true },
      ];
    }

    function CellComponent(props) {
      const { datediff, duration } = props;
      if (datediff && datediff <= 100) {
        return (<div class="progress-bar" style={{ width: '0%' }}>{duration}</div>);
      }
      else if (datediff && datediff <= 43200) {
        return (<div class="progress-bar" style={{ width: '30%' }}>{duration}</div>);
      }
      else if (datediff && datediff <= 86400) {
        return (<div class="progress-bar" style={{ width: '50%' }}>{duration}</div>);
      }
      else if (datediff && datediff > 86400 && datediff < 432000) {
        return (<div class="progress-bar" style={{ width: '75%' }}>{duration}</div>);
      }
      else {
        return (<div class="progress-bar" style={{ width: '100%' }}>{duration}</div>)
      }
    }

    let allowToDelete = false;
    var rolesList = data;
    rolesList.forEach(r => {
      if (allowToDelete === false) {
        allowToDelete = r.checked;
      }
    });

    return (
      <React.Fragment>
        <LoadingPopup loading={loaderIconVisible} />
        <ErrorMoal open={IsServerError ? true : false} message={"Your session has expired. Please login again."} stringID={"KC1678"} onCloseModel={() => this.errorCloseModel()} />
        <div class="pageTabHeader">
          <ul>
            <li>
              <Link to="/serviceManagement" title={formatMessage({id:'KC1004'})} class="serviceMgtHeader active"><FormattedMessage id="KC0188"/></Link>
            </li>
            <li class="selectedBox">
            </li>
          </ul>
        </div>
        <div class="gridViewTable">
          <div class="gridView">
            <div class="colm12D">
              <div class="colmDi">
                <h4><FormattedMessage id="KC0189"/>
                <div class={data.length > 0 ? "sendEmaiReport" : "sendEmaiReport disabled"}>
                    {emailErrorReportAuthFeature && emailErrorReportAuthFeature.featureAvailable ?
                      (localStorage.getItem("brandName") === "MERRYCHEF" || localStorage.getItem("brandName") === "CONVOTHERM" || localStorage.getItem("brandName") === "FRYMASTER" || localStorage.getItem("brandName") === "MANITOWOC ICE" || localStorage.getItem("brandName") === "DELFIELD" || localStorage.getItem("brandName") === "GARLAND" || localStorage.getItem("brandName") === "MULTIPLEX" || localStorage.getItem("brandName") === "LINCOLN" || localStorage.getItem("brandName") === "FRYMASTER-UHCTHD") ?
                        <Link to="#" onClick={() => this.onSendEmailHandler()} title={formatMessage({id:"KC0284"})}>&nbsp;</Link>
                        : "" : ""}
                    {localStorage.getItem("brandName") === "CREM" ?
                      <button class={allowToDelete && errorCenterAuthFeature && errorCenterAuthFeature.is_editable === 1 ? "btn btn-secondary clearErrorBtn" : "btn btn-secondary clearErrorBtn disabled"} data-toggle="modal" data-target="#confirmationDeleteModal" type="button"><FormattedMessage id="KC0193"/></button>
                      : ""}
                  </div>
                  <div className="sendEmaiReport">
                    <div className="checkBoxListGrp">
                      <div className="customCheckbox crticalCBox">
                        <input id="criticalError" type="checkbox" value="Error" onClick={(e) => this.changeErrorList_checkBox(e, "isCriticalSel")} checked={isCriticalSel} />
                        <label for="criticalError"><FormattedMessage id="KC0156"/> <span>{critical}</span></label>
                      </div>
                      <div className="customCheckbox nonCrticalCBox">
                        <input id="nCriticalError" type="checkbox" value="Warning" onClick={(e) => this.changeErrorList_checkBox(e, "isNonCriticalSel")} checked={isNonCriticalSel} />
                        <label for="nCriticalError"><FormattedMessage id="KC0157"/> <span>{nonCritical}</span></label>
                      </div>
                      <div className="customCheckbox eventsCBox">
                        <input id="eventsError" type="checkbox" value="eventsError" onClick={(e) => this.changeErrorList_checkBox(e, "isErrorEventSel")} checked={isErrorEventSel} />
                        <label for="eventsError"><FormattedMessage id="KC0422"/> <span>{totalEventsErrorCount}</span></label>
                      </div>
                    </div>
                  </div>
                </h4>
              </div>
              <div class="errorListTab setMinHeight01">
                {noRecords === false ?
                  <div class={(localStorage.getItem("brandName") === "FRYMASTER" || localStorage.getItem("brandName") === "GARLAND") ? "fErrorListTable" : "errorListTable"}>
                    <TableHeader
                      sortColumn={sortColumn}
                      onSort={this.onSortedChange}
                      columns={columns}
                      selectAllChange={this.handleChange}
                      isSelectAll={isSelectAllRoles}
                      isIndeterminate={isIndeterminate} />
                    {data.map((item, i) => {
                      const isDisabled = !(item.error_code && item.description && brandName && item.model_family_name);
                      return (
                        <ul class="tBody">
                          <li></li>
                          <li>{item.order_id}</li>
                          <li className="errorStausLi">
                            {item.is_critical === 0 && <span class="errorStaus alertLevel">&nbsp;&nbsp;</span>}
                            {item.is_critical === 1 && <span class="errorStaus warnLevel">&nbsp;&nbsp;</span>}
                            {item.is_critical === 2 && <span class="errorStaus errorEvent">&nbsp;&nbsp;</span>}
                          </li>
                          <li>{(localStorage.getItem("brandName") === "MULTIPLEX") ? "----" : item.error_code}</li>
                          {localStorage.getItem("brandName") === "FRYMASTER" ? <li>{formatMessage({ id: 'Frypot' })}   {item.holder_id}</li> : ""} {/* MLS:TODO */}
                          {localStorage.getItem("brandName") === "GARLAND" ? <li>{((item.holder_id === "1" || item.holder_id === null)? "--":item.holder_id)}</li> : ""}
                          <li>
                            <p>{item.description}</p>
                          </li>
                          <li>{item.current_start_date}</li>
                          <li className="progress">{CellComponent(item)}</li>
                          <li>
                            <button
                              onClick={() => isDisabled ? '' : this.errorHelpSection(item)}
                              title={formatMessage({ id: 'KC0198' })}
                              class={`helpIcon cursor-pointer ${isDisabled ? 'disabled' : ''}`}>
                            </button>
                          </li>
                          {
                            <li>
                              <div className={"customCheckbox customCheckboxWOlabel"} >
                                <input id={i} name={i} type="checkbox"
                                  checked={item.checked}
                                  onChange={(e) => this.onSelectRole(e, item)} />
                                <label htmlFor={i}></label>
                              </div>
                            </li>
                          }
                        </ul>
                      )
                    })
                    }
                  </div> : ""}
                {noRecords === true ? <NoRecordsFound loaderIconVisible={false} length={noRecords ? 0 : 1} classname={'norecordsfound'} /> : ""}
              </div>
              {/* <!-- Delete Confirmation Modal --> */}
              <div class="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                <div class="modal-dialog modal-md">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="deleteConfirmation">
                        <FormattedMessage id="KC0847"/>
					            </div>
                    </div>
                    <div class="modal-footer textAlignCenter">
                      <button class="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
                      <button onClick={(e) => this.clearErrorList()} class="btn btn-secondary deleteYesBtn" id="deleteYesBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0193"/></button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- //Delete Confirmation -->
	            <!-- Delete Confirmation Modal --> */}
              <div class="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="deleteConfirmationOkModal" tabindex="-1" role="dialog" aria-labelledby="confirmationOkModalLabel">
                <div class="modal-dialog modal-md">
                  <div class="modal-content">
                    <div class="modal-body">
                      <div class="confirmationDiv">
                        <FormattedMessage id="KC1823"/>
					            </div>
                    </div>
                    <div class="modal-footer textAlignCenter">
                      <button class="btn btn-secondary confirmationOKBtn" onclick="location.href='cremcurrenterrors.html'" type="button"><FormattedMessage id="KC0777"/></button>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- //Delete Confirmation --> */}
              { totalPages && totalPages > 1 ? <GoToPagination
                isBlackBackground={true}
                totalPageNumber={totalPages}
                pageNumber={currentPage}
                navigateTo={(p) => this.onPageChange(p)} /> : null }
            </div>
          </div>
        </div>
        <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel} /> 
        <SuccessMoal open={success_modal.open} message={success_modal.message} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} /> 
      </React.Fragment>
    );
  }
};

function mapStateToProps(state, ownProps) {
  return {
    searchData: state.advanceSearch.searchData,
    locale: state.localeInfo.locale,
  }
}

export default connect(mapStateToProps, null)(injectIntl(ServiceMgmtList));
