import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../service/httpService';
import jwt_decode from 'jwt-decode';
import UserManagementHeader from '../layoutSection/userManagementHeader'
import { UserProfile } from '../../service/api';
import qs from 'query-string';
import fConsts from '../../utils/featureConstants';
import authService from '../../service/authService';   
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import { FormattedMessage, injectIntl } from 'react-intl';

const userImages = [
	{ name: "System Manager", img: require("../../images/users/owner.svg").default },
	{ name: "Crem System Admin", img: require("../../images/users/owner.svg").default },	
	{ name: "Service Technician", img: require("../../images/users/service.svg").default },	
	{ name: "1st Line User", img: require("../../images/users/leaser.svg").default },
	{ name: "2nd Line User", img: require("../../images/users/chef.svg").default },
	{ name: "Crem User", img: require("../../images/users/manager.svg").default }
	
]

class MyInformation extends Component {
	constructor(props) {
		super(props);
		this.toggleHidden = this.toggleHidden.bind(this)
		this.handleOutsideClick = this.handleOutsideClick.bind(this);		
		this.state = {
			userData: {},
			emailfromTokan: '',
			countryCodeWithoutPlus:'',
			loaderIconVisible:false,
		};
	}

	toggleHidden(event) {
		event.preventDefault();

		if (!this.state.isHidden) {
			document.addEventListener('click', this.handleOutsideClick, false);
		} else {
			document.removeEventListener('click', this.handleOutsideClick, false);

		}
		this.setState({
			isHidden: !this.state.isHidden
		})
	}

	handleOutsideClick(event) {
		// ignore clicks on the component itself             
		if (this.pop) { 
			if (this.pop.contains(event.target)) {
				return;
			}
			this.toggleHidden(event);
		}
	}

	getUserProfileInformation = () => {		
		let getUserData = userProfileMockData.user;
		let token = localStorage.getItem("Access-Token");
		let loginUser =   JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		var decrypt = jwt_decode(token)
		//let customerid = decrypt["custom:customerid"];
		let customeremail = decrypt.email;
		customeremail=loggedinUser;
		this.setState({ emailfromTokan: loggedinUser, loaderIconVisible:true });
		const values = qs.parse(this.props.location.search);
        if (values.user !== undefined)
        {
			customeremail = values.user;
			var decVal = Buffer.from(values.user, 'base64')
			var setEmailValue = decVal.toString();
			customeremail = setEmailValue;
			this.setState({ queryString: values.user });
		}
		

		http.get(UserProfile.getUserProfileDetails,
			{headers: {
				'customer_id': localStorage.getItem("customerId"),
				'email': customeremail,
				'Content-Type': 'application/json',
				'base_country': localStorage.getItem("basecountry"),
				'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
			},data:{},
		}).then(response => {
			this.setState({
				userData: response.data,
				loaderIconVisible:false,
				countryCodeWithoutPlus:response.data.country_code.replace("+",""),
			})
		}).catch(err => {
			console.log('catch exception occured');
			this.setState({ userData: getUserData,loaderIconVisible:false });
		});
	}
	componentDidMount() {
		this.getUserProfileInformation();
		document.body.style.backgroundColor = "#F2F2F2";
	}

	// Woopra Events
	trackEvent = (e) => {
		WoopraEvents(`${e.trackTitle}`)
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { userData,loaderIconVisible, queryString } = this.state;
		let pickRole = userImages.find(r => r.name === userData.role_name);        
        let userRoleImage="";
        if (pickRole != null) {
             userRoleImage = pickRole.img;
		}
		else if(pickRole === undefined)
		{
			userRoleImage=require("../../images/users/user.png");
		}

		let phonenumandcountrycode=null;
		if(this.state.countryCodeWithoutPlus)		
			phonenumandcountrycode= (<h4 x-ms-format-detection="none"> {"+"+this.state.countryCodeWithoutPlus}&nbsp;{userData.phone_number}</h4>)
		

		let userNotificationAuthFeature = authService.getFeature("/notificationManagement");
        let notificationLink="/NotificationManagementView";	
        if (queryString !== undefined )
		{			
			notificationLink="/NotificationManagementView?user="+`${queryString}` ;				
		}
		else
		{
			notificationLink ="/NotificationManagementView";
		}

		let userNotificationFeature = authService.getFeatureByFeatureName(fConsts.Notifications);
		let IsNotificationActive = false;
		IsNotificationActive =  userNotificationFeature  && userNotificationFeature.feature_active;
		IsNotificationActive = queryString ? true : IsNotificationActive;

		return (
			<React.Fragment>
				<div id="adminWrapper">
					{/* <!-- Loading Progress --> */}
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress" }>
						<div className="loadingProgressIn"></div>
					</div>
					{/* <!-- //Loading Progress --> */}

					<div id="page-content-wrapper">
						<div class="userManagementWrapper">
							<div class="userProfileHeader">
								<ul>
									<li>
										<Link to={queryString !== undefined ? "/myinformation?user="+`${queryString}` : "/myinformation"} title={formatMessage({id: 'KC1916'})} class="active"><FormattedMessage id='KC1916' /></Link>
										<Link to={queryString !== undefined ? "/assignedunitgroups?user="+`${queryString}` : "/assignedunitgroups"} title={formatMessage({id: 'KC1917'})} onClick={(e)=> {e.trackTitle="Assigned Unit Groups";this.trackEvent(e)}}><FormattedMessage id='KC1917' /></Link>
										{/* { IsNotificationActive && <Link to={{pathname:notificationLink, state : {fromViewAccountpageValue: true}}} title="Notification Management">Notification Management</Link>} */}
										{ IsNotificationActive && <Link to={notificationLink} title={formatMessage({id:'KC0109'})} onClick={(e)=> {e.trackTitle="Notification Management";this.trackEvent(e)}}><FormattedMessage id='KC0109' /></Link>}
										{/* <Link to="/mySubscriptionView" title="Subscription Management">Subscription Management</Link> */}
										<Link to="/termsPricingCondition" title={formatMessage({id: 'KC2439'})} ><FormattedMessage id='KC2439' /></Link>
									</li>
								</ul>
							</div>

							<form class="myInformationForm">
								<div class="myInformationTable">
									<ul class="tHead">
										<li><span class="userProfileImg"><img src={userRoleImage} alt=""/></span></li>
										<li>
											<h3>{userData.name}</h3>
											<h4>{userData.role_name}</h4>
										</li>
										<li>&nbsp;</li>
									</ul>
									<ul class="tBody">
										<li>&nbsp;</li>
										<li>
											<h4>{userData.email}</h4>
											{phonenumandcountrycode}
										</li>
										<li>
											<h5>{userData.street_name} {userData.street_number}</h5>
											<h5>{userData.zip_code} {userData.city}</h5>
											<h5>{userData.country}</h5>
										</li>
									</ul>
								</div>
							</form>
						</div>
					</div>
				</div>
				<UserManagementHeader headerName="User Profile" headerNameStringID="KC0106" previousPage={queryString !== undefined  ? "User List" : "User Profile"} activeClass="UserProfileNav" />
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MyInformation));

const userProfileMockData = {
	"user": {
        "name": "",
		"customer_id": "1",
        "phone_number": "",
        "email": "",
        "street_name": "",
        "street_number": "",
        "zip_code": "",
        "city": "",
        "country": "",
        "state": ""
    }
}