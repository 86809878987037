import $ from 'jquery';
import _ from 'lodash';
import React, { Component } from 'react';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import http from '../../service/httpService';
import { BaseService, DeviceRegistration } from '../../service/api';

export default class ConsumerProfileCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: "",
            },
            consumerData: {},
            customerDetails: {},
        };
    }

    async componentDidMount() {
        $('body').addClass("deviceRegtrnBg");
        const consumerData = await (localStorage.getItem("consumerData") ? JSON.parse(localStorage.getItem("consumerData")) : {});
        if (!consumerData) {
            this.setState({
                loaderIconVisible: false
            })
            return;
        }
        this.setState({
            consumerData
        }, async () => {
            await this.getUnitDetails();
        })
    }

    getUnitDetails = async () => {
        const { consumerData } = this.state;
        var urlParams = new URLSearchParams(window.location.search);
        const customerId = urlParams.get("customer_id");
        const subscriptionId = urlParams.get("subscription_id");

        if (!consumerData && !consumerData.customer_id) {
            this.setState({ loaderIconVisible: false, error_modal: { open: true, message: 'Invalid way of registration', isHtml: false } });
            return;
        }

        await http({
            method: "POST",
            url: `${BaseService.root}${DeviceRegistration.chargifyConsumerCreation}`,
            data: {
                "email": consumerData.email || '',
                "name": consumerData.name || '',
                "subscriptionId": subscriptionId,
                "customer_id": consumerData.customer_id || '',
                "customer_name": consumerData.customer_name || '',
                "resourceId": customerId,
                "resourceType": "customers",
                "base_country": consumerData && consumerData.userDetails ? consumerData.userDetails.base_country : '',
                "access_countries": consumerData && consumerData.userDetails ? consumerData.userDetails.access_countries : ''
            },
            headers: {
                'Content-Type': 'application/json',
            }
        }).then(async response => {
            if (response && response.data) {
                const { customerDetails } = response.data
                const getCompanyName = customerDetails && customerDetails.customFields && customerDetails.customFields.length ? customerDetails.customFields.filter((i) => i.name === "Commercial Company Name") : [];
                const company_name = getCompanyName && getCompanyName.length ? getCompanyName[0].value : '';
                http({
                    method: "POST",
                    url: `${BaseService.root}${DeviceRegistration.eaasEmailConfirmation}`,
                    data: {
                        "name": consumerData.name || '',
                        "email": consumerData.email || '',
                        "customer_email": consumerData.customer_email || '',
                        company_name
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(response => {
                    this.setState({ loaderIconVisible: false, success_modal: { open: true, message: 'Thanks for registration.' } });
                }).catch(err => {
                    this.setState({ loaderIconVisible: false, success_modal: { open: true, message: 'Thanks for registration.' } });
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false, error_modal: { open: true, message: err && err.response && err.response.data && err.response.data.body && err.response.data.body.message ? err.response.data.body.message : 'Something went wrong' } });
        });
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            },
        });
        if(localStorage.getItem('isCookieAccepted')){
            localStorage.clear();
            localStorage.setItem('isCookieAccepted', true);
        }else{
            localStorage.clear();
        }
        this.props.history.push('/')
    }

    onSuccessCloseModal = async (e) => {
        this.setState({
            success_modal: {
                open: false,
            },
        })
        if(localStorage.getItem('isCookieAccepted')){
            localStorage.clear();
            localStorage.setItem('isCookieAccepted', true);
        }else{
            localStorage.clear();
        }
        this.props.history.push('/')
    }

    render() {
        const { loaderIconVisible, error_modal, success_modal } = this.state;

        return (
            <div id="adminWrapper">
                <div className="toggleTransparent"></div>
                <div className="sideNavToggleTransparent"></div>
                <div className="userToggleTransparent"></div>
                <div id="page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <a className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" href="/">&nbsp;</a>
                </nav>

                <div id="page-wrapper">

                </div >
                <ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </div >
        )
    }
};
