import React, { Component } from 'react';
import { format, addDays } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { HolderList } from '../../unitDashboard/globalWidgets/holderList';
import NumberFormat from 'react-number-format';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import LoadingPopup from '../../controls/loadingPopup';
import http from '../../../service/httpService';
import { ReportManagement } from '../../../service/api';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';

var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class LbsOilLifeDisposeInfo extends Component {

    constructor(props, context) {
        super(props, context);
        const holder_name = 'holder_name_' + props.widgetID;
        this.state = {
            type: 'Month',
            filterType: "Month",
            startDate: '',
            endDate: '',
            loaderIconVisible: false,
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            holder_id: "",
            [holder_name]: "Fryer",
            datapickflag:false
        };

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount = async () => {
        this.setState({
            filterType: "Month",
        });
        currentUnit = this.props.properties.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        this.getLBSOilLifeDisposeData("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, this.props.widgetID);
    }

    getLBSOilLifeDisposeData(type, fromdate, todate, unitID, brandID, widgetId, holder_id = '') {
        this.setState({ loaderIconVisible: true });
        let url = '';
        let filterType = '';
        switch (type) {
            case 'Year':
                filterType = 'yearly';
                break;
            case 'Quarter':
                filterType = 'quarterly';
                break;
            case 'Date Range':
                filterType = 'custom';
                break;
            default:
                filterType = 'monthly';
        }

        url = `${ReportManagement.widgetAPI}/${widgetId}/${brandID}`;

        const header = {
            'Content-Type': 'application/json',
            'unitid': unitID,
            'reqdate': fromdate,
            'filtertype': filterType,
        }

        if (filterType === 'custom') {
            delete header.reqdate;
            header.startdate = todate;
            header.enddate = fromdate;
        }
        if (holder_id !== "") {
            header.holderid = holder_id;
        }

        this.getData(url, header).then(response => {
            let currentValue = null;
            let priorValue = null;
            let trend = 0;
            let lbsOilCurrentValue = null;
            let lbsOilPriorValue = null;
            let lbsOilTrend = 0;
            if (response && response.widgetInformation && response.widgetInformation[0].avgOilLife) {
                currentValue = response.widgetInformation[0].avgOilLife !== undefined ? response.widgetInformation[0].avgOilLife[0].value : 0;
                priorValue = response.widgetInformation[0].avgOilLife !== undefined && response.widgetInformation[0].avgOilLife.length > 1 ? response.widgetInformation[0].avgOilLife[1].value : 0;
                trend = response.widgetInformation[0].avgOilLife !== undefined && response.widgetInformation[0].avgOilLife.length > 2 ? response.widgetInformation[0].avgOilLife[2].value : 0;
            }
            if (response && response.widgetInformation && response.widgetInformation[0].totalLBSOilDisposed) {
                lbsOilCurrentValue = response.widgetInformation[0].totalLBSOilDisposed !== undefined ? response.widgetInformation[0].totalLBSOilDisposed[0].value : 0;
                lbsOilPriorValue = response.widgetInformation[0].totalLBSOilDisposed !== undefined && response.widgetInformation[0].totalLBSOilDisposed.length > 1 ? response.widgetInformation[0].totalLBSOilDisposed[1].value : 0;
                lbsOilTrend = response.widgetInformation[0].totalLBSOilDisposed !== undefined && response.widgetInformation[0].totalLBSOilDisposed.length > 2 ? response.widgetInformation[0].totalLBSOilDisposed[2].value : 0;
            }

            this.setState({
                loaderIconVisible: false,
                currentValue: currentValue,
                priorValue: priorValue,
                trend: trend,
                lbsOilCurrentValue: lbsOilCurrentValue,
                lbsOilPriorValue: lbsOilPriorValue,
                lbsOilTrend: lbsOilTrend,
            });
        }).catch(error => {
            this.setState({ loaderIconVisible: false });
            console.log(error);
        });
    }

    getData(url, header) {
        return new Promise((resolve, reject) => {
            http
                .get(url,
                    {
                        headers: header,
                        data: {}
                    })
                .then(response => {
                    resolve({
                        widgetInformation: response.data
                    })
                })
                .catch(error => {
                    reject({
                        widgetInformation: {}
                    })
                });
        });
    }

    handleChangeStart(filtrationDate, mindate) {
        this.setState({
            startDate: filtrationDate,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            this.onDropDownHandler(e, 'LBSOilLifeDispose');
        });
    }

    handleChangeEnd(filtrationDate) {
        this.setState({
            endDate: filtrationDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            this.onDropDownHandler(e, 'LBSOilLifeDispose');
        });
    }

    fetchData(holder_id, text) {
        currentUnit = this.props.properties.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else {
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
        }

        this.setState({ type: text });
        if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.getLBSOilLifeDisposeData(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, this.props.widgetID, holder_id);
        }
    }

    onDropDownHandler(e, type = '') {
        this.fetchData(this.state.holder_id, e.nodeText);
        this.setState({
            filterType: e.nodeText,
            datapickflag: e.nodeText === 'Date Range'
        });
    }

    onHolderSelection(e, type = '') {
        const holderid = e.target.text.replace("Frypot ", "").replace("Fryer", "")
        this.setState({
            holder_id: holderid,
            holder_name: e.target.text
        });
        this.fetchData(holderid, type);
    }

    returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (localStorage.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

    render() {
        let { currentValue,
            priorValue,
            trend,
            lbsOilCurrentValue,
            lbsOilPriorValue,
            lbsOilTrend,
            loaderIconVisible } = this.state;
        currentUnit = this.props.properties.currentUnit;
        let oilUsageFilterTabHeader = null;

        if (currentUnit.BRAND_NAME === 'FRYMASTER') {
            oilUsageFilterTabHeader = (
                <div class="pageTabHeader greybg">
                    <ul>
                        <li class="filterDropdown">
                            <p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
                            <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate}
                                minDateDisable={this.state.sdmindate}
                                maxDateDisable={this.state.sdmaxdate}
                                endminDateDisable={this.state.edmindate}
                                endmaxDateDisable={this.state.edmaxdate}
                                endDatepara={this.state.endDate}
                                handleChangeStartProps={(date) => this.handleChangeStart(date)}
                                handleChangeEndProps={(date) => this.handleChangeEnd(date)}
                                OnChange={(e) => this.onDropDownHandler(e, "LBSOilLifeDispose")}
                                filterType={this.state.type}
                                disableDayFilter={true}
                                disableWeekFilter={true}
                                minDaysRequired={29} />
                            <HolderList OnHolderChange={(e) => this.onHolderSelection(e, this.state.type)} />
                        </li>
                    </ul>
                </div>
            )
        }

        let totalAndAvgOilLife = (
            <div class="gridViewTable gridViewcolm6DSM">
                <div class="gridView">
                    <div class="colm6D colm6D1">
                        <div class="colmDi">
                            <h4><FormattedMessage id="KC0479"/></h4>
                            <div class="colmDiVal">
                                <div class="valH1Div"> {this.returnNumberFormat(Math.round(lbsOilCurrentValue))} <span className={this.state.type === "Date Range" ? "displaynone" : Math.round(lbsOilCurrentValue) === Math.round(lbsOilPriorValue) ? "valH1Span" : lbsOilTrend === '1' ? "valH1Span valHigh" : lbsOilTrend === '0' ? "valH1Span valLow" : "valH1Span"}>&nbsp;</span></div>
                                <div className={this.state.type === "Date Range" ? "displaynone" : "valH5Div"}>
                                    {mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}`}: {this.returnNumberFormat(Math.round(lbsOilPriorValue))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="colm6D colm6D2">
                        <div class="colmDi">
                            <h4><FormattedMessage id="KC1796"/></h4> 
                            <div class="colmDiVal">
                                <div class="valH1Div"> {this.returnNumberFormat(currentValue ? currentValue.toFixed(1) : 0)} <span className={this.state.type === "Date Range" ? "displaynone" : currentValue === priorValue ? "valH1Span" : trend === '1' ? "valH1Span valHigh" : trend === '0' ? "valH1Span valLow" : "valH1Span"}>&nbsp;</span></div>
                                <div className={this.state.type === "Date Range" ? "displaynone" : "valH5Div"}>
                                    {mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}`}: {this.returnNumberFormat(priorValue ? priorValue.toFixed(1) : 0)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        return (
            <React.Fragment>
                <LoadingPopup loading={loaderIconVisible} />
                {/* Oil Usage Filter type*/}
                {oilUsageFilterTabHeader}
                {/* Level 1 | Widgets - 71 (Frymaster | Total LBS Oil Disposed | Average Oil Life (days) */}
                {totalAndAvgOilLife}
            </React.Fragment>
        )
    }
};

export default LbsOilLifeDisposeInfo;
