import React, { Component } from 'react';
import http from '../../../../../service/httpService';
import { MenuUploadAPI } from '../../../../../service/api';
import authService from '../../../../../service/authService';
import { CUSTOMER_ID, BASECOUNTRY_ID } from '../../../../../utils/appConstants';
import moment from '../../../../../utils/momentHelper.js';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { injectIntl, FormattedMessage } from 'react-intl';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'


class GarlandCookbookAddRecipeItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            selectedlibraries: [],
            search: '',
            previouspath: '',
            selectedCategoryName: '',
            isProductRecognitionChecked: false,
            isProductRecognitionAdded: false
        }
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        this.updateDimensions();
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            const productReg = localStorage.getItem('ProductRecgDetails') ? JSON.parse(localStorage.getItem('ProductRecgDetails')) : {};
            const productRegRecipe = productReg && productReg.recipe_items ? productReg.recipe_items : [];
            const isProductRecognitionAdded = productRegRecipe.length > 0;
            this.setState({ loaderIconVisible: true, isProductRecognitionAdded }, async () => {
                await this.getLibraryList().catch(err => {
                    this.setState({
                        loaderIconVisible: false
                    });
                });
            })

        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
        var windowHeight = $(window).height();
        var menuFilterHeader = $(".menuFilterHeader").height();
        var pageRRHeight = ((windowHeight) - (menuFilterHeader)) - 105;
        $(".readyRecipeSrlTable").css("height", pageRRHeight);

    }

    getLibraryList = () => {
        this.setState({
            loaderIconVisible: true,
            previouspath: this.props.history.location.previouspath
        });

        return new Promise((resolve, reject) => {
            http.get(MenuUploadAPI.getLibraryList,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'customerid': CUSTOMER_ID,
                        'cgids': localStorage.getItem("custGroupID"),
                        'brandid': localStorage.getItem("selectedbrandID"),
                        'modelfamilyids': localStorage.getItem("selectedFamilyID"),
                        'basecountry': BASECOUNTRY_ID,
                        'Type': "Recipe"
                    },
                    data: {}
                }).then(response => {
                    let { data } = response;
                    let libraryList = [];
                    const productReg = localStorage.getItem('ProductRecgDetails') ? JSON.parse(localStorage.getItem('ProductRecgDetails')) : {};

                    if (this.state.isProductRecognitionAdded) {
                        let prObj = libraryList.length ? libraryList[0] : {};
                        prObj['ID'] = 0;
                        prObj['LIBRARY_NAME'] = productReg && productReg.recipeName ? productReg.recipeName : "Auto";
                        prObj['LIBRARY_GUID'] = productReg && productReg.recipeID ? productReg.recipeID : "Auto";
                        prObj['LIBRARY_ID'] = productReg && productReg.recipeID ? productReg.recipeID : "Auto";
                        prObj['checked'] = false;
                        prObj['CREATED_ON'] = new Date();
                        prObj['UPDATED_ON'] = new Date();
                        if (data && data.libraryList) {
                            libraryList = [prObj, ...data.libraryList];
                        } else {
                            libraryList = [prObj]
                        }
                    } else {
                        libraryList = data && data.libraryList ? data.libraryList : []
                    }
                    let selectedCategory = localStorage.getItem("selectedCategory");
                    let selectedCategoryObj = JSON.parse(selectedCategory);
                    let categoryDetails = localStorage.getItem("resourceDetails");
                    let categoryDetailsObj = JSON.parse(categoryDetails);

                    let recipeDetails = [];
                    let categoryName = '';
                    if (selectedCategoryObj.subCategory.selected && selectedCategoryObj.subCategory !== undefined && selectedCategoryObj.subCategory !== []) {
                        var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
                        recipeDetails = categoryDetailsObj[foundIndex].subCategory.filter(c => c.ID === selectedCategoryObj.subCategory.ID);
                        categoryName = recipeDetails ? recipeDetails[0].categoryName : "";
                        recipeDetails = recipeDetails ? recipeDetails[0].recipe_items ? recipeDetails[0].recipe_items : [] : [];

                    }
                    else {
                        recipeDetails = categoryDetailsObj.filter(c => c.ID === selectedCategoryObj.ID);
                        categoryName = recipeDetails ? recipeDetails[0].categoryName : "";
                        recipeDetails = recipeDetails ? recipeDetails[0].recipe_items ? recipeDetails[0].recipe_items : [] : [];
                    }
                    console.log("libraryList Finish", libraryList);

                    libraryList.length > 0 && libraryList.map(item => {
                        item.CREATED_ON = moment.ConvertLocalTimeFromUTCTime(item.CREATED_ON, "DD/MM/YYYY");
                        item.checked = false;
                        recipeDetails.length > 0 && recipeDetails.map(recipeitem => {
                            if (item.ID === recipeitem.ID) {
                                item.checked = true;
                            }
                        });
                    });

                    this.setState({
                        loaderIconVisible: false,
                        data: libraryList,
                        selectedCategoryName: categoryName,
                        selectedlibraries: libraryList.filter(c => c.checked === true)
                    });
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
        });
    }

    recipeSearchResult = (e) => {
        this.setState({ search: e.target.value });
    }

    onAddRecipes = () => {
        let selectedCategory = localStorage.getItem("selectedCategory");
        let selectedCategoryObj = JSON.parse(selectedCategory);

        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);

        if (this.state.selectedlibraries && this.state.selectedlibraries.length >= 1024) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC2240"
                }
            });
        }
        else {
            if (selectedCategoryObj.subCategory.selected && selectedCategoryObj.subCategory !== undefined && selectedCategoryObj.subCategory !== []) {
                var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
                let selectedSubcategoryObj = categoryDetailsObj[foundIndex].subCategory.find(c => c.ID === selectedCategoryObj.subCategory.ID);
                let subcategoryObj = categoryDetailsObj[foundIndex].subCategory.filter(c => c.ID !== selectedCategoryObj.subCategory.ID);
                selectedSubcategoryObj.recipe_items = this.state.selectedlibraries;
                subcategoryObj.push(selectedSubcategoryObj);
                categoryDetailsObj[foundIndex].subCategory = subcategoryObj;

                localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
                this.props.history.push({
                    pathname: "/cookbookRecipeList",
                    isEdit: false,
                    previouspath: "/garlandCookbookAddRecipeItem"
                });
            }
            else {
                let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === selectedCategoryObj.ID);
                let categoryObj = categoryDetailsObj.filter(c => c.ID !== selectedCategoryObj.ID);
                selectedCategoryDetailsObj.recipe_items = this.state.selectedlibraries;
                categoryObj.push(selectedCategoryDetailsObj);
                localStorage.setItem("resourceDetails", JSON.stringify(categoryObj));
                this.props.history.push({
                    pathname: "/garlandCookbookAddRecipe",
                    isEdit: false,
                    previouspath: "/garlandCookbookAddRecipeItem"
                });
            }
        }
    }

    onSelectLibrary(e, item) {
        if (this.state.data && this.state.data.length > 0) {
            const libraries = [...this.state.data];
            const index = libraries.indexOf(item);
            libraries[index] = { ...libraries[index] };
            libraries[index].checked = e.target.checked;

            let selectedlibraries = libraries.filter(c => c.checked === true);

            this.setState({
                data: libraries,
                isSelectAllLibrary: selectedlibraries.length === libraries.length,
                selectedlibraries: selectedlibraries
            });
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, loaderIconVisible } = this.state;
        let { selectedCategoryName, search } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = localStorage.getItem("selectedbrandID");
        dataList = dataList && dataList.length > 0 ? dataList : [];
        const searchedItemInFilter = dataList && dataList.filter(
            (recipe) => {
                return recipe.LIBRARY_NAME.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }
        );
        dataList = searchedItemInFilter;
        let libraryAuthFeature = authService.getFeature("/libraryList");

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658" /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li className="selectedModel">ME, MG</li>
                            </ul>
                        </div>

                        <div className="rcDeviceWrapper">

                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} /> <button type="button" onClick={() => this.props.history.push('/garlandCookbookToTopology')} className="btn saveExitBtn">Save File &amp; Exit</button></div>

                            <div className="recipeEditorWrapper">

                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={() => this.props.history.replace('/garlandCookbookAddRecipe')} className="btn backButton">&nbsp;</button>
                                    {selectedCategoryName}
                                </div>
                                <div className="cbSearchForm">
                                    <ul>
                                        <li>
                                            <div className="cbRSearchBox">
                                                <input type="text" className="form-control" onChange={(e) => this.recipeSearchResult(e)} placeholder="Search Recipe" />
                                            </div>
                                            <button type="button" className="btn cbRSearchButton">&nbsp;</button>
                                        </li>
                                        <li>
                                            <button type="button" onClick={() => { this.onAddRecipes() }} className="btn recipeConfirmBtn">Confirm</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="reBodyWrapper withSearchBar mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn alignTop">
                                                {dataList.map((item, i) => {
                                                    return (
                                                        <div className="cbAddRecipe" key={i}>
                                                            <ul>
                                                                <li><img src={item.ID === 0 ? require("../../../../../images/recipecreation/Auto.png") : item.IMAGE_S3_SIGNED_URL ? item.IMAGE_S3_SIGNED_URL : require("../../../../../images/recipecreation/Default Recipe.bmp")} alt="" /></li>
                                                                <li>{item.LIBRARY_NAME}</li>
                                                                <li>{item.CREATED_ON}</li>
                                                                <li>
                                                                    <div className={libraryAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"} >
                                                                        <input id={item.ID} name="RecipesList" type="checkbox" value={item.checked} checked={item.checked} onChange={(e) => this.onSelectLibrary(e, item)} />
                                                                        <label htmlFor={item.ID}></label>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    )
                                                })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment >
        )
    }
}
export default injectIntl(GarlandCookbookAddRecipeItem)