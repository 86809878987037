import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import axios from 'axios';
import Jimp from 'jimp';
import $ from 'jquery';
import { MenuUploadAPI, BaseService } from '../../../../../service/api';
import { CUSTOMER_ID } from '../../../../../utils/appConstants';
import { v1 as uuidv1 } from 'uuid';
import LibraryListPopup from '../../libraryListPopup';
import { Cropper } from '../../../../controls/cropper';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'react-image-crop/dist/ReactCrop.css';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
const { v4: uuidv4 } = require('uuid')

class GarlandCookbookAddMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            menuName: "",
            categoryImage: null,
            srcfile: {},
            showCropPopup: false,
            isEditManu: localStorage.getItem("isEditCategory") || false,
            isImageChanged: false,
            isImageDeleted: false,
            cropWidth: 100,
            cropHeight: 100,
            unit: '°F',
            grill_Plate_limit: 'Off',
            grillPlateLimitCheck: false,
            grillPlateLimitValue: '',
            grill_Front_limit: 'Off',
            grillFrontLimitCheck: false,
            grillFrontLimitValue: '',
            upper_Platen_limit: 'Off',
            upperPlatenLimitCheck: false,
            upperPlatenLimitValue: '',
            flat_Cooking: 'No',
            isChanged: false,
            editedCookBookDatas:{}
        }
    }    

    componentDidMount = async () => {
        if (this.state.isEditManu) {
            let EditCookBookData = JSON.parse(localStorage.getItem("EditCategoryData"));
            this.setState({
                menuName: EditCookBookData.categoryName,
                imageDatab64: EditCookBookData.thumbnailData,
                unit: EditCookBookData.unit,
                flat_Cooking: EditCookBookData.flat_Cooking,
                grill_Plate_limit: EditCookBookData.grill_Plate_limit,
                grillPlateLimitCheck: EditCookBookData.grillPlateLimitCheck,
                grillPlateLimitValue: EditCookBookData.grillPlateLimitValue,
                grill_Front_limit: EditCookBookData.grill_Front_limit,
                grillFrontLimitCheck: EditCookBookData.grillFrontLimitCheck,
                grillFrontLimitValue: EditCookBookData.grillFrontLimitValue,
                upper_Platen_limit: EditCookBookData.upper_Platen_limit,
                upperPlatenLimitCheck: EditCookBookData.upperPlatenLimitCheck,
                upperPlatenLimitValue: EditCookBookData.upperPlatenLimitValue,
                editedCookBookDatas:EditCookBookData,
            })
        }
        document.body.style.backgroundColor = "#000000";
        $('#ImportButton').on('click', function (e) {
            $('#fileInput').trigger('click');
            $('#ConfirmationOfImportModal').modal('hide');
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
        localStorage.removeItem("isEditCategory")
        localStorage.removeItem("EditCategoryData")
        this.props.history.push({
            pathname: "/garlandCookbookEditor",
            isEdit: false,
            previouspath: "/cookbookAddCategory"
        });
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    manuNameChange = (e) => {
        if (e.target.value.trim().length > 0) {
            if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
                this.setState({
                    menuName: e.target.value,
                    isChanged: true
                });
            }
        }
    }

    OnDiscard = () => {
        this.props.history.push({
            pathname: "/garlandCookbookEditor",
            isEdit: false,
            previouspath: "/garlandCookbookAddMenu"
        });
    }
    OnCancel = () => {
        this.setState({
            showDiscardPopup: false
        })
    }

    backOnClick = () => {
        const { isChanged, isImageChanged } = this.state;
        if (isChanged || isImageChanged) {
            this.setState({ showDiscardPopup: true })
        } else {
            this.props.history.push({
                pathname: "/garlandCookbookEditor",
                categoryName: this.state.menuName,
                categoryImage: this.state.categoryImage,
                srcfile: this.state.srcfile,
                imageData: this.state.imageData,
                imageDataBuffer: this.state.imageDataBuffer,
                imageDatab64: this.state.imageDatab64,
                imageThumbnailBuffer: this.state.imageThumbnailBuffer,
                previouspath: "/garlandCookbookAddMenu",
                isEdit: this.state.isEdit,
                isImageChanged: this.state.isImageChanged
            });
        }
    }

    processNext = async () => {        
        if (!this.state.imageFileFormat) {
            await this.addOrUpdateCookBookDet();
        } else {
            await this.getImageS3Url();
        }
    }

    addOrUpdateCookBookDet = (imagefilekey, UploadedImages3url) => {
        let { isImageChanged, unit, flat_Cooking, grill_Plate_limit, grillPlateLimitCheck, grillPlateLimitValue, grill_Front_limit, grillFrontLimitCheck, grillFrontLimitValue } = this.state;
        let { upper_Platen_limit, upperPlatenLimitCheck, upperPlatenLimitValue,menuName,editedCookBookDatas } = this.state;        
        let cookBookDetails = localStorage.getItem("resourceDetails");
        let cookBookDetailsObj = JSON.parse(cookBookDetails);               
        let category = {};

        if (this.state.isEditManu) {
            let index = cookBookDetailsObj.findIndex(e => e.ID === editedCookBookDatas.ID);
            category = cookBookDetailsObj[index]
        }
        if (isImageChanged) {
            category.imageFileName = imagefilekey;
            category.imageFileFormat = ".png";
            category.imageFileSize = this.state.imageFileSize;
            category.imageS3Url = UploadedImages3url;
            category.imageData = "";
            category.thumbnailData = this.state.imageThumbnailb64;
        }
        category.categoryName = menuName ? menuName.trim() : "";
        category.type = "Category";
        category.parentID = "";
        category.selected = false;
        category.unit = unit;
        category.grill_Plate_limit = grill_Plate_limit;
        category.grillPlateLimitCheck = grillPlateLimitCheck;
        category.grillPlateLimitValue = grillPlateLimitValue;
        category.grill_Front_limit = grill_Front_limit;
        category.grillFrontLimitCheck = grillFrontLimitCheck;
        category.grillFrontLimitValue = grillFrontLimitValue;
        category.upper_Platen_limit = upper_Platen_limit;
        category.upperPlatenLimitCheck = upperPlatenLimitCheck;
        category.upperPlatenLimitValue = upperPlatenLimitValue;
        category.flat_Cooking = flat_Cooking;
        if (this.state.isEditManu) {
            let index = cookBookDetailsObj.findIndex(e => e.ID === editedCookBookDatas.ID);
            cookBookDetailsObj.splice(index, 1, category);
        } else {
            category.ID = uuidv1();
            cookBookDetailsObj.push(category);
        }
        localStorage.setItem("resourceDetails", JSON.stringify(cookBookDetailsObj));
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: true,
                message: this.state.isEditManu ? "KC2287" : "KC2286"
            }
        });
    }

    getImageS3Url = async () => {
        this.setState({
            loaderIconVisible: true
        });
        let imagefilekey = uuidv4();
        imagefilekey= imagefilekey.replace(/-/g, '');
        let headerpresigned = {
            'Content-Type': 'application/json',
            'customerid': CUSTOMER_ID,
            'imagefilekey': imagefilekey + '.png',
            'jsonfilekey': "",
            'audiofilekey': "",
            'isthumbnail': true
        }

        return new Promise((resolve, reject) => {
            let token = localStorage.getItem('Access-Token');
            headerpresigned.Authorization = token;
            axios({
                url: BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl,
                method: "GET",
                headers: headerpresigned,
                data: {}
            }).then(response => {
                let { imagePresignedURL, thumbnailPresignedURL } = response.data.presignedURLs
                let imageurl = imagePresignedURL;
                let thumbnailurl = thumbnailPresignedURL;

                let UploadedImages3url = "";
                if (imageurl) {
                    UploadedImages3url = imageurl.split('?');
                    UploadedImages3url = UploadedImages3url[0];
                }
                var xhrthumb = new XMLHttpRequest();
                xhrthumb.open("PUT", thumbnailurl);
                xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
                xhrthumb.onloadend = (res) => {
                    if (res.target.status == 200) {
                        var xhrimage = new XMLHttpRequest();
                        xhrimage.open("PUT", imageurl);
                        xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
                        xhrimage.onloadend = (res) => {
                            if (res.target.status == 200) {
                                this.addOrUpdateCookBookDet(imagefilekey, UploadedImages3url);
                            }
                        };
                        xhrimage.onerror = (err) => {
                            reject(err);
                        };
                        xhrimage.send(this.state.imageDataBuffer);
                    }
                };
                xhrthumb.onerror = (err) => {
                    reject(err);
                };
                xhrthumb.send(this.state.imageThumbnailBuffer);
            }).catch(err => {
                reject(err);
            });
        });
    }

    validateAllInputs = async () => {
        let { unit, grill_Plate_limit, grill_Front_limit, upper_Platen_limit } = this.state;

        if (unit === "°F" && upper_Platen_limit !== "Off") {
            if (Number(upper_Platen_limit) >= 149 && Number(upper_Platen_limit) <= 450) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Upper Platen Temp value between 149 to 450 C.'
                    }
                });
                return;
            }
        }

        if (unit === "°F" && grill_Plate_limit !== "Off") {
            if (Number(grill_Plate_limit) >= 149 && Number(grill_Plate_limit) <= 400) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Grill Plate Temp limit value between 149 to 400 F.'
                    },
                    loaderIconVisible: false,
                });
                return;
            }
        }
        if (unit === "°F" && grill_Front_limit !== "Off") {
            if (Number(grill_Front_limit) >= 149 && Number(grill_Front_limit) <= 400) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Grill Front Temp limit value between 149 to 400 F.'
                    }
                });
                return;
            }
        }
        if (unit === "°C" && grill_Plate_limit !== "Off") {
            if (Number(grill_Plate_limit) >= 65 && Number(grill_Plate_limit) <= 204) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Grill Plate Temp limit value between 65 to 204 C.'
                    }
                });
                return;
            }
        }
        if (unit === "°C" && grill_Front_limit !== "Off") {
            if (Number(grill_Front_limit) >= 65 && Number(grill_Front_limit) <= 204) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Grill Front Temp limit value between 65 to 204 C.'
                    }
                });
                return;
            }
        }

        if (unit === "°C" && upper_Platen_limit !== "Off") {
            if (Number(upper_Platen_limit) >= 65 && Number(upper_Platen_limit) <= 232) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Upper Platen Temp value between 65 to 232 C.'
                    }
                });
                return;
            }
        }
        return true;
    }

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                loaderIconVisible: true
            });
            let imageData = this.state.imageData ? this.state.imageData : {};
            let fileSize = event.target.files[0].size;
            fileSize = (fileSize / 1048576).toFixed(2);

            let fileName = event.target.files[0].name;
            fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
            let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
            let fileNamewithOutExt = fileName.replace('.' + fileext, "");
            let filelength = fileName.length;

            imageData.imagefileName = fileName;
            imageData.imagefileext = fileext;
            imageData.imagefileNamewithOutExt = fileNamewithOutExt;
            imageData.imagefileSize = fileSize;
            imageData.imagefilelength = filelength;

            let resolutionofimageVideo = 0;

            var FileUploadThumbnail = require('../../../../../utils/fileThumnailProcesser');
            let md5 = require('md5');

            if (fileSize) {
                var width;
                var height;
                let srcfile = event.target.files[0];
                if (fileext === "png" || fileext === "PNG") {
                    var reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = e.target.result;
                        let imageDataBuffer = Buffer.from(e.target.result.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
                        imageData.crc = md5(imageDataBuffer, "base64");
                        image.onload = (img) => {
                            width = img.target.width;
                            height = img.target.height;
                            resolutionofimageVideo = width + " x " + height;
                            if ((width > 128 || height > 128) && (fileext !== "svg" && fileext !== "SVG" && fileext !== "gif" && fileext !== "GIF" && fileext !== "bmp" && fileext !== "BMP")) {
                                this.setState({
                                    loaderIconVisible: false,
                                    showCropPopup: true,
                                    src: reader.result,
                                    cropWidth: width < height ? width : height,
                                    cropHeight: width < height ? width : height,
                                    imageData: imageData
                                });
                                $('#popupTrigger').trigger('click');
                            } else {
                                imageData.resolution = resolutionofimageVideo;
                                imageData.category = 'picture';
                                if (srcfile) {
                                    let thumhnail = new FileUploadThumbnail({
                                        maxWidth: 80,
                                        maxHeight: 80,
                                        file: srcfile,
                                        onSuccess: (srcthumb) => {
                                            srcthumb = srcthumb.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                            let imageThumbnailBuffer = Buffer.from(srcthumb, "base64");
                                            let image = new FileUploadThumbnail({
                                                maxWidth: 128,
                                                maxHeight: 128,
                                                file: srcfile,
                                                onSuccess: (src) => {
                                                    src = src.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                                    let imageDataBuffer = Buffer.from(src, "base64");
                                                    this.setState({
                                                        loaderIconVisible: false
                                                    });
                                                    this.setState({
                                                        imageFileName: fileName,
                                                        imageFileFormat: ".png",
                                                        imageFileSize: fileSize,
                                                        categoryImage: URL.createObjectURL(srcfile),
                                                        srcfile: srcfile,
                                                        imageDataBuffer: imageDataBuffer,
                                                        imageDatab64: src,
                                                        imageData: imageData,
                                                        imageThumbnailBuffer: imageThumbnailBuffer,
                                                        imageThumbnailb64: srcthumb,
                                                        isImageChanged: true
                                                    });
                                                },
                                                onError: function (err) {
                                                    this.setState({
                                                        loaderIconVisible: false
                                                    });
                                                }
                                            }).createThumbnail();
                                        },
                                        onError: function (err) {
                                            this.setState({
                                                loaderIconVisible: false
                                            });
                                        }
                                    }).createThumbnail();
                                }
                            };
                        }
                    }
                }
                else {
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: "Please select png image file."
                        }
                    });
                }
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1249"
                    }
                });
            }
            event.target.value = '';
        }
    }

    closeCropPopup = () => {
        this.setState({
            showCropPopup: false
        });
    }

    async cropSubmit(croppedImage) {
        $('#imageResizeModal').modal('hide');
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });

        let croppedImageType = croppedImage.type.toLowerCase() === "jpg" ? "jpeg" : croppedImage.type.toLowerCase();
        Jimp.read(croppedImage.data)
            .then(image => {
                image.resize(128, 128, (err, image) => {
                    image.getBufferAsync('image/' + croppedImageType).then(imagebuffer => {
                        image.getBase64Async('image/' + croppedImageType).then(imageb64 => {
                            let md5 = require('md5');
                            let fileSize = Buffer.byteLength(imagebuffer);
                            fileSize = (fileSize / 1048576).toFixed(2);
                            _this.state.imageData.resolution = "128 x 128";
                            _this.state.imageData.category = "picture";
                            _this.state.imageData.crc = md5(imagebuffer);
                            _this.state.imageData.fileSize = fileSize;
                            Jimp.read(imagebuffer)
                                .then(thumbnail => {
                                    thumbnail.resize(80, 80, (err, thumbnail) => {
                                        thumbnail.getBufferAsync('image/' + croppedImageType).then(thumbnailbuffer => {
                                            thumbnail.getBase64Async('image/' + croppedImageType).then(thumbnailb64 => {
                                                _this.setState({
                                                    imageFileName: croppedImage.name,
                                                    imageFileFormat: ".png",
                                                    imageFileSize: fileSize,
                                                    categoryImage: null,
                                                    srcfile: imagebuffer,
                                                    imageDataBuffer: imagebuffer,
                                                    imageDatab64: imageb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                                    imageData: _this.state.imageData,
                                                    imageThumbnailBuffer: thumbnailbuffer,
                                                    imageThumbnailb64: thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                                    isImageChanged: true,
                                                    loaderIconVisible: false
                                                });
                                            })
                                        })
                                    })
                                })
                        })
                    })
                })
            })
            .catch(err => {
                console.log(err);
                _this.setState({
                    loaderIconVisible: false
                });
            })
    }

    c2f = (celsius) => {
        return Math.round((1.8 * celsius) + 32);
    }

    f2c = (fahrenheit) => {
        return Math.round((fahrenheit - 32) / 1.8);
    }

    IsAlphaNumeric(e) {
        e.target.value = e.target.value.trim().replace(/[^\d]/g, '');
    }

    render() {
        let { menuName, loaderIconVisible, imageDatab64, error_modal, success_modal, imageData, cropWidth, cropHeight, showDiscardPopup } = this.state;
        let { upper_Platen_limit, upperPlatenLimitCheck, upperPlatenLimitValue } = this.state;
        const { formatMessage } = this.props.intl;
        let { unit, flat_Cooking, grill_Plate_limit, grillPlateLimitCheck, grillPlateLimitValue, grill_Front_limit, grillFrontLimitCheck, grillFrontLimitValue } = this.state;
        let enableSave = menuName && unit !== "";

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658" })}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180" /></Link>
                                    <button type="button" id="popupTrigger" className="displaynone" data-toggle="modal" data-target="#imageResizeModal" onClick={() => { }}>&nbsp;</button>
                                </li>
                                <li>
                                    <li className="selectedModel">ME, MG</li>
                                </li>
                            </ul>
                        </div>
                        <div className="rcDeviceWrapper">
                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} /></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={this.backOnClick} className="btn backButton">&nbsp;</button>
                                    {localStorage.getItem("cookBookFileName") ? localStorage.getItem("cookBookFileName") : ""} - Menu Creation
                                    <button type="button" onClick={() => this.processNext()} className={enableSave ? "btn nextButton" : "btn nextButton disabled"}>&nbsp;</button>
                                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSaveForm">
                                                    <div class="rcCNameDiv">
                                                        <div class="rcCreateFrm">
                                                            <ul>
                                                                <li>
                                                                    <label>Enter Menu Name</label>
                                                                    <input type="text" maxLength="15" className="form-control" value={menuName} onChange={(e) => { this.manuNameChange(e) }} />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="rcCreateFrm titleDiv">
                                                            <ul>
                                                                <li>
                                                                    <label>Pre-heat Settings</label>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="rcCreateFrm">
                                                            <ul>
                                                                <li>
                                                                    <label>Unit</label>
                                                                    <select className="form-control minWidth" value={unit}
                                                                        onChange={(e) => {
                                                                            const { grill_Plate_limit, grill_Front_limit, upper_Platen_limit } = this.state;

                                                                            if (upper_Platen_limit && upper_Platen_limit !== "Off" && upper_Platen_limit !== 0 && upper_Platen_limit !== "") {
                                                                                const upperValueConverted = e.target.value === "°C" ? this.f2c(upper_Platen_limit) : this.c2f(upper_Platen_limit);
                                                                                this.setState({ upper_Platen_limit: upperValueConverted, upperPlatenLimitValue: upperValueConverted });
                                                                            }

                                                                            if (grill_Plate_limit && grill_Plate_limit !== "Off" && grill_Plate_limit !== 0 && grill_Plate_limit !== "") {
                                                                                const valueConverted = e.target.value === "°C" ? this.f2c(grill_Plate_limit) : this.c2f(grill_Plate_limit);
                                                                                this.setState({ grill_Plate_limit: valueConverted, grillPlateLimitValue: valueConverted });
                                                                            }
                                                                            if (grill_Front_limit && grill_Front_limit !== "Off" && grill_Front_limit !== 0 && grill_Front_limit !== "") {
                                                                                const hotValueConverted = e.target.value === "°C" ? this.f2c(grill_Front_limit) : this.c2f(grill_Front_limit);
                                                                                this.setState({ grill_Front_limit: hotValueConverted, grillFrontLimitValue: hotValueConverted });
                                                                            }
                                                                            this.setState({ unit: e.target.value, isChanged: true })
                                                                        }}>
                                                                        {["°F", "°C"].map((unit, idx) => {
                                                                            return (
                                                                                <option key={idx} value={unit}>{unit}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </li>

                                                                <li>
                                                                    <label>Flat Cooking</label>
                                                                    <select className="form-control minWidth" value={flat_Cooking} 
                                                                        onChange={(e) => {
                                                                            this.setState({ flat_Cooking: e.target.value, isChanged: true })
                                                                            if (e.target.value === "Yes") {
                                                                                this.setState({
                                                                                    upper_Platen_limit: 'Off',
                                                                                    upperPlatenLimitCheck: false,
                                                                                    upperPlatenLimitValue: '',
                                                                                },()=>{})
                                                                            }
                                                                        }}>
                                                                        <option value="Yes">Yes</option>
                                                                        <option value="No">No</option>
                                                                    </select>
                                                                </li>
                                                            </ul>
                                                            <ul>
                                                                <li className='formIncolm'>
                                                                    <label>Upper Platen Temp</label>
                                                                    <div className= { flat_Cooking === "No" ? "dropdown setValDropdown" : "dropdown setValDropdown disabled" }>
                                                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButtonUpper" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            {upper_Platen_limit}
                                                                        </button>
                                                                        <div className="dropdown-menu topView dropdown-menu-left" aria-labelledby="dropdownMenuButtonUpper">
                                                                            <div className="setValD">
                                                                                <input type="number" value={upperPlatenLimitValue} className="form-control normalWidth"
                                                                                    onKeyUp={(e) => this.IsAlphaNumeric(e)} 
                                                                                    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                                    onChange={(e) => {
                                                                                        const maxValue = unit === "°F" ? 450 : 232;
                                                                                        if (e && e.target && e.target.value && (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
                                                                                            return;
                                                                                        }
                                                                                        const isPassed = unit === "°F" ? Number(e.target.value) >= 149 && Number(e.target.value) <= 450 : Number(e.target.value) >= 65 && Number(e.target.value) <= 232;
                                                                                        this.setState({
                                                                                            upperPlatenLimitValue: e.target.value,
                                                                                            upperPlatenLimitCheck: isPassed,
                                                                                            isChanged: true
                                                                                        })
                                                                                    }
                                                                                    } />
                                                                                <div className={`customCheckbox ${unit === "°F" ? upperPlatenLimitValue >= 149 && upperPlatenLimitValue <= 450 ? "" : "disabled" : upperPlatenLimitValue >= 65 && upperPlatenLimitValue <= 232 ? "" : "disabled"}`}>
                                                                                    <input id="TempSetU" name="TempSetU" type="checkbox" checked={upperPlatenLimitCheck} value="TempSetU"
                                                                                        onChange={() => {
                                                                                            if (!upperPlatenLimitValue || upperPlatenLimitValue === "") {
                                                                                                return;
                                                                                            }
                                                                                            this.setState({
                                                                                                upperPlatenLimitCheck: upperPlatenLimitValue && upperPlatenLimitValue !== "" ? true : false,
                                                                                                isChanged: true,
                                                                                                upper_Platen_limit: upperPlatenLimitValue && upperPlatenLimitValue !== "" ? upperPlatenLimitValue : "Off"
                                                                                            });
                                                                                        }
                                                                                        } />
                                                                                    <label for="TempSetU"></label>
                                                                                </div>
                                                                            </div>
                                                                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => this.setState({ upper_Platen_limit: 'Off', upperPlatenLimitCheck: false, upperPlatenLimitValue: '', isChanged: true })}>Off</a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className='form31colm'>
                                                                    <label>Grill Plate Temp</label>
                                                                    <div className= "dropdown setValDropdown">
                                                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButtonCold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            {grill_Plate_limit}
                                                                        </button>
                                                                        <div className="dropdown-menu topView dropdown-menu-left" aria-labelledby="dropdownMenuButtonCold">
                                                                            <div className="setValD">
                                                                                <input type="number" value={grillPlateLimitValue} className="form-control normalWidth"
                                                                                    onKeyUp={(e) => this.IsAlphaNumeric(e)} 
                                                                                    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                                    onChange={(e) => {
                                                                                        const maxValue = unit === "°F" ? 400 : 204;
                                                                                        if (e && e.target && e.target.value && (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
                                                                                            return;
                                                                                        }
                                                                                        const isPassed = unit === "°F" ? Number(e.target.value) >= 149 && Number(e.target.value) <= 400 : Number(e.target.value) >= 65 && Number(e.target.value) <= 204;
                                                                                        this.setState({
                                                                                            grillPlateLimitValue: e.target.value,
                                                                                            grillPlateLimitCheck: isPassed,
                                                                                            isChanged: true
                                                                                        })
                                                                                    }
                                                                                    } />
                                                                                <div className={`customCheckbox ${unit === "°F" ? grillPlateLimitValue >= 149 && grillPlateLimitValue <= 400 ? "" : "disabled" : grillPlateLimitValue >= 65 && grillPlateLimitValue <= 204 ? "" : "disabled"}`}>
                                                                                    <input id="TempSetV" name="TempSetV" type="checkbox" checked={grillPlateLimitCheck} value="TempSetV"
                                                                                        onChange={() => {
                                                                                            if (!grillPlateLimitValue || grillPlateLimitValue === "") {
                                                                                                return;
                                                                                            }
                                                                                            this.setState({
                                                                                                grillPlateLimitCheck: grillPlateLimitValue && grillPlateLimitValue !== "" ? true : false,
                                                                                                isChanged: true,
                                                                                                grill_Plate_limit: grillPlateLimitValue && grillPlateLimitValue !== "" ? grillPlateLimitValue : "Off"
                                                                                            });
                                                                                        }
                                                                                        } />
                                                                                    <label for="TempSetV"></label>
                                                                                </div>
                                                                            </div>
                                                                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => this.setState({ grill_Plate_limit: 'Off', grillPlateLimitCheck: false, grillPlateLimitValue: '', isChanged: true })}>Off</a>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                <li className='form3colm'>
                                                                    <label>Grill Front Temp</label>
                                                                    <div className="dropdown setValDropdown">
                                                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButtonHot" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            {grill_Front_limit}
                                                                        </button>
                                                                        <div className="dropdown-menu topView dropdown-menu-left" aria-labelledby="dropdownMenuButtonHot">
                                                                            <div className="setValD">
                                                                                <input type="number" value={grillFrontLimitValue} className="form-control normalWidth"
                                                                                    onKeyUp={(e) => this.IsAlphaNumeric(e)} 
                                                                                    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                                    onChange={(e) => {
                                                                                        const maxValue = unit === "°F" ? 400 : 204;
                                                                                        if (e && e.target && e.target.value && (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
                                                                                            return;
                                                                                        }
                                                                                        const isPassed = unit === "°F" ? Number(e.target.value) >= 149 && Number(e.target.value) <= 400 : Number(e.target.value) >= 65 && Number(e.target.value) <= 204;
                                                                                        this.setState({
                                                                                            grillFrontLimitValue: e.target.value,
                                                                                            grillFrontLimitCheck: isPassed,
                                                                                            isChanged: true
                                                                                        })
                                                                                    }
                                                                                    } />
                                                                                <div className={`customCheckbox ${unit === "°F" ? grillFrontLimitValue >= 149 && grillFrontLimitValue <= 400 ? "" : "disabled" : grillFrontLimitValue >= 65 && grillFrontLimitValue <= 204 ? "" : "disabled"}`}>
                                                                                    <input id="TempSetHot" name="TempSetHot" type="checkbox" checked={grillFrontLimitCheck} value="TempSetHot"
                                                                                        onChange={() => {
                                                                                            if (!grillFrontLimitValue || grillFrontLimitValue === "") {
                                                                                                return;
                                                                                            }
                                                                                            this.setState({
                                                                                                grillFrontLimitCheck: grillFrontLimitValue && grillFrontLimitValue !== "" ? true : false,
                                                                                                isChanged: true,
                                                                                                grill_Front_limit: grillFrontLimitValue && grillFrontLimitValue !== "" ? grillFrontLimitValue : "Off"
                                                                                            });
                                                                                        }
                                                                                        } />
                                                                                    <label for="TempSetHot"></label>
                                                                                </div>
                                                                            </div>
                                                                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => this.setState({ grill_Front_limit: 'Off', grillFrontLimitCheck: false, grillFrontLimitValue: '', isChanged: true })}>Off</a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>

                                                    <div className="rcCimportImageDiv editActive">
                                                        <div className="importImage">
                                                            {imageDatab64 && imageDatab64 !== "" ?
                                                                <button type="button" className="btn cancelButton"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            imageDatab64: '',
                                                                            imageData: {}
                                                                        })
                                                                    }}>&nbsp;</button>
                                                                : null}
                                                            <input type="file" accept="image/png" ref={input => this.inputElement = input} onChange={this.onImageChange} className="fileInputField" id="menuUpdate" />
                                                            <a href="javascript:void(0)" className="menuBrowse" ><img src={imageDatab64 ? `data:${'image/png'};base64,${imageDatab64}` : require("../../../../../images/recipecreation/photosPictures.png")} onClick={() => this.inputElement.click()} /> </a>
                                                        </div>
                                                        <label><FormattedMessage id="KC1229" /></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                {/* <!-- Confirmation Modal --> */}
                <div class="modal fade selectRecipeModal" data-keyboard="true" data-backdrop="static" id="selectRecipeModal" tabindex="-1" role="dialog" aria-labelledby="selectRecipeModalLabel">
                    <div class="modal-dialog modal-lg">
                        <LibraryListPopup libraryType={'CookBook'} />
                    </div>
                </div>
                {/* <!-- //Confirmation --> */}
                {/* <!-- imageResizeModal --> */}
                <div class="modal fade imageResizeModal" data-keyboard="true" data-backdrop="static" id="imageResizeModal" tabindex="-1" role="dialog" aria-labelledby="infoModalLabel">
                    <div class="modal-dialog modal-lg imageResizeModal">
                        <div class="modal-content">
                            <div class="modal-header modal-header-danger">
                                <FormattedMessage id="KC1234" /> <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCropPopup}>&nbsp;</button>
                            </div>
                            <div class="modal-body">
                                <form class="imageResizeForm">
                                    <Cropper src={this.state.src} OnCrop={(croppedImageUrl) => this.cropSubmit(croppedImageUrl)} fileName={imageData ? imageData.imagefileName : ""} fileFormat={imageData ? imageData.imagefileext : ""} cropWidth={cropWidth} cropHeight={cropHeight} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //imageResizeModal --> */}
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218" />
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={this.OnCancel} data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={this.OnDiscard} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default injectIntl(GarlandCookbookAddMenu)