import {ReportManagement} from '../../service/api';
import http from '../../service/httpService';
export const TOTAL_COOK_COUNT_DATA_REQUEST = 'TOTAL_COOK_COUNT_DATA_REQUEST';
export const TOTAL_COOK_COUNT_DATA_SUCCESS = 'TOTAL_COOK_COUNT_DATA_SUCCESS';
export const TOTAL_COOK_COUNT_DATA_FAIL = 'TOTAL_COOK_COUNT_DATA_FAIL';

export function getTotalCookCountData(type, fromdate, todate, deviceId, brandId, widgetId) {
    let url ;
    let filterType;
    switch (type) {
        case 'Week':
            filterType='weekly'; 
            break; 
        case 'Year':
            filterType='yearly';            
            break; 
        case 'Day':            
            filterType='daily';
            break;
        case 'Quarter':
            filterType='quarterly';            
            break; 
        case 'Date Range':
            filterType = 'custom';
            break; 
        default: 
            filterType = 'monthly';              
      }    
    
    url = `${ReportManagement.widgetAPI + '/' + widgetId + '/' + brandId}`;

    return dispatch => {
        dispatch({
            type: TOTAL_COOK_COUNT_DATA_REQUEST
        })
        
        let header;
        if(localStorage.getItem("brandName")==="MERRYCHEF" || localStorage.getItem("brandName") === "FRYMASTER" || localStorage.getItem("brandName") === "GARLAND")
        {   
            if (filterType === 'weekly')
            {
                header = {
                    'Content-Type':'application/json',            
                    'unitid' : deviceId,  
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType, 
                    'showtrend':"true",
                }; 
            }else if (filterType === 'custom')
            {
                header = {
                    'Content-Type':'application/json',            
                    'unitid' : deviceId,                                
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType,             
                    'showtrend':"false",
                }; 
            }
            else{
                header = {
                    'Content-Type':'application/json',            
                    'unitid' : deviceId,            
                    'reqdate' : fromdate,
                    'filterType' : filterType,  
                    'showtrend':"true",
                }; 
            }
        }else{
            if (filterType === 'weekly' || filterType === 'custom')
            {
                header = {
                    'Content-Type' : 'application/json',
                    'unitid' : deviceId,
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filtertype' : filterType,
                    'showtrend' : "true"
                }; 
            }
            else
            {
                header = {
                    'Content-Type' : 'application/json',
                    'unitid' : deviceId,
                    'reqDate' : fromdate,
                    'filtertype' : filterType,
                    'showtrend' : "true"
                };
            } 
        }
        http.get(url,
                {
                  headers: header,
                  data:{}
                })
            .then(response => {
                dispatch({
                    type: TOTAL_COOK_COUNT_DATA_SUCCESS,
                    totalCookCountData: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: TOTAL_COOK_COUNT_DATA_FAIL,
                    error
                })
            })
    }
}
