import React, { Component, PureComponent } from 'react';
import http from '../../../service/httpService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ReportManagement } from '../../../service/api';
import { format } from 'date-fns';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import $, { data } from 'jquery';
import { getCurrentUnit } from '../../../actions/organizationentity/entityactions';
import { FormattedMessage } from 'react-intl';

var moment = require('moment');
am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);
var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var startOfQuarter = require('date-fns/start_of_quarter');
var endOfWeek = require('date-fns/end_of_week');
var endOfMonth = require('date-fns/end_of_month');
var endOfQuarter = require('date-fns/end_of_quarter');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let currentUnit;
let unitID = "";
let brandID = "";
let unitModelName = "";
const Colors = ["#FFF", "#009c43", "#efb302", "#dc0026"];
class EquipmentEfficiencyChart extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			equipmentChartInfo: [],
			total_unit_count: 0,
			loading: true,
			filterType: "Month",
			trayUtilizationStatus: "",
			excellentStatus: "",
			goodStatus: "",
			poorStatus: "",
			sd: '',
			ed: '',
			startDate: '',
			endDate: '',
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			widgetShow: true,
			legendShow: true,
			datapickflag: false
		}
		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount() {
		let chart = am4core.create("equipmentEfficiencyBarChartdiv", am4charts.XYChart);
		this.getReportData(this.state.filterType, "", "");
	}

	componentDidUpdate = async(prevProp, prevState) => {
		if(this.props.globalDropdownFilter === 'Date Range' && this.props.disableDatePicker){
			return;
		}
		if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.filterType !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
			this.setState({ filterType: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
			this.handleEquipmentEfficiency(this.props.globalDropdownFilter);
			await this.props.handleDropdownChangeEnd();
		} else {
			if (this.props.globalDropdownFilter === 'Date Range'  && this.props.isGlobalDropdownChange) {
				if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
					this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
					await this.props.handleDropdownChangeEnd();
				}
				if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
					this.handleChangeStart(this.props.globalDropdownFilterStartDate);
					await this.props.handleDropdownChangeEnd();
				}
			}
		}
	}

	toggleDatePicker(e) {
		this.setState({ filterType: "Date Range" })
	}

	handleChangeStart(date) {
		if(this.state.startDate === date){
			return;
		}
		this.setState({
			startDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			this.handleEquipmentEfficiency(selectedtext);
		});
	}

	handleChangeEnd(date) {
		if(this.state.endDate === date){
			return;
		}
		this.setState({
			endDate: date,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			this.handleEquipmentEfficiency(selectedtext);
		});
	}

	getReportData(text, startDate, date) {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		currentUnit.PRODUCT_NAME = currentUnit.PRODUCT_NAME ? currentUnit.PRODUCT_NAME : localStorage.getItem('modelName');
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
		unitModelName = currentUnit.PRODUCT_NAME;

		const header = this.getHeaders(text, startDate, date, unitID, unitModelName);
		let url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + brandID}`;
		this.getData(url, header).then(response => {
			let trayUtilization = response.equipmentChartInfo.trayUtiliztionText ? response.equipmentChartInfo.trayUtiliztionText : "";
			let excellentValue = response.equipmentChartInfo.excellentText ? response.equipmentChartInfo.excellentText : "";
			let goodValue = response.equipmentChartInfo.goodText ? response.equipmentChartInfo.goodText : "";
			let poorValue = response.equipmentChartInfo.poorText ? response.equipmentChartInfo.poorText : "";
			this.setState({
				trayUtilizationStatus: trayUtilization,
				excellentStatus: excellentValue,
				goodStatus: goodValue,
				poorStatus: poorValue
			})
			this.renderChartData(text, response.equipmentChartInfo);
		}).catch(err => {
			//this.renderChartData(err);
		});
	}

	onDropDownHandler(e) {
		const { nodeText:text } = e;
		this.setState({ filterType: text, datapickflag: text === "Date Range", startDate:'', endDate:''})
		this.handleEquipmentEfficiency(text);
	}

	handleEquipmentEfficiency(text) {
		this.setState({ filterType: text });
		const { startDate, endDate } = this.state;

		if (text === "Date Range") {
			if (startDate !== "" && endDate !== "") {
				this.getReportData(text, startDate, endDate);
			}
		}
		else {
			this.getReportData(text, "", "");
		}
	}

	getHeaders(type = 'Month', startDateFormatted = '', endDateFormatted = '', unitID = '', unitModelName = '') {
		let filterType;
		switch (type) {
			case 'Week':
				filterType = 'weekly';
				break;
			case 'Year':
				filterType = 'yearly';
				break;
			case 'Day':
				filterType = 'daily';
				break;
			case 'Quarter':
				filterType = 'quarterly';
				break;
			case 'Date Range':
				filterType = 'custom';
				break;
			default:
				filterType = 'monthly';
		}

		let header;

		if (filterType === 'custom' || filterType === 'weekly') {
			if (type === 'Week') {
				startDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
				endDateFormatted = format(todaysDate, 'YYYY-MM-DD');

				var startDateMonth = getMonth(new Date(startDateFormatted));
				var endDateMonth = getMonth(new Date(endDateFormatted));

				if (startDateMonth !== endDateMonth) {
					startDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
				}
			}
			else {
				startDateFormatted = format(startDateFormatted, 'YYYY-MM-DD');;
				endDateFormatted = format(endDateFormatted, 'YYYY-MM-DD');;
			}

			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'startdate': startDateFormatted,
				'enddate': endDateFormatted,
				'filterType': filterType,
				'showtrend': "false",
				'model': unitModelName,
			};

		}
		else {
			startDateFormatted = format(todaysDate, 'YYYY-MM-DD');

			// currentUnit = this.props.currentUnit;
			// modelNo = currentUnit.PRODUCT_NAME;

			header = {
				'Content-Type': 'application/json',
				'unitId': unitID,
				'reqdate': startDateFormatted,
				'filterType': filterType,
				'showtrend': "false",
				'model': unitModelName,
			};
		}

		return header;
	}


	getData(url, header) {

		return new Promise((resolve, reject) => {
			http
				.get(url,
					{
						headers: header,
						data: {}
					})
				.then(response => {
					resolve({
						equipmentChartInfo: response.data
					})
				})
				.catch(error => {
					let { response } = error;
					if (response && response.data && response.data.body) {
						this.setState({ widgetShow: error.response.data.body.widgetShow })
					}
					reject({
						equipmentChartInfo: []
					})
				});
		});
	}

	renderChartData = (type, data) => {
		let response = data.Data && data.Data.length > 0 ? data.Data : [];
		$("#panChartlegend").addClass("legendSectionEquip");
		let responseExcellentText = data.excellentText;
		let responseGoodText = data.goodText;
		let responsePoorText = data.poorText;
		let responseTrayUtilizationText = data.trayUtiliztionText;
		let responseTrayValue = data.tray;

		if (type !== "Day") {
			let dateFormat = (type === "Week" || type === "Month" || type === "Custom") ? "D" : (type === "Year" || type === "Quarter") ? "MMMM" : "";
			if (response.length > 0) {
				response.map(item => {
					let day = item.Key.split('-')[2];
					let month = item.Key.split('-')[1];
					day = day && day.length === 1 ? '0' + day : day;
					month = month && month.length === 1 ? '0' + month : month;
					let date = item.Key.split('-')[0] + '-' + month + '-' + day;
					item.Key = (moment(date).format(dateFormat));
					item.alpha = 1;
					item.Excellent = item.Excellent === 0 ? null : item.Excellent;
					item.Good = item.Good === 0 ? null : item.Good;
					item.Poor = item.Poor === 0 ? null : item.Poor;
				});
			}
			else {
				response = [];
			}
		}
		else {
			if (response.length > 0) {
				if (localStorage.getItem("timeFormat") === "24"){
					response.map(item => { item.Key = (item.Key === 24 ? "00" : item.Key); item.alpha = 1 });
				} else {
					response.map(item => { item.Key = (item.Key === "12" ? "12 pm" : item.Key === "24" ? "12 am" : item.Key > 12 ? item.Key - 12 + " pm" : item.Key + " am"); item.alpha = 1 });
				}
			}
			else {
				response = [];
			}
		}

		let chart = am4core.create("equipmentEfficiencyBarChartdiv", am4charts.XYChart);
		if (response && response.length > 0) {
			this.setState({ legendShow: true });
			chart.seriesContainer.zIndex = -1;
			chart.data = response;
			this.chart = chart;
			chart.responsive.enabled = true;
			chart.colors.list = [am4core.color("#009c43"), am4core.color("#efb302"), am4core.color("#dc0026")];

			// Create axes
			let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
			categoryAxis.dataFields.category = "Key";
			categoryAxis.renderer.grid.template.location = 0;
			categoryAxis.renderer.minGridDistance = (type === "Year" || type === "Quarter") ? 60 : 10;
			categoryAxis.renderer.labels.template.disabled = true;
			categoryAxis.renderer.grid.template.disabled = true;
			categoryAxis.renderer.inside = false;
			categoryAxis.renderer.line.strokeOpacity = 1;
			categoryAxis.renderer.line.strokeWidth = 0.5;
			categoryAxis.renderer.line.stroke = am4core.color("#FFF");

			let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
			valueAxis.renderer.labels.template.disabled = true;
			valueAxis.renderer.grid.template.disabled = true;
			valueAxis.min = 0;
			valueAxis.renderer.grid.template.strokeOpacity = 0.5;

			//Configure Ticks
			if (chart.data.length > 0) {
				categoryAxis.renderer.ticks.template.disabled = false;
				categoryAxis.renderer.ticks.template.length = 5;
				categoryAxis.renderer.ticks.template.strokeOpacity = 1;
				categoryAxis.renderer.ticks.template.stroke = am4core.color("#d9d9d9");
				categoryAxis.renderer.ticks.template.strokeWidth = 1;
			}

			// Line series
			let series = null;
			// Create series
			function createSeries(field, name, colorcode) {
				// Set up series
				series = chart.series.push(new am4charts.ColumnSeries());
				series.columns.template.width = am4core.percent(80);
				series.name = name;
				series.dataFields.valueY = field;
				series.dataFields.categoryX = "Key";
				series.sequencedInterpolation = true;
				// Make it stacked
				series.stacked = true;
				// Configure columns tooltip
				series.columns.template.tooltipText = "[bold]{name}[/] : [font-size:14px] {valueY}";
				series.dataItems.template.locations.categoryX = 0.5;
				series.tooltip.pointerOrientation = "vertical";
				series.tooltip.label.adapter.add("text", function (text, target) {
					if (target.dataItem && target.dataItem.valueY == 0) {
						return "";
					}
					else {
						return text;
					}
				});
				return series;
			}

			if (response.length > 0) {
				Object.keys(response[0]).map((item, i) => {
					if (item !== "Key" && item !== "alpha") {
						createSeries(item, item.toString(), Colors[i]);
					}
				}
				)
			}

			categoryAxis.fillRule = function (dataItem) {
				var date = new Date(dataItem.alpha);
				if (dataItem.alpha !== undefined && dataItem.alpha === 1) {
					dataItem.axisFill.visible = true;
				}
				else {
					dataItem.axisFill.visible = false;
				}
			}

			let categoryStarts = null;
			let categoryEnds = null;
			if (type === "Week") {
				var startDateMonth = getMonth(startOfWeek(todaysDate, { weekStartsOn: 1 }));
				var endDateMonth = getMonth(endOfWeek(todaysDate, { weekStartsOn: 1 }));
				var todayMonth = getMonth(todaysDate);

				if (startDateMonth < todayMonth && endDateMonth === todayMonth) {
					categoryStarts = format(startOfMonth(todaysDate), 'D');
					categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
				}
				else if (startDateMonth === todayMonth && endDateMonth > todayMonth) {
					categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
					categoryEnds = format(endOfMonth(todaysDate), 'D');
				}
				else {
					categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
					categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
				}
			}
			else if (type === "Quarter") {
				categoryStarts = format(startOfQuarter(todaysDate), 'MMMM');
				categoryEnds = format(endOfQuarter(todaysDate), 'MMMM');
			}

			// Create category axis range
			var categoryAxis_range = categoryAxis.axisRanges.create();
			categoryAxis_range.category = categoryStarts;
			categoryAxis_range.endCategory = categoryEnds;
			categoryAxis_range.axisFill.fill = am4core.color("#396478");
			categoryAxis_range.axisFill.fillOpacity = 0.3;
			categoryAxis_range.label.disabled = true;

			let columnTemplate = series.columns.template;
			columnTemplate.strokeWidth = 0;
			columnTemplate.strokeOpacity = 0;
			categoryAxis.renderer.grid.template.location = 0;
		}
		else {
			if (this.chart) {
				this.chart.data = response;
			}
			this.setState({ legendShow: false });
		}
	}

	componentWillUnmount = () => {
		if (this.chart) {
			this.chart.dispose();
		}
	}

	render() {
		let { trayUtilizationStatus, excellentStatus, goodStatus, poorStatus, widgetShow, legendShow } = this.state;

		let UtilizationStatus = (trayUtilizationStatus === "Good") ? "chartValStatus valGood" :
			(trayUtilizationStatus === "Excellent") ? "chartValStatus valExcellent" :
				"chartValStatus valPoor";
		return (
			<div className={widgetShow === false ? "displaynone" : this.props.classValue}>
				<div className="colmDi">
					<h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
						<NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.filterType} disableDatePicker={this.props.disableDatePicker ? this.props.disableDatePicker : false} />
					</h4>
					<div class="colmDiVal">
						<div className={UtilizationStatus} id="equipmentEfficiencyVal"> {trayUtilizationStatus}</div>
						<div id="equipmentEfficiencyBarChartdiv"></div>
						<ul id="panChartlegend" className={legendShow ? "legendSectionEquip" : "displaynone"}>
							<li><span></span>{excellentStatus}</li>
							<li><span></span>{goodStatus}</li>
							<li><span></span>{poorStatus}</li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}
function mapStateToProps(state) {
	return {
		currentUnit: state.entityReducer.currentUnit,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getCurrentUnit,
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(EquipmentEfficiencyChart);
