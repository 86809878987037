import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import _ from 'lodash';
import { getMediaPreparationList } from '../../actions/navigation/mediaManagement/mediaPreparationListAction';
import { getMediaScreensaverList } from '../../actions/navigation/mediaManagement/mediaScreensaverListAction';
import http from '../../service/httpService';
import { MediaManagementAPI, BaseService } from '../../service/api';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import { TableHeader } from '../controls/tableHeader';
import { BASECOUNTRY_ID } from '../../utils/appConstants';
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import ReactPlayer from 'react-player'
import Constants from '../../woopraConfig/Woopraconstant';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import { UnitBuilder } from '../../utils/unitResponseBuilder';
import { FormattedMessage, injectIntl } from 'react-intl';


const mappingStringID = {
	"ScreenSaver": "KC0209",
	"Preparation": "KC0719"
}
class CremMediaPreperation extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: true,
			previewfilename: "",
			previewfileformat: "",
			previewurl: "",
			previewurl11: "",
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			sortColumn: { path: "", order: "", sort_color: "#63778a" },
			selectedMedia: {},
			overrideValue: [],
			replaceFromValue:""
		};

		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.callOrgChartPage = this.callOrgChartPage.bind(this);
		this.closePlayer = this.closePlayer.bind(this);
	}

	async componentDidMount() {
		this.setState({ loaderIconVisible: true });
		if (this.props.location && this.props.location.state && this.props.location.state.replaceFrom) {
			this.setState({ replaceFromValue: this.props.location.state.replaceFrom });
		}
		document.body.style.backgroundColor = "#000";
		await this.triggerMediaAction();
		setTimeout(() => {
			this.setState({ loaderIconVisible: false })
		}, 3000)
	}

	triggerMediaAction = async () => {
		const { replaceFrom } = this.props.location.state;
		if (replaceFrom === 'Preparation') {
			await this.props.getMediaPreparationList();
		} else {
			await this.props.getMediaScreensaverList()
		}
		return;
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.props.history.push('/mediaStatus');
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
		// this.props.history.push('/mediaLibrary');
	}

	closePlayer() {
		this.setState({
			previewurl: '',
			previewfilename: ''
		});
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: data ? data.message : ""
					}
				});
			}
		} else {
			this.setState({
				loaderIconVisible: false,

			});

		}
	}


	putData = (postData, url, method) => {
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				data: postData
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== "HTTP_200") {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	previewMediafile = (MEDIA_ID, FILE_NAME) => {
		if (MEDIA_ID && MEDIA_ID.length > 0 && MEDIA_ID != null) {
			http.get(`${MediaManagementAPI.getMediamediapreview}` + MEDIA_ID,
				{
					headers: {
						'Content-Type': 'application/json'
					},
					data: {}
				})
				.then(response => {

					let fileext = FILE_NAME.split('.');
					fileext = fileext[1];
					let previewformat = "";
					if (fileext === "png" || fileext === "jpg" || fileext === "jpeg") {
						previewformat = "image";
					}
					else if (fileext === "mp4" || fileext === "h264" || fileext === 'zip') {
						previewformat = "video";
					}
					WoopraEvents(`${Constants.PREVIEW_IMAGE}`);
					this.setState({
						previewfilename: FILE_NAME,
						previewurl: response.data.imageUrl,
						previewfileformat: previewformat
					});
				})
				.catch(error => {
					this.showError(error);
				})
		}
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	renderButton = (filetype, mediaID, fileName, dynacityValue) => {
		if (filetype === '.zip') {
			return (
				<div className="mediaPreparationIcon">
					<a onClick={(e) => { this.callOrgChartPage(dynacityValue) }} className="mediaTeeView" title={this.props.intl.formatMessage({id:"KC1030"})} >&nbsp;</a>
					<a href="javascript:void(0)" className="mediaPreview disabled" title={this.props.intl.formatMessage({id:"KC0730"})}>&nbsp;</a>
				</div>
			);
		} else {			
			return (
				<div className="mediaPreparationIcon">
					<a onClick={(e) => { this.callOrgChartPage(dynacityValue) }} className="mediaTeeView" title={this.props.intl.formatMessage({id:"KC1030"})} >&nbsp;</a>
					<a href="javascript:void(0)" data-toggle="modal" data-target="#mediaPreviewModal"
						onClick={() => this.previewMediafile(mediaID, fileName)}
						className="mediaPreview" title={this.props.intl.formatMessage({id:"KC0730"})}>&nbsp;</a>
				</div>
			);
		}
	}

	callOrgChartPage = (selCgid) => {
		this.setState({ loaderIconVisible: true });
		localStorage.setItem('orgCgid',selCgid)
		WoopraEvents(`${Constants.ORG_CHART_VIEW}`)
		this.props.history.push({
			pathname: "/organizationChartView",
			getCgids: selCgid,
			previousPath:"/cremMediaPreperation",
		});
	}

	finishHandler = async ()=>{
		this.setState({ loaderIconVisible: true});
		
		const { MEDIA_ID } = this.state.selectedMedia;
		const { THING,CLOUD_UNIT_NAME,ProductId,TimeZone_Standard,useroperation } =this.state.overrideValue[0];
		if(useroperation === 'skip'){
			$('#overwriteMediaModal').modal('hide');
			this.setState({loaderIconVisible:false})
			return;
		}else{
			let unitList = [];
			unitList.push({
				"thingArn": THING,
				"productId": ProductId,
				"cloudUnitName": CLOUD_UNIT_NAME,
				"unitTimeZone": TimeZone_Standard,
				"useroperation": useroperation
			})

			let data = {			
				"thingList": unitList,
				"mediaId": MEDIA_ID,
				"transferType": "auto-install"		
			}
			$('#overwriteMediaModal').modal('hide');
			await this.replacePost(data);
		}
	}

	confirmSelectedMedia = async() => {
		this.setState({ loaderIconVisible: true});
		const { MEDIA_ID, FILE_SIZE } = this.state.selectedMedia;
		const { THING,CLOUD_UNIT_NAME, ID, UNITID, BRAND_NAME, ProductId,TimeZone_Standard,AVAILABLE_SPACE } = JSON.parse(localStorage.getItem('currentUnit'));
		if(AVAILABLE_SPACE < FILE_SIZE){			
		$('#confirmationYesNoModal').modal('hide');
			let overrideValue = [];
			overrideValue.push({
				THING,
				BRAND_NAME,
				UNITID,
				ID,
				CLOUD_UNIT_NAME,
				ProductId,
				TimeZone_Standard,
				AVAILABLE_SPACE,
				'useroperation':'skip'
			})
			this.setState({
				overrideValue,
				loaderIconVisible:false,
			},()=>{
				$('#overwriteMediaModal').modal('show');
				
			})
			return;
		}
		let unitList = [];
		unitList.push({
			"thingArn": THING,
			"productId": ProductId,
			"cloudUnitName": CLOUD_UNIT_NAME,
			"unitTimeZone": TimeZone_Standard,
		})

		let data = {			
			"thingList": unitList,
			"mediaId": MEDIA_ID,
			"transferType": "auto-install"		
		}

		await this.replacePost(data);
		$('#confirmationYesNoModal').modal('hide');
	}

	replacePost =(data)=>{
		http({
			method: "POST",
			url: `${BaseService.root}${MediaManagementAPI.getUploadHistory}`,
			data,
			headers: {
				'Content-Type': 'application/json',
				'customer': localStorage.getItem("customerId"),
				'user': localStorage.getItem("user"),
				'basecountry': BASECOUNTRY_ID,
				'brandid':localStorage.getItem("unitBrandId"),
			}
		}).then(response => {
			if (response) {
				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: "KC1031"
					},
				});
			}
		}).catch(err => {
			this.showError(err);
		});
	}

	changeRadioButton = (ID, value)=>{
		let { overrideValue } = this.state;
		const findIndex = overrideValue.findIndex((i)=>i.ID === ID);
		overrideValue[findIndex].useroperation = value;
		this.setState({overrideValue});
	}

	checkSelectedAll = (userCheck, value)=>{
		const filterVal = userCheck.filter((i)=>i.useroperation === value);
		return filterVal.length === userCheck.length ? true : false;
	}

	checkAll = (value)=>{
		let { overrideValue } = this.state;
		for(let i =0; i<overrideValue.length; i++){
			if(overrideValue[i].useroperation){
				overrideValue[i].useroperation = value;
			}
		}
		this.setState({overrideValue});
	}

	rowClick = (e, selectedMedia) => {
		if (e.target.className === 'mediaPreview') {
			this.setState({ selectedMedia });
			$('#confirmationYesNoModal').modal('hide')
			$('#mediaPreviewModal').modal('show')

		} else {
			$('#mediaPreviewModal').modal('hide')
			$('#confirmationYesNoModal').modal('show')
		}
		this.setState({ selectedMedia }
			,() =>{
				this.renderButton(selectedMedia.FILE_FORMAT, selectedMedia.MEDIA_ID, selectedMedia.FILE_NAME, selectedMedia.ASSINGEDTO)
			});
	}

	render() {
		const zipimage = require("../../images/icons/ZipIcon.png");		
		const { previewfilename, previewurl, sortColumn, loaderIconVisible, previewfileformat, overrideValue, replaceFromValue } = this.state;
		const { mediaPreparationListInfo, mediaScreensaverListInfo } = this.props;
		// const { replaceFrom } = this.props.location.state;
		let units = overrideValue ? overrideValue : [];
        units = UnitBuilder(units);

		let { success_modal, error_modal } = this.state;
		const { formatMessage } = this.props.intl;

		let currentUnit = JSON.parse(localStorage.getItem('currentUnit'));

		let columns = [
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0102", isNbSp: true },
			{ path: "FILE_NAME", labelStringID: "KC0677" },
			{ path: "CREATEDAT", labelStringID: "KC0725" },
			{ path: "RESOLUTION", labelStringID: "KC0725", isNbSp: true },
			{ path: "FILE_SIZE", labelStringID: "KC1521", isNbSp: true },
			{ path: "IN_USE_COUNT", labelStringID: "KC0728", isNbSp: true },
			{ path: "", labelStringID: "KC0729", isNbSp: true },
			{ path: "", labelStringID: "KC0730", isNbSp: true },
		];

		let mediaReplaceList = replaceFromValue === 'Preparation' ? mediaPreparationListInfo : mediaScreensaverListInfo;

		mediaReplaceList.mediaList = _.orderBy(mediaReplaceList.mediaList, [sortColumn.path], [sortColumn.order]);

		if (previewfileformat === "image") {
			$("#imgdiv").removeClass("displaynone");
			$("#videodiv").addClass("displaynone");
		}
		else if (previewfileformat == "video") {
			$("#videodiv").removeClass("displaynone");
			$("#imgdiv").addClass("displaynone");
		}

		return (
			<div id="wrapper">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				<GlobalHeaderLayout activeClass="recipeNav" />
				<div id="page-content-wrapper">
					<div class="pageHeader">
						<ul>
							<li>
								<Link to="/mediaLibrary" title={formatMessage({id:"KC0152"})} className="active">{(mappingStringID[replaceFromValue] ? <FormattedMessage id={mappingStringID[replaceFromValue]}/> : replaceFromValue) || <FormattedMessage id="KC0215"/>}</Link>
							</li>
						</ul>
					</div>
					<div class="gridViewTable">
						<div className="gridView ">
							<div class="colm12D">
								<div className="mediaPreparationList mediatHead">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
									/>
								</div>
							</div>
						</div>

						<div className="modal fade mediaPreviewModal" data-keyboard="true" data-backdrop="static" id="mediaPreviewModal" tabIndex="-1" role="dialog" aria-labelledby="mediaPreviewModalLabel">
							<div className="modal-dialog modal-lg modal-xs">
								<div className="modal-content">
									<div className="modal-header modal-header-danger">
										{previewfilename} <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closePlayer}>&nbsp;</button>
									</div>
									<div className="modal-body">

										<div id="imgdiv" className="imageandVideoPreview displaynone">
											<img src={previewurl}></img>
										</div>

										<div id="videodiv" className="imageandVideoPreview displaynone">
											<ReactPlayer url={previewurl} playing controls />
										</div>
									</div>
								</div>
							</div>
						</div>
							
						<div className="gridView">
							<div className="colm12D">
								<form className="cremMediaForm">
									<div className="">
										<div className="mediaPreparationList">
											{mediaReplaceList.mediaList && mediaReplaceList.mediaList.map((item, i) =>												
												<ul className="tBody" onClick={(e) => this.rowClick(e,item)}>
													<li>{i + 1}</li>
													<li>
														<span className="mediaFileSpan"> <img src={item.FILE_FORMAT === '.zip' ? zipimage : item.thumbUrl} /></span>
													</li>
													<li>{item.FILE_NAME.split(".").length > 1 ? item.FILE_NAME.split(".")[0] + item.FILE_FORMAT.toLowerCase() : item.FILE_NAME}
														<div className="mediaSListSM">{item.CREATEDAT}</div>
														<div className="mediaSListRS">{item.RESOLUTION}, {item.FILE_SIZE} <FormattedMessage id="KC1498"/></div>
														<div className="mediaSListAS">
															{item.assignedCustomerGrops.map((subitem, j) =>
																<div className="assignedTo alert alert-dismissible fade show" role="alert">
																	{subitem}
																</div>
															)}
														</div></li>
													<li>{item.CREATEDAT}</li>
													<li>{item.RESOLUTION}</li>
													<li>{item.FILE_SIZE}</li>
													<li>{item.IN_USE_COUNT}</li>
													<li>{item.assignedCustomerGrops.map((subitem, j) =>
														<div className="assignedTo alert alert-dismissible fade show" role="alert">
															{subitem}
														</div>
													)}
													</li>
													<li>
														<div className="mediaPreparationIcon">
															<a onClick={(e) => { this.callOrgChartPage(item.ASSINGEDTO) }} className="mediaTeeView" title={formatMessage({id:"KC1030"})} >&nbsp;</a>
															<a href="javascript:void(0)" data-toggle="modal" data-target="#mediaPreviewModal"
																onClick={() => this.previewMediafile(item.MEDIA_ID, item.FILE_NAME)}
																className= {item.FILE_FORMAT === '.zip'?"mediaPreview disabled" :"mediaPreview"} title={formatMessage({id:"KC0730"})}>&nbsp;</a>
														</div>
													</li>
												</ul>)}
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<ErrorMoal open={error_modal.open}  stringID={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />

				{/* Overwrite Modal */}
				<div className="modal fade overwriteMediaModal" data-keyboard="true" data-backdrop="static" id="overwriteMediaModal"
					tabIndex="-1" role="dialog" aria-labelledby="overwriteMediaModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="overwriteMedia">
									<div className="overwriteMediaHeader">
										<ul>
											<li><img src={require('../../images/icons/AttentionYellow.svg').default} /></li>
											<li>
												{/* Issue 83135: <h5><FormattedMessage id="KC1986"/></h5> */}
												<p><FormattedMessage id="KC1032"/></p>
												<p><FormattedMessage id="KC1033"/></p>
											</li>
										</ul>
									</div>
									<div className="mediaOverwriteTable">
										<ul className="tHead">
											<li>#</li>
											<li><FormattedMessage id="KC0101"/></li>
											<li><FormattedMessage id="KC0104"/></li>
											<li>
												<div className="customRadioBtn">
													<input id="OverwriteAll" type="radio" name="selectAll" checked={this.checkSelectedAll(units.filter((i)=>i.useroperation),'overwrite')} onChange={()=>this.checkAll('overwrite')} value="OverwriteAll" />
													<label for="OverwriteAll"><FormattedMessage id="KC1034"/></label>
												</div>
											</li>
											<li>
												<div className="customRadioBtn">
													<input id="SkipAll" type="radio" name="selectAll" checked={this.checkSelectedAll(units.filter((i)=>i.useroperation),'skip')} onChange={()=>this.checkAll('skip')} value="SkipAll" />
													<label for="SkipAll"><FormattedMessage id="KC1035"/></label>
												</div>
											</li>
										</ul>
										{units.filter((i)=>i.useroperation).map((unitItem, i) => {
											return (
												<ul className="tBody" key={i}>
													<li>{i+1}</li>
													<li>
														<span className="unitImg"><img alt={unitItem.Brand_Alt} src={require('../../images/unit/CremCarrara.png')} /></span>
														<span className="unitName">{unitItem.BRAND_NAME} <b>{unitItem.CLOUD_UNIT_NAME}</b></span>
													</li>
													<li>{unitItem.UNITID}</li>
													<li>
														<div className="customRadioBtn">
															<input id={`Overwrite${unitItem.ID}`} name={`selectrow${unitItem.ID}`} onChange={()=>this.changeRadioButton(unitItem.ID,'overwrite')} type="radio" checked={unitItem.useroperation === "overwrite"} value="Overwrite" />
															<label for={`Overwrite${unitItem.ID}`}></label>
														</div>
													</li>
													<li>
														<div className="customRadioBtn">
															<input id={`Skip${unitItem.ID}`} type="radio" name={`selectrow${unitItem.ID}`} onChange={()=>this.changeRadioButton(unitItem.ID,'skip')} checked={unitItem.useroperation === "skip"} value="Skip" />
															<label for={`Skip${unitItem.ID}`}></label>
														</div>
													</li>
												</ul>
											)
										})}
									</div>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary submitBtn" data-dismiss="modal" onClick={()=>this.finishHandler()} type="button"><FormattedMessage id="KC0022"/></button>
							</div>
						</div>
					</div>
				</div>





				<div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="confirmationYesNoModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-body">
								<div class="confirmationYesNo">
									<p>{`${formatMessage({id:"KC1185" })} "${this.state.selectedMedia.FILE_NAME}" ${formatMessage({id:"KC1987"})} ${currentUnit.Brand_Alt} ${currentUnit.BRAND_TYPE}, ${currentUnit.location_name}, ${currentUnit.within_location}?`}</p>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
								<button class="btn btn-secondary yesBtn" onClick={() => this.confirmSelectedMedia()} type="button"><FormattedMessage id="KC0558"/></button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
};


function mapStateToProps(state) {
	return {
		mediaPreparationListInfo: state.mediaPreparationListInformation.mediaPreparationListInformation,
		mediaScreensaverListInfo: state.mediaScreensaverListInformation.mediaScreensaverListInformation,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaPreparationList,
		getMediaScreensaverList
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CremMediaPreperation));