import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import ErrorMoal from '../../../../modal/error-model';
import { FormattedMessage, injectIntl } from 'react-intl';

class FrymasterMenuAddFinalReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            menuName: ''
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.menuRedirect = this.menuRedirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
        this.onViewMenuButtonClick = this.onViewMenuButtonClick.bind(this);
        this.onRecipeReviewButtonClick = this.onRecipeReviewButtonClick.bind(this);
        this.onSharedWithButtonClick = this.onSharedWithButtonClick.bind(this);
        this.menuNameChange = this.menuNameChange.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let selectedCategory = localStorage.getItem("selectedCategory");
            let selectedCategoryObj = JSON.parse(selectedCategory);

            this.setState({
                menuName: selectedCategoryObj.categoryName
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    menuRedirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false,
            isDeleteCategory: false,
            errorCookbookName: ""
        })
    }

    onSaveButtonClick = () => {
        let menuName = this.state.menuName.trim();
        if (menuName.length < 4 || menuName.length > 20) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1392"
                }
            });
        }
        else {
            let categoryDetails = localStorage.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
            let selectedCategory = localStorage.getItem("selectedCategory");
            let selectedCategoryObj = JSON.parse(selectedCategory);

            var foundIndex = categoryDetailsObj.findIndex(x => x.ID === selectedCategoryObj.ID);
            if (foundIndex !== -1) {
                categoryDetailsObj[foundIndex].categoryName = menuName;
            }
            localStorage.setItem("selectedCategory", JSON.stringify(categoryDetailsObj[foundIndex]));
            localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));

            this.props.history.push({
                pathname: "/frymasterMenuList",
                isEdit: false,
                previouspath: "/frymasterMenuAddFinalReview"
            });
        }
    }

    onViewMenuButtonClick = () => {
        this.props.history.push({
            pathname: "/frymasterMenuRecipeList",
            isEdit: false,
            previouspath: "/frymasterMenuAddFinalReview"
        });
    }

    onRecipeReviewButtonClick = () => {
        this.props.history.push({
            pathname: "/frymasterMenuAddRecipes",
            isEdit: false,
            previouspath: "/frymasterMenuAddFinalReview"
        });
    }
    
    onSharedWithButtonClick = () => {
        this.props.history.push({
            pathname: "/frymasterMenuSelectFrypots",
            isEdit: false,
            previouspath: "/frymasterMenuAddFinalReview"
        });
    }

    menuNameChange = (e) => {
        this.setState({
            menuName: e.target.value
        });
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        let path = "/frymasterMenuList";
        if (libraryDetails.isEdit) {
            path = "/libraryList";
        }
        this.props.history.push({
            pathname: path,
            isEdit: false,
            previouspath: "/frymasterMenuAdd"
        });
    }

    onBackButtonClick = () => {
        if (!this.state.isChanged) {
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            let path = "/frymasterMenuRecipeList";
            if (libraryDetails.isEdit) {
                path = "/libraryList";
            }
            this.props.history.push({
                pathname: path,
                isEdit: false,
                previouspath: "/frymasterMenuAddFinalReview"
            });
        }
        else {
            this.setState({
                loaderIconVisible: false,
                showDiscardPopup: true
            });
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { loaderIconVisible, menuName, error_modal } = this.state;
        let brandid = localStorage.getItem("selectedbrandID");

        return (
            <React.Fragment>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                        <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>{menuName}
                                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01"> 
                                                    <div class="rcSettingsList02"> 
                                                        <ul>  
                                                            <li><FormattedMessage id="KC1394"/></li>
                                                            <li><input type="text" class="form-control" value={menuName} onChange={(e) => { this.menuNameChange(e) }} /></li>  
                                                        </ul>  
                                                    </div> 
                                                    <div class="rcSettingsList02"> 
                                                        <ul onClick={() => { this.onRecipeReviewButtonClick() }}>  
                                                            <li><FormattedMessage id="KC1381"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} alt=""/></li>
                                                        </ul>  
                                                    </div> 
                                                    
                                                </div>					
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button> 
                                    <button type="button" onClick={() => { this.onSaveButtonClick() }} class="btn saveButton rightAlign">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onViewMenuButtonClick() }} class="btn reviewButton"><FormattedMessage id="KC1399"/></button> 
                                </div> 
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterMenuAddFinalReview)
