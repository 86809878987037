import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UnitManagementInfo from './widgetconstants';
import * as _ from 'lodash';
import WoopraEvent from '../../../woopraConfig/WoopraEvents';
import constant from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage } from 'react-intl';

class HelpWidget extends Component {

	actionEvent = () => {
		WoopraEvent(`${constant.HELP_MANUALS}`);
	}

	render() {
		const unitMngInfo = UnitManagementInfo;
		const brandName = localStorage.getItem("brandName");
		let helpInfo = unitMngInfo[brandName].helpInfo;

		let helpInfoList = null;
		if(Array.isArray(helpInfo) && helpInfo.length) {
			helpInfoList = (
				_.map(_.filter(helpInfo, {visible: true}), el => <li>
					<button type="button" class="btn btn-default" onClick={() => {window.open(el.link, '_blank'); this.actionEvent()}}><span class="btnIcon Manuals"></span>{el.stringID ? <FormattedMessage id={el.stringID}/> : el.fieldname}</button>
				</li>)
			);
		}

		return (
			<React.Fragment>
				<div className={brandName === "CREM" ? "colm3D disabled" : "colm3D"}>
					<div className="colmDi">
						<h4><FormattedMessage id="KC0285"/></h4>
						<div className="colmDiVal">
							<div className="btnListView">
								<ul>
									{helpInfoList}
									<li>
										&nbsp;
									</li>
									<li>
										&nbsp;
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};

export default HelpWidget;
