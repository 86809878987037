import { SET_POPUP_STATUS, SET_MODAL_STATUS, SET_DIAGNOSTIC_CONNECTION_STATUS, SET_UNIT_CONNECTION_STATUS, SET_DIAGNOSTIC_CONFIGURATION_FILE, SET_LIVE_MONITORING_STREAM_DATA, SET_LIVE_MONITORING_DATA } from './type';
import { getUnitStatusDetails, getDiagnosticConnectionDetails, establishDiagnosticConnectionEvent, getDiagnosticConfigurationDetails } from '../../middleware/liveDiagnostic/liveDiagnosticMiddleware';


export const setUnitConnectionStatus = (status) => {
    return dispatch => {
        dispatch({
            type: SET_UNIT_CONNECTION_STATUS,
            payload: status
        })
    }
}

export const setModalStatus = (status) => {
    return dispatch => {
        dispatch({
            type: SET_MODAL_STATUS,
            payload: status
        })
    }
}

export const setPopupStatus = (status) => {
    return dispatch => {
        dispatch({
            type: SET_POPUP_STATUS,
            payload: status
        })
    }
}

export const setDiagnosticConnectionStatus = (status) => {
    return dispatch => {
        dispatch({
            type: SET_DIAGNOSTIC_CONNECTION_STATUS,
            payload: status
        })
    }
}

export const setLiveMonitoringStreamData = (data) => {
    return dispatch => {
        dispatch({
            type: SET_LIVE_MONITORING_STREAM_DATA,
            payload: data
        })
    }
}

export const setLiveMonitoringData = (data) => {
    return dispatch => {
        dispatch({
            type: SET_LIVE_MONITORING_DATA,
            payload: data
        })
    }
}



export const getUnitStatus = (url, payload) => {
    return dispatch => {
        return new Promise((resolve, _) => {
            getUnitStatusDetails(url, payload).then((res) => {
                dispatch(setUnitConnectionStatus(res));
                resolve(res);
            })
        })
    }
}

export const getDiagnosticConnectionData = (serialNo) => {
    return dispatch => {
        return new Promise((resolve, reject) => {
            getDiagnosticConnectionDetails(serialNo).then((res) => {
                dispatch(setDiagnosticConnectionStatus(res.data));
                resolve(res);
            }).catch((err) => {
                reject(err)
            })
        })
    }
}

export const establishDiagnosticConnection = (state, duration, currentUnit) => {
    return dispatch => {
        return new Promise((resolve, _) => {
            let data = {
                "modeRequested": "operational",
                state,
                duration,
                "deviceUuid": `${currentUnit.UNITID}_${currentUnit.BRAND_NAME}_${currentUnit.PRODUCT_TYPE}`,
                "enabledBy": localStorage.getItem('user')
            };
            if(state === "disable") {
                data.disabledBy = localStorage.getItem('user')
                data.disableSource = "cloud"
            }
            establishDiagnosticConnectionEvent(data).then((res) => {
                getDiagnosticConnectionDetails(currentUnit.UNITID).then((res) => {
                    resolve(res);
                })
            })
        })
    }
}

export const setDiagnosticConfigFile = (file) => {
    return dispatch => {
        dispatch({
            type: SET_DIAGNOSTIC_CONFIGURATION_FILE,
            payload: file
        })
    }
}

export const getDiagnosticConfigFile = (unitDetails) => {
    return dispatch => {
        return new Promise((resolve, rej) => {
            getDiagnosticConfigurationDetails(unitDetails).then((res) => {
                if (res) {
                    dispatch(setDiagnosticConfigFile(res));
                }
                resolve(res);
            }).catch((err) => {
                rej(err);
            })
        })
    }
}