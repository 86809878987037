import React, { Component } from 'react';
import { UserProfile, unitAPI, unitRegister, DeviceRegistration } from '../../../service/api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import { brandLocations, unitModels } from '../../../utils/imgContants';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import $ from 'jquery';
import qs from 'query-string';
import * as _ from 'lodash';
import { BRAND_ID } from '../../../utils/appConstants';
import 'bootstrap/dist/css/bootstrap.min.css';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import { AutoSizer, List } from 'react-virtualized';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';
import moment from '../../../utils/momentHelper.js';
import authService from '../../../service/authService';
import { smartTagMangement } from '../../../service/api_london';
import TagOrgModal from '../smartTag/tagOrgModel';
import {readArchivesFromCompressedResponse} from '../../../utils/helpers'

var bg = require('../../../images/recipecreation/photoPlaceholder.png')
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;
const brandLocationsMap = {};
brandsLocationLogo.forEach(item => (brandLocationsMap[item.name] = item));
const brandsMap = {};
brandsLogo.forEach(item => (brandsMap[item.name] = item));
var selectedTimeZoneId;
const HTTP_METHOD = {
    GET: 'GET',
    PUT: 'PUT',
    POST: 'POST',
    DELETE: 'DELETE'
}
class AssignUnitToTopology extends Component {
    getTimeZoneInfo = async () => {
        let token = localStorage.getItem('Access-Token');
        await http.get(unitRegister.timeZoneInfo,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': token,
                },
                data: {}
            }).then(response => {
                let { timezones } = response.data || {};
                this.setState({
                    loaderIconVisible: false,
                    timezonedata: timezones
                });
            }).catch(err => {
                console.log(err);
            });
    };
    nextPath(path) {
        $('body').removeClass("modal-open");
        $('.modal-backdrop').hide();
        this.props.history.push(path);
    }
    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/unitRegistration`);
        $('body').removeClass("modal-open");
        $('.modal-backdrop, .selectedUnitHeader').hide();
        if ($(".selectedUnitHeader").is(":visible")) {
            $(".orgznManagementWrapper.assignUnitTopologyMarginSpace").removeClass("withoutSelectedUnitHeader");
        } else {
            $(".orgznManagementWrapper.assignUnitTopologyMarginSpace").addClass("withoutSelectedUnitHeader");
        }
        $(document).find('.orgznSiteTreeLiDiv').removeClass('treePrentDivActive');
        $(document).find('.orgznSiteTreeLiDiv .treeNodeAvailable').removeClass('treeActive');
        $(".addUserHeader ul li button").attr("disabled", "disabled")
    }
    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        $('body').removeClass("modal-open");
        $('.modal-backdrop').hide();
        // this.props.history.push(`/unitRegistration`);
    }
    constructor(props) {
        super(props);
        this.state = {
            selectedUnits: [],
            userData: {},
            roleId: 0,
            userGroup: [],
            userPreference: [],
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            isFormValidated: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            assignedUnits: props.location.data ? props.location.data : [],
            totalUnits: props.location.totalUnits ? props.location.totalUnits : [],
            unitList: props.location.unitList,
            isUnitItemClicked: false,
            selectedAddress: null,
            selectedLocationName: "",
            findTreeLevel: "",
            selectedTimeZone: props.location.selectedTimeZone,
            inActiveUnits: props.location.inActiveUnits ? props.location.inActiveUnits : [],
            timezonedata: {},
            TimeZoneForSelNode: "",
            orgTimeZoneForSelNode: "",
            flattenTreeData: [],
            searchData: null,
            selectedTotalUnit: [],
            newAssetNumber: '',
            smartTag: [],
            searchUnitSmartTag: '',
            selectedSmartUnitTag: [],
            showpopup: [],
            type: 'orgList',
            showOrgPopup: false,
            isChangeHappened: false,
            initialSmartTag: []
        };
        this.treeData = {
            virtualScrollBufferRowCount: 10,
            rowBaseHeight: 80,
            virtualScrollRef: React.createRef(),
            collapsedNodes: {},
            activeNode: null,
            expand: {}
        };
        this.assignUnitToTopology = this.assignUnitToTopology.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.setUserToState = this.setUserToState.bind(this);
        this.treeViewRowRenderer = this.treeViewRowRenderer.bind(this);
        this.getTreeViewRowHeight = this.getTreeViewRowHeight.bind(this);
    }
    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (data) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data ? data.message : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }
    async componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        this.props.searchVisible(true);
        let { data, totalUnits } = this.props.location;
        console.log("totalUnits", totalUnits)
        try {
            await this.loadSmartTags(totalUnits);
            this.getCustGroupID();
            this.OnTimeZonLoad();
            await this.getTimeZoneInfo();
            const newAssetNumber = totalUnits && totalUnits.length && totalUnits.length === 1 ? totalUnits[0].UNIT_NUMBER ? totalUnits[0].UNIT_NUMBER : '' : '';
            this.identifyInitialTagForUnit(totalUnits);
            this.setState({
                selectedUnits: data,
                selectedTotalUnit: totalUnits ? totalUnits : [],
                newAssetNumber
            });
        } catch (err) {
            //Error Goes Here
            this.setState({
                loaderIconVisible: false
            });
        };
        window.addEventListener('resize', this.setTreeViewHeight);
    }

    identifyInitialTagForUnit = (totalUnit) => {
        let tags = [];
        for (let index = 0; index < totalUnit.length; index++) {
            const element = totalUnit[index];
            const tagInfo = element.tagIds ? element.tagIds.split(',') : [];
            tags = [...tags, ...tagInfo];
        }
        this.setState({ 
            selectedSmartUnitTag: tags.map(Number), 
        });
    }

    loadSmartTags = async (totalUnits) => {
        this.setState({ loaderIconVisible: true });
        let url = smartTagMangement.getTagList + "UNIT";
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
        if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag = 'yes';
        }
        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                customerId: parseInt(localStorage.getItem("customerId")),
                'cgid': localStorage.getItem("custGroupID"),
                'brandid': BRAND_ID,
                'registrationflag': registrationFlag,
            }
        }).then(resp => {
            const overAllList = resp.data.data;
            this.validateTagList(overAllList, totalUnits);
        }).catch(error => {
        })
    }

    validateTagList(overAllList, totalUnits) {
        let tagList = overAllList;
        try {
            tagList = tagList.map(v => ({ ...v, units: [] }));
            let tags = overAllList.map((i) => i.id);
            console.log("tagEle tags", tags);
            console.log("tagEle units", totalUnits);
            for (let index = 0; index < totalUnits.length; index++) {
                const element = totalUnits[index];
                if (element.tagIds) {
                    let splitedTag = element.tagIds.split(',');
                    for (let index = 0; index < splitedTag.length; index++) {
                        const splitedTagEle = splitedTag[index];
                        if (tags.includes(Number(splitedTagEle))) {
                            const index = tagList.findIndex(function (object) {
                                return object.id === Number(splitedTagEle);
                            });
                            tagList[index]['units'].push(element.ID);
                        }
                    }
                }
            }
            console.log("tagEle tagList", tagList);
            this.setState({
                smartTag: tagList,
                initialSmartTag: overAllList
            });
        } catch (error) {
            this.setState({
                smartTag: tagList,
                initialSmartTag: overAllList
            });
        }
    }

    componentDidUpdate(prevState) {
        /**
         * Tree View header has a dynamic height based on Org API response
         * So set the body height after ORG API success
         */
        this.setTreeViewHeight();
        let { searchData } = prevState;
        let curSearchData = this.state && this.state.searchData;
        if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
                this.getCustGroupID();
        }
    }

    /**
     * To remove the search text when navigating to other screens
     */
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

    /**
     * Convert Encoding UTF8 to binary
     */
     convertToBinary = (string) => {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
          codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }

    /**
         * Set tree view container height
         */
    setTreeViewHeight() {
        const windowHeight = $(window).height();
        const $treeViewBody = $('.assignOrgTreeWrapper .treeViewBody');
        $treeViewBody.css('height', windowHeight - $treeViewBody.offset().top); 
    } 
    setUserToState = (e) => {
        let { userModel } = this.props;
        userModel.roleId = this.props.userModel.roleId;
        userModel.assignUserBackPage = "/unitRegistration";
        this.props.setUserDataModel(userModel);
    }
    getData = (url, Cgids) => {
        let responseResult = {
            data: []
        }
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {
            let headers = { 'Content-Type': 'application/json', 'brandids': BRAND_ID };
            if (Cgids) headers.Cgids = Cgids;
            http.get(url, {
                headers,
                data: {}
            }
            ).then(response => {
                resolve(response);
            }).catch(err => {
                this.showError(err);
            })
        });
    }
    getCustGroupID = () => {
        let custGroupIDList = "";
        var setcustGroupIDList = "";
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        this.setState({ loaderIconVisible: true })
        const values = qs.parse(this.props.location.search);
        if (values.user !== undefined) {
            loggedinUser = values.user;
        }
        this.setState({ queryString: loggedinUser });
        http({
            method: "GET",
            url: `${UserProfile.get_Cust_GroupID}`,
            headers: {
                'customer_id': localStorage.getItem("customerId"),
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': localStorage.getItem('basecountry'),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
            }, data: {},
        }).then(response => {
            custGroupIDList = response.data.data;
            custGroupIDList.forEach(p => {
                setcustGroupIDList ? setcustGroupIDList = setcustGroupIDList + "," + p.customer_group_id : setcustGroupIDList = p.customer_group_id
            });
            if (setcustGroupIDList != "") {
                this.getOrgnanizationView(setcustGroupIDList);
            }
        }).catch(err => {
            console.log('catch exception occured');
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }
    getUnitSmartGroupsName(unitNode) {
        let smartGroups = unitNode.smartGroup || [];
        let smartGroupsName = [];
        if (smartGroups.length > 3) {
            smartGroupsName.push({
                sgName: '',
                sgFirstName: smartGroups.length,
            });
        } else if (smartGroups.length > 0) {
            smartGroups.forEach(item => {
                smartGroupsName.push({
                    sgName: item.name,
                    sgFirstName: item.smartGroupKeyName && item.smartGroupKeyName !== 'null'
                        ? item.smartGroupKeyName
                        : item.name.charAt(0)
                });
            });
        }
        return smartGroupsName;
    }
    convertToFlattenTreeData(nodes, nodeLevel = 1, currentRowIndex = 0, flattenTreeData = [], parentRowIndex = null, parentColIndex = null) {
        let prevRowIndex = 0;
        const nodeIndexes = [];
        const nodesLength = nodes.length;
        for (let i = 0; i < nodesLength; i++) {
            const node = nodes[i];
            // Set row and column index
            i > 0 && currentRowIndex++;
            const rowIndex = currentRowIndex;
            const level = node.level_id || nodeLevel; // If level_id not available in API response, read from method param. Fix for DEV env
            const colIndex = level - 1;
            // Set connectors in midway indexes, but not for level 1 and 6
            if ((level > 1 && level < 6) && i > 0) {
                const endRowIndex = rowIndex - 1; // Exclude current row
                let midwayRowsCount = endRowIndex - prevRowIndex;
                if (midwayRowsCount > 0) {
                    const startRowIndex = prevRowIndex + 1; // Exclude start index
                    for (let j = startRowIndex; j <= endRowIndex; j++) {
                        const rowData = flattenTreeData[j] || [];
                        rowData[colIndex] = {
                            __NODE_DATA__: {
                                isConnector: true,
                                rowIndex: j,
                                colIndex,
                                parentRowIndex,
                                parentColIndex
                            }
                        };
                        flattenTreeData[j] = rowData;
                    }
                }
            }
            // Create node object
            const childNodesProp = level < 5 ? `level${level + 1}` : 'units';
            const { [childNodesProp]: childNodes, ...nodeClone } = node;
            const nodeData = {
                isNode: true,
                rowIndex,
                colIndex,
                parentRowIndex,
                parentColIndex,
                hasChildren: !!node.child_count,
                unitCount: node.child_count == 0 && node.units && node.units.length > 0 ? true : false,
                isFirstChild: nodesLength > 1 && i === 0,
                isMidwayChild: i > 0 && i < (nodesLength - 1),
                isLastChild: nodesLength > 1 && i === (nodesLength - 1),
                isOnlyChild: nodesLength === 1
            };
            if (level === 6) {
                const smartGroupsName = this.getUnitSmartGroupsName(nodeClone);
                nodeData.smartGroupsName = smartGroupsName;
            }
            nodeClone.__NODE_DATA__ = nodeData;
            // Set node object in respective index
            const rowData = flattenTreeData[rowIndex] || [];
            rowData[colIndex] = nodeClone;
            flattenTreeData[rowIndex] = rowData;
            nodeIndexes.push([rowIndex, colIndex]);
            // Process its children
            if (nodeData.hasChildren || nodeData.unitCount) {
                //if (nodeData.hasChildren) {
                const result = this.convertToFlattenTreeData(
                    childNodes,
                    nodeLevel + 1,
                    currentRowIndex,
                    flattenTreeData,
                    rowIndex,
                    colIndex
                );
                flattenTreeData = result.flattenTreeData;
                flattenTreeData[rowIndex][colIndex].__NODE_DATA__.childNodeIndexes = result.nodeIndexes;
                currentRowIndex = result.currentRowIndex;
            }
            prevRowIndex = rowIndex;
        }
        return { flattenTreeData, nodeIndexes, currentRowIndex };
    }
    processOrgnanizationJSON(orgJSON) {
        // const length = orgJSON.start_level_id;
        const length = 1;
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }
        const { flattenTreeData } = this.convertToFlattenTreeData(orgJSON.level1);
        const { loaderIconVisible } = (!this.state.loaderIconVisible &&  flattenTreeData.length > 0) || (flattenTreeData.length == 0 && !this.state.loaderIconVisible) ? true : this.state.loaderIconVisible;
        this.setState({
            linkList: orgJSON,
            findTreeLevel: findLeveltoShow,
            flattenTreeData,
            loaderIconVisible: loaderIconVisible,
        }, () => {
            this.treeData.virtualScrollRef.current.recomputeRowHeights();
        });
    }
    getOrgnanizationView = async (values) => {
        this.setState({ loaderIconVisible: true })
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let { searchData } = this.state;
        let apiUrl = `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`;
        if (searchData && searchData.searchtext) {
            apiUrl = `${apiUrl}?searchText=${searchData.searchtext.toString()}`;
        }
        if (searchData) {
            let searchParam = {};

            Object.keys(searchData).map((item) => {
                if (item !== "searchtext") {
                    searchParam[item] = searchData[item];
                }
            })

            if (searchParam && Object.keys(searchParam).length) {
                apiUrl = `${apiUrl}?searchParams=${btoa(this.convertToBinary(JSON.stringify(searchParam)))}`;
            }
        }
        await http({
            method: "GET",
            url: apiUrl,
            headers: {
                "Cgids": values,
                'Content-Type': 'application/json',
                'brandids': BRAND_ID,
                'useremail': loggedinUser
            }, data: {},
        }).then(async response => {
            // if (response.data.fetchFromS3) {
            //     await $.getJSON(response.data.presignedS3URL, (data) => {
            //         this.processOrgnanizationJSON(data);
            //     });
            // }
            // else {
            //     this.processOrgnanizationJSON(response.data);
            // }      
            let orgJSON = await readArchivesFromCompressedResponse(response.data);
            this.processOrgnanizationJSON(orgJSON);
            this.setState({
                cgIds: values,
            })
        }).catch(err => {
            console.log('catch exception occured',err);
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        });
    }

    static getDerivedStateFromProps = (props) => ({...props});

    assignUnitToTopology = (e) => {
        e.preventDefault();
        let { userModel } = this.props;
        const { selectedTotalUnit } = this.state;
        this.setState({
            loaderIconVisible: true
        });
        let inActiveUnits = this.state.inActiveUnits;
        if (inActiveUnits.length > 0 || this.state. isChangeHappened) {
            let unitData = selectedTotalUnit && selectedTotalUnit.length ? selectedTotalUnit[0] : {};

            let unitIds = [];
            unitIds.push(unitData.ID);
            let postData = {
                unitIds: inActiveUnits.length > 0 ? inActiveUnits :  unitIds,
                assetNumber: this.state.newAssetNumber,
                activationStatus: inActiveUnits.length > 0 ? "ACTIVE" : unitData.ACTIVATION_STATUS,
            };

            this.changeUnitStatus(postData);
            this.applySmartTag();
        } else {
            this.applySmartTag();
            this.assignUnits();
        }
    }
    callAllocationAPIEaas = (unitIds, rootCgid, element) => {
        return new Promise(async (res, rej) => {
            const postData = {
                rootCgid,
                "model_family_name": element.MODEL_FAMILY_NAME,
                "model_name": element.MODEL_NAME,
                "brand_name": element.BRAND_NAME,
                "unitId": unitIds,
                "allocationType": "UPGRADE",
                "subscriptionType": "EAAS",
                "customerId": localStorage.getItem("customerId")
            };
            await http({
                method: "POST",
                url: DeviceRegistration.allocateEaasApi,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: postData
            }).then(async response => {
                res(response);
            }).catch(err => {
                res(err);
            });
        })
    }
    groupBy = (objectArray, property) => {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property];
            if (!acc[key]) {
                acc[key] = [];
            }
            // Add object to list for given key's value
            if (key) acc[key].push(obj);
            return acc;
        }, {});
    }
    getUnit = (array) => {
        let arr = [];
        for (let index = 0; index < array.length; index++) {
            const element = array[index];
            if (element && element.UNITID && element.EAAS_FLAG && element.EAAS_FLAG === 1) {
                arr.push(element.UNITID)
            }
        }
        return arr.join(',');
    }
    callEaasService = async () => {
        const { link, totalUnits } = this.state;
        const spliDynasty = link.dynasty ? link.dynasty.split(',') : [];
        const rootCgid = spliDynasty && spliDynasty.length ? spliDynasty[2] : '';
        const data = await this.groupBy(totalUnits, 'MODEL_FAMILY_NAME');
        const ObjectKeys = Object.keys(data);
        for (let index = 0; index < ObjectKeys.length; index++) {
            const element = ObjectKeys[index];
            const unitIds = await this.getUnit(data[element]);
            if (unitIds && unitIds !== "") {
                await this.callAllocationAPIEaas(unitIds, rootCgid, data[element][0])
            }
        }
        return link;
    }

    changeUnitStatus(postData) {
        this.putData(postData, unitRegister.activateUnits, HTTP_METHOD.PUT).then(response => {
            if (response && response.data && response.data.body && response.data.body.message) {
                WoopraEvents(`${Constants.UNIT_ACTIVATED}`);
                this.assignUnits();
            }
        }).catch(err => {
            this.showError(err);
        });
    }

    applySmartTag = () => {
        const { smartTag, selectedTotalUnit } = this.state;
        let getUnitIds = selectedTotalUnit.map((i)=>i.ID);
        let unitIds = [];
        for (let index = 0; index < getUnitIds.length; index++) {
            const element = getUnitIds[index];
            let tags = [];
            for (let index = 0; index < smartTag.length; index++) {
                const smartTagEle = smartTag[index];
                if(smartTagEle && smartTagEle.units && smartTagEle.units.length){
                    if(smartTagEle.units.includes(element)){
                        tags.push(smartTagEle.id);
                    }
                }
            }
            let id = [];
            id.push(element);
            unitIds.push({
                'unitId': id,
                tags
            })
        }
        let postData = {
            unitIds
        }
        this.smartTagApiCall(postData);
    }

    smartTagApiCall(postData) {
        return new Promise((resolve,_)=>{
            http({
                method: 'PUT',
                url: smartTagMangement.assignSmartTag,
                headers: {
                    'customerid': localStorage.getItem("customerId"),
                },
                data: postData
            }).then(res => {
                resolve(res);
            }).catch(err => {
                resolve(err);
            });
        })
    }

    assignUnits() {
        let timeZoneList = this.state.timezonedata.find(el => el.display_name === this.state.TimeZoneForSelNode)
        const timeZoneId = timeZoneList && timeZoneList.time_zone_id ? timeZoneList.time_zone_id : this.state.timezonedata && this.state.timezonedata.length ? this.state.timezonedata[0].time_zone_id : '';
        let putData = {
            unitIds: this.state.selectedUnits,
            CGID: this.state.link.cust_gp_id,
            timeZoneId
        }
        this.putData(putData, unitAPI.assignUnitToGroup, HTTP_METHOD.PUT).then(async response => {
            await this.callEaasService();
            this.setState({ loaderIconVisible: false });
            if (response && response.data && response.data.message) {
                this.setState({
                    userData: response.data,
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: (response.data.stringID || response.data.message) || ""
                    }
                });
                WoopraEvents(`${Constants.ASSIGN_UNITS}`)
            }
            this.props.history.push(`/unitRegistration`);
        }).catch(err => {
            this.showError(err);
        });
    }
    putData = (postData, url, method) => {
        let loggedinUser;
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        loggedinUser = loginUser.username;
        const header = {
            'customerid': localStorage.getItem("customerId"),
            'useremail': loggedinUser,
            'basecountry': localStorage.getItem("basecountry"),
        }
        return new Promise((resolve, reject) => {
            http({
                method: method,
                url: url,
                headers: header,
                data: postData
            }).then(res => {
                if (res && res.status === 200) {
                    resolve(res);
                } else {
                    reject({
                        response: {
                            data: res.data
                        }
                    });
                }
            }).catch(err => {
                reject(err);
            });
        });
    }
    updateLevel(levelList, link, levelItems = [], onsameLevel, stateLink) {
        let updatedList = [];
        let nextLevel = 1;
        if (!levelList) return;
        if (levelList && levelList.length > 0) nextLevel = levelList[0].level_id + 1;
        else {
            nextLevel = levelList.level_id + 1;
        }
        const index = levelList.findIndex((item, index) => {
            if (item.cust_gp_id === link.cust_gp_id) {
                let newLink = {};
                const newItem = item;
                const availableUnits = item.units ? item.units : [];
                newItem.units = availableUnits.concat(this.state.unitList);
                return levelList[index] = newItem;
            }
        })
        const index1 = levelList.findIndex((item, index) => {
            if (item.cust_gp_id === stateLink['cust_gp_id']) {
                const newItem = item;
                const oldStateUnits = [];
                item.units.map((unit) => {
                    if (!unit.checked) {
                        oldStateUnits.push(unit);
                    }
                })
                newItem.units = oldStateUnits;
                return levelList[index] = newItem;
            }
        })
        if (index < 0) {
            levelList.map((l1, index) => { this.updateLevel(l1['level' + nextLevel], link, levelItems, onsameLevel, stateLink) })
        }
        if (levelList && levelList.length > 0 && levelList[0].level_id === 1) {
            return levelList;
        }
    }
    onSamelevelPlusButtonClick(link, onsameLevel = false, stateLink = {}) {
        let upDateList = this.state.linkList;
        upDateList.level1 = this.updateLevel(upDateList.level1, link, [], onsameLevel, stateLink);
        this.treeData.activeNode = null;
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
        return upDateList;
    }
    onItemClick(e, link) {
        $(".addUserHeader > ul > li > .btn-default-text").addClass("activeState");
        if (link.level_id === 5) {
            let timeZoneList = this.state.timezonedata.find(el => el.display_name === link.TimeZone_Display)
            if (timeZoneList) {
                this.setState({
                    TimeZoneForSelNode: link.TimeZone_Display ? link.TimeZone_Display : "",
                    orgTimeZoneForSelNode: link.TimeZone_Display ? link.TimeZone_Display : "",
                }, () => {
                    this.getTimeZoneForSelctedNode(link);
                });
            } else {
                this.getTimeZoneForSelctedNode(link);
            }
            let selectedAddress, pickLocationName = null;
            const stateLink = this.state.link;
            if (link.dynastyAttributes) {
                selectedAddress = JSON.parse(link.dynastyAttributes);
                pickLocationName = selectedAddress.location_name;
            }
            let updateList = this.state.linkList;
            if (link.cust_gp_id != stateLink.cust_gp_id) {
                updateList = this.onSamelevelPlusButtonClick(link, false, stateLink)
            }
            const { flattenTreeData } = this.convertToFlattenTreeData(updateList.level1);
            this.setState({ flattenTreeData, linkList: updateList, link: link, isUnitItemClicked: false, selectedAddress: selectedAddress, selectedLocationName: pickLocationName })
            this.treeData.activeNode = link;
            this.treeData.virtualScrollRef.current.recomputeRowHeights();
        }
    }
    onUnitItemClick(e, unit) {
        const { UNITID, BRAND_NAME } = unit;
        this.setState({
            isUnitItemClicked: false, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6
        });
        this.treeData.activeNode = unit;
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }
    checkSamelevel(pDynasty, cDynasty) {
        let pDyn = [];
        let cDyn = [];
        let sameLevel = true;
        if (pDynasty && pDynasty.length > 0) {
            pDyn = pDynasty.split(',')
        } else {
            sameLevel = false;
        }
        if (cDynasty && cDynasty.length > 0) {
            cDyn = cDynasty.split(',')
        } else {
            sameLevel = false;
        }
        pDyn.map((i, index) => {
            if (pDyn[index] !== cDyn[index]) return sameLevel = false;
        })
        return sameLevel;
    }
    onCollapseButtonClick(e, link) {
        let showLink = this.state.showLink;
        let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
        const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
        if (e.target['className'].includes('collapsed')) {
            collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
        } else {
            collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
        }
        if (findIndex > -1) {
            showLink[findIndex] = collpaseObj;
        } else {
            showLink.push(collpaseObj)
        }
        this.setState({ showLink: showLink })
    }
    OnTimeZonLoad = () => {
        $("#closeTimeZone").click(function () {
            $('#timeZoneSelctOption  option[value="0"]').prop("selected", true);
            document.getElementById('timeZoneSelctOption').value = '';
        })
        $(".dropdownTimeZoneOptions").hide();
        $("#timeZoneSelctOption").focus(function () {
            $(".dropdownTimeZoneOptions").show();
        });
        var timezoneValue;
        $(document).click(function (e) {
            $(".dropdownTimeZoneOptions").hide();
        });
        $('#timeSchedulePanel').click(function (e) {
            e.stopPropagation();
        });
        $(".dropdownTimeZoneOptions  > li > a").click(function (e) {
            e.preventDefault();
            timezoneValue = $(this).text();
            selectedTimeZoneId = $(this).attr("data-value");
            $("#timeZoneSelctOption").val(timezoneValue);
            $(".dropdownTimeZoneOptions").hide();
            $("#OpenedTimeZone").removeClass("disabled");
        });
    }
    componentWillUpdate() {
        this.OnTimeZonLoad();
    }
    selectTimeZone = (e) => {
        let timeZoneList = this.state.timezonedata.find(el => el.display_name === e.target.value);
        this.setState({
            TimeZoneForSelNode: e.target.value,
        });
    }
    onClickNo = (e) => {
        this.validateTagList(this.state.initialSmartTag, this.state.selectedTotalUnit);
        this.identifyInitialTagForUnit(this.state.selectedTotalUnit);
        this.setState({
            TimeZoneForSelNode: this.state.orgTimeZoneForSelNode,
            newAssetNumber: '',
            searchUnitSmartTag:''
        });
    }
    getTimeZoneForSelctedNode = (item) => {
        let url = unitRegister.getSelNodeTimeZone;
        if (item.dynastyAttributes && JSON.parse(item.dynastyAttributes).lat && JSON.parse(item.dynastyAttributes).long) {
            url = url + '?lat=' + JSON.parse(item.dynastyAttributes).lat;
            url = url + '&long=' + JSON.parse(item.dynastyAttributes).long;
        }
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }, data: {}
        }).then(response => {
            if (response && response.data) {
                this.setState({
                    TimeZoneForSelNode: response.data.display_name,
                    orgTimeZoneForSelNode: response.data.display_name,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }
    /**
     * Check if the row has at least one visible node
     * @param {*} rowData Row Array
     * @returns Boolean - Return true if it has visible node else return false
     */
    doesRowHasVisibleNode(rowData) {
        return rowData.some((node, i) => node && node.__NODE_DATA__.isNode && (i < 5 ? !this.getNodeStatus(node).isInvisible : !this.getUnitNodeStatus(node).isInvisible));
    }
    /**
    * Get the Node status like invisible, count node
    * @param {*} node Node object
    * @returns Object Status object
    */
    getNodeStatus(node) {
        const nodeData = node.__NODE_DATA__;
        const dynastyArr = node.dynasty.split(',').map(item => parseInt(item));
        // Visibility, Count Node, Display count calculation
        const dynastyArrCopy = [...dynastyArr];
        dynastyArrCopy.pop(); // Remove its own ID
        dynastyArrCopy.length && dynastyArrCopy.pop(); // Remove parent node ID
        const isForeNodeCollapsed = dynastyArrCopy.some(item => this.treeData.collapsedNodes[item]);
        const isParentCollapsed = this.treeData.collapsedNodes[node.parent_group_id];
        const isFirstChild = nodeData.isFirstChild || nodeData.isOnlyChild;
        const parentNode = nodeData.parentRowIndex !== null ? this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex] : null;
        // Active status, Root Node active status calculation
        let isRootActiveNode = false;
        let isActive = false;
        if (this.treeData.activeNode && this.treeData.activeNode.__NODE_DATA__.colIndex < 5) {
            isActive = dynastyArr.indexOf(this.treeData.activeNode.cust_gp_id) >= 0;
            isRootActiveNode = node.cust_gp_id === this.treeData.activeNode.cust_gp_id;
        }
        return {
            isInvisible: isForeNodeCollapsed || (isParentCollapsed && !isFirstChild),
            isCountNode: !isForeNodeCollapsed && isParentCollapsed && isFirstChild,
            displayCount: parentNode ? parentNode.child_count : 0,
            isRootActiveNode,
            isActive
        };
    }
    /**
     * Row render callback for Virtual Scroll component
     * @param {*} param Event object
     * @returns ReactDOM
     */
    treeViewRowRenderer({ index, key, style }) {
        const { flattenTreeData } = this.state;
        const rowData = flattenTreeData[index];
        if (!this.doesRowHasVisibleNode(rowData)) {
            return <div className="treeViewRow" key={key} style={style}></div>
        }
        return <div className="treeViewRow" key={key} style={style}>
            {new Array(5).fill().map((_, j) => this.getNodeView(rowData[j], { key: `${key}_${j}` }))}
            {this.getUnitNodeView(rowData[5], { key: `${key}_5` })}
        </div>
    }
    /**
     * Create view elements for Tree View Unit Node
     * @param {*} unitNode Unit node object
     * @param {*} options Additional options
     * @returns ReactDOM
     */
    getUnitNodeView(unitNode, { key }) {
        // For empty
        if (!unitNode) {
            return <div key={key} className="treeViewCell"></div>
        }
        // For node
        const draggableProps = {
            draggable: true,
            onDragStart: (e) => this.onDragStart()
        };
        const status = this.getUnitNodeStatus(unitNode);
        if (status.isInvisible) {
            return <div key={key} className="treeViewCell"></div>
        } else if (status.isCountNode) {
            const { showElementInfo } = this.state;
            const activeClass = status.isActive ? 'active' : '';
            const rootActiveClass = status.isRootActiveNode ? 'treeActive' : '';
            return <div key={key} className={`treeViewCell unitCell countCell ${activeClass} ${rootActiveClass}`}>
                <Link
                    className="treeViewNode"
                    to="#"
                    onDoubleClick={() => { this.setState({ showElementInfo: !showElementInfo }) }}
                    onClick={(e) => { this.onUnitItemClick(e, unitNode) }}>
                    {status.displayCount}
                </Link>
            </div>
        }
        const { showElementInfo, link } = this.state;
        //this.showUnits(link);
        const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
        const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unitNode.BRAND_NAME && i.MODEL_NAME === unitNode.MODEL_NAME) : [];
        let unitImage = '';
        if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "") {
            unitImage = filteredBrandImage[0].LOGO_URL;
        } else {
            unitImage = brandsMap[unitNode.MODEL_NAME] ? brandsMap[unitNode.MODEL_NAME].img : bg;
        }
        let checkUnitsIDsSelected = this.state.totalUnits && this.state.totalUnits.findIndex((item => unitNode.UNITID == item.UNITID));
        const activeValueKey  = unitNode?.subscription_status !== null && unitNode?.subscription_status !== 'null' ? unitNode.subscription_status === 0 ? 'INACTIVE' : 'ACTIVE' : unitNode.ACTIVATION_STATUS;
        const isUnitInActive = activeValueKey === 'INACTIVE-ONLINE' || activeValueKey === 'INACTIVE';
        const nodeData = unitNode.__NODE_DATA__;
        const { isFirstChild, isMidwayChild, isLastChild } = nodeData;
        const firstChildClass = isFirstChild ? 'firstChild' : '';
        const midwayChildClass = isMidwayChild ? 'midwayChild' : '';
        const lastChildClass = isLastChild ? 'lastChild' : '';
        const activeClass = status.isActive && checkUnitsIDsSelected != -1 ? 'active' : '';
        const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
        return <div key={key} className={`treeViewCell unitCell ${firstChildClass} ${midwayChildClass} ${lastChildClass} ${activeClass} ${rootActiveClass}`}>
            <Link
                to="#"
                className={`treeViewNode ${isUnitInActive ? 'inActiveUnitNode' : ''}`}
            >
                <img className="nodeImg" src={unitImage} />
                <span className="nodeInfo">
                    <b className="d-block text-truncate unitSerialNo">{unitNode.UNITID}</b>
                    <b className="d-block text-truncate">{unitNode.MODEL_NAME}</b>
                    {/* {
                        nodeData.smartGroupsName.map((item, i) => {
                            return <span key={i} className="sgIndicator">{item.sgFirstName}&nbsp;</span>
                        })
                    } */}
                </span>
                    {isUnitInActive ? <span className="inActiveIcon"></span> : ''}
            </Link>
        </div>
    }
    /**
     * Tree View node expand/collapse click handler
     * @param {*} node Node object
     */
    handleExpandCollapse(node) {
        if (!this.treeData.collapsedNodes[node.cust_gp_id]) {
            this.treeData.collapsedNodes[node.cust_gp_id] = true;
        } else {
            delete this.treeData.collapsedNodes[node.cust_gp_id];
        }
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }
    /**
     * Return the computed Tree View row height
     * Compute the height based on row content
     * @param {*} param Event object
     * @returns Number
     */
    getTreeViewRowHeight({ index }) {
        const rowData = this.state.flattenTreeData[index];
        if (!this.doesRowHasVisibleNode(rowData)) {
            return 0;
        }
        // Compute row height based on unit node
        const unitNode = rowData[5];
        const status = unitNode ? this.getUnitNodeStatus(unitNode) : null;
        let rowBaseHeight = this.treeData.rowBaseHeight;
        if (unitNode && !status.isInvisible && !status.isCountNode) {
            if (unitNode.UNIT_NUMBER && unitNode.MODEL_NAME) {
                rowBaseHeight = rowBaseHeight + 15;
            }
            if (unitNode.__NODE_DATA__.smartGroupsName.length) {
                rowBaseHeight = rowBaseHeight + 22;
            }
        }
        return rowBaseHeight;
    }
    /**
     * Create view elements for Tree View Node
     * @param {*} node Node object
     * @param {*} options Additional options
     * @returns ReactDOM
     */
    getNodeView(node, { key }) {
        // For empty or connector
        if (!node) {
            return <div key={key} className="treeViewCell"></div>
        } else if (node.__NODE_DATA__.isConnector) {
            const parentNode = this.state.flattenTreeData[node.__NODE_DATA__.parentRowIndex][node.__NODE_DATA__.parentColIndex];
            const activeClass = this.getNodeStatus(parentNode).isActive ? 'active' : '';
            return <div key={key} className={`treeViewCell connector ${activeClass}`}></div>
        }
        // For node
        const nodeData = node.__NODE_DATA__;
        const draggableProps = {
            draggable: true,
            onDragStart: (e) => this.onDragLevelStart(node),
            onDragOver: (e) => this.onDragOver(e),
            onDrop: (e) => {
                // Get drop node siblings
                let parentNode = node;
                if (nodeData.parentRowIndex !== null) {
                    // If dropped into a root node
                    parentNode = this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex];
                }
                const nodeWithHierarchy = this.getNodeFromHierarchyData(parentNode);
                const nodeSiblings = nodeWithHierarchy[`level${parentNode.level_id + 1}`];
                return this.onDrop(node, nodeSiblings || []);
            }
        };
        const status = this.getNodeStatus(node);
        if (status.isInvisible) {
            return <div key={key} className="treeViewCell"></div>
        } else if (status.isCountNode) {
            const activeClass = status.isActive ? 'active' : '';
            const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
            return <div key={key} className={`treeViewCell countCell ${activeClass} ${rootActiveClass}`}>
                <div className="treeViewNodeWrapper" {...draggableProps}>
                    <Link
                        className="treeViewNode"
                        to="#"
                        onClick={(e) => { this.onItemClick(e, this.getNodeFromHierarchyData(node)) }}>
                        {status.displayCount}
                    </Link>
                </div>
            </div>
        }
        const dynastyAttributes = node.dynastyAttributes ? JSON.parse(node.dynastyAttributes) : {};
        const nodeImage = node.level_id && (node.level_id === 5 || node.level_id === 4) && brandLocationsMap[dynastyAttributes.location_type] && brandLocationsMap[dynastyAttributes.location_type].img ?  brandLocationsMap[dynastyAttributes.location_type].img : node.imagePath || (brandLocationsMap[dynastyAttributes.location_type]
            ? brandLocationsMap[dynastyAttributes.location_type].img
            : bg);
        const { isFirstChild, isMidwayChild, isLastChild, hasChildren, unitCount } = nodeData;
        const isCollapsed = this.treeData.collapsedNodes[node.cust_gp_id];
        const firstChildClass = isFirstChild ? 'firstChild' : '';
        const midwayChildClass = isMidwayChild ? 'midwayChild' : '';
        const lastChildClass = isLastChild ? 'lastChild' : '';
        const hasChildrenClass = hasChildren ? 'hasChildren' : '';
        const activeClass = status.isActive ? 'active' : '';
        const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
        const hasChildrenClassVisibile = hasChildrenClass == '' ? unitCount ? 'hasChildren' : hasChildrenClass : hasChildrenClass;
        return <div key={key} className={`treeViewCell ${firstChildClass} ${midwayChildClass} ${lastChildClass} ${hasChildrenClassVisibile} ${activeClass} ${rootActiveClass}`}>
            <div className="treeViewNodeWrapper" {...draggableProps}>
                <Link
                    className="treeViewNode"
                    to="#"
                    onClick={(e) => { this.onItemClick(e, this.getNodeFromHierarchyData(node), nodeImage) }}>
                    <img className="nodeImg" src={nodeImage} alt="" />
                    <span className="text-truncate nodeInfo"><b>{node.group_name}</b></span>
                </Link>
                {hasChildren || hasChildren == "" && unitCount ? <button className={`expandCollapseBtn ${isCollapsed ? 'collapsed' : ''}`} onClick={() => this.handleExpandCollapse(node)}></button> : ''}
            </div>
        </div>
    }
    /**
         * Get Node object from Hierarchy Data Structure
         * Use this method to retain the props removed during flatten array conversion
         * @param {*} searchNode Node to search
         * @returns Node object
         */
    getNodeFromHierarchyData(searchNode) {
        const dynastyArr = searchNode.dynasty.split(',').map(item => parseInt(item));
        let node = this.state.linkList;
        for (let i = 0; i < dynastyArr.length; i++) {
            const nodes = node[`level${i + 1}`];
            for (let j = 0; j < nodes.length; j++) {
                if (nodes[j].cust_gp_id === dynastyArr[i]) {
                    node = nodes[j];
                    break;
                }
            }
        }
        node = { ...node };
        node.__NODE_DATA__ = { ...searchNode.__NODE_DATA__ };
        return node;
    }
    /**
     * Get the Unit Node status like invisible, count node
     * @param {*} unitNode Unit Node object
     * @returns Object Status object
     */
    getUnitNodeStatus(unitNode) {
        const nodeData = unitNode.__NODE_DATA__;
        const parentNode = this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex];
        const dynastyArr = parentNode.dynasty.split(',').map(item => parseInt(item));
        // Visibility, Count Node, Display count calculation
        const dynastyArrCopy = [...dynastyArr];
        dynastyArrCopy.pop(); // Remove its own ID
        const isForeNodeCollapsed = dynastyArrCopy.some(item => this.treeData.collapsedNodes[item]);
        const isParentCollapsed = this.treeData.collapsedNodes[parentNode.cust_gp_id];
        const isFirstChild = nodeData.isFirstChild || nodeData.isOnlyChild;
        // Active status, Root Node active status calculation
        let isRootActiveNode = false;
        let isActive = false;
        if (this.treeData.activeNode) {
            if (this.treeData.activeNode.__NODE_DATA__.colIndex < 5) {
                isActive = dynastyArr.indexOf(this.treeData.activeNode.cust_gp_id) >= 0;
                isRootActiveNode = false;
            } else {
                isActive = unitNode.UNITID === this.treeData.activeNode.UNITID;
                isRootActiveNode = true;
            }
        }
        // Selection status
        // const isSelected = this.state.selectedUnitList.findIndex(item => item.UNITID === unitNode.UNITID) >= 0;
        return {
            isInvisible: isForeNodeCollapsed || (isParentCollapsed && !isFirstChild),
            isCountNode: !isForeNodeCollapsed && isParentCollapsed && isFirstChild,
            displayCount: parentNode.child_count,
            isRootActiveNode,
            isActive
        };
    }
    /*  */
    handleManualExpandCollapse = (levelID, data, makeCollapse, testvalue) => {
        this.treeData.active = levelID
        _.forEach(data, item => {
            _.forEach(item, node => {
                if (node && node.category_id == levelID && node.child_count > 0 && makeCollapse) {
                    if (!this.treeData.collapsedNodes[node.cust_gp_id]) {
                        this.treeData.collapsedNodes[node.cust_gp_id] = true;
                        this.treeData.expand[node.level_id] = true;
                    } else {
                        delete this.treeData.collapsedNodes[node.cust_gp_id];
                        delete this.treeData.expand[node.level_id];
                    }
                }
            })
        })
        if(this.treeData && this.treeData.virtualScrollRef && this.treeData.virtualScrollRef.current && this.treeData.virtualScrollRef.current.recomputeRowHeights){
            this.treeData.virtualScrollRef.current.recomputeRowHeights();
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        let treeHeadertextChanged = false;
        let { success_modal, error_modal, link, isFormValidated, selectedUnits, assignedUnits, selectedAddress, selectedLocationName, isUnitItemClicked, flattenTreeData } = this.state;
        let pickUnit, locationTypeCheck, picLocation = '';
        const { linkList, loaderIconVisible, timezonedata, TimeZoneForSelNode, showOrgPopup, showpopup, type } = this.state;
        isFormValidated = link.category_name;
        if (isFormValidated) {
            $("#btnfinish").addClass("btn-default-text activeState");
            $("#btnfinish").removeClass("disabled");
        } else {
            $("#btnfinish").removeClass("btn-default-text activeState");
            $("#btnfinish").addClass("disabled");
        }
        // $(".styles_overlay__CLSq-").removeClass("styles_overlay__CLSq-");
        // $("body").css("background-color: #2D3034");
        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map(item => <li>
                        <Link className={item.level_id === 1 ? "" : this.treeData.expand[item.level_id - 1] ? `collapseExpandHeader ${item.level_id - 1 === this.treeData?.active ? "activeArrow" : ''}` : "collapseExpandHeader expanded"} key={item.level_id} to="#" title={formatMessage({ id: 'KC1693' }, { number: item.level_id })} onClick={() => this.handleManualExpandCollapse(item.level_id - 1, this.state.flattenTreeData, true)}>{item.category_name}</Link>
                    </li>)
                );
            }
        }
        //User Story 48712: KC Portal - Unit registration flow improvement
        let level5Header = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                level5Header = (
                    categoryList.filter(el => el.level_id === 5).map(item => item.category_name)
                );
                treeHeadertextChanged = true;
            }
        }
        let isSmartTagAccess = authService.getFeature("/smartTag/tagList");
        return (
            <React.Fragment>
                <div id="adminWrapper" >
                    <div className={loaderIconVisible && treeHeadertextChanged ? "loadingProgress showloader" : loaderIconVisible && !treeHeadertextChanged ? "loadingProgress showloader" : !loaderIconVisible && !treeHeadertextChanged  ? "loadingProgress showloader" : "loadingProgress" }>
                        <div className="loadingProgressIn"></div>
                    </div>
                    <div id="page-content-wrapper" className='withSearchBar'>
                        <div className="orgznManagementWrapper">
                            <div className="assignOrgHeader">
                                <ul>
                                    <li>
                                        <Link to={{
                                            pathname: this.props.userModel.assignUserBackPage,
                                            data: this.props.userModel.roleId
                                        }} title={formatMessage({ id: 'KC0989' })} onClick={(e) => this.setUserToState(e)} className="backIcon " >&nbsp;</Link>
                                    </li>
                                    <li>
                                        <FormattedMessage id="KC1091" values={{ level: level5Header }} />
                                    </li>
                                    <li>
                                        <button id="#btnfinish" title={formatMessage({ id: 'KC0543' })} className={`btn btn-default-text ${isFormValidated ? "activeState" : "disabled"}`} data-toggle="modal" data-target="#timeZoneAssignModal" onClick={() => { }}><FormattedMessage id="KC0543" /></button>
                                    </li>
                                </ul>
                            </div>
                            <div className="collapseExpandHeader flatten">
                                <ul>
                                    {levelHeader}
                                </ul>
                            </div>
                            <div className="assignOrgTreeWrapper">
                                <div className="orgznSiteTree">
                                    <div className="treeViewBody">
                                        <AutoSizer>
                                            {({ height, width }) => (
                                                <List
                                                    ref={this.treeData.virtualScrollRef}
                                                    width={width || 100}
                                                    height={height || 100}
                                                    overscanRowCount={this.treeData.virtualScrollBufferRowCount}
                                                    rowCount={flattenTreeData.length}
                                                    rowHeight={this.getTreeViewRowHeight}
                                                    rowRenderer={this.treeViewRowRenderer} />
                                            )}
                                        </AutoSizer>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                    <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                    <UserManagementHeader headerName="Unit Registration and Assignment" headerNameStringID="KC1684" previousPage="Unit Registration" activeClass="UnitRegistrationNav" />
                </div>
                {/* <!-- Time Zone Modal --> */}
                <div class="modal fade unitAssignModal" data-keyboard="true" data-backdrop="static" id="timeZoneAssignModal" tabindex="-1" role="dialog" aria-labelledby="confirmationAssignModalLabel">
                    <div class={`modal-dialog ${isSmartTagAccess && isSmartTagAccess.is_editable  === 1 && this.state.smartTag && this.state.smartTag.length ? "modal-lg" : "modal-lg"}`}>
                        {this.popupModal(timezonedata, TimeZoneForSelNode, formatMessage, isSmartTagAccess)}
                    </div>
                </div>
                {/* <!-- //Time Zone Modal --> */}
                {this.state.showOrgPopup ? <TagOrgModal details={this.state.showpopup && this.state.showpopup.length ? this.state.showpopup[0] : {}} /> : null}
                {showpopup && showpopup.length ? this.loadTagPopup(showpopup, type, formatMessage) : null}
                {/* <!-- assign Confirmation Modal --> */}
                {/* <div id="confirmationDeleteModal" className="modal fade confirmationDeleteModal confirmationAssignModal" data-keyboard="true" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                {assignedUnits && <div className="deleteConfirmation">
                                    <FormattedMessage
                                        id="KC1090"
                                        values={{ assignedUnits: assignedUnits.length, locationName: selectedLocationName, categoryName: link.category_name, groupName: link.group_name }} />
                                </div>}
                            </div>
                            <div className="assignUpdateForm">
                                <ul>
                                    <li id="timeSchedulePanel">
                                        {/* <input type="text" className="form-control timeZoneSelctOption" id="timeZoneSelctOption" autocomplete="off" placeholder="Time Zone" name="timeZone" maxlength="8" /> */}
                                        {/* <ul id="timezoneinfo" className="dropdownTimeZoneOptions">
                                                    {
                                                        timezonedata && timezonedata.length > 0 && timezonedata.map((value) =>
                                                            <li><a href="javascript:void(0)" data-value={value.time_zone_id}>{value.display_name}</a></li>
                                                        )
                                                    }                                                    
                                                </ul> *
                                        <select className="form-control" id="selectBrandId" onChange={(e) => this.selectTimeZone(e)}>
                                            {/* User Story 60186: KC Portal: Assign units screen for change in drop down to bring only 45 standard time zones.*
                                            {timezonedata && timezonedata.length > 0 && timezonedata.filter(function (item) {
                                                return item.Kc_Display_Flag === 1;
                                            }).map((item, i) =>
                                                <option key={i} value={item.display_name} selected={TimeZoneForSelNode && TimeZoneForSelNode.toUpperCase() === item.display_name.toUpperCase() ? true : false}>{item.display_name}</option>
                                            )}
                                        </select>
                                    </li>
                                </ul>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" onClick={(e) => this.onClickNo(e)} type="button"><FormattedMessage id="KC0580" /></button>
                                <button className="btn btn-secondary deleteYesBtn" data-dismiss="modal" onClick={(e) => this.assignUnitToTopology(e)} type="button"><FormattedMessage id="KC0611" /></button>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <!-- //assign Confirmation --> */}
            </React.Fragment>
        )
    }

    downloadUnitList = (tagName) => {
        this.setState({ loaderIconVisible: true });
        let url = smartTagMangement.getPresignedUrl;
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
		if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag='yes';
        }
        url = url + `?registrationFlag=${registrationFlag}&tagName=${tagName}&isView=yes`;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'customerid': localStorage.getItem("customerId"),
                'cgid': localStorage.getItem("custGroupID"),
                'brandid': BRAND_ID,
            }, data: {}
        }).then(async response => {
            if (response && response.data && response.data.data) {
                this.setState({ loaderIconVisible: false });
                if (response.data.data.preSignedUrl && response.data.data.preSignedUrl !== '#') {
                    window.location = response.data.data.preSignedUrl;
                }
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    loadTagPopup(showpopup, type, formatMessage) {
        return <div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="stUnitDetailsModal" tabindex="-1" role="dialog" aria-labelledby="stUnitDetailsModalLabel">
            <div className="modal-dialog modal-md">
                <div className="modal-content">
                    <div className="modal-header modal-header-danger">
                        <FormattedMessage id="KC2312" />
                    </div>
                    <div className="modal-body">
                        {showpopup && showpopup.map((item, i) => <div className="reportDetailsD">
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li>
                                        <div className={type === "orgList" ? "tagTypeLocation" : "tagTypeUnit"}><span>{item.tagName}</span></div>
                                    </li>
                                </ul>
                            </div>
                            <h6><FormattedMessage id="KC0195" /></h6>
                            <p>{item.description ? item.description : 'NA'}</p>
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li>
                                        <p><FormattedMessage id="KC2313" /> <button type="button" onClick={() => $('#stUnitDetailsModal').modal('hide')} data-toggle="modal" data-target="#orgViewModal" className={(item.allcgids && item.allcgids.length === 0) || (item.allunitIds && item.allunitIds.length === 0) ? "orgViewIcon btn disabled" : "orgViewIcon btn"}><img src={require('../../../images/icons/iconUnitSelection.svg').default} /></button> {type === 'orgList' ? formatMessage({ id: "KC2306" }) : formatMessage({ id: "KC2307" })}</p>
                                    </li>
                                    {type === 'orgList' ? "" :
                                        <li>
                                            <Link to='#' onClick={() => this.downloadUnitList(item.tagName)} className="downloadListof"><FormattedMessage id="KC2314" /></Link>
                                        </li>}
                                </ul>
                            </div>
                            {(item.allcgids && item.allcgids.length === 0) || (item.allunitIds && item.allunitIds.length === 0) ? <p class="notUsed"><FormattedMessage id='KC2357' /></p> : null}
                            <div className="createdEditedDetailsO">
                                <ul className="tHead">
                                    <li><FormattedMessage id="KC0589" /></li>
                                    <li><FormattedMessage id="KC1278" /></li>
                                </ul>
                                <ul className="tBody">
                                    <li>
                                        <div className="createdEditedDetails">
                                            <ul>
                                                <li><FormattedMessage id="KC0241" />:</li>
                                                <li>{item.createdByName ? item.createdByName : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0136" />:</li>
                                                <li>{item.createdBy ? item.createdBy : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0298" />:</li>
                                                <li>{item.createdAt ? moment.GetDateTimeByFormat(item.createdAt, 'LLL') : ''}</li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="createdEditedDetails">
                                            <ul>
                                                <li><FormattedMessage id="KC0241" />:</li>
                                                <li>{item.updatedByName ? item.updatedByName : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0136" />:</li>
                                                <li>{item.updatedBy ? item.updatedBy : ''}</li>
                                            </ul>
                                            <ul>
                                                <li><FormattedMessage id="KC0298" />:</li>
                                                <li>{item.UpdatedAt ? moment.GetDateTimeByFormat(item.UpdatedAt, 'LLL') : ''}</li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>)}
                    </div>
                    <div className="modal-footer textAlignCenter">
                        <button className="btn btn-secondary okBtn" data-dismiss="modal" onClick={() => this.setState({ showpopup: [], showOrgPopup: false })} type="button"><FormattedMessage id="KC0777" /></button>
                    </div>
                </div>
            </div>
        </div >;
    }

    // hanleCheckboxForUnitTag = (id) => {
    //     let tagArr = this.state.selectedSmartUnitTag;
    //     if (tagArr.includes(id)) {
    //         const index = tagArr.findIndex((i) => i === id);
    //         tagArr.splice(index, 1);
    //     } else {
    //         tagArr.push(id);
    //     }
    //     this.setState({ selectedSmartUnitTag: tagArr})
    // }

    hanleCheckboxForUnitTag = (tag) => {
        let tagList = this.state.smartTag;
        let selectedSmartTagUnit = this.state.selectedSmartUnitTag;
        let totalUnits = this.state.selectedTotalUnit.map((i)=>i.ID)
        const index = tagList.findIndex(function(object) {
            return object.id === Number(tag.id);
        });
        tagList[index]['units'] = tagList[index]['units'].length ? [] : totalUnits;
        console.log("tagEle selectedSmartTagUnit start", selectedSmartTagUnit)

        if(selectedSmartTagUnit.length){
            // if(tagList[index]['units'].length){
            if(selectedSmartTagUnit.includes(Number(tag.id))){
                const STIndex = selectedSmartTagUnit.findIndex(function(object) {
                    return object === Number(tag.id);
                }); 
                // selectedSmartTagUnit.splice(STIndex, 1)
                selectedSmartTagUnit.push(tag.id)
            }else{
                selectedSmartTagUnit.push(tag.id)
            }
        }else{
            selectedSmartTagUnit.push(tag.id)
        }
        console.log("tagEle selectedSmartTagUnit", selectedSmartTagUnit)
        console.log("tagEle final", tagList)
        this.setState({ smartTag: tagList, selectedSmartUnitTag: selectedSmartTagUnit })
    }

    popupModal(timezonedata, TimeZoneForSelNode, formatMessage, isSmartTagAccess) {
        let { selectedTotalUnit, newAssetNumber, smartTag, searchUnitSmartTag } = this.state;
        let { BRAND_NAME = '', MODEL_NAME = '', UNITID = '', PRODUCT_TYPE = '' } = selectedTotalUnit && selectedTotalUnit.length ? selectedTotalUnit[0] : {};
        let dataList = smartTag;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        const searchedItemInFilter = dataList && dataList.filter(
            (tag) => {
                return tag.tagName.toLowerCase().indexOf(this.state.searchUnitSmartTag.toLowerCase()) !== -1;
            }
        );
        dataList = searchedItemInFilter;
        dataList = _.orderBy(dataList, ['units','tagName'], ['desc']);
        return <div class="modal-content">
            <div class="modal-header modal-header-danger">
                <FormattedMessage id="KC1505" />
            </div>
            <div class="modal-body">
                <div class="unitRegUnitDetails">
                    <div class="unitRegForm floatLeft">
                        <ul>
                            {selectedTotalUnit && selectedTotalUnit.length === 1 ? <li>
                                <div class="labelL"><FormattedMessage id='KC0062' />:</div>
                                <div class="input-group valueR">
                                    <input type="text" class="form-control disabled" value={BRAND_NAME} />
                                </div>
                            </li> : null}
                            {selectedTotalUnit && selectedTotalUnit.length === 1 ? <li>
                                <div class="labelL"><FormattedMessage id='KC0673' />:</div>
                                <div class="input-group valueR">
                                    <input type="text" class="form-control disabled" value={MODEL_NAME} />
                                </div>
                            </li> : null}
                            {selectedTotalUnit && selectedTotalUnit.length === 1 ? <li>
                                <div class="labelL"><FormattedMessage id='KC0102' />:</div>
                                <div class="input-group valueR">
                                    <input type="text" class="form-control disabled" value={PRODUCT_TYPE} />
                                </div>
                            </li>:null}
                            {selectedTotalUnit && selectedTotalUnit.length === 1 ? <li>
                                <div class="labelL"><FormattedMessage id='KC0104' />:</div>
                                <div class="input-group valueR">
                                    <input type="text" class="form-control disabled" value={UNITID} />
                                </div>
                            </li>:null}
                            {selectedTotalUnit && selectedTotalUnit.length === 1 ? <li>
                                <div class="labelL fWeightB"><FormattedMessage id='KC0105' />:</div>
                                <div class="input-group valueR">
                                    <input type="text" class="form-control" placeholder={formatMessage({ id: 'KC2361' })} value={newAssetNumber} onChange={(e) => this.setState({ newAssetNumber: e.target.value, isChangeHappened: true })} />
                                </div>
                            </li>:null}
                            <li>
                                <label class="fWeightB"><FormattedMessage id='KC2359' /></label>
                                <div class="input-group">
                                    <select className="form-control" id="selectBrandId" onChange={(e) => this.selectTimeZone(e)}>
                                        {/* User Story 60186: KC Portal: Assign units screen for change in drop down to bring only 45 standard time zones.*/}
                                        {timezonedata && timezonedata.length > 0 && timezonedata.filter(function (item) {
                                            return item.Kc_Display_Flag === 1;
                                        }).map((item, i) => <option key={i} value={item.display_name} selected={TimeZoneForSelNode && TimeZoneForSelNode.toUpperCase() === item.display_name.toUpperCase() ? true : false}>{item.display_name}</option>
                                        )}
                                    </select>
                                </div>

                            </li>
                        </ul>
                    </div>
                    {smartTag && smartTag.length ?<div class="smartTagsList">
                        <div class="STunitsTable">
                            <ul class="tHead">
                                <li>
                                    <div class="tableSerarchBar">
                                        <input class="form-control tagsSearch" type="text" placeholder={formatMessage({ id: "KC2618" })} value={searchUnitSmartTag}  onChange={(e) => this.setState({ searchUnitSmartTag: e.target.value })}/>
                                    </div>
                                </li>
                                <li><FormattedMessage id='KC0325' /></li>
                                <li><FormattedMessage id='KC2131' /></li>
                            </ul>
                        </div>
                        <div class="STunitsTableSrlBar">
                            <div class="STunitsTable">
                                {dataList && dataList.length ? dataList.map((tag, index) => {
                                    // const isChecked = this.state.selectedSmartUnitTag && this.state.selectedSmartUnitTag.length && this.state.selectedSmartUnitTag.includes(tag.id) ? true : false;
                                    const isChecked = tag.units.length ? true : false;
                                    return (
                                        <ul key={index} className={`tBody ${isChecked ? 'activeRow' : ''}`}>
                                            <li><div class="tagTypeUnit"><span>{tag.tagName}</span></div></li>
                                            <li><button type="button" class="btn stUnitsDetails" onClick={async () => {
                                                            let smartTagObj = {
                                                                mode: 'isView',
                                                                type: 'unitList',
                                                                selectedRow: tag
                                                            }
                                                            await localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
                                                            let showpopup = [];
                                                            showpopup.push(tag);
                                                            this.setState({ showpopup, showOrgPopup: true, type: 'unitList' }, () => {
                                                                $('#stUnitDetailsModal').modal('show');
                                                            });
                                                        }}>&nbsp;</button></li>
                                            <li>
                                                <div class={`customCheckbox customCheckboxWOlabel ${tag.units.length > 1 && this.state.selectedTotalUnit.length === tag.units.length && this.state.selectedSmartUnitTag.includes(tag.id) ? "" : this.state.selectedTotalUnit.length === 1 ? "" :  "selectAllDivD"}`}>
                                                    <input id={tag.tagName + 'unitView'} name={tag.tagName + 'unitView'} type="checkbox" checked={isChecked} onChange={(e) => this.hanleCheckboxForUnitTag(tag)} />
                                                    <label for={tag.tagName + 'unitView'}></label>
                                                </div>
                                            </li>
                                        </ul>
                                    )
                                }) : <div className='alignmentCenter'><FormattedMessage id='KC0791' /></div>}
                            </div>
                        </div>
                    </div>: null}
                </div>
            </div>
            <div class="modal-footer textAlignCenter">
                <button class="btn btn-secondary noBtn" data-dismiss="modal" type="button" onClick={(e) => this.onClickNo(e)}><FormattedMessage id="KC0021" /></button>
                <button onClick={(e) => this.assignUnitToTopology(e)} class="btn btn-secondary yesBtn" type="button"><FormattedMessage id="KC0558" /></button>
            </div>
        </div>;
    }
};
function mapStateToProps(state) {
    // 
    return {
        userModel: state.userReducer.UserModel,
        searchData: state.advanceSearch.searchData
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel, searchVisible, searchText
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignUnitToTopology));