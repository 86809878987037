import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import Switch from 'react-switch';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import InputMask from 'react-input-mask';
import $ from 'jquery';
import appconstants from '../../../../../utils/appConstants';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

class Scheduler extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            scheduler_enabled: "false",
            scheduler_configurations: [],
            disableAddStep:false,
            selected_unit: '',
            time_mask : [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/]
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.onSchedulerDayEnabled = this.onSchedulerDayEnabled.bind(this);
        this.onSchedulerDaySelect = this.onSchedulerDaySelect.bind(this);
        this.onDeleteStep = this.onDeleteStep.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.handleParamChange = this.handleParamChange.bind(this);
        this.handleParamCheck = this.handleParamCheck.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let jsonSchedulerDetails = localStorage.getItem("schedulerDetails");
            let ObjSchedulerDetails = JSON.parse(jsonSchedulerDetails);

            let jsonSettingsFile = localStorage.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            let jsonLibraryDetails = localStorage.getItem("libraryDetails");
            let ObjLibraryDetails = JSON.parse(jsonLibraryDetails);
            
            var foundIndex = -1;
            if (ObjSchedulerDetails.length > 0) {
                foundIndex = ObjSchedulerDetails.findIndex(s => s.day === ObjLibraryDetails.day);
            }
            
            this.setState({
                scheduler_enabled: ObjSchedulerDetails[foundIndex] ? ObjSchedulerDetails[foundIndex].enabled : "false",
                scheduled_day: ObjSchedulerDetails[foundIndex] ? ObjSchedulerDetails[foundIndex].day : appconstants.monday,
                scheduler_configurations: ObjSchedulerDetails[foundIndex] ? ObjSchedulerDetails[foundIndex].configurations : [],
                selected_unit: ObjSettingsFile.units.replace('deg', '° ')
            })
            if (ObjSchedulerDetails[foundIndex]) {
                if (ObjSchedulerDetails[foundIndex].configurations.length === 12) {
                    this.setState({
                        disableAddStep: true
                    });
                }
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    handleParamChange = (e) => {
        const { selected_unit } = this.state;
        this.setState({
            isChanged: true
        });
        if (["form-control temp"].includes(e.target.className)) {
            let scheduler_configurations = [...this.state.scheduler_configurations];
            scheduler_configurations[e.target.dataset.id][e.target.className.replace("form-control ", "")] = e.target.value.match(/[+-]?([0-9]*[.])?[0-9]+/) ? e.target.value > 0 ? selected_unit.includes('F') && e.target.value === '32' ? 'OFF' : Math.round(e.target.value) : 'OFF' : Math.round(e.target.value);
            this.setState({ scheduler_configurations: scheduler_configurations });
        } 
        else if (["form-control time"].includes(e.target.className)) {
            let scheduler_configurations = [...this.state.scheduler_configurations];
            let targetValue = e.target.value.replace('__:__', '00:01').replace('_:__', '0:00').replace(':__', ':00').replace('_', '0');
            scheduler_configurations[e.target.dataset.id][e.target.className.replace("form-control ", "")] = targetValue ? (parseInt(targetValue.replace(':',''))  > 2359 || (targetValue[0] === '2' && parseInt(targetValue[1]) > 3)) ? "23:59" : targetValue : targetValue;
            
            this.setState({ 
                scheduler_configurations: scheduler_configurations 
            })
        }
        else if (["form-control instruction"].includes(e.target.className)) {
            let scheduler_configurations = [...this.state.scheduler_configurations];
            scheduler_configurations[e.target.dataset.id][e.target.className.replace("form-control ", "")] = e.target.value;

            this.setState({ 
                scheduler_configurations: scheduler_configurations 
            })
        }
        else {
            this.setState({ [e.target.name]: e.target.value.toUpperCase() })
        }
    }

    handleParamCheck = (e) => {
        this.setState({
            isChanged: true
        });
        const modelFamilyID = {
            'dev' : '81',
            'other': '41'
        }
        const hostname = window && window.location && window.location.hostname;
        let backendHost = hostname === 'www.welbiltdigitaldev.com' || hostname === 'welbiltdigitaldev.com' ? 'dev' : 'other';

        const maxCelsiusValue = localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? 260 : 275;
        const maxFarenheitValue = localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? 500 : 527;
        if (["form-control temp"].includes(e.target.className)) {
            let scheduler_configurations = [...this.state.scheduler_configurations];
            let tempvalue = e.target.value.match(/[+-]?([0-9]*[.])?[0-9]+/) ? parseInt(e.target.value) : e.target.value !== 'OFF' ? 0 : e.target.value;
            if (this.state.selected_unit === '° C') {
                scheduler_configurations[e.target.dataset.id][e.target.className.replace("form-control ", "")] = (tempvalue === 0 ? "OFF" : tempvalue !== 'OFF' ? tempvalue < 100 ? 100 : tempvalue > maxCelsiusValue ? maxCelsiusValue : Math.round(tempvalue) : tempvalue);
            }
            else {
                scheduler_configurations[e.target.dataset.id][e.target.className.replace("form-control ", "")] = (tempvalue === 0 ? "OFF" : tempvalue !== 'OFF' ? tempvalue < 212 ? 212 : tempvalue > maxFarenheitValue ? maxFarenheitValue : Math.round(tempvalue) : tempvalue);
            }
            this.setState({ 
                scheduler_configurations: scheduler_configurations,
                error_modal: {
                    open: tempvalue === 0 ? false : (this.state.selected_unit === '° C' && (tempvalue < 100 || tempvalue > maxCelsiusValue)) ? true : (this.state.selected_unit === '° F' && (tempvalue < 212 || tempvalue > maxFarenheitValue)) ? true : false,
                    message: this.state.selected_unit === '° C' ?  localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? "KC2290" : "KC1209" :  localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? "KC2291" : "KC1328"
                }
             })
        } 
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/cleaning"
        });
    }

    onConfirmButtonClick = (action ,day = '') => {
        this.setState({
            loaderIconVisible: true
        });
        let { scheduler_enabled, scheduled_day, scheduler_configurations, selected_unit } = this.state;
        let isValidInput = true;
        let jsonSchedulerDetails = localStorage.getItem("schedulerDetails");
        let ObjSchedulerDetails = JSON.parse(jsonSchedulerDetails);
        if (scheduler_configurations.length > 0 && scheduler_enabled === "true") {
            scheduler_configurations.map((item, i) => {
                if(item.temp === "OFF") {
                    item.temp = 0;
                }
                isValidInput = isValidInput && ((selected_unit === '° C' && item.time && !item.time.includes('_') && ((item.temp >= 100 && item.temp <= 275) || item.temp === 0) && item.type !== "Instruction") || 
                (selected_unit === '° F' && item.time && ((item.temp >= 212 && item.temp <= 527) || item.temp === 0) && item.type !== "Instruction") ||
                (item.time && item.instruction && item.type === "Instruction")) ? true : false;
            });

            if (isValidInput) {
                var foundIndex = -1;
                if (ObjSchedulerDetails.length > 0) {
                    foundIndex = ObjSchedulerDetails.findIndex(x => x.day === scheduled_day);
                }
                if (foundIndex !== -1) {
                    ObjSchedulerDetails[foundIndex].enabled = scheduler_enabled;
                    ObjSchedulerDetails[foundIndex].day = scheduled_day;
                    ObjSchedulerDetails[foundIndex].configurations = scheduler_configurations;
                }
                else {
                    let schedule = {};
                    schedule.enabled = scheduler_enabled;
                    schedule.day = scheduled_day;
                    schedule.configurations = scheduler_configurations;
                    ObjSchedulerDetails.push(schedule);
                }
                localStorage.setItem("schedulerDetails", JSON.stringify(ObjSchedulerDetails));

                let jsonLibraryDetails = localStorage.getItem("libraryDetails");
                let ObjLibraryDetails = JSON.parse(jsonLibraryDetails);
                ObjLibraryDetails.day = scheduled_day;
                localStorage.setItem("libraryDetails", JSON.stringify(ObjLibraryDetails));

                let jsonSettingsFile = localStorage.getItem("librarySettingsData");
                let ObjSettingsFile = JSON.parse(jsonSettingsFile);
                ObjSettingsFile.scheduler = ObjSchedulerDetails;
                
                localStorage.setItem("librarySettingsData", JSON.stringify(ObjSettingsFile));
                if (action === "DaySelect") {
                    let schedule = ObjSchedulerDetails.find(s => s.day === day);
            
                    this.setState({
                        scheduled_day: day,
                        scheduler_enabled: schedule ? schedule.enabled : false,
                        scheduler_configurations: schedule ? schedule.configurations : [],
                        disableAddStep: schedule ? false : true
                    });
                }
                else {
                    this.props.history.push(`/settingsEditor`);
                }
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1329"
                    }
                });
            }
        }
        else if ((scheduler_configurations.length === 0 || scheduler_enabled === "false") && scheduled_day) { 
            var foundIndex = -1;
            if (ObjSchedulerDetails.length > 0) {
                foundIndex = ObjSchedulerDetails.findIndex(x => x.day === scheduled_day);
            }
            if (foundIndex !== -1) {
                var ObjNewSchedulerDetails = ObjSchedulerDetails.filter(x => x.day !== scheduled_day);
                localStorage.setItem("schedulerDetails", JSON.stringify(ObjNewSchedulerDetails));
            }

            let jsonLibraryDetails = localStorage.getItem("libraryDetails");
            let ObjLibraryDetails = JSON.parse(jsonLibraryDetails);
            ObjLibraryDetails.day = appconstants.monday;
            localStorage.setItem("libraryDetails", JSON.stringify(ObjLibraryDetails));

            let jsonSettingsFile = localStorage.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            ObjSettingsFile.scheduler = ObjSchedulerDetails;
            
            localStorage.setItem("librarySettingsData", JSON.stringify(ObjSettingsFile));
            if (action === "DaySelect") {
                let schedule = null;
                if (ObjSchedulerDetails.length > 0) {
                    schedule = ObjSchedulerDetails.find(s => s.day === day);
                }

                this.setState({
                    scheduled_day: day,
                    scheduler_enabled: schedule ? schedule.enabled : false,
                    scheduler_configurations: schedule ? schedule.configurations : [],
                    disableAddStep: schedule ? false : true
                });
            }
            else {
                this.props.history.push(`/settingsEditor`);
            }
        }
        else if ((scheduler_configurations.length === 0 || scheduler_enabled === "false") && scheduled_day) { 
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1330"
                }
            });
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1329"
                }
            });
        }
    }

    onSchedulerDayEnabled = (e) => {
        const { formatMessage } = this.props.intl;
        this.setState({
            scheduler_enabled: e.toString(),
            disableAddStep: e ? false : true
        });

        if (this.state.scheduler_configurations.length === 0) {
            this.setState({
                scheduler_configurations: [
                    { "id": 1, "time" : "00:01", "text" : formatMessage({ id: 'KC2031' }), "temp" : 0, "instruction" : "", "type":"Hot", },
                    { "id": 12, "time" : "00:01", "text" : formatMessage({ id: 'KC2032' }), "temp" : 0, "instruction" : "", "type":"Cold", }
                    ]
            });
        }
    }

    onSchedulerDaySelect = (e, day) => {
        
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    addStepRedirect = (e) => {
        let jsonSchedulerDetails = localStorage.getItem("schedulerDetails");
        let ObjSchedulerDetails = JSON.parse(jsonSchedulerDetails);
        var foundIndex = ObjSchedulerDetails.findIndex(x => x.day === this.state.scheduled_day);
        if (foundIndex !== -1) {
            ObjSchedulerDetails[foundIndex].enabled = this.state.scheduler_enabled;
            ObjSchedulerDetails[foundIndex].day = this.state.scheduled_day;
            ObjSchedulerDetails[foundIndex].configurations = this.state.scheduler_configurations;
        }
        else {
            let schedule = {};
            schedule.enabled = this.state.scheduler_enabled;
            schedule.day = this.state.scheduled_day;
            schedule.configurations = this.state.scheduler_configurations;
            ObjSchedulerDetails.push(schedule);
        }
        localStorage.setItem("schedulerDetails", JSON.stringify(ObjSchedulerDetails));
        let jsonLibraryDetails = localStorage.getItem("libraryDetails");
        let ObjLibraryDetails = JSON.parse(jsonLibraryDetails);
        ObjLibraryDetails.day = this.state.scheduled_day;
        localStorage.setItem("libraryDetails", JSON.stringify(ObjLibraryDetails));
        this.props.history.push(`/schedulerAdd`);
    }

    onDeleteStep = (e) => {
        let id = e.target.id.replace('paramDelete-', '');
        if (id) {
            let splicedata = this.state.scheduler_configurations;
            splicedata.splice(Math.round(id), 1);
            this.setState({
                scheduler_configurations: splicedata
            });
            if (splicedata.length < 12) {
                this.setState({
                    disableAddStep: false
                });
            }
        }
    }

    _onFocus(id, value) {
        if(value === '' || value === 'OFF') {
            document.getElementById(id).value = "";
        }
    }

    _onTimerFocus = (id) => {
        var textfield = document.getElementById(id);
        textfield.setSelectionRange(0, 0);
    }

    render() {
        let { scheduled_day, scheduler_enabled, scheduler_configurations, error_modal, success_modal, disableAddStep, selected_unit, time_mask} = this.state;
        let brandid = localStorage.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1270"/>
				                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">
                                                <div class="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            <div class="rcSchedulerList">
                                                                <span className={scheduled_day === 'Monday' ? "active" : ""} onClick={(e) => this.onConfirmButtonClick('DaySelect', 'Monday')}>M</span>
                                                                <span className={scheduled_day === 'Tuesday' ? "active" : ""} onClick={(e) => this.onConfirmButtonClick('DaySelect', 'Tuesday')}>T</span>
                                                                <span className={scheduled_day === 'Wednesday' ? "active" : ""} onClick={(e) => this.onConfirmButtonClick('DaySelect', 'Wednesday')}>W</span>
                                                                <span className={scheduled_day === 'Thursday' ? "active" : ""} onClick={(e) => this.onConfirmButtonClick('DaySelect', 'Thursday')}>T</span>
                                                                <span className={scheduled_day === 'Friday' ? "active" : ""} onClick={(e) => this.onConfirmButtonClick('DaySelect', 'Friday')}>F</span>
                                                                <span className={scheduled_day === 'Saturday' ? "active" : ""} onClick={(e) => this.onConfirmButtonClick('DaySelect', 'Saturday')}>S</span>
                                                                <span className={scheduled_day === 'Sunday' ? "active" : ""} onClick={(e) => this.onConfirmButtonClick('DaySelect', 'Sunday')}>S</span>
                                                            </div>
                                                            <div class="rcSettingsList02 schedulerList">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC1331"/></li>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <Switch
                                                                                onChange={(e) => this.onSchedulerDayEnabled(e)}
                                                                                checked={scheduler_enabled === "true" ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                </div>
                                                <div className="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            <div className={scheduler_enabled === "true" ? "rcAddSchedulerForm" : "displaynone"}>
                                                                {
                                                                    scheduler_configurations && scheduler_configurations.map((val, idx) => {
                                                                        let timeid = `time-${idx}`, tempid = `temp-${idx}`, paramDeleteid = `paramDelete-${idx}`, instructionid = `instruction-${idx}`;
                                                                        return (
                                                                            <ul>
                                                                                <li>
                                                                                    {(idx !==0 && val.type !== "Cold") ? <button type="button" class="btn recipeRowCount"> {idx} </button> : ""}
                                                                                </li>
                                                                                <li>
                                                                                    <form class={val.type === "Instruction" ? "rcSchedulerTable scheduler3Colm" : "rcSchedulerTable" } onChange={this.handleParamChange} onBlur={this.handleParamCheck} key={idx}>
                                                                                        <ul>
                                                                                            <li><InputMask mask={time_mask} maskChar="" placeholder={formatMessage({ id: 'KC0836' })} onClick={(e) => this._onTimerFocus(timeid)} value={val.time} name={timeid} data-id={idx} id={timeid} class="form-control time" /></li>
                                                                                            {val.type !== "Instruction" && <li>{val.text}</li> }
                                                                                            <li>
                                                                                                <input type="text" maxLength="5" name={tempid} onFocus={(e) => this._onFocus(tempid, e.target.value)}
                                                                                                data-id={idx} value={val.temp === 0 ? 'OFF' : val.temp === 'OFF' ? val.temp : val.temp} placeholder="0"
                                                                                                id={tempid} class={val.type !== "Instruction" ? "form-control temp" : "displaynone"} />
                                                                                                <input type="text" maxLength="30" name={instructionid}
                                                                                                data-id={idx} value={val.instruction} placeholder={formatMessage({ id: 'KC2033' })}
                                                                                                id={instructionid} class={val.type === "Instruction" ? "form-control instruction" : "displaynone"} />
                                                                                            </li>
                                                                                            <li>{val.type !== "Instruction" ? selected_unit : ""}</li>
                                                                                        </ul>
                                                                                    </form>
                                                                                </li>
                                                                                <li>
                                                                                <button name={paramDeleteid}
                                                                                data-id={idx}
                                                                                id={paramDeleteid} onClick={(e) => { this.onDeleteStep(e) }} type="button" class={(idx === 0 || val.type === "Cold") ? "displaynone" : "btn btn-default-text deleteButton"} title={formatMessage({ id: 'KC1213' })}>&nbsp;</button>
                                                                                </li>
                                                                            </ul>
                                                                        )
                                                                    })}
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick('Save') }} class="btn btn-default-text confirmButton">&nbsp;</button>&nbsp;&nbsp;
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                    <button type="button" id="scrollDownButton" onClick={this.addStepRedirect} class={(scheduler_enabled === "false" || disableAddStep) ? "btn btn-default-text displaynone" : "btn btn-default-text addButton"} title={formatMessage({ id: 'KC2034' })}>&nbsp;</button>&nbsp;
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(Scheduler)
