import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { headerRow } from './plan';
import GoToPagination from '../controls/goToPagination';
import { TableHeader } from '../controls/tableHeader';
import { subscriptionManagement } from '../../service/api_london';
import http from '../../service/httpService';
import { datePickerLocale } from '../../locale/constant';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import "react-datepicker/dist/react-datepicker.css";

export const subscriptionManagementDateFormatter = 'MMM DD, YYYY';

export function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

const Invoice = (props) => {
    const { formatMessage } = props.intl;
    const [loaderIconVisible, setLoaderIconVisible] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [totalPages, setTotalPages] = useState(null);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [allSelect, setAllSelect] = useState(false);
    const [isIndeterminateSelect, setIsIndeterminate] = useState(true);
    const [invoiceView, setInvoiceView] = useState(false);
    const [iframeLoadIssue, setIframLoadIssue] = useState(false);
    const [invoiceURL, setInvoiceURL] = useState('');
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const isActive = localStorage.getItem("chargifySubscriptionStatus") ? localStorage.getItem("chargifySubscriptionStatus") !== 'Inactive' : false;

    const params = new URLSearchParams(props.location.search);
    const invNumber = params.get('inv');

    useEffect(() => {
        $("div").removeClass("modal-backdrop fade show");
        document.body.style.backgroundColor = "#F2F2F2";
        getInvoiceData();
        updateDimensions();
        function handleResize() {
            updateDimensions();
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const updateDimensions = () => {
        const windowHeight = $(window).height();
        const $tableBody = $('.invoiceTableForm');
        const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0;
        $tableBody.css('height', windowHeight - ($tableBody.offset()?.top + $tableBodyBottom));
    }

    const updateErrorDimension = () => {
        const windowHeight = $(window).height();
        const $tableBody = $('.invoiceViewFrame');
        $tableBody.css('height', windowHeight - ($tableBody.offset()?.top + 20));
    }

    const getInvoiceData = async (currentPageNumber = currentPage, isClear = false) => {
        setLoaderIconVisible(true);
        let url = subscriptionManagement.getInvoice;
        url = url + `?customerId=${localStorage.getItem("customerId")}&pageNum=${currentPageNumber}&pageSize=${pageSize}`;

        if (!isClear && startDate) {
            url = url + `&startDate=${startDate}`
        }
        if (!isClear && endDate) {
            url = url + `&endDate=${endDate}`
        }
        if (!isClear && searchValue) {
            url = url + `&invnumber=${searchValue}`
        }

        http.get(url, {
            headers: {
                'Content-Type': 'application/json'
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                if (response.data?.invoices) {
                    if (invNumber) {
                        const invValue = response.data.invoices.filter((i) => i.uid === invNumber);
                        if (invValue?.length) {
                            await invoiceGet('view', invValue[0])
                        }
                    }
                    setInvoiceData(response.data.invoices);
                    setTotalPages(Math.ceil(response.data.meta.total_invoice_count / pageSize))
                }
                setCurrentPage(currentPageNumber);
                setLoaderIconVisible(false);

            }
        }).catch(err => {
            setLoaderIconVisible(false);
        });
    }

    const invoiceGet = async (type, invoice) => {
        setLoaderIconVisible(true);
        let url = subscriptionManagement.getInvoice;
        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'type': type === 'view' ? 'previewInvoice' : 'downloadInvoice',
                'uid': invoice.uid,
                'url': invoice.public_url
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                setInvoiceURL(response.data?.signedUrl)
                if (type === 'view') {
                    setInvoiceView(true);
                }
                if (type === 'download') {
                    const a = document.createElement('a');
                    a.download = `${invoice.uid}.pdf`;
                    a.href = response.data?.signedUrl;
                    a.style.display = 'none';
                    document.body.append(a);
                    a.click();
                    setLoaderIconVisible(false);
                }
            }
        }).catch(err => {
            setLoaderIconVisible(false);
        });
    }

    const onPageChange = (currentPageNumber) => {
        getInvoiceData(currentPageNumber)
    }


    const handleChange = (e) => {
        var result = [...invoiceData];
        result.forEach(r => {
            r.checked = e.target.checked;
        });
        let checked = result.filter(c => c.editable === true);
        if (checked.length === result.length) {
            setIsIndeterminate(false);
        }
        setInvoiceData(result);
        setAllSelect(e.target.checked);
    };

    const getCheckBoxstate = (ListCount, SelectedCount) => {
        if (SelectedCount == ListCount) {
            setAllSelect(true);
            setIsIndeterminate(false);
        } else if (SelectedCount == 0 && ListCount != 0) {
            setAllSelect(false);
            setIsIndeterminate(false);
        } else if (ListCount >= SelectedCount) {
            setAllSelect(true);
            setIsIndeterminate(true);
        }
    }

    const handleSingleCheckboxChange = async (e, item) => {
        if (invoiceData && invoiceData.length > 0) {
            let result = [...invoiceData];
            const index = result.findIndex(i => i.uid === item.uid);
            result[index] = { ...result[index] };
            result[index].checked = e.target.checked;
            let checked = result.filter(c => c.checked === true);

            setInvoiceData(result);
            getCheckBoxstate(result.length, checked.length)
        }
    }

    const ResetDatepicker = (e) => {
        e.preventDefault();
        return false;
    }

    let columns = [
        { path: "#", labelStringID: "KC0169" },
        { path: "invoice", labelStringID: "KC2438" },
        { path: "date", labelStringID: "KC0298" },
        { path: "", labelStringID: "KC2458" },
    ];
    let rowNumber = (currentPage * pageSize) - pageSize;

    return (
        <div id="adminWrapper">
            <div className="searchToggleTransparent"></div>
            <div className="toggleTransparent"></div>
            <div className="sideNavToggleTransparent"></div>
            <div className="userToggleTransparent"></div>
            <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <div id="page-content-wrapper">
                <div className="lightBgWrapper">
                    <div className="navBarXScroll">
                        <ul>
                            {headerRow(formatMessage, 'Invoice')}
                        </ul>
                    </div>
                    {invoiceView ?
                        <React.Fragment>
                            <div className="tableSHeader" style={{ borderBottom: 'none' }}>
                                <ul>
                                    <li>
                                        <Link to='/subscription/invoice' onClick={() => setInvoiceView(false)} title={formatMessage({ id: 'KC0989' })} className="backButton"><FormattedMessage id='KC0989' /></Link>
                                    </li>
                                    <li>&nbsp;</li>
                                </ul>
                            </div>
                            {iframeLoadIssue ? <form className="invoiceViewWrapper">
                                <div className="invoiceViewFrame">
                                    <div className="noRecordsTable viewforHTML"><span className="noRecordsTableInfo">Due to technical issue, can not download invoice at the moment. Please try again later. </span></div>
                                </div>

                            </form>
                                : <form style={{ margin: 10, textAlign: 'center' }} className="invoiceTableForm">
                                    <iframe width={`${(windowDimensions.width / 2)}px`}
                                        height={windowDimensions.height - 100}
                                        frameBorder={0}
                                        onError={() => {
                                            setIframLoadIssue(true);
                                            updateErrorDimension();
                                        }}
                                        onLoad={() => setLoaderIconVisible(false)}
                                        src={`${invoiceURL}#toolbar=0&view=fitH`}>
                                    </iframe>
                                </form>}
                        </React.Fragment>
                        : <React.Fragment>
                            <div className="tableSHeader">
                                <ul>
                                    <li>
                                        <div className="form-group">
                                            <span className="calendarPicker"></span>
                                            <DatePicker locale={datePickerLocale} popperProps={{positionFixed: true }} showIcon disabledKeyboardNavigation className='form-control dateField' id="startDate" autoComplete="off" showMonthDropdown showYearDropdown dropdownMode="select" placeholderText={formatMessage({ id: 'KC0695' })} onKeyDown={(e) => ResetDatepicker(e)} selected={startDate} onChange={(date) => setStartDate(date)} maxDate={endDate || new Date()}></DatePicker>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="form-group">
                                            <span className="calendarPicker"></span>
                                            <DatePicker locale={datePickerLocale} popperProps={{positionFixed: true }} showIcon disabledKeyboardNavigation className='form-control dateField' id="endDate" autoComplete="off" showMonthDropdown showYearDropdown dropdownMode="select" placeholderText={formatMessage({ id: 'KC0696' })} onKeyDown={(e) => ResetDatepicker(e)} selected={endDate} onChange={(date) => setEndDate(date)} minDate={startDate} maxDate={new Date()}> </DatePicker>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="form-group">
                                            <span className="searchIcon"></span>
                                            <input type="search" className="form-control searchBar" placeholder={formatMessage({ id: 'KC2443' })} value={searchValue} onChange={(e) => {
                                                if (e.target.value === '') {
                                                    getInvoiceData(1, true)
                                                }
                                                setSearchValue(e.target.value)
                                            }
                                            } />
                                        </div>
                                    </li>
                                    <li>
                                        <button type="button" className={`btn searchBtn ${(startDate !== '' && endDate !== '') || searchValue !== "" ? '' : 'disabled'}`} onClick={() => {
                                            getInvoiceData(1)
                                        }}><FormattedMessage id="KC0037" /></button>
                                        {(startDate !== '' && endDate !== '') || searchValue !== "" ?
                                            <button type="button" className={`btn clearBtn`} onClick={async () => {
                                                setStartDate('');
                                                setEndDate('');
                                                setSearchValue('');
                                                getInvoiceData(1, true);
                                            }}><FormattedMessage id="KC2711" /></button>
                                            : null}
                                    </li>
                                </ul>
                            </div>
                            <div className="pageTitle"><FormattedMessage id="KC2437" /></div>
                            <form className="invoiceTableForm">
                                <div className='invoiceTableOuter'>
                                    {invoiceData.length ? <div className="invoiceTable">
                                        <TableHeader
                                            isIndeterminate={isIndeterminateSelect}
                                            selectAllChange={handleChange}
                                            columns={columns}
                                            isSelectAll={allSelect}
                                        />
                                        {invoiceData?.map((invoice, index) => {
                                            const invNo = `#${invoice.number.toString().padStart(6, "0")}`
                                            return (
                                                <ul key={index} className="tBody">
                                                    <li>{rowNumber + index + 1}</li>
                                                    <li>{invNo}</li>
                                                    <li>{moment(invoice.issue_date).format(subscriptionManagementDateFormatter)}</li>
                                                    <li>
                                                        <button type="button" className="btn view" onClick={() => {
                                                            invoiceGet('view', invoice)
                                                        }}>&nbsp;</button>
                                                        <button type="button" className="btn downloadSE" onClick={() => {
                                                            invoiceGet('download', invoice)
                                                        }}>&nbsp;</button>
                                                    </li>
                                                </ul>
                                            )
                                        }
                                        )}
                                    </div> : !loaderIconVisible ? <div className="noRecordsTable"><button type="button" className="btn noRecordsTableInfo cursor-default">&nbsp;</button> <FormattedMessage id='KC2445' values={{ value: "Invoices" }} /></div> : null}
                                </div>
                                {invoiceData.length && totalPages && totalPages > 1 ? <GoToPagination
                                    isBlackBackground={false}
                                    totalPageNumber={totalPages}
                                    pageNumber={currentPage}
                                    navigateTo={(p) => onPageChange(p)} /> : null}
                            </form>
                        </React.Fragment>}
                </div>

            </div>

            <UserManagementHeader headerName={formatMessage({ id: 'KC1668' })} headerNameStringID="KC1668" activeClass="billingPortalNav" />
        </div >
    )
};



export default injectIntl(Invoice)