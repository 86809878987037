import http from '../../../service/httpService';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { unitRegister } from '../../../service/api';
import authService from '../../../service/authService';
import React, { Component } from 'react';
import ErrorMoal from '../../modal/error-model';
import SuccessMoal from '../../modal/success-model';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { TableHeader } from '../../controls/tableHeader';
import _ from 'lodash';
import { CUSTOMER_ID, BASECOUNTRY_ID } from '../../../utils/appConstants';
import NoRecordsFound from '../../controls/noRecordsFound';
import moment from '../../../utils/momentHelper.js';
import { searchVisible, searchText, clearSearchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function} from '../../globalSearchBar/selectOption';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from '../../controls/goToPagination';

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

var selectedTimeZoneId;

class UnitRegistration extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			customerId: parseInt(localStorage.getItem("customerId")),
			isSelectAllUnits: false,
			isIndeterminateSelect:true,
			isAdd: true,
			isActive: false,
			isAssign: false,
			isDelete: false,
			data: [],
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			unitIdValue: {},
			sortColumn: { path: "REGISTERED_ON", order: "asc", sort_color: "#63778a" },
			timezonedata: {},
			searchData: null,
			totalPages: null,
			currentPage : 1,
			pageSize: 25,
		}
		this.getAllAssignedUnitsList = this.getAllAssignedUnitsList.bind(this);
		this.onSelectUnit = this.onSelectUnit.bind(this);
		this.selectAllUnits = this.selectAllUnits.bind(this);
		this.insertUnitCertificate = this.insertUnitCertificate.bind(this);
		this.unitProvisioning = this.unitProvisioning.bind(this);
		this.OnActivateUnit = this.OnActivateUnit.bind(this);
		this.OnAssignUnit = this.OnAssignUnit.bind(this);
		this.OnDeleteUnit = this.OnDeleteUnit.bind(this);
		this.OnAssignTimezone = this.OnAssignTimezone.bind(this);
		this.getTimeZoneInfo = this.getTimeZoneInfo.bind(this);
	}

	async componentWillMount() {
		await this.getTimeZoneInfo();
		await this.OnTimeZonLoad();
	}
	
	updateDimension() {
        const windowHeight = $(window).height();
        const $tableBody = $('.unitRegListTableSrlBar');
		const $tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0 ;
        $tableBody.css('height', windowHeight - ($tableBody.offset().top + $tableBodyBottom));
    }

	async componentDidMount() {
		window.addEventListener('resize', this.updateDimension);
		this.props.searchVisible(true);
		this.setState({ loaderIconVisible: true });

		$('body').removeClass("modal-open");
		$("div").removeClass("modal-backdrop fade show");
		document.body.style.backgroundColor = "#F2F2F2";
		this.OnTimeZonLoad();
		await this.getAllAssignedUnitsList(1).catch(err => {
			this.setState({
				loaderIconVisible: false
			});
		});
	}

	// componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	componentWillUpdate() {
		this.OnTimeZonLoad();
	}

	    /**
	 * When navigate new page this event will return current page number
	 * @param {*} currentPage 
	 */
		onPageChange = (currentPage)  =>{
			this.setState({ loaderIconVisible: true },()=>{
				this.getAllAssignedUnitsList(currentPage);
			});
		}

	componentDidUpdate ( prevState ) {
		this.updateDimension();
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
			this.getAllAssignedUnitsList(1);
		}
	}

	static getDerivedStateFromProps = (props) => ({...props});

	OnTimeZonLoad = () => {
		$("#closeTimeZone").click(function () {
			$('#timeZoneSelctOption  option[value="0"]').prop("selected", true);
			document.getElementById('timeZoneSelctOption').value = '';
		})

		$(".dropdownTimeZoneOptions").hide();
		$("#timeZoneSelctOption").focus(function () {
			$(".dropdownTimeZoneOptions").show();
		});

		var timezoneValue;
		$(document).click(function (e) {
			$(".dropdownTimeZoneOptions").hide();
		});

		$('#timeSchedulePanel').click(function (e) {
			e.stopPropagation();
		});

		$(".dropdownTimeZoneOptions  > li > a").click(function (e) {
			console.log('called....click')
			e.preventDefault();
			timezoneValue = $(this).text();
			selectedTimeZoneId = $(this).attr("data-value");
			$("#timeZoneSelctOption").val(timezoneValue);
			$(".dropdownTimeZoneOptions").hide();
			$("#OpenedTimeZone").removeClass("disabled");
		});
	}

	OnAssignTimezone = () => {
		$("#OpenedTimeZone").addClass("disabled");
	}

	getTimeZoneInfo = () => {

		let token = localStorage.getItem('Access-Token');
		http.get(unitRegister.timeZoneInfo,
			{
				headers: {
					'Content-Type': 'application/json',
					'Authorization': token,
				},
				data: {}
			}).then(response => {
				let { timezones } = response.data || {};
				this.setState({
					loaderIconVisible: false,
					timezonedata: timezones
				});
			}).catch(err => {
				console.log(err);
			});
	};

	getAllAssignedUnitsList = (currentPage, sortColumn = this.state.sortColumn) => {
		let { searchData } = this.state;
		let url = `${unitRegister.getUnitsAssignations}?filterType=unassigned`;
		this.setState({
			loaderIconVisible: true
		});
		let responseResult = {
			data: []
		}
		
		
        let pageNumber = `&pageNum=` + currentPage;
        let pageLimit = '&pageSize=' + this.state.pageSize;
        url = url + pageNumber + pageLimit;

		//searchData_function
		let _search = searchData_function(searchData);
		if( _search ){
			url = `${url}${_search}`;
		}

		if(sortColumn && sortColumn.path){
			url = `${url}&sortOrder=${sortColumn.order}&sortBy=${sortColumn.path}`
		}

		return new Promise((resolve, reject) => {
			// http.get(unitRegister.getUnitsAssignations + localStorage.getItem("customerId") + "&filterType=unassigned", 
			http.get(url,
				{
					headers: {
						'Content-Type': 'application/json',
						customerId: CUSTOMER_ID,
						brandId: JSON.parse(localStorage.getItem('brandId')),
						'basecountry': BASECOUNTRY_ID

					},
					data: {}
				}).then(response => {
					let { data } = response;
					responseResult.data = data.response;
					if(responseResult.data){
						this.setState({ currentPage })
					}
					this.setState({
						loaderIconVisible: false,
						data: responseResult.data,
						totalPages: response.data.numberOfPages,
						sortColumn
					});
					resolve(responseResult);
				}).catch(err => {
					this.setState({ loaderIconVisible: false });
					reject(err);
				});
				this.props.searchVisible(true);
		});

	}

	selectAllUnits = (e) => {
		if (this.state.data && this.state.data.length > 0) {
			let units = [...this.state.data];
			units && units.length > 0 && units.map(item => {
				item.checked = e.target.checked;
			});
			this.isSelectAssignActiveDelete(units);
			this.setState({
				data: units,
				isSelectAllUnits: e.target.checked,
				isIndeterminateSelect: false
			});
		}
	}

	onSelectUnit = (e, item) => {
		if (this.state.data && this.state.data.length > 0) {
			const units = [...this.state.data];
			const index = units.indexOf(item);
			units[index] = { ...units[index] };
			units[index].checked = e.target.checked;

			let selectedUnits = units.filter(item => item.checked == true);
			this.isSelectAssignActiveDelete(units);

			function getCheckBoxstate() {
				if (selectedUnits.length == units.length) {
				  return { isSelectAllUnits: true, isIndeterminateSelect: false };
				} else if (selectedUnits.length == 0 && units.length != 0) {
				  return { isSelectAllUnits: false, isIndeterminateSelect: false };
				} else if (units.length >= selectedUnits.length) {
				  return { isSelectAllUnits: true, isIndeterminateSelect: true };
				}
			}

			this.setState({
				data: units,...getCheckBoxstate()
			});
		}
	}

	isSelectAssignActiveDelete = (units) => {
		let selectedUnits = units.filter(item => item.checked === true);
		let isAdd, isActive, isAssign, isDelete = false;
		let activeDevices = selectedUnits.filter(item => item.ACTIVATION_STATUS !== "INACTIVE");

		if (selectedUnits.length > 0) {
			isActive = activeDevices.length === 0 ? true : false;
			// isAssign = selectedUnits.length === activeDevices.length ? true : false;
			isAssign = true;
		}
		else {
			isActive = false;
			isAssign = false;
		}
		isAdd = selectedUnits.length > 0 ? false : true;
		isDelete = selectedUnits.length > 0 ? true : false;

		this.setState({
			isAdd: isAdd,
			isActive: isActive,
			isAssign: isAssign,
			isDelete: isDelete
		});
	}

	insertUnitCertificate = () => {
		let units = [...this.state.data];
		let selectedUnits = units.filter(item => item.checked == true);
		let unitId = selectedUnits.map(function (item) { return item.UNITID });
		let brandId = selectedUnits.map(function (item) { return item.BRAND_NAME });
		let postData = {
			unitId: unitId,
			brandId: brandId
		}

		this.insertData(postData, unitRegister.unitCertificate, HTTP_METHOD.POST).then(response => {
			if (response && response.data && response.data.body && response.data.body.message) {
				this.setState({
					success_modal: {
						open: true,
						message: response.data.body.message
					}
				});
			}
		}).catch(err => {
			this.showError(err);
		});

	}

	unitProvisioning = () => {
		let units = [...this.state.data];
		let selectedUnits = units.filter(item => item.checked == true);
		let unitId = selectedUnits.map(function (item) { return item.UNITID });
		let brandId = selectedUnits.map(function (item) { return item.BRAND_NAME });
		let provisionState = "CERTIFICATE_RECEIVED";
		let postData = {
			provisioningState: provisionState,
			brandId: brandId
		}
		let url = unitRegister.unitProvisionState + `/` + unitId;
		this.updateData(postData, url, HTTP_METHOD.PUT).then(response => {
			if (response && response.data && response.data.body && response.data.body.message) {
				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: response.data.body.message
					}
				});
			}
		}).catch(err => {
			this.showError(err);
		});
	}

	activateUnits = () => {
		let units = [...this.state.data];
		const totalUnits = units && units.length ? units.filter((i)=>i.checked === true) : []

		let assingUnits = this.getSelectedUnits();
		let falseActivatedUnits = assingUnits.map((e) => ({...e, ACTIVATION_STATUS: "ACTIVE" }));
		let inActiveUnits = assingUnits.filter((e) => e.ACTIVATION_STATUS === "INACTIVE").map((i) => i.ID);
		
		let assingUnitID = this.getSelectedUnitsId();
		let postData = {
			unitIds: assingUnitID
		}
		this.setState({
			unitIdValue: postData
		});
		$("div").removeClass("modal-backdrop fade show");
		this.props.history.push({
			pathname: "/assignUnitToTopology",
			data: this.getSelectedUnitsId(),
			unitList: falseActivatedUnits,
			inActiveUnits:inActiveUnits,
			selectedTimeZone: selectedTimeZoneId,
			totalUnits
		});
		
	}

	OnActivateUnit = () => {
		// this.insertUnitCertificate();
		// this.unitProvisioning();	
		let assingUnits = this.getSelectedUnitsId();
		let postData = {
			unitIds: assingUnits,
			activationStatus: "ACTIVE"
		}

		this.putData(postData, unitRegister.activateUnits, HTTP_METHOD.PUT).then(response => {
			this.getAllAssignedUnitsList(1);
			if (response && response.data && response.data.body && response.data.body.message) {
				this.setState({
					isActive: false,
					isAssign: false,
					isDelete: false,
					isAdd: true,
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: response.data.body.stringID || response.data.body.message
					}
					,isSelectAllUnits: false, 
					isIndeterminateSelect: false 
				});
				WoopraEvents(`${Constants.UNIT_ACTIVATED}`);
			}
		}).catch(err => {
			this.showError(err);
		});
	}

	checkandAsignUnits = () => {
		let assingUnits = this.getSelectedUnits();
		let inActiveUnits = assingUnits.filter((e) => e.ACTIVATION_STATUS === "INACTIVE")
		if(inActiveUnits.length > 0) {
			$('#activateNowModal').modal('show');
		}else{
			this.OnAssignUnit();
		}
	}

	OnAssignUnit = () => {
		let units = [...this.state.data];
		const totalUnits = units && units.length ? units.filter((i)=>i.checked === true) : []
		let assingUnits = this.getSelectedUnitsId();
		let postData = {
			unitIds: assingUnits
		}
		this.setState({
			unitIdValue: postData
		});

		$("div").removeClass("modal-backdrop fade show");
		this.props.history.push({
			pathname: "/assignUnitToTopology",
			data: this.getSelectedUnitsId(),
			unitList: this.getSelectedUnits(),
			selectedTimeZone: selectedTimeZoneId,
			totalUnits
		});
	}

	OnDeleteUnit = () => {

		let assingUnits = this.getSelectedUnitsId();
		let postData = {
			unitIds: assingUnits
		}

		this.putData(postData, unitRegister.deleteUnits, HTTP_METHOD.DELETE).then(response => {
			if (response && response.data && response.data.body && response.data.body.message) {
				if (response.data.httpCode === 200) {
					let units = [...this.state.data];
					let unAssignedUnits = units.filter(item => item.checked !== true);
					this.setState({
						data: unAssignedUnits,
						loaderIconVisible: false,
						isAdd: true,
						isActive: false,
						isAssign: false,
						isDelete: false,
						success_modal: {
							open: true,
							message: response.data.body.stringID || response.data.body.message
						}
						,isSelectAllUnits: false, 
						isIndeterminateSelect: false 
					},()=>{this.upDateEaaSFlag(units)});
					WoopraEvents(`${Constants.DELETE_UNIT}`);
				}
			}

		}).catch(err => {
			this.showError(err);
		});
	}

	putData = (postData, url, method) => {
		this.setState({
			loaderIconVisible: true
		});
		let loggedinUser;
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		loggedinUser = loginUser.username;
		const header = {
			'customerid': localStorage.getItem("customerId"),
			'useremail': loggedinUser,
		}
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				headers: header,
				data: postData
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== 200) {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	insertData = (postData, url, method) => {
		let token = "de520bca6d600ba7fbf637becb093158";
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				data: postData,
				headers: {
					"content-type": "application/json",
					"token": token
				}
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== 200) {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	updateData = (postData, url, method) => {
		let token = "de520bca6d600ba7fbf637becb093158";
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				data: postData,
				headers: {
					"content-type": "application/json",
					"token": token
				}
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode != 200) {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	getSelectedUnitsId = () => {
		let units = [...this.state.data];
		let assingUnits = [];
		if (units && units.length > 0) {
			for (let index = 0; index < units.length; index++) {
				if (units[index].checked == true) {
					assingUnits.push(units[index].ID);
				}
			}
		}

		return assingUnits;
	}
	getSelectedUnits = () => {
		let units = [...this.state.data];
		let assingUnits = [];
		if (units && units.length > 0) {
			for (let index = 0; index < units.length; index++) {
				if (units[index].checked == true) {
					assingUnits.push(units[index]);
				}
			}
		}

		return assingUnits;
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				let { body } = data;
				if (body) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: body.message
						}
					});
				}
			}
		} else {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: "KC1065"
				}
			});
			localStorage.removeItem("Access-Token");
			this.props.history.push(`/`)
		}
	}

	closeModel = (err) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			}
		});
	}


	onSuccessCloseModal = () => {
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/unitRegistration`);
	}

	closeModel() {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false
			}
		})
	}

	onSort = sortColumn => {
		this.getAllAssignedUnitsList(1,sortColumn);
	}

	getEaaSDeviceDetails = (unitDet) => {
		let units = unitDet;
		let EassDevicesDet = [];
		if (units && units.length > 0) {
			for (let index = 0; index < units.length; index++) {
				if (units[index].checked == true && units[index].EAAS_FLAG === 1) {
					let obj = {
						"brandId": units[index].BRAND_ID,
						"serialNumber": units[index].UNITID
					}
					EassDevicesDet.push(obj);
				}
			}
		}
		return EassDevicesDet;
	}

	upDateEaaSFlag = (unitDet) => {
		let url = unitRegister.updateEaaSFlag;
		let getEaaSDeviceDet = this.getEaaSDeviceDetails(unitDet);
		if (getEaaSDeviceDet.length > 0) {
			return new Promise((resolve, reject) => {
				http.post(url
					, getEaaSDeviceDet,
					{
						headers: {
							'Content-Type': 'application/json',
						}
					}
				).then(response => {
					let { data } = response;
					let { httpCode, responseCode, message } = data;
					if (response && response.status === 200) {
						this.setState({
							loaderIconVisible: false,
						});
					}

				}).catch(err => {
					reject(err);
				});
			});
		}
	}

	// clear Global Search
	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { data: dataList, isSelectAllUnits,isIndeterminateSelect, isActive, isAssign, isAdd, isDelete, error_modal, success_modal, unitIdValue, loaderIconVisible, sortColumn, totalPages, currentPage, pageSize } = this.state;
		dataList = dataList && dataList.length > 0 ? dataList : [];
		let rowNumber = (currentPage * pageSize) - pageSize;

		let { timezonedata } = this.state;
		let userProfileAuthFeature = authService.getFeature("/addUnit");
		let organizationAuthirizedFeature = authService.getFeature("/organizationManagement");
		let isSmartTagAccess = authService.getFeature("/smartTag/tagList");
		let columns = [
			{ path: "", label: "#" },
			{ path: "BRAND_NAME", labelStringID: "KC0062" },
			{ path: "MODEL_FAMILY_NAME", labelStringID: "KC0649" },
			{ path: "MODEL_NAME", labelStringID: "KC0064" },
			{ path: "UNIT_NUMBER", labelStringID: "KC0647" },
			{ path: "UNITID", labelStringID: "KC0104" },			
			{ path: "REGISTERED_ON", labelStringID: "KC0643" },
			{ path: "", labelStringID: "KC0655" },
			{ path: "ACTIVATION_STATUS", labelStringID: "KC0656" },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		return (
			<React.Fragment>

				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div className="loadingProgress">
						<div className="loadingProgressIn"></div>
					</div>

					<div id="page-content-wrapper" className="withSearchBar">

						<div className="unitRegistrationWrapper"> 
								<div class="navHeader">
									<ul>
										<li>
											{organizationAuthirizedFeature && (organizationAuthirizedFeature.is_editable === 0 || organizationAuthirizedFeature.is_editable === 1) ? <Link to="/organizationManagement" onClick={()=>this.clearGlobalSearch()} title={formatMessage({ id: "KC0519" })}><FormattedMessage id="KC0519" /></Link> : ""}
											<Link to="/unitRegistration" title={formatMessage({ id: "KC0631" })} className="active"><FormattedMessage id="KC0631" /></Link>
											{isSmartTagAccess && (isSmartTagAccess.is_editable === 0 || isSmartTagAccess.is_editable === 1)  ? <Link to="/smartTag/tagList" onClick={()=>this.clearGlobalSearch()} title={formatMessage({ id: "KC2318" })} ><FormattedMessage id="KC2318" /></Link> : ""}
										</li>
									</ul>
								</div>
								<div className="unitRegHeader">
									<ul>
										<li>
											<FormattedMessage id="KC0648"/>
                        				</li>
										<li>
											<button type="button" title={formatMessage({ id: 'KC1680' })} className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text unitActive ${isActive ? "" : "disabled"}` : "btn btn-default-text unitActive disabled"} onClick={() => this.OnActivateUnit()}><FormattedMessage id="KC0644"/></button>
											<button type="button" title={formatMessage({ id: 'KC1681' })} className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text unitAssign ${isAssign ? "" : "disabled"}` : "btn btn-default-text unitAssign disabled"} data-toggle="modal" onClick={(e) => this.checkandAsignUnits()} data-target="#confirmationAssignModal1"><FormattedMessage id="KC0645"/></button>
											<button type="button" title={formatMessage({ id: 'KC1682' })} className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text unitDelete ${isDelete ? "" : "disabled"}` : "btn btn-default-text unitDelete disabled"} onClick={() => this.OnDeleteUnit()}>&nbsp;</button>
											<Link to="/bulkUnitOnboarding" className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text bulkUnitOnboarding ${isAdd ? "" : "disabled"}` : "btn btn-default-text bulkUnitOnboarding disabled"} title={formatMessage({ id: 'KC2285' })}>&nbsp;</Link>
											<Link to="/addUnit" title={formatMessage({ id: 'KC1683' })} className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text unitAdd ${isAdd ? "" : "disabled"}` : "btn btn-default-text unitAdd disabled"}>&nbsp;</Link>
										</li>
									</ul>
								</div>  
								<div className="unitRegListTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.selectAllUnits}
										columns={columns}
										isIndeterminate={isIndeterminateSelect}
										isSelectAll={isSelectAllUnits} />
									</div>
								<div class="unitRegListTableSrlBar">
									<div className="unitRegListTable">
									{
										dataList && dataList.map((item, i) => {
											return (
												<ul className="tBody" key={i}>
													<li>{rowNumber + i + 1}</li>
													<li>{item.BRAND_NAME}
														<div class="unitsListSM">{item.MODEL_NAME}, {item.MODEL_FAMILY_NAME}</div>
														<div class="unitsRSM"><FormattedMessage id="KC0655"/>: {item.PROVISIONING_STATUS === "UNIT_REGISTERED" ? <b className="confirmationTick">&nbsp;</b> : <b className="confirmationTick">&nbsp;</b>}</div>
														<div class="unitsASM"><FormattedMessage id="KC0656"/>: {item.ACTIVATION_STATUS === "INACTIVE" ? "" : <b className="confirmationTick">&nbsp;</b>}</div></li>
													<li>{item.MODEL_FAMILY_NAME}</li>
													<li>{item.MODEL_NAME}</li>
													<li>{item.UNIT_NUMBER}</li>
													<li>{item.UNITID} <span class="unitsListAN"><FormattedMessage id="KC0105"/> <b>{item.UNIT_NUMBER}</b></span></li>
													<li>{item.REGISTERED_ON ? moment.GetDateTimeByFormat(item.REGISTERED_ON, 'LLL'):""}</li>
													<li>{item.PROVISIONING_STATUS === "UNIT_REGISTERED" ? <span className="confirmationTick">&nbsp;</span> : <span className="confirmationTick">&nbsp;</span>}</li>
													<li>{item.ACTIVATION_STATUS === "INACTIVE" ? "" : <span className="confirmationTick">&nbsp;</span>}</li>
													<li>
														<div className={userProfileAuthFeature.is_editable == 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"} >
															<input id={item.ID} name="unitRegList" type="checkbox" value={item.checked} checked={item.checked} onChange={(e) => this.onSelectUnit(e, item)} />
															<label htmlFor={item.ID}></label>
														</div>
													</li>
												</ul>
											)
										})
									}
									</div>
									<NoRecordsFound loaderIconVisible={loaderIconVisible} length={dataList !== undefined ? dataList.length : 0} classname={'norecordsfoundbluetext'} stringID={this.state.searchData === undefined && dataList && dataList.length === 0 ? "KC0205" : this.state.searchData && dataList && dataList.length === 0 ? "KC0853" : ''}/>
								</div>
								{ totalPages && totalPages > 1 ? <GoToPagination
									totalPageNumber={totalPages}
									pageNumber={currentPage}
								navigateTo={(p) => this.onPageChange(p)} /> : null }
							<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
							<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
						</div>
					</div>
					<UserManagementHeader headerName="Unit Registration and Assignment" headerNameStringID="KC1684" activeClass="UnitRegistrationNav" />
				</div>
				<div className="modal fade confirmationDeleteModal confirmationAssignModal" data-keyboard="true" data-backdrop="static" id="confirmationAssignModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="deleteConfirmation">
									<FormattedMessage id="KC1087"/>
				            	</div>
							</div>
							<div className="assignUpdateForm">
								<ul>
									<li id="timeSchedulePanel">
										<input type="text" className="form-control timeZoneSelctOption" id="timeZoneSelctOption" autocomplete="off" placeholder={formatMessage({ id: 'KC0256' })} name="timeZone" maxlength="8" />
										<ul id="timezoneinfo" className="dropdownTimeZoneOptions">
											{
												timezonedata && timezonedata.length > 0 && timezonedata.map((value) =>
													<li><a href="javascript:void(0)" data-value={value.time_zone_id}>{value.display_name}</a></li>
												)
											}
										</ul>
									</li>
								</ul>
							</div>
							<div className="modal-footer textAlignCenter">
								<button id="closeTimeZone" className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
								<a id="OpenedTimeZone" href="javascript:void(0)" title="" className="btn btn-secondary deleteYesBtn disabled" onClick={(e) => this.OnAssignUnit()}><FormattedMessage id="KC0645"/></a>
							</div>
						</div>
					</div>
				</div>

				{/* unit confirmation Assign Modal */}
				<div class="modal fade activateNowModal" data-keyboard="true" data-backdrop="static" id="activateNowModal" tabindex="-1" role="dialog" aria-labelledby="activateNowModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">  
							<div class="modal-body"> 
								<div class="deleteConfirmation"> 
									<FormattedMessage id="KC1685"/>
								</div> 
							</div>
							<div class="modal-footer textAlignCenter">
							<button class="btn btn-secondary activateNowBtn" data-dismiss="modal" type="button"onClick={(e) => this.activateUnits() }><FormattedMessage id="KC1088"/></button>
							<button class="btn btn-secondary woActivationBtn" data-dismiss="modal" type="button" onClick={(e) => this.OnAssignUnit()}><FormattedMessage id="KC1089"/></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ 
		searchVisible, searchText, clearSearchText
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UnitRegistration));