import React, { Component } from 'react';
import http from '../../service/httpService';
import {LocationInfo} from '../../service/api';
import { render } from 'react-dom';
import Map from './map'
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import am4geodata_worldLow from "@amcharts/amcharts4-geodata/worldLow";
import am4geodata_usaLow from "@amcharts/amcharts4-geodata/usaLow";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.useTheme(am4themes_animated);

class SampleMap extends Component {
  constructor(props) {
    super(props);
    this.state = {
			error: null,
			isLoaded: false,
			map_locations: []
        };
        
    this.renderMap = this.renderMap.bind(this);
  }

  redirect = (groupId) => {
		this.props.history.push(`/dashBoardLocations/specificLocation?groupId=` + groupId);
  }
  
renderMap= (mapData)=> {
   
    // Themes begin
am4core.useTheme(am4themes_animated);
// Themes end

/**
 * Define SVG path for target icon
 */
//var targetSVG = "M9,0C4.029,0,0,4.029,0,9s4.029,9,9,9s9-4.029,9-9S13.971,0,9,0z M9,15.93 c-3.83,0-6.93-3.1-6.93-6.93S5.17,2.07,9,2.07s6.93,3.1,6.93,6.93S12.83,15.93,9,15.93 M12.5,9c0,1.933-1.567,3.5-3.5,3.5S5.5,10.933,5.5,9S7.067,5.5,9,5.5 S12.5,7.067,12.5,9z";

// Create map instance
var chart = am4core.create("mapdiv", am4maps.MapChart);
chart.geodata = am4geodata_worldLow;
chart.projection = new am4maps.projections.Miller();

// Create map polygon series
var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
polygonSeries.exclude = ["AQ"];
polygonSeries.useGeodata = true;

var polygonTemplate = polygonSeries.mapPolygons.template;
polygonTemplate.tooltipText = "{name}";

// Series for United States map
var usaSeries = chart.series.push(new am4maps.MapPolygonSeries());
usaSeries.geodata = am4geodata_usaLow
var polygonTemplate = usaSeries.mapPolygons.template;
polygonTemplate.tooltipText = "{name}";

// create capital markers
var imageSeries = chart.series.push(new am4maps.MapImageSeries());

// define template
var imageSeriesTemplate = imageSeries.mapImages.template;

// var circle = imageSeriesTemplate.createChild(am4core.Sprite);
// circle.scale = 0.4;
// circle.fill = new am4core.InterfaceColorSet().getFor("alternativeBackground");
// circle.path = targetSVG;

imageSeriesTemplate.propertyFields.latitude = "latitute";
imageSeriesTemplate.propertyFields.longitude = "longitude";
imageSeriesTemplate.propertyFields.title = "location_name";
imageSeriesTemplate.nonScaling = true;
imageSeriesTemplate.horizontalCenter = "middle";
imageSeriesTemplate.verticalCenter = "middle";
imageSeriesTemplate.align = "center";
imageSeriesTemplate.valign = "middle";
imageSeriesTemplate.width = 8;
imageSeriesTemplate.height = 8;
imageSeriesTemplate.tooltipText = "{location_name}";
imageSeriesTemplate.fill = am4core.color("#000");
imageSeriesTemplate.background.fillOpacity = 0;
imageSeriesTemplate.background.fill = am4core.color("#ffffff");
imageSeriesTemplate.setStateOnChildren = true;
imageSeriesTemplate.states.create("hover");

imageSeries.data = [
  {
  "minZoomLevel": 6,
    "cust_gp_id": 6,
    "location_id": "103",
    "location_name": "Welbilt Kitchencare",
    "location_type": "Hotel",
    "region": "East",
    "country": "United States",
    "city": "Tampa",
    "street": "1505 N Dale Mabry Hwy",
    "latitute": 27.94742,
    "longitude": -82.45878,
    "total_Units": 1,
    "CriticalOnline": 1,
    "CriticalOffline": 0,
    "NonCriticalOnline": 0,
    "NonCriticalOffline": 0,
    "NoErrorOffline": 0,
    "NoErrorOnline": 0,
    "dynasty": "2,20,23,17,6"
  },
  {
  "minZoomLevel": 6,
    "cust_gp_id": 8,
    "location_id": "105",
    "location_name": "Amotofors Store",
    "location_type": "Pit Stop",
    "region": "East",
    "country": "Sweden",
    "city": "Amotfors",
    "street": "12823, rd",
    "latitute": 59.84312,
    "longitude": 12.34963,
    "total_Units": 1,
    "CriticalOnline": 1,
    "CriticalOffline": 0,
    "NonCriticalOnline": 0,
    "NonCriticalOffline": 0,
    "NoErrorOffline": 0,
    "NoErrorOnline": 0,
    "dynasty": "2,20,23,17,8"
  },
  {
  "minZoomLevel": 6,
    "cust_gp_id": 10,
    "location_id": "107",
    "location_name": "Mississauga Store",
    "location_type": "Hotel",
    "region": "West",
    "country": "Canada",
    "city": "Mississauga",
    "street": "1500 Dundas St E",
    "latitute": 43.78148,
    "longitude": -79.7276,
    "total_Units": 2,
    "CriticalOnline": 1,
    "CriticalOffline": 0,
    "NonCriticalOnline": 0,
    "NonCriticalOffline": 0,
    "NoErrorOffline": 0,
    "NoErrorOnline": 0,
    "dynasty": "2,20,23,18,10"
  },
  {
  "minZoomLevel": 6,
    "cust_gp_id": 13,
    "location_id": "110",
    "location_name": "Cracker Barrel",
    "location_type": "Hotel",
    "region": "East",
    "country": "United States",
    "city": "Mount Juliet",
    "street": "100 Herschel Drive",
    "latitute": 36.20245,
    "longitude": -86.5246,
    "total_Units": 1,
    "CriticalOnline": 1,
    "CriticalOffline": 0,
    "NonCriticalOnline": 0,
    "NonCriticalOffline": 0,
    "NoErrorOffline": 0,
    "NoErrorOnline": 0,
    "dynasty": "2,20,23,17,13"
  },
  {
  "minZoomLevel": 6,
    "cust_gp_id": 16,
    "location_id": "1",
    "location_name": "Downtown Kitchen",
    "location_type": "Casual Stop",
    "region": "West",
    "country": "Sweden",
    "city": "Copenhagen",
    "street": "street1",
    "latitute": 59.334591,
    "longitude": 18.06324,
    "total_Units": 2,
    "CriticalOnline": 1,
    "CriticalOffline": 0,
    "NonCriticalOnline": 0,
    "NonCriticalOffline": 0,
    "NoErrorOffline": 0,
    "NoErrorOnline": 0,
    "dynasty": "2,20,23,18,16"
  },
  {
  "minZoomLevel": 6,
    "cust_gp_id": 27,
    "location_id": "5",
    "location_name": "Welbilt Kitchen",
    "location_type": "Casual Stop",
    "region": "East",
    "country": "Sweden",
    "city": "Copenhagen",
    "street": "street1",
    "latitute": 59.334591,
    "longitude": 18.06324,
    "total_Units": 1,
    "CriticalOnline": 1,
    "CriticalOffline": 0,
    "NonCriticalOnline": 0,
    "NonCriticalOffline": 0,
    "NoErrorOffline": 0,
    "NoErrorOnline": 0,
    "dynasty": "2,30,21,32,27"
  },
  {
  "minZoomLevel": 6,
    "cust_gp_id": 4,
    "location_id": "101",
    "location_name": "Brandon FL",
    "location_type": "Casual Stop",
    "region": "East",
    "country": "United States",
    "city": "Brandon",
    "street": "308 W Brandon Blvd",
    "latitute": 28.19524,
    "longitude": -82.6798,
    "total_Units": 3,
    "CriticalOnline": 1,
    "CriticalOffline": 0,
    "NonCriticalOnline": 0,
    "NonCriticalOffline": 0,
    "NoErrorOffline": 1,
    "NoErrorOnline": 0,
    "dynasty": "2,20,23,17,4"
  },
  {
    "minZoomLevel": 6,
    "cust_gp_id": 12,
    "location_id": "109",
    "location_name": "Subway Knoxville",
    "location_type": "Pit Stop",
    "region": "East",
    "country": "United States",
    "city": "Subway Knoxville",
    "street": "325 Sub Way",
    "latitute": 35.96264,
    "longitude": -83.9167,
    "total_Units": 2,
    "CriticalOnline": 1,
    "CriticalOffline": 0,
    "NonCriticalOnline": 0,
    "NonCriticalOffline": 0,
    "NoErrorOffline": 1,
    "NoErrorOnline": 0,
    "dynasty": "2,20,23,18,12"
  },
  {
    "minZoomLevel": 1,
    "cust_gp_id": 6,
    "location_id": "103",
    "location_name": "Test1",
    "location_type": "Hotel",
    "region": "East",
    "country": "United States",
    "city": "Tampa",
    "street": "1505 N Dale Mabry Hwy",
    "latitute": 27.94742,
    "longitude": -82.45878,
    "total_Units": 6,
    "CriticalOnline": 3,
    "CriticalOffline": 0,
    "NonCriticalOnline": 0,
    "NonCriticalOffline": 0,
    "NoErrorOffline": 0,
    "NoErrorOnline": 0,
    "dynasty": "2,20,23,17,6"
  },
  {
  "minZoomLevel": 1,
  "cust_gp_id": 27,
  "location_id": "5",
  "location_name": "Test2",
  "location_type": "Casual Stop",
  "region": "East",
  "country": "Sweden",
  "city": "Copenhagen",
  "street": "street1",
  "latitute": 59.334591,
  "longitude": 18.06324,  
  "total_Units": 10,
  "CriticalOnline": 4,
  "CriticalOffline": 0,
  "NonCriticalOnline": 0,
  "NonCriticalOffline": 0,
  "NoErrorOffline": 0,
  "NoErrorOnline": 0,
  "dynasty": "2,20,23,17,6"
  }
];

chart.events.on( "mappositionchanged", updateCustomMarkers );

// set zoom events
chart.events.on("zoomlevelchanged", function(ev) {
  showorhideImage(chart); 
})

// Zoom control
chart.zoomControl = new am4maps.ZoomControl();
chart.zoomControl.plusButton.disabled = true;
chart.zoomControl.minusButton.disabled = true;

var customZoomFactor = 2; // default: 2

var plusButton = chart.zoomControl.createChild(am4core.Button);
plusButton.shouldClone = false;
plusButton.label.text = "+";
plusButton.label.fill = "#a3a3a3";
plusButton.background.fill = "#000";
plusButton.width = am4core.percent(100);
plusButton.padding(5, 5, 5, 5);
plusButton.marginTop = 2;
plusButton.events.on("hit", function() {
  chart.zoomToGeoPoint(
    chart.zoomGeoPoint,
    chart.zoomLevel * customZoomFactor,
    false
  );
  showorhideImage(chart);
});
plusButton.insertBefore(chart.zoomControl.slider);

var minusButton = chart.zoomControl.createChild(am4core.Button);
minusButton.shouldClone = false;
minusButton.align = "left";
minusButton.valign = "top";
minusButton.label.text = "-";
minusButton.label.fill = "#a3a3a3";
minusButton.background.fill = "#000";
minusButton.width = am4core.percent(100);
minusButton.padding(5, 5, 5, 5);
minusButton.marginTop = 6;
minusButton.marginBottom = 100;
minusButton.events.on("hit", function() {
  chart.zoomToGeoPoint(
    chart.zoomGeoPoint,
    chart.zoomLevel / customZoomFactor,
    false
  );
  showorhideImage(chart);
});
minusButton.insertAfter(chart.zoomControl.slider);


function updateCustomMarkers( event ) {
// go through all of the images
imageSeries.mapImages.each(function(image) {
  // check if it has corresponding HTML element
  if (!image.dummyData || !image.dummyData.externalElement) {
    // create onex
    image.dummyData = {
      externalElement: createCustomMarker(image)
    };
  }

  // reposition the element according to coordinates
  let xy = chart.geoPointToSVG( { longitude: image.longitude, latitude: image.latitude } );
  image.dummyData.externalElement.style.top = xy.y + 'px';
  image.dummyData.externalElement.style.left = xy.x + 'px';

  // if (chart.zoomLevel <= 9 && image.dataItem.dataContext.minZoomLevel == 1) {
  //   image.show();
  // }
  // else if (chart.zoomLevel > 9 && image.dataItem.dataContext.minZoomLevel >= 6) {
  //   image.show();
  // }
  // else {
  //   image.hide();
  // }
  showorhideImage(chart);
  
});
}

function showorhideImage(chart) {
  if (chart.zoomLevel <= 6)
  {
    for (let index = 0; index < document.getElementsByClassName("map-clickablelevel1").length; index++) {
      document.getElementsByClassName("map-clickablelevel1")[index].hidden = false;
    }
    for (let index = 0; index < document.getElementsByClassName("map-clickable").length; index++) {
      document.getElementsByClassName("map-clickable")[index].hidden = true;
    }
  }
  else
  {
    for (let index = 0; index < document.getElementsByClassName("map-clickablelevel1").length; index++) {
      document.getElementsByClassName("map-clickablelevel1")[index].hidden = true;
    }
    for (let index = 0; index < document.getElementsByClassName("map-clickable").length; index++) {
      document.getElementsByClassName("map-clickable")[index].hidden = false;
    }
  }
}

function createCustomMarker( image ) {
  
  let chart = image.dataItem.component.chart;
  //let objCount = {};
  let deviceCount = "0";
  let errorClass = "dot disappear";
  //var array_locations = JSON.parse(localStorage.getItem('map_locations1'));
  
  // objCount = array_locations.find(function(element) {
  //   return element.location_id === image.textContent;
  // });
  //debugger;

  if (image.dataItem.dataContext !== undefined)
  {
  if (image.dataItem.dataContext.CriticalOnline > 0)
  {
    deviceCount = image.dataItem.dataContext.CriticalOnline;
    errorClass = "dot redColor";
  }
  else if (image.dataItem.dataContext.NonCriticalOnline)
  {
    deviceCount = image.dataItem.dataContext.NonCriticalOnline;
    errorClass = "dot yellowColor";
  }
  else if (image.dataItem.dataContext.CriticalOffline || image.dataItem.dataContext.NonCriticalOffline || image.dataItem.dataContext.NoErrorOffline > 0)
  {
    deviceCount = image.dataItem.dataContext.CriticalOffline + image.dataItem.dataContext.NonCriticalOffline + image.dataItem.dataContext.NoErrorOffline;
    errorClass = "dot greyColor";
  }
  else
  {
    deviceCount = "0";
    errorClass = "dot disappear";
  }
}
  
  // create holder
  let holder = document.createElement( 'div' );
  if (image.dataItem.dataContext !== undefined)
  {
  holder.className = 'map-marker-location';
  holder.style.position = 'absolute';

  if (image.dataItem.dataContext.minZoomLevel <= 2)
  {
    holder.title = image.dataItem.dataContext.region + ", " + image.dataItem.dataContext.country;
    holder.innerHTML = image.dataItem.dataContext.total_Units;
  }
  else
  {
    holder.title = image.dataItem.dataContext.street + ", " + image.dataItem.dataContext.city + ", " + image.dataItem.dataContext.country;
  // SVG Issue
    let imgstr = "<img src='https://www.welbiltconnect.com/static/mediaHotel.293b05a2.svg'>";
    if (image.dataItem.dataContext.location_type === "Casual Stop")
      imgstr = "<img src='https://www.welbiltconnect.com/static/mediaFastCasual.8cdd9e2e.svg'>";
    else if (image.dataItem.dataContext.location_type === "Quick Stop")
      imgstr = "<img src='https://www.welbiltconnect.com/static/mediaRestaurant.e33db406.svg'>";
    else if (image.dataItem.dataContext.location_type === "Pit Stop")
      imgstr = "<img src='https://www.welbiltconnect.com/static/mediaConvenience.52de05ce.svg'>";
    
    holder.innerHTML = imgstr + image.dataItem.dataContext.location_name;
  }
  
  image.url = "/dashBoardLocations/specificLocation?groupId=" + image.dataItem.dataContext.cust_gp_id;
  if ( undefined !== image.url ) {
    holder.onclick = function() {
      //window.location.href = image.url;
      this.props.history.push(`/dashBoardLocations/specificLocation?groupId=` + image.dataItem.dataContext.cust_gp_id);
    };
    if (image.dataItem.dataContext.minZoomLevel === 1)
    {
      holder.className += ' map-clickablelevel1';
    }
    else {
      holder.className += ' map-clickable';
    }
  }

  // create dot
  let dot = document.createElement( 'div' );
  dot.className = errorClass;
  dot.textContent = deviceCount;

  holder.appendChild( dot );

  // create pulse
  let pulse = document.createElement( 'div' );
  pulse.className = 'pulse';
  holder.appendChild( pulse );

  // append the marker to the map container
  chart.svgContainer.htmlElement.appendChild( holder );
}
  return holder;
}




// imageSeries.mapImages.each(function(image) {
//   if (image.dataItem.dataContext.minZoomLevel) {
//     if (chart.zoomLevel <= 9 && image.dataItem.dataContext.minZoomLevel == 1) {
//       image.show();
//     }
//     else if (chart.zoomLevel > 9 && image.dataItem.dataContext.minZoomLevel >= 6) {
//       image.show();
//     }
//     else {
//       image.hide();
//     }
//   }
// });

}

componentDidMount() 
{
  let url =`${LocationInfo.list}`;
  http.get(url,
    {
        headers: {
            'Content-Type':'application/json',
            'cgid': '',                        
        },
        data:{}}
      ).then(res => {
          this.setState({
            isLoaded: true,
            map_locations: res.data.locations,
          });
    this.renderMap(res.data.locations);
  }).catch(err => {
  });
}  

  render() {
    return (
      <div id="mapdiv"></div>
    );
  }
}

export default SampleMap;
