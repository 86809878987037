import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { UnitInfo, smartGroupAPI } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import http from '../../../service/httpService'
import $ from 'jquery';
import _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { BASECOUNTRY_ID, getRestrictSmartGroupCountValue } from '../../../utils/appConstants';
import appconstants from '../../../utils/appConstants';
import { getSelectedFilterList, resetSelectedFilterList } from '../../../actions/listView/locations/filterListAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ConfirmModal from '../../modal/confirm-model';
import { BRAND_ID } from '../../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import { searchVisible,searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';
import authService from '../../../service/authService';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { ungzip } from 'pako';
import NoRecordsFound from '../../controls/noRecordsFound';
const restrictValue = getRestrictSmartGroupCountValue();

$(window).on('beforeunload', function () {
	if ($(".export-error-report").length > 0) {
		$("#exportReport").removeClass("export-error-report");
		$(".loadingProgress").css("display", "none");
	}
});

class EditSmartGrpAssignUpdateList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			data: [],
			userSelectAll: false,
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			nameFromReport: '',
			startDateFromReport: '',
			endDateFromReport: '',
			selectedoptionsFromReport: '',
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			errorResponse: "",
			oldSmartGroupName: "",
			oldSelectedPurposes: [],
			sumartGroupID: "",
			oldSelUnits: [],
			unModifiedOldSelUnits:[],
			findPageName: "",
			access_modal: {
				open: false,
				message: "",
				isHtml: false,
                disabledBtn: false,
			},
			purposeValData: [],
			searchData: null,
			isIndeterminateSelect: false,
			higherLevelUsersUnits:[]
		};
		this.filterstate = {
			sCgids: [],
			sCids: [],
			sBrandids: [],
			sModels: [],
			sMachineTypes: [],
			sSmartGroups: [],
			sPurposes: [],
		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);

		this.myReports = this.myReports.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);

	}

	componentDidMount = async () => {
		this.props.searchVisible(true);
		document.body.style.backgroundColor = "#F2F2F2";		
		await this.getFilterDetails();
		await this.getUnitDetails();		
		try {
			this.requestData();
		} catch (error) {
			//Error Goes Here
		}

		const sqidfromLocal = localStorage.getItem("sgID");
		let { selectedoptionsFromReport, oldSelectedPurposes } = this.state;
		if (selectedoptionsFromReport === undefined || selectedoptionsFromReport === "" || oldSelectedPurposes === undefined || oldSelectedPurposes === "") {
			this.getSmartGroupDetails();
		}
	}

	componentWillMount() {
		if (this.props.location && this.props.location.state) {
			this.setState({
				nameFromReport: this.props.location.state.nameFromReport,
				selectedoptionsFromReport: this.props.location.state.selectedoptionsFromReport,
				oldSmartGroupName: this.props.location.state.oldSmartGroupName,
				oldSelectedPurposes: this.props.location.state.oldSelectedPurposes,
				sumartGroupID: this.props.location.state.sumartGroupID,
				findPageName: this.props.location.state.pageFrom,
			});
		}
	}

	componentDidUpdate(prevState) {
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;

		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.setState({loaderIconVisible:true})
			this.requestData();
		}
	}

	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	/**
	 * Decompress Base64 encode string into JSON
	 * @param {*} base64 Base64 encoded string
	 * @returns JSON | null
	 */
	decompressData(base64) {
		try {
			const buffer = Buffer.from(base64, 'base64');
			const bufferArr = ungzip(buffer);
			const json = new TextDecoder('utf8').decode(bufferArr);
			return JSON.parse(json);
		} catch(err) {
			console.error(err);
			return null;
		}
	}

	getFilterDetails = async () => {
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.smartGroupFilter);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
		}

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
		let selectedPurposesList = filterModel.find(el => el.field_name === 'purposes');
		let selectedSGList = filterModel.find(el => el.field_name === 'smartgroup');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];
		let selectedPurposes = [];
		let selectedSG = [];

		selectedCountryList.dataSource.map(c => {
			return selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			return selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			return selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			return selectedMachineTypes.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
		});

		selectedSGList.dataSource.map(c => {
			return selectedSG.push(c.smartGrpId)
		});

		selectedPurposesList.dataSource.map(c => {
			return selectedPurposes.push(c.purposeId)
		});

		let cgids = "";
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
		}
		else {
			cgids = localStorage.getItem("custGroupID");
		}

		this.filterstate = ({
			sCgids: cgids,
			sCids: selectedCids,
			sBrandids: selectedBrands,
			sModels: selectedModels,
			sMachineTypes: selectedMachineTypes,
			sSmartGroups: selectedSG,
			sPurposes: selectedPurposes,

		});

		var d = document.getElementById("spannavNotifiStatus");
		if (selectedSG.length > 0 || selectedPurposes.length > 0 || selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
			d.className = "navNotifiStatus";
		}
		else {
			d.className = "";
		}

	}

	getUnitDetails = async () => {
		this.setState({ loaderIconVisible: true });
		http({
			method: "GET",
			url: smartGroupAPI.createSmartGroupWithUnit,
			headers: {
				'Content-Type': 'application/json',
				'sgid': localStorage.getItem("sgID"),
				'cgid': localStorage.getItem("custGroupID"),
			}, data: {},
		}).then(response => {
			let { data } = response;
			if (data && data.units && data.units.length > 0) {
				const unitList = [];
				let unModifiedOldSelUnits=[];
				data.units.map(item => {
					if (item.isDeleted === "0" || item.isDeleted === 0) {
						return [unitList.push(item.uId),unModifiedOldSelUnits.push(item)]
					}
				});
				this.setState({
					oldSelUnits: unitList,
					unModifiedOldSelUnits,
					loaderIconVisible: false,
					userSelectAll: unitList.length > 0,
					isIndeterminateSelect : unitList.length > 0
				})
			}

		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
			})
		});

	}

	getSmartGroupDetails = () => {
		this.setState({ loaderIconVisible: true });
		const sgid = localStorage.getItem("sgID");
		let queryString;
		queryString = `customerId=${localStorage.getItem("customerId")}`
		queryString = queryString + `&userId=${localStorage.getItem("user")}`

		let url = `${smartGroupAPI.createSmartGroup}?${queryString}`;
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				let sGList = response.data;
				let selSignleSMGList = [];
				let exisPurposesIDS = [];

				sGList.map(i => {
					if (i.smartGrpId === parseInt(sgid)) {
						selSignleSMGList.push(i);
					}

				});

				if (selSignleSMGList && selSignleSMGList.length > 0) {
					selSignleSMGList[0].purpose.map(item => {
						return exisPurposesIDS.push(item.purposeId)
					});
				}

				this.setState({
					oldSmartGroupName: selSignleSMGList && selSignleSMGList.length > 0 ? selSignleSMGList[0].smarGrpName : "",
					selectedoptionsFromReport: this.state.selectedoptionsFromReport ? this.state.selectedoptionsFromReport : exisPurposesIDS,
					oldSelectedPurposes: this.state.oldSelectedPurposes ? this.state.oldSelectedPurposes : exisPurposesIDS,
					loaderIconVisible: false,
				});
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});

	}

	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	requestData = async () => {
		this.setState({ loaderIconVisible: true });
		let selectedCids = [];
		let selectedModels = [];
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		let apiUrl = `${UnitInfo.unitList}?compress=true`;


		let { searchData } = this.state;

		if (searchData && searchData.searchtext) { 
			apiUrl = `${apiUrl}?searchText=${searchData.searchtext}`;
		}

		if (searchData) {
			let searchParam = {};

			Object.keys(searchData).map((item) => {
				if (item !== "searchtext") {
					searchParam[item] = searchData[item];
				}
			})

			if (searchParam && Object.keys(searchParam).length) {
				apiUrl = `${apiUrl}${searchData && searchData.searchtext ? "&" : "?"}searchParams=${btoa(JSON.stringify(searchParam))}`;
			}
		}

		let headerValue = {
			'Content-Type': 'application/json',
			'cgid': cgids,
			'cid': localStorage.getItem("customerId"),
			'brandid': BRAND_ID?BRAND_ID:"",
			'model': selectedModels.join(','),
			'sortby': sortedID,
			'sorttype': sortedDesc,
			'basecountry': BASECOUNTRY_ID,
			'userid': localStorage.getItem("user"),
			//"sgid":this.filterstate.sSmartGroups?this.filterstate.sSmartGroups:localStorage.getItem("sgID"),		
		}

		await http.get(apiUrl,
			{
				headers: headerValue,
				data: {}
			}).then(response => {
				if (response.data.compressed) {
					response.data.units = this.decompressData(response.data.units) || [];
				}
				let { units, } = response.data;
				units = UnitBuilder(units);

				var checkedCopy = [];
				var userSelectAll = this.state.userSelectAll;
				units.forEach(function (e, index) {
					checkedCopy.push(userSelectAll);
				});
				const selectedUnit = [];
				if(this.props.location && this.props.location.userCheckedData){
					for(let i=0; i<this.props.location.userCheckedData.length; i++){
						selectedUnit.push(this.props.location.userCheckedData[i].ID);
					}
				}

				let array1 = units;
				let array2 = this.state.oldSelUnits;
				_.forEach(array1, a1 => {
					const ifExist = this.props.location && this.props.location.userCheckedData && this.props.location.userCheckedData.length ? false : array2.find(a2 => a2 === a1.ID);
					a1.checked = ifExist || selectedUnit.includes(a1.ID) ? true : false;
				});

				let checked = array1.filter(c => c.checked === true);

				this.setState({
					loaderIconVisible: false,
					data: units,
					...this.getCheckBoxstate(units.length,checked.length)
				});

			}).catch(err => {

			});
	}

	onBackClickHandler = () => {
		this.props.history.push({
			pathname: this.state.findPageName === "smartGroupList" ? "/smartgroupslist" : "/editsmartgrp",
			state: {
				nameFromReport: this.state.nameFromReport,
				startDateFromReport: this.state.startDateFromReport,// "2020-04-09",
				endDateFromReport: this.state.endDateFromReport,// "2020-04-09",
				selectedoptionsFromReport: this.state.selectedoptionsFromReport,
				pageFrom: this.state.findPageName
			},
		});
	}

	myReports = () => {
		this.setState({
			loaderIconVisible: false,
		});
		this.props.history.push(`/reportList`);
	}


	handleChange = () => {
		let units = this.state.data;

		for(let i=0; i < units.length; i++){
			units[i].checked = !this.state.userSelectAll;
		}

		this.setState(prevState => ({
			data: units,
			userSelectAll: !prevState.userSelectAll,
			isIndeterminateSelect: false
		}));
	};

	getCheckBoxstate(ListCount, SelectedCount) {
		if (SelectedCount === ListCount) {
			return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (SelectedCount === 0 && ListCount !== 0) {
			return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (ListCount >= SelectedCount) {
			return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange = (e, ID) => {
		let units = [];
		for(let i=0; i< this.state.data.length; i++){
			units.push({...this.state.data[i],checked:this.state.data[i].ID === ID ? e.target.checked : this.state.data[i].checked || false});
		}
		
		this.setState({
			data: units,
			...this.getCheckBoxstate(units.length, units.filter((i)=>i.checked === true).length)
		});
	};

	onClickFinish = async (e) => {
		this.sgPurposeValidations();
	}

	sgPurposeValidations = async (postData) => {
		const { formatMessage } = this.props.intl;
		let userCheckedData = this.state.data.filter(unit => unit.checked === true);
		if(userCheckedData.length > restrictValue){
            this.setState({
                error_modal: {
                    open: true,
                    message: formatMessage({ id: 'KC1774' }, { count: restrictValue }),
                },
            });
            return;
        } 
		this.setState({ loaderIconVisible: true, })
		let newSGDetails = JSON.parse(localStorage.getItem("afterEditSG"));
		const unitList = [];
		let unitObj = [];
		userCheckedData.map(item => {
			unitList.push(item.ID);
			unitObj.push(
				{
					"unitId": item.ID,
					"brandId": item.BRAND_ID,
				}
			)
		});

		let postdataForPerVal = {
			"name": newSGDetails.smarGrpName,
			"purposeId": newSGDetails.purposes,
			"customerId": + localStorage.getItem("customerId"),
			"userId": localStorage.getItem("user"),
			"unitObj": unitObj,
			"smartGroupId": localStorage.getItem("sgID"),			
			"roleName":  localStorage.getItem("roleName"),			
			"childUsers":  JSON.parse(localStorage.getItem('childUsersEmail')),
		};

		await http.post(smartGroupAPI.purposeValidations, postdataForPerVal,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		).then(response => {
			if (response && response.data) {				
				if (response.data.invalidPurposeNames && response.data.invalidPurposeNames.length > 0) {
					let txtMsg = `<p>${formatMessage({ id: 'KC1777' }, { purposeNames: `<b>[${response.data.invalidPurposeNames}]</b>` })}</p>
                    <p>${formatMessage({ id: 'KC1778' }, { smartGroupName: `<b>[${newSGDetails.smarGrpName}]</b>` })}</p>`

					let higherLevelUsers = [];
                    if (response.data.higherLevelUsersCreated && response.data.higherLevelUsersCreated.length > 0){
                        higherLevelUsers = response.data.higherLevelUsersCreated;
                        txtMsg = txtMsg + `<p><b>${formatMessage({ id: 'KC1753' })}:</b> ${formatMessage({ id: 'KC1755' })}</p>`
                    }

					let nonHigherUnitObj = [];
                    let selecData = this.state.checkedUnits? this.state.checkedUnits :[];                    
                    for(let i=0; i< selecData.length; i++){
                        const item = selecData[i];
                        let filterVal = higherLevelUsers.filter(j => j.unitId === item.ID);
                        if (filterVal.length === 0 ) {
                            nonHigherUnitObj.push(
                            {
                                "unitId": item.ID,
                            })
                        }
                    }

					this.setState({
						loaderIconVisible: false,
						purposeValData: response.data.unitData,
						higherLevelUsersUnits : higherLevelUsers, 
						access_modal: {
							open: true,
							message: txtMsg,
							isHtml: true,
                            disabledBtn : nonHigherUnitObj.length > 0 ? false : true ,
						},
					});
				} else {
					this.submitForExportReport();
				}
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	submitForExportReport = async () => {
		const { formatMessage } = this.props.intl;
		this.setState({ loaderIconVisible: true, })
		let selectedUnitList = [], selectedOldUnit = [];
		let userChecked = this.state.data.filter((i)=>i.checked === true);

		if (userChecked && userChecked.length > 0) {
			userChecked.forEach(function (item, index) {
				selectedUnitList.push({
					"unitId": item.ID,
					"brandId": item.BRAND_ID,
				});
			})
		} else if (this.state.userSelectAll) {
			if (this.state.data && this.state.data.length > 0) {
				this.state.data.forEach(function (item, index) {
					selectedUnitList.push({
						"unitId": item.ID,
						"brandId": item.BRAND_ID,
					});
				})
			}
		}

		let parantUserSelUnitID = this.state.higherLevelUsersUnits;
		for (let i = 0; i < parantUserSelUnitID.length; i++) {                
			let index = selectedUnitList.findIndex(j => j.unitId === parantUserSelUnitID[i].unitId);
			selectedUnitList.splice(index, 1);
		}

		if (this.state.unModifiedOldSelUnits.length > 0) {
			this.state.unModifiedOldSelUnits.map((item) => {
				selectedOldUnit.push(
					{
						"unitId": item.ID,
						"brandId": item.BRAND_ID,
					}
				)
			})
		}

		let replaceUnit=[];
		let purValData =this.state.purposeValData;
		if (purValData.length>0){
			purValData.map(item => {
				if (item.invalidPurpose.length > 0 && item.higherUserCreated === 0) {
					return replaceUnit.push(
						{"unitId":item.unitId,
						"sgId":item.sgId,}
						)
				}
			});
		}

		let newSGDetails = JSON.parse(localStorage.getItem("afterEditSG"));
		let oldSGDetails = JSON.parse(localStorage.getItem("beforeEditSG"));
		let postdata = {
			"smartGroupId": localStorage.getItem("sgID"),
			"newName": newSGDetails.smarGrpName,
			"oldName": oldSGDetails.smarGrpName,
			"newPurposeIds": newSGDetails.purposes,
			"oldPurposeIds": oldSGDetails.purposes,
			"oldUnits": selectedOldUnit,
			"newUnits": selectedUnitList,
			"customerId": localStorage.getItem("customerId"),
			"userId": localStorage.getItem("user"),
		};

		if (replaceUnit.length>0){
			postdata.replaceUnit = replaceUnit;
		}

		await http.put(smartGroupAPI.createSmartGroup, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		).then(response => {
			if (response && response.data) {
				this.setState({ responseData: response.data, loaderIconVisible: false });

				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						message: formatMessage({id:"KC1661"}),
					},
				});
				WoopraEvents(`${Constants.SMART_GROUP_UPDATED}`);
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	showError = (err) => {
		try {
			let { response } = err;
			if (response && response.status) {

				let { data } = response;
				let { body } = data;
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: body.message ? body.message : ""
					}
				});
			} else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: err.message
					}

				});
			}
		} catch (e) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.message
				}

			});
		}
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = async () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/smartgroupslist`);
		await this.resetValue();
	}

	resetValue = () => {
		localStorage.removeItem("smartGroupFilter");
		const arr = [];
		this.props.resetSelectedFilterList(arr);
	}

	onClickListView = () => {
		this.props.history.push({
			pathname: "/editsmartGrpTopology",
			userCheckedData: this.state.data.filter(unit => unit.checked === true),
			state: {
				nameFromReport: this.state.nameFromReport,
				selectedoptionsFromReport: this.state.selectedoptionsFromReport,
				sumartGroupID: this.state.sumartGroupID,
				oldSmartGroupName: this.state.oldSmartGroupName,
				oldSelectedPurposes: this.state.oldSelectedPurposes,
				pageFrom: this.state.findPageName,
			},
		});
	}

	accessNowModel = (e) => {
		this.submitForExportReport(e);
		this.setState({
			access_modal: {
				open: false
			}
		})
		WoopraEvents(`${Constants.PURPOSE_VALIDATION_CONFIRM}`);
	}

	accessLaterModel = () => {
		this.setState({
			access_modal: {
				open: false
			}
		})
		WoopraEvents(`${Constants.PURPOSE_VALIDATION_CANCELED}`);
	}
	static getDerivedStateFromProps = (props) => ({ ...props });


	render() {
		const { loaderIconVisible, data, sortColumn, error_modal, success_modal, access_modal } = this.state;
		let userProfileAuthFeature = authService.getFeature("/smartgroupslist");
		const { formatMessage } = this.props.intl;

		let columns = [ 
			{ path: "", label: "#" },
			{ path: "", label: formatMessage({id:"KC0101"}), isNbSp: true },
			{ path: "BRAND_NAME", label: formatMessage({id:"KC0062"}) },
			{ path: "PRODUCT_TYPE", label: formatMessage({id:"KC0102"}) },
			{ path: "MODEL_NAME", label: formatMessage({id:"KC0064"}) },
			{ path: "UNITID", label: formatMessage({id:"KC0104"}) },
			{ path: "UNIT_NUMBER", label: formatMessage({id:"KC0674"}) },
			{ path: "location_name", label: formatMessage({id:"KC0675"}) },
			{ path: "Within_Location", label: formatMessage({id:"KC0061"}) },
			{ path: "Smart_Group", label: formatMessage({id:"KC0065"}) },
			{ path: "CONNECT_STATE", label: formatMessage({id:"KC0523"}), isNbSp: true },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		let unitList = data;

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);
		const filterCheckedData = unitList.filter((i) => i.checked === true);
		const filterOfflineSelectedData = unitList.filter((i) => i.checked === true && i.CONNECT_STATE === "Offline");
		let Updatedunits = (parseInt(filterCheckedData.length) - parseInt(filterOfflineSelectedData.length));

		let showHeader;

		showHeader = (
			<ul>
				<li><FormattedMessage id="KC1570" values={{ selectedCount: filterCheckedData.length || 0, offlineCount: filterOfflineSelectedData.length || 0 }}/></li>
				<li>
					<Link to="#" id="btnAssign" className="assignunitSite" onClick={this.onClickListView}>&nbsp;</Link>
					<Link to="#" id="btnAssign" className="assignunitList active">&nbsp;</Link>
				</li>
			</ul>
		);

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div id="exportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper" class="withSearchBar">
						<div class="reportWrapper">
							<div class="adminBgHeader">
								<ul>
									<li>
										<button onClick={() => this.onBackClickHandler()} title={formatMessage({id:'KC0989'})} class="backIcon">&nbsp;</button>
									</li>
									<li className="txtboldmsg">
										<FormattedMessage id='KC0711' />
				            	</li>
									<li>
										<button id="btnfinish" title={formatMessage({id:'KC0056'})} className={`btn-default-text nextText ${filterCheckedData.length >0 ? 'activeState': 'disabled'}`} onClick={() => this.onClickFinish()}><FormattedMessage id='KC0056'/></button>
									</li>
								</ul>
							</div>
							<div class="selectedUnitHeader">
								{showHeader}
							</div>
							<form class="SGlistUnits">
								<div class="SWlistUnitsTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isIndeterminate={this.state.isIndeterminateSelect}
										isSelectAll={this.state.userSelectAll} />
								</div>
									{unitList.map((item, i) => {
										const title = item.smartGroups && item.smartGroups.length > 0 ? item.smartGroups.map((i)=>i.smartGroupName) : [];
										return (
											<React.Fragment key={i}>
												<div class="SWlistUnitsTable">
													<ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody"} key={i}> 
														<li>{i + 1}</li>
														<li><img src={item.Brand_Logo} alt="" /></li>
														<li>{item.BRAND_NAME}</li>
														<li>{item.PRODUCT_TYPE}</li>
														<li>{item.MODEL_NAME}</li>
														<li>{item.UNITID}</li>
														<li>{item.UNIT_NUMBER}</li>
														<li>{item.location_name}</li>
														<li>{item.within_location}</li>
														<li>
															{item.smartGroups && item.smartGroups.map((item, i) => {
																if (i === 3) {
																	return <span title={title.join(',')} className="contentOverflow active"> ... </span>
																} else if (i <= 3) {
																	return <span title={item.smartGroupName} className="sgFL">{item.smartGroupKeyName ? item.smartGroupKeyName : item.smartGroupName.charAt(0)}</span>
																}
															})}
														</li>

														<li><span className={`${item.CONNECT_STATE === 'Offline' ? "" : ""}`}></span></li>
														{<li>
															<div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
																<input id={`checkbox_${i}`} type="checkbox" value={item.checked} defaultChecked={item.checked}
																	checked={item.checked}
																	onChange={(e) => this.handleSingleCheckboxChange(e, item.ID)} />
																<label for={`checkbox_${i}`} ></label>
															</div>
														</li>}
													</ul>
												</div> 
											</React.Fragment>
										)
									}
									)} 
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={(unitList !== undefined || unitList) ? unitList.length : 0} classname={'norecordsfoundbluetext'} />
							</form>
						</div>
					</div>
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<UserManagementHeader headerName="Organization Management" headerNameStringID="KC0519" activeClass="orgznManagementNav" filterKey={appconstants.smartGroupFilter} history={this.props.history} />
					<ConfirmModal  disabled = {access_modal.disabledBtn} open={access_modal.open} message={access_modal.message} isHtml={access_modal.isHtml} onAccessNowModel={(e) => this.accessNowModel(e)} onAccessLaterModel={(e) => this.accessLaterModel(e)} />
				</div>
				<div class="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationOkModal" tabindex="-1" role="dialog" aria-labelledby=" confirmationOkModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-body">
								<div class="confirmationContentDiv">
									<h5><FormattedMessage id='KC0832'/></h5>
									<p><FormattedMessage id="KC1571" values={{ Updatedunits, unitsSelected: filterCheckedData.length, unitsOffline: filterOfflineSelectedData.length }}/></p>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" onClick={() => this.myReports()} type="button"><FormattedMessage id='KC0012'/></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};


function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel,
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getSelectedFilterList,
		resetSelectedFilterList,
		searchVisible,
		searchText
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(EditSmartGrpAssignUpdateList));