import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import * as _ from 'lodash';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { MenuBrandsResponse } from '../../../utils/unitResponseBuilder';
import { getMediaLibraryList } from '../../../actions/navigation/mediaManagement/mediaLibraryListActions'
import { BaseService, ReportManagement } from '../../../service/api';
import { BRAND_ID } from '../../../utils/appConstants';
import $ from 'jquery';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import NoRecordsFound from '../../controls/noRecordsFound';

class BrandReportList extends Component {
	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			brandList: [],
			loaderIconVisible: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			}
		};
	}

	getBrands = () => {
		this.setState({ loaderIconVisible: true });
		let url = BaseService.root + ReportManagement.getReportBrands;
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				brandId: BRAND_ID,
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({
					loaderIconVisible: false,
					brandList: response.data.branList,
				});
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	componentDidMount = () => {
		$('body').removeClass("modal-backdrop show");
		document.body.style.backgroundColor = "#F2F2F2";
		this.getBrands();
	}

	onButtonClickHandler = (item) => {
		localStorage.setItem("brandName", item.BRAND_NAME);
		localStorage.setItem("selectedbrandID", item.BRAND_ID);
		WoopraEvents(`${Constants.REPORTS}-${item.BRAND_NAME}`);
		this.props.history.push(`/reportList`);
	}

	render() {
		let {
			brandList,
			loaderIconVisible } = this.state;
		let branList = [];
		let orderId = 1;
		let menuItemList;
		if (brandList !== undefined && brandList.length > 0) {
			branList = MenuBrandsResponse(brandList);
			branList = _.sortBy(branList, ['BRAND_NAME']);
			menuItemList = (
				branList.map((item) =>
					<ul className="tBody" key={item.ID} onClick={() => this.onButtonClickHandler(item)}>
						{(item.BRAND_NAME === "CREM" || item.BRAND_NAME === "MERCO" || item.BRAND_NAME === "FRYMASTER" || item.BRAND_NAME === "GARLAND" || item.BRAND_NAME === "FRYMASTER-UHCTHD") ? <li>{orderId++}</li> : ""}
						{(item.BRAND_NAME === "CREM" || item.BRAND_NAME === "MERCO" || item.BRAND_NAME === "FRYMASTER" || item.BRAND_NAME === "GARLAND" || item.BRAND_NAME === "FRYMASTER-UHCTHD") ? <li>{<img src={item.img} alt=""/>} </li> : ""}
						{(item.BRAND_NAME === "CREM" || item.BRAND_NAME === "MERCO" || item.BRAND_NAME === "FRYMASTER" || item.BRAND_NAME === "GARLAND" || item.BRAND_NAME === "FRYMASTER-UHCTHD") ? <li><button class="btn forwardNext">&nbsp;</button></li> : ""}
					</ul>
				)
			)
		}
		else {
			menuItemList = (
				<div className="menuBrandsTable">
					<NoRecordsFound loaderIconVisible={loaderIconVisible} length={branList !== undefined ? branList.length : 0} classname={'norecordsfoundbluetext'} />
				</div>)
		}
		return (

			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">

					<div className="menuUpdateWrapper"> 
						<div className="menuUpdateHeader">
							<ul>
								<li>
									<Link to="/brandReportList" title="Brands" className="active">Brands</Link>
								</li>
							</ul>
						</div>

						<div className="menuBrandsTable">
							<ul className="tHead">
								<li>#</li>
								<li>Brand</li>
								<li>&nbsp;</li>
							</ul>
						</div> 
						<form className="menuBrandsTableDiv">
							<div className="menuBrandsTable">

								{menuItemList}
							</div>
						</form>
					</div>
				</div>
				<UserManagementHeader headerName="Reports" activeClass="reportNav" />
			</div>
		)
	}
};

function mapStateToProps(state) {

	return {
		mediaLibraryListInfo: state.mediaLibraryListInformation.mediaLibraryListInformation,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaLibraryList,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandReportList);