import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../service/httpService';
import { unitAPI } from '../../service/api';
import  authService from '../../service/authService';

export class UserBrandList extends Component {
  constructor(props) {
    super(props);
    this.state = {             
      brandList: [],
      brandName: "",
      open: false
    }
    this.onBrandSelection = this.onBrandSelection.bind(this);  
  }

  componentWillMount = async () => {
    // await http.get(unitAPI.getBrands, {
    //   headers: {
    //     'Content-Type': 'application/json'
    //   },
    //   data: {}
    // }).then(response => {
    //   let brands = this.getLoginUserBrands(response.data);
    //   this.setState({
    //     brandList: brands.sort((a,b) => (a.BRAND_NAME > b.BRAND_NAME) ? 1 : ((b.BRAND_NAME > a.BRAND_NAME) ? -1 : 0)),
    //     brandName: brands[0].BRAND_NAME
    //   });
      // if (brands && brands.length > 0) {
      //   let e = {};
      //   e.target = {};
      //   e.target.text = brands[0].BRAND_NAME;
      //   this.props.onBrandSelection(e);
      // }
    // }).catch(err => {
    //   //Error Goes Here
    // });
    const brandListArr = await JSON.parse(localStorage.getItem('availableUnitsBrand'));
    this.setState({
      brandList: brandListArr,
      brandName: brandListArr && brandListArr.length > 0 ? brandListArr[0].BRAND_NAME : ''
    });
    if (brandListArr && brandListArr.length > 0) {
      let e = {};
      e.target = {};
      e.target.text = brandListArr[0].BRAND_NAME;
      this.props.onBrandSelection(e);
    }
  }

  componentDidMount() {
    let { brandList } = this.state.brandList;
    
  }
  /**
   * Get Default brands which was subscription by customer
   */
  getLoginUserBrands = (brands) => {
    this.setState({
      loaderIconVisible: true
    });
    let loginUserBrands = authService.getLoginUserBrands();
    let viewBrandsList = [], allBrandsList = [...brands];
    loginUserBrands.forEach(bId => {
      let selectedBrans = allBrandsList.filter(item => item.ID === bId);
      if (selectedBrans && selectedBrans.length > 0) {
        let brand = { ...selectedBrans[0] };
        brand.isChecked = false;
        viewBrandsList.push(brand);
        this.setState({
          loaderIconVisible: false
        });
      }
    });

    return viewBrandsList;
  }

  onBrandSelection = (e) => {
    this.props.onBrandSelection(e);
    this.setState({
      brandName: e.target.text
    });
  }

  render() {
    let { brandList, brandName } = this.state;
    let { brandLabelRequired } = this.props;
    return (
      brandLabelRequired && brandList && brandList.length === 1 ? <div class="dropdown brandSelectionGrp">
        {brandName}
      </div> : <div class="dropdown">
        <button class="btn btn-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {brandName}
        </button>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
          {brandList && brandList.length > 0 && brandList.map((item, i) =>
            <Link params={{ brandID: item.BRAND_ID }} class="dropdown-item" to="#" onClick={(e) => this.onBrandSelection(e)}>{item.BRAND_NAME}</Link>
          )}
        </div>
      </div>
    );
  }
}

export default UserBrandList;
