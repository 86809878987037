import axios from 'axios';
import api from '../../service/api';
import http from '../../service/httpService';
import {ReportManagement} from '../../service/api';
import { BRAND_ID } from '../../utils/appConstants';
export const HEATMAP_TABLE_REQUEST = 'HEATMAP_TABLE_REQUEST';
export const HEATMAP_TABLE_SUCCESS = 'HEATMAP_TABLE_SUCCESS';
export const HEATMAP_TABLE_FAIL = 'HEATMAP_TABLE_FAIL';


    export function getheatMapTableInfo (type, fromdate, todate, deviceId, brandid, productName) {
        let url ;
        let filterType;
        let groupbyValue = 'week';
        let widgetId = 22;
        let UNIT_BRAND_ID=1;
        switch (type) {
            case 'Week':
                filterType='weekly';  
                groupbyValue = 'day';      
                break; 
            case 'Year':
                filterType='yearly'; 
                groupbyValue = 'quarter';           
                break; 
            case 'Day':            
                filterType='daily';
                groupbyValue = 'day';
                break;
            case 'Quarter':
                filterType='quarterly';
                groupbyValue = 'month';            
                break; 
            case 'Date Range':
                filterType = 'custom';
                groupbyValue = 'day';
                break; 
            default: 
                filterType = 'monthly';              
          }    
        //url = api.heatMapTableDetails.replace("{deviceIdvalue}", deviceId).replace("{fromDatevalue}", fromdate).replace("{filterTypeValue}", filterType).replace("{groupbyValue}",groupbyValue)
    //${BaseService.root}
        if(localStorage.getItem("brandName")==="MERRYCHEF")
        {
            url = `${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
        }
        else if(localStorage.getItem("brandName")==="CONVOTHERM")
        {  
            let widgetId = 46;
            let UNIT_BRAND_ID = 6;
            url = `${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
        }
        else{
            url = `${ReportManagement.root}`
        }

    return dispatch => {
        dispatch({
            type: HEATMAP_TABLE_REQUEST
        })
          
        let header;

        if(localStorage.getItem("brandName")==="MERRYCHEF")
        {
            if (filterType === 'weekly' || filterType === 'custom')
            {
                header = {
                    'Content-Type':'application/json',
                    'unitid' : deviceId,
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType, 
                    'showtrend': "true",
                };
            }
            else
            {
                header = {
                    'Content-Type':'application/json',
                    'unitid' : deviceId,
                    'reqdate' : fromdate,
                    'filterType' : filterType, 
                    'showtrend': "true",
                }; 
            }
        }
        else if(localStorage.getItem("brandName")==="CONVOTHERM")
        {
            if (filterType === 'weekly')
            {
                header = {
                    'Content-Type':'application/json',
                    'unitid' : deviceId,
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType, 
                    'showtrend': "false",
                };
            }
            else
            {
                header = {
                    'Content-Type':'application/json',
                    'unitid' : deviceId,
                    'reqdate' : fromdate,
                    'filterType' : filterType, 
                    'showtrend': "false",
                }; 
            }
        }
        // CREM Expobar Model(EX3 DISPLAY & DIAMANT PRO DISPLAY) Number Of Products Sold Widget new header name(ex3) included for api invocation changes in reports & analytics screen
        else if(localStorage.getItem("brandName")==="CREM" && (productName === "EX3" || productName === "DIAMANT PRO"))
        {
            if (filterType === 'weekly' || filterType === 'custom')
            {
                header = {
                    'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'unitid' : deviceId,
                    'brandid': brandid !==undefined ? ( brandid.length > 0 ?  brandid : BRAND_ID):BRAND_ID,
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType,
                    'groupby' : 'product_type,'+ groupbyValue,
                    'ex3' : true
                }; 
            }
            else
            {
                header = {
                    'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'unitid' : deviceId,
                    'brandid': brandid !==undefined ? ( brandid.length > 0 ?  brandid : BRAND_ID):BRAND_ID,
                    'reqdate' : fromdate,
                    'filterType' : filterType,
                    'groupby' : 'product_type,'+ groupbyValue,
                    'ex3' : true
                }; 
            }
        }
        else
        {
            if (filterType === 'weekly' || filterType === 'custom')
            {
                header = {
                    'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'unitid' : deviceId,
                    //'brandid' : brandid,
                    'brandid': brandid !==undefined ? ( brandid.length > 0 ?  brandid : BRAND_ID):BRAND_ID,
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType,
                    'groupby' : 'product_type,'+ groupbyValue,
                }; 
            }
            else
            {
                header = {
                    'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'unitid' : deviceId,
                    // 'brandid' : brandid,
                    'brandid': brandid !==undefined ? ( brandid.length > 0 ?  brandid : BRAND_ID):BRAND_ID,
                    'reqdate' : fromdate,
                    'filterType' : filterType,
                    'groupby' : 'product_type,'+ groupbyValue,
                }; 
            }
        }
        header.cid=localStorage.getItem("customerId") ;

        http
            .get(url,
                {
                  headers: header,
                  data:{}
                })
            .then(response => {
                dispatch({
                    type: HEATMAP_TABLE_SUCCESS,
                    heatMapTableInformation: response.data,
                    filterType: filterType
                })
            })
            .catch(error => {
                dispatch({
                    type: HEATMAP_TABLE_FAIL,
                    error
                })
            })
    }
}