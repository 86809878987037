import { useEffect, useReducer } from "react"
import { getMediaAPIUrl, getMediaLibrary } from '../../service/mediaManagement/mediaLibrary';
import { FormattedMessage } from 'react-intl';
import moment from '../../utils/momentHelper';
import { Loader } from '@kcui/react/Loader';

const initialState = {
    mediaLibraryInformation: {
        mediaFileName: 'NA',
        mediaSize: 0,
        mediaDownloadUrl: '',
        mediaInstalledDate: 'NA'
    },
    loader: true
};
const reducer = (state, action) => {
    const { type, payload } = action;
    console.log("action: ", action);
    switch (type) {
        case "SET_MEDIA_FILE":
            return { ...state, mediaLibraryInformation: payload,  loader: false }
        default:
            return { ...state }
    }
};
const MediaLibrary = ({title, currentUnit }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        loadPreviousRequestLog();
    }, [])

    const loadPreviousRequestLog = async () => {
        let mediaPayload = {
            mediaFileName: 'NA',
            mediaSize: 0,
            mediaDownloadUrl: '',
            mediaInstalledDate: 'NA'
        }
        let mediaUrl = getMediaAPIUrl(currentUnit.UNITID);
        let mediaInfo = await getMediaLibrary(mediaUrl);
        let mediaObj = mediaInfo && mediaInfo.length > 0 ? mediaInfo.find((e) => e.mediaType === 'allMediaLibrary') : {};
        if(mediaObj){
            mediaPayload.mediaFileName = mediaObj.fileName || 'NA';
            mediaPayload.mediaSize = mediaObj.size || 0;
            mediaPayload.mediaDownloadUrl = mediaObj.mediaImagePath || '';
            mediaPayload.mediaInstalledDate = mediaObj.installed ? moment.ConvertLocalTimeFromUTCTime(mediaObj.installed, "YYYY-DD-MM") : 'NA';
            dispatch({ type: "SET_MEDIA_FILE", payload: mediaPayload })
        }
    }
    const downloadFiles = (uri) => {
		const  link = document.createElement("a");
		link.href = uri;
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	}

    return (
        <div class="gridViewTableErgo gridViewcolm6DSM">
            <div class="gridView">
                <div class="colm4D colm6D1">
                    <div class="colmDi title">
                        <h4><FormattedMessage id={title} /></h4>
                    </div>
                    <div class="colmDi">
                        {state.loader ? <Loader loader={true} /> :
                        <div class="mediaLibraryTable">
                            <ul>
                                <li>
                                    <ul>
                                        <li>
                                            <label><FormattedMessage id="KC0677"/></label>                                                        
                                            <p title= {state.mediaLibraryInformation.mediaFileName} class="labelValWrap">{state.mediaLibraryInformation.mediaFileName}</p> 
                                        </li>
                                        <li>
                                            <label><FormattedMessage id="KC0048"/></label>                                                       
                                            <span class="labelVal"><FormattedMessage id="KC2985"/></span>
                                        </li>                                                        
                                        <li>
                                            <label><FormattedMessage id="KC0213"/></label>                                                      
                                            <span class="labelVal">{state.mediaLibraryInformation.mediaSize} <FormattedMessage id="KC1498"/></span>
                                        </li>                                              
                                        <li>
                                            <label><FormattedMessage id="KC0214"/></label>                                                        
                                            <span class="labelVal">{state.mediaLibraryInformation.mediaInstalledDate}</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <button type="button" class={`btn btn-default viewFileBtn ${state.mediaLibraryInformation.mediaDownloadUrl === '' ? 'disabled' : ""}`} onClick={()=>downloadFiles(state.mediaLibraryInformation.mediaDownloadUrl)}><FormattedMessage id="KC2984"/></button>                                        
                        </div>}
                    </div>
                </div>                            
            </div>
        </div>
    )

}

export default MediaLibrary
