import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ReportManagement,smartGroupAPI } from '../../../service/api';
import http from '../../../service/httpService';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import ConfirmModal from '../../modal/confirm-model';
import { TableHeader } from '../../controls/tableHeader';
import authService from '../../../service/authService';
import Tooltip from 'react-tooltip-lite';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl } from 'react-intl';

class CreateNewSmartGrp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            loaderIconVisible: false,
            responseData: [],
            purposesList: [],
            oldProdID: "",
            oldProdName: "",
            purposesDesc: "",
            purposesName: "",
            smartGroupName : "",
            add_mode: false,
            update_mode: false,
            isMultiDelete: false,
            isAdd: false,
            isEdit: false,
            isDelete: false,            
            allSelect: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            selectedPurposes:[],
            purposeID:"",
            access_modal: {
                open: false,
                message: ""
            },
            selectedPurposesIDs:[],
            selectedPurposesList: [],
            notAllowedEdit: false,
            sortColumn: { path: "", order: "", sort_color: "#63778a" },
            isIndeterminateSelect: false,
        };
    }

    componentWillMount() {		
		if (this.props.location && this.props.location.state) {
            let values = this.props.location.state
            
			this.setState({
                smartGroupName: values.nameFromReport,	
                selectedPurposes: values.selectedoptionsFromReport,	
			})
		}
	}

    componentDidMount = () => {
        $('body').removeClass("modal-backdrop show");
        document.body.style.backgroundColor = "#F2F2F2";
        this.getPurposeDetails();
    }

    
    checkDeleteAccess = async (mediaValue) => {
		const userListEmail = JSON.parse(localStorage.getItem('childUsersEmail'));
		const { username } = JSON.parse(localStorage.getItem('loginInfo'));
		const roleName = localStorage.getItem('roleName') || '';

		let isDelete = false;
        if(mediaValue.userId && mediaValue.userId.toString().match('@')){
            isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(mediaValue.userId) || username === mediaValue.userId ? true : false;
        }else{
            isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
        }
        if (isDelete === false){
            return isDelete;
        }
		return isDelete;
	}


    getPurposeDetails = () => {
        this.setState({
            loaderIconVisible: true,
            allSelect: false,
            isDelete: false,
            isEdit: false,
        });
        let url = smartGroupAPI.purposeList + localStorage.getItem("customerId");
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                let arr = [];
				let isDelete = false;
                for (let i = 0; i < response.data.length; i++) {     
                    isDelete = await this.checkDeleteAccess(response.data[i]);
					arr.push({...response.data[i],isAuthorized:response.data[i].purposeType.toLowerCase() === 'standard' ? true : isDelete})
                };
                this.setState({
                    purposesList: arr,
                    loaderIconVisible: false,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    selectExistingProduct = (e, item) => {
        var rolesList = [...this.state.purposesList];
        rolesList.forEach(r => {
            if (r.checked) {
                this.setState({
                    oldProdID: r.product_id,
                    oldProdName: r.product_name,
                    purposesDesc: r.product_id,
                    purposesName: r.product_name,
                    add_mode: false,
                    update_mode: true,
                })
            }
        });
    }

    clickMultiDeleteButton = () => {
        this.setState({ isMultiDelete: true })
    }

    onAddClick = (e, item) => {
        this.setState({
            purposesName: "",
            purposesDesc:"",
            oldProdName: "",
            add_mode: true,
            update_mode: false,
        })        
        WoopraEvents(`${Constants.ADD_PURPOSE}`);
    }
    
    onEditClick = (e, item) => {
        var rolesList = [...this.state.purposesList];
        rolesList.forEach(i => {
            if (i.checked === true || i === true) {
                this.setState({
                    add_mode: false,
                    update_mode: true,
                    purposesName: i.name,
                    purposesDesc: i.description,
                    purposeID:i.id,
                })
            }

        });
        WoopraEvents(`${Constants.EDIT_PURPOSE}`);
    }

    onEditMultipleClick = (e, item) => {
        var rolesList = [...this.state.purposesList];
        let { purposeID } = this.state;
        let checkedItems = [];
  
        rolesList = rolesList.filter(c => c.purposeType === "Custom");
        rolesList.forEach(i => {
            if( i.checked === true && i.isAuthorized) {
                checkedItems.push( i);
            }
                     if ( i.checked === true || i === true ) {
                        this.setState({
                            add_mode: false,
                            update_mode: true,
                        })
                    }

        });
        
        if( checkedItems && checkedItems.length > 0) {
            // default selected 0 item 
            this.setState({
                purposesName : checkedItems[0].name,
                purposesDesc : checkedItems[0].description,
                purposeID    : checkedItems[0].id,
                selectedPurposesList: checkedItems,
            });
        }
        WoopraEvents(`${Constants.EDIT_PURPOSE}`);
    }

    findAddtOrUpdateProduct = () => {
        this.setState({ loaderIconVisible: true })
        if (this.state.add_mode) {
            this.addPurposeDetails();
            this.setState({ purposesDesc: "", purposesName: "" })
        } else if ( this.state.update_mode ) {
            this.updateProductDetails();
        }
    }

    addPurposeDetails = (e, item) => {
        const { formatMessage } = this.props.intl;

        let reqData = {
            "name": this.state.purposesName,
            "customerId": localStorage.getItem("customerId"),
            "userId": localStorage.getItem("user"),            
            "description": this.state.purposesDesc,            
        };
        let url = smartGroupAPI.purposeList;
        http.post(url, reqData,
            {
                headers: {
                    'Content-Type': 'application/json'
                }
            }
        )
            .then(response => {
                // let { data } = response;
                // let { message } = data;
                this.setState({
                    purposesDesc: "",
                    purposesName: "",
                    purposeID: null,
                    loaderIconVisible: false,
                    allSelect: false,
                    isDelete: false,
                    isEdit: false,

                    success_modal: {
                        open: true,
                        message: formatMessage({id:"KC1652"})
                    },
                    allSelect: false, 
                    isIndeterminateSelect: false
                });
                WoopraEvents(`${Constants.PURPOSE_ADDED}`, reqData);
            }).catch(err => {
                this.setState({
                    purposesDesc: "",
                    purposesName: "",
                    loaderIconVisible: false,
                    allSelect: false,
                    isDelete: false,
                    isEdit: false,
                    error_modal: {
                        open: true,                        
                        message: err.response && err.response.data && err.response.data.message? err.response.data.message: "KC1148",
                    },
                    allSelect: false, 
                    isIndeterminateSelect: false
                });
            });

    }

    updateProductDetails = (e, item) => {
        const { formatMessage } = this.props.intl;

        let reqData = {
            "name": this.state.purposesName,
            "description": this.state.purposesDesc,
            "customerId": localStorage.getItem("customerId"),
            "userId": localStorage.getItem("user"),
            "purposeId":this.state.purposeID,
        };
        let url = smartGroupAPI.deletepurposes;
        http.put(url, reqData,{
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            let { data } = response;
            let { httpCode, responseCode, message } = data;
            
            this.setState({
                curr_Prod_ID: "",
                curr_Prod_Name: "",
                purposeID: null,
                loaderIconVisible: false,
                purposesName: null,
                purposesDesc: null,
                success_modal: {
                    open: true,
                    message: formatMessage({id:"KC1653"})
                },
                allSelect: false, 
                isIndeterminateSelect: false
            });
            WoopraEvents(`${Constants.PURPOSE_UPDATED}`);
            
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response && err.response.data && err.response.data.message? err.response.data.message: "KC1148",
                },
                allSelect: false, 
                isIndeterminateSelect: false
            });
        });
    }

    deletePurposesDetails = () => {  
        const { formatMessage } = this.props.intl;
      
        this.setState({ loaderIconVisible: true });
        let purposeIds=[];
        let reqData;
        var rolesList = [...this.state.purposesList];
        rolesList.forEach(i => {
            if (i.checked === true && i.isAuthorized) { 
                purposeIds.push(i.id);
            }
        });

        reqData = {
            "purposeId": purposeIds,
        }

        let url = smartGroupAPI.deletepurposes;
        http.delete(url,{
            headers: {
                'Content-Type': 'application/json',                
            },
            data: reqData,
        }).then(response => {
            let { data } = response;
            let { httpCode } = data;  
            if (data && data.has2Delete){
                    this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,                    
                    selectedPurposesIDs:purposeIds,
                    access_modal: {
                        open: true,
                        message:  formatMessage({id:"KC1654"})
                    },
                    allSelect: false, 
                    isIndeterminateSelect: false
                });
            } else {
                this.setState({
                    curr_Prod_ID: "",
                    curr_Prod_Name: "",
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: this.state.isMultiDelete ? formatMessage({id:"KC1656"}) : formatMessage({id:"KC1655"})
                    },
                    allSelect: false, 
                    isIndeterminateSelect: false
                });
                WoopraEvents(`${Constants.PURPOSE_DELETED}`);
                this.getPurposesDetails();
            }
            
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response,
                },
            });
        });
    }

    deletePurposesForcely = () => {
        const { formatMessage } = this.props.intl;

        this.setState({ loaderIconVisible: true });
        let reqData;
        reqData = {
            "purposeId": this.state.selectedPurposesIDs,
            "has2Delete": true
        }

        let url = smartGroupAPI.deletepurposes;
        http.delete(url,{
            headers: {
                'Content-Type': 'application/json',                
            },
            data: reqData,
        }).then(response => {
            let { data } = response;
            let { httpCode } = data;           
            this.setState({
                curr_Prod_ID: "",
                curr_Prod_Name: "",
                loaderIconVisible: false,
                success_modal: {
                    open: true,
                    message: this.state.isMultiDelete ? formatMessage({id:"KC1656"}) : formatMessage({id:"KC1655"})
                },
                allSelect: false, 
                isIndeterminateSelect: false
            });
            this.getPurposesDetails();
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response,
                },
            });
        });
    }

    purposesDesc_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            purposesDesc: rName,
        })
    }

    purposesName_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            purposesName: rName,
        })
    }

    sgName_Input_Value = (e) => {
        let rName = e.target.value;
        this.setState({
            smartGroupName: rName,
        })
    }

    onSuccessCloseModal = (e) => {
        this.setState({
            success_modal: {
                open: false,
            }
 
        })
        this.getPurposeDetails();
    }

    closeModel = (err) => {
        this.setState({
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            }
        });
    }

    onSelectedList =( e, item) => {
        console.log(item, "item")
            this.setState({
                purposesName : item.name,
                purposesDesc : item.description,
                purposeID    : item.id,
                // defaultCheckedMultiPurpose: item.id
            });
    }

    checkEditDelet = (rolesList) =>{
        const { rolename = '', username = '' } = JSON.parse(localStorage.getItem('loginInfo'));

        let custValue = 0, notOurList = 0, standardValue =0;
        rolesList.forEach(r => {
            if ((r.purposeType.toUpperCase() === "CUSTOM") && r.checked && r.isAuthorized) {
                custValue = custValue + 1;
            }else if((r.purposeType.toUpperCase() === "CUSTOM") && r.checked && r.userId !== username){
                notOurList = notOurList + 1;
            }else if((r.purposeType.toUpperCase() === "CORPORATE"|| r.purposeType.toUpperCase() === "STANDARD") && r.isAuthorized && r.checked){
                standardValue = standardValue + 1;
            }
        });

        if(custValue > 0){
            this.setState({
                isEdit: true,
                isDelete: notOurList === 0 && standardValue === 0 ? true : false,
                notAllowedEdit: notOurList === 0 && standardValue  === 0 ? false : true
            })
        }else{
            this.setState({
                isEdit: false,
                isDelete: false
            })
        }
    }

    handleChange = async(e) => {
        var rolesList = [...this.state.purposesList];
        let allSelect = e.target.checked;

        rolesList.forEach(r => {
            r.checked = r.isAuthorized ? allSelect : false;            
        });
        
        this.setState({
            purposesList: rolesList,
            allSelect,
            isIndeterminateSelect: false,
            selectedPurposes:[],
        })
        await this.checkEditDelet(rolesList.filter((i)=>i.checked === true));   
    };

    onSelectRole = async(e, item) => {
        if (this.state.purposesList && this.state.purposesList.length > 0) {
            const rolesList = [...this.state.purposesList];
            const index = rolesList.indexOf(item);
            rolesList[index] = { ...rolesList[index] };
            rolesList[index].checked = rolesList[index].isAuthorized ? e.target.checked : false;

            let checked = rolesList.filter(c => c.checked === true);

            function getCheckBoxstate(ListCount,SelectedCount) {
                if (SelectedCount == ListCount) {
                return { allSelect: true, isIndeterminateSelect: false };
                } else if (SelectedCount == 0 && ListCount != 0) {
                return { allSelect: false, isIndeterminateSelect: false };
                } else if (ListCount >= SelectedCount) {
                return { allSelect: true, isIndeterminateSelect: true };
                }
            }

           await this.checkEditDelet(rolesList.filter((i)=>i.checked === true));   

           
           this.setState({
            purposesList: rolesList,
            selectedPurposes:[],
            ...getCheckBoxstate(rolesList.length,checked.length)
        })
 
        }
    }

    accessNowModel= (e) => {
        this.deletePurposesForcely();
		this.setState({
			access_modal:{
				open:false				
			  }
        })        
	}

	accessLaterModel= () =>  {        
		this.setState({
			access_modal:{
				open:false				
			  }
		})
	}

    checkSmartGroupName = async (e,selCheckboxDet) => {
        this.setState({ loaderIconVisible: true, })

        let postdata = {
            "name": this.state.smartGroupName,
            "customerId": + localStorage.getItem("customerId"),
            "userId": localStorage.getItem("user"),
        };
        await http.post(smartGroupAPI.purposeValidations, postdata,
            {
                headers: {
                    'Content-Type': 'application/json',
                }
            }
        ).then(response => {
            if (response && response.data) { 
                this.props.history.push({
                    pathname: "/smartGrpTopology",
                    state: {
                        nameFromReport: this.state.smartGroupName,                        
                        selectedoptionsFromReport: selCheckboxDet,
                    }
                });
                WoopraEvents(`${Constants.SMART_GROUP_NAME_VALIDATED}`);
            }
        }).catch(err => {
            e.preventDefault();
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err.response && err.response.data && err.response.data.message? err.response.data.message: "KC1110",
                },
            }); 
        });
    }

    onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	} 

    // Woopra Events
	trackEvent = (e) => {
		WoopraEvents(`${e}`)
	}

    componentDidUpdate(e) {
        //console.log("Find clicked Events",e)
      } 

    render() {
        let { purposeID, notAllowedEdit,selectedPurposesList,sortColumn,isIndeterminateSelect, purposesList,selectedPurposes,access_modal,smartGroupName, update_mode, 
            loaderIconVisible, error_modal, success_modal, data, allSelect, isEdit, isDelete , rolesList} = this.state;
        let isNextEnabled;
        let selCheckboxDet = []; 
        let userProfileAuthFeature = authService.getFeatureByFeatureName("Organization Management");
        const { formatMessage } = this.props.intl;

        let orderId = 1;

        const array1 = this.state.purposesList;

        if (this.state.selectedPurposes && this.state.selectedPurposes.length > 0) {
            const array2 = this.state.selectedPurposes;
            const tempviewBrandsList = _.forEach(array1, a1 => {
                const ifExist = array2.includes(a1.id);
                if (ifExist) {
                    a1.checked = true
                }
                //a1.checked = ifExist ? true : false;
            });
        }

        let columns = [
			{ path: "", label: "#" },
			{ path: "name", label: formatMessage({id:"KC0545"}) },
			{ path: "purposeType", label: formatMessage({id:"KC0102" })},
			{ path: "description", label: formatMessage({id:"KC0195"}) },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

        purposesList = _.orderBy(purposesList, [sortColumn.path], [sortColumn.order]);

		purposesList.forEach(item => {
			item.order_id = orderId++
		});
        
        purposesList && purposesList.forEach(r => {
            if (r.checked) {
                selCheckboxDet.push(r.id)
            }
        });  

        if (smartGroupName && selCheckboxDet.length>0){
            isNextEnabled = true;
        } else {
            isNextEnabled = false;
        }

        let allowMultipleEdit = false;

        if( isEdit === true ) {            
            let selCustomCheckbox = purposesList.filter(c => c.purposeType === "Custom");
            if( selCustomCheckbox.length > 1 ) {
                allowMultipleEdit = true; 
            } else {
                allowMultipleEdit = false;  
            }
        }

        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>

                <div id="page-content-wrapper">
                    <div class="userManagementWrapper">
                        <div class="addUserHeader">
                            <ul>
                                <li>
                                    <Link to = '/smartgroupslist' title={formatMessage({id:"KC0989"})} class="backIcon" >&nbsp;</Link>
                                </li>
                                <li>
                                    <FormattedMessage id='KC0570'/>
                                </li>
                                <li>
                                    <a href="javascript:void(0)" onClick={(e) => this.checkSmartGroupName(e,selCheckboxDet)} className={isNextEnabled ? "activeState" : "disabled"} id="AssignUpdate" title={formatMessage({id:"KC0543"})}><FormattedMessage id="KC0543" /></a>
                                </li>
                            </ul>
                        </div>

                        <div class="chooseUserFormWrapper">

                            <div class="chooseUserForm">
                                <ul>
                                    <li>
                                        <div class="smartGroup">
                                            <input type="text" class="form-control"  onChange={(e) => this.sgName_Input_Value(e)} value={this.state.smartGroupName} placeholder={formatMessage({id:'KC0571'})} />
                                        </div>
                                    </li>

                                </ul>
                            </div>

                            <div class="orgHeader">
                                <ul>
                                    <li>
                                        <a href="#" title={formatMessage({id:"KC1756"})} class="active"><FormattedMessage id='KC0544'/></a>
                                    </li>
                                    <li>
                                        {  allowMultipleEdit && <button className={userProfileAuthFeature.is_editable === 1 && isEdit ?"btn userEdit":"btn userEdit disabled"} onClick={(e) => this.onEditMultipleClick(e)} type="button" data-toggle="modal" data-target="#editPurposeListModal">&nbsp;</button>}
                                        { !allowMultipleEdit && <button className={userProfileAuthFeature.is_editable === 1 && isEdit ?"btn userEdit":"btn userEdit disabled"} onClick={(e) => this.onEditClick(e)} type="button" data-toggle="modal" data-target="#addCustomPurposeModal">&nbsp;</button>}
                                        <button type="button" className= {userProfileAuthFeature.is_editable === 1 && isDelete?"btn delete":"btn delete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal">&nbsp;</button>
                                        <button onClick={(e) => this.onAddClick(e)} type="button" data-toggle="modal" data-target="#addCustomPurposeModal" className="btn add">&nbsp;</button>                                        
                                    </li>
                                </ul>
                            </div>

                            <form class="orgPurposeTableForm">
                                <div class="orgPurposeTable">
                                    <TableHeader
                                        isIndeterminate={isIndeterminateSelect}
                                        selectAllChange={this.handleChange}
                                        sortColumn={sortColumn}
                                        onSort={this.onSort}
                                        columns={columns}
                                        isSelectAll={allSelect} />                   
                                    {purposesList && purposesList.map((item, i) =>
                                        <ul class="tBody">
                                            <li>{i+1}</li>
                                            <li>{item.name}</li>                                            
                                            <li>{item.purposeType}</li>
                                            <li>{item.description}</li>
                                            <li>
                                            {!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1748' })} direction="left" className="targetdisabled">
                                                <div className={"customCheckbox customCheckboxWOlabel disabled"}>
                                                    <input id={i} name={i} 
                                                        type="checkbox"
														checked={item.checked}
                                                        disabled
														onChange={(e) => this.onSelectRole(e, item)} />
													<label htmlFor={i}></label>
												</div>
                                                        </Tooltip> : <div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
                                                    <input id={i} name={i} 
                                                        type="checkbox"
														checked={item.checked}
														onChange={(e) => this.onSelectRole(e, item)} />
													<label htmlFor={i}></label>
												</div>}                        
                                            </li>
                                        </ul>
                                        )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {error_modal &&  error_modal.message && <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />}
                {success_modal && success_modal.message && <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />}
                <UserManagementHeader headerName="Organization Management" headerNameStringID="KC0519" activeClass="orgznManagementNav" />
                <ConfirmModal open={access_modal.open} stringID={access_modal.message} onAccessNowModel={(e) =>this.accessNowModel(e)} onAccessLaterModel={(e) =>this.accessLaterModel(e)}/>
                
                {/* <!-- Edit Custom Purpose Modal --> */}
                <div data-backdrop-limit="-1" class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="addCustomPurposeModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">  
                            <div class="modal-body" > 
                                <div class="confirmationYesNo">  
                                    <h5><FormattedMessage id='KC0559'/></h5>
                                    <ul className="customPurpose_Box">
                                        <li>
                                            <label><FormattedMessage id='KC0545'/></label>
                                            <input type="text" onChange={(e) => this.purposesName_Input_Value(e)} value={this.state.purposesName} class="form-control" placeholder={formatMessage({id:'KC0545'})}/>   
                                        </li>
                                        <li>
                                            <label><FormattedMessage id='KC0560'/></label>
						                    <textarea class="form-control" onChange={(e) => this.purposesDesc_Input_Value(e)} value={this.state.purposesDesc}   rows="2" cols="50" placeholder={formatMessage({id:'KC0560'})}></textarea>
                                        </li>
                                    </ul>
                                </div> 
                            </div>
                            
                            <div class="modal-footer textAlignCenter">
                            <button class="btn btn-secondary noBtn" data-dismiss="modal" type="button" onClick={(e) => this.trackEvent(`${Constants.PURPOSE_CANCELED}`)}><FormattedMessage id='KC0021'/></button>
                            <button  class={this.state.purposesName?"btn btn-secondary yesBtn":"btn btn-secondary yesBtn disabled"} onClick={(e) => this.findAddtOrUpdateProduct(e)} data-dismiss="modal" type="button">{update_mode?formatMessage({id:'KC0561'}):formatMessage({id:'KC0563'})}</button>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
                {/* <!-- //Add Custom Purpose --> */}
                <div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                <FormattedMessage id='KC1657'/>
					        </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" data-dismiss="modal" type="button" onClick={(e) => this.trackEvent( `${Constants.PURPOSE_CANCELED}`)}><FormattedMessage id='KC0021'/></button>
                                <button onClick={(e) => this.deletePurposesDetails(e)} className="btn btn-secondary deleteYesBtn" id="deleteYesBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0558'/> </button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Start Edit Purpose List Modal */}
                <div class="modal fade confirmationYesNoModal" 
                data-keyboard="true" data-backdrop="static" id="editPurposeListModal"
                data-backdrop-limit="-1" 
                tabindex="-1" role="dialog" aria-labelledby="editPurposeListModalLabel">
                    <div class="modal-dialog modal-md">
                     <div class="modal-content">  
                            <div class="modal-body">
                                <div class="confirmationYesNo">  
                                    <h5><FormattedMessage id='KC0557'/></h5>
                                    <div class="editPurposeList">
                                        {selectedPurposesList && selectedPurposesList.map((item, i) => <>
                                            <div class="customRadioBtn">
                                                <input id={item.name} 
                                                    name = { 'purpose' }
                                                    onChange={ (e)=> this.onSelectedList(e, item)}
                                                    type="radio"
                                                    defaultChecked = { purposeID  === item.id ? item.name: null }
                                                    value={item.name} />

                                                <label htmlFor={item.name}>{item.name}</label>
                                            </div>
                                        </>)}
                                    </div>                                    
                                </div>                                
                                { notAllowedEdit && <div className="noteText"> <FormattedMessage id='KC1658' /></div>}
                            </div>                            
                            <div class="modal-footer textAlignCenter">
                                <button class="btn btn-secondary noBtn" data-dismiss="modal" type="button" onClick={(e) => this.trackEvent(`${Constants.PURPOSE_CANCELED}`)}><FormattedMessage id='KC0021'/></button>
                                <button class="btn btn-secondary yesBtn" data-dismiss="modal"  id="editPurposeBtn" type="button" data-toggle="modal" data-target="#addCustomPurposeModal" onClick={(e) => this.trackEvent(`${Constants.PURPOSE_CONFIRM}`)}><FormattedMessage id='KC0558'/></button>
                                
                            </div>
                        </div> 
                        
                    
                    </div>
                </div>
                {/* End Edit Purpose List Modal */}

            </div>

            
        )
    }
};

function mapStateToProps(state) {
    return {
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({     
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(CreateNewSmartGrp));
