import React, { Component, Fragment } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { UnitInfo, smartGroupAPI, smartTagMangement } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import http from '../../../service/httpService'
import $ from 'jquery';
import _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { BaseService, ReportManagement } from '../../../service/api';
import { format } from 'date-fns';
import appconstants, { BASECOUNTRY_ID, BRAND_ID, unitSelectionAllowOnTransfer } from '../../../utils/appConstants';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getSoftwareAvailableUpdates } from '../../../actions/navigation/softwareManagement/softwareAvailableUpdatesActions'
import { getStoreInstalledItem } from '../../../actions/navigation/softwareManagement/storeInstalledItemAction'
import Tooltip from 'react-tooltip-lite';
import authService from '../../../service/authService';
import { ungzip } from 'pako';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';
import SmartTagDrop from '../../controls/smartTagDragDrop';
import OrgTagsDetail from '../smartTag/orgTagsDetail';
import moment from '../../../utils/momentHelper.js';
import TagOrgModal from '../smartTag/tagOrgModel';
import GenericModal from '../../modal/generic-model';

$(window).on('beforeunload', function () {
	if ($(".export-error-report").length > 0) {
		$("#exportReport").removeClass("export-error-report");
		$(".loadingProgress").css("display", "none");
	}
});

class SoftwareTransferSmartgroupsList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: true,
			data: [],
			initialUnit: [],
			userSelectAll: false,
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			nameFromReport: '',
			startDateFromReport: '',
			endDateFromReport: '',
			selectedoptionsFromReport: [],
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			errorResponse: "",
			storeInstalledItemInfo: {},
			locationInfo: {},
			showStPopUp:false,
			selUnitDetails:{},
			showpopup: [],
			showOrgPopup: false,
			type: "orgList",
			isSelectionHappen: false,
			generic_modal: { 
                open: false,
                message: "",
                messagetype: ""
            },
		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);

		this.myReports = this.myReports.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
		this.onGenericCloseModal = this.onGenericCloseModal.bind(this);

	}

	componentDidUpdate(prevState) { 
        this.updateDimensions(); 
    }
	updateDimensions() {
		const windowHeight = $(window).height();
        const assignUListSrlBar = $(".assignUListSrlBar");
        assignUListSrlBar.css('height', windowHeight - assignUListSrlBar.offset().top);
	}

	async componentDidMount() {
		window.addEventListener('resize', this.updateDimensions);
		document.body.style.backgroundColor = "#F2F2F2";
		try {
			await this.requestData();
			// this.getUnitDetails();	
		} catch (error) {
			//Error Goes Here
		}
		this.props.getSoftwareAvailableUpdates();
		this.props.getStoreInstalledItem();	
		
	} 

	
    getUnitDetails = async () => {
        this.setState({ loaderIconVisible: true });
        http({
            method: "GET",
            url: smartGroupAPI.createSmartGroupWithUnit,
            headers: {
                'Content-Type': 'application/json',
                'sgid': this.state.storeInstalledItemInfo.smart_groups_id,
                'cgid': localStorage.getItem("custGroupID"),
				'brandId': localStorage.getItem("unitBrandId"),
            }, data: {},
        }).then(response => {
            let { data } = response;
			let unitList = [], unitData =[];

			for (let j = 0; j < data.units.length; j++) {
				const item = data.units[j];
				if (item.isDeleted === "0" || item.isDeleted === 0) {
					unitList.push(item.uId);
				}
			}

			for (let i = 0; i < this.state.data.length; i++) {
				const { ID, ...rest } = this.state.data[i];
				if (unitList.includes(ID)) {
					unitData.push({ ...rest, ID, checked: true })
				} else {
					unitData.push(this.state.data[i])
				}
			}
			let unitArr = unitData.filter((i)=>i.checked === true)
			// this.checkUnitsCount(unitArr);
			this.setState({
				data: unitData,
				initialUnit: unitData,
				loaderIconVisible: false,
				...this.getCheckBoxstate(unitData.length,unitData.filter((i)=>i.checked === true).length )
			})
            
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
            })
        });

    }

	componentWillMount = () => {
		let storeInstalledItemInfo = this.props.storeInstalledItemInfo && this.props.storeInstalledItemInfo.SOFTWARE_ID ? this.props.storeInstalledItemInfo : JSON.parse(localStorage.getItem('selectedItemsInfo'));
		localStorage.setItem('selectedItemsInfo', JSON.stringify(storeInstalledItemInfo))

		let locationInfo = this.props.location && this.props.location.FILE_NAME ? this.props.location : JSON.parse(localStorage.getItem('locationInfo'));
		localStorage.setItem('locationInfo', JSON.stringify(locationInfo))

		this.setState({
			storeInstalledItemInfo: storeInstalledItemInfo,
			locationInfo: locationInfo
		});
	}
	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	/**
	 * Decompress Base64 encode string into JSON
	 * @param {*} base64 Base64 encoded string
	 * @returns JSON | null
	 */
	decompressData(base64) {
		try {
			const buffer = Buffer.from(base64, 'base64');
			const bufferArr = ungzip(buffer);
			const json = new TextDecoder('utf8').decode(bufferArr);
			return JSON.parse(json);
		} catch(err) {
			console.error(err);
			return null;
		}
	}

	requestData = async () => {
		
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.softwareFilters);
        const filterModel1 = ffModel && JSON.parse(ffModel); 
        if (filterModel1)
        {
            filterModel=filterModel1;
		}
		
		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
        let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
    	let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];
        let selectedUST = [];
		let selectedOST = [];

		selectedCountryList.dataSource.map(c => {
            selectedCgids.push(c.id)
        });
        
        if (selectedRegionList.dataSource.length > 0)
        {
            selectedCgids = [];
        }

        selectedRegionList.dataSource.map(c => {
            selectedCgids.push(c.cust_gp_id)
        });

        if (selectedLocationList.dataSource.length > 0)
        {
            selectedCgids = [];
        }

        selectedLocationList.dataSource.map(c => {
            selectedCgids.push(c.cust_gp_id)
		});
		
		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});
        
        selectedCustomerList.dataSource.map(c =>{
			selectedCids.push(c.ID)
        });
        
        selectedBrandList.dataSource.map(c =>{
			selectedBrands.push(c.ID)
        });
        
        selectedModelList.dataSource.map(c =>{
			selectedModels.push(c.ID)
        });

		selectedMachineTypeList.dataSource.map(c => {
			selectedMachineTypes.push(c.ID)
		});

        selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
            return selectedUST.push(c.id)
        });

        selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
            return selectedOST.push(c.id)
        });
		let cgids = "";
        if (selectedCgids.length > 0) {
            cgids = selectedCgids.join(',');
        }
        else {
            cgids = localStorage.getItem("custGroupID");
        }
		var d = document.getElementById("spannavNotifiStatus");
		
		if (selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0 || selectedUST.length > 0 || selectedOST.length > 0) {
			d.className = "navNotifiStatus";
		}
		else
		{
		  d.className = "";
		}
		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		let apiUrl = `${UnitInfo.unitList}?compress=true`;
		let softDetails = this.state.storeInstalledItemInfo;
		let headerValue = {
			'Content-Type': 'application/json',
			'cgid': cgids,
			'cid': localStorage.getItem("customerId"),
			'brandid': localStorage.getItem("unitBrandId"),
			'model': selectedModels.join(','),
			'sortby': sortedID,
			'sorttype': sortedDesc,
			// 'basecountry': BASECOUNTRY_ID,
			// 'modelids': softDetails.MODEL_IDS ? softDetails.MODEL_IDS : "",
			'modelids': localStorage.getItem('softwareModelId'),
			'unitrange': softDetails.unitRange ? JSON.stringify(softDetails.unitRange) : [],// localStorage.getItem("unitRange"),
			'provisionedunits': true,
			'showOnlyActiveUnits': true
		}

		await http.get(apiUrl,
			{
				headers: headerValue,
				data: {}
			}).then(response => {
				if (response.data.compressed) {
					response.data.units = this.decompressData(response.data.units) || [];
				}
				let { units, } = response.data;
				units = UnitBuilder(units);

				let unitsAlreadyCheck = [];
				if (this.props.location && this.props.location.userCheckedData) {
					for (let i = 0; i < this.props.location.userCheckedData.length; i++) {
						unitsAlreadyCheck.push(this.props.location.userCheckedData[i].ID)
					}
				}
				for (let j = 0; j < units.length; j++) {
					const item = units[j];
					if (unitsAlreadyCheck.includes(item.ID)) {
						units[j].checked = true;
					}
				}

				this.setState({
					data: units,
					...this.getCheckBoxstate(units.length,units.filter((i)=>i.checked === true).length),
					loaderIconVisible: false
				});

			}).catch(err => {

			});
	}

	onBackClickHandler = () => {
		this.props.history.push({
			pathname: `/softwareLibraryUpdate`,
			state: {
				nameFromReport: this.state.nameFromReport,
				startDateFromReport: this.state.startDateFromReport,// "2020-04-09",
				endDateFromReport: this.state.endDateFromReport,// "2020-04-09",
				selectedoptionsFromReport: this.state.selectedoptionsFromReport,
			},
		});
	}

	myReports = () => {
		this.setState({
			loaderIconVisible: false,
		});
		this.props.history.push(`/reportList`);
	}

	handleChange = () => {		
		let units = this.state.data;		
		for(let i=0; i < units.length; i++){
			units[i].checked = units[i].PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" ? !this.state.userSelectAll : false;
		}
		let unitsSelected = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);
		// this.checkUnitsCount(unitsSelected);
		this.setState(prevState => ({
			data: units,
			userSelectAll: !prevState.userSelectAll,
			isIndeterminateSelect: false,
			isSelectionHappen: false,
			...this.getCheckBoxstate(units.length,unitsSelected.length)
		}));
	};

	getCheckBoxstate(ListCount, SelectedCount) {
		if (SelectedCount == ListCount) {
			return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (SelectedCount == 0 && ListCount != 0) {
			return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (ListCount >= SelectedCount) {
			return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange = (e, ID) => {
		let units = [];
		for(let i=0; i< this.state.data.length; i++){
			units.push({...this.state.data[i],checked:this.state.data[i].ID === ID ? e.target.checked : this.state.data[i].checked || false});
		}
		let unitArr = units.filter((i)=>i.checked === true);
		// this.checkUnitsCount(unitArr);
		this.setState({
			data: units,
			isSelectionHappen: false,
			...this.getCheckBoxstate(units.length, units.filter((i)=>i.checked === true).length)
		});
	};

	showError = (err) => {
		try {
			let { response } = err;
			if (response && response.status) {

				let { data } = response;
				let { body } = data;
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: body.message ? body.message : ""
					}
				});
			} else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: err.message
					}

				});
			}
		} catch (e) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.message
				}

			});
		}
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/smartgroupslist`);
	}
	
	applyChanges = (postData) => {
		return new Promise((res, rej) => {
			let custGroupID = localStorage.getItem("custGroupID");
			let cgids = "";
			if (custGroupID !== "" && custGroupID !== undefined) {
				cgids = custGroupID;
			}
			http({
				method: "POST",
				url: smartTagMangement.transferTagDetails,
				headers: {
					'Content-Type': 'application/json',
					'customerid': localStorage.getItem("customerId"),
					'brandid': localStorage.getItem("unitBrandId"),
					'cgid': cgids
				},
				data: postData
			}).then(async response => {
				if(response && response.data){
					res(response.data.data || [] );
				}
			}).catch(err => {
				res(err);
			});

		})
	}

	applyFilterChanges = (values) => {
		if (values && values.length > 0) {
			this.setState({ loaderIconVisible: true, initialUnit: this.state.data })
			this.applyChanges(values).then((res)=>{
				const numberType = res && res.length ? res.map(Number) : [];
				let units = [];
				for (let i = 0; i < this.state.data.length; i++) {
					const ele = this.state.data[i];
					const isInactive = ele.PROVISIONING_STATUS !== "CERTIFICATE_RECEIVED";
					units.push({ ...this.state.data[i], checked: numberType.includes(ele.ID)? isInactive ? false :  true :  false });
				}
				let unitArr = units.filter((i)=>i.checked === true)
				// this.checkUnitsCount(unitArr);
				this.setState({
					data: units,
					loaderIconVisible: false,
					isSelectionHappen: true,
					...this.getCheckBoxstate(units.length, units.filter((i) => i.checked === true).length)
				},()=>{
					$('#transferDetailsModal').modal('hide');
				});
			})			
		}else{
			this.setState({ 
				data: this.state.initialUnit, 
				isSelectionHappen: false,
				...this.getCheckBoxstate(this.state.initialUnit.length, this.state.initialUnit.filter((i) => i.checked === true).length) 
			})
		}
	}

	showSTagInfo = (unitDet,type="org") =>{
		let unitObj = unitDet;
		unitObj.id = unitDet.ID;
        this.setState({
            showStPopUp:true,
			selUnitDetails:unitObj
        },()=>{
            $('#unitSmartTagsDetails').modal('show')
        })

    }

	getInfoView = (value, type) => {
		let smartTagObj = {
			mode: 'isView',
			type,
			selectedRow: value
		}
		localStorage.setItem('smartTagInfo', JSON.stringify(smartTagObj));
		let showpopup = [];
		showpopup.push(value);
		this.setState({ showpopup, showOrgPopup: true, type }, () => {
			$('#transferDetailsModal').modal('hide');
			$('#stUnitDetailsModal').modal('show');
		});
	}

	downloadUnitList = (tagName) => {
        this.setState({ loaderIconVisible: true });
        let url = smartTagMangement.getPresignedUrl;
        let registrationFlag = 'no';
        let unitRegistrationAuthFeature = authService.getFeature("/addUnit");
		if (unitRegistrationAuthFeature && unitRegistrationAuthFeature.is_editable === 1) {
            registrationFlag='yes';
        }
        url = url + `?registrationFlag=${registrationFlag}&tagName=${tagName}&isView=yes`;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'customerid': localStorage.getItem("customerId"),
                'cgid': localStorage.getItem("custGroupID"),
                'brandid': BRAND_ID,
            }, data: {}
        }).then(async response => {
            if (response && response.data && response.data.data) {
                this.setState({ loaderIconVisible: false });
                if (response.data.data.preSignedUrl && response.data.data.preSignedUrl !== '#') {
                    window.location = response.data.data.preSignedUrl;
                }
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

	onGenericCloseModal = () => {
		this.setState({
			generic_modal: {
				open: false,
				message: "",
				messagetype: ""
			}
		});
	}

	checkUnitsCount = (units) => {
		const totalUnits = parseInt(unitSelectionAllowOnTransfer);
		if (units && units.length > totalUnits) {
			this.setState({
				generic_modal: {
					open: true,
					message: "Please select maximum of 300 units",
					messagetype: "Maximum number of units",
					count: units.length,
					total: totalUnits
				}
			})
		}
	};

	render() {
		const { formatMessage } = this.props.intl;
		const { loaderIconVisible, data, sortColumn, error_modal, success_modal, showpopup, type, isSelectionHappen, generic_modal } = this.state;
		let userProfileAuthFeature = authService.getFeature("/softwareLibraryList");
		let columns = [ 
			{ path: "", label: "#" },
			{ path: "", labelStringID: "KC0101", isNbSp: true },
			{ path: "BRAND_NAME", labelStringID: "KC0062" },
			{ path: "PRODUCT_TYPE", labelStringID: "KC0102" },
			{ path: "MODEL_NAME", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "UNIT_NUMBER", labelStringID: "KC0674" },
			{ path: "location_name", labelStringID: "KC0675" },
			{ path: "Within_Location", labelStringID: "KC0061" },
			{ path: "", labelStringID: "KC2317" },
			{ path: "CONNECT_STATE", labelStringID: "KC0523", isNbSp: true },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		let unitList = data;

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);
		const filterCheckedData = unitList.filter((i) => i.checked === true);
		const filterOfflineSelectedData = unitList.filter((i) => i.checked === true && i.CONNECT_STATE === "Offline");
		let Updatedunits = (parseInt(filterCheckedData.length) - parseInt(filterOfflineSelectedData.length));

		let showHeader;
		showHeader = (
			<ul>
				<li>{`${unitList.length || 0} listed units`}{(filterCheckedData && filterCheckedData.length) || isSelectionHappen ? ` / ${filterCheckedData.length} units selected` : null}</li>
				<li>
					<button type="button" id="transferDetails" onClick={() => $('#transferDetailsModal').modal('show')} class="btn btn-default useTagsforunit" data-toggle="popover" data-content={formatMessage({ id: 'KC2356' })}><FormattedMessage id="KC2360" /></button>
					<Link to={{ pathname: '/softwareAssigngroupTopology', unitsChecked: filterCheckedData }} class="btn assignunitSite">&nbsp;</Link>
					<Link to="#" id="btnAssign" className="btn assignunitList active">&nbsp;</Link>
				</li>
			</ul>
		);

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div id="exportReport" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper">
						<div class="unitRegistrationWrapper">
							<div class="addUnitHeader">
								<ul>
									<li>
										<a href="javascript:void(0)" onClick={() => this.onBackClickHandler()} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</a>
									</li>
									<li className="txtboldmsg">
										<FormattedMessage id="KC0711"/>
				            		</li>
									<li>
										<Link to={{
											pathname: "/softwareTransferUnits",
											state: {
												userCheckedData: filterCheckedData,
												storeInstalledItemInfo: this.state.storeInstalledItemInfo,
												locationInfo: this.state.locationInfo,
											}}} id="btnfinish" title={formatMessage({ id: 'KC0543' })} className={`btn-default-text nextText ${filterCheckedData.length > 0 ? 'activeState' : 'disabled'}`}><FormattedMessage id="KC0543"/>
										</Link>
									</li>
								</ul>
							</div>
							<div class="selectedUnitHeader">
								{showHeader}
							</div>
							<form class="SGlistUnits">
								<div class="SWlistUnitsTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isIndeterminate={this.state.isIndeterminateSelect}
										isSelectAll={this.state.userSelectAll} />
								</div>
								<div className="assignUListSrlBar">
									{unitList.map((item, i) => {
										const title = item.smartGroups && item.smartGroups.length > 0 ? item.smartGroups.map((i)=>i.smartGroupName) : [];
										return (
											<React.Fragment key={i}>
												<div class="SWlistUnitsTable">
													<ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody"} key={i}>
														<li>{i + 1}</li>
														<li><img src={item.Brand_Logo} /></li>
														<li>{item.BRAND_NAME}</li>
														<li>{item.PRODUCT_TYPE}</li>
														<li>{item.MODEL_NAME}</li>
														<li>{item.UNITID}</li>
														<li>{item.UNIT_NUMBER}</li>
														<li>{item.location_name}</li>
														<li>{item.within_location}</li>
														<li>
															{item.orgTags && item.orgTags.length ? <button className="btn orgTagedUnits" type="button" onClick={() => this.showSTagInfo(item,'unit')}>&nbsp;</button> : ""}
															{item.unitTags && item.unitTags.length ? <button className="btn tagedUnits" type="button" onClick={() => this.showSTagInfo(item,'unit')}>&nbsp;</button> : ""}
														</li>

														<li><span className={`${item.CONNECT_STATE === 'Offline' ? "offlineStausTable" : ""}`}></span></li>
														{
															<li>
																{item.PROVISIONING_STATUS !== "CERTIFICATE_RECEIVED" ? <Tooltip content={formatMessage({ id: 'KC1735' })} direction="left" className="targetdisabled">
																	<div className="customCheckbox customCheckboxWOlabel disabled">
																		<input id={`checkbox_${i}`} type="checkbox" value={item.checked} defaultChecked={item.checked}
																			checked={item.checked}
																			onChange={(e) => this.handleSingleCheckboxChange(e, item.ID)} />
																		<label for={`checkbox_${i}`} ></label>
																	</div> </Tooltip>
																	: <div className= {userProfileAuthFeature.is_editable ===1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
																		<input id={`checkbox_${i}`} type="checkbox" value={item.checked} defaultChecked={item.checked}
																			checked={item.checked}
																			onChange={(e) => this.handleSingleCheckboxChange(e, item.ID)} />
																		<label for={`checkbox_${i}`} ></label>
																	</div>}
															</li>
														}
													</ul>
												</div> 
											</React.Fragment>
										)
									}
									)} 
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={unitList !== undefined ? unitList.length : 0} classname={'norecordsfoundbluetext'} />
								</div>
							</form>
						</div>
					</div>
					{/* <!-- Transfer Modal --> */}
					<div class="modal fade transferDetailsModal" data-keyboard="true" data-backdrop="static" id="transferDetailsModal" tabindex="-1" role="dialog">
						<SmartTagDrop applyFilterChanges={this.applyFilterChanges} getInfoView={this.getInfoView} />
					</div>
					{/* <!-- Transfer Modal --> */ }
					<div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="stUnitDetailsModal" tabindex="-1" role="dialog" aria-labelledby="stUnitDetailsModalLabel">
						<div className="modal-dialog modal-md">
							<div className="modal-content">
								<div className="modal-header modal-header-danger">
									<FormattedMessage id="KC2312" />
								</div>
								<div className="modal-body">
									{showpopup && showpopup.map((item, i) => <div className="reportDetailsD">
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li>
													<div className={type === "orgList" ? "tagTypeLocation" : "tagTypeUnit"}><span>{item.tagName}</span></div>
												</li>
											</ul>
										</div>
										<h6><FormattedMessage id="KC0195" /></h6>
										<p>{item.description ? item.description : 'NA'}</p>
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li>
													<p><FormattedMessage id="KC2313" /> <button type="button" onClick={() => $('#stUnitDetailsModal').modal('hide')} data-toggle="modal" data-target="#orgViewModal" className="orgViewIcon btn"><img src={require('../../../images/icons/iconUnitSelection.svg').default} /></button> {type === 'orgList' ? formatMessage({ id: "KC2306" }) : formatMessage({ id: "KC2307" })}</p>
												</li>
												{type === 'orgList' ? "" :
													<li>
														<Link to='#' onClick={() => this.downloadUnitList(item.tagName)} className="downloadListof"><FormattedMessage id="KC2314" /></Link>
													</li>}
											</ul>
										</div>
										<div className="createdEditedDetailsO">
											<ul className="tHead">
												<li><FormattedMessage id="KC0589" /></li>
												<li><FormattedMessage id="KC1278" /></li>
											</ul>
											<ul className="tBody">
												<li>
													<div className="createdEditedDetails">
														<ul>
															<li><FormattedMessage id="KC0241" />:</li>
															<li>{item.createdByName ? item.createdByName : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0136" />:</li>
															<li>{item.createdBy ? item.createdBy : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0298" />:</li>
															<li>{item.createdAt ? moment.GetDateTimeByFormat(item.createdAt, 'LLL') : ''}</li>
														</ul>
													</div>
												</li>
												<li>
													<div className="createdEditedDetails">
														<ul>
															<li><FormattedMessage id="KC0241" />:</li>
															<li>{item.updatedByName ? item.updatedByName : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0136" />:</li>
															<li>{item.updatedBy ? item.updatedBy : ''}</li>
														</ul>
														<ul>
															<li><FormattedMessage id="KC0298" />:</li>
															<li>{item.UpdatedAt ? moment.GetDateTimeByFormat(item.UpdatedAt, 'LLL') : ''}</li>
														</ul>
													</div>
												</li>
											</ul>
										</div>
									</div>)}
								</div>
								<div className="modal-footer textAlignCenter">
									<button className="btn btn-secondary okBtn" data-dismiss="modal" onClick={() => this.setState({ showpopup: [], showOrgPopup: false }, () => $('#transferDetailsModal').modal('show'))} type="button"><FormattedMessage id="KC0777" /></button>
								</div>
							</div>
						</div>
					</div >
                	{this.state.showOrgPopup ? <TagOrgModal details={this.state.showpopup && this.state.showpopup.length ? this.state.showpopup[0] : {}} /> : null}
					{this.state.showStPopUp ? <OrgTagsDetail closeSTModel = {()=>this.setState({showStPopUp:false})} unitDet = {this.state.selUnitDetails} isUnit = {true}/>: ""}
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
					<GenericModal open={generic_modal.open} stringID={generic_modal.message} messagetype={generic_modal.messagetype} values={{ count: generic_modal.count ? generic_modal.count : 0, total: generic_modal.total ? generic_modal.total : 0 }} onGenericCloseModal={(e) => this.onGenericCloseModal()} />
					<UserManagementHeader headerNameStringID="KC2358" headerName="Transfer Management" activeClass="transferNav" filterKey={appconstants.softwareFilters} history={this.props.history}/>
				</div>
				<div class="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationOkModal" tabindex="-1" role="dialog" aria-labelledby=" confirmationOkModalLabel">
					<div class="modal-dialog modal-md">
						<div class="modal-content">
							<div class="modal-body">
								<div class="confirmationContentDiv">
									<h5><FormattedMessage id="KC0832"/></h5>
									<p><FormattedMessage 
											id="KC1571"  
											values={{ Updatedunits, unitsSelected: filterCheckedData.length, unitsOffline: filterOfflineSelectedData.length }}/></p>
								</div>
							</div>
							<div class="modal-footer textAlignCenter">
								<button class="btn btn-secondary confirmationOKBtn" data-dismiss="modal" onClick={() => this.myReports()} type="button"><FormattedMessage id="KC0777"/></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel,
		storeInstalledItemInfo: state.storeInstalledItemInformation.storeInstalledItemInformation,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getSoftwareAvailableUpdates, getStoreInstalledItem
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SoftwareTransferSmartgroupsList));