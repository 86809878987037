import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import * as _ from 'lodash';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { getCycleRatesOnTimePercentage } from '../../../actions/reportManagement/utilization/cycleRatesOnTimePercentageActions';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { UNIT_BRAND_ID, ON_TIME_PERCENTAGE_WIDGET_ID, FRYMASTER_UHCTHD_ON_TIME_PERCENTAGE_WIDGET_ID } from '../../../utils/appConstants';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';

// common global parameters
var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'MM-DD-YYYY');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'MM-DD-YYYY');
let unitID = "";
let brandID = "";
let currentUnit;

class TotalOnTimePercentageWidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            locationInformation: {},
            deviceInformation: {},
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            startDateOnTime: "",
            endDateOnTime: "",
            datapickflag: false
        }
        this.handleChangeStartOnTime = this.handleChangeStartOnTime.bind(this);
        this.handleChangeEndOnTime = this.handleChangeEndOnTime.bind(this);
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        let widgetId = brandID === 11 ? FRYMASTER_UHCTHD_ON_TIME_PERCENTAGE_WIDGET_ID : ON_TIME_PERCENTAGE_WIDGET_ID;
        this.props.getCycleRatesOnTimePercentage("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, widgetId);
    }

    componentDidUpdate = async (prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter  && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleOnTimePercentange(this.props.globalDropdownFilter, '');
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range'  && this.props.isGlobalDropdownChange) {
                if (prevState.endDateOnTime !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEndOnTime(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDateOnTime !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStartOnTime(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //start Date Change Handler
    handleChangeStartOnTime(onTimeDate) {
        if(this.state.startDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            startDateOnTime: onTimeDate,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleOnTimePercentange(selectedtext, 'OnTimePercentage');
        });
    }

    //end Date Change Handler
    handleChangeEndOnTime(onTimeDate) {
        if(this.state.endDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            endDateOnTime: onTimeDate,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleOnTimePercentange(selectedtext, 'OnTimePercentage');
        });
    }

    //dropdown Handler
    onDropDownHandler(e, type = '') {
        const { nodeText:text } = e;
        this.setState({ type: text, datapickflag: text === "Date Range", startDateOnTime:'', endDateOnTime:''})
        this.handleOnTimePercentange(text, type);

    }

    handleOnTimePercentange(text, type) {
        currentUnit = this.props.currentUnit;
        const { startDateOnTime, endDateOnTime } = this.state;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        if (text === "Date Range") {
            if (type === "OnTimePercentage") {
                todaysDateFormatted = format(endDateOnTime, 'YYYY-MM-DD');
                weekDateFormatted = format(startDateOnTime, 'YYYY-MM-DD');
            }
        }
        else {
            todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
            weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
        }

        if (type === "OnTimePercentage") {
            this.setState({ type: text });
            if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") &&
                (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
                let widgetId = brandID === 11 ? FRYMASTER_UHCTHD_ON_TIME_PERCENTAGE_WIDGET_ID : ON_TIME_PERCENTAGE_WIDGET_ID;
                this.props.getCycleRatesOnTimePercentage(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, widgetId);
            }
        }
    }

    render() {

        const { cycleRatesOnTimePercentageInfo } = this.props;
        const onTimePercentageInformation = cycleRatesOnTimePercentageInfo.cycleRatesOnTimePercentageResponse ? cycleRatesOnTimePercentageInfo.cycleRatesOnTimePercentageResponse : {};
        let onTimePercentageModel = onTimePercentageInformation.content;
        const isTrend = _.isUndefined(onTimePercentageModel) ? false : _.isObject(_.find(onTimePercentageModel, el => el.key === 'trend'));
        let cycleRatesOnTimePercentageWidget, onTimePercentageValue = null;
        if (Array.isArray(onTimePercentageModel) && onTimePercentageModel.length) {
            onTimePercentageValue = (<div className="valH1Div">{onTimePercentageModel[0].value} {(isTrend && this.state.type !== "Date Range") && (<span className={onTimePercentageModel[2].value === '1' ? "valH1Span valHigh" : onTimePercentageModel[2].value === '0' ? "valH1Span valLow" : "valH1Span"}> &nbsp;</span>)}</div>);
            cycleRatesOnTimePercentageWidget = (
                <div class="colmDiVal">
                    {onTimePercentageValue}
                    {(isTrend && this.state.type !== "Date Range") && (<div class="valH5Div">
                        {mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]} /> :`prior ${this.state.type.toLowerCase()}` }:  {onTimePercentageModel[1].value}
                    </div>)}
                </div>
            )
        }
        // Merco Cycle Rates Total On Time Widget
        let onTimePercentageWidget = null;
        onTimePercentageWidget = (<div class="colmDi">
            <h4><FormattedMessage id="KC0374"/>
                    <span className="cornerIcon"></span>
                <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDateOnTime} maxDateDisable={this.state.sdmaxdate} minDateDisable={this.state.sdmindate}
                    endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDateOnTime}
                    handleChangeStartProps={(date) => this.handleChangeStartOnTime(date)} handleChangeEndProps={(date) => this.handleChangeEndOnTime(date)}
                    OnChange={(e) => this.onDropDownHandler(e, "OnTimePercentage")} filterType={this.state.type} />
            </h4>
            {cycleRatesOnTimePercentageWidget}

        </div>)

        return (
            <React.Fragment>
                <div className="colm6D colm6D2">
                    {onTimePercentageWidget}
                </div>
            </React.Fragment>
        )
    }
};
function mapStateToProps(state) {
    return {
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
        cycleRatesOnTimePercentageInfo: state.cycleRatesOnTimePercentageResponse,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCurrentUnit, getCurrentLocation, getCycleRatesOnTimePercentage }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(TotalOnTimePercentageWidget);
