import React, { PureComponent } from "react";
import http from "../../../service/httpService";
import { LocationInfo, UnitInfo } from "../../../service/api";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CUSTOMER_ID, BRAND_ID } from "../../../utils/appConstants";
import { FormattedMessage } from "react-intl";
import { reactIntl } from "../../../locale/locale-provider";

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

const mappingConnectivityStatusStringID = {
  Errors: "KC0070",
  "Non-Critical Errors": "KC0071",
  "No Errors": "KC0072",
};
class ErrorChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      errorStatus: [],
    };
  }

  componentDidMount() {
    // Set data
    let errorStatusChartURL = `${UnitInfo.connectivityerrorStatusURL}`;

    let cgids = "";

    if (this.props.orgid && this.props.orgid.length > 0) {
      cgids = this.props.orgid;
    }
    if (this.props.cgid && this.props.cgid.length > 0) {
      cgids = this.props.cgid;
    }

    let header = {
      "Content-Type": "application/json",
      filterparam: "error",
      cgid: cgids,
      cid: CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
      brandid: this.props.brandid.length > 0 ? this.props.brandid : BRAND_ID,
      model: this.props.model,
      machinetype: this.props.machineType,
    };
    if (this.props.tags && this.props.tags.length) {
      header["tags"] = this.props.tags.join(",");
    }
    if (this.props.Type == "Location") {
      errorStatusChartURL = `${LocationInfo.connectivityerrorStatusURL}`;
    } else {
      if (cgids === "") {
        header = {
          "Content-Type": "application/json",
          filterparam: "error",
          assignState: "assigned",
        };
      }
    }
    http
      .get(errorStatusChartURL, {
        headers: header,
        data: {},
      })
      .then((res) => {
        this.setState(
          {
            isLoaded: true,
            errorStatus: res.data,
          },
          () => {
            this.renderChart(res.data);
          }
        );
      });
  }

  renderChart = (data) => {
    if (data && data.length > 0) {
      // Create chart instance
      let chart = am4core.create("errorsStatuschartdiv", am4charts.PieChart);
      chart.data = data;
      this.chart = chart;
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "count";
      pieSeries.dataFields.category = "error_state";
      pieSeries.tooltip.label.adapter.add(
        "textOutput",
        function (text, target) {
          if (
            target.dataItem.dataContext &&
            target.dataItem.dataContext.error_state
          ) {
            const connectErrorState = reactIntl.formatMessage({
              id: mappingConnectivityStatusStringID[
                target.dataItem.dataContext.error_state
              ],
            });
            const countValue = text.split(":")[1];
            return `${connectErrorState}:${countValue}`;
          } else {
            return ``;
          }
        }
      );
      pieSeries.slices.template.propertyFields = "pulled";
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.slices.template.strokeWidth = 0;
      pieSeries.colors.list = [
        am4core.color("#f93251"),
        am4core.color("#ffbe00"),
        am4core.color("#545454"),
      ];
    } else {
      if (this.chart) {
        this.chart.data = data;
      }
    }
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // multilingual
  renderDynamicErrorList = (errorStatus) => {
    let stringID =
      errorStatus === "Errors"
        ? "KC0070"
        : errorStatus === "Non-Critical Errors"
        ? "KC0071"
        : errorStatus === "No Errors"
        ? "KC0072"
        : "";
    if (stringID) {
      return <FormattedMessage id={stringID} />;
    }
    return "";
  };

  render() {
    const { errorStatus, isLoaded } = this.state;
    console.log(`errorStatusChart ${isLoaded}`);
    const errorStatusList =
      errorStatus && errorStatus.length > 0 ? errorStatus : [];
    let objCount = [];
    objCount = [...errorStatusList].find(function (element) {
      return (
        element.count > 0 &&
        element.error_state !== "No Error(s)" &&
        element.error_state !== "No Errors"
      );
    });
    let totalCount;
    if (objCount && objCount.length > 0) {
      totalCount = objCount.reduce((prev, cur) => prev + cur.count, 0);
    }
    let displayChartClass = "colmDiVal";
    let displayClass = "colmDiVal displaynone";
    if (objCount === [] || objCount === undefined || totalCount === 0) {
      displayClass = "colmDiVal";
      displayChartClass = "colmDiVal displaynone";
    }
    return (
      <div>
        {!isLoaded && (
          <div className="colmDiVal">
            <div className="spinner" role="status"></div>
          </div>
        )}
        <div className={displayChartClass}>
          <div id="errorsStatuschartdiv"></div>
          <div class="errorsStatus">
            <div id="errorsStatuslegenddiv" class="errorsStatusTable">
              {Object.keys([...errorStatusList]).map((item, i) => (
                <div>
                  <div class="value">{errorStatusList[i].count}</div>
                  <div class="title">
                    {this.renderDynamicErrorList(
                      errorStatusList[i].error_state
                        ? errorStatusList[i].error_state.replace(
                            "Errors",
                            "Errors"
                          )
                        : ""
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {errorStatusList && errorStatusList.length > 0 && (
          <div className={displayClass}>
            <div class="valH1WStatusDiv dashboardWidget noErrorStatus">
              <FormattedMessage id="KC0072" />
              <span class="valH1WStatusSpan">&nbsp;</span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ErrorChart;
