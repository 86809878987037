import React, { PureComponent } from 'react';
import { getTotalErrorsCount, getAPIUrl } from '../../service/servicemanagement/serviceManagement';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

class UnitErrors extends PureComponent {
  constructor(props){
      super(props);
      this.state ={
        errorInfo:{},
        errorWidgetCritical :"",
        errorWidgetNonCritical:"",
        currentDeviceError:[],
      }
      this.getErrorCount = this.getErrorCount.bind(this);
  }

  componentDidMount() {
      this.getErrorCount();
      this.interval = setInterval(() => this.getErrorCount(), 30000);
  }

  componentWillUnmount() {
      clearInterval(this.interval);
  }

  componentDidUpdate(prevState) {
    const prevLocale = prevState.locale;
		const { locale } = this.props;
    if (prevLocale !== locale) {
      this.getErrorCount();
    }
  }

  getErrorCount = async() => {
    const { locale } = this.props;
    let currentUnit = this.props.currentUnit;
    currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
    let url = getAPIUrl(null, null, null, currentUnit.UNITID , "ErrorCount");
    url = locale ? url + "&langCode=" + locale : url;
    let errorInromation = await getTotalErrorsCount(url);
    if(errorInromation){
        this.setState({
            errorWidgetCritical: errorInromation.criticalCount,
            errorWidgetNonCritical:errorInromation.nonCriticalCount,
            errormsg:errorInromation.errormsg,
            currentDeviceError:errorInromation.currentDeviceError,
        });
    }
  }

  render() {

    let {errorWidgetCritical, errorWidgetNonCritical, errormsg,currentDeviceError } = this.state;

    if (errorWidgetCritical || errorWidgetNonCritical) {
        errorWidgetCritical = <div class="statusH4 leftAlign poorIcon"><span>{errorWidgetCritical}</span> <FormattedMessage id="KC0156"/></div>;
        errorWidgetNonCritical = <div class="statusH4 leftAlign goodIcon"><span>{errorWidgetNonCritical}</span> <FormattedMessage id="KC0157"/></div>;
    }
    else {
      if(errorWidgetCritical!== null && errorWidgetCritical === 0)
        errorWidgetCritical = <div class="valH1WStatusDiv noErrorStatus">
            <FormattedMessage id="KC0072"/>
        <span class="valH1WStatusSpan">
                &nbsp;
        </span>
        </div>;
        errorWidgetNonCritical = <div></div>;
    }
    return (
        <div class="colm3D colm3D1">
        <div class="colmDi">
            <h4><FormattedMessage id="KC0158"/> &nbsp; <span class="red-txt">{errormsg}</span></h4>
            <div class="colmDiVal minheight150">
            {errorWidgetCritical} 
            {errorWidgetNonCritical}            
            <div class="statusH6 poorIcon"><p style={{color: currentDeviceError && currentDeviceError.is_critical=== 1 ? '#F93251':"#FFBE00"}}  title={ currentDeviceError && currentDeviceError.description ? currentDeviceError.description : ""}><span>{currentDeviceError && currentDeviceError.description? currentDeviceError.error_code + " :" : ""}</span>  {currentDeviceError ? currentDeviceError.description : ""} </p></div>
            </div>
        </div>
    </div>
    )
  }
}

function mapStateToProps(state) {
	return {
		searchData: state.advanceSearch.searchData,
		filterlistInformtion: state.filterModel,
		locale: state.localeInfo.locale,
	}
}

export default connect(mapStateToProps)(injectIntl(UnitErrors));