import React, { Component } from 'react'
import PropTypes from 'prop-types'
import http from '../../../service/httpService';
import { MenuUploadAPI } from '../../../service/api';
import { CUSTOMER_ID, BASECOUNTRY_ID } from '../../../utils/appConstants';
import moment from '../../../utils/momentHelper.js';
import { TableHeader } from '../../controls/tableHeader';
import CreatedUserPopup  from "./createdUserPopup"
import _ from 'lodash';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
var changeCase = require("change-case");

class LibraryListPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderIconVisible: false,
      data: [],
      isSelectAllLibrary: false,
      error_modal: {
        open: false,
        message: ""
      },
      success_modal: {
        open: false,
        message: ""
      },
      selected_item: {},
      created_by: '',
      last_edited_by: '',
      sortColumn: { path: "", order: "", sort_color: "#63778a" },
      isImportEnabled: false,
      search: ''
    }
    this.getLibraryList = this.getLibraryList.bind(this);
    this.handleModelChange = this.handleModelChange.bind(this);
    this.OnLibraryItemClick = this.OnLibraryItemClick.bind(this);
    this.OnImport = this.OnImport.bind(this);
    this.librarySearchResult = this.librarySearchResult.bind(this);
  }

  async componentWillReceiveProps(props) {
    if (props.modelID !== this.props.modelID) {
      try {
        await this.getLibraryList(props.modelID).catch(err => {
          this.setState({
            loaderIconVisible: false
          });
        });
        $('.tBody').click(function () {
          $('.tBody').removeClass('active');
          $(this).addClass('active');
        });
      } catch (error) {
        //Error Goes Here
      }
    }
  }

  componentDidMount() {
    document.body.style.backgroundColor = "#000000";
  }

  getLibraryList = (modelID) => {
    this.setState({
      loaderIconVisible: true
    });

    return new Promise((resolve, reject) => {
      http.get(MenuUploadAPI.getLibraryList,
        {
          headers: {
            'Content-Type': 'application/json',
            'customerid': CUSTOMER_ID,
            'cgids': localStorage.getItem("custGroupID"),
            'brandid': localStorage.getItem("selectedbrandID"),
            'modelFamilyIds': localStorage.getItem("selectedFamilyID"),
            'basecountry': BASECOUNTRY_ID,
            'Type': this.props.libraryType
          },
          data: {}
        }).then(response => {
          let { data } = response;
          let libraryList = data && data.libraryList ? data.libraryList : [];
          libraryList.length > 0 && libraryList.map(item => {
            item.CREATED_ON = moment.ConvertLocalTimeFromUTCTime(item.CREATED_ON, "DD/MM/YYYY");
            item.checked = false;
          });

          this.setState({
            loaderIconVisible: false,
            data: libraryList,
            selectedlibraries: libraryList.filter(c => c.checked === true)
          });
          resolve(response);
        }).catch(err => {
          reject(err);
        });
    });
  }

  OnLibraryItemClick(item) {
    this.setState({
      selected_item: item,
      isImportEnabled: true
    });
  }

  OnImport() {
    this.props.OnImportLibrary(this.state.selected_item);
  }

  handleModelChange(e) {
    localStorage.setItem("selectedFamilyID", e.target.value);
    this.props.handleChangeProps(e);
  }

  OnToggleDetails = (item) => {
    const { CREATED_BY: created_by, UPDATED_BY: last_edited_by, ASSIGNED_CUSTOMER_GROUP: assignedTopologyDetails } = item;
    this.setState({
			created_by,
			last_edited_by,
			assignedTopologyDetails
		});
  }

  librarySearchResult = (e) => {
    this.setState({ search: e.target.value });
  }

  render() {
    let { data: dataList, isSelectAllLibrary, sortColumn, isImportEnabled, created_by, last_edited_by, search, assignedTopologyDetails } = this.state;
    dataList = dataList && dataList.length > 0 ? dataList : [];
    let { libraryType } = this.props;
    const { formatMessage } = this.props.intl;
    let columns = [
      { path: "", label: "#" },
      { path: "", labelStringID: "KC0048" },
      { path: "BRAND_NAME", labelStringID: "KC0062" },
      { path: "", labelStringID: "KC2065" },
      { path: "LIBRARY_NAME", labelStringID: "KC2066" },
      { path: "CREATED_ON", labelStringID: "KC0725" },
      { path: "", label: "" },
      { path: "IMAGE_FILE_SIZE", labelStringID: "KC0682" },
      { path: "", labelStringID: "KC0325" },
    ];

    dataList = _.orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path].toString().toLowerCase()], [sortColumn.order]);

    const searchedItemInFilter = dataList && dataList.filter(
        (library) => {
            return library.LIBRARY_NAME.toLowerCase().indexOf(search.toLowerCase()) !== -1;
        }
    );
    dataList = searchedItemInFilter;

    return (
      <React.Fragment>
        <div class="modal fade selectRecipeModal" data-keyboard="true" data-backdrop="static" id="selectRecipeModal" tabindex="-1" role="dialog" aria-labelledby="selectRecipeModalLabel">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header modal-header-danger">
                <FormattedMessage id="KC2067"/> {changeCase.capitalCase(Array.isArray(libraryType) ? libraryType[0].toLowerCase() : libraryType.toLowerCase())} 
                <div class="modalSearchForm"> 
                  <ul>
                    <li>
                      <div class="modalSearchField">
                        <input type="text" placeholder={formatMessage({ id: 'KC2068' })} onChange={(e) => this.librarySearchResult(e)} class="form-control" />
                      </div>
                    </li>
                    <li>
                      <button type="button" class="btn modalSearchButton">&nbsp;</button>
                    </li>
                  </ul> 
                </div>	
              </div>
              <div class="modal-body">
                <div class="selectRecipeTable">
                  <TableHeader
                    sortColumn={sortColumn}
                    onSort={this.onSort}
                    selectAllChange={this.selectAllLibrary}
                    columns={columns}
                    isSelectAll={isSelectAllLibrary} />
                </div>
                <div class="selectRecipeTableForm">
                <div class="selectRecipeTable">
                  {
                    dataList.map((item, i) => {
                      return (
                        <ul className="tBody" key={i} onClick={() => this.OnLibraryItemClick(item)}>
                          <li>{i + 1}</li>
                          <li>{item.TYPE === "Settings" ? "" : item.IMAGE_S3_SIGNED_URL ? <span className="menuPicture"><img src={item.IMAGE_S3_SIGNED_URL} alt="" /></span> : ""}</li>
                          <li>{item.BRAND_NAME}
                          <span class="unitsRCSM">{item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.NAME).toString() : ''}</span> 
                          <span class="unitsRCM">{item.LIBRARY_NAME}</span> 
                          <span class="unitsRCMD">{item.CREATED_ON}</span></li>
                          <li>{item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.NAME).toString() : ''}</li>
                          <li>{item.LIBRARY_NAME}</li>
                          <li>{item.CREATED_ON}</li>
                          <li>{item.ASSIGNED_COUNT ? "" : <span className="badge badge-primary"><FormattedMessage id="KC2112"/></span>}</li>
                          <li>{item.LIBRARY_FILE_SIZE ? item.LIBRARY_FILE_SIZE : "0.00"}</li>
                          <li><button type="button" className="btn btn-default-text menuInfo" data-toggle="modal" data-target="#detailsInfoModal" onClick={() => this.OnToggleDetails(item)}>&nbsp;</button></li>
                        </ul>
                      )
                    })
                  }
                </div>
                </div>
              </div>
              <div class="modal-footer textAlignCenter">
                <button class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
                <button class={isImportEnabled ? "btn btn-secondary yesBtn" : "btn btn-secondary yesBtn disabled"} data-dismiss="modal" type="button" onClick={() => this.OnImport()}><FormattedMessage id="KC2069"/></button>
              </div>
            </div>

          </div>
        </div>
        {/* <!-- Info Modal --> */}
        <CreatedUserPopup created_by={created_by} last_edited_by={last_edited_by} assignedTopology={assignedTopologyDetails ? assignedTopologyDetails : []} />
        {/* <!-- //Info Modal --> */}
      </React.Fragment>
    );
  }
}

export default injectIntl(LibraryListPopup)

LibraryListPopup.propTypes = {
  data: PropTypes.array.isRequired,
  libraryType: PropTypes.array.isRequired,
}
