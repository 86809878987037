import http from '../../service/httpService';
import {BaseService, ReportManagement} from '../../service/api';
import { BRAND_ID } from '../../utils/appConstants';
export const PEAKHOUR_BARCHART_REQUEST = 'PEAKHOUR_BARCHART_REQUEST';
export const PEAKHOUR_BARCHART_SUCCESS = 'PEAKHOUR_BARCHART_SUCCESS';
export const PEAKHOUR_BARCHART_FAIL = 'PEAKHOUR_BARCHART_FAIL';

export function getpeakHourChartInfo (type, fromdate, todate, deviceId, brandid, page, holder_id ='') {
    let url ;
    let filterType;
    let widgetId = null;
    let UNIT_BRAND_ID = null;

    switch (type) {
        case 'Week':
            filterType='weekly';        
            break; 
        case 'Year':
            filterType='yearly';            
            break; 
        case 'Day':            
            filterType='daily';
            break;
        case 'Quarter':
            filterType='quarterly';            
            break; 
        case 'Date Range':
            filterType = 'custom';
            break; 
        default: 
            filterType = 'monthly';              
        }   

    if(localStorage.getItem("brandName")==="MERRYCHEF")
    {
        widgetId = 21;
        UNIT_BRAND_ID = 1;
        url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
    }
    else if(localStorage.getItem("brandName")==="CONVOTHERM")
    {
        widgetId = 38;
        UNIT_BRAND_ID = 6;
        url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
    }
    else if(localStorage.getItem("brandName")==="FRYMASTER")
    {
        widgetId = 53;
        UNIT_BRAND_ID = 3;
        url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
    }
    else if(localStorage.getItem("brandName") === "GARLAND")
    {
        widgetId = 85;
        UNIT_BRAND_ID = 9;
        url = `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${UNIT_BRAND_ID}`;
    }
    else{
        url = `${ReportManagement.root}`
    }     

    return dispatch => {
          
        let header;
        if(localStorage.getItem("brandName") === "MERRYCHEF" || localStorage.getItem("brandName") === "CONVOTHERM"
            || localStorage.getItem("brandName") === "FRYMASTER" || localStorage.getItem("brandName") ==="GARLAND")
        {
            if (filterType === 'weekly' || filterType === 'custom')
            {
                header = {
                    'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'unitid' : deviceId,                                                 
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType,
                    'showtrend': "false",
                }; 
            }
            else
            {
                header = {
                    'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'unitid' : deviceId,                    
                    'reqdate' : fromdate,
                    'filterType' : filterType,
                    'showtrend': "false",
                }; 
            }
            if (localStorage.getItem("brandName") === "FRYMASTER" && holder_id !== "") {
                header.holderid = holder_id;
            }
        }
        else
        {
            if (filterType === 'weekly' || filterType === 'custom')
            {
                header = {
                    'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'unitid' : deviceId,              
                    'brandid': brandid !== undefined ? ( brandid.length > 0 ?  brandid : BRAND_ID):BRAND_ID,
                    'startdate' : todate,
                    'enddate' : fromdate,
                    'filterType' : filterType,
                    'groupby' : 'product_type'
                }; 
            }
            else
            {
                header = {
                    'Content-Type':'application/json',
                    'Access-Control-Allow-Origin':'*',
                    'unitid' : deviceId,
                    'brandid': brandid !== undefined ? ( brandid.length > 0 ?  brandid : BRAND_ID):BRAND_ID,
                    'reqdate' : fromdate,
                    'filterType' : filterType,
                    'groupby' : 'product_type'
                }; 
            }
        }
        header.cid=localStorage.getItem("customerId") ;

        http.get(url,
                {
                  headers: header,
                  data:{}
                })
            .then(response => {
                if(localStorage.getItem("brandName") === "MERRYCHEF" || localStorage.getItem("brandName") === "CONVOTHERM"
                || localStorage.getItem("brandName") === "FRYMASTER" || localStorage.getItem("brandName") ==="GARLAND")
                {
                    dispatch({
                        type: PEAKHOUR_BARCHART_SUCCESS,
                        time: type,
                        page: page,
                        device: deviceId,
                        peakHourChartInformation:response.data.peakHoursChart,
                    })
                }else{
                    dispatch({
                        type: PEAKHOUR_BARCHART_SUCCESS,
                        time: type,
                        page: page,
                        device: deviceId,
                        peakHourChartInformation:response.data.peakHoursDrinksChart,
                    })
                } 
            })

            .catch(error => {
                dispatch({
                    type: PEAKHOUR_BARCHART_FAIL,
                    error
                })
            })
    }
}
