import React, { Component } from 'react';
import $ from 'jquery';
import { BaseService, DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import countryCodeArray from '../login/countryCodeArray';
import GoCardless from './gocardless';

class EaasRegistrationForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            isCreditCardShow: false,
            jwtToken: '',
            plan: {},
            fields: {},
            errors: {},
            countryDropdown: [],
            stateDropdown: [],
            success_modal: {
                open: false,
                message: "",
            },
            error_modal: {
                open: false,
                message: "",
                isHtml: false
            },
            creditCardValidationError: false,
            getError: false,
            isPageLoad: false,
            countryCodeList: [],
            checkboxSelected: false
        }
    }

    componentDidMount = async () => {
        $("body").addClass("subscriptionBg");

        const consumerData = await (localStorage.getItem("consumerData") ? JSON.parse(localStorage.getItem("consumerData")) : {});

        if (!consumerData || !consumerData.userDetails) {
            this.setState({
                loaderIconVisible: false,
                isPageLoad: true
            })
            return;
        }
        await this.getCountryDropdown();
        this.setState({ consumerData }, () => {
            this.setState({ loaderIconVisible: false, countryCodeList: countryCodeArray });
        })
    }

    getCountryDropdown = () => {
        return new Promise(async (res, rej) => {
            let url = DeviceRegistration.getCountryDropdown;

            await http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {}
            }).then(async response => {
                if (response.data && response.data.length) {
                    let countryDropdown = response.data.filter((i) => i.country_name === "United Kingdom");
                    await this.setState({ countryDropdown });
                    const consumerData = await (localStorage.getItem("consumerData") ? JSON.parse(localStorage.getItem("consumerData")) : {});

                    const fields = {
                        'firstName': consumerData.userDetails && consumerData.userDetails.first_name ? consumerData.userDetails.first_name : '',
                        'lastName': consumerData.userDetails && consumerData.userDetails.last_name ? consumerData.userDetails.last_name : '',
                        'email': consumerData.email,
                        'phoneNumber': consumerData.userDetails && consumerData.userDetails.phone_number ? consumerData.userDetails.phone_number : '',
                        'countryCode': consumerData.userDetails && consumerData.userDetails.country_code ? consumerData.userDetails.country_code : ''
                    }

                    if (countryDropdown && countryDropdown.length) {
                        fields['welbiltInvoicingEntity'] = countryDropdown[0].eaas_invoice_entity || "";
                        fields["country"] = countryDropdown[0].country_iso2_code || "";
                        const countryName = countryDropdown[0].country_name || "";
                        let errors = this.state.errors;
                        errors['country'] = '';
                        this.setState({
                            fields,
                            errors
                        });
                        await this.loadStateDropdown(countryName, fields['country'])
                    }else{
                        this.setState({
                            fields
                        });
                    }
                    res(true);
                }
            }).catch(err => {
                res(false);
            });
        })
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            },
        })
        if (this.state.creditCardValidationError) {
            return;
        }
        if (localStorage.getItem('isCookieAccepted')) {
            localStorage.clear();
            localStorage.setItem('isCookieAccepted', true);
        } else {
            localStorage.clear();
        }
        this.props.history.push('/')
    }

    onSuccessCloseModal = async (e) => {
        this.setState({
            success_modal: {
                open: false,
            },
        });
        if (localStorage.getItem('isCookieAccepted')) {
            localStorage.clear();
            localStorage.setItem('isCookieAccepted', true);
        } else {
            localStorage.clear();
        }
        this.props.history.push('/')
    }

    callChargifyApi = () => {
        return new Promise(async (res, rej) => {
            try {
                const apiCall = await this.refs.goCardlessForm.handleSubmit();
                this.setState({ loaderIconVisible: true})
                res(apiCall);
            } catch (err) {
                this.setState({ loaderIconVisible: false, creditCardValidationError: true, error_modal: { open: true, message: err && err.message ? err.message : err.errors ? err.errors : 'Invalid details' } })
                rej(err)
            }
        })
    }

    registerCustomer = async () => {
        this.setState({ loaderIconVisible: true, creditCardValidationError: false });
        const { consumerData, fields } = this.state;
        if (await this.validateForm()) {
            let bodyParams = {
                "name": consumerData.name || "",
                "email": consumerData.email || "",
                "state_name": fields.stateName || "",
                "customer_id": consumerData.customer_id || '',
                "customer_name": consumerData.customer_name || '',
                "resourceType": "customers",
                "base_country": consumerData && consumerData.userDetails && consumerData.userDetails.base_country ? consumerData.userDetails.base_country : '',
                "access_countries": consumerData && consumerData.userDetails && consumerData.userDetails.access_countries ? consumerData.userDetails.access_countries : '',
                "kc_plan_id": consumerData && consumerData.chargify_product_details && consumerData.chargify_product_details.length ? consumerData.chargify_product_details[0].id : '',
                "chargifyMetaData": [
                    {
                        "name": "Commercial Company Name",
                        "value": fields.restaurantName || ""
                    },
                    {
                        "name": "Billing Email Address",
                        "value": fields.companyEmailAddress || ""
                    }, {
                        "name": "Welbilt Invoicing Entity",
                        "value": fields.welbiltInvoicingEntity || ""
                    }
                ],
                "subscriptionDetails": {
                    "currency": fields.country === 'GB' ? 'GBP' : 'USD',
                    "product_handle": consumerData && consumerData.chargify_product_details && consumerData.chargify_product_details.length ? consumerData.chargify_product_details[0].chargify_subscription_plan_attributes : "",
                    "customer_attributes": {
                        "first_name": fields.firstName || "",
                        "last_name": fields.lastName || "",
                        "email": fields.email || "",
                        "zip": fields.zipCode || "",
                        "phone": fields.phoneNumber || "",
                        "organization": fields.companyName || "",
                        "country": fields.country || "",
                        "state": fields.state || "",
                        "city": fields.city || "",
                        "address_2": fields.address2 || "",
                        "address": fields.address1 || "",
                        "vat_number": fields.vatNumber || ""
                    }
                },
            }
            if (fields.country === "GB") {
                const objValue = {
                    "name": "Registered in",
                    "value": "England and Wales No. 2656967 as Welbilt UK Limited"
                }
                const WelbiltVatNumber = {
                    "name": "Welbilt VAT Number",
                    "value": "GB243188561"
                }
                bodyParams['chargifyMetaData'].push(objValue);
                bodyParams['chargifyMetaData'].push(WelbiltVatNumber);
            }

            const creditCardParam = {
                "chargify_token": this.refs.goCardlessForm.state.token || "",
                "payment_type": "direct_debit",
            }
            bodyParams["subscriptionDetails"]["bank_account_attributes"] = creditCardParam;


            bodyParams["subscriptionDetails"]["calendar_billing"] = {
                "snap_day": 1,
                "calendar_billing_first_charge": "prorated"
            };
            await this.registerAPICall(bodyParams);
        } else {
            this.setState({ loaderIconVisible: false });
        }
    }

    onChangeEvent = (e, stateKey) => {
        let fields = this.state.fields;
        fields[stateKey] = stateKey === "email" || stateKey === "companyEmailAddress" ? e.target.value.toLowerCase() : e.target.value;
        this.setState({
            fields
        });
    }

    nameBlurEvent = (stateKey) => {
        let inputdataobj = this.state.fields[stateKey];
        if (inputdataobj && inputdataobj !== "undefined" && inputdataobj.trim() !== "") {
            this.setState({
                errors: {
                    ...this.state.errors,
                    [stateKey]: ''

                }
            })
        }
        return false;
    }

    emailblurevent = () => {
        let inputdataemailobj = this.state.fields.email;
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (inputdataemailobj && inputdataemailobj !== "undefined" && pattern.test(inputdataemailobj)) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    email: ''

                }
            })
        }
        return false;
    }

    phoneblurevent = () => {
        let inputdataobj = this.state.fields.phoneNumber;
        var pattern = new RegExp(/^[0-9]{10}$/);
        if (inputdataobj && inputdataobj !== "undefined" && pattern.test(inputdataobj)) {
            this.setState({
                errors: {
                    ...this.state.errors,
                    phoneNumber: ''

                }
            })
        }
        return false;
    }
    validateForm = async () => {
        let { fields, errors } = this.state;
        let isValidForm = true;
        if (!fields["firstName"] || fields["firstName"] === "undefined" || fields["firstName"].trim() === "") {
            errors["firstName"] = true;
            isValidForm = false;
        }
        // if (!fields["lastName"] || fields["lastName"] === "undefined" || fields["lastName"].trim() === "") {
        //     errors["lastName"] = true;
        //     isValidForm = false;
        // }
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if (!fields["email"] || fields["email"] === "undefined" || !pattern.test(fields["email"])) {
            errors["email"] = true;
            isValidForm = false;
        }
        // var phoneNumberPattern = new RegExp(/^[0-9]{10}$/);
        // if (!fields["phoneNumber"] || fields["phoneNumber"] === "undefined" || fields["phoneNumber"].trim() === "") {
        //     errors["phoneNumber"] = true;
        //     isValidForm = false;
        // }
        if (!fields["companyName"] || fields["companyName"] === "undefined" || fields["companyName"].trim() === "") {
            errors["companyName"] = true;
            isValidForm = false;
        }
        if (!fields["address1"] || fields["address1"] === "undefined" || fields["address1"].trim() === "") {
            errors["address1"] = true;
            isValidForm = false;
        }
        if (!fields["city"] || fields["city"] === "undefined" || fields["city"].trim() === "") {
            errors["city"] = true;
            isValidForm = false;
        }
        if (!fields["zipCode"] || fields["zipCode"] === "undefined" || fields["zipCode"].trim() === "") {
            errors["zipCode"] = true;
            isValidForm = false;
        }
        if (!fields["country"] || fields["country"] === "undefined" || fields["country"].trim() === "") {
            errors["country"] = true;
            isValidForm = false;
        }
        if (!fields["state"] || fields["state"] === "undefined" || fields["state"].trim() === "") {
            errors["state"] = true;
            isValidForm = false;
        }

        await this.setState({ errors });
        return isValidForm;
    }

    async registerAPICall(bodyParams) {
        const { consumerData } = this.state;
        http({
            method: "POST",
            url: DeviceRegistration.chargifyConsumerCreation,
            headers: {
                'Content-Type': 'application/json',
            },
            data: bodyParams
        }).then(async response => {
            if (response && response.data) {
                let consumerDataValue = await JSON.parse(localStorage.getItem('consumerData'));
                consumerDataValue['chargify_subscription_id'] = response.data.subscriptionId;
                await localStorage.setItem('consumerData', JSON.stringify(consumerDataValue))

                http({
                    method: "POST",
                    url: `${BaseService.root}${DeviceRegistration.eaasEmailConfirmation}`,
                    data: {
                        "name": consumerData.name || '',
                        "email": consumerData.email || '',
                        "customer_email": consumerData.customer_email || '',
                        "company_name": bodyParams && bodyParams.subscriptionDetails && bodyParams.subscriptionDetails.customer_attributes ? bodyParams.subscriptionDetails.customer_attributes.organization : ''
                    },
                    headers: {
                        'Content-Type': 'application/json',
                    }
                }).then(response => {
                    this.setState({ loaderIconVisible: false, success_modal: { open: true, message: 'You have completed the payment setup successfully. You will receive notification via email within 3 business days confirming mandate setup. Your KitchenConnect account is now ready to use.' } });
                }).catch(err => {
                    this.setState({ loaderIconVisible: false, success_modal: { open: true, message: 'You have completed the payment setup successfully. You will receive notification via email within 3 business days confirming mandate setup. Your KitchenConnect account is now ready to use.' } });
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false, error_modal: { open: true, message: err && err.response && err.response.data && err.response.data.body && err.response.data.body.message ? err.response.data.body.message : 'Something went wrong' } });
        });
    }

    render() {
        const { loaderIconVisible, isPageLoad, consumerData, countryCodeList, fields, errors, error_modal, success_modal, checkboxSelected } = this.state;
        if (isPageLoad) {
            return (<div id="page-wrapper">
                <div className="underConstruction">
                    <div className="underConstructionImg"></div>
                    <h3>You are not authorized to view this page.</h3>
                    <div className="clear"></div>
                </div>
            </div>)
        }
        return (
            <div id="adminWrapper">
                <div className="toggleTransparent"></div>
                <div className="sideNavToggleTransparent"></div>
                <div className="userToggleTransparent"></div>
                <div id="page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <div className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table">&nbsp;</div>
                </nav>
                <div id="page-content-wrapper">

                    <div className="subscriptionBForm">
                        <h2>Customer Registration</h2>
                        <div className="subscriptionInWrapper">
                            <div className="billingFormWrapper">
                                <h2>Personal Information</h2>
                                <div className="billingForm marginBottom">
                                    <ul>
                                        <li className="colm50 disabled">
                                            <label>First Name *</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="First Name" readOnly={true} disabled={true} value={fields.firstName} onChange={(e) => this.onChangeEvent(e, "firstName")} onBlur={() => this.nameBlurEvent("firstName")} autoFocus />
                                            </div>
                                            {errors["firstName"] && <div className="errorMsg">First Name is required</div>}
                                        </li>
                                        <li className="colm50 disabled">
                                            <label>Last Name *</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Last Name" readOnly={true} disabled={true} value={fields.lastName} onChange={(e) => this.onChangeEvent(e, "lastName")} onBlur={() => this.nameBlurEvent("lastName")} />
                                                {errors["lastName"] && <div className="errorMsg">Last Name is required</div>}
                                            </div>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li className="colm50 disabled">
                                            <label>Email Address *</label>
                                            <div className="input-group">
                                                <input type="email" className="form-control" placeholder="Email Address" readOnly={true} value={fields.email} disabled={true} onChange={(e) => this.onChangeEvent(e, "email")} onBlur={() => this.emailblurevent()} />
                                                <span className="requiredField">*</span>
                                                {errors["email"] && <div className="errorMsg">{fields.email.trim() === '' ? `Email is required` : `Email already exist`}</div>}
                                            </div>
                                        </li>
                                        <li className="colm50 disabled">
                                            <label>Phone Number *</label>
                                            <div className="input-group">
                                                <select className="form-control countryCode" disabled>
                                                    {
                                                        countryCodeList && countryCodeList.map((item, i) =>
                                                            <option key={i} value={item.code} selected={item.code === this.state.fields.countryCode}>{item.code}</option>
                                                        )
                                                    }
                                                </select>
                                                <input type="number" className="form-control phoneNumber" placeholder="Phone Number" readOnly={true} value={fields.phoneNumber} disabled={true} onChange={(e) => this.onChangeEvent(e, "phoneNumber")} />
                                                {/* {errors["phoneNumber"] && <div className="errorMsg">{fields.email.trim() === '' ? `Phone number is required` : `Please provide valid phone number`}</div>} */}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <h2>Company &amp; Restaurant Information</h2>
                                <div className="billingForm">
                                    <ul>
                                        <li>
                                            <label>Company Name *</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Company Name" value={fields.companyName} onChange={(e) => this.onChangeEvent(e, "companyName")} onBlur={() => this.nameBlurEvent("companyName")} />
                                                {errors["companyName"] && <div className="errorMsg">Company Name is required</div>}
                                            </div>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <label>Restaurant Name </label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Restaurant Name" value={this.state.fields.restaurantName} onChange={(e) => this.onChangeEvent(e, "restaurantName")} />
                                            </div>
                                        </li>
                                    </ul>
                                    {consumerData && consumerData.base_country === 'UK' ? null : <ul>
                                        <li>
                                            <label>Company Email Address </label>
                                            <div className="input-group">
                                                <input type="email" className="form-control" placeholder="Company Email Address" value={this.state.fields.companyEmailAddress} onChange={(e) => this.onChangeEvent(e, "companyEmailAddress")} />
                                            </div>
                                        </li>
                                    </ul>}
                                </div>
                                <div className="billingForm">
                                    {consumerData && consumerData.base_country === 'UK' ? <ul>
                                        <li className="colm50">
                                            <label>Company Email Address </label>
                                            <div className="input-group">
                                                <input type="email" className="form-control" placeholder="Company Email Address" value={this.state.fields.companyEmailAddress} onChange={(e) => this.onChangeEvent(e, "companyEmailAddress")} />
                                            </div>
                                        </li>
                                        <li className="colm50">
                                            <label>VAT Number </label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="VAT Number" value={fields.vatNumber} onChange={(e) => this.onChangeEvent(e, "vatNumber")} />
                                            </div>
                                        </li>
                                    </ul> : null}
                                    <ul>
                                        <li className="colm50">
                                            <label>Address 1 * </label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Address 1" value={fields.address1} onChange={(e) => this.onChangeEvent(e, "address1")} onBlur={() => this.nameBlurEvent("address1")} />
                                                {errors["address1"] && <div className="errorMsg">Address 1 is required</div>}
                                            </div>
                                        </li>
                                        <li className="colm50">
                                            <label>Address 2 </label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Address 2" value={fields.address2} onChange={(e) => this.onChangeEvent(e, "address2")} />
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div className="billingForm disabled">
                                    <ul>
                                        <li>
                                            <label>Country *</label>
                                            <div className="input-group">
                                                {this.renderCountry()}
                                            </div>
                                            {errors["country"] && <div className="errorMsg">Country is required</div>}
                                        </li>
                                    </ul>
                                </div>
                                <div className="billingForm">
                                    <ul>
                                        <li className="colm33">
                                            <label>City *</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="City" value={fields.city} onChange={(e) => this.onChangeEvent(e, "city")} onBlur={() => this.nameBlurEvent("city")} />
                                                {errors["city"] && <div className="errorMsg">City is required</div>}
                                            </div>
                                        </li>
                                        <li class="colm33">
                                            <label>State *</label>
                                            <div class="input-group">
                                                {this.renderState()}
                                                {errors["state"] && <div className="errorMsg">State is required</div>}
                                            </div>
                                        </li>
                                        <li className="colm33">
                                            <label>Zip Code *</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" placeholder="Zip Code" value={fields.zipCode} onChange={(e) => this.onChangeEvent(e, "zipCode")} onBlur={() => this.nameBlurEvent("zipCode")} />
                                                {errors["zipCode"] && <div className="errorMsg">Zip code is required</div>}
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <h2 >Billing Information - Set up a Direct Debit with Welbilt</h2>
                                <GoCardless ref="goCardlessForm" />
                                <div class="customCheckbox customCheckboxWlabel">
                                    <input id="confirm" checked={checkboxSelected} name="confirm" type="checkbox" value="confirm" onChange={() => this.setState({ checkboxSelected: !this.state.checkboxSelected })} />
                                    <label for="confirm">I confirm that I am the account holder and am authorised to set up Direct Debit payments on this account.</label>
                                </div>
                                <div class="clear"></div>
                                <button disabled={!checkboxSelected} className="btn btn-secondary billingFormRegister"
                                    onClick={async () => {
                                        await this.callChargifyApi();
                                        this.registerCustomer()
                                    }}
                                    type="button">Register</button>
                                <div class="directDebitGuarantee">
                                    <img src={require('../../images/directdebitguarantee.png')} alt="The Direct Debit Guarantee" title="The Direct Debit Guarantee" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} isHtml={error_modal.isHtml} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </div>
        )
    }

    loadStateDropdown = async (countryname, countryiso2code) => {
        await http.get(DeviceRegistration.stateDropdownApi, {
            headers: {
                'Content-Type': 'application/json',
                countryname,
                countryiso2code
            },
            data: {}
        }).then(async response => {
            if (response && response.data) {
                this.setState({ stateDropdown: response.data })
            }
        }).catch(err => {
        });
    }

    renderCountry() {
        const { countryDropdown, fields } = this.state;
        return <select
            disabled
            className="form-control"
            onChange={async (e) => {
                const split = e.target.value ? e.target.value.split('||') : [];
                let fields = this.state.fields;
                fields['welbiltInvoicingEntity'] = split.length && split[1] ? split[1] : '';
                fields['country'] = split.length ? split[0] : '';
                const countryName = split.length ? split[split.length - 1] : '';
                this.setState({
                    fields
                });
                let errors = this.state.errors;
                errors['country'] = '';
                await this.loadStateDropdown(countryName, fields['country'])
            }}>
            <option value="0">Select Country</option>
            {countryDropdown && countryDropdown.length ? countryDropdown.map((country, index) => {
                return (
                    <option key={index} selected={true} value={country.country_iso2_code + '||' + country.eaas_invoice_entity + '||' + country.country_name}>{country.country_name}</option>
                )
            }) : null}
        </select >;
    }

    renderState() {
        const { stateDropdown, fields } = this.state;
        return <select
            className="form-control"
            onChange={async (e) => {
                let fields = this.state.fields;
                const split = e.target.value ? e.target.value.split('||') : [];
                fields['state'] = split.length ? split[0] : '';
                fields['stateName'] = split.length ? split[split.length - 1] : '';
                this.setState({
                    fields
                });
                let errors = this.state.errors;
                errors['state'] = '';
            }}>
            <option value="0">Select State</option>
            {stateDropdown && stateDropdown.length ? stateDropdown.map((state, index) => {
                return (
                    <option selected={fields['state'] === state.state_code} key={index} value={state.state_code + '||' + state.state_name}>{state.state_name}</option>
                )
            }) : null}
        </select >;
    }
}

export default EaasRegistrationForm;