import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import $ from 'jquery';
class SchedulerAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            date_format: ""
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.addStep = this.addStep.bind(this);
    }

    componentDidMount = () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";

        try {
            
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/scheduler",
            isEdit: false,
            previouspath: "/schedulerAdd"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    addStep = (path) => {
        let jsonSchedulerDetails = localStorage.getItem("schedulerDetails");
        let ObjSchedulerDetails = JSON.parse(jsonSchedulerDetails);

        let jsonLibraryDetails = localStorage.getItem("libraryDetails");
        let ObjLibraryDetails = JSON.parse(jsonLibraryDetails);
        
        var foundIndex = ObjSchedulerDetails.findIndex(s => s.day === ObjLibraryDetails.day);

        let scheduler;
        let config = {};
        let configurations = ObjSchedulerDetails[foundIndex].configurations;
        scheduler = ObjSchedulerDetails[foundIndex].configurations.filter(item => item.type !== "Cold");
        let schedulerid = Math.max.apply(Math, scheduler.map(function(o) { return o.id; }));
        config.id = schedulerid + 1;
        config.time = "";
        config.text = "";
        config.temp = "";
        config.instruction = "";
        if (path === 'ChangeOfTemperature') {
            config.type = "Hot";
            configurations.push(config);
        }
        else {
            config.type = "Instruction";
            configurations.push(config);
        }
        ObjSchedulerDetails[foundIndex].configurations = configurations.sort(function (p1, p2) { return p1.id - p2.id; });
        localStorage.setItem("schedulerDetails", JSON.stringify(ObjSchedulerDetails));
        this.props.history.push(`/scheduler`);
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    render() {
        let { error_modal, success_modal } = this.state;
        let brandid = localStorage.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1270"/>
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="cbaddMenuRecipe">
                                                    <ul>
                                                        <li> 
                                                            <button class="border0" title={formatMessage({ id: 'KC2083' })} onClick={() => {this.addStep('ChangeOfTemperature')}}>
                                                                <span class="navIcon"><img src={require("../../../../../images/recipecreation/addButton.png")}  alt=""/></span>
                                                                <span class="navTitle"><FormattedMessage id="KC1332"/></span>
                                                            </button>  
                                                        </li>
                                                        <li>&nbsp;</li>
                                                        <li>
                                                            <button class="border0" title={formatMessage({ id: 'KC2084' })} onClick={() => {this.addStep('Instruction')}}>
                                                                <span class="navIcon"><img src={require("../../../../../images/recipecreation/addButton.png")}  alt=""/></span>
                                                                <span class="navTitle"><FormattedMessage id="KC1333"/><br/><br/></span>
                                                            </button> 
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(SchedulerAdd)
