import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import TopTenPieChart from '../../reportManagement/topTenPieChart';
import { TableHeader } from '../../controls/tableHeader';
import { getTopTenTableInfo } from '../../../actions/reportManagement/topTenTableActoin';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import { FormattedMessage, injectIntl } from 'react-intl';
import _ from 'lodash';

const Colors = ["#ff97cd", "#0083b3", "#7192f1", "#c10b6d", "#414548", "#eacd61", "#59a9ed", "#00eabf", "#ff3b84", "#e08e53"];

// common global parameters
var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = "";
let currentUnit;


class TopTenRecipes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            topTenType: 'Month',
            sortColumn: { path: "", order: "" },
            noResultMsg: 'No Results',
            sdTopTen: '',
            edTopTen: '',
            startDateTopTen: '',
            endDateTopTen: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            datapickflag: false
        }
        this.handleChangeStartTopTen = this.handleChangeStartTopTen.bind(this);
        this.handleChangeEndTopTen = this.handleChangeEndTopTen.bind(this);
    }

    onSort = sortColumn => {
        this.setState({
            sortColumn: sortColumn
        });
    }

    componentDidUpdate =async (prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.topTenType !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ topTenType: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleTenRecipes(this.props.globalDropdownFilter);
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDateTopTen !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEndTopTen(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDateTopTen !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStartTopTen(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //Top Ten Drinks    
    handleChangeStartTopTen(dateTopTen) {
        if(this.state.startDateTopTen === dateTopTen){
            return;
        }
        this.setState({
            startDateTopTen: dateTopTen,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleTenRecipes(selectedtext);
        });
    }

    handleChangeEndTopTen(dateTopTen) {
        if(this.state.endDateTopTen === dateTopTen){
            return;
        }
        this.setState({
            endDateTopTen: dateTopTen,
            topTenTableChanged: true,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleTenRecipes(selectedtext);
        });
    }

    componentDidMount() {
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        currentUnit = this.props.currentUnit.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        if (localStorage.getItem("brandName") === "CREM") {
            brandID = currentUnit.BRAND_NAME
        } else {
            brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        }
        this.props.getTopTenTableInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, "Unit");
        setTimeout(() => {
            this.setState({
                loaderIconVisible: false,
            });
        }, 2000)
    }

    //dropdown Handler
    onDropDownHandler(e) {
        const { nodeText : text } = e;
        this.setState({topTenType: text, datapickflag:text === 'Date Range', startDateTopTen: '', endDateTopTen: ''})
        this.handleTenRecipes(text);
    }

    handleTenRecipes(text) {
        currentUnit = this.props.currentUnit.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        if (localStorage.getItem("brandName") === "CREM") {
            brandID = currentUnit.BRAND_NAME;
        } else {
            brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        }
        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDateTopTen, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDateTopTen, 'YYYY-MM-DD');
        }
        else if (text === 'Week') {
            weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            if (localStorage.getItem("brandName") !== "CREM") {
                var startDateMonth = getMonth(new Date(weekDateFormatted));
                var endDateMonth = getMonth(new Date(todaysDateFormatted));

                if (startDateMonth !== endDateMonth) {
                    weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
                }
            }
        }
        else {
            todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
            weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
        }

        if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.props.getTopTenTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, "Unit");
        }
    }

    render() {
        let orderId = 1;
        let { sortColumn, } = this.state;
        const { formatMessage } = this.props.intl;
        let topTenTableTotal = 0;
        let topTenTable = [];
        const { topTenTableInfo, measure, measureStringID, caption, captionStringID } = this.props;
        let brandid = '0';

        let { topTenTableInformation } = topTenTableInfo;
        if (localStorage.getItem("brandName") === "CREM") {
            topTenTableInformation = topTenTableInfo.topTenTableInformation;
            brandid = '4';
        }
        else {
            topTenTableInformation = topTenTableInfo.topTenTableInformation.result;
            brandid = topTenTableInfo.topTenTableInformation.brandId ? topTenTableInfo.topTenTableInformation.brandId : '0';
        }

        if (topTenTableInformation !== "{}") {
            if (Array.isArray(topTenTableInformation) && topTenTableInformation.length > 0) {
                topTenTableTotal = topTenTableInformation.reduce((prev, cur) => prev + cur.counts, 0);
                // CREM Expobar Model(EX3 & Diamant Pro) Sprint-3 UI Implementation filter code added
                topTenTable = topTenTableInformation.filter(el => el.counts > 0)

                if (topTenTable && topTenTable.length > 0) {
                    topTenTable.map((item, i) => { item.color = Colors[i] });
                }
            }
        }

        var topTencountDiv;
        var totalsoldDiv;
        if (topTenTableTotal > 0) {
            let newNumberFormat =  <NumberFormat value={topTenTableTotal} displayType={'text'} thousandSeparator={true} />
            if (localStorage.getItem("thousandSeparator") === "."){
                newNumberFormat =  <NumberFormat value={topTenTableTotal} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
            }
            topTencountDiv = <div className="piechartvalueText"> {newNumberFormat} <span className="drinksText"/* trendimg"*/>{measureStringID ? <FormattedMessage id={measureStringID}/> : measure}</span></div>
            totalsoldDiv = <div className="totalsoldtext"> {captionStringID ? <FormattedMessage id={captionStringID}/> : caption} </div>
        }
        else {
            topTencountDiv = <div className="piechartvalueText">   <span className="drinksText"/* trendimg"*/></span></div>
            totalsoldDiv = <div className="totalsoldtext"> </div>
        }

        let columns = [
            { path: "", label: "#" },
            { path: "", label: "", isNbSp: true },
            { path: "productID", labelStringID: "KC0170" },
            { path: "trend", labelStringID: "KC0171" },
            { path: "counts", labelStringID: "KC0172" },
        ];

        topTenTable = _.orderBy(topTenTable, [sortColumn.path], [sortColumn.order]);

        topTenTable.forEach(item => {
            item.order_id = orderId++
        });

        return (

            <div id="Top10Products" className="colmDi">
                <h4>{ this.props.widgetnameStringID ? <FormattedMessage id={this.props.widgetnameStringID} /> : this.props.widgetname}
                        &nbsp; {localStorage.getItem("excludedProductList") === "true" ? <span class="blueDot" title={formatMessage({id: "KC1037"})}></span> : ""}
                        &nbsp; <span className="red-txt"></span>
                    <span className="cornerIcon"></span>
                    <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDateTopTen} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDateTopTen} handleChangeStartProps={(date) => this.handleChangeStartTopTen(date)} handleChangeEndProps={(date) => this.handleChangeEndTopTen(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.topTenType} />
                </h4>
                <div className="topSoldDrinkschartouterdiv">
                    <div id="topSoldDrinkschartdiv">
                        {<TopTenPieChart brandID={brandid} topTenTableInformation={topTenTableInfo} isChanged={this.state.topTenTableChanged} />}
                    </div>
                    <div className="piechartvalue">
                        {topTencountDiv}
                        {totalsoldDiv}
                    </div>
                </div>

                <div className="topSoldDrinks">
                    <div id="legenddiv" className="topSoldDrinksTable">
                        <TableHeader
                            sortColumn={sortColumn}
                            onSort={this.onSort}
                            columns={columns} />
                        {
                            topTenTable.map((item, i) => (
                                <ul className="tBody" key={i}>
                                    <li className="serialn">{item.order_id}</li>
                                    <li><span className="marker" style={{ backgroundColor: item.color }}></span></li>
                                    <li className="title">{item.productID}</li>
                                    <li className={item.trend === '1' ? "change positive" : item.trend === '0' ? "change negative" : ""}></li> 
                                    <li className="value">{item.counts}</li>
                                </ul>
                            ))
                        }
                    </div>
                    <div>
                        {
                            topTenTable && topTenTable.length > 0 && topTenTable !== null ? " " : <div className="noResultMsg"><FormattedMessage id="KC0264"/></div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        topTenTableInfo: state.topTenTableInformation,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getTopTenTableInfo }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TopTenRecipes));