import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import $ from 'jquery';

class TemperatureBand extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            global_preheat_band: '',
            selected_unit: '',
            converted_Units: '',
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onTempSelect = this.onTempSelect.bind(this);
        this.generateTheTemperatureValues = this.generateTheTemperatureValues.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = localStorage.getItem("librarySettingsData");
            let jsonSettingsObj = JSON.parse(jsonSettingsFile);

            this.setState({
                global_preheat_band: jsonSettingsObj.global_preheat_band.toString(),
                selected_unit: jsonSettingsObj.units.replace('deg', '° ')
            })
            this.generateTheTemperatureValues(jsonSettingsObj.units.replace('deg', '° '),jsonSettingsObj.global_preheat_band.toString());
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    generateTheTemperatureValues = (selectedUnit, preheatBand) => {
        let { selected_unit } = this.state;
        const celsius_Values_Set = [10, 20, 30, 40, 50, 60, 70];
        let converted_Temp_Set = '';
        if (selectedUnit && selectedUnit === '° C') {
            converted_Temp_Set = celsius_Values_Set.map((number, index) => {
                return (
                    {
                        temperature: number.toString(),
                        temperatureBand: ('band' + number).toString(),
                    }
                )
            });
            var foundIndex = converted_Temp_Set.findIndex(x => x.temperature === preheatBand);
            if (foundIndex == -1) {
                this.setState({
                    global_preheat_band: (preheatBand * 5 / 9).toString()
                })
            }
        } else if (selectedUnit && selectedUnit === '° F') {
            converted_Temp_Set = celsius_Values_Set.map((number, index) => {
                return (
                    {
                        temperature: (number * 9 / 5).toString(),
                        temperatureBand: ('band' + (number * 9 / 5)).toString(),
                    }
                )
            });
            var foundIndex = converted_Temp_Set.findIndex(x => x.temperature === preheatBand);
            if (foundIndex == -1) {
                this.setState({
                    global_preheat_band: (preheatBand * 9 / 5).toString()
                })
            }
        }
        this.setState({
            converted_Units: converted_Temp_Set
        })
    }

    onTempSelect = (event) => {
        this.setState({
            global_preheat_band: event.target.value
        });
    }

    onConfirmButtonClick = () => {
        let { history } = this.props;
        this.setState({
            loaderIconVisible: true,
        });
        let { global_preheat_band } = this.state;
        if (global_preheat_band) {
            let jsonSettingsFile = localStorage.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            ObjSettingsFile.global_preheat_band = parseInt(global_preheat_band ? global_preheat_band : 0);
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            localStorage.setItem("librarySettingsData", strSettingsFile);
            if( history && history.location.previouspath === "/cookbookParameters") {
                this.props.history.push(`/cookbookParameters`);
            } else {
                this.props.history.push(`/preHeat`);
            }
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC2037"
                }
            });
        }
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/preHeat`);
    }

    onBackButtonClick = () => {
        let { history } = this.props;
        if( history && history.location.previouspath === "/cookbookParameters") {
            this.props.history.push(`/cookbookParameters`);
        } else {
            this.props.history.push({
                pathname: "/preHeat",
                isEdit: false,
                previouspath: "/temperatureBand"
            });
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        let { global_preheat_band, error_modal, success_modal, selected_unit, converted_Units } = this.state;
        let brandid = localStorage.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1287"/>
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            {converted_Units && converted_Units.map((item, index) => {
                                                                return (
                                                                    <div class="rcTempList">
                                                                        <ul key={index}>
                                                                            <li>{item.temperature}{selected_unit} <FormattedMessage id="KC2106" /></li>
                                                                            <li>
                                                                                <div class="customRadioBtn">
                                                                                    <input id={item.temperatureBand} type="radio" name="bandAll" value={item.temperature} checked={global_preheat_band === item.temperature ? true : false} onChange={(e) => this.onTempSelect(e)} />
                                                                                    <label for={item.temperatureBand} value={item.temperature}>&nbsp;</label>
                                                                                </div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                )
                                                            })}
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn btn-default-text confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(TemperatureBand)
