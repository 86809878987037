import axios from 'axios';
import api from '../../service/api';
import http from '../../service/httpService';
import { getAPIUrl } from '../../service/servicemanagement/serviceManagement'; 
export const errorCount_REQUEST = 'errorCount_REQUEST';
export const errorCount_SUCCESS = 'errorCount_SUCCESS';
export const errorCount_FAIL = 'errorCount_FAIL';

export function getTotalErrorsCount (deviceId, brandid, type) {
    return dispatch => {
        dispatch({
            type: errorCount_REQUEST
        })

        let url = getAPIUrl(null, null, null, deviceId , type);
        http.get(url,
            {
                headers: {'Content-Type':'application/json',
                            'brandid': localStorage.getItem("brandName") }, 
                data:{}
              })
        .then(response => {
            console.log('errorCountInfo' ,response.data);
            dispatch({
                type: errorCount_SUCCESS,
                ErrorsCountInformation: response.data,
             })
        })            
        .catch(error => {
                dispatch({
                    type: errorCount_FAIL,
                    error
                })
            })
    }
}

// function getTotalErrorCount(){
//     return axios.get(api.serviceMgmtErrorCount);
// }

// function getTotalErrorList(){
//     return axios.get(api.serviceMgmtErrorList);
// }