import { SetLocationImage } from "../../utils/locationResponseBuilder";
import { UnitBuilder } from "../../utils/unitResponseBuilder";
import { BRAND_ID, CUSTOMER_ID } from '../../utils/appConstants';
import http from '../../service/httpService';
import { UnitInfo, dashboardRenderer } from '../../service/api';
import queryString from 'query-string';
import { decriptData } from "../../utils/dashboardRenderer"; 
import { ungzip } from 'pako';

function decompressData(base64) {
    try {
        const buffer = Buffer.from(base64, "base64");
        const bufferArr = ungzip(buffer);
        const json = new TextDecoder("utf8").decode(bufferArr);
        return JSON.parse(json);
    } catch (err) {
        console.error(err);
        return null;
    }
}

export const getUnitDetails = (serialNumber) => {
    return new Promise((resolve, rej) => {
        let url = `${UnitInfo.list}`;
        let pageNumber = "?pageNum=1";
        let pageLimit = "&pageSize=1";
        let searchParam = {
            serialNumber: serialNumber,
        };
        url = `${url}${pageNumber}${pageLimit}&searchParams=${btoa(JSON.stringify(searchParam))}`;
        http.get(url, {
            headers: {
                "Content-Type": "application/json",
                cgid: localStorage.getItem("custGroupID"),
                cid: CUSTOMER_ID,
                brandid: BRAND_ID,
                model: "",
                machinetype: "",
                sortby: "",
                sorttype: "",
            },
            data: {},
            params: {
                compress: true,
            },
        })
            .then((res) => {
                if (res?.data?.units?.length) {
                    let returnObj = {
                        units: {},
                        location: {},
                    };
                    let { units } = res?.data;
                    if (res.data.compressed) {
                        units = decompressData(units) || [];
                    }
                    units = UnitBuilder(units);

                    if (units.length) {
                        returnObj["units"] = units[0];
                        if (units[0] != null) {
                            let dynastyAttributes = units[0].dynastyAttributes ? JSON.parse(units[0].dynastyAttributes) : {};
                            if (dynastyAttributes != null) {
                                dynastyAttributes = SetLocationImage(dynastyAttributes);
                                returnObj["location"] = dynastyAttributes;
                            }
                        }
                    }
                    resolve(returnObj);
                } else {
                    resolve(res);
                }
            })
            .catch((err) => {
                rej(err);
            });
    });
}

export const getConfigurationDetails = (unitDetails) => {
    return new Promise((resolve, rej) => {
        // resolve(xpressChefData)
        if (!unitDetails || !Object.keys(unitDetails).length) {
            resolve({});
            return;
        }
        let url = `${dashboardRenderer.getConfiguration}`;
        const stringified = queryString.stringify({
            "modelFamily": unitDetails.MODEL_FAMILY_NAME,
            "model": unitDetails.MODEL_NAME,
            "brand": unitDetails.BRAND_NAME
        });
        url = url + (stringified ? `?${stringified}` : '');
        http.get(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            data: {}
        }).then(async (res) => {
            const decrypted = await decriptData(res?.data?.data);
            const parsedResponse = JSON.parse(decrypted);
            console.log("parsedResponse", parsedResponse);
            resolve(parsedResponse);
        }).catch(err => {
            rej(err);
        });
    })
}