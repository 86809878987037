import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import { v1 as uuidv1 } from 'uuid';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

class PreHeat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            preheat_list: [],
            disableAddPreheat: false,
            selected_unit: '',
            preheatParamValue: 'en_US'
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onTemperatureBandClick = this.onTemperatureBandClick.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.addPreheatSettings = this.addPreheatSettings.bind(this);
        this.onPreheatDelete = this.onPreheatDelete.bind(this);
        this.handleParamChange = this.handleParamChange.bind(this);
        this.handleParamSubmit = this.handleParamSubmit.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.validatePreheatValue = this.validatePreheatValue.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let jsonSettingsFile = localStorage.getItem("librarySettingsData");
            let jsonTemperature = JSON.parse(jsonSettingsFile).preheat_list;
            let jsonSettingsObj = JSON.parse(jsonSettingsFile);
            const preheatParamValue = jsonTemperature && jsonTemperature.length ? Object.keys(jsonTemperature[0].name)[0] : "en_US"

            this.setState({
                preheat_list: jsonTemperature ? jsonTemperature : [],
                disableAddPreheat: jsonTemperature && jsonTemperature.length > 4 ? true : false,
                selected_unit: jsonSettingsObj.units.replace('deg', '° '),
                preheatParamValue
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onTemperatureBandClick = (e) => {
        this.props.history.push({
            pathname: "/temperatureBand",
            isEdit: false,
            type: "Settings",
            previouspath: "/preHeat"
        });
    }

    addPreheatSettings = (e) => {
        this.setState((prevState) => ({
            preheat_list: [...prevState.preheat_list, {
                // id: "",
                name: {
                    [this.state.preheatParamValue]: "Preheat OFF"
                },
                set_point: {
                    value: 'OFF',
                    units: ""
                }
            }],
        }));
        if (this.state.preheat_list.length > 4) {
            this.setState({
                disableAddPreheat: true
            });
        }
    }

    onPreheatDelete = (e) => {
        let id = e.target.id.replace('preheatDelete-', '');
        if (id) {
            let splicedata = this.state.preheat_list;
            splicedata.splice(Math.round(id), 1);
            this.setState({
                preheat_list: splicedata
            });
            if (splicedata.length <= 6) {
                this.setState({
                    disableAddPreheat: false
                });
            }
        }
    }

    onConfirmButtonClick = (page) => {
        this.setState({
            loaderIconVisible: true
        });
        const modelFamilyID = {
            'dev' : '81',
            'other': '41'
        }
        const hostname = window && window.location && window.location.hostname;
        let backendHost = hostname === 'www.welbiltdigitaldev.com' || hostname === 'welbiltdigitaldev.com' ? 'dev' : 'other';

        const maxCelsiusValue = localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? 260 : 275;
        const maxFarenheitValue = localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? 500 : 527;
        let { preheat_list, selected_unit } = this.state;
        let isValidValueInput = true;
        let isValidTextInput = true;
        if (preheat_list.length > 0) {
            preheat_list.map((item, i) => {
                let set_point = item.set_point.value === '' ? item.set_point.value : item.set_point.value && item.set_point.value !== 'OFF' ? parseFloat(item.set_point.value) : 0;
                isValidValueInput = isValidValueInput && set_point !== '' &&
                ((selected_unit === '° C' && ((set_point >= 100 && set_point <= maxCelsiusValue) || set_point === 0)) || 
                (selected_unit === '° F' && ((set_point >= 212 && set_point <= maxFarenheitValue) || set_point === 0))) ? true : false;
                isValidTextInput = isValidTextInput && item.name[this.state.preheatParamValue];
            });

            if (!isValidTextInput) { 
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "Preheat name should be atleast 1 and not more than 20 characters."
                    }
                });
            }
            else if (isValidValueInput) {
                let jsonSettingsFile = localStorage.getItem("librarySettingsData");
                let ObjSettingsFile = JSON.parse(jsonSettingsFile);
                ObjSettingsFile.preheat_list = [];
                // ObjSettingsFile.preheat_times = [];
                preheat_list.map((item, i) => {
                    // let currentid = uuidv1();
                    ObjSettingsFile.preheat_list[i] = {};
                    // ObjSettingsFile.preheat_list[i].id = currentid;
                    ObjSettingsFile.preheat_list[i].name = {};
                    ObjSettingsFile.preheat_list[i].name[this.state.preheatParamValue] = item.name[this.state.preheatParamValue];
                    ObjSettingsFile.preheat_list[i].set_point = {};
                    ObjSettingsFile.preheat_list[i].set_point.value = item.set_point.value && item.set_point.value !== 'OFF' ? parseFloat(item.set_point.value) : 0;
                    ObjSettingsFile.preheat_list[i].set_point.units = ObjSettingsFile.units ? ObjSettingsFile.units : "degC";

                    // ObjSettingsFile.preheat_times[i] = {};
                    // ObjSettingsFile.preheat_times[i].preheat_id = currentid;
                })
                if(ObjSettingsFile.preheat_times){delete ObjSettingsFile.preheat_times;}
                let strSettingsFile = JSON.stringify(ObjSettingsFile);
                localStorage.setItem("librarySettingsData", strSettingsFile);
                this.props.history.push(`/`+page);
            }
            else if (page === "temperatureBand") {
                this.props.history.push(`/`+page);
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: selected_unit === "° C" ?  localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? "KC2292" : "KC2028" :  localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? "KC2293" : "KC2029"
                    }
                });
            }
        }
        else {
            if (page === "temperatureBand") {
                this.props.history.push({
                    pathname: "/temperatureBand",
                    isEdit: false,
                    type: "Settings",
                    previouspath: "/preHeat"
                });
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC2030"
                    }
                });
            }
        }
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/preHeat"
        });
    }

    handleParamChange = (e) => {
        const { selected_unit } = this.state;
        if (["form-control preheat_name"].includes(e.target.className)) {
            let preheat_list = [...this.state.preheat_list];
            // let name = e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$") ? e.target.value : "";
            let name = e.target.value;
            preheat_list[e.target.dataset.id].name[this.state.preheatParamValue] = name;
            this.setState({ preheat_list: preheat_list })
        }
        else if (["form-control preheat_value"].includes(e.target.className)) {
            let preheat_list = [...this.state.preheat_list];
            if (e.target.value === 'OF' || e.target.value === 'O' || e.target.value === '') {
                preheat_list[e.target.dataset.id].set_point.value = e.target.value;
            }
            else {
                let tempvalue = e.target.value.match(/[+-]?([0-9]*[.])?[0-9]+/) ? parseFloat(e.target.value) : 0;
                preheat_list[e.target.dataset.id].set_point.value = tempvalue > 0 ? selected_unit.includes('F') && tempvalue === 32 ? 'OFF' : tempvalue : 'OFF';
            }
            this.setState({ preheat_list: preheat_list })
        } else {
            this.setState({ [e.target.name]: e.target.value.toUpperCase() })
        }
    }

    handleParamSubmit = (e) => { e.preventDefault() }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    _onFocus(id, value) {
        if(value === "" || value === 'Preheat OFF' || value === 'OFF') {
            document.getElementById(id).value = "";
        }
    }

    validatePreheatValue = (e) => {
        const modelFamilyID = {
            'dev': '81',
            'other': '41'
        }
        const hostname = window && window.location && window.location.hostname;
        let backendHost = hostname === 'www.welbiltdigitaldev.com' || hostname === 'welbiltdigitaldev.com' ? 'dev' : 'other';
        const maxCelsiusValue = localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? 260 : 275;
        const maxFarenheitValue = localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? 500 : 527;
        let { preheat_list, selected_unit } = this.state;
        let isValidValueInput = true;

        if (preheat_list.length > 0) {
            let set_point;
            preheat_list.map((item, i) => {
                set_point = item.set_point.value === '' ? item.set_point.value : item.set_point.value && item.set_point.value !== 'OFF' ? parseFloat(item.set_point.value) : 0;
                isValidValueInput = isValidValueInput && set_point !== '' &&
                    ((selected_unit === '° C' && ((set_point >= 100 && set_point <= maxCelsiusValue) || set_point === 0)) ||
                        (selected_unit === '° F' && ((set_point >= 212 && set_point <= maxFarenheitValue) || set_point === 0))) ? true : false;
            });

            if (!isValidValueInput) {
                if (selected_unit === '° C') {
                    preheat_list[e.target.dataset.id].set_point.value = (set_point === 0 ? "OFF" : set_point !== 'OFF' ? set_point < 100 ? 100 : set_point > maxCelsiusValue ? maxCelsiusValue : set_point : set_point);
                }
                else {
                    preheat_list[e.target.dataset.id].set_point.value = (set_point === 0 ? "OFF" : set_point !== 'OFF' ? set_point < 212 ? 212 : set_point > maxFarenheitValue ? maxFarenheitValue : set_point : set_point);
                }
                this.setState({
                    loaderIconVisible: false,
                    preheat_list: preheat_list,
                    error_modal: {
                        open: true,
                        message: selected_unit === "° C" ? localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? "KC2292" : "KC2028" : localStorage.getItem('selectedFamilyID') === modelFamilyID[backendHost] ? "KC2293" : "KC2029"
                    }
                });
            }
        }
    }

    render() {
        let { preheat_list, disableAddPreheat, selected_unit, error_modal, success_modal } = this.state;
        let brandid = localStorage.getItem("selectedbrandID");
        const units = selected_unit;
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1286"/>
				                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsList">
                                                    <ul onClick={() => { this.onConfirmButtonClick('temperatureBand') }} >
                                                        <li>&nbsp;</li>
                                                        <li><FormattedMessage id="KC1287"/></li>
                                                        <li><img src={require("../../../../../images/recipecreation/rightArrow.png")}  alt=""/></li>
                                                    </ul>
                                                </div>
                                                <div class="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            {
                                                                preheat_list.map((val, idx) => {
                                                                    let preheatnameid = `preheatname-${idx}`, preheatvalueid = `preheatvalue-${idx}`, preheatDeleteid = `preheatDelete-${idx}`
                                                                    return (
                                                                        <form class="rcPreheatForm" onSubmit={this.handleParamSubmit} onChange={this.handleParamChange} key={idx}>
                                                                            <ul>
                                                                                <li>
                                                                                    <div class="rcPreheatTable">
                                                                                        <ul>
                                                                                            <li><input type="text" maxLength="20" name={preheatnameid}
                                                                                                data-id={idx} onFocus={(e) => this._onFocus(preheatnameid, e.target.value)} value={val.name[this.state.preheatParamValue]} placeholder={formatMessage({ id: 'KC0241' })}
                                                                                                id={preheatnameid} class="form-control preheat_name" /></li>
                                                                                            <li><input type="text" maxLength="7" name={preheatvalueid}
                                                                                                data-id={idx} onFocus={(e) => this._onFocus(preheatvalueid, e.target.value)} onBlur={ (e) => this.validatePreheatValue(e)} value={val.set_point.value !== 0 ? val.set_point.value : 'OFF'} placeholder={formatMessage({ id: 'KC0142' })}
                                                                                                id={preheatvalueid} class="form-control preheat_value" /></li>
                                                                                            <li>{units}</li>
                                                                                        </ul>
                                                                                    </div>
                                                                                </li>
                                                                                <li>
                                                                                    <button name={preheatDeleteid}
                                                                                        data-id={idx}
                                                                                        id={preheatDeleteid} onClick={(e) => { this.onPreheatDelete(e) }} type="button" class={preheat_list.length === 1 ? "btn btn-default-text deleteButton disabled" : "btn btn-default-text deleteButton"}>&nbsp;</button>
                                                                                </li>
                                                                            </ul>
                                                                        </form>
                                                                    )
                                                                })
                                                            }
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick('settingsEditor') }} class="btn btn-default-text confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                    <button type="button" onClick={this.addPreheatSettings} class={disableAddPreheat ? "btn btn-default-text displaynone" : "btn btn-default-text addButton"}>&nbsp;</button>&nbsp;
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={() => this.onSuccessCloseModal()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(PreHeat)
