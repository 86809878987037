import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, MenuUploadAPI } from '../../../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../../../service/httpService';
import _ from 'lodash';
import { setUserDataModel } from '../../../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import { injectIntl } from 'react-intl';
import ViewTopology from '../../../../../components/controls/viewTopology';
var recipeJSON = require('../../Templates/RecipeJSON');
var beautify = require("json-beautify");
var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

class AssignLibraryToTopology extends Component {
    onSuccessCloseModal = () => {
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"))
        if (libraryDetails.recipeType === "ReadyRecipe") {
            this.props.history.push(`/readyRecipeLibraryList`);
        }
        else {
            this.props.history.push(`/libraryList`);
        }
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/libraryList`);
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    componentWillMount = () => {
        let { previouspath } = this.props.location;
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        this.setState({
            previouspath: previouspath,
            recipeName: libraryDetails.recipeName ? libraryDetails.recipeName : "",
            imageData: libraryDetails.imageData ? libraryDetails.imageData : "",
            imageThumbnailb64: localStorage.getItem("libraryImage"),
            isEdit: libraryDetails.isEdit ? libraryDetails.isEdit : false,
            isImageChanged: libraryDetails.isImageChanged ? libraryDetails.isImageChanged : false
        });
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader )) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        if (libraryDetails.initialMethod === "Upload") {
            this.props.history.push({
                pathname: "/uploadSelectDevice",
                previouspath: "/assignLibraryToTopology"
            });
        }
        else {
            this.props.history.push({
                pathname: "/recipeCreationSave",
                previouspath: "/assignLibraryToTopology"
            });
        }
    }

    submitAddLibrary = (e,_customId) => {
        e.preventDefault();
        let _this = this;
        let httpMethod;
        let imageS3Url = _this.state.imageData ? _this.state.imageData.imageS3Url : "";
        
            _this.setState({
                loaderIconVisible: true
            })

            let brandName = localStorage.getItem("brandName");
            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
					'base_country': localStorage.getItem("basecountry"),
					'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(response => {
                let libraryId;
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                libraryId = libraryDetails.library_id;
                if (_this.state.isEdit) {
                    httpMethod = "PUT";
                } else {
                    httpMethod = "POST";
                }
                 
                let jsonTemplateFile = recipeJSON;
                let jsonTemplateObj = jsonTemplateFile.default;
                let libraryData = localStorage.getItem("libraryData");
                let libraryDataObj = JSON.parse(libraryData);
                jsonTemplateObj.id = _this.state.isEdit ? libraryDetails.id : uuidv1();
                jsonTemplateObj.edit_date = todaysDateUTCFormatted;
                jsonTemplateObj.author = response.data.name;
                jsonTemplateObj.email = loginUser.username;
                jsonTemplateObj.name.en_GB = _this.state.recipeName;
                jsonTemplateObj.image = _this.state.imageData.imagefileext ? "Resources/Images/" + libraryId + '.' + _this.state.imageData.imagefileext : "";
                
                delete libraryData.Import;
                jsonTemplateObj.settings = {};
                jsonTemplateObj.settings.portion = libraryDetails.settings ? parseInt(libraryDetails.settings.portion) : 1;
                jsonTemplateObj.settings.state = libraryDetails.settings ? libraryDetails.settings.state : "ambient";
                jsonTemplateObj.settings.weight = libraryDetails.settings ? parseInt(libraryDetails.settings.weight) : 50;

                let imageFileSize = _this.state.imageData.imagefileSize ? _this.state.imageData.imagefileSize : 0;
                jsonTemplateObj.ordered_steps = [];
                libraryDataObj.map((item, i) => {
                    jsonTemplateObj.ordered_steps[i] = {};
                    jsonTemplateObj.ordered_steps[i].duration = "PT" + item.time.split(':')[0] + "M" + item.time.split(':')[1] + "S";
                    //Temperature
                    jsonTemplateObj.ordered_steps[i].actions = {};
                    jsonTemplateObj.ordered_steps[i].actions.set_point = {};
                    jsonTemplateObj.ordered_steps[i].actions.set_point.value = item.temp ? parseInt(item.temp.replace('F', '').replace('C', '')) : 0;
                    jsonTemplateObj.ordered_steps[i].actions.set_point.units = item.temp.includes('C') ? "degC" : "degF";
                    //FanSpeed
                    jsonTemplateObj.ordered_steps[i].actions.fan = item.fan ? parseInt(item.fan): 0;
                    //Power
                    jsonTemplateObj.ordered_steps[i].actions.microwave = item.power ? parseInt(item.power) : 0;
                    //Prompt
                    jsonTemplateObj.ordered_steps[i].actions.prompt = {};
                    jsonTemplateObj.ordered_steps[i].actions.prompt.image = item.instructionImage ? item.instructionImage : "";
                    imageFileSize = imageFileSize + (item.instructionImageSize ? parseFloat(item.instructionImageSize) : 0);
                    //Prompt Message
                    jsonTemplateObj.ordered_steps[i].actions.prompt.message = {};
                    jsonTemplateObj.ordered_steps[i].actions.prompt.message.en_GB = item.instruction;
                });

                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);

                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": _this.state.recipeName,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + parseFloat(imageFileSize ? imageFileSize : 0)).toFixed(2),
                    "libraryS3Url": "",
                    "type": libraryDetails.recipeType,
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": _this.state.imageData.crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": _this.state.imageData.imagefileext ? libraryId + '.' + _this.state.imageData.imagefileext : "",
                    "imageFileFormat": _this.state.imageData.imagefileext,
                    "imageFileSize": imageFileSize,
                    "imageS3Url": imageS3Url,
                    "cgids": _customId,
                    "json": JSON.stringify(jsonTemplateObj),
                    "libraryGuid": jsonTemplateObj.id
                };

                if (_this.state.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': '',
                    'jsonfilekey': libraryId + '.json',
                    'audiofilekey': '',
                    'isthumbnail': false
                } 

                uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                    _this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: (httpMethod === "PUT" && libraryDetails.initialMethod === "Edit") ? "Recipe updated successfully." : "Recipe saved successfully."
                        },
                    });
                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log('catch exception occured');
            });
        
    }

    changeLoadingStatus=(value)=>{
        this.setState({loaderIconVisible:value})
    }

    render() {
        let { success_modal, error_modal } = this.state;
        const { loaderIconVisible } = this.state;
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology 
                    title={`KC1235`} 
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    onBackClickHandler={this.onBackClickHandler} 
                    modelFamilyIds={localStorage.getItem("selectedFamilyID")}  
                    preSelect={libraryDetails.isEdit ? true : false}
                    restrictUnitSelect={true} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader  headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignLibraryToTopology));