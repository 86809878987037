import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import $ from 'jquery';

var recipeJSON = require('../../Templates/Garland/GarlandRecipeJSON');

class GarlandRecipeEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.reciperedirect = this.reciperedirect.bind(this);
        this.cookbookredirect = this.cookbookredirect.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
    }

    reciperedirect = () => {
        localStorage.setItem("selectedGroupID", 0);
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.isImport = false;
        libraryDetails.initialMethod = "Add";
        localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
        localStorage.setItem("defaultLibraryData", JSON.stringify(recipeJSON.default));
        localStorage.setItem("libraryData", JSON.stringify(recipeJSON.default));
        this.props.history.push({
            pathname: "/garlandRecipeCreation",
            isEdit: false,
            previouspath: "/garlandRecipeEditor"
        });
    }

    cookbookredirect = () => {
        localStorage.setItem("selectedGroupID", 0);
        localStorage.setItem("resourceDetails", "[]");
        localStorage.setItem("selectedCategory", "{}");
        localStorage.setItem("ProductRecgDetails", "{}");
        localStorage.setItem("selectedPRRecipes", "{}");
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.isImageChanged = false;
        libraryDetails.cookbookName = "";
        libraryDetails.initialMethod = "Add";
        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
        localStorage.setItem("defaultLibraryData", "{}");
        localStorage.setItem("schedulerDetails", "{}");
        localStorage.setItem("libraryData", "{}");
        this.props.history.push({
            pathname: "/garlandCookbookCreation",
            isEdit: false,
            previouspath: "/garlandRecipeEditor"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, loaderIconVisible } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = localStorage.getItem("selectedbrandID");

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658" /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180" /></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li className="selectedModel">ME, MG</li>
                                {/* <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button className="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li> */}
                            </ul>
                        </div>
                        <div className="rcDeviceWrapper">
                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} alt="" /></div>
                            <div className="rEWrapper">
                                <div className="recipeEditorH">
                                    &nbsp;
                                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn">
                                                <div className="recipeEditorGrid garlandRC">
                                                    <ul>
                                                        <li>
                                                            <button type="button" onClick={(e) => { this.reciperedirect(e) }} className="btn recipeCreationNav" title={formatMessage({ id: 'KC1211' })}><FormattedMessage id="KC1211"/></button> 
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={(e) => { this.cookbookredirect(e) }} className="btn cookbookNav" title={formatMessage({ id: 'KC1340' })}><FormattedMessage id="KC1340"/></button> 
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="rEeditorF">
                                    <span className="easyTouchLogo"><img src={require("../../../../../images/recipecreation/eacyTouchLogo.png")} /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(GarlandRecipeEditor)
