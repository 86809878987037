import commonFunctions from './common.function';
import mercoFunctions from './merco.function';
import xpresschfFunctions from './xpresschef.function';
import iomFunctions from './iom.function';
import egroFunctions from './egro.function';

const clientFunctions = {
    COMMON: commonFunctions,
    MERCO: mercoFunctions,
    XPRESSCHEF: xpresschfFunctions,
    IOM: iomFunctions,
    EGRO: egroFunctions
};

export default clientFunctions;
