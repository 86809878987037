import $ from 'jquery';
import _ from 'lodash';
import React, { Component } from 'react';
import SuccessMoal from '../modal/success-model';
import ErrorMoal from '../modal/error-model';
import { DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';
import { FormattedMessage } from 'react-intl';

let headers = {
    'Content-Type': 'application/json',
};

export default class ProfileCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            newDevice: {},
            success_modal: {
                open: false,
                message: "",
            },
            error_modal: {
                open: false,
                message: "",
                isHtml: false
            },
        };
    }

    componentDidMount() {
        $('body').addClass("loginBg");
        const details = localStorage.getItem('deviceDetails') ? JSON.parse(localStorage.getItem('deviceDetails')) : {};
        var urlParams = new URLSearchParams(window.location.search);
        const customerId = urlParams.get("customer_id");
        const subscriptionId = urlParams.get("subscription_id");

        // if (!details.registrationCode && !details.serialNumber) {
        //     this.setState({ loaderIconVisible: false, error_modal: { open: true, message: 'Invalid way of registration',isHtml:false } });
        //     return;
        // }

        let postData = {
            "registrationCode": details.registrationCode || "",
            "serialNumber": details.serialNumber || "",
            "brandName": details.BRAND_NAME || "",
            "brandId": details.BRANDID || "",
            subscriptionId,
            "resourceType": "customers",
            "resourceId": customerId,
            "errorFlag": false
        };

        http({
            method: "POST",
            url: DeviceRegistration.chargifySubscriptionCreation,
            headers,
            data: postData
        }).then(async response => {
            if (response.data.httpCode === 'KC_ERR_200') {
                this.setState({ loaderIconVisible: false, success_modal: { open: true, message: 'Thanks for registration. You will receive confirmation through email.' } });
            } else {
                this.setState({ loaderIconVisible: false, error_modal: { open: true, message: '<div class="errorModalContent"><span style="color:red;">Something went wrong !!</span> We are not able to complete your registration. Please try again.</div>', isHtml: true } });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false, error_modal: { open: true, message: '<div class="errorModalContent"><span style="color:red;">Somethings went wrong !!</span> We are not able to complete your registration. Please try again.</div>', isHtml: true } });
        });
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            },
        })
        if(localStorage.getItem('isCookieAccepted')){
            localStorage.clear();
            localStorage.setItem('isCookieAccepted', true);
        }else{
            localStorage.clear();
        }
        this.props.history.push('/register-unit')
    }

    onSuccessCloseModal = async (e) => {
        this.setState({
            success_modal: {
                open: false,
            },
        });
        if(localStorage.getItem('isCookieAccepted')){
            localStorage.clear();
            localStorage.setItem('isCookieAccepted', true);
        }else{
            localStorage.clear();
        }
        this.props.history.push('/')
    }


    render() {
        const { loaderIconVisible, error_modal, success_modal } = this.state;

        return (
            <div id="adminWrapper">
                <div class="toggleTransparent"></div>
                <div class="sideNavToggleTransparent"></div>
                <div class="userToggleTransparent"></div>
                <div id="page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav class="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <FormattedMessage id='KC0038'>
                        {placeholder=><a class="navbar-brand welbiltLogo" title={placeholder} href="/">&nbsp;</a>}
                    </FormattedMessage>
                </nav>
                <div id="page-content-wrapper">



                </div>
                <ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} isHtml={error_modal.isHtml} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </div>
        )
    }
};
