import React, { Component } from "react";
import { PanVsTimeDropDown } from "../../controls/panVsTimeDropDown";
import { format } from "date-fns";
import $ from "jquery";
import http from "../../../service/httpService";
import { BaseService, ReportManagement } from "../../../service/api";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from "lodash";
import { FormattedMessage } from 'react-intl';
import { reactIntl } from '../../../locale/locale-provider';

var moment = require("moment");

// common global parameters
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, "YYYY-MM-DD");
let unitID = "";
let brandID = "";
let currentUnit;

class UHCTimeLinechartwidget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            PanType: "Day",
        };
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID
            ? currentUnit.UNITID
            : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = localStorage.getItem("brandName");
        this.getPanvsTimelinedata(
            "Day",
            todaysDateFormatted,
            todaysDateFormatted,
            unitID,
            brandID
        );
    };

    getPanvsTimelinedata = (type, fromdate, todate, deviceId, UNIT_BRAND_ID) => {
        let filterType;
        switch (type) {
            case "Day":
                filterType = "daily";
                break;
            default:
                filterType = "weekly";
        }

        const header = {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            brandid: UNIT_BRAND_ID,
            unitid: deviceId,
            filtertype: filterType,
            startdate: fromdate,
            enddate: todate,
            reqdate: todate,
        };

        http({
            method: "GET",
            url: `${BaseService.root}${ReportManagement.uhcPanVsTimeLineChart}`,
            headers: header,
            data: {},
        })
            .then((response) => {
                const panVsTimeResponse = response.data;
                if (
                    panVsTimeResponse &&
                    panVsTimeResponse.body &&
                    panVsTimeResponse.httpCode === 200
                ) {
                    $("#panChartDiv").removeClass("noRecordMsg");
                    $("#panChartlegend").removeClass("displaynone");
                    $("#panChartDiv").addClass("colmDi panStates");
                    $("#panChartlegend").addClass("legendSection");
                   this.renderPanStatesVsTime(type, panVsTimeResponse.body.timelineData);
                } else if (panVsTimeResponse.httpCode === 404) {
                    $("#panChartDiv").removeClass("colmDi panStates");
                    $("#panChartlegend").removeClass("legendSection");
                    $("#panChartDiv").addClass("noRecordMsg");
                    $("#panChartlegend").addClass("displaynone");
                    $("#panChartDiv").html(panVsTimeResponse.body && panVsTimeResponse.body.stringID ? reactIntl.formatMessage({id: panVsTimeResponse.body.stringID }) : panVsTimeResponse.body.message); 
                } else if (
                    panVsTimeResponse.DBMIG_ERR ===
                    "Database tables and code are not in sync."
                ) {
                    $("#panChartDiv").removeClass("colmDi panStates");
                    $("#panChartlegend").removeClass("legendSection");
                    $("#panChartDiv").addClass("noRecordMsg");
                    $("#panChartlegend").addClass("displaynone");
                    $("#panChartDiv").html(panVsTimeResponse.DBMIG_ERR);
                }
            })
            .catch((err) => {
                console.log("catch exception occured");
            });
    };

    onChangePanvsTime(text) {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID
            ? currentUnit.UNITID
            : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = localStorage.getItem("brandName");
        this.setState({ PanType: text });
        $("#panChartDiv").html("");
        $("#panChartlegend").addClass("displaynone");

        if (text === "Week") {
            let day = todaysDate.getDay(),
                diff = todaysDate.getDate() - day + (day === 0 ? -6 : 1);
            let startofweekday = new Date(todaysDate.setDate(diff));
            startofweekday = format(startofweekday, "YYYY-MM-DD");
            this.getPanvsTimelinedata(
                "weekly",
                startofweekday,
                todaysDateFormatted,
                unitID,
                brandID
            );
        } else {
            this.getPanvsTimelinedata(
                "Day",
                todaysDateFormatted,
                todaysDateFormatted,
                unitID,
                brandID
            );
        }
    }

    //Method to generate TimeLineChart
    renderPanStatesVsTime = (filterType, responsedata) => {
        if (responsedata && responsedata.length > 0) {
            am4core.useTheme(am4themes_animated);
            let chart = am4core.create("panChartDiv", am4charts.XYChart);
            this.chart = chart;
            // Add vertical scrollbar
            chart.scrollbarX = new am4core.Scrollbar();
            chart.scrollbarX.marginLeft = 0;
            chart.scrollbarX.dragIconHeight = 1;
            chart.scrollbarX.dragIconWidth = 1;
            chart.scrollbarX.marginBottom = 30;

            function customizeGrip(grip) {
                grip.showSystemTooltip = false;
                grip.icon.disabled = true;
                grip.background.disabled = true;
                grip.background.strokeOpacity = 0;
                let img = grip.createChild(am4core.Circle);
                img.width = 25;
                img.height = 25;
                img.fill = am4core.color("#327ed0");
                img.align = "center";
                img.valign = "middle";
            }

            chart.scrollbarX.thumb.showSystemTooltip = false;
            chart.scrollbarX.thumb.background.fill = am4core.color("#195d99");
            chart.scrollbarX.thumb.background.fillOpacity = 1;
            chart.scrollbarX.thumb.background.stroke = am4core.color("#195d99");
            chart.scrollbarX.thumb.background.strokeWidth = 0;
            chart.scrollbarX.thumb.background.strokeOpacity = 0.8;
            chart.scrollbarX.thumb.background.states.getKey(
                "hover"
            ).properties.fill = am4core.color("#195d99");
            chart.scrollbarX.thumb.background.states.getKey(
                "down"
            ).properties.fill = am4core.color("#195d99");

            customizeGrip(chart.scrollbarX.startGrip);
            customizeGrip(chart.scrollbarX.endGrip);

            chart.cursor = new am4charts.XYCursor();
            chart.hiddenState.properties.opacity = 1; // this creates initial fade-in
            chart.fillColor = "#fff";
            chart.paddingRight = 30;
            let colorSet;
            if (filterType === "Day") {
                chart.dateFormatter.inputDateFormat = "MM/dd/yyyy, hh:mm a";
                chart.fill = am4core.color("#ffffff");
                colorSet = new am4core.ColorSet();
                colorSet.saturation = 1;
            } else {
                chart.fill = am4core.color("#ffffff");
            }
            var errorData = responsedata.filter(function (data) {
                return data.name === "Error";
            });
            var errorGroups = _.groupBy(errorData, function (value) {
                return value.fromDate;
            });
            var groupsdata = _.map(errorGroups, function (group) {
                var errDesc = "";
                group.forEach((item) => {
                    errDesc +=
                        item.task +
                        " : " +
                        moment(item.fromDate).format("MM/DD/YYYY, h:mm A");
                    if (item.toDate !== null) {
                        errDesc += " - " + moment(item.toDate).format("MM/DD/YYYY, h:mm A");
                    }
                    errDesc += "\n";
                });
                return {
                    fromDate: group[0].fromDate,
                    toDate: group[0].toDate,
                    name: "Error",
                    panSize: group[0].panSize,
                    bullet: require("../../../images/icons/error.png"),
                    task: errDesc,
                    color: "rgba(97,140,206,0.0)",
                };
            });
            
            var responsedata = _.remove(responsedata, function (data) {
                return data.name !== "Error";
            });

            groupsdata.forEach((item) => {
                responsedata.push(item);
            });

            responsedata.forEach((item) => {
                item.strokeOpacity = 1;
                if (item.name.toLowerCase() === "error") {
                    item.strokeOpacity = 0;
                }
            });

            chart.data = [...responsedata];
            chart.dateFormatter.dateFormat = "MM/dd/yyyy, hh:mm a";
            chart.dateFormatter.inputDateFormat = "MM/dd/yyyy, hh:mm a";
            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
            categoryAxis.renderer.labels.template.fontSize = 12;
            categoryAxis.renderer.line.strokeOpacity = 0.5;
            categoryAxis.renderer.line.strokeWidth = 0.5;
            categoryAxis.renderer.line.stroke = am4core.color("#fff");
            categoryAxis.dataFields.category = "name";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.inversed = true;
            categoryAxis.renderer.cellStartLocation = 0.1;
            categoryAxis.renderer.cellEndLocation = 0.9;
            categoryAxis.renderer.minGridDistance = 10;

            function createRange(from, to, label) {
                var range = categoryAxis.axisRanges.create();
                range.category = from;
                range.endCategory = to;
                range.label.text = label;
                range.label.paddingTop = splitedRange.length > 3 ? 63 : 132;
                range.label.paddingRight = 50;
                range.label.location = 0;
                range.label.horizontalCenter = "middle";
                range.label.fontWeight = "bolder";
                range.grid.disabled = true;
            }

            function createRangeGrid(date) {
                var range = categoryAxis.axisRanges.create();
                range.category = date;
                range.grid.strokeOpacity = 0.2;
                range.tick.disabled = false;
                range.tick.strokeOpacity = 0.5;
                range.tick.length = 90;
                range.tick.stroke = am4core.color("#FFFFFF");
                range.tick.paddingTop = splitedRange.length > 3 ? 12 : 20;
            }

            let labelNames = chart.data.map(function (el) {
                return el.name;
            });
            let uniqueLabel = [...new Set(labelNames)];

            const splitedRange = new Array(Math.ceil(uniqueLabel.length / 3))
                .fill()
                .map((_) => uniqueLabel.splice(0, 3));

            splitedRange.forEach((e, i) => {
                if (e.length === 3) {
                    createRange(e[0], e[2], `Level ${parseInt(i) + 1}`);
                    createRangeGrid(e[2]);
                }
            });

            let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.labels.template.fill = am4core.color("#fff");
            if (filterType === "Day") {
                dateAxis.baseInterval = {
                    timeUnit: "hours",
                    count: 10,
                };
            }

            dateAxis.dataFields.category = "name";
            if (filterType === "Day") {
                dateAxis.dataFields.valueX = "hours";
            }
            dateAxis.renderer.labels.template.fontSize = 12;
            dateAxis.renderer.ticks.template.strokeOpacity = 1;
            dateAxis.renderer.line.strokeOpacity = 1;
            dateAxis.renderer.line.strokeWidth = 1;

            if (filterType === "weekly") {
                dateAxis.tooltipDateFormat = "dt MMM HH:mm a";
                dateAxis.dateFormatter.dateFormat = "MM/dd/yyyy, hh:mm a";
                dateAxis.renderer.line.stroke = am4core.color("#fff");
                dateAxis.renderer.minGridDistance = 70;
                dateAxis.baseInterval = { count: 1, timeUnit: "time" };
                dateAxis.periodChangeDateFormats.setKey("hour", "MMM dt");

                dateAxis.renderer.tooltipLocation = 0;
                let label = dateAxis.renderer.labels.template;
                label.wrap = true;
                label.maxWidth = 120;
                dateAxis.renderer.labels.template.adapter.add(
                    "text",
                    (label, target, key) => {
                        if (target.dataItem && target.dataItem.date) {
                            let md = moment(new Date(target.dataItem.date)).format("Do MMM");
                            let h = moment(new Date(target.dataItem.date)).format("h:mm A");
                            return md + "\n" + h;
                        }
                    }
                );
            } else {
                dateAxis.tooltipDateFormat = "hh:mm a";
                dateAxis.renderer.line.stroke = am4core.color("#fff");
                dateAxis.dateFormatter.dateFormat = "MM/dd/yyyy, hh:mm a";
                dateAxis.dateFormatter.inputDateFormat = "MM/dd/yyyy, HH:mm a";
                dateAxis.renderer.minGridDistance = 70;
                dateAxis.baseInterval = { count: 1, timeUnit: "minute" };
                dateAxis.dateFormats.setKey("hour", "hh a");
                dateAxis.renderer.tooltipLocation = 10;
                dateAxis.renderer.labels.template.adapter.add(
                    "text",
                    (label, target, key) => {
                        if (target.dataItem && target.dataItem.date) {
                            return moment(new Date(target.dataItem.date)).format("h:mm A");
                        }
                    }
                );
            }

            chart.scrollbarX.start = dateAxis.start;
            chart.scrollbarX.end = dateAxis.end;

            let series1 = chart.series.push(new am4charts.ColumnSeries());
            series1.dataFields.openDateX = "fromDate";
            series1.dataFields.dateX = "toDate";
            series1.name = "Idle";
            series1.dataFields.categoryY = "name";
            let withoutErrorTooltip =
                "{task} 1/3 {name} : {openDateX}[/] - {dateX}[/]";
            let withErrorTooltip = "{task}";
            chart.data.forEach((item) => {
                if (item.name !== "") {
                    item.task = item.task
                        .toLowerCase()
                        .split(" ")
                        .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                        .join(" ");
                    series1.columns.template.tooltipText = withoutErrorTooltip;
                }
            });

            series1.tooltip.label.adapter.add("text", function(text, target) {
                if (target.dataItem && target.dataItem._dataContext && target.dataItem._dataContext.panSize == -0.4) {
                  return "";
                }
                else {
                  return text;
                }
              });

            if (filterType === "Day") {
                series1.columns.template.dx = 0;
            }

            series1.columns.template.propertyFields.fill = "color"; // get color from data
            series1.columns.template.height = am4core.percent(100);
            series1.columns.template.propertyFields.stroke = "color";
            series1.columns.template.propertyFields.strokeOpacity = "strokeOpacity";
            series1.columns.template.stroke = "color";
            series1.columns.template.fillOpacity = 1;

            let lineSeries = chart.series.push(new am4charts.LineSeries());
            lineSeries.dataFields.categoryY = "name";
            lineSeries.dataFields.dateX = "fromDate";
            lineSeries.name = "";
            lineSeries.strokeWidth = 0;

            let bullet = lineSeries.bullets.push(new am4charts.Bullet());
            let image = bullet.createChild(am4core.Image);
            image.width = 15;
            image.height = 15;
            image.horizontalCenter = "middle";
            image.verticalCenter = "middle";
            image.propertyFields.href = "bullet";
            chart.data.forEach((item) => {
                image.tooltipText = withErrorTooltip;
            });
            if (filterType === "Day") {
                image.dx = 10;
            }

            // Add cursor
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.behavior = "zoomX";

            responsedata.forEach((item) => {
                let mergelegendName = item.task;
                mergelegendName = mergelegendName
                    .toLowerCase()
                    .split(" ")
                    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
                    .join(" ");
                if (item.panSize > 1 || item.panSize === -0.4) {
                    let panSize = item.panSize;
                    let number = item.name.split(" ").pop();
                    if (item.panSize === -0.4) {
                        mergelegendName =
                            mergelegendName +
                            " 1/2 " +
                            "Slot " +
                            (parseInt(number) - 1) +
                            " & " +
                            parseInt(number);
                    }
                    else if (item.panSize === 1.4 || item.panSize === 2) {
                        let pansizeName = panSize === 2 ? mergelegendName + " 2/3 " : mergelegendName + " 1/2 ";
                        mergelegendName =
                            pansizeName +
                            "Slot " +
                            parseInt(number) +
                            " & " +
                            (parseInt(number) + 1);
                    }
                    else if (item.panSize === 3) {
                        mergelegendName =
                            mergelegendName +
                            " 1/1 " +
                            "Slot " +
                            parseInt(number) +
                            ", " +
                            (parseInt(number) + 1) + 
                            " & " +
                            (parseInt(number) + 2);
                    }
                    
                    this.createFill(
                        chart,
                        categoryAxis,
                        item.name,
                        item.fromDate,
                        item.toDate,
                        am4core.color(item.color, 1),
                        item.panSize,
                        mergelegendName,
                        item
                    );
                }
            });
        }
    };

    //Generate Chart if panSize greater than 1
    createFill = (
        chart,
        categoryAxis,
        category,
        openDate,
        date,
        color,
        span,
        mergelegendName,
        item
    ) => {
        var fillAxis = chart.yAxes.push(new am4charts.CategoryAxis());
        fillAxis.dataFields.category = "name";
        fillAxis.renderer.grid.template.disabled = true;
        fillAxis.renderer.labels.template.disabled = true;
        fillAxis.renderer.inversed = true;
        fillAxis.renderer.cellStartLocation =
            categoryAxis.renderer.cellStartLocation;
        fillAxis.renderer.cellEndLocation =
            span - fillAxis.renderer.cellStartLocation;

        var fillSeries = chart.series.push(new am4charts.ColumnSeries());
        fillSeries.clustered = false;
        fillSeries.yAxis = fillAxis;
        fillSeries.columns.template.tooltipText = "{task} : {openDateX} - {dateX}";
        fillSeries.columns.template.height = am4core.percent(100);
        fillSeries.dataFields.openDateX = "fromDate";
        fillSeries.dataFields.dateX = "toDate";
        fillSeries.dataFields.categoryY = "name";
        fillSeries.fill = color;
        fillSeries.stroke = color;
        fillSeries.data = [
            {
                fromDate: openDate,
                toDate: date,
                name: category,
                task: mergelegendName,
            },
        ];

        fillSeries.dummyData = {
            name: mergelegendName,
        };
        fillSeries.adapter.add("name", function (name, target) {
            return target.dummyData.name;
        });
    };

    componentWillUnmount = () => {
        if (this.chart) {
            this.chart.dispose();
        }
    };

    render() {
        return (
            <React.Fragment>
                <h4>
                    {this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName} &nbsp; <span className="red-txt"></span>
                    <span className="cornerIcon"></span>
                    <PanVsTimeDropDown
                        translation={true}
                        OnChange={(e) => this.onChangePanvsTime(e.nodeText)}
                        PanType={this.state.PanType}
                    />
                </h4>
                <div id="panChartDiv" className="noRecordMsg"></div>
                <ul id="panChartlegend" className="displaynone">
                    <li>
                        <span></span><FormattedMessage id='KC0358'/>
          </li>
                    <li>
                        <span></span><FormattedMessage id='KC0359'/>
          </li>
                    <li>
                        <span></span><FormattedMessage id='KC0360'/>
          </li>
                    <li>
                        <span></span><FormattedMessage id='KC0361'/>
          </li>
                    <li>
                        <span></span><FormattedMessage id='KC1914'/> 
          </li>
                    <li>
                        <span></span><FormattedMessage id='KC0052'/>
          </li>
                </ul>
            </React.Fragment>
        );
    }
}

export default UHCTimeLinechartwidget;
