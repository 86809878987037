import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import ErrorMoal from '../modal/error-model';
import LoadingPopup from '../controls/loadingPopup';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import http from '../../service/httpService';
import { BaseService, ReportManagement } from '../../service/api';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import { format, addDays } from 'date-fns';
import { TableHeader } from '../controls/tableHeader';
import _ from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';

var moment = require('moment');

// common global parameters
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class UnitQualityPerformance extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'Month',
			filterType: "Month",
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			startDate: '',
			endDate: '',
			loaderIconVisible: false,
			qualityResponseData: [],
			IsServerError: false,
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			globalDropdownFilter: 'Month',
			globalDropdownFilterEndDate: '',
			globalDropdownFilterStartDate: '',
			datapickflag: false,
			datapickflagGlobal: false
		};

		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount = async () => {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = localStorage.getItem("brandName");
		this.getQualityPerformance("Month", todaysDateFormatted, todaysDateFormatted, unitID, brandID, '72');
	}

	handleChangeStart(holdingDate, mindate) {
		this.setState({
			startDate: holdingDate,
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			this.onDropDownHandler(e, 'QualityPerformance');
		});
	}

	handleChangeEnd(holdingDate) {
		this.setState({
			endDate: holdingDate,
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			this.onDropDownHandler(e, 'QualityPerformance');
		});
	}

	onDropDownHandler(e, type = '') {
		const { startDate, endDate } = this.state;
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = localStorage.getItem("brandName");
		const { nodeText:text } = e;
		this.setState({ type: text, datapickflag: text === 'Date Range' })

		if (text === "Date Range") {
            todaysDateFormatted = format(endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(startDate, 'YYYY-MM-DD');
        }
        else { 
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate,- 6), 'YYYY-MM-DD');
		}
		
		if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
			this.getQualityPerformance(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, '72');
		}
	}

	handleChangeStartGlobal(holdingDate, mindate) {
		this.setState({
			startDate: holdingDate,
			globalDropdownFilterStartDate: holdingDate
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			this.onDropDownHandlerGlobal(e, 'QualityPerformance');
		});
	}

	handleChangeEndGlobal(holdingDate) {
		this.setState({
			endDate: holdingDate,
			globalDropdownFilterEndDate: holdingDate
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext }, nodeText:selectedtext };
			this.onDropDownHandlerGlobal(e, 'QualityPerformance');
		});
	}

	onDropDownHandlerGlobal(e, type = '') {
		const { startDate, endDate } = this.state;
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = localStorage.getItem("brandName");
		const { nodeText:text } = e;
		this.setState({ type: text, globalDropdownFilter: text, datapickflagGlobal: text === 'Date Range', datapickflag: text === 'Date Range' })

		if (text === "Date Range") {
            todaysDateFormatted = format(endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(startDate, 'YYYY-MM-DD');
        }
        else { 
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate,- 6), 'YYYY-MM-DD');
		}
		
		if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
			this.getQualityPerformance(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, '72');
		}
	}

	getQualityPerformance = (type, fromdate, todate, deviceId, UNIT_BRAND_ID, widgetId) => {
		this.setState({ loaderIconVisible: true });
		let filterType;
		let brandId = 3;
		let header;
		switch (type) {
			case 'Week':
				filterType = 'weekly';
				break;
			case 'Year':
				filterType = 'yearly';
				break;
			case 'Day':
				filterType = 'daily';
				break;
			case 'Quarter':
				filterType = 'quarterly';
				break;
			case 'Date Range':
				filterType = 'custom';
				break;
			default:
				filterType = 'monthly';
		}

		header = {
			'Content-Type': 'application/json',
			'unitId': deviceId,
			'reqdate': fromdate,
			'filterType': filterType,
		}

		// added for Frymaster McD Filteration
		const { MODEL_FAMILY_NAME } = this.props ? this.props.currentUnit : '';
		const productName = _.upperCase(MODEL_FAMILY_NAME);
		if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
			header.productModel = productName;
		}

		if (filterType === 'weekly' || filterType === 'custom') {
			delete header.reqdate;
			header.startDate = todate;
			header.endDate = fromdate;
		}

		http({
			method: "GET",
			url: `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brandId}`,
			headers: header,
			data: {},
		}).then(response => {
			this.setState({ qualityResponseData: response.data, loaderIconVisible: false });
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
			console.log('catch exception occured');
		});
	}

	goToLoginPage =() =>{
		this.props.history.push('/');
	}

	errorCloseModel = () => {
		localStorage.removeItem("Access-Token");
		this.setState({
		  IsServerError: false
		});
		this.goToLoginPage();
	  }

	  onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	render() {
		const { type, loaderIconVisible, IsServerError, qualityResponseData, sortColumn } = this.state;
		const { formatMessage } = this.props.intl;
		currentUnit = this.props.currentUnit;
		
		let unitResponse = qualityResponseData && qualityResponseData[0] ? qualityResponseData[0] : {};
		let fryerValue = unitResponse && unitResponse.fryer && unitResponse.fryer.value ? unitResponse.fryer.value : "Fryer";
		let oilQualityValue = unitResponse && unitResponse.oilQuality && unitResponse.oilQuality.value ? unitResponse.oilQuality.value : "--";
		let oqsMeasurementValue = unitResponse && unitResponse.oqsMeasurement && unitResponse.oqsMeasurement.value ? unitResponse.oqsMeasurement.value : "--";
		let filterCompilanceValue = unitResponse && unitResponse.filterCompilance && unitResponse.filterCompilance.value ? unitResponse.filterCompilance.value.toFixed() : "0";
		let ecoModeValue = unitResponse && unitResponse.ecoMode && unitResponse.ecoMode.value ? unitResponse.ecoMode.value.toFixed(1) : "0";

		let oilQualityColor =  unitResponse && unitResponse.oilQuality && unitResponse.oilQuality.color ? unitResponse.oilQuality.color : "";
		let oqsMeasurementColor =  unitResponse && unitResponse.oqsMeasurement && unitResponse.oqsMeasurement.color ? unitResponse.oqsMeasurement.color : "";
		let filterCompilanceColor =  unitResponse && unitResponse.filterCompilance && unitResponse.filterCompilance.color ? unitResponse.filterCompilance.color : "";
		let ecoModeColor =  unitResponse && unitResponse.ecoMode && unitResponse.ecoMode.color ? unitResponse.ecoMode.color : "";
		
		const oilQualityClass = (oilQualityValue === "--") ? "" : (oilQualityColor === "#f93251") ? 'poorStatus' : (oilQualityColor === "#3cce82") ? 'greatStatus' : (oilQualityColor === "#FFBE00") ? 'goodStatus' : '';
		const oqsMeasurementClass = (oqsMeasurementValue === "--") ? "" : (oqsMeasurementColor === "#f93251") ? 'poorStatus' : (oqsMeasurementColor === "#3cce82") ? 'greatStatus' : (oqsMeasurementColor === "#FFBE00") ? 'goodStatus' : '';
		const filterCompilanceClass = (filterCompilanceColor === "#f93251") ? 'poorStatus' : (filterCompilanceColor === "#3cce82") ? 'greatStatus' : (filterCompilanceColor === "#FFBE00") ? 'goodStatus' : '';
		const ecoModeClass = (ecoModeColor === "#f93251") ? 'poorStatus' : (ecoModeColor === "#3cce82") ? 'greatStatus' : (ecoModeColor === "#FFBE00") ? 'goodStatus' : '';

		let invidualFrypots = unitResponse && unitResponse.fryerData && unitResponse.fryerData ? unitResponse.fryerData : [];
		let displayAllFrypots;

		
		let filtrationWidgetsName = 'Quick Filter';
		let filtrationWidgetNameStringID = 'KC1869'//Quick Filter Compliance
		let filtrationWidgetNameToolTipStringID = 'KC1871'
		const productName = _.upperCase(currentUnit.MODEL_FAMILY_NAME);
		if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
			filtrationWidgetsName = 'Auto Filter' 
			filtrationWidgetNameStringID = 'KC1870' //Auto Filter Compliance
			filtrationWidgetNameToolTipStringID = 'KC1872'
		}

		

		let columns = [
			{ path: "fryer", labelStringID: "KC0429", tooltipText: "" },
			{ path: "oilQuality", labelStringID: "KC0503", tooltipText: [<div><h4 className='tip-heading'> Last oil quality value (TPM)</h4><ul className='tip-list'><li>- Green : Good</li><li>- Amber : Discard Soon</li><li>- Red : Discard Now</li></ul></div>]}, // MLS:TODO
			{ path: "oqsMeasurement", labelStringID: "KC0504", tooltipStringID: "KC1873" },
			{ path: "filterCompilance", labelStringID: filtrationWidgetNameStringID , tooltipStringID: filtrationWidgetNameToolTipStringID },
			{ path: "ecoMode", labelStringID: "KC0505", tooltipStringID: "KC1887" },
			{ path: "", labelStringID: "KC0325", tooltipText: "" },
		];
		if(Array.isArray(invidualFrypots) && invidualFrypots.length){
			displayAllFrypots = (
				invidualFrypots.map((item) =>
					(<ul className="tBody">
						<li>{item.fryer.value}</li>
						<li className={(!item.oilQuality.value) ? '' : (item.oilQuality.color === "#f93251") ? 'poorStatus' : (item.oilQuality.color === "#3cce82") ? 'greatStatus' : (item.oilQuality.color === "#FFBE00") ? 'goodStatus' : ''}>{item.oilQuality.value ? item.oilQuality.value.toFixed(1) : '--'}</li>
						<li className={(!item.oqsMeasurement.value) ? '' : (item.oqsMeasurement.color === "#f93251") ? 'poorStatus' : (item.oqsMeasurement.color === "#3cce82") ? 'greatStatus' : (item.oqsMeasurement.color === "#FFBE00") ? 'goodStatus' : ''}>{item.oqsMeasurement.value ? item.oqsMeasurement.value : '--'}</li>
						<li className={(item.filterCompliance.color === "#f93251") ? 'poorStatus' : (item.filterCompliance.color === "#3cce82") ? 'greatStatus' : (item.filterCompliance.color === "#FFBE00") ? 'goodStatus' : ''}>{item.filterCompliance.value ? item.filterCompliance.value.toFixed() + "%" : '0%'}</li>
						<li className={(item.ecoMode.color === "#f93251") ? 'poorStatus' : (item.ecoMode.color === "#3cce82") ? 'greatStatus' : (item.ecoMode.color === "#FFBE00") ? 'goodStatus' : ''}>{item.ecoMode.value ? item.ecoMode.value.toFixed(1) + "%" : '0%'}</li>
						<li>&nbsp;</li>
					</ul>)
			)
			)
		}
		else {
		 	displayAllFrypots = (<div className="noResultMsg"><FormattedMessage id="KC0264"/></div>)
		}
		
		return (
			<React.Fragment>
				<div id="wrapper">
					<LoadingPopup loading={loaderIconVisible} />

					<GlobalHeaderLayout activeClass="qualityNav" />
					<div id="page-content-wrapper">
					<ErrorMoal open={IsServerError ? true : false} message={"Your session has expired. Please login again."} stringID={"KC1678"} onCloseModel={() => this.errorCloseModel()} />
						<div class="pageHeader">
							<ul>
								<li>
									<Link to="/unitQualityPerformance" title={formatMessage({ id: 'KC0501' })} className="active" ><FormattedMessage id="KC0501"/></Link>
								</li>
								<li class="filterDropdown">
									<p class="globalFilterText"><FormattedMessage id="KC0097"/></p>
									<NewDropDownListLink
										translation={true} 
										startDatepara={this.state.globalDropdownFilterStartDate} 
										minDateDisable={this.state.sdmindate} 
										maxDateDisable={this.state.sdmaxdate} 
										endminDateDisable={this.state.edmindate} 
										endmaxDateDisable={this.state.edmaxdate} 
										endDatepara={this.state.globalDropdownFilterEndDate} 
										handleChangeStartProps={(date) => this.handleChangeStartGlobal(date)}
										handleChangeEndProps={(date) => this.handleChangeEndGlobal(date)}
										OnChange={(e) => this.onDropDownHandlerGlobal(e, "QualityPerformance")}
										filterType={this.state.globalDropdownFilter} datapickflag={this.state.datapickflagGlobal} />
								</li>
							</ul>
						</div>


						<div class="gridViewTable">
							<div class="gridView">
								<div class="colm12D">
									<div class="colmDi">
										<h4><FormattedMessage id="KC0502"/>
												<NewDropDownListLink
													translation={true}
													datapickflag={this.state.datapickflag}
													startDatepara={this.state.startDate}
													minDateDisable={this.state.sdmindate}
													maxDateDisable={this.state.sdmaxdate}
													endminDateDisable={this.state.edmindate}
													endmaxDateDisable={this.state.edmaxdate}
													endDatepara={this.state.endDate}
													handleChangeStartProps={(date) => this.handleChangeStart(date)}
													handleChangeEndProps={(date) => this.handleChangeEnd(date)}
													OnChange={(e) => this.onDropDownHandler(e, "QualityPerformance")}
													filterType={type} />
										</h4>

										{/* <!-- Fryer / Frypot Table -->   */}
										<div class="unitQualityPrncTableDiv setMinHeight03">

											<div class="unitQualityPrncTableOuter">
												<div class="unitQualityPrncTable">
												<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										columns={columns} />
												</div>
												<div class="panel panel-default" id="holdingDataAccordion" role="tablist" aria-multiselectable="true">
													{/* <!-- Header 01 --> */}
													<div class="unitQualityPrncTable">
														<ul class="tBody">
															<li>{fryerValue}</li>
															<li class={oilQualityClass}>{oilQualityValue}</li>
															<li class={oqsMeasurementClass}>{oqsMeasurementValue}</li>
															<li class={filterCompilanceClass}>{filterCompilanceValue+"%"}</li>
															{/* <li class={fryerUtilizationClass}>{fryerUtilizationValue+"%"}</li> */}
															<li class={ecoModeClass}>{ecoModeValue+"%"}</li>
															<li><a role="button" data-toggle="collapse" data-parent="#holdingDataAccordion" href="#holdingDataAcc1" aria-expanded="true" aria-controls="holdingDataAcc1" class="btn collapseExpand">&nbsp;</a></li>
														</ul>
													</div>
													{/* <!-- Header 01 Content --> */}
													<div id="holdingDataAcc1" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="holdingDataAccH1">
														<div class="panel-body unitQualityPrncExpTable">
															{displayAllFrypots}
														</div>
													</div>

												</div>
											</div>
										</div>
										{/* <!-- //Fryer / Frypot Table -->  	  */}


									</div>
								</div>
							</div>
						</div>



					</div>
				</div>
			</React.Fragment>

		)
	}
};

function mapStateToProps(state) {
	return {
		currentLocation: state.entityReducer.currentLocation,
		currentUnit: state.entityReducer.currentUnit
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getCurrentLocation, getCurrentUnit }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(UnitQualityPerformance));
