import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CustomDropDownList } from '../controls/customDropDownList';
import { format } from 'date-fns';
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import { getHoldingTableData } from '../../actions/reportManagement/holdingTableActions';
import { FormattedMessage } from 'react-intl';

var startOfWeek = require('date-fns/start_of_week');
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let unitID = "";
let brandID = "";
let currentUnit;

class HoldingDataSummaryTable extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			type: 'Month',
			filterType: "Month",
			noResultMsg: 'No Results',
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			sortColumnDrinks: { path: "", order: "", sort_color: "#FFFFFF" },
			IsServerError: false,
			sd: '',
			ed: '',
			startDate: '',
			endDate: '',
			loaderIconVisible: true,
			hideDateRange: true,
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			datapickflag: false
		};

		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);
	}

	componentDidMount = async () => {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;

		if (brandID === "MERCO" || brandID === "FRYMASTER-UHCTHD") {
			this.props.getHoldingTableData("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
		else {
			this.props.getHoldingTableData("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, '68');
		}

		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	componentDidUpdate = async (prevProp, prevState) => {
		if (this.props.globalDropdownFilter !== "Year" && this.props.globalDropdownFilter !== "Quarter") {
			if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
				this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
				this.handleHoldingDataSummary(this.props.globalDropdownFilter);
				await this.props.handleDropdownChangeEnd();
			} else {
				if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
					if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
						this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
						await this.props.handleDropdownChangeEnd();
					}
					if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
						this.handleChangeStart(this.props.globalDropdownFilterStartDate);
						await this.props.handleDropdownChangeEnd();
					}
				}
			}
		}
	}


	handleChangeStart(holdingDate) {
		if(this.state.startDate ===  holdingDate){
			return;
		}
		this.setState({
			startDate: holdingDate,
			datapickflag:true
		}, () => {
			let selectedtext = 'Date Range';
			this.handleHoldingDataSummary(selectedtext, 'HoldingData');
		});
	}

	handleChangeEnd(holdingDate) {
		if(this.state.endDate === holdingDate){
			return;
		}
		this.setState({
			endDate: holdingDate,
			datapickflag:true
		}, () => {
			let selectedtext = 'Date Range';
			this.handleHoldingDataSummary(selectedtext, 'HoldingData');
		});
	}

	onDropDownHandler(e) {
		const { nodeText:text } = e;
		this.setState({ type: text, datapickflag: text === 'Date Range', startDate: '', endDate: ''})
		this.handleHoldingDataSummary(text);
	}

	handleHoldingDataSummary(text) {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_NAME;
		if (text === "Date Range") {
			todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
			weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
		}
		else {
			todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
			weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
		}
		if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
			if (brandID === "MERCO") {
				this.props.getHoldingTableData(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
			}
			else {
				this.props.getHoldingTableData(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, '68');
			}
		}
	}

	handleRangeChange(which, payload) {
		console.log(which, payload);
		this.setState({
			[which]: {
				...this.state[which],
				...payload,
			},
		});
	}

	render() {
		const { holdingTableInfo } = this.props;
		const holdingTableData = holdingTableInfo.holdingTableResponse ? holdingTableInfo.holdingTableResponse : {};
		let holdingTableContent = holdingTableData.content;
		const displayContents = holdingTableContent && holdingTableContent.length > 0 ? holdingTableContent : [];
		let displayHoldingDatas;
		if (Array.isArray(displayContents) && displayContents.length) {
			displayHoldingDatas = displayContents.map((item, i) =>
				<React.Fragment key={i}>
					<div className="holdingDataTable">
						<ul className="tBody">
							<li>{i + 1}</li>
							<li>{item.productName}</li>
							<li>{item.cycleStarts}</li>
							<li>{item.resetCycle}</li>
							<li>{item.expiredCycle}</li>
							<li>{item.avgHoldingTime}</li>
							<li>{item.doubleTaps}</li>
							<li><a role="button" data-toggle="collapse" draggable="false" data-parent="#holdingDataAccordion" data-target={`#holdingDataAcc_` + i} aria-expanded="false" aria-controls={`holdingDataAcc_` + i} className="btn collapseExpand collapsed">&nbsp;</a></li>
						</ul>
					</div>

					<div id={`holdingDataAcc_` + i} className="panel-collapse collapse" role="tabpanel" aria-labelledby={`holdingDataAcc_` + i}>
						<div className="panel-body holdingDataExpTable">
							{displayContents[i] && displayContents[i].details && displayContents[i].details.map((items, j) =>
								<React.Fragment key={j}>
									<ul className="tBody">
										<li>&nbsp;</li>
										<li>{items.panName}</li>
										<li>{items.cycleStarts}</li>
										<li>{items.resetCycle}</li>
										<li>{items.expiredCycle}</li>
										<li>{items.avgHoldingTime}</li>
										<li>{items.doubleTaps}</li>
										<li>&nbsp;</li>
									</ul>
								</React.Fragment>
							)}
						</div>
					</div>
				</React.Fragment>
			)
		}
		else {
			displayHoldingDatas = (<div className="noResultMsg"><FormattedMessage id="KC0264"/></div>)
		}
		return (
			<div className="gridViewTable">
				<div className="gridView">
					<div className="colm12D">
						<div className="colmDi">
							<h4><FormattedMessage id="KC0367"/>
						<span className="cornerIcon">
								</span>
								<CustomDropDownList translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate}
									minDateDisable={this.state.sdmindate}
									maxDateDisable={this.state.sdmaxdate}
									endDatepara={this.state.endDate}
									endminDateDisable={this.state.edmindate}
									endmaxDateDisable={this.state.edmaxdate}
									handleChangeStartProps={(date) => this.handleChangeStart(date)}
									handleChangeEndProps={(date) => this.handleChangeEnd(date)}
									OnChange={(e) => this.onDropDownHandler(e, "HoldingData")}
									filterType={this.state.type}
								/>
							</h4>
						</div>

						{/* <!-- Utilization Table -->   */}
						<div className="holdingDataTableDiv">

							<div className="holdingDataTableOuter">
								<div className="holdingDataTable">
									<ul className="tHead">
										<li>#</li>
										<li><FormattedMessage id="KC0368"/></li>
										<li><FormattedMessage id="KC1889"/></li>
										<li><FormattedMessage id="KC0369"/></li> 
										<li><FormattedMessage id="KC0370"/></li> 
										<li><FormattedMessage id="KC1890"/> <span> <FormattedMessage id="KC1811"/> </span></li>
										<li><FormattedMessage id="KC0371"/></li>
										<li><FormattedMessage id="KC0325"/></li>
									</ul>
								</div>
								<div className="panel panel-default" id="holdingDataAccordion" role="tablist" aria-multiselectable="true">
									{/* <!-- Header 01 --> */}
									{displayHoldingDatas}
									{/* <!-- Header 01 Content End --> */}
								</div>
							</div>
						</div>
						{/* //Utilization Table   */}
					</div>
				</div>
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		holdingTableInfo: state.holdingTableResponse,
		currentUnit: state.entityReducer.currentUnit,
		currentLocation: state.entityReducer.currentLocation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getHoldingTableData,
		getCurrentLocation,
		getCurrentUnit
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(HoldingDataSummaryTable);
