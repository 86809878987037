import React, { Component } from 'react'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { getDeviceAPIUrl, getDeviceInfo } from '../../../service/unitManagement/deviceinfo'; 
import { FormattedMessage } from 'react-intl';


class DeviceStatusWidget extends Component {
	constructor(props) {
		super(props);
		this.state = { 
			deviceInformation: {} 
		}
	}

	componentDidMount = async () => { 
		let deviceUrl = getDeviceAPIUrl( localStorage.getItem("unitID"), localStorage.getItem("brandName"));
		let deviceInfo = await getDeviceInfo(deviceUrl);
		if (deviceInfo ) {
			this.setState({ 
				deviceInformation: deviceInfo
			});
		}
	}

	render() {
		const { deviceInformation } = this.state; 
		let deviceOnline = 'KC0069', deviceOffline = 'KC0068',
			deviceOnlineStatus = 'divStatusWithIcon online',
			deviceOfflineStatus = 'divStatusWithIcon offline'; 
 	
		return (
			<React.Fragment>
                <div class="colm2D">
					<div class="colmDi">
						<div
							class={deviceInformation.connected === true ? deviceOnlineStatus : deviceOfflineStatus}>
							{deviceInformation.connected === true ? <FormattedMessage id={deviceOnline}/> : <FormattedMessage id={deviceOffline}/>}
						</div>
					</div>
				</div>
            </React.Fragment>
		)		
	}
}; 
export default  DeviceStatusWidget;
