import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import authService from '../../../service/authService';
import UserManagementHeader from '../../layoutSection/userManagementHeader'
import { UnitInfo, MediaManagementAPI } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model'; 
import _ from 'lodash';
import $ from 'jquery';
import qs from 'query-string';
import appconstants from '../../../utils/appConstants';
import { BRAND_ID_VALUE, BRAND_ID, BASECOUNTRY_ID } from '../../../utils/appConstants';
import NoRecordsFound from '../../controls/noRecordsFound';
import Tooltip from 'react-tooltip-lite';
import OfflineMediaImg from '../../../images/icons/offlineA.svg';
import { createPackageZip, setCreatePackageAction } from '../../../actions/navigation/mediaManagement/mediaCreatePackageAction';
import { ungzip } from 'pako';
import { FormattedMessage, injectIntl } from 'react-intl';
class AssignMediaList extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			units_list: [],
			total_unit_count: 0,
			selectedFiles: null,
			prev_page: 0,
			next_page: 2,
			data: [],
			pages: null,
			loading: true,
			unitsSelected: 0,
			unitsOffline: 0,
			userSelectAll: false,
			isIndeterminateSelect: false,
			userChecked: [],
			userCheckedData: [],
			loaderIconVisible: false,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			unitSelectFinishBtn: false,
			mediaSelectedItemsInfo: [],
			isCreateZip: localStorage.getItem("zipFileName") || null,
			isSuccessCreatePackage: null
		}
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
		this.onSortedChange = this.onSortedChange.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);
		this.postData = this.postData.bind(this);
		this.onCreateZipPackage = this.onCreateZipPackage.bind(this);
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		});
		let { isSuccessCreatePackage } = this.state;
		if( isSuccessCreatePackage) {
			this.props.setCreatePackageAction();
			this.props.history.push(`/mediapackage`);
		} 
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	fetchData(state, instance) {
		// Whenever the table model changes, or the user sorts or changes pages, this method gets called and passed the current table model.
		// You can set the `loading` prop of the table to true to use the built-in one or show you're own loading bar if you want.
		this.setState({ loading: true });
		// Request the data however you want.  Here, we'll use our mocked service we created earlier
		this.requestData(
			state.pageSize,
			state.page,
			state.sorted,
			state.filtered
		).then(res => {
			// Now just get the rows of data to your React Table (and update anything else like total pages or loading)
			this.setState({
				data: res.rows,
				pages: res.pages,
				loading: false
			});
		});
	}

	onSortedChange = (a, b, desc) => {
		this.setState({
			prev_page: 0,
			next_page: 2
		});
		return 0;
	};

	componentWillMount = () => {
		let mediaSelectedItemsInfo = this.props.mediaSelectedItemsInfo && this.props.mediaSelectedItemsInfo.length > 0 ? this.props.mediaSelectedItemsInfo : JSON.parse(localStorage.getItem('selectedItemsInfo'));
		localStorage.setItem('selectedItemsInfo', JSON.stringify(mediaSelectedItemsInfo))
		this.setState({
			mediaSelectedItemsInfo: mediaSelectedItemsInfo
		});
	}
	componentDidUpdate(prevState) { 
        this.updateDimensions(); 
    }
	updateDimensions() {
		const windowHeight = $(window).height();
        const assignUListSrlBar = $(".assignUListSrlBar");
        assignUListSrlBar.css('height', windowHeight - assignUListSrlBar.offset().top);
	} 
	componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions);
		document.body.style.backgroundColor = "#F2F2F2";
		try {
			this.requestData();
		} catch (error) {
			//Error Goes Here
			let { response, message } = error;
			if (response || message) {
				let { data } = response || { data: { message } };
				if (data & data.length > 0) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: data.message ? data.message : []
						}
					});
				}
			}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1083"
					}
				});
			}
		}
		 
		
	}

	 


	pageChange = (arg, next, prev) => {
		if (arg > -1) {
			this.setState({
				prev_page: arg,
				next_page: (arg + 1) + 1
			});
		}
	}

	handleChange = () => {
		var userSelectAll = !this.state.userSelectAll;
		this.setState({ userSelectAll: userSelectAll });
		var checkedCopy = [];
		let units = [...this.state.data];

		units && units.length > 0 && units.map(item => {
			item.checked = item.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" ? userSelectAll : false;
			checkedCopy.push(item.checked);
		});

		this.isSelectAssign(units);
		let unitsOfflineCount = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.CONNECT_STATE === "Offline");
		let unitsSelected = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);

		if (userSelectAll) {
			this.setState({
				userChecked: checkedCopy,
				isIndeterminateSelect: false,
				data: units,
				unitsSelected: unitsSelected.length,
				unitsOffline: unitsOfflineCount.length,
				userCheckedData: unitsSelected
			});
		}
		else {
			this.setState({
				userChecked: checkedCopy,
				isIndeterminateSelect: false,
				data: units,
				unitsSelected: 0,
				unitsOffline: 0,
				userCheckedData: [],
			});
		}
	};
	
	onCreateZipPackage() {
		let { selectedFiles , isCreateZip, userCheckedData} = this.state;
		let { prepartions, other } = selectedFiles;
		let  req = {};
		if(prepartions &&  other) {
			req = {files: [...prepartions, ...other ]};
		}
		if( userCheckedData && Array.isArray(userCheckedData)){
			let cgid = [];
            for(let i=0; i < userCheckedData.length;i++){
                const ele = userCheckedData[i];
                cgid.push(ele.CUSTOMER_GROUP_ID || '');
            } 
			req ={ ...req, selectedBrand: userCheckedData[0] };
		}
		if( isCreateZip) {
			req  = { ...req, zipFileName: isCreateZip};
		}
		this.setState({loaderIconVisible: true});
		this.props.createPackageZip(req);
	}

	getCheckBoxstate(ListCount,unselectedCount) {
		if (ListCount == unselectedCount) {
		  return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (unselectedCount == 0) {
		  return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (ListCount > unselectedCount) {
		  return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}
	
	handleSingleCheckboxChange = (e, index) => {
		var checkedCopy = this.state.userChecked;
		checkedCopy[index] = !this.state.userChecked[index];
		if (checkedCopy[index] === false) {
			this.setState({ userSelectAll: false });
		}
		let checkAll = [];

		const units = [...this.state.data];
		units[index] = { ...units[index] };
		units[index].checked = e.target.checked;

		checkAll = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && (c.checked === false || c.checked === undefined));
		let checked = units.filter(c => c.PROVISIONING_STATUS === "CERTIFICATE_RECEIVED" && c.checked === true);
		let unitsOfflineCount = checked.filter(c => c.CONNECT_STATE === "Offline");
		let unitRegistedCount = units.filter(c => c.PROVISIONING_STATUS === "UNIT_REGISTERED").length

		this.setState({
			data: units,
			userChecked: checkedCopy,
			unitsSelected: checked.length,
			unitsOffline: unitsOfflineCount.length,
			userCheckedData: checked,
			...this.getCheckBoxstate(parseInt(units.length) -parseInt(unitRegistedCount) 
			,checkAll.length)
		});

		this.isSelectAssign(checked);
	};

	isSelectAssign = (units) => {
		const checkAllTrue = units.filter(c => c.checked === true);
		this.setState({
			unitSelectFinishBtn: checkAllTrue.length > 0 || false
		});
	}

	getBrandId = () => {
		let brandName = localStorage.getItem("brandName");
		return BRAND_ID_VALUE[brandName] || 0 ;
	}

	/**
	 * Decompress Base64 encode string into JSON
	 * @param {*} base64 Base64 encoded string
	 * @returns JSON | null
	 */
	decompressData(base64) {
		try {
			const buffer = Buffer.from(base64, 'base64');
			const bufferArr = ungzip(buffer);
			const json = new TextDecoder('utf8').decode(bufferArr);
			return JSON.parse(json);
		} catch(err) {
			console.error(err);
			return null;
		}
	}

	requestData = async () => {
		
		let brandId = this.getBrandId();
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.mediaFilters);
        const filterModel1 = ffModel && JSON.parse(ffModel); 
        if (filterModel1)
        {
            filterModel=filterModel1;
		}
		
		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
		let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
		let selectedMachineTypes = [];

		selectedCountryList.dataSource.map(c => {
            selectedCgids.push(c.id)
        });
        
        if (selectedRegionList.dataSource.length > 0)
        {
            selectedCgids = [];
        }

        selectedRegionList.dataSource.map(c => {
            selectedCgids.push(c.cust_gp_id)
        });

        if (selectedLocationList.dataSource.length > 0)
        {
            selectedCgids = [];
        }

        selectedLocationList.dataSource.map(c => {
            selectedCgids.push(c.cust_gp_id)
		});
		
		// if (selectedWithinLocationList.dataSource.length > 0) {
		// 	selectedCgids = [];
		// }

		// selectedWithinLocationList.dataSource.map(c => {
		// 	return selectedCgids.push(c.cust_gp_id)
		// });
        
        selectedCustomerList.dataSource.map(c =>{
			selectedCids.push(c.ID)
        });
        
        selectedBrandList.dataSource.map(c =>{
			selectedBrands.push(c.ID)
        });
        
        selectedModelList.dataSource.map(c =>{
			selectedModels.push(c.ID)
        });

		selectedMachineTypeList.dataSource.map(c => {
			selectedMachineTypes.push(c.ID)
		});

		var d = document.getElementById("spannavNotifiStatus");
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID != "" && custGroupID != undefined) {
			cgids = custGroupID;
		}
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
			d.className = "navNotifiStatus";
		}
		else if (selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
			d.className = "navNotifiStatus";
		}
		else
		{
		  d.className = "";
		}

		
		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		

		let url = `${UnitInfo.unitList}?compress=true`;
		
		let apiUrl = url;
		let res = {
			rows: [],
			pages: 0
		};
		this.setState({
			loaderIconVisible: true
		});
		await http.get(apiUrl,
			{
				headers: {
					'Content-Type': 'application/json',
					'cgid': cgids,
					'cid': selectedCids.join(','),					
					'brandid': selectedBrands.length>0?selectedBrands.join(','): brandId ? brandId: BRAND_ID,
					'model': selectedModels.join(','),
					'machinetype': selectedMachineTypes.join(','),
					'sortby': sortedID,
					'sorttype': sortedDesc,
					'basecountry': BASECOUNTRY_ID,
					'showOnlyActiveUnits': true
				},
				data: {}
			}).then(response => {
				if (response.data.compressed) {
					response.data.units = this.decompressData(response.data.units) || [];
				}
				let { units } = response.data;
				let orderId = 1;
				units = UnitBuilder(units);

				units.forEach(item => {
					item.order_id = orderId++
				});

				var checkedCopy = [];
				var userSelectAll = this.state.userSelectAll;
				units.forEach(function (e, index) {
					checkedCopy.push(userSelectAll);
				});

				this.setState({
					loaderIconVisible: false,
					data: units,
					userChecked: checkedCopy,
					userSelectAll: userSelectAll
				});

			}).catch(err => {
				this.setState({loaderIconVisible: false});
				throw err;
			});

	};

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	onFinishClickHandler() {

		let userCheckedData = this.state.userCheckedData;
		const { mediaSelectedItemsInfo } = this.state;
		const unitList = [];
		userCheckedData.map(item => {
			return unitList.push(
				{
					"thingArn": item.THING,
					"productId": item.PRODUCT_ID,
					"cloudUnitName": item.CLOUD_UNIT_NAME,
					"unitTimeZone": item.TimeZone_Standard,
				}
			)
		});
		
		const req = {
			"thingList": unitList,
			"mediaId": mediaSelectedItemsInfo
		}
		
		this.postData(MediaManagementAPI.getUploadHistory, req).then(response => {
			this.props.history.push(`/mediaUploadHistory`);
			// this.props.history.push(`/mediapackage`);
		}).catch(err => {
			//Error Goes Here
			this.showError(err);
		});

	}

	showError = (err) => {
		let { response } = err;
				let { data } = response;
				let { message, body } = data;
				if (response && response.status === 400) {
					this.setState({
						loaderIconVisible: false,
						error_modal: {
							open: true,
							message: body.message ? body.message : ""
						}
					});
				}
				else if (response && response.status === 500) {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: message ? message : ""
					},
				});
				}
			else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: message ? message : ""
					}
				});
			}
	}

	postData = (url, reqData) => {
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let userName = loginUser.username;
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {

			http.post(url
				, reqData,
				{
					headers: {
						'Content-Type': 'application/json',
						'user': userName,
						'basecountry': BASECOUNTRY_ID
					}

				}
			).then(response => {
				let { data } = response;
				let { httpCode, responseCode, message } = data;
				if (response && response.status === 200) {
					this.setState({
						loaderIconVisible: false,
						success_modal: {
							open: true,
							message: message
						},
					});
				}

			}).catch(err => {
				reject(err);
			});
		});
	}
	static getDerivedStateFromProps = (props) => ({...props});
	render() {
		const { formatMessage } = this.props.intl;
		const { data, unitSelectFinishBtn, sortColumn, isCreateZip , isSuccessCreatePackage} = this.state;
		let { success_modal, error_modal, loaderIconVisible } = this.state;
		if( isSuccessCreatePackage ) {
			success_modal = { open: true , message : isSuccessCreatePackage };
			loaderIconVisible = false;
		}
		let userProfileAuthFeature = authService.getFeature("/assignMediaList");
		let orderId = 1;

		let columns = [
			{ path: "", label: "#" },
			{ path: "", label: "", isNbSp: true },
			{ path: "BRAND_NAME", labelStringID: "KC0062" },
			{ path: "PRODUCT_TYPE", labelStringID: "KC0102" },
			{ path: "MODEL_NAME", labelStringID: "KC0064" },
			{ path: "UNITID", labelStringID: "KC0104" },
			{ path: "UNIT_NUMBER", labelStringID: "KC0674" },
			{ path: "location_name", labelStringID: "KC0050" },
			{ path: "within_location", labelStringID: "KC0061" },
			{ path: "", labelStringID: "KC0523", isNbSp: true },
			{ path:"", label:"", isCheckBox:true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		let unitList = data;

		unitList.forEach(item => {
			item.order_id = orderId++
		});

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);

		const finishBtnClass = ['btn-default-text btn forwardNext'];

		if (unitSelectFinishBtn) {
			finishBtnClass.push('activeState');
		} else {
			finishBtnClass.push('disabled');
		}

		return (
			<div id="adminWrapper">
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>
				{/* <!-- //Fixed Header navbar -->  style={{padding:'64px 0px 0px'}}*/}
				<div id="page-content-wrapper" class="withSearchBar">
					<div className="unitRegistrationWrapper">
						<div className="addUnitHeader">
							<ul>
								<li>
									<Link to="/mediaLibraryList" title={formatMessage({ id: 'KC0989' })} className="backIcon"></Link>
								</li>
								<li>
									<FormattedMessage id="KC1526"/>
								</li>
								<li>
									{ isCreateZip && <button className={finishBtnClass.join(' ')} onClick = {() => this.onCreateZipPackage()} title={formatMessage({ id: 'KC0056' })}>&nbsp;<FormattedMessage id="KC0056"/></button>}
									{ !isCreateZip && <button type="button" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}  className={finishBtnClass.join(' ')} onClick = {() => this.onFinishClickHandler()} title={formatMessage({ id: 'KC0056' })}>&nbsp;<FormattedMessage id="KC0056"/></button>}
									
								</li>
							</ul>
						</div>
						<div className="selectedUnitHeader">
							<ul>
								<li><FormattedMessage id="KC1570" values={{ selectedCount: this.state.unitsSelected, offlineCount: this.state.unitsOffline }}/></li>

								<li>
                                        <Link to="/assignPackageMedia" className="assignunitSite">&nbsp;</Link>
                                        <a id="btnAssign" className="assignunitList active">&nbsp;</a>
                                    </li>
								{/* <li>
									<Link to="/assignMediaList" className="btn btn-default-text assignunitList active" >&nbsp;</Link>
								</li> */}
							</ul>
						</div>
						<div className="SWlistUnits">
							<div className="SWlistUnitsTable"> 
								<TableHeader
									sortColumn={sortColumn}
									onSort={this.onSort}
									selectAllChange={this.handleChange}
									columns={columns}
									isIndeterminate={this.state.isIndeterminateSelect}
									isSelectAll={this.state.userSelectAll} />
							</div>
							<div className="assignUListSrlBar">
							<div className="SWlistUnitsTable">
								{
									unitList.map((item, i) => (
										<ul className={item.CONNECT_STATE === "Offline" ? "tBody" : "tBody "} key={i}>
											<li>{i + 1}</li>
											<li><img src={item.Brand_Logo} /></li>
											<li>
												{item.BRAND_NAME}
												<div class="unitsListSM">{item.PRODUCT_TYPE},{item.MODEL_NAME}</div>
												<div class="unitsListSML">{item.location_name}</div>
											</li>
											<li>{item.PRODUCT_TYPE}</li>
											<li>{item.MODEL_NAME}</li>
											<li>{item.UNITID}
											<span class="unitsListAN"><FormattedMessage id="KC0674"/> <b>{item.UNIT_NUMBER}</b></span>
											</li>
											<li>{item.UNIT_NUMBER}</li>
											<li>{item.location_name}</li>
											<li>{item.within_location}</li>

											<li>
												{ item.CONNECT_STATE === 'Offline'  && <img src = { OfflineMediaImg} />}
											</li>

											<li>{item.PROVISIONING_STATUS !== "CERTIFICATE_RECEIVED" ? <Tooltip content={formatMessage({ id: 'KC1735' })} direction="left" className="targetdisabled">
												<div className={"customCheckbox customCheckboxWOlabel disabled"}>
													<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
														checked={item.checked}
														onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
													<label for={item.order_id - 1}></label>
												</div></Tooltip> 
													: <div className={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"}>
													<input id={item.order_id - 1} type="checkbox" value={item.checked} defaultChecked={item.checked}
														checked={item.checked}
														onChange={(e) => this.handleSingleCheckboxChange(e, item.order_id - 1)} />
													<label for={item.order_id - 1}></label>
												</div>}
											</li>
										</ul>
									))
								}
							</div>
							<NoRecordsFound loaderIconVisible={loaderIconVisible} length={unitList !== undefined ? unitList.length : 0} className={'media-li-no-record'} />
						</div>
						</div>
					</div>
				</div>
				<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
				<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
				<UserManagementHeader headerName="Media – Crem Library" headerNameStringID="KC1737" previousPage="mediaLibraryList" activeClass="mediaNav" filterKey={appconstants.mediaFilters} history={this.props.history} />
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel,
		selectedFiles: state.mediaDraggedItem.mediaDraggedItem,
		mediaSelectedItemsInfo: state.mediaPreparationListInformation.mediaSelectedItems,
		isSuccessCreatePackage : state.packageList.createPackageZip
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		createPackageZip,
		setCreatePackageAction
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignMediaList));