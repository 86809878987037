import { MenuUploadAPI } from '../../service/api';
import md5 from 'md5';
import uploadMenuToCloud from '../../components/navigation/menus/menuUpload';
import { SET_ADD_NEW_MENU_LIBRARY, SET_ADD_NEW_MENU_LIBRARY_LOADING, MODEL_FAIL} from '../stateActionsData/type';

export const setAddMenuLibrary = ( data ) => ({ type: SET_ADD_NEW_MENU_LIBRARY, payload: data});
const setLoader= ( bol ) => ({ type: SET_ADD_NEW_MENU_LIBRARY_LOADING, payload: bol});

export const  addMenuLibrary =  (reqData)  => dispatch => {
    dispatch(setLoader(true));
    let { menuName, versionNo, applicableModels, menuId, description, releaseLog, type, fileName, fileFormat, fileSize,filetype } = reqData;
    let customer = localStorage.getItem('customerId');
    let brand = localStorage.getItem('brandName');
    let brandId = localStorage.getItem('selectedbrandID');
    let user = localStorage.getItem('user');
    let cgid = localStorage.getItem('custGroupID');


    let reqBody = {
        customer,
        menuName,
        versionNo,
        applicableModels,
        description,
        releaseLog,
        filetype,
        cgid,
        operation: 'create',
        mode: '',
        transferType: '',
        lastTransferedDate: '',
        smartgroupName: '',
        isTestPush: false,
        menuId,
        brand, 
        brandId,
        user,
        type, 
        fileName, 
        fileFormat, 
        fileSize,
    }
    menuId = menuId + "." + fileFormat;
    
    let presignedUrl = `${MenuUploadAPI.getPresignedUrl}` + '?customer=' +customer + '&key=' + menuId + '&brand=' + brand;
    let reader = new FileReader();
	reader.readAsDataURL(reqData.files[0]);
	reader.onload = (e) => {
        reqBody.crc = md5(Buffer.from(reader.result.replace(/^data:application\/octet-stream;base64,/, "").replace(/^data:application\/json;base64,/, "").replace(/^data:application\/x-zip-compressed;base64,/, ""), "base64"));
    }

    uploadMenuToCloud(presignedUrl, reqBody, reqData.files, user).then(response => {
        dispatch(setAddMenuLibrary(response))
        dispatch(setLoader(false));
    }).catch(e => {
        dispatch(setLoader(false));
    });
}

