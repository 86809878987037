import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MenuUploadAPI } from '../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { CUSTOMER_ID } from '../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewTopology from '../../../components/controls/viewTopology';
import { FormattedMessage, injectIntl } from 'react-intl';

class AssignFrymasterMenuToTopology extends Component {
    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/libraryList`);
    }
    
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            prevPage: '',
            retrievethingList: '',
            retrieveObj: '',
            modelFamilyID: '',
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    showError = (err) => {
        let { response, stringID } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: stringID ? stringID : data.KC_ERR_400 ? data.KC_ERR_400 : data.message
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: stringID ? stringID : data.KC_ERR_500 ? data.KC_ERR_500 : data.message
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        window.addEventListener('resize', this.updateDimensions);
    }

    componentWillMount = () => {
        this.requestData();
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    requestData = () => {
        let { previouspath, retrieveType, retrieveData, thingList, ModalFamilyId } = this.props.location;
        if (previouspath == "/fileRetrievalList" && retrieveType == "Retrieve") {
            this.setState({
                prevPage: previouspath,
                retrievethingList: thingList,
                modelFamilyID: ModalFamilyId,
                retrieveObj: retrieveData
            })
        }
    }

    onBackClickHandler = () => {
        if (this.state.prevPage == "/fileRetrievalList") {
            this.props.history.push({
                pathname: "/fileRetrievalList",
                previouspath: "/retrieveLibraryToTopology"
            });
        }
    }

    submitAddLibrary = (e, _customId) => {
        this.setState({
			loaderIconVisible: true
		});
        e.preventDefault();
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;
        let mediaTypeData = [{ "type": "CookBook", "name": this.state.retrieveObj.retrieve.cookbookName }, { "type": "Settings", "name": this.state.retrieveObj.retrieve.settingsName }]
        let key_flag = true;
        Object.keys(mediaTypeData).map((key, index) => {
            if(key_flag) {
                if(mediaTypeData[key].name === '') {
                    mediaTypeData.splice(key, 1);
                    key_flag = false;
                }
            } else return;  
        });
        
        return new Promise((resolve, reject) => {
            let postData = {
                "mediaType": mediaTypeData,
                "BrandId": localStorage.getItem("selectedbrandID"),
                "country": localStorage.getItem("basecountry"),
                "customer_id": CUSTOMER_ID,
                "modelFamilyIds": this.state.modelFamilyID === undefined ? "" : this.state.modelFamilyID.toString(),
                "FileName": "",
                "TriggeredBy": loggedinUser,
                "cgid": _customId.join(','),
                "uploadtype": "Retrieve",
                "thingList": this.state.retrievethingList
            }
            let url = `${MenuUploadAPI.fileRetrieval}`
           http({
                method: "POST",
                url: url,
                data: postData,
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(res => {
                let { data } = res;
                let { httpCode, message } = data;
                if (httpCode === "HTTP_201") {
                    resolve(message);
                    this.setState({
                        loaderIconVisible: false
                    });
                    $('#cbfileretrievalprocessModal').modal('show');
                }
                else {
                    reject(res);
                    this.setState({
                        loaderIconVisible: false
                    });
                }
            }).catch(err => {
                reject(err);
                this.showError(err);
                this.setState({
                    loaderIconVisible: false
                });
            });
        });
    }

    onTopologySubmitHandler = () => {
        this.props.history.push({
            pathname: "/libraryList",
            previouspath: "/retrieveLibraryToTopology",
        });
    }

    changeLoadingStatus = (value) => {
        this.setState({ loaderIconVisible: value })
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { success_modal, error_modal } = this.state;
        const { loaderIconVisible } = this.state;
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        return (
            <React.Fragment>
                <div id="adminWrapper">
                    <div className="treeInfoToggleTransparent"></div>
                    <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                        <div className="loadingProgressIn"></div>
                    </div>
                    <ViewTopology
                        title="Select Nodes"
                        submitAddLibrary={this.submitAddLibrary}
                        showError={this.showError}
                        changeLoadingStatus={this.changeLoadingStatus}
                        onBackClickHandler={this.onBackClickHandler}
                        modelFamilyIds={this.state.modelFamilyID === undefined ? "" : this.state.modelFamilyID.toString()}
                        preSelect={libraryDetails.isEdit ? true : false} 
                        restrictUnitSelect={true} />
                    <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel} />
                    <SuccessMoal open={success_modal.open} message={success_modal.message} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                    <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" />
                </div>
                {/* Retrieval in progress Modal */}
                <div className="modal fade selectRecipeModal" data-keyboard="true" data-backdrop="static" id="cbfileretrievalprocessModal" tabindex="-1" role="dialog" aria-labelledby="cbfileretrievalModalLabel">
                    <div className="modal-dialog modal-dialog-centered modal-md">
                        <div className="modal-content">
                            <div className="modal-header bBg modal-header-danger">
                                <FormattedMessage id="KC2271"/> <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                            </div>
                            <div className="modal-body">
                                <div className="cbfileretrievalFormP">System will be retrieving requested files from device</div>
                                <div className="cbfileretrievalProcess">
                                    <ul>
                                        <li><img src={require('../../../images/unit/MerrychefConneX12.svg').default} alt="" /></li>
                                        <li><img src={require('../../../images/icons/arrowright.svg').default} alt="" /></li>
                                        <li><img src={require('../../../images/icons/onlineA.svg').default} alt="" /></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary confirmationOKBtn" onClick={() => this.onTopologySubmitHandler()} onclick="location.href='menulibrary.html'" type="button">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Retrival Information */}
            </React.Fragment>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AssignFrymasterMenuToTopology));
