import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';
import $ from 'jquery';
class DateAndTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            date_format: ""
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.onDateFormatSelect = this.onDateFormatSelect.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = localStorage.getItem("librarySettingsData");
            let jsonDateAndTime = JSON.parse(jsonSettingsFile);

            this.setState({
                date_format: jsonDateAndTime.date_format
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/settingsEditor",
            isEdit: false,
            previouspath: "/cleaning"
        });
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true
        });
        let { date_format } = this.state;
        if (date_format) {
            let jsonSettingsFile = localStorage.getItem("librarySettingsData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);
            ObjSettingsFile.date_format = date_format;
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            localStorage.setItem("librarySettingsData", strSettingsFile);
            this.props.history.push(`/settingsEditor`);
        }
    }

    onDateFormatSelect = (event) => {
        this.setState({
            date_format: event.target.value
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/settingsEditor`);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    render() {
        let { date_format, error_modal, success_modal } = this.state;
        let brandid = localStorage.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1327"/>
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    <ul>
                                                        <li>
                                                            <div class="rcSettingsList02">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC2021"/></li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onDateFormatSelect}>
                                                                            <input id="DateFormat01" type="radio" name="DateFormat" value="dd/mm/yyyy hh:mm 12h" checked={date_format === "dd/mm/yyyy hh:mm 12h" ? true : false} />
                                                                            <label for="DateFormat01" value="dd/mm/yyyy hh:mm 12h">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                </div>
                                                                <div class="rcSettingsList02">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC2023"/></li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onDateFormatSelect}>
                                                                            <input id="DateFormat02" type="radio" name="DateFormat" value="dd/mm/yyyy hh:mm 24h" checked={date_format === "dd/mm/yyyy hh:mm 24h" ? true : false} />
                                                                            <label for="DateFormat02" value="dd/mm/yyyy hh:mm 24h">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                </div>
                                                                <div class="rcSettingsList02">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC2025"/></li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onDateFormatSelect}>
                                                                            <input id="DateFormat03" type="radio" name="DateFormat" value="mm/dd/yyyy hh:mm 12h" checked={date_format === "mm/dd/yyyy hh:mm 12h" ? true : false} />
                                                                            <label for="DateFormat03" value="mm/dd/yyyy hh:mm 12h">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                </div>
                                                                <div class="rcSettingsList02">
                                                                <ul>
                                                                    <li><FormattedMessage id="KC2105"/></li>
                                                                    <li>
                                                                        <div class="customRadioBtn" onClick={this.onDateFormatSelect}>
                                                                            <input id="DateFormat04" type="radio" name="DateFormat" value="mm/dd/yyyy hh:mm 24h" checked={date_format === "mm/dd/yyyy hh:mm 24h" ? true : false} />
                                                                            <label for="DateFormat04" value="mm/dd/yyyy hh:mm 24h">&nbsp;</label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn btn-default-text confirmButton">&nbsp;</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(DateAndTime)
