import { SET_FILTER_DATA, RESET_FILTER_DATA } from '../../../../actions/listView/locations/type';
import { GET_DEFAULT_DATA } from '../../../../actions/listView/locations/filterListAction';
import * as _ from 'lodash';

const initialState = {
    filterModel: [
        {
            id: 1,
            field_name: 'country',
            display_name: 'Country',
            visible: false,
            sort: 2,
            dataSource: []
        },
        {
            id: 2,
            field_name: 'chain',
            display_name: 'Sub Group',
            visible: false,
            sort: 3,
            dataSource: []
        },
        {
            id: 8,
            field_name: 'withinlocation',
            display_name: 'Within Location',
            visible: false,
            sort: 5,
            dataSource: []
        },
        {
            id: 3,
            field_name: 'location',
            display_name: 'Location',
            visible: false,
            sort: 4,
            dataSource: []
        },
        {
            id: 4,
            field_name: 'customer',
            display_name: 'Customer',
            visible: false,
            sort: 1,
            dataSource: []
        },
        {
            id: 5,
            field_name: 'brand',
            display_name: 'Brand',
            visible: false,
            sort: 7,
            dataSource: []
        },
        {
            id: 6,
            field_name: 'machineType',
            display_name: 'Unit Type',
            visible: false,
            sort: 8,
            dataSource: []
        },
        {
            id: 7,
            field_name: 'model',
            display_name: 'Model Name',
            visible: false,
            sort: 9,
            dataSource: []
        },
        {
            id: 9,
            field_name: 'orgSmartTagsFilter',
            display_name: 'OrgChart Smart Tags',
            visible: false,
            sort: 6,
            dataSource: []
        },
        {
            id: 10,
            field_name: 'unitSmartTagsFilter',
            display_name: 'Unit Smart Tags',
            visible: false,
            sort: 10,
            dataSource: []
        },
    ]
};

export default function (state = initialState, action) {

    const ffModel = localStorage.getItem(action.filterKey);
    const fm = ffModel && JSON.parse(ffModel);

    switch (action.type) {
        case GET_DEFAULT_DATA:           
            return {
                ...state,
                filterModel: fm ? fm : JSON.parse(JSON.stringify(initialState.filterModel))
            }

        case SET_FILTER_DATA:
            const copyOfFilterModel = fm ? fm : [...state.filterModel];
            const { field, isChecked, id } = action.payload;
            const findIndex = copyOfFilterModel.findIndex(el => el.field_name === field);            
            if (isChecked) {
                copyOfFilterModel[findIndex].dataSource.push(action.payload);
            } else {                
                copyOfFilterModel[findIndex].dataSource = _.cloneDeep(copyOfFilterModel[findIndex].dataSource).filter(el => el.id !== id);
            }
            localStorage.setItem(action.filterKey, JSON.stringify(copyOfFilterModel));
            return {
                ...state,
                filterModel: copyOfFilterModel
            }

        case RESET_FILTER_DATA:
            const filterModel = [...state.filterModel].map(el => Object.assign(el, { dataSource: [] }));
            localStorage.setItem(action.filterKey, JSON.stringify(filterModel));
            return {
                ...state,
                filterModel: filterModel
            }
        default:
            return state;
    }
}
