import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { reactIntl } from '../../locale/locale-provider';

am4core.useTheme(am4themes_animated);

class PeakHoursChart extends Component {

  shouldComponentUpdate(nextProps, nextState) {
    const { peakHourChartInformation } = nextProps.peakHourChartInfo;
    if (nextProps.peakHourChartInfo.peakHourChartInformation !== this.props.peakHourChartInfo.peakHourChartInformation || nextProps.peakHourChartInfo.time !== this.props.peakHourChartInfo.time || nextProps.peakHourChartInfo.page !== this.props.peakHourChartInfo.page || nextProps.peakHourChartInfo.deviceid !== this.props.peakHourChartInfo.deviceid || !this.props.peakHourChartInfo.peakHourChartInformation || this.props.peakHourChartInfo.peakHourChartInformation.length === 0) {
      this.renderPeakHourComponent(peakHourChartInformation, true);
    }
  }

  renderPeakHourComponent(nextProps, isTimeChanged) {
    const { peakHourChartInformation } = this.props.peakHourChartInfo;
    let response = [];
    if (peakHourChartInformation && peakHourChartInformation.length > 0 && !isTimeChanged) {
      response = peakHourChartInformation;
    } else if (nextProps && nextProps.length > 0) {
      response = nextProps;
    }

    if(response && response.length>0) 
    {
    let chart = am4core.create("peakHoursDBChartdiv", am4charts.XYChart);
    chart.data = response;
    this.chart = chart;
    chart.responsive.enabled = true;
    chart.zoomOutButton.disabled = true;
    
    // Create axes
    let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
    categoryAxis.dataFields.category = "hours";
    categoryAxis.renderer.grid.template.location = 0;
    categoryAxis.renderer.minGridDistance = 10;
    categoryAxis.renderer.labels.template.disabled = true;
    categoryAxis.renderer.grid.template.disabled = true;

     //Configure ticks
    if(chart.data.length>0)
    {
    categoryAxis.strictMinMax = true
    categoryAxis.renderer.ticks.template.disabled = false;
    categoryAxis.renderer.ticks.template.length = 5;
    categoryAxis.renderer.ticks.template.strokeOpacity = 1;
    categoryAxis.renderer.ticks.template.stroke = am4core.color("#d9d9d9");
    categoryAxis.renderer.ticks.template.strokeWidth = 1;
    }
    
    categoryAxis.renderer.labels.template.adapter.add("dy", function (dy, target) {
      if (target.dataItem && target.dataItem.index & 2 == 2) {
        return dy + 25;
      }
      return dy;
    });

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.renderer.labels.template.disabled = true;
    valueAxis.renderer.line.strokeOpacity = 0;
    valueAxis.renderer.line.strokeWidth = 0;
    valueAxis.renderer.grid.template.disabled = true;

    // Create series
    let series = chart.series.push(new am4charts.ColumnSeries());
    series.dataFields.valueY = "totalcount";
    series.dataFields.categoryX = "hours";
    series.dataFields.tootltipY = "tooltipNum";
    series.name = "products";  /* MLS:TODO */
    series.columns.template.width = am4core.percent(93);
    series.columns.template.tooltipText = " products : [bold]{tootltipY}[/]"; /* MLS:TODO */
    series.columns.template.propertyFields.fillOpacity = "fillOpacity";
    series.columns.template.propertyFields.fill = "color";
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color("#FFFFFF");
    series.tooltip.label.fill = am4core.color("#000000");
    series.tooltip.label.adapter.add("textOutput", function (text, target) {
      if (target.dataItem.dataContext && target.dataItem.tootltipY) {
        return reactIntl.formatMessage({ id: "KC0176" }) + ` : [bold]${target.dataItem.tootltipY}[/]`;
      } else {
        return reactIntl.formatMessage({ id: "KC0176" }) + ` : `;
      }
    });

    let columnTemplate = series.columns.template;
    columnTemplate.strokeWidth = 0;
    columnTemplate.strokeOpacity = 0;
    
    this.findPeakHour(response);
    }

    else{
      if(this.chart)
      {
        this.chart.data = [];
      }
    }
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  findPeakHour = (data) => {
    let maxTotalCount = 0;
    //let peakHour = 0;

    let chartData = data && data.length > 0 ? data : [];
    let separator = localStorage.getItem("thousandSeparator");
    //Find maximum count
    for (let index = 0; index < chartData.length; index++) {
      if (maxTotalCount < chartData[index].totalcount) {
        maxTotalCount = chartData[index].totalcount;
      }
      // Tooltip to show data with . thousand separator
      let tooltipTotalCount = chartData[index].totalcount;
      chartData[index].tooltipNum = separator === "." ? tooltipTotalCount.toString().replace(',', separator) : tooltipTotalCount;
    }
    if (maxTotalCount) {

      for (let index = 0; index < chartData.length; index++) {

        if (chartData[index].totalcount === maxTotalCount) {
          chartData[index].fillOpacity = 1;
          chartData[index].color = "#00a4f2";
          chartData[index].stroke = "#00a4f2";
          //peakHour = chartData[index].hours;
        } else {
          chartData[index].fillOpacity = 1;
          chartData[index].color = "#006b94";
          chartData[index].stroke = "#006b94";
        }
      }
    }
    //return peakHour;
  }

  render() {
    return (
      <div id="peakHoursDBChartdiv"></div>
    );
  }
}

export default PeakHoursChart;
