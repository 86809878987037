import React, { Component } from 'react';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { format, addDays, differenceInMinutes } from 'date-fns';
import $ from 'jquery';
import http from '../../../service/httpService';
import LoadingPopup from '../../controls/loadingPopup';
import { BaseService, ReportManagement } from '../../../service/api';
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';
import { reactIntl } from '../../../locale/locale-provider';

// common global parameters
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class FrypotTimeChart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Day',
            filterType: "Day",
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            startDate: '',
            endDate: '',
            loaderIconVisible: false,
            datapickflag: false,
            message:''
        };

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = localStorage.getItem("brandName");
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        await this.getPanvsTimelinedata("Day", todaysDateFormatted, todaysDateFormatted, unitID, brandID, '66');
    }

    handleChangeStart(holdingDate) {
        this.setState({
            startDate: holdingDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            this.onDropDownHandler(e, 'FrypotTimeChart');
        });
    }

    handleChangeEnd(holdingDate) {
        this.setState({
            endDate: holdingDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            this.onDropDownHandler(e, 'FrypotTimeChart');
        });
    }

    getPanvsTimelinedata = (type, fromdate, todate, deviceId, UNIT_BRAND_ID, widgetId) => {
        this.setState({ loaderIconVisible: true });
        let filterType;
        let brandId = 3;
        let header;
        switch (type) {
            case 'Day':
                filterType = 'daily';
                break;
            case 'Date Range':
                filterType = 'custom';
                break;
            default:
                filterType = 'weekly';
        }

        header = {
            'Content-Type': 'application/json',
            'unitId': deviceId,
            'reqdate': fromdate,
            'filterType': filterType,
            'showtrend': "false"
        }

        if (filterType === 'weekly' || filterType === 'custom') {
            delete header.reqdate;
            header.startDate = todate;
            header.endDate = fromdate;
            header.showtrend = "false";
        }

        http({
            method: "GET",
            url: `${BaseService.root}${ReportManagement.widgetAPI}/${widgetId}/${brandId}`,
            headers: header,
            data: {},
        }).then(response => {
            const panVsTimeResponse = response.data;
            if (panVsTimeResponse && panVsTimeResponse.content && panVsTimeResponse.content.httpCode === 200) {
                $("#panChartDiv").removeClass("noRecordMsg");
                $("#panChartlegend").removeClass("displaynone");
                $("#panChartlegend").addClass("frypotLegendSection");
                this.setState({ loaderIconVisible: false });
                this.renderPanStatesVsTime(filterType, panVsTimeResponse.content.body.timelineData);
            }
            else if (panVsTimeResponse.content.httpCode === 404) {
                $("#panChartlegend").removeClass("frypotLegendSection");
                $("#panChartDiv").addClass("noRecordMsg");
                $("#panChartlegend").addClass("displaynone");
                this.setState({ loaderIconVisible: false, message : panVsTimeResponse.content.body.stringID ?  panVsTimeResponse.content.body.stringID :  panVsTimeResponse.content.body.message });
            }
            else if (response.status === 400) {
                $("#panChartlegend").removeClass("frypotLegendSection");
                $("#panChartDiv").addClass("noRecordMsg");
                $("#panChartlegend").addClass("displaynone");
                this.setState({ loaderIconVisible: false, message : 'Error occured while calling database.'});
            }
            else if (panVsTimeResponse.statusCode === 500) {
                $("#panChartlegend").removeClass("frypotLegendSection");
                $("#panChartDiv").addClass("noRecordMsg");
                $("#panChartlegend").addClass("displaynone");
                this.setState({ loaderIconVisible: false, message : panVsTimeResponse.content.body.stringID ?  panVsTimeResponse.content.body.stringID :  panVsTimeResponse.content.body.message});
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
            console.log('catch exception occured');
        });
    }

    onDropDownHandler(e) {
        const { nodeText:text } = e;
        this.setState({datapickflag : text === 'Date Range'})
        this.handleFrypotTimeChart(text);
    }

    handleFrypotTimeChart(text) {
        const { startDate, endDate } = this.state;

        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = localStorage.getItem("brandName");
        this.setState({ type: text ,message :'' })
        $("#panChartlegend").addClass("displaynone");
        if (text === "Date Range") {
            todaysDateFormatted = format(endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(startDate, 'YYYY-MM-DD');
        }
        else {
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
        }
        if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.getPanvsTimelinedata(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, '66');
        }
    }

    getDateDifferences(toDate, fromDate) {
        let totalMinutes = differenceInMinutes(toDate, fromDate);
        let days = Math.floor(totalMinutes / 60 / 24);
        let balanceMinutes = totalMinutes - days * 24 * 60;
        let hours = Math.floor(balanceMinutes / 60);
        let minutes = balanceMinutes % 60;
        let daysText = days > 1 ? reactIntl.formatMessage({ id: 'KC1894' }) : reactIntl.formatMessage({ id: 'KC0085' }); 
        let hoursText = hours > 1 ? reactIntl.formatMessage({ id: 'KC0280' }) : reactIntl.formatMessage({ id: 'KC0449' });
        let minutesText = minutes > 1 ? reactIntl.formatMessage({ id: 'KC1791' }) : reactIntl.formatMessage({ id: 'KC1790' });
        return ((days > 0) ? days + " " + daysText : "") + ((hours > 0) ? " " + hours + " " + hoursText : "") + ((minutes > 0) ? " " + minutes + " " + minutesText : "");
    }

    //Method to generate TimeLineChart
    renderPanStatesVsTime = (filterType, responsedata) => {

        if (responsedata && responsedata.length > 0) {
            am4core.useTheme(am4themes_animated);
            let chart = am4core.create("panChartDiv", am4charts.XYChart);
            this.chart = chart;
            chart.responsive.enabled = true;
            chart.svgContainer.hideOverflow = true;
            chart.cursor = new am4charts.XYCursor();
            chart.hiddenState.properties.opacity = 1; // this creates initial fade-in
            chart.fillColor = "#fff"
            chart.paddingRight = 30;
            let colorSet;
            chart.dateFormatter.inputDateFormat = "MM/dd/yyyy, h:mm a";
            chart.fill = am4core.color("#ffffff");
            colorSet = new am4core.ColorSet();
            colorSet.saturation = 1;

            responsedata.map(item => {
                item.fromDate = item.fromDate.replace('-', '/');
                item.toDate = item.toDate.replace('-', '/');
            });

            var errorData = responsedata.filter(function (data) {
                return data.name === "Error";
            });
            var errorGroups = _.groupBy(errorData, function (value) {
                return value.fromDate;
            });
            var groupsdata = _.map(errorGroups, function (group) {
                var errDesc = "";
                group.forEach(item => {
                    errDesc += item.task + " : " + moment(item.fromDate).format('MM/dd/yyyy, h:mm a');
                    if (item.toDate !== null) {
                        errDesc += " - " + moment(item.toDate).format('MM/dd/yyyy, h:mm a');
                    }
                    errDesc += "\n";
                });
                return {
                    fromDate: group[0].fromDate,
                    toDate: group[0].toDate,
                    name: "",
                    panSize: group[0].panSize,
                    bullet: require("../../../images/icons/error.png"),
                    task: errDesc,
                    color: "rgba(97,140,206,0.0)",
                }
            });
            var responsedata = _.remove(responsedata, function (data) {
                return data.name !== "Error";
            });
            groupsdata.forEach(item => {
                responsedata.push(item);
            });

            responsedata.forEach((item) => {
                item.strokeOpacity = 1;
                item.duration = this.getDateDifferences(format(item.toDate, 'YYYY-MM-DD HH:mm:00'), format(item.fromDate, 'YYYY-MM-DD HH:mm:00'));
                item.fromTime = format(item.fromDate, 'h:mm A');
                item.toTime = format(item.toDate, 'h:mm A');
                if (item.task === 'Unit Off') {
                    item.color = "#2d3034";
                }
            });

            chart.data = [...responsedata];
            chart.dateFormatter.dateFormat = "MM/dd/yyyy, h:mm a";
            chart.dateFormatter.inputDateFormat = "MM/dd/yyyy, h:mm a";
            let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.renderer.labels.template.fill = am4core.color("#fff");
            categoryAxis.renderer.labels.template.fontSize = 12;
            categoryAxis.renderer.line.strokeOpacity = .5;
            categoryAxis.renderer.line.strokeWidth = .5;
            categoryAxis.renderer.line.stroke = am4core.color("#fff");
            categoryAxis.dataFields.category = "name";
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.inversed = true;
            categoryAxis.renderer.cellStartLocation = 0.1;
            categoryAxis.renderer.cellEndLocation = 0.9;
            categoryAxis.renderer.minGridDistance = 10;

            let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
            dateAxis.renderer.labels.template.fill = am4core.color("#fff");
            dateAxis.baseInterval = [
                { timeUnit: "hour", count: 1 },
                { timeUnit: "day", count: 1 },
                { timeUnit: "week", count: 1 }];

            dateAxis.dataFields.category = "name";
            if (filterType === "Day") {
                dateAxis.dataFields.valueX = "hours";
            }
            dateAxis.renderer.labels.template.fontSize = 12;
            dateAxis.renderer.ticks.template.strokeOpacity = 1;
            dateAxis.renderer.line.strokeOpacity = 1;
            dateAxis.renderer.line.strokeWidth = 1;

            if (filterType === 'custom' || filterType === 'weekly') {
                dateAxis.tooltipDateFormat = "MM/dd/YYYY, h:mm a";
                dateAxis.dateFormatter.dateFormat = "MM/dd/YYYY, h:mm a";
                dateAxis.renderer.line.stroke = am4core.color("#fff");
                dateAxis.renderer.minGridDistance = 70;
                dateAxis.dateFormats.setKey("minute", "h:mm a");
                dateAxis.dateFormats.setKey("hour", "h:mm a");
                dateAxis.dateFormats.setKey("day", "MMM d   h:mm a");
                dateAxis.periodChangeDateFormats.setKey("hour", "MMM d   h:mm a");
                dateAxis.periodChangeDateFormats.setKey("minute", "h:mm a");

                dateAxis.renderer.tooltipLocation = 0;
                let label = dateAxis.renderer.labels.template;
                label.wrap = true;
                label.maxWidth = 80;
            }
            else {
                dateAxis.renderer.line.stroke = am4core.color("#fff");
                dateAxis.tooltipDateFormat = "h:mm a";
                dateAxis.dateFormatter.dateFormat = "yyyy-MM-dd h:mm a";
                dateAxis.renderer.minGridDistance = 70;
                dateAxis.dateFormats.setKey("hour", "hh:mm a");
                dateAxis.dateFormats.setKey("minute", "hh:mm a");
                dateAxis.periodChangeDateFormats.setKey("hour", "hh:mm a");
                dateAxis.periodChangeDateFormats.setKey("minute", "hh:mm a");
                dateAxis.renderer.tooltipLocation = 10;
            }

            let series1 = chart.series.push(new am4charts.ColumnSeries());
            series1.dataFields.openDateX = "fromDate";
            series1.dataFields.dateX = "toDate";
            series1.name = "Idle";
            series1.dataFields.categoryY = "name";
            let withoutErrorTooltip = "";
            if (window.screen.width < 900) {
                withoutErrorTooltip = `{task}  : {fromTime}[/] - {toTime}[/]
            ${reactIntl.formatMessage({id : "KC0197"})} : {duration}`;
                series1.tooltip.pointerOrientation = "down";
            }
            else {
                withoutErrorTooltip = `{task}  : {fromTime}[/] - {toTime}[/] ; ${reactIntl.formatMessage({id : "KC0197"})} : {duration}`;
            }
            let withErrorTooltip = "{task}";
            chart.data.forEach(item => {
                if (item.name !== "" && item.name !== "Error") {
                    series1.columns.template.tooltipText = withoutErrorTooltip;
                } else {
                    series1.columns.template.tooltipText = withErrorTooltip;
                }
            });
            series1.columns.template.dx = 0;
            series1.columns.template.propertyFields.fill = "color"; // get color from data
            series1.columns.template.propertyFields.stroke = am4core.color("#fff");
            series1.columns.template.propertyFields.strokeOpacity = "strokeOpacity";
            series1.columns.template.stroke = am4core.color("#fff");
            series1.columns.template.fillOpacity = 1;

            // Add scrollbar
            chart.scrollbarX = new am4core.Scrollbar();
            chart.scrollbarX.showSystemTooltip = false;
            chart.scrollbarX.marginLeft = 0;
            chart.scrollbarX.dragIconHeight = 1;
            chart.scrollbarX.dragIconWidth = 1;
            chart.scrollbarX.marginBottom = 30;

            function customizeGrip(grip) {
                grip.showSystemTooltip = false;
                grip.icon.disabled = true;
                grip.background.disabled = true;
                grip.background.strokeOpacity = 0;
                let img = grip.createChild(am4core.Circle);
                img.width = 25;
                img.height = 25;
                img.fill = am4core.color("#327ed0");
                img.align = "center";
                img.valign = "middle";
            }

            chart.scrollbarX.thumb.background.fill = am4core.color("#195d99");
            chart.scrollbarX.thumb.background.fillOpacity = 1;
            chart.scrollbarX.thumb.background.stroke = am4core.color("#195d99");
            chart.scrollbarX.thumb.background.strokeWidth = 0;
            chart.scrollbarX.thumb.background.strokeOpacity = .8;
            chart.scrollbarX.thumb.background.states.getKey('hover').properties.fill = am4core.color("#195d99");
            chart.scrollbarX.thumb.background.states.getKey('down').properties.fill = am4core.color("#195d99");

            customizeGrip(chart.scrollbarX.startGrip);
            customizeGrip(chart.scrollbarX.endGrip);

            chart.scrollbarX.start = dateAxis.start;
            chart.scrollbarX.end = dateAxis.end;

            // Add cursor
            chart.cursor = new am4charts.XYCursor();
            chart.cursor.behavior = "zoomX";
        }
    }

    componentWillUnmount = () => {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    render() {
        const { type, message,loaderIconVisible } = this.state;
        return (
            <React.Fragment>
                <LoadingPopup loading={loaderIconVisible} />
                <h4>{this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
                    <NewDropDownListLink
                        translation={true}
                        datapickflag={this.state.datapickflag}
                        startDatepara={this.state.startDate}
                        minDateDisable={this.state.sdmindate}
                        maxDateDisable={this.state.sdmaxdate}
                        endminDateDisable={this.state.edmindate}
                        endmaxDateDisable={this.state.edmaxdate}
                        endDatepara={this.state.endDate}
                        handleChangeStartProps={(date) => this.handleChangeStart(date)}
                        handleChangeEndProps={(date) => this.handleChangeEnd(date)}
                        OnChange={(e) => this.onDropDownHandler(e, "FrypotTimeChart")}
                        filterType={type}
                        disableMonthFilter={true}
                        disableQuarterFilter={true}
                        disableYearFilter={true}
                        disableDatePicker={false}
                        maxDaysRequired={6} />
                </h4>
                <div id="panChartDiv" className="noRecordMsg">{message != '' ?<FormattedMessage id={message}/>: null }</div>
                <ul id="panChartlegend" className="displaynone">
                    <li><span></span><FormattedMessage id="KC0453"/></li>
                    <li><span></span><FormattedMessage id="KC1274"/></li>
                    <li><span></span><FormattedMessage id="KC0455"/></li>
                    <li><span></span><FormattedMessage id="KC0456"/></li>
                    <li><span></span><FormattedMessage id="KC0360"/></li>
                    <li><span></span><FormattedMessage id="KC0361"/></li>
                </ul>
            </React.Fragment>
        )
    }
};
export default FrypotTimeChart;
