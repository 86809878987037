import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { injectIntl, FormattedMessage } from 'react-intl';
import authService from '../../../../../service/authService';
import http from '../../../../../service/httpService';
import { MenuUploadAPI } from '../../../../../service/api';
import { CUSTOMER_ID, BASECOUNTRY_ID } from '../../../../../utils/appConstants';
import moment from '../../../../../utils/momentHelper.js';
import $ from 'jquery';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'

class GarlandCookbookProductRecipe extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            allMenuRecipes: [],
            selectedMenuRecipes: [],
            selectedPRRecipes: [],
            search: '',
        }
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        this.updateDimensions();
        $(".mCustomScrollbar").mCustomScrollbar();
        let recipeDetails = JSON.parse(localStorage.getItem("selectedPRRecipes"));
        this.setState({
            selectedPRRecipes: recipeDetails,
        }, async () => {
            await this.getLibraryList()
        })
    }

    getAllMenuRecipeDetails = () => {
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let menuRecipe = [];
        categoryDetailsObj && categoryDetailsObj.length > 0 && categoryDetailsObj.map((details, i) => {
            details.recipe_items && details.recipe_items.length > 0 && details.recipe_items.map((item, i) => {
                menuRecipe.push(item);
            })
        })

        const allMenuRecipes = _.uniqBy(menuRecipe, 'ID');
        allMenuRecipes.forEach(v => {
            if (v.checked === true) v.checked = false;
        });

        this.setState({
            allMenuRecipes: allMenuRecipes.length > 0 ? allMenuRecipes : [],
        })


        let prDetails = localStorage.getItem("ProductRecgDetails");
        let recipeFrmPrObj = JSON.parse(prDetails);
        if (recipeFrmPrObj) {
            let recipeFrmPr = recipeFrmPrObj.recipe_items;
            if (recipeFrmPr && recipeFrmPr.length > 0) {
                this.compareTwoArr(allMenuRecipes, recipeFrmPr)
            }
        }
    }

    compareTwoArr = (array1, array2) => {
        const prList = _.forEach(array1, a1 => {
            const ifExist = _.find(array2, a2 => (a2.ID === a1.ID));
            a1.checked = ifExist ? true : false;
            if(ifExist){
                a1.maxGap = ifExist.maxGap;
                a1.minGap = ifExist.minGap;
            }
        });

        this.setState({
            allMenuRecipes: prList,
        })
    }

    updateDimensions = () => {
        var windowHeight = $(window).height();
        var menuFilterHeader = $(".menuFilterHeader").height();
        var pageRRHeight = ((windowHeight) - (menuFilterHeader)) - 105;
        $(".readyRecipeSrlTable").css("height", pageRRHeight);
    }

    onSelectLibrary = (e, item) => {
        if (this.state.allMenuRecipes && this.state.allMenuRecipes.length > 0) {
            const libraries = [...this.state.allMenuRecipes];
            const index = libraries.indexOf(item);
            libraries[index] = { ...libraries[index] };
            libraries[index].checked = e.target.checked;

            let selectedMenuRecipes = libraries.filter(c => c.checked === true);

            this.setState({
                allMenuRecipes: libraries,
                isSelectAllLibrary: selectedMenuRecipes.length === libraries.length,
                selectedMenuRecipes: selectedMenuRecipes
            });
        }
    }

    onConfirmClick = () => {
        let { selectedMenuRecipes } = this.state;
        if (selectedMenuRecipes && selectedMenuRecipes.length > 0) {
            localStorage.setItem("selectedPRRecipes", JSON.stringify(selectedMenuRecipes));
        }
        this.props.history.push({
            pathname: "/garlandCookbookProductCreation",
            isEdit: false,
            previouspath: "/garlandCookbookProductRecipe"
        });

    }

    getLibraryList = () => {
        let { selectedPRRecipes } = this.state;
        this.setState({
            loaderIconVisible: true,
            previouspath: this.props.history.location.previouspath
        });

        return new Promise((resolve, reject) => {
            http.get(MenuUploadAPI.getLibraryList,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'customerid': CUSTOMER_ID,
                        'cgids': localStorage.getItem("custGroupID"),
                        'brandid': localStorage.getItem("selectedbrandID"),
                        'modelfamilyids': localStorage.getItem("selectedFamilyID"),
                        'basecountry': BASECOUNTRY_ID,
                        'Type': "Recipe"
                    },
                    data: {}
                }).then(response => {
                    let { data } = response;
                    let libraryList = data && data.libraryList ? data.libraryList : [];

                    libraryList.length > 0 && libraryList.map(item => {
                        item.CREATED_ON = moment.ConvertLocalTimeFromUTCTime(item.CREATED_ON, "DD/MM/YYYY");
                        item.checked = false;
                        item.minGap = 0;
                        item.maxGap = 0;

                        selectedPRRecipes && selectedPRRecipes.length > 0 && selectedPRRecipes.map(recipeitem => {
                            if (item.ID === recipeitem.ID) {
                                item.checked = true;
                                item.maxGap = recipeitem.maxGap ? recipeitem.maxGap : 0;
                                item.minGap = recipeitem.minGap ? recipeitem.minGap : 0;
                            }
                        });
                    });

                    this.setState({
                        loaderIconVisible: false,
                        allMenuRecipes: libraryList,
                    });
                    resolve(response);
                }).catch(err => {
                    this.setState({
                        loaderIconVisible: false,
                    });
                    reject(err);
                });
        });
    }

    recipeSearchResult = (e) => {
        this.setState({ search: e.target.value });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { loaderIconVisible, allMenuRecipes, selectedMenuRecipes, search } = this.state;
        let libraryAuthFeature = authService.getFeature("/libraryList");
        const searchedItemInFilter = allMenuRecipes && allMenuRecipes.filter(
            (recipe) => {
                return recipe.LIBRARY_NAME.toLowerCase().indexOf(search.toLowerCase()) !== -1;
            }
        );
        allMenuRecipes = searchedItemInFilter;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658" /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li className="selectedModel">ME, MG</li>
                            </ul>
                        </div>

                        <div className="rcDeviceWrapper">
                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} /> <button type="button" onClick={() => this.props.history.push('/garlandCookbookToTopology')} className="btn saveExitBtn">Save File &amp; Exit</button></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={() => this.props.history.replace('/garlandCookbookProductCreation')} className="btn backButton">&nbsp;</button>
                                    Product Recognition
                                </div>
                                <div className="cbSearchForm">
                                    <ul>
                                        <li>
                                            <div className="cbRSearchBox">
                                                <input type="text" className="form-control" onChange={(e) => this.recipeSearchResult(e)} placeholder="Search Recipe" />
                                            </div>
                                            <button type="button" className="btn cbRSearchButton">&nbsp;</button>
                                        </li>
                                        <li>
                                            <button type="button" className={selectedMenuRecipes && selectedMenuRecipes.length > 0 ? "btn recipeConfirmBtn" : "btn recipeConfirmBtn disabled"} onClick={() => this.onConfirmClick()}>Confirm</button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="reBodyWrapper withSearchBar mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn alignTop">
                                                {
                                                    allMenuRecipes.length > 0 && allMenuRecipes.map((item, i) => {
                                                        return (
                                                            <div className="cbAddRecipe" key={i}>
                                                                <ul>
                                                                    <li><img src={item.IMAGE_S3_SIGNED_URL ? item.IMAGE_S3_SIGNED_URL : require("../../../../../images/recipecreation/Default Recipe.bmp")} alt="" /></li>
                                                                    <li>{item.LIBRARY_NAME}</li>
                                                                    <li>{item.CREATED_ON}</li>
                                                                    <li>
                                                                        <div className={libraryAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"} >
                                                                            <input id={item.ID} name="RecipesList" type="checkbox" value={item.checked} checked={item.checked} onChange={(e) => this.onSelectLibrary(e, item)} />
                                                                            <label htmlFor={item.ID}></label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </React.Fragment >
        )
    }
}

export default injectIntl(GarlandCookbookProductRecipe)
