import api from '../../../service/api.js';
import http from '../../../service/httpService.js';
import moment from '../../../utils/momentHelper.js';
import { BASECOUNTRY_ID } from '../../../utils/appConstants.js';
import { MediaManagementAPI } from '../../../service/api.js';
export const MEDIA_PREPARATION_LIST_REQUEST = 'MEDIA_PREPARATION_LIST_REQUEST';
export const MEDIA_PREPARATION_LIST_SUCCESS = 'MEDIA_PREPARATION_LIST_SUCCESS';
export const MEDIA_PREPARATION_LIST_FAIL = 'MEDIA_PREPARATION_LIST_FAIL';
export const MEDIA_SELECTED_LIST_SUCCESS = 'MEDIA_SELECTED_LIST_SUCCESS';


export function getMediaPreparationList(searchParam, brand, type) {
    const userListEmail = JSON.parse(localStorage.getItem('childUsersEmail'));
    const { username } = JSON.parse(localStorage.getItem('loginInfo'));
    const roleName = localStorage.getItem('roleName') || '';

    let custGroupID = localStorage.getItem("custGroupID");
    let cgids = "";
    if (custGroupID != "" && custGroupID != undefined) {
        cgids = custGroupID;
    }

    return dispatch => {
        dispatch({
            type: MEDIA_PREPARATION_LIST_SUCCESS,
            mediaPreparationListInformation:[],
        })
        dispatch({
            type: MEDIA_PREPARATION_LIST_REQUEST
        })
        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json',
                'customer': localStorage.getItem("customerId"),
                'brand': brand,
                'type': type,
                'cgids': cgids,
                'basecountry': BASECOUNTRY_ID
            };
            if (searchParam) {
                headers = Object.assign(headers, searchParam);
            }
            http.get(`${MediaManagementAPI.getMediaBrandCustomer}`,
                {
                    headers,
                    data: {}

                })
                .then(response => {

                    const { mediaList } = response.data;
                    let userFilteredMedia = { mediaList: [] };
                    if (mediaList) {
                        mediaList.forEach(item => {
                            if(item.UPLOADED_BY && item.UPLOADED_BY.toLowerCase() === username.toLowerCase()){
                                item.CREATEDAT = moment.ConvertLocalTimeFromUTCTime(item.CREATEDAT, "MMMM DD, YYYY hh:mm A");
                                /* let isDelete = false;
                                if (item.UPLOADED_BY && item.UPLOADED_BY.toString().match('@')) {
                                    isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(item.UPLOADED_BY) || username === item.UPLOADED_BY ? true : false;
                                } else {
                                    isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
                                }
                                item.isAuthorized = isDelete; */
                                item.isAuthorized = true;
                                userFilteredMedia.mediaList.push(item);
                            }
                        });
                    }
                    dispatch({
                        type: MEDIA_PREPARATION_LIST_SUCCESS,
                        mediaPreparationListInformation: userFilteredMedia,
                    })
                    resolve(userFilteredMedia)
                })
                .catch(error => {
                    dispatch({
                        type: MEDIA_PREPARATION_LIST_FAIL,
                        mediaPreparationListInformation: {},
                        error
                    })
                    resolve(error)
                })
        })
    }
}

export function mediaSelectedItems(selectedItems) {
    return dispatch => {
        dispatch({
            type: MEDIA_SELECTED_LIST_SUCCESS,
            mediaSelectedItems:selectedItems,
         })
    }
}








