import axios from 'axios';
import api from '../../service/api';
import http from '../../service/httpService';
import {BaseService, ReportManagement} from '../../service/api';
export const AVERAGE_DRINKS_LIST_REQUEST = 'AVERAGE_DRINKS_LIST_REQUEST';
export const AVERAGE_DRINKS_LIST_SUCCESS = 'AVERAGE_DRINKS_LIST_SUCCESS';
export const AVERAGE_DRINKS_LIST_FAIL = 'AVERAGE_DRINKS_LIST_FAIL';

export function getAverageDrinksList (type, fromdate, todate, deviceId, brandid) {
  let url ;
  let filterType;
  switch (type) {
    case 'Week':
        filterType='weekly';        
        break; 
      case 'Year':
          filterType='yearly';            
          break; 
      case 'Day':            
          filterType='daily';
          break;
      case 'Quarter':
          filterType='quarterly';            
          break; 
      case 'Date Range':
          filterType = 'custom';
          break; 
      default: 
          filterType = 'monthly';              
    }    
  //url = api.averageDrinksProduced.replace("{deviceIdvalue}", deviceId).replace("{fromDatevalue}", fromdate).replace("{filterTypeValue}", filterType)
//   url = `${BaseService.root}${ReportManagement.countroot}`;
url = `${ReportManagement.countroot}`;

    return dispatch => {
        dispatch({
            type: AVERAGE_DRINKS_LIST_REQUEST
        })
          
        let header;

        if (filterType === 'weekly' || filterType === 'custom')
        {
            header = {
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin':'*',
                'unitid' : deviceId,
                'brandid' : brandid,
                'startdate' : todate,
                'enddate' : fromdate,
                'filterType' : filterType,
                'op' : 'avg',
                'cid':localStorage.getItem("customerId"),
              }; 
        }
        else
        {
            header = {
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin':'*',
                'unitid' : deviceId,
                'brandid' : brandid,
                'reqdate' : fromdate,
                'filterType' : filterType,
                'op' : 'avg',
                'cid':localStorage.getItem("customerId"),
              }
        }

        http.get(url,
                {
                  headers: header,
                  data:{}
                })
            .then(response => {
                dispatch({
                    type: AVERAGE_DRINKS_LIST_SUCCESS,
                    averageDrinksInformation: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: AVERAGE_DRINKS_LIST_FAIL,
                    error
                })
            })
    }
}