import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, MenuUploadAPI } from '../../../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../../../service/httpService';
import _ from 'lodash';
import { brandLocations, unitModels } from '../../../../../utils/imgContants';
import { setUserDataModel } from '../../../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import { injectIntl } from 'react-intl';
import ViewTopology from '../../../../../components/controls/viewTopology';
var beautify = require("json-beautify");
var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

const cookingMethodologyMapping = {
    'Steel': 0,
    'Standard': 1,
    'Steel / Water': 2,
    'LRS / Water': 3
}

class GarlandCookbookToTopology extends Component {
    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/libraryList`);
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            console.log("response", response)
            console.log("data", data)
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : data.message ? data.message : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : data.message ? data.message : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        // if (libraryDetails.initialMethod === "Upload") {
        //     this.props.history.push({
        //         pathname: "/uploadSelectDevice",
        //         previouspath: "/garlandCookbookToTopology"
        //     });
        // }
        // else {
        this.props.history.push({
            pathname: "/garlandCookbookEditor",
            // isEdit: false,
            previouspath: "/garlandCookbookToTopology",
        });
        // }
    }

    f2c = (fahrenheit) => {
        return Math.round((fahrenheit - 32) / 1.8);
    }

    f2cGrill = (fahrenheit) => {
        return Math.round((fahrenheit) / 1.8);
    }

    submitAddLibrary = (e, _customId) => {
        e.preventDefault();
        let _this = this;
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let cookBookName = localStorage.getItem("cookBookFileName")
        let httpMethod;

        if (!categoryDetailsObj && !categoryDetailsObj.length > 0) {
            _this.props.history.push(`/garlandCookbookEditor`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = localStorage.getItem("brandName");
            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
                    'base_country': localStorage.getItem("basecountry"),
                    'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(response => {
                let libraryId;
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                if (libraryDetails.isEdit) {
                    httpMethod = "PUT";
                    libraryId = libraryDetails.library_id;
                } else {
                    httpMethod = "POST";
                    libraryId = uuidv1();
                }

                let jsonTemplateObj = {};
                let categoryDetailsData = localStorage.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetailsData);

                let imageSize = 0.00;
                let menuFileFormation = [];
                let categoryMenuList = [];
                let recipeAdded = categoryDetailsObj ? categoryDetailsObj : [];
                for (let index = 0; index < recipeAdded.length; index++) {
                    const element = recipeAdded[index];
                    let recipe_items = [];
                    if (element && element.recipe_items && element.recipe_items.length) {
                        for (let index = 0; index < element.recipe_items.length; index++) {
                            const recipeElement = element.recipe_items[index];
                            imageSize = imageSize + (recipeElement.IMAGE_FILE_SIZE ? parseFloat(recipeElement.IMAGE_FILE_SIZE) : 0.00);
                            recipe_items.push(recipeElement.LIBRARY_GUID);
                        }
                    }
                    let obj = {
                        "columns": 4,
                        "icon": element.imageFileName || "",
                        "isOn": 1,
                        "is_flat": element.flat_Cooking === "Off" ? 0 : 1,
                        "menuID": element.ID,
                        "menuName": element.categoryName,
                        "unit": element.unit,
                        // decicelsius conversion
                        "preHeatLowerFront": element.grill_Front_limit && element.grill_Front_limit !== "Off" ? element.unit === "°F" ? Number(this.f2c(element.grill_Front_limit)) * 10 : Number(element.grill_Front_limit) * 10 : 0,
                        "preHeatLowerPlaten": element.grill_Plate_limit && element.grill_Plate_limit !== "Off" ? element.unit === "°F" ? Number(this.f2c(element.grill_Plate_limit)) * 10 : Number(element.grill_Plate_limit) * 10 : 0,
                        "preHeatUpperPlaten": element.upper_Platen_limit && element.upper_Platen_limit !== "Off" ? element.unit === "°F" ? Number(this.f2c(element.upper_Platen_limit)) * 10 : Number(element.upper_Platen_limit) * 10 : 0,
                        "recipeList": recipe_items,
                        "rows": 2,
                        "schemaVersion": "1.0"
                    }
                    imageSize = imageSize + (element.imageFileSize ? parseFloat(element.imageFileSize) : 0.00);
                    menuFileFormation.push(obj);
                    let menuJSON = obj; 
                    let { unit, ...rest } = menuJSON;
                    let menuObj = {
                        "ID": element.ID,
                        "imageFileName": element.imageFileName || "",
                        "imageFileFormat": ".png",
                        "imageFileSize": element.imageFileSize || 0,
                        "imageS3Url": element.imageS3Url || 0,
                        "categoryName": element.categoryName,
                        "type": "Category",
                        "parentID": "",
                        "selected": false,
                        recipe_items,
                        menuJSON: {...rest}
                    }
                    categoryMenuList.push(menuObj)
                }
                jsonTemplateObj.menus = menuFileFormation;
                // productReg formation
                const productReg = localStorage.getItem('ProductRecgDetails') ? JSON.parse(localStorage.getItem('ProductRecgDetails')) : {};
                const productRegRecipe = productReg && productReg.recipe_items ? productReg.recipe_items : [];
                const isProductRecognitionAdded = productRegRecipe.length > 0 ? true : false;
                let productRegJSON = {};
                if (isProductRecognitionAdded) {
                    let cookingRegions = [];
                    if (productReg && productReg.recipe_items && productReg.recipe_items.length) {
                        for (let index = 0; index < productReg.recipe_items.length; index++) {
                            const element = productReg.recipe_items[index];
                            let obj = {
                                // deci micron conversion
                                "gapLowerLimit": Number(element.minGap) * 100000,
                                "gapUpperLimit": Number(element.maxGap) * 100000,
                                "regionRecipeID": element.LIBRARY_GUID
                            }
                            cookingRegions.push(obj);
                        }
                    }
                    productRegJSON = {
                        "active_compression": 0,
                        "cookingRegions": cookingRegions,
                        "cookingSteps": [
                            {
                                "CookTime": 0,
                                "Gap": 25400,
                                "PromptSelection": "none",
                                "tempLowerFront": productReg && productReg.grill_Front_limit && productReg.grill_Front_limit !== "Off" ? productReg.unit === "°F" ? Number(this.f2c(productReg.grill_Front_limit)) * 10 : Number(productReg.grill_Front_limit) * 10 : 0,
                                "tempLowerPlaten": productReg && productReg.grill_Plate_limit && productReg.grill_Plate_limit !== "Off" ? productReg.unit === "°F" ? Number(this.f2c(productReg.grill_Plate_limit)) * 10 : Number(productReg.grill_Plate_limit) * 10 : 0,
                                "tempUpperPlaten": productReg && productReg.upper_Platen_limit && productReg.upper_Platen_limit !== "Off" ? productReg.unit === "°F" ? Number(this.f2c(productReg.upper_Platen_limit)) * 10 : Number(productReg.upper_Platen_limit) * 10 : 0,
                            }
                        ],
                        "cooking_methodology": productReg && productReg.cookingMethodology ? cookingMethodologyMapping[productReg.cookingMethodology] : "",
                        "icon": "",
                        "partial_load": {
                            "elapsed_time": 0,
                            "enabled": 0,
                            "temp_delta": 0,
                            "time_scale_factor": 0
                        },
                        "pr_thaw": {
                            "detect_delta_gap": 0,
                            "enabled": 0,
                            "time": 0
                        },
                        "protein_type": "beef",
                        "unit": productReg && productReg.unit ? productReg.unit : "°C",
                        "recipeID": productReg && productReg.recipeID ? productReg.recipeID : "",
                        "recipeName": productReg && productReg.recipeName ? productReg.recipeName : "Auto",
                        "recipe_type": 2,
                        "schemaVersion": "1.1",
                        "smokeFanStart_T_Minus": 0,
                        "smokeFanStop_T_Plus": 0,
                        "too_cold_limit": productReg && productReg.tooColdLimitValue ? productReg.unit === "°F" ? Number(this.f2cGrill(productReg.tooColdLimitValue)) * 10 : Number(productReg.tooColdLimitValue) * 10 : 0,
                        "too_hot_limit": productReg && productReg.tooHotLimitValue ? productReg.unit === "°F" ? Number(this.f2cGrill(productReg.tooHotLimitValue)) * 10 : Number(productReg.tooHotLimitValue) * 10 : 0
                    }
                    jsonTemplateObj.product_recognition = productRegJSON;
                }


                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);
                let md5 = require('md5');
                let crc = md5(jsonBuffer, "base64");

                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": cookBookName,
                    "libraryFileName": libraryId,
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + imageSize ? Number(fileSize) + Number(imageSize) : 0).toFixed(2),
                    "libraryS3Url": "",
                    "type": "CookBook",
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": "",
                    "imageFileFormat": "",
                    "imageFileSize": imageSize ? (imageSize).toFixed(2) : "",
                    "imageS3Url": "",
                    "cgids": _customId,
                    "json": JSON.stringify(jsonTemplateObj),
                    "categories": categoryMenuList ? categoryMenuList : [],
                    "libraryGuid": libraryId
                };

                if (isProductRecognitionAdded) {
                    delete productRegJSON.unit;
                    libraryPostData['product_recognition'] = productRegJSON;
                }

                if (libraryDetails.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': "",
                    'jsonfilekey': libraryId,
                    'audiofilekey': "",
                    'isthumbnail': false
                }

                uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                    _this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: (httpMethod === "PUT" && libraryDetails.initialMethod === "Edit") ? "Menu updated successfully." : "Menu saved successfully."
                        },
                    });
                }).catch(err => {
                    _this.showError(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log('catch exception occured');
            });
        }
    }

    changeLoadingStatus = (value) => {
        this.setState({ loaderIconVisible: value })
    }

    render() {
        let { success_modal, error_modal } = this.state;
        const { loaderIconVisible } = this.state;
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology
                    title={`Assign menu to library`}
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    onBackClickHandler={this.onBackClickHandler}
                    modelFamilyIds={localStorage.getItem("selectedFamilyID")}
                    preSelect={libraryDetails.isEdit ? true : false}
                    restrictUnitSelect={true} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GarlandCookbookToTopology));