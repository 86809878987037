import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators, compose } from 'redux';
import { getMenuList } from '../../../actions/menuManagement/addMenusList';
import { unitAPI, MenuUploadAPI } from '../../../service/api';
import http from '../../../service/httpService';
import { BRAND_ID, RESTRICTED_CHAR, restrictedCharAvl } from '../../../utils/appConstants';
import { ModelFamilyBuilder } from '../../../utils/unitResponseBuilder';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import ErrorMoal from '../../modal/error-model';
import { format } from 'date-fns';

class copyLibrary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            error_modal: {
                open: false,
                message: ""
            },
            modelList: [],
            copyModel: '',
            fileName: '',
            description: '',
            characterCount: 250,
            default_Count: 250,
            copyItem: {}
        };
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#F2F2F2";
        const copyItem = localStorage.getItem('copyLibrary') ? JSON.parse(localStorage.getItem('copyLibrary')) : {};
        const description = copyItem.description ? copyItem.description : copyItem.DESCRIPTION ? copyItem.DESCRIPTION : '';
        const charCount = description.trim().length;
        const maxCharCount = this.state.default_Count;
        const characterCount = maxCharCount - charCount;
        let fileName = '';
        if(copyItem.fileName){
            fileName = copyItem.fileName;
        }else{
            fileName = copyItem.LIBRARY_NAME;
            if(fileName.length > 15){
                fileName = fileName.substring(0,15);
            }
            fileName = fileName + " Copy"
        }
       
        this.setState({
            fileName,
            description,
            copyModel: copyItem.copyModel ? copyItem.copyModel : copyItem.MODEL_FAMILY[0].ID,
            copyItem,
            characterCount
        })
        await this.getModelFamilies();
    }

    getModelFamilies = () => {
        this.setState({
            loaderIconVisible: true
        });

        return new Promise((resolve, reject) => {
            http.get(unitAPI.getModelFamilies,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'brandid': BRAND_ID
                    },
                    data: {}
                }).then(async response => {
                    let { data } = response;
                    let modelList = data && data.body ? data.body : [];
                    modelList = await modelList.filter(m => m.MODEL_FAMILY_ID !== 3 && m.MODEL_FAMILY_ID !== 4 && m.MODEL_FAMILY_ID !== 5 && m.MODEL_FAMILY_ID !== 38 && m.MODEL_FAMILY_ID !== 39);
                    modelList = modelList.filter(item => item.BRAND_ID === 1);

                    modelList = await ModelFamilyBuilder(modelList);
                    this.setState({
                        modelList,
                        loaderIconVisible: false
                    });
                    resolve(response);
                }).catch(err => {
                    this.setState({ loaderIconVisible: false })
                    reject(err);
                });
        });
    }

    handleWordCount = (e) => {
        // if (e.target.value.trim().match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            if (e.target.value.trim().length > 250) {
                this.setState({
                    error_modal: {
                        open: true,
                        message: "KC2265",
                    },
                });
                this.setState({
                    description: this.state.description.substring(0, 250)
                })
            }
            else {
                const charCount = e.target.value.trim().length;
                const maxCharCount = this.state.default_Count;
                const charLeft = maxCharCount - charCount;
                this.setState({
                    characterCount: charLeft,
                    description: e.target.value
                });
            }
        // } else {
        //     this.setState({
        //         description: '',
        //         characterCount: this.state.default_Count,
        //         error_modal: {
        //             open: true,
        //             message: "Please provide some valid description.",
        //         },
        //     });
        // }
    }

    ValidateinputField = (e) => {
        let restrictedChar = RESTRICTED_CHAR.split('').some(i => restrictedCharAvl(i,e.target.value));
        if (restrictedChar || e.nativeEvent.data === '\\'){
            return;
        }
        var maxLimit = 20;
        let _flag = "";
        if (e.target.value.trim().length > 0) {
            this.setState({
                errorCopyName: ''
            });
        }
        if (e.target.value.trim().length > maxLimit) _flag = "max"
        // if (e.target.value == "" && e.target.value.trim().length < minLimit) _flag = "min"
        if (_flag == "max" || _flag == "min") {
            this.setState({
                errorCopyName: 'KC2282',
                fileName: this.state.fileName.substring(0, maxLimit)
            });
        } else {
            this.setState({
                fileName: e.target.value
            })
        }
    }

    onModelCopySelection = (item) => {
        this.setState({ copyModel: item.MODEL_FAMILY_ID })
    }

    processNext = () => {
        let { copyItem, fileName, description, copyModel } = this.state;
        if (fileName && fileName.length > 20) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "File name maximum character limit is 20."
                }
            });
            return;
        }
        if (description && description.length > 250) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "Description maximum character limit is 250."
                }
            });
            return;
        }
        copyItem['fileName'] = fileName;
        copyItem['description'] = description;
        copyItem['copyModel'] = copyModel;
        localStorage.setItem('copyLibrary', JSON.stringify(copyItem));
        localStorage.setItem("selectedFamilyID", copyModel)
        const ids = copyItem && copyItem.ASSIGNED_CUSTOMER_GROUP ? copyItem.ASSIGNED_CUSTOMER_GROUP.map((obj) => obj.cust_gp_id) : [];
        localStorage.setItem('selectedGroupID', ids.join(','));
        if (localStorage.getItem("selectedbrandID") === '1'){
            this.submitAddLibrary()
        } else {
            this.props.history.push("/assignCopyLibraryToTopology");
        }
    }

    submitAddLibrary = (e, _customId) => {
        let copyDetails = JSON.parse(localStorage.getItem("copyLibrary"));
		this.setState({
			loaderIconVisible: true
		});
		var todaysDate = new Date();
		let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');
		let getLibraryName = copyDetails.fileName;
		let getLibraryDescription = copyDetails.description;
		return new Promise((resolve, reject) => {
			let current_Item = copyDetails;
			let postData = {
				libraryId: current_Item.LIBRARY_ID,
				libraryName: getLibraryName,
				description: getLibraryDescription,
				createdBy: current_Item.CREATED_BY,
				localDateTime: todaysDateFormatted,
				modelFamilyIds: copyDetails.copyModel,
                "cgids":[localStorage.getItem("parentCustGroupId")]
			}
			let url = `${MenuUploadAPI.createCopyLibrary}`
			http({
				method: 'POST',
				url: url,
				data: postData,
				headers: {
					'Content-Type': 'application/json'
				}
			}).then(async (res) => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== "HTTP_200") {
					reject({
						response: {
							data: res.data
						}
					});
					this.setState({
						loaderIconVisible: false
					});
				} else {
					resolve(res);
					this.props.history.push(`/libraryList`);
				}
			}).catch(err => {
				reject(err);
				this.setState({
					loaderIconVisible: false
				});
			});
		});
	}

    closeModel = () => {
        this.setState({
            loaderIconVisible: false,
            error_modal: {
                open: false,
                message: ''
            }
        })
    }

    render() {
        let { error_modal, loaderIconVisible, modelList, fileName, description, copyModel, characterCount } = this.state;
        const isEnabled = fileName && fileName.trim() !== "" && copyModel !== "";
        const { formatMessage } = this.props.intl;

        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="adminBgHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" class="backIcon" title={formatMessage({ id: 'KC0989' })}>&nbsp;</Link>
                                </li>
                                <li>
                                    <FormattedMessage id="KC2263" />
                                </li>
                                <li>
                                    <button type="button" className={`btn finishBtn ${isEnabled ? 'activeState' : 'disabled'}`} title={formatMessage({ id: localStorage.getItem("selectedbrandID") === '1' ? 'KC0056' : 'KC0543' })} onClick={this.processNext}>{localStorage.getItem("selectedbrandID") === '1' ? <FormattedMessage id="KC0056" /> : "Next"}</button>
                                </li>
                            </ul>
                        </div>

                        <div class="formWrapper">
                            <div class="formWrapperL">
                                <div class="formList">
                                    <ul>
                                        <li><label><FormattedMessage id="KC1523" /> *</label></li>
                                        <li>
                                            <input type="text" class="form-control" placeholder={formatMessage({ id: 'KC2934' })} maxLength="20" value={fileName} onChange={(e) => this.ValidateinputField(e)} />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="formWrapperR">
                                <div class="formList">
                                    <ul>
                                        <li><label><FormattedMessage id="KC0195" /></label></li>
                                        <li>
                                            <textarea class="form-control" rows="2" cols="50" placeholder={formatMessage({ id: 'KC2700' })} value={description} onChange={(e) => this.handleWordCount(e)}></textarea>
                                            <div class="charCount">{characterCount}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div class="clear"></div>
                            <div class="formList">
                                <ul>
                                    <li><label><FormattedMessage id="KC2699" /></label></li>
                                    <li>
                                        <div class="selectModelGrid">
                                            <ul>
                                                {modelList.map((item) => {
                                                    return (
                                                        <li className={item.MODEL_FAMILY_ID === copyModel ? "selectedList" : ""} onClick={() => this.onModelCopySelection(item)}>
                                                            <div class="selectDeviceGridW">
                                                                <span><img alt={item.Brand_Alt} src={item.Brand_Logo} /></span>
                                                                <h5>{item.MODEL_FAMILY_NAME}</h5>
                                                            </div>
                                                        </li>
                                                    )
                                                }
                                                )}
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        getMenuList,
    }, dispatch)

}

const mapStateToProps = state => {
    return {
        menuList: state.menuListLibrary.menuList,
        loaderIconVisible: state.menuListLibrary.isLoading
    };
}

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
)(injectIntl(copyLibrary))