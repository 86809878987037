import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import _ from 'lodash';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { MenuBrandsResponse } from '../../../utils/unitResponseBuilder';
import { getMediaLibraryList } from '../../../actions/navigation/mediaManagement/mediaLibraryListActions'
import { BaseService, MenuUploadAPI, UserProfile,UnitInfo } from '../../../service/api';
import { CUSTOMER_ID,BRAND_ID } from '../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import NoRecordsFound from '../../controls/noRecordsFound';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { injectIntl,FormattedMessage } from 'react-intl';
import { ungzip } from 'pako';

class MenuBrands extends Component {

	nextPath(path) {
		this.props.history.push(path);
	}

	constructor(props) {
		super(props);
		this.state = {
			brandList: [],
			loaderIconVisible: true,
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			pushOption: "New",
			isAvailable: false
		};
		this.onButtonClickHandler = this.onButtonClickHandler.bind(this);
	}

	getBrands = () => {
		this.setState({ loaderIconVisible: true });
		let url = BaseService.root + MenuUploadAPI.getMenuBrandList;
		http.get(url, {
			headers: {
				'Content-Type': 'application/json',
				brandId: BRAND_ID,
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				let {branList} = response.data;
				let convobrand = branList.filter(b => b.BRAND_ID === 6);
				this.setState({
					loaderIconVisible: false,
					brandList: branList,
					isAvailable: convobrand.length > 0 ? true : false
				});
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});
	}

	getUserInfo = () => {
		this.setState({ loaderIconVisible: true });
		let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
		if (!loginInfo.rolename) {
			http.get(UserProfile.getUserProfileDetails,
				{headers: {
					'customer_id': localStorage.getItem("customerId"),
					'email': loginInfo.username,
					'Content-Type': 'application/json',
					'base_country': localStorage.getItem("basecountry"),
					'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
				},data:{},
			}).then(response => {
				loginInfo.rolename = response.data.role_name;
				localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
				// this.setState({
				// 	loaderIconVisible:false,
				// })
			}).catch(err => {
				// this.setState({ loaderIconVisible:false });
			});
		}
	}

	componentDidMount = async() => {
		$('body').removeClass("modal-backdrop show");
		document.body.style.backgroundColor = "#F2F2F2";
		window.addEventListener("resize", this.updateDimensions());
		localStorage.removeItem('modelIdValue');
		localStorage.removeItem('softwareModelId');
		localStorage.removeItem('selectedFamilyID');
		localStorage.removeItem('showHatIcon');		
		this.getUserInfo();
		await this.getBrands();		
		let zendeskscript = document.getElementById('ze-snippet');
        if (zendeskscript) {
            zendeskscript.remove();
        }
	}

	updateDimensions() {
        const windowHeight = $(window).height();
        const tableBody = $('.pageScrollbar');
        const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0 ;
        tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
    }

	componentDidUpdate = () => {
        this.updateDimensions();
    }

	onButtonClickHandler = async (item) => {
		localStorage.setItem("brandName",item.BRAND_NAME);
		localStorage.setItem("selectedbrandID",item.BRAND_ID);
		WoopraEvents(`${Constants.MENU_BRAND_SELECTED} ${item.BRAND_NAME}`);
		if (item.BRAND_NAME === "MERCO" || item.BRAND_NAME === "MULTIPLEX" || item.BRAND_NAME === "LINCOLN" || item.BRAND_NAME === "FRYMASTER-UHCTHD" || item.BRAND_NAME === "XPRESSCHEF" || item.BRAND_NAME === "ICE-O-MATIC")
		{
			this.props.history.push(`/menuScheduleUpdateNow`);
		}
		else if (item.BRAND_NAME === "CREM" || item.BRAND_NAME === "EGRO")
		{
			this.props.history.push(`/menuLibrary`);
		}
		else if (item.BRAND_NAME === "MERRYCHEF" || item.BRAND_NAME === "GARLAND" || item.BRAND_NAME === "FRYMASTER")
		{
			// if (localStorage.getItem("brandName") === "MERRYCHEF" || localStorage.getItem('brandName') === "GARLAND") {
			// 	const modelFId = localStorage.getItem("brandName") === "MERRYCHEF" ? '1,2,40,41' : '31,32';
			// 	const brandId = localStorage.getItem("brandName") === "MERRYCHEF" ? '1' : '9';
			// 	await this.getUnitList(modelFId, brandId);
			// }
			// MerryChef
			let modelFId =  '1,2,40,41';
			let brandId = '1';
			// if (localStorage.getItem("brandName") === "MERRYCHEF" ) {
			// 	const modelFId =  '1,2,40,41';
			// 	const brandId = '1';
			// }
			if (localStorage.getItem('brandName') === "GARLAND" ) {
				 modelFId =  '31,32';
				 brandId =  '9';
			}
			await this.getUnitList(modelFId, brandId);
			this.props.history.push(`/libraryList`);
		}
		else if(item.BRAND_NAME === "CONVOTHERM" )
		{
			this.props.history.push(`/legacyLibraryList`);
		}
	}

	decompressData(base64) {
		try {
            const buffer = Buffer.from(base64, "base64");
            const bufferArr = ungzip(buffer);
            const json = new TextDecoder("utf8").decode(bufferArr);
            return JSON.parse(json);
        } catch (err) {
            console.error(err);
            return null;
        }
	}

	getUnitList(modelFamilyIds, brandId) {
		console.log("modelFamilyIds", modelFamilyIds, "brandId", brandId);
        this.setState({
            loaderIconVisible: true,
        });
        http.get(`${UnitInfo.list}?pageNum=1&pageSize=24`, {
            headers: {
                "Content-Type": "application/json",
                cgid: localStorage.getItem("custGroupID"),
                cid: CUSTOMER_ID,
                modelfamilyids: modelFamilyIds,
                brandid: brandId,
                model: "",
                machinetype: "",
            },
            data: {},
            params: {
                compress: true,
            },
        })
            .then((response) => {
                let { data } = response;
                let units = data && data.units ? data.units : [];
                if (response.data.compressed) {
                    units = this.decompressData(units) || [];
                }
                let isUnitAvailable = units.length > 0 ? true : false;
                localStorage.setItem("showHatIcon", isUnitAvailable);
            })
            .catch((err) => {
                this.setState({
                    loaderIconVisible: false,
                });
            });
	}

	// Woopra Events
	trackEvent =(e) => {
		WoopraEvents(`${Constants.MENU}-${e.trackTitle}`)
	}

	render() {
		const { formatMessage } = this.props.intl;
		let {brandList, loaderIconVisible, pushOption, isAvailable} = this.state;
		let branList = [];
		let orderId = 1;
		let menuItemList;
		let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
		if ((Object.keys(brandList).length > 0) && brandList && (brandList !== null) && (brandList !== undefined)) {
			branList = MenuBrandsResponse(brandList);
			branList = _.orderBy(branList, "BRAND_NAME", "asc");
			menuItemList = (
				branList.map((item) =>
				<li key={item.ID} onClick = {() => this.onButtonClickHandler(item)}>
				<h6>{item.BRAND_NAME}</h6>
				<div className="logoD">{<img src={item.img} alt="" />} </div>
				</li>
				)
			)
		}
		else {
			menuItemList = (
				<div className="menuBrandsTable">
					<NoRecordsFound loaderIconVisible={loaderIconVisible} length={branList !== undefined ? branList.length : 0} classname={'norecordsfoundbluetext'} />
					</div>)
		}
		return (
			<div id="adminWrapper">
				{/* <!-- Loading Progress --> */}
				<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

				<div id="page-content-wrapper">

					<div className="menuUpdateWrapper">
						
						<div className="menuUpdateHeader">
							<ul>
								<li>
									<Link to="/menuBrands" title={formatMessage({ id: 'KC0661' })} className="active" onClick={(e)=> {e.trackTitle="Brands";this.trackEvent(e)}}><FormattedMessage id="KC0661"/></Link>
									{loginInfo.rolename !== "Public Admin" && <Link to="/menuUpdateStatus" title={formatMessage({ id: 'KC0659' })} onClick={(e)=> {e.trackTitle="Update Status";this.trackEvent(e)}}><FormattedMessage id="KC0659"/></Link>}
									{isAvailable && <Link to="/menuApps" title={formatMessage({ id: 'KC0660' })} onClick={(e)=> {e.trackTitle="Apps";this.trackEvent(e)}}><FormattedMessage id="KC0660"/></Link>}
								</li>
							</ul>
						</div>

						<div className='pageScrollbar'>
						<form className="brandsLibraryForm">
							<div className="brandsList">
							<ul>
								{menuItemList}
							</ul>
							</div>
						</form>
						</div>
					</div>
				</div>
				{/* <!-- Select Flow Modal --> */}
                <div className="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="ConfirmationOfImportModal" tabindex="-1" role="dialog" aria-labelledby="confirmationYesNoModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="confirmationYesNo"> 
                                    <h5><FormattedMessage id="KC1114"/></h5>
                                    <div className="uploadOption">
                                        <div className="customRadioBtn">
                                            <input id="selectFromLibrary" type="radio" name="uploadOption" value="New" checked={pushOption === "New" ? true : false} onClick={this.onPushTypeSelect} />
                                            <label for="selectFromLibrary" value="New"><FormattedMessage id="KC1743"/></label>
                                        </div> 
                                        <div className="customRadioBtn">
                                            <input id="selectFromDevice" type="radio" name="uploadOption" value="Legacy" checked={pushOption === "Legacy" ? true : false} onClick={this.onPushTypeSelect} />
                                            <label for="selectFromDevice" value="Legacy"><FormattedMessage id="KC1113"/></label>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary noBtn" id="selectFromLiberary" type="button" onClick={this.cancelPushClick}><FormattedMessage id="KC0021"/></button>
                                <button className="btn btn-secondary yesBtn" id="ImportButton" type="button" onClick={this.submitPushClick}><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- // Select Flow Modal --> */}
				<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
			</div>
		)
	}
};

function mapStateToProps(state) {

	return {
		mediaLibraryListInfo: state.mediaLibraryListInformation.mediaLibraryListInformation,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getMediaLibraryList,
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MenuBrands));