import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import _ from 'lodash';
import  LibraryListPopup  from '../../libraryListPopup';
import http from '../../../../../service/httpService';
import { UserProfile, MenuUploadAPI, organizationNode } from '../../../../../service/api';
import { CUSTOMER_ID, BRAND_ID_VALUE, BASECOUNTRY_ID } from '../../../../../utils/appConstants';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
var beautify = require("json-beautify");
var AdmZip = require('adm-zip');
var request = require('request');
var todaysDate = new Date();
var LZString = require('lz-string');
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');
require('jquery-mousewheel')

class SettingsEditor extends Component {
    state = {
        loaderIconVisible: false,
        customerId: parseInt(localStorage.getItem("customerId")),
        data: [],
        success_modal: {
            open: false,
            message: ""
        },
        error_modal: {
            open: false,
            message: ""
        },
        selectedModel: 0,
        isChanged: false,
        isEdit: false,
        showDiscardPopup: false,
        settings_name: '',
        errorSettingsName: ''
    }

    constructor(props) {
        super(props);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.redirect = this.redirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.onSubmitSettingsEditor = this.onSubmitSettingsEditor.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onSaveButtonClick = this.onSaveButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.OnImport = this.OnImport.bind(this);
        this.onSettingsNameChange = this.onSettingsNameChange.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.closeModel = this.closeModel.bind(this);
    }

    componentDidMount = async () => {
		window.addEventListener('resize', this.updateDimensions());
        document.body.style.backgroundColor = "#000000";
        $('.mCustomScrollbar').mCustomScrollbar();
        try {
            let libraryData = JSON.parse(localStorage.getItem("librarySettingsData"));
            let defaultLibraryDetails = "";
            try {
                defaultLibraryDetails = JSON.parse(localStorage.getItem("defaultLibraryData"));
            }
            catch (err) {
                defaultLibraryDetails = JSON.parse(LZString.decompress(localStorage.getItem("defaultLibraryData")));
            }
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            this.setState({
                settings_name: libraryDetails.settingName,
                selectedModel: parseInt(localStorage.getItem("selectedProductID")),
                isEdit: libraryDetails.isEdit
            })
            if (!_.isEqual(defaultLibraryDetails, libraryData)) {
                this.setState({
                    isChanged: true
                })
            }
            if (this.props.history.location.state.getSelectedDynasty) {                
                let node = this.props.history.location.state.getSelectedDynasty;   
                let selectedUnitDetails = this.props.history.location.state.getSelectedUnitDetails;     
                await this.getNodeCaptionDetails(node,selectedUnitDetails); 
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
		const windowHeight = $(window).height();
		const $tableBody = $('.pageScrollbar');
		$tableBody.css('height', windowHeight - ($tableBody.offset().top));
	}

    componentDidUpdate = () => {
		this.updateDimensions();
	}

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false,
            errorSettingsName: ''
        })
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        let path = "/cookbookEditor";
        if (libraryDetails.isEdit) {
            path = "/libraryList";
        }
        this.props.history.push({
            pathname: path,
            isEdit: false,
            previouspath: "/settingsEditor"
        });
    }

    redirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    getNodeCaptionDetails = async (val,val1) => {
        this.setState({ loaderIconVisible: true });
        const url = organizationNode.nodeDetails + "96";
        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'cid': localStorage.getItem("customerId"),
                'dynasty' : val,
            }, data: {}
        }).then( response  => {
            if (response && response.data) {
                let result = response.data;
                let nodeDetails;
                result.map((item, i) => {
                    if (nodeDetails) {
                        if ((result.length -1) === i){
                            nodeDetails = nodeDetails + item.node_name;
                        } else {
                            nodeDetails = nodeDetails + item.node_name + "/";
                        }
                    } else {
                        nodeDetails = item.node_name + "/";
                    }
                })
                this.setState({
                    loaderIconVisible: false,
                    nodeCaptionDetails: nodeDetails,
                    selectedUnitDetails:val1,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    onSaveButtonClick = (action) => {
        let settingsName = this.state.settings_name.trim();
        if (settingsName.length < 1) {
            this.setState({
                errorSettingsName: "KC1265"
            })
        }
        else {
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            libraryDetails.settingName = settingsName;
            localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
            if (action === "Test") {
                this.props.history.push({
                    pathname: "/recipeAssignTopology",
                    previouspath: "/settingsEditor",
                });
            }
            else {
                this.props.history.push({
                    pathname: "/assignSettingsToTopology",
                    isEdit: false,
                    previouspath: "/settingsEditor"
                });
            }
        }
    }

    onBackButtonClick = () => {
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        if (!this.state.isChanged) {
            let path = "/cookbookEditor";
            if (libraryDetails.isEdit) {
                path = "/cookbookEditor";
            }
            this.props.history.push({
                pathname: path,
                isEdit: false,
                previouspath: "/settingsEditor"
            });
        }
        else {
            this.setState({
                loaderIconVisible: false,
                showDiscardPopup: true
            });
        }
    }

    onSubmitSettingsEditor = () => {
            this.props.history.push({
                pathname: "/cookbookEditor",
                previouspath: "/settingsEditor",
            });
    }

    onSettingsClick = (settingName) => {
        let pathName = "";
        if (settingName === "Modes") {
            pathName = "/settingsModes";
        }
        else if (settingName === "PreHeat") {
            pathName = "/preHeat";
        }
        else if (settingName === "Temperature") {
            pathName = "/temperatureBand";
        }
        else if (settingName === "TimerforTemperature") {
            pathName = "/timerforTemperature";
        }
        else if (settingName === "Sound") {
            pathName = "/sound";
        }
        else if (settingName === "Units") {
            pathName = "/units";
        }
        else if (settingName === "Password") {
            pathName = "/managerPassword";
        }
        else if (settingName === "Languages") {
            pathName = "/languages";
        }
        else if (settingName === "Cleaning") {
            pathName = "/cleaning";
        }
        else if (settingName === "Display") {
            pathName = "/screensaver";
        }
        else if (settingName === "DateAndTimeSettings") {
            pathName = "/dateAndTime";
        }
        else if (settingName === "Scheduler") {
            pathName = "/scheduler";
        }

        this.props.history.push({
            pathname: pathName,
            isEdit: false,
            type: "Settings",
            previouspath: "/settingsEditor"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    async OnImport(item) {
        this.setState({
            loaderIconVisible: true
        });
        await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
            headers: {
                'Content-Type': 'application/json',
                'library_id': encodeURIComponent(item.LIBRARY_ID),
                'library_isedit': true,
                'encryption' : "true"
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                let file_path = response.data.presignedURL;
                var libraryFiles = [];
                var b64 = "";
                var imageBuffer = null;
                localStorage.setItem("selectedGroupID", item.CGID);
                try {
                    request.get({ url: file_path, encoding: null }, (err, res, body) => {
                        var zip = new AdmZip(body);
                        var zipEntries = zip.getEntries();

                        zipEntries.forEach((entry, i) => {
                            libraryFiles[i] = zip.readFile(entry);

                            if (!entry.entryName.includes('.json')) {
                                imageBuffer = Buffer.from(libraryFiles[i]);
                                b64 = imageBuffer.toString('base64');
                            }
                        });

                        localStorage.setItem("selectedFamilyID", item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.ID).toString() : '');
                        let libraryData = [];
                        libraryData = JSON.parse(libraryFiles[0]);
                        localStorage.setItem("defaultLibraryData", JSON.stringify(libraryData));
                        libraryData.Import = true;
                        localStorage.setItem("librarySettingsData", JSON.stringify(libraryData));
                        let UpdatedJSONObj = JSON.parse(libraryFiles[0]);
                        let md5 = require('md5');
                        let libraryDetails = {};
                        libraryDetails.id = UpdatedJSONObj.id;
                        libraryDetails.imagefileName = item.LIBRARY_NAME + "." + item.IMAGE_FILE_FORMAT;
                        libraryDetails.imagefileext = item.IMAGE_FILE_FORMAT;
                        libraryDetails.imagefileNamewithOutExt = item.LIBRARY_NAME;
                        libraryDetails.imagefileSize = item.IMAGE_FILE_SIZE;
                        libraryDetails.imagefilelength = item.LIBRARY_NAME.length;
                        libraryDetails.library_id = item.LIBRARY_ID;
                        libraryDetails.settingName = item.LIBRARY_NAME;
                        libraryDetails.crc = md5(b64, "base64");
                        libraryDetails.isEdit = false;
                        libraryDetails.isImageChanged = true;
                        libraryDetails.settings = UpdatedJSONObj.sounds;
                        libraryDetails.day = "Monday";
                        libraryDetails.initialMethod = "Add";
                        
                        libraryData = JSON.parse(libraryFiles[0]);
                        let schedulers = libraryData.scheduler;
                        if (!Array.isArray(schedulers)) {
                            libraryData.scheduler = []
                        }
                        else {
                            schedulers.map((parent, i) => {
                                parent.configurations.map((item, j) => {
                                    if (item.type !== "Cold") {
                                        item.id = j + 1;
                                    }
                                    else {
                                        item.id = 12;
                                    }
                                })
                            })
                        }
                        
                        localStorage.setItem("schedulerDetails", JSON.stringify(libraryData.scheduler))
                        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                        localStorage.setItem("libraryImage", b64);

                        this.setState({
                            pathname: "/settingsEditor",
                            previouspath: "/libraryList",
                            loaderIconVisible: false,
                            isChanged: true
                        });
                    });
                }
                catch (ex) {
                    console.log(ex);
                    this.setState({
                        loaderIconVisible: false
                    });
                }
            }
        })
        .catch(err => {
            console.log(err);
            this.setState({
                loaderIconVisible: false
            });
        });
    }

    onSettingsNameChange = (e) => {
        // if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            this.setState({
                settings_name: e.target.value,
                errorSettingsName: ""
            });
        // }
    }
    
    selectTestDeleteButtonClick = () => {
        this.setState({
            nodeCaptionDetails: null,
            selectedUnitDetails: {},
        });
    }
    
    selectTestUnitButtonClick = async() => {
        let _this = this;
        let httpMethod;
        _this.setState({
            loaderIconVisible: true
        })
        let brandName = localStorage.getItem("brandName");
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;

        await http({
            method: "GET",
            url: UserProfile.getUserProfileDetails,
            headers: {
                'customer_id': CUSTOMER_ID,
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': localStorage.getItem("basecountry"),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
            }, data: {}
        }).then(async response => {
            let libraryId;
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            if (libraryDetails.isEdit) {
                httpMethod = "PUT";
                libraryId = libraryDetails.library_id;
            } else {
                httpMethod = "POST";
                libraryId = libraryDetails.settingName.replace(/[^a-zA-Z0-9\n\r.]+/g, '') + '_' + Date.now();
                libraryId = libraryId.replace(/\s+/g, '');
                libraryDetails.library_id = libraryId;
                localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
            }
            
            let libraryData = localStorage.getItem("librarySettingsData");
            let libraryDataObj = JSON.parse(libraryData);
            libraryDataObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
            libraryDataObj.edit_date = todaysDateUTCFormatted;
            libraryDataObj.author = response.data.name;
            libraryDataObj.email = loginUser.username;
            delete libraryDataObj.Import;

            libraryDataObj.scheduler.map((parent, i) => {
                parent.configurations.map((item, j) => {
                    delete item.id;
                })
            })

            if (libraryDataObj.scheduler && libraryDataObj.scheduler.length === 0)  {
                delete libraryDataObj.scheduler;
            }

            if (libraryDataObj.preheat_list && libraryDataObj.preheat_list.length === 0)  {
                delete libraryDataObj.preheat_list;
            }

            if (libraryDataObj.preheat_times && libraryDataObj.preheat_times.length === 0)  {
                delete libraryDataObj.preheat_times;
            }

                // convert JSON object to beautify String
                var jsonStr = beautify(libraryDataObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

            let fileSize = Buffer.byteLength(jsonBuffer);
            fileSize = (fileSize / 1048576);

            let settings = libraryDataObj.sounds;
            settings.map((item, i) => {
                fileSize = fileSize + parseFloat(item.fileSize ? item.fileSize : 0);
            })
            
            let md5 = require('md5');
            let crc = md5(jsonBuffer, "base64");

            let imagefile = settings.find(a => a.fileType === "screensaver");
            let libraryPostData = {
                "customerId": CUSTOMER_ID,
                "brandId": BRAND_ID_VALUE[brandName],
                "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                "libraryId": libraryId,
                "libraryName": libraryDetails.settingName,
                "libraryFileName": libraryId + ".json",
                "libraryFileFormat": "json",
                "libraryFileSize": (fileSize + parseFloat(imagefile.fileSize)).toFixed(2),
                "libraryS3Url": "",
                "type": "Settings",
                "createdUser": response.data.name + ' (' + loggedinUser + ')',
                "crc": crc,
                "baseCountry": BASECOUNTRY_ID,
                "imageFileName": "",
                "imageFileFormat": "",
                "imageFileSize": imagefile.fileSize,
                "imageS3Url": "",
                "cgids": [parseInt(localStorage.getItem("custGroupID") ? localStorage.getItem("custGroupID") : 0)],
                "json": JSON.stringify(libraryDataObj),
                "settings": settings ? settings : [],
                "libraryGuid": libraryDataObj.id
            };

            if (libraryDetails.isEdit) {
                delete libraryPostData.customerId;
                delete libraryPostData.createdUser;
                libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
            }

            if (libraryDetails.initialMethod === "Add") {
                libraryPostData.status = "DRAFT";
            }

            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey': "",
                'jsonfilekey': jsonBuffer ? libraryId + '.json' : "",
                'audiofilekey': '',
                'isthumbnail': false
            }

            await uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(async response => {
                let {selectedUnitDetails} = this.state;

                const unitList = [];
                unitList.push(
                    {
                        "thingArn": selectedUnitDetails.THING,
                        "productId": selectedUnitDetails.ProductId,
                        "cloudUnitName": selectedUnitDetails.CLOUD_UNIT_NAME,
                        "unitTimeZone": selectedUnitDetails.TimeZone_Standard
                    }
                )

                let postData = {
                    "customer": CUSTOMER_ID,
                    "brand": brandName,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "user": loggedinUser,
                    "libraryIds": [libraryId],
                    "country": BASECOUNTRY_ID,
                    "type": "Recipe",
                    "thingList": unitList,
                    "userName": loggedinUser,
                    "isTestPush": true
                };
        
                let url = MenuUploadAPI.getLibraryUpdateStatus;
                    await http.post(url, postData,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                    .then(response => {
                        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                        libraryDetails.isEdit = true;
                        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                    _this.setState({
                        loaderIconVisible: false,
                        isEdit: true,
                        success_modal: {
                            open: true,
                            message: "KC1227"
                        },
                        nodeCaptionDetails: null,
                        selectedUnitDetails: {},
                    });
                }).catch(err => {
                    let { response } = err;
                    let { data } = response;
                    let { message } = data;
                    this.setState({
                        error_modal: {
                            open: true,
                            message: message ? message : ""
                        },
                        loaderIconVisible: false
                    });
                });
            }).catch(err => {
                this.setState({
                    loaderIconVisible: false
                });
                console.log(err);
            });
        }).catch(err => {
            _this.setState({
                loaderIconVisible: false
            })
            console.log(err);
        });
    }

    render() {
        let { loaderIconVisible, data: dataList, isChanged, isEdit, showDiscardPopup, settings_name, errorSettingsName, selectedModel, success_modal, error_modal } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = localStorage.getItem("selectedbrandID");
        let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div className="pageScrollbar">
                        <div className="rcDeviceWrapper">
                            <div className="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")} /></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1190"/>
				                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn">
                                                <div className="rcSettingsList">
                                                    <div class="rcSettingsList">
                                                        <ul onClick={() => { this.onSettingsClick('Modes') }}>
                                                            <li><img src={require("../../../../../images/recipecreation/fullServeMode.svg").default} /></li>
                                                            <li><FormattedMessage id="KC1268"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} /></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList">
                                                        <ul onClick={() => { this.onSettingsClick('PreHeat') }}>
                                                            <li><img src={require("../../../../../images/recipecreation/preheat.svg").default} /></li>
                                                            <li><FormattedMessage id="KC1269"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} /></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList">
                                                        <ul onClick={() => { this.onSettingsClick('Languages') }}>
                                                            <li><img src={require("../../../../../images/recipecreation/language.svg").default} /></li>
                                                            <li><FormattedMessage id="KC0920"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} /></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList">
                                                        <ul onClick={() => { this.onSettingsClick('DateAndTimeSettings') }}>
                                                            <li><img src={require("../../../../../images/recipecreation/dateAndTime.svg").default} /></li>
                                                            <li><FormattedMessage id="KC1267"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} /></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList">
                                                        <ul onClick={() => { this.onSettingsClick('Scheduler') }}>
                                                            <li><img src={require("../../../../../images/recipecreation/scheduler.svg").default} /></li>
                                                            <li><FormattedMessage id="KC1270"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} /></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList">
                                                        <ul onClick={() => { this.onSettingsClick('Display') }}>
                                                            <li><img src={require("../../../../../images/recipecreation/display.svg").default} /></li>
                                                            <li><FormattedMessage id="KC1271"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} /></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList">
                                                        <ul onClick={() => { this.onSettingsClick('Sound') }}>
                                                            <li><img src={require("../../../../../images/recipecreation/sounds.svg").default} /></li>
                                                            <li><FormattedMessage id="KC1272"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} /></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList">
                                                        <ul onClick={() => { this.onSettingsClick('Units') }}>
                                                            <li><img src={require("../../../../../images/recipecreation/units.svg").default} /></li>
                                                            <li><FormattedMessage id="KC0045"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} /></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList">
                                                        <ul onClick={() => { this.onSettingsClick('Password') }}>
                                                            <li><img src={require("../../../../../images/recipecreation/password.svg").default} /></li>
                                                            <li><FormattedMessage id="KC1273"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} /></li>
                                                        </ul>
                                                    </div>
                                                    <div class="rcSettingsList">
                                                        <ul onClick={() => { this.onSettingsClick('Cleaning') }}>
                                                            <li><img src={require("../../../../../images/recipecreation/cleaning.svg").default} /></li>
                                                            <li><FormattedMessage id="KC1274"/></li>
                                                            <li><img src={require("../../../../../images/recipecreation/rightArrow.png")} /></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} className="btn backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                    <button type="button"  onClick={() => { this.onSubmitSettingsEditor() }}  class="btn saveCButton">&nbsp;</button>
                                    {/* <button type="button" data-toggle="modal" data-target="#testNameModal" className={loginInfo.rolename === "Public Admin" ? "displaynone" : (isChanged || isEdit) ? "btn testButton" : "btn testButton disabled"} title={formatMessage({ id: 'KC1244' })}><FormattedMessage id='KC0698' /></button> */}
                                </div>
                            </div>
                            {/* <div className="footerText"><FormattedMessage id="KC1217"/></div>
                            <div className={loginInfo.rolename === "Public Admin" ? "displaynone" : "testOnUnitTable"}>
                                <ul>
                                    <li><img src={require("../../../../../images/recipecreation/assetD.svg").default} /></li>
                                    <li>                                        
                                        <input type="text" className="form-control" value={this.state.nodeCaptionDetails ? this.state.selectedUnitDetails.UNITID + "_" + this.state.selectedUnitDetails.BRAND_NAME + "     " +this.state.nodeCaptionDetails:"" } placeholder={formatMessage({ id: 'KC1276' })}></input> 
                                    </li>
                                    <li>
                                        <button type="button" className="btn btn-default-text deleteButton" onClick={() => { this.selectTestDeleteButtonClick() }}>&nbsp;</button>
                                    </li>
                                    <li>
                                        <button type="button" className={this.state.nodeCaptionDetails ? "btn btn-default-text" : "btn btn-default-text disabled"} onClick={() => { this.selectTestUnitButtonClick() }}><FormattedMessage id="KC1231"/></button>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={isChanged && showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isChanged && showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
                {/* <!-- Import Modal --> */}
                <LibraryListPopup libraryType={'Settings'} OnImportLibrary={(item) => this.OnImport(item)} modelID={selectedModel} />
                {/* <!-- //Import Modal --> */}
                {/* <!-- Save Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="saveNameModal" tabindex="-1" role="dialog" aria-labelledby="saveNameModalLabel">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">  
                        <div class="modal-body"> 
                            <div class="confirmationYesNo">
                            <h5><FormattedMessage id="KC1277"/></h5>  
                            <div class="input-group"> 
                                <input class="form-control" type="text" maxLength="20" placeholder={formatMessage({ id: 'KC1277' })} value={settings_name} onChange={(e) => this.onSettingsNameChange(e)} />
                                <div className="errorMsg">{errorSettingsName != '' ? <FormattedMessage id={errorSettingsName}/> : ''}</div>
                            </div>
                            </div> 
                        </div>
                        <div class="modal-footer textAlignCenter">
                        <button onClick={() => { this.OnCancel() }} class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0021' /></button>
                        <button onClick={() => { this.onSaveButtonClick('Save') }} class="btn btn-secondary yesBtn" id="timeZoneAssign" type="button"><FormattedMessage id='KC0123' /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Save Modal --> */}
            {/* <!-- Test Modal --> */}
            <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="testNameModal" tabindex="-1" role="dialog" aria-labelledby="saveNameModalLabel">
                <div class="modal-dialog modal-md">
                    <div class="modal-content">  
                        <div class="modal-body"> 
                            <div class="confirmationYesNo">
                            <h5><FormattedMessage id="KC1277"/></h5>  
                            <div class="input-group"> 
                                <input class="form-control" type="text" placeholder={formatMessage({ id: 'KC1277' })} value={settings_name} onChange={(e) => this.onSettingsNameChange(e)} />
                                <div className="errorMsg">{errorSettingsName != '' ? <FormattedMessage id={errorSettingsName}/> : ''}</div>
                            </div>
                            </div> 
                        </div>
                        <div class="modal-footer textAlignCenter">
                        <button onClick={() => { this.OnCancel() }} class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0021' /></button>
                        <button onClick={() => { this.onSaveButtonClick('Test') }} class="btn btn-secondary yesBtn" id="timeZoneAssign" type="button"><FormattedMessage id='KC0123' /></button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- //Test Modal --> */}
            </React.Fragment>
        )
    }
}

export default injectIntl(SettingsEditor)
