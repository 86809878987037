import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import Jimp from 'jimp';
import $ from 'jquery';
import axios from 'axios';
import ErrorMoal from '../../../../modal/error-model';
import { MenuUploadAPI, BaseService } from '../../../../../service/api';
import { CUSTOMER_ID } from '../../../../../utils/appConstants';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Cropper } from '../../../../controls/cropper';
import { v1 as uuidv1 } from 'uuid';
import 'react-image-crop/dist/ReactCrop.css';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
var beautify = require("json-beautify");
const { v4: uuidv4 } = require('uuid')

class GarlandRecipeCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            error_modal: {
                open: false,
                message: ""
            },
            recipeName: '',
            cooking_methodology: 'Steel',
            unit: '°F',
            too_cold_limit: '',
            too_hot_limit: '',
            portion: "1",
            weight: "50",
            cropWidth: 128,
            cropHeight: 128,
            imageData: {},
            isImageChanged: false,
            showDiscardPopup: false,
            isChanged: false
        }
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        this.updateDimensions();
        await this.setInitialValue();
        $(".mCustomScrollbar").mCustomScrollbar();
    }

    setInitialValue = () => {
        const garlandObj = localStorage.getItem('garlandRecipe') ? JSON.parse(localStorage.getItem('garlandRecipe')) : {};
        if (garlandObj && garlandObj.recipeName) {
            this.setState({
                ...garlandObj,
                isChanged: true
            })
        };
        this.setState({ loaderIconVisible: false, isImageChanged: false })
    }

    IsAlphaNumeric(e) {
        e.target.value = e.target.value.trim().replace(/[^\d]/g, '');
    }

    updateDimensions = () => {
        var windowHeight = $(window).height();
        var menuFilterHeader = $(".menuFilterHeader").height();
        var pageRRHeight = ((windowHeight) - (menuFilterHeader)) - 150;
        $(".readyRecipeSrlTable").css("height", pageRRHeight);
    }

    onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            this.setState({
                loaderIconVisible: true
            });
            let imageData = this.state.imageData ? this.state.imageData : {};
            let fileSize = event.target.files[0].size;
            fileSize = (fileSize / 1048576).toFixed(2);

            let fileName = event.target.files[0].name;
            fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
            let fileext = fileName.substring(fileName.lastIndexOf('.') + 1);
            let fileNamewithOutExt = fileName.replace('.' + fileext, "");
            let filelength = fileName.length;
            imageData.imagefileName = fileName;
            imageData.imagefileext = fileext;
            imageData.imagefileNamewithOutExt = fileNamewithOutExt;
            imageData.imagefileSize = fileSize;
            imageData.imagefilelength = filelength;

            let resolutionofimageVideo = 0;

            var FileUploadThumbnail = require('../../../../../utils/fileThumnailProcesser');
            let md5 = require('md5');

            if (fileSize) {
                var width;
                var height;
                let srcfile = event.target.files[0];
                if (fileext === "png" || fileext === "PNG") {
                    var reader = new FileReader();
                    reader.readAsDataURL(event.target.files[0]);
                    reader.onload = (e) => {
                        var image = new Image();
                        image.src = e.target.result;
                        let imageDataBuffer = Buffer.from(e.target.result.replace(/^data:image\/[A-Za-z]+;base64,/, ""));
                        imageData.crc = md5(imageDataBuffer, "base64");
                        image.onload = (img) => {
                            width = img.target.width;
                            height = img.target.height;
                            resolutionofimageVideo = width + " x " + height;
                            // if ((width < 128 || height < 128) && (fileext !== "svg" && fileext !== "SVG" && fileext !== "gif" && fileext !== "GIF" && fileext !== "bmp" && fileext !== "BMP")) {
                            //     this.setState({
                            //         loaderIconVisible: false,
                            //         error_modal: {
                            //             open: true,
                            //             message: "Width and Height must be less than 128 x 128 pixels."
                            //         }
                            //     });
                            // }
                            if ((width > 128 || height > 128) && (fileext !== "svg" && fileext !== "SVG" && fileext !== "gif" && fileext !== "GIF" && fileext !== "bmp" && fileext !== "BMP")) {
                                this.setState({
                                    loaderIconVisible: false,
                                    showCropPopup: true,
                                    src: reader.result,
                                    cropWidth: width < height ? width : height,
                                    cropHeight: width < height ? width : height,
                                    imageData: imageData
                                });
                                $('#popupTrigger').trigger('click');
                            }
                            else {
                                imageData.resolution = resolutionofimageVideo;
                                imageData.category = 'picture';
                                if (srcfile) {
                                    let thumhnail = new FileUploadThumbnail({
                                        maxWidth: 80,
                                        maxHeight: 80,
                                        file: srcfile,
                                        onSuccess: (thumbsrc) => {
                                            thumbsrc = thumbsrc.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                            let imageThumbnailBuffer = Buffer.from(thumbsrc, "base64");
                                            let image = new FileUploadThumbnail({
                                                maxWidth: 128,
                                                maxHeight: 128,
                                                file: srcfile,
                                                onSuccess: (src) => {
                                                    src = src.replace(/^data:image\/[A-Za-z]+;base64,/, "");
                                                    let imageDataBuffer = Buffer.from(src, "base64");
                                                    this.setState({
                                                        loaderIconVisible: false
                                                    });
                                                    this.setState({
                                                        imageDataBuffer: imageDataBuffer,
                                                        imageDatab64: src,
                                                        imageData: imageData,
                                                        imageThumbnailBuffer: imageThumbnailBuffer,
                                                        imageThumbnailb64: thumbsrc,
                                                        isImageChanged: true
                                                    });
                                                },
                                                onError: function (err) {
                                                    this.showError(err);
                                                }
                                            }).createThumbnail();

                                        },
                                        onError: function (err) {
                                            this.showError(err);
                                        }
                                    }).createThumbnail();
                                }
                            }
                        };
                    }
                }
                else {
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: "Please select png image file."
                        }
                    });
                }
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1249"
                    }
                });
            }
            event.target.value = '';
        }
    }

    async cropSubmit(croppedImage) {
        $('#imageResizeModal').modal('hide');
        let _this = this;
        _this.setState({
            loaderIconVisible: true
        });

        let croppedImageType = croppedImage.type.toLowerCase() === "jpg" ? "jpeg" : croppedImage.type.toLowerCase();
        Jimp.read(croppedImage.data)
            .then(image => {
                image.resize(128, 128, (err, image) => {
                    image.getBufferAsync('image/' + croppedImageType).then(imagebuffer => {
                        image.getBase64Async('image/' + croppedImageType).then(imageb64 => {
                            let md5 = require('md5');
                            let fileSize = Buffer.byteLength(imagebuffer);
                            fileSize = (fileSize / 1048576).toFixed(2);
                            _this.state.imageData.resolution = "128 x 128";
                            _this.state.imageData.category = "picture";
                            _this.state.imageData.crc = md5(imagebuffer);
                            _this.state.imageData.fileSize = fileSize;
                            Jimp.read(imagebuffer)
                                .then(thumbnail => {
                                    thumbnail.resize(80, 80, (err, thumbnail) => {
                                        thumbnail.getBufferAsync('image/' + croppedImageType).then(thumbnailbuffer => {
                                            thumbnail.getBase64Async('image/' + croppedImageType).then(thumbnailb64 => {
                                                _this.setState({
                                                    imageDataBuffer: imagebuffer,
                                                    imageDatab64: imageb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                                    imageData: _this.state.imageData,
                                                    imageThumbnailBuffer: thumbnailbuffer,
                                                    imageThumbnailb64: thumbnailb64.replace(/^data:image\/[A-Za-z]+;base64,/, ""),
                                                    isImageChanged: true,
                                                    loaderIconVisible: false
                                                });
                                            })
                                        })
                                    })
                                })
                        })
                    })
                })
            })
            .catch(err => {
                _this.showError(err);
            })
    }

    processNext = () => {

        let recipeName = this.state.recipeName.trim();

        const { cooking_methodology, unit, too_cold_limit, too_hot_limit, imageData, imageThumbnailBuffer, imageThumbnailb64, imageDataBuffer, imageDatab64, isImageChanged } = this.state;
        if (unit === "°F" && too_cold_limit !== "") {
            if (Number(too_cold_limit) >= 0 && Number(too_cold_limit) <= 100) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter too cold limit value between 0 to 100 F.'
                    }
                });
                return;
            }
        }
        if (unit === "°F" && too_hot_limit !== "") {
            if (Number(too_hot_limit) >= 0 && Number(too_hot_limit) <= 100) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter too hot limit value between 0 to 100 F.'
                    }
                });
                return;
            }
        }
        if (unit === "°C" && too_cold_limit !== "") {
            if (Number(too_cold_limit) >= 0 && Number(too_cold_limit) <= 56) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter too cold limit value between 0 to 56 C.'
                    }
                });
                return;
            }
        }
        if (unit === "°C" && too_hot_limit !== "") {
            if (Number(too_hot_limit) >= 0 && Number(too_hot_limit) <= 56) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter too hot limit value between 0 to 56 C.'
                    }
                });
                return;
            }
        }
        this.setState({
            loaderIconVisible: true
        });
        if (this.state.isImageChanged && this.state.imageThumbnailb64) {
            let library_id = "";
            let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
            if (this.state.isEdit && libraryDetails && libraryDetails.library_id) {
                library_id = libraryDetails.library_id;
            }
            else {
                library_id = uuidv1();
            }

            let imagefilekey = uuidv4();
            imagefilekey= imagefilekey.replace(/-/g, '');
            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey': imagefilekey + '.png',
                'jsonfilekey': "",
                'audiofilekey': "",
                'isthumbnail': true
            }

            return new Promise((resolve, reject) => {
                let token = localStorage.getItem('Access-Token');
                headerpresigned.Authorization = token;
                axios({
                    url: BaseService.root + MenuUploadAPI.getlibraryUploadpresignedUrl,
                    method: "GET",
                    headers: headerpresigned,
                    data: {}
                }).then(response => {
                    let { imagePresignedURL, thumbnailPresignedURL } = response.data.presignedURLs
                    let imageurl = imagePresignedURL;
                    let thumbnailurl = thumbnailPresignedURL;

                    let UploadedImages3url = "";
                    if (imageurl) {
                        UploadedImages3url = imageurl.split('?');
                        UploadedImages3url = UploadedImages3url[0];
                    }
                    var xhrthumb = new XMLHttpRequest();
                    xhrthumb.open("PUT", thumbnailurl);
                    xhrthumb.setRequestHeader('Content-Type', 'multipart/form-data');
                    xhrthumb.onloadend = (res) => {
                        if (res.target.status == 200) {
                            var xhrimage = new XMLHttpRequest();
                            xhrimage.open("PUT", imageurl);
                            xhrimage.setRequestHeader('Content-Type', 'multipart/form-data');
                            xhrimage.onloadend = (res) => {
                                if (res.target.status == 200) {
                                    localStorage.setItem("libraryImage", this.state.imageThumbnailb64);
                                    libraryDetails['recipeName'] = recipeName;
                                    libraryDetails['recipe_type'] = 0;
                                    libraryDetails['cooking_methodology'] = cooking_methodology;
                                    libraryDetails['unit'] = unit;
                                    libraryDetails['too_cold_limit'] = too_cold_limit;
                                    libraryDetails['too_hot_limit'] = too_hot_limit;
                                    libraryDetails['imageThumbnailb64'] = imageThumbnailb64;
                                    libraryDetails['imageData'] = imageData;
                                    libraryDetails['isImageChanged'] = isImageChanged;
                                    libraryDetails['imageThumbnailBuffer'] = imageThumbnailBuffer;
                                    libraryDetails['imageDataBuffer'] = imageDataBuffer;
                                    libraryDetails['imageDatab64'] = imageDatab64;
                                    libraryDetails.library_id = library_id;
                                    libraryDetails.recipeID = library_id;
                                    libraryDetails.icon = imagefilekey;

                                    localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
                                    this.props.history.push('/garlandRecipeCreationSteps')
                                }
                            };
                            xhrimage.onerror = (err) => {
                                reject(err);
                            };
                            xhrimage.send(this.state.imageDataBuffer);
                        }
                    };
                    xhrthumb.onerror = (err) => {
                        reject(err);
                    };
                    xhrthumb.send(this.state.imageThumbnailBuffer);
                }).catch(err => {
                    reject(err);
                });
            });
        }
        else {
            let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
            if (!this.state.imageThumbnailb64) {
                libraryDetails.imageData = this.state.imageData;
                if (this.state.imageData == "") {
                    libraryDetails.imageData = {};
                }
                let md5 = require('md5');
                var jsonStr = beautify(libraryDetails, null, 2, 80)
                const jsonBuffer = Buffer.from(jsonStr);
                libraryDetails.imageData.crc = md5(jsonBuffer);
                libraryDetails.imageData.imagefileName = "";
                libraryDetails.imageData.imagefileNamewithOutExt = "";
                libraryDetails.imageData.imagefileSize = "";
                libraryDetails.imageData.imagefileext = "";
                libraryDetails.imageData.imageS3Url = "";
            }

            if (!libraryDetails.library_id) {
                let library_id = uuidv1();
                libraryDetails.library_id = library_id;
                libraryDetails.recipeID = library_id
            }
            libraryDetails['recipeName'] = recipeName;
            libraryDetails['recipe_type'] = 0;
            libraryDetails['cooking_methodology'] = cooking_methodology;
            libraryDetails['unit'] = unit;
            libraryDetails['too_cold_limit'] = too_cold_limit;
            libraryDetails['too_hot_limit'] = too_hot_limit;
            libraryDetails['imageThumbnailb64'] = imageThumbnailb64;
            libraryDetails['imageData'] = imageData;
            libraryDetails['isImageChanged'] = isImageChanged;
            libraryDetails['imageThumbnailBuffer'] = imageThumbnailBuffer;
            libraryDetails['imageDataBuffer'] = imageDataBuffer;
            libraryDetails['imageDatab64'] = imageDatab64;
            libraryDetails.icon = libraryDetails.icon ? libraryDetails.icon : "";

            localStorage.setItem("garlandRecipe", JSON.stringify(libraryDetails));
            this.props.history.push('/garlandRecipeCreationSteps')
        }
    }

    closeCropPopup = () => {
        this.setState({
            showCropPopup: false
        });
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    OnDiscard = () => {
        localStorage.removeItem('garlandRecipe')
        this.props.history.replace(this.state.isEdit ? '/libraryList' : '/garlandRecipeEditor')
    }
    OnCancel = () => {
        this.setState({
            showDiscardPopup: false
        })
    }

    processPrevious = () => {
        const { isChanged, isImageChanged } = this.state;
        if (isChanged || isImageChanged || this.state.isEdit) {
            this.setState({ showDiscardPopup: true })
        } else {
            localStorage.removeItem('garlandRecipe')
            this.props.history.replace(this.state.isEdit ? '/libraryList' : '/garlandRecipeEditor')
        }
    }

    c2f = (celsius) => {
        return Math.round(celsius * 9/5);
    }

    f2c = (fahrenheit) => {
        return Math.round(fahrenheit * 5/9);
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { loaderIconVisible, recipeName, cooking_methodology, unit, too_cold_limit, too_hot_limit, imageThumbnailb64, imageData, error_modal, cropWidth, cropHeight, showDiscardPopup } = this.state;

        const procedNextStep = recipeName && recipeName.trim() !== '' && too_cold_limit !== "" && too_hot_limit !== "" && cooking_methodology !== "" && unit !== "";
        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658" /></Link>
                                    <Link to="/librarySelectDevice" className="active" title={formatMessage({ id: 'KC1180' })}><FormattedMessage id="KC1180" /></Link>
                                    <button type="button" id="popupTrigger" className="displaynone" data-toggle="modal" data-target="#imageResizeModal" onClick={() => { }}>&nbsp;</button>
                                </li>
                                <li className="selectedModel">ME, MG</li>
                            </ul>
                        </div>

                        <div className="rcDeviceWrapper">
                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} /></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={this.processPrevious} className="btn backButton">&nbsp;</button>
                                    Recipe Creation
                                    <button type="button" disabled={!procedNextStep} onClick={this.processNext} className= {recipeName && recipeName.trim() && recipeName.trim() !== "" ? "btn nextButton" :"btn nextButton disabled"}>&nbsp;</button>
                                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn alignTop">
                                                <div className="rcSaveForm">
                                                    <div className="rcCNameDiv">
                                                        <div className="rcCreateFrm">
                                                            <ul>
                                                                <li>
                                                                    <label>Enter Recipe Name</label>
                                                                    <input type="text" minLength={1} maxLength={19} value={recipeName} onChange={(e) => this.setState({ recipeName: e.target.value, isChanged: true })} className="form-control" />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="rcCreateFrm">
                                                            <ul>
                                                                <li className="cookingMethodology">
                                                                    <label>Cooking Methodology</label>
                                                                    <select className="form-control mediumWidth" value={cooking_methodology} onChange={(e) => this.setState({ cooking_methodology: e.target.value, isChanged: true })}>
                                                                        {["Steel", "Standard", "Steel / Water", "LRS / Water"].map((method, idx) => {
                                                                            return (
                                                                                <option key={idx} value={method}>{method}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="rcCreateFrm">
                                                            <ul>
                                                                <li className="form5colm">
                                                                    <label>Unit</label>
                                                                    <select className="form-control minWidth" value={unit}
                                                                        onChange={(e) => {
                                                                            const { too_cold_limit, too_hot_limit } = this.state;
                                                                            if (too_cold_limit && too_cold_limit !== "OFF" && too_cold_limit !== 0 && too_cold_limit !== "") {
                                                                                const valueConverted = e.target.value === "°C" ? this.f2c(too_cold_limit) : this.c2f(too_cold_limit) && Number(this.c2f(too_cold_limit)) > 100 ? 100 : this.c2f(too_cold_limit) ;
                                                                                this.setState({ too_cold_limit: valueConverted });
                                                                            }
                                                                            if (too_hot_limit && too_hot_limit !== "OFF" && too_hot_limit !== 0 && too_hot_limit !== "") {
                                                                                const hotValueConverted = e.target.value === "°C" ? this.f2c(too_hot_limit) : this.c2f(too_hot_limit) && Number(this.c2f(too_hot_limit)) > 100 ? 100 : this.c2f(too_hot_limit);
                                                                                this.setState({ too_hot_limit: hotValueConverted });
                                                                            }
                                                                            this.setState({ unit: e.target.value, isChanged: true })
                                                                        }}>
                                                                        {["°F", "°C"].map((unit, idx) => {
                                                                            return (
                                                                                <option key={idx} value={unit}>{unit}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </li>
                                                                <li className="form3colm">
                                                                    <label>Too Cold Limit</label>
                                                                    <input type="number" className="form-control normalWidth" value={too_cold_limit} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} onKeyUp={(e) => this.IsAlphaNumeric(e)} 
                                                                        onChange={(e) => {
                                                                            const maxValue = unit === "°F" ? 100 : 56;
                                                                            if (e && e.target && e.target.value && (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
                                                                                return;
                                                                            }
                                                                            this.setState({ too_cold_limit: e.target.value, isChanged: true })} 
                                                                        }
                                                                    />
                                                                </li>
                                                                <li className="form3colm">
                                                                    <label>Too Hot Limit</label>
                                                                    <input type="number" className="form-control normalWidth" value={too_hot_limit} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} onKeyUp={(e) => this.IsAlphaNumeric(e)} 
                                                                        onChange={(e) => {
                                                                            const maxValue = unit === "°F" ? 100 : 56;
                                                                            if (e && e.target && e.target.value && (e.target.value.length > 3|| Number(e.target.value) > maxValue)) {
                                                                                return;
                                                                            }
                                                                            this.setState({ too_hot_limit: e.target.value, isChanged: true })} 
                                                                        }
                                                                    />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="rcCimportImageDiv editActive">
                                                        <div className="importImage">
                                                            {(imageThumbnailb64 && imageThumbnailb64 !== "") || (this.state.s3Url && this.state.s3Url !== "") ?
                                                                <button type="button" className="btn cancelButton"
                                                                    onClick={() => {
                                                                        this.setState({
                                                                            imageThumbnailb64: '',
                                                                            imageData: {},
                                                                            s3Url: ''
                                                                        })
                                                                    }}>&nbsp;</button>
                                                                : null}
                                                            <input type="file" accept="image/png" ref={input => this.inputElement = input} onChange={this.onImageChange} className="fileInputField" id="menuUpdate" />
                                                            <a href="javascript:void(0)" className="menuBrowse" ><img src={imageThumbnailb64 ? `data:${'image/png'};base64,${imageThumbnailb64}` : this.state.s3Url ? this.state.s3Url : require("../../../../../images/recipecreation/photosPictures.png")} onClick={() => this.inputElement.click()} /> </a>
                                                        </div>
                                                        <label>Select Photo </label>
                                                        {/* <label>Select Photo {imageThumbnailb64 ? "imageThumbnailb64": this.state.s3Url ? "s3Url" : "empty"}</label> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                        {/* <!-- imageResizeModal --> */}
                        <div className="modal fade imageResizeModal" data-keyboard="true" data-backdrop="static" id="imageResizeModal" tabindex="-1" role="dialog" aria-labelledby="infoModalLabel">
                            <div className="modal-dialog modal-lg imageResizeModal">
                                <div className="modal-content">
                                    <div className="modal-header modal-header-danger">
                                        <FormattedMessage id="KC1234" /> <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeCropPopup}>&nbsp;</button>
                                    </div>
                                    <div className="modal-body">
                                        <form className="imageResizeForm">
                                            <Cropper src={this.state.src} OnCrop={(croppedImageUrl) => this.cropSubmit(croppedImageUrl)} fileName={imageData ? imageData.imagefileName : ""} fileFormat={imageData ? imageData.imagefileext : ""} cropWidth={cropWidth} cropHeight={cropHeight} />
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- //imageResizeModal --> */}
                        {/* <!-- Discard Confirmation Modal --> */}
                        <div className={showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="deleteConfirmation">
                                            <FormattedMessage id="KC1218" />
                                        </div>
                                    </div>
                                    <div className="modal-footer textAlignCenter">
                                        <button className="btn btn-secondary deleteNoBtn" onClick={this.OnCancel} data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                                        <button className="btn btn-secondary deleteYesBtn" onClick={this.OnDiscard} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(GarlandRecipeCreation)
