import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { format, addDays } from 'date-fns';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { getUtilizationTableData } from '../../../actions/reportManagement/utilization/utilizationTableActions';
import { UNIT_BRAND_ID, NO_RESULT_MSG } from '../../../utils/appConstants';
import * as _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import { number } from '@amcharts/amcharts4/core';
import { FormattedMessage } from 'react-intl';

const htmlparser2 = require("htmlparser2");
var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'MM-DD-YYYY');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class TPMValueTable extends Component {

	constructor(props, context) {
		super(props, context);
		this.state = {
			type: 'Month',
			filterType: "Month",
			dateRange: {
				selection: {
					startDate: new Date(),
					endDate: new Date(),
					key: 'selection',
				},
			},
			noResultMsg: 'No Results',
			sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
			IsServerError: false,
			sdmindate: todaysDate,
			sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			edmaxdate: todaysDate,
			edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
			startDate: '',
			endDate: '',
			loaderIconVisible: true,
			hideDateRange: true,
			datapickflag: false
		};

		this.handleChangeStart = this.handleChangeStart.bind(this);
		this.handleChangeEnd = this.handleChangeEnd.bind(this);

	}

	componentDidMount = async () => {
		currentUnit = this.props.currentUnit;
		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
		todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
		this.props.getUtilizationTableData("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	componentDidUpdate = async (prevProp, prevState) => {
		if (this.props.globalDropdownFilter !== "Year" && this.props.globalDropdownFilter !== "Quarter") {
			if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
				this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
				this.handleTpmValueTable(this.props.globalDropdownFilter, '');
				await this.props.handleDropdownChangeEnd();
			} else {
				if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
					if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
						this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
						await this.props.handleDropdownChangeEnd();
					}
					if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
						this.handleChangeStart(this.props.globalDropdownFilterStartDate);
						await this.props.handleDropdownChangeEnd();
					}
				}
			}
		}
	}

	handleChangeStart(holdingDate) {
		if(this.state.startDate ===  holdingDate){
			return;
		}
		this.setState({
			startDate: holdingDate,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			this.handleTpmValueTable(selectedtext);
		});
	}

	handleChangeEnd(holdingDate) {
		if(this.state.endDate === holdingDate){
			return;
		}
		this.setState({
			endDate: holdingDate,
			datapickflag: true
		}, () => {
			let selectedtext = 'Date Range';
			this.handleTpmValueTable(selectedtext);
		});
	}

	onDropDownHandler(e) {
		const { nodeText:text } = e;
		this.setState({type: text, datapickflag: text === 'Date Range', startDate:'', endDate:''})
		this.handleTpmValueTable(text);
	}

	handleTpmValueTable(text) {
		currentUnit = this.props.currentUnit;
		const { startDate, endDate } = this.state;

		currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
		unitID = currentUnit.UNITID;
		brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
		if (text === "Date Range") {
			todaysDateFormatted = format(endDate, 'YYYY-MM-DD');
			weekDateFormatted = format(startDate, 'YYYY-MM-DD');
		}
		else {
			todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
			weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
		}
		this.setState({ type: text });
		if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") &&
			(weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
			this.props.getUtilizationTableData(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
		}
	}

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	setColor = (tpmValue) => {
		if (typeof tpmValue !== "number") {
			return;
		}
		if (tpmValue < 20)
			return "greatStatus";
		if (tpmValue >= 20 & tpmValue <= 23)
			return "goodStatus";
		if (tpmValue > 23)
			return "poorStatus";
		else {
			return "";
		}
	}

	render() {
		const { utilizationTableInfo } = this.props;
		const { sortColumn } = this.state;
		const utilizationTableData = utilizationTableInfo.utilizationTableResponse ? utilizationTableInfo.utilizationTableResponse : {};
		let utilizationTableWithColumn = utilizationTableData.content || [];
		let columns = [
			{ path: "", label: "#" }
		];

		let tableContent = (<div className="noResultMsg">{utilizationTableInfo.errormsgUtilizationTable ? utilizationTableInfo.errormsgUtilizationTable : NO_RESULT_MSG}</div>);
		if (Array.isArray(utilizationTableWithColumn) && utilizationTableWithColumn.length) {
			const headerObject = utilizationTableWithColumn.find(fl => fl.sno === "#");
			const keys = _.keys(_.omit(headerObject, ["oilDiscardDays", "predTpmValue"]));
			columns = keys.map(k => {
				if (k.includes("datetime")) {
					return {
						path: k, labelStringID: 'KC0298'
					}
				}
				else if (k === "sno") {
					return { path: k, label: "#" }
				}
				else {
					return {
						path: k, label: k.replace("frypot", "Frypot ")
					}
				}
			});
			columns.push({ path: "", labelStringID: 'KC0325' });
			utilizationTableWithColumn = _.orderBy(utilizationTableWithColumn, [sortColumn.path], [sortColumn.order]);
			utilizationTableWithColumn = utilizationTableWithColumn.filter(a => a.datetime !== "Date");

			const filterKey = _.filter(keys, el => ['sno', 'datetime'].indexOf(el) == -1);

			tableContent = utilizationTableWithColumn.map((el, index) => {
				return (
					<React.Fragment>
						<div className="TPMValueTable" key={"tableContent_" + index}>
							<ul className="tBody">
								<li>{el.sno}</li>
								<li>{el.datetime}
									<div className="frypotWiseSM">
										{_.map(filterKey, k => <div>{headerObject[k] + " : " + el[k]}</div>)}
									</div>
								</li>
								{filterKey.map(td => <li key={td + index} className={this.setColor(el[td])}> {el[td]}</li>)}

								<li><a role="button" data-toggle="collapse" data-parent="#TPMValueAccordion" data-target={"#TPMValueAcc1" + index} aria-expanded="false" aria-controls="TPMValueAcc1" className="btn collapseExpand">&nbsp;</a></li>

							</ul>
						</div>

						<div id={"TPMValueAcc1" + index} className="panel-collapse collapse" role="tabpanel" aria-labelledby="TPMValueAccH1">
							<div className="panel-body TPMValueTable">
								<ul className="tBody">
									<li>&nbsp;</li>
									<li><FormattedMessage id="KC1797"/></li> 
									{_.map(filterKey, k => <li>{el.predTpmValue[k]}</li>)}
									<li>&nbsp;</li>
								</ul>
								<ul className="tBody">
									<li>&nbsp;</li>
									<li><FormattedMessage id="KC1798"/></li> 
									{_.map(filterKey, k => <li>{el.oilDiscardDays[k]}</li>)}
									<li>&nbsp;</li>
								</ul>

							</div>
						</div>
					</React.Fragment>
				)
			})
		}
		return (
			<div className="gridViewTable">
				<div className="gridView">
					<div className="colm12D">
						<div className="colmDi">
							<h4><FormattedMessage id="KC0444"/>
								<span className="cornerIcon">
								</span>
								<NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate}
									minDateDisable={this.state.sdmindate}
									maxDateDisable={this.state.sdmaxdate}
									endminDateDisable={this.state.edmindate}
									endmaxDateDisable={this.state.edmaxdate}
									endDatepara={this.state.endDate}
									handleChangeStartProps={(date) => this.handleChangeStart(date)}
									handleChangeEndProps={(date) => this.handleChangeEnd(date)}
									OnChange={(e) => this.onDropDownHandler(e)}
									filterType={this.state.type}
									disableQuarterFilter={true}
									disableYearFilter={true}
									minDaysRequired={6}
									maxDaysRequired={29} />
							</h4>
							<div class="TPMValueTableDiv">
								<div className="TPMValueTableOuter">
									<div className="TPMValueTable">
										<TableHeader
											sortColumn={sortColumn}
											onSort={this.onSort}
											columns={columns} />
									</div>
									<div class="panel panel-default" id="TPMValueAccordion" role="tablist" aria-multiselectable="true">
										{tableContent}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
};

function mapStateToProps(state) {
	return {
		utilizationTableInfo: state.utilizationTableResponse,
		currentUnit: state.entityReducer.currentUnit,
		currentLocation: state.entityReducer.currentLocation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		getUtilizationTableData,
		getCurrentLocation,
		getCurrentUnit
	}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(TPMValueTable);