import React, { Component } from 'react';
import $ from 'jquery';
import { DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';
import { FormattedMessage } from 'react-intl';

const styleObj = {
    field: {
        width: `100%`
    },
    input: {
        width: '100%',
        padding: '1px 12px 1px 12px',
        // lineHeight: 'inherit',
        background: 'transparent',
        color: '#253465',
        fontSize: '16px',
        border: '1px solid #6E87A2',
        borderRadius: '6px',
        appearance: 'none',
        placeholder: { color: "#6E87A2" },
    },
    label: {
        float: 'left',
        clear: 'both',
        color: '#253465',
        fontSize: '16px',
        fontFamily: 'sans-serif',
        fontWeight: 'unset'
    },
    message: { paddingTop: '2px', paddingBottom: '1px' }
}

class directDebitCard extends Component {
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.chargifyForm = React.createRef();
        this.state = {
            token: '',
            envData: {},
            checkedValue: true,
            custDetails:  {},            
            billingDetails: this.props.paymentProfile ?  this.props.paymentProfile : {},
        };
    }

    handleSubmit = () => {
        return new Promise(async (res, rej) => {
            await this.chargify.token(
                this.chargifyForm.current,

                (token) => {
                    $('.cfy-container--inline.cfy-container--invalid').css('border-color', '#ddd')
                    res(this.setState({ token: token }));
                },

                (error) => {
                    this.props.throwError(error)
                    rej(error);
                }
            );
        })
    }

    getDDFormFields = (region) => {
        const {billingDetails,custDetails} = this.state;
        const subDet = billingDetails.address1 !=='' ? billingDetails : custDetails;
        const fieldsForUK = {
            firstName: {
                selector: '#chargify5',
                label: 'First Name *',
                required: true,
                placeholder: 'John',
                message: 'Invalid First Name',
                maxlength: '50',
                style: styleObj
            },
            lastName: {
                selector: '#chargify6',
                label: 'Last Name *',
                required: true,
                placeholder: 'Doe',
                message: 'Invalid Last Name',
                maxlength: '50',
                style: styleObj
            },
            accountNumber: {
                selector: "#chargify5",
                label: 'Account Number *',
                required: true,
                placeholder: 'XXXX-XXXX-XXXX-0126',
                message: 'Invalid Account Number',
                maxlength: '34',
                style: styleObj
            },
            branchCode: {
                selector: "#chargify6",
                label: 'Sort Code *',
                required: true,
                placeholder: 'XXXXXX',
                message: 'Invalid Sort Code',
                maxlength: '11',
                style: styleObj
            },
            accountHolderType: {
                selector: "#chargify5",
                label: "Account Holder Type *",
                required: true,
                placeholder: 'Select Account Holder Type',
                message: 'Invalid Account Holder Type',
                style: styleObj
            },
            bankName: {
                selector: "#chargify6",
                label: 'Bank Name *',
                required: true,
                placeholder: 'Bank Name',
                message: 'Invalid Bank Name',
                maxlength: '50',
                style: styleObj
            },
            email: {
                selector: "#chargify5",
                label: 'Email Address *',
                required: true,
                placeholder: 'example@email.com',
                message: 'Invalid Email Address',
                maxlength: '50',        
                style: styleObj,
                value:localStorage.getItem('user')
            },
        
            address: {
                selector: '#chargify7',
                label: 'Address Line 1 *',
                placeholder: 'Address 1',
                required: true,
                style: styleObj,
                message: 'Invalid Address Line 1',
                maxlength: '200',
                value: subDet.address1
            },
            address2: {
                selector: '#chargify8',
                label: 'Address Line 2',
                placeholder: 'Address 1',
                required: false,
                style: styleObj,
                message: 'Invalid Address Line 2',
                maxlength: '200',
                value: subDet.address2
            },
            country: {
                selector: '#chargify9',
                label: 'Country *',
                placeholder: 'Country',
                required: true,
                style: styleObj,
                message: 'Invalid Country',
                maxlength: '50',
                value: subDet.country
            },
            state: {
                selector: '#chargify9',
                label: 'State *',
                placeholder: 'State',
                required: true,
                style: styleObj,
                message: 'Invalid State',
                maxlength: '50',
                value: subDet.state
            },
            city: {
                selector: '#chargify10',
                label: 'City *',
                placeholder: 'City',
                required: true,
                style: styleObj,
                message: 'Invalid City',
                maxlength: '50',
                value: subDet.city
            },
            zip: {
                selector: '#chargify10',
                label: 'ZIP Code *',
                placeholder: 'ZIP Code',
                required: true,
                style: styleObj,
                message: 'Invalid ZIP Code',
                maxlength: '8',
                value: subDet.zip
            },
        }
        
        const fieldsForUS = {
            firstName: {
                selector: '#chargify5',
                label: 'First Name *',
                required: true,
                placeholder: 'John',
                message: 'Invalid First Name',
                maxlength: '50',
                style: styleObj
            },
            lastName: {
                selector: '#chargify6',
                label: 'Last Name *',
                required: true,
                placeholder: 'Doe',
                message: 'Invalid Last Name',
                maxlength: '50',
                style: styleObj
            },
            accountNumber: {
                selector: "#chargify5",
                label: 'Account Number *',
                required: true,
                placeholder: 'XXXX-XXXX-XXXX-0126',
                message: 'Invalid Account Number',
                maxlength: '34',
                style: styleObj
            },
            routingNumber: {
                selector: "#chargify6",
                label: 'Routing Number *',
                required: true,
                placeholder: 'XXXXXX',
                message: 'Invalid Routing Number',
                maxlength: '11',
                style: styleObj
            },
            accountType: {
                selector: "#chargify5",
                label: "Account Type *",
                required: true,
                placeholder: 'Select Account Type',
                message: 'Invalid Account Type',
                style: styleObj
            },
            accountHolderType: {
                selector: "#chargify6",
                label: "Account Holder Type *",
                required: true,
                placeholder: 'Select Account Holder Type',
                message: 'Invalid Account Holder Type',
                style: styleObj
            },
            bankName: {
                selector: "#chargify5",
                label: 'Bank Name *',
                required: true,
                placeholder: 'Bank Name',
                message: 'Invalid Bank Name',
                maxlength: '50',
                style: styleObj
            },
            email: {
                selector: "#chargify6",
                label: 'Email Address *',
                required: true,
                placeholder: 'example@email.com',
                message: 'Invalid Email Address',        
                maxlength: '50',
                style: styleObj,
                value:localStorage.getItem('user')
            },
        
            address: {
                selector: '#chargify7',
                label: 'Address Line 1 *',
                placeholder: 'Address 1',
                required: true,
                style: styleObj,
                message: 'Invalid Address Line 1',
                maxlength: '200',
                value: subDet.address1
            },
            address2: {
                selector: '#chargify8',
                label: 'Address Line 2',
                placeholder: 'Address 1',
                required: false,
                style: styleObj,
                message: 'Invalid Address Line 2',
                maxlength: '200',
                value: subDet.address2
            },
            country: {
                selector: '#chargify9',
                label: 'Country *',
                placeholder: 'Country',
                required: true,
                style: styleObj,
                message: 'Invalid Country',
                maxlength: '50',
                value: subDet.country
            },
            state: {
                selector: '#chargify9',
                label: 'State *',
                placeholder: 'State',
                required: true,
                style: styleObj,
                message: 'Invalid State',
                maxlength: '50',
                value: subDet.state                
            },
            city: {
                selector: '#chargify10',
                label: 'City *',
                placeholder: 'City',
                required: true,
                style: styleObj,
                message: 'Invalid City',
                maxlength: '50',
                value: subDet.city
            },
            zip: {
                selector: '#chargify10',
                label: 'ZIP Code *',
                placeholder: 'ZIP Code',
                required: true,
                style: styleObj,
                message: 'Invalid ZIP Code',
                maxlength: '5',
                value: subDet.zip
            },
        }
        
        const fieldsForEU = {
            firstName: {
                selector: '#chargify5',
                label: 'First Name *',
                required: true,
                placeholder: 'John',
                color: "#63778a",
                message: 'Invalid First Name',
                maxlength: '50',
                style: styleObj
            },
            lastName: {
                selector: '#chargify6',
                label: 'Last Name *',
                required: true,
                placeholder: 'Doe',
                message: 'Invalid Last Name',
                maxlength: '50',
                style: styleObj
            },
            bankIban: {
                selector: "#chargify5",
                label: 'IBAN (International Bank Account Number) *',
                required: true,
                placeholder: 'XXXX-XXXX-XXXX-0126',
                message: 'Invalid IBAN',
                maxlength: '34',
                style: styleObj
            },
            accountHolderType: {
                selector: "#chargify6",
                label: "Account Holder Type *",
                required: true,
                placeholder: 'Select Account Holder Type',
                message: 'Invalid Account Holder Type',
                style: styleObj
            },
            bankName: {
                selector: "#chargify5",
                label: 'Bank Name *',
                required: true,
                placeholder: 'Bank Name',
                message: 'Invalid Bank Name',
                maxlength: '50',
                style: styleObj
            },
            email: {
                selector: "#chargify6",
                label: 'Email Address *',
                required: true,
                placeholder: 'example@email.com',        
                message: 'Invalid Email Address',
                maxlength: '50',
                style: styleObj,
                value:localStorage.getItem('user'),
            },
        
            address: {
                selector: '#chargify7',
                label: 'Address Line 1 *',
                placeholder: 'Address 1',
                required: true,
                style: styleObj,
                message: 'Invalid Address Line 1',
                maxlength: '70',
                value: subDet.address1
            },
            address2: {
                selector: '#chargify8',
                label: 'Address Line 2',
                placeholder: 'Address 1',
                required: false,
                style: styleObj,
                message: 'Invalid Address Line 2',
                maxlength: '70',
                value: subDet.address2
            },
            country: {
                selector: '#chargify9',
                label: 'Country *',
                placeholder: 'Country',
                required: true,
                style: styleObj,
                message: 'Invalid Country',
                maxlength: '50',
                value: subDet.country
            },
            state: {
                selector: '#chargify9',
                label: 'State *',
                placeholder: 'State',
                required: true,
                style: styleObj,
                message: 'Invalid State',
                maxlength: '50',
                value: subDet.state
            },
            city: {
                selector: '#chargify10',
                label: 'City *',
                placeholder: 'City',
                required: true,
                style: styleObj,
                message: 'Invalid City',
                maxlength: '50',
                value: subDet.city
            },
            zip: {
                selector: '#chargify10',
                label: 'ZIP Code *',
                placeholder: 'ZIP Code',
                required: true,
                style: styleObj,
                message: 'Invalid ZIP Code',
                maxlength: '9',
                value: subDet.zip
            },
        }
        return region === "USD" ? fieldsForUS : region === "GBP" ? fieldsForUK : region === "EUR" ? fieldsForEU : {}
    }

    async loadKCUI(response) {        
        await this.chargify.load({
            selector: '#chargify-form',
            publicKey: response.public_key,
            type: response.default_currency === "USD" ? "bank" : "direct_debit",
            serverHost: response.server_host,
            addressDropdowns: true,
            optionalLabel: " ",
            // requiredLabel: "*",            
            fields: await this.getDDFormFields(response.default_currency)
        });

        this.props.handleLoader(false);
        this.props.setIsLoaded(true);
        // $('.cfy-additional-label').html('')
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        this.chargify = new window.Chargify(); 
        await this.setAddressDetails();
        await this.getChargifyApiKey();
    }

    setAddressDetails = () => {
        let subDet = this.props.paymentProfile ? this.props.paymentProfile : {};
        this.setState({
            billingDetails: {
                address1: subDet?.billing_address ? subDet.billing_address : "",
                address2: subDet?.billing_address_2 ? subDet.billing_address_2 : "",
                city: subDet?.billing_city ? subDet.billing_city : "",
                zip: subDet?.billing_zip ? subDet.billing_zip : "",
                state: subDet?.billing_state ? subDet.billing_state : "",
                country: subDet?.billing_country ? subDet.billing_country : "",
            }
        })

        subDet = localStorage.getItem('subscriptionDetails') && JSON.parse(localStorage.getItem('subscriptionDetails')) ? JSON.parse(localStorage.getItem('subscriptionDetails')) : {};
        this.setState({
            custDetails: {
                address1: subDet?.street_name ? subDet.street_name : "",
                address2: subDet?.street_name ? subDet.street_name : "",
                city: subDet?.city ? subDet.city : "",
                zip: subDet?.zip_code ? subDet.zip_code : "",
                state: subDet?.state ? subDet.state : "",
                country: subDet?.country ? subDet.country : "",
            }
        })
    }

    getChargifyApiKey = () => {
        return new Promise((res, rej) => {
            let url = DeviceRegistration.getChargifyApiKey;
            this.props.handleLoader(true);
            http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                },
                data: {}
            }).then(async response => {
                if (response.data && response.data.length) {
                    const envIDValue = localStorage.getItem('subscriptionDetails') && JSON.parse(localStorage.getItem('subscriptionDetails'))?.env_id ? JSON.parse(localStorage.getItem('subscriptionDetails'))?.env_id : 1
                    const envId = response.data.find((i) => i.id === envIDValue);
                    this.setState({ envData: envId })
                    await this.loadKCUI(envId);
                    res(true);
                }
            }).catch(err => {
                res(false);
            });
        })
    }

    componentWillUnmount() {
        this.chargify.unload();
    }

    onCheckBoxChanges = (e)=>{
        // window.scrollTo(0, 0);
        // window.scrollTo({ left: 0, top: document.body.scrollHeight, behavior: "smooth" });
        this.setState({ checkedValue: e.target.checked });
        // this.loadKCUI(this.state.envData);
    }

    render() {
        return (
            <form ref={this.chargifyForm} className='payment-card-subscription'>
                <div className="paymentForm">
                    <ul>
                        <li className="colm50 firstLastName">
                            <div id="chargify5"></div>
                        </li>
                        <li className="colm50 firstLastName">
                            <div id="chargify6"></div>
                        </li>
                    </ul>
                </div>

                <div className='billingInfo'>
                    <div className='billingInfoFirstSec'>
                        <span style={{ color: "#0D3968", fontSize: "24px", fontWeight: "400px" }}><FormattedMessage id='KC2977'/></span>
                        <div className='formRequired'><FormattedMessage id='KC2976'/></div>
                        <div class={"customCheckbox customCheckboxWOlabel"}>
                            <input
                                type="checkbox"
                                id='chargify'
                                name={'chargify'}
                                checked={this.state.checkedValue}
                                onChange={(e) => this.onCheckBoxChanges(e)}
                            />
                            <label style={{ padding: '3px 30px', whiteSpace: 'normal', color: "rgb(13, 57, 104)", fontSize: "14px", fontWeight: "400px" }} for={'chargify'}><FormattedMessage id='KC2978'/></label>
                        </div>
                    </div>
                    <div className={`paymentForm ${this.state.checkedValue ? 'disabled' : ''}`} style={{ display: this.state.checkedValue ? 'none' : '' }}>
                        <ul>
                            <li>
                                <div id="chargify7"></div>
                            </li>
                        </ul>
                    </div>
                    <div className={`paymentForm ${this.state.checkedValue ? 'disabled' : ''}`} style={{ display: this.state.checkedValue ? 'none' : '' }}>
                        <ul>
                            <li>
                                <div id="chargify8"></div>
                            </li>
                        </ul>
                    </div>
                    <div className={`paymentForm ${this.state.checkedValue ? 'disabled' : ''}`} style={{ display: this.state.checkedValue ? 'none' : '' }}>
                        <ul>
                            {/* {!this.props.loaderIconVisible ? */}
                                <li className="colm50 firstLastName">
                                    <div id="chargify9"></div>
                                </li>
                                {/* : null} */}
                            <li className="colm50 firstLastName">
                                <div id="chargify10"></div>
                            </li>
                        </ul>
                    </div>
                </div>
                {this.state.checkedValue ? <div style={{ marginTop: 0 }}>
                    {Object.keys(this.state.custDetails).length ? Object.keys(this.state.custDetails).map((dataVal) => {
                        return <p style={{ padding: 1, margin: 0, color: "#6E87A2", fontSize: "14px", fontFamily: "sans-serif" }}>{this.state.custDetails[dataVal]}</p>
                    }) : null}
                </div> : null}
            </form>
        )
    }
}

export default directDebitCard;