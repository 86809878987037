import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import $ from 'jquery';
import http from '../../../../../service/httpService';
import { injectIntl, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { MenuUploadAPI } from '../../../../../service/api';

import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import ErrorMoal from '../../../../modal/error-model';

const pattern = /^[0-9]{0,4}$/;

class GarlandRecipeCreationSteps extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            error_modal: {
                open: false,
                message: ""
            },
            steps: [],
            promptAction: []
        }
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        await this.getPromptActions();
        await this.setupInitial();
        this.updateDimensions();
        $(".mCustomScrollbar").mCustomScrollbar();
    }

    getPromptActions = async () => {
        await http({
            method: "GET",
            url: MenuUploadAPI.promptValueGet,
            headers: {
                'Content-Type': 'application/json',
            }, data: {}
        }).then(response => {
            console.log("prompt", response);
            if (response && response.data) {
                if (response.data.body && response.data.body.data) {
                    this.setState({
                        promptAction: [{ 'id': 0, text: "None", uuid: "" }, ...response.data.body.data] || []
                    })
                }
            }
        }).catch(err => {
            console.log('catch exception occured');
        });
    }

    setupInitial = () => {
        const garlandObj = localStorage.getItem('garlandRecipe') ? JSON.parse(localStorage.getItem('garlandRecipe')) : {};
        if (garlandObj && garlandObj.steps) {
            this.setState({ steps: garlandObj.steps, loaderIconVisible: false })
        } else {
            const { promptAction } = this.state;
            const defaultPrompt = promptAction.filter((i)=>i.text === "Default Done");

            this.setState({
                steps: [{
                    cookTime: '0',
                    promptSelection: defaultPrompt && defaultPrompt.length ? defaultPrompt[0].text : 'None',
                    prompt: defaultPrompt && defaultPrompt.length ? defaultPrompt[0].uuid : 'None',
                    uniqueId: (new Date()).getTime(),
                    flatCooking: 'No',
                    cookingGap: '0',
                    unit: '°F',
                    upperPlattenTemp: 'Off',
                    upperPlattenCheck: false,
                    upperPlattenValue: '',
                    grillPlattenTemp: 'Off',
                    grillPlattenCheck: false,
                    grillPlattenValue: '',
                    grillFrontTemp: 'Off',
                    grillFrontCheck: false,
                    grillFrontValue: '',
                }],
                loaderIconVisible: false
            })
        }
    }

    updateDimensions = () => {
        var windowHeight = $(window).height();
        var menuFilterHeader = $(".menuFilterHeader").height();
        var pageRRHeight = ((windowHeight) - (menuFilterHeader)) - 105;
        $(".readyRecipeSrlTable").css("height", pageRRHeight);
        console.log("welcome pageRRHeight", pageRRHeight)
    }

    addSteps = () => {
        let stepValue = this.state.steps;
        const { promptAction } = this.state;
        const defaultPrompt = promptAction.filter((i)=>i.text === "Default Done");

        this.setState((prevState) => ({
            steps: [...prevState.steps, {
                cookTime: '0',
                promptSelection: defaultPrompt && defaultPrompt.length ? defaultPrompt[0].text : 'None',
                prompt: defaultPrompt && defaultPrompt.length ? defaultPrompt[0].uuid : 'None',
                uniqueId: (new Date()).getTime(),
                flatCooking: 'No',
                cookingGap: '0',
                unit: stepValue && stepValue.length ? stepValue[0].unit : "°F",
                upperPlattenTemp: 'Off',
                upperPlattenCheck: false,
                upperPlattenValue: '',
                grillPlattenTemp: 'Off',
                grillPlattenCheck: false,
                grillPlattenValue: '',
                grillFrontTemp: 'Off',
                grillFrontCheck: false,
                grillFrontValue: '',
            }],
        }));
    }

    deleteRow = (index) => {
        let steps = this.state.steps;
        console.log("steps delete", steps)
        steps.splice(index, 1);
        this.setState({ steps }, () => {
            console.log("ththht", this.state)
        });
    }

    proceedPrevious = async () => {
        let garlandObj = localStorage.getItem('garlandRecipe') ? JSON.parse(localStorage.getItem('garlandRecipe')) : {};
        garlandObj['steps'] = this.state.steps;
        console.log("exex", garlandObj)
        await localStorage.setItem('garlandRecipe', JSON.stringify(garlandObj));
        this.props.history.replace('/garlandRecipeCreation')
    }

    onChangeEvent = (e, index, state) => {
        if (state === "unit") {
            let steps = this.state.steps;
            for (let idx = 0; idx < steps.length; idx++) {
                steps[idx][state] = e.target.value;
                if (steps[idx]['upperPlattenTemp'] && steps[idx]['upperPlattenTemp'] !== "Off" && steps[idx]['upperPlattenTemp'] !== 0 && steps[idx]['upperPlattenTemp'] !== "") {
                    const upperConvertedValue = e.target.value === "°C" ? this.f2c(steps[idx]['upperPlattenTemp']) : this.c2f(steps[idx]['upperPlattenTemp']);
                    steps[idx]['upperPlattenTemp'] = upperConvertedValue;
                    steps[idx]['upperPlattenValue'] = upperConvertedValue;
                }
                if (steps[idx]['grillPlattenTemp'] && steps[idx]['grillPlattenTemp'] !== "Off" && steps[idx]['grillPlattenTemp'] !== 0 && steps[idx]['grillPlattenTemp'] !== "") {
                    const grillConvertedValue = e.target.value === "°C" ? this.f2c(steps[idx]['grillPlattenTemp']) : this.c2f(steps[idx]['grillPlattenTemp']);
                    steps[idx]['grillPlattenTemp'] = grillConvertedValue;
                    steps[idx]['grillPlattenValue'] = grillConvertedValue;
                }
                if (steps[idx]['grillFrontTemp'] && steps[idx]['grillFrontTemp'] !== "Off" && steps[idx]['grillFrontTemp'] !== 0 && steps[idx]['grillFrontTemp'] !== "") {
                    const frontConvertedValue = e.target.value === "°C" ? this.f2c(steps[idx]['grillFrontTemp']) : this.c2f(steps[idx]['grillFrontTemp']);
                    steps[idx]['grillFrontTemp'] = frontConvertedValue;
                    steps[idx]['grillFrontValue'] = frontConvertedValue;

                }
            }
            this.setState({ steps })
        } else {
            let steps = this.state.steps;
            if (state === "promptSelection") {
                const promptValue = e.target.value.split('|||');
                steps[index][state] = promptValue && promptValue.length ? promptValue[0] : '';
                steps[index]['prompt'] = promptValue && promptValue.length ? promptValue[1] || "" : '';
            } else {
                steps[index][state] = e.target.value;
            }
            this.setState({ steps })
        }
    }

    validateForm = () => {
        const { steps } = this.state;
        for (let index = 0; index < steps.length; index++) {
            const { unit, upperPlattenTemp, grillFrontTemp, grillPlattenTemp, cookTime, cookingGap } = steps[index];
            if (unit === "°F" && upperPlattenTemp !== "Off") {
                if (Number(upperPlattenTemp) >= 149 && Number(upperPlattenTemp) <= 450) {
                } else {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: 'Please enter upper platten temp value between 149 to 450 F.'
                        }
                    });
                    return false;
                }
            }
            if (unit === "°C" && upperPlattenTemp !== "Off") {
                if (Number(upperPlattenTemp) >= 65 && Number(upperPlattenTemp) <= 232) {
                } else {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: 'Please enter upper platten temp value between 65 to 232 C.'
                        }
                    });
                    return false;
                }
            }
            if (unit === "°F" && grillPlattenTemp !== "Off") {
                if (Number(grillPlattenTemp) >= 149 && Number(grillPlattenTemp) <= 400) {
                } else {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: 'Please enter grill plate temp value between 149 to 400 F.'
                        }
                    });
                    return false;
                }
            }
            if (unit === "°C" && grillPlattenTemp !== "Off") {
                if (Number(grillPlattenTemp) >= 65 && Number(grillPlattenTemp) <= 204) {
                } else {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: 'Please enter grill plate temp value between 65 to 204 C.'
                        }
                    });
                    return false;
                }
            }
            if (unit === "°F" && grillFrontTemp !== "Off") {
                if (Number(grillFrontTemp) >= 149 && Number(grillFrontTemp) <= 400) {
                } else {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: 'Please enter grill front temp value between 149 to 400 F.'
                        }
                    });
                    return false;
                }
            }

            if (unit === "°C" && grillFrontTemp !== "Off") {
                if (Number(grillFrontTemp) >= 65 && Number(grillFrontTemp) <= 204) {
                } else {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: 'Please enter grill front temp value between 65 to 204 C.'
                        }
                    });
                    return false;
                }
            }
            if(cookTime === ""){
                $(`#cookTime${(index+1)}`).css('border','1px solid red')
                return false;
            }
            if(cookingGap === ""){
                $(`#cookGap${(index+1)}`).css('border','1px solid red')
                return false;
            }

        }
        return true;
    }

    onSave = async () => {
        this.setState({
            loaderIconVisible: true
        });
        let validate = await this.validateForm();
        if (!validate) {
            this.setState({ loaderIconVisible: false });
            return;
        }

        let garlandObj = localStorage.getItem('garlandRecipe') ? JSON.parse(localStorage.getItem('garlandRecipe')) : {};
        garlandObj['steps'] = this.state.steps;
        garlandObj.active_compression = 0;
        garlandObj.cookingRegions = [];
        let cookStep = [];
        for (let index = 0; index < this.state.steps.length; index++) {
            const element = this.state.steps[index];
            let obj = {
                "CookTime": parseInt(element.cookTime),
                // Decimicron conversion, so multiplied with 100000
                "Gap": parseInt(element.cookingGap) * 100000,
                "isFlat": element.flatCooking === "Yes" ? 1 : 0,
                "PromptSelection": element.promptSelection.toLowerCase(),
                // Decicelsius conversion, so multiplied with 100
                "tempLowerFront": element.grillFrontTemp === "Off" ? 0 : element.unit === "°F" ? parseInt(this.f2c(element.grillFrontTemp)) * 10 : parseInt(element.grillFrontTemp) * 10,
                "tempLowerPlaten": element.grillPlattenTemp === "Off" ? 0 : element.unit === "°F" ? parseInt(this.f2c(element.grillPlattenTemp)) * 10 : parseInt(element.grillPlattenTemp) * 10,
                "tempUpperPlaten": element.upperPlattenTemp === "Off" ? 0 : element.unit === "°F" ? parseInt(this.f2c(element.upperPlattenTemp)) * 10 : parseInt(element.upperPlattenTemp) * 10,
            };
            if (element.promptSelection !== "None") {
                obj["Prompt"] = element.prompt;
            }
            cookStep.push(obj);
        }
        garlandObj.cookingSteps = cookStep;
        garlandObj.partial_load = {};
        garlandObj.partial_load.elapsed_time = 0;
        garlandObj.partial_load.enabled = 0;
        garlandObj.partial_load.temp_delta = 0;
        garlandObj.partial_load.time_scale_factor = 0;
        garlandObj.pr_thaw = {};
        garlandObj.pr_thaw.detect_delta_gap = 0;
        garlandObj.pr_thaw.enabled = 0;
        garlandObj.pr_thaw.time = 0;
        garlandObj.protein_type = "beef";
        garlandObj.schemaVersion = "1.1";
        garlandObj.smokeFanStart_T_Minus = 0;
        garlandObj.smokeFanStop_T_Plus = 0;
        this.props.history.push({
            pathname: "/garlandLibraryToTopology",
            previouspath: "/garlandRecipeCreationSteps",
        });
        await localStorage.setItem('garlandRecipe', JSON.stringify(garlandObj));

    }

    c2f = (celsius) => {
        return Math.round((1.8 * celsius) + 32);
    }

    f2c = (fahrenheit) => {
        return Math.round((fahrenheit - 32) / 1.8);
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    IsAlphaNumeric(e) {
        e.target.value = e.target.value.trim().replace(/[^\d]/g, '');
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { loaderIconVisible, steps, error_modal, promptAction } = this.state;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658" /></Link>
                                    <Link to="/librarySelectDevice" className="active" title={formatMessage({ id: 'KC1180' })}><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li className="selectedModel">ME, MG</li>
                            </ul>
                        </div>

                        <div className="rcDeviceWrapper">
                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} /> <button type="button" onClick={this.onSave} className="btn saveExitBtn">Save &amp; Exit</button></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={() => this.proceedPrevious()} className="btn backButton">&nbsp;</button>
                                    Recipe Creation
                                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn alignTop">
                                                <div className="rcSaveForm">
                                                    {steps.map((step, index) => {
                                                        const rowCount = index + 1;
                                                        return (
                                                            <div key={rowCount + '_row'} data-id={rowCount} className="rcCreateFrm withBox">
                                                                <button type="button" className="btn rcRowCount">{rowCount}</button>
                                                                <button type="button" className={`btn deleteRowButton ${rowCount > 1 ? 'deleteActive' : ''}`} onClick={() => this.deleteRow(index)}>&nbsp;</button>
                                                                <ul>
                                                                    <li>
                                                                        <label>Cook Time (sec)</label>
                                                                        <input type="number" data-id={rowCount} id={`cookTime${rowCount}`} value={step.cookTime} className="form-control normalWidth"
                                                                            onKeyUp={(e) => this.IsAlphaNumeric(e)}
                                                                            onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                            onChange={(e) => {
                                                                                if (e && e.target && e.target.value && e.target.value.length > 4) {
                                                                                    return;
                                                                                }
                                                                                $(`#cookTime${(index+1)}`).css('border','none')
                                                                                this.onChangeEvent(e, index, 'cookTime')
                                                                            }
                                                                            } />
                                                                    </li>
                                                                    <li>
                                                                        <label>Prompt Selection</label>
                                                                        <select className="form-control" data-id={rowCount}
                                                                            onChange={(e) => {
                                                                                this.onChangeEvent(e, index, 'promptSelection')
                                                                            }}>
                                                                            {promptAction && promptAction.length ? promptAction.map((prompt, idx) => {
                                                                                return (
                                                                                    <option key={idx} selected={prompt.text === step.promptSelection} value={prompt.text + "|||" + prompt.uuid}>{prompt.text}</option>
                                                                                )
                                                                            }) : null}
                                                                        </select>
                                                                    </li>
                                                                    <li>
                                                                        <label>Flat Cooking</label>
                                                                        <select className="form-control minWidth" data-id={rowCount} value={step.flatCooking}
                                                                            onChange={(e) => {
                                                                                this.onChangeEvent(e, index, 'flatCooking');
                                                                                if (e.target.value === "Yes") {
                                                                                    const valueSet = {
                                                                                        target: {
                                                                                            value: 0
                                                                                        }
                                                                                    }
                                                                                    this.onChangeEvent(valueSet, index, 'cookingGap');
                                                                                    let steps = this.state.steps;
                                                                                    steps[index]['upperPlattenTemp'] = 'Off';
                                                                                    steps[index]['upperPlattenCheck'] = false;
                                                                                    steps[index]['upperPlattenValue'] = '';
                                                                                    this.setState({ steps },()=>{})
                                                                                }
                                                                            }}>
                                                                            {["Yes", "No"].map((cooking, idx) => {
                                                                                return (
                                                                                    <option key={idx} selected={cooking === step.flatCooking}>{cooking}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </li>
                                                                    <li className={step.flatCooking === "No" ? "" : "disabled"}>
                                                                        <label>Cooking Gap (mils)</label>
                                                                        <input type="number" value={step.cookingGap} id={`cookGap${rowCount}`} data-id={rowCount} className="form-control normalWidth"
                                                                            onKeyUp={(e) => this.IsAlphaNumeric(e)}
                                                                            onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                            onChange={(e) => {
                                                                                if (e && e.target && e.target.value && (e.target.value.length > 4 || Number(e.target.value) > 2000)) {
                                                                                    return;
                                                                                }
                                                                                $(`#cookGap${(index+1)}`).css('border','none')
                                                                                this.onChangeEvent(e, index, 'cookingGap')
                                                                            }
                                                                            } />
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li className={rowCount > 1 ? "disabled" : ''}>
                                                                        <label>Units</label>
                                                                        <select disabled={rowCount > 1} data-id={rowCount} className="form-control minWidth" value={steps && steps.length ? steps[0].unit : step.unit}
                                                                            onChange={(e) => {
                                                                                this.onChangeEvent(e, index, 'unit');

                                                                            }}>
                                                                            {["°F", "°C"].map((unit, idx) => {
                                                                                return (
                                                                                    <option key={idx} value={unit}>{unit}</option>
                                                                                )
                                                                            })}
                                                                        </select>
                                                                    </li>
                                                                    <li>
                                                                        <label>Upper Platen Temp</label>
                                                                        <div className= { step.flatCooking === "No" ? "dropdown setValDropdown" : "dropdown setValDropdown disabled" } data-id={rowCount}>
                                                                            <button className="btn dropdown-toggle" type="button" id={"dropdownMenuButtonUpper" + rowCount} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                {step.upperPlattenTemp}
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-left" aria-labelledby={"dropdownMenuButtonUpper" + rowCount}>
                                                                                <div className="setValD">
                                                                                    <input type="number" value={step.upperPlattenValue} className="form-control normalWidth"
                                                                                        onKeyUp={(e) => this.IsAlphaNumeric(e)}
                                                                                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                                        onChange={(e) => {
                                                                                            const maxValue = step.unit === "°F" ? 450 : 232;
                                                                                            if (e && e.target && e.target.value && (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
                                                                                                return;
                                                                                            }
                                                                                            this.onChangeEvent(e, index, 'upperPlattenValue');
                                                                                            if (step.unit === "°F" ? step.upperPlattenValue >= 149 && step.upperPlattenValue <= 450 : step.upperPlattenValue >= 65 && step.upperPlattenValue <= 232) {
                                                                                                let steps = this.state.steps;
                                                                                                steps[index]['upperPlattenCheck'] = true;
                                                                                                this.setState({ steps })
                                                                                            }else{
                                                                                                let steps = this.state.steps;
                                                                                                steps[index]['upperPlattenCheck'] = false;
                                                                                                this.setState({ steps })
                                                                                            }
                                                                                        }} />
                                                                                    <div className={`customCheckbox ${step.unit === "°F" ? step.upperPlattenValue >= 149 && step.upperPlattenValue <= 450 ? "" : "disabled" : step.upperPlattenValue >= 65 && step.upperPlattenValue <= 232 ? "" : "disabled"}`}>
                                                                                        <input id={"TempSetV" + rowCount} name={"TempSetV" + rowCount} type="checkbox" checked={step.upperPlattenCheck} value={"TempSetV" + rowCount}
                                                                                            onChange={() => {
                                                                                                if (!step.upperPlattenValue || step.upperPlattenValue === "") {
                                                                                                    return;
                                                                                                }
                                                                                                let steps = this.state.steps;
                                                                                                steps[index]['upperPlattenCheck'] = steps[index]['upperPlattenValue'] && steps[index]['upperPlattenValue'] !== "" ? true : false;
                                                                                                steps[index]['upperPlattenTemp'] = steps[index]['upperPlattenValue'] && steps[index]['upperPlattenValue'] !== "" ? steps[index]['upperPlattenValue'] : 'Off';

                                                                                               this.setState({ steps })
                                                                                            }
                                                                                            } />
                                                                                        <label for={"TempSetV" + rowCount}></label>
                                                                                    </div>
                                                                                </div>
                                                                                <a className="dropdown-item" href="javascript:void(0);"
                                                                                    onClick={() => {
                                                                                        let steps = this.state.steps;
                                                                                        steps[index]['upperPlattenCheck'] = false;
                                                                                        steps[index]['upperPlattenTemp'] = 'Off';
                                                                                        steps[index]['upperPlattenValue'] = '';

                                                                                        this.setState({ steps });
                                                                                    }}>Off</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <label>Grill Plate Temp</label>
                                                                        <div className="dropdown setValDropdown" data-id={rowCount}>
                                                                            <button className="btn dropdown-toggle" type="button" id={"dropdownMenuButtonGrill" + rowCount} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                {step.grillPlattenTemp}
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-left" aria-labelledby={"dropdownMenuButtonGrill" + rowCount}>
                                                                                <div className="setValD">
                                                                                    <input type="number" value={step.grillPlattenValue} className="form-control normalWidth"
                                                                                        onKeyUp={(e) => this.IsAlphaNumeric(e)}
                                                                                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                                        onChange={(e) => {
                                                                                            const maxValue = step.unit === "°F" ? 400 : 204;
                                                                                            if (e && e.target && e.target.value && (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
                                                                                                return;
                                                                                            }
                                                                                            this.onChangeEvent(e, index, 'grillPlattenValue');
                                                                                            if (step.unit === "°F" ? step.grillPlattenValue >= 149 && step.grillPlattenValue <= 400 : step.grillPlattenValue >= 65 && step.grillPlattenValue <= 204) {
                                                                                                let steps = this.state.steps;
                                                                                                steps[index]['grillPlattenCheck'] = true;
                                                                                                this.setState({ steps })
                                                                                            }else{
                                                                                                let steps = this.state.steps;
                                                                                                steps[index]['grillPlattenCheck'] = false;
                                                                                                this.setState({ steps })
                                                                                            }
                                                                                        }} />
                                                                                    <div className={`customCheckbox ${step.unit === "°F" ? step.grillPlattenValue >= 149 && step.grillPlattenValue <= 400 ? "" : "disabled" : step.grillPlattenValue >= 65 && step.grillPlattenValue <= 204 ? "" : "disabled"}`}>
                                                                                        <input id={"TempSetVGrill" + rowCount} name={"TempSetVGrill" + rowCount} type="checkbox" checked={step.grillPlattenCheck} value={"TempSetVGrill" + rowCount}
                                                                                            onChange={() => {
                                                                                                if (!step.grillPlattenValue || step.grillPlattenValue === "") {
                                                                                                    return;
                                                                                                }
                                                                                                let steps = this.state.steps;

                                                                                                steps[index]['grillPlattenCheck'] = steps[index]['grillPlattenValue'] && steps[index]['grillPlattenValue'] !== "" ? true : false;
                                                                                                steps[index]['grillPlattenTemp'] = steps[index]['grillPlattenValue'] && steps[index]['grillPlattenValue'] !== "" ? steps[index]['grillPlattenValue'] : 'Off';
                                                                                                
                                                                                                this.setState({ steps })
                                                                                            }
                                                                                            } />
                                                                                        <label for={"TempSetVGrill" + rowCount}></label>
                                                                                    </div>
                                                                                </div>
                                                                                <a className="dropdown-item" href="javascript:void(0);"
                                                                                    onClick={() => {
                                                                                        let steps = this.state.steps;
                                                                                        steps[index]['grillPlattenCheck'] = false;
                                                                                        steps[index]['grillPlattenTemp'] = 'Off';
                                                                                        steps[index]['grillPlattenValue'] = '';

                                                                                        this.setState({ steps });
                                                                                    }}>Off</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                    <li>
                                                                        <label>Grill Front Temp</label>
                                                                        <div className="dropdown setValDropdown" data-id={rowCount}>
                                                                            <button className="btn dropdown-toggle" type="button" id={"dropdownMenuButtonGrillFront" + rowCount} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                                {step.grillFrontTemp}
                                                                            </button>
                                                                            <div className="dropdown-menu dropdown-menu-left" aria-labelledby={"dropdownMenuButtonGrillFront" + rowCount}>
                                                                                <div className="setValD">
                                                                                    <input type="number" value={step.grillFrontValue} className="form-control normalWidth"
                                                                                        onKeyUp={(e) => this.IsAlphaNumeric(e)}
                                                                                        onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                                        onChange={(e) => {
                                                                                            const maxValue = step.unit === "°F" ? 400 : 204;
                                                                                            if (e && e.target && e.target.value && (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
                                                                                                return;
                                                                                            }
                                                                                            this.onChangeEvent(e, index, 'grillFrontValue');
                                                                                            if (step.unit === "°F" ? step.grillFrontValue >= 149 && step.grillFrontValue <= 400 : step.grillFrontValue >= 65 && step.grillFrontValue <= 204) {
                                                                                                let steps = this.state.steps;
                                                                                                steps[index]['grillFrontCheck'] = true;
                                                                                                this.setState({ steps })
                                                                                            }else{
                                                                                                let steps = this.state.steps;
                                                                                                steps[index]['grillFrontCheck'] = false;
                                                                                                this.setState({ steps })
                                                                                            }
                                                                                        }} />
                                                                                    <div className={`customCheckbox ${step.unit === "°F" ? step.grillFrontValue >= 149 && step.grillFrontValue <= 400 ? "" : "disabled" : step.grillFrontValue >= 65 && step.grillFrontValue <= 204 ? "" : "disabled"}`}>
                                                                                        <input id={"TempSetVGrillFront" + rowCount} name={"TempSetVGrillFront" + rowCount} type="checkbox" checked={step.grillFrontCheck} value={"TempSetVGrillFront" + rowCount}
                                                                                            onChange={() => {
                                                                                                if (!step.grillFrontValue || step.grillFrontValue === "") {
                                                                                                    return;
                                                                                                }
                                                                                                let steps = this.state.steps;
                                                                                                steps[index]['grillFrontCheck'] = steps[index]['grillFrontValue'] && steps[index]['grillFrontValue'] !== "" ? true : false;
                                                                                                steps[index]['grillFrontTemp'] = steps[index]['grillFrontValue'] && steps[index]['grillFrontValue'] !== "" ? steps[index]['grillFrontValue'] : 'Off';
                                                                                                                                                                                                
                                                                                                this.setState({ steps })
                                                                                            }
                                                                                            } />
                                                                                        <label for={"TempSetVGrillFront" + rowCount}></label>
                                                                                    </div>
                                                                                </div>
                                                                                <a className="dropdown-item" href="javascript:void(0);"
                                                                                    onClick={() => {
                                                                                        let steps = this.state.steps;
                                                                                        steps[index]['grillFrontCheck'] = false;
                                                                                        steps[index]['grillFrontTemp'] = 'Off';
                                                                                        steps[index]['grillFrontValue'] = '';

                                                                                        this.setState({ steps });
                                                                                    }}>Off</a>
                                                                            </div>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="recipeEditorF">
                                    <button type="button" disabled={steps.length >= 10} className="btn addButton" onClick={this.addSteps}>&nbsp;</button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
            </React.Fragment>
        )
    }
}

export default injectIntl(GarlandRecipeCreationSteps)
