import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Iframe from 'react-iframe'
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated"; 
import { getCurrentLocation } from '../../../actions/organizationentity/entityactions'; 
import { getDeviceAPIUrl, getDeviceInfo } from '../../../service/unitManagement/deviceinfo';
import { getLocationAPIUrl, getLocationInfo, getExpoLatLong } from '../../../service/unitManagement/locationinfo';
import UnitManagementInfo from './widgetconstants'; 
import { FormattedMessage } from 'react-intl';

am4core.useTheme(am4themes_animated);


class LocationInformationWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			locationInformation: {},
			latLongCordinates: {},
			deviceInformation: {} 
		}
	}

	componentDidMount = async () => { 
		let locationUrl = getLocationAPIUrl(localStorage.getItem("unitID"), localStorage.getItem("brandName"));
		let locationInfo = await getLocationInfo(locationUrl);

		if (locationInfo) {
			this.setState({
				locationInformation: locationInfo, 
			});
		} 

		const brandName = localStorage.getItem("brandName");
		const expobarProductName = localStorage.getItem("expobarModelName");
		/**** CREM Expobar Implmenation starts: getting data from device info api to show the latitude and longitude values in map ****/
		if(brandName === "CREM" && (expobarProductName === "EX3" || expobarProductName === "DIAMANT PRO"))
		{
			let deviceUrl = getDeviceAPIUrl(localStorage.getItem("unitID"), localStorage.getItem("brandName"));
			let deviceInfo = await getDeviceInfo(deviceUrl);
			let latLongCordinateResponse = await getExpoLatLong(deviceInfo.simCardNumber);
			
			if (deviceInfo) {
				this.setState({				 
					deviceInformation: deviceInfo
				});
			}
			if (latLongCordinateResponse && latLongCordinateResponse.hasOwnProperty('latitude')) {
				this.setState({
					latLongCordinates: latLongCordinateResponse
				});
			}
		}
		/**** CREM Expobar Implmenation ends: getting data from device info api to show the latitude and longitude values in map ****/

	}

	render() {
		const { locationInformation, latLongCordinates } = this.state;
		const { currentLocation } = this.props;
		const unitMngInfo = UnitManagementInfo; 
		const brandName = localStorage.getItem("brandName");
		console.log("Brandname: ", brandName)				
		let locationInfo = unitMngInfo[brandName] ? unitMngInfo[brandName].locationInfo : [];  
		const expobarProductName = localStorage.getItem("expobarModelName");		
		  	
		return (
			<React.Fragment>
				<div class={!this.props.page ? "colm4D colm4D3" : ""}>
					<div class="colmDi">
						<h4>{!this.props.page ? <FormattedMessage id='KC0292' /> : <FormattedMessage id='KC0050' />}</h4>
						<div class="colmDiVal">
							<div class="gridTableView">
								<ul>
								{!this.props.page ? <li>
										<img src={currentLocation.Brand_Logo} alt={currentLocation.Brand_Alt} title={currentLocation.Brand_Title} />
									</li> : ""}
								{this.props.page && brandName === "CREM" ? "" : <li>
										<ul>
											<li>
												<label>{locationInfo[0] ? locationInfo[0].stringID ? <FormattedMessage id={locationInfo[0].stringID}/> : locationInfo[0].fieldname : <FormattedMessage id="KC0050"/>}</label>
												<span class="labelVal">{locationInformation.location}</span>
											</li>
											{brandName === "CREM" ? <li>
												<label><FormattedMessage id='KC0228'/></label>
												<span class="labelVal">{locationInformation.location_id}</span>
											</li> : ""}
											<li>
												<label><FormattedMessage id='KC0051'/></label>
												<span class="labelVal">{locationInformation.address}</span>
											</li>
											<li>
												<label><FormattedMessage id='KC0061'/></label>
												<span class="labelVal">{locationInformation.withinLocation}</span>
											</li>
										</ul>
									</li>}	
								</ul>
							</div>
										{!this.props.page && (brandName === "CREM" && (expobarProductName === "EX3" || expobarProductName === "DIAMANT PRO")) ? <div class="locationMapView">
                    <Iframe
                      url={`https://maps.google.com/maps?q=${latLongCordinates.latitude !=='undefined'? latLongCordinates.latitude : '80'}, ${latLongCordinates.longitude !=='undefined' ? latLongCordinates.longitude : '80'}&z=15&output=embed`}
                      width="auto"
                      height="200px"
                      frameborder="0"
                      id="myId"
                      style={{ "min-width": "300px" }}
                      className="myClassname"
                      display="initial"
                      position="relative"
                      allowFullScreen
                    />
						</div> : ""}
						</div>
					</div>
				</div>
			</React.Fragment>
		)		
	}
};
function mapStateToProps(state) {
	return { 
		currentLocation: state.entityReducer.currentLocation
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ getCurrentLocation }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(LocationInformationWidget);
