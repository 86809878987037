import $ from 'jquery';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';
import UserManagementHeader from '../layoutSection/userManagementHeader';
import { subscriptionManagement } from '../../service/api_london';
import http from '../../service/httpService';
import { getWindowDimensions } from './invoice';
import { useSelector } from 'react-redux';

export const headerRow = (formatMessage, activeTab) => {
    const envIDValue = localStorage.getItem('subscriptionDetails') && JSON.parse(localStorage.getItem('subscriptionDetails'))?.env_id ? JSON.parse(localStorage.getItem('subscriptionDetails'))?.env_id : 0;
    const customerPaymentStatus = localStorage.getItem('subscriptionDetails') && JSON.parse(localStorage.getItem('subscriptionDetails'))?.customerPaymentStatus ? JSON.parse(localStorage.getItem('subscriptionDetails'))?.customerPaymentStatus : '';
    const headerRowObj = [
        { to: '/subscription/dashboard', id: 'KC0054', name: 'Dashboard', disable: false },
        { to: '/subscription/manageSubscription', id: 'KC2431', name: 'Manage Subscription', disable: false },
        { to: '/subscription/plan', id: 'KC2432', name: 'Plan', disable: false },
        { to: '/subscription/invoice', id: 'KC2433', name: 'Invoice', disable: false },
        { to: '/subscription/payments', id: 'KC2434', name: 'Payments', disable: envIDValue === 0 || !envIDValue },
        { to: '/subscription/manageAccount', id: 'KC2435', name: 'Manage Account', disable: customerPaymentStatus === 'SUBSCRIBED CC' || customerPaymentStatus === 'SUBSCRIBED DD' ? false : true  }]
    return (
        <li>
            {headerRowObj.map((i) => i.disable ? null : <Link to={i.to} title={formatMessage({ id: i.id })} className={activeTab === i.name ? "active" : ""}> <FormattedMessage id={i.id} /></Link>)}
        </li>
    )
}

const Plan = (props) => {
    const { formatMessage } = props.intl;
    const [loaderIconVisible, setLoaderIconVisible] = useState(false);
    const [features, setFeatures] = useState([]);
    const [featureSelected, setFeatureSelected] = useState({});
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const langDetails = useSelector(state => state.localeInfo.locale);

    useEffect(() => {
        $("div").removeClass("modal-backdrop fade show");
        document.body.style.backgroundColor = "#F2F2F2";
        getFeaturePlan();
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [langDetails]);

    const getFeaturePlan = () => {
        setLoaderIconVisible(true);
        let url = subscriptionManagement.getFeaturePlan;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                "language_code": langDetails ? langDetails  : "en-US"
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                processJSON(response.data);
            }
        }).catch(err => {
            setFeatures([]);
            setLoaderIconVisible(false);
        });
    }

    const processJSON = (value) => {
        let features = [];
        for (let i = 0; i < value.length; i++) {
            const ele = value[i];
            features.push({ isHeader: true, ...ele });
            if (ele.featuresList && ele.featuresList.length) {
                for (let j = 0; j < ele.featuresList.length; j++) {
                    const eleValue = ele.featuresList[j];
                    features.push({ isHeader: false, ...eleValue, indexId: j + 1 });
                }
            }
        }
        setFeatures(features);
        setLoaderIconVisible(false);
        return features;
    }


    $(function () {
        $('[data-toggle="popover"]').popover({
            trigger: 'hover',
        })
    })

    const titleCase = (word) => {
        return word.replace(/\b\w/g, (c) => c.toUpperCase());
    }
    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    
    return (
        <div id="adminWrapper">
            <div className="searchToggleTransparent"></div>
            <div className="toggleTransparent"></div>
            <div className="sideNavToggleTransparent"></div>
            <div className="userToggleTransparent"></div>
            <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
            </div>
            <div id="page-content-wrapper">
                <div className="lightBgWrapper">
                    <div className="navBarXScroll">
                        <ul>
                            {headerRow(formatMessage, 'Plan')}
                        </ul>
                    </div>
                    {features?.length && !loaderIconVisible ? <form className="planTableView">
                        <div className="planTableT">
                            <ul>
                                <li><FormattedMessage id='KC2444' /></li>
                                <li><FormattedMessage id='KC SAAS Pro' /></li>
                            </ul>
                        </div>
                        <div className="planTable">
                            {features?.map((i, index) => {
                                if (i.isHeader) {
                                    return (
                                        <ul key={index} className="tHead">
                                            <li>#</li>
                                            <li>{titleCase(i.section.toLowerCase())}</li>
                                            <li>&nbsp;</li>
                                        </ul>
                                    )
                                } else {
                                    return (
                                        <ul key={index} className="tBody">
                                            <li>{i.indexId}</li>
                                            <li>{i.feature_name} {isMobile ? <button type="button" id="planDetailsInfo" className="btn planDetails" onClick={() => {
                                                setFeatureSelected(i);
                                                if ($('#planDetailsInfoModal')) {
                                                    console.log("isMobile wekekwekekek", $('#planDetailsInfoModal'))
                                                    $('#planDetailsInfoModal').modal('show')
                                                }
                                            }}>&nbsp;</button> : <button type="button" id="planDetailsInfo" className="btn planDetails" onClick={() => {
                                                setFeatureSelected(i);
                                                if ($('#planDetailsInfoModal')) {
                                                    console.log("isMobile wekekwekekek", $('#planDetailsInfoModal'))
                                                    $('#planDetailsInfoModal').modal('show')
                                                }
                                            }} data-toggle="popover" data-content={i.hover_text || i.feature_name}>&nbsp;</button>}</li>
                                            <li>
                                                <button type="button" className="btn confirmGreen  cursor-default">&nbsp;</button>
                                            </li>
                                        </ul>
                                    )
                                }
                            })}
                        </div>
                        <div className="pageNotes"><FormattedMessage id="KC2436" /></div>
                    </form> : null}
                </div>
                {/* <!-- Plan Details Info Modal --> */}
                {windowDimensions.width < 500 || isMobile ? <div className="modal fade planDetailsInfoModal" data-keyboard="true" data-backdrop="static" id="planDetailsInfoModal" tabindex="-1" role="dialog">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="infoContententD">
                                    <h2>{featureSelected.feature_name || ''}</h2>
                                    <p>{featureSelected.hover_text || featureSelected.feature_name}</p>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn okBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0777' /></button>
                            </div>
                        </div>
                    </div>
                </div> : null}
                {/* <!-- //Plan Details Info Moda --> */}
            </div>

            <UserManagementHeader headerName={formatMessage({ id: 'KC1668' })} headerNameStringID="KC1668" activeClass="billingPortalNav" />
        </div >
    )
};

export default injectIntl(Plan)