import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { UserProfile,smartGroupAPI } from '../../../service/api';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import http from '../../../service/httpService';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { getUnitDetails } from '../../../actions/smart-groups/getSmartGroupUnit';
import { getUpdateHistory } from '../../../actions/smart-groups/getSmartGroupUpdateHistory';
import { deleteUnitList, setUnitDeleteData } from '../../../actions/smart-groups/deleteSmartGroupUnit';
import moment from '../../../utils/momentHelper.js';
import appconstants from '../../../utils/appConstants';
import { getSelectedFilterList, resetSelectedFilterList } from '../../../actions/listView/locations/filterListAction';
import NoRecordsFound from '../../controls/noRecordsFound';
import { TableHeader } from '../../controls/tableHeader';
import authService from '../../../service/authService';
import Tooltip from 'react-tooltip-lite';
import { Paginator } from '../../controls/paginator';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import { FormattedMessage, injectIntl } from 'react-intl';

class SmartGroupsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            unitDetails: null,
            isModalLoading: null,
            loaderIconVisible: false,
            isDeleted: false,
            responseData: [],
            excludedProductList: [],
            unitLoading: false,
            oldProdID: "",
            oldProdName: "",
            curr_Prod_ID: "",
            curr_Prod_Name: "",
            add_mode: false,
            update_mode: false,
            isMultiDelete: false,
            isAdd: false,
            isEdit: false,
            isDelete: false,
            allSelect: false,
            smartGroupInfo: null,
            customerId: null,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            sortColumn: { path: "", order: "", sort_color: "#63778a" },
            isIndeterminateSelect: false,
            isUnitEdit:false,
            currentPage : 1,
			pageSize: 25,
            totalPages:null,	
        };
        this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
            sSmartGroups:[],
            sPurposes:[],
        };
        this.deleteSelectedList = this.deleteSelectedList.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
    }

    componentDidMount = async () => {
        $('body').removeClass("modal-backdrop show");
        document.body.style.backgroundColor = "#F2F2F2";
        // await this.resetValue();
        await this.getFilterDetails();
        this.getProductsDetails();
        this.getUserInfo();
    }

    resetValue = async () => {
        localStorage.removeItem("smartGroupFilter");
        const arr=[];
        this.props.actions.resetSelectedFilterList(arr);
    }

    getProductsDetails = () => {
        localStorage.removeItem("beforeEditSG");
        localStorage.removeItem("afterEditSG");
        this.setState({
            loaderIconVisible: true,
            allSelect: false,
            isDelete: false,
            isEdit: false,
        });

        let queryString;
        queryString = `customerId=${localStorage.getItem("customerId")}`
        // queryString = queryString + `&userId=${localStorage.getItem("user")}`
        if (this.filterstate.sSmartGroups.length>0){
            queryString = queryString + `&sgid=${this.filterstate.sSmartGroups}`
        }
        if (this.filterstate.sPurposes.length>0){
            queryString = queryString + `&pid=${this.filterstate.sPurposes}`
        }
        let url = `${smartGroupAPI.createSmartGroup}?${queryString}`;
        let pageNumber = '&pageNum=' + this.state.currentPage;
		let pageLimit = '&pageSize=' + this.state.pageSize;
		
		url = url + pageNumber + pageLimit;
        http.get(url, {
            headers: {
                'Content-Type': 'application/json',                
            }, data: {}
        }).then( async response => {
            if (response && response.data) {
                var resVal = Array.isArray(response.data) ? [...response.data]:[];               
                let arr = []
                let isDelete = false;
                let findTotalPages=0;
                for (let i = 0; i < resVal.length; i++) {                                   
                    isDelete = await this.checkDeleteAccess(resVal[i]);                    
                    resVal[i].isAuthorized = isDelete ? isDelete : false;
                    findTotalPages = resVal[i].numberOfPages;
                    arr.push(resVal[i])
                };

                this.setState({
                    excludedProductList: arr,
                    loaderIconVisible: false,
                    totalPages: findTotalPages,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false,totalPages: 0 });
        });
    }

    clickMultiDeleteButton = () => {
        this.setState({ isMultiDelete: true })
    }

    onAddClick = (e, item) => {
        this.setState({
            oldProdID: "",
            oldProdName: "",
            add_mode: true,
            update_mode: false,
        })
    }

    onSuccessCloseModal = (e) => {
        this.setState({
            currentPage: 1,
            success_modal: {
                open: false
            }
        },()=>{
            this.getProductsDetails();
        });
        this.props.actions.setUnitDeleteData(null);        
    }

    closeModel = (err) => {
        this.setState({
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            }
        });
    }

    handleChange = async(e) => {
        var rolesList = [...this.state.excludedProductList];
        let allSelect = e.target.checked
        rolesList.forEach(r => {
            r.checked = r.isAuthorized ? allSelect : false;
        });
        const checked = rolesList.filter((i)=>i.checked === true);
        this.setState({
            excludedProductList: rolesList,
            allSelect: rolesList.length === checked.length ,
            isDelete: checked.length > 0,
            isEdit: checked.length === 1,
            isIndeterminateSelect: false
        })
    };

    onSelectRole = async(e, item) => {
        if (this.state.excludedProductList && this.state.excludedProductList.length > 0) {
            let rolesList = this.state.excludedProductList;
            const index = rolesList.findIndex((i)=> i.smartGrpId === item.smartGrpId);
            
            rolesList[index].checked = rolesList[index].isAuthorized ? e.target.checked : false;
            let checked = rolesList.filter(c => c.checked === true);

            function getCheckBoxstate(ListCount,SelectedCount) {
                if (SelectedCount == ListCount) {
                return { allSelect: true, isIndeterminateSelect: false };
                } else if (SelectedCount == 0 && ListCount != 0) {
                return { allSelect: false, isIndeterminateSelect: false };
                } else if (ListCount >= SelectedCount) {
                return { allSelect: true, isIndeterminateSelect: true };
                }
            }

            this.setState({
                excludedProductList: rolesList,
                isDelete:checked.length > 0 ,
                isEdit: checked.length === 1 ? true : false,
                ...getCheckBoxstate(rolesList.length,checked.length)
            })
        }
    }

    editSmartGroup = () => {
		let smartGrpList = [...this.state.excludedProductList];
        let editSmartGrpDetails = smartGrpList.filter(item => item.checked === true);
        localStorage.setItem('sgID', editSmartGrpDetails[0].smartGrpId);
        WoopraEvents(`${Constants.EDIT_SMART_GROUP}`);
		this.props.history.push({
            pathname: '/editsmartgrp',
            editSmartGrpRes:editSmartGrpDetails,			
			smartGrp_id: editSmartGrpDetails[0].smartGrpId,
		})
    }

    editUnits = async (item) => {
        const { smartGrpId, smarGrpName, purpose } = item;	
        let purposes = [];
        purpose.map((i)=>{
            if(!purposes.includes(i.purposeId)){
                purposes.push(i.purposeId)
            }
        })
        let beforeEditSG={
            "smarGrpName" : smarGrpName,
            "purposes" : purposes
        };
        localStorage.setItem('sgID', smartGrpId);               
        localStorage.setItem("beforeEditSG",JSON.stringify(beforeEditSG));
        localStorage.setItem('afterEditSG', JSON.stringify(beforeEditSG));
        WoopraEvents(`${Constants.EDIT_SMART_GROUP_UNITS}`);
		this.props.history.push({
            pathname: '/editsmartGrpAssignUpdateList',            
            state: { pageFrom:"smartGroupList" }
		})
    }
    
    //For First Letter Added in Smart Group List
    getFirstLetter(name) {
        if(name){
            var char = name.charAt(0).toUpperCase();
            return char;
        }    
    }
    
    // API implementation pending, so the code is hided.
    // deleteSelectedList(){
    //     let { excludedProductList } = this.state;
    //     let selectedIds = [];
    //     let selectedSGNames = [];
    //     excludedProductList.forEach( e=> {
    //       if (  e.checked === true) {
    //         selectedIds.push( e.smartGrpId);
    //         selectedSGNames.push( e.smarGrpName);
    //       }
    //     });
    //     let req = { 
    //         smartgroupId : selectedIds ,         
    //         smartgroupName : selectedSGNames,
    //         operation : "validate",
    //         customerId : +localStorage.getItem("customerId"),
    //         userId:localStorage.getItem("user"),
    //     };

    //     let url = smartGroupAPI.deleteSmartGrouplist;
    //     http.delete(url,{
    //         headers: {
	// 			'Content-Type': 'application/json',
	// 		},
    //         data: req,
    //     }).then(response => {
    //         let { data } = response;   
    //         delete req.operation;
    //         req["operation"] = "delete";
    //         req["linkedWith"] = data.linkedWith ? data.linkedWith : "";
            
    //         this.props.actions.deleteUnitList('',req);

    //     }).catch(err => {
    //         this.setState({
    //             loaderIconVisible: false,
    //             error_modal: {
    //                 open: true,
    //                 message: "Request failed.",
    //             },
    //         });
    //     });
        
    // }

    deleteSelectedList(){
        let { excludedProductList } = this.state;
        let selectedIds = [];
        excludedProductList.forEach( e=> {
          if (  e.checked === true) {
            selectedIds.push( e.smartGrpId);
          }
        });     
        let req = { id : selectedIds , customerId: +localStorage.getItem("customerId") ,userId:localStorage.getItem("user"),};
        
        this.props.actions.deleteUnitList('',req);
        WoopraEvents(`${Constants.DELETE_SMART_GROUP}`);
    }

    static getDerivedStateFromProps(props) {
        return {...props};
    }

    getFilterDetails = async () =>{		
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.smartGroupFilter);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
        }

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
        let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
        let selectedPurposesList = filterModel.find(el => el.field_name === 'purposes');
        let selectedSGList = filterModel.find(el => el.field_name === 'smartgroup');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
        let selectedMachineTypes = [];
        let selectedPurposes = [];
        let selectedSG = [];

		selectedCountryList.dataSource.map(c => {
			return selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			 selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			return selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			return selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			return selectedMachineTypes.push(c.ID)
        });        
        
        selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
        });
        
        selectedSGList && selectedSGList.dataSource.map(c => {
			return selectedSG.push(c.smartGrpId)
        });

        selectedPurposesList && selectedPurposesList.dataSource.map(c => {
			return selectedPurposes.push(c.id)
        });

		let cgids = "";
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
		}
		else {
			cgids = localStorage.getItem("custGroupID");
		}

		this.filterstate = ({
			sCgids: cgids,
			sCids: selectedCids,
			sBrandids: selectedBrands,
			sModels: selectedModels,
            sMachineTypes: selectedMachineTypes,
            sSmartGroups:selectedSG,
            sPurposes: selectedPurposes,
            
		});

		var d = document.getElementById("spannavNotifiStatus");
        if (selectedSG.length > 0 || selectedPurposes.length > 0 || selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
            d.className = "navNotifiStatus";
        }			
        else {
            d.className = "";
        }
		
	}

    onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
    }

    getUserInfo = () => {
		this.setState({ loaderIconVisible: true });
		let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
		if (!loginInfo.rolename) {
			http.get(UserProfile.getUserProfileDetails,
				{headers: {
					'customer_id': localStorage.getItem("customerId"),
					'email': loginInfo.username,
					'Content-Type': 'application/json'
				},data:{},
			}).then(response => {
				loginInfo.rolename = response.data.role_name;
				localStorage.setItem("loginInfo", JSON.stringify(loginInfo));
			}).catch(err => {
			});
		}
	}

    checkDeleteAccess = async (mediaValue) => {
		const userListEmail = JSON.parse(localStorage.getItem('childUsersEmail'));
		const { username } = JSON.parse(localStorage.getItem('loginInfo'));
		const roleName = localStorage.getItem('roleName') || '';

		let isDelete = false;
			if(mediaValue.createdBy && mediaValue.createdBy.toString().match('@')){
				isDelete = roleName.toLowerCase() === 'customer admin' || userListEmail.includes(mediaValue.createdBy) || username === mediaValue.createdBy ? true : false;
			}else{
				isDelete = roleName.toLowerCase() === 'customer admin' ? true : false;
			}
			if (isDelete === false){
				return isDelete;
			}
		return isDelete;
	}

    getSGUnitDeatils = async (smartGrpId,isAuthorized) => {
        this.props.actions.getUnitDetails(smartGrpId);

        this.setState({
            isUnitEdit:isAuthorized,
        })
    }

    onPageChange = (currentPage) => {
		this.setState({ loaderIconVisible: true, currentPage },()=>{
			this.getProductsDetails();
			window.scrollTo({
				top: 0,
				behavior: "smooth"
			});
		});
	}

    // Woopra Events
    trackEvent = (e) => {
        WoopraEvents(`${Constants.ORG_MANAGEMENT}-${e.trackTitle}`);
    }
    
	onCancelCall = (e) => {
		WoopraEvents(`${e}`)
	}

    render() {
        const { formatMessage } = this.props.intl;
        $(document).ready(function () {
            // Nav Toggle
            $('.collapse').off('click').on('click', function () {
                $(this).parents(".tBody").toggleClass('descExp');
            });
        });
        let userProfileAuthFeature = authService.getFeatureByFeatureName("Organization Management");
        let orderId = 1;

        let {isDeleted, unitDetails, isModalLoading,sortColumn,isIndeterminateSelect, 
            smartGroupInfo, unitLoading, excludedProductList, loaderIconVisible, error_modal, success_modal, 
            allSelect, isEdit, isDelete, isUnitEdit,currentPage, pageSize, } = this.state;
        let units = unitDetails && unitDetails.units;
        units = UnitBuilder(units);
        let infoUnits = smartGroupInfo && smartGroupInfo.units;
        if (infoUnits) {
            infoUnits.addedUnits = infoUnits.addedUnits ? UnitBuilder(infoUnits.addedUnits) : [];
            infoUnits.removedUnits = infoUnits.removedUnits ? UnitBuilder(infoUnits.removedUnits) : [];
        }

        let columns = [
			{ path: "", label: "" },
			{ path: "", label: "#" },
			{ path: "smarGrpName", label: formatMessage({id:'KC0241'}) },
			{ path: "purposeName", label: formatMessage({id:'KC0242'}) },
			{ path: "groupsize", label: formatMessage({id:'KC0541'}) },
			{ path: "modified", label: formatMessage({id:'KC0542'}) },
			{ path: "", label: formatMessage({id:'KC0325'}) },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

        excludedProductList = _.orderBy(excludedProductList, [sortColumn.path], [sortColumn.order]);

		excludedProductList.forEach(item => {
			item.order_id = orderId++
		});

        if( isDeleted && isDeleted.msg) {
            success_modal = { open : true, message: isDeleted.stringID ? isDeleted.stringID : isDeleted.msg};
        }
        let allPurposeName = "";
        let rowNumber = (currentPage * pageSize) - pageSize;        

        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>

                <div id="page-content-wrapper">
                    <div className="stickyTableHeader">
                        <div className="menuUpdateWrapper">
                            <div className="orgHeader">
                                <ul>
                                    <li>
                                        <Link to="/organizationManagement" title={formatMessage({id:'KC0525'})} onClick={(e) =>{e.trackTitle="All"; this.trackEvent(e)}} ><FormattedMessage id='KC0525' /></Link>
                                        <Link to="/smartgroupslist" title={formatMessage({id:'KC0520'})}  class="active"><FormattedMessage id='KC0520' /></Link>
                                        <Link to="/purposesList" title={formatMessage({id:'KC0544'})} onClick={(e) =>{e.trackTitle="Purposes"; this.trackEvent(e)}}><FormattedMessage id='KC0544' /></Link>
                                    </li>
                                    <li>
                                        <button onClick={() => { this.editSmartGroup() }} class={userProfileAuthFeature.is_editable === 1 && isEdit ?"btn userEdit" : "btn userEdit disabled"}>&nbsp;</button>
                                        <button type="button" className={userProfileAuthFeature.is_editable === 1 && isDelete ? "btn delete" : "btn delete disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal">&nbsp;</button>
                                         {/* <button type="button" className={isDelete?"btn delete":"btn delete disabled"} data-toggle="modal" data-target="#DeleteConfirmationModal" >&nbsp;</button> */}
                                        {/* <button type="button" data-toggle="modal" data-target="#DeleteConfirmationModal" className={userProfileAuthFeature.is_editable === 1 ? `btn btn-default-text userDelete ${isDelete ? "" : "disabled"}` : "btn btn-default-text userDelete disabled"} title="User Delete" onClick={() => { }}>&nbsp;</button> */}
                                        <Link to="/createnewsmartgrp" class="btn add" title={formatMessage({id:'KC0522'})}>&nbsp;</Link>                                        
                                    </li>
                                </ul>
                            </div>

                            {/* <form className="dspdPrdtsListForm"> */}

                            <div class="orgTableOuter">
                                <div className="orgTable">
                                <TableHeader
									isIndeterminate={isIndeterminateSelect}
									selectAllChange={this.handleChange}
                                    sortColumn={sortColumn}
									onSort={this.onSort}
									columns={columns}
									isSelectAll={allSelect} />
                                </div>

                                <div class="panel panel-default" id="availableUpdatesAccordion" role="tablist" aria-multiselectable="false">

                                {excludedProductList && excludedProductList.length>0 && excludedProductList.map((item, i) =>
                                    <div key ={ i}>
                                        <div class="orgTable">
                                        <ul className="tBody">
                                            <li><button onClick={() => this.getSGUnitDeatils(item.smartGrpId, item.isAuthorized)}  role="button" data-toggle="collapse" data-parent="#availableUpdatesAccordion" href= { "#availableUpdatesAcc" + item.smartGrpId}  aria-expanded="false" aria-controls= { "#availableUpdatesAcc" + item.smartGrpId} class="btn collapseExpand collapsed">&nbsp;</button></li>
                                            <li>{rowNumber + i + 1}</li>
                                            <li> <span class="sgFL"> {item.smartGroupKeyName?item.smartGroupKeyName:this.getFirstLetter(item.smarGrpName)}</span> {item.smarGrpName} </li>
                                            <li>
                                                {allPurposeName=""}
                                                {item.purpose.map((item1, j) =>   
                                                    {allPurposeName=allPurposeName?allPurposeName + "," + item1.purposeName:item1.purposeName}
                                                )}
                                                {item.purpose.map((item1, i) => 
                                                    i>1 ?                                              
                                                        i===2?<span class="purposeTo" title = {allPurposeName} key ={'item'+  i}>
                                                        {"+" + (item.purpose.length-i)}
                                                        </span>:"" 
                                                    : <span class="purposeTo" title = {item1.purposeName} key ={'item'+  i}>
                                                        {item1.purposeName}
                                                    </span> 
                                                )}
                                            </li>
                                            <li>{item.groupsize} <FormattedMessage id='KC0045' /></li>
                                            <li>{moment.GetDateTimeByFormat(item.modified, 'LLL') }</li>
                                            <li><button onClick ={ ()=>  this.props.actions.getUpdateHistory(item.smartGrpId)}  type="button" class="btn sgInfo" data-toggle="modal" data-target="#detailsInfoModal">&nbsp;</button></li> 
                                            <li>
                                                {!item.isAuthorized ? <Tooltip content={formatMessage({ id: 'KC1748' })} direction="left" className="targetdisabledCheckboxAlignment" >
                                                <div className={"customCheckbox customCheckboxWOlabel disabled"}>
													<input id={i} name={i} type="checkbox"
														checked={item.checked}
                                                        disabled
														onChange={(e) => this.onSelectRole(e, item)} />
													<label htmlFor={i}></label>
												</div>
                                                </Tooltip> : <div className={"customCheckbox customCheckboxWOlabel"}>
													<input id={i} name={i} type="checkbox"
														checked={item.checked}
														onChange={(e) => this.onSelectRole(e, item)} />
													<label htmlFor={i}></label>
												</div>}
                                            </li>
                                        </ul>
                                    </div>

                                    <div id= { "availableUpdatesAcc" + item.smartGrpId}  
                                        data-parent="#availableUpdatesAccordion" 
                                        class="panel-collapse collapse" 
                                        role="tabpanel" aria-labelledby="availableUpdatesAccH1">

                                        <div class="panel-body orgUnitsList">
                                        { unitLoading && <div className ="text-center"> <FormattedMessage id="KC1151"/></div>}

                                        { !unitLoading && <>
                                            <ul class="tHead">
                                                <li>#</li>
                                                <li><FormattedMessage id='KC0101' /></li>
                                                <li><FormattedMessage id='KC0062' /></li>
                                                <li><FormattedMessage id='KC0064' /></li>
                                                <li><FormattedMessage id='KC0649' /></li>
                                                <li><FormattedMessage id='KC0104' /></li>
                                                <li><FormattedMessage id='KC1152' /></li>
                                                <li><FormattedMessage id='KC0050' /></li>
                                                <li><FormattedMessage id='KC0061' /></li>
                                            </ul>
                                           
                                            { units && units.map((item, i) => 
                                                <ul class="tBody" key ={ item.ID}>
                                                    <li> {i + 1} </li>
                                                    <li> <img alt={item.Brand_Alt} src={item.Brand_Logo} /></li>
                                                    <li> {item.BRAND_NAME} </li>
                                                    <li> {item.MODEL_NAME} </li>
                                                    <li> {item.MODEL_FAMILY_NAME} </li>
                                                    <li> {item.UNITID} </li>
                                                    <li> {item.UNIT_NUMBER} </li>
                                                    <li> {item.location_name}</li>
                                                    <li> {item.group_name}</li>
                                                </ul>)}
                                            </>}

                                        </div>
                                        {  unitLoading === false && <>
                                            
                                            {((units &&  units.length === 0) || !units)  && <div className ="text-center"><FormattedMessage id='KC0568'/> </div> }

                                            {units &&  units.length > 0  && 
                                            <>
                                                {unitDetails &&  unitDetails.total_unit_count > 3 &&
                                                <button type="button" class="btn btn-disabled disabled">
                                                    <span> + {unitDetails.total_unit_count - 3 }</span> <FormattedMessage id='KC0045'/></button>}
                                                <button title={formatMessage({id:'KC1651'})} className={userProfileAuthFeature.is_editable === 1 && isUnitEdit ?"btn btn-default":"btn btn-default disabled"} id="AssignUpdate" onClick={() => { this.editUnits(item) }}><FormattedMessage id='KC1166' /></button>
                                            </>
                                            } 

                                        </>}
                                            
                                    </div>

                                    </div>
                                )}
                                </div>                                
                            </div>
                            <Paginator
                                    pageNumber={this.state.currentPage}
                                    pageCount={this.state.totalPages}
                                    onNextPage={(p) => this.onPageChange(p)}
                                    onPreviousPage={(p) => this.onPageChange(p)}
                                    applyClass="tablePagination"
                                />
                        </div>
                    </div>
                </div>

                {/* Update History Modal */}
                <div class="modal fade infoModal" data-keyboard="true" data-backdrop="static" id="detailsInfoModal" tabindex="-1" role="dialog" aria-labelledby="detailsInfoModalLabel">
                    <div class="modal-dialog modal-md infoModal sgModal">
                        { isModalLoading && <div class="modal-content text-center"> <br/><br/><FormattedMessage id='KC1151'/><br/><br/></div> }
                        { !isModalLoading &&<div class="modal-content">  
                            <div class="modal-header modal-header-danger">
                            <FormattedMessage id='KC0564' /> <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
                            </div>
                            <div class="modal-body"> 
                                <div class="sgModalContent">  
                                    <p><FormattedMessage id='KC0565' /></p>
                                    <div class="sgModalUnitsList"> 
                                        <ul class="tHead"> 
                                            <li><FormattedMessage id='KC0101' /></li>
                                            <li>&nbsp;</li> 
                                            <li><FormattedMessage id='KC0566' /></li>
                                            <li><FormattedMessage id='KC0741' /></li>
                                        </ul>
                                       
                                        { infoUnits && infoUnits.addedUnits &&infoUnits.addedUnits.map((item, i)=> 
                                        <ul class="tBody" key = { 'unit-'+ i}> 
                                            <li><img alt={item.Brand_Alt} src={item.Brand_Logo} /></li>
                                            <li> {item.BRAND_NAME}<span>{item.BRAND_TYPE}</span></li>   
                                            <li> {item.UNITID}<span>{item.UNIT_NUMBER}</span></li> 
                                            <li> {moment.GetDateTimeByFormat(item.updatedAt, 'LLL')}</li>   
                                        </ul> 
                                        ) }  
                                    </div>
                                    { infoUnits && infoUnits.addedUnits &&infoUnits.addedUnits.length === 0 && 
                                        <div className ="text-center"> <FormattedMessage id='KC0568'/></div>
                                    } 
                                    <p><FormattedMessage id="KC0244"/></p> 
                                    <div class="sgModalUnitsList"> 
                                        <ul class="tHead"> 
                                            <li><FormattedMessage id='KC0101' /></li>
                                            <li>&nbsp;</li>
                                            <li><FormattedMessage id='KC0566' /></li> 
                                            <li><FormattedMessage id='KC0741' /></li>
                                        </ul>
                                        { infoUnits && infoUnits.removedUnits &&infoUnits.removedUnits.map((item, i)=>
                                            <ul class="tBody" key ={ 'remove' + i}> 
                                            <li><img alt={item.Brand_Alt} src={item.Brand_Logo} /></li>
                                            <li> {item.BRAND_NAME}<span>{item.BRAND_TYPE}</span></li>   
                                            <li> {item.UNITID}<span>{item.UNIT_NUMBER}</span></li>  
                                            <li> {moment.GetDateTimeByFormat(item.updatedAt, 'LLL')}</li>
                                        </ul>  )}  
                                    </div> 
                                    { infoUnits && infoUnits.removedUnits &&infoUnits.removedUnits.length === 0 && <div className ="text-center"> <FormattedMessage id='KC0568'/></div>}
                                </div>
                               {smartGroupInfo && <div className="text-unit-color">+ <span>{smartGroupInfo.total_unit_count} </span>  <FormattedMessage id='KC0045'/></div>}
                            </div> 
                        </div>}
                    </div>
                </div>

                {/* Delete Smart Group Unit Modal */}
                <div className="modal fade SignOutModal" data-keyboard="true" data-backdrop="static" id="DeleteConfirmationModal" tabIndex="-1" role="dialog" aria-labelledby="SignOutModalLabel">
                    <div className="modal-dialog modal-md loginModal">
                        <div className="modal-content" >
                            <div className="modal-body">
                                <div className="singOutConfirmation">
                                    <FormattedMessage id='KC1167' />
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary signOutNoBtn" data-dismiss="modal" type="button" onClick={(e) => this.onCancelCall(`${Constants.PURPOSE_CANCELED}`)}><FormattedMessage id='KC0021' /></button>
                                <button className="btn btn-secondary signOutYesBtn" onClick={() => this.deleteSelectedList()} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                
                <NoRecordsFound loaderIconVisible={loaderIconVisible} length={excludedProductList !== undefined ? excludedProductList.length : 0} classname={'norecordsfoundbluetext'} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerName="Organization Management" headerNameStringID="KC0519" activeClass="orgznManagementNav" filterKey={appconstants.smartGroupFilter} history={this.props.history}/>

            </div>
        )
    }
};

const mapDispatchToProps = (dispatch)  => {
    return {
        actions: bindActionCreators({
            getUnitDetails,
            getUpdateHistory,
            deleteUnitList,
            setUnitDeleteData,
            getSelectedFilterList,
            resetSelectedFilterList
        }, dispatch)
    }
    }

const mapStateToProps = state => {
    return {
        customerId : state.userReducer.UserModel.user.customerId,
        unitDetails: state.smartGroups.unitList,
        unitLoading: state.smartGroups.isLoading,
        smartGroupInfo : state.smartGroupsHistory.unitHistory, 
        isModalLoading : state.smartGroupsHistory.isLoading,
        isDeleted: state.smartGroupUnitDelete.unitListDelete,
        filterlistInformtion: state.filterModel,
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(SmartGroupsList)); 
