import React, { Component } from 'react'
import moment from '../../../utils/momentHelper.js';
import { ReportOrgModal } from './reportOrgModal';
import _ from 'lodash';
import { ReportManagement } from '../../../service/api';
import http from '../../../service/httpService';
import { FormattedMessage } from 'react-intl';
import * as momentFormat  from 'moment';
import $ from 'jquery';

export class ReportDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderIconVisible: false
    }
  }

  componentDidUpdate = async (prevProp) => {
    document.body.style.backgroundColor = "#F2F2F2";
    $(".reportDetailsD").scrollTop(0);
    $(".assignOrgTreeOuter").scrollTop(0);
    if (prevProp.reportDetails !== this.props.reportDetails) {
      const { cgids, unitids, id } = this.props.reportDetails;
      let selectedunitidsarr = await this.getUnitListBycgidunitid(cgids, unitids);
      localStorage.setItem("selectedUnitID", JSON.stringify(selectedunitidsarr));
    }
  }

  componentWillUnmount() {
		$("div").removeClass("modal-backdrop fade show");
	}

  getUnitListBycgidunitid = (cgids, unitids) => {
		return new Promise(async(resolve, reject) => {
			await http.get(ReportManagement.getUnitListBycgidunitid, {
				headers: {
					'Content-Type': 'application/json',
					'cgids': cgids,
					'unitids': unitids,
          'brandid': localStorage.getItem("selectedbrandID"),
          'customerid': localStorage.getItem("customerId"),
          'basecountry': localStorage.getItem('basecountry')
				},
				data: {}
			}).then(response => {
				let selectedunitidsarr = response.data && response.data.units ? response.data.units : [];
				let unitidsData = unitids ? unitids.split(',') : [];
				selectedunitidsarr && selectedunitidsarr.length > 0 && selectedunitidsarr.map((item,i) => {
					let isExits = unitidsData.find(o => o === item.UNITID);
					if (isExits) {
						item.isNodeSelected = false;
					}
					else {
						item.isNodeSelected = true;
					}
				});
				resolve(selectedunitidsarr);
			}).catch(err => {
				reject(err);
			});
		});
	}

  render() {
    let { reportDetails, page,localeDet } = this.props;
    let dataPeriod = "";
    let startDateime = "";
    let endDateime = "";
    if (page === "scheduled") {
      dataPeriod = "(" + reportDetails.data_period + " " + (reportDetails.data_period_days ? reportDetails.data_period_days : "") + " " + (reportDetails.data_period_frequency === "daily" ? "day" : (reportDetails.data_period_frequency ? _.upperFirst(reportDetails.data_period_frequency.toString()).replace("ly","") : "")) + (parseInt(reportDetails.data_period_days) > 1 ? "s)" : ")")
      startDateime = reportDetails.start_datetime && localStorage.getItem("timeFormat") === "24" ? moment.ConvertLocalTimeFromUTCTime(reportDetails.start_datetime, "MMM DD, YYYY HH:mm") : reportDetails.start_datetime && localStorage.getItem("timeFormat") === "12" ? moment.ConvertLocalTimeFromUTCTime(reportDetails.start_datetime, "MMM DD, YYYY hh:mm A") : "";
      endDateime = reportDetails.end_datetime && localStorage.getItem("timeFormat") === "24" && reportDetails.end_datetime !== "9999-12-31T00:00:00.000Z" ? moment.ConvertLocalTimeFromUTCTime(reportDetails.end_datetime, "MMM DD, YYYY HH:mm") : reportDetails.end_datetime && localStorage.getItem("timeFormat") === "12" && reportDetails.end_datetime !== "9999-12-31T00:00:00.000Z" ? moment.ConvertLocalTimeFromUTCTime(reportDetails.end_datetime, "MMM DD, YYYY hh:mm A") : "No End Date";
    }
    else if (reportDetails.is_scheduled) {
      dataPeriod = "(" + reportDetails.data_period + " " + (reportDetails.data_period_days ? reportDetails.data_period_days : "") + " " + (reportDetails.data_period_frequency === "daily" ? "day" : (reportDetails.data_period_frequency ? _.upperFirst(reportDetails.data_period_frequency.toString()).replace("ly","") : "")) + (parseInt(reportDetails.data_period_days) > 1 ? "s)" : ")")
      startDateime = reportDetails.sch_start_date && localStorage.getItem("timeFormat") === "24" ? momentFormat(reportDetails.sch_start_date.replace('T00:00:00.000Z','')).format("MMM DD, YYYY") + ' 00:00:00' : reportDetails.sch_start_date && localStorage.getItem("timeFormat") === "12" ? momentFormat(reportDetails.sch_start_date.replace('T00:00:00.000Z','')).format("MMM DD, YYYY") + ' 00:00:00 AM': "";
      endDateime = reportDetails.sch_end_date && localStorage.getItem("timeFormat") === "24" ? momentFormat(reportDetails.sch_end_date.replace('T23:59:59.000Z','')).format("MMM DD, YYYY") + ' 23:59:59': reportDetails.sch_end_date && localStorage.getItem("timeFormat") === "12" ? momentFormat(reportDetails.sch_end_date.replace('T23:59:59.000Z','')).format("MMM DD, YYYY") + ' 11:59:59 PM': "";

      if (!reportDetails.sch_start_date || !reportDetails.sch_end_date || (reportDetails.sch_start_date > reportDetails.sch_end_date)) {
        startDateime = 'No Data';
        endDateime = 'No Data';
      }
    }
    else {
      startDateime = reportDetails.from_date && localStorage.getItem("timeFormat") === "24" ? momentFormat(reportDetails.from_date.replace('T00:00:00.000Z','')).format("MMM DD, YYYY") + ' 00:00:00' : reportDetails.from_date && localStorage.getItem("timeFormat") === "12" ? momentFormat(reportDetails.from_date.replace('T00:00:00.000Z','')).format("MMM DD, YYYY") + ' 00:00:00 AM': "";
      endDateime = reportDetails.to_date && localStorage.getItem("timeFormat") === "24" ? momentFormat(reportDetails.to_date.replace('T23:59:59.000Z','')).format("MMM DD, YYYY") + ' 23:59:59': reportDetails.to_date && localStorage.getItem("timeFormat") === "12" ? momentFormat(reportDetails.to_date.replace('T23:59:59.000Z','')).format("MMM DD, YYYY") + ' 11:59:59 PM': "";
    }
    return (
    <React.Fragment>
    <div class="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="reportDetailsModal" tabindex="-1" role="dialog" aria-labelledby="reportDetailsModalLabel">
        <div class="modal-dialog modal-md">
            <div class="modal-content">  
                <div class="modal-header modal-header-danger">
                  <FormattedMessage id='KC0783'/> - {reportDetails.name}{reportDetails.filetype} <button type="button" class="close" data-dismiss="modal" aria-label="Close">&nbsp;</button> 
                </div>
                <div class="modal-body"> 
                    <div class="reportDetailsD" style={{height: "515px"}}> 
                        <h6><FormattedMessage id='KC0195'/></h6>
                        <p className='textareaP'>{reportDetails.memo}</p>
                        <h6><FormattedMessage id='KC2184'/> {dataPeriod}</h6>
                        {page === "scheduled" ? <p><span class="pFromDate"><FormattedMessage id='KC0577'/>: {startDateime}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <span class="pToDate"><FormattedMessage id='KC0578'/>: {endDateime}</span></p> 
                        : <p><span class="pFromDate"><FormattedMessage id='KC0091'/>: {startDateime}</span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="pToDate"><FormattedMessage id='KC0092'/>: {endDateime}</span></p>}
                        <p><FormattedMessage id='KC2122'/> &nbsp; <button type="button" data-toggle="modal" data-target="#orgViewModal" className="orgViewIcon btn active">
                                            <img src={require('../../../images/mainmenu/OrganizationMgmt.svg').default} />
                                        </button> <FormattedMessage id='KC2123'/>.</p> 
                        { reportDetails.options && reportDetails.options.length > 0 && <h6><FormattedMessage id='KC2124'/></h6>}
                        { reportDetails.options && reportDetails.options.map((item) => 
                            <div class="reportDetailsD"> 
                                <h6>{item.text_translations[localeDet]?.option ? item.text_translations[localeDet].option : item.option}</h6>
                                <p>{ item.text_translations[localeDet]?.description ? item.text_translations[localeDet].description : item.description}</p>
                            </div> 
                        )}
                    </div> 
                </div> 
            </div>
        </div>
    </div>
    <ReportOrgModal reportID={reportDetails.id} />
    </React.Fragment>
    );
  }
}
