import http from '../../../service/httpService';
import { BRAND_ID } from '../../../utils/appConstants';
import { LocationInfo} from '../../../service/api';
export const CUSTOMER_REQUEST = 'CUSTOMER_REQUEST';
export const CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS';
export const CUSTOMER_FAIL = 'CUSTOMER_FAIL';

export function getCustomer (req = '') {
    return dispatch => {
        dispatch({
            type: CUSTOMER_REQUEST
        })

         
        http
            .get(`${LocationInfo.filters}`,
                {headers: {
                    'Content-Type':'application/json',
                    'Type': 'Customer',
                    'cid': localStorage.getItem("customerId"),
                    'brandid': BRAND_ID,
                },
                data:{}})
            .then(response => {
                dispatch({
                    type: CUSTOMER_SUCCESS,
                    customerInformation: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: CUSTOMER_FAIL,
                    error
                })
            })
    }
}
