import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { SoftwareManagementAPI,UnitInfo } from '../../../service/api';
import http from '../../../service/httpService';
import moment from '../../../utils/momentHelper.js';
import { FormattedMessage } from 'react-intl';


class InstalledPackages extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			installedPackagesResponseData: [],
			deviceInfo:[],
		};

	}

	componentDidMount = async () => {		
		this.getUnitSoftwareDetails();
		await this.getUnitSoftwareDetails_From_ExistingAPI();
	}

	updateAll = async () => {	
		await this.getUnitSoftwareDetails();
		await this.getUnitSoftwareDetails_From_ExistingAPI();
	}
	

	getUnitSoftwareDetails_From_ExistingAPI = async (item) => {
		this.setState({
			loaderIconVisible: true
		});		
		let responseResult = {
			data: []
		}
		const unitId = localStorage.getItem("unitID");
		const brandId = localStorage.getItem("brandName");	
		let url = `${UnitInfo.root}${unitId}/${brandId}/device-info`;	
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
				},
				data: {}
			}).then(response => {
				this.setState({
					loaderIconVisible: false,
					deviceInfo: response.data
				});
				resolve(response.data);
			}).catch(err => {
				this.setState({
					loaderIconVisible: false,
				});
				reject(err);
			});
		});
	}

	getUnitSoftwareDetails = async (item) => {
		this.setState({
			loaderIconVisible: true
		});		
		let responseResult = {
			data: []
		}
		let url = SoftwareManagementAPI.unitSoftwareInfo;		
		return new Promise((resolve, reject) => {
			http.get(url, {
				headers: {
					'Content-Type': 'application/json',
					'cloudUnitName':localStorage.getItem("cloudUnitName"),
				},
				data: {}
			}).then(response => {
				this.setState({
					loaderIconVisible: false,
					installedPackagesResponseData: response.data
				});
				resolve(response.data);
			}).catch(err => {
				this.setState({
					loaderIconVisible: false,
				});
				reject(err);
			});
		});
	}

	render() {
		let { installedPackagesResponseData,deviceInfo } = this.state;
		let pageRender;

		let CtrlSoftFileName=deviceInfo && deviceInfo.stum_ver ? deviceInfo.stum_ver:"";
		let CtrlSoftVersion="";
		let CtrlSoftUDate="";

		let MDPFileName=deviceInfo && deviceInfo.mpd_filename ? deviceInfo.mpd_filename :"";
		let MDPVersion="";
		let MDPUDate="";

		let IPDFileName=deviceInfo && deviceInfo.ipd_filename ? deviceInfo.ipd_filename :"";
		let IPDVersion="";
		let IPDUDate="";

		let MachinSetFileName=deviceInfo && deviceInfo.machine_setup ? deviceInfo.machine_setup:"";
		let MachinSetVersion="";
		let MachinSetUDate="";

		let MenuFileName=deviceInfo && deviceInfo.menu_version ? deviceInfo.menu_version:"";
		let MenuVersion="";
		let MenuUDate="";

		let CompilationFileName=deviceInfo && deviceInfo.compilation_ver ? deviceInfo.compilation_ver:"";
		let CompilationVersion="";
		let CompilationUDate="";

		let IOTFileName=deviceInfo && deviceInfo.iot_version ? deviceInfo.iot_version:"";
		let IOTVersion=deviceInfo && deviceInfo.iot_version ? deviceInfo.iot_version:"";
		let IOTUDate="";

		let value="";
		if (installedPackagesResponseData  ){
			if (installedPackagesResponseData.constrolSoftware){
				value = installedPackagesResponseData.constrolSoftware;
				// CtrlSoftFileName=value.stum_filename?value.stum_filename:"";
				// CtrlSoftVersion=value.stum_ver?value.stum_ver:"";
				CtrlSoftUDate=value.lastUpdatedAt?moment.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
				
			}

			if (installedPackagesResponseData.mdp){
				value = installedPackagesResponseData.mdp;
				// MDPFileName=value.mpd_filename?value.mpd_filename:"";
				// MDPVersion=value.mpd_ver?value.mpd_ver:"";
				 MDPUDate=value.lastUpdatedAt?moment.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
			}

			if (installedPackagesResponseData.ipd){
				value = installedPackagesResponseData.ipd;
				// IPDFileName=value.mpd_filename?value.mpd_filename:"";
				// IPDVersion=value.mpd_ver?value.mpd_ver:"";
				 IPDUDate=value.lastUpdatedAt?moment.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
			}

			
			if (installedPackagesResponseData.machine){
				value = installedPackagesResponseData.machine;
				// MachinSetFileName=value.machine_setup?value.machine_setup:"";
				// MachinSetVersion=value.mpd_ver?value.mpd_ver:"";
				 MachinSetUDate=value.lastUpdatedAt?moment.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
			}

			if (installedPackagesResponseData.menu){
				value = installedPackagesResponseData.menu;
				// MachinSetFileName=value.menu?value.menu:"";
				// MachinSetVersion=value.menuVersion?value.menuVersion:"";
				MachinSetUDate=value.lastUpdatedAt?moment.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
			}			

			if (installedPackagesResponseData.compilation){
				value = installedPackagesResponseData.compilation;
				// CompilationFileName=value.stum_filename?value.stum_filename:"";
				// CompilationVersion=value.stum_ver?value.stum_ver:"";
				CompilationUDate=value.lastUpdatedAt?moment.GetDateTimeByFormat(value.lastUpdatedAt, 'LLL'):"";
			}

		}		

		pageRender = (
			<div class="colm6D colm6D1">
				<div class="colmDi">
					<h4><FormattedMessage id="KC0229"/></h4>
					<div class="colmDiVal">
						<div class="gridTableIPsView borbt">
							<ul class="tHead">
								<li>
									<label><FormattedMessage id="KC0232"/></label>
								</li>
								<li></li>
								<li>
									<label><FormattedMessage id="KC0231"/></label>
								</li>
							</ul>
							<ul class="tBody">
								<li>
									<label>{CtrlSoftFileName}</label>
									{/* <span class="labelVal">v00.02.009</span> */}
								</li>
								<li>
									<span class="labelVal">{CtrlSoftVersion}</span>
								</li>
								<li>
									<label>{CtrlSoftUDate}</label>									
								</li>
								{/* <li>
									<span class="labelVal"><button type="button" class="btn lastUpdateRef">&nbsp;</button></span>
								</li> */}
							</ul>
						</div>
						<div class="gridTableIPsView borbt">
							<ul class="tHead">
								<li>
									<label><FormattedMessage id="KC0233"/></label>
								</li>
								<li>
									<label>&nbsp;</label>
								</li>
							</ul>
							<ul class="tBody">
								<li>
									<label>{MDPFileName}</label>									
								</li>
								<li>
									<span class="labelVal">{MDPVersion}</span>
								</li>
								<li>
									<label>{MDPUDate}</label>
									<span class="labelVal">&nbsp;</span>
								</li>
							</ul>
						</div>
						<div class="gridTableIPsView borbt">
							<ul class="tHead">
								<li>
									<label><FormattedMessage id="KC0234"/></label>
								</li>
								<li>
									<label>&nbsp;</label>
								</li>
							</ul>
							<ul class="tBody">
								<li>
									<label>{IPDFileName}</label>									
								</li>
								<li>
									<span class="labelVal">{IPDVersion}</span>
								</li>
								<li>
									<label>{IPDUDate}</label>
									<span class="labelVal">&nbsp;</span>
								</li>
							</ul>
						</div>
						<div class="gridTableIPsView borbt">
							<ul class="tHead">
								<li>
									<label><FormattedMessage id="KC0235"/></label>
								</li>
								<li>
									<label>&nbsp;</label>
								</li>
							</ul>
							<ul class="tBody">
								<li>
									<label>{MachinSetFileName}</label>									
								</li>
								<li>
									<span class="labelVal">{MachinSetVersion}</span>
								</li>
								<li>
									<label>{MachinSetUDate}</label>
									{/* <span class="labelVal"><button type="button" class="btn lastUpdateRef">&nbsp;</button></span> */}
								</li>
							</ul>
						</div>
						<div class="gridTableIPsView borbt">
							<ul class="tHead">
								<li>
									<label><FormattedMessage id="KC0236"/></label>
								</li>
								<li>
									<label>&nbsp;</label>
								</li>
							</ul>
							<ul class="tBody">
								<li>
									<label>{MenuFileName}</label>									
								</li>
								<li>
									<span class="labelVal">{MenuVersion}</span>
								</li>
								<li>
									<label>{MenuUDate}</label>
									<span class="labelVal">&nbsp;</span>
								</li>
							</ul>
						</div>
						<div class="gridTableIPsView borbt">
							<ul class="tHead">
								<li>
									<label><FormattedMessage id="KC0237"/></label>
								</li>
								<li>
									<label>&nbsp;</label>
								</li>
							</ul>
							<ul class="tBody">
								<li>
									<label>{CompilationFileName}</label>									
								</li>
								<li>
									<span class="labelVal">{CompilationVersion}</span>
								</li>
								<li>
									<label>{CompilationUDate}</label>
									<span class="labelVal">&nbsp;</span>
								</li>
							</ul>
						</div>
						<div class="gridTableIPsView">
							<ul class="tHead">
								<li>
									<label><FormattedMessage id="KC0238"/></label>
								</li>
								<li>
									<label>&nbsp;</label>
								</li>
							</ul>
							<ul class="tBody">
								<li>
									<label>{IOTFileName}</label>									
								</li>
								<li>
									<span class="labelVal">&nbsp;</span>
								</li>
								<li>
									<label>{IOTUDate}</label>
									<span class="labelVal">&nbsp;</span>
								</li>
							</ul>
						</div>
						<button type="button" onClick={() => { this.updateAll() }} class="btn btn-default float-right"><FormattedMessage id="KC0239"/></button>
					</div>
				</div>
			</div>
		);

		return (
			<React.Fragment>
				{pageRender}
			</React.Fragment>
		)
	}
};
export default InstalledPackages; 
