


import React, { Component } from 'react';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import $ from 'jquery';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

class FrymasterGeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
          selectedModel: 0
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSettingsClick = (pathname) => {
        this.props.history.push({
            pathname,
            isEdit: false,
            type: "Settings",
            previouspath: "/frymasterGeneralSettings"
        });
    }

    onBackNavigation=()=>{
        this.props.history.push(`/frymasterSettings`);
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");
        return (
            <React.Fragment>
                {/* Frymaster General Setting Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster General Settings Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster General Settings Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button
                                        type="button"
                                        onClick={() => { this.onBackNavigation() }}
                                        class="btn backButton">&nbsp;
                                    </button> <FormattedMessage id="KC1975"/>
                                </div>
                                {/* Frymaster General Setting List Wrapper */}
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01">
                                                    {[
                                                        { title: "KC1411", imgPath: require( "../../../../../../images/recipecreation/rightArrow.png"), routePath:'/frymasterTimeDate' },
                                                        { title: "KC1417", imgPath:  require("../../../../../../images/recipecreation/rightArrow.png"), routePath:'/frymasterLocale' },
                                                        { title: "KC1424", imgPath:  require("../../../../../../images/recipecreation/rightArrow.png"), routePath:'/frymasterScreen' },
                                                        { title: "KC1289", imgPath:  require("../../../../../../images/recipecreation/rightArrow.png"), routePath:'/frymasterTemperature' },
                                                        { title: "KC0920", imgPath:  require("../../../../../../images/recipecreation/rightArrow.png"), routePath:'/frymasterLanguage' },
                                                        { title: "KC1290", imgPath: require( "../../../../../../images/recipecreation/rightArrow.png"), routePath:'/frymasterSound' },
                                                        { title: "KC1433", imgPath: require("../../../../../../images/recipecreation/rightArrow.png"),routePath:'/frymasterConfig' }].map((item, index) => {
                                                            return (
                                                                <div key={index} class="rcSettingsList02">
                                                                    <ul onClick={() => { this.onSettingsClick(item.routePath) }}>
                                                                        <li><FormattedMessage id={item.title}/></li>
                                                                        <li><img src={item.imgPath}  alt=""/></li>
                                                                    </ul>
                                                                </div>
                                                            )
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster General Settings Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterGeneralSettings)
