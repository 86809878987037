import React, { Component } from 'react';
import * as _ from 'lodash';
import { Link } from 'react-router-dom';
import authService from '../../../service/authService';
import http from '../../../service/httpService';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import ErrorMoal from '../../modal/error-model';
import SuccessMoal from '../../modal/success-model';

import { ReportManagement } from '../../../service/api';
import WoopraEvents from '../../../woopraConfig/WoopraEvents';
import Constants from '../../../woopraConfig/Woopraconstant';

const reportConfiguration = [
    {
        key: 'error_report',
        settingsName: 'Error Report',
        stateKey: 'error'
    },
    {
        key: 'compliance_report',
        settingsName: 'Filter Compliance Report',
        stateKey: 'filtercompliance'
    }
];

const period = ['monthly', 'weekly', 'daily'];




class AutomatedEmailSettings extends Component {

    state = {
        loaderIconVisible: false,
        error: null,
        filtercompliance: null,
        btnEnabled: false,
        checkBoxDisabled: true,
        success_modal: {
            open: false,
            message: ""
        },
        error_modal: {
            open: false,
            message: ""
        }
    }

    getAutoEmailSettings = async () => {
        try {
            this.setState({ loaderIconVisible: true });
            const configResponse = await http.get(ReportManagement.getAutoEmailSetting, {
                headers: {
                    'Content-Type': 'application/json',
                    email: localStorage.getItem("user"),
                    brand_id: localStorage.getItem("selectedbrandID"),
                    customer_id: localStorage.getItem("customerId"),
                    customer_name: localStorage.getItem("customerName"), 
                },
                data: {}
            });
            return _.isObject(configResponse.data) ? configResponse.data.data : [];
        } catch (error) {
            throw error;
        }
    };


    updateAutoEmailSettings = () => {
        try {
            const { error, filtercompliance } = this.state;
            this.setState({ loaderIconVisible: true });
            const payLoad = {
                email: localStorage.getItem("user"),
                brand_id: localStorage.getItem("selectedbrandID"),
                customer_id: localStorage.getItem("customerId"),
                customer_name: localStorage.getItem("customerName"),
                error, filtercompliance
            };

            http.put(
                ReportManagement.updateAutoEmailSetting,
                payLoad,
                { headers: { 'Content-Type': 'application/json' } }
            ).then(response => {
                let { data } = response.data;
                if (data) {
                    this.setState({
                        loaderIconVisible: false,
                        success_modal: {
                            open: true,
                            message: "Email configuration updated."
                        },
                    });
                    WoopraEvents(`${Constants.EMAIL_REPORT_CONFIGURATION_UPDATED}`);
                }
                else {
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: data.message ? data.message : "Email configuration failed."
                        }
                    });
                    WoopraEvents(`${Constants.EMAIL_REPORT_CONFIGURATION_FAILED}`);
                }
            }).catch(err => {
                console.log(err);
            });
        } catch (error) {
            console.log(error);
        }
    };

    onSuccessCloseModal = () => {
        this.setState({
            success_modal: {
                open: false
            }
        });
        this.props.history.push(`/reportList`);
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }


    componentDidMount = () => {
        document.body.style.backgroundColor = "#F2F2F2";

        this.getAutoEmailSettings().then(result => {
            const { error, filtercompliance } = _.head(result) || {};
            this.setState({ error, filtercompliance, loaderIconVisible: false, checkBoxDisabled: false });
        }).catch(err => {
            console.log(err);
            this.setState({ loaderIconVisible: false });
        });
    }

    checkBoxChangeHandler(event, stateKey) {
        if (stateKey) {
            const copyOfState = _.isArray(this.state[stateKey]) ? [...this.state[stateKey]] : [];
            const key = event.target.name;
            const index = copyOfState.findIndex(el => el.hasOwnProperty(key));
            if (index > -1) {
                copyOfState[index][key] = event.target.checked;
            }

            this.setState({
                [stateKey]: copyOfState,
                btnEnabled: true
            });
        }
    };


    render() {
        const { loaderIconVisible, btnEnabled, checkBoxDisabled, error_modal, success_modal } = this.state;
        let userProfileAuthFeature = authService.getFeature("/reportList");

        const checkBoxComp = (input) => {
            const stateValue = this.state[input.stateKey] || [];

            return period.map((k, ind) => {
                const name = k.toLowerCase();
                const findObj = stateValue.find(el => el.hasOwnProperty(k)) || {};

                const checkedValue = findObj[k] ? true : false;

                return (
                    <div class={userProfileAuthFeature.is_editable === 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"} key={input.stateKey + ind}>
                        <input type="checkbox" name={name} id={input.stateKey + ind}
                            checked={checkedValue}
                            disabled={checkBoxDisabled}
                            onChange={(e) => this.checkBoxChangeHandler(e, input.stateKey)}
                        />
                        <label htmlFor={input.stateKey + ind}>{_.capitalize(k)}</label>
                    </div>
                )
            });
        };

        let emailReportSettings = reportConfiguration.map((el) => {
            return (
                <form class="assigntoBrandsForm" key={el.key}>
                    <div class="assigntoEmailReport">
                        <ul class="tHead">
                            <li>{el.settingsName}</li>
                        </ul>
                        <ul class="tBody">
                            <li>
                                {checkBoxComp(el)}
                            </li>
                        </ul>
                    </div>
                </form>
            )
        });
        if (Array.isArray(emailReportSettings) && emailReportSettings.length === 0) {
            emailReportSettings = (<h6 className="norecordsfoundbluetext">No existing email report configuration.</h6>);
        }


        let buttonEnable = (btnEnabled) ? 'btn-default-text activeState' : 'disabled';

        return (
            <div id="adminWrapper">
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <UserManagementHeader headerName="Reports" activeClass="reportNav" />

                    <div class="userManagementWrapper">

                        <div class="addUserHeader">
                            <ul>
                                <li>
                                    <Link to="/reportList" title="My Reports" class="backIcon"></Link>
                                </li>
                                <li>Email Report Configuration</li>
                                <li>
                                    <Link to="#" title="Finish" className={buttonEnable} onClick={() => this.updateAutoEmailSettings()}>Finish</Link>
                                </li>
                            </ul>
                        </div>

                        <div class="chooseUserFormWrapper">
                            {emailReportSettings}
                        </div>
                    </div>
                    <SuccessMoal open={success_modal.open} message={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                    <ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} />
                </div>
            </div>
        )
    }
};


export default AutomatedEmailSettings;