import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as _ from "lodash";
import $ from "jquery";
import FiltersChildComponents from "./FiltersChildComponents";
import FilterList from "../FilterBase/FilterList/FilterList";
import backDropEvent from "../../events/backdropEvent";
import http from "../../service/httpService";
import { BRAND_ID, BRAND_ID_VALUE } from "../../utils/appConstants";
import { resetSelectedFilterList } from "../../actions/listView/locations/filterListAction";
import {
  LocationInfo,
  smartTagMangement,
  UserProfile,
} from "../../service/api";
import "bootstrap/dist/css/bootstrap.min.css";
import { FormattedMessage, injectIntl } from "react-intl";
import { filterBaseMappingStringID } from "../../locale/constant";
import { getOrgTags } from "./orgTagshelper";
import { readArchivesFromCompressedResponse } from "../../utils/helpers";

class FilterBase extends Component {
  // Default State Values
  state = {
    isShowListView: true,
    filterViewName: "KC0055",
    filterFieldName: null,
    optionList: [],
    loaderIconVisible: true,
    orgNodeCaptions: [],
    filterModel: [],
    groupID: [],
    groupDynasty: [],
    brandDetails: [],
    machineDetails: {},
    orgSmartTagList: [],
    unitSmartTagList: [],
    unitS: [],
    isInitialValue: "",
    isInitialBrandValue: "",
  };

  // Actions need to trigger after component mounted
  async componentDidMount() {
    this.getBrandUnitModal();
    this.getOrgSTagDetails("orgList");
    this.getOrgSTagDetails("unitList");
    await this.getOrgnanizationView();
    await this.findCustLevelToShow();

    this.setState({ loaderIconVisible: false });
    if (this.props.filterHidden) {
      let elem = backDropEvent.showBackDrop();
      let navHeader = document.querySelector(".navbar");
      navHeader.addEventListener("click", this.backdropClose);
      navHeader.addEventListener("touchstart", this.backdropClose);
      elem.addEventListener("click", this.backdropClose);
      elem.addEventListener("touchstart", this.backdropClose);
    }
  }

  // This function is used to prepare the object for the top level location, maas, shiptos, within location from org api
  getOrgNames = async (orgValue = []) => {
    let result = [],
      levelId = [];
    for (let i = 0; i < orgValue.length; i++) {
      const { category_name, level_id, nextLevelAccess } = orgValue[i];
      if (!levelId.includes(level_id)) {
        result.push({ category_name, level_id });
        levelId.push(level_id);
      }
      if ((nextLevelAccess && orgValue[i]["level2"]) || orgValue[i]["level2"]) {
        for (let j = 0; j < orgValue[i]["level2"].length; j++) {
          const { category_name, level_id, nextLevelAccess } =
            orgValue[i]["level2"][j];
          if (!levelId.includes(level_id)) {
            result.push({ category_name, level_id });
            levelId.push(level_id);
          }
          if (
            (nextLevelAccess && orgValue[i]["level2"][j]["level3"]) ||
            orgValue[i]["level2"][j]["level3"]
          ) {
            for (
              let k = 0;
              k < orgValue[i]["level2"][j]["level3"].length;
              k++
            ) {
              const { category_name, level_id, nextLevelAccess } =
                orgValue[i]["level2"][j]["level3"][k];
              if (!levelId.includes(level_id)) {
                result.push({ category_name, level_id });
                levelId.push(level_id);
              }
              if (
                (nextLevelAccess &&
                  orgValue[i]["level2"][j]["level3"][k]["level4"]) ||
                orgValue[i]["level2"][j]["level3"][k]["level4"]
              ) {
                for (
                  let l = 0;
                  l < orgValue[i]["level2"][j]["level3"][k]["level4"].length;
                  l++
                ) {
                  const { category_name, level_id, nextLevelAccess } =
                    orgValue[i]["level2"][j]["level3"][k]["level4"][l];
                  if (!levelId.includes(level_id)) {
                    result.push({ category_name, level_id });
                    levelId.push(level_id);
                  }
                  if (
                    (nextLevelAccess &&
                      orgValue[i]["level2"][j]["level3"][k]["level4"][l][
                        "level5"
                      ]) ||
                    orgValue[i]["level2"][j]["level3"][k]["level4"][l]["level5"]
                  ) {
                    for (
                      let h = 0;
                      h <
                      orgValue[i]["level2"][j]["level3"][k]["level4"][l][
                        "level5"
                      ].length;
                      h++
                    ) {
                      const { category_name, level_id } =
                        orgValue[i]["level2"][j]["level3"][k]["level4"][l][
                          "level5"
                        ][h];
                      if (!levelId.includes(level_id)) {
                        result.push({ category_name, level_id });
                        levelId.push(level_id);
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return result;
  };

  checkOrgName = async (filterModel) => {
    //compare two arrays
    let array1 = filterModel;
    let array2 = await this.getOrgNames(this.state.orgNodeCaptions);
    if (this.state.orgNodeCaptions && this.state.orgNodeCaptions.length > 0) {
      for (let i = 0; i < array1.length; i++) {
        for (let k = 0; k < array2.length; k++) {
          if (array1[i].sort === array2[k].level_id) {
            if (
              array1[i].sort === 1 ||
              array1[i].sort === 2 ||
              array1[i].sort === 3 ||
              array1[i].sort === 4 ||
              array1[i].sort === 5
            ) {
              array1[i].display_name = array2[k].category_name;
              break;
            }
          }
        }
      }
      filterModel = array1;
    } else {
      filterModel = [];
      filterModel = _.flatten(filterModel);
    }
    return filterModel;
  };

  // Find Customer level to show the visiblilty
  findCustLevelToShow = async () => {
    let groupID = [],
      groupDynasty = [],
      isInitialValue = "",
      isInitialBrandValue = "";
    let filterModel =
      JSON.parse(localStorage.getItem(this.props.filterKey)) ||
      this.props.filterlistInformtion.filterModel;
    filterModel = _.orderBy(filterModel, ["sort"], ["asc"]);
    for (let i = 0; i < filterModel.length; i++) {
      filterModel[i].visible = true;
      if (
        filterModel[i].dataSource.length &&
        ["customer", "country", "chain", "location", "withinlocation"].includes(
          filterModel[i].field_name
        )
      ) {
        for (let j = 0; j < filterModel[i].dataSource.length; j++) {
          if (!groupID.length) {
            isInitialValue = filterModel[i].dataSource[j].level_id;
          }
          groupID.push(filterModel[i].dataSource[j].cust_gp_id);
          groupDynasty.push(filterModel[i].dataSource[j].dynasty);
        }
      }
    }
    for (let i = 0; i < filterModel.length; i++) {
      if (
        filterModel[i].dataSource.length &&
        ["brand", "machineType", "model"].includes(filterModel[i].field_name)
      ) {
        if (isInitialBrandValue === "") {
          isInitialBrandValue = filterModel[i].field_name;
        }
      }
    }
    filterModel = await this.checkOrgName(filterModel);
    this.setState({
      filterModel,
      groupDynasty,
      groupID,
      isInitialValue,
      isInitialBrandValue,
    });
  };

  // Get org structure from api
  getOrgnanizationView = async () => {
    this.setState({ loaderIconVisible: true });
    let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
    let loggedinUser = loginUser.username;
    await http({
      method: "GET",
      url: `${
        UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")
      }`,
      headers: {
        Cgids: localStorage.getItem("custGroupID"),
        "Content-Type": "application/json",
        brandids: BRAND_ID,
        useremail: loggedinUser,
      },
      data: {},
    })
      .then(async (response) => {
        // if (response.data.fetchFromS3) {
        //     await $.getJSON(response.data.presignedS3URL, (data) => {
        //         this.setState({ orgNodeCaptions: data['level1'], loaderIconVisible: false });
        //     })
        // }
        // else {
        //     this.setState({ orgNodeCaptions: response.data['level1'], loaderIconVisible: false });
        // }
        let orgJSON = await readArchivesFromCompressedResponse(response.data);
        localStorage.setItem("custLevelID", orgJSON.start_level_name);
        localStorage.setItem("startLevelID", orgJSON.start_level_id);
        localStorage.setItem("parentCustGroupId", orgJSON.parent_group_id);
        this.setState({
          orgNodeCaptions: orgJSON["level1"],
          loaderIconVisible: false,
        });
      })
      .catch(() => {
        this.setState({
          loaderIconVisible: false,
        });
      });
  };

  getOrgSTagDetails = async (type) => {
    let url = smartTagMangement.getTagListView;
    if (type === "orgList") {
      url = url + "ORG";
    } else {
      url = url + "UNIT";
    }
    await http
      .get(url, {
        headers: {
          "Content-Type": "application/json",
          customerid: localStorage.getItem("customerId"),
          brandid: BRAND_ID,
          // 'model': '',
          cgid: localStorage.getItem("custGroupID"),
        },
        data: {},
      })
      .then(async (response) => {
        if (response && response.data) {
          this.setState({
            [type === "orgList" ? "orgSmartTagList" : "unitSmartTagList"]:
              response.data.data.length > 0 ? response.data.data : [],
          });
        }
      })
      .catch((err) => {});
  };

  formBrandData = (brandResult) => {
    let brandData = [];
    for (let i = 0; i < brandResult.length; i++) {
      if (brandResult[i].unitType && brandResult[i].unitType.length > 0) {
        for (let j = 0; j < brandResult[i].unitType.length; j++) {
          for (let k = 0; k < brandResult[i].unitType[j].models.length; k++) {
            let temp = {
              brandId: brandResult[i].ID,
              brandName: brandResult[i].BRAND_NAME,
              unitId: brandResult[i].unitType[j].PRODUCT_TYPE_ID,
              unitName: brandResult[i].unitType[j].PRODUCT_TYPE_NAME,
              modelId: brandResult[i].unitType[j].models[k].PRODUCT_ID,
              modelName: brandResult[i].unitType[j].models[k].MODEL_NAME,
            };
            let unitSmartTagData = [];
            for (
              let l = 0;
              l < brandResult[i].unitType[j].models[k].unitTagDetials.length;
              l++
            ) {
              unitSmartTagData.push({
                id: brandResult[i].unitType[j].models[k].unitTagDetials[l].id,
                tagName:
                  brandResult[i].unitType[j].models[k].unitTagDetials[l]
                    .tagName,
              });
            }
            if (unitSmartTagData.length > 0) {
              temp.unitSmartTagData = unitSmartTagData;
            }
            brandData.push(temp);
          }
        }
      } else {
        return brandData;
      }
    }
    return brandData;
  };

  // Get brand details from api
  getBrandUnitModal = () => {
    let brandIDToHeader = BRAND_ID;
    const pageName = window.location.href.split("/").pop();
    let barndIDValue = BRAND_ID_VALUE;
    let selectedBrandName = localStorage.getItem("brandName");
    barndIDValue = BRAND_ID_VALUE[selectedBrandName];
    if (
      pageName === "menuAssignUpdateList" ||
      pageName === "assignUpdateList" ||
      pageName === "assignmedialistView" ||
      pageName === "assignmediatopology" ||
      pageName === "softwareTransferSmartgroupsList" ||
      pageName === "softwareAssigngroupTopology" ||
      pageName === "menuTransfergroupsList" ||
      pageName === "menuAssigngroupTopology"
    ) {
      brandIDToHeader = barndIDValue;
    }
    http
      .get(`${LocationInfo.filters}`, {
        headers: {
          "Content-Type": "application/json",
          Type: "Brand",
          brandid: brandIDToHeader,
          cid: localStorage.getItem("customerId"),
        },
        data: {},
      })
      .then(async (response) => {
        if (response && response.data) {
          const brandDetails = await this.formBrandData(response.data);
          await this.setState({ brandDetails });
        }
      });
  };

  backdropClose = (e) => {
    if ($(e.target).closest(".navFilterWrapper").length === 0) {
      if (
        !$(e.target).hasClass("loadingProgress") &&
        !$(e.target).hasClass("navFilterWrapper")
      ) {
        if (
          !$(e.target).hasClass("navFilter") &&
          $("body").has("#backdropElement")
        ) {
          document
            .querySelector(".navbar")
            .removeEventListener("click", this.backdropClose);
          document
            .querySelector(".navbar")
            .removeEventListener("touchstart", this.backdropClose);
          this.props.callFilterHideToggle(e);
          backDropEvent.hideBackdrop();
        }
      }
    }
  };

  // This function is triggered when the arrow is pressed to load the category checkbox value details
  choosenFilterViewHandler = async (filterEvt) => {
    const isInitialBrandValue = this.state.isInitialBrandValue;
    if (filterEvt.field_name) {
      this.setState({
        filterViewName: filterEvt.display_name,
        filterFieldName: filterEvt.field_name,
      });
      if (filterEvt.field_name === "unitSmartTagsFilter") {
        this.setState({ loaderIconVisible: true });
        let allBrandData = [...this.state.brandDetails];
        let getBrand = await this.state.filterModel.filter(
          (i) => i.field_name === "brand"
        );
        let getMachine = await this.state.filterModel.filter(
          (i) => i.field_name === "machineType"
        );
        let getModel = await this.state.filterModel.filter(
          (i) => i.field_name === "model"
        );
        if (
          (getBrand && getBrand.length && getBrand[0].dataSource.length) ||
          (getMachine &&
            getMachine.length &&
            getMachine[0].dataSource.length) ||
          (getModel && getModel.length && getModel[0].dataSource.length)
        ) {
          let brandSelected = [],
            unitSelected = [],
            modelSelected = [],
            unitSTSelected = [];
          for (let i = 0; i < getBrand[0].dataSource.length; i++) {
            const ele = getBrand[0].dataSource[i];
            brandSelected.push(ele.id);
          }
          if (brandSelected.length > 0) {
            allBrandData = allBrandData.filter((i) =>
              brandSelected.includes(i.brandId)
            );
          }

          for (let i = 0; i < getMachine[0].dataSource.length; i++) {
            const ele = getMachine[0].dataSource[i];
            unitSelected.push(ele.id);
          }
          if (unitSelected.length > 0) {
            allBrandData = allBrandData.filter((i) =>
              unitSelected.includes(i.unitId)
            );
          }

          for (let i = 0; i < getModel[0].dataSource.length; i++) {
            const ele = getModel[0].dataSource[i];
            modelSelected.push(ele.id);
          }
          if (modelSelected.length > 0) {
            allBrandData = allBrandData.filter((i) =>
              modelSelected.includes(i.modelId)
            );
          }

          let optionList = [],
            unitSTResult = [];
          for (let i = 0; i < allBrandData.length; i++) {
            const ele = allBrandData[i];
            if (!unitSTResult.includes(ele.id)) {
              if (brandSelected.length && brandSelected.includes(ele.brandId)) {
                let dataDet = ele.unitSmartTagData;
                if (dataDet) {
                  for (let j = 0; j < dataDet.length; j++) {
                    if (!unitSTResult.includes(dataDet[j].id)) {
                      let isChecked = await this.getCheckedValue(
                        "unitSmartTagsFilter",
                        dataDet[j].id
                      );
                      optionList.push({
                        id: dataDet[j].id,
                        type: "unit",
                        ID: dataDet[j].id,
                        value: dataDet[j].tagName,
                        isChecked,
                      });
                      unitSTResult.push(dataDet[j].id);
                    }
                  }
                }
              } else if (
                modelSelected.length &&
                modelSelected.includes(ele.modelId)
              ) {
                let dataDet = ele.unitSmartTagData;
                if (dataDet) {
                  for (let j = 0; j < dataDet.length; j++) {
                    if (!unitSTResult.includes(dataDet[j].id)) {
                      let isChecked = await this.getCheckedValue(
                        "unitSmartTagsFilter",
                        dataDet[j].id
                      );
                      optionList.push({
                        id: dataDet[j].id,
                        type: "unit",
                        ID: dataDet[j].id,
                        value: dataDet[j].tagName,
                        isChecked,
                      });
                      unitSTResult.push(dataDet[j].id);
                    }
                  }
                }
              } else if (
                unitSelected.length &&
                unitSelected.includes(ele.unitId)
              ) {
                let dataDet = ele.unitSmartTagData;
                if (dataDet) {
                  for (let j = 0; j < dataDet.length; j++) {
                    if (!unitSTResult.includes(dataDet[j].id)) {
                      let isChecked = await this.getCheckedValue(
                        "unitSmartTagsFilter",
                        dataDet[j].id
                      );
                      optionList.push({
                        id: dataDet[j].id,
                        type: "unit",
                        ID: dataDet[j].id,
                        value: dataDet[j].tagName,
                        isChecked,
                      });
                      unitSTResult.push(dataDet[j].id);
                    }
                  }
                }
              } else if (unitSTSelected.length) {
                let dataDet = ele.unitSmartTagData;
                if (dataDet) {
                  for (let j = 0; j < dataDet.length; j++) {
                    let isChecked = await this.getCheckedValue(
                      "unitSmartTagsFilter",
                      ele.id
                    );
                    optionList.push({
                      id: ele.id,
                      type: "unit",
                      ID: ele.id,
                      value: ele.tagName,
                      isChecked,
                    });
                    unitSTResult.push(ele.id);
                  }
                }
              } else {
                let dataDet = ele.unitSmartTagData;
                if (dataDet) {
                  for (let j = 0; j < dataDet.length; j++) {
                    let isChecked = await this.getCheckedValue(
                      "unitSmartTagsFilter",
                      ele.id
                    );
                    optionList.push({
                      id: ele.id,
                      type: "unit",
                      ID: ele.id,
                      value: ele.tagName,
                      isChecked,
                    });
                    unitSTResult.push(ele.id);
                  }
                }
              }
            }
          }
          this.setState({
            optionList: _.orderBy(optionList, ["value"]),
            isShowListView: false,
            loaderIconVisible: false,
          });
          return;
        } else {
          let dataDet = [...this.state.brandDetails] || [];
          let optionList = [],
            unitSmartTagsResult = [];
          for (let i = 0; i < dataDet.length; i++) {
            const unitSTvalue = dataDet[i].unitSmartTagData;
            if (unitSTvalue) {
              for (let j = 0; j < unitSTvalue.length; j++) {
                if (!unitSmartTagsResult.includes(unitSTvalue[j].id)) {
                  let isChecked = await this.getCheckedValue(
                    "unitSmartTagsFilter",
                    unitSTvalue[j].id
                  );
                  optionList.push({
                    id: unitSTvalue[j].id,
                    type: "unit",
                    ID: unitSTvalue[j].id,
                    value: unitSTvalue[j].tagName,
                    isChecked,
                  });
                  unitSmartTagsResult.push(unitSTvalue[j].id);
                }
              }
            }
          }
          this.setState({
            optionList: _.orderBy(optionList, ["value"]),
            isShowListView: false,
            loaderIconVisible: false,
          });
          return;
        }
      }
      if (filterEvt.field_name === "brand") {
        let allBrandData = [...this.state.brandDetails];
        let getMachine = await this.state.filterModel.filter(
          (i) => i.field_name === "machineType"
        );
        let getModel = await this.state.filterModel.filter(
          (i) => i.field_name === "model"
        );
        let getUnitST = await this.state.filterModel.filter(
          (i) => i.field_name === "unitSmartTagsFilter"
        );

        if (
          (getMachine &&
            getMachine.length &&
            getMachine[0].dataSource.length) ||
          (getModel && getModel.length && getModel[0].dataSource.length) ||
          (getUnitST && getUnitST.length && getUnitST[0].dataSource.length)
        ) {
          let unitSelected = [],
            modelSelected = [],
            unitSTSelected = [];
          for (let i = 0; i < getMachine[0].dataSource.length; i++) {
            const ele = getMachine[0].dataSource[i];
            unitSelected.push(ele.id);
          }
          if (unitSelected.length > 0) {
            allBrandData = allBrandData.filter((i) =>
              unitSelected.includes(i.unitId)
            );
          }

          for (let i = 0; i < getModel[0].dataSource.length; i++) {
            const ele = getModel[0].dataSource[i];
            modelSelected.push(ele.id);
          }
          if (modelSelected.length > 0) {
            allBrandData = allBrandData.filter((i) =>
              modelSelected.includes(i.modelId)
            );
          }

          for (let i = 0; i < getUnitST[0].dataSource.length; i++) {
            const ele = getUnitST[0].dataSource[i];
            unitSTSelected.push(ele.id);
          }
          let optionList = [],
            brandIdResult = [];
          for (let i = 0; i < allBrandData.length; i++) {
            const ele = allBrandData[i];
            if (!brandIdResult.includes(ele.brandId)) {
              if (unitSelected.length && unitSelected.includes(ele.unitId)) {
                let isChecked = await this.getCheckedValue(
                  "brand",
                  ele.brandId
                );
                optionList.push({
                  id: ele.brandId,
                  ID: ele.brandId,
                  value: ele.brandName,
                  isChecked,
                });
                brandIdResult.push(ele.brandId);
              } else if (
                modelSelected.length &&
                modelSelected.includes(ele.modelId)
              ) {
                let isChecked = await this.getCheckedValue(
                  "brand",
                  ele.brandId
                );
                optionList.push({
                  id: ele.brandId,
                  ID: ele.brandId,
                  value: ele.brandName,
                  isChecked,
                });
                brandIdResult.push(ele.brandId);
              } else if (unitSTSelected.length) {
                let dataDet = ele.unitSmartTagData;
                if (dataDet) {
                  for (let j = 0; j < dataDet.length; j++) {
                    if (!brandIdResult.includes(ele.brandId)) {
                      if (unitSTSelected.includes(dataDet[j].id)) {
                        let isChecked = await this.getCheckedValue(
                          "brand",
                          ele.brandId
                        );
                        optionList.push({
                          id: ele.brandId,
                          ID: ele.brandId,
                          value: ele.brandName,
                          isChecked,
                        });
                        brandIdResult.push(ele.brandId);
                      }
                    }
                  }
                }
              } else if (isInitialBrandValue === "brand") {
                let isChecked = await this.getCheckedValue(
                  "brand",
                  ele.brandId
                );
                optionList.push({
                  id: ele.brandId,
                  ID: ele.brandId,
                  value: ele.brandName,
                  isChecked,
                });
                brandIdResult.push(ele.brandId);
              }
            }
          }
          this.setState({
            optionList,
            isShowListView: false,
            loaderIconVisible: false,
          });
          return;
        } else {
          let optionList = [],
            brandIdResult = [];
          for (let i = 0; i < this.state.brandDetails.length; i++) {
            const ele = this.state.brandDetails[i];
            if (!brandIdResult.includes(ele.brandId)) {
              let isChecked = await this.getCheckedValue("brand", ele.brandId);
              optionList.push({
                id: ele.brandId,
                ID: ele.brandId,
                value: ele.brandName,
                isChecked,
              });
              brandIdResult.push(ele.brandId);
            }
          }
          this.setState({
            optionList,
            isShowListView: false,
            loaderIconVisible: false,
          });
          return;
        }
      }
      if (filterEvt.field_name === "machineType") {
        this.setState({ loaderIconVisible: true });
        let getBrand = await this.state.filterModel.filter(
          (i) => i.field_name === "brand"
        );
        let getModel = await this.state.filterModel.filter(
          (i) => i.field_name === "model"
        );
        let getUnitST = await this.state.filterModel.filter(
          (i) => i.field_name === "unitSmartTagsFilter"
        );
        if (
          (getBrand && getBrand.length && getBrand[0].dataSource.length) ||
          (getModel && getModel.length && getModel[0].dataSource.length) ||
          (getUnitST && getUnitST.length && getUnitST[0].dataSource.length)
        ) {
          let brandSelected = [],
            modelSelected = [],
            unitSTSelected = [];
          for (let i = 0; i < getBrand[0].dataSource.length; i++) {
            const ele = getBrand[0].dataSource[i];
            brandSelected.push(ele.id);
          }
          for (let i = 0; i < getModel[0].dataSource.length; i++) {
            const ele = getModel[0].dataSource[i];
            modelSelected.push(ele.id);
          }
          for (let i = 0; i < getUnitST[0].dataSource.length; i++) {
            const ele = getUnitST[0].dataSource[i];
            unitSTSelected.push(ele.id);
          }
          let optionList = [],
            machineResult = [];
          for (let i = 0; i < this.state.brandDetails.length; i++) {
            const ele = this.state.brandDetails[i];
            if (!machineResult.includes(ele.unitId)) {
              if (brandSelected.length && brandSelected.includes(ele.brandId)) {
                let isChecked = await this.getCheckedValue(
                  "machineType",
                  ele.unitId
                );
                optionList.push({
                  id: ele.unitId,
                  ID: ele.unitId,
                  value: ele.unitName,
                  isChecked,
                });
                machineResult.push(ele.unitId);
              } else if (
                modelSelected.length &&
                modelSelected.includes(ele.modelId)
              ) {
                let isChecked = await this.getCheckedValue(
                  "machineType",
                  ele.unitId
                );
                optionList.push({
                  id: ele.unitId,
                  ID: ele.unitId,
                  value: ele.unitName,
                  isChecked,
                });
                machineResult.push(ele.unitId);
              } else if (unitSTSelected.length) {
                let dataDet = ele.unitSmartTagData;
                if (dataDet) {
                  for (let j = 0; j < dataDet.length; j++) {
                    if (!machineResult.includes(ele.unitId)) {
                      if (unitSTSelected.includes(dataDet[j].id)) {
                        let isChecked = await this.getCheckedValue(
                          "machineType",
                          ele.modelId
                        );
                        optionList.push({
                          id: ele.unitId,
                          ID: ele.unitId,
                          value: ele.unitName,
                          isChecked,
                        });
                        machineResult.push(ele.unitId);
                      }
                    }
                  }
                }
              } else if (isInitialBrandValue === "machineType") {
                let isChecked = await this.getCheckedValue(
                  "machineType",
                  ele.unitId
                );
                optionList.push({
                  id: ele.unitId,
                  ID: ele.unitId,
                  value: ele.unitName,
                  isChecked,
                });
                machineResult.push(ele.unitId);
              }
            }
          }
          this.setState({
            optionList,
            isShowListView: false,
            loaderIconVisible: false,
          });
          return;
        } else {
          let optionList = [],
            machineResult = [];
          for (let i = 0; i < this.state.brandDetails.length; i++) {
            const ele = this.state.brandDetails[i];
            if (!machineResult.includes(ele.unitId)) {
              let isChecked = await this.getCheckedValue(
                "machineType",
                ele.unitId
              );
              optionList.push({
                id: ele.unitId,
                ID: ele.unitId,
                value: ele.unitName,
                isChecked,
              });
              machineResult.push(ele.unitId);
            }
          }
          this.setState({
            optionList,
            isShowListView: false,
            loaderIconVisible: false,
          });
          return;
        }
      }
      if (filterEvt.field_name === "model") {
        this.setState({ loaderIconVisible: true });
        let getBrand = await this.state.filterModel.filter(
          (i) => i.field_name === "brand"
        );
        let getMachine = await this.state.filterModel.filter(
          (i) => i.field_name === "machineType"
        );
        let getUnitST = await this.state.filterModel.filter(
          (i) => i.field_name === "unitSmartTagsFilter"
        );
        if (
          (getBrand && getBrand.length && getBrand[0].dataSource.length) ||
          (getMachine &&
            getMachine.length &&
            getMachine[0].dataSource.length) ||
          (getUnitST && getUnitST.length && getUnitST[0].dataSource.length)
        ) {
          let brandSelected = [],
            machineSelected = [],
            unitSTSelected = [];
          for (let i = 0; i < getBrand[0].dataSource.length; i++) {
            const ele = getBrand[0].dataSource[i];
            brandSelected.push(ele.id);
          }
          for (let i = 0; i < getMachine[0].dataSource.length; i++) {
            const ele = getMachine[0].dataSource[i];
            machineSelected.push(ele.id);
          }
          for (let i = 0; i < getUnitST[0].dataSource.length; i++) {
            const ele = getUnitST[0].dataSource[i];
            unitSTSelected.push(ele.id);
          }
          let optionList = [],
            modelResult = [];
          for (let i = 0; i < this.state.brandDetails.length; i++) {
            const ele = this.state.brandDetails[i];
            if (!modelResult.includes(ele.modelId)) {
              if (isInitialBrandValue === "model") {
                let isChecked = await this.getCheckedValue(
                  "model",
                  ele.modelId
                );
                optionList.push({
                  id: ele.modelId,
                  ID: ele.modelId,
                  value: ele.modelName,
                  isChecked,
                });
                modelResult.push(ele.modelId);
              }
              if (brandSelected.length && brandSelected.includes(ele.brandId)) {
                let isChecked = await this.getCheckedValue(
                  "model",
                  ele.modelId
                );
                optionList.push({
                  id: ele.modelId,
                  ID: ele.modelId,
                  value: ele.modelName,
                  isChecked,
                });
                modelResult.push(ele.modelId);
              } else if (
                machineSelected.length &&
                machineSelected.includes(ele.unitId)
              ) {
                let isChecked = await this.getCheckedValue(
                  "model",
                  ele.modelId
                );
                optionList.push({
                  id: ele.modelId,
                  ID: ele.modelId,
                  value: ele.modelName,
                  isChecked,
                });
                modelResult.push(ele.modelId);
              } else if (unitSTSelected.length) {
                let dataDet = ele.unitSmartTagData;
                if (dataDet) {
                  for (let j = 0; j < dataDet.length; j++) {
                    if (!modelResult.includes(ele.modelId)) {
                      if (unitSTSelected.includes(dataDet[j].id)) {
                        let isChecked = await this.getCheckedValue(
                          "model",
                          ele.modelId
                        );
                        optionList.push({
                          id: ele.modelId,
                          ID: ele.modelId,
                          value: ele.modelName,
                          isChecked,
                        });
                        modelResult.push(ele.modelId);
                      }
                    }
                  }
                }
              }
            }
          }
          this.setState({
            optionList,
            isShowListView: false,
            loaderIconVisible: false,
          });
          return;
        } else {
          let optionList = [],
            modelResult = [];
          for (let i = 0; i < this.state.brandDetails.length; i++) {
            const ele = this.state.brandDetails[i];
            if (!modelResult.includes(ele.modelId)) {
              let isChecked = await this.getCheckedValue("model", ele.modelId);
              optionList.push({
                id: ele.modelId,
                ID: ele.modelId,
                value: ele.modelName,
                isChecked,
              });
              modelResult.push(ele.modelId);
            }
          }
          this.setState({
            optionList,
            isShowListView: false,
            loaderIconVisible: false,
          });
          return;
        }
      }

      //  First prepare the object for this particular filter field
      let optionList = await this.prepareDataObj(
        this.state.orgNodeCaptions,
        filterEvt.field_name
      );
      optionList = _.uniqBy(optionList, "id");
      let validatedList = [];
      // If already any filter is assigned, then need to get the corresponding filter portion.
      if (
        this.state.groupID.length &&
        filterEvt.field_name !== "orgSmartTagsFilter"
      ) {
        await optionList.map(async (option) => {
          let { groupDynasty, groupID, isInitialValue } = this.state;
          if (option.level_id === isInitialValue) {
            validatedList = optionList;
          }
          if (groupID.includes(option.parent_group_id)) {
            validatedList.push(option);
          } else {
            for (let i = 0; i < groupDynasty.length; i++) {
              const grp = groupDynasty[i];
              let splitedGrp = grp ? grp.split(",") : [];
              let joinedText = splitedGrp.slice(0, option.level_id).join(",");
              if (joinedText === option.dynasty) {
                let isExst = await validatedList.filter(
                  (i) => i.cust_gp_id === option.cust_gp_id
                );
                if (!isExst.length) {
                  validatedList.push(option);
                }
              }
            }
          }
        });
      } else {
        validatedList = optionList;
      }
      this.setState({
        optionList: validatedList,
        isShowListView: false,
        loaderIconVisible: false,
      });
    }
  };

  // This function is used to close the filter already opened
  closeFilterChildViewHandler = () => {
    this.setState({
      isShowListView: true,
      filterViewName: "KC0055",
    });
  };

  // This function is trigged from child component to handle the checkbox
  checkBoxHandler = async (obj) => {
    let {
      filterModel,
      filterFieldName,
      groupID,
      groupDynasty,
      isInitialValue,
      isInitialBrandValue,
    } = this.state;
    if (!groupID.length) {
      isInitialValue = obj.level_id;
    }
    obj.field = obj.field || filterFieldName;
    obj.isChecked = !obj.isChecked;
    const index = filterModel.findIndex((e) => e.field_name === obj.field);
    console.log("dsd", obj);
    if (obj.isChecked) {
      if (obj?.cust_gp_id) {
        const { id, ID, value, field, cust_gp_id, dynasty, isChecked } = obj;
        filterModel[index].dataSource.push({
          id,
          ID,
          value,
          cust_gp_id,
          field,
          field_name: field,
          dynasty,
          isChecked,
        });
      } else {
        filterModel[index].dataSource.push(obj);
      }
    } else {
      let dataIndex = "";
      if (
        ["withinlocation", "location", "chain", "country", "customer"].includes(
          obj.field
        )
      ) {
        dataIndex = filterModel[index].dataSource.findIndex(
          (e) => e.cust_gp_id === obj.cust_gp_id
        );
      } else {
        dataIndex = filterModel[index].dataSource.findIndex(
          (e) => e.id === obj.id
        );
      }
      filterModel[index].dataSource.splice(dataIndex, 1);
    }
    if (isInitialBrandValue === "") {
      isInitialBrandValue = obj.field || filterFieldName;
    }
    if (
      ["withinlocation", "location", "chain", "country", "customer"].includes(
        obj.field
      )
    ) {
      if (!groupDynasty.includes(obj.dynasty)) {
        groupDynasty.push(obj.dynasty);
      } else {
        const groupIndex = groupDynasty.findIndex((i) => i === obj.dynasty);
        groupDynasty.splice(groupIndex, 1);
      }

      if (!groupID.includes(obj.cust_gp_id)) {
        groupID.push(obj.cust_gp_id);
      } else {
        const groupIndexs = groupID.findIndex((i) => i === obj.cust_gp_id);
        groupID.splice(groupIndexs, 1);
      }
    }
    this.setState({
      filterFieldName: obj.field,
      filterModel,
      groupDynasty,
      groupID,
      isInitialValue,
      isInitialBrandValue,
    });
  };

  // This function is used to reset the filter
  resetFilterListHandler = () => {
    this.props.resetSelectedFilterList(this.props.filterKey);
    let { filterModel } = this.state;
    for (let i = 0; i < filterModel.length; i++) {
      filterModel[i].dataSource = [];
    }
    this.setState({
      filterModel,
      groupID: [],
      groupDynasty: [],
      isInitialValue: "",
    });
    localStorage.removeItem("statusType");
  };

  // This function is used to handle the finish button
  applyFilter = (e) => {
    const filterModel = _.flatten(this.state.filterModel);
    localStorage.setItem(
      `${this.props.filterKey}`,
      JSON.stringify(filterModel)
    );
    const { history } = this.props;
    history.go();
  };

  // This function is used to check the checkbox is already checked or not using key and value
  getCheckedValue = async (key, value) => {
    let { filterModel } = this.state;
    const index = filterModel.findIndex((e) => e.field_name === key);
    if (
      filterModel[index] &&
      filterModel[index].dataSource &&
      filterModel[index].dataSource.length
    ) {
      for (let i = 0; i < filterModel[index].dataSource.length; i++) {
        const ele = filterModel[index].dataSource[i];
        if (
          [
            "withinlocation",
            "location",
            "chain",
            "country",
            "customer",
          ].includes(key)
        ) {
          if (ele.field === key && value === ele.cust_gp_id) {
            return ele.isChecked;
          }
        } else {
          if (ele.field === key && value === ele.id) {
            return ele.isChecked;
          }
        }
      }
    }
    return false;
  };

  // This function is used to prepare the object for the top level location, maas, shiptos, within location from org api
  prepareDataObj = async (orgValue = [], key) => {
    let result = [];
    let arrIDS;
    if (key === "orgSmartTagsFilter") {
      const fields = ["withinlocation", "location", "chain", "country"];
      let sendData = orgValue;

      for (const filter of this.state.filterModel) {
        if (
          fields.includes(filter.field_name) &&
          filter.dataSource.length > 0
        ) {
          sendData = filter;
          break; // Exit the loop once the first matching filter is found
        }
      }
      const orgTags = getOrgTags(orgValue, sendData);
      const result = [];
      for (const ele of orgTags) {
        const isChecked = await this.getCheckedValue(
          "orgSmartTagsFilter",
          ele.id
        );
        result.push({
          id: ele.id,
          type: "org",
          ID: ele.id,
          value: ele.tagName,
          ...ele,
          isChecked,
        });
      }
      return result;
    }

    // This is for reverse casecading
    let getOrgSmartTags = await this.state.filterModel.filter(
      (i) => i.field_name === "orgSmartTagsFilter"
    );
    arrIDS = getOrgSmartTags && getOrgSmartTags[0].dataSource.map((v) => v.id);

    for (const element of orgValue) {
      const { group_name, ...rest } = element;
      if (key === "customer") {
        let cgid = element.cust_gp_id;
        let isChecked = await this.getCheckedValue(key, cgid);
        result.push({
          id: cgid,
          ID: cgid,
          value: group_name,
          isChecked,
          ...rest,
        });
      }
      if (key !== "customer" && element["level2"]) {
        for (let j = 0; j < element["level2"].length; j++) {
          const { group_name, ...rest } = element["level2"][j];
          const tempArr = element["level2"][j];

          if (key === "country") {
            if (arrIDS.length > 0) {
              tempArr.isOrgTagExist &&
                tempArr.OrgTagDetails.some((tag) => {
                  if (arrIDS.includes(tag.id)) {
                    // This is for reverse casecading
                    let cgid = element["level2"][j].cust_gp_id;
                    this.getCheckedValue(key, cgid).then((isChecked) => {
                      result.push({
                        id: cgid,
                        ID: cgid,
                        value: group_name,
                        isChecked,
                        ...rest,
                      });
                    });
                  }
                });
            } else {
              let cgid = element["level2"][j].cust_gp_id;
              let isChecked = await this.getCheckedValue(key, cgid);
              result.push({
                id: cgid,
                ID: cgid,
                value: group_name,
                isChecked,
                ...rest,
              });
            }
          }
          if (
            key !== "customer" &&
            key !== "country" &&
            element["level2"][j]["level3"]
          ) {
            for (let k = 0; k < element["level2"][j]["level3"].length; k++) {
              const { group_name, ...rest } = element["level2"][j]["level3"][k];
              const tempArr = element["level2"][j]["level3"][k];

              if (key === "chain") {
                if (arrIDS.length > 0) {
                  tempArr.isOrgTagExist &&
                    tempArr.OrgTagDetails.some((tag) => {
                      if (arrIDS.includes(tag.id)) {
                        // This is for reverse casecading
                        let cgid = element["level2"][j]["level3"][k].cust_gp_id;
                        this.getCheckedValue(key, cgid).then((isChecked) => {
                          result.push({
                            id: cgid,
                            ID: cgid,
                            value: group_name,
                            isChecked,
                            ...rest,
                          });
                        });
                      }
                    });
                } else {
                  let cgid = element["level2"][j]["level3"][k].cust_gp_id;
                  let isChecked = await this.getCheckedValue(key, cgid);
                  result.push({
                    id: cgid,
                    ID: cgid,
                    value: group_name,
                    isChecked,
                    ...rest,
                  });
                }
              }
              if (
                key !== "customer" &&
                key !== "country" &&
                key !== "chain" &&
                element["level2"][j]["level3"][k]["level4"]
              ) {
                for (
                  let l = 0;
                  l < element["level2"][j]["level3"][k]["level4"].length;
                  l++
                ) {
                  const { group_name, ...rest } =
                    element["level2"][j]["level3"][k]["level4"][l];
                  const tempArr =
                    element["level2"][j]["level3"][k]["level4"][l];
                  if (key === "location") {
                    if (arrIDS.length > 0) {
                      tempArr.isOrgTagExist &&
                        tempArr.OrgTagDetails.some((tag) => {
                          if (arrIDS.includes(tag.id)) {
                            // This is for reverse casecading
                            let cgid =
                              element["level2"][j]["level3"][k]["level4"][l]
                                .cust_gp_id;
                            this.getCheckedValue(key, cgid).then(
                              (isChecked) => {
                                result.push({
                                  id: cgid,
                                  ID: cgid,
                                  value: group_name,
                                  isChecked,
                                  ...rest,
                                });
                              }
                            );
                          }
                        });
                    } else {
                      let cgid =
                        element["level2"][j]["level3"][k]["level4"][l]
                          .cust_gp_id;
                      let isChecked = await this.getCheckedValue(key, cgid);
                      result.push({
                        id: cgid,
                        ID: cgid,
                        value: group_name,
                        isChecked,
                        ...rest,
                      });
                    }
                  }
                  if (
                    key !== "customer" &&
                    key !== "country" &&
                    key !== "chain" &&
                    key !== "location" &&
                    element["level2"][j]["level3"][k]["level4"][l]["level5"]
                  ) {
                    for (
                      let h = 0;
                      h <
                      element["level2"][j]["level3"][k]["level4"][l]["level5"]
                        .length;
                      h++
                    ) {
                      const { group_name, ...rest } =
                        element["level2"][j]["level3"][k]["level4"][l][
                          "level5"
                        ][h];
                      const tempArr =
                        element["level2"][j]["level3"][k]["level4"][l][
                          "level5"
                        ][h];
                      if (key === "withinlocation") {
                        if (arrIDS.length > 0) {
                          tempArr.isOrgTagExist &&
                            tempArr.OrgTagDetails.some((tag) => {
                              if (arrIDS.includes(tag.id)) {
                                // This is for reverse casecading
                                let cgid =
                                  element["level2"][j]["level3"][k]["level4"][
                                    l
                                  ]["level5"][h].cust_gp_id;
                                this.getCheckedValue(key, cgid).then(
                                  (isChecked) => {
                                    result.push({
                                      id: cgid,
                                      ID: cgid,
                                      value: group_name,
                                      isChecked,
                                      ...rest,
                                    });
                                  }
                                );
                              }
                            });
                        } else {
                          let cgid =
                            element["level2"][j]["level3"][k]["level4"][l][
                              "level5"
                            ][h].cust_gp_id;
                          let isChecked = await this.getCheckedValue(key, cgid);
                          result.push({
                            id: cgid,
                            ID: cgid,
                            value: group_name,
                            isChecked,
                            ...rest,
                          });
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    return result;
  };

  render() {
    const { formatMessage } = this.props.intl;
    let {
      isShowListView,
      filterViewName,
      optionList,
      loaderIconVisible,
      filterModel,
    } = this.state;

    let visibleComponent = null;
    let backArrow = null;

    if (isShowListView) {
      visibleComponent = (
        <React.Fragment>
          <FilterList
            history={this.props.history}
            filterList={filterModel}
            clicked={this.choosenFilterViewHandler}
            removeitem={this.checkBoxHandler}
          />
          <div class="clearBtnDiv">
            <button
              onClick={() => this.resetFilterListHandler()}
              type="button"
              disabled={false}
              class="btn"
            >
              <FormattedMessage id="KC0067" />
            </button>
          </div>
        </React.Fragment>
      );
    } else {
      visibleComponent = (
        <FiltersChildComponents
          list={optionList}
          changed={this.checkBoxHandler}
        />
      );
      backArrow = (
        <button
          type="button"
          title={formatMessage({ id: "KC0989" })}
          class="backIcon btn"
          onClick={() => this.closeFilterChildViewHandler()}
        ></button>
      );
    }

    return (
      <React.Fragment>
        <div
          id="loader"
          className={`loadingProgress ${loaderIconVisible ? "showloader" : ""}`}
        >
          <div className="loadingProgressIn"></div>
        </div>
        <div>
          <div id="filterListDiv">
            <div class="filterHeader borderBtm">
              <ul>
                <li>{backArrow}</li>
                <li>
                  <FormattedMessage
                    id={
                      filterBaseMappingStringID[filterViewName]
                        ? filterBaseMappingStringID[filterViewName]
                        : filterViewName
                    }
                  />
                </li>
                <li>
                  <Link
                    to={this.props.history.location.pathname}
                    onClick={(e) => this.applyFilter()}
                    title={formatMessage({ id: "KC0056" })}
                  >
                    <FormattedMessage id="KC0056" />
                  </Link>
                </li>
              </ul>
            </div>
            <div
              class="filterListOuter"
              ref={(node1) => {
                this.pop1 = node1;
              }}
            >
              {visibleComponent}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    filterlistInformtion: state.filterModel,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ resetSelectedFilterList }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(FilterBase));
