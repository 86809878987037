import React, { Component } from 'react';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import {BRAND_ID} from '../../../utils/appConstants';
import { Link } from 'react-router-dom';
import http from '../../../service/httpService';
import { unitAPI } from '../../../service/api';
import { ModelFamilyBuilder } from '../../../utils/unitResponseBuilder';
import { orderBy } from 'lodash'
import { injectIntl,FormattedMessage } from 'react-intl';

class LibrarySelectDevice extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            sortColumn: { path: "MODEL_FAMILY_NAME", order: "asc", sort_color: "#fff" },
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
        }
        this.getModelFamilies = this.getModelFamilies.bind(this);
        this.onModelSelection = this.onModelSelection.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#f2f2f2";
        try {
            await this.getModelFamilies();
        } catch (error) {
            //Error Goes Here
        }
    }

    getModelFamilies = () => {
        this.setState({
            loaderIconVisible: true
        });

        return new Promise((resolve, reject) => {
            http.get(unitAPI.getModelFamilies,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'brandid': BRAND_ID
                    },
                    data: {}
                }).then(async response => {
                    let { data } = response;
                    let modelList = data && data.body ? data.body : [];
                    modelList = await modelList.filter(m => m.MODEL_FAMILY_ID !== 3 && m.MODEL_FAMILY_ID !== 4 && m.MODEL_FAMILY_ID !== 5 && m.MODEL_FAMILY_ID !== 38 && m.MODEL_FAMILY_ID !== 39);

                    if (localStorage.getItem("brandName") === "MERRYCHEF") {
                        modelList = modelList.filter(item => item.BRAND_ID === 1);
                    } else if (localStorage.getItem("brandName") === "FRYMASTER") {
                        modelList = modelList.filter(item => item.BRAND_ID === 3);
                    } else if (localStorage.getItem("brandName") === "GARLAND") {
                        modelList = modelList.filter(item => item.BRAND_ID === 9);
                    }
                    modelList = await ModelFamilyBuilder(modelList);
                    this.setState({
                        data: modelList,
                        loaderIconVisible: false
                    });
                    resolve(response);
                }).catch(err => {
                    reject(err);
                });
        });
    }

    onModelSelection = (item) => {
        localStorage.setItem("selectedFamilyID", item.MODEL_FAMILY_ID);
        localStorage.setItem("selectedbrandID", item.BRAND_ID);
        localStorage.setItem("brandName", item.BRAND_ID === 1 ? "MERRYCHEF" : item.BRAND_ID === 3 ? "FRYMASTER" : item.BRAND_ID === 9 ? "GARLAND" : "CONVOTHERM");
        if (item.BRAND_ID === 3) {
            this.props.history.push(`/frymasterRecipeEditor`);
        }else if(item.BRAND_ID === 9){
            this.props.history.push('/garlandRecipeEditor')
        }
        else {
            // this.props.history.push(`/recipeEditor`);
            // -- User Story 82573: MRCHF NRE - Cookbook Creation --//
            this.props.history.push(`/cookbookParameters`);
        }
    }

    formObj = (arr) =>{
        let arrReturn = [];
        let MODEL_FAMILY_NAME = [];
        let MODEL_FAMILY_ID = [];
        let obj = {};
        for (let index = 0; index < arr.length; index++) {
            const element = arr[index];
            MODEL_FAMILY_NAME.push(element.MODEL_FAMILY_NAME)
            MODEL_FAMILY_ID.push(element.MODEL_FAMILY_ID);
            obj = element;
        }
        if(arr && arr.length){
            obj['MODEL_FAMILY_ID'] =  MODEL_FAMILY_ID.join(',');
            obj['MODEL_FAMILY_NAME']=  MODEL_FAMILY_NAME.join(', ');
            arrReturn.push(obj);
        }
        return arrReturn;
    }

    render() {
        let { data: dataList, sortColumn, loaderIconVisible } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        const { formatMessage } = this.props.intl;

        if (dataList) {
            dataList = orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path].toString().toLowerCase()], [sortColumn.order]);
        }

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
					<div className="loadingProgressIn"></div>
				</div>

                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="adminBgHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0989' })} className="backIcon">&nbsp;</Link>
                                </li>
                                <li>
                                    <FormattedMessage id="KC1205"/>
					        </li>
                                <li>
                                    {/* <Link to="/recipeEditor" title="Finish">Finish</Link> */}
                                    {/* <a href="/recipeEditor" title="Finish">Finish</a> */}
                                </li>
                            </ul>
                        </div>
                        <div class="menuSelectDeviceTableOuter">
                            <div class="panel panel-default" id="menuSelectDeviceAccordion" role="tablist" aria-multiselectable="true">
                                {/* <!-- Header 01 --> */}
                                {localStorage.getItem("selectedbrandID") === "3" && <div class="menuSelectDeviceTable">
                                    <ul class="tBody">
                                        <li><a role="button" data-toggle="collapse" data-parent="#menuSelectDeviceAccordion" href="#menuSelectDvcAcc1" aria-expanded="true" aria-controls="menuSelectDvcAcc1" class="btn collapseExpand">&nbsp;</a></li>
                                        <li><img src={require("../../../images/logos/Frymaster_Color.svg").default} alt="" /></li>
                                    </ul>
                                </div>}
                                {/* <!-- Header 01 Content --> */}
                               {localStorage.getItem("selectedbrandID") === "3" && <div id="menuSelectDvcAcc1" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="menuSelectDvcAccH1">
                                    <div class="panel-body selectDeviceGrid">
                                        <ul>
                                            {dataList.map((item) => {
                                                return (
                                                    <li onClick={() => this.onModelSelection(item)}>
                                                        <div class="selectDeviceGridW">
                                                            <span><img alt={item.Brand_Alt} src={item.Brand_Logo} /></span>
                                                            <h5>{item.MODEL_FAMILY_NAME}</h5>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            )}
                                        </ul>
                                    </div>
                                </div>} 
                                {/* <!-- Header 02 --> */}
                                {localStorage.getItem("selectedbrandID") === "1" && <div class="menuSelectDeviceTable">
                                    <ul class="tBody">
                                        <li><a role="button" data-toggle="collapse" data-parent="#menuSelectDeviceAccordion" href="#menuSelectDvcAcc2" aria-expanded="true" aria-controls="menuSelectDvcAcc2" class="btn collapseExpand">&nbsp;</a></li>
                                        <li><img src={require("../../../images/logos/Merrychef_Color.svg").default} alt="" /></li>
                                    </ul>
                                </div>}
                                {/* <!-- Header 02 Content --> */}
                                {localStorage.getItem("selectedbrandID") === "1" && <div id="menuSelectDvcAcc2" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="menuSelectDvcAccH2">
                                    <div class="panel-body selectDeviceGrid">
                                        <ul>
                                            {dataList.map((item) => {
                                                return (
                                                    <li onClick={() => this.onModelSelection(item)}>
                                                        <div class="selectDeviceGridW">
                                                            <span><img alt={item.Brand_Alt} src={item.Brand_Logo} /></span>
                                                            <h5>{item.MODEL_FAMILY_NAME}</h5>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            )}
                                        </ul>
                                    </div>
                                </div>}

                                {/* <!-- Header 03 --> */}
                                {localStorage.getItem("selectedbrandID") === "9" && <div class="menuSelectDeviceTable">
                                    <ul class="tBody">
                                        <li><a role="button" data-toggle="collapse" data-parent="#menuSelectDeviceAccordion" href="#menuSelectDvcAcc1" aria-expanded="true" aria-controls="menuSelectDvcAcc1" class="btn collapseExpand">&nbsp;</a></li>
                                        <li><img src={require("../../../images/logos/Garland_Color.svg").default} alt="" /></li>
                                    </ul>
                                </div>}
                                {/* <!-- Header 03 Content --> */}
                               {localStorage.getItem("selectedbrandID") === "9" && <div id="menuSelectDvcAcc1" class="panel-collapse collapse show" role="tabpanel" aria-labelledby="menuSelectDvcAccH1">
                                    <div class="panel-body selectDeviceGrid">
                                        <ul>
                                            {this.formObj(dataList).map((item) => {
                                                return (
                                                    <li onClick={() => this.onModelSelection(item)}>
                                                        <div class="selectDeviceGridW">
                                                            <span><img alt={item.Brand_Alt} src={item.Brand_Logo} /></span>
                                                            <h5>{item.MODEL_FAMILY_NAME}</h5>
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            )}
                                        </ul>
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(LibrarySelectDevice)
