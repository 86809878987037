import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelList } from '../../../../../controls/modelList';
import ErrorMoal from '../../../../../modal/error-model';
import * as moment from 'moment';
import { FormattedMessage, injectIntl } from 'react-intl';
import { datePickerLocale } from '../../../../../../locale/constant';

class FrymasterMenuLimitedOffersTime extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            categoryList: [],
            error_modal: {
                open: false,
                message: ""
            },
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        const { item, timeOffer } = this.props.history.location.state;
        try {
            let libraryDetails = localStorage.getItem("libraryDetails");
            let libraryDetailsObj = JSON.parse(libraryDetails);

            let selectedMenu = [];
            if (timeOffer === "Scheduled") {
                selectedMenu = libraryDetailsObj.limitedTimeOffer.scheduled.filter(i => i.id === item.categoryId);
            }
            else if (timeOffer === "Unscheduled") {
                selectedMenu = libraryDetailsObj.limitedTimeOffer.unscheduled.filter(i => i.id === item.categoryId);
            }
            if (selectedMenu.length) {
                this.setState({
                    startDate: selectedMenu[0].startDate ? moment(selectedMenu[0].startDate, "MMMM DD, yyyy").toDate() : Date.now(),
                    endDate: selectedMenu[0].endDate ? moment(selectedMenu[0].endDate, "MMMM DD, yyyy").toDate() : Date.now(),
                    selectedModel: parseInt(localStorage.getItem("selectedProductID"))
                })
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        let { startDate, endDate } = this.state;
        let jsonSettingsFile = localStorage.getItem("libraryDetails");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);

        const { item, timeOffer } = this.props.history.location.state;

        if (!startDate || !endDate) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1409"
                }
            });
            return;
        }

        if (!ObjSettingsFile.limitedTimeOffer) {
            ObjSettingsFile.limitedTimeOffer = {}
        }

        if (timeOffer === "Scheduled") {
            const findIndex = ObjSettingsFile.limitedTimeOffer.scheduled.findIndex(i => i.id === item.categoryId);
            ObjSettingsFile.limitedTimeOffer.scheduled[findIndex]['startDate'] = moment(startDate).format('MMMM DD, yyyy') + "T00:00:00";
            ObjSettingsFile.limitedTimeOffer.scheduled[findIndex]['endDate'] = moment(endDate).format('MMMM DD, yyyy') + "T23:59:59";
        }

        if (timeOffer === "Unscheduled") {
            const findIndex = ObjSettingsFile.limitedTimeOffer.unscheduled.findIndex(i => i.id === item.categoryId);
            ObjSettingsFile.limitedTimeOffer.unscheduled[findIndex]['startDate'] = moment(startDate).format('MMMM DD, yyyy') + "T00:00:00";
            ObjSettingsFile.limitedTimeOffer.unscheduled[findIndex]['endDate'] = moment(endDate).format('MMMM DD, yyyy') + "T23:59:59";
        }

        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryDetails", strSettingsFile);
        this.props.history.push(`/frymasterMenuLimitedOffer`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterMenuLimitedOffer`);
    }

    handleOnSliderChange = (value, stateKey) => {
        this.setState({
            [stateKey]: value
        })
    }

    handleDateChange = (date) => {
        this.setState({
            startDate: date,
        });
    }
    handleEndDateChange = (date) => {
        this.setState({
            endDate: date,
        });
    }

    ResetDatepicker = (e) => {
        e.preventDefault();
        return false;
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");
        const { error_modal } = this.state;
        const { item } = this.props.history.location.state;

        return (
            <React.Fragment>
                {/* Frymaster Menu Auto Change Header Page */}
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelList brandid={brandid} isModelEditable={true} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Menu Auto Change Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")} alt=""/>
                            </div>

                            {/* Frymaster Menu Auto Change Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> {item.categoryName}
                                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">


                                                <div class="frymasterRCScdlForm">
                                                    <ul>
                                                        <li>
                                                            <label><FormattedMessage id="KC0695"/></label>
                                                            <DatePicker
                                                                locale={datePickerLocale}
                                                                disabledKeyboardNavigation
                                                                id="startDate"
                                                                onKeyDown={(e) => this.ResetDatepicker(e)}
                                                                autoComplete="off"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                selected={this.state.startDate}
                                                                placeholderText={formatMessage({ id: 'KC1979' })}
                                                                dateFormat={"MMMM dd, yyyy"}
                                                                onChange={this.handleDateChange}
                                                                minDate={new Date()}
                                                                className="form-control" />
                                                        </li>
                                                        <li>
                                                            <label><FormattedMessage id="KC0696"/></label>
                                                            <DatePicker
                                                                locale={datePickerLocale}
                                                                disabledKeyboardNavigation
                                                                id="endDate"
                                                                onKeyDown={(e) => this.ResetDatepicker(e)}
                                                                autoComplete="off"
                                                                showMonthDropdown
                                                                showYearDropdown
                                                                dropdownMode="select"
                                                                selected={this.state.endDate}
                                                                placeholderText={formatMessage({ id: 'KC1979' })}
                                                                dateFormat={"MMMM dd, yyyy"}
                                                                onChange={this.handleEndDateChange}
                                                                minDate={this.state.startDate ? this.state.startDate : new Date()}
                                                                className="form-control" />
                                                        </li>
                                                    </ul>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => this.onConfirmButtonClick()} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Menu Auto Change Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} message={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterMenuLimitedOffersTime)
