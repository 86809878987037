import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, MenuUploadAPI } from '../../../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../../../service/httpService';
import _ from 'lodash';
import { setUserDataModel } from '../../../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { CUSTOMER_ID, BASECOUNTRY_ID, BRAND_ID_VALUE } from '../../../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import { injectIntl } from 'react-intl';
import ViewTopology from '../../../../../components/controls/viewTopology';
var recipeJSON = require('../../Templates/Garland/GarlandRecipeJSON');
var beautify = require("json-beautify");

const cookingMethodologyMapping = {
    'Steel':0,
    'Standard':1,
    'Steel / Water': 2,
    'LRS / Water': 3
}

class GarlandLibraryToTopology extends Component {

    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/libraryList`);
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    componentWillMount = () => {
        let { previouspath } = this.props.location;
        let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
        this.setState({
            previouspath: previouspath,
            recipeName: libraryDetails.recipeName ? libraryDetails.recipeName : "",
            imageData: libraryDetails.imageData ? libraryDetails.imageData : "",
            imageThumbnailb64: localStorage.getItem("libraryImage"),
            isEdit: libraryDetails.isEdit ? libraryDetails.isEdit : false,
            isImageChanged: libraryDetails.isImageChanged ? libraryDetails.isImageChanged : false
        });
    }

    showError = (err) => {
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_400 ? data.KC_ERR_400 : data.message ? data.message  : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.KC_ERR_500 ? data.KC_ERR_500 : data.message ? data.message  : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        this.updateDimensions();
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        this.props.history.push({
            pathname: "/garlandRecipeCreationSteps",
            previouspath: "/garlandLibraryToTopology"
        });
    }

    f2c = (fahrenheit) => {
        return Math.round((fahrenheit) / 1.8);
    }

    submitAddLibrary = (e, _customId) => {
        e.preventDefault();
        let _this = this;
        let httpMethod;

        _this.setState({
            loaderIconVisible: true
        })

        let brandName = localStorage.getItem("brandName");
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        let loggedinUser = loginUser.username;

        http({
            method: "GET",
            url: UserProfile.getUserProfileDetails,
            headers: {
                'customer_id': CUSTOMER_ID,
                'email': loggedinUser,
                'Content-Type': 'application/json',
                'base_country': localStorage.getItem("basecountry"),
                'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
            }, data: {}
        }).then(response => {
            let libraryId;
            let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
            libraryId = libraryDetails.recipeID;
            if (_this.state.isEdit) {
                httpMethod = "PUT";
            } else {
                httpMethod = "POST";
            }
            let imageS3Url = libraryDetails.imageData ? libraryDetails.imageData.imageS3Url : "";
            console.log("libraryDetails", libraryDetails)
            let jsonTemplateFile = recipeJSON;
            let jsonTemplateObj = jsonTemplateFile.default;
            let cookingSteps = libraryDetails.cookingSteps ? libraryDetails.cookingSteps : []
            let cookingStepsReducer = cookingSteps.map(({ isFlat, ...rest }) => rest);

            jsonTemplateObj.cookingSteps = cookingStepsReducer;
            jsonTemplateObj.cooking_methodology = libraryDetails.cooking_methodology ?  cookingMethodologyMapping[libraryDetails.cooking_methodology] : 0;
            jsonTemplateObj.icon = libraryDetails.icon ? libraryDetails.icon : "";
            jsonTemplateObj.recipeID = libraryId || "";
            jsonTemplateObj.recipeName = libraryDetails.recipeName ? libraryDetails.recipeName : "";
            jsonTemplateObj.too_cold_limit = libraryDetails.too_cold_limit && libraryDetails.too_cold_limit !== "OFF" ? libraryDetails.unit === "°F" ? Number(this.f2c(libraryDetails.too_cold_limit)) * 10: Number(libraryDetails.too_cold_limit) * 10 : 0;
            jsonTemplateObj.too_hot_limit = libraryDetails.too_hot_limit && libraryDetails.too_hot_limit !== "OFF" ? libraryDetails.unit === "°F" ? Number(this.f2c(libraryDetails.too_hot_limit)) * 10 : Number(libraryDetails.too_hot_limit) * 10 : 0;

            let imageFileSize = libraryDetails.imageData.imagefileSize ? libraryDetails.imageData.imagefileSize : 0;

            // convert JSON object to beautify String
            var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
            // read json string to Buffer
            const jsonBuffer = Buffer.from(jsonStr);
            console.log("jsonTemplateObj", jsonTemplateObj)

            let fileSize = Buffer.byteLength(jsonBuffer);
            fileSize = (fileSize / 1048576);
            const temp_units = {
                "unit_cooking_method": libraryDetails.unit ? libraryDetails.unit : "°C",
                "unit_cooking_steps": libraryDetails.steps && libraryDetails.steps.length ? libraryDetails.steps[0].unit : "°C"
            }

            let newJsonObj = jsonTemplateObj;
            newJsonObj['cookingSteps'] = libraryDetails.cookingSteps ? libraryDetails.cookingSteps : [];

            let libraryPostData = {
                "customerId": CUSTOMER_ID,
                "brandId": BRAND_ID_VALUE[brandName],
                "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                "libraryId": libraryId,
                "libraryName": _this.state.recipeName,
                "libraryFileName": libraryId,
                "libraryFileFormat": "json",
                "libraryFileSize": (fileSize + parseFloat(imageFileSize ? imageFileSize : 0)).toFixed(2),
                "libraryS3Url": "",
                "type": 'Recipe',
                "createdUser": response.data.name + ' (' + loggedinUser + ')',
                "crc": libraryDetails.imageData.crc,
                "baseCountry": BASECOUNTRY_ID,
                "imageFileName": libraryDetails && libraryDetails.icon ? libraryDetails.icon + '.png' : "",
                "imageFileFormat": 'png',
                "imageFileSize": imageFileSize,
                "imageS3Url": imageS3Url,
                "cgids": _customId,
                "json": JSON.stringify(jsonTemplateObj),
                "libraryGuid": libraryId,
                "temp_units" : JSON.stringify(temp_units)
            };

            if (_this.state.isEdit) {
                delete libraryPostData.customerId;
                delete libraryPostData.createdUser;
                libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
            }

            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey': '',
                'jsonfilekey': libraryId+'.json',
                'audiofilekey': '',
                'isthumbnail': false
            }

            uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(response => {
                _this.setState({
                    loaderIconVisible: false,
                    success_modal: {
                        open: true,
                        message: (httpMethod === "PUT" && libraryDetails.initialMethod === "Edit") ? "Recipe updated successfully." : "Recipe saved successfully."
                    },
                });
                localStorage.removeItem('garlandRecipe')
            }).catch(err => {
                localStorage.removeItem('garlandRecipe')
                _this.showError(err);
            });
        }).catch(err => {
            _this.setState({
                loaderIconVisible: false
            })
            console.log('catch exception occured');
        });

    }

    changeLoadingStatus = (value) => {
        this.setState({ loaderIconVisible: value })
    }

    render() {
        let { success_modal, error_modal } = this.state;
        const { loaderIconVisible } = this.state;
        let libraryDetails = JSON.parse(localStorage.getItem("garlandRecipe"));
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology
                    title={`KC1235`}
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    onBackClickHandler={this.onBackClickHandler}
                    modelFamilyIds={localStorage.getItem("selectedFamilyID")}
                    preSelect={libraryDetails.isEdit ? true : false}
                    restrictUnitSelect={true} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(GarlandLibraryToTopology));