import http from '../service/httpService'
import { UnitInfo } from '../service/api';
import store from '../store'
import { setCurrentUnit } from '../actions/organizationentity/entityactions'
import { setunitBrandId } from './appConstants'
import { UnitBuilder } from './unitResponseBuilder'
import { ungzip } from 'pako';


export const updateDimensions = ($)=>{
  $("body").removeClass("loginBg");
  $("body").removeClass("deviceRegtrnBg");
  var windowHeight = $(window).height();
  var pageTabHeader = $(".pageTabHeader").height();
  var mediaHeader = $(".mediaHeader").height();
  var pageWrapperHeight = ((windowHeight) - (pageTabHeader)) - 114;
  var mediaPageWrapperHeight = ((windowHeight) - (mediaHeader)) - 155;
  var gidviewListHeight = ((windowHeight) - 440);
  $(".setMinHeight01").css("min-height", pageWrapperHeight);
  $(".mediaTableListForm").css("min-height", mediaPageWrapperHeight);
  $(".allLocationsGrid").css("min-height", gidviewListHeight);
  document.body.style.backgroundColor = "#000"; 
}

const getExcludeProductDetails = async (brandid, customerId) => {		
  const response = await http.get(UnitInfo.excludeProduct,
    {
      headers: {
        'Content-Type': 'application/json',					
        'cid': customerId,
        'brandid': brandid,					
      },
      data: {}
    })
  let excludeProduct = response.data && response.data[0]
  let checklength = excludeProduct && excludeProduct.excludedProductList && excludeProduct.excludedProductList.length ? excludeProduct.excludedProductList.length :0;

  localStorage.setItem('excludedProductList', checklength>0?true:false); 		
}

function decompressData(base64) {
  try {
      const buffer = Buffer.from(base64, "base64");
      const bufferArr = ungzip(buffer);
      const json = new TextDecoder("utf8").decode(bufferArr);
      return JSON.parse(json);
  } catch (err) {
      console.error(err);
      return null;
  }
}

export default (unitId, unitBrandId, push) => {
    const customerId = localStorage.getItem("customerId");
    const setcustGroupIDList = localStorage.getItem("custGroupID");
    
    if(unitBrandId !== null){
      http({
        method: "GET",
        url: `${UnitInfo.list}`,	
        headers: {			
          'Content-Type': 'application/json',
          'cgid': setcustGroupIDList,
          'cid': customerId ? customerId : 'NA',
          'brandid': unitBrandId
        },data:{},
        params: {
          compress: true
        }
      }).then(response => {
        let { units } = response.data;
        if (response.data.compressed) {
          units = decompressData(units) || [];
        }
        units = UnitBuilder(units);
        const csvUnitData = units.filter(each => {
          return each.UNITID === unitId
        })
        console.log(csvUnitData[0])
        console.log("======redirecting to unit=======", csvUnitData[0])
        if(typeof csvUnitData[0] === 'undefined'){
          return false
        }
        const item = csvUnitData[0];
        store.dispatch(setCurrentUnit(item));
        localStorage.setItem('unitID', item.UNITID);
        localStorage.setItem("brandName", item.BRAND_NAME);
        localStorage.setItem('unitBrandId', item.BRAND_ID); 
        localStorage.setItem('modelName', item.PRODUCT_NAME); 
        setunitBrandId(item.BRAND_ID? item.BRAND_ID : "", item.UNITID? item.UNITID : "", item.PRODUCT_NAME? item.PRODUCT_NAME : "");
        getExcludeProductDetails(item.BRAND_ID, customerId);      
      }).then((response)=>{
        if(response === false){
          push('/un-authorized');
        }else{
          push('/unitDashboard')
        }
      })
    }
  return;
}