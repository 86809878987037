import $ from 'jquery';
import _ from 'lodash';
import React, { Component } from 'react';
import { DeviceRegistration } from '../../service/api';
import http from '../../service/httpService';
import ErrorMoal from '../modal/error-model';
import LanguagePreferenceCommon from '../../components/languageDropdown/LanguagePreferenceCommon';
import 'malihu-custom-scrollbar-plugin';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
import TermsAndCondition from './termsAndCondition';
import { FormattedMessage,injectIntl } from 'react-intl';
import { connect } from 'react-redux';

class SubscriptionPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: true,
            features: [],
            planDetails: [],
            termsAndCondition: false,
            validatedResponse: {},
            tierSelected: [],
            error_modal: {
                open: false,
                message: "",
                isHtml: false
            },
            initialText: 'Basic'
        }
    }

    updateDimensions = () => {
        $('.mCustomScrollbar').mCustomScrollbar();

        var windowHeight = $(window).height();

        var subscriptionListH = $(".subscriptionList .tHead").height();
        var subscriptionFooterF = $(".subscriptionList.subscriptionFooter").height();
        var subscriptionTableSrlBarH = ((windowHeight) - (subscriptionFooterF + subscriptionListH)) - 120;
        $(".subscriptionTableSrlBar").css("height", subscriptionTableSrlBarH);
        // For term and condition height
        var termsConditionsDHeight = ((windowHeight) - 100);
        $(".termsConditionsD").css("height", termsConditionsDHeight);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            },
        })
        this.props.history.push('/register-unit')
    }

    async componentDidMount() {
        $('body').removeClass("deviceRegtrnBg");
        $('body').removeClass("loginBg");
        $('body').addClass("subscriptionBg");
        await this.validateDevice();
        await this.getPlanTier();
        this.updateDimensions();
    }

    componentDidUpdate = async (prevState) => {
        let { locale } = this.props;
        if (prevState.locale !== locale){
            await this.getPlanTier();
        }
    }

    setPlanDetails = async (plan) => {
        let planDetails = [];
        for (let index = 0; index < plan.length; index++) {
            const { price, isFreeTrial, pricingTiers, ...element } = plan[index];
            const isBasic = (price === '0' || price === 0) && !isFreeTrial && !pricingTiers.length;
            const isPro = !isBasic && pricingTiers.length && !isFreeTrial;

            if (isPro && (price === '0' || price === 0)) {
                planDetails.push({ price: pricingTiers[0].pricePerUnit, isFreeTrial, pricingTiers, ...element })
            } else {
                planDetails.push({ price: this.convertToDollor(price), isFreeTrial, pricingTiers, ...element })
            }
        }
        this.setState({ planDetails });
    }

    getPlanTier = async () => {
        const { validatedResponse } = this.state;
        let url = DeviceRegistration.getPlanTierDetails;

        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'pricingcomponentid': validatedResponse.COMPONENT_ID || '',
                'trialmonths': validatedResponse.TRIAL_MONTHS
            },
            data: {}
        }).then(async response => {
            if (response && response.data && response.data.length) {
                await this.setPlanDetails(response.data);
                await this.getPlanFeatures();
            } else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: response && response.data && response.data.body && response.data.body.message ? response.data.body.message : "Something went wrong!!! Please try again"
                    }
                })
            }
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : "Something went wrong!!! Please try again"
                }
            })
        });
    }

    getPlanFeatures = async () => {
        this.setState({ loaderIconVisible: true});
        const { validatedResponse } = this.state;
        let url = DeviceRegistration.getPlanDetailsForSubscription;
        const { locale } = this.props;
        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',                
                "language_code": locale ? locale  : "en-US"
            },
            data: {}
        }).then(async response => {
            if (response && response.data && response.data.length) {
                this.processJSON(response.data);
            } else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: response && response.data && response.data.body && response.data.body.message ? response.data.body.message : "Something went wrong!!! Please try again"
                    }
                })
            }
        }).catch(err => {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: err && err.response && err.response.data && err.response.data.message ? err.response.data.message : "Something went wrong!!! Please try again"
                }
            })
        });
    }

    validateDevice = () => {
        return new Promise((res, rej) => {
            const jwtToken = localStorage.getItem('authorizeJwtToken');
            this.setState({ loaderIconVisible: true });
            let url = DeviceRegistration.jwtTokenAuth;

            http.get(url, {
                headers: {
                    'Content-Type': 'application/json',
                    'token': jwtToken,
                },
                data: {}
            }).then(async response => {
                if (response && response.data) {
                    if (response.data.httpCode === 200) {
                        if (response.data.body && response.data.body.length) {
                            await this.setState({ validatedResponse: response.data.body[0] });
                            res(true);
                        }
                    } else {
                        this.props.history.push('/register-unit');
                        res(false);
                    }
                }
            }).catch(err => {
                this.props.history.push('/register-unit');
                res(false);
            });
        })
    }

    processJSON = (value) => {
        let features = [];
        for (let i = 0; i < value.length; i++) {
            const ele = value[i];
            features.push({ isHeader: true, ...ele });
            if (ele.featuresList && ele.featuresList.length) {
                for (let j = 0; j < ele.featuresList.length; j++) {
                    const eleValue = ele.featuresList[j];
                    features.push({ isHeader: false, ...eleValue });
                }
            }
        }
        this.setState({ features, loaderIconVisible: false })
    }
    getBrowser = () => {
        const userAgent = navigator.userAgent;
        let browser = "unkown";
        // Detect browser name
        browser = (/ucbrowser/i).test(userAgent) ? 'UCBrowser' : browser;
        browser = (/edg/i).test(userAgent) ? 'Edge' : browser;
        browser = (/googlebot/i).test(userAgent) ? 'GoogleBot' : browser;
        browser = (/chromium/i).test(userAgent) ? 'Chromium' : browser;
        browser = (/firefox|fxios/i).test(userAgent) && !(/seamonkey/i).test(userAgent) ? 'Firefox' : browser;
        browser = (/; msie|trident/i).test(userAgent) && !(/ucbrowser/i).test(userAgent) ? 'IE' : browser;
        browser = (/chrome|crios/i).test(userAgent) && !(/opr|opera|chromium|edg|ucbrowser|googlebot/i).test(userAgent) ? 'Chrome' : browser;;
        browser = (/safari/i).test(userAgent) && !(/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox/i).test(userAgent) ? 'Safari' : browser;
        browser = (/opr|opera/i).test(userAgent) ? 'Opera' : browser;

        // detect browser version
        switch (browser) {
            case 'UCBrowser': return `${browser}/${this.browserVersion(userAgent, /(ucbrowser)\/([\d\.]+)/i)}`;
            case 'Edge': return `${browser}/${this.browserVersion(userAgent, /(edge|edga|edgios|edg)\/([\d\.]+)/i)}`;
            case 'GoogleBot': return `${browser}/${this.browserVersion(userAgent, /(googlebot)\/([\d\.]+)/i)}`;
            case 'Chromium': return `${browser}/${this.browserVersion(userAgent, /(chromium)\/([\d\.]+)/i)}`;
            case 'Firefox': return `${browser}/${this.browserVersion(userAgent, /(firefox|fxios)\/([\d\.]+)/i)}`;
            case 'Chrome': return `${browser}/${this.browserVersion(userAgent, /(chrome|crios)\/([\d\.]+)/i)}`;
            case 'Safari': return `${browser}/${this.browserVersion(userAgent, /(safari)\/([\d\.]+)/i)}`;
            case 'Opera': return `${browser}/${this.browserVersion(userAgent, /(opera|opr)\/([\d\.]+)/i)}`;
            case 'IE': const version = this.browserVersion(userAgent, /(trident)\/([\d\.]+)/i);
                // IE version is mapped using trident version 
                // IE/8.0 = Trident/4.0, IE/9.0 = Trident/5.0
                return version ? `${browser}/${parseFloat(version) + 4.0}` : `${browser}/7.0`;
            default: return `unknown/0.0.0.0`;
        }
    }

    browserVersion = (userAgent, regex) => {
        return userAgent.match(regex) ? userAgent.match(regex)[2] : null;
    }

    updateSelfsignup = () => {
        return new Promise(async (res, rej) => {
            const { validatedResponse } = this.state;
            const browserInfo = await this.getBrowser();
            const OS = navigator.platform;
            const browserLanguage = navigator.language;

            const client_info = {
                'browser': browserInfo,
                OS,
                browserLanguage

            }
            http({
                method: "PUT",
                url: DeviceRegistration.updateSelfSignipSession,
                data: {
                    'session_uuid': validatedResponse.session_uuid || '',
                    'signup_stage': 'PLAN_SELECTED',
                    client_info
                },
            }).then(async response => {
                res(true);
            }).catch(err => {
                res(false);
            });
        })
    }

    choosePlan = async (plan) => {
        if (await this.validateDevice()) {
            await this.updateSelfsignup();
            this.props.history.push('/register-customer?value=' + btoa(JSON.stringify(plan)))
        } else {
            this.props.history.push('/register-unit')
        }
    }

    toggle = (e) => {
        $(this).find("span").text(function (i, text) {
            return text === "Basic" ? "Pro" : "Basic";
        })
        $(".subscriptionList").toggleClass("basicPlanSelected");
        $(this).toggleClass("basicPlanActive");
        this.setState((prevState) => ({ initialText: prevState.initialText === "Basic" ? "Pro" : "Basic" }))
    }

    onClickAgree = () => {
        this.setState({
            termsAndCondition: true,
        });
    };

    convertToDollor = (input) => {
        return input === 0 || input === '0' ? input : parseInt(input, 10) / 100;
    }

    render() {
        const { loaderIconVisible, features, planDetails, termsAndCondition, tierSelected, error_modal } = this.state;

        const isFreeTrialAvailable = planDetails && planDetails.length ? planDetails.filter((i) => i.isFreeTrial === true) : [];

        return (
            <div id="adminWrapper">
                <div className="toggleTransparent"></div>
                <div className="sideNavToggleTransparent"></div>
                <div className="userToggleTransparent"></div>
                <div id="page-loader" className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <nav className="navbar fixed-top navbar-expand-lg navbar-dark bgAdmin">
                    <a className="navbar-brand welbiltLogo" title="Welbilt - Bringing innovation to the table" >&nbsp;</a>
                    <div id="login-pre">
						<LanguagePreferenceCommon/>
					</div>
                </nav>
                <div id="page-content-wrapper">
                    <div className="subscriptionWrapper">
                        <button className={this.state.initialText === "Basic" ? "btn planToggleBtn" : "btn planToggleBtn basicPlanActive"} onClick={() => this.toggle()} type="button"><span>{this.state.initialText}</span></button>
                        <form className="subscriptionForm">
                            <div className="subscriptionList subscriptionH">
                                <ul className="tHead">
                                    {features && features.length && planDetails && planDetails.length > 0 ? <li><FormattedMessage id='KC0957'/></li> : null}
                                    {features && features.length && planDetails && planDetails.length > 0 ? planDetails.map((plan, index) => {
                                        const isBasic = (plan.price === '0' || plan.price === 0) && !plan.isFreeTrial && !plan.pricingTiers.length;
                                        return (<React.Fragment key={index}>
                                            <li>
                                            {/* <div className={isBasic ? "subscriptionPlanBasic" : "subscriptionPlanPro"} > */}
                                            <div className={"subscriptionPlanPro"} >
                                                    {/* {plan.isFreeTrial ? <span className="freeTrialIcon"></span> : null} */}
                                                    <span className="freeTrialIcon"></span>
                                                    {/* <h2>{plan.plan_name || `KC SAAS Pro`}</h2> */}
                                                    <h2>{`KC SAAS Pro`}</h2>
                                                    {isBasic ? <h4><FormattedMessage id="KC2893"/></h4> : plan.isFreeTrial ? <h4/> : <h4 className="freeTrialR" style={plan.pricingTiers.length ? { textDecoration: 'none' } : {}}>${plan.price}<span>/mo &amp; Unit</span></h4>}
                                                    {!plan.isFreeTrial && plan.pricingTiers && plan.pricingTiers.length ? <button className="btn viewPrice" data-toggle="modal" data-target="#proPlanModal" onClick={() => this.setState({ tierSelected: plan.pricingTiers })} type="button">View Pricing Tiers</button> : null}
                                                </div>
                                            </li>
                                            {index === 0 ? <li>&nbsp;</li> : null}
                                        </React.Fragment>)
                                    }) : null}


                                </ul>
                            </div>
                            <div className="subscriptionTableSrlBar mCustomScrollbar">
                                <div className="subscriptionList">
                                    {features && features.length ? features.map((feature, index) => {
                                        return (<ul title={feature.hover_text || ""} key={index} className={feature.isHeader ? "tTitle" : "tBody"}>
                                            {feature.isHeader ? <li>{feature.section}</li> : <li><span>{feature.feature_name}</span></li>}
                                            {planDetails && planDetails.length ? planDetails.map((plan, planIndex) => {
                                                return (
                                                    <React.Fragment key={planIndex}>
                                                        {feature.isHeader ? <li>&nbsp;</li> : <li><span className="imageSpan">
                                                            <img src={feature.plans.includes(plan.plan_name) ? require('../../images/icons/confirmGreen.svg').default : require('../../images/icons/crossX.png')} alt="Available" title="Available" />
                                                        </span></li>}
                                                        {planIndex === 0 ? <li>&nbsp;</li> : null}
                                                    </React.Fragment>
                                                )
                                            }) : null}
                                        </ul>)
                                    }) : null}
                                </div>
                            </div>
                            <div className="subscriptionList subscriptionFooter">
                                <ul>
                                    {features && features.length && planDetails && planDetails.length > 0 ? <li>
                                        <div className="customCheckbox customCheckboxWlabel"/>                                        
                                        <div className="termsAndC"/>
                                    </li> : null}
                                    {features && features.length && planDetails && planDetails.length ? planDetails.map((plan, planIndex) => {
                                        const isBasic = (plan.price === '0' || plan.price === 0) && !plan.isFreeTrial && !plan.pricingTiers.length;
                                        return (
                                            <React.Fragment key={planIndex}>
                                                <li>
                                                    <button className="btn chooseProBtn" disabled={                                                        
                                                            isFreeTrialAvailable.length && isBasic ? true : false
                                                            } onClick={() => this.choosePlan(plan)} type="button"><FormattedMessage id='KC2539'/></button>
                                                </li>
                                                {planIndex === 0 ? <li>&nbsp;</li> : null}
                                            </React.Fragment>
                                        )
                                    }) : null}
                                </ul>

                            </div>
                            {features && features.length && planDetails && planDetails.length > 0 ? <div className="subscriptionNotesDiv">* <FormattedMessage id='KC2538'/></div> : null}
                        </form>

                    </div>

                </div>
                {/* <!-- Pro Plan Modal --> */}
                <div className="modal fade subscriptionModal" data-keyboard="true" data-backdrop="static" id="proPlanModal" tabindex="-1" role="dialog" aria-labelledby="subscriptionModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header modal-header-danger">
                                Pricing Tier Information <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                            </div>
                            <div className="modal-body">
                                <div className="subscriptionDiv">
                                    <p>When selecting this subscription plan the following prices per unit will be applied, based in the number of devices registered in your KitchenConnect account.</p>
                                    <div className="subscriptionPlanList">
                                        <ul className="tHead">
                                            <li>No. of Devices</li>
                                            <li>Price Per Unit</li>
                                        </ul>
                                        {tierSelected && tierSelected.length ? tierSelected.map((tier, index) => {
                                            return (
                                                <ul className="tBody">
                                                    <li>{tier.noOfDevice}</li>
                                                    <li>${tier.pricePerUnit}</li>
                                                </ul>
                                            )
                                        }) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary confirmationOKBtn" data-dismiss="modal" type="button">OK</button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Pro Plan --> 
	<!-- Report Details Modal --> */}
                <div className="modal fade termsConditionsModal" data-keyboard="true" data-backdrop="static" id="termsAndConditionsModal" tabindex="-1" role="dialog" aria-labelledby="reportDetailsModalLabel" style={{ display: 'none' }} aria-hidden="true">
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header modal-header-danger">
                                Terms and Conditions <button type="button" className="close" data-dismiss="modal" aria-label="Close">&nbsp;</button>
                            </div>
                            <div className="modal-body">
                                <div className="termsConditionsD" style={{ height: "253px;" }}>
                                    <TermsAndCondition />
                                    <button className="btn btn-secondary termsConditionAgreeBtn" onClick={() => this.onClickAgree()} data-dismiss="modal" type="button">I Agree</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Report Details Modal -->  */}
                <ErrorMoal open={error_modal.open} message={error_modal.message} onCloseModel={this.closeModel} isHtml={error_modal.isHtml} />
            </div>
        )
    }
};

function mapStateToProps(state) {
	return {
		locale: state.localeInfo.locale,
	}
};

export default connect(mapStateToProps)(injectIntl(SubscriptionPlan));