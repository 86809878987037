import { useState } from "react"
import { useIdleTimer } from "react-idle-timer"
import { userIdleDetails } from '../../utils/appConstants'
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ onIdle, onLogout, onDismissal }) => {
    const [isIdle, setIdle] = useState(false);

    const idleTimer = useIdleTimer({
        timeout: userIdleDetails.userIdleTime,
        promptTimeout: userIdleDetails.userPromptTimeout,
        onPrompt: onIdle,
        onIdle: onLogout,
        debounce: 500,
        onAction: onDismissal,
        onActive: onDismissal,
    })
    return {
        isIdle,
        setIdle,
        idleTimer
    }
}
export default useIdleTimeout;