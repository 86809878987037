import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MenuUploadAPI } from '../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { CUSTOMER_ID } from '../../../utils/appConstants';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewTopology from '../../../components/controls/viewTopology';
import { injectIntl } from 'react-intl';

class LibraryUploadTopology extends Component {
    onSuccessCloseModal = () => {
        this.props.history.push(`/libraryList`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push(`/libraryList`);
    }

    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            jsonBuffer: ''
        };
        this.submitAddLibrary = this.submitAddLibrary.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackClickHandler = this.onBackClickHandler.bind(this);
    }

    showError = (err) => {
        let { response, stringID } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: stringID ? stringID : data.KC_ERR_400 ? data.KC_ERR_400 : ""
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: stringID ? stringID : data.KC_ERR_500 ? data.KC_ERR_500 : ""
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1083"
                }
            });
        }
    }

    componentWillMount() {
        let { srcfile, previouspath, uploadOption, upload_filename, description, selectedModelFamilyID } = this.props.location;
		this.setState({
			srcfile: srcfile,
			previouspath: previouspath,
			uploadOption: uploadOption,
            upload_filename: upload_filename,
            description: description,
            selectedModelFamilyID: selectedModelFamilyID
		});
    }

    componentDidMount() {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        window.addEventListener('resize', this.updateDimensions);
    }

    updateDimensions() {
        var windowHeight = $(window).height();
        var assignOrgHeader = $(".assignOrgHeader").height();
        var collapseExpandHeader = $(".collapseExpandHeader").height();
        var selectedUnitH = $(".selectedUnitHeader").height();
        var assignOrgTreeWrprH = ((windowHeight) - (assignOrgHeader + collapseExpandHeader + selectedUnitH)) - 110;
        $(".assignOrgTreeOuter").css("height", assignOrgTreeWrprH);
    }

    onBackClickHandler = () => {
        const {srcfile, uploadOption, upload_filename, description, selectedModelFamilyID} = this.state;
        this.props.history.push({
            pathname: "/uploadSelectDevice",
            srcfile: srcfile,
            uploadOption: uploadOption,
            upload_filename: upload_filename,
            description: description,
            selectedModelFamilyID: selectedModelFamilyID,
            previouspath: "/libraryUploadTopology"
        });
    }

    submitAddLibrary = async(e,_customId) => {
        e.preventDefault();
        let _this = this;
		let uploadedfile = this.state.srcfile;
		if ((uploadedfile === undefined) || (uploadedfile === "")) {
			this.props.history.push(`/libraryList`);
		} else {
            this.setState({ loaderIconVisible: true });
            let fileName = uploadedfile.name;
			fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '');
			let fileextSplit = fileName.split('.');
			let fileNamewithOutExt = fileextSplit[0];
			let libraryId = fileNamewithOutExt + '_' + Date.now();
            libraryId = libraryId.replace(/\s+/g, '');
			let fileSize = uploadedfile.size;
			fileSize = (fileSize / 1024).toFixed(2);
			let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
			let loggedinUser = loginUser.username;
			
            let {uploadOption, upload_filename, description} = this.state;
            let option = "";
            if (uploadOption === "Package") {
                option = ["CookBook","Settings"];
            }
            else {
                option = [uploadOption];
            }

			let postData = {
				"customer_id": CUSTOMER_ID,
				"modelFamilyIds": localStorage.getItem("selectedFamilyID"),
				"BrandId": localStorage.getItem("selectedbrandID"),
				"mediaType": option,
				"FileName": libraryId + '.zip',
				"country": localStorage.getItem("basecountry"),
				"cgid": _customId ? _customId.join(',') : "",
				"TriggeredBy": loggedinUser,
                "uploadtype": "Upload",
                "name": upload_filename,
                "description": description
			};
			
            this.uploadMenuToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, postData, uploadedfile, libraryId).then(response => {
                this.props.history.push(`/libraryList`);
			}).catch(err => {
				_this.showError(err);
                _this.setState({ loaderIconVisible: false });
			});
		}
    }

    uploadMenuToCloud(url, postData, uploadedfile, libraryId) {
        return new Promise((resolve, reject) => {
            let headerpresigned = {
                'Content-Type': 'application/json',
                'customerid': CUSTOMER_ID,
                'imagefilekey': "",
                'jsonfilekey': libraryId + '.zip',
                'audiofilekey': "",
                'isthumbnail': false
            }
            http({
                url: url,
                method: 'GET',
                headers: headerpresigned,
                data: {}
            }).then(response => {
                let { jsonPresignedURL } = response.data.presignedURLs;
                var xhr = new XMLHttpRequest();
                xhr.open("PUT", jsonPresignedURL);
                xhr.setRequestHeader('Content-Type', 'multipart/form-data');
                xhr.onloadend =  (res) => {
                     if(res.target.status == 200){
                                http({
                                    method: "POST",
                                    url:`${MenuUploadAPI.uploadLibraryFile}`,
                                    data: postData,
                                    headers: {
                                        'Content-Type': 'application/json'
                                    }
                                }).then(res =>{
                                    let { data } = res;
                                    let { httpCode, message } = data;
                                if(httpCode === "HTTP_200"){                          
                                    resolve(message);
                                    }
                                    else{
                                        reject(res);
                                    }
                                }).catch(err => {
                                    reject(err);
                                });
                            }
                };
                xhr.onerror = (err) =>{
                    reject(err);
                };
                xhr.send(uploadedfile);
            }).catch(err => {
                reject(err);
            });
        });
    }

    changeLoadingStatus=(value)=>{
        this.setState({loaderIconVisible:value})
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { success_modal, error_modal, } = this.state;
        const { loaderIconVisible } = this.state;
        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <ViewTopology
                    title={formatMessage({ id: 'KC2262' })} 
                    submitAddLibrary={this.submitAddLibrary}
                    showError={this.showError}
                    changeLoadingStatus={this.changeLoadingStatus}
                    onBackClickHandler={this.onBackClickHandler} 
                    modelFamilyIds={localStorage.getItem("selectedFamilyID")} 
                    preSelect={false} 
                    restrictUnitSelect={true} />
                <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} message={success_modal.message} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" />
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LibraryUploadTopology));
