import React from 'react';
import { useDispatch } from 'react-redux';
import { Formik } from "formik"
import * as Yup from "yup"
import { FormattedMessage, injectIntl } from 'react-intl';
import Loader from '../../components/Loader';
import UserManagementHeader from '../../components/layoutSection/userManagementHeader';
import { UserMgmtAddUser, LanguagePreference } from '../../service/api';
import http from '../../service/httpService';
import AddUserForm from '../../Features/userMgmt/create';
import KCLink from "../../UI_Components/KCLink";
import { setUserDataModel } from '../../actions/stateActionsData/userAction';
import ErrorMoal from '../../components/modal/error-model';
import { useSelector } from 'react-redux';

const initialStateValues = {
    firstName: "",
    lastName: "",
    email: "",
    retypeEmail: "",
    phoneNumber: "",
    countryCode: "",
    languageList:""
}

const AddUserPage = (props) => {
    const [loader, setLoading] = React.useState(false);
    const userModelValue = useSelector(state => state.userReducer.UserModel)
    const [countryCode, setCountryCode] = React.useState([]);
    const [errorModal, setErrorModal] = React.useState({ open: false, message: '' });
    const [initialValues, setInitialValue] = React.useState(userModelValue.user || initialStateValues);
    const [languageList, setLanguageList] = React.useState([]);
    const { formatMessage } = props.intl;
    const dispatch = useDispatch();


    const validateEmail = async (email) => {
        let userDetails = localStorage.getItem("loginInfo");
        let loggedInUser = JSON.parse(userDetails);
        let postData = {
            user: {
                email: email.trim(),
                customer_id: localStorage.getItem("customerId"),
                base_country: localStorage.getItem("basecountry"),
                access_countries: JSON.stringify([localStorage.getItem("accesscountry")])
            },
            user_name: loggedInUser.username,
            name: localStorage.getItem("userName"),
            customerName: localStorage.getItem("customerName"),
            "type": "validate-domain"
        };

        try {
            setLoading(true)
            const response = await http({
                method: "POST",
                url: UserMgmtAddUser.addUser,
                data: postData,
                config: { headers: { 'Content-Type': 'application/json' } }
            })
            setLoading(false)
            if (response) {
                return true
            }
        } catch (error) {
            setLoading(false)
            return false

        }
    }

    const validationSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().required(<FormattedMessage id="KC2552" />),
            lastName: Yup.string().required(<FormattedMessage id="KC2553" />),
            email: Yup.string()
                .required(<FormattedMessage id="KC1675" />)
                .email(<FormattedMessage id="KC2654" />),
            retypeEmail: Yup.string()
                .required(<FormattedMessage id="KC1675" />)
                .oneOf([Yup.ref('email'), null], <FormattedMessage id="KC2903" />),
            languageList: Yup.number().required(<FormattedMessage id="KC2898" />)
        })
    }

    const setUserToState = async (values) => {
        const isValidEmail = await validateEmail(values.email);
        if (isValidEmail) {
            let userModel = {};
            userModel.user = values;
            userModel.previousPage = "/user/create";
            userModel.assignUserBackPage = "/user/select-role";
            let newList = countryCode;
            newList.forEach(item => {
                if (item.code === values["countryCode"]) {
                    item.isSelected = true;
                } else {
                    item.isSelected = false;
                }
            });
            userModel.user.country_code = values["countryCode"];
            userModel.user.country_code = userModel.user.country_code ? userModel.user.country_code : newList.length > 0 && newList[0].code
            userModel.countryCodeList = newList;
            userModel.user.languageList = userModel.user.languageList || languageList[1]?.Id
            console.log("userModel ==== ",userModel)
            dispatch(setUserDataModel(userModel));
            props.history.push('/user/select-role', { data: values })
        } else {
            setErrorModal({ open: true, message: 'KC2655' })
        }
    }


    React.useEffect(() => {
        document.body.style.backgroundColor = "#F2F2F2";
        getCountryCodeList();
        getLanguageList();
        console.log("props.location.data",userModelValue);
        if(userModelValue.user){
            setInitialValue(userModelValue)
        }
        return () => {
        }
    }, []);

    const getCountryCodeList = async () => {
        setLoading(true)
        let result = await http.get(UserMgmtAddUser.getCountryCodeApi, {
            headers: { 'Content-Type': 'application/json' },
            data: {}
        });
        let { data } = result.data;
        setCountryCode(data);
        setLoading(false);
    }

    const getLanguageList = async () => {
        setLoading(true)
        const url = LanguagePreference.getLanguages;
        let result = await http.get(url, {
            headers: { 'Content-Type': 'application/json' },
            data: {}
        });
        let { data } = result.data;        
        setLanguageList(data);
        setLoading(false);
    }

    const closeModel = () => {
        setLoading(false);
        setErrorModal({ open: false, message: '' })
    }

    return (
        <>
            <Loader loading={loader} />
            <UserManagementHeader headerNameStringID='KC0581' headerName="User Management" activeClass="UserManagementNav" />
            <div id="adminWrapper">
                <div id="page-content-wrapper">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        isInitialValid={userModelValue.user ? true : false }
                    >
                        {(formik) => (
                            <div className="userManagementWrapper">
                                <div className="addUserHeader">
                                    <ul>
                                        <li>
                                            <KCLink to="/user/list" title={formatMessage({ id: 'KC0989' })} className="backIcon"></KCLink>
                                        </li>
                                        <li>
                                            <FormattedMessage id="KC0596" />
                                        </li>
                                        <li>
                                            <button id="btnnext" className={`btn-default-text ${formik.isValid ? '' : 'disabled'}`} title={formatMessage({ id: "KC0543" })}
                                                onClick={(e) => setUserToState(formik.values)}
                                            ><FormattedMessage id={'KC0543'} /></button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="addUserFormWrapper">
                                    <div className="addUserForm">
                                        <AddUserForm countryCodeList={countryCode} languageList = {languageList} />
                                        <div className="requiredNoteDiv"><FormattedMessage id={'KC0654'} /></div>
                                        
                                    </div>
                                </div>
                            </div>
                        )}
                    </Formik>
                </div>
            </div>
            <ErrorMoal open={errorModal.open} stringID={errorModal.message} onCloseModel={closeModel} />
        </>
    )
}


export default injectIntl(AddUserPage);