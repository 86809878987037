import React, { Component, PureComponent } from "react";
import http from "../../../service/httpService";
import { LocationInfo, UnitInfo } from "../../../service/api";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { CUSTOMER_ID, BRAND_ID } from "../../../utils/appConstants";
import { FormattedMessage } from "react-intl";
import { reactIntl } from "../../../locale/locale-provider";

const mappingConnectivityStatusStringID = {
  Offline: "KC0068",
  Online: "KC0069",
};

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

class ConnectivityChart extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      connectivityStatus: [],
    };
  }

  componentDidMount() {
    // Create chart instance

    // Set data
    let connectivityStatusURL = `${UnitInfo.connectivityerrorStatusURL}`;

    let cgids = "";

    if (this.props.orgid && this.props.orgid.length > 0) {
      cgids = this.props.orgid;
    }
    if (this.props.cgid && this.props.cgid.length > 0) {
      cgids = this.props.cgid;
    }

    let header = {
      "Content-Type": "application/json",
      filterparam: "connect",
      cgid: cgids,
      cid: CUSTOMER_ID === "-1" ? this.props.cid : CUSTOMER_ID,
      brandid: this.props.brandid.length > 0 ? this.props.brandid : BRAND_ID,
      model: this.props.model,
      machinetype: this.props.machineType,
    };
    if (this.props.tags && this.props.tags.length) {
      header["tags"] = this.props.tags.join(",");
    }
    if (this.props.Type == "Location") {
      connectivityStatusURL = `${LocationInfo.connectivityerrorStatusURL}`;
    } else {
      if (cgids === "") {
        header = {
          "Content-Type": "application/json",
          filterparam: "connect",
          assignState: "assigned",
        };
      }
    }

    http
      .get(connectivityStatusURL, {
        headers: header,
        data: {},
      })
      .then((res) => {
        this.setState(
          {
            isLoaded: true,
            connectivityStatus: res.data,
          },
          () => {
            this.renderChart(res.data);
          }
        );
        // Add data
      });

    // Add data
    //chart.data = generateChartData();

    // Add and configure Series
  }

  renderChart = (data) => {
    if (data && data.length > 0) {
      let chart = am4core.create(
        "connectivityStatuschartdiv",
        am4charts.PieChart
      );
      chart.data = data;
      this.chart = chart;
      let pieSeries = chart.series.push(new am4charts.PieSeries());
      pieSeries.dataFields.value = "connectState_count";
      pieSeries.dataFields.category = "connect_state";
      pieSeries.tooltip.label.adapter.add(
        "textOutput",
        function (text, target) {
          if (
            target.dataItem.dataContext &&
            target.dataItem.dataContext.connect_state
          ) {
            const connectState = reactIntl.formatMessage({
              id: mappingConnectivityStatusStringID[
                target.dataItem.dataContext.connect_state
              ],
            });
            const countValue = text.split(":")[1];
            return `${connectState}:${countValue}`;
          } else {
            return ``;
          }
        }
      );
      pieSeries.slices.template.propertyFields = "pulled";
      pieSeries.labels.template.disabled = true;
      pieSeries.ticks.template.disabled = true;
      pieSeries.slices.template.strokeWidth = 0;
      pieSeries.colors.list = [
        am4core.color("#ffffff"),
        am4core.color("#545454"),
      ];
    } else {
      if (this.chart) {
        this.chart.data = data;
      }
    }
  };

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  // multilingual
  renderConnectState = (connectState) => {
    let stringID = connectState === "Online" ? "KC0069" : "KC0068";
    return <FormattedMessage id={stringID} />;
  };

  render() {
    const { isLoaded, connectivityStatus } = this.state;
    console.log(`connectivityStatusChart ${isLoaded}`);
    const connectivityStatusList =
      connectivityStatus && connectivityStatus.length > 0
        ? connectivityStatus
        : [];

    let objCount = [];
    objCount = [...connectivityStatusList].find(function (element) {
      return element.connectState_count > 0;
    });
    let displayChartClass = "colmDiVal";
    let displayClass = "colmDiVal displaynone";
    if (objCount === [] || objCount === undefined) {
      displayClass = "colmDiVal";
      displayChartClass = "colmDiVal displaynone";
    }
    return (
      <div>
        {!isLoaded && (
          <div className="colmDiVal">
            <div className="spinner" role="status"></div>
          </div>
        )}

        <div className={displayChartClass}>
          <div id="connectivityStatuschartdiv"></div>
          <div class="connectivityStatus">
            <div
              id="connectivityStatuslegenddiv"
              class="connectivityStatusTable"
            >
              {Object.keys([...connectivityStatusList]).map((item, i) => (
                <div>
                  <div class="value">
                    {connectivityStatusList[i].connectState_count}
                  </div>
                  <div class="title">
                    {this.renderConnectState(
                      connectivityStatusList[i].connect_state
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={displayClass}>
          <div class="valH1WStatusDiv">
            &nbsp;
            <span class="valH1WStatusSpan">&nbsp;</span>
          </div>
        </div>
      </div>
    );
  }
}

export default ConnectivityChart;
