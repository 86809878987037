import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getDeviceAPIUrl, getDeviceInfo } from '../../../service/unitManagement/deviceinfo';

export class HolderList extends Component { 

    constructor(props){
        super(props);        
        this.state = {             
            deviceInformation: {},
            holder_name: this.props.isDisplayAll === false ? "Frypot 1" : "Fryer"
        }

        this.onHolderSelection = this.onHolderSelection.bind(this);  
    }

    componentDidMount = async () => {
		let deviceUrl = getDeviceAPIUrl(localStorage.getItem("unitID"), localStorage.getItem("brandName"));
		let deviceInfo = await getDeviceInfo(deviceUrl);
		
		if (deviceInfo) {
			this.setState({
				deviceInformation: deviceInfo
			});
		}
    }
    
    onHolderSelection =(e)=>{
        this.props.OnHolderChange(e);    
        this.setState({ 
            holder_name: e.target.text
        });
    }  

    render() {       
        let {deviceInformation, holder_name} = this.state;
        let frypotList = [];
        if (deviceInformation && deviceInformation.softwareVersion && deviceInformation.softwareVersion.frypots) {
            let frypotLists = deviceInformation.softwareVersion.frypots;
            frypotList = Object.keys(frypotLists).map(function (key) {
                return { key: key, value: frypotLists[key] };
            });
        }

        return (
            <div class="dropdown">
                <button class="btn btn-secondary dropdown-toggle dateRangeSelection" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    {holder_name}
                    </button>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    {this.props.isDisplayAll === false ? "" : <Link class="dropdown-item" to="#" onClick={(e) => this.onHolderSelection(e)}>Fryer</Link>}
                    {frypotList && frypotList.map((item, i) => {
                        //User Story 51105: Frypot configuration is not updating in DB when powerOn event is published
							if(deviceInformation && deviceInformation.totalFrypots && (i+1 <= parseInt(deviceInformation.totalFrypots))){
                               return <Link class="dropdown-item" to="#" onClick={(e) => this.onHolderSelection(e)}>{item.key.replace("frypotId","Frypot ")}</Link>
							}
						})}
                    </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentDate: state.showDefaultDateReducer.currentDate
    }
}

export default connect(mapStateToProps)(HolderList);

