import React, { useState } from "react"
import useIdleTimeout from './useIdleTimeout';
import CountdownTimer from './CountdownTimer';
import Modal from "react-responsive-modal";
import { FormattedMessage } from 'react-intl';
import { userIdleDetails } from '../../utils/appConstants'
import auth from '../../utils/authHelper';
import { BaseService } from '../../service/api';
import http from '../../service/httpService';
import { withRouter } from 'react-router-dom';

const IdleTimeWrapper = (props) => {
    const [openModal, setOpenModal] = useState(false);

    const handleIdle = () => {
        setOpenModal(true);
    }

    const handleDismissal = () => {
        setOpenModal(false);
        idleTimer.reset();
    }

    const handleLogout = async () => {
        setOpenModal(false);
        const response = await http({ method: 'PUT', url: BaseService.logout, data: {} });
        if (response?.data) {
            localStorage.setItem('signoutStatus', true);
            auth.deleteLocalSession();
            props.history.push("/");
        }
    }

    const styles = {
        fontFamily: "sans-serif",
        textAlign: "center"
    };

    let successMsg = (
        <div className="modal fade SignInSuccessModal show" style={{ display: "block" }}>
            <div className="modal-dialog modal-md loginModal">
                <div className="modal-content">
                    <div className="modal-body">
                        {<div className="singINConfirmation">Due to inactivity you will be logged out in <CountdownTimer initialSeconds={userIdleDetails.counterStart} /> ... seconds</div>}
                    </div>
                </div>
            </div>
        </div>
    );

    const { idleTimer } = useIdleTimeout({ onIdle: handleIdle, onLogout: handleLogout, onDismissal: handleDismissal });

    return (
        <div style={styles}>
            <Modal open={openModal} showCloseIcon={false} center onClose={() => { }}>
                {successMsg}
            </Modal>
        </div>
    )

}
export default withRouter(IdleTimeWrapper);