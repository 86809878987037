import http from '../../../service/httpService';
import {smartGroupAPI} from '../../../service/api';
import { BRAND_ID } from '../../../utils/appConstants';
export const SMARTGROUP_REQUEST = 'SMARTGROUP_REQUEST';
export const SMARTGROUP_SUCCESS = 'SMARTGROUP_SUCCESS';
export const SMARTGROUP_FAIL = 'SMARTGROUP_FAIL';
export const SELECTED_SMARTGROUP_LIST ='SELECTED_SMARTGROUP_LIST';

export function getSmartGroup (req) {
    return dispatch => {
        dispatch({
            type: SMARTGROUP_REQUEST
        })

        let queryString;
        queryString = `customerId=${localStorage.getItem("customerId")}`
        queryString = queryString + `&userId=${localStorage.getItem("user")}`
        let url = `${smartGroupAPI.createSmartGroup}?${queryString}`; 
        http.get(url,
                {
                    headers: {
                        'Content-Type':'application/json', 
                        'cid': localStorage.getItem("customerId"),                       
                    },
                    data:{}
                }
                )
            .then(response => {
                dispatch({
                    type: SMARTGROUP_SUCCESS,
                    smartgroupInformation: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: SMARTGROUP_FAIL,
                    error
                })
            })
    }
}
 