import http from '../../../service/httpService';
import { SoftwareManagementAPI } from '../../../service/api';
import { BAKING_PLAN_LIST, BAKING_PLAN_LIST_SUCCESS, BAKING_PLAN_LIST_FAIL } from './actionTypes';

export function getBakingPlans(currentPage, pageSize, sortColumn) {
    return async dispatch => {
        dispatch({
            type: BAKING_PLAN_LIST
        })

        let url = `${SoftwareManagementAPI.bakingPlanList}`;
		let pageNumber = '?pageNumber=' + currentPage;
		let pageLimit = '&pageSize=' + pageSize;
        let sortBy
		if(sortColumn.path && (sortColumn.order == 'asc' || sortColumn.order == 'desc')){
            sortBy = sortColumn.path == "FILE_NAME" ? "baking_plan_file_name" :
                        sortColumn.path == "LOCATION" ? "location" :
                        sortColumn.path == "WITHIN_LOCATION" ? "within_location" :
                        sortColumn.path == "CREATED" ? "created" : ''
        }
		url = url + pageNumber + pageLimit;
        url = sortBy ? `${url}&sortBy=${sortBy}&sortOrder=${sortColumn.order}` : url
        await http.get(url,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'cid': localStorage.getItem("customerId") ? localStorage.getItem("customerId") : ''
                },
                data:{}
            })
            .then(response => {
                if(response && response.data && response.data.data && response.data.data.length > 0) {
                    response.data.data = response.data.data.map(oneData => {
                        return {
                            ...oneData,
                            SOFTWARE_ID: oneData.id,
                            TYPE: oneData.type
                        }
                    })
                }
                dispatch({
                    type: BAKING_PLAN_LIST_SUCCESS,
                    payload: response.data.data,
                    totalPages: response.data.totalPages,
                    currentPage: response.data.totalRecords,
                    loaderIconVisible: false
                })

            })
            .catch(error => {
                console.log("Action File Catch: ", error)
                dispatch({
                    type: BAKING_PLAN_LIST_FAIL,
                    loaderIconVisible: false,
                    error
                })
            })
    }
}


