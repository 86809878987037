import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { injectIntl, FormattedMessage } from 'react-intl';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import ErrorMoal from '../../../../modal/error-model';
let errorMessageContent = 'Min and Max gap values should be between 0 to 2000 and should not overlap across all recipes.';

class GarlandCookbookProductCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            selectedPRRecipes: [],
            minGapVal: '',
            maxGapVal: '',
        }
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        this.updateDimensions();
        $(".mCustomScrollbar").mCustomScrollbar();

        let categoryDetails = localStorage.getItem("selectedPRRecipes");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        if (categoryDetailsObj) {
            this.setState({
                selectedPRRecipes: categoryDetailsObj,
            })
        }
    }

    updateDimensions = () => {
        var windowHeight = $(window).height();
        var menuFilterHeader = $(".menuFilterHeader").height();
        var pageRRHeight = ((windowHeight) - (menuFilterHeader)) - 105;
        $(".readyRecipeSrlTable").css("height", pageRRHeight);
    }

    onDeleteRecipe = (e, item) => {
        if (this.state.selectedPRRecipes && this.state.selectedPRRecipes.length > 0) {
            const libraries = [...this.state.selectedPRRecipes];
            var selectedPRRecipes = libraries.filter(function (el) { return el.ID != item.ID; });
            localStorage.setItem("selectedPRRecipes", JSON.stringify(selectedPRRecipes));
            this.setState({
                allMenuRecipes: libraries,
                selectedPRRecipes: selectedPRRecipes
            });
        }
    }

    checkOverlap = (o1, o2) => {
        return (
            o1.minGap >= o2.minGap && o1.minGap <= o2.maxGap ||
            o1.maxGap >= o2.minGap && o1.maxGap <= o2.maxGap ||
            o2.minGap >= o1.minGap && o2.minGap <= o1.maxGap ||
            o2.maxGap >= o1.minGap && o2.maxGap <= o1.maxGap
        );
    }

    onConfirmClick = async () => {
        let { selectedPRRecipes } = this.state;
        const isValid = this.valid(selectedPRRecipes);

        if (isValid) {
            if (selectedPRRecipes && selectedPRRecipes.length > 0) {
                await localStorage.setItem("selectedPRRecipes", JSON.stringify(selectedPRRecipes));

                let categoryObj = JSON.parse(localStorage.getItem("ProductRecgDetails"));
                categoryObj.recipe_items = selectedPRRecipes;
                await localStorage.setItem("ProductRecgDetails", JSON.stringify(categoryObj));
            }
            this.props.history.push({
                pathname: "/garlandCookbookEditor",
                isEdit: false,
                previouspath: "/garlandCookbookProductCreation"
            });
        } else {
            this.setState({
                error_modal: {
                    open: true,
                    message: errorMessageContent
                }
            })
        }
    }

    onAddRecipe = async () => {
        let { selectedPRRecipes } = this.state;
        if (selectedPRRecipes && selectedPRRecipes.length > 0) {
            await localStorage.setItem("selectedPRRecipes", JSON.stringify(selectedPRRecipes));
        }
        this.props.history.push({
            pathname: "/garlandCookbookProductRecipe",
            isEdit: false,
            previouspath: "/garlandCookbookProductCreation"
        });
    }

    isValidCheck = (obj, index) => {
        const isReturn  = true;
        for (let indexJ = 0; indexJ < this.state.selectedPRRecipes.length; indexJ++) {
            const elementJ = this.state.selectedPRRecipes[indexJ];

            if (index !== indexJ) {
                // if (index !== indexJ && Number(elementJ.minGap) !== 0 && Number(elementJ.maxGap) !== 0) {
                const obj2 = {
                    minGap: Number(elementJ.minGap),
                    maxGap: Number(elementJ.maxGap)
                }
                if (this.checkOverlap(obj, obj2)) {
                    return false;
                }
            }
        }
        return isReturn;
    }

    onBlurCheck = async (obj, index, from) => {
        if(obj.minGap === obj.maxGap){
            this.setState({
                error_modal: {
                    open: true,
                    message: 'Min and Max values should not be same.'
                }
            })
            return;   
        }
        if(from === 'max' && obj.minGap > obj.maxGap){
            this.setState({
                error_modal: {
                    open: true,
                    message: 'Max value should be greater than min value.'
                }
            })
            return; 
        }
        const isValidCheck = await this.isValidCheck(obj, index);
        if (isValidCheck) {
        } else {
            this.setState({
                error_modal: {
                    open: true,
                    message: 'Please check min and max value. Value is overlapped.'
                }
            })
        }
    }

    onMinGapChange = async (e, item) => {
        let val = e.target.value;
        if (e && e.target && e.target.value && (e.target.value.length > 4 || Number(e.target.value) > 2000)) {
            return;
        }
        if (val < 0 || val > 2000) {
            return;
        }

        if (this.state.selectedPRRecipes && this.state.selectedPRRecipes.length > 0) {
            const libraries = [...this.state.selectedPRRecipes];
            const index = libraries.indexOf(item);
            libraries[index] = { ...libraries[index] };
            libraries[index].minGap = val;
            this.setState({
                selectedPRRecipes: libraries,
            })
        }
    }

    onMaxGapChange = (e, item) => {
        let val = e.target.value;
        if (e && e.target && e.target.value && (e.target.value.length > 4 || Number(e.target.value) > 2000)) {
            return;
        }
        if (val < 0 || val > 2000) {
            return;
        }
        if (this.state.selectedPRRecipes && this.state.selectedPRRecipes.length > 0) {
            const libraries = [...this.state.selectedPRRecipes];
            const index = libraries.indexOf(item);
            libraries[index] = { ...libraries[index] };
            libraries[index].maxGap = val;
            this.setState({
                selectedPRRecipes: libraries,
            })
        }
    }

    IsAlphaNumeric(e) {
        e.target.value = e.target.value.trim().replace(/[^\d]/g, '');
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    valid = (selectedPRRecipes) => {
        let result = true;
        for (let index = 0; index < selectedPRRecipes.length; index++) {
            const element = selectedPRRecipes[index];
            if (element.minGap === "" || element.maxGap === "") {
                result = false;
            }
            if (Number(element.minGap) === 0 && Number(element.maxGap) === 0) {
                return false;
            }else if((Number(element.minGap) > Number(element.maxGap) )|| (Number(element.minGap) === Number(element.maxGap) )  ){
                errorMessageContent = 'Max value should be greater than min value.';
                return false;
            }else {
                for (let indexJ = 0; indexJ < selectedPRRecipes.length; indexJ++) {
                    const elementJ = selectedPRRecipes[indexJ];
                    if (index !== indexJ) {
                        const obj1 = {
                            minGap: Number(element.minGap),
                            maxGap: Number(element.maxGap)
                        }
                        const obj2 = {
                            minGap: Number(elementJ.minGap),
                            maxGap: Number(elementJ.maxGap)
                        }
                        if (this.checkOverlap(obj1, obj2)) {
                            errorMessageContent = 'Min and Max gap values should be between 0 to 2000 and should not overlap across all recipes.';
                            return false;
                        }
                    }
                }
            }
        }
        return result;
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, loaderIconVisible, selectedPRRecipes, error_modal } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658" /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li className="selectedModel">ME, MG</li>
                            </ul>
                        </div>

                        <div className="rcDeviceWrapper">
                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} /><button type="button" onClick={() => this.props.history.push('/garlandCookbookToTopology')} className="btn saveExitBtn">Save File &amp; Exit</button></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={() => this.props.history.replace('/garlandCookbookProduct')} className="btn backButton">&nbsp;</button>
                                    Product Recognition
                                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn alignTop">
                                                <div className="cbcategory menuEditActive">
                                                    <ul>
                                                        <li className="garlandCBcategory">
                                                            <a onClick={() => this.onAddRecipe()} className="cbAddButton" title="Add Recipes">Add Recipes</a>
                                                        </li>
                                                        <li className="garlandCBcategory">
                                                            <button type="button" className={selectedPRRecipes.length ? "btn confirmButton" : "btn confirmButton disabled"} onClick={() => this.onConfirmClick()}>Confirm</button>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="productRecgnTable">
                                                    <ul className="tHead">
                                                        <li>Recipes</li>
                                                        <li>&nbsp;</li>
                                                        <li>Gap Min (mils) <span>MIN: 0, MAX: 2000</span></li>
                                                        <li>Gap Max (mils) <span>MIN: 0, MAX: 2000</span></li>
                                                        <li>&nbsp;</li>
                                                    </ul>
                                                    {selectedPRRecipes && selectedPRRecipes.length > 0 && selectedPRRecipes.map((item, i) => {
                                                        return (
                                                            <ul className="tBody">
                                                                <li><span className="recipeIcon"><img src={item.IMAGE_S3_SIGNED_URL ? item.IMAGE_S3_SIGNED_URL : require("../../../../../images/recipecreation/Default Recipe.bmp")} alt="" /></span></li>
                                                                <li>{item.LIBRARY_NAME}</li>
                                                                <li><input id={item.ID} type="number" onBlur={() => {
                                                                    const obj = {
                                                                        minGap: Number(item.minGap),
                                                                        maxGap: Number(item.maxGap)
                                                                    };
                                                                    if (Number(item.minGap) !== 0 && Number(item.maxGap) !== 0) {
                                                                        this.onBlurCheck(obj, i, 'min');
                                                                    }
                                                                }} onKeyUp={(e) => this.IsAlphaNumeric(e)} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} onChange={(e) => this.onMinGapChange(e, item)} value={item.minGap} className="form-control" /></li>
                                                                <li><input id={item.ID} type="number" onBlur={() => {
                                                                    const obj = {
                                                                        minGap: Number(item.minGap),
                                                                        maxGap: Number(item.maxGap)
                                                                    };
                                                                    // if (Number(item.minGap) !== 0 && Number(item.maxGap) !== 0) {
                                                                        this.onBlurCheck(obj, i, 'max');
                                                                    // }
                                                                }} onKeyUp={(e) => this.IsAlphaNumeric(e)} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} onChange={(e) => this.onMaxGapChange(e, item)} value={item.maxGap} className="form-control" /></li>
                                                                <li>
                                                                    <button id={item.ID} type="button" onClick={(e) => this.onDeleteRecipe(e, item)} className="btn deleteButton">&nbsp;</button>
                                                                </li>
                                                            </ul>
                                                        )
                                                    })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
            </React.Fragment >
        )
    }
}

export default injectIntl(GarlandCookbookProductCreation)