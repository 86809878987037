import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { getTotalTrayCounts } from '../../../actions/unitDashboard/totalTrayCountAction';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import * as _ from 'lodash';
import NumberFormat from 'react-number-format';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';


// common global parameters
var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'MM-DD-YYYY');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'MM-DD-YYYY');
let unitID = "";
let brandID = "";
let currentUnit;

class TotalTrayCount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            locationInformation: {},
            deviceInformation: {},
            unitTime: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            startDateOnTime: "",
            endDateOnTime: "",
            datapickflag: false
        }
        this.handleChangeStartOnTime = this.handleChangeStartOnTime.bind(this);
        this.handleChangeEndOnTime = this.handleChangeEndOnTime.bind(this);
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        this.props.getTotalTrayCounts("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);
    }

    componentDidUpdate = async (prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter,datapickflag: this.props.globalDropdownFilter === "Date Range" });
            this.handleTotalTrayCount(this.props.globalDropdownFilter, 'TotalTrayCount');
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange ) {
                if (prevState.endDateOnTime !== this.props.globalDropdownFilterEndDate) {
                   this.handleChangeEndOnTime(this.props.globalDropdownFilterEndDate)
                   await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDateOnTime !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStartOnTime(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //start Date Change Handler
    handleChangeStartOnTime(onTimeDate) {
        if(this.state.startDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            startDateOnTime: onTimeDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleTotalTrayCount(selectedtext, 'TotalTrayCount');
        });
    }

    //end Date Change Handler
    handleChangeEndOnTime(onTimeDate) {
        if(this.state.endDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            endDateOnTime: onTimeDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleTotalTrayCount(selectedtext, 'TotalTrayCount');
        });
    }

    //dropdown Handler
    onDropDownHandler(e, type = '') {
        const { nodeText: text } = e;
        this.setState({ type: text,datapickflag:text === 'Date Range', startDateOnTime: '', endDateOnTime: '' });
        this.handleTotalTrayCount(text, type);
    }

    handleTotalTrayCount(text, type) {
        currentUnit = this.props.currentUnit;
        const { startDateOnTime, endDateOnTime } = this.state;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        if (text === "Date Range") {
            if (type === "TotalTrayCount") {
                todaysDateFormatted = format(endDateOnTime, 'YYYY-MM-DD');
                weekDateFormatted = format(startDateOnTime, 'YYYY-MM-DD');
            }
        }
        else if (text === 'Week') {
            weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

            var startDateMonth = getMonth(new Date(weekDateFormatted));
            var endDateMonth = getMonth(new Date(todaysDateFormatted));

            if (startDateMonth !== endDateMonth) {
                weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
            }
        }
        else {
            todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
            weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
        }

        if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") &&
            (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.props.getTotalTrayCounts(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
        }
    }

    returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (localStorage.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

    render() {

        const { totatTrayCountInfo } = this.props;
        const totalTrayCountObject = totatTrayCountInfo.totalTrayCountResponse ? totatTrayCountInfo.totalTrayCountResponse : {};
        let totalTrayCountModel = totalTrayCountObject.content;
        let totalTrayCountValue = 0;
        let commonFilterConsumptionValue = null;
        let customFilterWidget = null;
        let refeshTime = format(new Date(), 'hh:mm');
        let refeshTime24hrs = format(new Date(), 'HH');

        const isTrend = _.isUndefined(totalTrayCountModel) ? false : _.isObject(_.find(totalTrayCountModel, el => el.key === 'trend'));
        totalTrayCountValue = totalTrayCountModel && totalTrayCountModel[0] ? totalTrayCountModel[0].value : "0";
        let unitTime = totalTrayCountObject && totalTrayCountObject.unitTime ? totalTrayCountObject.unitTime.toLowerCase() : '';
        if (localStorage.getItem("timeFormat") === "24"){
            unitTime = unitTime ? moment(unitTime, 'hh:mm A').format('HH:mm'):"";
        } 
        let TotalTrayCountResponseValue = null;
        if (Array.isArray(totalTrayCountModel) && totalTrayCountModel.length) {

            commonFilterConsumptionValue = (<div className="valH1Div"> {this.returnNumberFormat(Math.round(totalTrayCountModel[0].value))} {(isTrend && this.state.type !== "Date Range") && (<span className={totalTrayCountModel[2].value === '1' ? "valH1Span valHigh" : totalTrayCountModel[2].value === '0' ? "valH1Span valLow" : "valH1Span"}> {unitTime}</span>)}</div>);

            TotalTrayCountResponseValue = (
                <div class="colmDiVal">
                    { commonFilterConsumptionValue}
                    { (isTrend && this.state.type !== "Date Range") && (<div class="valH5Div">
                        { mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }: {this.returnNumberFormat(Math.round(totalTrayCountModel[1].value))}
                    </div>)}
                </div>
            )
        }
        else {
            customFilterWidget = (<div className="valH1Div"> { totalTrayCountValue}{(isTrend && this.state.type !== "Date Range") && (<span className={totalTrayCountValue === '1' ? "valH1Span valHigh" : totalTrayCountValue === '0' ? "valH1Span valLow" : "valH1Span"}> &nbsp;</span>)}</div>);

            TotalTrayCountResponseValue = (
                <div class="colmDiVal">
                    { customFilterWidget}
                    { (this.state.type !== "Date Range") && (<div class="valH5Div">
                        { mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }:  { totalTrayCountValue}
                    </div>)}
                </div>
            )
        }
        // Convotherm Average Trays Widget
        let TotalTrayCountWidget = null;
        TotalTrayCountWidget = (<div class="colmDi">
            <h4><FormattedMessage id='KC0391'/>
                    <span className="cornerIcon"></span>
                <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDateOnTime} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate}
                    endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDateOnTime} handleChangeStartProps={(date) => this.handleChangeStartOnTime(date)} handleChangeEndProps={(date) => this.handleChangeEndOnTime(date)}
                    OnChange={(e) => this.onDropDownHandler(e, "TotalTrayCount")} filterType={this.state.type} />
            </h4>
            {TotalTrayCountResponseValue}
        </div>)

        return (
            <React.Fragment>
                <div className="colm4D">
                    {TotalTrayCountWidget}
                </div>
            </React.Fragment>
        )
    }
};
function mapStateToProps(state) {
    return {
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
        totatTrayCountInfo: state.totalTrayCountResponse,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCurrentUnit, getCurrentLocation, getTotalTrayCounts }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(TotalTrayCount);
