import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../controls/modelFamilyList';
import { injectIntl,FormattedMessage } from 'react-intl';

class CookbookFavouritesList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.redirect = this.redirect.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            
        } catch (error) {
            //Error Goes Here
        }
    }

    redirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    onBackButtonClick = () => {
        this.props.history.push({
            pathname: "/cookbookEditor",
            isEdit: false,
            previouspath: "/cookbookAddCategory"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        let { data: dataList } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = localStorage.getItem("selectedbrandID");
        const { formatMessage } = this.props.intl;

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/merrychefW.png")}  alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <FormattedMessage id="KC1254"/>
                				</div>
                                <div class="reBodyWrapper">
					<div class="reWrapperTable">
						<div class="reWrapperRow">
							<div class="reWrapperColmn alignTop">
							
                            <div class="cbPhotogallery">
								<ul>
									<li>
										<a href="#" title={formatMessage({ id: 'KC1254' })}>
											<span class="navIcon"><img src={require("../../../../../images/recipecreation/breakfastImg.png")}  alt=""/></span>
											<span class="navTitle"><FormattedMessage id="KC1255"/></span>
										</a> 
									</li>
									<li>
										<a href="#" title={formatMessage({ id: 'KC2082' })}>
											<span class="navIcon"><img src={require("../../../../../images/recipecreation/breakfastImg.png")}  alt=""/></span>
											<span class="navTitle"><FormattedMessage id="KC1256"/></span>
										</a> 
									</li> 
									<li>
                                        <Link to="/cookbookAddCategory" title={formatMessage({ id: 'KC1242' })}>
											<span class="navIcon"><img src={require("../../../../../images/recipecreation/breakfastImg.png")}  alt=""/></span>
											<span class="navTitle"><FormattedMessage id="KC1257"/></span>
										</Link> 
									</li>
									<li>
										<a href="menucbaddcategory.html" title={formatMessage({ id: 'KC1242' })}>
											<span class="navIcon"><img src={require("../../../../../images/recipecreation/breakfastImg.png")}  alt=""/></span>
											<span class="navTitle"><FormattedMessage id="KC1258"/></span>
										</a> 
									</li>
                                    <li>
										<a href="#" title={formatMessage({ id: 'KC1254' })}>
											<span class="navIcon"><img src={require("../../../../../images/recipecreation/breakfastImg.png")}  alt=""/></span>
											<span class="navTitle"><FormattedMessage id="KC1255"/></span>
										</a> 
									</li>
									<li>
                                        <a href="#" title={formatMessage({ id: 'KC2082' })}>
											<span class="navIcon"><img src={require("../../../../../images/recipecreation/breakfastImg.png")}  alt=""/></span>
											<span class="navTitle"><FormattedMessage id="KC1256"/></span>
										</a> 
									</li> 
									<li>
                                        <Link to="/cookbookAddCategory" title={formatMessage({ id: 'KC1242' })}>
											<span class="navIcon"><img src={require("../../../../../images/recipecreation/breakfastImg.png")}  alt=""/></span>
											<span class="navTitle"><FormattedMessage id="KC1257"/></span>
										</Link> 
									</li>
									<li>
										<a href="menucbaddcategory.html" title={formatMessage({ id: 'KC1242' })}>
											<span class="navIcon"><img src={require("../../../../../images/recipecreation/breakfastImg.png")}  alt=""/></span>
											<span class="navTitle"><FormattedMessage id="KC1258"/></span>
										</a> 
									</li>
								</ul>
							</div>
							
							</div>
						</div>
					</div>
				</div>
                                <div class="recipeEditorF">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn btn-default-text backButton" title={formatMessage({ id: 'KC1214' })}>&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(CookbookFavouritesList)
