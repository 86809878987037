
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import http from '../../../../service/httpService';
import { MenuUploadAPI } from '../../../../service/api';
import authService from '../../../../service/authService';
import ErrorMoal from '../../../modal/error-model';
import SuccessMoal from '../../../modal/success-model';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../../layoutSection/userManagementHeader';
import { TableHeader } from '../../../controls/tableHeader';
import { orderBy } from 'lodash'
import { CUSTOMER_ID, getMiniproFamilyID} from '../../../../utils/appConstants';
import NoRecordsFound from '../../../controls/noRecordsFound';
import moment from '../../../../utils/momentHelper.js';
import { CheckValidDB} from "../../../../utils/convothermDB";
import { injectIntl,FormattedMessage } from 'react-intl';
import { searchVisible, searchText } from '../../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function } from '../../../globalSearchBar/selectOption';
import { CommonDetailViewEdit } from '../../../controls/commonDetailViewEdit';

const HTTP_METHOD = {
	GET: 'GET',
	PUT: 'PUT',
	POST: 'POST',
	DELETE: 'DELETE'
}

let md5 = require('md5');

class LibraryList extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			customerId: parseInt(localStorage.getItem("customerId")),
			isSelectAllLibrary: false,
			isIndeterminateSelect:false,
			isCreateCookBook: false,
			isAssign: false,
			isUpload: true,
			isDownload: false,
			isDelete: false,
			data: [],
			error_modal: {
				open: false,
				message: ""
			},
			success_modal: {
				open: false,
				message: ""
			},
			unitIdValue: {},
			sortColumn: { path: "UPDATED_ON", order: "desc", sort_color: "#63778a" },
			created_by: '',
			last_edited_by: '',
			cookbook_name:'',
			errorCookbookName:'',
			cookbookCount: 0,
			recipeCount: 0,
			readyRecipeCount: 0,
			legacyCount: 0,
			settingsCount: 0,
			selected_item: {},
			totalfilesize: 0.00,
			uploadOption: 'Recipe',
			selectedFile: {},
			actionType: 'Upload',
			cookbookerrmsg: '',
			searchData: null			
		}
		this.getLibraryList = this.getLibraryList.bind(this);
		this.onSelectLibrary = this.onSelectLibrary.bind(this);
		this.selectAllLibrary = this.selectAllLibrary.bind(this);
		this.onUploadLibraryCheck = this.onUploadLibraryCheck.bind(this);
		this.OnDownloadLibrary = this.OnDownloadLibrary.bind(this);
		this.OnDeleteLibrary = this.OnDeleteLibrary.bind(this);
		this.OnCancelLibrary = this.OnCancelLibrary.bind(this);
		this.OnToggleDetails = this.OnToggleDetails.bind(this);
		this.OnAssignLibrary = this.OnAssignLibrary.bind(this);
		this.containsSpecialChars = this.containsSpecialChars.bind(this);
	}

	async componentDidMount() {
		this.props.searchVisible(true);
		$('body').removeClass("modal-open");
		$('div').removeClass("modal-backdrop show");
		$('.styles_overlayCenter__YHoO7').addClass("displaynone");
		document.body.style.backgroundColor = "#F2F2F2";
		localStorage.setItem("libraryData", "{}");
		localStorage.setItem("libraryDetails", "{}");
		localStorage.setItem("libraryImage", "");
		localStorage.setItem("schedulerDetails", "{}");
		localStorage.setItem("defaultLibraryData", "{}");
		localStorage.setItem("resourceDetails", "[]");
		localStorage.setItem("selectedCategory", "{}");
		localStorage.setItem("selected_cgid", "[]");
		window.addEventListener('resize', this.updateDimensions());
		$('#UploadLibrary').click(function () { $('#menuUpdate').trigger('click'); });
		await this.getLibraryList().catch((err) => {
			let {message,errorCode} = err.response.data;
			if(errorCode == '403100' && message=='Token Expired Or Invalid'){
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: "KC1678"
					}
				});
			}else{
				this.setState({
					loaderIconVisible: false
				});

			}
		});
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateDimensions());
		$('div').removeClass("modal-backdrop fade show");
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	static getDerivedStateFromProps(props) {
		return { ...props };
	}
	
	componentDidUpdate(prevState) {
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if(curSearchData && (isEqualObj(searchData, curSearchData) === false) ) {
			this.getLibraryList();
		}
	}

	updateDimensions() {
		var windowHeight = $(window).height();
		var menuAssignFTable = $(".menuAssignFTable").height();
		var menuFilterHeader = $(".menuFilterHeader").height();
		var menuLibraryTable = $(".menuLibraryTable").height();
		var pageMnuWrprHeight = ((windowHeight) - (menuFilterHeader + menuLibraryTable + menuAssignFTable)) - 65;
		$(".menuLibrarySrlTable").css("height", pageMnuWrprHeight);
	}

	OnToggleDetails = (assignedTopologyDetails) => {
	
		const { ASSIGNED_CUSTOMER_GROUP, ...rest } = assignedTopologyDetails;
		// initialize a Set object
		var newArray = ASSIGNED_CUSTOMER_GROUP.filter(value => Object.keys(value).length !== 0);
		const uniqueValuesSet = new Set();
		// array of objects with duplicate values 
		const filteredArr = newArray.filter((obj) => {
			// check if name property value is already in the set 
			const isPresentInSet = uniqueValuesSet.has(obj.cust_gp_id);
			uniqueValuesSet.add(obj.cust_gp_id)
			return !isPresentInSet
		});
		this.setState({
			assignedTopologyDetails: { ASSIGNED_CUSTOMER_GROUP: filteredArr, ...rest },
		},()=>{
			$('#detailsInfoModal').modal('show')
		});
	}

	onSaveButtonClick = () => {
		let cookbookName = this.state.cookbook_name.trim();
		if (cookbookName.length < 1 || cookbookName.length > 50) {
			this.setState({
				errorCookbookName: "KC1192"
			})
		}
		else {
			const libraries = [...this.state.data];
			let libraryList = libraries.filter(c => c.checked === true);
			let kcbrandsitedata = {};
			kcbrandsitedata.libraryList = libraryList;
			kcbrandsitedata.cookbookName = this.state.cookbook_name;
			localStorage.setItem("kcbrandsitedata",JSON.stringify(kcbrandsitedata));
			localStorage.setItem("selectedbrandID", libraryList[0].BRAND_ID);
			localStorage.setItem("brandName", libraryList[0].BRAND_NAME);
			let model_family = JSON.parse(localStorage.getItem("selectedFamilyID"));
			localStorage.setItem("selectedFamilyID", model_family ? Array.prototype.map.call(model_family, m => m.ID).toString() : '');
			if(localStorage.getItem("brandName") === 'MERRYCHEF'){
				this.props.history.push({
					pathname: "/assignLegacyRecipeToTopology",
					previouspath: "/legacyLibraryList",
				});
			}else if (localStorage.getItem("brandName") === 'CONVOTHERM'){
				this.props.history.push({
					pathname: "/assignLegacyCookbookToTopology",
					previouspath: "/legacyLibraryList",
				});
			}
		}
	}
    
	onCookbookNameChange = (e) => {
        if (e.target.value.match(/^[a-zA-Z0-9 ]*$/)) {
            this.setState({
                cookbook_name: e.target.value,
                errorCookbookName: ""
            });
        }
        else {
            this.setState({
                errorCookbookName: "KC1193"
            });
        }
    }

	containsSpecialChars = (str) => {
		const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
		return specialChars.test(str);
	}

	getLibraryList = async() => {
		this.setState({
			loaderIconVisible: true
		});
		let url = MenuUploadAPI.getConvoLibraryList;
		let { searchData } = this.state;
		let _checkIfExists, encoded;
		let encodedSearchData = { searchtext : '' };
		if(searchData && searchData.searchtext!='') {
			_checkIfExists = this.containsSpecialChars(searchData.searchtext);
		}
		if (_checkIfExists) {
			encoded = encodeURIComponent(searchData.searchtext);
			encodedSearchData.searchtext = encoded;
		}
		//searchData_function
		let _search = await searchData_function(encodedSearchData && encodedSearchData.searchtext!='' ? encodedSearchData : searchData);
		let _encodedFlag = encodedSearchData && encodedSearchData.searchtext!='' ? `&encoded=true` : `&encoded=false`;
		if (_search) {
			url = `${url}?${_search}${_encodedFlag}`;
		}
		return new Promise((resolve, reject) => {
			http.get(url,
				{
					headers: {
						'Content-Type': 'application/json',
						'customerid': CUSTOMER_ID,
						'cgids': localStorage.getItem("custGroupID"),
					},
					data: {}
				}).then(response => {
					let { data } = response;
					let libraryList = data ? data : [];
					libraryList.length > 0 && libraryList.map(item => {
						item.checked = false;
						item.UPDATED_ON = moment.ConvertLocalTimeFromUTCTime(item.UPDATED_ON, "MMMM D, YYYY hh:mm A");
						item.LIBRARY_FILE_SIZE = item.LIBRARY_FILE_SIZE ? parseFloat(item.LIBRARY_FILE_SIZE, 2) : 0.00;
					});
					this.setState({
						loaderIconVisible: false,
						data: libraryList
					});
					resolve(response);
				}).catch(err => {
					reject(err);
				});
		});

	}

	selectAllLibrary = (e) => {
		const { data } = this.state;
		if (data && data.length > 0) {
			let libraries = [...data];
			const selectedBrands = [...new Set(libraries.map(a => a.BRAND_NAME))];

			if (selectedBrands.length === 1) {
				libraries && libraries.length > 0 && libraries.map(item => {
					item.checked = e.target.checked;
				});

				let selectedUnits = libraries.filter(c => c.checked === true);
				let selectedCookbook = libraries.filter(c => c.TYPE === "CookBook" && c.checked === true);
				let selectedRecipes = libraries.filter(c => c.TYPE === "Recipe" && c.checked === true);
				let selectedSettings = libraries.filter(c => c.TYPE === "Settings" && c.checked === true);
				let selectedReadyRecipes = libraries.filter(c => c.TYPE === 'ReadyRecipe' && c.checked === true)
				let selectedLegacy = libraries.filter(c => c.TYPE === "Legacy" && c.checked === true);
				this.isSelectAssignActiveDelete(selectedUnits, selectedCookbook, selectedSettings, selectedLegacy, libraries, libraries[0], e.target.checked);

				this.setState({
					data: libraries,
					isSelectAllLibrary: e.target.checked,
					isIndeterminateSelect: false,
					cookbookCount: selectedCookbook.length,
					recipeCount: selectedRecipes.length + selectedReadyRecipes.length,
					settingsCount: selectedSettings.length,
					readyRecipeCount: selectedReadyRecipes.length
				});
			}
			else {
				this.openErrorModal("KC1174");
			}
		}
	}

	getArrayDifferences(result1, result2) {
		//Find values that are in result2 but not in result1
		var uniqueResultTwo = result2.filter(function(obj) {
			return !result1.some(function(obj2) {
				return obj.ID == obj2.ID;
			});
		});

		return uniqueResultTwo;
	}

	setConsolidatedFamilies(result1, result2) {
		var result = [];
		if (result2.length > 0) {
			//Find values that are in result2 but not in result1
			var uniqueResultTwo = result2.filter(function(obj) {
				return !result1.some(function(obj2) {
					return obj.ID == obj2.ID;
				});
			});
			//Combine the two arrays of unique entries
			result = result1.concat(uniqueResultTwo);
		}
		else {
			result = result1;
		}
		localStorage.setItem("selectedFamilyID", JSON.stringify(result));
	}

	setArrayConsolidated(result1, result2, checked) {
		if (checked) {
			//Find values that are in result2 but not in result1
			var uniqueResultTwo = result2.filter((obj) => !result1.some((obj2) => obj.cust_gp_id == obj2.cust_gp_id));

			//Combine the two arrays of unique entries
			var result = result1.concat(uniqueResultTwo);
			localStorage.setItem("selected_cgid", JSON.stringify(result));
		}
		else {
			let selected_cgid = JSON.parse(localStorage.getItem("selected_cgid"));
			result2.forEach((obj) => {
				let index = selected_cgid.findIndex((i) => i.cust_gp_id === obj.cust_gp_id);
				selected_cgid.splice(index, 1);
			});
			localStorage.setItem("selected_cgid", JSON.stringify(selected_cgid));
		}
	}
	
	onSelectLibrary = (e, item) => {
		const { data } = this.state;

		if (data && data.length > 0) {
			const libraries = [...data];
			const index = libraries.indexOf(item);
			libraries[index] = { ...libraries[index] };
			libraries[index].checked = e.target.checked;

			let selected_cgid = localStorage.getItem("selected_cgid") ? JSON.parse(localStorage.getItem("selected_cgid")) : [];
			let current_cgid = item.ASSIGNED_CUSTOMER_GROUP ? item.ASSIGNED_CUSTOMER_GROUP : [];
			this.setArrayConsolidated(selected_cgid, current_cgid, e.target.checked);

			let selectedUnits = libraries.filter(c => c.checked === true);
			let selectedCookbook = libraries.filter(c => c.TYPE === "CookBook" && c.checked === true);
			let selectedRecipes = libraries.filter(c => c.TYPE === "Recipe" && c.checked === true);
			let selectedReadyRecipes = libraries.filter(c => c.TYPE === "ReadyRecipe" && c.checked === true);
			let selectedSettings = libraries.filter(c => c.TYPE === "Settings" && c.checked === true);
			let selectedLegacy = libraries.filter(c => c.TYPE === "Legacy" && c.checked === true);
			this.isSelectAssignActiveDelete(selectedUnits, selectedCookbook, selectedSettings, selectedLegacy, libraries, item, e.target.checked);

			if (selectedUnits.length === 1) {
				this.setState({
					selected_item: selectedUnits[0]
				});
			}

			function getCheckBoxstate() {
				if (selectedUnits.length == libraries.length) {
					return { isSelectAllLibrary: true, isIndeterminateSelect: false };
				} else if (selectedUnits.length == 0 && libraries.length != 0) {
					return { isSelectAllLibrary: false, isIndeterminateSelect: false };
				} else if (libraries.length >= selectedUnits.length) {
					return { isSelectAllLibrary: true, isIndeterminateSelect: true };
				}
			}

			this.setState({
				data: libraries,
				cookbookCount: selectedCookbook.length,
				recipeCount: selectedRecipes.length + selectedReadyRecipes.length,
				settingsCount: selectedSettings.length,
				legacyCount: selectedLegacy.length,
				readyRecipeCount: selectedReadyRecipes.length,
				...getCheckBoxstate()
			});
		}
	}

	isSelectAssignActiveDelete = (selectedUnits, selectedCookbook, selectedSettings, selectedLegacy, libraries, item, isChecked) => {
		let totalfilesize = selectedUnits.reduce((prev, cur) => prev + parseFloat(cur.LIBRARY_FILE_SIZE), 0);
		let isCreateCookBook, isAssign, isUpload, isDownload, isDelete = false;
		let cookbookerrmsg = '';
		const selectedlibraries = libraries.filter(c => c.checked === true);
		let selectedbrand = selectedlibraries.length > 0 ? selectedlibraries[0].BRAND_NAME : "";
		let selectedFamily = selectedlibraries.length > 0 ? selectedlibraries[0].MODEL_FAMILY : [];
		let currentFamily = selectedlibraries.length > 0 ? item.MODEL_FAMILY : [];
		let difference = this.getArrayDifferences(currentFamily.length > selectedFamily.length ? currentFamily : selectedFamily, currentFamily.length < selectedFamily.length ? selectedFamily : currentFamily);
		let currentlibrary = selectedlibraries.filter(c => c.ID === item.ID);
		let isValid =  currentlibrary.length === 0 ? true : selectedlibraries.length > 0 && difference.length === 0 && selectedbrand === item.BRAND_NAME ? true : selectedlibraries.length === 0 ? true : false;

		if (selectedUnits.length === 1 && isChecked) {
			localStorage.setItem("selectedFamilyID", JSON.stringify(currentFamily));
		}
		else if (selectedUnits.length === 1 && !isChecked) {
			localStorage.setItem("selectedFamilyID", JSON.stringify(selectedFamily));
		}
		else {
			this.setConsolidatedFamilies(localStorage.getItem("selectedFamilyID") ? JSON.parse(localStorage.getItem("selectedFamilyID")) : [], selectedFamily);
		}

		if (selectedUnits.length === 1 && selectedCookbook.length === 1) {
			isCreateCookBook = false;
			isAssign = true;
			isUpload = false;
			isDownload = true;
			isDelete = true;
		} 
		else if (selectedUnits.length > 0 && selectedCookbook.length === 0 && selectedSettings.length === 0 && isValid) {
			isCreateCookBook = true;
			isAssign = false;
			isUpload = false;
			isDownload = false;
			isDelete = true;
			totalfilesize = totalfilesize * 2.1;
		}
		else if ((selectedUnits.length > 1 && selectedCookbook.length > 1) || (selectedUnits.length > 1 && selectedSettings.length > 1)) {
			isCreateCookBook = false;
			isAssign = false;
			isUpload = false;
			isDownload = false;
			isDelete = true;
			cookbookerrmsg = 'KC1194';
		} else if (((selectedUnits.length > 1 && selectedCookbook.length < 2) || (selectedUnits.length > 1 && selectedSettings.length < 2))  && isValid) {
			isCreateCookBook = true;
			isAssign = false;
			isUpload = false;
			isDownload = false;
			isDelete = true;
			totalfilesize = totalfilesize * 1.2;
		} else if (!isValid) {
			isCreateCookBook = false;
			isAssign = false;
			isUpload = false;
			isDownload = false;
			isDelete = true;
		} else {
			isCreateCookBook = false;
			isAssign = false;
			isUpload = true;
			isDownload = false;
			isDelete = false;
		}

		this.setState({
			isCreateCookBook,
			isAssign,
			isUpload,
			isDownload,
			isDelete,
			totalfilesize,
			cookbookerrmsg
		});
	}

	OnAssignLibrary = () => {
		const { data } = this.state;

		let selecteddata = data.filter(c => c.checked == true);
		if (selecteddata) {
			let libraryIds = selecteddata.map(a => a.LIBRARY_ID);
			const types = [...new Set(selecteddata.map(a => a.TYPE))];
			let type = "";
			if (types.length > 1) {
				type = "Package";
			}
			else if (types.length === 1) {
				type = types[0];
			}

			this.props.history.push({
				pathname: "/legacyScheduleUpdateNow",
				libraryIds: libraryIds,
				libraryType: type,
				previouspath: "/legacyLibraryList"
			});
		}
	}

	onUploadLibraryCheck = async (event) => {
		const { files } = event.target;
		if (files && files[0]) {
			let fileName = files[0].name;
			fileName = fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
			let fileextSplit = fileName.split('.');
			let fileNamewithOutExt = fileextSplit[0];
			let fileext = fileextSplit[1];
			if (localStorage.getItem("brandName") === "CONVOTHERM" &&  (fileext === "db" || fileext === "DB")) {
				if(fileNamewithOutExt.length > 3 && fileNamewithOutExt.length < 51){
					if((files[0].size/(1024 * 1024)) < 20 ) {
						this.handleDBFileType(event, fileNamewithOutExt);
					}else{
						this.openErrorModal("KC1195");
					}
				}else{
					this.openErrorModal("KC1196"); 
				}
			} else {
				this.openErrorModal("KC1197"); 
			}
		}
	}

	// Check the unique field is present or not to identify the correct json file
	uniqueKeyFind = (brand, type) => {
		if (brand === "MERRYCHEF") {
			if (type === 'CookBook') {
				return 'menus'
			} else if (type === 'Settings') {
				return 'mode'
			} else {
				return 'ordered_steps'
			}
		}
		if (brand === "FRYMASTER") {
			if (type === 'CookBook') {
				return 'autoMenuChange'
			} else if (type === 'Settings') {
				return 'general'
			} else {
				return 'conditional_steps'
			}
		}
	}

	handleDBFileType (event, fileNamewithOutExt) {
		this.setState({
			loaderIconVisible: true
		});
		const { files } = event.target;
		var reader = new FileReader();
		CheckValidDB(files[0]).then((data) => {
			reader.readAsDataURL(files[0]);
			reader.onload = async (e) => {
				let fileBuffer = Buffer.from(e.target.result.replace(/^data:application\/octet-stream;base64,/, ""), "base64");
				let totalfilesize = Buffer.byteLength(fileBuffer);
				totalfilesize = (totalfilesize / 1048576);
				let LIBRARY_NAME =  (fileNamewithOutExt + '_' + Date.now()).replace(/\s+/g, '')
				let url = await this.getPresignedUrl(LIBRARY_NAME);
				await this.uploadIntoS3(fileBuffer, url.presignedURLs);
				//C4,maxx,maxxpro, mini model families mapped for upload //User Story 67131: Mini device visibility in Org Tree View while uploading a cookbook
				//userstory:98522 added minipro model family
				const miniProID = getMiniproFamilyID();
				localStorage.setItem("selectedFamilyID", `26,27,28,36,${miniProID}`);
				this.setState({
					loaderIconVisible: false
				});
				await this.props.history.push({
					pathname: "/uploadLegacyCookbookToTopology",
					library_name: LIBRARY_NAME,
					name: fileNamewithOutExt,
					totalfilesize: totalfilesize,
					URL: url.presignedURLs,
					crc: md5(fileBuffer, "base64"),
					previouspath: "/legacyLibraryList"
				});
			};
		}).catch(err => {
			this.openErrorModal("KC1198");
		});
	}

	getPresignedUrl = (LIBRARY_NAME) => {
        return new Promise((resolve, reject) => {
            http.get(MenuUploadAPI.getConvoUploadpresignedUrl, {
                headers: {
                    'Content-Type': "application/json",
                    'customerid': CUSTOMER_ID,
                    'cookbookfilekey' : `${LIBRARY_NAME}.db`,
                },
                data: {},
            })
            .then((response) => {
                resolve(response.data);
            })
            .catch((err) => {
                reject(err);
            });
        });
    }

	uploadIntoS3 = (buffer, s3Url) => {
		return new Promise((resolve, reject) => {
			try {
				const url = s3Url;
				const xhr = new XMLHttpRequest();
				xhr.open("PUT", url);
				xhr.setRequestHeader('Content-Type', 'multipart/form-data');
				xhr.onloadend = (res) => {
					resolve(res);
				};
				xhr.onerror = (err) => {
					reject(err);
				};
				xhr.send(buffer);
			} catch (error) {
				reject(error);
			}
		});
	};

	OnDownloadLibrary = () => {
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {
			let selectedLibraryId = this.getSelectedIds('ID');
			http.get(MenuUploadAPI.getConvoLibraryInfo, {
				headers: {
					'Content-Type': 'application/json',
					'libraryids': selectedLibraryId,
					'kcbrandsiteflag': 'false',
					'customerid': CUSTOMER_ID,
				}, data: {}
			}).then(response => {
				if (response && response.data) {
					resolve(response.data);
					let file_path = response.data.cookBookS3URL[0];
					var a = document.createElement('A');
					a.href = file_path;
					a.download = file_path.substr(file_path.lastIndexOf('/') + 1);
					document.body.appendChild(a);
					a.click();
					document.body.removeChild(a);
					$(".loadingProgress").removeAttr("style");
					this.setState({
						loaderIconVisible: false
					});
				}
			}).catch(err => {
				this.setState({
					loaderIconVisible: false
				});
			});
		});
	}

	OnCancelLibrary = () => {
		$('#ConfirmationOfImportModal').modal('hide');
	}

	OnDeleteLibrary = async () => {
		this.setState({
			loaderIconVisible: true
		})
		let selectedLibraryIds = await this.getSelectedIds('ID');
		let postData = {
			'Ids': selectedLibraryIds,
			'customerid': CUSTOMER_ID,
		}

		this.putData(postData, MenuUploadAPI.getConvoLibraryList, HTTP_METHOD.DELETE).then(response => {
			if (response && response.data && response.data.message) {
				if (response.data.httpCode === "HTTP_200") {
					let libraries = [...this.state.data];
					let unAssignedUnits = libraries.filter(item => item.checked !== true);
					this.setState({
						data: unAssignedUnits,
						cookbookCount: 0,
						recipeCount: 0,
						readyRecipeCount: 0,
						settingsCount: 0,
						legacyCount: 0,
						totalfilesize: 0,
						loaderIconVisible: false,
						isSelectAllLibrary:false,
						isIndeterminate: false,
						isCreateCookBook: false,
						isAssign: false,
						isUpload: true,
						isDownload: false,
						isDelete: false,
						cookbookerrmsg: '',
						success_modal: {
							open: true,
							message: (response.data.stringID || response.data.message)
						}
					});
				}
			}
		}).catch(err => {
			this.showError(err);
		});
	}

	putData = (postData, url, method) => {
		this.setState({
			loaderIconVisible: true
		});
		return new Promise((resolve, reject) => {
			http({
				method: method,
				url: url,
				headers: {'Content-Type': 'application/json'},
				data: postData
			}).then(res => {
				let { data } = res;
				let { httpCode } = data;
				if (httpCode !== "HTTP_200") {
					reject({
						response: {
							data: res.data
						}
					});
				} else {
					resolve(res);
				}
			}).catch(err => {
				reject(err);
			});
		});
	}

	getSelectedIds = (key) => {
		let libraries = [...this.state.data];
		let assingUnits = [];
		if (libraries && libraries.length > 0) {
			for (let index = 0; index < libraries.length; index++) {
				if (libraries[index].checked == true) {
					assingUnits.push(libraries[index][key]);
				}
			}
		}
		return assingUnits;
	}

	showError = (err) => {
		let { response } = err;
		if (response) {
			let { data } = response;
			if (data) {
				let { body } = data;
				if (body) {
					this.openErrorModal(body.message);
				}
			}
		} else {
			this.openErrorModal("KC1065");
		}
	}

	openErrorModal = (message) => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: true,
				message
			}
		});
	}

	onSuccessCloseModal = () => {
		this.setState({
			loaderIconVisible: false,
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/legacyLibraryList`);
	}

	closeModel = () => {
		this.setState({
			loaderIconVisible: false,
			error_modal: {
				open: false,
				message: ''
			}
		})
	}

	OnCancel = () => {
        this.setState({
            errorCookbookName: "",
			cookbook_name:'',
        })
    }

	onSort = sortColumn => {
		this.setState({
			sortColumn: sortColumn
		});
	}

	updateSize() {
		const { totalfilesize } = this.state;
		let widthPercent = '0%';
		if (totalfilesize <= 20) {
			widthPercent = (totalfilesize * 5) + '%';
		} else if (totalfilesize > 20) {
			widthPercent = '100%';
		} return <div>
			<div className="maxFileSize">
				<div className={(totalfilesize > 20) ? "progress-bar errorUpload" : "progress-bar "} role="progressbar"
					style={{ width: widthPercent }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
					{totalfilesize.toFixed(2)} mb</div>
				max 20 mb</div>
		</div>
	}

	onCloseDetailsPopup = () => {
		this.setState({
			assignedTopologyDetails: null,
		})
	} 

	onSaveDetailsPopup = (event) => {
		const { data } = this.state;
		data.forEach((item) => {
			if(item.ID === event.ID){
				item.DESCRIPTION = event.DESCRIPTION;
				item.ASSIGNED_CUSTOMER_GROUP = event.ASSIGNED_CUSTOMER_GROUP;
				item.UPDATED_ON = event.UPDATED_ON;
				item.UPDATED_BY = event.UPDATED_BY;
			}
		})
		this.setState({
			data
		})
	}

	render() {
		const { formatMessage } = this.props.intl;
		let { data: dataList, isSelectAllLibrary, isIndeterminateSelect, isUpload, errorCookbookName,cookbook_name,isDownload, isCreateCookBook, isAssign, isDelete, error_modal, success_modal, loaderIconVisible, sortColumn, created_by, last_edited_by, cookbookCount, recipeCount, readyRecipeCount, settingsCount, legacyCount, totalfilesize, cookbookerrmsg, assignedTopologyDetails } = this.state;
		dataList = dataList && dataList.length > 0 ? dataList : [];

		let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
		let libraryAuthFeature = authService.getFeature("/legacyLibraryList");
		let readyLibraryAuthFeature = authService.getFeature("/legacyReadyRecipeLibraryList");
		
		let columns = [
			{ path: "", label: "#" },
			{ path: "TYPE", labelStringID: "KC0102" },
			{ path: "", labelStringID: "KC0680" },
			{ path: "BRAND_NAME", labelStringID: "KC0062" },
			{ path: "", labelStringID: "KC0649" },
			{ path: "LIBRARY_NAME", labelStringID: "KC0241" },
			{ path: "UPDATED_ON", labelStringID: "KC2005" },
			{ path: "", label: "" },
			{ path: "LIBRARY_FILE_SIZE", labelStringID: "KC0682" },
			{ path: "", labelStringID: "KC0325" },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: libraryAuthFeature.is_editable },
		];

		if (sortColumn.path === "UPDATED_ON") {
			if (sortColumn.order === "desc") {
				dataList = dataList.sort((d1, d2) => new Date(d2.UPDATED_ON) - new Date(d1.UPDATED_ON));
			}
			else {
				dataList = dataList.sort((d1, d2) => new Date(d1.UPDATED_ON) - new Date(d2.UPDATED_ON));
			}
		}
		else if (sortColumn.path === "LIBRARY_FILE_SIZE") {
			dataList = orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path]], [sortColumn.order]);
		}
		else {
			dataList = orderBy(dataList, [dataList => dataList[sortColumn.path] && dataList[sortColumn.path].toString().toLowerCase()], [sortColumn.order]);
		}

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper" className="withSearchBar">
						<div className="menuUpdateWrapper">
							<div className="menuFilterHeader">
								<ul>
									<li>
										<Link className="active" to="/legacyLibraryList" title={formatMessage({ id: "KC0658"})}><FormattedMessage id='KC0658' /></Link>
										<Link to="/legacyReadyRecipeLibraryList" title={formatMessage({ id: 'KC1181' })} ><FormattedMessage id="KC1181"/></Link>
										{loginInfo.rolename !== "Public Admin" && <Link to="/menuUpdateStatus" title={formatMessage({ id: "KC0659"})}><FormattedMessage id='KC0659' /></Link>}
									</li>
									<li>
										<button type="button" title={formatMessage({ id: "KC0683"})} className={libraryAuthFeature.is_editable === 1 ? `btn btn-default-text menuUpload ${isUpload ? "" : "disabled"}` : "btn btn-default-text menuUpload disabled"} onClick={() => { this.inputElement.click() }}>&nbsp;</button>
										<input type="file" ref={input => this.inputElement = input} onChange={(e) => this.onUploadLibraryCheck(e)} className="fileInputField" id="menuUpdate" />
										<button type="button" title={formatMessage({ id: "KC0699"})} className={libraryAuthFeature.is_editable === 1 ? `btn btn-default-text menuDownload ${isDownload ? "" : "disabled"}` : "btn btn-default-text menuDownload disabled"} onClick={(e) => this.OnDownloadLibrary()}>&nbsp;</button>
										<button type="button" title={formatMessage({ id: 'KC1185' })} className={libraryAuthFeature.is_editable === 1 ? `btn btn-default-text transfertoSG ${isAssign ? "" : "disabled"}` : "btn btn-default-text transfertoSG disabled"} onClick={() => this.OnAssignLibrary()}>&nbsp;</button>
										<button type="button" title={formatMessage({ id: "KC0524"})} className={libraryAuthFeature.is_editable === 1 ? `btn btn-default-text menuDelete ${isDelete ? "" : "disabled"}` : "btn btn-default-text menuDelete disabled"} data-toggle="modal" data-target="#confirmationDeleteModal" onClick={() => { }}>&nbsp;</button>
									</li>
								</ul>
							</div>
							<form className="menuLibraryTableForm">
								<div className="menuLibraryTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.selectAllLibrary}
										columns={columns}
										isIndeterminate={isIndeterminateSelect}
										isSelectAll={isSelectAllLibrary} />
								</div>
								<div className="menuLibrarySrlTable">
									<div className="menuLibraryTable">
										{
											dataList.map((item, i) => {
												return (
													<ul className="tBody" key={i}>
														<li>{i + 1}</li>
														<li><span className="menuType"><img src={require(item.TYPE === "ReadyRecipe" ? "../../../../images/icons/recipeS.png" : item.TYPE === "Recipe" ? "../../../../images/icons/recipeS.png" : item.TYPE === "Settings" ? "../../../../images/icons/settingLG.png" : item.TYPE === "Legacy" ? "../../../../images/icons/legacyMenuD.png" : "../../../../images/icons/manualS.png")} title={item.TYPE === "ReadyRecipe" ? formatMessage({ id: 'KC0640' }) : item.TYPE === "Recipe" ? formatMessage({ id: 'KC1381' }) : item.TYPE === "Settings" ? formatMessage({ id: 'KC1190' }) : item.TYPE === "Legacy" ? formatMessage({ id: 'KC1113' }) : formatMessage({ id: 'KC2009' })} alt="" /></span></li>
														<li><span className="menuPicture"> <img src={item.TYPE === "CookBook" ? require("../../../../images/recipecreation/cookbookA.png") : item.THUMB_NAIL_CONTENT} /></span></li>
														<li>{item.BRAND_NAME}&nbsp;
															<div className="menuSListSM">{item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.NAME).toString() : ''}</div>
															<div className="menuSListSMF">{item.LIBRARY_NAME}</div>
															<div className="menuSListRS">{item.UPDATED_ON} {item.ASSIGNED_COUNT ? "" : <span className="badge badge-primary">new</span>}</div>
															<div className="menuSListAS">{item.LIBRARY_FILE_SIZE ? item.LIBRARY_FILE_SIZE.toFixed(2) : "0.00"} mb</div>
														</li>
														<li>{item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.NAME).toString() : ''}</li>
														<li>{item.LIBRARY_NAME}</li>
														<li>{item.UPDATED_ON}</li>
														<li>{item.ASSIGNED_COUNT ? "" : <span className="badge badge-primary"><FormattedMessage id='KC2112' /></span>}</li>
														<li>{item.LIBRARY_FILE_SIZE ? item.LIBRARY_FILE_SIZE.toFixed(2) : "0.00"}</li>
														<li><button type="button" className="btn btn-default-text menuInfo" data-toggle="modal" data-target="#detailsInfoModal" onClick={() => this.OnToggleDetails(item)}>&nbsp;</button></li>
														<li>
															<div className={libraryAuthFeature.is_editable == 1 ? "customCheckbox customCheckboxWOlabel" : "customCheckbox customCheckboxWOlabel disabled"} >
																<input id={item.ID} name="menuLibraryList" type="checkbox" value={item.checked} checked={item.checked} onChange={(e) => this.onSelectLibrary(e, item)} />
																<label htmlFor={item.ID}></label>
															</div>
														</li>
													</ul>
												)
											})
										}
									</div>
									<NoRecordsFound loaderIconVisible={loaderIconVisible} length={dataList !== undefined ? dataList.length : 0} classname={'norecordsfoundbluetext'} />
								</div>
							</form>
							{/* <!-- Assign -->  */}
							<div className={loginInfo.rolename === "Public Admin" ? "displaynone" : "menuAssignFTable"}>
								<ul>
									<li>
										<div className="menuAssignL">
											<ul>
												<li>
													<div className={cookbookCount ? "dragedDetailsTable active" : "dragedDetailsTable"}>
														<span className="menuManualCount">{cookbookCount}</span>
														<span className="menuManualText"><FormattedMessage id="KC1188"/></span>
														<br/><b>{cookbookerrmsg != '' ? <FormattedMessage id={cookbookerrmsg}/> : ''}</b>
													</div>
												</li>
												<li>
													<div className={recipeCount ? "dragedDetailsTable active" : "dragedDetailsTable"}>
														<span className="menuRecipeCount">{recipeCount}</span>
														<span className="menuRecipeText"><FormattedMessage id="KC1189"/></span>
													</div>
												</li>
												{localStorage.getItem("brandName") !== "CONVOTHERM" ? (<li>
													<div className={settingsCount ? "dragedDetailsTable active" : "dragedDetailsTable"}>
														<span className="menuSettingsCount">{settingsCount}</span>
														<span className="menuSettingsText"><FormattedMessage id="KC1190"/></span>
													</div>
												</li>): null}
											</ul>
										</div>
										{this.updateSize()}
									</li>
									<li className={(cookbookCount || recipeCount || readyRecipeCount || settingsCount || legacyCount) && isCreateCookBook && totalfilesize <= 20 && libraryAuthFeature.is_editable === 1 ? "active" : ""}>
										<button type="button" disabled={!((cookbookCount || recipeCount || readyRecipeCount || settingsCount || legacyCount) && isCreateCookBook && totalfilesize <= 20 && libraryAuthFeature.is_editable === 1)} data-toggle="modal" data-target="#createCookbookModal">
											<span className=""><FormattedMessage id="KC0685"/></span>
										</button>
									</li>
								</ul>
							</div>
							{/* <!-- //Assign --> */}
							<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
							<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
						</div>
					</div>
					<UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
				</div>
				{/* <!-- Info Modal --> */}
				{assignedTopologyDetails ? <CommonDetailViewEdit detailsProps={assignedTopologyDetails} onSave={this.onSaveDetailsPopup} onClose={this.onCloseDetailsPopup} thumbnail={assignedTopologyDetails.TYPE === "CookBook" ? require("../../../../images/recipecreation/cookbookA.png") : assignedTopologyDetails.THUMB_NAIL_CONTENT} /> : null}
				{/* <!-- //Info Modal --> */}
				{/* <!-- Create Cookbook Modal --> */}
				<div className="modal fade selectRecipeModal" data-keyboard="true" data-backdrop="static" id="createCookbookModal" tabindex="-1" role="dialog" aria-labelledby="createCookbookModalLabel">
					<div className="modal-dialog modal-dialog-centered modal-md">
						<div className="modal-content">  
							<div className="modal-header modal-header-danger tAlginCenter">
								<FormattedMessage id="KC0685"/>
							</div>
							<div className="modal-body">
								<div className="addEditFormWrapper">
									<div className="addEditForm">
										<ul>
											<li className="marginBottom10px">
												<div className="input-group">
													<input class="form-control" type="text" placeholder={formatMessage({ id: 'KC0686' })} value={cookbook_name} onChange={(e) => this.onCookbookNameChange(e)} />
													<div className="errorMsg">{errorCookbookName != '' ? <FormattedMessage id={errorCookbookName}/> : ''}</div>
												</div>
											</li>
										</ul> 
									</div>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
							<button onClick={() => this.OnCancel()} class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id="KC0021"/></button>
							<button onClick={() => this.onSaveButtonClick()} class="btn btn-secondary yesBtn" id="timeZoneAssign" type="button"><FormattedMessage id="KC0123"/></button>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- //Create Cookbook Menu --> */}
				{/* <!-- Delete Confirmation Modal --> */}
				<div className="modal fade confirmationDeleteModal" data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="deleteConfirmation">
									<FormattedMessage id="KC0684"/>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancelLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
								<button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDeleteLibrary()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
							</div>
						</div>
					</div>
				</div>
				{/* <!-- //Delete Confirmation --> */}
			</React.Fragment>
		)
	}
};

const mapStateToProps = state => {
	return {
		searchData: state.advanceSearch.searchData
	}
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        searchVisible, searchText
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LibraryList));
