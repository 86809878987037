import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import LoadingPopup from '../../controls/loadingPopup';
import http from '../../../service/httpService';
import { BaseService, C2CSwaggerAPI } from '../../../service/api';

import {
	APIKeyDetail,
	APIUsageGraph,
	KCApiGroups,
	CycleGraph,
	RegenerateConfirmBox,
	DescriptionHeader} from './components'
import { FormattedMessage } from 'react-intl';

const C2CSwaggerListView = () => {

	const [loaderIconVisible, setLoaderIconVisible] = useState(false)
	const [apiKeyDetail, setApiKeyDetail] = useState()
	const [previousCycleData, setPreviousCycleData] = useState();
	const [currentCycleData, setCurrentCycleData] = useState();
	const [showConfirmationBox, setShowConfirmationBox] = useState(false);

	const getApiDetails = async () => {
		try{
			setLoaderIconVisible(true);
			const cid = localStorage.getItem("customerId");
			const userId = localStorage.getItem("user")
			const response = await http({
				method: 'get',
				url: `${BaseService.root}${C2CSwaggerAPI.C2CAPIUsageDetailsV2}?customerId=${cid}&userId=${userId}`,
				data: {}
			});
			setLoaderIconVisible(false)
			console.log("Response ", response);
			if (response && response.data) {
				if(response.data.data){
					const {apiKey, usage} = response.data.data;
					if(usage){
						setPreviousCycleData(usage[0]);
						setCurrentCycleData(usage[1]);
					}
					if(apiKey){
						console.log(apiKey)
						setApiKeyDetail({ ...apiKey, secret: apiKey.key});
					}
				}
			}

		}catch(error){

		}
		
	}

	const generateApiKey = async () => {
		try{
			setLoaderIconVisible(true);
			const customerId = localStorage.getItem("customerId");
			const userId = localStorage.getItem("user")
			const apiKeyResponse = await http({
				method: 'post',
				url: `${BaseService.root}${C2CSwaggerAPI.C2CAPIGenerateAPIKeyV2}`,
				data: {customerId,userId}
			});
			setLoaderIconVisible(false)
			if(apiKeyResponse.data){
				const apiKey = apiKeyResponse.data.data;
				setApiKeyDetail({ ...apiKey, secret: apiKey.key});
			}
			

		}catch(error){
			setLoaderIconVisible(false)
		}
	}
	
	useEffect(()=>{
		document.body.style.backgroundColor = "#F2F2F2";
		getApiDetails();
	},[])
	const onConfirmHandler = async() => {
		await generateApiKey();
		setShowConfirmationBox(false)
    }

	return (
		<div id="adminWrapper">
			<LoadingPopup loading={loaderIconVisible} />
			<div id="page-content-wrapper">
				<div className="adminDivWrapper">
					<DescriptionHeader/>
					<APIKeyDetail {...apiKeyDetail} onGenerateApiKey={()=> setShowConfirmationBox(true)}/>
					{
						currentCycleData 
						&& previousCycleData
						&& <APIUsageGraph>
						{currentCycleData && <CycleGraph {...currentCycleData} cycle= {<FormattedMessage id="KC1606"/>}/>} 
						{previousCycleData && <CycleGraph {...previousCycleData} cycle= {<FormattedMessage id="KC1607"/>}/>} 
					</APIUsageGraph>
					}
					<KCApiGroups/>
				</div>
			</div>
			<RegenerateConfirmBox 
				open={showConfirmationBox} 
				onClose={()=> setShowConfirmationBox(false)}
				onConfirm={onConfirmHandler} />
			<UserManagementHeader headerNameStringID="KC0637" headerName="KC API Portal" activeClass="swaggerNav" />
		</div>
	)
}

export default C2CSwaggerListView;

