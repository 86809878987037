


import React, { Component } from 'react';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import { injectIntl,FormattedMessage } from 'react-intl';

class FrymasterLocale extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            locale: "Non-CE"
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
        this.onChangeLocale = this.onChangeLocale.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = localStorage.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            if (ObjSettingsFile.general.locale) {
                const locale = ObjSettingsFile.general.locale;
                this.setState({
                    locale: locale === 0 ? "Non-CE" : locale === 1 ? "CE" : locale === 2 ? "Japan" : locale === 3 ? "Belgium" : "Other"
                })
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    localValueSelection = (value) => {
        if (value === "Non-CE") {
            return 0;
        }
        if (value === "CE") {
            return 1;
        }
        if (value === "Japan") {
            return 2;
        }
        if (value === "Belgium") {
            return 3;
        }
        if (value === "Other") {
            return 4;
        }

    }

    onConfirmButtonClick = async () => {
        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        const locale = await this.localValueSelection(this.state.locale);
        ObjSettingsFile.general.locale = locale;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterGeneralSettings`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSettingsClick = (pathname) => {
        this.props.history.push({
            pathname,
            isEdit: false,
            type: "Settings",
            previouspath: "/fryMasterSettings"
        });
    }

    onChangeLocale = (e) => {
        this.setState({
            locale: e.target.value
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterGeneralSettings`);
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");
        const { locale } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Locale Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Locale Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Locale Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1417"/>
                                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="textWRadioBtnList">
                                                    <ul>
                                                        <li><FormattedMessage id="KC1418"/></li>
                                                        <li>
                                                            <div class="customRadioBtn" onChange={this.onChangeLocale}>
                                                                <input id="Non-CE" type="radio" checked={locale === "Non-CE"} name="Locale" value="Non-CE" />
                                                                <label for="Non-CE" value="Non-CE">&nbsp;</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1419"/></li>
                                                        <li>
                                                            <div class="customRadioBtn" onChange={this.onChangeLocale}>
                                                                <input id="CE" type="radio" checked={locale === "CE"} name="Locale" value="CE" />
                                                                <label for="CE" value="CE">&nbsp;</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1420"/></li>
                                                        <li>
                                                            <div class="customRadioBtn" onChange={this.onChangeLocale}>
                                                                <input id="Japan" type="radio" checked={locale === "Japan"} name="Locale" value="Japan" />
                                                                <label for="Japan" value="Japan">&nbsp;</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1421"/></li>
                                                        <li>
                                                            <div class="customRadioBtn" onChange={this.onChangeLocale}>
                                                                <input id="Belgium" type="radio" checked={locale === "Belgium"} name="Locale" value="Belgium" />
                                                                <label for="Belgium" value="Belgium">&nbsp;</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC0454"/></li>
                                                        <li>
                                                            <div class="customRadioBtn" onChange={this.onChangeLocale}>
                                                                <input id="Other" type="radio" checked={locale === "Other"} name="Locale" value="Other" />
                                                                <label for="Other" value="Other">&nbsp;</label>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Locale Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterLocale)
