import { trendArrowImages } from '../../../utils/dashboardRenderer';
import { format } from 'date-fns';
const moment = require('moment');

const _ = require('lodash');
const startOfWeek = require('date-fns/start_of_week');
const startOfMonth = require('date-fns/start_of_month');
const startOfQuarter = require('date-fns/start_of_quarter');
const endOfWeek = require('date-fns/end_of_week');
const endOfMonth = require('date-fns/end_of_month');
const endOfQuarter = require('date-fns/end_of_quarter');
const getMonth = require('date-fns/get_month');

const lesserThanOne = "<1";
const typeNumber = "number";

const clientFunctions = {
    topTenPieChartData: {
        transformResponse: ({ _clientResponse }) => {
            // Define product colors (replace with your actual colors)
            const productColors = ["#ff97cd", "#0083b3", "#7192f1", "#c10b6d", "#414548", "#eacd61", "#59a9ed", "#00eabf", "#ff3b84", "#e08e53"];

            const current = _clientResponse?.data?.data?.current?.length ? _clientResponse.data.data.current : [];
            // Transform the data
            const transformedData = current.map((currentItem, indx) => {
                // Get color based on product
                const color = productColors[indx]; // Default to black if color not found

                // Find corresponding item in previous array (if available)
                const previousItem = _clientResponse?.data?.data?.previous?.find((prevItem) => prevItem.product === currentItem.product) || 0;

                // Calculate trend
                const trend = previousItem ? (currentItem.count === previousItem.count ? 0 : currentItem.count > previousItem.count ? 1 : -1) : 0;

                // Create the new object
                return {
                    productID: currentItem.product,
                    counts: currentItem.count,
                    trend: trendArrowImages[trend === 1 ? "high" : trend === -1 ? "low" : ""],
                    color,
                    order: currentItem.count
                };
            });
            const sortedTransformData = transformedData.sort((a, b) => a.counts - b);
            return {
                data: {
                    value: sortedTransformData
                }
            }
        }
    },
    peakHourChartData: {
        transformResponse: ({ _clientResponse }) => {
            const timeFormat = localStorage.getItem("timeFormat");
            let peakHourDataV = _clientResponse?.data?.data?.peakHour; //peakHourData
            try {
                if (peakHourDataV.length === 0) {
                    return {
                        chartData: {
                            chartArr: [],
                            value: { "label": `0:00`, "value": 1400 },
                            text: ""
                        }
                    };
                }
                // Extract unique product names
                const products = [...new Set(peakHourDataV.flatMap(dataItem => dataItem.data.map(item => item.product)))];

                // Prepare Data for 24 Hours
                const result = Array.from({ length: 24 }, (_, i) => {
                    const hour = i + 1;
                    const hourData = peakHourDataV.find(data => data.hour + 1 === hour) || { data: [] };

                    const productCounts = Object.fromEntries(products.map(product => [product, 0]));
                    productCounts.value = 0;

                    hourData.data.forEach(item => {
                        productCounts[item.product] = item.count;
                        productCounts.value += item.count;
                    });

                    return { x: hour, ...productCounts };
                });

                // find peakhour
                let maxTotalCount = 0;
                let peakHour = 0;
                let timeValue = '';

                let chartData = result && result.length > 0 ? result : [];
                // Find maximum count
                for (let index = 0; index < chartData.length; index++) {
                    if (maxTotalCount < chartData[index].value) {
                        maxTotalCount = chartData[index].value;
                        peakHour = chartData[index].x;
                    }
                }
                peakHour = parseInt(peakHour);

                // Check AM or PM
                if (timeFormat === "12") {
                    timeValue = peakHour === 24 ? "am" : peakHour >= 12 ? "pm" : "am";
                    peakHour = peakHour === 24 ? 0 : peakHour > 12 ? peakHour - 12 : peakHour;
                }

                return {
                    chartData: {
                        chartArr: result,
                        value: { "label": `${peakHour}:00`, "value": 1400 },
                        text: timeValue
                    }
                };

            } catch (error) {
                throw error;
            }
        }
    },
    productChartData: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            let productData = _clientResponse?.data?.data?.productData || [];

            if (productData.length === 0) {
                return { chartData: { data: [], subTitleMessage: "0", setMaxPrecision: false } };
            }

            let totalCooks = 0, average = 0;
            const is24Hours = localStorage.getItem("timeFormat") === "24";
            const days = moment().daysInMonth();
            const daysInMonth =  Array.from({ length: days }, (_, i) => { return i + 1});
            const hours24 =  Array.from({ length: 24 }, (_, i) => { return `${i}-${i+1}`});
            const hours12 =  Array.from({ length: 24 }, (_, i) => { return  (i > 12)? `${i-12}-${i-11} pm` : (i === 12)? `12-1 pm` : `${i}-${i+1} am`});
            const months =  Array.from({ length: 12 }, (_, i) => { return moment().month(i).format('MMMM')});

            const xAxis = {
                hourly24: hours24,
                hourly12: hours12,
                Year: months,
                Quarter: months,
                Month: daysInMonth,
                Week: daysInMonth
            }

            totalCooks = _.sumBy(productData, 'cooks');

            //Get average cooks per day
            if (typeof (totalCooks) === typeNumber) {
                average = totalCooks / _widgetScope.dateFilterDays;
                average = average > 0 && average < 1 ? lesserThanOne : average.toFixed(0, 2);
            }

            if (_widgetScope.dateFilterValue.value !== "Day") {
                let dateFormat = (_widgetScope.dateFilterValue.value === "Week" || _widgetScope.dateFilterValue.value === "Month" || _widgetScope.dateFilterValue.value === "Custom") ? "D" : (_widgetScope.dateFilterValue.value === "Year" || _widgetScope.dateFilterValue.value === "Quarter") ? "MMMM" : "";
                productData = productData.map(item => {
                    let day = item.key.split('-')[2] || 1; //Setting default date to 1 for quarter and year type filter
                    let month = item.key.split('-')[1];
                    day = day && day.length === 1 ? '0' + day : day;
                    month = month && month.length === 1 ? '0' + month : month;
                    let date = item.key.split('-')[0] + '-' + month + '-' + day;
                    item.key = (moment(date).format(dateFormat));
                    return {
                        "x": item.key,
                        "y": item.cooks,
                        "alpha": 1
                    };
                });

            } else {
                if (is24Hours) {
                    productData = productData.map(item => {
                        item.key = item.key +1;
                        item.key = (item.key === 24 ? "23-24" : `${item.key - 1}-${item.key}`);
                        return {
                            "x": item.key,
                            "y": item.cooks,
                            "alpha": 1
                        };
                    });
                } else {
                    productData = productData.map(item => {
                        item.key = item.key +1;
                        item.key = (item.key === 12 ? "11-12 am" : item.key === 24 ? "11-12 pm" : item.key > 12 ? item.key === 13 ? "12-1 pm" : `${item.key - 13}-${item.key - 12} pm` : `${item.key - 1}-${item.key} am`);
                        item.alpha = 1;
                        return {
                            "x": item.key,
                            "y": item.cooks,
                            "alpha": 1
                        };
                    });
                }
            }

            let dataSet = [];
            if (_widgetScope.dateFilterValue.value === "Day") {
                let baseX = is24Hours ? xAxis.hourly24 : xAxis.hourly12;
                dataSet = baseX.map(item => {
                    return {
                        x: item,
                        y: productData.find(res => res.x == item)?.y || 0,
                        alpha: 1
                    }
                })
            } else {
                dataSet = xAxis[_widgetScope.dateFilterValue.value].map(item => {
                    return {
                        x: item,
                        y: productData.find(res => res.x == item)?.y || 0,
                        alpha: 1
                    }
                });
            }


            const todaysDate = new Date();
            let categoryStarts = null;
            let categoryEnds = null;
            if (_widgetScope.dateFilterValue.value === "Week") {
                let startDateMonth = getMonth(startOfWeek(todaysDate, { weekStartsOn: 1 }));
                let endDateMonth = getMonth(endOfWeek(todaysDate, { weekStartsOn: 1 }));
                let todayMonth = getMonth(todaysDate);

                if (startDateMonth < todayMonth && endDateMonth === todayMonth) {
                    categoryStarts = format(startOfMonth(todaysDate), 'D');
                    categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                }
                else if (startDateMonth === todayMonth && endDateMonth > todayMonth) {
                    categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                    categoryEnds = format(endOfMonth(todaysDate), 'D');
                }
                else {
                    categoryStarts = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                    categoryEnds = format(endOfWeek(todaysDate, { weekStartsOn: 1 }), 'D');
                }
            }
            else if (_widgetScope.dateFilterValue.value === "Quarter") {
                categoryStarts = format(startOfQuarter(todaysDate), 'MMMM');
                categoryEnds = format(endOfQuarter(todaysDate), 'MMMM');
            }
            return {
                chartData: {
                    data: dataSet,
                    subTitleMessage: average,
                    setMaxPrecision: true,
                    categoryStarts: categoryStarts,
                    categoryEnds: categoryEnds,
                    average
                }
            };

        }
    },
    avgProducts: {
        transformResponse: ({ _clientResponse, _widgetScope }) => {
            let currentAvg = 0, previousAvg = 0;
            let current = _clientResponse?.data?.data?.current;
            let previous = _clientResponse?.data?.data?.previous;
            if (typeof (current) === typeNumber) {
                currentAvg = current / _widgetScope.dateFilterDays;
                if (currentAvg > 0 && currentAvg < 1) {
                    currentAvg = lesserThanOne
                } else {
                    currentAvg = currentAvg.toFixed(0, 2);
                }
            }
            if (typeof (previous) === typeNumber) {
                previousAvg = previous / _widgetScope.dateFilterDays;
                if (previousAvg > 0 && previousAvg < 1) {
                    previousAvg = lesserThanOne
                } else {
                    previousAvg = previousAvg.toFixed(0, 2);
                }
            }
            return {
                data: {
                    data: {
                        current: currentAvg,
                        previous: previousAvg
                    }
                }
            }
        }
    },
    liveDiagnostic: {
        // add live diagnostic widget functions here
    }
};

export default clientFunctions;