import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import { injectIntl,FormattedMessage } from 'react-intl';
var recipeJSON = require('../../Templates/Frymaster/FrymasterRecipeJSON');

class FrymasterRecipeTypeSelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onMultiSegmentFlowClick = this.onMultiSegmentFlowClick.bind(this);
        this.onSingleSegmentFlowClick = this.onSingleSegmentFlowClick.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
    }

    onMultiSegmentFlowClick = () => {
        localStorage.setItem("selectedGroupID", 0);
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.isImport = false;
        recipeJSON.default.recipeSegmentType = "Multi";
        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
        localStorage.setItem("defaultLibraryData", JSON.stringify(recipeJSON.default));
        localStorage.setItem("libraryData", JSON.stringify(recipeJSON.default));
        this.props.history.push({
            pathname: "/frymasterRecipeCreation",
            isEdit: false,
            previouspath: "/frymasterRecipeEditor"
        });
    }

    onSingleSegmentFlowClick = () => {
        localStorage.setItem("selectedGroupID", 0);
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.isImport = false;
        recipeJSON.default.recipeSegmentType = "Single";
        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
        localStorage.setItem("defaultLibraryData", JSON.stringify(recipeJSON.default));
        localStorage.setItem("libraryData", JSON.stringify(recipeJSON.default));
        this.props.history.push({
            pathname: "/frymasterRecipeCreation",
            isEdit: false,
            previouspath: "/frymasterRecipeEditor"
        });
    }

    onBackButtonClick = () => {
        this.props.history.push(`/frymasterRecipeEditor`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { loaderIconVisible} = this.state;
        let brandid = localStorage.getItem("selectedbrandID");

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1363"/> 
                                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="rcSettingsLi01 fullWidth"> 
                                                    <ul> 
                                                        <li>
                                                            <div class="rcTypeConfrmatn">
                                                            <p><FormattedMessage id="KC1364"/></p>
                                                                <button class="noBtn" onClick={(e) => { this.onMultiSegmentFlowClick(e) }} type="button"><img src={require("../../../../../images/recipecreation/cancelButton.png")}  alt=""/></button> 
                                                                <button class="yesBtn" onClick={(e) => { this.onSingleSegmentFlowClick(e) }} type="button"><img src={require("../../../../../images/recipecreation/confirmButton.png")}  alt=""/></button>
                                                            </div>
                                                        </li>
                                                    </ul>   
                                                </div>  
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button> 
                                </div>  
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterRecipeTypeSelection)
