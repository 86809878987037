import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../service/httpService';
import { LocationInfo } from '../../service/api';
import { brandLocations } from '../../utils/imgContants';
import { LocationBuilder } from '../../utils/locationResponseBuilder';
import { setCurrentLocation } from '../../actions/organizationentity/entityactions';
import AllLocationHeaderLayout from '../layoutSection/allLocationHeaderLayout';
import appconstants from '../../utils/appConstants'
import { CUSTOMER_ID, BRAND_ID } from '../../utils/appConstants';
import { TableHeader } from '../controls/tableHeader';
import $ from 'jquery';
import NoRecordsFound from '../controls/noRecordsFound';

import { searchVisible, searchText } from '../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../globalSearchBar/selectOption';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import constant from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from '../controls/goToPagination';

const brandsLogo = brandLocations;

class LocationsView extends Component {

  nextPath(path) {
    this.props.history.push(path);
  }

  constructor(probs) {
    super(probs);
    this.state = {
      data: [],
      totalPages: null,
			loading: true,
			currentPage : 1,
			pageSize: 25,
			sortColumn:{path:"", order:"", sort_color:"#F2F2F2" },
      errorType: "",
	  searchData: null,
    }

    this.onSortedChange = this.onSortedChange.bind(this);
    this.navigateLocation = this.navigateLocation.bind(this);
  }

  componentDidMount = () =>{
		this.props.searchVisible(true);
		document.body.style.backgroundColor = "#000000";
		this.updateDimensions();
		const { currentPage, sortColumn } = this.state;
		this.loadData(currentPage, sortColumn);
	}

	async componentDidUpdate(prevState) {
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.setState({ loading: true,currentPage:1 },()=>{
				const { currentPage, sortColumn } = this.state;
				this.loadData(currentPage, sortColumn);
			});
           
		}
		window.addEventListener("resize", this.updateDimensions());
	}

	
    // componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}


	updateDimensions() {
		const windowHeight = $(window).height();
        const tableBody = $('.pageScrollbar');
        const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0 ;
        tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
	}

	/**
	 * Fetch server data by On-Demand
	 * Send current page number
	 * Send Sorted details
	 */
	loadData = async(currentPage, sortColumn) => {	
		try {
			const response = await this.requestData(currentPage, sortColumn);
			this.setState({
				data: response.rows,
				totalPages: response.pages,
				currentPage : currentPage,
				loading: response.showLoading,
			});
		} catch (error) {
			this.setState({ loading: false });
		}
	}

    /**
	 * When navigate new page this event will return current page number
	 * @param {*} currentPage 
	 */
    onPageChange = (currentPage)  =>{
		this.setState({ loading: true });
		this.loadData(currentPage);
    }
	
	/**
	 * When sort the record by On-Demand
	 * This event will return the sorted column details
	 */
	onSortedChange = (sortColumn) =>{
		this.setState({
			sortColumn: sortColumn,
			currentPage: 1
		});
		this.loadData(1, sortColumn);
	}

	/**
	 * Business login to fetch units by below constraints
	 * 1. Fetch by Brand specific units
	 * 2. Fetch by Sorted column details
	 * 3. Fetch by page wise
	 */
	requestData = async (currentPage, sortColumn) => {
		try {
		  const sortData = sortColumn ? sortColumn : this.state.sortColumn;
			const pageSize = this.state.pageSize;
			const sortType = sortData.order;
			const sortBy =  sortData.path;
			const page = currentPage - 1;

			let filterModel = this.props.filterlistInformtion.filterModel;
			const ffModel = localStorage.getItem(appconstants.filterKey);
			const filterModel1 = ffModel && JSON.parse(ffModel);
			if (filterModel1) {
				filterModel = filterModel1;
			}

			let selectedCountryList = filterModel.find(el => el.field_name === 'country');
			let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
			let selectedLocationList = filterModel.find(el => el.field_name === 'location');
			let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
			let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
			let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
			let selectedModelList = filterModel.find(el => el.field_name === 'model');
			let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
            let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
            let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

			let selectedCgids = [];
			let selectedCids = [];
			let selectedBrands = [];
			let selectedModels = [];
			let selectedMachineTypes = [];
            let selectedST = [];

			selectedCountryList.dataSource.map(c => {
				return selectedCgids.push(c.id)
			});

			if (selectedRegionList.dataSource.length > 0) {
				 selectedCgids = [];
			}

			selectedRegionList.dataSource.map(c => {
				return selectedCgids.push(c.cust_gp_id)
			});

			if (selectedLocationList.dataSource.length > 0) {
				selectedCgids = [];
			}

			selectedLocationList.dataSource.map(c => {
				return selectedCgids.push(c.cust_gp_id)
			});

			if (selectedWithinLocationList.dataSource.length > 0) {
				selectedCgids = [];
			}

			selectedWithinLocationList.dataSource.map(c => {
				return selectedCgids.push(c.cust_gp_id)
			});

			selectedCustomerList.dataSource.map(c => {
				return selectedCids.push(c.ID)
			});

			selectedBrandList.dataSource.map(c => {
				return selectedBrands.push(c.ID)
			});

			selectedModelList.dataSource.map(c => {
				return selectedModels.push(c.ID)
			});

			selectedMachineTypeList.dataSource.map(c => {
				return selectedMachineTypes.push(c.ID)
			});

			selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
                return selectedST.push(c.id)
            });

            selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
                return selectedST.push(c.id)
              });

			var d = document.getElementById("spannavNotifiStatus");
			let cgids = "";
			if (selectedCgids.length > 0) {
				cgids = selectedCgids.join(',');
			}
			else {
				cgids = localStorage.getItem("custGroupID");
			}

			if (selectedCgids.length > 0 || selectedST.length > 0|| selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
				d.className = "navNotifiStatus";
			}
			else {
				d.className = "";
			}
			let url = `${LocationInfo.list}`;
			let pageNumber = '?pageNum=' + (page + 1);
			let pageLimit = '&pageSize=' + pageSize;

			let order = page * pageSize;

			let { searchData } = this.state;
			let apiUrl = url + pageNumber + pageLimit;

			if (searchData && searchData.searchtext) {
				apiUrl = `${apiUrl}&searchText=${searchData.searchtext}`;
			}

			if (searchData) {
				let searchParam = {};

				Object.keys(searchData).map((item) => {
					if (item !== "searchtext") {
						return searchParam[item] = searchData[item];
					}
				})

				if (searchParam && Object.keys(searchParam).length) {
					apiUrl = `${apiUrl}&searchParams=${btoa(JSON.stringify(searchParam))}`;
				}
			}
			let headers = {
				'Content-Type': 'application/json',
				'cgid': cgids,
				'cid': CUSTOMER_ID === "-1" ? selectedCids.join(',') : CUSTOMER_ID,
				'brandid': selectedBrands.length > 0 ? selectedBrands.join(',') : BRAND_ID,
				'model': selectedModels.join(','),
				'machinetype': selectedMachineTypes.join(','),
				'sortby': sortBy,
				'sorttype': sortType
			}
            console.log("selectedST", selectedST);
            if(selectedST && selectedST.length){
                headers['tags'] = selectedST.join(',')
            }
			const response = await http.get(apiUrl,
				{
					headers,
					data: {}
				})

			let { locations, numberOfPages } = response.data;
			locations.map((item, i) => {
				item.order_id = ++order;
			});
			locations = LocationBuilder(locations);

			return {
				rows: locations,
				pages: numberOfPages,
				showLoading: false,
			};

		} catch (error) {
			return {
				rows: [],
				pages: 0,
				showLoading: false,
			};
		}
		
  };
  
  navigateLocation = (rowData) => {
    this.props.setCurrentLocation(rowData);
    if (rowData.order_id > 0) {
    //   this.props.history.push(`/dashBoardLocations/specificLocation?groupId=${rowData.location_id}`);
	//   this.props.history.push(`/dashBoardLocations/unitsView?groupId=${rowData.location_id}`);

	  this.props.history.push({
		pathname: "/dashBoardLocations/unitsView",
		groupId: rowData.location_id,
	});
    }
  }

  static getDerivedStateFromProps = (props) => ({...props});

  // woopra events
  locationEvent = () =>{
	WoopraEvents(`${constant.LIST_VIEW_LOCATION}`);
  }

  unitEvent = () =>{
	WoopraEvents(`${constant.LIST_VIEW_UNIT}`);
  }

  render() {

    const { data, totalPages, currentPage, sortColumn, loading } = this.state;
	const { formatMessage } = this.props.intl;

    let columns = [
			{ path :"", label:"#"},
			{ path :"", label:""},
			{ path:"location_name", labelStringID:"KC0050"},
			{ path:"country", labelStringID:"KC1045"}, 
			{ path:"city", labelStringID:"KC0098"},
			{ path:"street", labelStringID:"KC0099"},
			{ path:"total_Units", labelStringID:"KC0100"},
			{ path:"", label:"", isNbSp:true}
    ];
    
    return (
      <React.Fragment>
        <div id="wrapper">
					<div className={loading ? "loadingProgress showloader" : "loadingProgress"}>
                <div className="loadingProgressIn"></div>
          </div>
          <AllLocationHeaderLayout activeClass="ListviewA" filterKey={appconstants.filterKey} history={this.props.history} />
          <div id="page-content-wrapper" class="withSearchBar">
            <div className="pageTabHeader marginTop2px">
              <ul>
                <li>
                  <Link to="/locationsView" title={formatMessage({id:'KC0044'})} className="locationsView active" onClick={()=> this.locationEvent()}><FormattedMessage id='KC0044'/></Link>
                </li>
                <li>
                  <Link to="/unitsView" title={formatMessage({id:'KC0045'})} className="unitsView" onClick={()=> this.unitEvent()}><FormattedMessage id='KC0045'/></Link>
                </li>
                <li className="selectedBox">
                  <div className="selectedValBox">
                    <span className="errorBoxXXXXXXXX "></span>
                    <span className="warningBoxXXXXXXXX"></span>
                  </div>
                </li>
              </ul>
            </div>
            <div className="listLocations">
              <div className={data && data.length > 0 ? "listLocationsTable" : "displaynone"}>
              <TableHeader 
								 sortColumn ={sortColumn}
								 onSort={this.onSortedChange}
								 columns={columns}/>
								 </div>
								 <div className="pageScrollbar">
								 <div class="listLocationsTable">
								{
									data.map((item, i) => {
										const connectOpacity = item && item.CriticalOffline + item.NonCriticalOffline + item.NoErrorOffline === item.total_Units ? 0.5 : 1;
										let locationsGridErrorStaus = 'errorStaus', locationsGridwarningStaus = 'warningStaus',
                    locationsGridofflineStaus = 'offlineStaus', locationsGridNoErrorStaus = '';
										let classValue = "";
										if (item) {
											classValue = item ? ((item.CriticalOnline) > '0' ? locationsGridErrorStaus :(item.NonCriticalOnline > '0') ? locationsGridwarningStaus :
                      (item.CriticalOffline + item.NonCriticalOffline + item.NoErrorOffline === item.total_Units) ? locationsGridofflineStaus : locationsGridNoErrorStaus) : "";
										}
										const rowStyle = {
											className: classValue,
											opacity: connectOpacity
										};
										return (
											<ul style={rowStyle} 
											    class={`tBody ${rowStyle.className}`} 
											    onClick={()=> this.navigateLocation(item)}>
												<li>{item.order_id}</li>
												<li><img alt={item.Brand_Alt} src={item.Brand_Logo} /></li> 
												<li>{item.location_name} <span class="locationsListSM">{item.street}, {item.city}, {item.country}</span></li>
												<li>{item.country}</li>
												<li>{item.city}</li>
												<li>{item.street}</li>
												<li>{item.total_Units}</li>
												<li>{item.CriticalOffline + item.NonCriticalOffline + item.NoErrorOffline === item.total_Units ?
													<img alt={""} src={require('../../images/icons/offlineA.svg').default} /> : <span className='number'>{}</span>}
												</li>
											</ul>
										)
									})
								}
              </div>
			  <NoRecordsFound loaderIconVisible={loading} length={data !== undefined ? data.length : 0} classname={'norecordsfound'}  stringID={this.state.searchData && data && data.length === 0 ? "KC0853" : "KC0205"}/>	
			  					 
            </div>
			</div>
			{ totalPages && totalPages > 1 ? <GoToPagination
				isBlackBackground={true}
				totalPageNumber={totalPages}
				pageNumber={currentPage}
				navigateTo={(p) => this.onPageChange(p)} /> : null }
          </div>
        </div>
      </React.Fragment>
    )
  }
};

function mapStateToProps(state) {
  return {
    filterlistInformtion: state.filterModel,
    currentLocation: state.entityReducer.currentLocation,
    selectedCountries: state.filterInformation.selectedCountries,
    selectedRegions: state.filterInformation.selectedRegions,
    selectedLocations: state.filterInformation.selectedLocations,
    selectedCustomers: state.filterInformation.selectedCustomers,
    selectedBrands: state.filterInformation.selectedBrands,
    selectedModels: state.filterInformation.selectedModels,
    searchData: state.advanceSearch.searchData
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setCurrentLocation,searchVisible,searchText }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(LocationsView));
