


import React, { Component } from 'react';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import ErrorMoal from '../../../../../modal/error-model';
import { injectIntl,FormattedMessage } from 'react-intl';

class FrymasterTemperature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            temperature_unit: "true",
            display_temperature_button: "true",
            error_modal: {
                open: false,
                message: ""
            }
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onSettingsClick = this.onSettingsClick.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onConfirmButtonClick = this.onConfirmButtonClick.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
            let jsonSettingsFile = localStorage.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                temperature_unit: ObjSettingsFile.general.temperature.temperature_unit === 1 ? "false" : "true",
                display_temperature_button: ObjSettingsFile.general.temperature.display_temperature_button === 1 ? "true" : "false"
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        let { temperature_unit, display_temperature_button } = this.state;
        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);

        ObjSettingsFile.general.temperature.temperature_unit = temperature_unit === "true" ? 0 : 1;
        ObjSettingsFile.general.temperature.display_temperature_button = display_temperature_button === "true" ? 1 : 0;
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterGeneralSettings`);
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onSettingsClick = (pathname) => {
        this.props.history.push({
            pathname,
            isEdit: false,
            type: "Settings",
            previouspath: "/fryMasterSettings"
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterGeneralSettings`);
    }

    onToggleChange = (e, stateKey) => {
        this.setState({
            [stateKey]: e.toString()
        });
    }

    // This function is used to convert the temperature values in smart cook prompt and ecomode .
    convertAllTemperatureValues = (temperatureValue) => {
        const currentTemperature = temperatureValue === "true" ? "°F" : "°C";

        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);

        let smartCookValue = ObjSettingsFile.cooking.smart_cook_prompt.prompt_temperature;
        let ecoModeValue = ObjSettingsFile.eco_mode.temperature;

        if (currentTemperature === "°F") {
            smartCookValue = smartCookValue === 0 || smartCookValue === "" ? smartCookValue : (Number(smartCookValue) * 1.8) + 32;
            ecoModeValue = ecoModeValue === 0  || ecoModeValue === "" ? ecoModeValue : (Number(ecoModeValue) * 1.8) + 32;
        }
        if (currentTemperature === "°C") {
            smartCookValue = smartCookValue === 0  || smartCookValue === "" ? smartCookValue : (Number(smartCookValue) - 32) / 1.8;
            ecoModeValue = ecoModeValue === 0  || ecoModeValue === "" ? ecoModeValue : (Number(ecoModeValue) - 32) / 1.8;
        }

        ObjSettingsFile.cooking.smart_cook_prompt.prompt_temperature = Number.isInteger(smartCookValue) ? smartCookValue : Number(smartCookValue).toFixed(2);
        ObjSettingsFile.eco_mode.temperature = Number.isInteger(ecoModeValue)  ? ecoModeValue : Number(ecoModeValue).toFixed(2);
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryData", strSettingsFile);
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");
        const { temperature_unit, display_temperature_button, error_modal } = this.state;

        return (
            <React.Fragment>
                {/* Frymaster Temperature Header Page */}
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Temperature Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Temperature Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1289"/>
                                </div>

                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">

                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li><FormattedMessage id="KC1428"/></li>
                                                        <li class="smallFormFields">
                                                            <div class="btn-group-toggle" data-toggle="buttons">
                                                                <span class={temperature_unit === "false" ? "toggleOFF" : "toggleOFF disabled"}>°<FormattedMessage id="KC1981"/></span>
                                                                <Switch
                                                                    onChange={(e) => {
                                                                        this.onToggleChange(e, 'temperature_unit');
                                                                        this.convertAllTemperatureValues(e.toString())
                                                                    }}
                                                                    checked={temperature_unit === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={temperature_unit === "true" ? "toggleON" : "toggleON disabled"}>°<FormattedMessage id="KC1980"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="frymasterRCForm">
                                                    <ul class="borderNone">
                                                        <li class="width245px"><FormattedMessage id="KC1429"/></li>
                                                        <li class="arrowBtn">
                                                            <div class="btn-group-toggle" data-toggle="buttons"><span class={display_temperature_button === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC2016"/></span>
                                                                <Switch
                                                                    onChange={(e) => {
                                                                        this.onToggleChange(e, 'display_temperature_button')
                                                                    }}
                                                                    checked={display_temperature_button === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={display_temperature_button === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC2002"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Temperature Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterTemperature)
