import React, { Component } from 'react'; 
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelList } from '../../../../../controls/modelList';
import ErrorMoal from '../../../../../modal/error-model';
import $ from 'jquery';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
import { FormattedMessage, injectIntl } from 'react-intl';
require('jquery-mousewheel')

class FrymasterMenuAutoChange extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            categoryList: [],
            hourFormat: 'false',
            timeMask: [/[0-2]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            error_modal: {
                open: false,
                message: ""
            }
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
        this.closeModel = this.closeModel.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let categoryDetails = localStorage.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);

            let libraryDetails = localStorage.getItem("libraryDetails");
            let libraryDetailsObj = JSON.parse(libraryDetails);
            let menuDetails = [];

            if (libraryDetailsObj && libraryDetailsObj.autoMenuChange && libraryDetailsObj.autoMenuChange.autoMenuChangeValue.length) {
                libraryDetailsObj.autoMenuChange.autoMenuChangeValue.map((value) => {
                    menuDetails.push({
                        categoryId: value.id,
                        categoryName: this.getCategoryName(value.id, categoryDetailsObj),
                        mode: value.mode === "Off" ? "true" : "false",
                        timeFormat: value.timeFormat === "AM" ? "false" : "true",
                        time: value.time
                    })
                })
            } else {
                categoryDetailsObj.map((value) => {
                    menuDetails.push({
                        categoryId: value.ID,
                        categoryName: value.categoryName,
                        mode: "false",
                        timeFormat: 'false',
                        time: ''
                    })
                })

            }
            this.setState({
                categoryList: menuDetails,
                hourFormat: libraryDetailsObj && libraryDetailsObj.autoMenuChange ? libraryDetailsObj.autoMenuChange.hourFormat === "24 HR" ? "false" : "true" : 'false',
                selectedModel: parseInt(localStorage.getItem("selectedProductID"))
            });
        } catch (error) {
            //Error Goes Here
        }
    }

    getCategoryName = (id, categoryDetailsObj) => {
        const filterdCategory = categoryDetailsObj.filter((value) => value.ID === id);
        return filterdCategory.length ? filterdCategory[0].categoryName : ''
    }

    onConfirmButtonClick = () => {
        let { hourFormat, categoryList } = this.state;
        let jsonSettingsFile = localStorage.getItem("libraryDetails");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        let isValid = true;

        categoryList.map((i) => {
            if (isValid) {
                isValid = !i.time || i.time.includes("_") ? false : true;
            }
        })

        if (isValid) {
            if (!ObjSettingsFile.autoMenuChange) {
                ObjSettingsFile.autoMenuChange = {}
            }
    
            ObjSettingsFile.autoMenuChange.hourFormat = hourFormat === "true" ? "12 HR" : "24 HR";
            let autoMenuChangeValue = [];
    
            categoryList.map((i) => {
                autoMenuChangeValue.push({
                    "id": i.categoryId,
                    "mode": i.mode === "true" ? "Off" : "On",
                    "timeFormat": i.timeFormat === "true" ? "PM" : "AM",
                    "time": i.time
                })
            })
    
            ObjSettingsFile.autoMenuChange.autoMenuChangeValue = autoMenuChangeValue;
    
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            localStorage.setItem("libraryDetails", strSettingsFile);
            this.props.history.push(`/frymasterMenuEditor`);
        }
        else {
            this.setState({ 
                error_modal: {
                    open: true,
                    message: "KC1402"
                }
            });
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterMenuEditor`);
    }

    onToggleChange = (e, stateKey) => {
        this.setState({
            [stateKey]: e.toString()
        });

        if (stateKey === "hourFormat") {
            this.setState({
                timeMask: e.toString() === "true" ? [/[0-1]/, /[0-2]/, ':', /[0-5]/, /[0-9]/] : [/[0-2]/, /[0-3]/, ':', /[0-5]/, /[0-9]/],
                time: ''
            });
        }
    }

    updateValues = (value, key, id) => {
        const { categoryList } = this.state;
        let updateValue = categoryList;
        const findIndex = categoryList.findIndex(i => i.categoryId === id);
        updateValue[findIndex][key] = value;

        if (key === 'time') {
            let startsWith = value[0];

            this.setState({
                timeMask: [
                    this.state.hourFormat === "false" ? /[0-2]/ : /[0-1]/,
                    this.state.hourFormat === "true" && startsWith === "1" ? /[0-2]/ : this.state.hourFormat === "false" && startsWith === "2" ? /[0-4]/ : /[0-9]/,
                    ':',
                    value.substr(0, 2) === "12" || value.substr(0, 2) === "24" ? /[0-0]/ : /[0-5]/,
                    value.substr(0, 2) === "12" || value.substr(0, 2) === "24" ? /[0-0]/ : /[0-9]/
                ],
            })
        }

        this.setState({ categoryList: updateValue })
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");

        const { categoryList, hourFormat, timeMask, error_modal } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Menu Auto Change Page */}
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelList brandid={brandid} isModelEditable={true} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Menu Auto Change Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")} alt=""/>
                            </div>

                            {/* Frymaster Menu Auto Change Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1395"/>
                                </div>


                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn alignTop">
                                                <div class="frymasterRCForm autoMenuChanges">
                                                    <ul>
                                                        <li></li>
                                                        <li class="smallFormFields">
                                                            <div class="btn-group-toggle floatLeft" data-toggle="buttons">
                                                                <span class={hourFormat === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1403"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'hourFormat')}
                                                                    checked={hourFormat === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={hourFormat === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1404"/></span>
                                                            </div>

                                                            <span><FormattedMessage id="KC0144"/></span>
                                                        </li>
                                                    </ul>
                                                    {
                                                        categoryList.map((item, i) => {
                                                            return (
                                                                <ul class={categoryList.length === i + 1 ? "borderNone" : ""}>
                                                                    <li>
                                                                        <div class="btn-group-toggle" data-toggle="buttons">
                                                                            <span class={item.mode === "false" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1405"/></span>
                                                                            <Switch
                                                                                onChange={(e) => this.onToggleChange(e, 'mode', item.categoryId)}
                                                                                checked={item.mode === "true" ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />   <span class={item.mode === "true" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1360"/></span>
                                                                        </div> &nbsp; {item.categoryName}</li>
                                                                    <li class="smallFormFields">
                                                                        <div class={hourFormat === "true" ? "btn-group-toggle floatLeft" : "btn-group-toggle floatLeft disabled"} data-toggle="buttons">
                                                                            <span class={item.timeFormat === "false" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1406"/></span>
                                                                            <Switch
                                                                                onChange={(e) => this.updateValues(e.toString(), 'timeFormat', item.categoryId)}
                                                                                checked={item.timeFormat === "true" ? true : false}
                                                                                uncheckedIcon={false}
                                                                                checkedIcon={false}
                                                                                onColor={'#64c832'}
                                                                                offColor={'#c4c1c0'}
                                                                                offHandleColor={'#ffffff'}
                                                                                onHandleColor={'#ffffff'}
                                                                            />  <span class={item.timeFormat === "true" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1407"/></span>
                                                                        </div>
                                                                        <InputMask
                                                                            mask={timeMask}
                                                                            maskChar=""
                                                                            placeholder={hourFormat === "true" ? formatMessage({ id: "KC1973" }) : formatMessage({ id: "KC0836" })}
                                                                            onChange={(e) => this.updateValues(e.target.value, 'time', item.categoryId)}
                                                                            value={item.time}
                                                                            class="form-control" /> 
                                                                    </li>
                                                                </ul>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => this.onConfirmButtonClick()} class="btn saveButton">&nbsp;</button>
                                </div>
                            </div>
                            {/* Frymaster Menu Auto Change Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} message={error_modal.message} onCloseModel={this.closeModel} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterMenuAutoChange)
