import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { format } from 'date-fns';
import { ReportManagement } from '../../../service/api';
import http from '../../../service/httpService';
import {UNIT_ID, UNIT_BRAND_ID} from '../../../utils/appConstants';
import { FormattedMessage } from 'react-intl';

var moment = require('moment');

var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

const mappingStringID = {
    "Last Performed": "KC0493",
}
class SingleDataWidget extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            singleDataInformation: {},
            widgetname: '',
            value: '',
            key: 'Last Performed',
            holder_id: ''
        }
        this.onDropDownHandler = this.onDropDownHandler.bind(this); 
    }

    componentDidMount() {
        if (this.props.widgetName === 'OIL LIFE / NO. OF DISPOSES') {
            this.getFilterBasedData('1');
        }
        else {
            this.getReportData();
        }
    }

    getReportData() {
        let header;
        if(localStorage.getItem("brandName") === "FRYMASTER"){
            header = {
                'Content-Type': 'application/json',
                'unitid': UNIT_ID,
                'reqDate': todaysDateFormatted,
                'filterType': 'daily',
                'showtrend': "false"
            }
        /*Frymaster TLOV/JLOV model changes */
        const productName = localStorage.getItem("modelFamilyName");
        if(this.props.widgetID === "56" || this.props.widgetID === "58"){
        if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
            header.productModel = productName;
        }}
        }
        else {
            header = {
               'Content-Type': 'application/json',
               'unitid': UNIT_ID,
               'reqDate': todaysDateFormatted,
               'showtrend': "false"
           };
        }

        let url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + UNIT_BRAND_ID}`;
        this.getData(url, header).then(response => {
            this.setState({
                widgetname: response.singleDataInformation.widgetname,
                value: response.singleDataInformation && response.singleDataInformation.content[0] !== undefined ? response.singleDataInformation.content[0].value : "",
                key: response.singleDataInformation && response.singleDataInformation.content[0] !== undefined ? response.singleDataInformation.content[0].key : ""
            });
        }).catch(error => {
            console.log(error);
        });
    }

    getData(url, header) {
        return new Promise((resolve, reject) => {
            http
                .get(url,
                    {
                        headers: header,
                        data: {}
                    })
                .then(response => {
                    resolve({
                        singleDataInformation: response.data
                    })
                })
                .catch(error => {
                    reject({
                        singleDataInformation: {}
                    })
                });
        });
    }

    onDropDownHandler =(e)=>{
        this.getFilterBasedData(e.target.text);
    } 

    getFilterBasedData(cycle_id = '') {
        this.setState({holder_id : cycle_id});
        let header = {
                'Content-Type': 'application/json',
                'unitid': UNIT_ID,
                'reqDate': todaysDateFormatted,
                'filterType': 'daily',
                'showtrend': "false",
                'cycle': cycle_id
            }

        let url = `${ReportManagement.widgetAPI + '/' + this.props.widgetID + '/' + UNIT_BRAND_ID}`;
        this.getData(url, header).then(response => {
            this.setState({
                widgetname: response.singleDataInformation.widgetname,
                value: response.singleDataInformation && response.singleDataInformation.content[0] !== undefined ? response.singleDataInformation.content[0].value : "",
                key: response.singleDataInformation && response.singleDataInformation.content[0] !== undefined ? response.singleDataInformation.content[0].key : ""
            });
        }).catch(error => {
            console.log(error);
        });
    }

    render() {
        let { value, key } = this.state;
        let days = "";
        let hours = "";
        let mins = "";
        let frymasHotOQSCleanValue = null;
        let disposeFilter = null;
        if(localStorage.getItem("brandName") === 'FRYMASTER' && this.props.widgetName === 'OIL LIFE / NO. OF DISPOSES') {
            disposeFilter = (
            <div class="dropdown datepickerBtnGroup">
                <button class="btn btn-secondary dropdown-toggle dateRangeSelection" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {this.state.holder_id}
                </button>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                    <Link className="dropdown-item" to="#" onClick={(e) => this.onDropDownHandler(e)}>1</Link>
                    <Link className="dropdown-item" to="#" onClick={(e) => this.onDropDownHandler(e)}>2</Link>
                    <Link className="dropdown-item" to="#" onClick={(e) => this.onDropDownHandler(e)}>3</Link>
                    <Link className="dropdown-item" to="#" onClick={(e) => this.onDropDownHandler(e)}>4</Link>
                    <Link className="dropdown-item" to="#" onClick={(e) => this.onDropDownHandler(e)}>5</Link>
                </div>
            </div>
        )
      }

        let newNumberFormat =  <NumberFormat value={Math.round(value !== undefined ? value : 0)} displayType={'text'} thousandSeparator={true} />
        if (localStorage.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={Math.round(value !== undefined ? value : 0)} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        //Widget Display details
        let widgetValue = null;
        if (this.props.widgetName === "DOOR OPENINGS" || this.props.widgetName === "TOTAL COOKS SINCE MENU UPDATE") {
            widgetValue = (<div class="valH1Div">{newNumberFormat}</div>);
        }
        else if (this.props.widgetName === "OVEN TEMPERATURE" || this.props.widgetName === "CORE TEMPERATURE") {
            widgetValue = (<div class="valH1Div timeDiv">{value}  <span class="TimeRangeSpan"><FormattedMessage id="KC1874"/></span></div>);
        }
        else if (this.props.widgetName === 'HOT / COLD CLEAN' || this.props.widgetName === 'OQS FILTER' || this.props.widgetName === 'CLEAN & FILTER' || this.props.widgetName === 'MAINTENANCE' || this.props.widgetName === 'DEEP CLEAN') {
            widgetValue = (<div class="lastPerformedDiv">{ mappingStringID[key] ? <FormattedMessage id={mappingStringID[key]} /> : {key}} </div>);
            // widgetValue = (<div class="lastPerformedDiv">{key} </div>);
            if (localStorage.getItem("timeFormat") === "24" && this.props.widgetName !== 'HOT / COLD CLEAN'){
                value = value !== "--" ? moment(value).format('MMM D, YYYY HH:mm'):0;
            }
            frymasHotOQSCleanValue = (<div class="dateTimeDiv">{value ? value : 0}</div>);

        }
        else if (this.props.widgetName === 'OIL LIFE / NO. OF DISPOSES') {
            widgetValue = (<div class="valH1Div">{value ? value.toFixed(1) : 0} <FormattedMessage id="KC1894"/></div>); 
        }
        else if (localStorage.getItem("brandName") === "FRYMASTER") {
            frymasHotOQSCleanValue = (<div class="dateTimeDiv">{value ? value : 0}</div>);
        }
        else if (this.props.widgetName === "TOTAL ON TIME") {
            widgetValue = "";
        }
        else {
            try {
                days = value !== "" ? value.split(" ")[0].replace("days", "") : "0";
                hours = value !== "" ? value.split(" ")[1].replace("h", "") : "0";
                mins = value !== "" ? value.split(" ")[2].replace("m", "") : "0";
            }
            catch (err) {
                days = 0;
                hours = 0;
                mins = 0;
            }
            widgetValue = (<div class="valH1Div timeDiv">{days}<span class="TimeRangeSpan">{days < 2 ? <FormattedMessage id="KC1885"/> : <FormattedMessage id="KC1886"/>}</span> {hours}<span class="TimeRangeSpan"><FormattedMessage id="KC1898"/></span> {mins}<span class="TimeRangeSpan"><FormattedMessage id="KC1899"/></span></div>); 
        }
        //End Widget Display details
        return (
            <React.Fragment>
                <div class={this.props.classValue}>
                    <div class="colmDi">
                        <h4>
                            {this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
                            {disposeFilter}
                        </h4>
                        <div class="colmDiVal">
                            {widgetValue}
                            {frymasHotOQSCleanValue}
                            <div class="valH5Div">
                                &nbsp;
                                </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default SingleDataWidget;
