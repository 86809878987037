import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { injectIntl, FormattedMessage } from 'react-intl';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
const { v4: uuidv4 } = require('uuid')

class GarlandCookbookProduct extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,


            unit: '°F',
            cookingMethodology: '',
            tooColdLimitValue: '',
            tooHotLimitValue: '',

            grill_Plate_limit: 'Off',
            grillPlateLimitCheck: false,
            grillPlateLimitValue: '',

            grill_Front_limit: 'Off',
            grillFrontLimitCheck: false,
            grillFrontLimitValue: '',

            upper_Platen_limit: 'Off',
            upperPlatenLimitCheck: false,
            upperPlatenLimitValue: '',
            isChanged: false
        }
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        this.updateDimensions();
        $(".mCustomScrollbar").mCustomScrollbar();

        let EditCategoryData = JSON.parse(localStorage.getItem("ProductRecgDetails"));
        if (EditCategoryData) {
            this.setState({
                cookingMethodology: EditCategoryData.cookingMethodology,

                unit: EditCategoryData.unit || "°F",
                tooColdLimitValue: EditCategoryData && EditCategoryData.tooColdLimitValue ? EditCategoryData.tooColdLimitValue :  0,
                tooHotLimitValue: EditCategoryData && EditCategoryData.tooHotLimitValue ? EditCategoryData.tooHotLimitValue :  0,

                grill_Plate_limit: EditCategoryData.grill_Plate_limit || "Off",
                grillPlateLimitCheck: EditCategoryData.grillPlateLimitCheck,
                grillPlateLimitValue: EditCategoryData.grillPlateLimitValue,

                grill_Front_limit: EditCategoryData.grill_Front_limit || "Off",
                grillFrontLimitCheck: EditCategoryData.grillFrontLimitCheck,
                grillFrontLimitValue: EditCategoryData.grillFrontLimitValue,

                upper_Platen_limit: EditCategoryData.upper_Platen_limit || "Off",
                upperPlatenLimitCheck: EditCategoryData.upperPlatenLimitCheck,
                upperPlatenLimitValue: EditCategoryData.upperPlatenLimitValue,

                selectedMenuRecipes: EditCategoryData.recipe_items,
            })
        }
    }

    updateDimensions = () => {
        var windowHeight = $(window).height();
        var menuFilterHeader = $(".menuFilterHeader").height();
        var pageRRHeight = ((windowHeight) - (menuFilterHeader)) - 105;
        $(".readyRecipeSrlTable").css("height", pageRRHeight);

    }

    c2f = (celsius) => {
        return Math.round((1.8 * celsius) + 32);
    }

    f2c = (fahrenheit) => {
        return Math.round((fahrenheit - 32) / 1.8);
    }

    c2fGrill = (celsius) => {
        return Math.round(1.8 * celsius);
    }

    f2cGrill = (fahrenheit) => {
        return Math.round(fahrenheit / 1.8);
    }

    processNext = () => {
        let { unit, grill_Plate_limit, grillPlateLimitCheck, grillPlateLimitValue, grill_Front_limit, grillFrontLimitCheck, grillFrontLimitValue } = this.state;
        let { upper_Platen_limit, upperPlatenLimitCheck, upperPlatenLimitValue, cookingMethodology, tooColdLimitValue, tooHotLimitValue, selectedMenuRecipes } = this.state;
        this.setState({
            loaderIconVisible: true
        });
        if (unit === "°F" && tooColdLimitValue !== "") {
            if (Number(tooColdLimitValue) >= 0 && Number(tooColdLimitValue) <= 100) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter too cold limit value between 0 to 100 F.'
                    },
                    loaderIconVisible: false
                });
                return;
            }
        }
        if (unit === "°F" && tooHotLimitValue !== "") {
            if (Number(tooHotLimitValue) >= 0 && Number(tooHotLimitValue) <= 100) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter too hot limit value between 0 to 100 F.'
                    },
                    loaderIconVisible: false
                });
                return;
            }
        }
        if (unit === "°C" && tooColdLimitValue !== "") {
            if (Number(tooColdLimitValue) >= 0 && Number(tooColdLimitValue) <= 56) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter too cold limit value between 0 to 56 C.'
                    },
                    loaderIconVisible: false
                });
                return;
            }
        }
        if (unit === "°C" && tooHotLimitValue !== "") {
            if (Number(tooHotLimitValue) >= 0 && Number(tooHotLimitValue) <= 56) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter too hot limit value between 0 to 56 C.'
                    },
                    loaderIconVisible: false
                });
                return;
            }
        }
        if (unit === "°F" && upper_Platen_limit !== "Off") {
            if (Number(upper_Platen_limit) >= 149 && Number(upper_Platen_limit) <= 450) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Upper Platen Temp value between 149 to 450 C.'
                    },
                    loaderIconVisible: false
                });
                return;
            }
        }
        if (unit === "°F" && grill_Plate_limit !== "Off") {
            if (Number(grill_Plate_limit) >= 149 && Number(grill_Plate_limit) <= 400) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Grill Plate Temp limit value between 149 to 400 F.'
                    },
                    loaderIconVisible: false,
                });
                return;
            }
        }
        if (unit === "°F" && grill_Front_limit !== "Off") {
            if (Number(grill_Front_limit) >= 149 && Number(grill_Front_limit) <= 400) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Grill Front Temp limit value between 149 to 400 F.'
                    },
                    loaderIconVisible: false
                });
                return;
            }
        }
        if (unit === "°C" && grill_Plate_limit !== "Off") {
            if (Number(grill_Plate_limit) >= 65 && Number(grill_Plate_limit) <= 204) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Grill Plate Temp limit value between 65 to 204 C.'
                    },
                    loaderIconVisible: false
                });
                return;
            }
        }
        if (unit === "°C" && grill_Front_limit !== "Off") {
            if (Number(grill_Front_limit) >= 65 && Number(grill_Front_limit) <= 204) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Grill Front Temp limit value between 65 to 204 C.'
                    },
                    loaderIconVisible: false
                });
                return;
            }
        }
        if (unit === "°C" && upper_Platen_limit !== "Off") {
            if (Number(upper_Platen_limit) >= 65 && Number(upper_Platen_limit) <= 232) {
            } else {
                this.setState({
                    error_modal: {
                        open: true,
                        message: 'Please enter Upper Platen Temp value between 65 to 232 C.'
                    },
                    loaderIconVisible: false
                });
                return;
            }
        }
        let categoryObj = localStorage.getItem("ProductRecgDetails") ? JSON.parse(localStorage.getItem("ProductRecgDetails")) : {};

        categoryObj.unit = unit;
        categoryObj.grill_Plate_limit = grill_Plate_limit;
        categoryObj.grillPlateLimitCheck = grillPlateLimitCheck;
        categoryObj.grillPlateLimitValue = grillPlateLimitValue;
        categoryObj.grill_Front_limit = grill_Front_limit;
        categoryObj.grillFrontLimitCheck = grillFrontLimitCheck;
        categoryObj.grillFrontLimitValue = grillFrontLimitValue;
        categoryObj.upper_Platen_limit = upper_Platen_limit;
        categoryObj.upperPlatenLimitCheck = upperPlatenLimitCheck;
        categoryObj.upperPlatenLimitValue = upperPlatenLimitValue;
        categoryObj.cookingMethodology = cookingMethodology;
        categoryObj.tooColdLimitValue = tooColdLimitValue;
        categoryObj.tooHotLimitValue = tooHotLimitValue;

        categoryObj.recipe_items = selectedMenuRecipes;

        categoryObj.recipeID = categoryObj && categoryObj.recipeID && categoryObj.recipeID !== "" ? categoryObj.recipeID : uuidv4();
        categoryObj.recipeName = categoryObj && categoryObj.recipeName && categoryObj.recipeName !== "" ? categoryObj.recipeName : "Auto";

        localStorage.setItem("ProductRecgDetails", JSON.stringify(categoryObj));
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: true,
                message: this.state.isEditCategory ? "KC2287" : "KC2286"
            }
        });

        this.props.history.push({
            pathname: "/garlandCookbookProductCreation",
            isEdit: false,
            previouspath: "/garlandCookbookProduct"
        });
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
        localStorage.removeItem("isEditCategory")
        localStorage.removeItem("EditCategoryData")
        this.props.history.push({
            pathname: "/garlandCookbookEditor",
            isEdit: false,
            previouspath: "/cookbookAddCategory"
        });
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    tooColdChange = (e) => {
        const maxValue = this.state.unit === "°F" ? 100 : 56;
        if (e && e.target && e.target.value && (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
            return;
        }
        this.setState({
            tooColdLimitValue: e.target.value, isChanged: true
        });
    }

    checkTheLimitVal= (e) => {
        const maxValue = this.state.unit === "°F" ? 100 : 56;
        const minValue = this.state.unit === "°F" ? 0 : 0;
        if (e && e.target && e.target.value ) {
            if (Number(e.target.value) > maxValue || Number(e.target.value) < minValue) {
                let name = e.target.TooColdLimit;
                let msgDet; 
                if (name === "TooColdLimit"){
                    this.setState({
                        tooColdLimitValue:"",
                    })
                    msgDet = this.state.unit === "°F" ? 'Please enter too cold limit value between 0 to 100 F.' : 'Please enter too cold limit value between 0 to 56 C.'                    
                } else {
                    this.setState({
                        tooHotLimitValue:"",
                    })
                    msgDet = this.state.unit === "°F" ? 'Please enter too hot limit value between 0 to 100 F.' : 'Please enter too hot limit value between 0 to 56 C.'
                }
                this.setState({
                    error_modal: {
                        open: true,
                        message: msgDet,
                    },                    
                });
            }
        }
    }

    tooHotChange = (e) => {
        const maxValue = this.state.unit === "°F" ? 100 : 56;
        if (e && e.target && e.target.value && (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
            return;
        }
        this.setState({
            tooHotLimitValue: e.target.value, isChanged: true
        });
    }

    OnDiscard = () => {
        localStorage.setItem('ProductRecgDetails', "{}")
        localStorage.setItem('selectedPRRecipes', "{}")
        this.props.history.push({
            pathname: "/garlandCookbookEditor",
            isEdit: false,
            previouspath: "/garlandCookbookProduct"
        });
    }
    OnCancel = () => {
        this.setState({
            showDiscardPopup: false
        })
    }

    backOnClick = () => {
        const { isChanged } = this.state;
        if (isChanged) {
            this.setState({ showDiscardPopup: true })
        } else {
            this.props.history.push({
                pathname: "/garlandCookbookEditor",
                previouspath: "/garlandCookbookProduct",
            });
        }
    }

    IsAlphaNumeric(e) {
        e.target.value = e.target.value.trim().replace(/[^\d]/g, '');
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, loaderIconVisible, showDiscardPopup } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];

        let { unit, grill_Plate_limit, grillPlateLimitCheck, grillPlateLimitValue, grill_Front_limit, grillFrontLimitCheck, grillFrontLimitValue, error_modal, success_modal } = this.state;
        let { upper_Platen_limit, upperPlatenLimitCheck, upperPlatenLimitValue, cookingMethodology, tooColdLimitValue, tooHotLimitValue } = this.state;
        const procedNextStep = tooColdLimitValue !== "" && tooHotLimitValue !== "" && cookingMethodology !== "" && unit !== "" && grill_Plate_limit !== "" && grill_Front_limit !== "" && upper_Platen_limit !== "";

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658" /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li className="selectedModel">ME, MG</li>
                            </ul>
                        </div>

                        <div className="rcDeviceWrapper">
                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} /></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={this.backOnClick} className="btn backButton">&nbsp;</button>
                                    Product Recognition
                                    <button type="button" disabled={!procedNextStep} onClick={() => this.processNext()} className="btn nextButton">&nbsp;</button>
                                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn">
                                                <div className="rcSaveForm">
                                                    <div className="rcCNameDiv productRecgnForm">
                                                        <div className="rcCreateFrm">
                                                            <ul>
                                                                <li className="cookingMethodology">
                                                                    <label>Cooking Methodology</label>
                                                                    <select className="form-control mediumWidth" value={cookingMethodology} onChange={(e) => { this.setState({ cookingMethodology: e.target.value, isChanged: true }) }}>
                                                                        <option value="Steel">Steel</option>
                                                                        <option value="Standard">Standard</option>
                                                                        <option value="Steel / Water">Steel / Water</option>
                                                                        <option value="LRS / Water">LRS / Water</option>
                                                                    </select>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="rcCreateFrm">
                                                            <ul>
                                                                <li className="form3colm">
                                                                    <label>Unit</label>
                                                                    <select className="form-control minWidth" value={unit}
                                                                        onChange={(e) => {
                                                                            const { grill_Plate_limit, grill_Front_limit, upper_Platen_limit, tooColdLimitValue, tooHotLimitValue } = this.state;

                                                                            if (tooColdLimitValue && tooColdLimitValue !== "" && tooColdLimitValue !== 0 && tooColdLimitValue !== "") {
                                                                                const upperValueConverted = e.target.value === "°C" ? this.f2cGrill(tooColdLimitValue) : this.c2fGrill(tooColdLimitValue) && Number(this.c2fGrill(tooColdLimitValue)) > 100 ? 100 : this.c2fGrill(tooColdLimitValue);
                                                                                this.setState({ tooColdLimitValue: upperValueConverted });
                                                                            }

                                                                            if (tooHotLimitValue && tooHotLimitValue !== "" && tooHotLimitValue !== 0 && tooHotLimitValue !== "") {
                                                                                const upperValueConverted = e.target.value === "°C" ? this.f2cGrill(tooHotLimitValue) : this.c2fGrill(tooHotLimitValue) && Number(this.c2fGrill(tooHotLimitValue)) > 100 ? 100 : this.c2fGrill(tooHotLimitValue);
                                                                                this.setState({ tooHotLimitValue: upperValueConverted });
                                                                            }

                                                                            if (upper_Platen_limit && upper_Platen_limit !== "Off" && upper_Platen_limit !== 0 && upper_Platen_limit !== "") {
                                                                                const upperValueConverted = e.target.value === "°C" ? this.f2c(upper_Platen_limit) : this.c2f(upper_Platen_limit);
                                                                                this.setState({ upper_Platen_limit: upperValueConverted, upperPlatenLimitValue: upperValueConverted });
                                                                            }

                                                                            if (grill_Plate_limit && grill_Plate_limit !== "Off" && grill_Plate_limit !== 0 && grill_Plate_limit !== "") {
                                                                                const valueConverted = e.target.value === "°C" ? this.f2c(grill_Plate_limit) : this.c2f(grill_Plate_limit);
                                                                                this.setState({ grill_Plate_limit: valueConverted, grillPlateLimitValue: valueConverted });
                                                                            }
                                                                            if (grill_Front_limit && grill_Front_limit !== "Off" && grill_Front_limit !== 0 && grill_Front_limit !== "") {
                                                                                const hotValueConverted = e.target.value === "°C" ? this.f2c(grill_Front_limit) : this.c2f(grill_Front_limit);
                                                                                this.setState({ grill_Front_limit: hotValueConverted, grillFrontLimitValue: hotValueConverted });
                                                                            }
                                                                            this.setState({ unit: e.target.value, isChanged: true })
                                                                        }}>
                                                                        {["°F", "°C"].map((unit, idx) => {
                                                                            return (
                                                                                <option key={idx} value={unit}>{unit}</option>
                                                                            )
                                                                        })}
                                                                    </select>
                                                                </li>
                                                                <li className="form3colm">
                                                                    <label>Too Cold Limit</label>
                                                                    <input type="number" name = "TooColdLimit" className="form-control normalWidth" value={tooColdLimitValue} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}  onKeyUp={(e) => this.IsAlphaNumeric(e)} onChange={(e) => { this.tooColdChange(e) }} />
                                                                </li>
                                                                <li className="form3colm">
                                                                    <label>Too Hot Limit</label>
                                                                    <input type="number" name="TooHotLimit" className="form-control normalWidth" value={tooHotLimitValue} onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}  onKeyUp={(e) => this.IsAlphaNumeric(e)} onChange={(e) => { this.tooHotChange(e) }} />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <div className="rcCreateFrm">
                                                            <ul>
                                                                <li className='form3colm'>
                                                                    <label>Upper Platen Temp</label>
                                                                    <div className="dropdown setValDropdown">
                                                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButtonUpper" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            {upper_Platen_limit}
                                                                        </button>
                                                                        <div className="dropdown-menu topView dropdown-menu-left" aria-labelledby="dropdownMenuButtonUpper">
                                                                            <div className="setValD">
                                                                                <input type="number" value={upperPlatenLimitValue} className="form-control normalWidth"
                                                                                    onKeyUp={(e) => this.IsAlphaNumeric(e)}  
                                                                                    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                                    onChange={(e) => {
                                                                                        const maxValue = unit === "°F" ? 450 : 232;
                                                                                        if (e && e.target && e.target.value &&  (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
                                                                                            return;
                                                                                        }
                                                                                        const isPassed = unit === "°F" ? Number(e.target.value) >= 149 && Number(e.target.value) <= 450 : Number(e.target.value) >= 65 && Number(e.target.value) <= 232;
                                                                                        this.setState({
                                                                                            upperPlatenLimitValue: e.target.value,
                                                                                            upperPlatenLimitCheck: isPassed,
                                                                                            isChanged: true
                                                                                        })
                                                                                    }
                                                                                    } />
                                                                                <div className={`customCheckbox ${unit === "°F" ? upperPlatenLimitValue >= 149 && upperPlatenLimitValue <= 450 ? "" : "disabled" : upperPlatenLimitValue >= 65 && upperPlatenLimitValue <= 232 ? "" : "disabled"}`}>
                                                                                    <input id="TempSetU" name="TempSetU" type="checkbox" checked={upperPlatenLimitCheck} value="TempSetU"
                                                                                        onChange={() => {
                                                                                            if (!upperPlatenLimitValue || upperPlatenLimitValue === "") {
                                                                                                return;
                                                                                            }
                                                                                            this.setState({
                                                                                                upperPlatenLimitCheck: upperPlatenLimitValue && upperPlatenLimitValue !== "" ? true : false,
                                                                                                isChanged: true,
                                                                                                upper_Platen_limit: upperPlatenLimitValue && upperPlatenLimitValue !== "" ? upperPlatenLimitValue : "Off"
                                                                                            });
                                                                                        }
                                                                                        } />
                                                                                    <label for="TempSetU"></label>
                                                                                </div>
                                                                            </div>
                                                                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => this.setState({ upper_Platen_limit: 'Off', upperPlatenLimitCheck: false, upperPlatenLimitValue: '', isChanged: true })}>Off</a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className='form3colm'>
                                                                    <label>Grill Plate Temp</label>
                                                                    <div className="dropdown setValDropdown">
                                                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButtonCold" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            {grill_Plate_limit}
                                                                        </button>
                                                                        <div className="dropdown-menu topView dropdown-menu-left" aria-labelledby="dropdownMenuButtonCold">
                                                                            <div className="setValD">
                                                                                <input type="number" value={grillPlateLimitValue} className="form-control normalWidth"
                                                                                    onKeyUp={(e) => this.IsAlphaNumeric(e)}  
                                                                                    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                                    onChange={(e) => {
                                                                                        const maxValue = unit === "°F" ? 400 : 204;
                                                                                        if (e && e.target && e.target.value &&  (e.target.value.length > 3 || Number(e.target.value) > maxValue)) {
                                                                                            return;
                                                                                        }
                                                                                        const isPassed = unit === "°F" ? Number(e.target.value) >= 149 && Number(e.target.value) <= 400 : Number(e.target.value) >= 65 && Number(e.target.value) <= 204;
                                                                                        this.setState({
                                                                                            grillPlateLimitValue: e.target.value,
                                                                                            grillPlateLimitCheck: isPassed,
                                                                                            isChanged: true
                                                                                        })
                                                                                    }
                                                                                    } />
                                                                                <div className={`customCheckbox ${unit === "°F" ? grillPlateLimitValue >= 149 && grillPlateLimitValue <= 400 ? "" : "disabled" : grillPlateLimitValue >= 65 && grillPlateLimitValue <= 204 ? "" : "disabled"}`}>
                                                                                    <input id="TempSetV" name="TempSetV" type="checkbox" checked={grillPlateLimitCheck} value="TempSetV"
                                                                                        onChange={() => {
                                                                                            if (!grillPlateLimitValue || grillPlateLimitValue === "") {
                                                                                                return;
                                                                                            }
                                                                                            this.setState({
                                                                                                grillPlateLimitCheck: grillPlateLimitValue && grillPlateLimitValue !== "" ? true : false,
                                                                                                isChanged: true,
                                                                                                grill_Plate_limit: grillPlateLimitValue && grillPlateLimitValue !== "" ? grillPlateLimitValue : "Off"
                                                                                            });
                                                                                        }
                                                                                        } />
                                                                                    <label for="TempSetV"></label>
                                                                                </div>
                                                                            </div>
                                                                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => this.setState({ grill_Plate_limit: 'Off', grillPlateLimitCheck: false, grillPlateLimitValue: '', isChanged: true })}>Off</a>
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                                <li className='form3colm'>
                                                                    <label>Grill Front Temp</label>
                                                                    <div className="dropdown setValDropdown">
                                                                        <button className="btn dropdown-toggle" type="button" id="dropdownMenuButtonHot" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            {grill_Front_limit}
                                                                        </button>
                                                                        <div className="dropdown-menu topView dropdown-menu-left" aria-labelledby="dropdownMenuButtonHot">
                                                                            <div className="setValD">
                                                                                <input type="number" value={grillFrontLimitValue} className="form-control normalWidth"
                                                                                    onKeyUp={(e) => this.IsAlphaNumeric(e)}  
                                                                                    onKeyDown={(e) =>["e", "E", "+", "-"].includes(e.key) && e.preventDefault()} 
                                                                                    onChange={(e) => {
                                                                                        const maxValue = unit === "°F" ? 400 : 204;
                                                                                        if (e && e.target && e.target.value && (e.target.value.length > 3 ||  Number(e.target.value) > maxValue)) {
                                                                                            return;
                                                                                        }
                                                                                        const isPassed = unit === "°F" ? Number(e.target.value) >= 149 && Number(e.target.value) <= 400 : Number(e.target.value) >= 65 && Number(e.target.value) <= 204;
                                                                                        this.setState({
                                                                                            grillFrontLimitValue: e.target.value,
                                                                                            grillFrontLimitCheck: isPassed,
                                                                                            isChanged: true
                                                                                        })
                                                                                    }
                                                                                    } />
                                                                                <div className={`customCheckbox ${unit === "°F" ? grillFrontLimitValue >= 149 && grillFrontLimitValue <= 400 ? "" : "disabled" : grillFrontLimitValue >= 65 && grillFrontLimitValue <= 204 ? "" : "disabled"}`}>
                                                                                    <input id="TempSetHot" name="TempSetHot" type="checkbox" checked={grillFrontLimitCheck} value="TempSetHot"
                                                                                        onChange={() => {
                                                                                            if (!grillFrontLimitValue || grillFrontLimitValue === "") {
                                                                                                return;
                                                                                            }
                                                                                            this.setState({
                                                                                                grillFrontLimitCheck: grillFrontLimitValue && grillFrontLimitValue !== "" ? true : false,
                                                                                                isChanged: true,
                                                                                                grill_Front_limit: grillFrontLimitValue && grillFrontLimitValue !== "" ? grillFrontLimitValue : "Off"
                                                                                            });
                                                                                        }
                                                                                        } />
                                                                                    <label for="TempSetHot"></label>
                                                                                </div>
                                                                            </div>
                                                                            <a className="dropdown-item" href="javascript:void(0);" onClick={() => this.setState({ grill_Front_limit: 'Off', grillFrontLimitCheck: false, grillFrontLimitValue: '', isChanged: true })}>Off</a>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218" />
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={this.OnCancel} data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={this.OnDiscard} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment >
        )
    }
}
export default injectIntl(GarlandCookbookProduct)