import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { injectIntl, FormattedMessage } from 'react-intl';
import $ from 'jquery';
import { Link } from 'react-router-dom';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'

class GarlandCookbookCreation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            menuFileName: "",
            isChanged: false,
            showDiscardPopup: false,
            isEdit:false,

        }
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        this.updateDimensions();
        $(".mCustomScrollbar").mCustomScrollbar();

        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        this.setState({
            menuFileName: localStorage.getItem("cookBookFileName") ? localStorage.getItem("cookBookFileName") : "",
            isChanged : localStorage.getItem("cookBookFileName") ? true : false,            
            isEdit: libraryDetails && libraryDetails.isEdit ? libraryDetails.isEdit : false,
        })
    }

    updateDimensions = () => {
        var windowHeight = $(window).height();
        var menuFilterHeader = $(".menuFilterHeader").height();
        var pageRRHeight = ((windowHeight) - (menuFilterHeader)) - 105;
        $(".readyRecipeSrlTable").css("height", pageRRHeight);

    }

    processNext = () => {
        this.setState({
            loaderIconVisible: true
        });
        let menuFileName = this.state.menuFileName ? this.state.menuFileName.trim() : "";
        if (menuFileName.length < 1) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1247"
                }
            });
        }
        localStorage.setItem("cookBookFileName", menuFileName);
        this.props.history.push({
            pathname: "/garlandCookbookEditor",
            isEdit: false,
            previouspath: "/garlandCookbookCreation"
        });
    }

    menuFileNameChange = (e) => {
        if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            this.setState({
                menuFileName: e.target.value,
                isChanged: true
            });
        }
    }

    OnDiscard = () => {
        localStorage.setItem("cookBookFileName", "");
		localStorage.setItem("ProductRecgDetails", "{}");
		localStorage.setItem("selectedPRRecipes", "{}");
        localStorage.setItem("resourceDetails", "[]");
        localStorage.setItem("libraryDetails", "{}");
        this.props.history.replace(this.state.isEdit ? '/libraryList' : '/garlandRecipeEditor')
    }
    OnCancel = () => {
        this.setState({
            showDiscardPopup: false
        })
    }

    backOnClick = () => {
        const { isChanged, isImageChanged } = this.state;
        if (isChanged || isImageChanged) {
            this.setState({ showDiscardPopup: true })
        } else {
            localStorage.removeItem("cookBookFileName")
            this.props.history.push({
                pathname: "/garlandRecipeEditor",
                // isEdit: false,
                previouspath: "/garlandCookbookCreation"
            });
        }

    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, loaderIconVisible, menuFileName, showDiscardPopup } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658" /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li className="selectedModel">ME, MG</li>
                            </ul>
                        </div>

                        <div className="rcDeviceWrapper">
                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} /></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={() => this.backOnClick()} className="btn backButton">&nbsp;</button>
                                    Menu Creation
                                    <button type="button" onClick={() => this.processNext()} className={menuFileName && menuFileName.trim() && menuFileName.trim() !== "" ? "btn nextButton" : "btn nextButton disabled"}>&nbsp;</button>
                                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn alignTop">
                                                <div className="rcSaveForm">
                                                    <div className="rcCNameDiv">
                                                        <div className="rcCreateFrm">
                                                            <ul>
                                                                <li>
                                                                    <label>Enter File Name</label>
                                                                    <input type="text" maxLength="25" className="form-control" value={menuFileName} onChange={(e) => { this.menuFileNameChange(e) }} />
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <!-- Discard Confirmation Modal --> */}
                        <div className={showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                            <div className="modal-dialog modal-md">
                                <div className="modal-content">
                                    <div className="modal-body">
                                        <div className="deleteConfirmation">
                                            <FormattedMessage id="KC1218" />
                                        </div>
                                    </div>
                                    <div className="modal-footer textAlignCenter">
                                        <button className="btn btn-secondary deleteNoBtn" onClick={this.OnCancel} data-dismiss="modal" type="button"><FormattedMessage id='KC0580' /></button>
                                        <button className="btn btn-secondary deleteYesBtn" onClick={this.OnDiscard} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default injectIntl(GarlandCookbookCreation)