import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import SuccessMoal from '../../../../modal/success-model';
import ErrorMoal from '../../../../modal/error-model';
import $ from 'jquery';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import _ from 'lodash';
import { injectIntl,FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

class FrymasterActionPrompt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            dynamicdataList: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            disableAddStep: false,
            isChanged: false,
            showDiscardPopup: false,
            time_mask: [/[0-1]/, /[0-9]/, ':', /[0-5]/, /[0-9]/],
            promptName: '',
            temperature: 0,
            totalCookTime: 0,
            sensitivity: 0,
            filterCountValue: 0,
            filterCountType: 'Global',
            drainTimer: false,
            promptTimeValue: 0,
            acknowledge_mode: false,
            holdTimerValue: 0,
            instantOnValue: 0
        }
        this.onDeleteStep = this.onDeleteStep.bind(this);
        this.onSavePrompt = this.onSavePrompt.bind(this);
        this.handleModelChange = this.handleModelChange.bind(this);
        this.addStep = this.addStep.bind(this);
        this.handleParamChange = this.handleParamChange.bind(this);
        this.handleParamSubmit = this.handleParamSubmit.bind(this);
        this.handleParamCheck = this.handleParamCheck.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onBackButtonClick = this.onBackButtonClick.bind(this);
        this.OnDiscard = this.OnDiscard.bind(this);
        this.OnCancel = this.OnCancel.bind(this);
        this.onAcknowledgeMode = this.onAcknowledgeMode.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            window.addEventListener('resize', this.updateDimensions);
            let libraryData = JSON.parse(localStorage.getItem("libraryData"));

            let steps = []
            libraryData.conditional_steps.map((item, i) => {
                let step = {};
                step.index = i;
                step.name = item.actions.prompt.message.en_US;
                step.time = item.actions.prompt.duration;
                step.acknowledge_mode = item.actions.prompt.ack;
                step.image_type = item.actions.prompt.image;
                step.promptDelete = '';
                steps.push(step);
            })

            if (steps.length > 0) {
                this.setState({
                    dynamicdataList: steps,
                    disableAddStep: steps.length > 3 ? true : false
                });
            }
            else {
                this.setState({
                    dynamicdataList: [{ index: 0, name: '', time: '', acknowledge_mode: 'false', image_type: 'none', promptDelete: '' }]
                });
            }
        } catch (error) {
        //Error Goes Here
        }
    }

    updateDimensions() {
        $("#scrollDownButton").on("click", function () {
            $(".mCustomScrollbar").mCustomScrollbar("update");
            setTimeout(function(){
                $(".mCustomScrollbar").mCustomScrollbar('scrollTo','bottom');
            },100);
        });
    }

    onAcknowledgeMode = (e, idx) => {
        if (e.target.className) {
            let dynamicdataList = [...this.state.dynamicdataList]
            dynamicdataList[idx]["acknowledge_mode"] = e.toString();
            this.setState({
                dynamicdataList: dynamicdataList,
                isChanged: true
            })
        }
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false
        })
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        this.props.history.push(`/frymasterRecipeCreation`);
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
    }

    closeModel = () => {
        this.setState({ error_modal: { open: false } })
    }

    onBackButtonClick = () => {
        if (!this.state.isChanged) {
            this.props.history.push(`/frymasterRecipeCreation`);
        }
        else {
            this.setState({
                loaderIconVisible: false,
                showDiscardPopup: true
            });
        }
    }

    onSavePrompt = () => {
        this.setState({
            loaderIconVisible: true,
        });

        if (this.state.dynamicdataList.length > 0) {
            let emptyInput = false;
            this.state.dynamicdataList.map((item, i) => {
                if (!emptyInput) {
                    emptyInput = !item.name || !item.time || item.time.includes('_') || !item.acknowledge_mode || !item.image_type ? true : false;
                }
            });

            if (!emptyInput) {
                // let recipeData = JSON.stringify(this.state.dynamicdataList);
                let libraryData = JSON.parse(localStorage.getItem("libraryData"));
                libraryData.conditional_steps = [];
                this.state.dynamicdataList.map((item, i) => {
                    libraryData.conditional_steps[i] = {};
                    libraryData.conditional_steps[i].condition = {};
                    libraryData.conditional_steps[i].condition.property_name = "remainingTime";
                    libraryData.conditional_steps[i].condition.property_value = item.time;
                    libraryData.conditional_steps[i].condition.comparator = "<=";
                    libraryData.conditional_steps[i].condition.max_triggers = 1;
                    libraryData.conditional_steps[i].actions = {};
                    libraryData.conditional_steps[i].actions.prompt = {};
                    libraryData.conditional_steps[i].actions.prompt.message = {};
                    libraryData.conditional_steps[i].actions.prompt.message.en_US = item.name;
                    libraryData.conditional_steps[i].actions.prompt.type = "ok";
                    libraryData.conditional_steps[i].actions.prompt.ack = item.acknowledge_mode.toLowerCase();
                    libraryData.conditional_steps[i].actions.prompt.image = item.image_type.toLowerCase();
                    libraryData.conditional_steps[i].actions.prompt.duration = item.time;
                })
                localStorage.setItem("libraryData", JSON.stringify(libraryData));
                this.props.history.push({
                    pathname: "/frymasterRecipeCreation",
                    previouspath: "/frymasterActionPrompt",
                    isEdit: this.state.isEdit
                });
            }
            else {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: "KC1206"
                    }
                });
            }
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1366"
                }
            });
        }
    }

    addStep = (e) => {
        this.setState((prevState) => ({
            dynamicdataList: [...prevState.dynamicdataList, { index: prevState.dynamicdataList.length + 1, name: '', time: '', acknowledge_mode: 'false', image_type: 'none', promptDelete: '' }],
            isChanged: true
        }));
        if (this.state.dynamicdataList.length > 2) {
            this.setState({
                disableAddStep: true
            });
        }
    }

    handleParamChange = (e) => {
        this.setState({
            isChanged: true
        });
        if (["image_type"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "")] = e.target.value;
            this.setState({
                dynamicdataList: dynamicdataList,
            })
        }
        else if (["form-control name", "form-control instruction"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "").replace("floatRight ", "")] = e.target.value

            this.setState({
                dynamicdataList: dynamicdataList
            })
        }
        else if (["form-control floatRight time"].includes(e.target.className)) {
            let dynamicdataList = [...this.state.dynamicdataList]
            dynamicdataList[e.target.dataset.id][e.target.className.replace("form-control ", "").replace("floatRight ", "")] = e.target.value ? (parseInt(e.target.value.replace(':',''))  > 1000 || e.target.value[0] === "1") ? "10:00" : e.target.value : e.target.value;

            this.setState({
                dynamicdataList: dynamicdataList
            })
        }
        else {
            this.setState({ [e.target.name]: e.target.value.toUpperCase() })
        }
    }

    handleParamCheck = (e) => {
        this.setState({
            isChanged: true
        });
    }

    onDeleteStep = (e) => {
        let id = e.target.id.replace('promptDelete-', '');
        if (id) {
            let splicedata = this.state.dynamicdataList;
            splicedata.splice(Math.round(id), 1);
            this.setState({
                dynamicdataList: splicedata,
                isChanged: true
            });
            if (splicedata.length < 4) {
                this.setState({
                    disableAddStep: false
                });
            }
        }
    }

    handleParamSubmit = (e) => { e.preventDefault() }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: basedataList, dynamicdataList, disableAddStep, success_modal, error_modal, loaderIconVisible, showDiscardPopup, time_mask } = this.state;
        basedataList = basedataList && basedataList.length > 0 ? basedataList : [];
        basedataList = basedataList.filter(b => b.PARAMETER_NAME !== "Instruction");
        let brandid = localStorage.getItem("selectedbrandID");

        return (
            <React.Fragment>
                {/* <!-- Loading Progress --> */}
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button type="button" className="btn btn-default-text menuDownload" data-toggle="modal" data-target="#selectRecipeModal">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} /></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1353"/>
				                </div>
                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                {
                                                    dynamicdataList.map((val, idx) => {
                                                        let nameid = `name-${idx}`, timeid = `time-${idx}`, acknowledge_modeid = `acknowledge_mode-${idx}`, image_type_noneid = `image_type_none-${idx}`, image_type_shakeid = `image_type_shake-${idx}`, image_type_stirid = `image_type_stir-${idx}`, promptDeleteid = `promptDelete-${idx}`
                                                        return (
                                                            <div class="frymasterRCForm" onSubmit={this.handleParamSubmit} onChange={this.handleParamChange} onBlur={this.handleParamCheck} key={idx}>
                                                                <ul class="borderNone">
                                                                    <li><FormattedMessage id="KC1372"/> {idx + 1}</li>
                                                                    <li><button name={promptDeleteid}
                                                                                data-id={idx}
                                                                                id={promptDeleteid} onClick={(e) => { this.onDeleteStep(e) }} type="button" class="btn deleteButton" title={formatMessage({ id: 'KC1371' })}>&nbsp;</button>
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li><FormattedMessage id="KC0241"/></li>
                                                                    <li><input type="text" maxLength="10" name={nameid}
                                                                        data-id={idx} value={val.name} placeholder=""
                                                                        id={nameid} class="form-control name" /></li>
                                                                </ul>
                                                                <ul>
                                                                    <li><FormattedMessage id="KC0144"/></li>
                                                                    <li class="smallFormFields">
                                                                        <InputMask mask={time_mask} maskChar="" placeholder={formatMessage({ id: 'KC2085' })} value={val.time} name={timeid} data-id={idx} id={timeid} class="form-control floatRight time" />
                                                                    </li>
                                                                </ul>
                                                                <ul>
                                                                    <li class="width190px"><FormattedMessage id="KC1373"/></li>
                                                                    <li class="smallFormFields">
                                                                        <div class="btn-group-toggle floatRight" data-toggle="buttons"> <FormattedMessage id="KC1285"/>&nbsp;&nbsp;
                                                                        <Switch
                                                                            onChange={(e) => this.onAcknowledgeMode(e, idx)}
                                                                            checked={val.acknowledge_mode === "true" ? true : false}
                                                                            uncheckedIcon={false}
                                                                            checkedIcon={false}
                                                                            onColor={'#64c832'}
                                                                            offColor={'#c4c1c0'}
                                                                            offHandleColor={'#ffffff'}
                                                                            onHandleColor={'#ffffff'}
                                                                        /> &nbsp;&nbsp;<FormattedMessage id="KC0155"/>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                                <ul class={dynamicdataList.length === idx +1 ? "borderNone" : ""}>
                                                                    <li><FormattedMessage id="KC1374"/></li>
                                                                    <li class="widthAuto">
                                                                        <div class="customRadioBtn">
                                                                            <input id={image_type_noneid} type="radio" name={image_type_noneid} data-id={idx} className="image_type" value="none" checked={val.image_type === "none" ? true : false} />
                                                                            <label for={image_type_noneid} value="none"><FormattedMessage id="KC1375"/></label>
                                                                        </div>
                                                                        <div class="customRadioBtn">
                                                                            <input id={image_type_shakeid} type="radio" name={image_type_shakeid} data-id={idx} className="image_type" value="shake" checked={val.image_type === "shake" ? true : false} />
                                                                            <label for={image_type_shakeid} value="shake"><FormattedMessage id="KC1376"/></label>
                                                                        </div>
                                                                        <div class="customRadioBtn">
                                                                            <input id={image_type_stirid} type="radio" name={image_type_stirid} data-id={idx} className="image_type" value="stir" checked={val.image_type === "stir" ? true : false} />
                                                                            <label for={image_type_stirid} value="stir"><FormattedMessage id="KC1377"/></label>
                                                                        </div>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" id="scrollDownButton" onClick={this.addStep} class={disableAddStep ? "btn addButton displaynone" : "btn addButton"}>&nbsp;</button>
                                    <button type="button" onClick={() => { this.onSavePrompt() }} class="btn confirmButton rightAlign">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onBackButtonClick() }} class="btn cancelButton rightAlign">&nbsp;</button>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218"/>
					            </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580"/></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id="KC0611"/></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterActionPrompt)
