import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { Link } from "react-router-dom";
import { getCurrentUnit, getCurrentLocation } from '../../actions/organizationentity/entityactions';
import GlobalHeaderLayout from '../layoutSection/globalHeaderLayout';
import ErrorListForHistoric from './errorListForHistoric';
import authHelper from '../../utils/authHelper';
import { BaseService, WarrantyAPI } from '../../service/api';
import http from '../../service/httpService';
import { searchVisible, searchText } from '../../actions/advanceSearch/advanceSearchAction';
import { NewDropDownListLink } from '../controls/newDropDownListLink';
import WoopraEvents from '../../woopraConfig/WoopraEvents';
import Constants from '../../woopraConfig/Woopraconstant';
import { FormattedMessage, injectIntl } from 'react-intl';

am4core.useTheme(am4themes_animated);

class HistoricErrors extends Component {

	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: true,
			warrantyResponseData: [],
			currentPercentageUsed: 0,
			currentPercentageUsed_width: { width: "0%" },
			isGlobalDropdownChange:false,
			globalDropdownFilter: 'Month',
			globalDropdownFilterEndDate: '',
			globalDropdownFilterStartDate: '',
			datapickflag: false
		}
	}

	componentDidMount() {
		this.props.searchVisible(true);
		this.setState({ loaderIconVisible: true });

		const unitID = localStorage.getItem("unitID");
		const brandID = localStorage.getItem("unitBrandId");
		this.getWarrantyinformations(unitID, brandID);
		setTimeout(() => {
			this.setState({
				loaderIconVisible: false,
			});
		}, 2000)
	}

	handleChangeGlobalStart(date) {
		this.setState({
			globalDropdownFilterStartDate: date,
			datapickflag: true,
			isGlobalDropdownChange: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext } };
			this.onDropDownGlobalHandler(e);
		});
	}

	handleChangeGlobalEnd(date) {
		this.setState({
			globalDropdownFilterEndDate: date,
			datapickflag: true,
			isGlobalDropdownChange: true
		}, () => {
			let selectedtext = 'Date Range';
			var e = { target: { text: selectedtext } };
			this.onDropDownGlobalHandler(e);
		});
	}

	onDropDownGlobalHandler(e) {
		const filterType = e.target.text;
		this.setState({
			globalDropdownFilter: filterType,
			isGlobalDropdownChange:true,
			datapickflag: filterType === 'Date Range'
		});
	}

	// componentWillUnmount
	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}


	getWarrantyinformations = (unitVal, brandVal) => {
		const unitId = unitVal,brandId=brandVal; 
		//const unitId = "FRYMASDEV09", brandId = 4
		let header = {
			'Content-Type': 'application/json',
		}

		this.setState({loaderIconVisible: true} , () =>  {
			console.log(this.state.loaderIconVisible, "loaderIconVisible ---- Out SIde the HTTP");
		});
		
		http({
			method: "GET",
			url: `${BaseService.root}${WarrantyAPI.Warrantyinformations}?unitId=${unitId}&brandId=${brandId}`,
			headers: header,
			data: {},
		}).then(response => {
			this.setState({ warrantyResponseData: response.data, loaderIconVisible: false });
			const progVal = response.data.graphData.value;
			this.setState({
				currentPercentageUsed_width: { width: `${progVal}%`, background: response.data.graphData.color }
			})
			console.log('catch exception occured', response.data);
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
			console.log('catch exception occured');
		});
	}

	goToLoginPage = () => {
		this.props.history.push('/');
	}

	errorCloseModel = () => {
		authHelper.deleteLocalSession();
		this.props.history.push('/');
	}

	static getDerivedStateFromProps = (props) => ({...props});

		// woopra event
		errorEvent = (e) => {
			console.log(`${Constants.SERVICE_MGMNT_CURRENT_AND_HISTORIC_ERRORS}-${e.trackTitle}`)
			WoopraEvents(`${Constants.SERVICE_MGMNT_CURRENT_AND_HISTORIC_ERRORS}-${e.trackTitle}`);
		}

	render() {

		const { currentLocation, currentUnit, IsTokenExpired, ExpiredMessage } = this.props;
		let { loaderIconVisible, currentPercentageUsed, currentPercentageUsed_width, warrantyResponseData, isGlobalDropdownChange,globalDropdownFilter,globalDropdownFilterEndDate,globalDropdownFilterStartDate } = this.state;
		const { formatMessage } = this.props.intl;
		return (
			<React.Fragment>
				<div id="wrapper" className='serviceManagementWsearch'>
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<GlobalHeaderLayout activeClass="serviceNav" />
					<div id="page-content-wrapper" className="withSearchBar">
						<div className="pageHeader">
							<ul>
								<li>
									<Link to="/serviceManagement" title={formatMessage({ id: "KC0185" })} onClick={(e)=>{e.trackTitle="Current Errors";this.errorEvent(e)}} ><FormattedMessage id="KC0185"/></Link>
								</li>
								<li>
									<Link to="/serviceManagement/historicErrors" title={formatMessage({ id: "KC0186" })} className="active"><FormattedMessage id="KC0186"/></Link>
								</li>
								{/* <li class="filterDropdown">
									<p class="globalFilterText">APPLY TO ALL</p>
									<NewDropDownListLink startDatepara={this.state.globalDropdownFilterStartDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.globalDropdownFilterEndDate} handleChangeStartProps={(date) => this.handleChangeGlobalStart(date)} handleChangeEndProps={(date) => this.handleChangeGlobalEnd(date)} datapickflag={this.state.datapickflag} OnChange={(e) => this.onDropDownGlobalHandler(e)} filterType={this.state.globalDropdownFilter} />
								</li> */}
							</ul>
						</div>
						<ErrorListForHistoric currentUnit={currentUnit} currentLocation={currentLocation} loaderIconVisible={loaderIconVisible} OnCloseSessionModal={() => this.goToLoginPage()} isGlobalDropdownChange={isGlobalDropdownChange} handleDropdownChangeEnd={() => this.setState({ isGlobalDropdownChange: false })}  globalDropdownFilter={globalDropdownFilter} globalDropdownFilterEndDate={globalDropdownFilterEndDate} globalDropdownFilterStartDate={globalDropdownFilterStartDate}/>
					</div>
				</div>
			</React.Fragment>
		)
	}
};

function mapStateToProps(state) {
	return {
		currentLocation: state.entityReducer.currentLocation,
		currentUnit: state.entityReducer.currentUnit,
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		searchVisible,
		getCurrentLocation, 
		getCurrentUnit,
		searchText 
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HistoricErrors));
