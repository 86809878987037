import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import ErrorMoal from '../../../../../modal/error-model';
import { FormattedMessage, injectIntl } from 'react-intl';

class FrymasterCookingOilCondition extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            lowTempIdle: "true",
            lowTempAudio: "true",
            lowTempCooking: "true",
            oilCold: "",
            oilLow: "",
            error_modal: {
                open: false,
                message: ""
            },
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        try {
            let jsonSettingsFile = localStorage.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                lowTempAudio: ObjSettingsFile.cooking.oil_condition_alarms.low_temp_audio === 1 ? "true" : "false",
                lowTempIdle: ObjSettingsFile.cooking.oil_condition_alarms.low_temp_idle === 1 ? "true" : "false",
                lowTempCooking: ObjSettingsFile.cooking.oil_condition_alarms.low_temp_cooking === 1 ? "true" : "false",
                oilCold: ObjSettingsFile.cooking.oil_condition_alarms.oil_cold,
                oilLow: ObjSettingsFile.cooking.oil_condition_alarms.oil_low,
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true,
        });
        let { lowTempAudio, lowTempCooking, lowTempIdle, oilCold, oilLow } = this.state;
        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.cooking.oil_condition_alarms) {
            ObjSettingsFile.cooking.oil_condition_alarms = {};
        }
        if (oilCold > 60 || oilCold < 0) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1471"
                }
            });
            return;
        }
        if (oilLow > 60 || oilLow < 0) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1472"
                }
            });
            return;
        }
        ObjSettingsFile.cooking.oil_condition_alarms.low_temp_audio = lowTempAudio === "true" ? 1 : 0;
        ObjSettingsFile.cooking.oil_condition_alarms.low_temp_cooking = lowTempCooking === "true" ? 1 : 0;
        ObjSettingsFile.cooking.oil_condition_alarms.low_temp_idle = lowTempIdle === "true" ? 1 : 0;
        ObjSettingsFile.cooking.oil_condition_alarms.oil_cold = Number(oilCold);
        ObjSettingsFile.cooking.oil_condition_alarms.oil_low = Number(oilLow);
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterCooking`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterCooking`);
    }

    onToggleChange = (e, stateKey) => {
        this.setState({
            [stateKey]: e.toString()
        });
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");

        const { lowTempIdle, lowTempAudio, lowTempCooking, oilCold, oilLow, error_modal } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Cooking Oil Condition Header Page */}
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Cooking Oil Condition Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")} alt="" />
                            </div>

                            {/* Frymaster Cooking Oil Condition Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1473"/>
                                </div>



                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li class="width245px"><FormattedMessage id="KC1474"/></li>
                                                        <li>
                                                            <div class="btn-group-toggle" data-toggle="buttons"> <span class={lowTempAudio === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1360"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'lowTempAudio')}
                                                                    checked={lowTempAudio === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={lowTempAudio === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1405"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li class="width245px"><FormattedMessage id="KC1475"/></li>
                                                        <li>
                                                            <div class="btn-group-toggle" data-toggle="buttons"> <span class={lowTempCooking === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1360"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'lowTempCooking')}
                                                                    checked={lowTempCooking === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={lowTempCooking === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1405"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li class="width245px"><FormattedMessage id="KC1476"/></li>
                                                        <li>
                                                            <div class="btn-group-toggle" data-toggle="buttons"> <span class={lowTempIdle === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1360"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'lowTempIdle')}
                                                                    checked={lowTempIdle === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /><span class={lowTempIdle === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1405"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li class="width245px"><FormattedMessage id="KC1477"/></li>
                                                        <li class="smallFormFields">
                                                            <InputMask
                                                                maskChar=""
                                                                placeholder={formatMessage({ id: 'KC1978' })}
                                                                onChange={(e) => {
                                                                    const re = /^[0-9\b]+$/;
                                                                    // if value is not blank, then test the regex
                                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                                        this.setState({ oilCold: e.target.value })
                                                                    }
                                                                }}
                                                                value={oilCold}
                                                                class="form-control" />
                                                        </li>
                                                    </ul>
                                                    <ul class="borderNone">
                                                        <li class="width245px"><FormattedMessage id="KC1478"/></li>
                                                        <li class="smallFormFields">
                                                            <InputMask
                                                                maskChar=""
                                                                placeholder={formatMessage({ id: 'KC1978' })}
                                                                onChange={(e) => {
                                                                    const re = /^[0-9\b]+$/;
                                                                    // if value is not blank, then test the regex
                                                                    if (e.target.value === '' || re.test(e.target.value)) {
                                                                        this.setState({ oilLow: e.target.value })
                                                                    }
                                                                }}
                                                                value={oilLow}
                                                                class="form-control" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>

                                </div>
                            </div>
                            {/* Frymaster Cooking Oil Condition Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} message={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterCookingOilCondition)
