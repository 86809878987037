import http from '../../../service/httpService';
import { LocationInfo} from '../../../service/api';
import { BRAND_ID,  BRAND_ID_VALUE } from '../../../utils/appConstants';
export const BRAND_REQUEST = 'BRAND_REQUEST';
export const BRAND_SUCCESS = 'BRAND_SUCCESS';
export const BRAND_FAIL = 'BRAND_FAIL';

export function getBrand (req = '', isCascadeActionRequest) {
    return dispatch => {
        dispatch({
            type: BRAND_REQUEST,
            isCascadeActionRequest: isCascadeActionRequest ? true : false
        })
        
    let brandIDToHeader = BRAND_ID;
            const pageName = window.location.href.split('/').pop();
            let barndIDValue = BRAND_ID_VALUE;
            let selectedBrandName = localStorage.getItem("brandName")
            barndIDValue = BRAND_ID_VALUE[selectedBrandName];
            if (pageName === "menuAssignUpdateList" || pageName === "assignUpdateList") {
                brandIDToHeader = barndIDValue;
            }

        http
            .get(`${LocationInfo.filters}`,
                {headers: {
                    'Content-Type':'application/json',
                    'Type': 'Brand',
                    'brandid': brandIDToHeader,
                    'cid': localStorage.getItem("customerId"),
                },
                data:{}})
            .then(response => {
                dispatch({
                    type: BRAND_SUCCESS,
                    brandInformation: response.data                    
                })
            })
            .catch(error => {
                dispatch({
                    type: BRAND_FAIL,
                    error
                })
            })
    }
}
