import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import http from '../../service/httpService';
import { UnitInfo } from '../../service/api';
import { UnitBuilder } from '../../utils/unitResponseBuilder';
import { setCurrentUnit } from '../../actions/organizationentity/entityactions';
import AllLocationHeaderLayout from '../layoutSection/allLocationHeaderLayout';
import auth from '../../service/authService';
import appconstants from '../../utils/appConstants'
import { CUSTOMER_ID, BRAND_ID } from '../../utils/appConstants';
import { TableHeader } from '../controls/tableHeader';
import $ from 'jquery';
import {setunitBrandId} from '../../utils/appConstants';
import NoRecordsFound from '../controls/noRecordsFound';
import qs from 'query-string';

import { FormattedMessage, injectIntl } from 'react-intl';
import GoToPagination from '../controls/goToPagination';
import { brandWithDashboardRenderer } from '../../utils/dashboardRenderer';
import { searchVisible, searchText, clearSearchText } from '../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj, searchData_function} from '../globalSearchBar/selectOption';
import { ungzip } from 'pako';

class specificunitsView extends Component {

	constructor(probs) {
		super(probs);
		this.state = {
			data: [],
			totalPages: null,
			loading: true,
			currentPage : 1,
			pageSize: 25,
			sortColumn:{path:"", order:"", sort_color:"#F2F2F2" }
		}
		this.onSortedChange = this.onSortedChange.bind(this);
		this.navigateUnit = this.navigateUnit.bind(this);
	}

	componentDidMount = () =>{
		document.body.style.backgroundColor = "#000000";
		this.updateDimensions();
		localStorage.removeItem('showOfflinePopup');
		localStorage.removeItem('liveMonitoringTracker');
		this.props.searchVisible(true);
		const { currentPage, sortColumn } = this.state;
		this.loadData(currentPage, sortColumn);
	}

	updateDimensions() {
		const windowHeight = $(window).height();
        const tableBody = $('.pageScrollbar');
        const tableBodyBottom = $('.paginationDWgoto, .paginationWgoto').height() ? $('.paginationDWgoto, .paginationWgoto').height() + 18 : 0 ;
        tableBody.css('height', windowHeight - (tableBody && tableBody.offset() && tableBody.offset().top + tableBodyBottom));
	}
	
	componentDidUpdate(prevState) {
		window.addEventListener("resize", this.updateDimensions());
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;
		if( curSearchData && (isEqualObj( searchData, curSearchData )  === false) ) {
			this.setState({ loading: true,currentPage:1 },()=>{
				const { currentPage, sortColumn } = this.state;
				this.loadData(currentPage, sortColumn);
			});
		}
	}

	componentWillUnmount() {		
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	// clear Global Search
	clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

	static getDerivedStateFromProps = (props) => ({...props});
	
	/**
	 * Fetch server data by On-Demand
	 * Send current page number
	 * Send Sorted details
	 */
	loadData = async(currentPage, sortColumn) => {	
		try {
			const response = await this.requestData(currentPage, sortColumn);
			this.setState({
				data: response.rows,
				totalPages: response.pages,
				currentPage : currentPage,
				loading: response.showLoading,
			});
		} catch (error) {
			this.setState({ loading: false });
		}
	}

    /**
	 * When navigate new page this event will return current page number
	 * @param {*} currentPage 
	 */
    onPageChange = (currentPage)  =>{
		this.setState({ loading: true });
		this.loadData(currentPage);
    }
	
	/**
	 * When sort the record by On-Demand
	 * This event will return the sorted column details
	 */
	onSortedChange = (sortColumn) =>{
		this.setState({
			sortColumn: sortColumn,
			currentPage: 1
		});
		this.loadData(1, sortColumn);
	}

	decompressData(base64) {
		try {
            const buffer = Buffer.from(base64, "base64");
            const bufferArr = ungzip(buffer);
            const json = new TextDecoder("utf8").decode(bufferArr);
            return JSON.parse(json);
        } catch (err) {
            console.error(err);
            return null;
        }
	}

	/**
	 * Business login to fetch units by below constraints
	 * 1. Fetch by Brand specific units
	 * 2. Fetch by Sorted column details
	 * 3. Fetch by page wise
	 */
	requestData = async (currentPage, sortColumn) => {
		try {
		    const sortData = sortColumn ? sortColumn : this.state.sortColumn;
			const pageSize = this.state.pageSize;
			const sortType = sortData.order;
			const sortBy =  sortData.path;
			const page = currentPage - 1;

			let filterModel = this.props.filterlistInformtion.filterModel;
			const ffModel = localStorage.getItem(appconstants.filterKey);
			const filterModel1 = ffModel && JSON.parse(ffModel);
			if (filterModel1) {
				filterModel = filterModel1;
			}

			let selectedCountryList = filterModel.find(el => el.field_name === 'country');
			let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
			let selectedLocationList = filterModel.find(el => el.field_name === 'location');
			let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
			let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
			let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
			let selectedModelList = filterModel.find(el => el.field_name === 'model');
			let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
            let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
            let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

			let selectedCgids = [];
			let selectedCids = [];
			let selectedBrands = [];
			let selectedModels = [];
			let selectedMachineTypes = [];
            let selectedST = [];

			selectedCountryList.dataSource.map(c => {
				selectedCgids.push(c.id)
			});

			if (selectedRegionList.dataSource.length > 0) {
				selectedCgids = [];
			}

			selectedRegionList.dataSource.map(c => {
				selectedCgids.push(c.cust_gp_id)
			});

			if (selectedLocationList.dataSource.length > 0) {
				selectedCgids = [];
			}

			selectedLocationList.dataSource.map(c => {
				selectedCgids.push(c.cust_gp_id)
			});

			if (selectedWithinLocationList.dataSource.length > 0) {
				selectedCgids = [];
			}

			selectedWithinLocationList.dataSource.map(c => {
				return selectedCgids.push(c.cust_gp_id)
            });

			selectedCustomerList.dataSource.map(c => {
				selectedCids.push(c.ID)
			});

			selectedBrandList.dataSource.map(c => {
				selectedBrands.push(c.ID)
			});

			selectedModelList.dataSource.map(c => {
				selectedModels.push(c.ID)
			});

			selectedMachineTypeList.dataSource.map(c => {
				selectedMachineTypes.push(c.ID)
			});

			selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
                return selectedST.push(c.id)
            });

            selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
                return selectedST.push(c.id)
            });

			const values = qs.parse(this.props.location.search);
			var d = document.getElementById("spannavNotifiStatus");
			let cgids = "";
			if (selectedCgids.length > 0) {
				cgids = selectedCgids.join(',');
			}
			else {
				cgids = this.props?.location?.groupId ? this.props.location.groupId:localStorage.getItem("custGroupID");
			}

			if (selectedCgids.length > 0 || selectedST.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
				d.className = "navNotifiStatus";
			}
			else {
				d.className = "";
			}
			let url = `${UnitInfo.list}`;
			let pageNumber = '?pageNum=' + (page + 1);
			let pageLimit = '&pageSize=' + pageSize;

			let order = page * pageSize;
			let apiUrl = url + pageNumber + pageLimit;
			let { searchData } = this.state;
			if (searchData && searchData.searchtext) {
				apiUrl = `${apiUrl}&searchText=${searchData.searchtext}`;
			}

			if (searchData) {
				let searchParam = {};

				Object.keys(searchData).forEach((item) => {
					if (item !== "searchtext") {
						return searchParam[item] = searchData[item];
					}
				})

				if (searchParam && Object.keys(searchParam).length) {
					apiUrl = `${apiUrl}&searchParams=${btoa(JSON.stringify(searchParam))}`;
				}
			}
			let headers = {
				'Content-Type': 'application/json',
				'cgid':  cgids,
				'cid': CUSTOMER_ID === "-1" ? selectedCids.join(',') : CUSTOMER_ID,
				'brandid': selectedBrands.length > 0 ? selectedBrands.join(',') : BRAND_ID,
				'model': selectedModels.join(','),
				'machinetype': selectedMachineTypes.join(','),
				'sortby': sortBy,
				'sorttype': sortType
			}
            console.log("selectedST", selectedST);
            if(selectedST && selectedST.length){
                headers['tags'] = selectedST.join(',')
            }
			const response = await http.get(apiUrl, {
                headers,
                data: {},
                params: {
                    compress: true,
                },
            });

            let { units, numberOfPages } = response.data;
            if (response.data.compressed) {
                units = this.decompressData(units) || [];
            }
			units.map((item, i) => {
				item.order_id = ++order;
			});
			units = UnitBuilder(units);

			return {
				rows: units,
				pages: numberOfPages,
				showLoading: false,
			};

		} catch (error) {
			return {
				rows: [],
				pages: 0,
				showLoading: false,
			};
		}
		
	};

	/**
	 * Navigate to Dashboard with repective Unit
	 */
	navigateUnit =(rowData, url)=>{
		this.props.setCurrentUnit(rowData);
		if (rowData.ID > 0)
		{
			this.props.history.push(url);
		}
		localStorage.setItem("brandName", rowData.BRAND_NAME);
		localStorage.setItem('unitID', rowData.UNITID);
		localStorage.setItem('unitBrandId', rowData.BRAND_ID);
		localStorage.setItem('modelName', rowData.MODEL_NAME);
		localStorage.setItem('modelFamilyName', rowData.MODEL_FAMILY_NAME); 
		localStorage.setItem('cloudUnitName', rowData.CLOUD_UNIT_NAME); 
		setunitBrandId(rowData.BRAND_ID? rowData.BRAND_ID : "", rowData.UNITID? rowData.UNITID : "", rowData.MODEL_NAME? rowData.MODEL_NAME : "");  
		this.getExcludeProductDetails(rowData.BRAND_ID);
	}

	getExcludeProductDetails = async (brandid) => {		
		const response = await http.get(UnitInfo.excludeProduct,
			{
				headers: {
					'Content-Type': 'application/json',					
					'cid': CUSTOMER_ID,
					'brandid': brandid,					
				},
				data: {}
			})
		let excludeProduct = response.data && response.data[0]
		let checklength = excludeProduct && excludeProduct.excludedProductList && excludeProduct.excludedProductList.length ? excludeProduct.excludedProductList.length :0;

		localStorage.setItem('excludedProductList', checklength>0?true:false); 		
	}

	render() {
		const { data, totalPages , currentPage, sortColumn, loading } = this.state;
		const { formatMessage } = this.props.intl;
 
		let features = auth.getAllFeatures();
		features = features ? features.filter(a => a.feature_category === "Unit Data") : [];

		let columns = [
			{ path :"", label:"#"},
			{ path :"", labelStringID:"KC0101"},
			{ path:"BRAND_NAME", labelStringID:"KC0062"},
			{ path:"PRODUCT_TYPE", labelStringID:"KC0102"},
			{ path:"MODEL_NAME", labelStringID:"KC0064"},
			{ path:"UNITID", labelStringID:"KC0104"},
			{ path:"UNIT_NUMBER", labelStringID:"KC0105"},
			{ path:"location_name", labelStringID:"KC0050"},
			{ path: "within_location", labelStringID: "KC0061" },
			{ path:"", label:"", isNbSp:true}
		];

		return (
			<React.Fragment>
				<div id="wrapper">
				    <div className={loading ? "loadingProgress showloader" : "loadingProgress"}>
                        <div className="loadingProgressIn"></div>
                    </div>
					<AllLocationHeaderLayout activeClass="ListviewA" filterKey={appconstants.filterKey} history={this.props.history} />
					<div id="page-content-wrapper">
						<div className="pageTabHeader marginTop2px">
							<ul>
								{/* <li>
									<Link to="/LocationsView" title="Locations" className="locationsView ">Locations</Link>
								</li> */}
								<li>
									<Link to="/unitsView" title={formatMessage({id: 'KC0045'})} className="unitsView active"><FormattedMessage id="KC1880"/></Link>
								</li>
								<li class="selectedBox">
									<div class="selectedValBox">
										<span class="errorBoxXXXXXXXX "></span>
										<span class="warningBoxXXXXXXXX"></span>
									</div>
								</li>
							</ul>
						</div>
						<div class="listUnits">
							<div class={data && data.length > 0 ? "listUnitsTable" : "displaynone"}>
							 <TableHeader 
								 sortColumn ={sortColumn}
								 onSort={this.onSortedChange}
								 columns={columns}/>
								 </div>
								 <div className="pageScrollbar">
									<div className="listUnitsTable">
								{
									data.map((item, i) => {
										const connectOpacity = item && item.CONNECT_STATE === "Offline" ? 0.5 : 1;
										let unitsGridErrorStaus = 'errorStaus',
											unitsGridwarningStaus = 'warningStaus',
											unitsGridNoErrorStaus = '';
										let classValue = "";
										if (item) {
											classValue = item ? (item.CONNECT_STATE === 'Offline' ? unitsGridNoErrorStaus : item.ERROR_STATE === 'Errors' && item.CONNECT_STATE === 'Online' ? unitsGridErrorStaus :
												item.ERROR_STATE === 'Non-Critical Errors' && item.CONNECT_STATE === 'Online' ? unitsGridwarningStaus : unitsGridNoErrorStaus) : "";
										}
										const rowStyle = {
											className: classValue,
											opacity: connectOpacity
										};
										const linkTo = features.length > 0 ? brandWithDashboardRenderer[item.BRAND_NAME] ? `/_dashboard/${item.UNITID}/unitdashboard/` :  features[0].navigateUrl : '/un-authorized';
										return (
											<ul style={rowStyle} 
											    class={`tBody ${rowStyle.className}`}
											    onClick= { ()=> this.navigateUnit(item, linkTo)}>
												<li>{item.order_id}</li>
												<li><img alt={item.Brand_Alt} src={item.Brand_Logo} /></li> 
												<li>{item.BRAND_NAME} 
													<span class="unitsListSM">{item.PRODUCT_TYPE}, {item.MODEL_NAME}</span>
													<span class="unitsListSML">{item.location_name}</span></li>
												<li>{item.PRODUCT_TYPE}</li>
												<li>{item.MODEL_NAME}</li>
												<li>{item.UNITID}  <span class="unitsListAN"><FormattedMessage id='KC0105' /> <b>{item.UNIT_NUMBER}</b></span></li>
												<li>{item.UNIT_NUMBER}</li>
												<li>{item.location_name}</li>
												<li>{item.within_location}</li>
												<li>{item.CONNECT_STATE === "Offline" ?
													<img alt={""} src={require('../../images/icons/offlineA.svg').default} /> : <span className='number'>{}</span>}
												</li>
											</ul>
										)
									})
								}
								<NoRecordsFound loaderIconVisible={loading} length={data !== undefined ? data.length : 0} classname={'norecordsfound'} />
							</div>
							</div>
						</div>
						{ totalPages && totalPages > 1 ? <GoToPagination
                            isBlackBackground={true}
                            totalPageNumber={totalPages}
                            pageNumber={currentPage}
                            navigateTo={(p) => this.onPageChange(p)} /> : null }
					</div>
				</div>
			</React.Fragment>
		);
	}
};


function mapStateToProps(state) {
	return {
		filterlistInformtion: state.filterModel,
		currentUnit: state.entityReducer.currentUnit,
		selectedCountries: state.filterInformation.selectedCountries,
		selectedRegions: state.filterInformation.selectedRegions,
		selectedLocations: state.filterInformation.selectedLocations,
		selectedCustomers: state.filterInformation.selectedCustomers,
		selectedBrands: state.filterInformation.selectedBrands,
		selectedModels: state.filterInformation.selectedModels,
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ setCurrentUnit,searchVisible, searchText, clearSearchText }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(specificunitsView));