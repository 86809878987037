import React, { Component } from 'react';
import { connect } from 'react-redux';
import { UserProfile, UserMgmtAddUser, smartGroupAPI,MediaManagementAPI } from '../../../service/api';
import { bindActionCreators } from 'redux';
import http from '../../../service/httpService';
import { Link } from 'react-router-dom';
import { brandLocations, unitModels, } from '../../../utils/imgContants';
import { setUserDataModel } from '../../../actions/stateActionsData/userAction';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import appconstants from '../../../utils/appConstants';
import { getSelectedFilterList } from '../../../actions/listView/locations/filterListAction';
import { FormattedMessage, injectIntl } from 'react-intl';
import {  BASECOUNTRY_ID } from '../../../utils/appConstants';
import $ from 'jquery';
import _ from 'lodash';
import moment from '../../../utils/momentHelper.js';
import TagOrgModal from '../smartTag/tagOrgModel';
import OrgTagsDetail from '../smartTag/orgTagsDetail';
import { AutoSizer, List } from 'react-virtualized';
import { readArchivesFromCompressedResponse } from '../../../utils/helpers.js';

var bg = require('../../../images/unit/CremCarrera.svg').default
const brandsLogo = unitModels;
const brandsLocationLogo = brandLocations;
const brandLocationsMap = {};
brandsLocationLogo.forEach(item => (brandLocationsMap[item.name] = item));
const brandsMap = {};
brandsLogo.forEach(item => (brandsMap[item.name] = item));
class OrganizationChartView extends Component {

    onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		})
		this.props.history.push(`/mediaLibraryList`);
	}

    closeModel() {
        this.setState({
            info_modal: {
                open: false
            }
        })
    }

    redirectModel() {
        this.setState({
            error_modal: {
                open: false
            }
        })
        this.props.history.push('/mediaLibraryList');
    }

    constructor(props) {
        super(props);
        this.state = {
            userData: {},
            roleId: 0,
            userGroup: [],
            userPreference: [],
            loaderIconVisible: false,
            success_modal: {
                open: false,
                message: ""
            },
            error_modal: {
                open: false,
                message: ""
            },
            info_modal: {
                open: false,
                message: ""
            },
            showIcons: false,
            level: 1,
            link: {},
            linkList: {},
            newLinkCreated: false,
            isFormValidated: false,
            showElementInfo: false,
            showLink: [],
            unit: {},
            selectedDynasty: [],
            selectedCgIds: [],
            selectedGroupName: [],
            selectedgroupId: {},
            findTreeLevel: "",
            previouspath: '',
            checkedUnits: [],
            initialload: true,
            nameFromReport: '',
            oldSmartGroupName : "",
            oldSelectedPurposes:[],
            sumartGroupID:"",
            oldSelUnits:[],
            unModifiedOldSelUnits:[],
            findPageName:"",
            veryFirstTime:true,
            mediaSelectedItemsInfo: [],
            showStPopUp:false,
            showOrgPopup: false,
            type: "orgList",
            showpopup: [],
            units: [],
            flattenTreeData: [], /* Contains flatten hierarchy data structure in Array<Array> */
            dynastyValue:[]
        };
        this.filterstate = {
            sCgids: [],
            sCids: [],
            sBrandids: [],
            sModels: [],
            sMachineTypes: [],
            sSmartTags:[],
            dynastyValue:[]
        };
        this.treeData = {
            virtualScrollBufferRowCount: 10,
            rowBaseHeight: 80,
            virtualScrollRef: React.createRef(),
            collapsedNodes: {},
            activeNode: null,
            expand: {}
        };
        // this.showLinks = this.showLinks.bind(this);
        this.getData = this.getData.bind(this);
        this.submitAddMedia = this.submitAddMedia.bind(this);
        this.closeModel = this.closeModel.bind(this);
        this.redirectModel = this.redirectModel.bind(this);
        this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);
        this.onClickListView = this.onClickListView.bind(this);
        this.treeViewRowRenderer = this.treeViewRowRenderer.bind(this);
        this.getTreeViewRowHeight = this.getTreeViewRowHeight.bind(this);
    }
    showError = (err) => {
        const { formatMessage } = this.props.intl;
        let { response } = err;
        if (response) {
            let { data } = response;
            if (response && response.status === 400) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.message ? data.message : "",
                    }
                });
            } else if (response && response.status === 500) {
                this.setState({
                    loaderIconVisible: false,
                    error_modal: {
                        open: true,
                        message: data.message ? data.message : "",
                    }
                });
            }
        } else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: formatMessage({ id: 'KC1725' })
                }

            });
        }
    }

    componentDidUpdate(prevState) {
        this.updateDimensions();
    }

    componentWillMount = () => {
		let storeInstalledItemInfo = this.props.storeInstalledItemInfo && this.props.storeInstalledItemInfo.SOFTWARE_ID ? this.props.storeInstalledItemInfo : JSON.parse(localStorage.getItem('selectedItemsInfo'));
		localStorage.setItem('selectedItemsInfo', JSON.stringify(storeInstalledItemInfo))

		let locationInfo = this.props.location && this.props.location.FILE_NAME ? this.props.location : JSON.parse(localStorage.getItem('locationInfo'));
		localStorage.setItem('locationInfo', JSON.stringify(locationInfo))

		this.setState({
			storeInstalledItemInfo: storeInstalledItemInfo,
			locationInfo: locationInfo
		});
	}

    componentDidMount= async () => {
        document.body.style.backgroundColor = "#F2F2F2";
        $("div").removeClass("modal-backdrop fade show");
        await this.getDynacity();
        this.getData(UserMgmtAddUser.get_Orgnanization_View + localStorage.getItem("customerId")).then(response => {
            this.getCustGroupID(response.data);
        });
        window.addEventListener('resize', this.updateDimensions);        
    }

    updateDimensions() {
        const windowHeight = $(window).height();
        const $treeViewBody = $('.assignOrgTreeWrapper .treeViewBody');
        $treeViewBody.css('height', windowHeight - $treeViewBody.offset().top);
    }

    getFilterDetails = async () =>{		
		let filterModel = this.props.filterlistInformtion.filterModel;
		const ffModel = localStorage.getItem(appconstants.smartGroupFilter);
		const filterModel1 = ffModel && JSON.parse(ffModel);
		if (filterModel1) {
			filterModel = filterModel1;
		}

		let selectedCountryList = filterModel.find(el => el.field_name === 'country');
		let selectedRegionList = filterModel.find(el => el.field_name === 'chain');
		let selectedLocationList = filterModel.find(el => el.field_name === 'location');
		let selectedWithinLocationList = filterModel.find(el => el.field_name === 'withinlocation');
		let selectedCustomerList = filterModel.find(el => el.field_name === 'customer');
		let selectedBrandList = filterModel.find(el => el.field_name === 'brand');
		let selectedModelList = filterModel.find(el => el.field_name === 'model');
        let selectedMachineTypeList = filterModel.find(el => el.field_name === 'machineType');
        let selectedUnitSTList = filterModel.find(el => el.field_name === 'unitSmartTagsFilter');
    	let selectedOrgSTList = filterModel.find(el => el.field_name === 'orgSmartTagsFilter');

		let selectedCgids = [];
		let selectedCids = [];
		let selectedBrands = [];
		let selectedModels = [];
        let selectedMachineTypes = [];
        let selectedST = [];

		selectedCountryList.dataSource.map(c => {
			return selectedCgids.push(c.id)
		});

		if (selectedRegionList.dataSource.length > 0) {
			 selectedCgids = [];
		}

		selectedRegionList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		if (selectedWithinLocationList.dataSource.length > 0) {
			selectedCgids = [];
		}

		selectedWithinLocationList.dataSource.map(c => {
			return selectedCgids.push(c.cust_gp_id)
		});

		selectedCustomerList.dataSource.map(c => {
			return selectedCids.push(c.ID)
		});

		selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
		});

		selectedModelList.dataSource.map(c => {
			return selectedModels.push(c.ID)
		});

		selectedMachineTypeList.dataSource.map(c => {
			return selectedMachineTypes.push(c.ID)
        });        
        
        selectedBrandList.dataSource.map(c => {
			return selectedBrands.push(c.ID)
        });
        
        selectedUnitSTList && selectedUnitSTList.dataSource.map(c => {
            return selectedST.push(c.id)
        });

        selectedOrgSTList && selectedOrgSTList.dataSource.map(c => {
            return selectedST.push(c.id)
        });

		let cgids = "";
		if (selectedCgids.length > 0) {
			cgids = selectedCgids.join(',');
		}
		else {
			cgids = localStorage.getItem("custGroupID");
		}

		this.filterstate = ({
			sCgids: cgids,
			sCids: selectedCids,
			sBrandids: selectedBrands,
			sModels: selectedModels,
            sMachineTypes: selectedMachineTypes,
            sSmartTags:selectedST,
		});

		var d = document.getElementById("spannavNotifiStatus");
        if (selectedST.length > 0 || selectedCgids.length > 0 || selectedCids.length > 0 || selectedBrands.length > 0 || selectedModels.length > 0 || selectedMachineTypes.length > 0) {
            d.className = "navNotifiStatus";
        }			
        else {
            d.className = "";
        }
		
	}

    getDynacity = () => {
        let selCgid = localStorage.getItem('orgCgid');
		http.get(`${UserProfile.get_Dynacity_Elements}`,
		{
			headers: {
				'Content-Type': 'application/json',
				"cgids": selCgid,
			},
			data: {}
		})
		.then(response => {
            this.setState({dynastyValue:response.data.cgids})
		}).catch(error => {
			this.setState({
				linkListValue: {},
				loaderIconVisible: false,
			})
		})
    }
    
    getData = (url, Cgids) => {        
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {
            let headers = { 'Content-Type': 'application/json', };
            if (Cgids) headers.Cgids = Cgids;
            http.get(url, {
                headers,
                data: {}
            }
            ).then(response => {
                resolve(response);

            }).catch(err => {
                reject(err)
            })
        });
    }

    getCustGroupID = (allData) => {
        let loggedinUser;
        let custGroupID = localStorage.getItem("custGroupID");
        let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
        loggedinUser = loginUser.username;

        this.setState({ loaderIconVisible: true, })
        try {
            this.getOrgnanizationView(custGroupID, allData);
        } catch (err) {
            console.log('catch exception occured');
            this.setState({
                linkList: [],
                loaderIconVisible: false,
            })
        };
    }

    /**
   * Get Unit Smart Group names for display at Unit Node
   * @param {*} unitNode Unit Node object
   * @returns Array<String> Smart Group names
   */
    getUnitSmartGroupsName(unitNode) {
        let smartGroups = unitNode.smartGroup || [];
        let smartGroupsName = [];
        if (smartGroups.length > 3) {
            smartGroupsName.push({
                sgName: '',
                sgFirstName: smartGroups.length,
            });
        } else if (smartGroups.length > 0) {
            smartGroups.forEach(item => {
                smartGroupsName.push({
                    sgName: item.name,
                    sgFirstName: item.smartGroupKeyName && item.smartGroupKeyName !== 'null'
                        ? item.smartGroupKeyName
                        : item.name.charAt(0)
                });
            });
        }
        return smartGroupsName;
    }

    /**
    * Convert hierarchy data structure into flatten array
    * @param {*} nodes Array<HierarchyNode> Array of hierarchy node object
    * @param {*} nodeLevel Node level No
    * @param {*} currentRowIndex Current row index for Tree Data
    * @param {*} flattenTreeData Array<Array> Accumulated flatten tree data
    * @param {*} parentRowIndex Parent Node row index
    * @param {*} parentColIndex Parent Node column index
    * @returns Array<Array>
    */
    convertToFlattenTreeData(nodes, nodeLevel = 1, currentRowIndex = 0, flattenTreeData = [], parentRowIndex = null, parentColIndex = null) {
        let prevRowIndex = 0;
        const nodeIndexes = [];
        const nodesLength = nodes.length;
        for (let i = 0; i < nodesLength; i++) {
            const node = nodes[i];

            // Set row and column index
            i > 0 && currentRowIndex++;
            const rowIndex = currentRowIndex;
            const level = node.level_id || nodeLevel; // If level_id not available in API response, read from method param. Fix for DEV env
            const colIndex = level - 1;

            // Set connectors in midway indexes, but not for level 1 and 6
            if ((level > 1 && level < 6) && i > 0) {
                const endRowIndex = rowIndex - 1; // Exclude current row
                let midwayRowsCount = endRowIndex - prevRowIndex;
                if (midwayRowsCount > 0) {
                    const startRowIndex = prevRowIndex + 1; // Exclude start index
                    for (let j = startRowIndex; j <= endRowIndex; j++) {
                        const rowData = flattenTreeData[j] || [];
                        rowData[colIndex] = {
                            __NODE_DATA__: {
                                isConnector: true,
                                rowIndex: j,
                                colIndex,
                                parentRowIndex,
                                parentColIndex
                            }
                        };
                        flattenTreeData[j] = rowData;
                    }
                }
            }

            // Create node object
            const childNodesProp = level < 5 ? `level${level + 1}` : 'units';
            const { [childNodesProp]: childNodes, ...nodeClone } = node;
            const nodeData = {
                isNode: true,
                rowIndex,
                colIndex,
                parentRowIndex,
                parentColIndex,
                hasChildren: !!node.child_count,
                isFirstChild: nodesLength > 1 && i === 0,
                isMidwayChild: i > 0 && i < (nodesLength - 1),
                isLastChild: nodesLength > 1 && i === (nodesLength - 1),
                isOnlyChild: nodesLength === 1
            };
            if (level === 6) {
                const smartGroupsName = this.getUnitSmartGroupsName(nodeClone);
                nodeData.smartGroupsName = smartGroupsName;
            }
            nodeClone.__NODE_DATA__ = nodeData;

            // Set node object in respective index
            const rowData = flattenTreeData[rowIndex] || [];
            rowData[colIndex] = nodeClone;
            flattenTreeData[rowIndex] = rowData;
            nodeIndexes.push([rowIndex, colIndex]);

            // Process its children
            if (nodeData.hasChildren) {
                const result = this.convertToFlattenTreeData(
                    childNodes,
                    nodeLevel + 1,
                    currentRowIndex,
                    flattenTreeData,
                    rowIndex,
                    colIndex
                );
                flattenTreeData = result.flattenTreeData;
                flattenTreeData[rowIndex][colIndex].__NODE_DATA__.childNodeIndexes = result.nodeIndexes;
                currentRowIndex = result.currentRowIndex;
            }
            prevRowIndex = rowIndex;
        }
        return { flattenTreeData, nodeIndexes, currentRowIndex };
    }

    formUnits = (flattenTreeData) => {
        let unit = [];
        for (let index = 0; index < flattenTreeData.length; index++) {
            for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
                const nodeEle = flattenTreeData[index][indexJ];
                if (nodeEle && nodeEle.UNITID) {
                    unit.push(nodeEle);
                }
            }
        }
        return unit;
    }

    async seperateUnits(orgJSON) {
        const { flattenTreeData } = this.convertToFlattenTreeData(orgJSON.level1);
        const units = this.formUnits(flattenTreeData);
        this.setState({ units, flattenTreeData })
    }

    async processOrgnanizationJSON(orgJSON) {
        const length = 1;
        let findLeveltoShow = orgJSON.level1[0];
        if (length > 1) {
            for (let i = 2; i <= length; i++) {
                findLeveltoShow = findLeveltoShow[`level${i}`][0];
            }
        }
        await this.seperateUnits(orgJSON);
        this.setState({
            linkList: orgJSON,
            link: orgJSON,
            findTreeLevel: findLeveltoShow,
            loaderIconVisible: false,
            isUnitItemClicked: true
        },
            () => {
                this.treeData.virtualScrollRef.current.recomputeRowHeights();
            });
    }

    getOrgnanizationView = (values, allData) => {
        this.setState({ loaderIconVisible: true });
        http({
            method: "GET",
            url: `${UserProfile.get_Orgnanization_View + localStorage.getItem("customerId")}`,
            headers: {
                "Cgids": values,
                'Content-Type': 'application/json',                
                'brandids': localStorage.getItem("unitBrandId"),
                "basecountry": BASECOUNTRY_ID,
                'useremail': localStorage.getItem("user"),
            }, data: {},
        }).then(async response => {
            // if (response.data.fetchFromS3) {
            //     await $.getJSON(response.data.presignedS3URL, (data) => {
            //         this.processOrgnanizationJSON(data);
            //     });
            // }
            // else {
            //     this.processOrgnanizationJSON(response.data);
            // }
            let orgJSON = await readArchivesFromCompressedResponse(response.data);
			this.processOrgnanizationJSON(orgJSON);
            
            this.setState({
                cgIds: values
            })
        }).catch(err => {
            this.setState({
                linkListValue: {},
                loaderIconVisible: false,
            })
        });

    }
    
    submitAddMedia = (e) => {
        e.preventDefault();
        let userCheckedData = this.state.checkedUnits.filter(unit => unit.checked === true);
        if (!userCheckedData.length) { 
            this.setState({
                loaderIconVisible: false,
                info_modal: {
                    open: true,
                    message: "KC1154"
                }
            });
        } else {
            const unitList = [];
            userCheckedData.map(item => {
                return unitList.push(item.id)
            });

            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            let postData = {
                "smartGroupId": this.state.sumartGroupID,
                "newName": this.state.nameFromReport,
                "oldName": this.state.oldSmartGroupName, 
                "newPurposeIds": this.state.selectedoptionsFromReport,
                "oldPurposeIds": this.state.oldSelectedPurposes,               
                "oldUnits": this.state.unModifiedOldSelUnits.length>0?this.state.unModifiedOldSelUnits:[],
                "newUnits": unitList,
                "customerId": localStorage.getItem("customerId"),
                "userId": loggedinUser,
            };
            this.setState({ loaderIconVisible: true });
            let url = smartGroupAPI.createSmartGroup;
            this.postData(url, postData).then(response => {                               
            }).catch(err => {
                this.showError(err);
            });            
        }
    }

    postData = (url, reqData) => {
        this.setState({
            loaderIconVisible: true
        });
        return new Promise((resolve, reject) => {

            http.post(url, reqData,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'user': localStorage.getItem('user')

                    }
                }
            )
                .then(response => {
                    let { data } = response;
                    let { message } = data;

                    if (response && response.status === 200) {
                        this.setState({
                            loaderIconVisible: false,
                            success_modal: {
                                open: true,
                                message: message
                            },
                        });
                    }              
                    
                }).catch(err => {
                    reject(err);
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: err.message,
                        },
                    });
                });
        });
    }

    onFinishClickHandler() {
        let userCheckedData = this.state.checkedUnits;        
        userCheckedData = userCheckedData.filter(contact => contact.checked === true);
		const { mediaSelectedItemsInfo } = this.state;
		const unitList = [];
		userCheckedData.map(item => {
			return unitList.push(
				{
					"thingArn": item.THING,
					"productId": item.ProductId,
					"cloudUnitName": item.CLOUD_UNIT_NAME,
					"unitTimeZone": item.TimeZone_Standard,
				}
			)
		});
		
		const req = {
			"thingList": unitList,
			"mediaId": mediaSelectedItemsInfo
		}
		
		this.postData(MediaManagementAPI.getUploadHistory, req).then(response => {
			this.props.history.push(`/mediaUploadHistory`);
		}).catch(err => {
			this.showError(err);
		});

	}

    onItemClick(e, link) {

        this.setState({
            veryFirstTime:false,
        })

        const copydynasty = [...this.state.selectedDynasty];
        let dynasty = [];
        let checkselectedcopy = true;
        let levelofcountry = 2;
        const previousselected = [...this.state.selectedGroupName];
        let Currentselected = [];
        let matchedornot = false;
        if (previousselected !== undefined && previousselected.length > 0) {
            previousselected.forEach(function (item, index) {
                if ((item.category_id === link.category_id) && (item.level_id === levelofcountry && link.level_id === levelofcountry) && (item.cust_gp_id !== link.cust_gp_id) && (item.dynasty !== link.dynasty) && (item.group_name !== link.group_name)) {
                    matchedornot = true;
                }
            })
        }

        if (copydynasty.length) {
            copydynasty.forEach(el => {
                if (el === link.dynasty) {
                    dynasty = copydynasty.filter(el => el !== link.dynasty);
                    Currentselected = previousselected.filter(s => s.dynasty !== link.dynasty);
                    checkselectedcopy = false;
                }
                else if (el && el.includes(link.dynasty)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];
                }
                else if ((el && link.dynasty.includes(el)) && (!matchedornot)) {
                    dynasty = copydynasty.filter(fl => fl !== el);
                    dynasty = [...dynasty, link.dynasty];

                    Currentselected = previousselected.filter(s => s.dynasty !== el);
                    Currentselected = [...Currentselected, link];

                    checkselectedcopy = false;
                }
                else if (checkselectedcopy) {
                    dynasty = [...copydynasty, link.dynasty];
                    Currentselected = [...previousselected, link]
                }
            })
        }
        else {
            dynasty = [...copydynasty, link.dynasty];
            Currentselected = [...previousselected, link]
        }

        this.setState({
            link: link, isUnitItemClicked: false,
            selectedDynasty: dynasty,
            selectedGroupName: Currentselected,
            initialload: false,
            checkedUnits: [],
            isFormValidated: dynasty.length > 0 ? true : false
        })
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }
    onUnitItemClick(e, unit) {
        let modifyOldSelUnit = [];
        modifyOldSelUnit=this.state.oldSelUnits ;
        const index = modifyOldSelUnit.indexOf(unit.id);
        if (index > -1) {
            modifyOldSelUnit.splice(index, 1);
            unit.checked = false;
        } else {
            modifyOldSelUnit.push(unit.id);
            unit.checked = true;
        }        
        if (modifyOldSelUnit.length>0){
            this.setState({
                oldSelUnits:modifyOldSelUnit,
            });
        }

        let checkedUnits = this.state.checkedUnits ? this.state.checkedUnits : [];         
        if (!checkedUnits.find(item => item === unit)) {
            checkedUnits.push(unit);
        }
        else {
            const findIndex = checkedUnits.findIndex(item => item.id === unit.id);
            checkedUnits[findIndex] = unit;
        }
        this.setState({
            checkedUnits: checkedUnits, 
        });

        let selectedItemCount = checkedUnits.filter(unit => unit.checked === true);

        this.setState({
            isFormValidated: selectedItemCount.length>0 ? true :false, isUnitItemClicked: false, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6
        })
        // this.treeData.activeNode = unit;
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }
    onOrgCheckBoxChange(e, unit) {
        let checkedUnits = this.state.checkedUnits ? this.state.checkedUnits : [];        
        if (e.target.checked) {
            unit.checked = true;
        }
        else {
            unit.checked = false;
        }
        if (!checkedUnits.find(item => item === unit)) {
            checkedUnits.push(unit);
        }
        else {
            const findIndex = checkedUnits.findIndex(item => item.id === unit.id);
            checkedUnits[findIndex] = unit;
        }

        let updateOldSelUnit = [];
        if (e.target.checked) {
            updateOldSelUnit = [...this.state.oldSelUnits];
            updateOldSelUnit.push(unit.id);
            
        } else {            
            const copyOldSelUnits = [...this.state.oldSelUnits];
            const index = copyOldSelUnits.findIndex(item => item === unit.id);
            copyOldSelUnits.splice(index, 1);
            updateOldSelUnit = copyOldSelUnits;
        }

        this.setState({
            checkedUnits: checkedUnits, 
            isUnitItemClicked: true, link: {}, isAddressAvailable: false, unit: unit,
            isLoactionTypeAvailable: false, level: 6,
            oldSelUnits:updateOldSelUnit,
        })
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }

    // showUnits(link) {
    //     let { initialload, selectedDynasty } = this.state;
    //     const showCollpaeCount = this.showCollpaseItemCount(link, true);
    //     if (link.level_id === 5 && link.units && link.units.length > 0) {
    //         return link.units.map((unit, index) => {
    //             let treeActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "disabled" : !unit.THING ? "disabled"  : ""
    //             // let pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
    //             let pickUnit = {};
    //             const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
    //             const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unit.BRAND_NAME && i.MODEL_NAME === unit.MODEL_NAME) : [];
    //             if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "" ) {
    //                 pickUnit['img'] = filteredBrandImage[0].LOGO_URL;
    //                 pickUnit['alt'] = filteredBrandImage[0].MODEL_NAME;
    //                 pickUnit['title'] = filteredBrandImage[0].MODEL_NAME;
    //             }else{
    //                 pickUnit = brandsLogo.find(b => b.name === unit.MODEL_NAME);
    //             }
    //             const unitInActive = unit.ACTIVATION_STATUS === "INACTIVE" ? "unitDeactive" : !unit.THING ? "unitNotProvisioned"  : ""
    //             let checkedUnits = this.state.checkedUnits;     
                
    //             let ifExist =false;
    //             let isParentDivActive="";     
                
    //             let array2= this.state.dynastyValue;

    //             ifExist = array2 && array2.find(a2 =>  Number(a2) === unit.CUSTOMER_GROUP_ID); 
    //             if (treeActive !== "disabled"){
    //                 treeActive = ifExist ? "treeActive" : "";
    //                 isParentDivActive = ifExist ? 'treePrentDivActive' : '';
    //             }                
    //             if (ifExist){
    //                 unit.checked = true;
    //                 if (!checkedUnits.find(item => item === unit)) {
    //                     checkedUnits.push(unit);
    //                 }
    //             }  

    //             if (selectedDynasty.length > 0) {
    //                 selectedDynasty.map((dynasty, index) => {
    //                     if (link.dynasty.includes(dynasty)) {
    //                         unit.dynasty = dynasty;
    //                         if (!initialload && (unit.ACTIVATION_STATUS === "INACTIVE" || !unit.THING)) { 
    //                             unit.checked = false;
    //                             if (checkedUnits.find(item => item === unit)) {
    //                                 checkedUnits = checkedUnits.filter(item => item !== unit);
    //                             }
    //                         }
    //                         else if (!initialload && !showCollpaeCount && unit.ACTIVATION_STATUS !== "INACTIVE" && unit.THING) {
    //                             if (checkedUnits.find(item => item.UNITID === unit.UNITID && item.checked === false)) {
    //                                 unit.checked = false;
    //                             }
    //                             else {
    //                                 unit.checked = true;
    //                                 if (!checkedUnits.find(item => item === unit)) {
    //                                     checkedUnits.push(unit);
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 })
    //             }
    //             else {
    //                 this.state.checkedUnits = checkedUnits;
    //             }
    //             let smartGroupDet = unit.smartGroup ? unit.smartGroup : [];
    //             let smartGroupName=[];
    //             if (smartGroupDet.length > 3) {
    //                 smartGroupName.push({
    //                     "sgName":"",
    //                     "sgFirstName": smartGroupDet.length,
    //                 })
    //             } else if (smartGroupDet.length > 0) {
    //                 smartGroupDet.map((item, i) => {
    //                     smartGroupName.push({
    //                         "sgName":item.name,
    //                         "sgFirstName":item.smartGroupKeyName && item.smartGroupKeyName !=="null" ? item.smartGroupKeyName : item.name.charAt(0),
    //                     })
    //                 })
    //             }
    //             return <li>                    
    //                 <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
    //                     <Link to="#" className={treeActive}>
    //                         {showCollpaeCount && <div><span className="treeImg">
    //                             <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
    //                         </span>
    //                             <span className="treeText deviceModel"
    //                             ><b>{unit.UNITID}</b></span>
    //                             <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
    //                         {!showCollpaeCount && <div><span className="treeImg">
    //                             <img src={pickUnit ? pickUnit.img : bg} alt="" />
    //                         </span>
    //                          <span className="treeText deviceModel">{unit.UNITID}<b>{unit.UNIT_NUMBER}</b> <b>{unit.MODEL_NAME}</b></span>
    //                             <span className={unitInActive}></span>
    //                             {unit.isUnitTagExist && <button className="btn tagedUnits" type="button" onClick={(e) => {
    //                                 if (e.defaultPrevented) return
    //                                 e.preventDefault()
    //                                 this.showSTagInfo(unit, "unit")
    //                             }}>&nbsp;</button>}
    //                             {/* {smartGroupName.map((item, i) => {
    //                                 return <span className = "sgFL">{item.sgFirstName}&nbsp;</span>
    //                             })}  */}
    //                         </div>}
    //                     </Link>
    //                 </div>
    //             </li>
    //         });
    //     }
    // }
    // checkSamelevel(pDynasty, cDynasty) {
    //     let pDyn = [];
    //     let cDyn = [];
    //     let sameLevel = true;
    //     if (pDynasty && pDynasty.length > 0) {
    //         pDyn = pDynasty.split(',')
    //     } else {
    //         sameLevel = false;
    //     }
    //     if (cDynasty && cDynasty.length > 0) {
    //         cDyn = cDynasty.split(',')
    //     } else {
    //         sameLevel = false;
    //     }
    //     pDyn.map((i, index) => {
    //         if (pDyn[index] !== cDyn[index]) return sameLevel = false;
    //     })
    //     return sameLevel;

    // }
    // onCollapseButtonClick(e, link) {
    //     let showLink = this.state.showLink;
    //     let collpaseObj = { dynasty: '', collapsed: false, count: 0 }
    //     const findIndex = showLink.findIndex(item => item.dynasty === link.dynasty);
    //     if (e.target['className'].includes('collapsed')) {
    //         collpaseObj = { dynasty: link.dynasty, collapsed: false, count: link.child_count }
    //     } else {
    //         collpaseObj = { dynasty: link.dynasty, collapsed: true, count: link.child_count }
    //     }
    //     if (findIndex > -1) {
    //         showLink[findIndex] = collpaseObj;
    //     } else {
    //         showLink.push(collpaseObj)
    //     }

    //     this.setState({ showLink: showLink })
    // }
    // showCollpaseItemCount(link, showUnits = false) {
    //     const { showLink } = this.state;
    //     let collapseItem;
    //     if (showUnits) {
    //         collapseItem = showLink.find(item => {
    //             if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
    //                 item.dynasty.split(',').length === link.dynasty.split(',').length)
    //                 return item;
    //         });
    //     } else {
    //         collapseItem = showLink.find(item => {
    //             if (link.dynasty && this.checkSamelevel(item.dynasty, link.dynasty) && item.collapsed &&
    //                 item.dynasty.split(',').length + 1 === link.dynasty.split(',').length)
    //                 return item;
    //         });
    //     }
    //     if (collapseItem) {
    //         if (link.dynasty && collapseItem) {
    //             return collapseItem.count;
    //         }
    //         return null;
    //     } else {
    //         return null;
    //     }
    // }

    showSTagInfo = (node, type = "org") => {
        this.setState({
            showStPopUp: true,
            isUnitItemClicked: type === "unit" ? true : false,
            unit: type === "unit" ? node : {},
            link: type === "org" ? node : {},
        }, () => {
            $('#unitSmartTagsDetails').modal('show')
        })

    }

    // showLinks(linkList) {
    //     let level;
    //     let groupName = '';
    //     let picLocation;
    //     if (!linkList) return null;        
    //     if (linkList && linkList.length > 0) {            
    //         return linkList.map((link, index) => {
    //             level = link.level_id;
    //             groupName = link.group_name;
    //             const nextLevel = level + 1;
    //             const id = link.cust_gp_id + link.parent_group_id + link.category_id + link.level_id;
    //             const leveClassName = 'level' + nextLevel + 'Expand0' + id;

    //             let array2 = this.state.dynastyValue;

    //             const isParentDivActive = array2 && array2.find(a2 =>  Number(a2) === link.cust_gp_id) ? 'treePrentDivActive' : '';
    //             const treeActive = (array2 && array2.find(a2 =>  Number(a2) === link.cust_gp_id)) ? 'treeActive' : '';


    //             const showCollpaeCount = this.showCollpaseItemCount(link);

    //             const location_type_Select = JSON.parse(link.dynastyAttributes);
    //             if (location_type_Select && location_type_Select.location_type) {
    //                 picLocation = brandsLocationLogo.find(b => b.name === location_type_Select.location_type);
    //             }
    //             return <li>
    //                 <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
    //                     <Link to="#" className={(link.level_id === 5 && link.units && link.units.length > 0)
    //                         || (link['level' + nextLevel] && link['level' + nextLevel].length > 0)
    //                         ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}>
    //                         {showCollpaeCount && <div><span className="treeImg">
    //                             <img src={require("../../../images/unit/CremCarrera.svg").default} alt=""  />
    //                         </span>
    //                             <span className="treeText deviceModel">
    //                                 <b>{groupName}</b></span>
    //                             <span className="treeCountDispaly" >{showCollpaeCount} </span></div>}
    //                         {!showCollpaeCount && <div><span className="treeImg">
    //                             <img src={link.imagePath ? link.imagePath : picLocation ? picLocation.img : bg} alt=""  />
    //                         </span>
    //                             <span className="treeText deviceModel">
    //                                 <b>{groupName}</b></span></div>}
    //                         {link.isOrgTagExist && <button className="btn orgTagedUnits" type="button" onClick={(e) => {
    //                             if (e.defaultPrevented) return
    //                             e.preventDefault()
    //                             this.showSTagInfo(link, "org")
    //                         }}>&nbsp;</button>}
    //                     </Link>
    //                     {((link['level' + nextLevel] && link['level' + nextLevel].length > 0) ||

    //                         (link.level_id === 5 && link.units && link.units.length > 0)) &&
    //                         <button className="btn btn-primary expdCollpse  " type="button" data-toggle="collapse"
    //                             data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
    //                             onClick={(e) => this.onCollapseButtonClick(e, link)}>
    //                             &nbsp;</button>}
    //                     <ul className="collapse show" id={leveClassName}>
    //                         {this.showLinks(link['level' + nextLevel])
    //                         }
    //                         {this.showUnits(link)}
    //                     </ul>
    //                 </div>
    //             </li>

    //         });
    //     }
    //     level = linkList.level_id;
    //     groupName = linkList.group_name;
    //     const nextLevel = level + 1;
    //     const id = linkList.cust_gp_id + linkList.parent_group_id + linkList.category_id + linkList.level_id;
    //     const leveClassName = 'level' + nextLevel + 'Expand0' + id;
        
    //     let array2 = this.state.dynastyValue;

    //     const isParentDivActive = array2 && array2.find(a2 =>  Number(a2) === linkList.cust_gp_id) ? 'treePrentDivActive' : '';
    //     const treeActive = (array2 && array2.find(a2 =>  Number(a2) === linkList.cust_gp_id)) ? 'treeActive' : '';
    //     return <ul>
    //         <li>
    //             <div className={'orgznSiteTreeLiDiv' + ' ' + isParentDivActive}>
    //                 <Link to="#" className={linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0
    //                     ? 'treeNodeAvailable' + ' ' + treeActive : 'treeNodeAvailableNoLast' + ' ' + treeActive}>
    //                     <span className="treeImg">
    //                         <img src={require("../../../images/unit/CremCarrera.svg").default} alt="" />
    //                     </span>
    //                     <span className="treeText deviceModel"
    //                     ><b>{groupName}</b></span>
    //                     {linkList.isOrgTagExist && <button className="btn orgTagedUnits" type="button" onClick={(e) => {
    //                         if (e.defaultPrevented) return
    //                         e.preventDefault()
    //                         this.showSTagInfo(linkList, "org")
    //                     }}>&nbsp;</button>}
    //                 </Link>
    //                 {((linkList['level' + nextLevel] && linkList['level' + nextLevel].length > 0) ||

    //                     (linkList.level_id === 5 && linkList.units && linkList.units.length > 0)) &&
    //                     <button className="btn btn-primary expdCollpse " type="button" data-toggle="collapse"
    //                         data-target={'#' + leveClassName} aria-expanded="false" aria-controls={leveClassName}
    //                         onClick={(e) => this.onCollapseButtonClick(e, linkList)}>
    //                         &nbsp;</button>}

    //                 <ul className="collapse show " id={leveClassName}>
    //                     {linkList['level' + nextLevel] && this.showLinks(linkList['level' + nextLevel])}
    //                     {this.showUnits(linkList)}
    //                 </ul>

    //             </div>
    //         </li>
    //     </ul>
    // }
    
    onClickListView = () => {
        this.props.history.push({
            pathname: "/editsmartGrpAssignUpdateList",
            state: {
                nameFromReport: this.state.nameFromReport,               
                selectedoptionsFromReport: this.state.selectedoptionsFromReport,
                sumartGroupID:this.state.sumartGroupID,
                oldSmartGroupName: this.state.oldSmartGroupName, 
                oldSelectedPurposes: this.state.oldSelectedPurposes, 
                pageFrom:this.state.findPageName,
            },
            previouspath: this.state.previouspath,
            scheduleDateTimeUtc: this.state.scheduleDateTimeUtc,
            Type: this.state.Type,
            libraryIds: this.state.libraryIds,
            libraryType: this.state.libraryType
        });
    }

    formBreadCrum = (dynasty) => {
        const { flattenTreeData } = this.state;
        let crum = [];
        const dynastyArr = dynasty.split(',');
        for (let index = 0; index < flattenTreeData.length; index++) {
            for (let indexJ = 0; indexJ < flattenTreeData[index].length; indexJ++) {
                const nodeEle = flattenTreeData[index][indexJ];
                if (nodeEle && nodeEle.cust_gp_id) {
                    if (dynastyArr.includes(nodeEle.cust_gp_id.toString())) {
                        crum.push(nodeEle.group_name);
                    }
                }
            }
        }
        // return crum && crum.length ? crum.join(' &gt; ') : '';
        return crum && crum.length ? crum.join(' > ') : '';
    }

    /**User Story 83428: Org View Collapse/Expand - All assign, transfer Screens*/

    /**
     * Check if the row has at least one visible node
     * @param {*} rowData Row Array
     * @returns Boolean - Return true if it has visible node else return false
     */
    doesRowHasVisibleNode(rowData) {
        return rowData.some((node, i) => node && node.__NODE_DATA__.isNode && (i < 5 ? !this.getNodeStatus(node).isInvisible : !this.getUnitNodeStatus(node).isInvisible));
    }
    /**
    * Get the Node status like invisible, count node
    * @param {*} node Node object
    * @returns Object Status object
    */
    getNodeStatus(node) {
        const nodeData = node.__NODE_DATA__;
        const dynastyArr = node.dynasty.split(',').map(item => parseInt(item));
        // Visibility, Count Node, Display count calculation
        const dynastyArrCopy = [...dynastyArr];
        dynastyArrCopy.pop(); // Remove its own ID
        dynastyArrCopy.length && dynastyArrCopy.pop(); // Remove parent node ID
        const isForeNodeCollapsed = dynastyArrCopy.some(item => this.treeData.collapsedNodes[item]);
        const isParentCollapsed = this.treeData.collapsedNodes[node.parent_group_id];
        const isFirstChild = nodeData.isFirstChild || nodeData.isOnlyChild;
        const parentNode = nodeData.parentRowIndex !== null ? this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex] : null;
        // Active status, Root Node active status calculation
        let isRootActiveNode = true;
        let isActive = false;
            for (let i = 0; i<=5; i++){
                if(this.state?.dynastyValue.includes(dynastyArr[i])){
                    isActive = true;
                    break;
                }
            }
            if (this.state?.dynastyValue.includes(node.parent_group_id)) isRootActiveNode = false;
            
        return {
            isInvisible: isForeNodeCollapsed || (isParentCollapsed && !isFirstChild),
            isCountNode: !isForeNodeCollapsed && isParentCollapsed && isFirstChild,
            displayCount: parentNode ? parentNode.child_count : 0,
            isRootActiveNode,
            isActive
        };
    }
    /**
     * Row render callback for Virtual Scroll component
     * @param {*} param Event object
     * @returns ReactDOM
     */
    treeViewRowRenderer({ index, key, style }) {
        const { flattenTreeData } = this.state;
        const rowData = flattenTreeData[index];
        if (!this.doesRowHasVisibleNode(rowData)) {
            return <div className="treeViewRow" key={key} style={style}></div>
        }
        return <div className="treeViewRow" key={key} style={style}>
            {new Array(5).fill().map((_, j) => this.getNodeView(rowData[j], { key: `${key}_${j}` }))}
            {this.getUnitNodeView(rowData[5], { key: `${key}_5` })}
        </div>
    }
    /**
     * Create view elements for Tree View Unit Node
     * @param {*} unitNode Unit node object
     * @param {*} options Additional options
     * @returns ReactDOM
     */
    getUnitNodeView(unitNode, { key }) {
        // For empty
        if (!unitNode) {
            return <div key={key} className="treeViewCell"></div>
        }
        // For node
        const status = this.getUnitNodeStatus(unitNode);
        if (status.isInvisible) {
            return <div key={key} className="treeViewCell"></div>
        } else if (status.isCountNode) {
            const { showElementInfo } = this.state;
            const activeClass = status.isActive ? 'active' : '';
            const rootActiveClass = status.isRootActiveNode ? 'treeActive' : '';
            const activeValueKey  = unitNode?.subscription_status !== null && unitNode?.subscription_status !== 'null' ? unitNode.subscription_status === 0 ? 'INACTIVE' : 'ACTIVE' : unitNode.ACTIVATION_STATUS;
            const treeActive = (activeValueKey === "INACTIVE" || activeValueKey === "INACTIVE_ONLINE") ? "disabled" : !unitNode.THING ? "disabled"  : ""
            return <div key={key} className={`treeViewCell unitCell countCell ${activeClass} ${rootActiveClass} ${treeActive}`}>
                <Link
                    className="treeViewNode"
                    to="#"
                    onDoubleClick={() => { this.setState({ showElementInfo: !showElementInfo }) }}
                    // onClick={(e) => { this.onUnitItemClick(e, unitNode) }}
                    >
                    {status.displayCount}
                </Link>
            </div>
        }
        const { showElementInfo, link } = this.state;
        //this.showUnits(link);
        const brandImageStorage = localStorage.getItem('modelImage') ? JSON.parse(localStorage.getItem('modelImage')) : [];
        const filteredBrandImage = brandImageStorage && brandImageStorage.length ? brandImageStorage.filter((i) => i.BRAND_NAME === unitNode.BRAND_NAME && i.MODEL_NAME === unitNode.MODEL_NAME) : [];
        let unitImage = '';
        if (filteredBrandImage && filteredBrandImage.length && filteredBrandImage[0].LOGO_URL && filteredBrandImage[0].LOGO_URL !== "") {
            unitImage = filteredBrandImage[0].LOGO_URL;
        } else {
            unitImage = brandsMap[unitNode.MODEL_NAME] ? brandsMap[unitNode.MODEL_NAME].img : bg;
        }
        let checkUnitsIDsSelected = this.state.totalUnits && this.state.totalUnits.findIndex((item => unitNode.UNITID == item.UNITID));
        const activeValueKey  = unitNode?.subscription_status !== null && unitNode?.subscription_status !== 'null' ? unitNode.subscription_status === 0 ? 'INACTIVE' : 'ACTIVE' : unitNode.ACTIVATION_STATUS;

        const isUnitInActive = activeValueKey === 'INACTIVE-ONLINE' || activeValueKey === 'INACTIVE';
        const treeActive = activeValueKey === 'INACTIVE-ONLINE' || activeValueKey === 'INACTIVE' ? "disabled" : !unitNode.THING ? "disabled"  : ""
        const unitInActive = activeValueKey === 'INACTIVE-ONLINE' || activeValueKey === 'INACTIVE' ? "unitDeactive" : !unitNode.THING ? "unitNotProvisioned"  : ""
        const nodeData = unitNode.__NODE_DATA__;
        const { isFirstChild, isMidwayChild, isLastChild } = nodeData;
        const firstChildClass = isFirstChild ? 'firstChild' : '';
        const midwayChildClass = isMidwayChild ? 'midwayChild' : '';
        const lastChildClass = isLastChild ? 'lastChild' : '';
        const activeClass = status.isActive && checkUnitsIDsSelected != -1 ? 'active' : '';
        const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
        return <div key={key} className={`treeViewCell unitCell ${firstChildClass} ${midwayChildClass} ${lastChildClass} ${activeClass} ${rootActiveClass}`}>
            <Link
                to="#"
                className={`treeViewNode ${isUnitInActive ? 'inActiveUnitNode' : ''} ${treeActive}`}
            >
                <img className="nodeImg" src={unitImage} />
                <span className="nodeInfo">
                    <b className="d-block text-truncate unitSerialNo">{unitNode.UNITID}</b>
                    <b className="d-block text-truncate">{unitNode.UNIT_NUMBER}</b>
                    <b className="d-block text-truncate">{unitNode.MODEL_NAME}</b>
                    {/* {
                        nodeData.smartGroupsName.map((item, i) => {
                            return <span key={i} className="sgIndicator">{item.sgFirstName}&nbsp;</span>
                        })
                    } */}      
                </span>
                {isUnitInActive ? <span className="inActiveIcon"></span> : ""}
                <span className={unitInActive}></span>
                {unitNode.isUnitTagExist && <button className="btn tagedUnits" type="button" onClick={(e) => {
                    if (e.defaultPrevented) return
                    e.preventDefault()
                    this.showSTagInfo(unitNode, "unit")
                }}>&nbsp;</button>}
                </Link>
        </div>
    }
    /**
     * Tree View node expand/collapse click handler
     * @param {*} node Node object
     */
    handleExpandCollapse(node) {
        if (!this.treeData.collapsedNodes[node.cust_gp_id]) {
            this.treeData.collapsedNodes[node.cust_gp_id] = true;
        } else {
            delete this.treeData.collapsedNodes[node.cust_gp_id];
        }
        this.treeData.virtualScrollRef.current.recomputeRowHeights();
    }
    /**
     * Return the computed Tree View row height
     * Compute the height based on row content
     * @param {*} param Event object
     * @returns Number
     */
    getTreeViewRowHeight({ index }) {
        const rowData = this.state.flattenTreeData[index];
        if (!this.doesRowHasVisibleNode(rowData)) {
            return 0;
        }
        // Compute row height based on unit node
        const unitNode = rowData[5];
        const status = unitNode ? this.getUnitNodeStatus(unitNode) : null;
        let rowBaseHeight = this.treeData.rowBaseHeight;
        if (unitNode && !status.isInvisible && !status.isCountNode) {
            if (unitNode.UNIT_NUMBER && unitNode.MODEL_NAME) {
                rowBaseHeight = rowBaseHeight + 15;
            }
            if (unitNode.__NODE_DATA__.smartGroupsName.length) {
                rowBaseHeight = rowBaseHeight + 22;
            }
        }
        return rowBaseHeight;
    }
    /**
     * Create view elements for Tree View Node
     * @param {*} node Node object
     * @param {*} options Additional options
     * @returns ReactDOM
     */
    getNodeView(node, { key }) {
        // For empty or connector
        if (!node) {
            return <div key={key} className="treeViewCell"></div>
        } else if (node.__NODE_DATA__.isConnector) {
            const parentNode = this.state.flattenTreeData[node.__NODE_DATA__.parentRowIndex][node.__NODE_DATA__.parentColIndex];
            const activeClass = this.getNodeStatus(parentNode).isActive ? 'active' : '';
            return <div key={key} className={`treeViewCell connector ${activeClass}`}></div>
        }
        // For node
        const nodeData = node.__NODE_DATA__;
        const status = this.getNodeStatus(node);
        if (status.isInvisible) {
            return <div key={key} className="treeViewCell"></div>
        } else if (status.isCountNode) {
            const activeClass = status.isActive ? 'active' : '';
            const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
            return <div key={key} className={`treeViewCell countCell ${activeClass} ${rootActiveClass}`}>
                <div className="treeViewNodeWrapper">
                    <Link
                        className="treeViewNode"
                        to="#"
                        // onClick={(e) => { this.onItemClick(e, this.getNodeFromHierarchyData(node)) }}
                        >
                        {status.displayCount}
                    </Link>
                </div>
            </div>
        }
        const dynastyAttributes = node.dynastyAttributes ? JSON.parse(node.dynastyAttributes) : {};
        const nodeImage = node.level_id && (node.level_id === 5 || node.level_id === 4) && brandLocationsMap[dynastyAttributes.location_type] && brandLocationsMap[dynastyAttributes.location_type].img ?  brandLocationsMap[dynastyAttributes.location_type].img : node.imagePath || (brandLocationsMap[dynastyAttributes.location_type]
            ? brandLocationsMap[dynastyAttributes.location_type].img
            : bg);
        const { isFirstChild, isMidwayChild, isLastChild, hasChildren, unitCount } = nodeData;
        const isCollapsed = this.treeData.collapsedNodes[node.cust_gp_id];
        const firstChildClass = isFirstChild ? 'firstChild' : '';
        const midwayChildClass = isMidwayChild ? 'midwayChild' : '';
        const lastChildClass = isLastChild ? 'lastChild' : '';
        const hasChildrenClass = hasChildren ? 'hasChildren' : '';
        const activeClass = status.isActive ? 'active' : '';
        const rootActiveClass = status.isRootActiveNode ? 'rootActive' : '';
        const hasChildrenClassVisibile = hasChildrenClass == '' ? unitCount ? 'hasChildren' : hasChildrenClass : hasChildrenClass;
        return <div key={key} className={`treeViewCell ${firstChildClass} ${midwayChildClass} ${lastChildClass} ${hasChildrenClassVisibile} ${activeClass} ${rootActiveClass}`}>
            <div className="treeViewNodeWrapper">
                <Link
                    className="treeViewNode"
                    to="#"
                    // onClick={(e) => {
                    //     if (e.defaultPrevented) return
                    //     e.preventDefault(); 
                    //     // this.onItemClick(e, this.getNodeFromHierarchyData(node), nodeImage)
                    //      }}
                         >
                    <img className="nodeImg" src={nodeImage} alt="" />
                    <span className="text-truncate nodeInfo"><b>{node.group_name}</b></span>
                    {node.isOrgTagExist && <button className="btn orgTagedUnits" type="button" onClick={(e) => {
                            if (e.defaultPrevented) return
                            e.preventDefault()
                            this.showSTagInfo(node,"org")
                    }}>&nbsp;</button>}
                </Link>
                {hasChildren || hasChildren == "" && unitCount ? <button className={`expandCollapseBtn ${isCollapsed ? 'collapsed' : ''}`} onClick={() => this.handleExpandCollapse(node)}></button> : ''}
            </div>
        </div>
    }
    /**
         * Get Node object from Hierarchy Data Structure
         * Use this method to retain the props removed during flatten array conversion
         * @param {*} searchNode Node to search
         * @returns Node object
         */
    getNodeFromHierarchyData(searchNode) {
        const dynastyArr = searchNode.dynasty.split(',').map(item => parseInt(item));
        let node = this.state.linkList;
        for (let i = 0; i < dynastyArr.length; i++) {
            const nodes = node[`level${i + 1}`];
            for (let j = 0; j < nodes.length; j++) {
                if (nodes[j].cust_gp_id === dynastyArr[i]) {
                    node = nodes[j];
                    break;
                }
            }
        }
        node = { ...node };
        node.__NODE_DATA__ = { ...searchNode.__NODE_DATA__ };
        return node;
    }
    /**
     * Get the Unit Node status like invisible, count node
     * @param {*} unitNode Unit Node object
     * @returns Object Status object
     */
    getUnitNodeStatus(unitNode) {
        const nodeData = unitNode.__NODE_DATA__;
        const parentNode = this.state.flattenTreeData[nodeData.parentRowIndex][nodeData.parentColIndex];
        const dynastyArr = parentNode.dynasty.split(',').map(item => parseInt(item));
        // Visibility, Count Node, Display count calculation
        const dynastyArrCopy = [...dynastyArr];
        dynastyArrCopy.pop(); // Remove its own ID
        const isForeNodeCollapsed = dynastyArrCopy.some(item => this.treeData.collapsedNodes[item]);
        const isParentCollapsed = this.treeData.collapsedNodes[parentNode.cust_gp_id];
        const isFirstChild = nodeData.isFirstChild || nodeData.isOnlyChild;
        // Active status, Root Node active status calculation
        let isRootActiveNode = true;
        let isActive = false;
        for (let i = 0; i <= dynastyArr.length; i++) {
            if (this.state?.dynastyValue.includes(dynastyArr[i])) {
                isActive = true;
                break;
            }
        }

        if (this.state?.dynastyValue.includes(unitNode.CUSTOMER_GROUP_ID)) isRootActiveNode = false;

        return {
            isInvisible: isForeNodeCollapsed || (isParentCollapsed && !isFirstChild),
            isCountNode: !isForeNodeCollapsed && isParentCollapsed && isFirstChild,
            displayCount: parentNode.child_count,
            isRootActiveNode,
            isActive
        };
    }
    /*  */
    handleManualExpandCollapse = (levelID, data, makeCollapse, testvalue) => {
        this.treeData.active = levelID
        _.forEach(data, item => {
            _.forEach(item, node => {
                if (node && node.category_id == levelID && node.child_count > 0 && makeCollapse) {
                    if (!this.treeData.collapsedNodes[node.cust_gp_id]) {
                        this.treeData.collapsedNodes[node.cust_gp_id] = true;
                        this.treeData.expand[node.level_id] = true;
                    } else {
                        delete this.treeData.collapsedNodes[node.cust_gp_id];
                        delete this.treeData.expand[node.level_id];
                    }
                }
            })
        })
        if(this.treeData && this.treeData.virtualScrollRef && this.treeData.virtualScrollRef.current && this.treeData.virtualScrollRef.current.recomputeRowHeights){
            this.treeData.virtualScrollRef.current.recomputeRowHeights();
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { success_modal, error_modal, info_modal,  isFormValidated, selectedGroupName, showpopup, type, flattenTreeData, dynastyValue } = this.state;
        const { loaderIconVisible, linkList } = this.state;    
        let userCheckedData = this.state.checkedUnits;        
        userCheckedData = userCheckedData.filter(contact => contact.checked === true);           
        if (isFormValidated) {
            $("#btnfinish").addClass("btn-default-text activeState");
            $("#btnfinish").removeClass("disabled");
        }
        else {
            $("#btnfinish").removeClass("activeState");
            $("#btnfinish").addClass("disabled");
        }
        let levelHeader = null;
        if (_.isObject(linkList)) {
            const { categoryList } = linkList;
            if (categoryList) {
                levelHeader = (
                    categoryList.filter(el => el.level_id >= 1).map(item => <li>
                        <Link className={item.level_id === 1 ? "" : this.treeData.expand[item.level_id - 1] ? `collapseExpandHeader ${item.level_id - 1 === this.treeData?.active ? "activeArrow" : ''}` : "collapseExpandHeader expanded"} key={item.level_id} to="#" title={formatMessage({ id: 'KC1693' }, { number: item.level_id })} onClick={() => this.handleManualExpandCollapse(item.level_id - 1, this.state.flattenTreeData, true)}>{item.category_name}</Link>
                    </li>)
                );
            }
        }

        return (
            <div id="adminWrapper">
                <div className="treeInfoToggleTransparent"></div>
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="unitRegistrationWrapper">
                        <div className="assignOrgHeader">
                            <ul>
                                <li>
                                    <Link to={{
                                        pathname: this.props.location.previousPath,
                                        state: {
                                            nameFromReport: this.state.nameFromReport,
                                            selectedoptionsFromReport: this.state.selectedoptionsFromReport,
                                            pageFrom:this.state.findPageName,
                                        }
                                    }} title={formatMessage({ id: 'KC0989' })} class="backIcon">&nbsp;</Link>
                                </li>
                                <li>
                                    <FormattedMessage id="KC1541"/>
					            </li>
                                
                            </ul>
                        </div>
                        <div className="selectedUnitHeader">
                            <ul>
                                <li>
                                    {
                                    selectedGroupName.map((subitem, j) =>
                                        <span className="selectedVal">
                                        {subitem.group_name} &nbsp; 
                                        </span>
                                    )}
                                </li>
                                <li>
                                    {/* <Link to="/menuSmartGroupsList" id="btnAssign"   className="sgStatus" onClick={this.onClickListView}>&nbsp;</Link> */}
                                    <Link to="#" class="assignunitSite active">&nbsp;</Link>
                                    <Link to={{
                                        pathname: '/organizationChartListView',
                                        getCgids:this.props.location.getCgids,
                                        previousPath: this.props.location.previousPath
                                    }} id="btnAssign" className="assignunitList" onClick={this.onClickListView}>&nbsp;</Link>
                                </li>
                            </ul>
                        </div>
                        
                            <div className="collapseExpandHeader flatten">
                                <ul>
                                    {levelHeader}
                                </ul>
                            </div>

                        <div className="assignOrgTreeWrapper">
                            <div className="orgznSiteTree">
                                <div className="treeViewBody">
                                    <AutoSizer>
                                        {({ height, width }) => (
                                            <List
                                                ref={this.treeData.virtualScrollRef}
                                                width={width || 100}
                                                height={height || 100}
                                                overscanRowCount={this.treeData.virtualScrollBufferRowCount}
                                                rowCount={flattenTreeData.length}
                                                rowHeight={this.getTreeViewRowHeight}
                                                rowRenderer={this.treeViewRowRenderer} />
                                        )}
                                    </AutoSizer>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* <!-- Transfer Modal --> */}
                {this.state.showStPopUp ? <OrgTagsDetail closeSTModel={() => this.setState({ showStPopUp: false })} nodeDet={this.state.link} unitDet={this.state.unit} isUnit={this.state.isUnitItemClicked} formBreadCrum={this.formBreadCrum} /> : ""}
                <div className="modal fade reportDetailsModal" data-keyboard="true" data-backdrop="static" id="stUnitDetailsModal" tabindex="-1" role="dialog" aria-labelledby="stUnitDetailsModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-header modal-header-danger">
                                <FormattedMessage id="KC2312" />
                            </div>
                            <div className="modal-body">
                                {showpopup && showpopup.map((item, i) => <div className="reportDetailsD">
                                    <div className="createdEditedDetailsO">
                                        <ul className="tHead">
                                            <li>
                                                <div className={type === "orgList" ? "tagTypeLocation" : "tagTypeUnit"}><span>{item.tagName}</span></div>
                                            </li>
                                        </ul>
                                    </div>
                                    <h6><FormattedMessage id="KC0195" /></h6>
                                    <p>{item.description ? item.description : 'NA'}</p>
                                    <div className="createdEditedDetailsO">
                                        <ul className="tHead">
                                            <li>
                                                <p><FormattedMessage id="KC2313" /> <button type="button" onClick={() => $('#stUnitDetailsModal').modal('hide')} data-toggle="modal" data-target="#orgViewModal" className="orgViewIcon btn"><img src={require('../../../images/icons/iconUnitSelection.svg').default} /></button> {type === 'orgList' ? formatMessage({ id: "KC2306" }) : formatMessage({ id: "KC2307" })}</p>
                                            </li>
                                            {type === 'orgList' ? "" :
                                                <li>
                                                    <Link to='#' onClick={() => this.downloadUnitList(item.tagName)} className="downloadListof"><FormattedMessage id="KC2314" /></Link>
                                                </li>}
                                        </ul>
                                    </div>
                                    <div className="createdEditedDetailsO">
                                        <ul className="tHead">
                                            <li><FormattedMessage id="KC0589" /></li>
                                            <li><FormattedMessage id="KC1278" /></li>
                                        </ul>
                                        <ul className="tBody">
                                            <li>
                                                <div className="createdEditedDetails">
                                                    <ul>
                                                        <li><FormattedMessage id="KC0241" />:</li>
                                                        <li>{item.createdByName ? item.createdByName : ''}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC0136" />:</li>
                                                        <li>{item.createdBy ? item.createdBy : ''}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC0298" />:</li>
                                                        <li>{item.createdAt ? moment.GetDateTimeByFormat(item.createdAt, 'LLL') : ''}</li>
                                                    </ul>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="createdEditedDetails">
                                                    <ul>
                                                        <li><FormattedMessage id="KC0241" />:</li>
                                                        <li>{item.updatedByName ? item.updatedByName : ''}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC0136" />:</li>
                                                        <li>{item.updatedBy ? item.updatedBy : ''}</li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC0298" />:</li>
                                                        <li>{item.UpdatedAt ? moment.GetDateTimeByFormat(item.UpdatedAt, 'LLL') : ''}</li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>)}
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary okBtn" data-dismiss="modal" onClick={() => this.setState({ showpopup: [], showOrgPopup: false }, () => $('#transferDetailsModal').modal('show'))} type="button"><FormattedMessage id="KC0777" /></button>
                            </div>
                        </div>
                    </div>
                </div >
                {this.state.showOrgPopup ? <TagOrgModal details={this.state.showpopup && this.state.showpopup.length ? this.state.showpopup[0] : {}} /> : null}
                <ErrorMoal open={info_modal.open} stringID={info_modal.message} onCloseModel={this.closeModel} />
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.redirectModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />                                
                <UserManagementHeader headerName="Media Management" headerNameStringID="KC0632" activeClass="mediaNav" />
            </div>

        )
    }
};

function mapStateToProps(state) {
    return {
        userModel: state.userReducer.UserModel,
        filterlistInformtion: state.filterModel,
        mediaSelectedItemsInfo: state.mediaPreparationListInformation.mediaSelectedItems,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setUserDataModel,
        getSelectedFilterList,
    }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(OrganizationChartView));