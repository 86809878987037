import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import ErrorMoal from '../../../../../modal/error-model';
import { FormattedMessage, injectIntl } from 'react-intl';

class FrymasterCookingBasket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            delayTimer: "",
            basketLift: "true",
            error_modal: {
                open: false,
                message: ""
            },
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        try {
            let jsonSettingsFile = localStorage.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            this.setState({
                delayTimer: ObjSettingsFile.cooking.basket_lift.delay_timer,
                basketLift: ObjSettingsFile.cooking.basket_lift.basket_lift === 1 ? "true" : "false",
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true,
        });
        let { basketLift, delayTimer } = this.state;
        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.cooking.basket_lift) {
            ObjSettingsFile.cooking.basket_lift = {}
        }
        if (delayTimer > 100 || delayTimer < 0) {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1469"
                }
            });
        } else {
            ObjSettingsFile.cooking.basket_lift.delay_timer = Number(delayTimer);
            ObjSettingsFile.cooking.basket_lift.basket_lift = basketLift === "true" ? 1 : 0;
            let strSettingsFile = JSON.stringify(ObjSettingsFile);
            localStorage.setItem("libraryData", strSettingsFile);
            this.props.history.push(`/frymasterCooking`);
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterCooking`);
    }

    onToggleChange = (e, stateKey) => {
        const value = e.toString();
        this.setState({
            [stateKey]: value
        });
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");

        const { delayTimer, basketLift,error_modal } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Cooking Basket Header Page */}
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Cooking Basket Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Cooking Basket Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1357"/>
                                </div>



                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li><FormattedMessage id="KC1357"/></li>
                                                        <li>
                                                            <div class="btn-group-toggle" data-toggle="buttons">
                                                                <span class={basketLift === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1360"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'basketLift')}
                                                                    checked={basketLift === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                />
                                                                <span class={basketLift === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1405"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul class="borderNone">
                                                        <li><FormattedMessage id="KC1470"/></li>
                                                        <li class="smallFormFields">
                                                            <InputMask
                                                                placeholder={formatMessage({ id: 'KC1978' })}
                                                                onChange={(e) => {
                                                                    const re = /^[0-9\b]+$/;
                                                                    const value = e.target.value;
                                                                    // if value is not blank, then test the regex
                                                                    if (value === '' || re.test(value)) {
                                                                        this.setState({
                                                                            delayTimer: value
                                                                        })
                                                                    }
                                                                }}
                                                                value={delayTimer}
                                                                class="form-control" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>

                                </div>
                            </div>
                            {/* Frymaster Cooking Basket Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} message={error_modal.message} stringID={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterCookingBasket)
