import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

am4core.options.commercialLicence = true;
am4core.useTheme(am4themes_animated);

const Colors = ["#ff97cd", "#0083b3", "#7192f1", "#c10b6d", "#414548", "#eacd61", "#59a9ed", "#00eabf", "#ff3b84", "#e08e53"];

class PeakHoursChart extends Component {

	shouldComponentUpdate(nextProps, nextState) {
		const { peakHourChartInformation } = nextProps.peakHourChartInfo;
		if (nextProps.peakHourChartInfo.peakHourChartInformation !== this.props.peakHourChartInfo.peakHourChartInformation || nextProps.peakHourChartInfo.time !== this.props.peakHourChartInfo.time || nextProps.peakHourChartInfo.page !== this.props.peakHourChartInfo.page || nextProps.peakHourChartInfo.deviceid !== this.props.peakHourChartInfo.deviceid || !this.props.peakHourChartInfo.peakHourChartInformation || this.props.peakHourChartInfo.peakHourChartInformation.length === 0) {
			this.renderChartComponent(peakHourChartInformation, true);
			return true;
		}
		return false;
	  }

	  renderChartComponent(nextProps, isTimeChanged) {

		const { peakHourChartInformation } = this.props.peakHourChartInfo;
		let response = [];
		if (peakHourChartInformation && peakHourChartInformation.length > 0 && !isTimeChanged) {
			response = peakHourChartInformation;
		} else if (nextProps && nextProps.length > 0) {
			response = nextProps;
		}
		
		if (response.length > 0) {
			if (localStorage.getItem("timeFormat") === "24"){
				response.map(item => { item.hours = (item.hours === "24" ? "23-00" : `${item.hours - 1}-${item.hours}` ); });
			} else {
				response.map(item => { item.hours = (item.hours === "12" ? "11-12 pm" : item.hours === "24" ? "11-12 am" : item.hours > 12 ? item.hours === "13" ? "12-1 pm" : `${item.hours-13}-${item.hours-12} pm` : `${item.hours -1}-${item.hours} am`); });
			}
		}
		else {
			response = [];
		}

		if(response && response.length>0)
		{
		let chart = am4core.create("PeakHoursBarChartdiv1", am4charts.XYChart);
		this.chart = chart;
		chart.responsive.enabled = true;
		chart.data = response;
		chart.colors.list = [am4core.color("#0aaeff"), am4core.color("#0aadfd"), am4core.color("#0bacfb"),
		am4core.color("#0baaf9"), am4core.color("#0ba9f7"), am4core.color("#0ca8f5"), am4core.color("#0ca7f3"),
		am4core.color("#0ca5f1"), am4core.color("#0da4ef"), am4core.color("#0da3ed"), am4core.color("#0da2eb"),
		am4core.color("#0ea0e9"), am4core.color("#0e9fe6"), am4core.color("#0f9ee5"), am4core.color("#0f9ce2"),
		am4core.color("#0f9be1"), am4core.color("#109ade"), am4core.color("#1099dd"), am4core.color("#1097da"),
		am4core.color("#1196d9"), am4core.color("#1195d6"), am4core.color("#1193d4"), am4core.color("#1292d2"),
		am4core.color("#1291d0"), am4core.color("#1290ce"), am4core.color("#138ecc"), am4core.color("#138dca"),
		am4core.color("#138cc8"), am4core.color("#148bc6"), am4core.color("#1489c4"), am4core.color("#1488c2"),
		am4core.color("#1587c0"), am4core.color("#1585be"), am4core.color("#1684bc"), am4core.color("#1683ba"),
		am4core.color("#1682b8"), am4core.color("#1781b6"), am4core.color("#1780b4"), am4core.color("#177eb2"),
		am4core.color("#187db0"), am4core.color("#187cae"), am4core.color("#187aab"), am4core.color("#1979aa"),
		am4core.color("#1978a7"), am4core.color("#1977a6"), am4core.color("#1a75a3"), am4core.color("#1a74a2"),
		am4core.color("#1a739f"), am4core.color("#1b729e"), am4core.color("#1b709b"), am4core.color("#1b6f9a"),
		am4core.color("#1c6e98"), am4core.color("#1c6c95"), am4core.color("#1d6b94"), am4core.color("#1d6a91"),
		am4core.color("#1d6990"), am4core.color("#1e678d"), am4core.color("#1e668c"), am4core.color("#1e6589"),
		am4core.color("#1f6488"), am4core.color("#1f6285"), am4core.color("#1f6183"), am4core.color("#206081"),
		am4core.color("#205e7f"), am4core.color("#205d7d"), am4core.color("#215c7b"), am4core.color("#215b79"),
		am4core.color("#215a77"), am4core.color("#225975"), am4core.color("#225773"), am4core.color("#225671"),
		am4core.color("#23556f"), am4core.color("#23536d"), am4core.color("#24526b"), am4core.color("#245169"),
		am4core.color("#245067"), am4core.color("#254e65"), am4core.color("#254d63"), am4core.color("#254c61"),
		am4core.color("#264b5f"), am4core.color("#26495d"), am4core.color("#26485a"), am4core.color("#274759"),
		am4core.color("#274556"), am4core.color("#274455"), am4core.color("#284352"), am4core.color("#284251"),
		am4core.color("#28404e"), am4core.color("#293f4d"), am4core.color("#293e4a"), am4core.color("#293d49"),
		am4core.color("#2a3b46"), am4core.color("#2a3a44"), am4core.color("#2b3942"), am4core.color("#2b3740"),
		am4core.color("#2b363e"), am4core.color("#2c353c"), am4core.color("#2c343a"), am4core.color("#2c3238"),
		am4core.color("#2d3136"),
		];

		// Create axes
		let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
		categoryAxis.dataFields.category = "hours";
		categoryAxis.renderer.grid.template.location = 0;
		categoryAxis.renderer.minGridDistance = 30;
		categoryAxis.renderer.labels.template.fill = am4core.color("#FFF");
		categoryAxis.renderer.labels.template.fontSize = 10;
		categoryAxis.renderer.inside = false;
		categoryAxis.renderer.labels.template.disabled = false;
		categoryAxis.renderer.grid.template.strokeOpacity = 0;
		

		let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
		valueAxis.renderer.grid.template.location = 0;
		valueAxis.renderer.minGridDistance = 100;
		// Enable .total and .totalPercent placeholders for stacked series
		valueAxis.calculateTotals = true;
		valueAxis.renderer.inside = false;
		valueAxis.renderer.labels.template.disabled = false;

		// valueAxis.max = 80;
		valueAxis.renderer.labels.template.fill = am4core.color("#FFF");
		valueAxis.renderer.labels.template.fontSize = 10;
		valueAxis.renderer.grid.template.strokeOpacity = 1;
		valueAxis.renderer.grid.template.stroke = am4core.color("#FFF");
		valueAxis.renderer.grid.template.strokeWidth = 0.3;

		// Create series
		function createSeries(field, name, colorcode) {

			// Set up series
			let series = chart.series.push(new am4charts.ColumnSeries());
			series.name = name;
			series.dataFields.valueY = field;
			series.dataFields.categoryX = "hours";
			series.sequencedInterpolation = true;
			// Make it stacked
			series.stacked = true;

			// Configure columns
			series.tooltip.getFillFromObject = false;
			series.tooltip.background.fill = am4core.color("#FFFFFF");
			series.tooltip.label.fill = am4core.color("#000000");
			series.columns.template.tooltipText = "[bold]{name}[/]\n[font-size:14px] {valueY}";
			series.tooltip.label.adapter.add("textOutput", function (text, target) {
				var res = "";
				if (target.dataItem.dataContext != undefined) {
					var result = Object.keys(target.dataItem.dataContext).map(function (key) {
						return { key: key, value: Number(target.dataItem.dataContext[key]) };
					});

					var topValues = result.sort(function (p1, p2) { return p2.value - p1.value; }).filter(function (a) { return a.key != 'hours' && a.key != 'totalcount' && a.key != 'fillOpacity' }).slice(0, 4);
					topValues.forEach(function (item, i) {
						res += "[bold]" + item.key + " : " + item.value + "\n";
					})
				}
				return res;
			});

			return series;
		}
		if (response.length > 0) {
			Object.keys(response[0]).map((item, i) => {
				if (item !== "hours" && item !== "totalcount") {
					createSeries(item, item.toString(), Colors[i]);
				}
			})
		}
	   }
	   else{
		   if(this.chart){
			   this.chart.data = response;
		   }
	   }
	}

	componentWillUnmount() {
		if (this.chart) {
			this.chart.dispose();
		}
	}

	render() {
		return (
			<div class="PeakHoursBarChartdiv1"></div>
		);
	}
}

export default PeakHoursChart;
