import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import { injectIntl,FormattedMessage } from 'react-intl';
var recipeJSON = require('../../Templates/Frymaster/FrymasterRecipeJSON');
var settingsJSON = require('../../Templates/Frymaster/FrymasterSettingsJSON');

class FrymasterRecipeEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.reciperedirect = this.reciperedirect.bind(this);
        this.cookbookredirect = this.cookbookredirect.bind(this);
        this.settingsredirect = this.settingsredirect.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";

        try {
        } catch (error) {
            //Error Goes Here
        }
    }

    reciperedirect = () => {
        localStorage.setItem("selectedGroupID", 0);
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.isImport = false;
        libraryDetails.initialMethod = "Add";
        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
        localStorage.setItem("defaultLibraryData", JSON.stringify(recipeJSON.default));
        localStorage.setItem("libraryData", JSON.stringify(recipeJSON.default));
        this.props.history.push({
            pathname: "/frymasterRecipeTypeSelection",
            isEdit: false,
            previouspath: "/frymasterRecipeEditor"
        });
    }

    cookbookredirect = () => {
        localStorage.setItem("selectedGroupID", 0);
        localStorage.setItem("resourceDetails", "[]");
        localStorage.setItem("selectedCategory", "{}");
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.isImageChanged = false;
        libraryDetails.cookbookName = "";
        libraryDetails.initialMethod = "Add";
        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
        localStorage.setItem("defaultLibraryData", "{}");
        localStorage.setItem("schedulerDetails", "{}");
        localStorage.setItem("libraryData", "{}");
        this.props.history.push({
            pathname: "/frymasterMenuEditor",
            isEdit: false,
            previouspath: "/frymasterRecipeEditor"
        });
    }

    settingsredirect = () => {
        let libraryDetails = {}
        libraryDetails.isEdit = false;
        libraryDetails.settingName = "";
        libraryDetails.initialMethod = "Add";
        localStorage.setItem("libraryData", JSON.stringify(settingsJSON.default));
        localStorage.setItem("selectedGroupID", 0);
        localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
        localStorage.setItem("defaultLibraryData", JSON.stringify(settingsJSON.default));
        this.props.history.push({
            pathname: "/frymasterSettings",
            isEdit: false,
            previouspath: "/frymasterRecipeEditor"
        });
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, loaderIconVisible} = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = localStorage.getItem("selectedbrandID");

        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    {/* <a href="menuassignstatus.html" title="Status">Status</a> */}
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>
                        <div class="rcDeviceWrapper">
                            <div class="rcSelectedBrandLogo"><img src={require("../../../../../images/logos/frymasterW.png")} alt=""/></div>
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH">
                                    &nbsp;
				                </div>
                                <div class="reBodyWrapper">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="recipeEditorGrid">
                                                    <ul>
                                                        <li>
                                                            <button type="button" onClick={(e) => { this.reciperedirect(e) }} class="btn recipeCreationNav" title={formatMessage({ id: 'KC1211' })}><FormattedMessage id="KC1211"/></button> 
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={(e) => { this.cookbookredirect(e) }} class="btn menusNav" title={formatMessage({ id: 'KC1340' })}><FormattedMessage id="KC1340"/></button> 
                                                        </li>
                                                        <li>
                                                            <button type="button" onClick={(e) => { this.settingsredirect(e) }} class="btn settingsNav" title={formatMessage({ id: 'KC1190' })}><FormattedMessage id="KC1190"/></button> 
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="recipeEditorF">
                                    <span class="easyTouchLogo"><img src={require("../../../../../images/recipecreation/eacyTouchLogo.png")}  alt=""/></span>
                                </div>
                            </div>
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterRecipeEditor)
