import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BaseService, WarrantyAPI,HACCPManagementAPI } from '../../../service/api';
import http from '../../../service/httpService';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import * as moment from 'moment';
import { FormattedMessage } from 'react-intl';

class ConnectivityStatusWidget extends Component {
	constructor(props) {
		super(props);
		this.state = {
			type: 'Month',
			loaderIconVisible: false,
			responseData: {"lastSeenAt": "N/A",
			"lastSeenDays": "",
			"droppedConnection": "N/A",
			"totalDroppedConnection": "N/A"},
			cloudUnitName:"",
		};

	}

	onDropDownHandler = async (e, type = '') => {
		const { nodeText:text } = e;
		this.setState({ type: text });
		await this.getConnectivityStatusInformations(text);
	}

	componentDidMount = async () => {
		await this.getCloudsDetailsForThink();
		await this.getConnectivityStatusInformations("Month");
	}

	getCloudsDetailsForThink = async () => {		
		let unitID = localStorage.getItem("unitID");		
		const url = HACCPManagementAPI.unitLogDetails + unitID;
		await http.get(url, {
			headers: {
				'Content-Type': 'application/json'
			}, data: {}
		}).then(response => {
			if (response && response.data) {
				this.setState({					
					cloudUnitName: response.data.cloudUnitName,
				});
			}
		}).catch(err => {
			
		});
	}

	getConnectivityStatusInformations = async (filterType) => {
		const filtType = filterType?filterType:this.state.type;
		const url = `${BaseService.root}${WarrantyAPI.connectivityStatus}`;
		let postdata = {
			cloudUnitName: this.state.cloudUnitName? this.state.cloudUnitName : localStorage.getItem("cloudUnitName"),
			filter: (filtType==="Quarter")?"Quater":(filtType==="Week")?"week":filtType,
		}
		await http.post(url, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		).then(response => {
			if (response && response.data) {				
				this.setState({ responseData: response.data, loaderIconVisible: false });
			}
		}).catch(err => {
			this.setState({ loaderIconVisible: false });
		});		
	};

	render() {
		let { responseData } = this.state;
		let Connectivity_Status_widget;
		let lastSeenAt = (responseData &&  responseData.lastSeenAt);
        lastSeenAt = (lastSeenAt && lastSeenAt !== 'N/A') &&  moment(lastSeenAt).format('MM/DD/YYYY') || 'N/A';
		Connectivity_Status_widget = (
			<div class="colm3D colm3D1">
				<div class="colmDi">
					<h4><FormattedMessage id="KC0040"/>
						<NewDropDownListLink translation={true} OnChange={(e) => this.onDropDownHandler(e, "AverageCook")} filterType={this.state.type} disableDatePicker={true} disableDayFilter={true}/>
					</h4>
					<div class="colmDiVal">
						<div class="warantyTable">
							<ul>
								<li>
									<ul>
										<li>
											<label><FormattedMessage id="KC1824"/> <br/><FormattedMessage id="KC1825"/> </label>										
											<span class="labelVal">{responseData ? responseData.droppedConnection:<FormattedMessage id="KC1664"/>}</span>
										</li>										
										<li>
											<label><FormattedMessage id="KC0226"/></label>											
											<span class="labelVal">{lastSeenAt?lastSeenAt:<FormattedMessage id="KC1664"/>} <br/>{responseData && responseData.lastSeenDays ? "(" + responseData.lastSeenDays + ")":""}</span>
										</li>
										{/* <li>
                                                <label>&nbsp;</label>
                                                <span class="labelVal">&nbsp;</span>
                                            </li> */}
										{/* <li>
                                               <div class="dropdown frypotBtnGroup">
												  <button class="btn btn-secondary dropdown-toggle frypotSelection" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
													Week
												  </button>
												  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
													<a class="dropdown-item" href="javascript:void(0);">Week</a>
													<a class="dropdown-item" href="javascript:void(0);">Month</a>
													<a class="dropdown-item" href="javascript:void(0);">Quarter</a>
													<a class="dropdown-item" href="javascript:void(0);">Year</a> 
												  </div>
												</div> 
                                            </li> */}

										{/* <li>
                                                <label>&nbsp;</label>
                                                <span class="labelVal">&nbsp;</span>
                                            </li> */}
										<li>
											<label><FormattedMessage id="KC0227"/></label>
											<span class="labelVal">{responseData.totalDroppedConnection}</span>
										</li>
									</ul>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>

		);
		return (
			<React.Fragment>
				{Connectivity_Status_widget}
			</React.Fragment>
		)
	}
};
export default ConnectivityStatusWidget; 
