import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import NumberFormat from 'react-number-format';
import { format, addDays } from 'date-fns';
import { getTotalDrinksList } from '../../../actions/reportManagement/totalDrinksAction';
import { getTotalCookCountData } from '../../../actions/unitDashboard/totalCookCountAction';
import { UNIT_ID, UNIT_BRAND_ID } from '../../../utils/appConstants';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { FormattedMessage, injectIntl } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';


var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
let brandID = "";

class TotalCookCount extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            sd: '',
            ed: '',
            startDate: '',
            endDate: '',
            unitTime: '',
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
        }
        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }
    componentDidMount() {
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        if (localStorage.getItem("brandName") === "CREM") {
            brandID = "CREM";
            this.props.getTotalDrinksList("Month", todaysDateFormatted, weekDateFormatted, UNIT_ID, brandID);
        }
        else if (localStorage.getItem("brandName") === "MERRYCHEF") {
            let widgetID = 4;
            this.props.getTotalCookCountData("Month", todaysDateFormatted, weekDateFormatted, UNIT_ID, UNIT_BRAND_ID, widgetID);
        }
        else if (localStorage.getItem("brandName") === "FRYMASTER") {
            let widgetID = 52;
            this.props.getTotalCookCountData("Month", todaysDateFormatted, weekDateFormatted, UNIT_ID, UNIT_BRAND_ID, widgetID);
        }
        else if (localStorage.getItem("brandName") === "GARLAND") {
            let widgetID = 86;
            this.props.getTotalCookCountData("Month", todaysDateFormatted, weekDateFormatted, UNIT_ID, UNIT_BRAND_ID, widgetID);
        }
    }

    componentDidUpdate = async (prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleCookCount(this.props.globalDropdownFilter);
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStart(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //Total Drinks Produced
    handleChangeStart(date) {
        if(this.state.startDate === date){
            return;
        }
        this.setState({
            startDate: date,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleCookCount(selectedtext);
        });
    }

    handleChangeEnd(date) {
        if(this.state.endDate === date){
            return;
        }
        this.setState({ endDate: date, datapickflag: true }
            , () => {
                let selectedtext = 'Date Range';
                this.handleCookCount(selectedtext);
            });

    }

    onDropDownHandler(e) {
        const { nodeText: text } = e;
        this.setState({ type: text, datapickflag: text === 'Date Range', startDate: '', endDate: ''})
        this.handleCookCount(text);
    }

    handleCookCount(text) {
        if (localStorage.getItem("brandName") === "CREM") {
            brandID = "CREM";
            if (text === "Date Range") {
                todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
                weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
            }
            else {
                todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
                weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
            }
            if (todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") {
                this.props.getTotalDrinksList(text, todaysDateFormatted, weekDateFormatted, UNIT_ID, brandID);
            }
        }
        else {
            let widgetID;
            if (localStorage.getItem("brandName") === "MERRYCHEF") {
                widgetID = 4;
            } else if (localStorage.getItem("brandName") === "GARLAND") {
                widgetID = 86;
            } else {
                widgetID = 52;
            }
            if (widgetID === 52) {
                if (text === "Date Range") {
                    todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
                    weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
                }
                else {
                    todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
                    weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
                }
            } else {
                if (text === "Date Range") {
                    todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
                    weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
                }
                else if (text === 'Week') {
                    weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
                    todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

                    var startDateMonth = getMonth(new Date(weekDateFormatted));
                    var endDateMonth = getMonth(new Date(todaysDateFormatted));

                    if (startDateMonth !== endDateMonth) {
                        weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
                    }
                }
                else {
                    todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
                    weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
                }
            }
            if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
                this.props.getTotalCookCountData(text, todaysDateFormatted, weekDateFormatted, UNIT_ID, UNIT_BRAND_ID, widgetID);
            }
        }
    }

    returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (localStorage.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

    render() {
        const totalDrinksInformation = this.props.ProductsProduced.totalDrinksInfo.totalDrinksInformation;
        const response = this.props.totalCookCountDataInfo;
        const { formatMessage } = this.props.intl;

        let currentValue = null;
        let priorValue = null;
        let trend = 0;
        let unitTime = '';        
        if (localStorage.getItem("brandName") === "CREM") {
            currentValue = totalDrinksInformation.currentConsumption ? totalDrinksInformation.currentConsumption : 0;
            priorValue = totalDrinksInformation.lastConsumption ? totalDrinksInformation.lastConsumption : 0;
            trend = totalDrinksInformation.trend;
            unitTime = totalDrinksInformation.unitTime ? totalDrinksInformation.unitTime.toLowerCase() : '';            
        }
        else {
            currentValue = response.totalCookCountData.content !== undefined ? response.totalCookCountData.content[0].value : 0;
            priorValue = response.totalCookCountData.content !== undefined && response.totalCookCountData.content.length > 1 ? response.totalCookCountData.content[1].value : 0;
            trend = response.totalCookCountData.content !== undefined && response.totalCookCountData.content.length > 2 ? response.totalCookCountData.content[2].value : 0;
            unitTime = response.totalCookCountData && response.totalCookCountData.unitTime ? response.totalCookCountData.unitTime.toLowerCase() : '';
        }
        
        if (localStorage.getItem("timeFormat") === "24"){
            unitTime = unitTime ? moment(unitTime, 'hh:mm A').format('HH:mm'):"";
        }

        return (
            <React.Fragment>
                <div className={this.props.classValue}>
                    <div className="colmDi">
                        <h4>{this.props.widgetnameStringID ? <FormattedMessage id={this.props.widgetnameStringID}/> : this.props.widgetname}
                        &nbsp; {localStorage.getItem("excludedProductList") === "true" ? <span class="blueDot" title={formatMessage({id:"KC1037"})}></span> : ""} 
                            &nbsp; <span className="red-txt">{/*errormsgTotalDrinks*/}</span><span className="cornerIcon"></span>
                            <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate} endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDate} handleChangeStartProps={(date) => this.handleChangeStart(date)} handleChangeEndProps={(date) => this.handleChangeEnd(date)} OnChange={(e) => this.onDropDownHandler(e)} filterType={this.state.type} />
                        </h4>
                        <div className={this.props.widgetname === "FILTER CLEANINGS" ? "colmDiVal minheight155" : "colmDiVal"}>
                            <div className="valH1Div"> {this.returnNumberFormat(Math.round(currentValue))} <span className={this.state.type === "Date Range" ? "displaynone" : Math.round(currentValue) === Math.round(priorValue) ? "valH1Span" : trend === '1' ? "valH1Span valHigh" : trend === '0' ? "valH1Span valLow" : "valH1Span"}>{unitTime}&nbsp;</span></div>
                            <div className={this.state.type === "Date Range" ? "displaynone" : "valH5Div"}>
                                { mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }: {this.returnNumberFormat(Math.round(priorValue))}
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

function mapStateToProps(state) {
    return {
        totalCookCountDataInfo: state.totalCookCountData,
        totalDrinksInfo: state.totalDrinksInformation,
        currentLocation: state.entityReducer.currentLocation,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getTotalCookCountData, getTotalDrinksList }, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TotalCookCount));
