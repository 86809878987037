import api from '../../../service/api';
import http from '../../../service/httpService';
import moment from '../../../utils/momentHelper.js';
import { BASECOUNTRY_ID } from '../../../utils/appConstants';
import { MediaManagementAPI } from '../../../service/api';
export const MEDIA_AUDIOLIBRARY_LIST_REQUEST = 'MEDIA_AUDIOLIBRARY_LIST_REQUEST';
export const MEDIA_AUDIOLIBRARY_LIST_SUCCESS = 'MEDIA_AUDIOLIBRARY_LIST_SUCCESS';
export const MEDIA_AUDIOLIBRARY_LIST_FAIL = 'MEDIA_AUDIOLIBRARY_LIST_FAIL';
export const MEDIA_SELECTED_LIST_SUCCESS = 'MEDIA_SELECTED_LIST_SUCCESS';

export function getMediaAudioLibraryList(searchParam) {
    let custGroupID = localStorage.getItem("custGroupID");
    let cgids = "";
    if (custGroupID != "" && custGroupID != undefined) {
        cgids = custGroupID;
    }

    return dispatch => {
        dispatch({
            type: MEDIA_AUDIOLIBRARY_LIST_SUCCESS,
            mediaAudioLibraryListInformation: [],
        })
        dispatch({
            type: MEDIA_AUDIOLIBRARY_LIST_REQUEST
        })
        return new Promise((resolve, reject) => {

            let headers = {
                'Content-Type': 'application/json',
                'customer': localStorage.getItem("customerId"),
                'brand': 'MERRYCHEF',
                'type': 'audioLibrary',
                'cgids': cgids,
                'basecountry': BASECOUNTRY_ID
            };
            if (searchParam) {
                headers = Object.assign(headers, searchParam);
            }
            http.get(`${MediaManagementAPI.getMediaBrandCustomer}`,
                {
                    headers,
                    data: {}
                })
                .then(response => {
                    const { mediaList } = response.data;
                    if (mediaList) {
                        mediaList.forEach(item => {
                            item.CREATEDAT = moment.ConvertLocalTimeFromUTCTime(item.CREATEDAT, "MMMM DD, YYYY hh:mm A");
                        });
                    }
                    dispatch({
                        type: MEDIA_AUDIOLIBRARY_LIST_SUCCESS,
                        mediaAudioLibraryListInformation: response.data,
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    dispatch({
                        type: MEDIA_AUDIOLIBRARY_LIST_FAIL,
                        mediaAudioLibraryListInformation: {},
                        error
                    })
                    resolve(error)
                })
        })
    }
}

export function mediaSelectedItems(selectedItems) {
    return dispatch => {
        dispatch({
            type: MEDIA_SELECTED_LIST_SUCCESS,
            mediaSelectedItems:selectedItems,
         })
    }
}








