import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';

class PeakHour extends Component {

  findPeakHour = (data) => {
    let maxTotalCount = 0;
    let peakHour = 0;

    let chartData = data && data.length > 0 ? data : [];

    //Find maximum count
    for (let index = 0; index < chartData.length; index++) {
      if (maxTotalCount < chartData[index].totalcount) {
        maxTotalCount = chartData[index].totalcount;
      }
    }

    if (maxTotalCount) {
      for (let index = 0; index < chartData.length; index++) {
        if (chartData[index].totalcount == maxTotalCount) {
          peakHour = chartData[index].hours;
        }
      }
    }

    return parseInt(peakHour);
  }

  render() {
    const { peakHourChartInformation } = this.props.peakHourChartInfo;
    let peakhour = this.findPeakHour(peakHourChartInformation);

    let newTimeFormat =  <div className="valH1Div"> {peakhour > 12 ? peakhour-12 + ":00" : peakhour + ":00"} <span className="valH1Span">{peakhour === 24 ? <FormattedMessage id="KC1883"/> : peakhour >= 12 ? <FormattedMessage id="KC1884"/> : <FormattedMessage id="KC1883"/>}</span></div> 
    if (localStorage.getItem("timeFormat") === "24"){
      newTimeFormat =  <div className="valH1Div"> {peakhour === 24 ? "00:00" : peakhour + ":00"}</div>
    }
    return (
      <>
        {newTimeFormat}
      </>
    );
  }
}

export default PeakHour;
