import {ReportManagement} from '../../service/api';
import http from '../../service/httpService';
export const TOTAL_DRINKS_LIST_REQUEST = 'TOTAL_DRINKS_LIST_REQUEST';
export const TOTAL_DRINKS_LIST_SUCCESS = 'TOTAL_DRINKS_LIST_SUCCESS';
export const TOTAL_DRINKS_LIST_FAIL = 'TOTAL_DRINKS_LIST_FAIL';

//export function getTotalDrinksList (type = 'Day') {
export function getTotalDrinksList (type, fromdate, todate, deviceId, brandid) {
    let url ;
    let filterType;
    switch (type) {
        case 'Week':
            filterType='weekly'; 
            break; 
        case 'Year':
            filterType='yearly';            
            break; 
        case 'Day':            
            filterType='daily';
            break;
        case 'Quarter':
            filterType='quarterly';            
            break; 
        case 'Date Range':
            filterType = 'custom';
            break; 
        default: 
            filterType = 'monthly';              
      }    
    //url = api.totalDrinksProduced.replace("{deviceIdvalue}", deviceId).replace("{fromDatevalue}", fromdate).replace("{filterTypeValue}", filterType)
    url = `${ReportManagement.countroot}`;
//'groupby' : 'product_type,'+ filterType,
    return dispatch => {
        dispatch({
            type: TOTAL_DRINKS_LIST_REQUEST
        })
        
        let header;

        if (filterType === 'weekly' || filterType === 'custom')
        {
            header = {
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin':'*',
                'unitid' : deviceId,
                'brandid' : brandid,
                'startdate' : todate,
                'enddate' : fromdate,
                'filterType' : filterType,
                'cid':localStorage.getItem("customerId")
              }; 
        }
        else
        {
            header = {
                'Content-Type':'application/json',
                'Access-Control-Allow-Origin':'*',
                'unitid' : deviceId,
                'brandid' : brandid,
                'reqdate' : fromdate,
                'filterType' : filterType,
                'cid':localStorage.getItem("customerId")
              }; 
        }

        http.get(url,
                {
                  headers: header,
                  data:{}
                })
            .then(response => {
                dispatch({
                    type: TOTAL_DRINKS_LIST_SUCCESS,
                    totalDrinksInformation: response.data
                })
            })
            .catch(error => {
                dispatch({
                    type: TOTAL_DRINKS_LIST_FAIL,
                    error
                })
            })
    }
}