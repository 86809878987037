import { ServiceManagement } from '../api'; 
import http from '../httpService';



export const getAPIUrl = (error, page, pageSize, unitId, viewType) => {

    viewType = viewType ? viewType : "ErrorList";
    let url = `${ServiceManagement.root}${unitId}?viewType=${viewType}`;
    let errorType = error ? error : "";
    url = errorType ? url + "&errorType=" + errorType : url;
    let pageNumber = page != null ? '&pageNum=' + page : "";
    let pageLimit = pageSize != null ? '&pageSize=' + pageSize : "";
    let apiUrl = url + pageNumber + pageLimit;
    return apiUrl;
}

const queryParamsWithSearch = ( searchData, url ) => {
    if(searchData ) {
        Object.keys(searchData).forEach(key=> {
        if( searchData[key] ){
            url = `${url}&&${key}=${searchData[key].toLowerCase()}`
        }
        });
    }
    return url;
}

export const getAPIFlagBaseUrl = (error,event, page, pageSize, unitId, viewType , searchData,locale='en-US') => {
    
    viewType = viewType ? viewType : "ErrorList";
    let url = `${ServiceManagement.root}${unitId}?viewType=${viewType}`;
    let errorType = error;
    let eventType = event; 
    if(errorType != ""){
        url = url + "&errorType=" + errorType;        
    }
    if( eventType  ) {
        url = eventType ? url + "&errorEvent=" + eventType : url;        
    }
    url = url + "&langCode=" + locale
    
    let pageNumber = page != null ? '&pageNum=' + page : "";
    let pageLimit = pageSize != null ? '&pageSize=' + pageSize : "";
    let apiUrl = url + pageNumber + pageLimit;
    apiUrl  = queryParamsWithSearch(searchData, apiUrl);
    return apiUrl;
}

export const getErrorList = async (url, sortby, sorttype) => {

    let responseResult = { 
        data: [],
        totalPages: 0,
        totalErrorCount: 0
    }
   
    try {
        let response = await http.get(url, {
            headers: {
            'Content-Type': 'application/json', 
            'brandid': localStorage.getItem("brandName"),
            'sortby' : sortby,
            'sorttype' : sorttype,
          },data:{}});
        let { data, totalPages, totalErrorCount } = response.data;
        responseResult.data = data;
        responseResult.totalPages = totalPages;
        responseResult.totalErrorCount = totalErrorCount;
        return responseResult;
    } catch (err) {
        return responseResult;
    }

}

export const getErrorList_ForHistoricError = async (url,error, event, pageNumber, pageLimit, sortby,sorttype, filterType, searchData ) => {    
    let responseResult = { 
        data: [],
        totalPages: 0,
        totalErrorCount: 0
    }
    url  = queryParamsWithSearch(searchData, url);

    try {
        let response = await http.get(url, {
            headers: {
            'Content-Type': 'application/json', 
          },data:{}});
        let { data, totalPages, totalErrorCount } = response.data;
        responseResult.data = response.data;
        responseResult.totalPages = totalPages;
        responseResult.totalErrorCount = totalErrorCount;
        return responseResult;
    } catch (err) {
        return responseResult;
    }

}

export const checkbox_ForHistoricError = async (url,error, event, pageNumber, pageLimit, sortby,sorttype, filterType ) => {    
    let responseResult = { 
        data: [],
        totalPages: 0,
        totalErrorCount: 0
    }

    try {
        let response = await http.get(url, {
            headers: {
            'Content-Type': 'application/json', 
          },data:{}});
        let { data, totalPages, totalErrorCount } = response.data;
        responseResult.data = data;
        responseResult.totalPages = totalPages;
        responseResult.totalErrorCount = totalErrorCount;
        return responseResult;
    } catch (err) {
        return responseResult;
    }

}

export const getTotalErrorsCount = async (url) => {

    let ErrorsCountInformation = {};
    try {
        let response = await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'brandid': localStorage.getItem("brandName")
        },data:{}});
        ErrorsCountInformation = response.data;
        return ErrorsCountInformation
    } catch (err) {
        return ErrorsCountInformation;
    }

}
