import React, { Component } from 'react';
import UserManagementHeader from '../../../../layoutSection/userManagementHeader';
import { Link } from 'react-router-dom';
import { ModelFamilyList } from '../../../../../components/controls/modelFamilyList';
import ErrorMoal from '../../../../modal/error-model';
import _ from 'lodash';
import LibraryListPopup from '../../libraryListPopup';
import { UserProfile, MenuUploadAPI, organizationNode } from '../../../../../service/api';
import { CUSTOMER_ID, BRAND_ID_VALUE, BASECOUNTRY_ID } from '../../../../../utils/appConstants';
import http from '../../../../../service/httpService';
import uploadLibraryToCloud from '../../../menus/uploadLibrary';
import SuccessMoal from '../../../../modal/success-model';
import { v1 as uuidv1 } from 'uuid';
import { format } from 'date-fns';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import $ from 'jquery';
import { injectIntl, FormattedMessage } from 'react-intl';
import 'malihu-custom-scrollbar-plugin'
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css'
require('jquery-mousewheel')

var AdmZip = require('adm-zip');
var request = require('request');
var cookbookJSON = require('../../Templates/CookbookJSON');
var beautify = require("json-beautify");
var todaysDate = new Date();
let todaysDateUTCFormatted = format(todaysDate, 'YYYY-MM-DDTHH:mm:ss.sss');

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

class GarlandCookbookEditor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaderIconVisible: false,
            customerId: parseInt(localStorage.getItem("customerId")),
            data: [],
            error_modal: {
                open: false,
                message: ""
            },
            success_modal: {
                open: false,
                message: ""
            },
            selectedModel: 0,
            categoryList: [],
            deleteCategoryVisible: false,
            editEnabled: false,
            isChanged: false,
            isEdit: false,
            isImport: false,
            showDiscardPopup: false,
            isDeleteCategory: false,
            selectedCategory: {},
            cookbook_name: '',
            errorCookbookName: ''
        }
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        localStorage.removeItem("isEditSubCategory");
        localStorage.removeItem("EditSubCategoryData");
        localStorage.removeItem("isEditCategory");
        localStorage.removeItem("EditCategoryData");
        this.updateDimensions();
        $(".mCustomScrollbar").mCustomScrollbar();
        try {
            let categoryDetails = localStorage.getItem("resourceDetails");
            let categoryDetailsObj = JSON.parse(categoryDetails);
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            let categoryListObj = categoryDetailsObj.filter(c => c.ID !== 'Press&Go');
            this.setState({
                categoryList: categoryListObj,
                isChanged: categoryDetailsObj.length > 0 ? true : false,
                isEdit: libraryDetails.isEdit,
                isImport: libraryDetails.Import,
                cookbook_name: libraryDetails.cookbookName,
                selectedModel: parseInt(localStorage.getItem("selectedProductID"))
            });
            if (this.props.history.location.state.getSelectedDynasty) {
                let node = this.props.history.location.state.getSelectedDynasty;
                let selectedUnitDetails = this.props.history.location.state.getSelectedUnitDetails;
                await this.getNodeCaptionDetails(node, selectedUnitDetails);
            }
        } catch (error) {
            //Error Goes Here
        }
    }

    updateDimensions = () => {
        var windowHeight = $(window).height();
        var menuFilterHeader = $(".menuFilterHeader").height();
        var pageRRHeight = ((windowHeight) - (menuFilterHeader)) - 105;
        $(".readyRecipeSrlTable").css("height", pageRRHeight);
    }

    onDragEnd = (result) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        const items = reorder(
            this.state.categoryList,
            result.source.index,
            result.destination.index
        );

        this.setState({
            categoryList: items,
        });
        localStorage.setItem("resourceDetails", JSON.stringify(items));
    }

    onSuccessCloseModal = () => {
        this.setState({
            loaderIconVisible: false,
            success_modal: {
                open: false
            }
        })
    }

    redirect = () => {
        this.props.history.push({
            pathname: "/recipeCreation",
            isEdit: false,
            previouspath: "/recipeCreation"
        });
    }

    closeModel = () => {
        this.setState({
            error_modal: {
                open: false
            }
        })
    }

    OnCancel = () => {
        this.setState({
            showDiscardPopup: false,
            isDeleteCategory: false,
            errorCookbookName: ""
        })
    }

    onDeleteConfirm = () => {
        this.setState({
            loaderIconVisible: true
        })
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let changedCategoryDetailsObj = categoryDetailsObj.filter(c => c.ID !== this.state.selectedCategory.ID);
        let categoryDetailsWithouPressGoObj = changedCategoryDetailsObj.filter(c => c.ID !== 'Press&Go');
        localStorage.setItem("resourceDetails", JSON.stringify(changedCategoryDetailsObj));
        this.setState({
            loaderIconVisible: false,
            categoryList: categoryDetailsWithouPressGoObj,
            isChanged: changedCategoryDetailsObj.length > 0 ? true : false,
            isDeleteCategory: false
        });
    }

    OnDiscard = () => {
        this.setState({
            loaderIconVisible: true
        })
        let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
        let path = "/recipeEditor";
        if (libraryDetails.isEdit) {
            path = "/libraryList";
        }
        this.props.history.push({
            pathname: path,
            isEdit: false,
            previouspath: "/garlandCookbookEditor"
        });
    }

    onclickEditCookbook = () => {
        this.setState({
            deleteCategoryVisible: !this.state.deleteCategoryVisible,
            editEnabled: !this.state.editEnabled,
        });
    }

    editCategory = (e, item) => {
        localStorage.setItem("isEditCategory", true);
        localStorage.setItem("EditCategoryData", JSON.stringify(item));
        this.props.history.push({
            pathname: "/garlandCookbookAddMenu",
            isEdit: true,
            previouspath: "/garlandCookbookEditor"
        });
    }

    addCategory = () => {
        this.props.history.push({
            pathname: "/garlandCookbookAddMenu",
            isEditCategory: false,
            isEdit: false,
            previouspath: "/garlandCookbookEditor"
        });
    }

    onCategorySelect = (e, item) => {
        item.selected = true;
        delete item.imageData;
        delete item.thumbnailData;
        if (item.subCategory) {
            item.subCategory.map((subitem, j) => {
                delete subitem.imageData;
                delete subitem.thumbnailData;
            })
        }
        localStorage.setItem("selectedCategory", JSON.stringify(item));
        this.props.history.push({
            pathname: "/garlandCookbookAddRecipe",
            isEdit: false,
            previouspath: "/garlandCookbookEditor"
        });
    }

    getNodeCaptionDetails = async (val, val1) => {
        this.setState({ loaderIconVisible: true });
        const url = organizationNode.nodeDetails + "96";
        await http.get(url, {
            headers: {
                'Content-Type': 'application/json',
                'cid': localStorage.getItem("customerId"),
                'dynasty': val,
            }, data: {}
        }).then(response => {
            if (response && response.data) {
                let result = response.data;
                let nodeDetails;
                result.map((item, i) => {
                    if (nodeDetails) {
                        if ((result.length - 1) === i) {
                            nodeDetails = nodeDetails + item.node_name;
                        } else {
                            nodeDetails = nodeDetails + item.node_name + "/";
                        }
                    } else {
                        nodeDetails = item.node_name + "/";
                    }
                })
                this.setState({
                    loaderIconVisible: false,
                    nodeCaptionDetails: nodeDetails,
                    selectedUnitDetails: val1,
                });
            }
        }).catch(err => {
            this.setState({ loaderIconVisible: false });
        });
    }

    onSaveButtonClick = (action) => {
        if (this.state.isChanged) {
            let cookbookName = this.state.cookbook_name.trim();
            if (cookbookName.length < 1 || cookbookName.length > 20) {
                this.setState({
                    errorCookbookName: "KC1240"
                })
            }
            else {
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                libraryDetails.cookbookName = cookbookName;
                localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                if (action === "Test") {
                    this.props.history.push({
                        pathname: "/recipeAssignTopology",
                        previouspath: "/garlandCookbookEditor",
                    });
                }
                else {
                    this.props.history.push({
                        pathname: "/assignCookbookToTopology",
                        isEdit: false,
                        previouspath: "/garlandCookbookEditor"
                    });
                }
            }
        }
        else {
            this.setState({
                loaderIconVisible: false,
                error_modal: {
                    open: true,
                    message: "KC1241"
                }
            });
        }
    }

    onDeleteCategory = (e, item) => {
        this.setState({
            selectedCategory: item,
            loaderIconVisible: false,
            isDeleteCategory: true
        });
    }

    onBackButtonClick = () => {
        if (!this.state.isChanged) {
            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
            let path = "/recipeEditor";
            if (libraryDetails.isEdit) {
                path = "/libraryList";
            }
            this.props.history.push({
                pathname: path,
                isEdit: false,
                previouspath: "/garlandCookbookEditor"
            });
        }
        else {
            this.setState({
                loaderIconVisible: false,
                showDiscardPopup: true
            });
        }
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    async OnImport(item) {
        this.setState({
            loaderIconVisible: true
        });
        await http.get(MenuUploadAPI.getlibraryDownloadUrl, {
            headers: {
                'Content-Type': 'application/json',
                'library_id': item.LIBRARY_ID,
                'library_isedit': true
            }, data: {}
        }).then(async response => {
            if (response && response.data) {
                let file_path = response.data.presignedURL;
                var libraryFiles = [];
                var b64 = "";
                var imageBuffer = null;
                localStorage.setItem("selectedGroupID", item.CGID);
                await http.get(MenuUploadAPI.getLibraryList,
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            'customerid': CUSTOMER_ID,
                            'cgids': localStorage.getItem("custGroupID"),
                            'brandid': localStorage.getItem("selectedbrandID"),
                            'productid': localStorage.getItem("selectedProductID"),
                            'basecountry': BASECOUNTRY_ID,
                            'Type': "Recipe"
                        },
                        data: {}
                    }).then(response => {
                        let recipeData = response ? response.data ? response.data.libraryList ? response.data.libraryList : [] : [] : [];
                        try {
                            request.get({ url: file_path, encoding: null }, (err, res, body) => {
                                var zip = new AdmZip(body);
                                var zipEntries = zip.getEntries();

                                zipEntries.forEach((entry, i) => {
                                    if (item.TYPE === "CookBook") {
                                        libraryFiles[i] = {};
                                        libraryFiles[i].fileName = entry.entryName.replace('Resources/Images/', '');
                                        imageBuffer = zip.readFile(entry);

                                        if (!entry.entryName.includes('.json')) {
                                            libraryFiles[i].fileBuffer = imageBuffer.toString('base64');
                                        }
                                        else {
                                            libraryFiles[i].fileBuffer = imageBuffer;
                                            b64 = imageBuffer.toString('base64');
                                        }
                                    }
                                    else {
                                        libraryFiles[i] = zip.readFile(entry);

                                        if (!entry.entryName.includes('.json')) {
                                            imageBuffer = Buffer.from(libraryFiles[i]);
                                            b64 = imageBuffer.toString('base64');
                                        }
                                    }
                                });

                                localStorage.setItem("selectedFamilyID", item.MODEL_FAMILY ? Array.prototype.map.call(item.MODEL_FAMILY, m => m.ID).toString() : '');
                                let UpdatedJSONObj = JSON.parse(libraryFiles[0].fileBuffer);
                                let md5 = require('md5');
                                let libraryDetails = {};
                                libraryDetails.id = UpdatedJSONObj.id;
                                libraryDetails.library_id = item.LIBRARY_ID;
                                libraryDetails.cookbookName = item.LIBRARY_NAME;
                                libraryDetails.crc = md5(b64, "base64");
                                libraryDetails.isEdit = false;
                                libraryDetails.initialMethod = "Add";

                                //CookBook
                                let categoryDetailsObj = [];
                                UpdatedJSONObj && UpdatedJSONObj.menus && UpdatedJSONObj.menus.map((item, i) => {
                                    let category = {};
                                    category.ID = item.id;

                                    let imageData = null;
                                    let thumbnailData = null;
                                    let imageFileFormat = "";
                                    let imageFileName = "";
                                    let imageFileSize = "";

                                    libraryFiles && libraryFiles.map((libraryitem, j) => {
                                        if (libraryitem.fileName.includes('tmb_')) {
                                            if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "") === item.image.replace("Resources/Images/", "tmb_").replace("Resources/", "tmb_").replace(".jpeg", ".png").replace(".jpg", ".png").replace(".JPEG", ".png").replace(".JPG", ".png").replace(".PNG", ".png")) {
                                                thumbnailData = libraryitem.fileBuffer;
                                            }
                                        }
                                        else if (!libraryitem.fileName.toLowerCase().includes('.json')) {
                                            if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "") === item.image.replace("Resources/Images/", "").replace("Resources/", "")) {
                                                imageData = libraryitem.fileBuffer;
                                                let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
                                                let fileextSplit = fileName.split('.');
                                                let fileExt = fileextSplit[1];
                                                imageFileFormat = "." + fileExt;
                                                imageFileName = libraryitem.fileName;
                                                // read json string to Buffer
                                                let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
                                                imageFileSize = (fileSize / 1048576).toFixed(2);
                                            }
                                        }
                                    });

                                    category.imageFileName = imageFileName;
                                    category.imageFileFormat = imageFileFormat;
                                    category.imageFileSize = imageFileSize;
                                    category.imageS3Url = item.imageS3Url ? item.imageS3Url : "";
                                    category.categoryName = item.name ? item.name.en_GB ? item.name.en_GB : "" : "";
                                    category.imageData = "";
                                    category.thumbnailData = thumbnailData;
                                    category.recipe_items = [];
                                    item.recipe_items && item.recipe_items.map((recipeitem, k) => {
                                        let recipe = recipeData.filter(d => d.LIBRARY_ID === recipeitem);
                                        category.recipe_items[k] = recipe && recipe[0] ? recipe[0] : [];
                                    });
                                    category.subCategory = [];
                                    item.menu_items && item.menu_items.map((subitem, l) => {
                                        let subCategory = {};
                                        subCategory.ID = subitem.id;

                                        let imageData = null;
                                        let thumbnailData = null;
                                        let imageFileFormat = "";
                                        let imageFileName = "";
                                        let imageFileSize = "";

                                        libraryFiles && libraryFiles.map((libraryitem, m) => {
                                            if (libraryitem.fileName.includes('tmb_')) {
                                                if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "") === subitem.image.replace("Resources/Images/", "tmb_").replace("Resources/", "tmb_").replace(".jpeg", ".png").replace(".jpg", ".png").replace(".JPEG", ".png").replace(".JPG", ".png").replace(".PNG", ".png")) {
                                                    thumbnailData = libraryitem.fileBuffer;
                                                }
                                            }
                                            else if (!libraryitem.fileName.toLowerCase().includes('.json')) {
                                                if (libraryitem.fileName.replace("Resources/Images/", "").replace("Resource/", "") === subitem.image.replace("Resources/Images/", "").replace("Resources/", "")) {
                                                    imageData = libraryitem.fileBuffer;
                                                    let fileName = libraryitem.fileName.replace(/[^a-zA-Z 0-9\n\r.]+/g, '_');
                                                    let fileextSplit = fileName.split('.');
                                                    let fileExt = fileextSplit[1];
                                                    imageFileFormat = "." + fileExt;
                                                    imageFileName = libraryitem.fileName;
                                                    // read json string to Buffer
                                                    let fileSize = Buffer.byteLength(libraryitem.fileBuffer);
                                                    imageFileSize = (fileSize / 1048576).toFixed(2);
                                                }
                                            }
                                        });

                                        subCategory.imageFileName = imageFileName;
                                        subCategory.imageFileFormat = imageFileFormat;
                                        subCategory.imageFileSize = imageFileSize;
                                        subCategory.imageS3Url = subitem.imageS3Url ? subitem.imageS3Url : "";
                                        subCategory.categoryName = subitem.name ? subitem.name.en_GB ? subitem.name.en_GB : "" : "";
                                        subCategory.imageData = "";
                                        subCategory.thumbnailData = thumbnailData;
                                        subCategory.recipe_items = [];
                                        subitem.recipe_items && subitem.recipe_items.map((recipeitem, n) => {
                                            let recipe = recipeData.filter(d => d.LIBRARY_ID === recipeitem);
                                            subCategory.recipe_items[n] = recipe && recipe[0] ? recipe[0] : [];
                                        });
                                        category.subCategory.push(subCategory);
                                    });
                                    categoryDetailsObj.push(category);
                                });

                                // localStorage.setItem("defaultLibraryData", JSON.stringify(categoryDetailsObj));
                                categoryDetailsObj.Import = true;
                                localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
                                localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));

                                let categoryDetails = localStorage.getItem("resourceDetails");
                                categoryDetailsObj = JSON.parse(categoryDetails);
                                this.setState({
                                    categoryList: categoryDetailsObj,
                                    isChanged: true,
                                    loaderIconVisible: false
                                });
                            });
                        }
                        catch (ex) {
                            console.log(ex);
                            this.setState({
                                loaderIconVisible: false
                            });
                        }
                    });
            }
        })
            .catch(err => {
                console.log(err);
                this.setState({
                    loaderIconVisible: false
                });
            });
    }

    onCookbookNameChange = (e) => {
        if (e.target.value.match("^[a-zA-Z0-9 !@#$%&*?'()_+-]*$")) {
            this.setState({
                cookbook_name: e.target.value,
                errorCookbookName: ""
            });
        }
    }

    selectTestDeleteButtonClick = () => {
        this.setState({
            nodeCaptionDetails: null,
            selectedUnitDetails: {},
        });
    }

    selectTestUnitButtonClick = async () => {
        let _this = this;
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);
        let httpMethod;

        if (!categoryDetailsObj && !categoryDetailsObj.length > 0) {
            _this.props.history.push(`/garlandCookbookEditor`);
        } else {
            _this.setState({
                loaderIconVisible: true
            })
            let brandName = localStorage.getItem("brandName");
            let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
            let loggedinUser = loginUser.username;

            await http({
                method: "GET",
                url: UserProfile.getUserProfileDetails,
                headers: {
                    'customer_id': CUSTOMER_ID,
                    'email': loggedinUser,
                    'Content-Type': 'application/json',
                    'base_country': localStorage.getItem("basecountry"),
                    'emulation': localStorage.getItem('emulation') ? 'true' : 'false'
                }, data: {}
            }).then(async response => {
                let libraryId;
                let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                if (libraryDetails.isEdit) {
                    httpMethod = "PUT";
                    libraryId = libraryDetails.library_id;
                } else {
                    httpMethod = "POST";
                    libraryId = libraryDetails.cookbookName.replace(/[^a-zA-Z0-9\n\r.]+/g, '') + '_' + Date.now();
                    libraryId = libraryId.replace(/\s+/g, '');
                    libraryDetails.library_id = libraryId;
                    localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                }

                let jsonTemplateFile = cookbookJSON;
                let jsonTemplateObj = jsonTemplateFile.default;
                let categoryDetailsData = localStorage.getItem("resourceDetails");
                let categoryDetailsObj = JSON.parse(categoryDetailsData);
                jsonTemplateObj.id = libraryDetails.isEdit ? libraryDetails.id : uuidv1();
                jsonTemplateObj.edit_date = todaysDateUTCFormatted;
                jsonTemplateObj.author = response.data.name;
                jsonTemplateObj.email = loginUser.username;

                let imageSize = 0.00;
                jsonTemplateObj.menus = [];
                categoryDetailsObj.map((item, i) => {
                    jsonTemplateObj.menus[i] = {};
                    jsonTemplateObj.menus[i].id = item.ID;
                    jsonTemplateObj.menus[i].edit_date = todaysDateUTCFormatted;
                    jsonTemplateObj.menus[i].author = response.data.name;
                    jsonTemplateObj.menus[i].email = loginUser.username;
                    jsonTemplateObj.menus[i].name = {};
                    jsonTemplateObj.menus[i].name.en_GB = item.categoryName;
                    jsonTemplateObj.menus[i].image = "Resources/Images/" + item.imageFileName;
                    jsonTemplateObj.menus[i].recipe_items = item.recipe_items ? item.recipe_items.map(r => r.LIBRARY_ID) : [];
                    jsonTemplateObj.menus[i].imageS3Url = item.imageS3Url;
                    jsonTemplateObj.menus[i].menu_level = "top";
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            if (j === 0) {
                                jsonTemplateObj.menus[i].menu_items = [];
                            }
                            jsonTemplateObj.menus[i].menu_items[j] = {};
                            jsonTemplateObj.menus[i].menu_items[j].id = subitem.ID;
                            jsonTemplateObj.menus[i].menu_items[j].edit_date = todaysDateUTCFormatted;
                            jsonTemplateObj.menus[i].menu_items[j].author = response.data.name;
                            jsonTemplateObj.menus[i].menu_items[j].email = loginUser.username;
                            jsonTemplateObj.menus[i].menu_items[j].name = {};
                            jsonTemplateObj.menus[i].menu_items[j].name.en_GB = subitem.categoryName;
                            jsonTemplateObj.menus[i].menu_items[j].image = "Resources/Images/" + subitem.imageFileName;
                            jsonTemplateObj.menus[i].menu_items[j].recipe_items = subitem.recipe_items ? subitem.recipe_items.map(r => r.LIBRARY_ID) : [];
                            jsonTemplateObj.menus[i].menu_items[j].imageS3Url = subitem.imageS3Url;
                            jsonTemplateObj.menus[i].menu_items[j].menu_level = "sub";
                            imageSize = imageSize + subitem.imageFileSize ? parseFloat(subitem.imageFileSize) : 0.00;
                        })
                    }
                    imageSize = imageSize + item.imageFileSize ? parseFloat(item.imageFileSize) : 0.00;
                })

                // convert JSON object to beautify String
                var jsonStr = beautify(jsonTemplateObj, null, 2, 80)
                // read json string to Buffer
                const jsonBuffer = Buffer.from(jsonStr);

                let fileSize = Buffer.byteLength(jsonBuffer);
                fileSize = (fileSize / 1048576);
                let md5 = require('md5');
                let crc = md5(jsonBuffer, "base64");

                let categorys = categoryDetailsObj;
                delete categorys.Import;
                categorys && categorys.map((item, i) => {
                    delete item.imageData;
                    delete item.thumbnailData;
                    let recipes = item.recipe_items ? item.recipe_items.map(r => r.LIBRARY_ID) : [];
                    delete item.recipe_items;
                    categorys[i].recipe_items = recipes ? recipes : [];
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            delete subitem.imageData;
                            delete subitem.thumbnailData;
                            let recipes = subitem.recipe_items ? subitem.recipe_items.map(r => r.LIBRARY_ID) : [];
                            delete subitem.recipe_items;
                            categorys[i].subCategory[j].recipe_items = recipes ? recipes : [];
                        })
                    }
                });

                categorys && categorys.map((item, i) => {
                    if (item.subCategory) {
                        item.subCategory.map((subitem, j) => {
                            categorys.push(subitem);
                            subitem = null;
                        })
                    }
                });

                let libraryPostData = {
                    "customerId": CUSTOMER_ID,
                    "brandId": BRAND_ID_VALUE[brandName],
                    "modelFamilyIds": localStorage.getItem("selectedFamilyID"),
                    "libraryId": libraryId,
                    "libraryName": libraryDetails.cookbookName,
                    "libraryFileName": libraryId + ".json",
                    "libraryFileFormat": "json",
                    "libraryFileSize": (fileSize + imageSize).toFixed(2),
                    "libraryS3Url": "",
                    "type": "CookBook",
                    "createdUser": response.data.name + ' (' + loggedinUser + ')',
                    "crc": crc,
                    "baseCountry": BASECOUNTRY_ID,
                    "imageFileName": "",
                    "imageFileFormat": "",
                    "imageFileSize": (imageSize).toFixed(2),
                    "imageS3Url": "",
                    "cgids": [parseInt(localStorage.getItem("custGroupID") ? localStorage.getItem("custGroupID") : 0)],
                    "json": JSON.stringify(jsonTemplateObj),
                    "categories": categorys ? categorys : [],
                    "libraryGuid": jsonTemplateObj.id
                };

                if (libraryDetails.isEdit) {
                    delete libraryPostData.customerId;
                    delete libraryPostData.createdUser;
                    libraryPostData.updatedUser = response.data.name + ' (' + loggedinUser + ')';
                }

                if (libraryDetails.initialMethod === "Add") {
                    libraryPostData.status = "DRAFT";
                }

                let headerpresigned = {
                    'Content-Type': 'application/json',
                    'customerid': CUSTOMER_ID,
                    'imagefilekey': "",
                    'jsonfilekey': libraryId + '.json',
                    'audiofilekey': "",
                    'isthumbnail': false
                }

                await uploadLibraryToCloud(MenuUploadAPI.getlibraryUploadpresignedUrl, httpMethod, headerpresigned, libraryPostData, null, null, jsonBuffer, false).then(async response => {
                    let { selectedUnitDetails } = this.state;

                    const unitList = [];
                    unitList.push(
                        {
                            "thingArn": selectedUnitDetails.THING,
                            "productId": selectedUnitDetails.ProductId,
                            "cloudUnitName": selectedUnitDetails.CLOUD_UNIT_NAME,
                            "unitTimeZone": selectedUnitDetails.TimeZone_Standard
                        }
                    )

                    let postData = {
                        "customer": CUSTOMER_ID,
                        "brand": brandName,
                        "brandId": BRAND_ID_VALUE[brandName],
                        "user": loggedinUser,
                        "libraryIds": [libraryId],
                        "country": BASECOUNTRY_ID,
                        "type": "Recipe",
                        "thingList": unitList,
                        "userName": loggedinUser,
                        "isTestPush": true
                    };

                    let url = MenuUploadAPI.getLibraryUpdateStatus;
                    await http.post(url, postData,
                        {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        }
                    )
                        .then(response => {
                            let libraryDetails = JSON.parse(localStorage.getItem("libraryDetails"));
                            libraryDetails.isEdit = true;
                            localStorage.setItem("libraryDetails", JSON.stringify(libraryDetails));
                            _this.setState({
                                loaderIconVisible: false,
                                isEdit: true,
                                success_modal: {
                                    open: true,
                                    message: "Library test distributed successfully."
                                },
                                nodeCaptionDetails: null,
                                selectedUnitDetails: {},
                            });
                        }).catch(err => {
                            let { response } = err;
                            let { data } = response;
                            let { message } = data;
                            this.setState({
                                error_modal: {
                                    open: true,
                                    message: message ? message : ""
                                },
                                loaderIconVisible: false
                            });
                        });
                }).catch(err => {
                    this.setState({
                        loaderIconVisible: false
                    });
                    console.log(err);
                });
            }).catch(err => {
                _this.setState({
                    loaderIconVisible: false
                })
                console.log(err);
            });
        }
    }

    addPressandGo = () => {
        let categoryDetails = localStorage.getItem("resourceDetails");
        let categoryDetailsObj = JSON.parse(categoryDetails);

        let selectedCategoryDetailsObj = categoryDetailsObj.find(c => c.ID === 'Press&Go');
        if (!selectedCategoryDetailsObj) {
            let pressGoObj = {};
            pressGoObj.ID = "Press&Go";
            pressGoObj.categoryName = "Press&Go";
            pressGoObj.imageFileFormat = "";
            pressGoObj.imageFileName = "";
            pressGoObj.imageFileSize = "";
            pressGoObj.imageS3Url = "";
            pressGoObj.parentID = "";
            pressGoObj.selected = false;
            pressGoObj.thumbnailData = null;
            pressGoObj.type = "Category";
            categoryDetailsObj.push(pressGoObj);
            localStorage.setItem("resourceDetails", JSON.stringify(categoryDetailsObj));
        }

        this.props.history.push({
            pathname: "/cookbookPressAndGo",
            isEdit: false,
            previouspath: "/garlandCookbookEditor"
        });
    }

    render() {
        const { formatMessage } = this.props.intl;
        let { data: dataList, loaderIconVisible } = this.state;
        dataList = dataList && dataList.length > 0 ? dataList : [];
        let brandid = localStorage.getItem("selectedbrandID");
        let { categoryList, deleteCategoryVisible, isChanged, showDiscardPopup, success_modal, error_modal, isDeleteCategory, cookbook_name, errorCookbookName, isEdit, isImport, selectedModel } = this.state;
        categoryList = _.orderBy(categoryList);
        let loginInfo = JSON.parse(localStorage.getItem("loginInfo"));
        const productReg = localStorage.getItem('ProductRecgDetails') ? JSON.parse(localStorage.getItem('ProductRecgDetails')) : {};
        const productRegRecipe = productReg && productReg.recipe_items ? productReg.recipe_items : [];
        const isProductRecognitionAdded = productRegRecipe.length;
       
        return (
            <React.Fragment>
                <UserManagementHeader headerNameStringID="KC0657" headerName="Menu & Unit Settings" activeClass="menuNav" />
                <div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
                    <div className="loadingProgressIn"></div>
                </div>
                <div id="page-content-wrapper">
                    <div className="menuUpdateWrapper">
                        <div className="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658" /></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} className="active"><FormattedMessage id="KC1180" /></Link>
                                </li>
                                <li className="selectedModel">ME, MG</li>
                            </ul>
                        </div>

                        <div className="rcDeviceWrapper">
                            <div className="rcGarlandLogo"><img src={require("../../../../../images/logos/Garland_Color.svg").default} /> <button type="button" onClick={() => this.props.history.push('/garlandCookbookToTopology')} className={categoryList.length > 0 ? "btn saveExitBtn" : "btn saveExitBtn disabled"}>Save File &amp; Exit</button></div>
                            <div className="recipeEditorWrapper">
                                <div className="recipeEditorH selectedH">
                                    <button type="button" onClick={() => this.props.history.replace('/garlandCookbookCreation')} className="btn backButton">&nbsp;</button>
                                    {localStorage.getItem("cookBookFileName") ? localStorage.getItem("cookBookFileName") : ""}
                                    {/* <button type="button" className="btn editButton">&nbsp;</button> */}
                                    <button type="button" class={(isChanged || isEdit || isImport) ? "btn btn-default-text editButton" : "displaynone"} onClick={this.onclickEditCookbook}>&nbsp;</button>
                                </div>
                                <div className="reBodyWrapper mCustomScrollbar">
                                    <div className="reWrapperTable">
                                        <div className="reWrapperRow">
                                            <div className="reWrapperColmn alignTop">
                                                <DragDropContext onDragEnd={this.onDragEnd}>
                                                    <Droppable droppableId="droppable" direction="horizontal">
                                                        {(provided) => (
                                                            <div ref={provided.innerRef} {...provided.droppableProps} class="cbcategory menuEditActive">
                                                                <ul>
                                                                    <li className="garlandCBcategory">
                                                                        <Link to="/garlandCookbookAddMenu" className="cbAddButton" title={formatMessage({ id: 'KC2083' })}><FormattedMessage id="KC2083" /></Link>
                                                                    </li>
                                                                    <li className="garlandCBcategory">
                                                                        <Link to={categoryList && categoryList.length ? "/garlandCookbookProduct" : "/garlandCookbookEditor"} className={`${isProductRecognitionAdded ? "cbAddedButton" : "cbAddButton"} ${categoryList && categoryList.length ? "" : "disabled"}`} title={formatMessage({ id: 'Product Recognition' })}><FormattedMessage id="Product Recognition" /></Link>
                                                                    </li>
                                                                    {categoryList.map((item, index) => (
                                                                        <Draggable key={item.ID} draggableId={item.categoryName} index={index}>
                                                                            {(provided) => (
                                                                                <li key={item.ID} ref={provided.innerRef} {...provided.draggableProps}
                                                                                    {...provided.dragHandleProps}>
                                                                                    <div class="cbcategoryBox">
                                                                                        <button type="button" class={deleteCategoryVisible ? "btn cancelButton" : "displaynone"} onClick={(e) => this.onDeleteCategory(e, item)}>&nbsp;</button>
                                                                                        <div class={deleteCategoryVisible ? "cbcategoryAction" : "displaynone"}>
                                                                                            <button type="button" class={deleteCategoryVisible ? "btn cbEditButton" : "displaynone"} onClick={(e) => this.editCategory(e, item)}>&nbsp;</button>
                                                                                        </div>
                                                                                        <Link to="#" class="navIcon" onClick={(e) => this.onCategorySelect(e, item)}> <img src={item.thumbnailData ? `data:${'image/png'};base64,${item.thumbnailData}` : require("../../../../../images/recipecreation/photosPictures.png")} /></Link>
                                                                                        <Link to="#" class="navTitle" onClick={(e) => this.onCategorySelect(e, item)}>{item.categoryName}</Link>
                                                                                    </div>
                                                                                </li>
                                                                            )}
                                                                        </Draggable>
                                                                    ))}
                                                                    {provided.placeholder}
                                                                </ul>
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                </DragDropContext>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- Discard Confirmation Modal --> */}
                <div className={isChanged && showDiscardPopup ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isChanged && showDiscardPopup ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC1218" />
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580" /></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.OnDiscard()} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Discard Confirmation Modal --> */}
                {/* <!-- Delete Confirmation Modal --> */}
                <div className={isDeleteCategory ? "modal fade confirmationDeleteModal show" : "modal fade"} style={isDeleteCategory ? { display: 'block' } : { display: 'none' }} data-keyboard="true" data-backdrop="static" id="confirmationDeleteModal" tabindex="-1" role="dialog" aria-labelledby="confirmationDeleteModalLabel">
                    <div className="modal-dialog modal-md">
                        <div className="modal-content">
                            <div className="modal-body">
                                <div className="deleteConfirmation">
                                    <FormattedMessage id="KC2288" />
                                </div>
                            </div>
                            <div className="modal-footer textAlignCenter">
                                <button className="btn btn-secondary deleteNoBtn" onClick={() => this.OnCancel()} data-dismiss="modal" type="button"><FormattedMessage id="KC0580" /></button>
                                <button className="btn btn-secondary deleteYesBtn" onClick={() => this.onDeleteConfirm()} data-dismiss="modal" type="button"><FormattedMessage id='KC0611' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Delete Confirmation Modal --> */}
                {/* <!-- Import Modal --> */}
                <LibraryListPopup libraryType={'CookBook'} OnImportLibrary={(item) => this.OnImport(item)} modelID={selectedModel} />
                {/* <!-- //Import Modal --> */}
                {/* <!-- Save Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="saveNameModal" tabindex="-1" role="dialog" aria-labelledby="saveNameModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="confirmationYesNo">
                                    <h5><FormattedMessage id='KC0686' /></h5>
                                    <div class="input-group">
                                        <input class="form-control" type="text" maxLength="20" placeholder={formatMessage({ id: "KC0686" })} value={cookbook_name} onChange={(e) => this.onCookbookNameChange(e)} />
                                        <div className="errorMsg">{errorCookbookName != '' ? <FormattedMessage id={errorCookbookName} /> : ''}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button onClick={() => { this.OnCancel() }} class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0021' /></button>
                                <button onClick={() => { this.onSaveButtonClick('Save') }} class="btn btn-secondary yesBtn" id="timeZoneAssign" type="button"><FormattedMessage id='KC0123' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Save Modal --> */}
                {/* <!-- Test Modal --> */}
                <div class="modal fade confirmationYesNoModal" data-keyboard="true" data-backdrop="static" id="testNameModal" tabindex="-1" role="dialog" aria-labelledby="saveNameModalLabel">
                    <div class="modal-dialog modal-md">
                        <div class="modal-content">
                            <div class="modal-body">
                                <div class="confirmationYesNo">
                                    <h5><FormattedMessage id='KC0686' /></h5>
                                    <div class="input-group">
                                        <input class="form-control" type="text" placeholder={formatMessage({ id: "KC0686" })} value={cookbook_name} onChange={(e) => this.onCookbookNameChange(e)} />
                                        <div className="errorMsg">{errorCookbookName != '' ? <FormattedMessage id={errorCookbookName} /> : ''}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer textAlignCenter">
                                <button onClick={() => { this.OnCancel() }} class="btn btn-secondary noBtn" data-dismiss="modal" type="button"><FormattedMessage id='KC0021' /></button>
                                <button onClick={() => { this.onSaveButtonClick('Test') }} class="btn btn-secondary yesBtn" id="timeZoneAssign" type="button"><FormattedMessage id='KC0123' /></button>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <!-- //Test Modal --> */}
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
                <SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} />
            </React.Fragment>
        )
    }
}
export default injectIntl(GarlandCookbookEditor)