import React, { Component } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import UserManagementHeader from '../../layoutSection/userManagementHeader';
import { UnitInfo, smartGroupAPI, smartTagMangement } from '../../../service/api';
import { UnitBuilder } from '../../../utils/unitResponseBuilder';
import http from '../../../service/httpService'
import $ from 'jquery';
import _ from 'lodash';
import { TableHeader } from '../../controls/tableHeader';
import SuccessMoal from '../../modal/success-model';
import ErrorMoal from '../../modal/error-model';
import { BASECOUNTRY_ID, BRAND_ID } from '../../../utils/appConstants';
import { searchVisible, searchText } from '../../../actions/advanceSearch/advanceSearchAction';
import { isEqualObj } from '../../globalSearchBar/selectOption';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import authService from '../../../service/authService';
import { ungzip } from 'pako';
import NoRecordsFound from '../../controls/noRecordsFound';
import { FormattedMessage, injectIntl } from 'react-intl';
import OrgTagsDetail from './orgTagsDetail';

class smartTagUnitList extends Component {
	constructor(props) {
		super(props);
		this.state = {
			loaderIconVisible: false,
			data: [],
			userSelectAll: false,
			isIndeterminateSelect: false,
			userCheckedData: [],
			sortColumn: { path: "", order: "", sort_color: "#fff" },
			nameFromReport: '',
			startDateFromReport: '',
			endDateFromReport: '',
			selectedoptionsFromReport: [],
			success_modal: {
				open: false,
				message: ""
			},
			error_modal: {
				open: false,
				message: ""
			},
			errorResponse: "",
			access_modal: {
				open: false,
				message: "",
				isHtml: false,
				disabledBtn: false,
			},
			purposeValData: [],
			searchData: null,
			higherLevelUsersUnits: [],
			treeNodeActive: {},
			showStPopUp:false,
			selUnitDetails:{}
		};
		this.closeModel = this.closeModel.bind(this);
		this.onSuccessCloseModal = this.onSuccessCloseModal.bind(this);

		this.myReports = this.myReports.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.handleSingleCheckboxChange = this.handleSingleCheckboxChange.bind(this);

	}

	componentDidMount() {
		this.props.searchVisible(true);
		document.body.style.backgroundColor = "#F2F2F2";
		this.updateDimensions()
        $("div").removeClass("modal-backdrop fade show");
		if (this.props && this.props.location && this.props.location.userCheckedData) {
			this.setState({ treeNodeActive: this.props.location.userCheckedData })
		}

		try {
			if (this.props.location && this.props.location.state) {
				this.setState({
					nameFromReport: this.props.location.state.nameFromReport,
					startDateFromReport: this.props.location.state.startDateFromReport,
					endDateFromReport: this.props.location.state.endDateFromReport,
					selectedoptionsFromReport: this.props.location.state.selectedoptionsFromReport,
				});
			}
			this.requestData();
		} catch (error) {
			//Error Goes Here
		}
	}

	updateDimensions() {
		const windowHeight = $(window).height();
        const assignUListSrlBar = $(".assignUListSrlBar");
        assignUListSrlBar.css('height', windowHeight - assignUListSrlBar.offset().top);
	} 
	componentWillMount() {
		if (this.props.location && this.props.location.state) {
			this.setState({
				nameFromReport: this.props.location.state.nameFromReport,
				startDateFromReport: this.props.location.state.startDateFromReport,
				endDateFromReport: this.props.location.state.endDateFromReport,
				selectedoptionsFromReport: this.props.location.state.selectedoptionsFromReport,
			});
		}
	}

	componentDidUpdate(prevState) {
		this.updateDimensions();
		let { searchData } = prevState;
		let curSearchData = this.state && this.state.searchData;

		if (curSearchData && (isEqualObj(searchData, curSearchData) === false)) {
			this.setState({ loaderIconVisible: true })
			this.requestData();
		}
	}

	componentWillUnmount() {
		this.props.searchText({});
		this.props.searchVisible(false);
	}

	 // clear Global Search
	 clearGlobalSearch = () => {
		this.props.clearSearchText();
	}

	/**
     * Convert Encoding UTF8 to binary
     */
    convertToBinary = (string) => {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
            codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }

	onSort = sortColumn => {
		$(".table-header").removeClass('sortingParent');
		$(".sortingChild").parent().addClass('sortingParent');
		this.setState({
			sortColumn: sortColumn
		});
	}

	/**
	 * Decompress Base64 encode string into JSON
	 * @param {*} base64 Base64 encoded string
	 * @returns JSON | null
	 */
	decompressData(base64) {
		try {
			const buffer = Buffer.from(base64, 'base64');
			const bufferArr = ungzip(buffer);
			const json = new TextDecoder('utf8').decode(bufferArr);
			return JSON.parse(json);
		} catch (err) {
			console.error(err);
			return null;
		}
	}

	requestData = async () => {
		this.setState({ loaderIconVisible: true });
		let selectedModels = [];
		let custGroupID = localStorage.getItem("custGroupID");
		let cgids = "";
		if (custGroupID !== "" && custGroupID !== undefined) {
			cgids = custGroupID;
		}
		let sortedDesc = "desc";
		let sortedID = "CONNECT_STATE";
		// let apiUrl = `${smartTagMangement.unitList}?brandId=${BRAND_ID}` ;
		let apiUrl = `${smartTagMangement.unitList}`;

		let { searchData } = this.state;

		if (searchData && searchData.searchtext) {
			apiUrl = `${apiUrl}?searchText=${searchData.searchtext}`;
		}

		if (searchData) {
			let searchParam = {};

			Object.keys(searchData).map((item) => {
				if (item !== "searchtext") {
					searchParam[item] = searchData[item];
				}
			})

			if (searchParam && Object.keys(searchParam).length) {
				apiUrl = `${apiUrl}${searchData && searchData.searchtext ? "&" : "?"}searchParams=${btoa(JSON.stringify(searchParam))}`;
			}
		}

		let headerValue = {
			'Content-Type': 'application/json',
			'cgid': cgids,
			'customerid': localStorage.getItem("customerId"),
			'brandid': BRAND_ID,
		}

		await http.get(apiUrl,
			{
				headers: headerValue,
				data: {}
			}).then(response => {
				if (response.data.data.compressed) {
					response.data.data = this.decompressData(response.data.data) || [];
				}
				let { data: units, } = response.data;
				units = UnitBuilder(units);

				if (this.props.location && this.props.location.isUnitItemClicked) {
					const dynastyArr = this.state.treeNodeActive && Object.keys(this.state.treeNodeActive).length ? Object.keys(this.state.treeNodeActive) : [];
					for (let i = 0; i < units.length; i++) {
						const item = units[i];
						if (dynastyArr.includes(item.UNITID)) {
							units[i].checked = true;
						}
					}
				}
				// else {
				// 	const dynastyArr = this.state.treeNodeActive && Object.keys(this.state.treeNodeActive).length ? Object.keys(this.state.treeNodeActive) : [];

				// 	for (let int = 0; int < units.length; int++) {
				// 		const result = units[int];
				// 		const dynacityValue = result.dynasty ? result.dynasty.split(',') : [];
				// 		for (let index = 0; index < dynacityValue.length; index++) {
				// 			const element = dynacityValue[index];
				// 			if (dynastyArr.includes(element)) {
				// 				units[int].checked = true;
				// 			}
				// 		}
				// 	}
				// }
				this.setState({
					loaderIconVisible: false,
					data: units,
					...this.getCheckBoxstate(units.length, units.filter((i) => i.checked === true).length)
				});

			}).catch(err => {
				this.setState({
					loaderIconVisible: false,
					data: [],
				});
			});
	}

	onBackClickHandler = (userCheckedData) => {
		this.props.history.push({
			pathname: `/smartTag/createUnitTag`,
			state: {
				userCheckedData
			},
		});
	}

	myReports = () => {
		this.setState({
			loaderIconVisible: false,
		});
		this.props.history.push(`/reportList`);
	}

	handleChange = () => {
		let units = this.state.data;

		for (let i = 0; i < units.length; i++) {
			units[i].checked = !this.state.userSelectAll;
		}

		this.setState(prevState => ({
			data: units,
			userSelectAll: !prevState.userSelectAll,
			isIndeterminateSelect: false
		}));
	};

	getCheckBoxstate(ListCount, SelectedCount) {
		if (SelectedCount === ListCount) {
			return { userSelectAll: true, isIndeterminateSelect: false };
		} else if (SelectedCount === 0 && ListCount !== 0) {
			return { userSelectAll: false, isIndeterminateSelect: false };
		} else if (ListCount >= SelectedCount) {
			return { userSelectAll: true, isIndeterminateSelect: true };
		}
	}

	handleSingleCheckboxChange = (e, ID) => {
		let units = [];
		for (let i = 0; i < this.state.data.length; i++) {
			units.push({ ...this.state.data[i], checked: this.state.data[i].ID === ID ? e.target.checked : this.state.data[i].checked || false });
		}

		this.setState({
			data: units,
			...this.getCheckBoxstate(units.length, units.filter((i) => i.checked === true).length)
		});
	};

	onClickFinish = async (e) => {
		this.setState({
			loaderIconVisible: true,
		});
		let selectedNodeList = this.state.data;
		let unitIds = [];
		selectedNodeList = selectedNodeList.filter((i) => i.checked === true);
		selectedNodeList.map((item, i) => {
			unitIds.push(item.ID)
		})
		let tagDetails = JSON.parse(localStorage.getItem("smartTagInfo"));
		let loginUser = JSON.parse(localStorage.getItem("loginInfo"));
		let loggedinUser = loginUser.username;
		let userName = localStorage.getItem("userName");
		let value = tagDetails.unitTagDetails;
		if(!value || !value.TagName){
			this.setState({
				loaderIconVisible: false
			});
            return
        }
		if(tagDetails && tagDetails.selectedRow && tagDetails.selectedRow.unAssignedUnits && tagDetails.selectedRow.unAssignedUnits.length){
            unitIds = [...unitIds,...tagDetails.selectedRow.unAssignedUnits]
        }

		
		const allValue = tagDetails && tagDetails.selectedRow && tagDetails.selectedRow.allunitIds ? tagDetails && tagDetails.selectedRow.allunitIds : [];
        const userValue = tagDetails && tagDetails.selectedRow && tagDetails.selectedRow.userUnitIds ? tagDetails && tagDetails.selectedRow.userUnitIds : [];

        let existEle = allValue && allValue.length ?allValue.filter(
            function(i) {
                return this.indexOf(i) < 0;
            },
            userValue
        ) : [];
        if(existEle && existEle.length){
            unitIds = [...unitIds, ...existEle]
        }
		let postdata = {
            "tagName": value.TagName,
            "description": value.TagDescription,
            "tagType": tagDetails.type === "unitList" ? "UNIT" : "ORG",
            "createdBy": tagDetails.mode === "edit" ? tagDetails.selectedRow.createdBy : loggedinUser,
            "createdByName": tagDetails.mode === "edit" ? tagDetails.selectedRow.createdByName : userName,
            "updatedBy": loggedinUser,
            "updatedByName": userName,
            "customerGroupId": tagDetails.type === "unitList" ? "" : selectedNodeList,
            "unitId": unitIds,
            "customerId": + localStorage.getItem("customerId"),
        };
		let url = smartTagMangement.createOrgTag;
		if (tagDetails.mode === 'add') {
			this.triggerSmartTag(url, postdata, 'post');
		} else {
			url = smartTagMangement.createOrgTag;
            const id = tagDetails.selectedRow.id;
			this.triggerSmartTag(url+`/${id}`, postdata, 'put');
		}
	}

	triggerSmartTag = async (url, postdata, method) => {
		await http[method](url, postdata,
			{
				headers: {
					'Content-Type': 'application/json',
				}
			}
		).then(response => {
			if (response && response.data) {
				this.setState({
					loaderIconVisible: false,
					success_modal: {
						open: true,
						newMessageFormat: true,
						message: method === "post" ? 'KC2316' : 'KC2323'
					},
				});
			}
		}).catch(err => {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.response && err.response.data && err.response.data.error ? err.response.data.error : "KC1110",
				},
			});
		});
	}

	showError = (err) => {
		try {
			let { response } = err;
			if (response && response.status) {

				let { data } = response;
				let { body } = data;
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: body.message ? body.message : ""
					}
				});
			} else {
				this.setState({
					loaderIconVisible: false,
					error_modal: {
						open: true,
						message: err.message
					}

				});
			}
		} catch (e) {
			this.setState({
				loaderIconVisible: false,
				error_modal: {
					open: true,
					message: err.message
				}

			});
		}
	}

	closeModel() {
		this.setState({ error_modal: { open: false } })
	}

	onSuccessCloseModal = () => {
		this.setState({
			success_modal: {
				open: false
			}
		});
		this.props.history.replace(`/smartTag/tagList`);
	}

	showSTagInfo = (unitDet,type="org") =>{
		let unitObj = unitDet;
		unitObj.id = unitDet.ID;
        this.setState({
            showStPopUp:true,
			selUnitDetails:unitObj
        },()=>{
            $('#unitSmartTagsDetails').modal('show')
        })

    }

	static getDerivedStateFromProps = (props) => ({ ...props });

	render() {
		const { loaderIconVisible, data, sortColumn, error_modal, success_modal, access_modal } = this.state;
		let userProfileAuthFeature = authService.getFeature("/smartgroupslist");
		const { formatMessage } = this.props.intl;

		let columns = [
			{ path: "", label: "#" },
			{ path: "", label: formatMessage({ id: "KC0101" }), isNbSp: true },
			{ path: "BRAND_NAME", label: formatMessage({ id: "KC0062" }) },
			{ path: "PRODUCT_TYPE", label: formatMessage({ id: "KC0102" }) },
			{ path: "MODEL_NAME", label: formatMessage({ id: "KC0673" }) },
			{ path: "UNITID", label: formatMessage({ id: "KC0104" }) },
			{ path: "UNITNUMBER", label: formatMessage({ id: "KC0105" }) },
			{ path: "location_name", label: formatMessage({ id: "KC0050" }) },
			{ path: "within_location", label: formatMessage({ id: "KC0061" }) },
			{ path: "", label: formatMessage({ id: "KC2317" }) },
			{ path: "", label: "", isCheckBox: true, isCheckBoxActive: userProfileAuthFeature.is_editable },
		];

		let unitList = data;

		unitList = _.orderBy(unitList, [sortColumn.path], [sortColumn.order]);
		const filterCheckedData = unitList.filter((i) => i.checked === true);
		const filterOfflineSelectedData = unitList.filter((i) => i.checked === true && i.CONNECT_STATE === "Offline");
		let Updatedunits = (parseInt(filterCheckedData.length) - parseInt(filterOfflineSelectedData.length));

		let showHeader;
		showHeader = (
			<ul>
				<li><FormattedMessage id="KC1570" values={{ selectedCount: filterCheckedData.length || 0, offlineCount: filterOfflineSelectedData.length || 0 }} /></li>
				<li>
					<Link to={{ pathname: "/smartTag/smartTagTopology", userCheckedData: filterCheckedData }} className="assignunitSite">&nbsp;</Link>
					<Link to="#" id="btnAssign" className="assignunitList active">&nbsp;</Link>
				</li>
			</ul>
		);

		return (
			<React.Fragment>
				<div id="adminWrapper">
					<div className={loaderIconVisible ? "loadingProgress showloader" : "loadingProgress"}>
						<div className="loadingProgressIn"></div>
					</div>
					<div id="page-content-wrapper" className='withSearchBar'>
						<div className="reportWrapper">
							<div className="adminBgHeader">
								<ul>
									<li>
										<button onClick={() => this.onBackClickHandler(filterCheckedData)} title={formatMessage({ id: 'KC0989' })} className="backIcon border0">&nbsp;</button>
									</li>
									<li className="txtboldmsg">
										<FormattedMessage id='KC0711' />
									</li>
									<li>
										<button id="btnfinish" title={formatMessage({ id: 'KC0056' })} className={`btn-default-text nextText border0 ${filterCheckedData.length > 0 ? 'activeState' : 'disabled'}`} onClick={() => this.onClickFinish()}><FormattedMessage id='KC0056' /></button>
									</li>
								</ul>
							</div>
							<div className="selectedUnitHeader">
								{showHeader}
							</div>
							<form className="SWlistUnits">
								<div className="SWlistUnitsTable">
									<TableHeader
										sortColumn={sortColumn}
										onSort={this.onSort}
										selectAllChange={this.handleChange}
										columns={columns}
										isIndeterminate={this.state.isIndeterminateSelect}
										isSelectAll={this.state.userSelectAll} />
								</div>
								<div className="assignUListSrlBar">
									{unitList.map((item, i) => {
										const title = item.smartGroups && item.smartGroups.length > 0 ? item.smartGroups.map((i) => i.smartGroupName) : [];
										return (
											<React.Fragment key={i}>
												<div className="SWlistUnitsTable">
													<ul className="tBody" key={i}>
														{/* <li><button role="button" type="button" data-toggle="collapse" data-parent="#sgAssignAccordion" href={"#sgAssignAcc" + i} aria-expanded="false" aria-controls={"sgAssignAcc" + i} className="btn collapseExpand collapsed">&nbsp;</button></li> */}
														<li>{i + 1}</li>
														<li><img src={item.Brand_Logo} alt="" /></li>
														<li>{item.BRAND_NAME}
															<span className="unitsListSM">conneX 12 HP, Speed Oven</span>
															<span className="unitsListSML">Welbilt Kitchen, New Port Richey</span>
														</li>
														<li>{item.PRODUCT_TYPE}</li>
														<li>{item.MODEL_NAME}</li>
														{/* <li>{item.MODEL_NAME}</li> */}
														<li>{item.UNITID}</li>
														<li>{item.UNITNUMBER}</li>
														<li>{item.location_name}</li>
														<li>{item.within_location}</li>
														{/* <li><span className={`${item.CONNECT_STATE === 'Offline' ? "offlineStausTable" : ""}`}></span></li> */}
														<li>
															{item.orgTags && item.orgTags.length ? <button className="btn orgTagedUnits" type="button" onClick={() => this.showSTagInfo(item,'unit')}>&nbsp;</button> : ""}
															{item.unitTags && item.unitTags.length ? <button className="btn tagedUnits" type="button" onClick={() => this.showSTagInfo(item,'unit')}>&nbsp;</button> : ""}
														</li>
														{<li>
															<div className="customCheckbox customCheckboxWOlabel">
																<input id={`checkbox_${i}`} type="checkbox" value={item.checked} defaultChecked={item.checked}
																	checked={item.checked}
																	onChange={(e) => this.handleSingleCheckboxChange(e, item.ID)} />
																<label for={`checkbox_${i}`} ></label>
															</div>
														</li>}
													</ul>
												</div>
											</React.Fragment>
										)
									}
									)}
								</div>
								<NoRecordsFound loaderIconVisible={loaderIconVisible} length={unitList !== undefined ? unitList.length : 0} classname={'norecordsfoundbluetext'} />
							</form>
						</div>
					</div>
					{this.state.showStPopUp ? <OrgTagsDetail closeSTModel = {()=>this.setState({showStPopUp:false})} unitDet = {this.state.selUnitDetails} isUnit = {true}/>: ""}
					<ErrorMoal open={error_modal.open} stringID={error_modal.message} onCloseModel={this.closeModel} />
					<SuccessMoal open={success_modal.open} stringID={success_modal.message} onSuccessModel={this.onSuccessCloseModal} newMessageFormat={true} />
					<UserManagementHeader headerName="Smart Tag Management" headerNameStringID="KC2318" activeClass="smartTagNav" />
				</div>
				<div className="modal fade confirmationOkModal" data-keyboard="true" data-backdrop="static" id="confirmationOkModal" tabindex="-1" role="dialog" aria-labelledby=" confirmationOkModalLabel">
					<div className="modal-dialog modal-md">
						<div className="modal-content">
							<div className="modal-body">
								<div className="confirmationContentDiv">
									<h5><FormattedMessage id='KC0832' /></h5>
									<p><FormattedMessage id="KC1571" values={{ Updatedunits, unitsSelected: filterCheckedData.length, unitsOffline: filterOfflineSelectedData.length }} /></p>
								</div>
							</div>
							<div className="modal-footer textAlignCenter">
								<button className="btn btn-secondary confirmationOKBtn" data-dismiss="modal" onClick={() => this.myReports()} type="button"><FormattedMessage id='KC0012' /></button>
							</div>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
};


function mapStateToProps(state) {
	return {
		searchData: state.advanceSearch.searchData
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		searchVisible, searchText
	}, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(smartTagUnitList));