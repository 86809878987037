import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Switch from 'react-switch';
import InputMask from 'react-input-mask';
import UserManagementHeader from '../../../../../layoutSection/userManagementHeader';
import appconstants from '../../../../../../utils/appConstants';
import { ModelFamilyList } from '../../../../../controls/modelFamilyList';
import ErrorMoal from '../../../../../modal/error-model';
import { FormattedMessage, injectIntl } from 'react-intl';

class FrymasterCookingSmarkCook extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedModel: 0,
            prompt: "true",
            promptTimer: "",
            promptTemperature: "",
            generalTemperature: 0,
            error_modal: {
                open: false,
                message: ""
            },
        }
        this.handleModelChange = this.handleModelChange.bind(this);
        this.onBackNavigation = this.onBackNavigation.bind(this);
    }

    componentDidMount = async () => {
        document.body.style.backgroundColor = "#000000";
        try {
            let jsonSettingsFile = localStorage.getItem("libraryData");
            let ObjSettingsFile = JSON.parse(jsonSettingsFile);

            if (ObjSettingsFile.general.temperature.temperature_unit) {
                this.setState({ generalTemperature: ObjSettingsFile.general.temperature.temperature_unit })
            }

            this.setState({
                prompt: ObjSettingsFile.cooking.smart_cook_prompt.prompt === 1 ? "true" : "false",
                promptTimer: Number(ObjSettingsFile.cooking.smart_cook_prompt.prompt_timer),
                promptTemperature: Number(ObjSettingsFile.cooking.smart_cook_prompt.prompt_temperature),
            })
        } catch (error) {
            //Error Goes Here
        }
    }

    onConfirmButtonClick = () => {
        this.setState({
            loaderIconVisible: true,
        });
        let { prompt, promptTemperature, promptTimer, generalTemperature } = this.state;
        let jsonSettingsFile = localStorage.getItem("libraryData");
        let ObjSettingsFile = JSON.parse(jsonSettingsFile);
        if (!ObjSettingsFile.cooking.smart_cook_prompt) {
            ObjSettingsFile.cooking.smart_cook_prompt = {};
        }

        if (generalTemperature === 0) {
            if (Number(promptTemperature) !== 0) {
                if (Number(promptTemperature) > 30 || Number(promptTemperature) < 0) {
                    this.setState({
                        loaderIconVisible: false,
                        error_modal: {
                            open: true,
                            message: "KC1464"
                        }
                    });
                    return;
                }
            }
        }
        
        if (generalTemperature === 1) {
            if (Number(promptTemperature) !== 0) {
                if (Number(promptTemperature) > -1.1111111 || Number(promptTemperature) < -17.222222) {
                    this.setState({
                        error_modal: {
                            open: true,
                            message: "KC1976"
                        }
                    });
                    return;
                }
            }
        }

        if (promptTimer > 60 || promptTimer < 0) {
            this.setState({
                error_modal: {
                    open: true,
                    message: "KC1465"
                }
            });
            return;
        }
        ObjSettingsFile.cooking.smart_cook_prompt.prompt = prompt === "true" ? 1 : 0;
        ObjSettingsFile.cooking.smart_cook_prompt.prompt_timer = Number(promptTimer);
        ObjSettingsFile.cooking.smart_cook_prompt.prompt_temperature = Number(promptTemperature);
        let strSettingsFile = JSON.stringify(ObjSettingsFile);
        localStorage.setItem("libraryData", strSettingsFile);
        this.props.history.push(`/frymasterCooking`);
    }

    handleModelChange(e) {
        this.setState({
            selectedModel: e.target.value,
        }, () => {
            //call the required function to fill the values
            // console.log('function called recipe Editor - value :' + e.target.value);
        });
    }

    closeModel() {
        this.setState({
            error_modal: {
                open: false,
                message: ""
            }
        })
    }

    onBackNavigation = () => {
        this.props.history.push(`/frymasterCooking`);
    }



    handleOnSliderChange = (value, stateKey) => {
        this.setState({
            [stateKey]: value
        })
    }

    onToggleChange = (e, stateKey) => {
        this.setState({
            [stateKey]: e.toString()
        });
    }

    onSetValueChange = (e, key) => {
        const re = /^[0-9\b]+$/;
        // if value is not blank, then test the regex
        if (e.target.value === '' || re.test(e.target.value)) {
            this.setState({ [key]: e.target.value })
        }
    }

    render() {
        const { formatMessage } = this.props.intl;
        let brandid = localStorage.getItem("selectedbrandID");

        const { prompt, promptTimer, promptTemperature, error_modal } = this.state;
        return (
            <React.Fragment>
                {/* Frymaster Cooking Smart Cook Header Page */}
                <UserManagementHeader headerName="Menu & Unit Settings" headerNameStringID="KC1776" activeClass="menuNav" filterKey={appconstants.menuFilters} />
                <div id="page-content-wrapper">
                    <div class="menuUpdateWrapper">
                        <div class="menuFilterHeader fixedHeader">
                            <ul>
                                <li>
                                    <Link to="/libraryList" title={formatMessage({ id: 'KC0658' })}><FormattedMessage id="KC0658"/></Link>
                                    <Link to="/librarySelectDevice" title={formatMessage({ id: 'KC1180' })} class="active"><FormattedMessage id="KC1180"/></Link>
                                    <Link to="#" title={formatMessage({ id: 'KC0523' })} ><FormattedMessage id="KC0523"/></Link>
                                </li>
                                <li>
                                    <ModelFamilyList brandid={brandid} isModelEditable={false} handleChangeProps={(e) => this.handleModelChange(e)} />
                                    <button class="btn btn-default-text menuDownload disabled">&nbsp;</button>
                                </li>
                            </ul>
                        </div>

                        {/* FryMaster Cooking Smart Cook Page Content */}

                        <div class="rcDeviceWrapper">
                            {/* Frymaster Logo */}
                            <div class="rcSelectedBrandLogo">
                                <img src={require("../../../../../../images/logos/frymasterW.png")}  alt=""/>
                            </div>

                            {/* Frymaster Cooking Smart Cook Wrapper */}
                            <div class="recipeEditorWrapper">
                                <div class="recipeEditorH selectedH">
                                    <button type="button" onClick={() => { this.onBackNavigation() }} class="btn backButton">&nbsp;</button> <FormattedMessage id="KC1466"/>
                                </div>



                                <div class="reBodyWrapper mCustomScrollbar">
                                    <div class="reWrapperTable">
                                        <div class="reWrapperRow">
                                            <div class="reWrapperColmn">
                                                <div class="frymasterRCForm">
                                                    <ul>
                                                        <li>Prompt</li>
                                                        <li>
                                                            <div class="btn-group-toggle" data-toggle="buttons">
                                                                <span class={prompt === "false" ? "toggleOFF" : "toggleOFF disabled"}><FormattedMessage id="KC1360"/></span>
                                                                <Switch
                                                                    onChange={(e) => this.onToggleChange(e, 'prompt')}
                                                                    checked={prompt === "true" ? true : false}
                                                                    uncheckedIcon={false}
                                                                    checkedIcon={false}
                                                                    onColor={'#64c832'}
                                                                    offColor={'#c4c1c0'}
                                                                    offHandleColor={'#ffffff'}
                                                                    onHandleColor={'#ffffff'}
                                                                /> <span class={prompt === "true" ? "toggleON" : "toggleON disabled"}><FormattedMessage id="KC1405"/></span>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                    <ul>
                                                        <li><FormattedMessage id="KC1467"/></li>
                                                        <li class="smallFormFields">
                                                            <InputMask
                                                                placeholder={formatMessage({ id: 'KC1978' })}
                                                                onChange={(e) => this.onSetValueChange(e, "promptTimer")}
                                                                value={promptTimer}
                                                                class="form-control" />
                                                        </li>
                                                    </ul>
                                                    <ul class="borderNone">
                                                        <li class="width190px"><FormattedMessage id="KC1468"/></li>
                                                        <li class="smallFormFields">
                                                            <InputMask
                                                                placeholder={this.state.generalTemperature === "°F" ? formatMessage({ id: 'KC1980' }) : formatMessage({ id: 'KC1981' })}
                                                                onChange={(e) => this.onSetValueChange(e, "promptTemperature")}
                                                                value={promptTemperature}
                                                                class="form-control" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                {/* Language Button */}
                                <div class="recipeEditorF">
                                    <button type="button" class="btn languageButton">&nbsp;</button>
                                    <button type="button" onClick={() => { this.onConfirmButtonClick() }} class="btn confirmCButton">&nbsp;</button>

                                </div>
                            </div>
                            {/* Frymaster Cooking Smart Cook Footer */}
                            <div class="footerText"><FormattedMessage id="KC1217"/></div>
                        </div>
                    </div>
                </div>
                <ErrorMoal open={error_modal.open} stringID={error_modal.message} message={error_modal.message} onCloseModel={() => this.closeModel()} />
            </React.Fragment>
        )
    }
}

export default injectIntl(FrymasterCookingSmarkCook)
