import React, { Component } from 'react';
import { format, addDays } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { HolderList } from '../../unitDashboard/globalWidgets/holderList';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import LoadingPopup from '../../controls/loadingPopup';
import http from '../../../service/httpService';
import { ReportManagement } from '../../../service/api';
import { TableHeader } from '../../controls/tableHeader';
import { NO_RESULT_MSG } from '../../../utils/appConstants';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class OilDisposeInformation extends Component {

    constructor(props, context) {
        super(props, context);
        const holder_name = 'holder_name_' + props.widgetID;
        this.state = {
            type: 'Month',
            filterType: "Month",
            noResultMsg: 'No Results',
            sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
            startDate: '',
            endDate: '',
            loaderIconVisible: false,
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            holder_id: "Frypot 1",
            [holder_name]: "Fryer",
            datapickflag:false
        };

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount = async () => {
        this.setState({
            filterType: "Month",
        });
        currentUnit = this.props.properties.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        this.getOilDisposeInformationData("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, this.props.widgetID, this.state.holder_id.replace("Frypot ", ""));
    }

    getOilDisposeInformationData(type, fromdate, todate, unitID, brandID, widgetId, holder_id = '') {
        this.setState({ loaderIconVisible: true });
        let url = '';
        let filterType = '';
        switch (type) {
            case 'Year':
                filterType = 'yearly';
                break;
            case 'Quarter':
                filterType = 'quarterly';
                break;
            case 'Date Range':
                filterType = 'custom';
                break;
            default:
                filterType = 'monthly';
        }

        url = `${ReportManagement.widgetAPI}/${widgetId}/${brandID}`;

        const header = {
            'Content-Type': 'application/json',
            'unitid': unitID,
            'reqdate': fromdate,
            'filtertype': filterType,
        }

        if (filterType === 'custom') {
            delete header.reqdate;
            header.startdate = todate;
            header.enddate = fromdate;
        }
        if (holder_id !== "") {
            header.holderid = holder_id;
        }

        this.getData(url, header).then(response => {
            let disposeSummary = [];
            if (response && response.widgetInformation && response.widgetInformation[0].content) {
                disposeSummary = response.widgetInformation && response.widgetInformation[0].content ? response.widgetInformation[0].content : [];
            }
            this.setState({
                loaderIconVisible: false,
                disposeSummary: disposeSummary
            });
        }).catch(error => {
            this.setState({ loaderIconVisible: false });
            console.log(error);
        });
    }

    getData(url, header) {
        return new Promise((resolve, reject) => {
            http
                .get(url,
                    {
                        headers: header,
                        data: {}
                    })
                .then(response => {
                    resolve({
                        widgetInformation: response.data
                    })
                })
                .catch(error => {
                    reject({
                        widgetInformation: {}
                    })
                });
        });
    }

    handleChangeStart(filtrationDate, mindate) {
        this.setState({
            startDate: filtrationDate,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            this.onDropDownHandler(e, 'LBSOilLifeDispose');
        });
    }

    handleChangeEnd(filtrationDate) {
        this.setState({
            endDate: filtrationDate,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            var e = { target: { text: selectedtext }, nodeText:selectedtext };
            this.onDropDownHandler(e, 'LBSOilLifeDispose');
        });
    }

    fetchData(holder_id, text) {
        currentUnit = this.props.properties.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else {
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
        }

        this.setState({ type: text });
        if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.getOilDisposeInformationData(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, this.props.widgetID, holder_id);
        }
    }

    onDropDownHandler(e, type = '') {
        this.fetchData(this.state.holder_id.replace("Frypot ", ""), e.nodeText);
        this.setState({
            filterType: e.nodeText,
            datapickflag: e.nodeText === 'Date Range'
        });
    }

    onHolderSelection(e, type = '') {
        const holderid = e.target.text.replace("Frypot ", "").replace("Fryer", "")
        this.setState({
            holder_id: holderid,
            holder_name: e.target.text
        });
        this.fetchData(holderid, type);
    }

    onSort = sortColumn => {
        this.setState({
            sortColumn: sortColumn
        });
    }

    render() {
        let { disposeSummary,
            loaderIconVisible,
            sortColumn,
            holder_id } = this.state;
        currentUnit = this.props.properties.currentUnit;
        let showDisposeInfoBlank = null;
        if (disposeSummary && disposeSummary.length > 0) {
            showDisposeInfoBlank = disposeSummary && disposeSummary.length > 0 ? disposeSummary : [];
        }
        const showDisposeInfo = _.orderBy(showDisposeInfoBlank, [sortColumn.path], [sortColumn.order]);
        let displayDisposeSummary;

        // let filtrationWidgetsName = 'Quick Filter';
        let filtrationWidgetsNameStringID = 'KC1869' // Quick Filter Compliance
        const productName = _.upperCase(currentUnit.MODEL_FAMILY_NAME);
        if (productName && ['JLOV', 'TLOV'].indexOf(productName) > -1) {
            // filtrationWidgetsName = 'Auto Filter',
            filtrationWidgetsNameStringID = 'KC1870' // Auto Filter Compliance
        }

        let columns = [
            { path: "sno", label: "#" },
            { path: "newOilDate", labelStringID:"KC0481" },
            { path: "disposeDate", labelStringID:"KC0482" },
            { path: "oilLife", labelStringID: "KC0483" }, 
            { path: "tpmValue", labelStringID:"KC0484" },
            { path: "cooksCompleted", labelStringID:"KC0485" },
            { path: "filterCompliance", labelStringID: filtrationWidgetsNameStringID }, 
        ];
        if (Array.isArray(showDisposeInfo) && showDisposeInfo.length) {
            displayDisposeSummary = showDisposeInfo.map((el, i) =>
                <React.Fragment key={el.sno}>
                    <div class="disposeInformationTable">
                        <ul className="tBody" >
                            <li>{el.sno}</li>
                            <li>{el.newOilDate}
                                <div class="disposeInformationSM"><FormattedMessage id="KC0482"/>
                                <div>{el.disposeDate}</div>
                                </div></li>
                            <li>{el.disposeDate}</li>
                            <li>{el.oilLife ? el.oilLife : '0'}</li>
                            <li>{el.tpmValue ? el.tpmValue.toFixed(1) : '0'}</li>
                            <li>{el.cooksCompleted ? el.cooksCompleted : '0'}</li>
                            <li>{el.filterCompliance ? el.filterCompliance.toFixed() + '%' : '0%'}</li>
                        </ul>
                    </div>
                </React.Fragment>
            )
        }
        else {
            displayDisposeSummary = (<div className="noResultMsg"><FormattedMessage id="KC0264"/></div>)
        }

        let disposeTableContent = null;

        if (holder_id) {
            disposeTableContent = (
                <React.Fragment>
                    <div class="disposeInformationTable">
                        <TableHeader
                            sortColumn={sortColumn}
                            onSort={this.onSort}
                            columns={columns} />
                    </div>
                    {/*Show Dispose Information when frypot selected */}
                    {displayDisposeSummary}
                </React.Fragment>
            )
        }

        let disposeInformation = (
            <div class="gridViewTable">
                <div class="gridView">
                    <div class="colm12D">
                        <div class="colmDi">
                            <h4><FormattedMessage id="KC0480"/>
                                <div className="oilDisposeFilter">
                                    <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate}
                                        minDateDisable={this.state.sdmindate}
                                        maxDateDisable={this.state.sdmaxdate}
                                        endminDateDisable={this.state.edmindate}
                                        endmaxDateDisable={this.state.edmaxdate}
                                        endDatepara={this.state.endDate}
                                        handleChangeStartProps={(date) => this.handleChangeStart(date)}
                                        handleChangeEndProps={(date) => this.handleChangeEnd(date)}
                                        OnChange={(e) => this.onDropDownHandler(e, "LBSOilLifeDispose")}
                                        filterType={this.state.type}
                                        disableDayFilter={true}
                                        disableWeekFilter={true}
                                        minDaysRequired={29} />
                                    <HolderList OnHolderChange={(e) => this.onHolderSelection(e, this.state.type)} isDisplayAll={false} />
                                </div>
                            </h4>
                            <div className="disposeInformationTableOuter">
                                {disposeTableContent}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
        return (
            <React.Fragment>
                <LoadingPopup loading={loaderIconVisible} />
                {/* Level 2 | Widgets - 82 (Frymaster | Dispose Information )  */}
                {disposeInformation}
            </React.Fragment>
        )
    }
};

export default OilDisposeInformation;
