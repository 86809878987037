import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { format, addDays } from 'date-fns';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { getCleaningTableInfo } from '../../../actions/reportManagement/cleaningTableAction';
import { TableHeader } from '../../controls/tableHeader';
import _ from 'lodash';
import { FormattedMessage } from 'react-intl';

var moment = require('moment');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
let weekDateFormatted;
let unitID = "";
let brandID = "";
let currentUnit;

class FiltrationTableData extends Component {

    constructor(props, context) {
        super(props, context);
        this.state = {
            type: 'Month',
            filterType: "Month",
            noResultMsg: 'No Results',
            sortColumn: { path: "", order: "", sort_color: "#FFFFFF" },
            sd: '',
            ed: '',
            startDate: '',
            endDate: '',
            loaderIconVisible: true,
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            datapickflag: false
        };

        this.handleChangeStart = this.handleChangeStart.bind(this);
        this.handleChangeEnd = this.handleChangeEnd.bind(this);
    }

    componentDidMount = async () => {
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_NAME;
        this.props.getCleaningTableInfo("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID, '67');

        setTimeout(() => {
            this.setState({
                loaderIconVisible: false,
            });
        }, 2000)
    }

    componentDidUpdate = async(prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleFiltrationTable(this.props.globalDropdownFilter, '');
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDate !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEnd(this.props.globalDropdownFilterEndDate);
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDate !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStart(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    handleChangeStart(filtrationDate) {
        if(this.state.startDate === filtrationDate){
            return;
        }
        this.setState({
            startDate: filtrationDate,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleFiltrationTable(selectedtext);
        });
    }

    handleChangeEnd(filtrationDate) {
        if(this.state.endDate ===  filtrationDate){
            return;
        }
        this.setState({
            endDate: filtrationDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleFiltrationTable(selectedtext);
        });
    }

    onDropDownHandler(e) {
        const { nodeText:text } = e;
        this.setState({type: text,datapickflag: text === 'Date Range',startDate:'',endDate:''})
        this.handleFiltrationTable(text);
    }

    handleFiltrationTable(text) {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_NAME;
        if (text === "Date Range") {
            todaysDateFormatted = format(this.state.endDate, 'YYYY-MM-DD');
            weekDateFormatted = format(this.state.startDate, 'YYYY-MM-DD');
        }
        else {
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
            weekDateFormatted = format(addDays(todaysDate, - 6), 'YYYY-MM-DD');
        }
        if ((todaysDateFormatted !== "Invalid Date" && weekDateFormatted !== "Invalid Date") && (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.props.getCleaningTableInfo(text, todaysDateFormatted, weekDateFormatted, unitID, brandID, '67');
        }
    }

    onSort = sortColumn => {
        this.setState({
            sortColumn
        });
    }

    render() {
        const { filterTableInfo } = this.props;
        let { sortColumn, type } = this.state;
        let filterTableData = filterTableInfo ? filterTableInfo : {};
        let filterTableContent = filterTableData.content && filterTableData.content.length > 0 ? filterTableData.content : [];
        const displayContents = _.orderBy(filterTableContent, [sortColumn.path], [sortColumn.order]);
        let displayFilterDatas;
        let columns = [
            { path: "", label: "#" },
            { path: "filter_type", labelStringID: "KC0462" },
            { path: "filter_Requested", labelStringID: "KC0463" },
            { path: "filter_Bypassed", labelStringID: "KC0464" },
            { path: "filter_completed", labelStringID: "KC0465" },
            { path: "", labelStringID: "KC0325" },
        ];
        if (Array.isArray(displayContents) && displayContents.length) {
            displayFilterDatas = displayContents.map((el, i) =>
                <React.Fragment key={el.filter_type}>
                    <div className="filtrationDataTable">
                        <ul className="tBody" >
                            <li>{i + 1}</li>
                            <li>{el.filter_type.charAt(0).toUpperCase() + el.filter_type.slice(1)}</li>
                            <li>{el.filter_Requested}</li>
                            <li>{el.filter_Bypassed}</li>
                            <li>{el.filter_completed}</li>
                            <li><a role="button" data-toggle="collapse" draggable="false" data-parent="#holdingDataAccordion" data-target={`#holdingDataAcc_` + i} aria-expanded="false" aria-controls={`holdingDataAcc_` + i} className="btn collapseExpand collapsed">&nbsp;</a></li>
                        </ul>
                    </div>

                    <div id={`holdingDataAcc_` + i} className="panel-collapse collapse">
                        <div className="panel-body filtrationDataExpTable">
                            {el.details.map((items, j) =>
                            (<ul className="tBody" key={j}>
                                <li></li>
                                <li>{' Frypot '}{items.Frypot_Id}</li>
                                <li>{items.filter_Requested}</li>
                                <li>{items.filter_Bypassed}</li>
                                <li>{items.filter_completed}</li>
                                <li></li>
                            </ul>)
                            )}
                        </div>
                    </div>
                </React.Fragment>
            )
        }
        else {
            displayFilterDatas = (<div className="noResultMsg"><FormattedMessage id="KC0264"/></div>)
        }
        return (
            <div className="gridViewTable">
                <div className="gridView">
                    <div className="colm12D">
                        <div className="colmDi">
                            <h4>{ this.props.widgetNameStringID ? <FormattedMessage id={this.props.widgetNameStringID}/> : this.props.widgetName}
                                <span className="cornerIcon"></span>
                                <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDate}
                                    minDateDisable={this.state.sdmindate}
                                    maxDateDisable={this.state.sdmaxdate}
                                    endminDateDisable={this.state.edmindate}
                                    endmaxDateDisable={this.state.edmaxdate}
                                    endDatepara={this.state.endDate}
                                    handleChangeStartProps={(date) => this.handleChangeStart(date)}
                                    handleChangeEndProps={(date) => this.handleChangeEnd(date)}
                                    OnChange={(e) => this.onDropDownHandler(e, "FiltrationData")}
                                    filterType={type}
                                    disableDatePicker={false} />
                            </h4>
                            {/* Filtration Data Table Starts  */}
                            <div className="filtrationDataTableDiv">

                                <div className="filtrationDataTableOuter">
                                    <div className="filtrationDataTable">
                                        <TableHeader
                                            sortColumn={sortColumn}
                                            onSort={this.onSort}
                                            columns={columns} />
                                    </div>
                                    <div className="panel panel-default" id="holdingDataAccordion" role="tablist" aria-multiselectable="true">
                                        {/* <!-- Header 01 --> */}
                                        {displayFilterDatas}
                                        {/* <!-- Header 01 Content End --> */}
                                    </div>
                                </div>
                            </div>
                            {/* Filtration Data Table Ends */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        filterTableInfo: state.cleaningTableInformation.cleaningTableInformation,
        filterType: state.filterType.filterType,
        currentUnit: state.entityReducer.currentUnit,
        currentLocation: state.entityReducer.currentLocation
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getCleaningTableInfo,
        getCurrentLocation,
        getCurrentUnit
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(FiltrationTableData);
