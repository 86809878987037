import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { format } from 'date-fns';
import { NewDropDownListLink } from '../../controls/newDropDownListLink';
import { getAvgTrays } from '../../../actions/unitDashboard/averageTraysAction';
import { getCurrentUnit, getCurrentLocation } from '../../../actions/organizationentity/entityactions';
import { UNIT_BRAND_ID } from '../../../utils/appConstants';
import * as _ from 'lodash';
import NumberFormat from 'react-number-format';
import { FormattedMessage } from 'react-intl';
import { mappingStringID } from '../../../locale/constant';

// common global parameters
var startOfWeek = require('date-fns/start_of_week');
var startOfMonth = require('date-fns/start_of_month');
var moment = require('moment');
var getMonth = require('date-fns/get_month');
var todaysDate = new Date();
let todaysDateFormatted = format(todaysDate, 'MM-DD-YYYY');
let weekDate = new Date();
let weekDateFormatted = format(startOfWeek(weekDate, { weekStartsOn: 1 }), 'MM-DD-YYYY');
let unitID = "";
let brandID = "";
let currentUnit;

class AverageTrays extends Component {
    constructor(props) {
        super(props);
        this.state = {
            type: 'Month',
            locationInformation: {},
            deviceInformation: {},
            sdmindate: todaysDate,
            sdmaxdate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            edmaxdate: todaysDate,
            edmindate: moment(todaysDate).subtract('month', 6).subtract(1, 'days')._d,
            startDateOnTime: "",
            endDateOnTime: "",
            datapickflag: false
        }
        this.handleChangeStartOnTime = this.handleChangeStartOnTime.bind(this);
        this.handleChangeEndOnTime = this.handleChangeEndOnTime.bind(this);
    }

    componentDidMount = async () => {
        currentUnit = this.props.currentUnit;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');
        this.props.getAvgTrays("Month", todaysDateFormatted, weekDateFormatted, unitID, brandID);

    }

    componentDidUpdate = async (prevProp, prevState) => {
        if (prevProp.globalDropdownFilter !== this.props.globalDropdownFilter && prevState.type !== this.props.globalDropdownFilter && this.props.isGlobalDropdownChange) {
            this.setState({ type: this.props.globalDropdownFilter, datapickflag: this.props.globalDropdownFilter === 'Date Range' });
            this.handleAvaerageTrays(this.props.globalDropdownFilter, 'AvgTrays');
            await this.props.handleDropdownChangeEnd();
        } else {
            if (this.props.globalDropdownFilter === 'Date Range' && this.props.isGlobalDropdownChange) {
                if (prevState.endDateOnTime !== this.props.globalDropdownFilterEndDate) {
                    this.handleChangeEndOnTime(this.props.globalDropdownFilterEndDate)
                    await this.props.handleDropdownChangeEnd();
                }
                if (prevState.startDateOnTime !== this.props.globalDropdownFilterStartDate) {
                    this.handleChangeStartOnTime(this.props.globalDropdownFilterStartDate);
                    await this.props.handleDropdownChangeEnd();
                }
            }
        }
    }

    //start Date Change Handler
    handleChangeStartOnTime(onTimeDate) {
        if(this.state.startDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            startDateOnTime: onTimeDate,
            datapickflag:true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleAvaerageTrays(selectedtext, 'AvgTrays');
        });
    }

    //end Date Change Handler
    handleChangeEndOnTime(onTimeDate) {
        if(this.state.endDateOnTime === onTimeDate){
            return;
        }
        this.setState({
            endDateOnTime: onTimeDate,
            datapickflag: true
        }, () => {
            let selectedtext = 'Date Range';
            this.handleAvaerageTrays(selectedtext, 'AvgTrays');
        });
    }

    //dropdown Handler
    onDropDownHandler(e, type = '') {
        const { nodeText: text } = e;
        this.setState({ type: text, datapickflag: text === 'Date Range', startDateOnTime: '', endDateOnTime: ''})
        this.handleAvaerageTrays(text, type);

    }

    handleAvaerageTrays(text, type) {
        currentUnit = this.props.currentUnit;
        const { startDateOnTime, endDateOnTime } = this.state;
        currentUnit.UNITID = currentUnit.UNITID ? currentUnit.UNITID : localStorage.getItem("unitID");
        unitID = currentUnit.UNITID;
        brandID = currentUnit.BRAND_ID ? currentUnit.BRAND_ID : UNIT_BRAND_ID;
        if (text === "Date Range") {
            if (type === "AvgTrays") {
                todaysDateFormatted = format(endDateOnTime, 'YYYY-MM-DD');
                weekDateFormatted = format(startDateOnTime, 'YYYY-MM-DD');
            }
        }
        else if (text === 'Week') {
            weekDateFormatted = format(startOfWeek(todaysDate, { weekStartsOn: 1 }), 'YYYY-MM-DD');
            todaysDateFormatted = format(todaysDate, 'YYYY-MM-DD');

            var startDateMonth = getMonth(new Date(weekDateFormatted));
            var endDateMonth = getMonth(new Date(todaysDateFormatted));

            if (startDateMonth !== endDateMonth) {
                weekDateFormatted = format(startOfMonth(todaysDate), 'YYYY-MM-DD');
            }
        }
        else {
            todaysDateFormatted = format(new Date(), 'YYYY-MM-DD');
            weekDateFormatted = format(startOfWeek(new Date(), { weekStartsOn: 1 }), 'YYYY-MM-DD');
        }


        if ((todaysDateFormatted != "Invalid Date" && weekDateFormatted != "Invalid Date") &&
            (weekDateFormatted <= todaysDateFormatted || todaysDateFormatted >= weekDateFormatted)) {
            this.props.getAvgTrays(text, todaysDateFormatted, weekDateFormatted, unitID, brandID);
        }

    }

    returnNumberFormat = (value) =>{
        let newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={true} />
        if (localStorage.getItem("thousandSeparator") === "."){
            newNumberFormat =  <NumberFormat value={value} displayType={'text'} thousandSeparator={"."} decimalSeparator={","} />
        }
        return newNumberFormat
    }

    render() {

        const { avgTraysInfo } = this.props;
        const avgTraysObject = avgTraysInfo.avgTraysResponse ? avgTraysInfo.avgTraysResponse : {};
        let avgTraysModel = avgTraysObject.content;
        let avgTraysValue = 0;
        let commonFilterConsumptionValue = null;
        let customFilterWidget = null;
        let avgCurrentValue = null;
        let avgPriorValue = null;

        const isTrend = _.isUndefined(avgTraysModel) ? false : _.isObject(_.find(avgTraysModel, el => el.key === 'trend'));
        avgTraysValue = avgTraysModel && avgTraysModel[0] ? avgTraysModel[0].value : "0";

        let AverageTraysResponseValue = null;
        if (Array.isArray(avgTraysModel) && avgTraysModel.length) {

            avgCurrentValue = (avgTraysModel[0].value > 0 && avgTraysModel[0].value < 1) ? "<1" : (this.returnNumberFormat(Math.round(avgTraysModel[0].value)));
            if (avgTraysModel.length > 1) {
                avgPriorValue = (avgTraysModel[1].value > 0 && avgTraysModel[1].value < 1) ? "<1" : (this.returnNumberFormat(Math.round(avgTraysModel[1].value)));
            }
            commonFilterConsumptionValue = (<div className="valH1Div">{avgCurrentValue} {(isTrend && this.state.type !== "Date Range") && (<span className={avgTraysModel[2].value === '1' ? "valH1Span valHigh" : avgTraysModel[2].value === '0' ? "valH1Span valLow" : "valH1Span"}> <FormattedMessage id="KC1897"/></span>)}</div>); 

            AverageTraysResponseValue = (
                <div class="colmDiVal">
                    { commonFilterConsumptionValue}
                    { (isTrend && this.state.type !== "Date Range") && (<div class="valH5Div">
                    { mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }:  {avgPriorValue}
                    </div>)}
                </div>
            )
        }
        else {
            customFilterWidget = (<div className="valH1Div"> { avgTraysValue}{(isTrend && this.state.type !== "Date Range") && (<span className={avgTraysValue === '1' ? "valH1Span valHigh" : avgTraysValue === '0' ? "valH1Span valLow" : "valH1Span"}> &nbsp;</span>)}</div>);

            AverageTraysResponseValue = (
                <div class="colmDiVal">
                    { customFilterWidget}
                    { (this.state.type !== "Date Range") && (<div class="valH5Div">
                    { mappingStringID[`prior ${this.state.type.toLowerCase()}`] ? <FormattedMessage id={mappingStringID[`prior ${this.state.type.toLowerCase()}`]}/> : `prior ${this.state.type.toLowerCase()}` }: {avgTraysValue}
                    </div>)}
                </div>
            )
        }
        // Convotherm Average Trays Widget
        let AverageTraysWidget = null;
        AverageTraysWidget = (<div class="colmDi">
            <h4><FormattedMessage id='KC0393'/>
                    <span className="cornerIcon"></span>
                <NewDropDownListLink translation={true} datapickflag={this.state.datapickflag} startDatepara={this.state.startDateOnTime} minDateDisable={this.state.sdmindate} maxDateDisable={this.state.sdmaxdate}
                    endminDateDisable={this.state.edmindate} endmaxDateDisable={this.state.edmaxdate} endDatepara={this.state.endDateOnTime} handleChangeStartProps={(date) => this.handleChangeStartOnTime(date)} handleChangeEndProps={(date) => this.handleChangeEndOnTime(date)}
                    OnChange={(e) => this.onDropDownHandler(e, "AvgTrays")} filterType={this.state.type} />
            </h4>
            {AverageTraysResponseValue}
        </div>)

        return (
            <React.Fragment>
                <div className="colm4D colm4D3">
                    {AverageTraysWidget}
                </div>
            </React.Fragment>
        )
    }
};
function mapStateToProps(state) {
    return {
        currentLocation: state.entityReducer.currentLocation,
        currentUnit: state.entityReducer.currentUnit,
        avgTraysInfo: state.avgTraysResponse,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ getCurrentUnit, getCurrentLocation, getAvgTrays }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AverageTrays);
